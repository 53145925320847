import Dashboard from "../../_pages/Dashboard";
import FuelManagementDashboard from "../../_pages/FuelManagementSystem/Dashboard/FuelManagementDashboard";
import FuelManagementSapDashboard from "../../_pages/FuelManagementSystem/Dashboard/FuelManagementSapDashboard";
import FuelManagement from "../../_pages/FuelManagementSystem/FuelManagement/FuelManagement";
import FuelManagementAdd from "../../_pages/FuelManagementSystem/FuelManagementAdd";
import MyReceiptList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Receipt/MyReceiptList";
import ReceiptAdd from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Receipt/ReceiptAdd";
import ReceiptList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Receipt/ReceiptList";
import FuelManagementList from "../../_pages/FuelManagementSystem/FuelManagementList";
import CapaEdit from "../../_pages/SafetyManagementSystem/CapaEdit";
import CapaAssignedIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/CapaAssignedIncidentList";
import IncidentManagementDashboard from "../../_pages/SafetyManagementSystem/IncidentManagement/Dashboard/IncidentManagementDashboard";
import IncidentManagement from "../../_pages/SafetyManagementSystem/IncidentManagement/IncidentManagement";
import IncidentReport from "../../_pages/SafetyManagementSystem/IncidentManagement/IncidentReport";
import InvestigationIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/InvestigationIncidentList";
import MyApprovalCapOwnerIncidentHistoryList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyApprovalCapOwnerIncidentHistoryList";
import MyApprovalInvestigatorIncidentHistoryList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyApprovalInvestigatorIncidentHistoryList";
import MyApprovalReportedIncidentHistoryList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyApprovalReportedIncidentHistoryList ";
// import MyIncidentApprovalHistoryList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyIncidentApprovalHistoryList";
import MyIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyIncidentList";
import ReportedIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/ReportedIncidentList";
import SafetyManagementDashboard from "../../_pages/SafetyManagementSystem/IncidentManagement/SafetyManagementDashboard";
import ViewIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/ViewIncidentList";
import SafetyManagementEdit from "../../_pages/SafetyManagementSystem/SafetyManagementEdit";
import SafetyManagementList from "../../_pages/SafetyManagementSystem/SafetyManagementList";
import FuelMgmtDashboard from "../../components/Common/dashboard/ModuleDashboard/FuelManagemtDashboard";
import MobProfile from "../../components/MobProfile/MobProfile";
import ViewProfile from "../../components/Profile/ViewProfile";

export const MOBILE_ROUTE = [
  {
    path: "/mobile",
    element: <Dashboard />,
  },
  {
    path: "/mobile/profile",
    element: <MobProfile />,
    footerActive: true,
  },
  {
    path: "/mobile/view-profile",
    element: <ViewProfile />,
    footerActive: true,
  },
  {
    path: "/mobile/safety-management-system/dashboard",
    element: <SafetyManagementDashboard />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
    footerActive: true,
    // isDashboard: true,
  },
  {
    path: "/mobile/safety-management-system/dashboard/:id",
    element: <IncidentManagementDashboard />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
    footerActive: true,
    isDashboard: true,
    firstPage: 1,
  },

  // Incident Management
  {
    path: "/mobile/safety-management-system/incident-management",
    element: <IncidentManagement />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
    footerActive: true,
  },
  {
    path: "/mobile/safety-management-system/incident-management/report/add",
    element: <IncidentReport />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/mobile/safety-management-system/incident-management/report/edit/:id",
    element: <IncidentReport />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    element: <ViewIncidentList />,
    path: "/mobile/safety-management-system/incident-management/view-incidents",
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    element: <MyIncidentList />,
    path: "/mobile/safety-management-system/incident-management/my-incidents",
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/mobile/safety-management-system/incident-management/my-approval-action",
    element: <SafetyManagementList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/mobile/safety-management-system/incident-management/incident-reported",
    element: <ReportedIncidentList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/mobile/safety-management-system/incident-management/incident-investigation",
    element: <InvestigationIncidentList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/mobile/safety-management-system/incident-management/incident-capa",
    element: <CapaAssignedIncidentList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  // {
  //   path: "/mobile/safety-management-system/incident-management/incident-approval-history",
  //   element: <MyIncidentApprovalHistoryList />,
  //   permission: [
  //     "safety_admin",
  //     "safety_user",
  //     "safety_management_dashbaord",
  //     "super_admin",
  //   ],
  // },
  {
    path: "/mobile/safety-management-system/incident-management/incident-reported-approval_history",
    element: <MyApprovalReportedIncidentHistoryList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/mobile/safety-management-system/incident-management/incident-investigator-approval_history",
    element: <MyApprovalInvestigatorIncidentHistoryList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/mobile/safety-management-system/incident-management/incident-capa-owner-approval_history",
    element: <MyApprovalCapOwnerIncidentHistoryList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/mobile/incident-management/edit/:id",
    element: <SafetyManagementEdit />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/mobile/safety-management-system/incident-management/capa/edit/:id",
    element: <CapaEdit />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },

  // Fuel Management
  {
    path: "/mobile/fuel-management-system/dashboard",
    element: <FuelMgmtDashboard />,
    // permission: ["fuel_management_dashbaord"],
    footerActive: true,
    // isDashboard: true,
  },
  {
    path: "/mobile/fuel-management-system/dashboard/:id",
    element: <FuelManagementDashboard />,
    permission: ["fuel_management_dashbaord"],
    footerActive: true,
    isDashboard: true,
    firstPage: 1,
  },
  {
    path: "/mobile/fuel-management-system/sap-dashboard/:id",
    element: <FuelManagementSapDashboard />,
    permission: ["fuel_management_dashbaord"],
    footerActive: true,
    isDashboard: true,
    firstPage: 1,
  },
  {
    path: "/mobile/fuel-management-system",
    element: <FuelManagement />,
    permission: ["fuel_admin", "dispenser", "fuel_management_dashbaord"],
    footerActive: true,
  },
  {
    path: "/mobile/fuel-management-system/fuel-management-list/create",
    element: <FuelManagementAdd />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/mobile/fuel-management-system/:id",
    element: <FuelManagementAdd />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/mobile/fuel-management-system/fuel-management-list",
    element: <FuelManagementList />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/mobile/fuel-management-system/fuel-management-list/:id",
    element: <FuelManagementList />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/mobile/fuel-management-system/fuel-management-list/fuel-receipt/create",
    element: <ReceiptAdd />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/mobile/fuel-management-system/fuel-management-list/fuel-receipt/:id",
    element: <ReceiptAdd />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/mobile/fuel-management-system/fuel-management-list/fuel-receipt",
    element: <ReceiptList />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/mobile/fuel-management-system/fuel-management-list/my-fuel-receipt",
    element: <MyReceiptList />,
    permission: ["fuel_admin", "dispenser"],
  },
];
