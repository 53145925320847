import React, { useState, useEffect, useMemo } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import {
  SidebarData,
  reasonListApisStationTypeWater,
  reasonListColumnFields,
} from "./DATA";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import { tabUrl } from "../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import { permissionCheck } from "../../../_services/Auth/helper";
import EmptyDataList from "../../ContractorManagement/ContractorPerformance/EmptyDataList";
import { getAllMasterDropdownStationTypeWater } from "../../../services/_reasonList/reasonList";
import { auth_user } from "../../../../src/_helpers/helper";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";

const MasterStationTypeWaterList = () => {
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.envConfig)
  );
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const authUser = auth_user();
  const [isSuperAdmin, setIsSuperAdmin] = useState(true);
  const super_admin = useMemo(() => {
    setIsSuperAdmin(authUser?.role?.roleLable);
  }, [authUser?.role?.roleLable]);
  let navigate = useNavigate();

  const tabButtons = () => <CustomTabs tabeList={customTabs} />;

  const onEdit = (id) =>
    navigate("/configuration/environment/sation-type-water/" + id);

  const getAll = async () => {
    const response = await getAllMasterDropdownStationTypeWater();
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });

    getAll();
  }, []);

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                pagination
                apis={reasonListApisStationTypeWater}
                fields={reasonListColumnFields}
                title="Data"
                hideSnColumn
                pageLink={"/configuration/environment/sation-type-water"}
                primaryKey="id"
                hideDeleteButton
                hideEditButton
                // childrenAfterAddButton={tabButtons}
                // hideThreeDotButton={isSuperAdmin == 1 ? false : true}
                editFunButton={onEdit}
                listname={"stationtype"}
                // hideAddButton={!permissionCheck(["super_admin"])}
                customTableTitle={"Water Station Type"}
                //   viewTitle={"Contractor Performance Details"}
                breadCrumb={[
                  {
                    name: "Environment Management System",
                    path: "#",
                  },
                  {
                    path: "/environment-management/configuration",
                    name: "Configuration",
                  },
                  {
                    name: "Water Station Type",
                    path: "#",
                  },
                ]}
                isNoTabOnPage
                hideSearch={true}
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            pageLink={"/configuration/environment/sation-type-water"}
            title={"Water Station Type"}
            breadCrumb={[
              {
                path: "#",
                name: "Environment Management System",
              },
              {
                name: "Configuration",
                path: "/environment-management/configuration",
              },
              {
                path: "#",
                name: "Water Station Type",
              },
            ]}
            // hideAddBtn={!permissionCheck(["super_admin"])}
            // tabButtons={tabButtons}
          />
        </div>
      </div>
    </div>
  );
};

export default MasterStationTypeWaterList;
