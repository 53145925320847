import {
  deleteIncident,
  getMyIncidentHistoryListWithPagination,
  getMyIncidentListWithPagination,
  getSafetyManagementListWithPagination,
  getViewIncidentListWithPagination,
} from "../../services/_safetyManagement/safetyManagement";
import { DDMMYYYYFormat, DDMMYYYYHHMMAFormat } from "../../_helpers/dateHelper";
import {
  getIncidentSeverityClass,
  incidentTypes,
  incidentTypesObj,
  severities,
  statusProgressObj,
} from "./IncidentManagement/DATA";
import CustomProgressBar from "../../components/Common/CustomProgressBar/CustomProgressBar";
import { IS_MOBILE } from "../../_helpers/helper";
import moment from "moment";

export const navigateUrl = `${
  IS_MOBILE() ? "/mobile" : ""
}/safety-management-system/incident-management`;
export const safetyAdminPermission = "safety_admin";

export const incidentCloseLabel = {
  conclusion:
    "Conclusion of the Cause and Circumstances Leading to the Accident & or Dangerous Occurrence.",
  averted: "How the accident / Incident could have been averted.",
  responsibilities:
    "Derived Responsibilities (with detailed reason & specific justification mentioning clearly the nature of violation/ Contravention/ non compliance of the statute ,circular, Safety guidelines, Safety-advice, Safety-Campaigns etc,.if  any",
  recommendation: `Recommended Effective & Impactful "Safety Campaigns" to be taken up in order to prevent recurrence of such Types & or Similar Types & or accident / incidents in future in the same mine and other mines.`,
  actionsTaken: "Action taken.",
};

export const safetyManagementColumnFields = [
  {
    selector: "incident_uid",
    sortable: true,
    name: "Incident ID",
  },
  {
    name: "Subsidiary",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.name,
    name: "Mine",
  },
  {
    name: "Incident Time",
    selector: "incident_datetime",
    sortable: true,
    cell: (row) => DDMMYYYYHHMMAFormat(row?.incident_datetime),
    minWidth: "200px",
  },
  {
    selector: "incidentMedias",
    sortable: true,
    cell: (row) => row?.category,
    minWidth: "150px",
    name: "Incident Category",
  },
  {
    name: "Department",
    selector: "department",
    sortable: true,
    // cell: (row) => row?.site?.name,
  },
  {
    sortable: true,
    name: "Status",
    selector: "status",
    minWidth: "250px",
    // cell: (row) => statusList[row?.status],
    cell: (row) => {
      return (
        <span className={`statusSec ${statusColor(row?.status)}`}>
          {statusList[row?.status]}
        </span>
      );
    },
  },
];

export const MyIncidentColumnFields = [
  {
    name: "Incident ID",
    selector: "incident_uid",
    sortable: true,
    isShowMobile: true,
    // omit: true,
  },
  {
    name: "Incident/Accident Date",
    selector: "incident_datetime",
    cell: (row) => DDMMYYYYFormat(row?.incident_datetime),
    sortable: true,
  },
  {
    name: "Subsidiary",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  {
    selector: "category",
    name: "Incident/Accident Type",
    sortable: true,
    cell: (row) => incidentTypesObj[row?.category],
    minWidth: "150px",
  },
  {
    selector: "severity",
    sortable: true,
    cell: (row) => {
      return (
        <div
          className={
            severities[row?.severity] &&
            `inSevrty ${getIncidentSeverityClass(row?.severity)}`
          }
        >
          {row?.severity}
        </div>
      );
    },
    name: "Incident/Accident Severity",
  },
  {
    name: "Progress",
    selector: "progress",
    // sortable: true,
    customClass: "progressBar",
    cell: (row) => {
      return row?.status === "draft" ? (
        statusProgressObj[row?.status]
      ) : (
        <CustomProgressBar value={statusProgressObj[row?.status]} />
      );
    },
  },
  {
    minWidth: "250px",
    name: "Status",
    selector: "status",
    sortable: true,
    // cell: (row) => statusList[row?.status],
    cell: (row) => {
      return (
        <span className={`statusSec ${statusColor(row?.status)}`}>
          {statusList[row?.status]}
        </span>
      );
    },
  },
];

export const MyIncidentHistoryColumnFields = [
  {
    name: "Incident ID",
    selector: "incident_uid",
    sortable: true,
  },
  {
    name: "Subsidiary",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  {
    name: "Incident/Accident Time",
    selector: "incident_datetime",
    sortable: true,
    cell: (row) => DDMMYYYYHHMMAFormat(row?.incident_datetime),
    minWidth: "200px",
  },
  {
    name: "Incident Category",
    selector: "incidentMedias",
    sortable: true,
    cell: (row) => row?.category,
    minWidth: "150px",
  },
  {
    name: "Department",
    selector: "department",
    sortable: true,
    // cell: (row) => row?.site?.name,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    minWidth: "250px",
    // cell: (row) => statusList[row?.status],
    cell: (row) => {
      return (
        <span className={`statusSec ${statusColor(row?.status)}`}>
          {statusList[row?.status]}
        </span>
      );
    },
  },
];

export const statusColor = (status) => {
  switch (status) {
    case statusListObj.reported:
      return "draft";
    case statusListObj.incident_closed:
      return "submit";
    case statusListObj.capa_assigned:
      return "draft";
    case statusListObj.capa_closed:
      return "submit";
    case statusListObj.investigation_completed:
      return "draft";
    case statusListObj.investigation_in_progress:
      return "draft";
    case statusListObj.draft:
      return "submit";
    default:
      return null;
  }
};

export const newStatusColor = (status) => {
  switch (status) {
    case statusListObj.reported:
      return "draft";
    case statusListObj.incident_closed:
      return "completed";
    case statusListObj.capa_assigned:
      return "assigned";
    case statusListObj.capa_closed:
      return "closed";
    case statusListObj.investigation_completed:
      return "assigned";
    case statusListObj.investigation_in_progress:
      return "inprogress";
    case statusListObj.draft:
      return "draft";
    default:
      return null;
  }
};

export const safetyManagementApis = Object.freeze({
  getAll: {
    api: getSafetyManagementListWithPagination,
  },
});

export const MyIncidentApis = Object.freeze({
  getAll: {
    api: getMyIncidentListWithPagination,
  },
});
export const ViewIncidentApis = Object.freeze({
  getAll: {
    api: getViewIncidentListWithPagination,
  },
  delete: {
    api: deleteIncident,
  },
});
export const MyIncidentHistoryApis = Object.freeze({
  getAll: {
    api: getMyIncidentHistoryListWithPagination,
  },
});

export const initialForm = {
  site_id: "",
  category: "",
  category_other: "",
  department: "",
  incident_datetime: "",
  incident_reportdatetime: "",
  additional_details: "",
  reported_by: "",
  status: "",
  is_lti: "",
  lti_count: "",
  number_of_injuries: "",
  number_of_fatality: "",
  comment: "",
  change_status: "",
  change_comment: "",
  currentAssignedUser: "",
  personResponsible: "",
  dbms_id: "",
  isCompensationInfor: false,
  isResponsiblePersonInfo: false,
  lossOfManshifts: "",
  actionsToPrevent: "",
  lossOfProduction: "",
  isDgmsReportable: false,
};

export const statusList = {
  reported: "Reported",
  investigation_in_progress: "Investigation In Progress",
  investigation_completed: "Investigation Completed",
  capa_assigned: "CAPA Assigned",
  capa_closed: "CAPA Closed",
  incident_closed: "Incident Closed",
  draft: "Draft",
};

export const isDgmsReportableOpt = {
  1: "Yes",
  0: "No",
};
export const formCOpt = {
  false: "Done",
  true: "Pending",
};

export const statusListObj = {
  reported: "reported",
  investigation_in_progress: "investigation_in_progress",
  investigation_completed: "investigation_completed",
  capa_assigned: "capa_assigned",
  capa_closed: "capa_closed",
  incident_closed: "incident_closed",
  draft: "draft",
};
export const capaPriority = {
  low: "Low",
  medium: "Medium",
  high: "High",
};

export const initialCapa = {
  capaType: "",
  remarks: "",
  priority: "",
  dueDate: "",
  ownerId: "",
  completionDate: null,
  actionsOrResolutions: "",
};

export const capaRules = [
  {
    label: "Completion Date",
    field_name: "completionDate",
    isRequired: true,
    type: "string",
  },
  {
    label: "Resolution Provided",
    field_name: "actionsOrResolutions",
    isRequired: true,
    type: "string",
  },
];

export const assignToRules = {
  label: "Assign To",
  field_name: "currentAssignedUser",
  isRequired: true,
  type: "string",
};

export const StatusUpdateRules = [
  {
    label: "Change Status",
    field_name: "change_status",
    isRequired: true,
    type: "string",
  },
  {
    label: "Comment",
    field_name: "change_comment",
    isRequired: true,
    type: "string",
  },
];
export const CategoryRules = [
  {
    label: "Branch",
    field_name: "branch",
    isRequired: true,
    type: "string",
  },
];
export const subCategoryRules = [
  {
    label: "Leaf",
    field_name: "leaf",
    isRequired: true,
    type: "string",
  },
];

export const safetyManagementFilterForm = {
  startDate: "",
  endDate: "",
  status: "",
  is_capa: false,
  is_investigated: false,
  // contractor_id: "",
};

export const tabUrl = {
  safetyManagement: [
    {
      to: "/safety-management-system/incident-management/incident-reported",
      label: "Reported",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
  ],
  safetyManagementApprovalHistory: [
    {
      to: "/safety-management-system/incident-management/incident-reported-approval_history",
      label: "Admin",
      active: 0,
      permission: ["safety_admin"],
    },
    {
      to: "/safety-management-system/incident-management/incident-investigator-approval_history",
      label: "Investigator",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
    {
      to: "/safety-management-system/incident-management/incident-capa-owner-approval_history",
      label: "CAPA Owner",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
  ],
};

export const mobileTabUrl = {
  safetyManagement: [
    {
      to: "/mobile/safety-management-system/incident-management/incident-reported",
      label: "Reported",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
  ],
  safetyManagementApprovalHistory: [
    {
      to: "/mobile/safety-management-system/incident-management/incident-reported-approval_history",
      label: "Admin",
      active: 0,
      permission: ["safety_admin"],
    },
    {
      to: "/mobile/safety-management-system/incident-management/incident-investigator-approval_history",
      label: "Investigator",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
    {
      to: "/mobile/safety-management-system/incident-management/incident-capa-owner-approval_history",
      label: "CAPA Owner",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
  ],
};

export const editStatusDropdown = {
  reported: ["investigation_in_progress", "capa_assigned", "incident_closed"],
  investigation_in_progress: ["investigation_completed"],
  investigation_completed: ["capa_assigned", "incident_closed"],
  capa_assigned: ["capa_closed"],
  capa_closed: ["incident_closed"],
};

export const startCloseDateTimeLabel = {
  reported: "Incident Report Date and Time",
  investigation_in_progress: "Investigation Start Date and Time",
  capa_assigned: "CAPA Start Date and Time",
  investigation_completed: "Investigation Close Date",
  capa_closed: "CAPA Close Date",
  incident_closed: "Incident Close Date",
};

export const startCloseDateTimeKey = {
  investigation_in_progress: "investigation_start_time",
  capa_assigned: "capa_start_time",
  investigation_completed: "investigation_close_time",
  capa_closed: "capa_close_time",
  incident_closed: "incident_close_time",
};

export const checkPreviousDateKey = {
  reported: "incident_reportdatetime",
  investigation_in_progress: "investigation_start_time",
  investigation_completed: "investigation_close_time",
  capa_assigned: "capa_start_time",
  capa_closed: "capa_close_time",
};

export const capaFormRules = [
  {
    field_name: "capaType",
    label: "CAPA Type",
    type: "String",
    isRequired: true,
  },
  {
    field_name: "remarks",
    label: "Description",
    type: "String",
    isRequired: true,
  },
  {
    field_name: "ownerId",
    label: "CAPA Owner",
    type: "String",
    isRequired: true,
  },
  {
    field_name: "priority",
    label: "Priority",
    type: "String",
    isRequired: true,
  },
  {
    field_name: "dueDate",
    label: "Due Date",
    type: "string",
    isRequired: true,
  },
];

export const checkStartCloseDateTimeByStatus = (status, changeStatus, form) => {
  const obj = {};
  if (status === "reported") {
    Object.keys(startCloseDateTimeKey)?.forEach((key) => {
      obj[startCloseDateTimeKey[key]] =
        changeStatus === key
          ? form[startCloseDateTimeKey[key]] ||
            moment().format("YYYY-MM-DD HH:mm")
          : null;
    });
  } else {
    editStatusDropdown?.[status]?.forEach((key) => {
      obj[startCloseDateTimeKey[key]] =
        changeStatus === key
          ? form[startCloseDateTimeKey[key]] ||
            moment().format("YYYY-MM-DD HH:mm")
          : null;
    });
  }
  return obj;
};
