import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { MultipleBarChartCard } from "../../../components/ChartCard/ChartCard";
import { isArray } from "lodash";
import Select from "../../../components/Select/Select";
import { DDMMYYYYHHMMAFormat } from "../../../_helpers/dateHelper";
import NoRecord from "../../../components/Common/NoRecord";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";

const PowerConsumptionReportDas = ({ data, form, onChange, pageType }) => {
  // console.log("daata " , data);
  return (
    <>
      <div className="dashboardWrapper">
        <Row id={`${"screen" + pageType}`}>
          {Object.keys(data)?.length ? (
            Object.keys(data)?.map((mines) => {
              return (
                isArray(data?.[mines]) &&
                data?.[mines]?.map((d, idx) => {
                  return (
                    <Col md={6} key={idx} id={`zoomSec_${idx}_${mines}`}>
                      <Card className="chartCard chartCardBtmSpace CAAQMS_chart">
                        <Card.Header className="d-flex justify-content-between">
                          <span>{d?.name}</span>
                          <div className="filterZoomSec">
                            {!d?.hideFilter && (
                              <Col className="mbRemove">
                                <Select
                                  // label={"Incident Type"}
                                  name={d?.value}
                                  value={form?.[d?.value]}
                                  onChange={onChange}
                                  options={d?.options}
                                  blankSelect={"none"}
                                />
                              </Col>
                            )}
                            <ZoomScreen id={`zoomSec_${idx}_${mines}`} />
                          </div>
                        </Card.Header>
                        <Card.Body className="pb-5">
                          {d?.type === "multiLine" && (
                            <MultipleBarChartCard
                              yAxisName={d?.yAxisName}
                              // xAxisName={item?.xAxisName}
                              category={d[d?.value]?.categoryData}
                              seriesData={
                                isArray(d[d?.value]?.data) &&
                                d[d?.value]?.data.map((o) => ({
                                  ...o,
                                  // type: item.type === "multiLine" ? d.type : item.type,
                                }))
                              }
                              customToolTip
                              minMaxData={d[d?.value]?.minMaxData}
                            />
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })
              );
            })
          ) : (
            <NoRecord />
          )}
        </Row>
      </div>
    </>
  );
};

export default PowerConsumptionReportDas;
