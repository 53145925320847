import { Html5Qrcode } from "html5-qrcode";
import React, { useEffect } from "react";
import { BiQrScan } from "react-icons/bi";

const QrScanner = (props) => {
  useEffect(() => {
    // File based scanning
    const fileinput = document.getElementById("qr-input-file");

  if (!fileinput) {
    // Element not found, return early
    return;
  }
    const html5QrCode = new Html5Qrcode("qr-input-file");
    fileinput.addEventListener("change", (e) => {
      // console.log('checkkkk++++++', e);
      if (e.target.files.length == 0) {
        // console.log('fixesss' , e);
        // No file selected, ignore
        return;
      }

      const imageFile = e.target.files[0];
      // Scan QR Code
      html5QrCode
        .scanFile(imageFile, true)
        .then((decodedText) => {
          const fileErr = "";
          props.qrCodeSuccessCallback(decodedText, fileErr);
          // console.log(decodedText);
        })
        .catch((err) => {
          const decodedText = "";
          const fileErr = "Error scanning file";
          props.qrCodeSuccessCallback(decodedText, fileErr);
          // failure, handle it.
          // console.log(`Error scanning file. Reason: ${err}`);
        });
    });
  }, []);

  return (
    <>
      <div className="uploadFileBtn">
        <label htmlFor={`qr-input-file`} className="uploadLabel">
          <BiQrScan size={20} />
        </label>
        <input
          id={`qr-input-file`}
          className="uploadFile"
          type="file"
          accept="image/*"
          data-testid="qr-input-file"
        />
      </div>
    </>
  );
};

export default QrScanner;
