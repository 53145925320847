import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import {
  mobileTabUrl,
  safetyAdminPermission,
  safetyManagementApis,
  safetyManagementColumnFields,
  safetyManagementFilterForm,
  tabUrl,
} from "./DATA";
import filter from "../../Assets/Images/filter.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../_helpers/hooks";
import {
  exportSafetyManagement,
  getSafetyManagementListWithPagination,
} from "../../services/_safetyManagement/safetyManagement";
import SafetyManagementView from "./SafetyManagementView";
import SafetyManagementFilterForm from "./SafetyManagementFilterForm";
import "./safety.scss";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { IS_MOBILE, findUserPermissionedTabList } from "../../_helpers/helper";
import SafetySideBar from "./SafetySideBar/SafetySideBar";
import { SidebarData } from "./IncidentManagement/DATA";

const SafetyManagementList = () => {
  let navigate = useNavigate();
  const [getAllFilter, setGetAllFilter] = useState();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(
      IS_MOBILE() ? mobileTabUrl.safetyManagement : tabUrl.safetyManagement
    )
  );

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} />;
  };

  useEffect(() => {
    getAll();
  }, []);
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(safetyManagementFilterForm);

  const onResetFilter = () => {
    setForm(safetyManagementFilterForm);
    setGetAllFilter();
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl?.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    navigate(customTabs[0].to);
    getAll();
  }, []);

  const viewAsPopup = (form) => {
    return <SafetyManagementView form={form} />;
  };

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }

    setGetAllFilter(filter);
  }, [filterStatus]);

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filter-button"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <SafetyManagementFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      </div>
    );
  };

  const getAll = async () => {
    const response = await getSafetyManagementListWithPagination(
      "page=1&limit=2"
    );
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={safetyManagementColumnFields}
                apis={safetyManagementApis}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/incident-management"}
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                getAllFilter={getAllFilter}
                hideAddButton
                downloadExcel={exportSafetyManagement}
                customTableTitle={"Safety Management"}
                viewTitle={"Incident Details"}
                childrenAfterAddButton={tabButtons}
                permissionEditButton={safetyAdminPermission}
                listname="incident"
                actionBtn
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
          // pageLink={"/incident-management"}
          // title={"Incident Management"}
          // tabButtons={tabButtons}
          // hideAddBtn
          />
        </div>
      </div>
    </div>
  );
};

export default SafetyManagementList;
