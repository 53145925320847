import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
const SapUpdateFilter = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
  contractorList,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>Collection Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              name="endDate"
              onChangeDate={onChange}
            />
          </Col>
        </Row>
        <Form.Label>Created At Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              value={form?.startDateCreatedAt}
              dateFormat="YYYY-MM-DD"
              name="startDateCreatedAt"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDateCreatedAt}
              dateFormat="YYYY-MM-DD"
              name="endDateCreatedAt"
              onChangeDate={onChange}
            />
          </Col>
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              type="button"
              data-testid="reset-filter-button"
              onClick={onResetFilter}
              className="secondaryBtn"
            >
              Clear all
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              data-testid="submit-filter-button"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default SapUpdateFilter;
