import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  incidentTabList,
  initialForm,
  fuelManagementRules,
  latiLongituteData,
  hmrReadingRuls,
  odometerReadingRuls,
  fuelMeterAfterRuls,
  workshopObjForm,
} from "./DATA";
import SafetySideBar from "../../../src/_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import CustomTabsComponent from "../../components/CustomTabs/CustomTabsComponent";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_MIXUP_VALUE_TEXT,
  MIXUP_DATA,
  MOB_MIXUP_DATA1,
  MOB_MIXUP_DATA2,
  SidebarData,
  fuelDispenseDetailObj,
  isNegativeFuelLevelTank,
  mobileFuelSidebarData,
} from "../../_pages/FuelManagementSystem/FuelManagement/DATA";
import AddGeneralInfo from "./FuelManagement/AddFuelDispenced/AddGeneralInfo";
import AddFuelStation from "./FuelManagement/AddFuelDispenced/AddFuelStation";
import AddHmmVehicle from "./FuelManagement/AddFuelDispenced/AddHmmVehicle";
import AddDispancedDetails from "./FuelManagement/AddFuelDispenced/AddDispancedDetails";
import AddFuelDispenced from "../FuelManagementSystem/FuelManagement/AddFuelDispenced/AddFuelDispenced";
import { useForm } from "../../_helpers/hooks";
import { Validation } from "../../_helpers/validation/Validation";
import { fuelDispenseApis } from "../FuelManagementSystem/DATA";
import { getAllSites } from "../../..../../_services/SiteServices/SiteServices";
import {
  createFuelDispence,
  getAllFuelLocation,
  getHemmDataById,
  updateFuelDispence,
  findDistance,
  getHemmAndMachineSerialList,
  getAllHemm,
  exportMaxFuelDispenseQuantity,
  getPreviousMeter,
} from "../../services/_fuelDispense/fuelDispense";
import { exportFuelLocationBySiteId } from "../../services/_fuelDispense/fuelDispense";
import toastr from "toastr";
import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";
import {
  FIND_GEOLOCATION,
  IS_MOBILE,
  IS_USER_GPS_ACTIVE,
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
  auth_user,
  digitCheckAfterDecimal,
  isDecimal,
  isDecimalCheck,
  isNotInteger,
  isObject,
} from "../../_helpers/helper";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { groupBy } from "lodash";
import { getAllWorkshopMasters } from "../../services/_reasonList/reasonList";
import { setFuelDispense } from "../../redux/actions/UserActions";
import localStorageService from "../../services/localStorageService";

const FuelManagementAdd = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const [form, setForm] = useState(initialForm);
  const [sites, setSites] = useState();
  const [userLoc, setUserLoc] = useState({});
  const [locationFlag, setLocationFlag] = useState(true);
  const [sitesOption, setSitesOption] = useState();
  const [subsidiaryOption, setSubsidiaryOption] = useState();
  const [subsidiarySites, setSubsidiarySites] = useState();
  const [preExistingHemmId, setPreExistingHemmId] = useState(false);
  const [fuelLocationOpt, setFuelLocationOpt] = useState([]);
  const [loaderValue, setLoaderValue] = useState(0);
  const [finalFuelLocationOpt, setFinalFuelLocationOpt] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [optionFlag, setOptionFlag] = useState(1);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [dispensedMode, setDispensedMode] = useState("fuelLocation");
  const [maxCapacity, setMaxCapacity] = useState(null);
  const [workshopData, setWorkshopData] = useState([...workshopObjForm]);
  const [negativeMssg, setNegativeMssg] = useState("");
  const [storeFuelDispense, setStoreFuelDispense] = useState("");
  const [storeFlag, setStoreFlag] = useState(false);

  const [updatedIncidentTabList, setUpdatedIncidentTabList] =
    useState(incidentTabList);
  const [error, setError] = useState({
    ...initialForm,
  });
  const [tabComplete, setTabComplete] = useState(null);

  useEffect(() => {
    const storeFuelDispenseData =
      localStorageService.getItem("storeFuelDispense");
    FIND_GEOLOCATION();
    setStoreFuelDispense(storeFuelDispenseData);
  }, []);

  const errorObjByTab = useCallback(
    (data) => {
      const gData = groupBy(data, "tab");
      const obj = {};
      for (let key in gData) {
        key = +key;
        const element = gData[key];
        obj[key] = Object.values(Validation(form, element)).filter(
          (d) => d !== ""
        ).length
          ? false
          : true;
      }

      return obj;
    },
    [form]
  );

  const checkTabValidation = useCallback(() => {
    let finalFuelManagementRules = fuelManagementRules;
    if (!form.hmr_reading_check_box) {
      let checkKey = finalFuelManagementRules?.find(
        (d) => d?.field_name === "user_entered_hmr_reading"
      );
      if (!checkKey) finalFuelManagementRules.push(hmrReadingRuls);
    } else {
      finalFuelManagementRules = finalFuelManagementRules?.filter(
        (d) => d?.field_name !== "user_entered_hmr_reading"
      );
    }

    if (!form.odometer_reading_check_box) {
      let checkKey = finalFuelManagementRules?.find(
        (d) => d?.field_name === "user_entered_odometer_reading"
      );
      if (!checkKey) finalFuelManagementRules.push(odometerReadingRuls);
    } else {
      finalFuelManagementRules = finalFuelManagementRules?.filter(
        (d) => d?.field_name !== "user_entered_odometer_reading"
      );
    }

    // if (form.fuel_meter !== "") {
    //   let checkKey = finalFuelManagementRules?.find(
    //     (d) => d?.field_name === "fuel_meter_after"
    //   );
    //   if (!checkKey) finalFuelManagementRules.push(fuelMeterAfterRuls);
    // } else {
    //   finalFuelManagementRules = finalFuelManagementRules?.filter(
    //     (d) => d?.field_name !== "fuel_meter_after"
    //   );
    // }

    const obj = errorObjByTab(finalFuelManagementRules);
    if (!obj.hasOwnProperty(3)) obj["3"] = true;
    setTabComplete(obj);
  }, [
    errorObjByTab,
    // form.fuel_meter,
    form.hmr_reading_check_box,
    form.odometer_reading_check_box,
  ]);

  useEffect(() => {
    checkTabValidation();
  }, [checkTabValidation]);

  useEffect(() => {
    if (!tabComplete) return;
    setUpdatedIncidentTabList((prev) => {
      return prev.map((d) => ({
        ...d,
        tabStatus: tabComplete[d.tab.toString()],
      }));
    });
  }, [tabComplete]);

  const onChange = (e, idx) => {
    let { name, value } = e.target;
    if (
      (name === "user_entered_hmr_reading" ||
        name === "user_entered_hmr_hours" ||
        name === "last_hmr_reading" ||
        name === "fuel_meter" ||
        name === "fuel_meter_after" ||
        name === "capacity" ||
        name === "user_entered_odometer_reading" ||
        name === "last_odometer_reading" ||
        name === "user_entered_odometer_kms" ||
        name === "fuel_dispensing_qty") &&
      !digitCheckAfterDecimal(value, 2)
    )
      return;
    let val = { ...form, [name]: value };
    setForm((prev) => {
      return { ...prev, ...val };
    });

    if (name === "workshop_id") {
      setForm((prev) => {
        return {
          ...prev,
          fuel_location_id: "",
        };
      });
    }

    if (name === "fuel_meter_after" || name === "fuel_meter") {
      setForm((prev) => {
        let fuelDispensingQtyValue = +val?.fuel_meter_after - +val?.fuel_meter;
        return {
          ...prev,
          fuel_dispensing_qty: isDecimal(fuelDispensingQtyValue),
        };
      });
    }
    if (name === "fuel_dispensing_qty") {
      let fuelMeterAfterValue = +val?.fuel_dispensing_qty + +val?.fuel_meter;
      setForm((prev) => {
        return {
          ...prev,
          fuel_meter_after: isDecimal(fuelMeterAfterValue),
        };
      });
    }
    if (
      name === "user_entered_hmr_hours" &&
      val?.user_entered_hmr_hours != ""
    ) {
      if (
        val?.last_hmr_reading != "" &&
        val?.last_hmr_reading != undefined &&
        val?.last_hmr_reading != null &&
        val?.last_hmr_reading != "N/A"
      ) {
        setForm((prev) => {
          return {
            ...prev,
            user_entered_hmr_reading: isDecimal(
              (+val?.last_hmr_reading ? +val?.last_hmr_reading : 0) +
                +val?.user_entered_hmr_hours
            ),
          };
        });
      } else if (
        val?.user_entered_hmr_reading != "" &&
        val?.user_entered_hmr_reading != undefined &&
        val?.user_entered_hmr_reading != null &&
        val?.last_hmr_reading != "N/A"
      ) {
        setForm((prev) => {
          return {
            ...prev,
            last_hmr_reading: isDecimal(
              (+val?.user_entered_hmr_reading
                ? +val?.user_entered_hmr_reading
                : 0) - +val?.user_entered_hmr_hours
            ),
          };
        });
      }
    }
    if (
      name === "user_entered_hmr_reading" &&
      val?.user_entered_hmr_reading != ""
    ) {
      if (
        val?.last_hmr_reading != "" &&
        val?.last_hmr_reading != undefined &&
        val?.last_hmr_reading != null &&
        val?.last_hmr_reading != "N/A"
      ) {
        setForm((prev) => {
          return {
            ...prev,
            user_entered_hmr_hours: isDecimal(
              +val?.user_entered_hmr_reading -
                (+val?.last_hmr_reading ? val?.last_hmr_reading : 0)
            ),
          };
        });
      } else if (
        val?.user_entered_hmr_hours != "" &&
        val?.user_entered_hmr_hours != undefined &&
        val?.user_entered_hmr_hours != null &&
        val?.last_hmr_reading != "N/A"
      ) {
        setForm((prev) => {
          return {
            ...prev,
            last_hmr_reading: isDecimal(
              +val?.user_entered_hmr_reading - +val?.user_entered_hmr_hours
                ? +val?.user_entered_hmr_hours
                : 0
            ),
          };
        });
      }
    }
    if (name === "last_hmr_reading" && val?.last_hmr_reading != "") {
      if (
        val?.user_entered_hmr_hours != "" &&
        val?.user_entered_hmr_hours != undefined &&
        val?.user_entered_hmr_hours != null
      ) {
        setForm((prev) => {
          return {
            ...prev,
            user_entered_hmr_reading: isDecimal(
              +val?.user_entered_hmr_hours + +val?.last_hmr_reading
            ),
          };
        });
      } else if (
        val?.user_entered_hmr_reading != "" &&
        val?.user_entered_hmr_reading != undefined &&
        val?.user_entered_hmr_reading != null
      ) {
        setForm((prev) => {
          return {
            ...prev,
            user_entered_hmr_hours: isDecimal(
              +val?.user_entered_hmr_reading - +val?.last_hmr_reading
            ),
          };
        });
      }
    }
    if (
      name === "user_entered_odometer_kms" &&
      val?.user_entered_odometer_kms != ""
    ) {
      if (
        val?.last_odometer_reading != "" &&
        val?.last_odometer_reading != undefined &&
        val?.last_odometer_reading != null &&
        val?.last_odometer_reading != "N/A"
      ) {
        setForm((prev) => {
          return {
            ...prev,
            user_entered_odometer_reading: isDecimal(
              (+val?.last_odometer_reading ? +val?.last_odometer_reading : 0) +
                +val?.user_entered_odometer_kms
            ),
          };
        });
      } else if (
        val?.user_entered_odometer_reading != "" &&
        val?.user_entered_odometer_reading != undefined &&
        val?.user_entered_odometer_reading != null &&
        val?.last_odometer_reading != "N/A"
      ) {
        setForm((prev) => {
          return {
            ...prev,
            last_odometer_reading: isDecimal(
              (+val?.user_entered_odometer_reading
                ? +val?.user_entered_odometer_reading
                : 0) - +val?.user_entered_odometer_kms
            ),
          };
        });
      }
    }
    if (
      name === "user_entered_odometer_reading" &&
      val?.user_entered_odometer_reading != ""
    ) {
      if (
        val?.last_odometer_reading != "" &&
        val?.last_odometer_reading != undefined &&
        val?.last_odometer_reading != null &&
        val?.last_odometer_reading != "N/A"
      ) {
        setForm((prev) => {
          return {
            ...prev,
            user_entered_odometer_kms: isDecimal(
              +val?.user_entered_odometer_reading -
                (+val?.last_odometer_reading ? +val?.last_odometer_reading : 0)
            ),
          };
        });
      } else if (
        val?.user_entered_odometer_kms != "" &&
        val?.user_entered_odometer_kms != undefined &&
        val?.user_entered_odometer_kms != null &&
        val?.last_odometer_reading != "N/A"
      ) {
        setForm((prev) => {
          return {
            ...prev,
            last_odometer_reading: isDecimal(
              +val?.user_entered_odometer_reading -
                (+val?.user_entered_odometer_kms
                  ? +val?.user_entered_odometer_kms
                  : 0)
            ),
          };
        });
      }
    }
    if (name === "last_odometer_reading" && val?.last_odometer_reading != "") {
      if (
        val?.user_entered_odometer_kms != "" &&
        val?.user_entered_odometer_kms != undefined &&
        val?.user_entered_odometer_kms != null
      ) {
        setForm((prev) => {
          return {
            ...prev,
            user_entered_odometer_reading: isDecimal(
              (+val?.last_odometer_reading ? +val?.last_odometer_reading : 0) +
                +val?.user_entered_odometer_kms
            ),
          };
        });
      } else if (
        val?.user_entered_odometer_reading != "" &&
        val?.user_entered_odometer_reading != undefined &&
        val?.user_entered_odometer_reading != null
      ) {
        setForm((prev) => {
          return {
            ...prev,
            user_entered_odometer_kms: isDecimal(
              (+val?.user_entered_odometer_reading
                ? +val?.user_entered_odometer_reading
                : 0) - +val?.last_odometer_reading
            ),
          };
        });
      }
    }
    if (name === "hmr_reading_check_box") {
      setForm((prev) => {
        return {
          ...prev,
          hmr_reading_check_box: +prev.hmr_reading_check_box === 1 ? 0 : 1,
          user_entered_hmr_reading: "",
        };
      });
    }
    if (name === "last_hmr_reading_check_box") {
      setForm((prev) => {
        return {
          ...prev,
          last_hmr_reading_check_box:
            +prev.last_hmr_reading_check_box === 1 ? 0 : 1,
          last_hmr_reading: "",
        };
      });
    }
    if (name === "hmr_hours_check_box") {
      setForm((prev) => {
        return {
          ...prev,
          hmr_hours_check_box: +prev.hmr_hours_check_box === 1 ? 0 : 1,
          user_entered_hmr_hours: "",
        };
      });
    }
    if (name === "last_odometer_reading_check_box") {
      setForm((prev) => {
        return {
          ...prev,
          last_odometer_reading_check_box:
            +prev.last_odometer_reading_check_box === 1 ? 0 : 1,
          last_odometer_reading: "",
        };
      });
    }
    if (name === "odometer_kms_check_box") {
      setForm((prev) => {
        return {
          ...prev,
          odometer_kms_check_box: +prev.odometer_kms_check_box === 1 ? 0 : 1,
          user_entered_odometer_kms: "",
        };
      });
    }
    if (name === "odometer_reading_check_box") {
      setForm((prev) => {
        return {
          ...prev,
          odometer_reading_check_box:
            +prev.odometer_reading_check_box === 1 ? 0 : 1,
          user_entered_odometer_reading: "",
        };
      });
    }
    if (name === "fuel_reading_check_box") {
      setForm((prev) => {
        return {
          ...prev,
          fuel_reading_check_box: +prev.fuel_reading_check_box === 1 ? 0 : 1,
          fuel_meter: "",
          fuel_meter_after: "",
        };
      });
    }
  };

  const handleSuggestionClick = (suggestion) => {
    // suggestion = suggestion?.split("/")[0]?.trim();
    setForm((prev) => {
      return { ...prev, hemm_id: suggestion };
    });
    setSelectedSuggestion(suggestion);
    setSuggestions([]); // Clear suggestions when a suggestion is clicked
    setOptionFlag(0);
  };

  const FIND_GEOLOCATION = () => {
    const successCallback = (position) => {
      setUserLoc({
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
      });
      setLocationFlag(true);
    };

    const errorCallback = (error) => {
      setLocationFlag(false);
    };

    // NOSONAR - Ignore all issues on this line
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback); // NOSONAR - Ignore all issues on this comment
  };
  const sendData = async (uesrLocationData) => {
    setLoaderValue(1);
    if (dispensedMode === "bowser") {
      form.dispensedByBowser = form.fuel_location_id;
      // form.fuel_location_id = "";
    } else {
      form.dispensedByBowser = null;
    }
    if (form.isBowser === 0 || form.isBowser == false) {
      form.dispensedInBowser = "0";
    }

    let response = params?.id
      ? await updateFuelDispence(
          {
            ...form,
            hemm_id: form?.hemm_id?.split("/")[0]?.trim(),
            dispensedMode: dispensedMode,
            person_latitude: uesrLocationData?.latitude || null,
            person_longitude: uesrLocationData?.longitude || null,
            is_person_gps_mandatory: IS_USER_GPS_ACTIVE() ? 1 : 0,
          },
          params.id
        )
      : await createFuelDispence({
          ...form,
          hemm_id: form?.hemm_id?.split("/")[0]?.trim(),
          dispensedMode: dispensedMode,
          person_latitude: uesrLocationData?.latitude || null,
          person_longitude: uesrLocationData?.longitude || null,
          is_person_gps_mandatory: IS_USER_GPS_ACTIVE() ? 1 : 0,
        });

    if (!response?.status) {
      setLoaderValue(0);
      return toastr.error(
        response?.message ? response?.message : "something went wrong"
      );
    }
    if (response?.status === 2) {
      setLoaderValue(0);
      return toastr.error(response?.message);
    }
    let userId = auth_user();
    if (response?.status) {
      let storeDispense = {
        dispensedMode: dispensedMode,
        fuel_location_id: form?.fuel_location_id,
        mine: form?.mine,
        subsidiary: form?.subsidiary,
        workshop_id: form?.workshop_id,
      };
      setFuelDispense(storeDispense);
      toastr.success(`Saved Successfully`);
    }
    navigate(
      `${
        IS_MOBILE() ? "/mobile" : ""
      }/fuel-management-system/fuel-management-list/${userId.id}`
    );
  };
  console.log("dsfddsafs", form);

  useEffect(() => {
    let isNegative = isNegativeFuelLevelTank(form, maxCapacity);

    if (
      (isNegative || maxCapacity < +form.fuel_dispensing_qty) &&
      dispensedMode !== "bowser"
    ) {
      setNegativeMssg(
        "Please check dispense quantity as it is more than current tank stock. You can still submit it and correct later within 2 days."
      );
    } else if (+form.capacity < +form.fuel_dispensing_qty && !form.isBowser) {
      setNegativeMssg(
        "Please check dispense quantity as it is more than current tank capacity of HEMM. You can still submit it and correct later within 2 days."
      );
    } else if (
      (isNegative || maxCapacity < +form.fuel_dispensing_qty) &&
      dispensedMode === "bowser"
    ) {
      setNegativeMssg(
        "Please check dispense quantity as it is more than current bowser stock. You can still submit it and correct later within 2 days."
      );
    } else if (+form.capacity < +form.fuel_dispensing_qty && form.isBowser) {
      setNegativeMssg(
        "Please check dispense quantity as it is more than current tank capacity of Bowser. You can still submit it and correct later within 2 days."
      );
      return;
    } else setNegativeMssg("");
  }, [dispensedMode, form, maxCapacity]);

  const onSubmit = async () => {
    const errorObj = Validation(form, fuelManagementRules);
    if (form?.fuel_meter_mandatory !== 1) {
      errorObj.fuel_meter = "";
      errorObj.fuel_meter_img_id = "";
      errorObj.fuel_meter_img_after_id = "";
      errorObj.fuel_meter_after = "";
    }
    if (form?.hmr_reading_check_box == 1) {
      errorObj.user_entered_hmr_reading = "";
    }
    if (form?.odometer_reading_check_box == 1) {
      errorObj.user_entered_odometer_reading = "";
    }
    if (form?.odometer_reading_check_box == 1) {
      errorObj.fuel_meter = "";
      errorObj.fuel_meter_after = "";
    }
    if (
      form?.gps_active === 1 &&
      form?.hemm_fms_verify !== 1 &&
      toastr.error("FMS Verification Needed")
    )
      return;

    if (form?.fuel_meter < 0)
      errorObj.fuel_meter = "Fuel Meter Reading can't negative";

    if (form?.fuel_meter_after < 0)
      errorObj.fuel_meter_after = "Fuel Meter Reading can't negative";

    if (form?.fuel_dispensing_qty < 0)
      errorObj.fuel_dispensing_qty = "Dispense Quantity can't negative";

    if (form?.last_hmr_reading < 0 || form?.user_entered_hmr_hours < 0) {
      toastr.error("HMR Reading can't negative");
      return;
    }

    if (
      form?.user_entered_odometer_reading < 0 ||
      form?.last_odometer_reading < 0 ||
      form?.user_entered_odometer_kms < 0
    ) {
      toastr.error("Odometer Reading can't negative");
      return;
    }
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      Object.values(errorObj).filter((d) => d !== "").length > 0 &&
        toastr.error("All fields are required");
      // Object.values(errorObj).filter((d) => d !== "").length === 1 &&
      //   toastr.error("Dispense Quantity can't negative");
      return;
    }

 
    if (form?.hmr_reading_check_box != 1 && form?.user_entered_hmr_hours < 0) {
      toastr.error("HMR Reading Should Be Greater Than Last HMR Reading");
      return;
    }

    if (
      form?.odometer_reading_check_box != 1 &&
      form?.user_entered_odometer_kms < 0
    ) {
      toastr.error(
        "Odometer Reading Should Be Greater Than Last Odometer Reading"
      );
      return;
    }

  

    if (form?.fuel_meter === "") form.fuel_meter = null;
    if (form?.fuel_meter_after === "") form.fuel_meter_after = null;
    if (form?.fuel_meter_img_id === "") form.fuel_meter_img_id = null;
    if (form?.fuel_meter_img_after_id === "")
      form.fuel_meter_img_after_id = null;

    const successCallback = async (position) => {
      const uesrLocationData = {
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
      };
      sendData(uesrLocationData);
      return position;
    };

    const errorCallback = async (error) => {
      // console.log("error", error);
      const uesrLocationData = {};
      if (IS_USER_GPS_ACTIVE()) {
        toastr.error("Please allow your location");
        return;
      }
      sendData(uesrLocationData);
    };
    // NOSONAR - Ignore all issues on this line
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback); // NOSONAR - Ignore all issues on this comment
  };

  const onDraft = async () => {
    form["status"] = "Draft";
    onSubmit();
  };

  async function fetchData() {
    let response = await fuelDispenseApis.getById.api(params?.id);
    if (response?.status) {
      setDispensedMode(
        response?.data?.dispensedByBowser ? "bowser" : "fuelLocation"
      );
      setForm(() => {
        return {
          afterFuelDispense: response?.data?.afterFuelDispense,
          // is_person_gps_mandatory: response?.data?.is_person_gps_mandatory,
          // person_latitude: response?.data?.person_latitude,
          // person_longitude: response?.data?.person_longitude,
          // hemm_fms_verify: response?.data?.hemm_fms_verify,
          hemm_id:
            response?.data?.fuel_dispense_hemm_datas?.hemm_id +
            " / " +
            response?.data?.fuel_dispense_hemm_datas?.machine_serial,
          fms_vehicle_type: response?.data?.fms_vehicle_type,
          fms_vehicle_name: response?.data?.fms_vehicle_name,
          // fms_hemm_latitude: response?.data?.fms_hemm_latitude,
          // fms_hemm_longitude: response?.data?.fms_hemm_longitude,
          // user_entered_hmr_reading: response?.data?.fms_hmr_reading,
          // user_entered_odometer_reading: response?.data?.fms_odometer_reading,
          // user_entered_hmr_reading: response?.data?.user_entered_hmr_reading,
          // user_entered_odometer_reading:
          //   response?.data?.user_entered_odometer_reading,
          fuel_location_id:
            response?.data?.dispensedByBowser ||
            response?.data?.fuel_location?.id,
          fuel_lat: response?.data?.fuel_location?.lat,
          fuel_long: response?.data?.fuel_location?.long,
          fuel_dispensing_qty: response?.data?.fuel_dispensing_qty,

          hemm_pre_image_data: response?.data?.hemm_pre_image_data,
          hemm_post_image_data: response?.data?.hemm_post_image_data,
          dispancer_post_image_data: response?.data?.dispancer_post_image_data,
          dispancer_pre_image_data: response?.data?.dispancer_pre_image_data,
          fuel_meter_img: response?.data?.fuel_meter_img_data,
          fuel_meter_img_after: response?.data?.fuel_meter_img_after_data,
          fuel_meter_after: response?.data?.fuel_meter_after,
          subsidiary: response?.data?.site?.parentSite?.id,
          mine: response?.data?.site?.id,
          fuel_meter: response?.data?.fuel_meter,
          user_entered_hmr_reading: response?.data?.user_entered_hmr_reading,
          user_entered_odometer_reading:
            response?.data?.user_entered_odometer_reading,
          user_entered_hmr_hours: response?.data?.user_entered_hmr_hours,
          user_entered_odometer_kms: response?.data?.user_entered_odometer_kms,

          hemm_pre_image_id: response?.data?.hemm_pre_image_data?.id,
          hemm_post_image_id: response?.data?.hemm_post_image_data?.id,
          last_hmr_reading: response?.data?.last_hmr_reading,
          last_odometer_reading: response?.data?.last_odometer_reading,
          dispancer_post_image_id:
            response?.data?.dispancer_post_image_data?.id,
          dispancer_pre_image_id: response?.data?.dispancer_pre_image_data?.id,
          status: "Submitted",
          statusFlag: response?.data?.status,
          createdAt: response?.data?.createdAt,
          isBowser: response?.data?.dispensedInBowser,
          hmr_hours_check_box: response?.data?.user_entered_hmr_hours ? 0 : 1,
          last_hmr_reading_check_box: response?.data?.last_hmr_reading ? 0 : 1,
          hmr_reading_check_box: response?.data?.user_entered_hmr_reading
            ? 0
            : 1,
          odometer_reading_check_box: response?.data
            ?.user_entered_odometer_reading
            ? 0
            : 1,
          last_odometer_reading_check_box: response?.data?.last_odometer_reading
            ? 0
            : 1,
          odometer_kms_check_box: response?.data?.user_entered_odometer_kms
            ? 0
            : 1,
          fuel_reading_check_box: response?.data?.fuel_meter ? 0 : 1,
          fuelOperator:
            response?.data?.fuelOperator?.firstName +
            " " +
            response?.data?.fuelOperator?.lastName,
          workshop_id: response?.data?.workshop_id,
          receiver: response?.data?.receiver,
          issuer: response?.data?.issuer,
        };
      });
    }
  }

  useEffect(() => {
    params.id && fetchData();
  }, [params?.id, setForm]);

  useEffect(() => {
    getAllSites("filter[siteType]=1,2,3&filter[activeStatus]=active").then(
      (response) => {
        if (!response?.data) return;
        setSites(response.data);
        const list = response.data
          ?.filter((d) => d.siteType === 3)
          .map((d) => {
            return { value: d.id, label: d.name };
          });
        setSitesOption(list);

        const parentList = response.data
          ?.filter((d) => d.siteType === 2)
          .map((d) => {
            return { value: d.id, label: d.name };
          });
        setSubsidiarySites(parentList);
      }
    );
    maxAllowedCapacity();
  }, []);

  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj = sites?.find((d) => +d?.id === +auth_user()?.siteId) || {};
    setSubsidiarySites([
      {
        value: siteObj?.parentSite?.id,
        label: siteObj?.parentSite?.name,
      },
    ]);
    setForm((prev) => ({
      ...prev,
      mine: siteObj?.id,
      subsidiary: siteObj?.parentSite?.id || "",
    }));
  }, [sites, locationFlag]);

  useEffect(() => {
    if (!IS_USER_SITE_RO()) return;
    const siteObj =
      subsidiarySites?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setForm((prev) => ({
      ...prev,
      subsidiary: siteObj?.value,
    }));
  }, [subsidiarySites]);

  useEffect(() => {
    const result = fuelLocationOpt?.find(
      (obj) => obj.id === form?.fuel_location_id
    );
    setForm((prev) => {
      return {
        ...prev,
        fuel_lat: result?.lat,
        fuel_long: result?.long,
      };
    });
  }, [form?.fuel_location_id]);

  async function fuelLocation(filter) {
    if (dispensedMode === "fuelLocation") {
      let response = await getAllFuelLocation(filter);
      if (response?.status === 0) return;
      response?.data
        ? setFinalFuelLocationOpt(
            response?.data?.filter(
              (d) => d?.status && d?.tank_id && d?.tank?.status
            )
          )
        : setFinalFuelLocationOpt([]);
    } else {
      let filter = "isBowser=1";
      let response = await getAllHemm(filter);
      if (response?.status === 0) return;
      response?.data
        ? setFinalFuelLocationOpt(response?.data?.filter((d) => d?.status))
        : setFinalFuelLocationOpt([]);
    }
  }

  async function maxAllowedCapacity() {
    if (!dispensedMode || !form.fuel_location_id) return;
    let id = form.fuel_location_id;
    // let response = await exportMaxFuelDispenseQuantity(
    //   id,
    //   dispensedMode,
    //   form.createdAt
    // );
    // if (dispensedMode === "fuelLocation" && response.data.maxCapacity)
    let response = await exportMaxFuelDispenseQuantity(
      id,
      dispensedMode,
      form.createdAt
    );
    if (
      dispensedMode === "fuelLocation" &&
      !isObject(response.data.maxCapacity)
    )
      setMaxCapacity(response.data.maxCapacity);
    else if (dispensedMode === "bowser" && !isObject(response.data.maxCapacity))
      setMaxCapacity(response.data.maxCapacity);
    else setMaxCapacity();

    if (!params.id) {
      let res = await getPreviousMeter(id, dispensedMode, form.createdAt);
      if (res?.data?.fuel_meter_after)
        setForm((prev) => {
          return {
            ...prev,
            fuel_meter: res?.data?.fuel_meter_after,
            fuel_dispensing_qty: isDecimal(
              (+prev?.fuel_meter_after || 0) - res?.data?.fuel_meter_after
            ),
          };
        });
      else
        setForm((prev) => {
          return {
            ...prev,
            fuel_meter: "",
            fuel_dispensing_qty: "",
          };
        });
    }
  }

  useEffect(() => {
    fuelLocation();
    maxAllowedCapacity();
    // previousMeter();
  }, [dispensedMode, params.id]);

  useEffect(() => {
    maxAllowedCapacity();
  }, [form.fuel_location_id, form.createdAt]);

  const onChangeMine = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value, fuel_location_id: "" }));
  };

  useEffect(() => {
    const locationList =
      finalFuelLocationOpt?.filter((d) => +d?.site_id === +form?.mine) || [];
    setFuelLocationOpt(locationList);
  }, [finalFuelLocationOpt, form?.mine]);

  const onChangeSubsidiary = ({ target: { name, value } }) => {
    // const selected = sites?.find((site) => site?.id === +value);
    // console.log("checkONnn", sites, value);
    // const list = sites
    //   ?.filter((d) => d.siteType === 3 && d.parentSiteId === value)
    //   .map((d) => {
    //     return { value: d.id, label: d.name };
    //   });
    // setSitesOption(list);

    setForm({ ...form, [name]: value, mine: "", fuel_location_id: "" });
  };

  useEffect(() => {
    const list = sites
      ?.filter((d) => d.siteType === 3 && d.parentSiteId === form?.subsidiary)
      .map((d) => {
        return { value: d.id, label: d.name };
      });
    setSitesOption(list);
  }, [form?.subsidiary, sites]);

  const onNewScanResult = (decodedText, fileError) => {
    onChange({
      target: {
        name: "hemm_id",
        value: decodedText ? decodedText : "",
      },
    });
    setError((prev) => ({ ...prev, hemm_id: fileError }));
  };
  const getHemmData = async (id) => {
    if (!id) return;
    let filter = `isBowser=${form.isBowser ? 1 : 0}`;
    const response = await getHemmDataById(id, filter);
    if (optionFlag) {
      const response2 = await getHemmAndMachineSerialList(id, filter);
      const hemmSerialArray = response2?.data?.map(
        (d) => d.hemm_id + " / " + (d.machine_serial ? d.machine_serial : "")
      );

      setSuggestions(hemmSerialArray);
    }
    if (response.status) {
      setPreExistingHemmId(true);
      setForm((prev) => {
        return {
          ...prev,
          model: response?.data?.model,
          capacity: response?.data?.capacity,
          loadCapacity: response?.data?.loadCapacity,
          fms_vehicle_type: response?.data?.fms_vehicle_type,
          make: response?.data?.make,
          fms_vehicle_name: response?.data?.fms_vehicle_name,
          // fuel_meter: response?.data?.fuel_meter,
          fms_hemm_latitude: response?.data?.fms_hemm_latitude,
          fms_hemm_longitude: response?.data?.fms_hemm_longitude,
          hemm_fms_verify: response?.data?.hemm_fms_verify,
          fuel_meter_mandatory: response?.data?.fuel_meter_mandatory,
          gps_active: response?.data?.gps_active,
          dispenseData: response?.data?.dispenseData,
          appliedFrom: response?.data?.appliedFrom,
          hmrReading: response?.data?.hmrReading,
          latitude_captured_date: response?.data?.latitude_captured_date
            ? moment(response?.data?.latitude_captured_date).format(
                "DD/MM/YYYY"
              )
            : null,
          createdAt: moment(),
        };
      });

      if (response?.data?.dispenseData) {
        let previousData =
          moment(form.createdAt) < moment(form.appliedFrom)
            ? response?.data?.dispenseData.user_entered_hmr_reading
            : form.hmrReading;
        setForm((prev) => {
          return {
            ...prev,
            last_hmr_reading: response?.data?.dispenseData
              ? response?.data?.dispenseData.status === "Draft"
                ? ""
                : form.appliedFrom
                ? previousData
                : response?.data?.dispenseData.user_entered_hmr_reading || "N/A"
              : "",
            last_hmr_reading_actual: response?.data?.dispenseData
              ? response?.data?.dispenseData.status === "Draft"
                ? ""
                : response?.data?.dispenseData.user_entered_hmr_reading || "N/A"
              : "",
            last_odometer_reading: response?.data?.dispenseData
              ? response?.data?.dispenseData.status === "Draft"
                ? ""
                : response?.data?.dispenseData.user_entered_odometer_reading ||
                  "N/A"
              : "",
          };
        });
      }
    } else {
      setPreExistingHemmId(false);
      setForm((prev) => {
        return {
          ...prev,
          model: "",
          capacity: "",
          loadCapacity: "",
          fms_vehicle_type: "",
          make: "",
          last_hmr_reading: null,
          last_odometer_reading: null,
          fms_vehicle_name: "",
          fms_hemm_latitude: "",
          fms_hemm_longitude: "",
          latitude_captured_date: null,
          hemm_fms_verify: 0,
          gps_active: 0,
          fuel_meter_mandatory: 0,
          user_entered_hmr_reading: null,
          user_entered_odometer_reading: null,
          // fuel_meter: null,
          dispenseData: "",
          user_entered_odometer_kms: null,
          user_entered_hmr_hours: null,
        };
      });
    }
  };
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    setOptionFlag(1);
    const debounceDelay = 1000;
    const timeoutId = setTimeout(() => {
      if (form?.hemm_id) getHemmData(form?.hemm_id?.split("/")[0]?.trim());
    }, debounceDelay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [form.hemm_id, form.isBowser]);

  useEffect(() => {
    if (!locationFlag || !finalFuelLocationOpt?.length) return;
    const filteredData = finalFuelLocationOpt.filter(
      (item) => item.workshop_id === null
    );
    const resultObj = latiLongituteData(filteredData, userLoc);
    if (params.id) return;
    setForm((prev) => ({
      ...prev,
      fuel_location_id: resultObj?.locationId,
      mine: resultObj?.mineId || prev?.mine,
      subsidiary: resultObj?.subsidiaryId || prev?.subsidiary,
    }));
  }, [finalFuelLocationOpt, userLoc, params.id]);

  useEffect(() => {
    if (
      params.id ||
      !storeFuelDispense ||
      !Object.keys(storeFuelDispense).length ||
      storeFlag
    )
      return;

    setForm((prev) => ({
      ...prev,
      ...storeFuelDispense,
    }));
    setDispensedMode(storeFuelDispense?.dispensedMode);
  }, [params.id, storeFuelDispense, finalFuelLocationOpt, storeFlag]);

  //   if (fuelLocationOpt.length > 0) {
  //   let minimumIs;
  //   let distanceDiff = 0;
  //   let minimumIndex;
  //   let nearestFuelStation = [];
  //   fuelLocationOpt.forEach((d) => {
  //     distanceDiff = findDistance({
  //       lat1: userLoc.latitude,
  //       lat2: d.lat,
  //       lon1: userLoc.longitude,
  //       lon2: d.long,
  //     });
  //     nearestFuelStation.push(distanceDiff);
  //   });
  //   const numericNumbers = nearestFuelStation.filter((e) => !isNaN(e));
  //   minimumIs = Math.min(...numericNumbers);
  //   minimumIndex = nearestFuelStation.indexOf(minimumIs);

  //   if (minimumIs < 10) {
  //     form.fuel_location_id = fuelLocationOpt[minimumIndex].id;
  //     form.mine = fuelLocationOpt[minimumIndex].site_id;
  //     form.subsidiary = fuelLocationOpt[minimumIndex].site?.parentSite?.id;
  //   }
  // };

  const changeRecipientType = () => {
    setForm({
      ...form,
      isBowser: !form.isBowser,
      model: "",
      capacity: "",
      fms_vehicle_type: "",
      make: "",
      fms_vehicle_name: "",
      fms_hemm_latitude: "",
      fms_hemm_longitude: "",
      hemm_fms_verify: "",
      fuel_meter_mandatory: "",
      gps_active: "",
      dispenseData: "",
      latitude_captured_date: "",
      odometer_reading_check_box: 1,
      last_odometer_reading_check_box: 1,
      odometer_kms_check_box: 1,
      user_entered_odometer_reading: "",
      last_odometer_reading: "",
      user_entered_odometer_kms: "",
      hmr_hours_check_box: !form.isBowser ? 1 : 0,
      last_hmr_reading_check_box: !form.isBowser ? 1 : 0,
      hmr_reading_check_box: !form.isBowser ? 1 : 0,
      user_entered_hmr_reading: !form.isBowser
        ? ""
        : form?.user_entered_hmr_reading,
      last_hmr_reading: !form.isBowser ? "" : form?.last_hmr_reading,
      user_entered_hmr_hours: !form.isBowser
        ? ""
        : form?.user_entered_hmr_hours,
    });
    setPreExistingHemmId(false);
  };
  const changeDispensedMode = () => {
    setStoreFlag(true);
    setDispensedMode(
      dispensedMode === "fuelLocation" ? "bowser" : "fuelLocation"
    );
  };

  useEffect(() => {
    getAllWorkshopMasters().then((response) => {
      if (!response?.data) return;
      setWorkshopData((prev) => [
        ...prev,
        ...response.data
          .filter((d) => d?.status)
          .map((d) => ({ label: d.name, value: d.id })),
      ]);
    });
  }, []);
  useEffect(() => {
    if (form.createdAt) {
      if (moment(form.createdAt) >= moment(form.appliedFrom))
        setForm((prev) => ({
          ...prev,
          last_hmr_reading: form.hmrReading,
        }));
      else
        setForm((prev) => ({
          ...prev,
          last_hmr_reading: form.last_hmr_reading_actual,
        }));
    } else {
      const todayDate = moment();
      if (todayDate >= moment(form.appliedFrom)) {
        setForm((prev) => ({
          ...prev,
          last_hmr_reading: form.hmrReading,
        }));
      }
    }
  }, [
    form.createdAt,
    form.appliedFrom,
    form.hmrReading,
    form.last_hmr_reading_actual,
  ]);

  return (
    <div
      className={`${
        IS_MOBILE() ? "mobileSidebarWrapper" : "wrapper sidebarWrapper"
      }`}
    >
      <SafetySideBar
        SidebarData={IS_MOBILE() ? mobileFuelSidebarData : SidebarData}
        pageTitle={params?.id ? "Edit Fuel dispenses" : "Add Fuel dispenses"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            {!IS_MOBILE() && (
              <h3
                className=""
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Fuel Management System",
                      path: "/",
                    },
                    {
                      name: "Fuel Management",
                      path: "/fuel-management-system",
                    },
                    {
                      name: "Add Fuel dispenses",
                      path: "#",
                    },
                  ]}
                  // module={ACTIVE_MODULE.incident}
                />
              </h3>
            )}

            <CustomTabsComponent
              tabeList={updatedIncidentTabList}
              tab={tab}
              setTab={setTab}
            />

            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="formWrapper">
                  <div className="maverickFormCard">
                    {/* {tab === 1 && (
                      <AddGeneralInfo
                        form={form}
                        onChange={onChange}
                        error={error}
                        tab={tab}
                        setTab={setTab}
                      />
                    )} */}
                    <FormDetails
                      form={form}
                      sites={sitesOption}
                      subsidery={subsidiarySites}
                      workshopData={workshopData}
                      fuelLocationOpt={fuelLocationOpt}
                      dispensedMode={dispensedMode}
                      maxCapacity={maxCapacity}
                      dispensedInBowser={form?.isBowser}
                    />
                    {tab === 1 && (
                      <AddFuelStation
                        form={form}
                        onChange={onChange}
                        onChangeMine={onChangeMine}
                        onChangeSubsidiary={onChangeSubsidiary}
                        error={error}
                        sites={sitesOption}
                        subsidiarySite={subsidiarySites}
                        tab={tab}
                        setTab={setTab}
                        fuelLocationOpt={fuelLocationOpt}
                        locationFlag={locationFlag}
                        changeDispensedMode={changeDispensedMode}
                        dispensedMode={dispensedMode}
                        maxCapacity={maxCapacity}
                        isEdit={params.id}
                        workshopData={workshopData}
                      />
                    )}
                    {tab === 2 && (
                      <AddHmmVehicle
                        form={form}
                        onChange={onChange}
                        error={error}
                        tab={tab}
                        setTab={setTab}
                        handleSuggestionClick={handleSuggestionClick}
                        selectedSuggestion={selectedSuggestion}
                        suggestions={suggestions}
                        onNewScanResult={onNewScanResult}
                        disableHemmDetailInput={
                          preExistingHemmId ? true : false
                        }
                        changeRecipientType={changeRecipientType}
                      />
                    )}
                    {tab === 3 && (
                      <AddFuelDispenced
                        id={params?.id}
                        maxCapacity={maxCapacity}
                        form={form}
                        onChange={onChange}
                        error={error}
                        condition={"before_"}
                        tab={tab}
                        setTab={setTab}
                      />
                    )}
                    {tab === 4 && (
                      <>
                        <AddFuelDispenced
                          id={params?.id}
                          form={form}
                          onChange={onChange}
                          error={error}
                          condition={"after_"}
                          tab={tab}
                          setTab={setTab}
                          onSubmit={onSubmit}
                          onDraft={onDraft}
                          loaderValue={loaderValue}
                          negativeMssg={negativeMssg}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuelManagementAdd;

export const FormDetails = ({
  form,
  subsidery,
  sites,
  workshopData,
  fuelLocationOpt,
  dispensedMode,
  maxCapacity,
  dispensedInBowser,
}) => {
  let tankBowserLabel =
    dispensedMode === "fuelLocation"
      ? "Tank Current Stock (L)"
      : "Bowser Current Stock (L)";
  let useWorkshop =
    form?.subsidiary === 3 && dispensedMode === "fuelLocation" ? true : false;
  let filteredOpt =
    dispensedMode === "fuelLocation"
      ? fuelLocationOpt.filter(
          (item) => item?.workshop_id === form?.workshop_id
        )
      : [];
  let fuelLevelTank = form?.fuel_location_id && maxCapacity ? maxCapacity : 0;
  let formObj = {
    workshop_id: form?.workshop_id === null ? "Not Available" : "",
    fuelLevelTank: fuelLevelTank,
    tankName: "",
  };

  Object.keys(fuelDispenseDetailObj)?.forEach((key, i) => {
    if (form[key]) {
      if (key === "subsidiary") {
        formObj[key] = subsidery?.find((d) => +d?.value === +form[key])?.label;
      }
      if (key === "mine") {
        formObj[key] = sites?.find((d) => +d?.value === +form[key])?.label;
      }
      if (key === "workshop_id") {
        formObj[key] = workshopData?.find(
          (d) => +d?.value === +form[key]
        )?.label;
      }
      if (key === "fuel_location_id") {
        let fuelLocationObj = (useWorkshop ? filteredOpt : fuelLocationOpt)
          ?.map((d) => ({
            ...d,
            locationLabel: d?.fuel_location_name || d?.hemm_id,
            tankName: d?.tank?.name || d?.hemm_id,
          }))
          ?.find((d) => +d?.id === +form[key]);
        formObj[key] = fuelLocationObj?.locationLabel;
        formObj["tankName"] = fuelLocationObj?.tankName;
      }
      if (key === "capacity") {
        formObj[key] = +form?.capacity;
      }
    }
  });

  let mixValue = GET_MIXUP_VALUE_TEXT(MIXUP_DATA, useWorkshop, formObj);

  let mixValueMob1 = GET_MIXUP_VALUE_TEXT(
    MOB_MIXUP_DATA1,
    useWorkshop,
    formObj
  );
  let mixValueMob2 = GET_MIXUP_VALUE_TEXT(
    MOB_MIXUP_DATA2,
    useWorkshop,
    formObj
  );

  return IS_MOBILE() ? (
    <div className={`selectedSec formDtls mobView`}>
      <Col xs={12} lg={6}>
        <p className="mb-0">{mixValueMob1}</p>
        <p>{mixValueMob2}</p>
      </Col>

      {formObj?.tankName ? (
        <>
          <Col xs={12} lg={4} className="selectedData">
            <label>{`${formObj?.tankName} ${tankBowserLabel}`}:</label>
          </Col>
          <Col xs={12}>
            <p className="mb-0">{formObj?.fuelLevelTank}</p>
          </Col>
        </>
      ) : (
        ""
      )}

      {formObj?.capacity || formObj?.capacity === 0 ? (
        <Col xs={12} lg={2} className="selectedData">
          <label>
            {dispensedInBowser
              ? fuelDispenseDetailObj?.capacityBowser
              : fuelDispenseDetailObj?.capacity}
            :
          </label>
          <p>{formObj?.capacity}</p>
        </Col>
      ) : (
        ""
      )}
    </div>
  ) : (
    <div className={`selectedSec formDtls`}>
      <Col xs={12} lg={6} className="selectedData">
        <p>{mixValue}</p>
      </Col>

      {formObj?.tankName ? (
        <Col xs={12} lg={4} className="selectedData">
          <label>{`${formObj?.tankName} ${tankBowserLabel}`}:</label>
          <p className="mb-3">{formObj?.fuelLevelTank}</p>
        </Col>
      ) : (
        ""
      )}

      {formObj?.capacity || formObj?.capacity === 0 ? (
        <Col xs={12} lg={2} className="selectedData">
          <label>
            {dispensedInBowser
              ? fuelDispenseDetailObj?.capacityBowser
              : fuelDispenseDetailObj?.capacity}
            :
          </label>{" "}
          <p className={"mb-3"}>{formObj?.capacity}</p>
        </Col>
      ) : (
        ""
      )}
    </div>
  );
};
