import selectionIcon1 from "../../../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../../../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../../../Assets/Images/selectionIcon3.svg";
import selectionIcon4 from "../../../Assets/Images/selectionIcon4.svg";
import selectionIcon5 from "../../../Assets/Images/selectionIcon5.svg";

export const activityFlowList = [
  {
    img: selectionIcon1,
    label: "Activity",
    link: "activity",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon2,
    label: "Place of Operation",
    link: "area-of-operation",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon3,
    label: "KPI Data",
    link: "incident-kpi",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon1,
    label: "Incident/Accident Causes",
    link: "violations-leading",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon2,
    label: "Upload Document",
    link: "upload-files",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon3,
    label: "Designation",
    link: "designation",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon1,
    label: "Mine Details",
    link: "mine-details",
    permission: ["super_admin", "safety_admin"],
  },
];
