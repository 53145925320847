import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomRadio from "../../../../../components/_form/CustomRadio/CustomRadio";
import IncidentEvidences from "../IncidentEvidences";
import ReportButton from "../ReportButton/ReportButton";

const IncidentEvidenceTab = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  evidences,
  evidencesErrors,
  onChangeEvidence,
  onChangeEvidenceFile,
  addEvidence,
  deleteEvidence,
  tab,
  asterikRules,
  isEdit,
}) => {
  return (
    <>
      <div className="FormWrapper">
        <Form>
          <Row>
            <Col lg={4}>
              <CustomRadio
                type="checkbox"
                name="reportEvidencesRequired"
                onChange={onChange}
                title={"Evidence Available"}
                acceptTitle={"Yes"}
                rejectTitle={"No"}
                customClass={"customRadioClass"}
                value={
                  form.reportEvidencesRequired === null
                    ? false
                    : form.reportEvidencesRequired
                }
              />
              <span className="small text-danger">
                {error?.isAssetIncident}
              </span>
            </Col>
          </Row>
          {form.reportEvidencesRequired && (
            <Col md="12">
              <div className="formWrapper rootCause">
                <IncidentEvidences
                  dataArray={evidences}
                  errorArray={evidencesErrors}
                  onChange={onChangeEvidence}
                  onChangeFile={onChangeEvidenceFile}
                  onAdd={addEvidence}
                  onDelete={deleteEvidence}
                  asterikRules={asterikRules}
                />
              </div>
            </Col>
          )}
        </Form>
      </div>
      <ReportButton
        setTab={setTab}
        tab={tab}
        onSubmit={onSubmit}
        id={isEdit && form.status === "reported"}
      />
    </>
  );
};

export default IncidentEvidenceTab;
