import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { IS_USER_SITE_MINES, auth_user } from "../../../_helpers/helper";
import {
  getAllBowsers,
  getAllMineLists,
  getAllTanks,
  fuelWorkshop,
  getAllWorkshops,
} from "../../../services/_fuelDispense/fuelDispense";
import Select from "../../../components/Select/Select";

const FuelAdditionalFilter = ({
  form,
  onChange,
  setForm,
  hideBowser,
  hideWorkshop,
  hideTank,
  bowserText,
}) => {
  const [sitesOption, setSitesOption] = useState([]);
  const [workshopOption, setWorkshopOption] = useState([
    { label: "Not Available", value: "null" },
  ]);
  const [bowserOption, setBowserOption] = useState([]);
  const [tankOption, setTankOption] = useState([]);
  const [workshopByMine, setWorkshopByMine] = useState([]);
  const [allowedTanks, setAllowedTanks] = useState([]);

  const getAllSitesList = async () => {
    let list = await getAllMineLists();
    setSitesOption(list);
  };

  const getAllWorkshop = async () => {
    let list = await getAllWorkshops();
    // let opt = list.filter((d) => d.status);
    setWorkshopOption((prev) => {
      return prev.concat(list);
    });
  };

  const getAllBowser = async () => {
    let list = await getAllBowsers();
    setBowserOption(list);
  };

  const getAllTank = async () => {
    let list = await getAllTanks();
    setTankOption(list);
  };

  const getFueLWorkshop = async () => {
    let list = await fuelWorkshop();
    setWorkshopByMine(list);
  };

  useEffect(() => {
    getAllSitesList();
    getAllWorkshop();
    getAllBowser();
    getAllTank();
    getFueLWorkshop();
  }, []);

  useEffect(() => {
    if (!IS_USER_SITE_MINES() || !setForm) return;
    const siteObj =
      sitesOption?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setForm((prev) => ({
      ...prev,
      mine: siteObj?.value,
    }));
  }, [sitesOption]);

  useEffect(() => {
    let allowedTank = workshopByMine[form?.workshopType];
    setAllowedTanks(allowedTank);
  }, [form?.workshopType]);


  return (
    <>
      <Col md={6}>
        <Select
          onChange={onChange}
          value={form?.mine}
          name="mine"
          options={sitesOption}
          label={"Mine"}
          disabled={IS_USER_SITE_MINES()}
        />
      </Col>

      {!hideWorkshop ? (
        <Col md={6}>
          <Select
            onChange={onChange}
            value={form?.workshopType}
            name="workshopType"
            options={workshopOption}
            label={"Workshop Type"}
          />
        </Col>
      ) : (
        ""
      )}
      {!hideTank ? (
        <Col md={6}>
          <Select
            onChange={onChange}
            value={form?.tank}
            name="tank"
            options={
              allowedTanks && form?.mine
                ? tankOption.filter(
                    (d) =>
                      d.site_id === form?.mine && allowedTanks.includes(d.value)
                  )
                : allowedTanks
                ? tankOption.filter((d) => allowedTanks.includes(d.value))
                : form?.mine
                ? tankOption.filter((d) => d.site_id === form?.mine)
                : tankOption
            }
            label={"Tank"}
          />
        </Col>
      ) : (
        ""
      )}

      {!hideBowser ? (
        <Col md={bowserText ? 12 : 6}>
          <Select
            onChange={onChange}
            value={form?.bowser}
            name="bowser"
            options={
              form?.mine
                ? bowserOption.filter((d) => d.site_id === form?.mine)
                : bowserOption
            }
            label={bowserText ? bowserText : "Bowser"}
          />
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

export default FuelAdditionalFilter;
