import React, { useState, useEffect, useMemo } from "react";
import toastr from "toastr";
import { airQualityFormLabel, areaQualityValidation } from "./DATA";
import { customSearchFilter } from "../../../../_helpers/helper";
import { Button, Col } from "react-bootstrap";
import { Input } from "reactstrap";
import InputError from "../../../../components/_form/InputError/InputError";
import { BulkUploadService } from "./BulkUploadService";
import { useLocation, useNavigate } from "react-router-dom";
import { formLabel, reportTypeListObj, reportTypeListUrl } from "../DATA";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import { uploadFileOnS3 } from "../../../../services/S3Service";

function BulkUploadAirQuality({
  siteList,
  bulkDataList,
  back,
  uploadFile,
  serverErrorData,
  s3UrlData,
}) {
  let errorInitialValue = [];
  errorInitialValue = uploadFile?.fileData?.map((d) => {
    return {};
  });
  const navigate = useNavigate();
  const [form, setForm] = useState([]);
  const [filterForm, setFilterForm] = useState([]);
  let [serverErrorDatas, setServerErrorDatas] = useState(serverErrorData);
  const [error, setError] = useState(errorInitialValue);
  const [errorShow, setErrorShow] = useState(0);
  const [errorMessage, setErrorMessage] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [flag, setFlag] = useState(1);
  // const onChangeSearch = ({ target: { name, value } }) => {
  //   setSearchText(value);
  // };

  console.log("ttttt", useLocation().search);
  const urlFileType = useLocation().search.replace("?type=", "");

  const waterLevelObj = {
    Q1: "winter",
    Q2: "pre_monsoon",
    Q3: "monsoon",
    Q4: "post_monsoon",
  };

  let uniqueArray = [];
  serverErrorDatas &&
    serverErrorDatas[0]?.uniqueArr?.map((data) => {
      uniqueArray.push(formLabel[urlFileType][data]);
    });
  // if (d.index === i + 2) {
  //   return <span className="fromerror">{"yes"}</span>;
  // }

  const textOrNumber = (d) => {
    if (
      d === "station_name" ||
      d === "project" ||
      d === "subsidiary" ||
      d === "well_type" ||
      d === "code" ||
      d === "station_category" ||
      d === "station_code" ||
      d === "og" ||
      d === "pre_monsoon" ||
      d === "monsoon" ||
      d === "post_monsoon" ||
      d === "winter" ||
      d === "equipment" ||
      d === "type_of_equipment" ||
      d === "remarks" ||
      d === "spm" ||
      d === "so2" ||
      d === "feeder" ||
      d === "quarter"
    ) {
      return "text";
    } else {
      return "number";
    }
  };

  useEffect(() => {
    if (!searchText) {
      setForm(filterForm);
      return;
    }
  }, [filterForm, searchText]);

  const setFormData = useMemo(() => {
    const errorArray = [];
    console.log("bulkDataList++++", bulkDataList);
    return bulkDataList?.map((d) => {
      let extraToChange = {};

      if (
        siteList
          ?.filter((d) => d.siteType === 3)
          ?.map((d) => d?.id)
          .includes(+d.currentWorkLocation)
      ) {
        extraToChange.mineTag = "yes";
      } else {
        extraToChange.mineTag = "no";
      }
      errorArray.push({
        currentWorkLocation: d?.isExistMesssage ? d?.isExistMesssage : "",
      });
      setErrorMessage(errorArray);

      return {
        ...d,
        ...extraToChange,
        siteType:
          siteList?.find((site) => +site?.id === +d?.currentWorkLocation)
            ?.siteType || "",
      };
    });
  }, [bulkDataList, siteList]);

  useEffect(() => {
    setForm(setFormData);
    setFilterForm(setFormData);
  }, [setFormData]);

  /********************* Form Function ***************************/
  const onChangeInput = ({ target: { name, value } }, index, isLabel) => {
    if (
      urlFileType === "Ambient-Air-Quality-CAAQMS" ||
      urlFileType === "Ambient-Air-Quality-CMPDIL" ||
      urlFileType === "Micro-Meteorological-CAAQMS" ||
      urlFileType === "Performance-Report-Of-Pollution" ||
      urlFileType === "Water-Quality-CMPDIL" ||
      urlFileType === "Noise-Level-CMPDIL" ||
      urlFileType === "Land-Reclamation-Status-CMPD" ||
      urlFileType === "Plantation-Project" ||
      urlFileType === "Power-Consumption-Report" ||
      urlFileType === "Ground-Water-level-CMPDIL"
    ) {
      // console.log("666666666666666666",name);
      // console.log("66666666666666666655555555555555555",value);
      if (value === "") {
        error[index][name] = isLabel + " is Required";
      } else {
        error[index][name] = null;
        // setFileError({ ...fileError, [name]: null });
        delete error[index][name];
      }
    } else if (urlFileType === "air-quality-data-emr") {
      if (
        name === "station_name" ||
        name === "station_category" ||
        name === "sampling_date" ||
        name === "analysis_date"
      ) {
        if (value === "") {
          error[index][name] = isLabel + " is Required";
        } else {
          error[index][name] = null;
          // setFileError({ ...fileError, [name]: null });
          delete error[index][name];
        }
      }
    } else if (urlFileType === "ground-water-level") {
      if (name === "well_no") {
        if (value === "") {
          error[index][name] = isLabel + " is Required";
        } else {
          error[index][name] = null;
          delete error[index][name];
        }
      }
    } else if (urlFileType === "overall-weather-data") {
      if (name === "weather_datetime") {
        if (value === "") {
          error[index][name] = isLabel + " is Required";
        } else {
          error[index][name] = null;
          delete error[index][name];
        }
      }
    } else if (urlFileType === "plantation-data") {
      if (name === "plantation_date") {
        if (value === "") {
          error[index][name] = isLabel + " is Required";
        } else {
          error[index][name] = null;
          delete error[index][name];
        }
      }
    }

    // if (urlFileType === "Ambient-Air-Quality-CMPDIL") {
    //   if (
    //     (name === "spm" && isNaN(value)) ||
    //     (name === "so2" && isNaN(value))
    //   ) {
    //     value = 0;
    //   }
    // }

    setForm((prev) =>
      prev.map((f, i) => (i !== index ? f : { ...f, [name]: value }))
    );
    setFilterForm((prev) =>
      prev.map((f, i) => (i !== index ? f : { ...f, [name]: value }))
    );
  };

  const onSubmit = async (e) => {
    let errorFlag = 0;
    const fields = Object.keys(airQualityFormLabel[uploadFile?.fileType]);
    console.log("field111111111", fields);
    const finalFormData = form.map((d, i) => {
      // let fields = Object.keys(d);
      fields.forEach((data) => {
        if (d[data] === "" || d[data] === undefined) {
          error[i][data] =
            airQualityFormLabel[uploadFile?.fileType][data] + " is Required";
        } else {
          if (
            uploadFile?.fileType === "Ambient_Air_Quality_CMPDIL" &&
            data === "spm"
          ) {
            if (isNaN(d[data])) {
              if (d[data] !== "BDL" && d[data] !== "NA") {
                error[i][data] =
                  airQualityFormLabel[uploadFile?.fileType][data] +
                  " should be a number / BDL / NA";
              } else {
                d[data] = d[data] === "NA" ? null : -1;
              }
            }
          }
          if (
            uploadFile?.fileType === "Water_Quality_CMPDIL" &&
            data === "og"
          ) {
            if (isNaN(d[data])) {
              if (d[data] !== "BDL" && d[data] !== "NA") {
                error[i][data] =
                  airQualityFormLabel[uploadFile?.fileType][data] +
                  " should be a number / BDL / NA";
                // setErrorShow((prev) => prev + 1);
              } else {
                d[data] = d[data] === "NA" ? null : -1;
              }
            }
          }
          if (
            uploadFile?.fileType === "Ground_Water_level_CMPDIL" &&
            data === "water_level"
            // data === "monsoon" ||
            // data === "post_monsoon" ||
            // data === "winter"
          ) {
            if (isNaN(d[data])) {
              if (d[data] !== "BDL" && d[data] !== "NA") {
                error[i][data] =
                  airQualityFormLabel[uploadFile?.fileType][data] +
                  " should be a number / BDL / NA";
                // setErrorShow((prev) => prev + 1);
              } else {
                d[data] = d[data] === "NA" ? null : -1;
              }
            }
          }

          if (
            (uploadFile?.fileType === "Ambient_Air_Quality_CMPDIL" ||
              uploadFile?.fileType === "Ambient_Air_Quality_CAAQMS") &&
            data === "so2"
          ) {
            if (isNaN(d[data])) {
              if (d[data] !== "BDL" && d[data] !== "NA") {
                error[i][data] =
                  airQualityFormLabel[uploadFile?.fileType][data] +
                  " should be a number / BDL / NA";
                // setErrorShow((prev) => prev + 1);
              } else {
                d[data] = d[data] === "NA" ? null : -1;
              }
            }
          }
        }
      });
      return d;
    });
    error.map((d) => {
      if (Object.keys(d).length > 0) {
        setErrorShow((prev) => prev + 1);
        errorFlag = 1;
        return;
      }
    });

    if (errorFlag === 1) {
      toastr.error(`Please Enter valid values`);
      return;
    }
    e?.preventDefault();
    setSearchText("");

    const fileDataIs = form.map((d) => {
      if (uploadFile?.fileType === "Ambient_Air_Quality_CAAQMS") {
        d.air_quality_date = d?.air_quality_date?.split("T")[0];
      }
      if (uploadFile?.fileType === "Ambient_Air_Quality_CMPDIL") {
        d.air_quality_date = d?.air_quality_date?.split("T")[0];
      }
      return d;
    });

    // const s3UrlData = await uploadFileOnS3(uploadFile);
    console.log("s3UrlDatas3UrlDatas3UrlDatas3UrlData", s3UrlData);
    let response = await BulkUploadService.addAreaQualityExcel({
      fileData: fileDataIs,
      filePath: s3UrlData?.s3Url,
      fileName: uploadFile?.fileName,
      fileType: uploadFile?.fileType,
      filename: uploadFile?.filename,
      header: uploadFile?.header,
      mine: uploadFile?.mine,
      originalname: uploadFile?.originalname,
      subsidiary: uploadFile?.subsidiary,
      uploadPath: uploadFile?.uploadPath,
    });

    if (!response?.status) {
      setServerErrorDatas(response?.data?.invalidData);

      return toastr.error(response?.message);
    }

    toastr.success(response.message || "Success.");
    navigate(
      "/environment-management/manage/uploaded-files?type=" +
        reportTypeListUrl[uploadFile?.fileType]
    );
  };

  const tableHeader = uploadFile?.header?.map((d) => {
    return d.replace(".", "");
  });

  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr className="tableTh">
              <th>S.No.</th>
              {tableHeader
                ?.filter(
                  (heading) =>
                    heading !== "monsoon" &&
                    heading !== "pre_monsoon" &&
                    heading !== "post_monsoon" &&
                    heading !== "winter"
                )
                ?.map((d) => (
                  <>
                    {/* <th>{d}</th> */}
                    <th>{airQualityFormLabel[uploadFile?.fileType]?.[d]}</th>
                  </>
                ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(form) &&
              form.map((x, i) => {
                if (flag) {
                  x["water_level"] =
                    x[waterLevelObj[x?.quarter?.split("-")[0]]];
                  setFlag(0);
                }
                const minesFlag =
                  !x.currentWorkLocation ||
                  siteList
                    .filter((d) => d.siteType !== 3)
                    .map((d) => d.id)
                    .includes(+x.currentWorkLocation);
                return (
                  <>
                    <tr
                      key={x.employeeId}
                      style={x.isExist ? { "background-color": "#f9b9b9" } : {}}
                    >
                      <th>{i + 1}</th>

                      {uploadFile?.header
                        ?.filter(
                          (heading) =>
                            heading !== "monsoon" &&
                            heading !== "pre_monsoon" &&
                            heading !== "post_monsoon" &&
                            heading !== "winter"
                        )
                        ?.map((d) => (
                          <td>
                            {d === "date" ||
                            d === "plantation_date" ||
                            d === "air_quality_date" ||
                            d === "sample_date" ||
                            d === "date_of_sampling" ? (
                              <CustomDatePicker
                                value={x[d]}
                                dateFormat="YYYY-MM-DD"
                                name={d}
                                onChange={(e) =>
                                  onChangeInput(e, i, formLabel[urlFileType][d])
                                }
                                error={error[i]?.[d]}
                              />
                            ) : (
                              <>
                                <Input
                                  disabled={
                                    d === "subsidiary" ||
                                    d === "project" ||
                                    d === "station_name" ||
                                    d === "station_category" ||
                                    d === "total_lease_hold_area" ||
                                    d === "well_type" ||
                                    d === "code" ||
                                    d === "category_of_station" ||
                                    d === "equipment" ||
                                    d === "type_of_equipment" ||
                                    d === "feeder" ||
                                    d === "station_code"
                                  }
                                  name={d}
                                  type={textOrNumber(d)}
                                  value={x[d]}
                                  onChange={(e) =>
                                    onChangeInput(
                                      e,
                                      i,
                                      formLabel[urlFileType][d]
                                    )
                                  }
                                  className="form-control"
                                  style={{ width: d === "year" ? "71px" : "" }}
                                />

                                {Array.isArray(error) && error[i] && (
                                  <span className="fromerror">
                                    {error[i]?.[d]}
                                  </span>
                                )}
                              </>
                            )}
                          </td>
                        ))}
                    </tr>
                    <tr>
                      <td colspan="17">
                        {serverErrorDatas?.map((d) => {
                          if (d.index === i + 2) {
                            return (
                              <span className="trError">
                                {uniqueArray?.join(" and ") +
                                  " " +
                                  d.message +
                                  ", "}
                              </span>
                            );
                          }
                        })}
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="btnSec">
        <span onClick={back} className="btn secondaryBtn btn-primary  mr-2">
          Back
        </span>

        <Button
          onClick={onSubmit}
          type="submit"
          variant="primary"
          className="m-1 text-capitalize primaryBtn"
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default React.memo(BulkUploadAirQuality);
