import React, { useEffect, useState } from "react";
import {
  initialForm,
  validationRules,
  hemmApi,
  capacityRules,
  reasonList,
  validationRulesHmr,
} from "./DATA";
import { Col, Form, Row, Button } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { useNavigate, useParams } from "react-router-dom";
import { SidebarData } from "../../FuelManagement/DATA";
import {
  auth_user,
  digitCheckAfterDecimal,
  IS_USER_SITE_MINES,
} from "../../../../_helpers/helper";
import { useForm } from "../../../../_helpers/hooks";
import { Validation } from "../../../../_helpers/validation/Validation";
import {
  updateHemm,
  addHemm,
} from "../../../../services/_fuelDispense/fuelDispense";
import toastr from "toastr";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import Select from "../../../../components/Select/Select";
import CheckBoxField from "../../../../components/_form/CheckBoxField/CheckBoxField";
import moment from "moment";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";

const HemmAdd = () => {
  const params = useParams();
  const [mineList, setMineList] = useState([]);
  const navigate = useNavigate();
  const [form, onChange, setForm] = useForm(initialForm);
  const [error, setError] = useState({
    ...initialForm,
  });
  const onSubmit = async () => {
    let finalValidationRules = validationRules;
    // if (form?.isBowser) finalValidationRules?.push(capacityRules);
    // else
    //   finalValidationRules = finalValidationRules?.filter(
    //     (d) => d?.field_name !== "capacity"
    //   );
    const errorObj = Validation(
      form,
      finalValidationRules?.map((rules) => {
        return form?.isBowser
          ? rules?.field_name === "hemm_id"
            ? { ...rules, label: "Bowser Id" }
            : rules?.field_name === "fms_vehicle_type"
            ? { ...rules, label: "Bowser Type" }
            : rules?.field_name === "fms_vehicle_name"
            ? { ...rules, label: "Bowser Name" }
            : rules
          : rules;
      })
    );
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }
    if (
      (form.reason || form.appliedFrom || form.hmrReading) &&
      params.id &&
      !form.isBowser
    ) {
      let errorObjHmr = Validation(form, validationRulesHmr);
      setError(errorObjHmr);
      if (Object.values(errorObjHmr).filter((d) => d !== "").length) return;
    }

    let response = params?.id
      ? await updateHemm(form, params.id)
      : await addHemm(form);

    if (!response?.status) {
      if (response?.msg === "Hemm Id Already exists")
        setError({ ...error, hemm_id: response?.msg });
      return toastr.error(response?.msg || "something went wrong");
    }
    if (response?.status) toastr.success(`Saved Successfully`);
    navigate("/fuel-management-system/configuration/hemm-data");
  };

  async function fetchData() {
    let response = await hemmApi.getById.api(params?.id);
    if (response?.status) {
      setForm(() => {
        return {
          make: response?.data?.make,
          model: response?.data?.model,
          capacity: response?.data?.capacity,
          hemm_id: response?.data?.hemm_id,
          fms_vehicle_type: response?.data?.fms_vehicle_type,
          fms_vehicle_name: response?.data?.fms_vehicle_name,
          machine_serial: response?.data?.machine_serial,
          site_id: response?.data?.site_id,
          isBowser: +response?.data?.isBowser,
          lastUpdated: response?.getLastDispensedDate?.createdAt,
          reason: response?.data?.isHmrUsed !== 1 ? response?.data?.reason : "",
          hmrReading:
            response?.data?.isHmrUsed !== 1 ? response?.data?.hmrReading : "",
          appliedFrom:
            response?.data?.isHmrUsed !== 1 ? response?.data?.appliedFrom : "",
        };
      });
    }
  }
  const mineLists = async () => {
    let list = [];
    const response = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );

    if (response.status) {
      list = response.data?.map((d) => {
        return { value: d.id, label: d.name };
      });
    }
    setMineList(list);
  };
  useEffect(() => {
    mineLists();
  }, []);
  useEffect(() => {
    fetchData();
  }, [params.id, setForm]);
  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj =
      mineList?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setForm((prev) => ({
      ...prev,
      site_id: siteObj?.value,
    }));
  }, [mineList]);
  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    path: "/",
                    name: "Fuel Management",
                  },
                  {
                    name: "Configuration",
                    path: "/fuel-management-system/configuration",
                  },
                  {
                    path: "#",
                    name: form?.isBowser ? "Add Bowser" : "Add HEMM",
                  },
                ]}
              />
            </h3>
            <div className="reportCard">
              <div className="formWrapper">
                <div className="maverickFormCard">
                  <div className="creationWrapper">
                    <Form>
                      <Row>
                        <Col lg={6}>
                          <FormInputText
                            name="hemm_id"
                            value={form.hemm_id}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={form?.isBowser ? "Bowser Id" : "HEMM Id"}
                            error={error?.hemm_id}
                          />
                        </Col>
                        <Col lg={6}>
                          <Select
                            onChange={onChange}
                            name="site_id"
                            options={mineList}
                            label={"Mine"}
                            value={form?.site_id}
                            error={error?.site_id}
                            disabled={IS_USER_SITE_MINES()}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="machine_serial"
                            value={form.machine_serial}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Machine Serial Number"}
                            error={error?.machine_serial}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="fms_vehicle_type"
                            value={form.fms_vehicle_type}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={
                              form?.isBowser
                                ? "Bowser Type"
                                : "FMS Vehicle Type"
                            }
                            error={error?.fms_vehicle_type}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="fms_vehicle_name"
                            value={form.fms_vehicle_name}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={
                              form?.isBowser
                                ? "Bowser Name"
                                : "FMS Vehicle Name"
                            }
                            error={error?.fms_vehicle_name}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="make"
                            value={form.make}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Make"}
                            error={error?.make}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="model"
                            value={form.model}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Model"}
                            error={error?.model}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="capacity"
                            value={form.capacity}
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              digitCheckAfterDecimal(value, 2) &&
                              onChange({ target: { name, value } })
                            }
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Tank Capacity"}
                            error={error?.capacity}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="loadCapacity"
                            value={form.loadCapacity}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Load Capacity"}
                            error={error?.loadCapacity}
                          />
                        </Col>
                        <Col lg={4} className="mt-5">
                          <CheckBoxField
                            name={"isBowser"}
                            checked={form?.isBowser}
                            onChange={onChange}
                            label={"Is Bowser"}
                          />
                        </Col>
                      </Row>

                      {params.id && !form.isBowser ? (
                        <>
                          <h5 className="mt-4">HMR Reading update</h5>
                          <Row>
                            <Col lg={6}>
                              <Select
                                onChange={onChange}
                                name="reason"
                                options={reasonList}
                                label={"Reason"}
                                value={form?.reason}
                                error={error?.reason}
                              />
                            </Col>
                            <Col lg={6}>
                              <Form.Label className="form-label-input">
                                Applied From
                              </Form.Label>
                              <CustomDatePicker
                                value={form.appliedFrom}
                                isValidDate={(day) =>
                                  day.isAfter(moment(form.lastUpdated)) &&
                                  day.isSameOrBefore(moment())
                                }
                                name="appliedFrom"
                                onChange={onChange}
                                dateFormat="YYYY-MM-DD"
                                error={error.appliedFrom}
                              />
                            </Col>
                            <Col lg={6}>
                              <FormInputText
                                name="hmrReading"
                                value={form.hmrReading}
                                onChange={({ target: { name, value } }) =>
                                  value >= 0 &&
                                  digitCheckAfterDecimal(value, 2) &&
                                  onChange({ target: { name, value } })
                                }
                                type="number"
                                class="form-control"
                                placeholder=""
                                label={"HMR Reading"}
                                error={error?.hmrReading}
                              />
                            </Col>
                            <Col lg={6}></Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                    </Form>
                    <div className="btnSec">
                      <Button
                        data-testid="next-btn"
                        onClick={() =>
                          navigate(
                            `/fuel-management-system/configuration/hemm-data`
                          )
                        }
                        className="secondaryBtn iconBtnForm"
                      >
                        Back
                      </Button>
                      <Button
                        data-testid="next-btn-submit"
                        onClick={() => onSubmit()}
                        className="primaryBtn iconBtnForm"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HemmAdd;
