import selectionIcon1 from "../../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../../Assets/Images/selectionIcon3.svg";
import selectionIcon4 from "../../Assets/Images/selectionIcon4.svg";
import selectionIcon5 from "../../Assets/Images/selectionIcon5.svg";
import { permissionCheck } from "../../_services/Auth/helper";
import { getAllSapList } from "../../services/_contractorList/contractorList";

export const urlAccordingTabPermissions = () => {
  let url;
  if (permissionCheck(["contractor_hindrance_dashboard"]))
    url = "/contractor-management/contractor-dashboard/hindrance";
  else if (permissionCheck(["performance_production_dashboard"]))
    url = "/contractor-management/contractor-dashboard/production-performance";
  else if (permissionCheck(["contractor_performance_score_card_dashboard"]))
    url =
      "/contractor-management/contractor-dashboard/performance-score-card-vendor";
  else if (permissionCheck(["contractor_performance_dashboard"]))
    url = "/contractor-management/contractor-dashboard/performance";
  return url;
};

export const tabUrl = {
  safetyManagement: [
    {
      active: 0,
      to: "/safety-management-system/incident-management/incident-reported",
      label: "Admin Actions",
      permission: ["safety_admin"],
    },
    {
      to: "/safety-management-system/incident-management/incident-investigation",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
      label: "Investigator Actions",
    },
    {
      to: "/safety-management-system/incident-management/incident-capa",
      label: "CAPA Owner Actions",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
  ],
  safetyManagementApprovalHistory: [
    {
      label: "Admin",
      active: 0,
      permission: ["safety_admin"],
      to: "/safety-management-system/incident-management/incident-reported-approval_history",
    },
    {
      to: "/safety-management-system/incident-management/incident-investigator-approval_history",
      label: "Investigator",
      // permission: ["fuel_admin", "dispenser"],
      active: 0,
    },
    {
      label: "CAPA Owner",
      to: "/safety-management-system/incident-management/incident-capa-owner-approval_history",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
  ],
};
export const SidebarData = [
  {
    title: "Dashboard",
    active: 0,
    img: selectionIcon1,
    // link: "/contractor-management/contractor-dashboard/performance",
    // activeUrl: "dashboard",
    // permission: ["contractor_performance_dashboard", "performance_production_dashboard", "contractor_performance_score_card_dashboard", "contractor_hindrance_dashboard"],
    link: urlAccordingTabPermissions,
    activeUrl: "dashboard",
    permission: [
      "contractor_performance_dashboard",
      "performance_production_dashboard",
      "contractor_performance_score_card_dashboard",
      "contractor_hindrance_dashboard",
    ],
  },
  {
    title: "SAP Report",
    active: 0,
    img: selectionIcon1,
    link: "/contractor-management/sap-report",
    activeUrl: "sap-report",
    permission: ["quick_sight_dashboard"],
  },
  // {
  //   title: "SAP Production",
  //   active: 0,
  //   img: selectionIcon1,
  //   link: "/contractor-management/sap-production",
  //   activeUrl: "production",
  //   permission: ["quick_sight_dashboard"],
  // },
  {
    active: 0,
    img: selectionIcon2,
    title: "User Management",
    link: "/contractor-management/user-list",
    activeUrl: "user-list",
    permission: ["contractor_module_admin"],
  },
  {
    title: "Configuration",
    img: selectionIcon3,
    active: 0,
    link: "/contractor-management/configuration",
    activeUrl: "configuration",
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    activeUrl: "contractor-performance",
    img: selectionIcon4,
    title: "Contractor Performance",
    active: 0,
    link: "/contractor-management/contractor-performance",
    permission: [
      "section_incharge",
      "contractor_module_admin",
      "contractor_performance",
    ],
  },
  {
    link: "/contractor-management/contractor-hindrance/module",
    img: selectionIcon4,
    title: "Contractor Hindrance",
    active: 0,
    activeUrl: "contractor-hindrance",
    permission: [
      "representative",
      "section_incharge",
      "colliery_manager",
      "contractor_module_admin",
      // "contractor",
      "contractor_hindrance",
    ],
  },
  {
    img: selectionIcon4,
    title: "Contractor Scorecard",
    active: 0,
    link: "/contractor-management/contractorscore-management",
    activeUrl: "contractor-score",
    permission: ["contractor_module_admin", "section_incharge"],
  },
  {
    img: selectionIcon5,
    title: "Help Manual",
    active: 0,
    activeUrl: "help-manual",
    link: "/contractor-management/help-manual",
  },
];
export const incidentApprovalFlowList = [
  {
    img: selectionIcon1,
    label: "Reported Incident",
    link: "/safety-management-system/incident-management/incident-reported",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon2,
    label: "Investigation Assigned",
    link: "/safety-management-system/incident-management/incident-investigation",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon3,
    label: "CAPA Assigned",
    // link: "/dashboard/contractor-performance-dashboard",
    // role: ["contractor_dashboard"],
  },
];
export const incidentFlowList = [
  {
    img: selectionIcon1,
    label: "Report Incident",
    link: "/safety-management-system/incident-management/report/add",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon2,
    label: "My Incidents",
    link: "/safety-management-system/incident-management/my-incidents",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon3,
    label: "View Incidents",
    link: "/safety-management-system/incident-management/view-incidents",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon4,
    label: "My Approval Actions",
    link: "/safety-management-system/incident-management/my-approval-action",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon5,
    label: "My Approval History",
    link: "/safety-management-system/incident-management/incident-reported-approval_history",
    // role: ["contractor_dashboard"],
  },
];

export const performanceFlowList = [
  {
    img: selectionIcon1,
    label: "Add Contractor Performance",
    link: "/contractor-management/contractor-performances/create",
    permission: ["section_incharge", "contractor_performance"],
  },
  {
    img: selectionIcon2,
    label: "My Contractor Performance",
    link: "/contractor-management/contractor-performance/my-contractor-performance",
    permission: ["section_incharge", "contractor_performance"],
  },
  {
    img: selectionIcon3,
    label: "View Contractor Performance",
    link: "/contractor-management/contractor-performances",
    permission: ["contractor_module_admin"],
  },
];

export const contractorConfiguration = [
  {
    img: selectionIcon1,
    label: "Contractor Reason",
    link: "/contractor-management/configuration/reason-list",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon1,
    label: "Other than Contractor Reason",
    link: "/contractor-management/configuration/other-reason-list",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon2,
    label: "Contractor",
    link: "/contractor-management/configuration/contractor-list",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon2,
    label: "NIT Master",
    link: "/contractor-management/configuration/nit-list",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon2,
    label: "PO Master",
    link: "/contractor-management/configuration/po-list",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon3,
    label: "Contractor User",
    link: "/contractor-management/configuration/contractor-users",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon4,
    label: "Import SAP Data",
    link: "/contractor-management/configuration/sap-import",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon4,
    label: "Section",
    link: "/contractor-management/configuration/section",
    // role: ["contractor_dashboard"],
  },
];

export const validationRules = [
  {
    isRequired: true,
    field_name: "name",
    label: "Name",
    type: "string",
  },
  {
    field_name: "code",
    label: "Code",
    type: "number",
    isRequired: true,
  },
];

export const contractorSapData = {
  subsidiary: "Subsidiary",
  mine: "Mine",
  contractor: "Contractor",
  targetQnt: "Trgt.Qty",
  achiQty: "Achi.Qty",
  nit: "NIT",
  po: "PO Number",
  plant: "Plant",
  productionDate: "Production Date",
  materialNumber: "Material Number",
  purchasingDocument: "Purchasing Document",
  optr: "Oprtr/Vend",
  name: "Contractor Name",
  nitAgreement: "NIT Agreement",
  relDate: "Rel. Date",
  shortfall: "Shortfall",
  shortFallBycontractor: "Shrt.due to Contractor",
  reasonByCont1: "Reason by Cont.",
  reasonByCont2: "Reason by Cont.",
  shortOtherThanContractor: "short other than Contractor",
  reasonByOther1: "Reason by Other",
  reasonByOther2: "Reason by Other",
  remarks: "Remarks",
  createdBy: "Created By",
  createdOn: "Created On",
};

export const contractorSapColumnFields = [
  {
    name: contractorSapData?.subsidiary,
    selector: "siteData",
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: contractorSapData?.mine,
    selector: "siteData",
    cell: (row) => row?.site?.name,
  },
  {
    name: contractorSapData.contractor,
    selector: "contractor",
    cell: (row) => row?.contractor?.contractor,
    sortable: true,
  },
  {
    name: contractorSapData.targetQnt,
    selector: "trgtQty",
    cell: (row) => row?.trgtQty,
    sortable: true,
  },
  {
    name: contractorSapData.achiQty,
    selector: "achiQty",
    cell: (row) => row?.achiQty,
    sortable: true,
  },
  {
    name: contractorSapData.nit,
    selector: "nit",
    cell: (row) => row?.nit?.nit,
    sortable: true,
  },
  {
    name: contractorSapData.po,
    selector: "purchasingDocument",
    cell: (row) => row?.purchasingDocument,
    sortable: true,
  },
];

export const contractorSapApis = Object.freeze({
  getAll: {
    api: getAllSapList,
  },
});
