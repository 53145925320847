import { CommmonService } from "../_services/CommonService/CommonService";

export const uploadFileOnS3 = async (file, fileType) => {
  const { fileName, data } = await CommmonService.getSingleSignedUrl({
    fileName: file.name,
    fileType: "xlx/xlsx",
  });
  const result = await fetch(data, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    body: file,
  });
  return {
    s3Url: result.url.split("?")[0],
    originalname: file.name,
    filename: fileName,
    size: file.size,
    type: file.type,
  };
};
