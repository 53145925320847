import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../components/DateTimeCustom/CustomDateTime";
import Select from "../../components/Select/Select";
import { getAllSites } from "../../_services/SiteServices/SiteServices";
import FilterFormBtn from "../../components/Common/FilterFormBtn/FilterFormBtn";
import { gpsStatus } from "./DATA";
const UserFilterForm = ({
  contractorList,
  form,
  isOpen,
  onChange,
  onSubmitFilter,
  setOpen,
  onResetFilter,
}) => {
  const [siteList, setSiteList] = useState([]);

  const allSites = async () => {
    const response = await getAllSites(
      "filter[siteType]=1,2,3&filter[activeStatus]=active"
    );
    if (!response?.status) return;
    setSiteList(response?.data);
  };

  useEffect(() => {
    allSites();
  }, []);
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Row>
          <Col md={12}>
            <Select
              onChange={onChange}
              value={form?.siteId}
              name="siteId"
              options={siteList}
              label={"Location"}
              KEY_label={"name"}
              KEY_value={"id"}
            />
          </Col>
          {/* <Form.Label>Input Date</Form.Label> */}
          {/* <Col md={6}>
            <CustomDatePicker
              onChangeDate={onChange}
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              name="endDate"
              onChangeDate={onChange}
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              value={form?.gps_active}
              onChange={onChange}
              name="gps_active"
              label={"GPS"}
              options={Object.keys(gpsStatus)?.map((key) => ({
                value: gpsStatus[key],
                label: key,
              }))}
            />
          </Col> */}
        </Row>
        <FilterFormBtn
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          setOpen={setOpen}
        />
      </div>
    </Collapse>
  );
};

export default UserFilterForm;
