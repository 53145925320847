import React, { Component } from "react";
import { BsFileEarmarkArrowDown, BsFileEarmarkArrowUp } from "react-icons/bs";
import { Modal, ModalBody, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { CommmonService } from "../../_services/CommonService/CommonService";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
// import { NotificationContainer, NotificationManager } from "react-notifications";
import Loading from "../../../src/components/Loader/Loading";
import toastr from "toastr";
import { PARSE_IMG_URL } from "../../_helpers/commonHelper";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { uploadFileOnS3 } from "../../services/S3Service";
// import { IoIosCloseCircleOutline } from "react-icons/io";

class MultipleFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alias: "",
      isLoading: false,
      showManageModal: false,
      imageUrl: "",
      source: this.props.source
    };

    this.onFileChange = this.onFileChange.bind(this);
  }

  async onFileChange(e) {
    const target = e.target;
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (this.maxSelectFile(e) && this.checkValidation()) {
        this.setState({ isLoading: true });

        // const formData = new FormData();

        // formData.append("alias", this.state.alias);
        // formData.append("file", selectedFile);
        const dataForDoc = await uploadFileOnS3(selectedFile);
        const fileUploadResponse = await CommmonService.uploadDocument(
          {
            ...dataForDoc,
            alias: this.state.alias, source: this.state.source
          }
        );

        if (!fileUploadResponse?.status) {
          this.setState({ isLoading: false });
          return toastr.error("Upload Failed!");
        }

        this.props.onFileChange(e, fileUploadResponse.data);

        this.setState(
          {
            alias: "",
            isLoading: false,
          },
          () => {
            toastr.info("Document Uploaded.");
          }
        );
      }
    } else {
    }
    target.value = null;
  }

  maxSelectFile = (event) => {
    const selectedFileArray = this.props.selectedFileArray; // create file object

    if (
      Array.isArray(selectedFileArray) &&
      selectedFileArray.length >= this.props.uploadLimit
    ) {
      const msg =
        "Only " + this.props.uploadLimit + " file(s) can be uploaded at a time";
      toastr.info(msg);
      event.target.value = null; // discard selected file
      this.setState({
        selectedFileError: msg,
      });
      return false;
    } else {
      this.setState({
        selectedFileError: "",
      });
    }
    return true;
  };

  checkValidation = () => {
    if (this.props.required) {
      if (!this.state.alias) {
        toastr.info("alias is required");
        return false;
      }
    }

    return true;
  };

  deleteDocumentFile = (e, fileIndex) => {
    e?.preventDefault();
    this.props.deleteDocumentFile(e, fileIndex);
  };

  handleInputChange = ({ target: { value } }) => {
    this.setState({
      alias: value,
    });
  };

  imageShowManageModal = (fileName) => {
    const uploadImg = PARSE_IMG_URL(URL_CONFIG.DEV_URL, fileName);
    this.setState((prev) => ({
      ...prev,
      showManageModal: true,
      imageUrl: uploadImg ? uploadImg : "",
    }));
  };

  render() {
    let { labelName, selectedFileArray } = this.props;

    let { Loading } = this.state;
    return (
      <div className={`${this.props.clsNameCustom}`}>
        {Loading ? <Loading /> : ""}
        <div
          className="uploadOuterDiv"
          style={
            this.props.unstyledDiv
              ? undefined
              : { width: "90%", margin: "0 10px 0 0" }
          }
        >
          {this.props.isLabel === false ? (
            ""
          ) : (
            <label>{labelName ? labelName : "Alias"}</label>
          )}

          <div className="alias_docUpload">
            <input
              type="text"
              className="form-control"
              placeholder="Enter alias name"
              value={this.state.alias}
              maxLength="20"
              onChange={(e) => this.handleInputChange(e)}
              readOnly={this.props.isDisable ? true : false}
            />
            {this.props.isDisable ? (
              <>
                <div className="uploadFileBtn disable">
                  <label style={{ background: "#e6e9eb" }}>
                    {this.props.isMandatory ? (
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "15px",
                        }}
                      >
                        *
                      </span>
                    ) : (
                      ""
                    )}
                    <BsFileEarmarkArrowUp color="rgb(185 185 185)" />
                  </label>
                </div>
              </>
            ) : (
              <div className="uploadFileBtn">
                <label htmlFor="uploadMultiple">
                  {this.props.isMandatory ? (
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "15px",
                      }}
                    >
                      *
                    </span>
                  ) : (
                    ""
                  )}
                  <BsFileEarmarkArrowUp />
                </label>
                <input
                  id="uploadMultiple"
                  className="uploadFile"
                  type="file"
                  onChange={(e) => this.onFileChange(e)}
                ></input>
              </div>
            )}
          </div>
        </div>
        {selectedFileArray?.length > 0 &&
          selectedFileArray.map((file, fileIndex) => {
            return (
              <div className="uploadedFilesList" key={fileIndex}>
                <div className="uploadedFilesListInner">
                  <span> {file && file.alias ? file.alias : ""}</span>
                  <div className="uploadIconsGroup">
                    {this.props.permission !== "" &&
                      this.props.permission !== undefined &&
                      this.props.permission !== null ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {file && file.real_document_name}
                          </Tooltip>
                        }
                      >
                        <a
                          href={
                            URL_CONFIG.DEV_URL + "uploads/" + file.document_name
                          }
                          className="icons text-cyanBlue"
                        >
                          <BsFileEarmarkArrowDown />
                        </a>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {file && file.real_document_name}
                          </Tooltip>
                        }
                      >
                        <span
                          onClick={() => {
                            this.imageShowManageModal(file.document_name);
                          }}
                        >
                          <BsFileEarmarkArrowDown />
                        </span>
                      </OverlayTrigger>
                    )}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">{"Delete Document"}</Tooltip>
                      }
                    >
                      <span
                        className="pointer icons text-danger"
                        onClick={(e) => this.deleteDocumentFile(e, fileIndex)}
                      >
                        <FiTrash2 />
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            );
          })}
        {/* {submitted && error.selectedFile !== "" && (
                        <div className="fromerror">{error.selectedFile}</div>)} */}
        {/* <NotificationContainer /> */}

        <Modal
          show={this.state.showManageModal}
          onHide={() =>
            this.setState((prev) => ({ ...prev, showManageModal: false }))
          }
          size="lg"
          centered
        >
          <ModalBody className="safetyModal">
            <div
              className="closeIcon"
              data-testid="filter-button"
              onClick={() => {
                this.setState((prev) => ({ ...prev, showManageModal: false }));
              }}
            >
              <IoIosCloseCircleOutline />
            </div>
            <div className="popupImgVideo text-center">
              <img className="img-fluid" src={this.state.imageUrl} alt="" />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default MultipleFileUpload;
