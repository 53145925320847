import React, { useEffect, useState } from "react";
import { initialForm, incidentKpiLocationRules } from "./DATA";
import { Button } from "react-bootstrap";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { useNavigate, useParams } from "react-router-dom";
import { SidebarData } from "../../IncidentManagement/DATA";
import { useForm } from "../../../../_helpers/hooks";
import { Validation } from "../../../../_helpers/validation/Validation";
import { incidentKpiApi } from "./DATA";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";
import {
  createIncidentKpi,
  updateIncidentKpi,
} from "../../../../services/_safetyManagement/incidentServices";

import toastr from "toastr";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import Select from "../../../../components/Select/Select";
import {
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
  idByCheckMine,
  subsidiaryListByMine,
} from "../../../../_helpers/helper";

const IncidentKpiAddEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState({ ...initialForm });
  const [sites, setSites] = useState();
  const [finalSiteList, setFinalSiteList] = useState();
  const [subsidery, setSubsidery] = useState([]);
  const [userSite, setUserSite] = useState();
  const [error, setError] = useState({
    ...initialForm,
  });

  const onChange = ({ target: { name, value } }) => {
    setForm((prev) => {
      const pre = { ...prev, [name]: value };
      if (name === "mine") {
        pre.site_id = "";
      }
      return pre;
    });
  };

  function checkString(string) {
    var pattern = /^[1-9][0-9]{3}$/;
    var invalidPattern = /^0{1,4}/;
    return pattern.test(string) && !invalidPattern.test(string);
  }

  const onSubmit = async () => {
    let yearValidation = checkString(form.kpi_year);

    const errorObj = Validation(form, incidentKpiLocationRules);
    setError(errorObj);
    if (!yearValidation) {
      setError((prev) => ({ ...prev, kpi_year: "Invalid Year" }));
    }
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }

    let response = params?.id
      ? await updateIncidentKpi(form, params.id)
      : await createIncidentKpi(form);

    if (!response?.status || response?.status === 401)
      return !response?.status && toastr.error(response?.message);

    if (response?.status) toastr.success(`Saved Successfully`);
    navigate("/safety-management-system/configuration/incident-kpi");
  };

  async function fetchData() {
    let response = await incidentKpiApi.getById.api(params?.id);
    if (response?.status) {
      setForm(() => {
        return {
          site_id: response?.data?.site_id,
          kpi_year: response?.data?.kpi_year,
          total_hrs: response?.data?.total_hrs,
        };
      });
    }
  }

  useEffect(() => {
    if (!params?.id) return;
    setForm((prev) => ({
      ...prev,
      mine: sites?.find((d) => d?.id === prev?.site_id)?.parentSiteId || "",
    }));
  }, [params?.id, sites]);

  useEffect(() => {
    params?.id && fetchData();
  }, [params.id, setForm]);

  useEffect(() => {
    getAllSites("filter[siteType]=1,2,3&filter[activeStatus]=active").then(
      (response) => {
        if (!response?.data) return;
        const siteData = response.data.filter((d) => d?.siteType === 3);
        setSites(siteData);
        setFinalSiteList(siteData);
        setSubsidery(subsidiaryListByMine(response.data));
      }
    );
  }, []);

  useEffect(() => {
    setFinalSiteList(
      form?.mine ? sites?.filter((d) => d?.parentSiteId === +form?.mine) : sites
    );
  }, [form?.mine, sites]);

  useEffect(() => {
    if (params?.id) return;
    setForm((prev) => ({
      ...prev,
      site_id: idByCheckMine(sites)?.siteId,
      mine: idByCheckMine(sites)?.subsideryId,
    }));
  }, [sites]);

  useEffect(() => {
    let authUser = localStorage.getItem("auth_user");
    let user = authUser ? JSON.parse(authUser) : "";
    let user_site = authUser ? JSON.parse(JSON.parse(authUser)?.siteId) : "";
    setUserSite(user_site);
  }, [sites]);
  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              style={{ paddingTop: "5px", paddingBottom: "1px" }}
              className="title"
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    path: "/",
                    name: "Safety Management",
                  },
                  {
                    name: "Configuration",
                    path: "/safety-management-system/configuration",
                  },
                  {
                    path: "#",
                    name: "Add KPI Data",
                  },
                ]}
              />
            </h3>
            <div className="reportCard">
              <div className="formWrapper">
                <div className="maverickFormCard">
                  <div className="creationWrapper">
                    <Form>
                      <Row>
                        <Col lg={3}>
                          <Select
                            label={"Subsidiary"}
                            name="mine"
                            value={form?.mine}
                            onChange={onChange}
                            options={subsidery}
                            KEY_label={"name"}
                            KEY_value={"id"}
                            error={error?.mine}
                            disabled={
                              !!params?.id ||
                              IS_USER_SITE_MINES() ||
                              IS_USER_SITE_RO()
                            }
                            blankSelect={"Select"}
                          ></Select>
                        </Col>
                        <Col lg={3}>
                          <Select
                            label={"Mine"}
                            name="site_id"
                            value={form?.site_id}
                            onChange={onChange}
                            options={finalSiteList}
                            KEY_label={"name"}
                            KEY_value={"id"}
                            error={error?.site_id}
                            disabled={!!params?.id || IS_USER_SITE_MINES()}
                            blankSelect={"Select"}
                          ></Select>
                          {/* <FormInputText
                            name="mine"
                            value={form?.site_id ? sites?.find(d => form?.site_id === d.id)?.parentSite?.name : ''}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Subsidiary"}
                            // error={error?.mine}
                            disabled={true}
                          /> */}
                        </Col>
                        <Col lg={3}>
                          <FormInputText
                            name="kpi_year"
                            value={form.kpi_year}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Year"}
                            error={error?.kpi_year}
                          />
                        </Col>
                        <Col lg={3}>
                          <FormInputText
                            name="total_hrs"
                            value={form.total_hrs}
                            onChange={onChange}
                            type="number"
                            class="form-control"
                            placeholder=""
                            label={"Total Work Hours By All Staff"}
                            error={error?.total_hrs}
                          />
                        </Col>
                      </Row>
                    </Form>
                    <div className="btnSec">
                      <Button
                        onClick={() =>
                          navigate(
                            `/safety-management-system/configuration/incident-kpi`
                          )
                        }
                        data-testid="next-btn"
                        className="secondaryBtn iconBtnForm"
                      >
                        Back
                      </Button>
                      <Button
                        onClick={() => onSubmit()}
                        className="primaryBtn iconBtnForm"
                        data-testid="next-btn"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentKpiAddEdit;
