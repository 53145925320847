import { isArray } from "lodash";
import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { activityLabel } from "./DATA";
import { capitalizeName } from "../../../../_helpers/helper";

const ActivityView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            {/* <p className="head">Sample Coding Register Data</p> */}
            <Row>
              <Col sm={6}>
                <label>{activityLabel?.activity}</label>
                <p name="user-name" className="content">
                  {`${form?.name}`}
                </p>
              </Col>
              <Col sm={6}>
                <label>{activityLabel?.subActivity}</label>
                {form.subActivity.map((d, i) => {
                  return (
                    <p key={"activity" + i} name="user-name" className="content">
                      {i + 1 + ". "}
                      {`${d?.name}`}
                    </p>
                  );
                })}
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default ActivityView;
