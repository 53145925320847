import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { mineLabel } from "./DATA";
import CreatedByCreatedAt from "../../../../components/Common/CreatedByCreatedAt";

const MineDetailsView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <Row>
            <Col sm={4}>
              <label>{mineLabel.subsidiary}</label>
              <p className="content">{form?.parentSite?.name}</p>
            </Col>
            <Col sm={4}>
              <label>{mineLabel.name}</label>
              <p className="content">{form?.name}</p>
            </Col>
            <Col sm={4}>
              <label>{mineLabel.siteIdCode}</label>
              <p className="content">{form?.siteIdCode}</p>
            </Col>
            <Col sm={4}>
              <label>{mineLabel.lin}</label>
              <p className="content">{form?.siteData?.lin}</p>
            </Col>
            <Col sm={4}>
              <label>{mineLabel.ownerName}</label>
              <p className="content">{form?.siteData?.ownerName}</p>
            </Col>
            <Col sm={4}>
              <label>{mineLabel.region}</label>
              <p className="content">{form?.siteData?.region}</p>
            </Col>
            <Col sm={4}>
              <label>{mineLabel.dgmsMineCode}</label>
              <p className="content">{form?.siteData?.dgmsMineCode}</p>
            </Col>
          </Row>
          <h5 className="mt-3">Particulars of the mine</h5>
          <div className="previewContent mt-1">
            <Row>
              <Col sm={4}>
                <label>{mineLabel.mineral}</label>
                <p className="content">{form?.siteData?.mineral}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.mineVillageAreaRoad}</label>
                <p className="content">{form?.siteData?.mineVillageAreaRoad}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.minePostOffice}</label>
                <p className="content">{form?.siteData?.minePostOffice}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.mineSubDivision}</label>
                <p className="content">{form?.siteData?.mineSubDivision}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.mineState}</label>
                <p className="content">{form?.siteData?.mineState}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.mineDistrict}</label>
                <p className="content">{form?.siteData?.mineDistrict}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.minePinCode}</label>
                <p className="content">{form?.siteData?.minePinCode}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.minePoliceStation}</label>
                <p className="content">{form?.siteData?.minePoliceStation}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.mineRailwayStation}</label>
                <p className="content">{form?.siteData?.mineRailwayStation}</p>
              </Col>
            </Row>
          </div>
          <h5 className="mt-3">Agent Details</h5>
          <div className="previewContent mt-1">
            <Row>
              <Col sm={4}>
                <label>{mineLabel.nameAgent}</label>
                <p className="content">{form?.siteData?.nameAgent}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.postalAddressAgent}</label>
                <p className="content">{form?.siteData?.postalAddressAgent}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.districtAgent}</label>
                <p className="content">{form?.siteData?.districtAgent}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.stateAgent}</label>
                <p className="content">{form?.siteData?.stateAgent}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.pinAgent}</label>
                <p className="content">{form?.siteData?.pinAgent}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.mobileAgent}</label>
                <p className="content">{form?.siteData?.mobileAgent}</p>
              </Col>

              <Col sm={4}>
                <label>{mineLabel.teleAgent}</label>
                <p className="content">{form?.siteData?.teleAgent}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.faxAgent}</label>
                <p className="content">{form?.siteData?.faxAgent}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.emailAgent}</label>
                <p className="content">{form?.siteData?.emailAgent}</p>
              </Col>
            </Row>
          </div>
          <h5 className="mt-3">Manager Details</h5>
          <div className="previewContent mt-1">
            <Row>
              <Col sm={4}>
                <label>{mineLabel.nameManager}</label>
                <p className="content">{form?.siteData?.nameManager}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.postalAddressManager}</label>
                <p className="content">
                  {form?.siteData?.postalAddressManager}
                </p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.districtManager}</label>
                <p className="content">{form?.siteData?.districtManager}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.stateManager}</label>
                <p className="content">{form?.siteData?.stateManager}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.pinManager}</label>
                <p className="content">{form?.siteData?.pinManager}</p>
              </Col>

              <Col sm={4}>
                <label>{mineLabel.mobileManager}</label>
                <p className="content">{form?.siteData?.mobileManager}</p>
              </Col>

              <Col sm={4}>
                <label>{mineLabel.teleManager}</label>
                <p className="content">{form?.siteData?.teleManager}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.faxManager}</label>
                <p className="content">{form?.siteData?.faxManager}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.emailManager}</label>
                <p className="content">{form?.siteData?.emailManager}</p>
              </Col>
            </Row>
          </div>
          <h5 className="mt-3">Postal address of owner</h5>
          <div className="previewContent mt-1">
            <Row>
              <Col sm={4}>
                <label>{mineLabel.ownerVillageAreaRoad}</label>
                <p className="content">
                  {form?.siteData?.ownerVillageAreaRoad}
                </p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.ownerPostOffice}</label>
                <p className="content">{form?.siteData?.ownerPostOffice}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.ownerSubDivision}</label>
                <p className="content">{form?.siteData?.ownerSubDivision}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.ownerDistrict}</label>
                <p className="content">{form?.siteData?.ownerDistrict}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.ownerState}</label>
                <p className="content">{form?.siteData?.ownerState}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.ownerPinCode}</label>
                <p className="content">{form?.siteData?.ownerPinCode}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.ownerPoliceStation}</label>
                <p className="content">{form?.siteData?.ownerPoliceStation}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.ownerRailwayStation}</label>
                <p className="content">{form?.siteData?.ownerRailwayStation}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.ownerEmail}</label>
                <p className="content">{form?.siteData?.ownerEmail}</p>
              </Col>{" "}
              <Col sm={4}>
                <label>{mineLabel.ownerTelephone}</label>
                <p className="content">{form?.siteData?.ownerTelephone}</p>
              </Col>{" "}
              <Col sm={4}>
                <label>{mineLabel.ownerFax}</label>
                <p className="content">{form?.siteData?.ownerFax}</p>
              </Col>
              <Col sm={4}>
                <label>{mineLabel.ownerMobile}</label>
                <p className="content">{form?.siteData?.ownerMobile}</p>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default MineDetailsView;
