import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { contractorListApis, contractorListColumnFields } from "./DATA";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import { tabUrl2 } from "../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import { permissionCheck } from "../../../_services/Auth/helper";
import EmptyDataList from "../ContractorPerformance/EmptyDataList";
import { getAllContractorList } from "../../../services/_contractorList/contractorList";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../_services/CommonService/CommonService";
import toastr from "toastr";

const ContractorList = () => {
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl2.contractorPerformance)
  );
  const cref = useRef(null);
  let navigate = useNavigate();

  const getAll = async () => {
    const response = await getAllContractorList("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0)
      setIsListData(true);
    else setIsListData(false);
  };

  const onEdit = (id) => {
    navigate("/contractor-management/configuration/contractor-list/edit/" + id);
  };

  useEffect(() => {
    setCustomTabs((prev) => {
      return (
        Array.isArray(prev) &&
        prev.map((d) => {
          if (d.to === usrl.pathname) {
            return {
              ...d,
              active: 1,
            };
          }
          return d;
        })
      );
    });
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} />;
  };

  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: `contractors`,
        id: id,
        status: status
      }
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const finalColumnFields = useMemo(
    () => [
      ...contractorListColumnFields,
      {
        name: "Status",
        selector: "status",
        cell: (row) => {
          return (
            (row.status === 1 && (
              <span
                href="#"
                className="icons text-success pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOn
                  size="20"
                  data-testid={"toogle-user-list-on-" + row.id}
                />
              </span>
            )) ||
            (row.status === 0 && (
              <span
                href="#"
                className="icons text-danger pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOff
                  size="20"
                  data-testid={"toogle-user-list-off-" + row.id}
                />
              </span>
            ))
          );
        },
      },
    ],
    []
  );

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            {/* <h4>Contractor Performance</h4> */}
            {/* <CustomTabs tabeList={customTabs} /> */}
            <div className="customDataTbl">
              <DataTableWithServer
                breadCrumb={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/contractor-management/configuration",
                  },
                  {
                    name: "Contractor List",
                    path: "#",
                  },
                ]}
                fields={finalColumnFields}
                cref={cref}
                apis={contractorListApis}
                title="Data"
                // viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={
                  "/contractor-management/configuration/contractor-list"
                }
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                listname={"contractorlist"}
                customTableTitle={"Contractor List"}
                isNoTabOnPage
                viewTitle={"Contractor Performance Details"}
                // hideSearch={true}
                editFunButton={onEdit}
                deleteFun
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              pageLink={"/contractor-management/configuration/contractor-list"}
              breadCrumb={[
                {
                  name: "Contractor Management",
                  path: "#",
                },
                {
                  name: "Configuration",
                  path: "/contractor-management/configuration",
                },
                {
                  name: "Contractor List",
                  path: "#",
                },
              ]}
              title={"Contractor"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorList;
