import React, { Component } from "react";
import { TiInfoOutline } from "react-icons/ti";

export default class NoRecord extends Component {
  render() {
    return (
      <div className="noRecordFound text-danger">
        <TiInfoOutline /> {this.props.noRecordText || "No Records found...!"}
      </div>
    );
  }
}
