import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../../../../components/FormInputText/FormInputText";
import { CustomSwitch } from "../../../../../../components/CustomSwitch/CustomSwitch";
import { responsiblePersonLabel } from "../../../DATA";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Select from "../../../../../../components/MobileComponents/Select/Select";
import AsterikOnly from "../../../../../../components/Common/Asterik/AstrikOnly";

const ResponsibleInfoMobile = ({
  form = {},
  onChange,
  informatinsDetails,
  responsiblePersonForm,
  designations,
  onChangeInformation,
  errors,
  addNewField,
  removeLastField,
}) => {
  return (
    <div className="mobileFormWrapper">
      <div className="ehsFormWrapper p-0">
        <div className="customSwitchSec">
          <CustomSwitch
            name="isResponsiblePersonInfo"
            value={
              form.isResponsiblePersonInfo === null
                ? false
                : form.isResponsiblePersonInfo
            }
            onChange={onChange}
            title={"Responsibility for Accident"}
          />
        </div>
        <Form className="mt-3">
          <Row>
            {form.isResponsiblePersonInfo && (
              <Col md="12">
                <h6 className="mt-4 ms-3">
                  Responsibility for Accident <AsterikOnly />{" "}
                </h6>

                {responsiblePersonForm?.length ? (
                  responsiblePersonForm?.map((x, index) => {
                    return (
                      <div className="addDataformCards" key={index}>
                        <div className="formFields">
                          <FormInputText
                            label={responsiblePersonLabel.responsiblePerson}
                            name="responsiblePerson"
                            type="text"
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            value={x.responsiblePerson}
                            error={errors[index]?.responsiblePerson}
                          />
                        </div>
                        <div className="formFields">
                          {/* <FormInputText
                            label={responsiblePersonLabel.designationCode}
                            name="designationCode"
                            type="text"
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            value={x.designationCode}
                            error={errors[index]?.designationCode}
                          /> */}
                          <Select
                            name="designationCode"
                            label={responsiblePersonLabel.designationCode}
                            value={x?.designationCode}
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            options={designations}
                            error={errors[index]?.designationCode}
                            KEY_label={"code"}
                            KEY_value={"code"}
                          ></Select>
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={responsiblePersonLabel.designation}
                            name="designation"
                            type="text"
                            // onChange={({ target: { name, value } }) =>
                            //   onChangeInformation(index, name, value)
                            // }
                            value={x.designation}
                            error={errors[index]?.designation}
                            disabled={true}
                          />
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={responsiblePersonLabel.rulesConverted}
                            name="rulesConverted"
                            type="text"
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            value={x.rulesConverted}
                            error={errors[index]?.rulesConverted}
                          />
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={responsiblePersonLabel.natureOfContravention}
                            name="natureOfContravention"
                            type="text"
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            value={x.natureOfContravention}
                            error={errors[index]?.natureOfContravention}
                          />
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={responsiblePersonLabel.actionTaken}
                            name="actionTaken"
                            type="text"
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            value={x.actionTaken}
                            error={errors[index]?.actionTaken}
                          />
                        </div>
                        <div className="actionIconsGroup">
                          <span
                            data-testid={"remove-leaf" + index}
                            onClick={() => removeLastField(index)}
                            className="icons font-21 text-danger"
                          >
                            <IoIosCloseCircleOutline
                              size="26"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div></div>
                )}
                {/* <div className="d-flex justify-content-between">
                  <span className="addTxt" onClick={addNewField}>
                    <AiOutlinePlus />
                    Add
                  </span>
                  <span
                    onClick={()=>removeLastField(responsiblePersonForm?.length)}
                  >
                    <AiOutlineMinus />
                    Remove
                  </span>
                </div> */}
                <div className="addMoreBtn">
                  <span
                    className="addTxt"
                    onClick={addNewField}
                    data-testid="add-leaf"
                  >
                    <AiOutlinePlus />
                    Add
                  </span>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ResponsibleInfoMobile;
