import React, { useCallback, useEffect, useMemo, useState } from "react";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import { Container } from "react-bootstrap";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  GETVAL,
  SidebarData,
  assetDetailsRules,
  evidenceRules,
  fatalityRule,
  incidentReportRules,
  incidentStatuses,
  incidentTabList,
  incidentTypesObjKeys,
  initialAssetDetails,
  initialEvidence,
  initialForm,
  initialPersonalDetails,
  initialVehicleDetails,
  injuriesRule,
  matDetailsList,
  mobileSidebarData,
  objStaffType,
  otherCategoryRule,
  otherSubAreaValidation,
  othersInfoRule,
  otherstaffNameRule,
  outcomeOpt,
  personalDetailsRules,
  placeOfIncidentRule,
  roleData,
  staffEmployerRule,
  staffNameRule,
  vehicleDetailsRules,
} from "./DATA";
import CustomTabsComponent from "../../../components/CustomTabs/CustomTabsComponent";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getAllSites } from "../../../_services/SiteServices/SiteServices";
import IncidentInfoTab from "./components/ReportForm/IncidentInfoTab";
import { getAllActivity } from "../../../_services/_master/Activity/ActivityService";
import { getDepartment } from "../../../_services/UserManagement/DepartmentService";
import { getAllAreaOfOperation } from "../../../_services/_master/Activity/ehs/ehsAreaOfOperationServices";
import GeneralInfoTab from "./components/ReportForm/GeneralInfoTab";
import toastr from "toastr";
import {
  CommmonService,
  filterOptionsOnStatusBasis,
} from "../../../_services/CommonService/CommonService";
import { UserService } from "../../../_services/UserService/UserService";
import {
  IS_MOBILE,
  IS_USER_SITE_MINES,
  NUMBER_BY_TRUE_FALSE,
  TRUE_FALSE_BY_NUMBER,
  auth_user,
  capitalizeName,
  isAfterHoursMinutes,
} from "../../../_helpers/helper";
import { Validation } from "../../../_helpers/validation/Validation";
import {
  createIncidentReport,
  getAllMasterDropdownViolationsLeading,
  getIncidentReportById,
  updateIncidentReport,
} from "../../../services/_safetyManagement/incidentServices";
import { groupBy } from "lodash";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import {
  getAllContractor,
  getAllContractorByNit,
} from "../../../services/_contractorPerformance/contractorPerformance";
import { statusListObj } from "../DATA";
import { getAllMasterDropdownDesignation } from "../../../services/_reasonList/reasonList";
import {
  getHemmAndMachineSerialList,
  getHemmDataById,
} from "../../../services/_fuelDispense/fuelDispense";
let PersonnelDetailsTab;
let EquipmentDetailsTab;
let VehicleDetailsTab;
let IncidentEvidenceTab;
if (IS_MOBILE()) {
  PersonnelDetailsTab =
    require("./components/mobile/ReportForm/PersonnelDetailsTab").default;
  EquipmentDetailsTab =
    require("./components/mobile/ReportForm/EquipmentDetailsTab").default;
  VehicleDetailsTab =
    require("./components/mobile/ReportForm/VehicleDetailsTab").default;
  IncidentEvidenceTab =
    require("./components/mobile/ReportForm/IncidentEvidenceTab").default;
} else {
  PersonnelDetailsTab =
    require("./components/ReportForm/PersonnelDetailsTab").default;
  EquipmentDetailsTab =
    require("./components/ReportForm/EquipmentDetailsTab").default;
  VehicleDetailsTab =
    require("./components/ReportForm/VehicleDetailsTab").default;
  IncidentEvidenceTab =
    require("./components/ReportForm/IncidentEvidenceTab").default;
}

const checkForLt = (personalDetails) => {
  // const checkLtiHours = personalDetails?.find((d) => {
  //   return +d?.ltiHours > 0 && !d?.deleted;
  // });
  // if (!checkLtiHours) {
  //   return "Atleast 1 LTI Hour record Should Be Greater Than 0 Hour for LTI incident type";
  // }
};

const IncidentReport = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const [form, setForm] = useState({
    ...initialForm,
    peopleCount: 1,
    number_of_injuries: 0,
    number_of_fatality: 0,
    incident_datetime: moment().format("YYYY-MM-DD HH:mm"),
    incident_reportdatetime: moment().format("YYYY-MM-DD HH:mm"),
    staffId: auth_user()?.id || null,
  });
  const [errorForm, setErrorForm] = useState({
    ...initialForm,
  });
  const [saving, setSaving] = useState(false);
  const [sites, setSites] = useState([]);
  const [area, setArea] = useState([]);
  const [subArea, setSubArea] = useState([]);
  const [activity, setActivity] = useState([]);
  const [subActivity, setSubActivity] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [contractorsStaff, setContractorsStaff] = useState([]);
  const [otherSubArea, setOtherSubArea] = useState();
  const [assetDetails, setAssetDetails] = useState([
    JSON.parse(JSON.stringify(initialAssetDetails)),
  ]);
  const [matDetails, setMatDetails] = useState(matDetailsList);
  const [assetDetailsErrors, setAssetDetailsErrors] = useState([]);
  const [personalDetails, setPersonalDetails] = useState([
    JSON.parse(JSON.stringify(initialPersonalDetails)),
  ]);
  const [personalDetailsErrors, setPersonalDetailsErrors] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([
    JSON.parse(JSON.stringify(initialVehicleDetails)),
  ]);
  const [vehicleDetailsErrors, setVehicleDetailsErrors] = useState([]);
  const [evidences, setEvidences] = useState([
    JSON.parse(JSON.stringify(initialEvidence)),
  ]);
  const [usersList, setUsersList] = useState([]);
  const [evidencesErrors, setEvidencesErrors] = useState([]);
  const [disableEmployer, setDisableEmployer] = useState(true);
  const [isStaffVisible, setIsStaffVisible] = useState(false);
  const [isStaffTextBox, setIsStaffTextBox] = useState(false);
  const [showSubAreaText, setShowSubAreaTex] = useState(false);
  const [tabComplete, setTabComplete] = useState(null);
  const [personnelDetailsTabComplete, setPersonnelDetailsTabComplete] =
    useState(null);
  const [equipmentDetailsTabComplete, setEquipmentDetailsTabComplete] =
    useState(null);
  const [vehicleDetailsTabComplete, setVehicleDetailsTabComplete] =
    useState(null);
  const [evidenceTabComplete, setEvidenceTabComplete] = useState(null);
  const [subsidaryName, setSubsidaryName] = useState("");
  const [violationsLeadingList, setViolationsLeadingList] = useState([]);
  const [designationList, setDesignationList] = useState([]);

  const notAppObj = {
    id: "Not Applicable",
    name: "Not Applicable",
  };

  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj = sites?.find((d) => d.id === auth_user()?.siteId) || {};
    setForm((prev) => ({
      ...prev,
      siteId: siteObj?.id,
      subsidaryId: siteObj?.parentSite?.id,
      roName: siteObj?.parentSite?.name,
    }));
    setSubsidaryName(siteObj?.parentSite?.name || "");
  }, [sites]);

  useEffect(() => {
    if (IS_USER_SITE_MINES()) return;
    const siteObj = sites?.find((d) => d.id === form?.siteId) || {};
    setSubsidaryName(siteObj?.parentSite?.name || "");
  }, [form?.siteId, sites]);

  const errorObjByTab = useCallback(
    (data) => {
      const gData = groupBy(data, "tab");
      const obj = {};
      for (let key in gData) {
        key = +key;
        const element = gData[key];
        obj[key] = Object.values(Validation(form, element)).filter(
          (d) => d !== ""
        ).length
          ? false
          : true;
      }

      return obj;
    },
    [form]
  );

  const finalValidIncidentRules = useCallback(() => {
    let finalIncidentReportRules = incidentReportRules;
    if (form.staffType === "others") {
      finalIncidentReportRules.push(otherstaffNameRule);
      finalIncidentReportRules = finalIncidentReportRules?.filter(
        (d) =>
          d?.field_name !== "staffName" && d?.field_name !== "staffEmployer"
      );
    } else if (form.staffType === "users") {
      finalIncidentReportRules.push(staffNameRule);
      finalIncidentReportRules.push(staffEmployerRule);
      finalIncidentReportRules = finalIncidentReportRules?.filter(
        (d) => d?.field_name !== "otherStaffName"
      );
    } else if (form.staffType === "contractor") {
      finalIncidentReportRules.push(otherstaffNameRule);
      finalIncidentReportRules.push(staffEmployerRule);
      finalIncidentReportRules = finalIncidentReportRules?.filter(
        (d) => d?.field_name !== "staffName"
      );
    } else if (form.staffType === "Not Applicable") {
      finalIncidentReportRules = finalIncidentReportRules?.filter(
        (d) =>
          d?.field_name !== "staffName" &&
          d?.field_name !== "staffEmployer" &&
          d?.field_name !== "otherStaffName"
      );
    }

    if (form.subAreaId == parseInt(999) && form.otherSubArea === "") {
      finalIncidentReportRules.push(otherSubAreaValidation);
    } else
      finalIncidentReportRules = finalIncidentReportRules?.filter(
        (d) => d?.field_name !== "otherSubArea"
      );

    if (form.category === "others") {
      finalIncidentReportRules.push(otherCategoryRule);
    } else {
      finalIncidentReportRules = finalIncidentReportRules?.filter(
        (d) => d?.field_name !== "category_other"
      );
    }
    if (form.othersInvolved) {
      !finalIncidentReportRules.find((d) => d?.field_name === "othersInfo") &&
        finalIncidentReportRules.push(othersInfoRule);
    } else {
      finalIncidentReportRules = finalIncidentReportRules?.filter(
        (d) => d?.field_name !== "othersInfo"
      );
    }
    if (form?.isPersonalInjury) {
      finalIncidentReportRules.push(injuriesRule);
      finalIncidentReportRules.push(fatalityRule);
      finalIncidentReportRules.push(placeOfIncidentRule);
    } else {
      finalIncidentReportRules = finalIncidentReportRules?.filter(
        (d) =>
          d?.field_name !== "number_of_injuries" &&
          d?.field_name !== "number_of_fatality" &&
          d?.field_name !== "place_of_incident"
      );
    }
    return finalIncidentReportRules;
  }, [
    form.category,
    form?.isPersonalInjury,
    form.otherSubArea,
    form.othersInvolved,
    form.staffType,
    form.subAreaId,
  ]);

  const checkTabValidation = useCallback(() => {
    const finalIncidentReportRules = finalValidIncidentRules();

    const obj = errorObjByTab(finalIncidentReportRules);

    if (
      form.isPersonalInjury &&
      (form.peopleCount < 1 || !personalDetails.length || !obj["3"])
    )
      obj["3"] = false;
    else obj["3"] = true;

    if (form.isAssetIncident && !assetDetails.length) obj["4"] = false;
    else obj["4"] = true;

    if (form.isVehicleIncident && !vehicleDetails.length) obj["5"] = false;
    else obj["5"] = true;

    if (form.reportEvidencesRequired && !evidences.length) obj["6"] = false;
    else obj["6"] = true;

    setTabComplete(obj);
  }, [
    assetDetails.length,
    errorObjByTab,
    evidences.length,
    finalValidIncidentRules,
    form.isAssetIncident,
    form.isPersonalInjury,
    form.isVehicleIncident,
    form.peopleCount,
    form.reportEvidencesRequired,
    personalDetails.length,
    vehicleDetails.length,
  ]);

  useEffect(() => {
    checkTabValidation();
  }, [checkTabValidation]);

  const errorObjPersonnelDetailsTab = useCallback(() => {
    let checkTabError = true;
    const personalDetailsErrorArray = form.isPersonalInjury
      ? personalDetails.map((data) =>
          data.deleted ? {} : Validation(data, personalDetailsRules)
        )
      : [];

    for (const array of [personalDetailsErrorArray])
      for (const errObj of array)
        for (const key in errObj)
          if (errObj[key]) {
            checkTabError = false;
          }
    setPersonnelDetailsTabComplete(checkTabError);
  }, [form.isPersonalInjury, personalDetails]);

  useEffect(() => {
    errorObjPersonnelDetailsTab();
  }, [errorObjPersonnelDetailsTab]);

  const errorObjEquipmentDetailsTab = useCallback(() => {
    let checkTabError = true;
    const assetDetailsErrorArray = form.isAssetIncident
      ? assetDetails.map((data) =>
          data.deleted ? {} : Validation(data, assetDetailsRules)
        )
      : [];

    for (const array of [assetDetailsErrorArray])
      for (const errObj of array)
        for (const key in errObj)
          if (errObj[key]) {
            checkTabError = false;
          }
    setEquipmentDetailsTabComplete(checkTabError);
  }, [assetDetails, form.isAssetIncident]);

  useEffect(() => {
    errorObjEquipmentDetailsTab();
  }, [errorObjEquipmentDetailsTab]);

  const errorObjVehicleDetailsTab = useCallback(() => {
    let checkTabError = true;
    const vehicleDetailsErrorArray = form.isVehicleIncident
      ? vehicleDetails.map((data) =>
          data.deleted ? {} : Validation(data, vehicleDetailsRules)
        )
      : [];

    for (const array of [vehicleDetailsErrorArray])
      for (const errObj of array)
        for (const key in errObj)
          if (errObj[key]) {
            checkTabError = false;
          }
    setVehicleDetailsTabComplete(checkTabError);
  }, [form.isVehicleIncident, vehicleDetails]);

  useEffect(() => {
    errorObjVehicleDetailsTab();
  }, [errorObjVehicleDetailsTab]);

  const errorObjEvidenceTab = useCallback(() => {
    let checkTabError = true;
    const evidencesErrorArray = form.reportEvidencesRequired
      ? evidences.map((data) =>
          data.deleted ? {} : Validation(data, evidenceRules)
        )
      : [];

    for (const array of [evidencesErrorArray])
      for (const errObj of array)
        for (const key in errObj)
          if (errObj[key]) {
            checkTabError = false;
          }
    setEvidenceTabComplete(checkTabError);
  }, [evidences, form.reportEvidencesRequired]);

  useEffect(() => {
    errorObjEvidenceTab();
  }, [errorObjEvidenceTab]);

  useEffect(() => {
    if (parseInt(form.subAreaId) === 999) {
      setShowSubAreaTex(true);
    } else {
      setShowSubAreaTex(false);
    }
  }, [form.subAreaId]);
  useEffect(() => {
    // Conditions
    if (form.staffType === "contractor") {
      setIsStaffVisible(true);
      setIsStaffTextBox(false);
    } else if (form.staffType === "employee") {
      setIsStaffVisible(true);
      setIsStaffTextBox(false);
    } else if (form.staffType === "others") {
      setIsStaffTextBox(true);
      setIsStaffVisible(false);
    } else {
      setIsStaffVisible(true);
      setIsStaffTextBox(false);
    }
  }, [form.staffType]);

  const allContractor = async () => {
    const response = await getAllContractorByNit();
    if (!response) return;
    const otherObj = { id: "Others", contractor: "Others" };
    setContractorsStaff([...response, otherObj]);
  };
  async function fetchData() {
    const response5 = await UserService.getUsers("filter[status]=1");
    setUsersList(
      response5?.data?.map((d, i) => ({
        ...d,
        name_email_employeeId: `${capitalizeName(
          d.firstName + " " + d.lastName
        )} / ${d.email}`,
      }))
    );

    allContractor();

    const response1 = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );
    setSites(response1?.data);

    const resAooIs = await getAllAreaOfOperation();
    const response2 = filterOptionsOnStatusBasis(resAooIs?.data);
    let formatPlaceName = response2?.map((d) => {
      return {
        ...d,
        name: d?.code + " - " + d?.name,
      };
    });

    let formatWorkingName = formatPlaceName?.map((data) => {
      let updatedName = data.subAreaOfOperation.map((d) => {
        return {
          ...d,
          name: d.code + " - " + d?.name,
        };
      });
      return {
        ...data,
        subAreaOfOperation: updatedName,
      };
    });

    setArea(formatWorkingName);

    const resIs = await getAllActivity();
    const response3 = filterOptionsOnStatusBasis(resIs?.data);
    const activityList =
      (Array.isArray(response3) && [...response3, notAppObj]) || [];
    setActivity(activityList);

    // const response4 = await getDepartment("filter[status]=1");
    // setDepartments(response4?.data?.departments);

    const res6Is = await getAllMasterDropdownViolationsLeading();
    const response6 = filterOptionsOnStatusBasis(res6Is?.data);
    let updatedViolation = response6?.map((d) => {
      return {
        ...d,
        name: d?.code + " - " + d?.name,
      };
    });
    setViolationsLeadingList(updatedViolation);

    const res7Is = await getAllMasterDropdownDesignation();
    const response7 = filterOptionsOnStatusBasis(res7Is?.data);
    let updatedDesignation = response7?.map((d) => {
      return {
        ...d,
        name: d?.code + " - " + d?.name,
      };
    });

    setDesignationList(updatedDesignation);
  }
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchUserSiteList() {
    const response = await UserService.getUsers(
      `filter[status]=1&site_id=${form?.siteId}`
    );
    const userList = response?.data?.map((user) => ({
      ...user,
      name_email_employeeId: `${capitalizeName(
        user.firstName + " " + user.lastName
      )} / ${user.email}`,
    }));
    setUsersList(userList);
  }

  useEffect(() => {
    if (!form.siteId) return;
    fetchUserSiteList();
  }, [form.siteId]);
  const onChange = ({ target: { name, type, value, checked } }) => {
    if (type === "checkbox" && !checked)
      switch (name) {
        case "reportEvidencesRequired":
          if (evidences?.filter((data) => !data.deleted).length)
            return toastr.warning("Please delete all rows first!");
          break;
        case "isPersonalInjury":
          if (personalDetails?.filter((data) => !data.deleted).length)
            return toastr.warning("Please delete all rows first!");
          break;
        case "isAssetIncident":
          if (assetDetails?.filter((data) => !data.deleted).length)
            return toastr.warning("Please delete all rows first!");
          break;
        case "isVehicleIncident":
          if (vehicleDetails?.filter((data) => !data.deleted).length)
            return toastr.warning("Please delete all rows first!");
          break;

        default:
          break;
      }

    setForm((prevForm) => {
      const updates = {
        ...prevForm,
        [name]: type === "checkbox" ? checked : value,
      };

      if (name === "isPersonalInjury" && !checked) updates.peopleCount = 0;
      if (name === "siteId") {
        const selected = sites?.find((site) => site?.id === +value);
        if (selected) {
          if (selected.siteType === 3) {
            updates.subsidaryId = selected.parentSite?.id;
            updates.roName = selected.parentSite?.name;
          } else if (selected.siteType === 2) {
            updates.subsidaryId = selected.id;
            updates.roName = selected.name;
          } else {
            updates.subsidaryId = "";
            updates.roName = "";
          }
        } else {
          updates.subsidaryId = "";
          updates.roName = "";
        }
      }

      if (name === "peopleCount") {
        updates.number_of_injuries = 0;
        updates.number_of_fatality = 0;
      }

      // Unset severity on type change
      if (name === "category") {
        updates.severity = "";
        updates.number_of_fatality = 0;
        updates.is_lti = value === incidentTypesObjKeys?.lti ? true : false;
      }

      if (name === "staffType") {
        if (value === objStaffType.users) {
          updates.staffEmployer = subsidaryName;
          updates.staffName = "";
        } else {
          updates.staffEmployer = "";
          updates.staffName = "";
        }
      }
      if (name === "activityId") {
        if (value === notAppObj.id) {
          updates.subActivityId = notAppObj.id;
        } else {
          updates.subActivityId = "";
        }
      }

      return updates;
    });
    changeDependentDropdown(name, value);
  };

  console.log("checkFOmr", form);

  const changeDependentDropdown = (name, value) => {
    let filterData = [];
    // eslint-disable-next-line default-case
    switch (name) {
      case "areaId":
        setSubArea(filterData);
        if (area.length) {
          filterData = area.find((x) => x.id === value)?.subAreaOfOperation;
          filterData?.filter((x) => x.id === 999);

          let subAreaData = filterData ? [...filterData] : [];
          if (subAreaData.length) {
            setSubArea(subAreaData);
          }

          // Unset SubArea Values;
          if (form.areaId !== value) {
            setForm((prevForm) => ({
              ...prevForm,
              subAreaId: "",
            }));
          }
        }
        break;
      case "activityId":
        setSubActivity([...filterData, notAppObj]);
        if (activity?.length) {
          filterData = activity.find((x) => x.id === value)?.subActivity;
          if (filterData?.length) {
            setSubActivity([...filterData, notAppObj]);
          }
        }
        break;
    }
  };

  const onChangeDate = (momentDate, name) => {
    if (typeof momentDate === "string") return;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: momentDate.format("YYYY-MM-DD HH:mm:ss"),
    }));
  };

  // console.log("form", form);

  /************************* EquipmentDetailsTab **********************/
  // ASSET DETAILS FUNCTION(s)
  const addRowAssetDetails = () => {
    if (!form.isAssetIncident)
      return toastr.warning("Please enable 'Equipment Details' first!");
    setAssetDetails((prev) => [
      ...prev,
      JSON.parse(JSON.stringify(initialAssetDetails)),
    ]);
  };

  const onChangeAssetDetails = async (index, name, value) => {
    console.log("index--------name-------value", index, name, value);

    if (name === "sku") {
      let hemmData = await getHemmDataById(value);
      if (hemmData.status) {
        let hemmDataFound = hemmData.data;

        //set name
        setAssetDetails((prev) =>
          prev.map((d, idx) =>
            idx !== index ? d : { ...d, name: hemmDataFound.fms_vehicle_name }
          )
        );

        //set type
        setAssetDetails((prev) =>
          prev.map((d, idx) =>
            idx !== index ? d : { ...d, type: hemmDataFound.fms_vehicle_type }
          )
        );

        //set hemm key
        setAssetDetails((prev) =>
          prev.map((d, idx) =>
            idx !== index ? d : { ...d, hemm_id: hemmDataFound.id }
          )
        );
      } else {
        //set name
        setAssetDetails((prev) =>
          prev.map((d, idx) => (idx !== index ? d : { ...d, name: "" }))
        );

        //set type
        setAssetDetails((prev) =>
          prev.map((d, idx) => (idx !== index ? d : { ...d, type: "" }))
        );

        //set hemm key
        setAssetDetails((prev) =>
          prev.map((d, idx) => (idx !== index ? d : { ...d, hemm_id: null }))
        );
      }
    }

    setAssetDetails((prev) =>
      prev.map((d, idx) => (idx !== index ? d : { ...d, [name]: value }))
    );
  };

  const deleteRowAssetDetails = (index) => {
    setAssetDetails((prev) => {
      if (prev[index].id) prev[index].deleted = 1;
      else prev.splice(index, 1);

      return [...prev];
    });
  };

  /************************* PersonnelDetailsTab **********************/
  // PERSONAL DETAILS FUNCTION(s)
  const addRowPersonalDetails = () => {
    if (!form.isPersonalInjury)
      return toastr.warning("Please enable 'Personal Injury' first!");
    setPersonalDetails((prev) => [
      ...prev,
      JSON.parse(JSON.stringify(initialPersonalDetails)),
    ]);
  };

  const onChangePersonalDetails = (index, name, value) => {
    // console.log("checkOnchange", name, value);
    setPersonalDetails((prev) =>
      prev.map((d, idx) => {
        if (idx !== index) return d;

        d[name] = value;
        if (name === "type") {
          if (value === "users") {
            d.employer = subsidaryName;
          } else if (value === "others") {
            d.employer = "Not Applicable";
          } else d.employer = "";
          d.name = "";
          d.userId = null;
        }

        if (
          name === "bodyParts" &&
          value?.split(",")?.includes("notApplicable")
        ) {
          d.bodyParts = "notApplicable";
        }

        if (name === "outcome") {
          d.ltiHours = 0;
        }

        return d;
      })
    );
  };

  // VEHICLE DETAILS FUNCTION(s)
  const addRowVehicleDetails = () => {
    if (!form.isVehicleIncident)
      return toastr.warning("Please enable 'Vehicle Details' first!");
    setVehicleDetails((prev) => [
      ...prev,
      JSON.parse(JSON.stringify(initialVehicleDetails)),
    ]);
  };

  const onChangeVehicleDetails = (index, name, value) => {
    setVehicleDetails((prev) =>
      prev.map((d, idx) => (idx !== index ? d : { ...d, [name]: value }))
    );
  };

  const deleteRowVehicleDetails = (index) => {
    setVehicleDetails((prev) => {
      if (prev[index].id) prev[index].deleted = 1;
      else prev.splice(index, 1);

      return [...prev];
    });
  };

  const onChangePersonalDetailsEmployee = (index, userId) => {
    setPersonalDetails((prev) =>
      prev.map((d, idx) => {
        const userObj = usersList.find((o) => +o.id === +userId) || {};
        return idx !== index
          ? d
          : {
              ...d,
              name: userObj?.name_email_employeeId || userId,
              // eslint-disable-next-line no-undef
              userId: _.isEmpty(userObj) ? null : userId,
              //phone,
              age: userObj?.dob ? CommmonService.getAge(userObj?.dob) : "",
            };
      })
    );
  };

  const deleteRowPersonalDetails = (index) => {
    setPersonalDetails((prev) => {
      if (prev[index].id) prev[index].deleted = 1;
      else prev.splice(index, 1);

      return [...prev];
    });
  };

  /************************* IncidentEvidenceTab **********************/
  // EVIDENCE FUNCTION(s)
  const addEvidence = () =>
    setEvidences((previous) => [
      ...previous,
      JSON.parse(JSON.stringify(initialEvidence)),
    ]);

  const onChangeEvidence = ({ name, value, type, checked, index }) => {
    setEvidences((prevEvidences) =>
      prevEvidences.map((data, idx) => {
        if (idx !== index) return data;

        data[name] = type === "checkbox" ? checked : value;
        return data;
      })
    );
  };

  const onChangeEvidenceFile = ({ responseData, index }) => {
    setEvidences((prevEvidences) =>
      prevEvidences.map((data, idx) => {
        if (idx !== index) return data;

        data.file = responseData;
        data.fileId = responseData.id;

        return data;
      })
    );
  };

  const deleteEvidence = (index) =>
    setEvidences((previous) =>
      previous
        .map((data, idx) =>
          idx !== index ? data : data.id ? { ...data, deleted: 1 } : null
        )
        .filter((data) => !!data)
    );

  // console.log("fomr++++++", form);
  // console.log("personalDetails", personalDetails);

  const onSubmit = useCallback(
    async (
      // incidentStatus = incidentStatuses.draft,
      incidentStatus = null,
      validate = true,
      dontGoBack = false
    ) => {
      if (saving) return;
      setSaving(true);

      // const toast = toastr.warning("Saving, please wait...");

      let flag = 0;
      let capaFlag = 0;
      let finalIncidentReportRules = incidentReportRules;

      if (form.staffType === "others") {
        finalIncidentReportRules.push(otherstaffNameRule);
        finalIncidentReportRules = finalIncidentReportRules?.filter(
          (d) =>
            d?.field_name !== "staffName" && d?.field_name !== "staffEmployer"
        );
      } else if (form.staffType === "users") {
        finalIncidentReportRules.push(staffNameRule);
        finalIncidentReportRules.push(staffEmployerRule);
        finalIncidentReportRules = finalIncidentReportRules?.filter(
          (d) => d?.field_name !== "otherStaffName"
        );
      } else if (form.staffType === "contractor") {
        finalIncidentReportRules.push(otherstaffNameRule);
        finalIncidentReportRules.push(staffEmployerRule);
        finalIncidentReportRules = finalIncidentReportRules?.filter(
          (d) => d?.field_name !== "staffName"
        );
      } else if (form.staffType === "Not Applicable") {
        finalIncidentReportRules = finalIncidentReportRules?.filter(
          (d) =>
            d?.field_name !== "staffName" &&
            d?.field_name !== "staffEmployer" &&
            d?.field_name !== "otherStaffName"
        );
      }

      if (form.subAreaId == parseInt(999) && form.otherSubArea == "") {
        finalIncidentReportRules.push(otherSubAreaValidation);
      }
      if (form.category === "others") {
        finalIncidentReportRules.push(otherCategoryRule);
      } else {
        finalIncidentReportRules = finalIncidentReportRules?.filter(
          (d) => d?.field_name !== "category_other"
        );
      }
      if (form.othersInvolved) {
        !finalIncidentReportRules.find((d) => d?.field_name === "othersInfo") &&
          finalIncidentReportRules.push(othersInfoRule);
      } else {
        finalIncidentReportRules = finalIncidentReportRules?.filter(
          (d) => d?.field_name !== "othersInfo"
        );
      }
      if (form?.isPersonalInjury) {
        finalIncidentReportRules.push(injuriesRule);
        finalIncidentReportRules.push(fatalityRule);
        finalIncidentReportRules.push(placeOfIncidentRule);
      } else {
        finalIncidentReportRules = finalIncidentReportRules?.filter(
          (d) =>
            d?.field_name !== "number_of_injuries" &&
            d?.field_name !== "number_of_fatality" &&
            d?.field_name !== "place_of_incident"
        );
      }

      const errorObj = Validation(form, finalIncidentReportRules);

      const personalDetailsErrorArray = form.isPersonalInjury
        ? personalDetails.map((data) =>
            data.deleted ? {} : Validation(data, personalDetailsRules)
          )
        : [];

      const assetDetailsErrorArray = form.isAssetIncident
        ? assetDetails.map((data) =>
            data.deleted ? {} : Validation(data, assetDetailsRules)
          )
        : [];

      const vehicleDetailsErrorArray = form.isVehicleIncident
        ? vehicleDetails.map((data) =>
            data.deleted ? {} : Validation(data, vehicleDetailsRules)
          )
        : [];

      const evidencesErrorArray = form.reportEvidencesRequired
        ? evidences.map((data) =>
            data.deleted ? {} : Validation(data, evidenceRules)
          )
        : [];

      if (form.isPersonalInjury && !personalDetails.length)
        errorObj.isPersonalInjury = "Minimum one row is required.";
      if (form.isAssetIncident && !assetDetails.length)
        errorObj.isAssetIncident = "Minimum one row is required.";
      if (form.isVehicleIncident && !vehicleDetails.length)
        errorObj.isVehicleIncident = "Minimum one row is required.";
      if (form.reportEvidencesRequired && !evidences.length)
        errorObj.reportEvidencesRequired = "Minimum one row is required.";
      if (
        new Date(form.incident_datetime).getTime() >
        new Date(form.incident_reportdatetime).getTime()
      )
        errorObj.incident_reportdatetime =
          "Report Date - Time must be later than incident Date - Time.";

      setErrorForm(errorObj);
      setPersonalDetailsErrors(personalDetailsErrorArray);
      setAssetDetailsErrors(assetDetailsErrorArray);
      setVehicleDetailsErrors(vehicleDetailsErrorArray);
      setEvidencesErrors(evidencesErrorArray);
      // setCapasErrors(capaErrorArray);

      if (
        form?.incident_datetime &&
        !isAfterHoursMinutes(form?.incident_datetime)
      ) {
        errorObj.incident_datetime = "Incident time should be past time.";
        toastr.warning("Incident time should be past time!");
        setSaving(false);
        return;
      }

      if (validate || form?.status !== statusListObj?.draft) {
        if (form.isPersonalInjury && form.peopleCount < 1)
          errorObj.peopleCount = "Please enter minimum 1 count.";

        for (const array of [
          personalDetailsErrorArray,
          assetDetailsErrorArray,
          vehicleDetailsErrorArray,
          evidencesErrorArray,
          //capaErrorArray,
        ])
          for (const errObj of array)
            for (const key in errObj)
              if (errObj[key]) {
                flag = 1;
                break;
              }
      }

      for (const key in errorObj)
        if (errorObj[key]) {
          flag = 1;
          break;
        }

      let LtFlag = 0;
      let msg = "";
      if (form.category === "lti") {
        msg = checkForLt(personalDetails);
        if (msg) {
          LtFlag = 1;
        }
      }

      if (flag !== 0) {
        // toastr.clear(toast);
        toastr.warning(
          "Incident inputs missing. Please complete applicable sections!"
        );
        if (LtFlag !== 0) {
          toastr.error(msg);
        }

        setSaving(false);
        return;
      } else {
        if (LtFlag !== 0) {
          toastr.error(msg);
          setSaving(false);
          return;
        }
      }

      if (
        form.isPersonalInjury &&
        +form?.peopleCount < +personalDetails?.filter((d) => !d?.deleted).length
      ) {
        toastr.error(
          "Personnel Details row should be not greater than from Number of people involved"
        );
        setSaving(false);
        return;
      }

      const documentData = evidences;
      const finalDocument = form.reportEvidencesRequired
        ? documentData?.map((obj) => ({
            document_id: obj?.fileId,
            document_type: obj?.type,
            id: obj?.id || null,
            deleted: +obj?.deleted || 0,
          }))
        : [];

      const formData = {
        ...form,
        status: !incidentStatus
          ? form?.status !== statusListObj?.draft
            ? form?.status
            : incidentStatus
          : incidentStatus,
        personalDetails: form.isPersonalInjury
          ? personalDetails?.map((d) => ({
              ...d,
              imeDate: d?.imeDate ? d?.imeDate : null,
              pmeDate: d?.pmeDate ? d?.pmeDate : null,
              vtcDate: d?.vtcDate ? d?.vtcDate : null,
              hoursAtWork: d?.hoursAtWork ? d?.hoursAtWork : null,
              outcome: d?.outcome ? d?.outcome : null,
              injuryCode: d?.injuryCode ? d?.injuryCode : null,
            }))
          : [],
        assetDetails: form.isAssetIncident ? assetDetails : [],
        vehicleDetails: form.isVehicleIncident ? vehicleDetails : [],
        evidences: form.reportEvidencesRequired ? evidences : [],
        documentIds: finalDocument,
        lti_count: NUMBER_BY_TRUE_FALSE(form?.is_lti),
        is_lti: form?.is_lti ? "1" : "0",

        fireInvolved: NUMBER_BY_TRUE_FALSE(form?.fireInvolved),
        electricShock: NUMBER_BY_TRUE_FALSE(form?.electricShock),
        lighting: NUMBER_BY_TRUE_FALSE(form?.lighting),
        movingEquipmet: NUMBER_BY_TRUE_FALSE(form?.movingEquipmet),
        explosion: NUMBER_BY_TRUE_FALSE(form?.explosion),
        fallFromHeight: NUMBER_BY_TRUE_FALSE(form?.fallFromHeight),
        vehicleCollision: NUMBER_BY_TRUE_FALSE(form?.vehicleCollision),
        fallOfObject: NUMBER_BY_TRUE_FALSE(form?.fallOfObject),
        inundation: NUMBER_BY_TRUE_FALSE(form?.inundation),
        othersInvolved: NUMBER_BY_TRUE_FALSE(form?.othersInvolved),
        isPersonalInjury: NUMBER_BY_TRUE_FALSE(form?.isPersonalInjury),
        isAssetIncident: NUMBER_BY_TRUE_FALSE(form?.isAssetIncident),
        isVehicleIncident: NUMBER_BY_TRUE_FALSE(form?.isVehicleIncident),
        reportEvidencesRequired: NUMBER_BY_TRUE_FALSE(
          form?.reportEvidencesRequired
        ),
        costOfAccident: form?.costOfAccident || null,
        dateOfImmediate: form?.dateOfImmediate || null,
        immediateLastAccident: form?.immediateLastAccident || null,
        briefDescription: form?.briefDescription || null,
        activityId: form?.activityId === notAppObj.id ? null : form?.activityId,
        subActivityId:
          form?.subActivityId === notAppObj.id ? null : form?.subActivityId,
        // capas,

        number_of_injuries: form?.number_of_injuries || 0,
        number_of_fatality: form?.number_of_fatality || 0,
        othersInfo: form?.othersInfo || null,
      };

      if (
        form.category === "fatality" &&
        personalDetails.filter((item) => item.outcome === "Fatality").length ===
          0
      ) {
        toastr.error(
          "One outcome from personnel details must be Fatality for incident type Fatal"
        );
        setSaving(false);
        return;
      }

      const response = form?.id
        ? await updateIncidentReport(formData, form?.id)
        : await createIncidentReport(formData);

      if (!response?.status || response?.status === 401) {
        !response?.status && toastr.error(response?.message);
        setSaving(false);
        return;
      }

      toastr.success(response?.message);

      setForm((prev) => ({ ...prev, id: +response.data.id }));

      setPersonalDetails((prev) =>
        prev.map((d, i) => {
          if (d.id) return d;
          d.id = response.data.personalDetails?.[i]?.id || null;
          return d;
        })
      );
      setAssetDetails((prev) =>
        prev.map((d, i) => {
          if (d.id) return d;
          d.id = response.data.assetDetails?.[i]?.id || null;
          return d;
        })
      );
      setVehicleDetails((prev) =>
        prev.map((d, i) => {
          if (d.id) return d;
          d.id = response.data.vehicleDetails?.[i]?.id || null;
          return d;
        })
      );

      setEvidences((prev) =>
        prev.map((d, i) => {
          if (d.id) return d;
          d.id = response.data.evidences?.[i]?.id || null;
          return d;
        })
      );

      setSaving(false);

      if (dontGoBack) return;

      !incidentStatus
        ? navigate(-1)
        : navigate(
            (IS_MOBILE() ? `/mobile` : "") +
              "/safety-management-system/incident-management/my-incidents"
          );
    },
    [
      assetDetails,
      evidences,
      form,
      // history,
      params.id,
      personalDetails,
      vehicleDetails,
      // capas,
      saving,
    ]
  );

  const finalIncidentTabList = useMemo(() => {
    if (!tabComplete) return incidentTabList;
    return incidentTabList?.map((d) => {
      if (d.tab <= 2) return { ...d, tabStatus: tabComplete[d.tab.toString()] };
      if (d.tab === 3)
        return {
          ...d,
          tabStatus: !!(
            tabComplete[d.tab.toString()] && personnelDetailsTabComplete
          ),
        };
      if (d.tab === 4)
        return {
          ...d,
          tabStatus: !!(
            tabComplete[d.tab.toString()] && equipmentDetailsTabComplete
          ),
        };
      if (d.tab === 5)
        return {
          ...d,
          tabStatus: !!(
            tabComplete[d.tab.toString()] && vehicleDetailsTabComplete
          ),
        };
      if (d.tab === 6)
        return {
          ...d,
          tabStatus: !!(tabComplete[d.tab.toString()] && evidenceTabComplete),
        };
    });
  }, [
    equipmentDetailsTabComplete,
    evidenceTabComplete,
    personnelDetailsTabComplete,
    tabComplete,
    vehicleDetailsTabComplete,
  ]);

  useEffect(() => {
    if (!params.id) return;

    getIncidentReportById(params.id).then((response) => {
      if (!response?.data) return;

      const {
        personalDetails,
        vehicleDetails,
        assetDetails,
        // spills,
        // evidences,
        incidentMedias,
        staffType,
        capas,
        fatalityCategory,
        ...form
      } = response.data;

      const findActivity = activity.find(
        (x) => x.id === +response?.data?.activityId && x.status
      );
      const findArea = area.find(
        (x) => x.id === +response?.data?.areaId && x.status
      );
      const findViolationsLeading = violationsLeadingList.find(
        (x) => x.id === +response?.data?.violationsLeading && x.status
      );
      const findDesignation = designationList.find(
        (x) => x.id === +response?.data?.designation && x.status
      );

      const subAreaFilter = findArea?.subAreaOfOperation || [];
      setSubArea(subAreaFilter);

      const subActivityFilter = findActivity?.subActivity || [];
      setSubActivity([...subActivityFilter, notAppObj]);

      setForm((prev) => {
        return {
          ...prev,
          ...form,
          fatalityCategory: fatalityCategory === "Statistical" ? true : false,
          subsidaryId: response?.data?.site?.parentSite?.id,
          roName: response?.data?.site?.parentSite?.name,
          siteId: response?.data?.site_id,
          isPersonalInjury: TRUE_FALSE_BY_NUMBER(
            response.data?.isPersonalInjury
          ),
          isAssetIncident: TRUE_FALSE_BY_NUMBER(response.data?.isAssetIncident),
          isVehicleIncident: TRUE_FALSE_BY_NUMBER(
            response.data?.isVehicleIncident
          ),
          reportEvidencesRequired: TRUE_FALSE_BY_NUMBER(
            response.data?.reportEvidencesRequired
          ),
          staffType: staffType,
          activityId:
            response.data?.activityId === null
              ? notAppObj.id
              : findActivity
              ? response.data?.activityId
              : "",
          subActivityId:
            response.data?.subActivityId === null
              ? notAppObj.id
              : findActivity
              ? response.data?.subActivityId
              : "",
          areaId:
            response.data?.areaId === null
              ? notAppObj.id
              : findArea
              ? response.data?.areaId
              : "",
          subAreaId:
            response.data?.subAreaId === null
              ? notAppObj.id
              : findArea
              ? response.data?.subAreaId
              : "",
          violationsLeading: findViolationsLeading
            ? response.data?.violationsLeading
            : "",
          designation: findDesignation ? response.data?.designation : "",
        };
      });

      setPersonalDetails(
        personalDetails?.length
          ? personalDetails?.map((d) => ({ ...d }))
          : [JSON.parse(JSON.stringify(initialPersonalDetails))]
      );
      setVehicleDetails(
        vehicleDetails?.length
          ? vehicleDetails
          : [JSON.parse(JSON.stringify(initialVehicleDetails))]
      );
      setAssetDetails(
        assetDetails?.length
          ? assetDetails
          : [JSON.parse(JSON.stringify(initialAssetDetails))]
      );

      setEvidences(
        incidentMedias?.length
          ? incidentMedias.map((obj) => ({
              incidentDetailsId: obj?.incident_id,
              id: obj?.id,
              type: obj?.document_type,
              description: obj?.media?.alias,
              file: obj?.media,
              fileId: obj?.document_id,
            }))
          : [JSON.parse(JSON.stringify(initialEvidence))]
      );
    });
  }, [activity, area, params.id, violationsLeadingList, designationList]);

  useEffect(() => {
    let fatalityCount = personalDetails.filter((d) => d.outcome === "Fatality");
    let injuryCount = personalDetails.filter(
      (d) => d.outcome === "Minor Injury" || d.outcome === "Serious Injury"
    );
    setForm((prev) => {
      return {
        ...prev,
        number_of_injuries: injuryCount?.length,
        number_of_fatality: fatalityCount?.length,
      };
    });
  }, [personalDetails]);

  useEffect(() => {
    if (form.category === "fatality") outcomeOpt.push("Fatality");
    else {
      for (let i = 0; i < personalDetails.length; i++) {
        if (personalDetails[i].outcome === "Fatality") {
          personalDetails.splice(i, 1);
          i--;
        }
      }

      const indexOfFatality = outcomeOpt.indexOf("Fatality");
      if (indexOfFatality !== -1) {
        outcomeOpt.splice(indexOfFatality, 1);
      }
    }
  }, [form.category]);

  return (
    <div
      className={`${
        IS_MOBILE() ? "mobileSidebarWrapper" : "wrapper sidebarWrapper"
      }`}
    >
      <SafetySideBar
        SidebarData={IS_MOBILE() ? mobileSidebarData : SidebarData}
        pageTitle={"Report Incident"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            {!IS_MOBILE() && (
              <h3
                className=""
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Safety Management",
                      path: "#",
                    },
                    {
                      name: "Incident Management",
                      path: "/safety-management-system/incident-management",
                    },
                    {
                      name: "Report Incident",
                      path: "#",
                    },
                  ]}
                  // module={ACTIVE_MODULE.incident}
                />
              </h3>
            )}
            {/* <Container fluid> */}
            <CustomTabsComponent
              tabeList={finalIncidentTabList}
              tab={tab}
              setTab={setTab}
            />
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="formWrapper">
                  <div className="maverickFormCard">
                    {tab === 1 && (
                      <IncidentInfoTab
                        form={form}
                        onChange={onChange}
                        error={errorForm}
                        onChangeDate={onChangeDate}
                        sites={sites}
                        departments={departments}
                        setTab={setTab}
                        // onSubmit={onSubmit}
                        area={area}
                        subArea={subArea}
                        activity={activity}
                        subActivity={subActivity}
                        users={usersList}
                        // employeeList={employeeList}
                        contractors={contractors}
                        disableEmployer={disableEmployer}
                        contractorsStaff={contractorsStaff}
                        otherSubArea={otherSubArea}
                        tab={tab}
                        isStaffVisible={isStaffVisible}
                        isStaffTextBox={isStaffTextBox}
                        showSubAreaText={showSubAreaText}
                        asterikRules={finalValidIncidentRules()}
                      />
                    )}
                    {tab === 2 && (
                      <GeneralInfoTab
                        form={form}
                        onChange={onChange}
                        error={errorForm}
                        setTab={setTab}
                        tab={tab}
                        violationsLeadingList={violationsLeadingList}
                        // onSubmit={onSubmit}
                        asterikRules={finalValidIncidentRules()}
                      />
                    )}

                    {tab === 3 && (
                      <PersonnelDetailsTab
                        form={form}
                        onChange={onChange}
                        error={errorForm}
                        setTab={setTab}
                        // onSubmit={onSubmit}
                        contractorsStaff={contractorsStaff}
                        personalDetails={personalDetails}
                        personalDetailsErrors={personalDetailsErrors}
                        onChangePersonalDetails={onChangePersonalDetails}
                        onChangePersonalDetailsEmployee={
                          onChangePersonalDetailsEmployee
                        }
                        deleteRowPersonalDetails={deleteRowPersonalDetails}
                        addRowPersonalDetails={addRowPersonalDetails}
                        users={usersList}
                        tab={tab}
                        designationList={designationList}
                        asterikRules={finalValidIncidentRules()}
                        personalAsterikRules={personalDetailsRules}
                        outcomeOpt={outcomeOpt}
                      />
                    )}

                    {tab === 4 && (
                      <EquipmentDetailsTab
                        form={form}
                        onChange={onChange}
                        error={errorForm}
                        setTab={setTab}
                        // onSubmit={onSubmit}
                        assetDetails={assetDetails}
                        matDetails={matDetails}
                        // onChangeAssetDetailsSku={onChangeAssetDetailsSku}
                        // onChangeAssetDetailsSkuInput={
                        //   onChangeAssetDetailsSkuInput
                        // }
                        // mats={mats}
                        assetDetailsErrors={assetDetailsErrors}
                        onChangeAssetDetails={onChangeAssetDetails}
                        deleteRowAssetDetails={deleteRowAssetDetails}
                        addRowAssetDetails={addRowAssetDetails}
                        tab={tab}
                        asterikRules={assetDetailsRules}
                      />
                    )}

                    {tab === 5 && (
                      <VehicleDetailsTab
                        form={form}
                        onChange={onChange}
                        error={errorForm}
                        setTab={setTab}
                        // onSubmit={onSubmit}
                        vehicleDetails={vehicleDetails}
                        vehicleDetailsErrors={vehicleDetailsErrors}
                        onChangeVehicleDetails={onChangeVehicleDetails}
                        deleteRowVehicleDetails={deleteRowVehicleDetails}
                        addRowVehicleDetails={addRowVehicleDetails}
                        tab={tab}
                        asterikRules={vehicleDetailsRules}
                      />
                    )}

                    {tab === 6 && (
                      <IncidentEvidenceTab
                        form={form}
                        onChange={onChange}
                        error={errorForm}
                        setTab={setTab}
                        onSubmit={onSubmit}
                        evidences={evidences}
                        evidencesErrors={evidencesErrors}
                        onChangeEvidence={onChangeEvidence}
                        onChangeEvidenceFile={onChangeEvidenceFile}
                        addEvidence={addEvidence}
                        deleteEvidence={deleteEvidence}
                        tab={tab}
                        asterikRules={evidenceRules}
                        isEdit={params.id}
                      />
                    )}
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentReport;
