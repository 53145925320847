import {
  getAllSupplier,
  getSupplierById,
} from "../../../../services/_fuelDispense/fuelDispense";
export const initialForm = {
  supplierName: "",
  supplierCode: "",
};
export const supplierLabel = {
  supplierName: "Supplier Name",
  supplierCode: "Supplier Short Name",
};
export const validationRules = [
  {
    label: supplierLabel.supplierName,
    type: "string",
    isRequired: true,
    field_name: "supplierName",
  },
  {
    label: supplierLabel.supplierCode,
    type: "string",
    isRequired: true,
    field_name: "supplierCode",
  },
];

export const supplierApi = {
  getAll: {
    api: getAllSupplier,
  },
  getById: {
    api: getSupplierById,
  },
};

export const fuelLocationColumnFields = [
  {
    name: supplierLabel.supplierName,
    selector: "supplierName",
    sortable: true,
    cell: (row) => row?.supplierName,
  },
  {
    name: supplierLabel.supplierCode,
    selector: "supplierCode",
    sortable: true,
    cell: (row) => row?.supplierCode,
  },
];
