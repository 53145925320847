import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../../Assets/Images/filter.svg";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../FuelManagement/DATA";
import {
  exportMyFuelReceipt,
  getAllFuelSensor,
  getAllTank,
} from "../../../../services/_fuelDispense/fuelDispense";
import "../../fuelmgmt.scss";
import FuelSensorView from "./FuelSensorView";
import { useForm } from "../../../../_helpers/hooks";
import { checkGivenDate } from "../../../../_services/CommonService/CommonService";
import { IS_MOBILE } from "../../../../_helpers/helper";
import {
  exportFuelSensor,
  fuelSensorApi,
  fuelSensorFields,
  fuelSensorFilterForm,
} from "./DATA";
import FuelSensorFilter from "./FuelSensorFilter";

const FuelSensorList = () => {
  const [filterStatus, setFilterStatus] = useState(0);
  const params = useParams();
  let navigate = useNavigate();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [form, onChange, setForm] = useForm(fuelSensorFilterForm);

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }
    if (params?.id) filter += `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [filterStatus]);

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    let response;
    if (params?.id)
      response = await getAllFuelSensor(
        "page=1&limit=2&createdBy=" + params?.id
      );
    else response = await getAllFuelSensor("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const viewAsPopup = (form) => {
    return <FuelSensorView form={form} />;
  };

  const onEdit = (id) => {
    if (IS_MOBILE())
      navigate(
        "/mobile/fuel-management-system/fuel-management-list/fuel-sensor/" + id
      );
    else
      navigate(
        "/fuel-management-system/fuel-management-list/fuel-sensor/" + id
      );
  };

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);
  const onResetFilter = () => {
    setForm({ fuelSensorFilterForm });
    if (!params?.id) setGetAllFilter();
    else setGetAllFilter("&createdBy=" + params?.id);
  };
  const FilterBtn = () => {
    const [open, setOpen] = useState(false);
    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <FuelSensorFilter
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      </div>
    );
  };
  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const statusByOptions = (row) => {
    if (checkGivenDate(row?.date)) {
      return true;
    } else {
      return false;
    }
  };
  const deleteCheckFn = (row) => {
    return true;
  };

  return isListData ? (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={"View Fuel Sensor"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                deleteCheck={deleteCheckFn}
                fields={fuelSensorFields}
                apis={fuelSensorApi}
                downloadExcel={exportFuelSensor}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={
                  "/fuel-management-system/fuel-management-list/fuel-sensor"
                }
                primaryKey="id"
                pagination={true}
                deleteFun
                // hideDeleteButton={false}
                hideEditButton
                hideAddButton
                getAllFilter={getAllFilter}
                breadCrumb={[
                  {
                    path: "#",
                    name: "Fuel Management System",
                  },
                  {
                    name: "Fuel Management",
                    path: "/fuel-management-system",
                  },
                  {
                    name: "Fuel Sensor List",
                    path: "#",
                  },
                ]}
                customTableTitle={"Fuel Sensor List"}
                editFunButton={onEdit}
                viewTitle={"Fuel Sensor Details"}
                isNoTabOnPage
                statusByOptions={statusByOptions}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            hideAddBtn={1}
            pageLink={
              "/fuel-management-system/fuel-management-list/fuel-sensor"
            }
            title={"Fuel Sensor List"}
          />
        </div>
      </div>
    </div>
  );
};

export default FuelSensorList;
