import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./customTabs.scss";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";

const CustomTabs = ({
  tabeList,
  hideLeftRightBtn,
  noLink,
  onClickTab,
  activeTab,
  customHeight,
  fuelCustomTab,
}) => {
  let scrl = useRef(null);
  let containerRef = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const ulWidth = scrl?.current?.offsetWidth;
  const containerWidth = containerRef?.current?.offsetWidth;

  // Calculate the percentage width
  const ulWidthPercentage = (ulWidth / containerWidth) * 100;

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <div
      className="misReportList"
      data-testid="misReportList"
      ref={containerRef}
    >
      <div
        className="miscroll approvalHistoryTabs"
        style={{ width: customHeight ? customHeight : "100%" }}
      >
        {((!hideLeftRightBtn && scrollX !== 0 && tabeList?.length >= 10) ||
          (ulWidthPercentage > 96 && scrollX !== 0) ||
          fuelCustomTab) && (
          <button className="prev">
            <IoChevronBack
              data-testid="leftSlide"
              onClick={() => slide(-100)}
            />
          </button>
        )}

        <ul className="" ref={scrl}>
          {Array.isArray(tabeList) &&
            tabeList?.map((d, i) => {
              return !noLink ? (
                <li key={i}>
                  <Link className={d.active ? "active" : ""} to={d.to}>
                    <span>{d.label}</span>
                  </Link>
                </li>
              ) : (
                <li key={i}>
                  {d?.visibalTab ? (
                    <span
                      data-testid="click-tab"
                      onClick={() => onClickTab(d.value)}
                      className={`listing ${
                        d.value === activeTab ? "active" : ""
                      }`}
                    >
                      {d.label.toUpperCase()}
                    </span>
                  ) : (
                    <span className={`listing noVisibal`}>
                      {d.label.toUpperCase()}
                    </span>
                  )}
                </li>
              );
            })}
        </ul>

        {(!hideLeftRightBtn &&
          (!scrolEnd || scrollX === 0) &&
          tabeList?.length >= 10) ||
          ((ulWidthPercentage > 96 || fuelCustomTab) &&
            (!scrolEnd || scrollX === 0) && (
              <button className="next">
                <IoChevronForward
                  data-testid="rightSlide"
                  onClick={() => slide(+100)}
                />
              </button>
            ))}
      </div>
    </div>
  );
};

export default CustomTabs;
