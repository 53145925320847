import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat, DDMMYYYYHHMMAFormat } from "../../_helpers/dateHelper";
import SamplePrepareRegisterView from "./CoalSamplePreparation/SamplePrepareRegisterView";
import { coalGradeLabel } from "./DATA";
import { thirdParyLabLabel } from "./ThirdPartyLabResult/DATA";
import CreatedByCreatedAt from "../../components/Common/CreatedByCreatedAt";

const ConsolidatedView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">Coal Sample Preparation Register Data</p>
            <SamplePrepareRegisterView
              hideClass
              form={form}
              noPadding
              noCreatedBy={true}
            />
          </div>

          <div className="previewContent">
            <p className="head">Sample Coding Register Data</p>
            <Row>
              <Col sm={6}>
                <label>{coalGradeLabel?.code_given_to_sample}</label>
                <p className="content">{form?.code_given_to_sample}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.sample_coding_done_by}</label>
                <p className="content">
                  {form?.sample_coding_users?.firstName}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.date_of_sample_receipt_in_lab}</label>
                <p className="content">
                  {form?.date_of_sample_receipt_in_lab
                    ? DDMMYYYYFormat(form?.date_of_sample_receipt_in_lab)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.date_given_for_test}</label>
                <p className="content">
                  {form?.date_given_for_test
                    ? DDMMYYYYFormat(form?.date_given_for_test)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.sample_sender_name}</label>
                <p className="content">{form?.sample_sender_name}</p>
              </Col>
            </Row>
          </div>

          <div className="previewContent">
            <p className="head">Sample De-Coding Register Data</p>
            <p className="">Sample De-Coding Related Data</p>
            <div className="previewContent">
              <Row>
                <Col sm={6}>
                  <label>{coalGradeLabel?.date_of_test_result_recieved}</label>
                  <p className="content">
                    {form?.date_of_test_result_recieved
                      ? DDMMYYYYFormat(form?.date_of_test_result_recieved)
                      : ""}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.decoded_by}</label>
                  <p className="content">{form?.decoded_by}</p>
                </Col>
              </Row>
            </div>

            <p className="">Lab Quality Results</p>
            <div className="previewContent">
              <h6>On Air Dry Basis</h6>
              <Row>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.lab_on_air_dry_bases_moisture + ` %`}
                  </label>
                  <p className="content">
                    {form?.lab_on_air_dry_bases_moisture}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.lab_on_air_dry_bases_ash + ` %`}
                  </label>
                  <p className="content">{form?.lab_on_air_dry_bases_ash}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.lab_on_air_dry_bases_vm + ` %`}
                  </label>
                  <p className="content">{form?.lab_on_air_dry_bases_vm}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.lab_on_air_dry_bases_gcv + ` (kCal/kg)`}
                  </label>
                  <p className="content">{form?.lab_on_air_dry_bases_gcv}</p>
                </Col>
              </Row>
              <div className="seperator"></div>
              <h6>On EM Basis ​</h6>
              <Row>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.lab_on_em_bases_moisture + ` %`}
                  </label>
                  <p className="content">{form?.lab_on_em_bases_moisture}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.lab_on_em_bases_ash + ` %`}​</label>
                  <p className="content">{form?.lab_on_em_bases_ash}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.lab_on_em_bases_vm + ` %`}​</label>
                  <p className="content">{form?.lab_on_em_bases_vm}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.lab_on_em_bases_gcv + ` (kCal/kg)`}
                  </label>
                  <p className="content">{form?.lab_on_em_bases_gcv}</p>
                </Col>
              </Row>
              <div className="seperator"></div>
              <Row>
                <Col sm={6}>
                  <label>{coalGradeLabel?.lab_analyzed_grade}</label>
                  <p className="content">{form?.lab_analyzed_grade}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.lab_result_checked_by}</label>
                  <p className="content">{form?.lab_result_checked_by}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.lab_result_tested_by}​</label>
                  <p className="content">{form?.lab_result_tested_by}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.result_reporting_to_customer}​</label>
                  <p className="content">
                    {form?.result_reporting_to_customer
                      ? DDMMYYYYFormat(form?.result_reporting_to_customer)
                      : ""}
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          <div className="previewContent">
            <p className="head">Third Party Lab Results Register Data</p>
            <p className="">Third Party Lab Quality Results</p>
            <div className="previewContent">
              <h6>On Air Dry Basis</h6>
              <Row>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_on_air_dry_bases_moisture +
                      ` %`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_air_dry_bases_moisture}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_on_air_dry_bases_ash + ` %`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_air_dry_bases_ash}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_on_air_dry_bases_vm + ` %`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_air_dry_bases_vm}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_on_air_dry_bases_gcv +
                      ` (kCal/kg)`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_air_dry_bases_gcv}
                  </p>
                </Col>
              </Row>
              <div className="seperator"></div>
              <h6>On EM Basis ​</h6>
              <Row>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_on_em_bases_moisture + ` %`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_em_bases_moisture}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_on_em_bases_ash + ` %`}
                  </label>
                  <p className="content">{form?.third_party_on_em_bases_ash}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_on_em_bases_vm + ` %`}
                  </label>
                  <p className="content">{form?.third_party_on_em_bases_vm}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_on_em_bases_gcv +
                      ` (kCal/kg)`}
                  </label>
                  <p className="content">{form?.third_party_on_em_bases_gcv}</p>
                </Col>
              </Row>
              <div className="seperator"></div>
              <Row>
                <Col sm={6}>
                  <label>{thirdParyLabLabel?.third_party_date_of_result}</label>
                  <p className="content">
                    {form?.third_party_date_of_result
                      ? DDMMYYYYHHMMAFormat(form?.third_party_date_of_result)
                      : ""}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_total_moisture + ` %`}
                  </label>
                  <p className="content">{form?.third_party_total_moisture}</p>
                </Col>
                <Col sm={6}>
                  <label>{thirdParyLabLabel?.third_party_analyzed_grade}</label>
                  <p className="content">{form?.third_party_analyzed_grade}</p>
                </Col>
              </Row>
            </div>
          </div>

          <div className="previewContent">
            <p className="head">Referee Lab Results Register Data</p>
            <p className="">Coal Dispatch Related Data</p>
            <div className="previewContent">
              <Row>
                <Col sm={6}>
                  <label>{coalGradeLabel?.name_of_referee_agency}</label>
                  <p className="content">{form?.name_of_referee_agency}</p>
                </Col>
              </Row>
            </div>

            <p className="">Referee Lab Quality Results</p>
            <div className="previewContent">
              <h6>On Air Dry Basis</h6>
              <Row>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.referee_on_air_dry_bases_moisture + ` %`}
                  </label>
                  <p className="content">
                    {form?.referee_on_air_dry_bases_moisture}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.referee_on_air_dry_bases_ash + ` %`}
                  </label>
                  <p className="content">
                    {form?.referee_on_air_dry_bases_ash}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.referee_on_air_dry_bases_vm + ` %`}
                  </label>
                  <p className="content">{form?.referee_on_air_dry_bases_vm}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.referee_on_air_dry_bases_gcv +
                      ` (kCal/kg)`}
                  </label>
                  <p className="content">
                    {form?.referee_on_air_dry_bases_gcv}
                  </p>
                </Col>
              </Row>
              <div className="seperator"></div>
              <h6>On EM Basis ​</h6>

              <Row>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.referee_on_em_bases_moisture + ` %`}
                  </label>
                  <p className="content">
                    {form?.referee_on_em_bases_moisture}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.referee_on_em_bases_ash + ` %`}
                  </label>
                  <p className="content">{form?.referee_on_em_bases_ash}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.referee_on_em_bases_vm + ` %`}</label>
                  <p className="content">{form?.referee_on_em_bases_vm}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.referee_on_em_bases_gcv + ` (kCal/kg)`}
                  </label>
                  <p className="content">{form?.referee_on_em_bases_gcv}</p>
                </Col>
              </Row>
              <div className="seperator"></div>
              <Row>
                <Col sm={6}>
                  <label>{coalGradeLabel?.referee_date_of_result}</label>
                  <p className="content">
                    {form?.referee_date_of_result
                      ? DDMMYYYYHHMMAFormat(form?.referee_date_of_result)
                      : ""}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.referee_total_moisture + ` %`}</label>
                  <p className="content">{form?.referee_total_moisture}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.referee_analyzed_grade}​</label>
                  <p className="content">{form?.referee_analyzed_grade}</p>
                </Col>

                <Col sm={6}>
                  <label>{coalGradeLabel?.dispute_raised_by}​</label>
                  <p className="content">
                    {form?.dispute_created_by?.firstName +
                      form?.dispute_created_by?.lastName}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.dispute_raised_date}​</label>
                  <p className="content">
                    {form?.dispute_raised_date
                      ? DDMMYYYYFormat(form?.dispute_raised_date)
                      : ""}
                  </p>
                </Col>

                <Col md={4}>
                  <label>Referred By</label>
                  <p className="content">{form?.referred_by}</p>
                </Col>
                <Col md={4}>
                  <label>Referred To</label>
                  <p className="content">{form?.referred_to}</p>
                </Col>
              </Row>
            </div>
          </div>
          <CreatedByCreatedAt id={form?.id} table={"coal_grade_quality_data"} />
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default ConsolidatedView;
