import React from "react";
import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";

function FuelCard({ label, quantity, maxDate, vertical, additionalClass }) {
  return (
    <>
      {vertical ? (
        <div class={`text-center ${additionalClass ? additionalClass : ""}`}>
          <h5>
            {label === "Total Available"
              ? label + " By " + DDMMYYYYFormat(maxDate)
              : label}
          </h5>
          <h7>{quantity}</h7>
        </div>
      ) : (
        <div className="dashSmallCard">
          <p>
            {label === "Total Available"
              ? label + " By " + DDMMYYYYFormat(maxDate)
              : label}
          </p>
          <h4>{quantity}</h4>
        </div>
      )}
    </>
  );
}

export default FuelCard;
