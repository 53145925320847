import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import moment from "moment";
const AddDispancedDetails = ({ form = {}, onChange, error = {} }) => {
  return (
    <div className="">
      {/* <Form> */}
      <Row>
        {(!!form?.is_fuel_meter_manadatory || form?.fuel_meter) && (
          <Col lg={4}>
            <FormInputText
              name="fuel_meter_after"
              value={form?.fuel_meter_after}
              onChange={onChange}
              type="text"
              class="form-control"
              placeholder=""
              label={"Fuel Meter Reading (After Dispense)"}
              error={error?.fuel_meter_after}
            />
          </Col>
        )}
        <Col lg={4}>
          <FormInputText
            name="fuel_dispensing_qty"
            value={form?.fuel_dispensing_qty}
            onChange={onChange}
            type="text"
            class="form-control"
            placeholder=""
            // disabled={
            //   form?.is_fuel_meter_manadatory ||
            //   (form?.fuel_meter_after && form?.fuel_meter)
            // }
            label={"Dispensed Quantity (L)"}
            error={error?.fuel_dispensing_qty}
          />
        </Col>

        {/* <Col lg={4}>
          <Form.Label className="form-label-input">
            Dispensed Date and Time
          </Form.Label>
          <CustomDatePicker
            value={
              form.createdAt
                ? form.createdAt
                : moment().format("YYYY-MM-DD HH:mm")
            }
            isValidDate={(day) => day.isSameOrAfter(moment().subtract(3,'d')) && day.isSameOrBefore(moment())}
            name="createdAt"
            onChange={onChange}
            timeFormat={"HH:mm"}
            dateFormat="YYYY-MM-DD"
          />
          <span className="small text-danger">{error.createdAt}</span>
        </Col> */}
      </Row>
      {/* </Form> */}
    </div>
  );
};

export default AddDispancedDetails;
