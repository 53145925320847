import React from "react";
import { Link } from "react-router-dom";
import Filter from "../../Assets/Images/filter.svg";
import FilesExport from "../Common/FilesExport";
import { IS_MOBILE } from "../../_helpers/helper";

const ScreenButton = ({ screens, isActive, handleShow, title }) => {
  return (
    <>
      <div className="dashboardOptions">
        <div className="dashboardOpt">
          {screens?.map((d, idx) => {
            return (
              <Link key={idx} to={d?.link}>
                <span className={`${+isActive === d?.screen ? "active" : ""}`}>
                  {d?.screen}
                </span>
              </Link>
            );
          })}
          {!IS_MOBILE() && title === "Incident Dashboard" && (
            <FilesExport id={`#${"screen" + isActive}`} fullScreen />
          )}
        </div>
        <div className="dashboardFilter" onClick={handleShow}>
          <img src={Filter} className="img-fluid" alt="filter" />
        </div>
      </div>
    </>
  );
};

export default ScreenButton;
