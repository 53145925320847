import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import {
  MyIncidentColumnFields,
  ViewIncidentApis,
  safetyManagementFilterForm,
  tabUrl,
} from "./../DATA";
import filter from "../../../Assets/Images/filter.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../../_helpers/hooks";
import {
  exportSafetyManagement,
  exportSafetyManagementFormA,
  exportSafetyManagementFormB,
  exportSafetyManagementFormC,
  exportSafetyManagementPdf,
  getViewIncidentListWithPagination,
} from "../../../services/_safetyManagement/safetyManagement";
import SafetyManagementView from "./../SafetyManagementView";
import SafetyManagementFilterForm from "./../SafetyManagementFilterForm";
// import "./../safety.scss";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import {
  IS_MOBILE,
  findUserPermissionedTabList,
} from "../../../_helpers/helper";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import {
  SidebarData,
  filterOptionFunc,
  mobileSidebarData,
  statusUpdateLink,
} from "./DATA";
import { permissionCheck } from "../../../_services/Auth/helper";

const ViewIncidentList = () => {
  const [getAllFilter, setGetAllFilter] = useState();
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.safetyManagement)
  );
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(safetyManagementFilterForm);

  const getAll = async () => {
    const response = await getViewIncidentListWithPagination("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    setGetAllFilter(filterOptionFunc(form));
  }, [filterStatus]);

  const onResetFilter = () => {
    setGetAllFilter();
    setForm(safetyManagementFilterForm);
  };

  const viewAsPopup = (form) => {
    return <SafetyManagementView form={form} />;
  };

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    getAll();
  }, []);

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          className="secondaryBtn"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          data-testid="filter-button"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <SafetyManagementFilterForm
          onResetFilter={onResetFilter}
          form={form}
          onChange={onChange}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          showFormCFilter={true}
        />
      </div>
    );
  };

  const deleteCheckFn = (row) => {
    return permissionCheck(["super_admin"]);
  };

  const onEdit = (id) => {
    navigate(
      "/safety-management-system/incident-management/mine-details-by-incident/" +
        id
    );
  };

  return isListData ? (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={IS_MOBILE() ? mobileSidebarData : SidebarData}
        pageTitle={"View Incidents"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={MyIncidentColumnFields}
                apis={ViewIncidentApis}
                title="Data"
                FilterBtn={FilterBtn}
                getAllDelete={true}
                deleteFun={true}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={
                  "/safety-management-system/incident-management/view-incidents"
                }
                breadCrumb={[
                  { name: "Safety Management", path: "#" },
                  {
                    name: "Incident Management",
                    path: "/safety-management-system/incident-management",
                  },
                  {
                    name: "View Incidents",
                    path: "#",
                  },
                ]}
                isNoTabOnPage
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                getAllFilter={getAllFilter}
                hideAddButton
                downloadExcel={exportSafetyManagement}
                downloadPdf={exportSafetyManagementPdf}
                downloadFormA={exportSafetyManagementFormA}
                downloadFormB={exportSafetyManagementFormB}
                downloadFormC={exportSafetyManagementFormC}
                customTableTitle={"View Incidents"}
                viewTitle={"Incident Details"}
                modalSize={"xl"}
                listname={"incident"}
                icons
                deleteCheck={deleteCheckFn}
                formClink={
                  IS_MOBILE()
                    ? "/mobile/incident-management/form-c/edit/"
                    : "/incident-management/form-c/edit/"
                }
                editFunButton={!IS_MOBILE() && deleteCheckFn() ? onEdit : null}
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        goBack={() => navigate(-1)}
        SidebarData={IS_MOBILE() ? mobileSidebarData : SidebarData}
        pageTitle={"View Incidents"}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            //   tabButtons={tabButtons}
            pageLink={"/incident-management"}
            title={"Incident Management"}
            hideAddBtn
            breadCrumb={[
              { name: "Safety Management", path: "#" },
              {
                path: "/safety-management-system/incident-management",
                name: "Incident Management",
              },
              {
                name: "View Incidents",
                path: "#",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewIncidentList;
