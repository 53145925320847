import FormInputText from "../../../../components/FormInputText/FormInputText";
import { Col, Row } from "react-bootstrap";
import { mineLabel } from "./DATA";

const MineOwnerForm = ({ form, error, onChange }) => {
  return (
    <Row>
      <Col lg={3}>
        <FormInputText
          name="ownerVillageAreaRoad"
          value={form.ownerVillageAreaRoad}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerVillageAreaRoad}
          error={error?.ownerVillageAreaRoad}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerPostOffice"
          value={form.ownerPostOffice}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerPostOffice}
          error={error?.ownerPostOffice}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerSubDivision"
          value={form.ownerSubDivision}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerSubDivision}
          error={error?.ownerSubDivision}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerDistrict"
          value={form.ownerDistrict}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerDistrict}
          error={error?.ownerDistrict}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerState"
          value={form.ownerState}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerState}
          error={error?.ownerState}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerPinCode"
          value={form.ownerPinCode}
          onChange={onChange}
          type="number"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerPinCode}
          error={error?.ownerPinCode}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerPoliceStation"
          value={form.ownerPoliceStation}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerPoliceStation}
          error={error?.ownerPoliceStation}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerRailwayStation"
          value={form.ownerRailwayStation}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerRailwayStation}
          error={error?.ownerRailwayStation}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerEmail"
          value={form.ownerEmail}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerEmail}
          error={error?.ownerEmail}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerTelephone"
          value={form.ownerTelephone}
          onChange={onChange}
          type="number"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerTelephone}
          error={error?.ownerTelephone}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerFax"
          value={form.ownerFax}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerFax}
          error={error?.ownerFax}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="ownerMobile"
          value={form.ownerMobile}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.ownerMobile}
          error={error?.ownerMobile}
        />
      </Col>
    </Row>
  );
};
export default MineOwnerForm;
