import React from "react";

const CustomRadio = ({
  title,
  name,
  acceptTitle = "Yes",
  rejectTitle = "No",
  value,
  onChange = () => {},
  disabled = false,
  customClass,
  checkBox,
  optionNewLine,
  textStyle,
  customStyle,
  removeSpace,
}) => (
  <div
    className={`customRadio ${customClass || ""}`}
    style={{ marginLeft: customStyle }}
  >
    <div className="mr-3" style={{ marginRight: textStyle }}>
      {title}
    </div>
    <div
      className={`${optionNewLine ? "" : "d-flex"}    ${
        removeSpace ? "" : "justify-content-between "
      } spaceCont `}
    >
      <div className="pr-4">
        <label className="radio radio-primary form-check-inline mb-0">
          <input
            type={checkBox ? "checkbox" : "radio"}
            name={name}
            value="Yes"
            checked={value === true}
            onChange={({ target: { name } }) =>
              onChange({ target: { name, type: "checkbox", checked: true } })
            }
            disabled={disabled}
          />
          <span>{acceptTitle}</span>
          <span className="checkmark"></span>
        </label>
      </div>
      <div className="pr-2">
        <label className="radio radio-primary form-check-inline mb-0">
          <input
            type={checkBox ? "checkbox" : "radio"}
            name={name}
            value="No"
            checked={value === false}
            onChange={({ target: { name } }) =>
              onChange({ target: { name, type: "checkbox", checked: false } })
            }
            disabled={disabled}
          />
          <span>{rejectTitle}</span>
          <span className="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
);

export default CustomRadio;
