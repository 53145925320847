import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../components/FormInputText/FormInputText";
import LabelCustom from "../../../components/LabelCustom/LabelCustom";
import { coalGradeLabel } from "../DATA";
import moment from "moment";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../components/Select/Select";
import { modeOfDispatchList } from "../CoalSamplePreparation/DATA";

export const CoalDispatchRelatedForm = ({
  rrDDNoOList,
  declaredGradeList,
  form,
  onChange,
  error,
  ModeOfDispatchOnChangeFn,
  RrDdNoOnChangeFn,
  RrDdDateOnChangeFn,
  assignNameOfSamplingAgency,
}) => {
  // useEffect(() => {
  //   let modeOfDispatchListOption = coalGradeData.map((row) => {
  //     return { label: row.mode_of_dispatch, value: row.mode_of_dispatch };
  //   });

  //   setModeOfDispatchList(modeOfDispatchListOption);
  // }, [coalGradeData]);

  return (
    <div className="creationWrapper">
      <Form>
        <Row>
          <Col md={6}>
            <Select
              onChange={(e) => {
                onChange(e);
                ModeOfDispatchOnChangeFn(e);
              }}
              value={form?.mode_of_dispatch}
              name="mode_of_dispatch"
              options={modeOfDispatchList}
              data-testid="ModeOfDispatchOnChangeFnTestId"
              error={error?.mode_of_dispatch}
              label={coalGradeLabel?.mode_of_dispatch}
            />
          </Col>
          <Col md={6}>
            <LabelCustom>
              {/* {form?.mode_of_dispatch === "SHRA"
                ? "RR date"
                : "Gross Weight Date"} */}
              Collection Date
            </LabelCustom>
            <CustomDatePicker
              value={form?.rr_or_do_date}
              error={error?.rr_or_do_date}
              name="rr_or_do_date"
              onChange={(e) => {
                onChange(e);
                RrDdDateOnChangeFn(e);
              }}
            />
            {/* <InputError error={error?.rr_or_do_date} /> */}
          </Col>
          <Col md={6}>
            <Select
              onChange={(e) => {
                onChange(e);
                RrDdNoOnChangeFn(e);
              }}
              value={form?.rr_or_do_number}
              name="rr_or_do_number"
              options={rrDDNoOList}
              error={error?.rr_or_do_number}
              label={coalGradeLabel?.rr_or_do_number}
            />
          </Col>

          <Col md={6}>
            <Select
              onChange={(e) => {
                onChange(e);
                assignNameOfSamplingAgency(e);
              }}
              value={form?.rr_or_do_number ? form?.declared_grade : ""}
              name="declared_grade"
              options={declaredGradeList}
              error={error?.declared_grade}
              label={coalGradeLabel?.declared_grade}
              disabled={true}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="text"
              label={coalGradeLabel?.name_of_sampling_agency}
              onChange={onChange}
              name="name_of_sampling_agency"
              value={form?.name_of_sampling_agency}
              error={error?.name_of_sampling_agency}
              readOnly
            />
          </Col>
          <Col md={6}>
            <LabelCustom>
              {coalGradeLabel?.third_party_date_of_result}
            </LabelCustom>
            <CustomDatePicker
              isValidDate={(day) => day.isBefore(moment())}
              value={
                form.third_party_date_of_result
                  ? form.third_party_date_of_result
                  : ""
              }
              error={error?.third_party_date_of_result}
              name="third_party_date_of_result"
              onChange={(e) => {
                onChange(e);
              }}
              timeFormat={"HH:mm A"}
              dateFormat="YYYY-MM-DD"
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
