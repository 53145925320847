import { RequireAuth, removeExtraKeys } from "../../_services/Auth/RequireAuth";
import Header from "../Header/Header";
import { useRoutes } from "react-router-dom";
import RoutePermission from "../../_services/Auth/RoutePermision";
import { IS_MOBILE } from "../../_helpers/helper";
import MobFooter from "../MobFooter/MobFooter";
import { useEffect, useState } from "react";
import { createBrowserHistory } from "history";

function PrivateRoute() {
  const [isFooter, setIsFooter] = useState(false);
  const { pathname } = createBrowserHistory().location;

  useEffect(() => {
    const IsPathNumeric = pathname.match(/\d+/g);
    const currentUrl =
      IsPathNumeric && IsPathNumeric?.length
        ? pathname.replace(/\d+/g, "")
        : pathname;
    const filterRouteActive =
      RoutePermission.find((d) => removeExtraKeys(d?.path) === currentUrl)
        ?.footerActive || false;
    setIsFooter(filterRouteActive);
  }, [pathname]);

  return (
    <>
      <RequireAuth routes={RoutePermission}>
        {!IS_MOBILE() && <Header />}
        <div className={`${IS_MOBILE() ? "mobWrapper" : "contentWrapper"}`}>
          {useRoutes(RoutePermission)}
        </div>
        {IS_MOBILE() && isFooter && <MobFooter activeLink={pathname} />}
      </RequireAuth>
    </>
  );
}

export default PrivateRoute;
