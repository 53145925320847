import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import PieChartWithModal from "../../../components/PieChartWithModal/PieChartWithModal";
import NoRecord from "../../../components/Common/NoRecord";
import { decimalPointNumber } from "../../../_helpers/helper";

const LandReclamationStatus = ({ data, form, onChange,pageType }) => {
  return (
    <>
      <div className="dashboardWrapper" id={`${"screen"+pageType}`}>
        <Row>
          {Object.keys(data)?.length ? (
            Object.keys(data)?.map((key, idx) => {
              let totalArea=0;
              const cardSize =
                Object.keys(data)?.length < 4
                  ? 12 / Object.keys(data)?.length
                  : 4;
                  data[key]?.[key]?.forEach(element => {
                    totalArea=totalArea+element?.value;
                  });
              return (
                <Col md={cardSize} key={idx}>
                  <Card className="chartCard chartCardBtmSpace">
                    <Card.Header>{data[key]?.title}</Card.Header>
                    <Card.Body className="pieCartOuter">
                      <PieChartWithModal
                        pieChartColour={false}
                        seriesData={data[key]?.[key] || []}
                        customLabelFalse={true}
                        isCustomLegend={true}
                        unit ={" Hectare"}
                        customTitle={"Total Area = "+decimalPointNumber(totalArea,2)}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          ) : (
            <NoRecord />
          )}
        </Row>
      </div>
    </>
  );
};

export default LandReclamationStatus;
