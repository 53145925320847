import { IS_MOBILE } from "../_helpers/helper";
import { UserService } from "../_services/UserService/UserService";
import jwtAuthService from "./jwtAuthService";

export const logout = () => {
  localStorage.removeItem("auth_user");
  localStorage.removeItem("jwt_token");
  localStorage.removeItem("permissions");

  localStorage.removeItem("id_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("token_type");
  return true;
};

export const logoutUser = async (changePass = false) => {
  let logOutSuccess = false;
  const response = await UserService.signOutUser({
    isMobile: changePass ? 2 : IS_MOBILE() ? 1 : 0,
  });
  if (response?.status) {
    jwtAuthService.logout();
    logOutSuccess = true;
  }
  return logOutSuccess;
};
