import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import { downloadFileByURL } from "../../_helpers/commonHelper";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import moment from "moment";
export const getSafetyManagementCapaAssignedIncidentListWithPagination = async (
  filter
) => {
  let url = "/incident/my-approval-action";

  if (filter) url += "?" + filter + "&type=capa_assigned";

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getSafetyManagementInvestigationAssignedIncidentExcel = async (
  filter
) => {
  try {
    let url = "/incident/export-my-approval-action";
    url += "?type=investigation_in_progress";

    if (filter) url += "&" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const getSafetyManagementReportedIncidentExcel = async (filter) => {
  try {
    let url = "/incident/export-my-approval-action";
    url += "?type=reported";

    if (filter) url += "&" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const getSafetyManagementCapaAssignedIncidentExcel = async (filter) => {
  try {
    let url = "/incident/export-my-approval-action";
    url += "?type=capa_assigned";
    if (filter) url += "&" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const getSafetyManagementInvestigationAssignedIncidentListWithPagination =
  async (filter) => {
    let url = "/incident/my-approval-action";

    if (filter) url += "?" + filter + "&type=investigation_in_progress";

    try {
      const response = await RestMethod.GET(url);
      const { status, message, data } = response.data;
      const d = {
        status,
        message,
        totalCount: data?.totalCount,
        data: data?.incident,
      };
      return d;
    } catch (error) {
      console.log("error detected while fetching data from api", error);
      return {
        data: [],
      };
    }
  };

export const getSafetyManagementCapaOwnerIncidentListWithPagination = async (
  filter
) => {
  let url = "/incident/my-approval-action-history";

  if (filter) url += "?" + filter + "&type=capaOwner";

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getSafetyManagementCapaOwnerIncidentExcel = async (filter) => {
  try {
    let url = "/incident/export-my-approval-action-history";
    url += "?type=capaOwner";
    if (filter) url += "&" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const getSafetyManagementInvestigatorIncidentListWithPagination = async (
  filter
) => {
  let url = "/incident/my-approval-action-history";

  if (filter) url += "?" + filter + "&type=investigator";

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getSafetyManagementInvestigatorIncidentExcel = async (filter) => {
  try {
    let url = "/incident/export-my-approval-action-history";
    url += "?type=investigator";
    if (filter) url += "&" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const getMyIncidentHistoryListWithPagination = async (filter) => {
  let url = "/incident/my-approval-action-history";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.data?.totalCount,
      data: data?.data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getSafetyManagementReportedIncidentHistoryListWithPagination =
  async (filter) => {
    let url = "/incident/my-approval-action-history";

    if (filter) url += "?" + filter + "&type=admin";

    try {
      const response = await RestMethod.GET(url);
      const { status, message, data } = response.data;
      const d = {
        status,
        message,
        totalCount: data?.totalCount,
        data: data?.incident,
      };
      return d;
    } catch (error) {
      console.log("error detected while fetching data from api", error);
      return {
        data: [],
      };
    }
  };

export const getSafetyManagementReportedIncidentHistoryExcel = async (
  filter
) => {
  try {
    let url = "/incident/export-my-approval-action-history";
    url += "?type=admin";
    if (filter) url += "&" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const getSafetyManagementReportedIncidentListWithPagination = async (
  filter
) => {
  let url = "/incident/my-approval-action";

  if (filter) url += "?" + filter + "&type=reported";

  try {
    const response = await RestMethod.GET(url);

    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getCapaById = async (id) => {
  let url = "/capa/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return !status ? [] : data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getSafetyManagementListWithPagination = async (filter) => {
  let url = "/incident";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.data?.totalCount,
      data: data?.data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getViewIncidentListWithPagination = async (filter) => {
  let url = "/incident";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.data?.totalCount,
      data: data?.data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getMyIncidentListWithPagination = async (filter) => {
  let url = "/incident/my";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.data?.totalCount,
      data: data?.data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteIncident = async (filter) => {
  let url = "/incident";

  if (filter) url += "/" + filter;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const exportSafetyManagement = async (filter) => {
  try {
    let url = "/incident/export-incident-data";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const exportSafetyManagementPdf = (filter) => {
  try {
    let url = URL_CONFIG.DEV_URL + "incident/incident-download";
    if (filter) url += "?" + filter;
    downloadFileByURL(url, moment().format("DD-MM-YYYY") + "incident.pdf");
  } catch (error) {
    return {
      data: [],
    };
  }
};
const getId = (filter) => {
  const searchIdRegex = /search\[id\]=\d+/g;
  const searchIdMatches = filter.match(searchIdRegex);
  if (searchIdMatches) {
    const searchIdValue = searchIdMatches[0].split("=")[1];
    return parseInt(searchIdValue, 10);
  }
};
export const exportSafetyManagementFormA = (filter) => {
  try {
    let url = URL_CONFIG.DEV_URL + "incident/incident-formA-download";
    if (filter) url += "?" + filter;

    let id = getId(filter);

    downloadFileByURL(
      url,
      moment().format("DD-MM-YYYY") + " - " + id + " - Form4A.pdf"
    );
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const exportSafetyManagementFormB = (filter) => {
  try {
    let url = URL_CONFIG.DEV_URL + "incident/incident-formB-download";
    if (filter) url += "?" + filter;
    let id = getId(filter);
    downloadFileByURL(
      url,
      moment().format("DD-MM-YYYY") + " - " + id + " - Form4B.pdf"
    );
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const exportSafetyManagementFormC = (filter) => {
  try {
    let url = URL_CONFIG.DEV_URL + "incident/incident-formC-download";
    if (filter) url += "?" + filter;
    let id = getId(filter);
    downloadFileByURL(
      url,
      moment().format("DD-MM-YYYY") + " - " + id + " - Form4C.pdf"
    );
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const exportMySafetyManagement = async (filter) => {
  try {
    let url = "/incident/export-my-incident-data";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

// Change Incident Status

export const changeIncidentStatus = async (data) => {
  try {
    const response = await RestMethod.PUT(
      `/incident/change-status/${data?.id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return null;
  }
};
export const changeIncidentFormCStatus = async (data) => {
  try {
    const response = await RestMethod.PUT(
      `/incident/form-c-status/${data?.id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return null;
  }
};
export const changeCapaStatus = async (data) => {
  try {
    const response = await RestMethod.POST(`/capa/${data?.id}`, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return null;
  }
};

export async function safetyDashboard1(data) {
  try {
    const response = await RestMethod.POST(
      "/dashboard/safety-management-d1",
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function safetyDashboard2(data) {
  try {
    const response = await RestMethod.POST(
      "/dashboard/safety-management-d2",
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function safetyDashboard3(data) {
  try {
    const response = await RestMethod.POST(
      "/dashboard/safety-management-d3",
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function getIncidentDashboardTbl(filter) {
  try {
    const response = await RestMethod.GET(
      "/dashboard/safety-management-d2/table-data?" + (filter || "")
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function safetyDashboard4(data) {
  try {
    const response = await RestMethod.POST(
      "/dashboard/safety-management-d4",
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export const getAllCsisDataListWithPagination = async (filter) => {
  let url = "/incident/all-accident";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.data?.totalCount,
      data: data?.data?.accident,
      lastSync: response.data.data.lastSync,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const exportAccidentCiData = async (filter) => {
  try {
    let url = "/incident/export-accident-ci-data";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
