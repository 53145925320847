import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../components/FormInputText/FormInputText";
import { nameSamplingAgencyList, samplePrepareRegisterLabel } from "./DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../components/Select/Select";

function SampleRelatedForm({ form, onChange, error, samplingAgencyOpt }) {
  return (
    <div className="creationWrapper">
      <Form>
        <Row>
          <Col md={6}>
            <FormInputText
              type="text"
              placeholder="Enter here"
              label={samplePrepareRegisterLabel?.sample_id}
              onChange={onChange}
              name="sample_id"
              value={form?.sample_id}
              error={error?.sample_id}
            />
          </Col>
          <Col md={6}>
            <Form.Label>
              {samplePrepareRegisterLabel?.date_of_collection}
            </Form.Label>
            <CustomDatePicker
              value={form.date_of_collection ? form.date_of_collection : ""}
              error={error?.date_of_collection}
              name="date_of_collection"
              onChangeDate={onChange}
              dateFormat="YYYY-MM-DD"
              disabled={true}
            />
          </Col>

          <Col md={6}>
            <Form.Label>
              {samplePrepareRegisterLabel?.date_of_prepartaion}
            </Form.Label>
            <CustomDatePicker
              isValidDate={(day) => day.isAfter(form.rr_or_do_date)}
              value={form.date_of_prepartaion ? form.date_of_prepartaion : ""}
              name="date_of_prepartaion"
              onChangeDate={onChange}
              error={error?.date_of_prepartaion}
              dateFormat="YYYY-MM-DD"
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="text"
              placeholder="Enter here"
              label={samplePrepareRegisterLabel?.ncl_seal_number}
              onChange={onChange}
              name="ncl_seal_number"
              value={form?.ncl_seal_number}
              error={error?.ncl_seal_number}
            />
          </Col>
          <Col md={6}>
            <Form.Label>
              {samplePrepareRegisterLabel?.date_of_sample_sent_to_lab}
            </Form.Label>
            <CustomDatePicker
              value={
                form.date_of_sample_sent_to_lab
                  ? form.date_of_sample_sent_to_lab
                  : ""
              }
              name="date_of_sample_sent_to_lab"
              isValidDate={(day) => day.isAfter(form.rr_or_do_date)}
              onChangeDate={onChange}
              error={error?.date_of_sample_sent_to_lab}
              dateFormat="YYYY-MM-DD"
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="text"
              placeholder="Enter here"
              label={samplePrepareRegisterLabel?.referee_seal_number}
              onChange={onChange}
              name="referee_seal_number"
              value={form?.referee_seal_number}
              error={error?.referee_seal_number}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="text"
              placeholder="Enter here"
              label={samplePrepareRegisterLabel?.name_of_ncl_representative}
              onChange={onChange}
              name="name_of_ncl_representative"
              value={form?.name_of_ncl_representative}
              error={error?.name_of_ncl_representative}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default SampleRelatedForm;
