import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  incidentFlowList,
  mobileIncidentFlowList,
  mobileSidebarData,
  SidebarData,
} from "./DATA";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import "./IncidentMgmt.scss";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { IS_MOBILE } from "../../../_helpers/helper";
import { filterUrlFunc } from "../../../_services/CommonService/CommonService";

const IncidentManagement = () => {
  // console.log("check++", IS_MOBILE());

  return IS_MOBILE() ? (
    <>
      <div className={`mobileSidebarWrapper`}>
        <SafetySideBar
          SidebarData={mobileSidebarData}
          moduleTitle={"Safety Management"}
        />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="MgmtWrapper pt-4">
                <Row className="dashboardItem">
                  {filterUrlFunc(mobileIncidentFlowList)?.map((d, i) => {
                    return (
                      <Col lg={4} key={i} className="mb-4">
                        <Link to={d?.link}>
                          <div className="dashboardItemContent">
                            <div className="imgArea">
                              <img
                                alt="dashboardItemIcons"
                                src={d.img}
                                className="img-fluid"
                              />
                            </div>
                            <label>{d?.label}</label>
                          </div>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="screenSpaceLR">
                <h3
                  className=""
                  style={{ paddingBottom: "1px", paddingTop: "5px" }}
                >
                  <MainBreadcrumb
                    routeSegments={[
                      {
                        name: "Safety Management",
                        path: "/safety-management-system/incident-management",
                      },
                      {
                        name: "Incident Management",
                        path: "#",
                      },
                    ]}
                    // module={ACTIVE_MODULE.incident}
                  />
                </h3>
                <div className="MgmtWrapper pt-4">
                  <Row className="dashboardItem">
                    {filterUrlFunc(incidentFlowList)?.map((d, idx) => {
                      return (
                        <Col lg={4} key={idx} className="mb-4">
                          <Link to={d?.link}>
                            <div className="dashboardItemContent">
                              <div className="imgArea">
                                <img
                                  src={d.img}
                                  className="img-fluid"
                                  alt="dashboardItemIcons"
                                />
                              </div>
                              <label>{d.label}</label>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncidentManagement;
