import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { submitStatus, modeOfDispatchLabel } from "../DATA";

export const RefereeLabLabel = {
  referee_on_air_dry_bases_gcv: "GCV",
  referee_on_em_bases_moisture: "Moisture",
  referee_on_em_bases_ash: "Ash",
  referee_on_em_bases_vm: "VM",
  site_id: "Site ID",
  date_of_sample_sent_to_lab: "Date of sample sent to lab",
  code_given_to_sample: "Code given to sample",
  sample_coding_done_by: "Sample coding done by",
  date_of_sample_receipt_in_lab: "Date of sample receipt in lab",
  date_given_for_test: "Date given for test",
  lab_on_air_dry_bases_moisture: "Moisture",
  lab_on_air_dry_bases_ash: "Ash",
  result_reporting_to_customer: "Result reporting date to customer",
  lab_on_air_dry_bases_vm: "VM",
  lab_on_air_dry_bases_gcv: "GCV",
  lab_on_em_bases_moisture: "Moisture",
  lab_on_em_bases_ash: "Ash",
  lab_on_em_bases_vm: "VM",
  lab_on_em_bases_gcv: "GCV",
  lab_analyzed_grade: "Lab analyzed grade",
  name_of_referee_agency: "Name of Referee Lab",
  referee_on_air_dry_bases_moisture: "Moisture",
  referee_on_air_dry_bases_ash: "Ash",
  referee_on_air_dry_bases_vm: "VM",
  referee_on_em_bases_gcv: "GCV",
  referee_total_moisture: "Total Moisture",
  referee_analyzed_grade: "Referee Lab analyzed grade",
  referee_date_of_result: "Date of the result",
  name_of_sampling_agency: "Name of sampling agency",
  third_party_on_air_dry_bases_moisture: "Moisture",
  third_party_on_air_dry_bases_ash: "Ash",
  third_party_on_air_dry_bases_vm: "VM",
  third_party_on_air_dry_bases_gcv: "GCV",
  third_party_on_em_bases_moisture: "Moisture",
  third_party_on_em_bases_ash: "Ash",
  third_party_on_em_bases_vm: "VM",
  third_party_on_em_bases_gcv: "GCV",
  third_party_total_moisture: "Total moisture",
  third_party_analyzed_grade: "Third party lab analyzed grade",
  id: "ID",
  sample_id: "Sample ID",
  mode_of_dispatch: "Mode of Dispatch",
  rr_or_do_date: "Collection Date",
  rr_or_do_number: "RR/ DO No.",
  declared_grade: "Declared Grade",
  date_of_test_result_recieved: "Date of test result recieved",
  sample_sender_name: "Sample sender name",
  decoded_by: "Decoded By",
  lab_result_checked_by: "Checked by",
  lab_result_tested_by: "Tested by",
};

export const CoalDispatchRelatedRules = [
  {
    label: RefereeLabLabel?.mode_of_dispatch,
    field_name: "mode_of_dispatch",
    isRequired: true,
    type: "string",
  },
  {
    label: RefereeLabLabel?.rr_or_do_date,
    field_name: "rr_or_do_date",
    isRequired: true,
    type: "string",
  },
  {
    label: RefereeLabLabel?.rr_or_do_number,
    field_name: "rr_or_do_number",
    isRequired: true,
    type: "number",
  },
  {
    label: RefereeLabLabel?.declared_grade,
    field_name: "declared_grade",
    isRequired: true,
    type: "string",
  },
  {
    label: RefereeLabLabel?.name_of_referee_agency,
    field_name: "name_of_referee_agency",
    isRequired: true,
    type: "string",
  },
];

export const RefereeLabRules = [
  {
    label: RefereeLabLabel?.referee_total_moisture,
    field_name: "referee_total_moisture",
    isRequired: true,
    type: "number",
    maxNumber: 100,
    minNumber: 0,
  },
  // {
  //   label: RefereeLabLabel?.referee_on_air_dry_bases_moisture,
  //   field_name: "referee_on_air_dry_bases_moisture",
  //   isRequired: true,
  //   type: "number",
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  // {
  //   label: RefereeLabLabel?.referee_on_air_dry_bases_ash,
  //   field_name: "referee_on_air_dry_bases_ash",
  //   isRequired: true,
  //   type: "number",
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  // {
  //   label: RefereeLabLabel?.referee_on_air_dry_bases_vm,
  //   field_name: "referee_on_air_dry_bases_vm",
  //   isRequired: true,
  //   type: "number",
  //   minNumber: 0,
  //   maxNumber: 100,
  // },
  // {
  //   label: RefereeLabLabel?.referee_on_air_dry_bases_gcv,
  //   minNumber: 0,
  //   field_name: "referee_on_air_dry_bases_gcv",
  //   maxNumber: 100,
  //   isRequired: true,
  //   type: "number",
  // },
  {
    label: RefereeLabLabel?.referee_on_em_bases_moisture,
    field_name: "referee_on_em_bases_moisture",
    isRequired: true,
    type: "number",
    // maxNumber: 100,
    minNumber: 0,
  },
  {
    label: RefereeLabLabel?.referee_on_em_bases_ash,
    field_name: "referee_on_em_bases_ash",
    isRequired: true,
    type: "number",
    // maxNumber: 100,
    minNumber: 0,
  },
  // {
  //   label: RefereeLabLabel?.referee_on_em_bases_vm,
  //   field_name: "referee_on_em_bases_vm",
  //   isRequired: true,
  //   type: "number",
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  {
    label: RefereeLabLabel?.referee_on_em_bases_gcv,
    minNumber: 0,
    field_name: "referee_on_em_bases_gcv",
    isRequired: true,
    type: "number",
    // maxNumber: 100,
  },
  {
    label: RefereeLabLabel?.referee_analyzed_grade,
    field_name: "referee_analyzed_grade",
    isRequired: true,
    type: "string",
  },
  {
    label: RefereeLabLabel?.referee_date_of_result,
    field_name: "referee_date_of_result",
    isRequired: true,
    type: "string",
  },
];

export const RefereeLabResultInitialForm = {
  mode_of_dispatch: "",
  rr_or_do_number: "",
  rr_or_do_date: "",
  declared_grade: "",
  dispute_raised_by: "",
  name_of_referee_agency: "",
  referee_on_air_dry_bases_moisture: "",
  referee_on_air_dry_bases_ash: "",
  referee_on_air_dry_bases_vm: "",
  referee_on_air_dry_bases_gcv: "",
  referee_on_em_bases_moisture: "",
  referee_on_em_bases_ash: "",
  referee_on_em_bases_vm: "",
  referee_on_em_bases_gcv: "",
  referee_total_moisture: "",
  referee_analyzed_grade: "",
  referee_date_of_result: "",
};

export const refereeLabResultColumnFields = [
  {
    sortable: true,
    selector: "sample_id",
    name: "Sample ID",
  },
  {
    selector: "mode_of_dispatch",
    sortable: true,
    isSearchable: true,
    name: "Mode of Dispatch",
    cell: (row) =>
      row?.mode_of_dispatch ? modeOfDispatchLabel[row?.mode_of_dispatch] : "",
  },
  {
    name: "Delivery No",
    selector: "rr_or_do_number",
    sortable: true,
    isSearchable: true,
  },
  {
    selector: "rr_or_do_date",
    name: "Collection Date",
    sortable: true,
    cell: (row) =>
      row?.rr_or_do_date ? DDMMYYYYFormat(row?.rr_or_do_date) : "",
  },
  {
    name: "Declared Grade",
    selector: "declared_grade",
    sortable: true,
    isSearchable: true,
  },
  {
    sortable: true,
    name: "Name of referee agency",
    selector: "name_of_referee_agency",
  },
  {
    name: "Status",
    // selector: "sample_prepare_status",
    // sortable: true,
    minWidth: "160px",
    cell: (row) => {
      if (row?.referee_lab_approval === "Pending") {
        if (row?.referee_lab_status === submitStatus?.submitted) {
          return (
            <span className="statusSec submit">{submitStatus?.submitted}</span>
          );
        } else {
          return (
            <span className="statusSec draft">{submitStatus?.drafted}</span>
          );
        }
      } else {
        return (
          <span className="statusSec draft">{row?.referee_lab_approval}</span>
        );
      }
    },
  },
  // {
  //   name: "System Feedback",
  //   selector: (row) => <GiGolfFlag className="feedbackIcon" />,
  //   center: true,
  // },
];

export const refereeLabResultFilterForm = {
  startDate: "",
  endDate: "",
  mode_of_dispatch: "",
};

export const refereeLabResultData = {
  tableColumn: refereeLabResultColumnFields,
  filterForm: refereeLabResultFilterForm,
  pageLink: "/coal-grade/referee-lab-result",
  tableTittle: "Referee Lab Results Register",
  type: "referee-lab-result",
  role: ["area_quality_manager"],
  // viewComponent: SamplePrepareRegisterView,
};
