import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { tankLabel } from "./DATA";
import CreatedByCreatedAt from "../../../../components/Common/CreatedByCreatedAt";
import toastr from "toastr";
import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../../_helpers/dateHelper";
import {
  deleteFuelDispense,
  getAllReceiptDispensed,
} from "../../../../services/_fuelDispense/fuelDispense";
import NoRecord from "../../../../components/Common/NoRecord";
import { FiEdit3 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { isDateFromThreeDaysBack } from "../../../../_helpers/helper";
import { RiDeleteBin5Line } from "react-icons/ri";

const StockViewBowser = ({ form, setShowView, getAll }) => {
  const [type, setType] = useState("dispense");
  const [dispenseReceiptData, setDispenseReceiptData] = useState({});

  const getData = async () => {
    let filter = `tankId=${form?.tank_id}&date=${form?.date}&typeOf=bowser`;
    let dispenseReceipt = await getAllReceiptDispensed(filter);

    if (dispenseReceipt.status) {
      setDispenseReceiptData(dispenseReceipt);
    }
  };
  useEffect(() => {
    getData();
  }, [form.tank_id, form.date]);

  const handelDelete = async (id) => {
    let deleteRecord = await deleteFuelDispense(id);
    if (deleteRecord.status) {
      toastr.success("Record deleted");
      setShowView(false);
      getAll();
    } else toastr.error("Something  went wrong!");
  };

  const handelDeleteReceipt = async (id) => {
    let deleteRecord = await deleteFuelDispense(id);
    if (deleteRecord.status) {
      toastr.success("Record deleted");
      setShowView(false);
      getAll();
    } else toastr.error("Something  went wrong!");
  };
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <Row>
            <Col md={4}>
              <Row>
                <Col sm={6}>
                  <label>{tankLabel.site_id}</label>
                </Col>
                <Col sm={6}>
                  <p className="content">{form?.site?.name}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <label>{"Bowser Name"}</label>
                </Col>
                <Col sm={6}>
                  <p className="content">{form?.bowser?.hemm_id}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <label>{tankLabel.date}</label>
                </Col>
                <Col sm={6}>
                  <p className="content">
                    {form?.date && DDMMYYYYFormat(form.date)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <label>{tankLabel.dispenseQyt}</label>
                </Col>
                <Col sm={6}>
                  <p className="content">{form?.dispenseQyt}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <label>{tankLabel.totalReceipt}</label>
                </Col>
                <Col sm={6}>
                  <p className="content">{form?.totalReceipt}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <label>{tankLabel.finalOpeningStock}</label>
                </Col>
                <Col sm={6}>
                  <p className="content">{form?.finalOpeningStock}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <label>{tankLabel.finalClosingStock}</label>
                </Col>
                <Col sm={6}>
                  <p className="content">{form?.finalClosingStock}</p>
                </Col>
              </Row>
            </Col>
            <Col md={8}>
              <div
                className="dashboardTabs coalGradeScreenTabs "
                style={{ width: "100%" }}
              >
                <ul>
                  <li
                    className={`scoreCardDashboards ${
                      type === "dispense" ? "active" : ""
                    }`}
                    onClick={() => setType("dispense")}
                  >
                    Dispenses
                  </li>
                  <li
                    className={`scoreCardDashboards ${
                      type === "receipt" ? "active" : ""
                    }`}
                    onClick={() => setType("receipt")}
                  >
                    Receipt
                  </li>
                </ul>
              </div>
              {type === "dispense" ? (
                <Row>
                  <div
                    className="webformCardContent table-responsive"
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                  >
                    <table className="avgTblData table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>Hemm Id</th>
                          <th>Dispensed Quantity(L)</th>
                          <th>Dispensed DateTime</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(dispenseReceiptData.allDispense) &&
                          dispenseReceiptData.allDispense.map((d, i) => {
                            return (
                              <tr key={i}>
                                <td>{d?.fuel_dispense_hemm_datas?.hemm_id}</td>
                                <td>{d?.fuel_dispensing_qty}</td>
                                <td>{DDMMYYYYHHMMAFormat(d?.createdAt)}</td>
                                <td>
                                  {isDateFromThreeDaysBack(d?.createdAt) ? (
                                    <>
                                      <Link
                                        to={`/fuel-management-system/${d?.id}`}
                                        className="icons text-cyanBlue"
                                        style={{ marginRight: "10px" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <FiEdit3
                                          style={{ fontSize: "17px" }}
                                          title="Edit"
                                        />
                                      </Link>
                                      <Link
                                        onClick={() => {
                                          handelDelete(d?.id);
                                        }}
                                      >
                                        <RiDeleteBin5Line
                                          style={{
                                            fontSize: "17px",
                                            color: "red",
                                          }}
                                          title="Delete"
                                        />
                                      </Link>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {Array.isArray(dispenseReceiptData.allDispense) &&
                      !dispenseReceiptData.allDispense?.length > 0 && (
                        <span className="text-center">
                          <NoRecord />
                        </span>
                      )}
                  </div>
                </Row>
              ) : (
                <Row>
                  <div
                    className="webformCardContent table-responsive"
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                  >
                    <table className="avgTblData table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>Tank</th>
                          <th>Fuel Location</th>
                          <th>Receipt Quantity(L)</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(dispenseReceiptData.allReceipt) &&
                          dispenseReceiptData.allReceipt.map((d, i) => {
                            return (
                              <tr key={i}>
                                <td>{d?.tank?.name}</td>
                                <td>{d?.fuel_location?.fuel_location_name}</td>
                                <td>{d?.fuel_dispensing_qty}</td>
                                <td>{DDMMYYYYHHMMAFormat(d?.createdAt)}</td>
                                <td>
                                  {isDateFromThreeDaysBack(d?.createdAt) ? (
                                    <>
                                      <Link
                                        to={`/fuel-management-system/${d?.id}`}
                                        className="icons text-cyanBlue"
                                        style={{ marginRight: "10px" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <FiEdit3
                                          style={{ fontSize: "17px" }}
                                          title="Edit"
                                        />
                                      </Link>
                                      <Link
                                        onClick={() => {
                                          handelDeleteReceipt(d?.id);
                                        }}
                                      >
                                        <RiDeleteBin5Line
                                          style={{
                                            fontSize: "17px",
                                            color: "red",
                                          }}
                                          title="Delete"
                                        />
                                      </Link>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {Array.isArray(dispenseReceiptData.allReceipt) &&
                      !dispenseReceiptData.allReceipt?.length > 0 && (
                        <span className="text-center">
                          <NoRecord />
                        </span>
                      )}
                  </div>
                </Row>
              )}
            </Col>
          </Row>

          <CreatedByCreatedAt
            id={form?.id}
            table={"fuel_stock_updates"}
            border={false}
          />
        </div>
      </Modal.Body>
    </>
  );
};

export default StockViewBowser;
