import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { UserService } from "../../_services/UserService/UserService";
import "./viewProfile.scss";
import user from "../../Assets/Images/user.png";
import { IS_MOBILE, auth_user } from "../../_helpers/helper";
import { capitalizeName } from "../../_helpers/helper";
import SafetySideBar from "../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { useNavigate } from "react-router-dom";
import { employeeNameEmailLabel } from "../../_helpers/helper";
const ViewProfile = () => {
  const [userData, setUserData] = useState({});
  let navigate = useNavigate();

  const getUser = () => {
    setUserData(employeeNameEmailLabel());
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      {IS_MOBILE() && (
        <SafetySideBar pageTitle={"My Profile"} goBack={() => navigate(-1)} />
      )}
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Card className="userInfo">
            <div className="userInfoDetails">
              <h4>{userData?.name}</h4>
              <p className="mb-0">
                <span data-testid="emailText">E-mail: </span>{" "}
                {userData?.email ? userData?.email : ""}
              </p>
              <p className="mb-0">
                <span>Phone: </span>{" "}
                {userData?.contactNumber ? userData?.contactNumber : ""}
              </p>
              <p className="mb-0">
                <span>Mine: </span> {userData?.mine ? userData?.mine : ""}
              </p>
              <p className="mb-0">
                <span>Subsidiary: </span>{" "}
                {userData?.subsidiary ? userData?.subsidiary : ""}
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
