import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Button } from "reactstrap";
import {
  SidebarData,
  EnvSapApis,
  EnvSapColumnFields,
  exportEnvSap,
} from "./DATA";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import FuelDispenseFilterForm from "./FuelDispenseFilterForm";
// import FuelDispenseView from "./FuelDispenseView";
import filter from "../../../Assets/Images/filter.svg";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { IS_MOBILE } from "../../../_helpers/helper";
import { getEnvSapListWithPagination } from "../../../services/_reasonList/reasonList";
import EmptyDataList from "../../ContractorManagement/Hindrance/EmptyDataList";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { useForm } from "../../../_helpers/hooks";
import EnvImportSapView from "./EnvImportSapView";
import UploadFileFilterForm from "./UploadFileFilter";
const EnvImportSap = () => {
  const params = useParams();
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm({
    startDate: "",
    endDate: "",
  });
  let authUser = localStorage.getItem("auth_user");
  let user = authUser ? JSON.parse(authUser) : "";
  const getAll = async () => {
    const response = await getEnvSapListWithPagination("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };
  useEffect(() => {
    getAll();
  }, []);

  const onResetFilter = () => {
    setForm({
      startDate: "",
      endDate: "",
    });
    if (!params?.id) setGetAllFilter();
    else setGetAllFilter("&createdBy=" + user?.id);
  };

  useEffect(() => {
    getAll();
  }, []);

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const viewAsPopup = (form) => {
    return <EnvImportSapView form={form} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <UploadFileFilterForm
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          onChange={onChange}
          onResetFilter={onResetFilter}
          form={form}
        />
      </div>
    );
  };

  const onEdit = (id) => {
    navigate(`${IS_MOBILE() ? "/mobile" : ""}/fuel-management-system/${id}`);
  };

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }
    if (params?.id) filter += `&createdBy=${user?.id}`;
    setGetAllFilter(filter);
  }, [filterStatus]);

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${user?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);
  return isListData ? (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={params?.id ? "My Fuel Dispenses" : "View Fuel Dispenses"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                hideThreeDotButton={true}
                // deleteCheck={deleteCheckFn}
                fields={EnvSapColumnFields}
                apis={EnvSapApis}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                primaryKey="id"
                pagination
                deleteFun={true}
                hideEditButton={true}
                getAllFilter={getAllFilter}
                // hideAddButton
                breadCrumb={[
                  {
                    name: "Environment Management System",
                    path: "/",
                  },
                  {
                    name: "Environment Management",
                    path: "/environment-management/manage",
                  },
                  {
                    name: "Ambient Air Quality CAAQMS (API)",
                    path: "#",
                  },
                ]}
                // childrenAfterAddButton={tabButtons}
                downloadExcel={exportEnvSap}
                customTableTitle={"Ambient Air Quality CAAQMS (API)"}
                // editFunButton={onEdit}
                viewTitle={"Ambient Air Quality CAAQMS (API)"}
                isNoTabOnPage
                hideAddButton
                editPage={params?.id ? true : false}
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={"Ambient Air Quality CAAQMS (API)"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            breadCrumb={[
              {
                name: "Environment Management System",
                path: "/",
              },
              {
                name: "Environment Management",
                path: "/environment-management/manage",
              },
              {
                name: "Ambient Air Quality CAAQMS (API)",
                path: "#",
              },
            ]}
            title={"Ambient Air Quality CAAQMS (API)"}
            hideAddBtn
          />
        </div>
      </div>
    </div>
  );
};

export default EnvImportSap;
