import React from "react";
import ReactEcharts from "echarts-for-react";
import NoRecord from "../../components/Common/NoRecord";

const DonutChart = ({ dataArray, customName }) => {
  const data = dataArray || [];

  const colors = ["#5f6bc2", "#F0F0F0"]; // Light gray color palette
  // Find the maximum value in the data array
  const maxValue = Math.max(...data.map((item) => item.value));

  // Calculate the second value for the chart
  const secondValue = 100 - maxValue;

  const options = {
    series: [
      {
        type: "pie",
        radius: ["50%", "70%"],
        label: {
          show: false,
        },
        data: [
          {
            name: data[0]?.name || "",
            value: maxValue,
            itemStyle: {
              color: colors[0],
            },
          },
          {
            name: "Remaining",
            value: secondValue,
            itemStyle: {
              color: colors[1],
            },
          },
        ],
      },
    ],
    graphic: [
      {
        type: "text",
        left: "center",
        top: "center",
        style: {
          text: `${maxValue.toFixed(2)}%`,
          textAlign: "center",
          fontSize: 18,
          fontWeight: 600,
          fill: "#5f6bc2",
        },
      },
      {
        type: "group",
        left: "center",
        top: "90%",
        children: [
          {
            type: "text",
            left: "50%",
            top: 0,
            style: {
              text: customName,
              textAlign: "center",
              fontSize: 14,
              fill: "#212121",
              fontWeight: 600,
            },
          },
        ],
      },
    ],
  };

  return (
    <>
      {Array.isArray(data) && data.length > 0 ? (
        <ReactEcharts
          option={options}
          style={{ width: "100%", height: "185px" }}
        />
      ) : (
        <NoRecord />
      )}
    </>
  );
};

export default DonutChart;
