import React, { useEffect, useState } from "react";
import { getDashboardDataNit } from "../../../services/_contractorPerformance/contractorPerformance";
import { useForm } from "../../../_helpers/hooks";
import { DaysBetweenDates } from "../../../_helpers/helper";
import { isDecimal } from "../../../_helpers/helper";
import {
  Col,
  Container,
  Form,
  Row,
  Card,
  Table,
  Button,
  Modal,
} from "react-bootstrap";
import {
  BasicBarChartWithLabelDataFormat,
  mixLineBarDataFormat,
} from "../../../components/Chart/Common/config";
import { saveAsExcelXlsx } from "../../../components/export/exportExcel";
import { getAllContractorByNit } from "../../../services/_contractorPerformance/contractorPerformance";
import { BasicBarChartWithLabelCard } from "../../../components/ChartCard/ChartCard";
import { DDMMYYYYFormatBackslash } from "../../../_helpers/dateHelper";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { AiOutlineFileExcel } from "react-icons/ai";
import { getNitList } from "../../../services/_contractorPerformance/contractorPerformance";
import MixedLineBarChart from "../../../components/Common/MixBarChart";
import { getAllSites } from "../../../services/_uploadedFiles/uploadedFiles";
import {
  getHindranceSapDashboard,
  exportHindranceSapTable,
} from "../../../services/_hindrance/hindrance";
import {
  formFieldDataError,
  contractorPerformanceScoreDashboardForm,
  reasonType,
} from "./DATA";
import Select from "../../../components/Select/Select";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import PleaseSelect from "../../../components/Common/PleaseSelect";
import NoRecord from "../../../components/Common/NoRecord";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import FilesExport from "../../../components/Common/FilesExport";
import { SelectedDateLable } from "../../SafetyManagementSystem/IncidentManagement/Dashboard/DATA";
import { isArray } from "lodash";
import { permissionCheck } from "../../../_services/Auth/helper";
import { getAllPoList } from "../../../services/_poList/poList";
import DashboardDetailCard from "./DashboardDetailCard";
import { useDispatch, useSelector } from "react-redux";
import { setContractorDashFilter } from "../../../redux/actions/UserActions";

const ContractorHindranceDashboardSap = () => {
  const [form, onChange, setForm] = useForm({
    ...contractorPerformanceScoreDashboardForm,
  });
  const [sites, setSites] = useState("");

  const [allMineListDrop, setAllMineListDrop] = useState({});
  const [allsiteListDrop, setAllSiteListDrop] = useState({});
  const [formFieldError, setFormFieldError] = useState(formFieldDataError);
  const [nitList, setNitList] = useState([]);
  const [poList, setPoList] = useState([]);
  const [nitData, setNitData] = useState();
  const [allNitList, setallNitList] = useState([]);
  const [show, setShow] = useState(false);
  const [chart, setChart] = useState({});
  const [achievedTillDate, setAchievedTillDate] = useState("");
  const [mixBarLine, setMixBarLine] = useState({});
  const [equipmentAvlType, setEquipmentAvlType] = useState({});
  const [reasonTable, setReasonTable] = useState({});
  const [materialType, setMaterialType] = useState("coal");
  const [unit, setUnit] = useState("Tonnes");
  const [allowedType, setAllowedType] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [contractorList, setContractorList] = useState([]);
  const [minMaxDate, setMinMaxDate] = useState({});
  const [storeFlag, setStoreFlag] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storeFilter = useSelector(
    (state) => state?.dashboardFilter?.contractorDash
  );

  const getAllNitList = async () => {
    const allNitListData = await getNitList(form?.vendor);
    if (allNitListData?.status) {
      setallNitList(
        allNitListData?.data.filter((entry) => entry?.site_id === form?.mine)
      );
      setNitList(
        allNitListData?.data
          ?.filter(
            (entry) => entry?.site_id === form?.mine && entry?.status === 1
          )
          .map((d) => {
            return { value: d.id, label: d.nit };
          })
      );
    }
  };
  const getPoList = async () => {
    let res = await getAllPoList();
    let poOpt = res.data.map((d) => {
      return {
        value: d.id,
        nit: d.nit_id,
        label: d.po,
      };
    });
    setPoList(poOpt);
  };

  useEffect(() => {
    setFormFieldError({});
    getNitForDashboard();
    getPoList();
  }, []);

  useEffect(() => {
    if (materialType === "coal") {
      setUnit("Tonnes");
    } else if (materialType === "ob") {
      setUnit("CuM");
    }
  }, [materialType]);
  useEffect(() => {
    if (form.vendor) return;
    else setNitList([]);
  }, [form.vendor]);
  const getDashboardData = async () => {
    if (!form.tender) return;
    let queryString = "";

    if (form.po) {
      queryString += `&po=${form?.po}`;
    }
    if (form.vendor) {
      queryString += `&contractor_id=${form?.vendor}`;
    }
    if (form.tender) {
      queryString += `&tender=${form?.tender}`;
    }
    if (form?.mine) {
      queryString += `&mine=${form?.mine}`;
    }
    if (form.startDate) {
      queryString += `&startDate=${form?.startDate}`;
    }
    if (form.endDate) {
      queryString += `&endDate=${form?.endDate}`;
    }
    if (materialType === "") return;
    queryString += `&materialType=${materialType}`;
    let response = await getHindranceSapDashboard(queryString);
    if (response?.status) {
      let barChartCont = BasicBarChartWithLabelDataFormat(
        response?.data?.data?.barChart?.outputCont
      );
      let barChartOther = BasicBarChartWithLabelDataFormat(
        response?.data?.data?.barChart?.outputOther
      );
      setChart({ barChartCont: barChartCont, barChartOther: barChartOther });
      let mixLineBar = mixLineBarDataFormat(response?.data?.data?.barLineChart);
      setMixBarLine(mixLineBar);
      setMinMaxDate(response?.data?.data?.minMax_date || {});
      const sum = response?.data?.data?.EquipmentAvailabilityTable.reduce(
        (acc, curr) => acc + curr.actual,
        0
      );

      setAchievedTillDate(sum);
    }

    setEquipmentAvlType(response?.data?.data?.EquipmentAvailabilityTable);
    setReasonTable(response?.data?.data?.tableReasonData);
  };
  useEffect(() => {
    if (!applyFilter) return;
    getDashboardData();
    setApplyFilter(false);
  }, [applyFilter]);

  useEffect(() => {
    getDashboardData();
  }, [form.mine, form.tender, form.vendor, materialType, form.po]);

  const getSiteList = async () => {
    let allsiteList = await getAllSites();
    if (allsiteList?.status === 1 && allsiteList?.message === "success") {
      setSites(allsiteList?.data);
      let dataObjSite = [];
      allsiteList?.data?.forEach((data) => {
        if (data.parentSite.id) {
          const labelExists = dataObjSite.some(
            (obj) => obj.label === data.parentSite.name
          );
          if (!labelExists) {
            dataObjSite.push({
              label: data.parentSite.name,
              value: data.parentSite.id,
            });
          }
        }
      });
      setAllSiteListDrop(dataObjSite);
    }
  };

  useEffect(() => {
    let dataObjMine = [];
    if (sites.length > 0) {
      sites?.forEach((data) => {
        if (data.siteType === 3) {
          dataObjMine.push({
            value: data.id,
            label: data.name,
          });
        }
      });
    }
    setAllMineListDrop(dataObjMine);
  }, [allsiteListDrop]);

  useEffect(() => {
    if (allMineListDrop.length === 1 && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({ ...prevForm, mine: allMineListDrop[0].value }));
    }
  }, [allMineListDrop]);

  const ventorList = async () => {
    if (!form?.mine) return;
    let site = `site_id=${form?.mine}`;
    const allContractorList = await getAllContractorByNit(site);
    setContractorList(
      allContractorList?.map((d) => {
        return { value: d.id, label: d.contractor };
      })
    );
  };

  useEffect(() => {
    getSiteList();
    ventorList();
  }, []);

  useEffect(() => {
    if (allsiteListDrop.length === 1 && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({
        ...prevForm,
        subsidiary: allsiteListDrop[0]?.value,
      }));
    }
  }, [allsiteListDrop]);

  useEffect(() => {
    if (allNitList) {
      const foundObject = allNitList.find((obj) => obj.id === form?.tender);
      if (foundObject) {
        setNitData(foundObject);
      }
      if (foundObject?.production_type === "2") setMaterialType("ob");
      if (
        foundObject?.production_type === "1" ||
        foundObject?.production_type === "3"
      )
        setMaterialType("coal");
    }
  }, [allNitList, form?.tender]);

  const handleClose = () => {
    getDashboardData();
    setShow(false);
  };
  const handleShow = () => {
    getDashboardData();
    setShow(true);
  };
  const resetFilter = () => {
    setForm((prev) => ({ ...prev, startDate: "", endDate: "" }));
  };

  useEffect(() => {
    if (form?.subsidiary && isArray(sites) && sites.length > 0) {
      let mines = sites?.filter((obj) => obj.parentSiteId === form?.subsidiary);
      let dataObjMine = [];
      if (mines.length > 0) {
        mines?.forEach((data) => {
          dataObjMine.push({
            value: data.id,
            label: data.name,
          });
        });
      }
      setAllMineListDrop(dataObjMine);
    } else {
      setAllMineListDrop([]);
    }
  }, [form?.subsidiary, sites]);

  useEffect(() => {
    if (form?.mine) {
      ventorList();
      let mines = allNitList?.filter(
        (obj) => obj.site_id === form?.mine && obj.status === 1
      );
      let dataObjMine = [];
      if (mines?.length > 0) {
        mines?.forEach((data) => {
          dataObjMine.push({
            value: data.id,
            label: data.tender_id,
          });
        });
      }
      setNitList(dataObjMine);
    } else setNitList([]);
  }, [form?.mine]);

  useEffect(() => {
    setNitData(allNitList?.find((obj) => obj.id === form?.tender));
  }, [allNitList, form?.tender]);

  // console.log("setForm++++", form);
  // console.log("allNitList++++", allNitList);
  // console.log("site++++", sites);

  useEffect(() => {
    if (form.vendor) getAllNitList();
  }, [form.vendor]);
  const downloadExcel = async () => {
    let filterForExcel = "&importExcel=true";
    if (form.vendor) {
      filterForExcel += `&contractor_id=${form?.vendor}`;
    }
    if (form.po) {
      filterForExcel += `&po=${form?.po}`;
    }
    if (form.tender) {
      filterForExcel += `&tender=${form?.tender}`;
    }
    if (form.mine) {
      filterForExcel += `&mine=${form?.mine}`;
    }
    if (form.startDate) {
      filterForExcel += `&startDate=${form?.startDate}`;
    }
    if (form.endDate) {
      filterForExcel += `&endDate=${form?.endDate}`;
    }
    filterForExcel += `&materialType=${materialType}`;
    let response = await exportHindranceSapTable(filterForExcel);
    if (response?.data && response?.status) {
      await saveAsExcelXlsx(
        response?.data,
        response?.column,
        response?.fileName
      );
    }
  };
  // To get mine vendor subsidiary for which data available
  const getNitForDashboard = async () => {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    let res = await getDashboardDataNit(
      "contractor_hindrance_sap",
      auth_user?.siteId
    );
    if (res.data != null && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({
        ...prevForm,
        vendor: res?.data?.data?.contractor_id,
        tender: res?.data?.data?.nit_id,
        mine: res?.data?.data?.site_id || prevForm.mine,
        subsidiary:
          res?.data?.data?.site?.parentSite?.id || prevForm.subsidiary,
      }));
    }
  };
  useEffect(() => {
    if (nitData?.production_type === "3") setAllowedType(["ob", "coal"]);
    else if (nitData?.production_type === "2") setAllowedType(["ob"]);
    else if (nitData?.production_type === "1") setAllowedType(["coal"]);
    else setAllowedType([]);
  }, [nitData?.production_type]);

  useEffect(() => {
    setMaterialType("");
  }, [form?.vendor]);

  useEffect(() => {
    if (!storeFlag) return;
    if (!Object.keys(storeFilter)?.length) {
      setStoreFlag(false);
      return;
    }
    setForm((prev) => ({ ...prev, ...storeFilter }));
    setStoreFlag(false);
  }, [setForm, storeFilter, storeFlag]);

  useEffect(() => {
    if (storeFlag) return;
    let formObj = {};
    Object.keys(form)?.forEach((d) => {
      if (d !== "startDate" && d !== "endDate") formObj[d] = form?.[d];
    });
    dispatch(setContractorDashFilter(formObj));
  }, [dispatch, form, storeFlag]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="dashboardTabs coalGradeScreenTabs">
            <ul>
              {permissionCheck(["contractor_hindrance_dashboard"]) && (
                <li
                  className="active"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/hindrance`
                    )
                  }
                >
                  Contractor Hindrance
                </li>
              )}
              {permissionCheck(["performance_production_dashboard"]) && (
                <li
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/production-performance`
                    )
                  }
                >
                  Production Performance
                </li>
              )}
              {permissionCheck([
                "contractor_performance_score_card_dashboard",
              ]) && (
                <li
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/performance-score-card-vendor`
                    )
                  }
                  data-testid="nav-link-3"
                >
                  Contractor Score Card
                </li>
              )}
              {permissionCheck(["contractor_performance_dashboard"]) && (
                <li
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/performance`
                    )
                  }
                >
                  Contractor Performance
                </li>
              )}
            </ul>
            <SelectedDateLable form={form} minMaxDate={minMaxDate} />
            <FilesExport id={`#${"ContractorHindrance"}`} fullScreen />
            <div
              className="dashboardFilter"
              data-testid="handleShowFun"
              onClick={handleShow}
            ></div>
          </div>
          <Container fluid>
            <div className="dashboardWrapper innerDashboards">
              <Row id="ContractorHindrance">
                <div class="dashboardHeader breadcrumbWithTitle d-none">
                  <MainBreadcrumb
                    routeSegments={[
                      {
                        name: "Contractor Management",
                        path: "#",
                      },
                      {
                        name: "Contractor Hindrance Dashboard",
                        path: "#",
                      },
                    ]}
                  />
                  <h3 class="title mb-0">Contractor Hindrance Dashboard</h3>
                </div>

                <div>
                  <Form>
                    <div className="topFilterSec">
                      <div className="colindiaCard borderRadius5">
                        <Row>
                          <Col lg={2}>
                            <Select
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  tender: "",
                                  mine: "",
                                  vendor: "",
                                }));
                              }}
                              value={form?.subsidiary}
                              name="subsidiary"
                              options={allsiteListDrop}
                              label={"Subsidiary"}
                              disabled={
                                allsiteListDrop.length === 1 ? true : false
                              }
                            />
                          </Col>
                          <Col lg={2}>
                            <Select
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  tender: "",
                                  vendor: "",
                                }));
                              }}
                              value={form?.mine}
                              name="mine"
                              options={allMineListDrop}
                              label={"Mine"}
                              disabled={
                                allMineListDrop.length === 1 ? true : false
                              }
                            />
                          </Col>
                          <Col lg={4}>
                            <Select
                              options={form?.mine ? contractorList : []}
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  tender: "",
                                }));
                              }}
                              value={form?.vendor}
                              name="vendor"
                              label={"Vendor"}
                            />
                          </Col>
                          <Col lg={2}>
                            <Select
                              onChange={onChange}
                              value={form?.tender}
                              name="tender"
                              options={form?.subsidiary ? nitList : []}
                              label={"NIT"}
                            />
                          </Col>
                          <Col lg={2}>
                            <Select
                              blankSelect="Select All"
                              onChange={onChange}
                              value={form?.po}
                              name="po"
                              options={
                                form?.tender
                                  ? poList.filter(
                                      (item) => item.nit === form?.tender
                                    )
                                  : []
                              }
                              label={"PO"}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {form?.tender &&
                      form?.vendor &&
                      form.subsidiary &&
                      form.mine && (
                        <DashboardDetailCard
                          nitData={nitData}
                          unit={unit}
                          achievedTillDate={achievedTillDate}
                        />
                      )}

                    {form?.tender && (
                      <Row className="pt-3">
                        <Col md={2}>
                          <div className="dashboardTabs coalGradeScreenTabs ">
                            <ul>
                              {allowedType.includes("coal") ? (
                                <li
                                  className={`scoreCardDashboards ${
                                    materialType === "coal" ? "active" : ""
                                  }`}
                                  onClick={() => setMaterialType("coal")}
                                >
                                  Coal
                                </li>
                              ) : (
                                ""
                              )}
                              {allowedType.includes("ob") ? (
                                <li
                                  className={`scoreCardDashboards ${
                                    materialType === "ob" ? "active" : ""
                                  }`}
                                  onClick={() => setMaterialType("ob")}
                                >
                                  OB
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </div>
                        </Col>
                        <Col md={12}>
                          <Card className="chartCard mb-3">
                            <Card.Header>
                              <span className="d-flex justify-content-between align-items-center">
                                Hindrance Information{" "}
                                {Array.isArray(equipmentAvlType) &&
                                  equipmentAvlType?.length > 0 && (
                                    <span className="btn-icon excel">
                                      <AiOutlineFileExcel
                                        onClick={() => downloadExcel()}
                                        size="25"
                                        title="Download excel"
                                      />
                                    </span>
                                  )}
                              </span>
                            </Card.Header>
                            <Card.Body
                              className="p-0 scroll-table overflow-scroll"
                              style={{ height: "250px" }}
                            >
                              <div className="webformCardContent table-responsive">
                                <table className="avgTblData hindranceLrgTbl table table-striped table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Target ({unit})</th>
                                      <th>Actuals ({unit})</th>
                                      <th>Total Shortfall ({unit})</th>
                                      <th>
                                        Shortfall due to Contractor ({unit})
                                      </th>
                                      <th>
                                        Shortfall other than Contractor ({unit})
                                      </th>
                                      <th>% Attributable to Contractor</th>
                                      <th>Reasons by Contractor </th>
                                      <th>Reasons by Other </th>
                                      <th>Remarks</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Array.isArray(equipmentAvlType) &&
                                      equipmentAvlType.map((d, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>
                                              {DDMMYYYYFormatBackslash(d?.date)}
                                            </td>
                                            <td>{isDecimal(d?.target)}</td>
                                            <td>{isDecimal(d?.actual)}</td>
                                            <td>
                                              {d?.shortFall
                                                ? isDecimal(d?.shortFall)
                                                : "0.00"}
                                            </td>
                                            <td>
                                              {d?.shrtDueContractor
                                                ? isDecimal(
                                                    d?.shrtDueContractor
                                                  )
                                                : "0.00"}
                                            </td>
                                            <td>
                                              {d?.shrtOtherThanContractor
                                                ? isDecimal(
                                                    d?.shrtOtherThanContractor
                                                  )
                                                : "0.00"}
                                            </td>
                                            <td>
                                              {d?.actual < d?.target
                                                ? isDecimal(
                                                    (d?.shrtDueContractor /
                                                      d?.shortFall) *
                                                      100
                                                  ) + "%"
                                                : "0%"}
                                            </td>
                                            <td>{d?.reasonByContractor}</td>
                                            <td>{d?.reasonForShortfall}</td>
                                            <td>{d?.comments}</td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                                {Array.isArray(equipmentAvlType) &&
                                  !equipmentAvlType?.length > 0 && (
                                    <span className="text-center">
                                      <NoRecord />
                                    </span>
                                  )}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Form>
                  {form?.tender ? (
                    <Row>
                      <Col lg={4} id="zoomSec_ActualCommit">
                        <Card className="chartCard chartCardBtmSpace ">
                          <Card.Header className="d-flex align-items-center justify-content-between">
                            <span> Achieved vs Target</span>
                            <ZoomScreen
                              id={`zoomSec_ActualCommit`}
                              cardSizeClass="col-md-4"
                            />
                          </Card.Header>
                          <Card.Body>
                            <MixedLineBarChart
                              category={mixBarLine?.type}
                              lineData={mixBarLine?.line_data}
                              barData={mixBarLine?.bar_data}
                              barData2={mixBarLine?.bar_data2}
                              lineName={"Plan"}
                              barName={"Target"}
                              barName2={"Achieved"}
                              legendFlag={true}
                              yAxisLeft={unit}
                              yAxisRight=""
                              height="273px"
                              width="100%"
                              xAxisName="Achieved vs Target MTD "
                              customToolTip
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={4} id="zoomSec_">
                        <Card className="chartCard chartCardBtmSpace ">
                          <Card.Header className="d-flex align-items-center justify-content-between">
                            <span>Hindrance Reason Breakdown</span>
                            <Select
                              blankSelect={"none"}
                              onChange={onChange}
                              value={form?.reasonType}
                              name="reasonType"
                              options={reasonType}
                              label={""}
                              width={"50%"}
                              selectClass={"none"}
                            />
                            <ZoomScreen
                              id={`zoomSec_`}
                              cardSizeClass="col-md-4"
                            />
                          </Card.Header>
                          <Card.Body
                            className="pb-4"
                            style={{ height: "305px" }}
                          >
                            {form.reasonType === "contractorReason" ? (
                              <>
                                {Array.isArray(
                                  chart?.barChartCont?.categoryData
                                ) &&
                                Array.isArray(chart?.barChartCont?.data) &&
                                chart?.barChartCont?.categoryData.length > 1 ? (
                                  <BasicBarChartWithLabelCard
                                    yAxisName={"Count"}
                                    category={chart?.barChartCont?.categoryData}
                                    seriesData={chart?.barChartCont?.data}
                                  />
                                ) : (
                                  <NoRecord />
                                )}
                              </>
                            ) : (
                              <>
                                {Array.isArray(
                                  chart?.barChartOther?.categoryData
                                ) &&
                                Array.isArray(chart?.barChartOther?.data) &&
                                chart?.barChartOther?.categoryData.length >
                                  1 ? (
                                  <BasicBarChartWithLabelCard
                                    yAxisName={"Count"}
                                    category={
                                      chart?.barChartOther?.categoryData
                                    }
                                    seriesData={chart?.barChartOther?.data}
                                  />
                                ) : (
                                  <NoRecord />
                                )}
                              </>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card className="chartCard mb-3">
                          <Card.Body className="p-0">
                            <Card.Header className="d-flex align-items-center justify-content-between">
                              <span> Top 5 Hindrance Reasons</span>
                              <Select
                                blankSelect={"none"}
                                onChange={onChange}
                                value={form?.topReasonType}
                                name="topReasonType"
                                options={reasonType}
                                label={""}
                                width={"50%"}
                                selectClass={"none"}
                              />
                            </Card.Header>
                            <div className="hindranceTblSmall table-responsive">
                              {form?.topReasonType === "contractorReason" ? (
                                <Table
                                  striped
                                  bordered
                                  hover
                                  className="mb-0 avgTblData hindranceTbl"
                                  style={{
                                    ...(reasonTable &&
                                      reasonTable?.reasonByPresentCont?.length >
                                        0 && {
                                        height: "305px",
                                      }),
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Top 5 Hindrance Reasons</th>
                                      <th>Distribution %</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {reasonTable &&
                                      reasonTable?.reasonByPresentCont?.length >
                                        0 &&
                                      [
                                        ...reasonTable.reasonByPresentCont.slice(
                                          0,
                                          5
                                        ),
                                      ].map((d, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>{d?.reason}</td>
                                            <td>
                                              {isDecimal(d?.percent)
                                                ? isDecimal(d?.percent) + "%"
                                                : ""}
                                            </td>
                                          </tr>
                                        );
                                      })}

                                    {Array.isArray(reasonTable) &&
                                      !reasonTable?.reasonByPresentCont
                                        ?.length > 0 && (
                                        <span
                                          className="text-center"
                                          style={{
                                            height: "280px",
                                          }}
                                        >
                                          <NoRecord />
                                        </span>
                                      )}
                                  </tbody>
                                </Table>
                              ) : (
                                <Table
                                  striped
                                  bordered
                                  hover
                                  className="mb-0 avgTblData hindranceTbl"
                                  style={{
                                    ...(reasonTable &&
                                      reasonTable?.reasonByPresentOther
                                        ?.length > 0 && {
                                        height: "305px",
                                      }),
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Top 5 Hindrance Reasons</th>
                                      <th>Distribution %</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {reasonTable &&
                                      reasonTable.reasonByPresentOther &&
                                      reasonTable.reasonByPresentOther.length >
                                        0 &&
                                      [
                                        ...reasonTable.reasonByPresentOther.slice(
                                          0,
                                          5
                                        ),
                                      ].map((d, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>{d?.reason}</td>
                                            <td>{isDecimal(d?.percent)}% </td>
                                          </tr>
                                        );
                                      })}

                                    {Array.isArray(reasonTable) &&
                                      !reasonTable?.reasonByPresentOther
                                        ?.length > 0 && (
                                        <span
                                          className="text-center"
                                          style={{
                                            height: "280px",
                                          }}
                                        >
                                          <NoRecord />
                                        </span>
                                      )}
                                  </tbody>
                                </Table>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  ) : (
                    <div className="text-center">
                      <PleaseSelect type={"NIT ID"} />
                    </div>
                  )}
                </div>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      {/* Filter Modal */}
      <Modal
        className="filterModal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="px-3">
          <h5 className="modal-title">Filter</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <Row>
              <label className="pb-2">Date Duration</label>
              <Col lg={12}>
                <CustomDatePicker
                  dateFormat="YYYY-MM-DD"
                  value={form?.startDate}
                  name="startDate"
                  onChangeDate={onChange}
                  placeholder="Start Date"
                />
              </Col>
              <Col lg={12}>
                <CustomDatePicker
                  //className={"cndrPosition"}
                  value={form?.endDate}
                  dateFormat="YYYY-MM-DD"
                  name="endDate"
                  onChangeDate={onChange}
                  placeholder="End Date"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="filterBtn">
            <Button
              style={{ minWidth: "auto" }}
              className="secondaryBtn"
              data-testid="resetFilter"
              onClick={() => resetFilter()}
            >
              Reset
            </Button>
            <Button
              style={{ minWidth: "auto" }}
              className="primaryBtn"
              data-testid="close-message-popup"
              onClick={() => handleClose()}
            >
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContractorHindranceDashboardSap;
