import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  EventFlowFileTypeList,
  envFileType,
  SidebarData,
  reportTypeListTableLabel,
} from "./DATA";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import "../../SafetyManagementSystem/IncidentManagement/IncidentMgmt.scss";
import linkImg1 from "../../../Assets/Images/selectionIcon1.svg";
import linkImg2 from "../../../Assets/Images/selectionIcon2.svg";
import linkImg3 from "../../../Assets/Images/selectionIcon3.svg";
import linkImg4 from "../../../Assets/Images/selectionIcon4.svg";
import linkImg5 from "../../../Assets/Images/selectionIcon5.svg";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
const EnvironmentReportManagement = () => {
  const [fileName, setFileName] = useState("");
  const url = useLocation();
  useEffect(() => {
    envFileType.forEach((d) => {
      if (url.pathname.split("/").includes(d)) {
        setFileName(d);
      }
    });
  }, []);
  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <h3
                className="title"
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Environment Management System",
                      path: "#",
                    },
                    {
                      name: "Environment Management",
                      path: "/environment-management/manage",
                    },
                    {
                      name: reportTypeListTableLabel[fileName],
                      path: "#",
                    },
                  ]}
                  // module={ACTIVE_MODULE.incident}
                />
              </h3>
              {fileName && (
                <div className="MgmtWrapper">
                  <Row className="dashboardItem">
                    {[
                      {
                        img: linkImg1,
                        label: "Upload File",
                        link:
                          "/environment-management/manage/upload?type=" +
                          fileName,
                        // role: ["contractor_dashboard"],
                      },
                      {
                        img: linkImg1,
                        label: "Uploaded File History",
                        link:
                          "/environment-management/manage/uploaded-files?type=" +
                          fileName,
                        // role: ["contractor_dashboard"],
                      },
                      {
                        img: linkImg1,
                        label: "Uploaded File Data",
                        link:
                          "/environment-management/manage/all-data?type=" +
                          fileName,
                        // role: ["contractor_dashboard"],
                      },
                    ]?.map((d, idx) => {
                      return (
                        <Col lg={4} key={idx + "_key"} className="mb-4">
                          <Link to={d?.link}>
                            <div className="dashboardItemContent">
                              <div className="imgArea">
                                <img
                                  src={d.img}
                                  className="img-fluid"
                                  alt="dashboardItemIcons"
                                />
                              </div>
                              <label>{d.label}</label>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnvironmentReportManagement;
