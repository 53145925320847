import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";

const ApproveReject = ({
  showApproveButton,
  showRejectButton,
  rowContent,
  onClickApprove,
  onClickReject,
}) => {
  return (
    <>
      {showApproveButton && (
        <span
          className="icons text-success pointer me-2"
          onClick={() => onClickApprove(rowContent)}
          title="Approve"
        >
          <FaCheck size={18} />
        </span>
      )}
      {showRejectButton && (
        <span
          className="icons text-danger pointer"
          onClick={() => onClickReject(rowContent)}
          title="Reject"
        >
          <FaTimes size={18} />
        </span>
      )}
    </>
  );
};

export default ApproveReject;
