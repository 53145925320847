import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  BasicBarChartCardWithCustomToolTip,
  MultipleBarChartCard,
} from "../../../components/ChartCard/ChartCard";
import { noiseCmpdilPageChartList } from "./DATA";
import { isArray } from "lodash";
import Select from "../../../components/Select/Select";
import MapComponet from "../../../components/MapComponet/MapComponet";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import { SimpleBarChart } from "../../../components/Common/SimpleBarChart";

const NoiseLevelCmpdil = ({ data, form, onChange, pageType }) => {
  // console.log('noise' , data)
  return (
    <div className="dashboardWrapper">
      <Row id={`${"screen" + pageType}`}>
        {noiseCmpdilPageChartList?.map((d, idx) => {
          return d?.type === "map" ? (
            <Col md={12}>
              <Card className="chartCard mb-3">
                <MapComponet data={data?.mapData} />
              </Card>
            </Col>
          ) : (
            <Col md={6} id={`zoomSec_${idx}`} key={idx}>
              <Card className="chartCard chartCardBtmSpace CMPDIL_chart">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <span>{d?.name}</span>
                  <div className="filterZoomSec">
                    {!d?.hideFilter && (
                      <Col className="mbRemove">
                        <Select
                          name={d?.value}
                          // label={"Incident Type"}
                          value={form?.[d?.value]}
                          onChange={onChange}
                          blankSelect={"none"}
                          options={d?.options}
                        />
                      </Col>
                    )}
                    <ZoomScreen id={`zoomSec_${idx}`} />
                  </div>
                </Card.Header>
                <Card.Body>
                  {d?.type === "multiLine" && (
                    <MultipleBarChartCard
                      // xAxisName={item?.xAxisName}
                      yAxisName={d?.yAxisName}
                      seriesData={
                        isArray(data?.[d?.value]?.data) &&
                        data[d?.value]?.data.map((d) => ({
                          ...d,
                          // type: item.type === "multiLine" ? d.type : item.type,
                        }))
                      }
                      category={data?.[d?.value]?.categoryData}
                      customToolTip
                      minMaxData={data?.[d?.value]?.minMaxData}
                    />
                  )}
                  {d?.type === "barWithCustomTooltip" && (
                    <SimpleBarChart
                      yAxisName={d?.yAxisName}
                      xAxis={data[d?.value]?.xAxis}
                      series={data[d?.value]?.series}
                      legend={data[d?.value]?.legend}
                      customTooltip
                      height="270px"
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default NoiseLevelCmpdil;
