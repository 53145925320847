import React from "react";
import { Col, Row } from "react-bootstrap";

const InvestigationView = ({ form }) => {
  return (
    <>
      <Row>
        <Col lg={6}>
          <label>Root Cause Description</label>
          <p>{form?.root_desc}</p>
        </Col>
        <Col lg={6}>
          <label>Root Cause</label>
          <p>{(form?.root_cause === 1 && "Yes") || "Not Applicable"}</p>
        </Col>
      </Row>
      <Row>
        {form?.incidentRootCauseBranches?.map((data, index) => {
          return (
            <>
              <Col md="6">
                <div className="form-group">
                  <label>Branch Number</label>
                  <p>{data.branch_number}</p>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label>Branch Description</label>
                  <p>{data.branch}</p>
                </div>
              </Col>
              <Col md="12">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="tableHead-bg">
                      <tr>
                        <th style={{ width: "200px" }}>Leaf Number</th>
                        <th>Leaf</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.incidentRootCauseLeafs.map((d, i) => {
                        return (
                          <tr key={i}>
                            <th>{d.leaf_number}</th>
                            <td>
                              <p>{d.leaf}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </>
          );
        })}
      </Row>
    </>
  );
};

export default InvestigationView;
