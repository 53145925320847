import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import "./Dashboard.css";
import TabFilterButton from "../../../components/TabFilterButton/TabFilterButton";
import {
  CGSlippagePageChartList,
  CHART_TYPE_BY_DATA,
  PAGE_TYPE_BY_CHART_FILTER,
  PAGE_TYPE_BY_CHART_LIST,
  Tabs,
  chartFilter,
  nestedChartTabs,
} from "./DATA";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import { BasicBarChartCardWithCustomToolTip } from "../../../components/ChartCard/ChartCard";
import Select from "../../../components/Select/Select";
import {
  coalGradeSingleChart,
  coalGradeSlippageReport,
} from "../../../services/_coalGrade/coalGrade";
import { SimpleBarChart } from "../../../components/Common/SimpleBarChart";
import { BiCalendar } from "react-icons/bi";
import NestedPieChart from "../../../components/Common/NestedPieChart";
import StackWithLineChart from "../../../components/Common/StackWithLineChart";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";

const CGSlippageReport = ({
  onChange,
  cref,
  form,
  pageType,
  appliedFilters,
  customerList = [],
  topSelectedTab,
  setMinMaxDate,
}) => {
  const [selectedTab, setSelectedTab] = useState("M");
  const [chartData, setChartData] = useState({});
  const [chartFilterForm, setChartFilterForm] = useState(
    PAGE_TYPE_BY_CHART_FILTER(+pageType)
  );
  const [actionChartName, setActionChartName] = useState("");

  const fetchData = useCallback(
    async (appliedFilters, isClickApply) => {
      if (isClickApply) {
        setChartFilterForm(PAGE_TYPE_BY_CHART_FILTER(+pageType));
        setActionChartName("");
        setSelectedTab("M");
      }
      let response = await coalGradeSlippageReport(appliedFilters);
      if (!response?.status) {
        setChartData({});
        return;
      }
      const resData = response.data;
      setMinMaxDate(resData?.minMax_date || {});
      CGSlippagePageChartList?.forEach((item) => {
        setChartData((prev) => ({
          ...prev,
          [item?.value]: CHART_TYPE_BY_DATA(item?.type, resData?.[item?.value]),
        }));
      });
    },
    [pageType]
  );

  useEffect(() => {
    let isClickApply = true;
    let finalFltr = `${appliedFilters || ""}&tab=${topSelectedTab}`;
    fetchData(finalFltr, isClickApply);
  }, [fetchData, topSelectedTab]);

  useImperativeHandle(
    cref,
    () => {
      let finalFltr = `${appliedFilters || ""}&tab=${topSelectedTab}`;
      let isClickApply = true;
      return {
        fetchData: () => fetchData(finalFltr, isClickApply),
      };
    },
    [appliedFilters, topSelectedTab, fetchData]
  );

  const finalChartList = useMemo(() => {
    return PAGE_TYPE_BY_CHART_LIST(pageType)?.map((d, idx) => {
      return d?.value === "coalDispatchedbarChart" ||
        d?.value === "MultibarChart"
        ? { ...d, options: customerList }
        : d;
    });
  }, [customerList, pageType]);

  const singleDashboardFun = useCallback(
    async (filter) => {
      if (!actionChartName) return;
      let response = await coalGradeSingleChart(`${+pageType}`, filter);
      if (!response?.status) return;
      const chartTypeObj =
        PAGE_TYPE_BY_CHART_LIST(+pageType).find(
          (d) => d?.value === actionChartName
        ) || {};
      setChartData((prev) => ({
        ...prev,
        [actionChartName]: CHART_TYPE_BY_DATA(
          chartTypeObj?.type,
          response?.data
        ),
      }));
    },
    [actionChartName, pageType]
  );

  useEffect(() => {
    if (!actionChartName) return;
    let filter = "";
    filter = `graphName=${actionChartName}&graphKey=${
      chartFilterForm[actionChartName]
    }${appliedFilters || ""}&tab=${topSelectedTab}`;
    let finalFilter =
      actionChartName === "coalGradeSlippagebarChart"
        ? `${filter}&slippageTabBarChart=${selectedTab}`
        : filter;
    singleDashboardFun(finalFilter);
  }, [actionChartName, chartFilterForm, selectedTab, singleDashboardFun]);

  const onChangeChart = ({ target: { name, value } }) => {
    setChartFilterForm((prev) => ({ ...prev, [name]: value }));
    setActionChartName(name);
  };

  const handleChange = (val) => {
    setSelectedTab(val);
    setActionChartName("coalGradeSlippagebarChart");
  };
  return (
    <div className="dashboardWrapper">
      <Row className="chart-listing" id={`${"screen" + pageType}`}>
        {finalChartList?.map((d, idx) => {
          return (
            <Col md={6} key={idx} id={`zoom_${idx}`}>
              <Card
                className={`chartCard chartCardBtmSpace CAAQMS_chart ${
                  d?.className || ""
                }`}
              >
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>
                    {d?.name ===
                      "Grade Slippage (3rd Party lab-wise comparison)" &&
                    topSelectedTab === "cil"
                      ? "Grade Slippage (CIL Lab)"
                      : d?.name}
                  </span>
                  <div className="filterZoomSec">
                    {!d?.hideFilter && (
                      <Col className="mbRemove gradeSlippageSec">
                        {d.value == "coalGradeSlippagebarChart" && (
                          <Select
                            name={d?.value}
                            value={chartFilterForm?.[d?.value]}
                            onChange={onChangeChart}
                            options={d?.options}
                            blankSelect={"none"}
                          />
                        )}
                        {!d.hideTabFilter && (
                          <Dropdown className="fuelConsumptionDays">
                            <Dropdown.Toggle>
                              <BiCalendar />
                            </Dropdown.Toggle>
                            {selectedTab?.charAt(0)?.toUpperCase()}
                            <Dropdown.Menu>
                              <div className="tab alphabetToggle">
                                {chartFilter.slice(2, 6).map((option) => (
                                  <TabFilterButton
                                    key={option?.id}
                                    label={option?.label}
                                    id={option?.id}
                                    toggleValue={option?.value}
                                    handleChange={() =>
                                      handleChange(option?.value)
                                    }
                                    selectedTab={selectedTab}
                                  />
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </Col>
                    )}
                    <ZoomScreen id={`zoom_${idx}`} cardSizeClass="col-md-6" />
                  </div>
                </Card.Header>

                <Card.Body>
                  {d?.type === "barWithCustomTooltip" && (
                    <BasicBarChartCardWithCustomToolTip
                      yAxisName={d?.yAxisName}
                      xAxisName={d?.xAxisName}
                      category={chartData[d?.value]?.categoryData}
                      seriesData={chartData[d?.value]?.data}
                      customToolTip={chartData[d?.value]?.dataList}
                      valueWithUnit={d?.yAxisName}
                    />
                  )}
                  {d?.type === "stack" && (
                    <StackWithLineChart
                      yAxisName={d?.yAxisName}
                      category={chartData[d?.value]?.categoryData}
                      series={chartData[d?.value]?.data || []}
                      // customTooltip
                      customCoalGradeTooltip
                    />
                  )}
                  {d?.type === "multiBar" && (
                    <SimpleBarChart
                      yAxisName={d?.yAxisName}
                      height={"272px"}
                      xAxis={{
                        type: "category",
                        data: chartData[d?.value]?.categoryData,
                      }}
                      yAxis={{
                        type: "value",
                        boundaryGap: [0, 0.01],
                        name: d?.yAxisName,
                      }}
                      series={chartData[d?.value]?.data}
                      // customTooltip
                      coalGradeToolTip
                    />
                  )}
                  {d?.type === "nestPie" && (
                    <>
                      <div className="tab alphabetToggle labWisComparisonTab">
                        {nestedChartTabs?.map((option) => (
                          <TabFilterButton
                            key={option?.id}
                            label={option?.label}
                            id={option?.id}
                            toggleValue={option?.value}
                            handleChange={() =>
                              onChangeChart({
                                target: {
                                  name: d?.value,
                                  value: option?.value,
                                },
                              })
                            }
                            selectedTab={chartFilterForm?.[d?.value]}
                          />
                        ))}
                      </div>
                      <NestedPieChart
                        data={chartData?.pieNesthart?.filter(
                          (d) => d?.id && d?.name
                        )}
                        unit={" Tonne"}
                        type={chartFilterForm?.[d?.value]}
                      />
                    </>
                    // )}
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default CGSlippageReport;
