import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { submitStatus, modeOfDispatchLabel } from "../DATA";

export const samplePrepareRegisterInitialForm = {
  id: null,
  site_id: null,
  mine: null,
  mode_of_dispatch: "",
  rr_or_do_date: "",
  rr_or_do_number: "",
  declared_grade: "",
  siding: "siding",
  no_of_rakes: "",
  fnote_or_dnote_number: null,
  fnote_or_dnote_Date: "",
  rr_number: null,
  rr_date: "",
  billing_Doc: "",
  billed_qty: "",
  material: "",
  buyer_name: "",
  sample_id: "",
  name_of_sampling_agency: "",
  ncl_seal_number: "",
  referee_seal_number: "",
  name_of_ncl_representative: "",
  date_of_collection: "",
  date_of_prepartaion: "",
  date_of_sample_sent_to_lab: "",
  name_of_sampling_agency_representative: "",
  date_of_sample_receipt_in_lab: "2023-01-20",
  // sample_sender_name: ""
};

export const samplePrepareRegisterLabel = {
  id: null,
  // site_id: "",
  mode_of_dispatch: "Mode of Dispatch",
  rr_or_do_date: "Collection Date",
  rr_or_do_number: "Delivery No",
  declared_grade: "Declared Grade",
  siding: "",
  no_of_rakes: "",
  fnote_or_dnote_number: "",
  fnote_or_dnote_Date: "",
  rr_number: "",
  rr_date: "",
  billing_Doc: "",
  billed_qty: "",
  material: "",
  buyer_name: "",
  subsidiary: "Subsidiary",
  mine: "Mine",
  sample_id: "Sample ID",
  name_of_sampling_agency: "Name of Sampling Agency",
  ncl_seal_number: "NCL Seal No.",
  referee_seal_number: "Referee Seal No.​",
  name_of_ncl_representative: "Name of NCL Representative",
  date_of_collection: "Date of Collection",
  date_of_prepartaion: "Date of Preparation​",
  date_of_sample_sent_to_lab: "Date of Sample sent to Lab",
  name_of_sampling_agency_representative: "Sampling Agency Representative",
  date_of_sample_receipt_in_lab: "",
  // sample_sender_name: ""
};

export const nameSamplingAgencyList = [
  { value: "Pvt ltd 1", label: "Pvt ltd 1" },
  { value: "Pvt ltd 2", label: "Pvt ltd 2" },
  { value: "Pvt ltd 3", label: "Pvt ltd 3" },
  { value: "Pvt ltd 4", label: "Pvt ltd 4" },
  { value: "Pvt ltd 5", label: "Pvt ltd 5" },
];

export const modeOfDispatchList = [
  { value: "SHRA", label: "Rail" },
  { value: "SHRO", label: "Road" },
  { value: "SHMG", label: "Merry Go Round" },
];
export const refereeStatus = [
  { value: "Drafted", label: "Referee Pending" },
  { value: "Submitted", label: "Referee Approved" },
];
export const rrDoNoList = [
  { value: 1233423, label: "1233423" },
  { value: 1233446, label: "1233446" },
  { value: 1233458, label: "1233458" },
  { value: 1233496, label: "1233496" },
  { value: 1233450, label: "1233450" },
];

export const declaredGradeList = [
  { value: "A1", label: "A1" },
  { value: "A2", label: "A2" },
  { value: "A3", label: "A3" },
  { value: "A4", label: "A4" },
  { value: "A5", label: "A5" },
];

export const coalDispatchRelatedRules = [
  {
    field_name: "site_id",
    label: samplePrepareRegisterLabel?.mine,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "mode_of_dispatch",
    label: samplePrepareRegisterLabel?.mode_of_dispatch,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "rr_or_do_number",
    label: samplePrepareRegisterLabel?.rr_or_do_number,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "rr_or_do_date",
    label: samplePrepareRegisterLabel?.rr_or_do_date,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "declared_grade",
    label: samplePrepareRegisterLabel?.declared_grade,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "name_of_sampling_agency",
    label: samplePrepareRegisterLabel?.name_of_sampling_agency,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "name_of_sampling_agency_representative",
    label: samplePrepareRegisterLabel?.name_of_sampling_agency_representative,
    type: "string",
    isRequired: true,
  },
];

export const sampleRelatedRules = [
  {
    field_name: "sample_id",
    label: samplePrepareRegisterLabel?.sample_id,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "date_of_collection",
    label: samplePrepareRegisterLabel?.date_of_collection,
    type: "string",
    isRequired: true,
  },

  {
    field_name: "date_of_prepartaion",
    label: samplePrepareRegisterLabel?.date_of_prepartaion,
    type: "string",
    isRequired: true,
  },
  // {
  //   field_name: "ncl_seal_number",
  //   label: samplePrepareRegisterLabel?.ncl_seal_number,
  //   type: "string",
  //   isRequired: true,
  // },
  {
    field_name: "date_of_sample_sent_to_lab",
    label: samplePrepareRegisterLabel?.date_of_sample_sent_to_lab,
    type: "string",
    isRequired: true,
  },
  // {
  //   field_name: "referee_seal_number",
  //   label: samplePrepareRegisterLabel?.referee_seal_number,
  //   type: "string",
  //   isRequired: true,
  // },
  // {
  //   field_name: "name_of_ncl_representative",
  //   label: samplePrepareRegisterLabel?.name_of_ncl_representative,
  //   type: "string",
  //   isRequired: true,
  // },
];

export const samplePrepareRegisterRules = [
  ...coalDispatchRelatedRules,
  ...sampleRelatedRules,
];

export const samplePrepareFilterForm = {
  startDate: "",
  endDate: "",
  mode_of_dispatch: "",
};

export const samplePrepareColumnFields = [
  {
    name: "Mode of Dispatch",
    selector: "mode_of_dispatch",
    sortable: true,
    isSearchable: true,
    cell: (row) =>
      row?.mode_of_dispatch ? modeOfDispatchLabel[row?.mode_of_dispatch] : "",
  },
  {
    name: "Delivery No",
    // selector: (row) => row?.["rr_or_do_number"],
    // sortField: "rr_or_do_number",
    selector: "rr_or_do_number",
    sortable: true,
    isSearchable: true,
  },
  {
    name: "Collection Date",
    selector: "rr_or_do_date",
    sortable: true,
    cell: (row) =>
      row?.rr_or_do_date ? DDMMYYYYFormat(row?.rr_or_do_date) : "",
  },
  {
    name: "Declared Grade",
    selector: "declared_grade",
    sortable: true,
    isSearchable: true,
  },
  {
    name: "Sample ID",
    selector: "sample_id",
    sortable: true,
  },
  {
    name: "Date of Collection",
    selector: "date_of_collection",
    sortable: true,
    cell: (row) =>
      row?.date_of_collection ? DDMMYYYYFormat(row?.date_of_collection) : "",
  },
  {
    name: "Status",
    selector: "sample_prepare_status",
    sortable: true,
    minWidth: "160px",
    cell: (row) => {
      if (row?.sample_prepare_status === submitStatus?.submitted) {
        return (
          <span className="statusSec submit">{submitStatus?.submitted}</span>
        );
      } else {
        return <span className="statusSec draft">{submitStatus?.drafted}</span>;
      }
    },
  },
  // {
  //   name: "System Feedback",
  //   selector: (row) => <GiGolfFlag className="feedbackIcon" />,
  //   center: true,
  // },
];

export const samplePrepareData = {
  tableColumn: samplePrepareColumnFields,
  filterForm: samplePrepareFilterForm,
  pageLink: "/coal-grade/sample-prepare",
  tableTittle: "Coal Sample Preparation Register",
  type: "sample-prepare",
  role: ["chemist"],
  // viewComponent: SamplePrepareRegisterView,
};
