import React from "react";
import { Col, Row } from "react-bootstrap";
import CheckBoxField from "../../components/_form/CheckBoxField/CheckBoxField";
import {
  CONTRACTOR_PERMISSION_OBJECT,
  MODULE_OBJECT_VIEW,
  ROLE_OBJECT,
  ROLE_OBJECT_VIEW,
} from "../../_services/Auth/helper";

const PermissionsForm = ({ onChange, form, moduleType = null }) => {
  let finalRoleObject = ROLE_OBJECT;
  if (form?.roleId === 3) finalRoleObject = CONTRACTOR_PERMISSION_OBJECT;
  return (
    <>
      <h6 className="mt-2">Permissions​</h6>
      <div className="seperator mt-0"></div>
      <Row>
        {Object.keys(finalRoleObject)?.map((module, idx) => {
          if ((moduleType && module === moduleType) || moduleType === null) {
            const roleData = Object.keys(finalRoleObject[module])
              ?.filter(
                (d) =>
                  (form?.roleId === 4 || form?.roleId === 3) &&
                  d !== "contractor"
              )
              ?.map((role, i) => {
                return (
                  <Col key={i} md={4}>
                    <CheckBoxField
                      name={role}
                      checked={form?.[role]}
                      onChange={onChange}
                      label={ROLE_OBJECT_VIEW?.[role]}
                      id={`${idx}check${i}`}
                      disabled={form?.roleId === 4 && role === "contractor"}
                    />
                  </Col>
                );
              });
            return (
              <>
                <h6 className="mt-2">{MODULE_OBJECT_VIEW[module]}</h6>{" "}
                {roleData}
              </>
            );
          }
        })}
      </Row>
    </>
  );
};

export default PermissionsForm;
