import {
  deleteContractor,
  getAllContractorList,
} from "../../../services/_contractorList/contractorList";

export const contractorListColumnFields = [
  {
    name: "Contractor code",
    selector: "contractor_code",
    sortable: true,
  },
  {
    name: "Contractor",
    selector: "contractor",
    sortable: true,
  },
  {
    name: "Contractor Person",
    selector: "contractor_person",
    sortable: true,
  },
  // {
  //   name: "NIT Number",
  //   selector: "nit_number",
  // },
  // {
  //   name: "Mine",
  //   selector: "siteData",
  //   // sortable: true,
  //   cell: (row) => row?.siteData?.name,
  // },
  // {
  //   name: "Subsidiary",
  //   selector: "siteData",
  //   // sortable: true,
  //   cell: (row) => row?.siteData?.parentSite?.name,
  // },
];
export const formFields = {
  contractor: "",
  contractor_person: "",
  // nit_number: "",
  // site_id: "",
  contractor_code: "",
};

export const contractorListApis = Object.freeze({
  getAll: {
    api: getAllContractorList,
  },
  delete: {
    api: deleteContractor,
  },
});

export const contractorRules = [
  {
    field_name: "contractor",
    label: "Contractor",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "contractor_person",
    label: "Contractor Person",
    type: "string",
    isRequired: true,
  },
  // {
  //   field_name: "nit_number",
  //   label: "NIT Number",
  //   type: "string",
  //   isRequired: true,
  // },
  // {
  //   field_name: "site_id",
  //   label: "Site",
  //   type: "string",
  //   isRequired: true,
  // },
  {
    field_name: "contractor_code",
    label: "Contractor Code",
    type: "string",
    isRequired: true,
  },
];
