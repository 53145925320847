import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { activityFlowList } from "./DATA";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import { SidebarData } from "../IncidentManagement/DATA";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { filterUrlFunc } from "../../../_services/CommonService/CommonService";

const Configuration = () => {
  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="screenSpaceLR">
                <h3
                  className=""
                  style={{ paddingBottom: "1px", paddingTop: "5px" }}
                >
                  <MainBreadcrumb
                    routeSegments={[
                      {
                        name: "Safety Management",
                        path: "#",
                      },
                      {
                        name: "Configuration",
                        path: "#",
                      },
                    ]}
                    // module={ACTIVE_MODULE.incident}
                  />
                </h3>
                <div className="MgmtWrapper ">
                  <Row className="dashboardItem">
                    {filterUrlFunc(activityFlowList)?.map((d, i) => {
                      return (
                        <Col lg={4} key={i} className="mb-4">
                          <Link to={d?.link}>
                            <div className="dashboardItemContent">
                              <div className="imgArea">
                                <img
                                  src={d.img}
                                  className="img-fluid"
                                  alt="dashboardItemIcons"
                                />
                              </div>
                              <label>{d?.label}</label>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Configuration;
