import React from "react";
import ReactEcharts from "echarts-for-react";
import { isDecimal } from "../../_helpers/helper";

const NestedPieChart = ({ data = [], unit = "", type }) => {
  const parentData = [];
  const childData = [];
  const category = [];

  Array.isArray(data) &&
    data?.forEach((d) => {
      category.push(d.name);
      parentData.push({
        name: d.name + "," + isDecimal(d.value),
        value: d.value,
      });
      d?.clild?.forEach((e) => {
        category.push(e.name);
        childData.push({
          name: e.name + "," + isDecimal(e.value),
          value: e.value,
          samplingName: e.samplingName,
        });
      });
    });

  const option = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        let nameQuantity = params?.data?.name.split(",");
        
        const percentage = (
          (params.data.value /
            parentData.reduce((acc, item) => acc + item.value, 0)) *
          100
        ).toFixed(2);

        const percentageChild = (
          (params.data.value /
          childData.reduce((acc, item) => acc + item.value, 0)) *
          100
        ).toFixed(2);


        if (params.seriesType === "pie" && params.data.samplingName) {
          return `Sampling Agency: ${params?.data?.samplingName} <br>
     
          ${
            type === "dispatch_quantity"
              ? `Dispatch Grade: `
              : `Grade Slippage: `
          }  ${nameQuantity[0]} <br>
          Total Bill Quantity: ${nameQuantity[1]} Tonnes (${percentageChild}%)`;
        } else {
          return `Sampling Agency: ${nameQuantity[0]}<br>
          Total Bill Quantity: ${nameQuantity[1]} ${unit} ${
            " (" + percentage + "%)"
          }
          `;
        }
      },
    },
    legend: {
      data: category,
    },
    series: [
      {
        top: "-50px",
        bottom: "-65px",
        type: "pie",
        radius: [0, "40%"],
        label: {
          position: "inner",
          fontSize: 10,
        },
        data: parentData,
      },
      {
        top: "-50px",
        bottom: "-65px",
        type: "pie",
        radius: ["40%", "65%"],
        label: {
          position: "inner",
          fontSize: 10,
        },
        data: childData,
      },
    ],
  };
  return <ReactEcharts option={option} style={{ height: "262px" }} />;
};

export default NestedPieChart;
