import React, { useState, useEffect, useMemo, useCallback } from "react";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "./DATA";

import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";
import HelpManualPreview from "../ContractorManagement/CommonComponent/HelpManualCommonComponent";
import HelpManualCommonComponent from "../ContractorManagement/CommonComponent/HelpManualCommonComponent";

// import "./helpManual.scss";
const HelpManual = () => {
  
  return (
    <div className="wrapper sidebarWrapper">
    <SafetySideBar SidebarData={SidebarData} />
            <HelpManualCommonComponent  moduleName={"Coal Grade"}/>
    </div>
  );
};

export default HelpManual;
