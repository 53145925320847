import { Rules } from "./Rules";

export function Validation(state, rules) {
  let errobj = {};
  for (const element of rules) {
    Object.keys(state).forEach((index) => {
      if (index === element.field_name) {
        /**call rule file here */
        let errormsg = Rules(state[index], element);
        if (errormsg !== "") {
          if (
            (!element.isRequired && state[index] === "") ||
            (Array.isArray(state[index]) && state[index].length === 0)
          ) {
            errobj[index] = "";
          } else {
            errobj[index] = errormsg;
          }
        } else {
          errobj[index] = "";
        }
      }
    });
  }
  return errobj;
}
