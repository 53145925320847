import { TiInfoLarge, TiInfoOutline } from "react-icons/ti";
import selectionIcon1 from "../../../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../../../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../../../Assets/Images/selectionIcon3.svg";
import selectionIcon4 from "../../../Assets/Images/selectionIcon4.svg";
import selectionIcon5 from "../../../Assets/Images/selectionIcon5.svg";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import {
  getSafetyManagementCapaAssignedIncidentListWithPagination,
  getSafetyManagementCapaOwnerIncidentListWithPagination,
  getSafetyManagementInvestigationAssignedIncidentListWithPagination,
  getSafetyManagementInvestigatorIncidentListWithPagination,
  getSafetyManagementReportedIncidentHistoryListWithPagination,
  getSafetyManagementReportedIncidentListWithPagination,
} from "../../../services/_safetyManagement/safetyManagement";

import { capaPriority, statusColor, statusList } from "../DATA";
import CapasView from "./components/CapasView";
import EquipmentDetailsView from "./components/ReportFormView/EquipmentDetailsView";
import GeneralInfoView from "./components/ReportFormView/GeneralInfoView";
import IncidentEvidenceView from "./components/ReportFormView/IncidentEvidenceView";
import PersonnelDetailsView from "./components/ReportFormView/PersonnelDetailsView";
import VehicleDetailsView from "./components/ReportFormView/VehicleDetailsView";
import { BiSearchAlt, BiUserPin } from "react-icons/bi";
import { GrBike } from "react-icons/gr";
import { FiSettings } from "react-icons/fi";
import { IS_MOBILE } from "../../../_helpers/helper";
import CustomProgressBar from "../../../components/Common/CustomProgressBar/CustomProgressBar";

export const reportEditLink = `${
  IS_MOBILE() ? "/mobile" : ""
}/safety-management-system/incident-management/report/edit`;

export const statusUpdateLink = `${
  IS_MOBILE() ? "/mobile" : ""
}/incident-management/edit`;

export const tabUrl = {
  safetyManagement: [
    {
      to: "/safety-management-system/incident-management/incident-reported",
      permission: ["safety_admin"],
      label: "Admin Actions",
      active: 0,
    },
    {
      to: "/safety-management-system/incident-management/incident-investigation",
      label: "Investigator Actions",
      // permission: ["fuel_admin", "dispenser"],
      active: 0,
    },
    {
      label: "CAPA Owner Actions",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
      to: "/safety-management-system/incident-management/incident-capa",
    },
  ],
  safetyManagementApprovalHistory: [
    {
      to: "/safety-management-system/incident-management/incident-reported-approval_history",
      permission: ["safety_admin"],
      label: "Admin",
      active: 0,
    },
    {
      to: "/safety-management-system/incident-management/incident-investigator-approval_history",
      label: "Investigator",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
    {
      to: "/safety-management-system/incident-management/incident-capa-owner-approval_history",
      // permission: ["fuel_admin", "dispenser"],
      label: "CAPA Owner",
      active: 0,
    },
  ],
};

export const mobileTabUrl = {
  safetyManagement: [
    {
      to: "/mobile/safety-management-system/incident-management/incident-reported",
      active: 0,
      permission: ["safety_admin"],
      label: "Admin Actions",
    },
    {
      to: "/mobile/safety-management-system/incident-management/incident-investigation",
      label: "Investigator Actions",
      // permission: ["fuel_admin", "dispenser"],
      active: 0,
    },
    {
      to: "/mobile/safety-management-system/incident-management/incident-capa",
      label: "CAPA Owner Actions",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
  ],
  safetyManagementApprovalHistory: [
    {
      label: "Admin",
      active: 0,
      permission: ["safety_admin"],
      to: "/mobile/safety-management-system/incident-management/incident-reported-approval_history",
    },
    {
      to: "/mobile/safety-management-system/incident-management/incident-investigator-approval_history",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
      label: "Investigator",
    },
    {
      to: "/mobile/safety-management-system/incident-management/incident-capa-owner-approval_history",
      active: 0,
      label: "CAPA Owner",
      // permission: ["fuel_admin", "dispenser"],
    },
  ],
};

export const fuelManagementFilterForm = {
  startDate: "",
  endDate: "",
  // contractor_id: "",
};

export const IncidentHistoryCapaColumnFields = [
  {
    selector: "capaId",
    sortable: true,
    isShowMobile: true,
    name: "CAPA Id",
  },
  {
    sortable: true,
    name: "Subsidiary",
    selector: "site_id",
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  // {
  //   selector: "incident_datetime",
  //   name: "Incident Time",
  //   sortable: true,
  //   cell: (row) => DDMMYYYYHHMMAFormat(row?.incident_datetime),
  //   minWidth: "200px",
  // },
  {
    selector: "capaType",
    sortable: true,
    cell: (row) => row?.capaType,
    name: "CAPA Type",
  },
  {
    name: "Priority",
    selector: "priority",
    sortable: true,
    cell: (row) => capaPriority[row?.priority],
  },
  {
    name: "Completed Date",
    selector: "completionDate",
    cell: (row) => DDMMYYYYFormat(row?.completionDate),
    sortable: true,
  },
  {
    name: "CAPA Status",
    selector: "capaStatus",
    sortable: true,
    cell: (row) => capaStatusView[row?.capaStatus.toLowerCase()],
  },
];
export const IncidentCapaColumnFields = [
  {
    name: "CAPA Id",
    selector: "capaId",
    sortable: true,
    isShowMobile: true,
  },
  {
    name: "Subsidiary",
    selector: "site_id",
    cell: (row) => row?.site?.parentSite?.name,
    sortable: true,
  },
  {
    name: "Mine",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  // {
  //   name: "Incident Time",
  //   selector: "incident_datetime",
  //   sortable: true,
  //   cell: (row) => DDMMYYYYHHMMAFormat(row?.incident_datetime),
  //   minWidth: "200px",
  // },
  {
    cell: (row) => row?.capaType,
    name: "CAPA Type",
    selector: "capaType",
    sortable: true,
  },
  {
    name: "Priority",
    selector: "priority",
    sortable: true,
    cell: (row) => capaPriority[row?.priority],
  },
  {
    selector: "dueDate",
    name: "Due Date",
    sortable: true,
    cell: (row) => DDMMYYYYFormat(row?.dueDate),
  },
  {
    name: "CAPA Status",
    selector: "capaStatus",
    sortable: true,
    cell: (row) => capaStatusView[row?.capaStatus.toLowerCase()],
  },
];

export const fuelDispenseColumnFields = [
  {
    name: "Incident ID",
    selector: "incident_uid",
    sortable: true,
    isShowMobile: true,
  },
  {
    name: "Incident/Accident Date",
    selector: "incident_datetime",
    cell: (row) =>
      row?.createdAt ? DDMMYYYYFormat(row?.incident_datetime) : "",
    sortable: true,
  },
  {
    name: "Subsidiary",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "site_id",
    sortable: true,
    cell: (row) => {
      return row?.site?.name;
    },
  },
  // {
  //   name: "Incident Time",
  //   selector: "incident_datetime",
  //   sortable: true,
  //   cell: (row) => DDMMYYYYHHMMAFormat(row?.incident_datetime),
  //   minWidth: "200px",
  // },
  {
    name: "Incident/Accident Type",
    selector: "category",
    sortable: true,
    cell: (row) => incidentTypesObj[row?.category],
    minWidth: "150px",
  },
  // {
  //   name: "Department",
  //   selector: "department",
  //   sortable: true,
  //   // cell: (row) => row?.site?.name,
  // },
  {
    name: "Incident/Accident Severity",
    selector: "severity",
    sortable: true,
    cell: (row) => {
      return (
        <div
          className={
            severities[row?.severity] &&
            `inSevrty ${getIncidentSeverityClass(row?.severity)}`
          }
        >
          {row?.severity}
        </div>
      );
    },
  },
  {
    name: "Progress",
    selector: "progress",
    // sortable: true,
    customClass: "progressBar",
    cell: (row) => {
      return row?.status === "draft" ? (
        statusProgressObj[row?.status]
      ) : (
        <CustomProgressBar value={statusProgressObj[row?.status]} />
      );
    },
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    minWidth: "250px",
    // cell: (row) => statusList[row?.status],
    cell: (row) => {
      return (
        <span className={`statusSec ${statusColor(row?.status)}`}>
          {statusList[row?.status]}
        </span>
      );
    },
  },
];
export const capaOwnerApprovalHistory = Object.freeze({
  getAll: {
    api: getSafetyManagementCapaOwnerIncidentListWithPagination,
  },
});
export const investigatorApprovalHistory = Object.freeze({
  getAll: {
    api: getSafetyManagementInvestigatorIncidentListWithPagination,
  },
});
export const repotedApprovalHistory = Object.freeze({
  getAll: {
    api: getSafetyManagementReportedIncidentHistoryListWithPagination,
  },
});
export const fuelDispenseApis = Object.freeze({
  getAll: {
    api: getSafetyManagementReportedIncidentListWithPagination,
  },
});
export const capaAssined = Object.freeze({
  getAll: {
    api: getSafetyManagementCapaAssignedIncidentListWithPagination,
  },
});
export const investionAssined = Object.freeze({
  getAll: {
    api: getSafetyManagementInvestigationAssignedIncidentListWithPagination,
  },
});

export const SidebarData = [
  {
    link: "/safety-management-system/dashboard/1",
    img: selectionIcon1,
    title: "Dashboard",
    active: 0,
    activeUrl: "dashboard",
    permission: ["safety_management_dashbaord"],
  },
  {
    img: selectionIcon2,
    // link: "/safety-management-system/user-management",
    title: "User Management",
    link: "/safety-management-system/user-management",
    activeUrl: "user-management",
    active: 0,
    permission: ["safety_admin"],
  },
  {
    img: selectionIcon3,
    title: "Configuration",
    active: 0,
    link: "/safety-management-system/configuration",
    permission: ["safety_admin", "super_admin"],
    activeUrl: "configuration",
  },
  {
    title: "Incident Management",
    active: 0,
    link: "/safety-management-system/incident-management",
    activeUrl: "incident-management",
    img: selectionIcon4,
    permission: ["safety_admin", "safety_user", "super_admin"],
  },
  {
    title: "CSIS Data",
    active: 0,
    link: "/safety-management-system/csis-data",
    activeUrl: "csis-data",
    img: selectionIcon4,
    permission: ["safety_admin", "safety_user"],
  },
  {
    title: "Document",
    img: selectionIcon3,
    activeUrl: "documents",
    active: 0,
    link: "/safety-management-system/documents",
  },
  {
    title: "Help Manual",
    img: selectionIcon5,
    activeUrl: "help-manual",
    active: 0,
    link: "/safety-management-system/help-manual",
  },
];
export const mobileSidebarData = [
  {
    img: selectionIcon1,
    title: "Dashboard",
    active: 0,
    link: "/mobile/safety-management-system/dashboard/1",
    activeUrl: "dashboard",
    permission: ["safety_management_dashbaord"],
  },
  // {
  //   img: selectionIcon2,
  //   title: "User Management",
  //   link: "/mobile/safety-management-system/user-management",
  //   activeUrl: "user-management",
  //   active: 0,
  //   // link: "/mobile/safety-management-system/user-management",
  //   // permission: ["fuel_management_dashbaord"],
  // },
  // {
  //   img: selectionIcon3,
  //   title: "Configuration",
  //   active: 0,
  //   link: "/mobile/safety-management-system/configuration",
  //   activeUrl: "configuration",
  //   permission: ["safety_admin"],
  // },
  {
    img: selectionIcon4,
    title: "Incident Management",
    active: 0,
    link: "/mobile/safety-management-system/incident-management",
    activeUrl: "incident-management",
    // permission: ["coal_grade_dashboard"],
  },
  // {
  //   img: selectionIcon5,
  //   title: "Help Manual",
  //   active: 0,
  //   // link: "/mobile/safety-management-system/help-manual",
  //   // permission: ["environment_management_dashboard"],
  // },
];
export const incidentApprovalFlowList = [
  {
    img: selectionIcon1,
    label: "Reported Incident",
    link: "/safety-management-system/incident-management/incident-reported",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon2,
    label: "Investigation Assigned",
    link: "/safety-management-system/incident-management/incident-investigation",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon3,
    label: "CAPA Assigned",
    // link: "/dashboard/contractor-performance-dashboard",
    // role: ["contractor_dashboard"],
  },
];
export const incidentFlowList = [
  {
    img: selectionIcon1,
    label: "Report Incident",
    link: "/safety-management-system/incident-management/report/add",
    permission: ["safety_user", "safety_admin"],
  },
  {
    img: selectionIcon2,
    label: "My Incidents",
    link: "/safety-management-system/incident-management/my-incidents",
    permission: ["safety_user", "safety_admin"],
  },
  {
    img: selectionIcon3,
    label: "View Incidents",
    link: "/safety-management-system/incident-management/view-incidents",
    permission: ["safety_admin", "safety_admin", "super_admin"],
  },
  {
    img: selectionIcon4,
    label: "My Approval Actions",
    link: "/safety-management-system/incident-management/my-approval-action",
    permission: ["safety_user", "safety_admin"],
  },
  {
    img: selectionIcon5,
    label: "My Approval History",
    link: "/safety-management-system/incident-management/incident-reported-approval_history",
    permission: ["safety_user", "safety_admin"],
  },
];

export const mobileIncidentFlowList = [
  {
    img: selectionIcon1,
    label: "Report Incident",
    link: "/mobile/safety-management-system/incident-management/report/add",
    permission: ["safety_admin", "safety_user"],
  },
  {
    img: selectionIcon2,
    label: "My Incidents",
    link: "/mobile/safety-management-system/incident-management/my-incidents",
    permission: ["safety_admin", "safety_user"],
  },
  {
    img: selectionIcon3,
    label: "View Incidents",
    link: "/mobile/safety-management-system/incident-management/view-incidents",
    permission: ["safety_admin"],
  },
  {
    img: selectionIcon4,
    label: "My Approval Actions",
    link: "/mobile/safety-management-system/incident-management/my-approval-action",
    permission: ["safety_admin", "safety_user"],
  },
  {
    img: selectionIcon5,
    label: "My Approval History",
    link: "/mobile/safety-management-system/incident-management/incident-reported-approval_history",
    permission: ["safety_admin", "safety_user"],
  },
];

export const contractorScoreFlowList = [
  {
    img: selectionIcon1,
    label: "Add Contractor Score Card",
    link: "/contractor-management/contractor-score/create",
    permission: ["section_incharge"],
  },
  {
    img: selectionIcon3,
    label: "My Contractor Score Card",
    link: "/contractor-management/my-contractor-score",
    permission: ["section_incharge"],
  },
  {
    img: selectionIcon2,
    label: "View Contractor Score Card",
    link: "/contractor-management/contractor-score",
    permission: ["contractor_module_admin"],
  },
];

export const incidentTabList = [
  {
    label: "Incident/Accident Info",
    tab: 1,
    tabStatus: false,
    Icon: TiInfoOutline,
  },
  {
    label: "General Info",
    tab: 2,
    tabStatus: false,
    Icon: TiInfoLarge,
  },
  {
    label: "Personnel Details",
    tab: 3,
    tabStatus: false,
    Icon: BiUserPin,
  },
  {
    label: "Equipment Details",
    tab: 4,
    tabStatus: false,
    Icon: FiSettings,
  },
  {
    label: "Vehicle Details",
    tab: 5,
    tabStatus: false,
    Icon: GrBike,
  },
  {
    label: "Evidence",
    tab: 6,
    tabStatus: false,
    Icon: BiSearchAlt,
  },
];

export const initialForm = {
  // id: null,
  // type: "",
  category: "",
  incident_datetime: "",
  incident_reportdatetime: "",
  siteId: "",
  severity: "",
  fireInvolved: false,
  electricShock: false,
  lighting: false,
  // facilityShutdown: false,
  movingEquipmet: false,
  explosion: false,
  fallFromHeight: false,
  vehicleCollision: false,
  fallOfObject: false,
  inundation: false,
  indirectLosses: "",
  facilityStatus: "",
  similarIncidentHappened: "",
  summary: "",
  description: "",
  measuresOrActions: "",
  peopleCount: "",
  isPersonalInjury: null,
  isAssetIncident: null,
  isVehicleIncident: null,
  reportEvidencesRequired: null,
  // stateMasterId: "",
  // countryMasterId: "",
  department: "",
  shift: "",
  areaId: "",
  subsidaryId: "",
  staffType: "",
  // staffEmployer: "",
  staffName: "",
  subAreaId: "",
  // otherSubArea: "",
  activityId: "",
  subActivityId: "",
  otherSubArea: "",
  otherStaffName: "",
  is_lti: false,
  staffId: null,
  lti_count: 0,
  number_of_injuries: null,
  number_of_fatality: null,
  category_other: "",
  staffEmployer: "",
  place_of_incident: "",
  costOfAccident: null,
  violationsLeading: null,
  fatalityCategory: "",
  dateOfImmediate: "",
  immediateLastAccident: "",
  briefDescription: "",
  distanceFromFace: null,
  seam: "",
  level: "",
  dip: "",
  detailsNotCovered: "",
  pitIncline: "",
  districtPanel: "",
  othersInvolved: false,
  othersInfo: "",
};

export const incidentTypes = {
  unsafeAct: "Unsafe Act",
  unsafeCondition: "Unsafe Condition",
  nearMiss: "Near Miss",
  mti: "MTI (Medical Treatment Injury)",
  lti: "LTI (Lost Time Injury)",
  seriousBInjury: "Serious bodily injury",
  fatality: "Fatality",
  others: "Others",
};

export const incidentTypesObj = {
  nearMiss: "Near Miss",
  minorInjury: "Minor",
  reportable: "Reportable",
  seriousInjury: "Serious",
  dangerousOccurrence: "Dangerous Occurrence",
  fatality: "Fatal",
  others: "Others",
  lti: "Lost Time Injury",
};

export const incidentTypesObjKeys = {
  fatalAccidents: "fatalAccidents",
  seriousIncidents: "seriousIncidents",
  majorIncidents: "majorIncidents",
  minorIncidents: "minorIncidents",
  nearMisses: "nearMisses",
  lti: "lti",
};

export const incidentTypesTooltip = [
  {
    label: incidentTypesObj.nearMiss,
    tooltip: "Resulting no damage to equipment and no injury to person",
  },
  {
    label: incidentTypesObj.minorInjury,
    tooltip: "Resulting no significant injury",
  },
  {
    label: incidentTypesObj.reportableInjury,
    tooltip: "Resulting absence from duty for more than 72 hours",
  },
  {
    label: incidentTypesObj.seriousInjury,
    tooltip:
      "Resulting fracture/loss of hearing or eyesight/permanent physical incapacity",
  },
  {
    label: "Dangerous Occurrence",
    tooltip:
      "Resulting damage to equipment or environment or interruption of work with or without injury to person(s)",
  },
  {
    label: incidentTypesObj.fatality,
    tooltip: "Resulting death of person(s)",
  },

  {
    label: incidentTypesObj.others,
    tooltip: "Any other category not covered above.",
  },
  {
    label: incidentTypesObj.lti,
    tooltip:
      "Lost Time Injury. Person is not able to report to work next day resulting in loss of work hours.",
  },
];

export const INCIDENTTYPESEVERITY_4_5 = [
  "lti",
  "seriousBInjury",
  "fatality",
  "unsafeAct",
  "unsafeCondition",
  "nearMiss",
  "mti",
];

export const highSeverities = [
  {
    value: "1",
    label: "1",
    tooltip:
      "Improper placement/Improper position/Disorderly workplace/Poor house keeping",
  },
  {
    value: "2",
    label: "2",
    tooltip:
      "Not using PPEs/Using improper tools or equipment/Space congestion",
  },
  {
    value: "3",
    label: "3",
    tooltip: "Violation of rules/ regulation/ SOPs/Codes & Procedures",
  },
  {
    value: "4",
    label: "4",
    tooltip:
      "Serious hazard - injury or potential to cause injury if not corrected",
  },
  {
    value: "5",
    label: "5",
    tooltip:
      "Urgent danger- serious injury or fatality or potentiality to cause serious injury or fatality if not corrected",
  },
  {
    value: "Not Applicable",
    label: "Not Applicable",
    tooltip: "Not Applicable",
  },
];

export const lowSeverities = [
  {
    value: "1",
    label: "1",
    tooltip:
      "Improper placement/Improper position/Disorderly workplace/Poor house keeping",
  },
  {
    value: "2",
    label: "2",
    tooltip:
      "Not using PPEs/Using improper tools or equipment/Space congestion",
  },
  {
    value: "3",
    label: "3",
    tooltip: "Violation of rules/ regulation/ SOPs/Codes & Procedures",
  },
];

export const staffType = {
  employee: "Employee",
  contractor: "Contractor",
  others: "Visitor",
};

export const viewStaffType = {
  users: "Employee",
  contractor: "Contractor",
  others: "Others",
};

export const objStaffType = {
  users: "users",
  others: "others",
  contractor: "contractor",
};

export const STAFFEMPLOYER = [
  { label: "NCL", value: "NCL" },
  { label: "SECL", value: "SECL" },
];

// export const permitShifts = {
//   shiftOne: "I",
//   shiftTwo: "II",
//   shiftThree: "III",
//   shiftFour: "General",
// };

export const shiftObj = {
  I: "1st",
  II: "2nd",
  III: "3rd",
  General: "General",
};

export const initialAssetDetails = {
  type: "",
  sku: "",
  name: "",
  details: "",
  damage: "No",
};

// export const matDetailsList = [
//   { id: 1, name: "DTH Drill Machine" },
//   { id: 2, name: "Jack hammer" },
//   { id: 3, name: "Long Hole/Ring Drilling Machine" },
//   { id: 4, name: "Exploration Drill Machine" },
//   { id: 5, name: "Excavator" },
//   { id: 6, name: "Payloader" },
//   { id: 7, name: "Dumper" },
//   { id: 8, name: "Tipper-Truck" },
//   { id: 9, name: "Buyer's Truck" },
//   { id: 10, name: "Explosive Van" },
//   { id: 11, name: "Trailer" },
//   { id: 12, name: "Dozer" },
//   { id: 13, name: "Grader" },
//   { id: 14, name: "Mobile Screening Plant" },
//   { id: 15, name: "Mobile Crusher Plant" },
//   { id: 16, name: "Fixed Crushing & Sizing Plant" },
//   { id: 17, name: "Light Vehicle" },
//   { id: 18, name: "Over Head Crane" },
//   { id: 19, name: "Hoists & Other Cranes" },
//   { id: 20, name: "Hydra & Mobile Cranes" },
//   { id: 21, name: "Forklift" },
//   { id: 22, name: "Pump" },
//   { id: 23, name: "Submersible Pump" },
//   { id: 24, name: "Sub-Station" },
//   { id: 25, name: "Transformer" },
//   { id: 26, name: "DG Set" },
//   { id: 27, name: "Electrical OH Lines & Cablea" },
//   { id: 28, name: "Lathe Machine" },
//   { id: 29, name: "Grinding & Cutting Machine" },
//   { id: 30, name: "Welding Machine " },
//   { id: 31, name: "Compressors" },
//   { id: 32, name: "Pressure Tank" },
//   { id: 33, name: "Rocker Shovel" },
//   { id: 34, name: "Haulage" },
//   { id: 35, name: "Locomotive" },
//   { id: 36, name: "Winder" },
//   { id: 37, name: "Ventilation Main Fan" },
//   { id: 38, name: "Auxiliary/Booster Fan" },
//   { id: 39, name: "Others" },
// ];

export const matDetailsList = [
  { id: 1, name: "DTH Drill Machine" },
  { id: 2, name: "Jack hammer" },
  { id: 3, name: "Long Hole/Ring Drilling Machine" },
  { id: 4, name: "Exploration Drill Machine" },
  { id: 5, name: "Excavator" },
  { id: 6, name: "Payloader" },
  { id: 7, name: "Dumper" },
  { id: 8, name: "Tipper-Truck" },
  { id: 9, name: "Buyer's Truck" },
  { id: 10, name: "Explosive Van" },
  { id: 11, name: "Trailer" },
  { id: 12, name: "Dozer" },
  { id: 13, name: "Grader" },
  { id: 14, name: "Mobile Screening Plant" },
  { id: 15, name: "Mobile Crusher Plant" },
  { id: 16, name: "Fixed Crushing & Sizing Plant" },
  { id: 17, name: "Light Vehicle" },
  { id: 18, name: "Over Head Crane" },
  { id: 19, name: "Hoists & Other Cranes" },
  { id: 20, name: "Hydra & Mobile Cranes" },
  { id: 21, name: "Forklift" },
  { id: 22, name: "Pump" },
  { id: 23, name: "Submersible Pump" },
  { id: 24, name: "Sub-Station" },
  { id: 25, name: "Transformer" },
  { id: 26, name: "DG Set" },
  { id: 27, name: "Electrical OH Lines & Cable" },
  { id: 28, name: "Lathe Machine" },
  { id: 29, name: "Grinding & Cutting Machine" },
  { id: 30, name: "Welding Machine " },
  { id: 31, name: "Compressors" },
  { id: 32, name: "Pressure Tank" },
  { id: 33, name: "Rocker Shovel" },
  { id: 34, name: "Haulage" },
  { id: 35, name: "Locomotive" },
  { id: 36, name: "Winder" },
  { id: 37, name: "Ventilation Main Fan" },
  { id: 38, name: "Auxiliary/Booster Fan" },
  { id: 48, name: "Crane" },
  { id: 40, name: "Drill Machine" },
  { id: 41, name: "Surface Miner" },
  { id: 42, name: "Shovel" },
  { id: 43, name: "Tyre handler" },
  { id: 44, name: "Excavator/ shovel" },
  { id: 45, name: "Road Sale tipper" },
  { id: 46, name: "Wheel Dozer" },
  { id: 47, name: "Water Tanker" },
  { id: 39, name: "Others" },
];

export const initialPersonalDetails = {
  type: "Users",
  name: "",
  // phone: "",
  age: "",
  userId: null,
  employer: "",
  bodyParts: "",
  imeDate: "",
  pmeDate: "",
  vtcDate: "",
  formA_No: null,
  designation: "",
  gender: "",
  hoursAtWork: "",
  outcome: "",
  injuryCode: "",
};

export const GETVAL = {
  employee: "employee",
  contractor: "contractor",
  others: "others",
  Others: "Others",
  omc: "omc",
};

export const initialVehicleDetails = {
  number: "",
  type: "LMV",
  owner: "Company",
  driverName: "",
  observation: "",
  insuranceStatus: "Comprehensive",
};

export const evidenceTypes = {
  photo: "Photo",
  video: "Video",
  // audio: "Audio",
  document: "Document",
};

export const initialEvidence = {
  incidentDetailsId: null,
  id: null,
  type: Object.keys(evidenceTypes)[0],
  description: "",
  file: null,
  fileId: null,
};

export const evidenceTypesObj = {
  photo: "Photo",
  document: "Document",
};

export const departmentList = [
  { value: "Excavation", label: "Excavation" },
  { value: "Civil", label: "Civil" },
  { value: "E &M", label: "E &M" },
  { value: "Mining", label: "Mining" },
  {
    value: "Electrical Telecommunication",
    label: "Electrical Telecommunication",
  },
  { value: "Security", label: "Security" },
  { value: "Material Management", label: "Material Management" },
  { value: "System", label: "System" },
  { value: "Marketing and Sales", label: "Marketing and Sales" },
  { value: "Environment & Forest", label: "Environment & Forest" },
  { value: "Others", label: "Others" },
];

export const dataValueList = [
  { value: "Coal India", label: "Coal India" },
  { value: "Coal India1", label: "Coal India1" },
  { value: "Coal India2", label: "Coal India2" },
];

export const incidentStatuses = {
  draft: "Draft",
  open: "Reported",
  mmReview: "MM Review",

  investigationProgress: "Investigation Inprogress",
  investigationCompleted: "Investigation Completed",
  investigationReviewed: "Investigation Reviewed",

  capaAssigned: "CAPA Assigned",
  capaCompleted: "CAPA Completed",
  // capaCompleted2: "CAPA Completed",
  capaReviewed: "CAPA Reviewed",

  closed: "Closed",
};

export const staffNameRule = {
  field_name: "staffName",
  label: "Staff Name",
  type: "string",
  isRequired: true,
  tab: 1,
};

export const staffEmployerRule = {
  field_name: "staffEmployer",
  label: "Employer",
  type: "string",
  isRequired: true,
  tab: 1,
};

export const otherstaffNameRule = {
  field_name: "otherStaffName",
  label: "Other Staff Name",
  type: "string",
  isRequired: true,
  tab: 1,
};

export const otherSubAreaValidation = {
  field_name: "otherSubArea",
  label: "Other Sub Area",
  type: "string",
  isRequired: true,
  tab: 1,
};

export const otherCategoryRule = {
  field_name: "category_other",
  label: "Others Incident Type",
  type: "string",
  isRequired: true,
  tab: 1,
};

export const injuriesRule = {
  field_name: "number_of_injuries",
  label: "Injuries",
  type: "number",
  // isRequired: true,
  minNumber: 0,
  tab: 3,
};

export const fatalityRule = {
  field_name: "number_of_fatality",
  label: "Fatality",
  type: "number",
  // isRequired: true,
  minNumber: 0,
  tab: 3,
};

export const placeOfIncidentRule = {
  field_name: "place_of_incident",
  label: "Location of Accident/Incident",
  type: "string",
  isRequired: true,
  tab: 3,
};

export const othersInfoRule = {
  field_name: "othersInfo",
  label: "Others",
  type: "string",
  isRequired: true,
  tab: 2,
};

export const incidentReportRules = [
  {
    tab: 1,
    field_name: "category",
    label: "Type",
    type: "string",
    isRequired: true,
  },
  {
    label: "Incident/Accident Severity",
    type: "string",
    isRequired: true,
    tab: 1,
    field_name: "severity",
  },
  {
    field_name: "incident_datetime",
    label: "Incident/Accident Date",
    type: "string",
    isRequired: true,
    tab: 1,
  },
  {
    label: "Report Date",
    type: "string",
    field_name: "incident_reportdatetime",
    isRequired: true,
    tab: 1,
  },
  {
    field_name: "siteType",
    label: "Site Name",
    type: "string",
    isRequired: true,
    tab: 1,
  },
  {
    label: "Site",
    type: "string",
    field_name: "siteId",
    isRequired: true,
    tab: 1,
  },
  {
    tab: 1,
    field_name: "staffType",
    label: "Staff Type",
    type: "string",
    isRequired: true,
  },
  {
    label: "Indirect Losses",
    field_name: "indirectLosses",
    isRequired: true,
    type: "string",
    tab: 2,
  },
  {
    isRequired: true,
    field_name: "facilityStatus",
    label: "Facility Status",
    type: "string",
    tab: 2,
  },
  {
    field_name: "similarIncidentHappened",
    label: "Similar Incident Happened",
    type: "string",
    isRequired: true,
    tab: 2,
  },
  {
    field_name: "summary",
    label: "Summary",
    type: "string",
    isRequired: true,
    tab: 2,
  },
  {
    field_name: "description",
    label: "Description",
    type: "string",
    isRequired: true,
    tab: 2,
  },
  {
    label: "Measures or Actions",
    type: "string",
    isRequired: true,
    field_name: "measuresOrActions",
    tab: 2,
  },
  {
    label: "Department",
    type: "string",
    isRequired: true,
    field_name: "department",
    tab: 1,
  },
  {
    field_name: "areaId",
    type: "string",
    isRequired: true,
    label: "Place",
    tab: 1,
  },
  {
    label: "Workings",
    type: "string",
    isRequired: true,
    field_name: "subAreaId",
    tab: 1,
  },
  {
    tab: 1,
    field_name: "shift",
    label: "Shift",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "activityId",
    label: "Activity",
    tab: 1,
    type: "string",
    isRequired: true,
  },
  {
    label: "Sub Activity",
    type: "string",
    isRequired: true,
    field_name: "subActivityId",
    tab: 1,
  },
  {
    field_name: "peopleCount",
    label: "People Count",
    tab: 3,
    type: "number",
    isRequired: true,
  },
  {
    field_name: "violationsLeading",
    label: "Incident/Accident Cause",
    type: "string",
    isRequired: true,
    tab: 2,
  },
  // {
  //   label: "Distance from the face",
  //   field_name: "distanceFromFace",
  //   isRequired: false,
  //   type: "number",
  //   tab: 2,
  // },
];

export const evidenceRules = [
  {
    field_name: "description",
    label: "Description",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "fileId",
    label: "File",
    type: "string",
    isRequired: true,
  },
];

export const vehicleDetailsRules = [
  {
    field_name: "number",
    label: "Number",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "driverName",
    label: "Driver Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "observation",
    label: "Observation",
    type: "string",
    isRequired: true,
  },
];

export const assetDetailsRules = [
  {
    field_name: "type",
    label: "Type",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "sku",
    label: "SKU",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "name",
    label: "Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "details",
    label: "Details",
    type: "string",
    isRequired: true,
  },
];

export const personalDetailsRules = [
  {
    field_name: "name",
    label: "Name",
    type: "string",
    isRequired: true,
  },
  // {
  //   field_name: "phone",
  //   label: "Phone",
  //   type: "string",
  //   isRequired: true,
  // },
  {
    field_name: "age",
    label: "Age",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "gender",
    label: "Gender",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "hoursAtWork",
    label: "Hours at Work	",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "outcome",
    label: "Outcome",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "injuryCode",
    label: "Injury Code",
    type: "string",
    isRequired: true,
  },
  // {
  //   field_name: "ltiHours",
  //   label: "LTI Hours",
  //   type: "number",
  //   isRequired: true,
  // },
  {
    field_name: "designation",
    label: "Designation",
    type: "string",
    isRequired: true,
  },
];

export const INCIDENT_VIEW_COMPONENT = (tab, data) => {
  switch (tab) {
    case 2:
      return <GeneralInfoView form={data} />;
    case 3:
      return <PersonnelDetailsView form={data} />;
    case 4:
      return <EquipmentDetailsView form={data} />;
    case 5:
      return <VehicleDetailsView form={data} />;
    case 6:
      return <IncidentEvidenceView form={data} />;
    default:
      return "";
  }
};

export const capaStatusView = {
  completed: "Completed",
  open: "Open",
};

export const capaFilterForm = {
  startDate: "",
  endDate: "",
  capaStatus: "",
};

export const statusProgressObj = {
  reported: "16",
  investigation_in_progress: "32",
  investigation_completed: "48",
  capa_assigned: "64",
  capa_closed: "90",
  incident_closed: "100",
  draft: "N/A",
};

export const severities = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
};

export const getIncidentSeverityClass = (severity) => {
  switch (severities[severity]) {
    case severities[1]:
      return "severityOne";
    case severities[2]:
      return "severityTwo";
    case severities[3]:
      return "severityThree";
    case severities[4]:
      return "severityFour";
    default:
      return "notstarted";
  }
};

export const roleData = {
  roles: ["safety_admin", "safety_user", "safety_management_dashbaord"],
};

export const filterOptionFunc = (form) => {
  let filter = "";
  for (const key in form) {
    const element = form[key];
    if (element) {
      if (key === "startDate" || key === "endDate")
        filter += `&${key}=${element}`;
      else if (key === "is_investigated" || key === "is_capa")
        filter += `&search[${key}]=${1}`;
      else filter += `&search[${key}]=${element}`;
    }
  }
  return filter;
};

export const bodyLabel = {
  notApplicable: "Not Applicable",
  head: "10 - Head",
  eye: "12 - Eye",
  back: "31 - Back",
  chest: "32 - Chest",
  pelvis: "34 - Pelvis",
  spinalColumn: "38 - Spinal column",
  upperLimb: "40 - Upper limb",
  fingers: "45 - Fingers",
  otherUpperLimbs: "49 - Other upper limbs",
  lowerLimbs: "50 - Lower limbs",
  others: "99 - Others",
};

export const BODY_PART_LIST = [
  ...Object.keys(bodyLabel).map((d) => ({ label: bodyLabel[d], value: d })),
];

export const checkValidCount = (
  peopleCount,
  injuryCount,
  fatalityCount,
  incidentType,
  field
) => {
  if (field === "number_of_injuries") {
    if (incidentType !== "fatality") return injuryCount <= peopleCount;
    else {
      if (!fatalityCount) return injuryCount <= peopleCount;
      else if (fatalityCount) return injuryCount <= peopleCount - fatalityCount;
    }
  } else {
    if (!injuryCount) return fatalityCount <= peopleCount;
    else return fatalityCount <= peopleCount - injuryCount;
  }
};

export const injuryCodeObj = {
  amputation: "1 - Amputation",
  fracture: "2 - Fracture",
  internalInjury: "3 - Internal injury",
  burnInjury: "4 - Burn injury",
  others: "5 - Others",
};

export const compensationObjLavel = {
  outcome: "Outcome",
  injuryCode: "Name of Injured/Killed",
  dateOfReturnDuty: "Date of Return to Duty",
  returnForRegular: "Returned for Regular other Job",
  compensationPaid: "Compensation Paid (Rs)",
  exGratiaPaid: "Ex Gratia Paid (Rs)",
  otherBenefits: "Other Benefits or Benefits to Dependents",
  statutoryCompensationPaid: "Statutory Compensation Paid (Rs)",
  totalExperience: "Total Experience(months)",
  jobDoneAtAccidentTime: "Job done at the time of accident",
  experienceInJobAtAccidentTime: "Experience in Job at time of accident",
  vocationallyTrained: "Vocationally trained",
  initialVocationTraining: "Initial Vocation Training",
  dateOfRefresherTraining: "Date of Refresher Training",
  jobNature: "Job Nature",
  causeOfDeath: "Cause of Death",
  bodyPartsLoss: "Body Parts(s) loss",
  bodyPartsGoneOut: "Body Parts(s) Gone out of use",
};
export const responsiblePersonLabel = {
  responsiblePerson: "Person Responsible",
  designationCode: "Designation Code",
  designation: "Designation",
  rulesConverted: "Rules Contravened",
  natureOfContravention: "Nature of Contravention",
  actionTaken: "Action Taken",
};
export const responsiblePersonForm = {
  responsiblePerson: "",
  designationCode: "",
  designation: "",
  rulesConverted: "",
  natureOfContravention: "",
  actionTaken: "",
};
export const informatinsInitialObj = {
  // outcome: "",
  // injuryCode: "",
  dateOfReturnDuty: "",
  returnForRegular: "",
  compensationPaid: "",
  exGratiaPaid: "",
  totalExperience: "",
  otherBenefits: "",
  statutoryCompensationPaid: "",
  jobDoneAtAccidentTime: "",
  experienceInJobAtAccidentTime: "",
  vocationallyTrained: "",
  initialVocationTraining: "",
  dateOfRefresherTraining: "",
  jobNature: "",
  causeOfDeath: "",
};

export const informationRules = [
  {
    field_name: "dateOfReturnDuty",
    label: compensationObjLavel.dateOfReturnDuty,
    type: "String",
    isRequired: true,
  },
  {
    field_name: "returnForRegular",
    label: compensationObjLavel.returnForRegular,
    type: "String",
    isRequired: true,
  },
  {
    field_name: "compensationPaid",
    label: compensationObjLavel.compensationPaid,
    type: "number",
    isRequired: true,
  },
  {
    field_name: "exGratiaPaid",
    label: compensationObjLavel.exGratiaPaid,
    type: "number",
    isRequired: true,
  },
  {
    field_name: "totalExperience",
    label: compensationObjLavel.totalExperience,
    type: "number",
    isRequired: true,
  },
  {
    field_name: "otherBenefits",
    label: compensationObjLavel.otherBenefits,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "statutoryCompensationPaid",
    label: compensationObjLavel.statutoryCompensationPaid,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "jobDoneAtAccidentTime",
    label: compensationObjLavel.jobDoneAtAccidentTime,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "experienceInJobAtAccidentTime",
    label: compensationObjLavel.experienceInJobAtAccidentTime,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "vocationallyTrained",
    label: compensationObjLavel.vocationallyTrained,
    type: "number",
    isRequired: true,
  },
  {
    field_name: "initialVocationTraining",
    label: compensationObjLavel.initialVocationTraining,
    type: "number",
    isRequired: true,
  },
  {
    field_name: "jobNature",
    label: compensationObjLavel.jobNature,
    type: "number",
    isRequired: true,
  },
  // {
  //   field_name: "dateOfRefresherTraining",
  //   label: compensationObjLavel.dateOfRefresherTraining,
  //   type: "string",
  //   isRequired: true,
  // },
  {
    field_name: "causeOfDeath",
    label: compensationObjLavel.causeOfDeath,
    type: "string",
    isRequired: true,
  },
];

export const responsibilityRules = [
  {
    field_name: "responsiblePerson",
    label: responsiblePersonLabel.responsiblePerson,
    type: "String",
    isRequired: true,
  },
  {
    field_name: "designationCode",
    label: responsiblePersonLabel.designationCode,
    type: "String",
    isRequired: true,
  },
  {
    field_name: "designation",
    label: responsiblePersonLabel.designation,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "rulesConverted",
    label: responsiblePersonLabel.rulesConverted,
    type: "String",
    isRequired: true,
  },
  {
    field_name: "natureOfContravention",
    label: responsiblePersonLabel.natureOfContravention,
    type: "String",
    isRequired: true,
  },
  {
    field_name: "actionTaken",
    label: responsiblePersonLabel.actionTaken,
    type: "string",
    isRequired: true,
  },
];

export const outcomeOpt = ["Minor Injury", "Serious Injury", "Fatality"];
