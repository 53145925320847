import FormInputText from "../../../../components/FormInputText/FormInputText";
import { Col, Row } from "react-bootstrap";
import { mineLabel } from "./DATA";

const AgentDetailsForms = ({ form, error, onChange }) => {
  return (
    <Row>
      <Col lg={3}>
        <FormInputText
          name="nameAgent"
          value={form.nameAgent}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.nameAgent}
          error={error?.nameAgent}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="postalAddressAgent"
          value={form.postalAddressAgent}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.postalAddressAgent}
          error={error?.postalAddressAgent}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="districtAgent"
          value={form.districtAgent}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.districtAgent}
          error={error?.districtAgent}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="stateAgent"
          value={form.stateAgent}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.stateAgent}
          error={error?.stateAgent}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="pinAgent"
          value={form.pinAgent}
          onChange={onChange}
          type="number"
          class="form-control"
          placeholder=""
          label={mineLabel.pinAgent}
          error={error?.pinAgent}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="mobileAgent"
          value={form.mobileAgent}
          onChange={onChange}
          type="number"
          class="form-control"
          placeholder=""
          label={mineLabel.mobileAgent}
          error={error?.mobileAgent}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="teleAgent"
          value={form.teleAgent}
          onChange={onChange}
          type="number"
          class="form-control"
          placeholder=""
          label={mineLabel.teleAgent}
          error={error?.teleAgent}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="faxAgent"
          value={form.faxAgent}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.faxAgent}
          error={error?.faxAgent}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="emailAgent"
          value={form.emailAgent}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.emailAgent}
          error={error?.emailAgent}
        />
      </Col>
    </Row>
  );
};
export default AgentDetailsForms;
