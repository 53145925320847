import React from "react";
import { reportTypeList } from "../../../_pages/EnvironmentManagement/UploadedFiles/DATA";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import MultiSelectWithCheckbox from "../../../components/SearchableSelect/MultiSelectCkeckOption";
const UploadFileFilterForm = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
  contractorList,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>Input Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              name="endDate"
              onChangeDate={onChange}
            />
          </Col>
          {/* <Col md={12}>
            <Form.Label>Report type</Form.Label>
            <MultiSelectWithCheckbox
              options={reportTypeList}
              value={form?.file_type
                ?.split(",")
                ?.map((id) => {
                  const found = reportTypeList.find(
                    (site) => site.value === id
                  );
                  return found ? { ...found } : null;
                })
                ?.filter((d) => !!d)}
              onChange={(selectedSites) => {
                onChange({
                  target: {
                    name: "file_type",
                    value: selectedSites?.map((site) => site.value).join(","),
                  },
                });
              }}
              // disabled={completionFlag || mmFlag}
              hasSelectAll={false}
              disableSearch={true}
            />
          </Col> */}
        </Row>
        <Row className="btnArea py-3">
          <Col>
            <Button
              type="button"
              data-testid="onResetFilter"
              onClick={onResetFilter}
              className="secondaryBtn"
            >
              Clear all
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              data-testid="onSubmitFilter"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default UploadFileFilterForm;
