import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import CoalDispatchForm from "./CoalDispatchForm";
import SampleRelatedForm from "./SampleRelatedForm";
import { isDecimal } from "../../../_helpers/helper";
import { Col, Row } from "react-bootstrap";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { employeeNameEmailLabel } from "../../../_helpers/helper";
import {
  coalDispatchRelatedRules,
  samplePrepareRegisterInitialForm,
  samplePrepareRegisterRules,
} from "./DATA";
import { useForm } from "../../../_helpers/hooks";
import { Validation } from "../../../_helpers/validation/Validation";
import toastr from "toastr";
import {
  createSamplePrepareRegister,
  coalGradeRrGradeList,
  salesData,
} from "../../../services/_coalGrade/coalGrade";
import { useNavigate, useParams } from "react-router";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import {
  SidebarData,
  allGrades,
  coalGradeApis,
  coalGrateTypeObj,
  submitStatus,
} from "../DATA";
import { StepButton } from "../../../components/_button/StepButton";
import { auth_user, idByCheckMine } from "../../../_helpers/helper";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { getAllMasterDropdownSamplingAgency } from "../../../services/_reasonList/reasonList";
import { getAllSites } from "../../../_services/SiteServices/SiteServices";
import { addOneDayAndFormat } from "../../../_helpers/helper";

const SamplePrepareRegisterAdd = () => {
  const [tab, setTab] = useState(1);
  const [show, setShow] = useState(false);
  const [error, setError] = useState({});
  const [rrListOption, setRrListOption] = useState([]);
  const [rrGradeList, setRrGradeList] = useState([]);
  const [gradeListOption, setGradeListOption] = useState([]);
  const [samplingAgencyOpt, setSamplingAgencyOpt] = useState([]);
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [salesDatas, setSalesDatas] = useState();
  const [sites, setSites] = useState([]);
  const [form, onChange, setForm] = useForm({
    ...samplePrepareRegisterInitialForm,
    site_id: auth_user() ? auth_user()?.siteId : null,
  });
  const params = useParams("id");
  const navigate = useNavigate();
  const [gradeList, setGradeList] = useState(
    Object.keys(allGrades).map((key) => {
      return { value: key, label: key };
    })
  );

  const tabFun = (n) => {
    setTab(n);
  };

  const closePopup = () => {
    setShow(false);
    navigate(`/coal-grade/sample-prepare/actions/list`);
  };

  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };

  const nextStapeFun = () => {
    const errorObj = Validation(form, coalDispatchRelatedRules);
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) return;
    setTab(2);
  };

  const onSubmit = async (submitType) => {
    let errorObj = {};
    let data = {};

    if (submitType == submitStatus?.drafted) {
      data = { ...form, sample_prepare_status: submitStatus?.drafted };
    } else {
      data = { ...form, sample_prepare_status: submitStatus?.submitted };

      errorObj = Validation(form, samplePrepareRegisterRules);
      setError(errorObj);
    }

    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    const response = await createSamplePrepareRegister(data);
    if (!response?.status) return toastr.error(response?.message);
    if (submitType == submitStatus?.drafted) {
      setForm((prev) => {
        return { ...prev, ...response?.data?.coalGradeQualityData };
      });
      toastr.success(`Saved Successfully`);
    } else {
      setShow(true);
      setMessagePopupType(false);
    }
  };

  const getAll = async (filter = "") => {
    const filterObj = `type=${coalGrateTypeObj?.samplePrepare}`;
    filter += `&${filterObj}`;
    const response = await coalGradeApis.getAll.api(filter);

    return response?.data;
  };

  const getSiteList = async () => {
    let allsiteList = await getAllSites(
      "filter[siteType]=1,2,3&filter[activeStatus]=active"
    );
    if (allsiteList?.status === 1 && allsiteList?.message == "success") {
      setSites(allsiteList?.data);
    }
  };

  useEffect(() => {
    (async () => {
      if (!params?.id) return;
      let data = await getAll(`search[id]=${params?.id}&status_filter=true`);
      if (data[0]?.sample_prepare_status == submitStatus?.submitted) {
        toastr.error("Unauthorized");
        navigate("/coal-grade/sample-prepare/actions/list");
      }

      data.map((data) => {
        return setForm((prev) => {
          return { ...prev, ...data };
        });
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      site_id: idByCheckMine(sites)?.siteId,
      mine: idByCheckMine(sites)?.subsideryId,
    }));
  }, [sites]);

  const getRrList = async (filter) => {
    const RrGradeList = await coalGradeRrGradeList(filter);
    if (RrGradeList?.status) {
      setRrGradeList(RrGradeList?.data?.listRoGradeData);
      const options = RrGradeList?.data?.listRoGradeData?.map((item) => {
        return {
          value: item.rr_number,
          label: String(item.rr_number),
        };
      });
      setRrListOption(options);
    }
  };
  useEffect(() => {
    if (form?.mode_of_dispatch && form?.rr_or_do_date && form?.site_id) {
      let filter = [];
      for (const key in form) {
        if (
          key === "rr_or_do_date" ||
          key === "mode_of_dispatch" ||
          key === "site_id"
        ) {
          const element = form[key];
          if (element) {
            filter += `&${key}=${element}`;
          }
        }
      }
      getRrList(filter);
    }
  }, [form?.mode_of_dispatch, form?.rr_or_do_date, form?.site_id]);

  useEffect(() => {
    if (form?.rr_or_do_number !== "" && rrGradeList) {
      let filtered = rrGradeList.filter(
        (obj) => obj.rr_number === form?.rr_or_do_number
      );
      let options = filtered.map((item) => {
        if (item.rr_number == form?.rr_or_do_number) {
          return {
            value: item.declared_grade,
            label: String(item.declared_grade).substring(0, 3),
          };
        }
      });
      setGradeListOption(options);
      form.declared_grade = options[0]?.value;
    }
  }, [form?.rr_or_do_number, rrGradeList]);

  const samplingAgency = async () => {
    let response = await getAllMasterDropdownSamplingAgency();
    if (response?.status) {
      let options = response.data.map((item) => {
        return {
          value: String(item.id),
          label: item.name,
        };
      });
      setSamplingAgencyOpt(options);
    }
  };
  useEffect(() => {
    if (params.id) return;
    form.date_of_sample_sent_to_lab = addOneDayAndFormat(form?.rr_or_do_date);
    form.date_of_prepartaion = addOneDayAndFormat(form?.rr_or_do_date);
  }, [form.rr_or_do_date]);
  useEffect(() => {
    samplingAgency();
    getSiteList();
  }, []);
  const getSalesData = async () => {
    let query = `rr_or_do_number=${form?.rr_or_do_number}&declared_grade=${form?.declared_grade}&rr_or_do_date=${form?.rr_or_do_date}&mode_of_dispatch=${form?.mode_of_dispatch}&site_id=${form?.site_id}`;
    let res = await salesData(query);
    if (res?.status) setSalesDatas(res?.data?.salesData);
    else setSalesDatas();
  };

  useEffect(() => {
    if (
      form?.site_id &&
      form?.mode_of_dispatch &&
      form?.rr_or_do_date &&
      form?.rr_or_do_number &&
      form?.declared_grade
    ) {
      getSalesData();
    } else {
      setSalesDatas();
    }
  }, [
    form?.site_id,
    form?.mode_of_dispatch,
    form?.rr_or_do_date,
    form?.rr_or_do_number,
    form?.declared_grade,
  ]);

  form.date_of_collection = form.rr_or_do_date;

  useEffect(() => {
    if (params.id) return;
    let UserData = employeeNameEmailLabel();
    console.log("UserDataUserDataUserData", UserData);
    setForm((prev) => {
      return {
        ...prev,
        name_of_sampling_agency_representative: UserData?.nameEmail,
      };
    });
  }, []);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <Container fluid>
              <h3
                className=""
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Coal Grade",
                      path: "/",
                    },
                    {
                      name: "Coal Quality",
                      path: "/coal-grade/list",
                    },
                    {
                      name: "Sample Prepare",
                      path: "/coal-grade/sample-prepare/actions/list",
                    },
                    {
                      name: "Add Sample",
                      path: "#",
                    },
                  ]}
                />
              </h3>
              <h3 className="title">Add Sample</h3>
              <div className="creationCard">
                <div className="formStepperWrapper">
                  <ul>
                    <li
                      className={`coalDispatchStep ${
                        tab === 1 ? "activeStep" : "doneStep"
                      }`}
                    >
                      <div className="stepIcon">
                        <span></span>{" "}
                      </div>
                      <label className="mt-3">
                        Coal Dispatch / Sample Prepare
                      </label>
                    </li>
                    <li
                      className={`sampleRelatedStep ${
                        tab !== 2 ? "initialStep" : "activeStep"
                      }`}
                    >
                      <div className="stepIcon">
                        <span></span>{" "}
                      </div>
                      <label className="mt-3">Sample Related Data</label>
                    </li>
                  </ul>
                </div>
                <div className="formWrapper">
                  <div className="maverickFormCard">
                    {tab === 1 && (
                      <CoalDispatchForm
                        error={error}
                        setTab={tabFun}
                        form={form}
                        onChange={onChange}
                        gradeList={gradeList}
                        samplingAgencyOpt={samplingAgencyOpt}
                        rrListOption={rrListOption ? rrListOption : []}
                        gradeListOption={gradeListOption ? gradeListOption : []}
                      />
                    )}
                    {tab === 2 && (
                      <SampleRelatedForm
                        error={error}
                        form={form}
                        onChange={onChange}
                      />
                    )}
                    {salesDatas &&
                      tab === 1 &&
                      form?.site_id &&
                      form?.mode_of_dispatch &&
                      form?.rr_or_do_date &&
                      form?.rr_or_do_number &&
                      form?.declared_grade && (
                        <Row
                          className="creationWrapper"
                          style={{
                            marginLeft: "2%",
                          }}
                        >
                          <Col md={5} className="d-flex">
                            <label className="me-2 form-label-input">
                              Fnote No:-
                            </label>
                            <p className="content">
                              {salesDatas[0]?.fnote_or_dnote_number}
                            </p>
                          </Col>
                          <Col md={3} className="d-flex">
                            <label className="me-2 form-label-input">
                              RR No:-
                            </label>
                            <p className="content">
                              {salesDatas[0]?.rr_number}
                            </p>
                          </Col>
                          <Col md={4} className="d-flex">
                            <label className="me-2 form-label-input">
                              Invoice Number:-
                            </label>
                            <p className="content">
                              {salesDatas[0]?.invoice_number}
                            </p>
                          </Col>
                          <Col md={5} className="d-flex">
                            <label className="me-2 form-label-input">
                              Billed Quantity:-
                            </label>
                            <p className="content">
                              {isDecimal(+salesDatas[0]?.billed_qty)}
                            </p>
                          </Col>
                          <Col md={3} className="d-flex">
                            <label className="me-2 form-label-input">
                              Fnote Date:-
                            </label>
                            <p className="content">
                              {salesDatas[0]?.fnote_or_dnote_Date &&
                                DDMMYYYYFormat(
                                  salesDatas[0]?.fnote_or_dnote_Date
                                )}
                            </p>
                          </Col>
                          <Col md={3} className="d-flex">
                            <label className="me-2 form-label-input">
                              RR Date:-
                            </label>
                            <p className="content">
                              {salesDatas[0]?.rr_date &&
                                DDMMYYYYFormat(salesDatas[0]?.rr_date)}
                            </p>
                          </Col>

                          <Col md={5} className="d-flex">
                            <label className="me-2 form-label-input">
                              Sales Document Number:-
                            </label>
                            <p className="content">
                              {salesDatas[0]?.sales_document}
                            </p>
                          </Col>

                          <Col md={7} className="d-flex">
                            <label className="me-2 form-label-input">
                              Buyer Name:-
                            </label>
                            <p className="content">
                              {salesDatas[0]?.buyer_name_sales}
                            </p>
                          </Col>
                        </Row>
                      )}
                  </div>
                </div>

                <div className="btnSec">
                  <Button
                    data-testid="handle-click-for-show"
                    onClick={() => closeFun()}
                    className="secondaryBtn"
                  >
                    Close
                  </Button>

                  <StepButton
                    tab={tab}
                    tabFun={tabFun}
                    onSubmit={onSubmit}
                    nextStapeFun={nextStapeFun}
                  />
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <MessagePopup
        messagePopupType={messagePopupType}
        show={show}
        setShow={setShow}
        closePopup={closePopup}
      />
    </div>
  );
};

export default SamplePrepareRegisterAdd;
