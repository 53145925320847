import React from "react";

const Asterik = ({ fieldName, requiredRules = [] }) => {
  const findRule =
    (Array.isArray(requiredRules) &&
      requiredRules?.find((obj) => obj?.field_name === fieldName)) ||
    {};
  return findRule?.isRequired ? <span style={{ color: "red" }}>*</span> : "";
};

export default Asterik;
