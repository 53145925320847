import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";
import { IS_MOBILE, siteId } from "../../../../_helpers/helper";
import { reportTypeListUrl } from "../../../EnvironmentManagement/UploadedFiles/DATA";
import { newStatusColor, statusList, statusListObj } from "../../DATA";

export const wigetList1 = [
  {
    name: "LTI Count",
    key: "lti_count",
    value: "",
  },
  {
    name: "% CAPA Closure",
    key: "capa_closure",
    value: "",
  },
  {
    name: "Near Miss",
    key: "near_misses",
    value: "",
  },
  {
    name: "Injuries",
    key: "injuries",
    value: "",
  },
  {
    name: "Fatalities",
    key: "fatalities",
    value: "",
  },
];

export const wigetList2 = [
  {
    name: "Avg time to close Incidents",
    key: "avg_time_to_close_incident",
    value: "",
  },
  {
    name: "Avg time to close CAPA",
    key: "avg_time_to_close_capa",
    value: "",
  },
  {
    name: "Avg time to close Investigations",
    key: "avg_time_to_close_investigation",
    value: "",
  },
];

export const pageOneChartList = [
  {
    isHo: false,
    isRO: false,
    title: "Incident/Accident Status",
    value: "incident_status",
    type: "pieVs",
  },
  {
    title: "Investigation Status",
    type: "pieVs",
    isRO: false,
    isHo: false,
    value: "investigation_status",
  },
  {
    type: "pieVs",
    value: "capa_status",
    isRO: false,
    isHo: false,
    title: "CAPA Status",
  },
  {
    isHo: false,
    title: "Incident/Accident By Worker Type",
    value: "person_designation",
    type: "pieVs",
    isRO: false,
  },
  // {
  //   isHo: false,
  //   title: "Incident By Department Type",
  //   value: "incident_deparment_data",
  //   type: "pieVs",
  //   isRO: false,
  // },
  {
    isHo: false,
    value: "incident_workArea_data",
    type: "pieVs",
    isRO: false,
    title: "Incident/Accident By Place Type",
  },
  {
    title: "Incident/Accident By Day Time",
    isHo: false,
    type: "pieVs",
    isRO: false,
    value: "incident_day_time_data",
  },
];

export const pageThreeChartList = [
  {
    title: "Incidents/Accident by Type",
    yAxisName: "Count",
    xAxisName: "Incident/Accident Type",
    value: "incident_type",
    type: "barWithCustomTooltip",
    className: "barchartOuter",
  },
  {
    title: "Incidents/Accident by Severity",
    yAxisName: "Count",
    xAxisName: "Severity",
    value: "incident_severity",
    type: "barWithCustomTooltip",
    className: "barchartOuter",
  },
  {
    title: "Lost Time Injury counts",
    yAxisName: "Count",
    xAxisName: "Period",
    value: "lti_count",
    type: "barWithCustomTooltip",
    className: "barchartOuter",
  },
  {
    title: "CAPA Status (Outstanding)",
    yAxisName: "Count",
    xAxisName: "Period",
    value: "capa_status",
    type: "barWithCustomTooltip",
    className: "barchartOuter",
  },
  {
    title: "Incidents/Accident by Mine",
    value: "incident_area",
    type: "pieVs",
    isRO: false,
    isHo: false,
    className: "piecartOuter",
  },
  {
    title: "Investigations by Mine",
    value: "investigation_area",
    type: "pieVs",
    isRO: false,
    isHo: false,
    className: "piecartOuter",
  },
];

export const pageFourChartList = [
  {
    isRO: false,
    title: "Body Part Analysis",
    value: "incident_bodyParts",
    type: "pieVs",
    isHo: false,
  },
  {
    value: "violations_data",
    title: "Causes",
    type: "pieVs",
    isRO: false,
    isHo: false,
  },
  {
    type: "pieVs",
    title: "Incidents/Accident by Shifts",
    value: "incident_shift",
    isRO: false,
    isHo: false,
  },
  {
    title: "Age Analysis",
    value: "incident_age_graph",
    type: "pieVs",
    isRO: false,
    isHo: false,
  },
  {
    isHo: false,
    title: "Incident/Accident by Categories",
    value: "incident_category",
    type: "pieVs",
    isRO: false,
  },
  {
    title: "Incident/Accident by Months",
    isHo: false,
    value: "incident_byMonth",
    type: "pieVs",
    isRO: false,
  },
];

export const desktpScreenPage = [
  {
    link: "/safety-management-system/dashboard/1",
    screen: 1,
  },
  {
    link: "/safety-management-system/dashboard/2",
    screen: 2,
  },
  {
    link: "/safety-management-system/dashboard/3",
    screen: 3,
  },
  {
    link: "/safety-management-system/dashboard/4",
    screen: 4,
  },
];

export const mobileScreenPage = [
  {
    link: "/mobile/safety-management-system/dashboard/1",
    screen: 1,
  },
  {
    link: "/mobile/safety-management-system/dashboard/2",
    screen: 2,
  },
  {
    link: "/mobile/safety-management-system/dashboard/3",
    screen: 3,
  },
  {
    link: "/mobile/safety-management-system/dashboard/4",
    screen: 4,
  },
];

export const screenPage = IS_MOBILE() ? mobileScreenPage : desktpScreenPage;

export const initialFilterForm = {
  // areaId: "",
  siteId: "",
  subsideryId: "",
  startDate: "",
  endDate: "",
  category: "",
};

export const boxStyles = (width, height) => {
  return {
    height: `${height}px`,
    width: `${width}%`,
    backgroundColor: "#d4f4f7bf",
    padding: "5px",
    border: "1px solid #fff",
  };
};

export const breakArrayIntoGroups = (dataArray, groupSize) => {
  let groups = [];
  for (let i = 0; i < dataArray?.length; i += groupSize) {
    let group = dataArray?.slice(i, i + groupSize);
    groups.push(group);
  }
  return groups;
};

export const incidentSummary = [
  {
    name: "Incident ID",
    selector: "incident_uid",
    isShowMobile: true,
  },
  {
    name: "Description",
    selector: "description",
    customClass: "descriptionFull",
    minWidth: "200px",
    cell: (row) => {
      const desText = row?.description?.substring(0, 15);
      return (
        <span title={row?.description}>
          {!IS_MOBILE()
            ? desText?.length < 14
              ? desText
              : desText + "..."
            : row?.description}
        </span>
      );
    },
  },
  {
    name: "Incident Date",
    selector: "incident_datetime",
    cell: (row) =>
      row?.incident_datetime ? DDMMYYYYFormat(row?.incident_datetime) : "",
  },
  {
    name: "Closure Date",
    selector: "incident_close_time",
    cell: (row) =>
      row?.incident_close_time ? DDMMYYYYFormat(row?.incident_close_time) : "",
  },
  {
    name: "Status",
    selector: "status",
    cell: (row) =>
      row?.status ? (
        <span className={`statusSec ${newStatusColor(row?.status)}`}>
          {row?.status !== statusListObj?.investigation_in_progress
            ? statusList[row?.status]
            : "In Progress"}
        </span>
      ) : (
        ""
      ),
    isShowMobile: true,
  },
];

export const incidentTableTypeObj = {
  incidentSummuryData: "incidentSummuryData",
  incidentInvestigationSummuryData: "incidentInvestigationSummuryData",
};

export const SelectedDateLable = ({ form, minMaxDate, type }) => {
  const minDate =
    type !== reportTypeListUrl?.Plantation_Project
      ? minMaxDate?.minDate
        ? DDMMYYYYFormat(minMaxDate?.minDate)
        : ""
      : minMaxDate?.minDate || "";
  const maxDate =
    type !== reportTypeListUrl?.Plantation_Project
      ? minMaxDate?.maxDate
        ? DDMMYYYYFormat(minMaxDate?.maxDate)
        : ""
      : minMaxDate?.maxDate || "";

  const selectedYear = form?.year || "";
  const startDate = form?.startDate ? DDMMYYYYFormat(form?.startDate) : "";
  const endDate = form?.endDate ? DDMMYYYYFormat(form?.endDate) : "";
  const minMaxDateValue = minDate && maxDate && `(${minDate} - ${maxDate})`;
  return (
    <span className="blueLabel" data-testId="minMaxDate">
      {type !== reportTypeListUrl?.Plantation_Project
        ? "Date Duration: "
        : "Year: "}
      {/* Date Duration:{" "} */}
      {selectedYear
        ? selectedYear
        : startDate && endDate
        ? `${startDate} - ${endDate}`
        : "Inception " + minMaxDateValue}
    </span>
  );
};

export const SelectedMineSubsidiary = (form) => {
  console.log("aaaaaaaaaaaaaaaaaaaaa", form.form);
  return (
    <div>
      {!form.form.siteId && !form.form.subsideryId ? (
        <div className="blueLabel">NCL/SECL: All Mines</div>
      ) : (
        ""
      )}
      {!form.form.siteId && form.form.subsideryId ? (
        <div className="blueLabel">
          {siteId[form.form.subsideryId]}: All Mines
        </div>
      ) : (
        ""
      )}
      {form.form.siteId && form.form.subsideryId ? (
        <div className="blueLabel">
          {siteId[form.form.subsideryId]}: {siteId[form.form.siteId]}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
