import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

export const getAllMasterDropdownReson = async (filter) => {
  let url = "/master-data/type/reason_list";
  if (filter) url += "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllMasterDropdownOtherReson = async (filter) => {
  let url = "/master-data/type/other_reason_list";
  if (filter) url += "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllMasterDropdownDesignation = async (filter) => {
  let url = "/master-data/type/designation";
  if (filter) url += "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllWorkshopMasters = async (filter) => {
  let url = "/master-data/type/workshop_id";
  if (filter) url += "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllMasterDropdownViolationsLeading = async (filter) => {
  let url = "/master-data/type/violations_leading?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllMasterDropdownSamplingAgency = async () => {
  let url = "/master-data/all/type/sampling_agency";
  try {
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllMasterResonForDropdown = async () => {
  let url = "/master-data/all/type/reason_list";
  try {
    const response = await RestMethod.GET(url);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllMasterDropdownstationCategoryAirQualityEmr = async () => {
  let url = "/master-data/type/station_category_air_quality_emr";
  try {
    const response = await RestMethod.GET(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllPollutionControlMachineApis = async (filter) => {
  try {
    let url = "/site-based-master-data/type/pollution_control_machine";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.incident || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllEquipmentApis = async (filter) => {
  try {
    let url = "/master-data/type/equipment";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllEquipmentTypeApis = async (filter) => {
  try {
    let url = "/master-data/type/equipment_type";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllPollutionApis = async (filter) => {
  try {
    let url = "/master-data/type/pollution_control_machine";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllLandReclamationApis = async (filter) => {
  try {
    let url = "/site-based-master-data/type/land_reclamation";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllPowerConsumptionApis = async (filter) => {
  try {
    let url = "/site-based-master-data/type/power_consumption";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllSection = async (filter) => {
  try {
    let url = "/site-based-master-data/type/section";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllConfig = async (filter, lastSegment) => {
  try {
    let url = "/master-data/type/" + lastSegment + "/";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllConfigSite = async (filter, lastSegment) => {
  try {
    let url = "/site-based-master-data/type/" + lastSegment + "/";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

// station_category_noise

export const getAllMasterDropdownStationCategoryNoise = async (type) => {
  let url = "/master-data/type/station_category_noise";
  try {
    const response = await RestMethod.GET(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllMasterDropdownStationTypeWater = async () => {
  let url = "/master-data/type/station_type_water";
  try {
    const response = await RestMethod.GET(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getCoalQualitySapListWithPagination = async (filter) => {
  
  let url = "/environment-management/coal-quality-sap";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getEnvSapListWithPagination = async (filter) => {
  
  let url = "/environment-management/env-sap-data";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    console.log("dataaaaaaaaaaaaaaaa",data);
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteCoalQualitySap = async (filter) => {
  let url = "/environment-management/coal-quality-sap/delete";

  if (filter) url += "/" + filter;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteEnvSap = async (filter) => {
  let url = "/environment-management/env-sap/delete";

  if (filter) url += "/" + filter;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const exportCoalQualitySapById = async (id) => {
  let url = "/environment-management/coal-quality-sap-export/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const exportEnvSapById = async (id) => {
  let url = "/environment-management/env-sap-export/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getAllMasterDropdownstationCategoryWater = async (type) => {
  let url = "/master-data/type/station_category_water";
  try {
    const response = await RestMethod.GET(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

// station api
export const getAllEnvConfigcaaqms = async (filter) => {
  try {
    let url = "/env-station-master-data/type/CAAQMS";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllEnvConfigcmpdil = async (filter) => {
  try {
    let url = "/env-station-master-data/type/CMPDIL";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllEnvConfigWaterQuality = async (filter) => {
  try {
    let url = "/env-station-master-data/type/water_quality";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllEnvConfigNoiseLevel = async (filter) => {
  try {
    let url = "/env-station-master-data/type/noise_level";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllEnvConfigGroundWater = async (filter) => {
  try {
    let url = "/env-station-master-data/type/ground_water_CMPDIL";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

// PERMISSIBLE api
export const getAllEnvConfigcaaqmsPermissible = async (filter) => {
  try {
    let url = "/env-permissible-master-data/type/CAAQMS_permissible";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllEnvConfigcmpdilPermissible = async (filter) => {
  try {
    let url = "/env-permissible-master-data/type/CMPDIL_permissible";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllEnvConfigNoisePermissible = async (filter) => {
  try {
    let url = "/env-permissible-master-data/type/noise_permissible";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deletePermissibleMasterDropdown = async (id) => {
  let url = "/env-permissible-master-data/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteMasterDropdown = async (id) => {
  let url = "/master-data/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteFuelConfigWithSiteApis = async (id) => {
  let url = "/site-based-master-data/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteEnvConfigStation = async (id) => {
  let url = "/env-station-master-data/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteFuelConfigWithoutSiteApis = async (id) => {
  let url = "/master-data/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const createMasterDropdown = async (data) => {
  let url = "/master-data/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateMasterDropdown = async (data, id) => {
  let url = "/master-data/update/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updatePOMaster = async (data, id) => {
  let url = "contractor/po-data/update/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getAllEnvConfigStation = async (filter, lastSegment) => {
  try {
    let url = "/env-station-master-data/type/" + lastSegment + "/";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllEnvConfigPermissible = async (filter, lastSegment) => {
  try {
    let url = "/env-permissible-master-data/type/" + lastSegment + "/";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const deleteEnvConfigStationApis = async (id) => {
  let url = "/env-station-master-data/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllMasterDropdownSupplier = async (filter) => {
  let url = "/master-data/type/supplier";
  if (filter) url += "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
