import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { IS_MOBILE, getUniqueQueryParams } from "../../_helpers/helper";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { TABLE_EXPEND_ROW } from "../DataTableWithServer/DATA";
import CustomPagination from "../DataTableWithServer/CustomPagination";
import { getQueryParam } from "../../_helpers/commonHelper";
import { useDebounce } from "../../_helpers/hooks";

const DataTableComponent = forwardRef(
  (
    {
      columns = [],
      keyField,
      filterDataBy,
      fixedHeader,
      getApi,
      dataFilter,
      dashboardType,
    },
    ref
  ) => {
    const paginationPerPageArray = [10, 15, 20, 25, 30];

    const [rows, setRows] = useState(() => []);

    const [totalRows, setTotalRows] = useState(0);

    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(() => +(getQueryParam("page") || 1));

    const [finalColumns, setFinalColumns] = useState(() => []);

    const [perPage, setPerPage] = useState(() =>
      paginationPerPageArray.includes(+getQueryParam("limit"))
        ? +getQueryParam("limit")
        : 10
    );

    const formFilter = null;

    const [filter, setFilter] = useState(null);

    const getAllData = async () => {
      let filterObj = formFilter && filter ? `${formFilter}&${filter}` : filter;
      if (!filterObj) return;
      const response = await getApi(filterObj);

      if (dashboardType === "fuelSapDashboard1") {
        setRows(
          response?.data?.consumptionDetails?.map((d, i) => {
            d.sid = (page - 1) * perPage + i + 1;
            return d;
          })
        );
        setTotalRows(response?.data?.totalCount);
      }

      if (dashboardType === "incidentAccident") {
        setRows(
          response?.data?.incidentSummuryData?.map((d, i) => {
            d.sid = (page - 1) * perPage + i + 1;
            return d;
          })
        );
        setTotalRows(response?.data?.count);
      }

      if (dashboardType === "investigation") {
        setRows(
          response?.data?.incidentInvestigationSummuryData?.map((d, i) => {
            d.sid = (page - 1) * perPage + i + 1;
            return d;
          })
        );
        setTotalRows(response?.data?.count);
      }

      if (dashboardType === "fuelDashboard2") {
        setRows(
          response?.data?.fuelReceiptDatas?.map((d, i) => {
            d.sid = (page - 1) * perPage + i + 1;
            return d;
          })
        );
        setTotalRows(response?.data?.count);
      }
      if (dashboardType === "fuelDashboard1") {
        setRows(
          response?.data?.hemmData?.map((d, i) => {
            d.sid = (page - 1) * perPage + i + 1;
            return d;
          })
        );
        setTotalRows(response?.data?.fuel_hemm_count);
      }
      setLoading(false);
    };

    const getAll = useDebounce(() => {
      getAllData();
    }, [filter]);

    useEffect(() => {
      let text = `page=${page}&limit=${perPage}`;
      if (filterDataBy) text += filterDataBy;
      if (dataFilter) text += `&${dataFilter}`;
      let uniqueTextQuery = getUniqueQueryParams(text);
      setFilter(uniqueTextQuery);
    }, [dataFilter, filterDataBy, page, perPage]);

    useEffect(() => {
      if (!filter) return;
      setLoading(true);
      getAll(filter);
    }, [filter, getAll]);

    useEffect(() => {
      getAll();
    }, [getAll]);
    const handlePerRowsChange = useCallback((newPerPage) => {
      setPerPage(newPerPage);
    }, []);
    const handlePageChange = useCallback((newPage) => {
      setPage(newPage);
    }, []);

    useEffect(() => {
      const columnsData = IS_MOBILE()
        ? columns?.filter((d) => d?.isShowMobile)
        : columns;
      setFinalColumns(columnsData);
    }, [columns]);

    return (
      <div className="scrollTableDashboard">
        <DataTable
          columns={finalColumns}
          data={rows}
          keyField={keyField || "id"}
          expandableRows={IS_MOBILE() ? true : false}
          expandableIcon={{
            collapsed: <FiPlusCircle className="collapsedIcon" size={20} />,
            expanded: (
              <FiMinusCircle className="expandedIcon text-danger" size={20} />
            ),
          }}
          // expandableRowsComponent={(data) =>
          //   TABLE_EXPEND_ROW(data, columnsData)
          // }
          noDataComponent={
            <div style={{ padding: "24px" }}>
              {loading ? "Loading" : "There are no records to display"}
            </div>
          }
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          fixedHeader={fixedHeader}
          pagination={true}
          paginationComponent={CustomPagination}
          paginationServer={true}
          paginationDefaultPage={page}
        />
      </div>
    );
  }
);

export default DataTableComponent;
