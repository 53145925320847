import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import { auth_user } from "../../_helpers/helper";
import { getPermission } from "../Auth/helper";
import { UserService } from "../UserService/UserService";

export const filterUrlFunc = (urlObj = []) => {
  let userPermission = getPermission();
  const authUser = auth_user();
  return urlObj?.filter((obj) => {
    if (!obj?.permission) return true;
    else {
      const isRole = !Array.isArray(obj?.permission)
        ? obj?.permission === authUser?.role?.roleLable
          ? true
          : false
        : obj?.permission?.find((role) => userPermission?.[role]);
      return isRole ? true : false;
    }
  });
};

export const CommmonService = {
  userCheck,
  uploadDocument,
  getSingleSignedUrl,
  uploadDocumentOnS3,
  // deleteDocument,
  // approveReject,
  // reorderTableColumns,
  // debounce,
  // calculateFraction,
  // dateConflicts,
  getAge,
  // updateQueryParam,
  getQueryParam,
  // aadharCheck,
  // eployeeIdCheck,
  // getInformationTableData,
  // getAllSubModule,
  // getAllEvents,
};
async function userCheck(data) {
  try {
    const response = await RestMethod.GET("/common/checkuser/" + data);
    if (
      response.data.data[0].role.roleLable == "1" ||
      response.data.data[0].role.roleLable == "6"
    ) {
      return "0";
    } else {
      return "1";
    }
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function uploadDocument(data) {
  try {
    const response = await RestMethod.POST("/common/document", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function getSingleSignedUrl(data) {
  try {
    const response = await RestMethod.POST("/common/get-s3-signed-url", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function uploadDocumentOnS3({ url, data }) {
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

// async function deleteDocument(id) {
//   try {
//     const response = await RestMethod.DELETE("/common/document/" + id);
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }

// async function approveReject(data) {
//   try {
//     const response = await RestMethod.POST("/common/approve-reject", data);
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }

// async function reorderTableColumns(data) {
//   try {
//     const response = await RestMethod.POST(
//       "/common/reorder-table-columns",
//       data
//     );
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }

// function debounce(func, delay = 500) {
//   let callable = true;

//   return function () {
//     const context = this;
//     const args = arguments;
//     if (callable) {
//       callable = false;
//       func.apply(context, args);
//       setTimeout(() => (callable = true), delay);
//     }
//   };
// }

// ONLY POSITIVE NUMBERS - NEGETIVE NOT ALLOWED!!!
// function calculateFraction(string) {
//   if (!string) return null;
//   if (typeof string === "number") return string;

//   let value = 0;

//   const parts = string.split(" ");
//   if (parts.length > 2) return null;

//   const [number, fractionPart] = parts;

//   if (isNaN(number) || number < 0) return null;
//   value = +number;

//   if (fractionPart) {
//     const [one, two] = fractionPart.split("/");

//     if (!one || !two || isNaN(one) || isNaN(two)) return null;

//     value += +one / +two;
//   }

//   return +value.toFixed(2);
// }

// function dateConflicts(
//   oneFromDate,
//   oneToDate,
//   twoFromDate,
//   twoToDate,
//   rangeInDays = 0
// ) {
//   const range = (rangeInDays * 24 * 60 * 60 * 1000) / 2;

//   const oneFromTime = new Date(oneFromDate).getTime() - range;
//   const oneToTime = new Date(oneToDate).getTime() + range;
//   const twoFromTime = new Date(twoFromDate).getTime() - range;
//   const twoToTime = new Date(twoToDate).getTime() + range;

//   return (
//     (twoFromTime >= oneFromTime && twoFromTime <= oneToTime) ||
//     (twoToTime >= oneFromTime && twoToTime <= oneToTime)
//   );
// }

function getAge(dob) {
  let ageDifMs = Date.now() - new Date(dob).getTime();
  let ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

// function updateQueryParam(key, value) {
//   if (window.history.replaceState) {
//     const searchParams = new URLSearchParams(window.location.search);
//     searchParams.set(key, encodeURIComponent(value));
//     const newurl =
//       window.location.protocol +
//       "//" +
//       window.location.host +
//       window.location.pathname +
//       "?" +
//       searchParams.toString();
//     window.history.replaceState({ path: newurl }, "", newurl);
//   }
// }

function getQueryParam(key) {
  const query = new URLSearchParams(window.location.search);

  return decodeURIComponent(query.get(key));
}

export const isNclHoUser = () => {
  const authUser = auth_user();
  console.log("ncl check", authUser.siteId);
  return [1, 2, 4, 5, 6, 7].includes(authUser.siteId);
};

export const fuelDashboardLink = (source) => {
  const userValue = isNclHoUser();
  if (userValue !== true && userValue !== false) {
    return fuelDashboardLink();
  }
  return (source && source === "sap") || (!source && userValue === true)
    ? "/fuel-management-system/sap-dashboard/1"
    : "/fuel-management-system/dashboard/1";
};
export const fuelDashboardLinkMobile = (source) => {
  const userValue = isNclHoUser();
  if (userValue !== true && userValue !== false) {
    return fuelDashboardLinkMobile();
  }
  return (source && source === "sap") || (!source && userValue === true)
    ? "/mobile/fuel-management-system/sap-dashboard/1"
    : "/mobile/fuel-management-system/dashboard/1";
};

export const checkGivenDate = (date) => {
  let givenDate = new Date(date);
  let today = new Date();
  if (today.toDateString() === givenDate.toDateString()) {
    return true;
  } else return false;
};
// async function aadharCheck(data) {
//   try {
//     const response = await RestMethod.POST("/common/aadhar-check", data);
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }

// async function eployeeIdCheck(data) {
//   try {
//     const response = await RestMethod.POST("/common/employee-id-check", data);
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }
// async function getInformationTableData() {
//   try {
//     const response = await RestMethod.GET("/common/admin-configuration");
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }
// async function getAllSubModule() {
//   try {
//     console.log("This is get All subModule");
//     const response = await RestMethod.GET("/common/all-submodule");
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }
// async function getAllEvents() {
//   try {
//     const response = await RestMethod.GET("/common/get-all-events");

//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }

export async function changeStatus(data) {
  try {
    const response = await RestMethod.PUT("/common/change-status", data);

    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export function filterOptionsOnStatusBasis(data) {
  return data?.filter((d) => d?.status);
}

export function checkType(value, expectedType) {
  try {
    let pattern = /^[1-9.]+$/;
    if (expectedType === "string") {
      if (+value) return false;
      else return true;
    }
    if (expectedType === "float") {
      if (value.includes(".") && pattern.test(value)) return true;
      else return false;
    }
    if (expectedType === "integer") {
      if (value.includes(".")) return false;
      else {
        if (+value) return true;
        else return false;
      }
    }
  } catch (error) {
    return false;
  }
}

export function findType(value) {
  try {
    let pattern = /^[1-9.]+$/;
    if (value.includes(".") && pattern.test(value)) return "float";
    if (!value.includes(".") && +value) return "integer";
    if (!+value) return "string";
  } catch (error) {
    return false;
  }
}
