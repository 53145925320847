import React, { useCallback, useEffect, useState } from "react";
import "../../FuelManagementSystem/fuelmgmt.scss";

import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Modal } from "react-bootstrap";
import EnvDashboardFilter from "./EnvDashboardFilter";
import {
  SidebarData,
  categoryTypeObjByUrl,
  reportTypeListObj,
  reportTypeListTableLabel,
  reportTypeListUrl,
} from "../UploadedFiles/DATA";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { useForm } from "../../../_helpers/hooks";
import { getAllSites } from "../../../_services/SiteServices/SiteServices";
import {
  CHART_TYPE_BY_DATA,
  PAGE_TYPE_BY_CHART_FILTER,
  PAGE_TYPE_BY_CHART_LIST,
  PAGE_TYPE_BY_DASHBOARD,
  PAGE_TYPE_BY_FORM_FILTER,
  envDasFilterLabelObj,
  initialFilterForm,
  landReclamationPageChartObj,
  powerConsumptionPageChartObj,
  screenPageObj,
} from "./DATA";
import {
  envDashboard,
  envSingleDashboard,
  getCategoryByType,
} from "../../../services/_uploadedFiles/uploadedFiles";
import { isArray } from "lodash";
import TabWithScreen from "../../../components/TabWithScreen/TabWithScreen";
import {
  IS_USER_SITE_HO,
  IS_USER_SITE_MINES,
  auth_user,
} from "../../../_helpers/helper";
import { createBrowserHistory } from "history";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import FilesExport from "../../../components/Common/FilesExport";
import { SelectedDateLable } from "../../SafetyManagementSystem/IncidentManagement/Dashboard/DATA";

const EnvironmentManagementDashboard = () => {
  let navigate = useNavigate();
  const history = createBrowserHistory();
  const params = useParams("type");
  const [show, setShow] = useState(false);
  const [activeScreen, setAtiveScreen] = useState();
  const [dashboardData, setDashboardData] = useState({});
  const [chart, setChart] = useState({});
  const [form, onChange, setForm] = useForm(
    PAGE_TYPE_BY_FORM_FILTER(params?.type)
  );

  const [extraChartFilterForm, setExtraChartFilterForm] = useState({
    extrafilterType: "",
  });
  const [chartFilterForm, setChartFilterForm] = useState(
    PAGE_TYPE_BY_CHART_FILTER(params?.type)
  );
  const [actionChartName, setActionChartName] = useState("");
  const [sites, setSites] = useState([]);
  const [finalSiteList, setFinalSiteList] = useState([]);
  const [subsidery, setSubsidery] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [visibleDiv, setVisibleDiv] = useState(1);
  const [minMaxDate, setMinMaxDate] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onChangeChart = ({ target: { name, value } }) => {
    setChartFilterForm((prev) => ({ ...prev, [name]: value }));
    setActionChartName(name);
  };

  const onChangeExtra = ({ target: { name, value } }) => {
    setExtraChartFilterForm((prev) => ({ ...prev, [name]: value }));
    setActionChartName("lineDashboard");
  };

  useEffect(() => {
    setChartFilterForm(PAGE_TYPE_BY_CHART_FILTER(params?.type));
    setForm(PAGE_TYPE_BY_FORM_FILTER(params?.type));
    setActionChartName("");
  }, [params?.type]);

  const dashboardDataFun = useCallback(
    async (filter) => {
      let response = await envDashboard(
        `${reportTypeListObj[params?.type]}`,
        filter
      );
      if (!response?.status) {
        setChart({});
        return;
      }
      const resData = response.data;
      setMinMaxDate(resData?.minMax_date || {});
      if (params?.type === reportTypeListUrl.power_consumption_report) {
        const initialObj = {};
        const powerChartFilterObj = {};
        isArray(resData?.multiBarDashboard) &&
          resData?.multiBarDashboard?.map((obj) => {
            Object.keys(obj)?.forEach((d) => {
              const resultData = Object.keys(obj[d])?.map((key) => {
                const uniqKey = `${key}_${d}_${obj[d][key][0]?.siteId}`;
                if (key === "unit") {
                  powerChartFilterObj[uniqKey] = "hourly";
                }
                return {
                  ...powerConsumptionPageChartObj[key],
                  name: `${powerConsumptionPageChartObj[key]?.name}-${d} (${
                    key === "unit" ? "KWH" : "Power Factor"
                  })`,
                  yAxisName: key === "unit" ? "KWH" : "KVAH",
                  value: uniqKey,
                  [uniqKey]: CHART_TYPE_BY_DATA(
                    powerConsumptionPageChartObj[key]?.type,
                    obj[d][key]
                  ),
                };
              });
              initialObj[d] = resultData;
            });
          });
        setChartFilterForm(powerChartFilterObj);
        setChart(initialObj);
      } else if (params?.type === reportTypeListUrl.Plantation_Project) {
        setChart(resData?.multiBarDashboard || {});
        // setForm((prev) => ({ ...prev, year: resData?.maxYear }));
      } else if (
        params?.type === reportTypeListUrl.Land_Reclamation_Status_CMPD
      ) {
        const initialObj = {};
        isArray(resData?.pieDashboard) &&
          resData?.pieDashboard?.map((obj) => {
            Object.keys(obj)?.forEach((key) => {
              const resultData = {
                ...landReclamationPageChartObj,
                title: `${landReclamationPageChartObj?.title} ( ${key} )`,
                value: key,
                [key]: CHART_TYPE_BY_DATA(
                  landReclamationPageChartObj?.type,
                  obj[key]
                ),
              };
              initialObj[key] = resultData;
            });
          });
        setChart(initialObj);
      } else {
        PAGE_TYPE_BY_CHART_LIST(params?.type)?.forEach((item) => {
          // if (
          //   (params?.type === reportTypeListUrl.Ambient_Air_Quality_CAAQMS||params?.type === reportTypeListUrl.Ambient_Air_Quality_CMPDIL ||params?.type === reportTypeListUrl.Noise_Level_CMPDIL ||params?.type === reportTypeListUrl.Water_Quality_CMPDIL) &&
          //   item?.value === "lineDashboard" &&
          //   resData?.lineDashboard?.length > 0
          // ) {
          //   resData.lineDashboard = resData?.lineDashboard?.map((d) => {
          //     if (d.date) {
          //       d.date = DDMMYYYYFormat(d.date);
          //     }
          //     return d;
          //   });
          // }
          setChart((prev) => ({
            ...prev,
            [item?.value]:
              // this condition for barDashboard to convert multiBarDashboard
              item?.value !== "barDashboard" && item?.chartType !== "multiBar"
                ? CHART_TYPE_BY_DATA(item?.type, resData?.[item?.value])
                : resData?.[item?.value],
          }));
        });
      }
      setDashboardData(resData);
    },
    [params?.type]
  );

  const singleDashboardFun = useCallback(
    async (filter) => {
      if (!actionChartName) return;
      let response = await envSingleDashboard(
        `${reportTypeListObj[params?.type]}`,
        filter
      );
      if (!response?.status) return;
      if (params?.type === reportTypeListUrl.power_consumption_report) {
        let initialObj = {};
        Object.keys(response?.data)?.forEach((d) => {
          const resultData = Object.keys(response?.data[d])?.forEach((key) => {
            const uniqKey = `${key}_${d}_${response?.data[d][key][0]?.siteId}`;

            const ds = {
              ...powerConsumptionPageChartObj[key],
              name: `${powerConsumptionPageChartObj[key]?.name}-${d} (${
                key === "unit" ? "KWH" : "Power Factor"
              })`,
              yAxisName: key === "unit" ? "KWH" : "KVAH",
              value: uniqKey,
              [uniqKey]: CHART_TYPE_BY_DATA(
                powerConsumptionPageChartObj[key]?.type,
                response?.data[d][key]
              ),
            };
            initialObj[uniqKey] = ds;
          });
        });
        const mineKey = actionChartName?.split("_")?.[1];
        const powerObj =
          chart[mineKey]?.find((o) => o?.value !== actionChartName) || {};
        setChart((prev) => ({
          ...prev,
          [mineKey]: [initialObj[actionChartName], powerObj],
        }));
      } else {
        const chartTypeObj =
          PAGE_TYPE_BY_CHART_LIST(params?.type).find(
            (d) => d?.value === actionChartName
          ) || {};
        setChart((prev) => ({
          ...prev,
          [actionChartName]:
            // this condition for barDashboard to convert multiBarDashboard
            actionChartName !== "barDashboard"
              ? CHART_TYPE_BY_DATA(chartTypeObj?.type, response?.data)
              : response?.data,
        }));
      }
    },
    [actionChartName, params?.type]
  );

  useEffect(() => {
    if (params?.type !== reportTypeListUrl.Land_Reclamation_Status_CMPD) return;
    const filterObjLand = {
      siteId: dashboardData?.filterSetData?.site?.id || "",
      subsideryId: dashboardData?.filterSetData?.site?.parentSite?.id || "",
      year: +dashboardData?.filterSetData?.maxYear || "",
    };
    setForm((prev) => ({ ...prev, ...filterObjLand }));
  }, [
    dashboardData?.filterSetData?.maxYear,
    dashboardData?.filterSetData?.site?.id,
    dashboardData?.filterSetData?.site?.parentSite?.id,
    params?.type,
    setForm,
    isReset,
  ]);

  useEffect(() => {
    if (!actionChartName) return;
    let filter = "";

    let extrafilter = "";
    for (const key in extraChartFilterForm) {
      const element = extraChartFilterForm[key];
      if (element) {
        extrafilter += `&${key}=${element}`;
      }
    }
    filter = `graphName=${actionChartName}&graphKey=${chartFilterForm[actionChartName]}`;
    for (const key in form) {
      const element = form[key];
      if (element) {
        filter += `&${key}=${element}`;
      }
    }
    const finalFilter = filter + extrafilter;
    singleDashboardFun(finalFilter);
  }, [
    actionChartName,
    chartFilterForm,
    extraChartFilterForm,
    singleDashboardFun,
  ]);

  useEffect(() => {
    if (params?.type === reportTypeListUrl.Ambient_Air_Quality_CAAQMS)
      setExtraChartFilterForm({ extrafilterType: "useStation" });
    else setExtraChartFilterForm({ extrafilterType: "" });
  }, [params?.type]);

  useEffect(() => {
    dashboardDataFun();
  }, [dashboardDataFun]);
  const getSiteData = () => {
    getAllSites("filter[siteType]=1,2,3&filter[activeStatus]=active")?.then(
      (response) => {
        if (!response?.data) return;
        const siteData = response.data.filter((d) => d?.siteType === 3);
        let subsideryData = [];
        if (IS_USER_SITE_MINES()) {
          subsideryData = [
            {
              id: response.data?.length
                ? response.data?.[0]?.parentSite?.id
                : "",
              name: response.data?.length
                ? response.data?.[0]?.parentSite?.name
                : "",
            },
          ];
        } else {
          subsideryData = response.data.filter((d) => d?.siteType === 2);
        }
        setSites(siteData);
        setFinalSiteList(siteData);
        setSubsidery(subsideryData);
      }
    );
  };

  useEffect(() => {
    getSiteData();
  }, []);

  useEffect(() => {
    if (!categoryTypeObjByUrl[params?.type]) return;
    (async () => {
      const response = await getCategoryByType(
        categoryTypeObjByUrl[params?.type]
      );
      if (!response?.status) return;
      setCategoryList(response?.data || []);
      let residentialCtgry =
        response?.data?.find((d) => d?.category === "Residential")?.category ||
        "";
      let defaultCategory = residentialCtgry
        ? residentialCtgry
        : response?.data?.length
        ? response?.data[0]?.category
        : "";
      setForm({
        ...PAGE_TYPE_BY_FORM_FILTER(params?.type),
        category: defaultCategory,
      });
    })();
  }, [params?.type]);

  useEffect(() => {
    if (IS_USER_SITE_HO()) return;
    const siteObj = sites?.find((d) => d.id === auth_user()?.siteId) || {};
    setForm((prev) => {
      let siteDataObj = {};
      if (prev.hasOwnProperty("siteId")) {
        siteDataObj = {
          siteId: IS_USER_SITE_MINES() ? siteObj?.id : "",
          subsideryId: IS_USER_SITE_MINES()
            ? siteObj?.parentSite?.id
            : auth_user()?.siteId,
        };
      }
      return {
        ...prev,
        ...siteDataObj,
      };
    });
  }, [sites, params.type]);

  useEffect(() => {
    setFinalSiteList(
      form?.subsideryId
        ? sites?.filter((d) => d?.parentSiteId === +form?.subsideryId)
        : sites
    );
  }, [form?.subsideryId, sites]);

  useEffect(() => {
    if (!Object.values(reportTypeListUrl)?.includes(params?.type))
      navigate("/");
  }, [navigate, params?.type]);

  const resetFun = () => {
    setExtraChartFilterForm({ extrafilterType: "useStation" });
    setForm(PAGE_TYPE_BY_FORM_FILTER(params?.type));
    setIsReset(true);
    getSiteData();
  };

  const applyFun = () => {
    setShow(false);
    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        filter += `&${key}=${element}`;
      }
    }
    for (const key in chartFilterForm) {
      const element = chartFilterForm[key];
      if (element) {
        filter += `&${key}=${element}`;
      }
    }

    for (const key in extraChartFilterForm) {
      const element = extraChartFilterForm[key];
      if (element) {
        filter += `&${key}=${form.subsideryId === 3 ? "useStation" : element}`;
      }
    }

    dashboardDataFun(filter);
  };

  useEffect(() => {
    if (!isReset) return;
    dashboardDataFun();
    setIsReset(false);
  }, [dashboardDataFun, isReset]);

  const handleDivClick = (divId) => {
    setVisibleDiv(divId);
  };

  useEffect(() => {
    navigate(
      `/environment-management/dashboard/${screenPageObj[visibleDiv][0]}`
    );
  }, [visibleDiv]);
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <TabWithScreen
            screens={screenPageObj}
            isActive={visibleDiv}
            handleDivClick={handleDivClick}
            tabLabelObj={reportTypeListTableLabel}
            handleShow={handleShow}
            activeTab={params?.type}
          />
          <Container fluid>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="filterModal"
            >
              <Modal.Header closeButton className="px-3">
                <h5 className="modal-title">Filter</h5>
              </Modal.Header>
              <EnvDashboardFilter
                subsidery={subsidery}
                sites={finalSiteList}
                form={form}
                onChange={onChange}
                resetFun={resetFun}
                applyFun={applyFun}
                categoryList={categoryList}
              />
            </Modal>
            <FilterSelectedDetail
              form={form}
              subsidery={subsidery}
              sites={finalSiteList}
              minMaxDate={minMaxDate}
              type={params?.type}
            />
            {PAGE_TYPE_BY_DASHBOARD(
              params?.type,
              chart,
              onChangeChart,
              chartFilterForm,
              onChangeExtra,
              extraChartFilterForm,
              form
            )}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentManagementDashboard;

export const FilterSelectedDetail = ({
  form,
  subsidery,
  sites,
  minMaxDate,
  type,
}) => {
  let subsideryName = form?.subsideryId || "All";
  let mineName = form?.siteId || "All";
  return (
    <div className="selectedSec">
      {Object.keys(form)?.map((key, i) => {
        if (form[key]) {
          if (key === "subsideryId") {
            subsideryName = subsidery?.find((d) => d?.id === form[key])?.name;
          }
          if (key === "siteId") {
            mineName = sites?.find((d) => d?.id === form[key])?.name;
          }
        }
        return (
          key !== "endDate" &&
          key !== "startDate" &&
          (type !== reportTypeListUrl?.Plantation_Project ||
            key !== "year") && (
            <Col key={i} lg={2} className="selectedData">
              <label>{envDasFilterLabelObj[key]}:</label>
              <p>
                {(key === "startDate" || key === "endDate") && form[key]
                  ? DDMMYYYYFormat(form[key])
                  : key === "subsideryId"
                  ? subsideryName
                  : key === "siteId"
                  ? mineName
                  : form[key]}
              </p>
            </Col>
          )
        );
      })}

      {(form.hasOwnProperty("startDate") ||
        (type === reportTypeListUrl?.Plantation_Project &&
          form.hasOwnProperty("year"))) && (
        <Col key={"FilterSelectedDetail" + 1} lg={4} className="selectedData">
          <p>
            <SelectedDateLable
              form={form}
              minMaxDate={minMaxDate}
              type={type}
            />
          </p>
        </Col>
      )}
    </div>
  );
};
