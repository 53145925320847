import React, { useEffect, useState } from "react";
import { initialForm, validationRules, deviceApi, deviceLabel } from "./DATA";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../../../../components/Select/Select";
import { SidebarData } from "../../DATA";
import toastr from "toastr";
import FormInputText from "../../../../../components/FormInputText/FormInputText";
import { getAllSites } from "../../../../../_services/SiteServices/SiteServices";
import SafetySideBar from "../../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import {
  IS_USER_SITE_MINES,
  auth_user,
  idByCheckMine,
} from "../../../../../_helpers/helper";
import { useForm } from "../../../../../_helpers/hooks";
import { Validation } from "../../../../../_helpers/validation/Validation";
import MainBreadcrumb from "../../../../../components/Breadcrumb/MainBreadcrumb";
import { getAllEnvConfigcaaqms } from "../../../../../services/_reasonList/reasonList";
import { updateDevice } from "../../../../../services/_uploadedFiles/uploadedFiles";

const DeviceAdd = () => {
  const params = useParams();
  const [mineList, setMineList] = useState([]);
  const [stationList, setStationList] = useState([]);
  const navigate = useNavigate();
  const [form, onChange, setForm] = useForm(initialForm);
  const [error, setError] = useState({
    ...initialForm,
  });
  const onSubmit = async () => {
    const errorObj = Validation(form, validationRules);

    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }

    let response = await updateDevice(form, params.id);

    if (!response?.status) {
      return toastr.error(response?.message || "something went wrong");
    }
    if (response?.status) toastr.success(`Saved Successfully`);
    navigate("/environment-management/configuration/device");
  };

  async function fetchData() {
    let response = await deviceApi.getById.api(params?.id);
    if (response?.status) {
      setForm(() => {
        return {
          mineName: response?.data?.mineName,
          site_id: response?.data?.site_id,
          deviceName: response?.data?.deviceName,
          station_id: response?.data?.station_id,
          mineAlreadyExists:response?.data?.site_id,
        };
      });
    }

    let stationList = await getAllEnvConfigcaaqms();
    if (stationList.status) {
      let list = stationList.data
        ?.map((d) => {
          if (d.status) {
            return { value: d.id, label: d.name, site_id: d.siteId };
          }
          return null;
        })
        .filter((item) => item !== null);
      setStationList(list);
    }
  }

  const mineLists = async () => {
    let list = [];
    const response = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );

    if (response.status) {
      list = response.data?.map((d) => {
        return { id: d.id, label: d.name };
      });
    }
    setMineList(list);
  };
  useEffect(() => {
    mineLists();
  }, []);

  useEffect(() => {
    if (params.id) fetchData();
  }, [params.id, setForm]);

  // useEffect(() => {
  //   if (!IS_USER_SITE_MINES()) return;
  //   const siteObj =
  //     mineList?.find((d) => +d?.value === +auth_user()?.siteId) || {};
  //   setForm((prev) => ({
  //     ...prev,
  //     site_id: siteObj?.value,
  //   }));
  // }, [mineList]);

  // useEffect(() => {
  //   setForm((prev) => ({
  //     ...prev,
  //     site_id: idByCheckMine(mineList)?.siteId,
  //   }));
  // }, [mineList]);

  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    path: "/",
                    name: "Fuel Management",
                  },
                  {
                    name: "Configuration",
                    path: "/environment-management/configuration",
                  },
                  {
                    path: "#",
                    name: "Add Tank",
                  },
                ]}
              />
            </h3>
            <div className="reportCard">
              <div className="formWrapper">
                <div className="maverickFormCard">
                  <div className="creationWrapper">
                    <Form>
                      <Row>
                        <Col lg={3}>
                          <FormInputText
                            name="mineName"
                            value={form.mineName}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={deviceLabel.mineName}
                            error={error?.mineName}
                            disabled={true}
                          />
                        </Col>
                        <Col lg={3}>
                          <FormInputText
                            name="deviceName"
                            value={form.deviceName}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={deviceLabel.deviceName}
                            error={error?.deviceName}
                            disabled={true}
                          />
                        </Col>
                        <Col lg={3}>
                          <Select
                            onChange={onChange}
                            name="site_id"
                            options={mineList}
                            label={deviceLabel.site_id}
                            value={form?.site_id}
                            error={error?.site_id}
                            // disabled={true}
                            KEY_label={"label"}
                            KEY_value={"id"}
                            disabled={IS_USER_SITE_MINES() && form?.mineAlreadyExists}
                          />
                        </Col>

                        <Col lg={3}>
                          <Select
                            onChange={onChange}
                            name="station_id"
                            options={
                              deviceLabel.site_id
                                ? stationList.filter(
                                    (d) => d.site_id === form.site_id
                                  )
                                : stationList
                            }
                            label={deviceLabel.station_id}
                            value={form?.station_id}
                            error={error?.station_id}
                          />
                        </Col>
                      </Row>
                    </Form>
                    <div className="btnSec">
                      <Button
                        data-testid="next-btn"
                        onClick={() =>
                          navigate(
                            `/environment-management/configuration/device`
                          )
                        }
                        className="secondaryBtn iconBtnForm"
                      >
                        Back
                      </Button>
                      <Button
                        data-testid="next-btn-submit"
                        onClick={() => onSubmit()}
                        className="primaryBtn iconBtnForm"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceAdd;
