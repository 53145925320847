import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import {
  fields,
  initialFilters,
  SidebarData,
  exportMyContractorHindrance,
  myHindranceApis,
} from "./DATA";
import EmptyDataList from "./EmptyDataList";
import HindranceFilterForm from "../Hindrance/HindranceFilterForms";
import { getAllMyHindrance } from "../../../services/_hindrance/hindrance";
import { useForm } from "../../../_helpers/hooks";
import HindranceView from "./HindranceView";
import { useLocation, useNavigate } from "react-router-dom";
import "./hindrance.scss";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import HindranceHistory from "./HindranceHistory";
import {getAllContractorByNit} from"../../../services/_contractorPerformance/contractorPerformance";

const MyHindranceList = () => {
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(initialFilters);
  const [contractorList, setContractorList] = useState([]);


  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const onEdit = (id) => {
    navigate("/contractor-management/contractor-hindrance/update/" + id);
  };

  const onApprove = (id) => {
    navigate(
      "/contractor-management/contractor-hindrance/approve/representative/" + id
    );
  };

  const onResetFilter = () => {
    setForm({});
    setGetAllFilter();
  };
  const getAll = async () => {
    const response = await getAllMyHindrance();
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else {
      setIsListData(false);
    }

    const allContractorList = await getAllContractorByNit();

    if (Array.isArray(allContractorList) && allContractorList.length > 0) {
      setContractorList(
        allContractorList.map((d) => {
          return { value: d.id, label: d.contractor };
        })
      );
    } else {
      setContractorList([]);
    }
  };
  useEffect(() => {
    getAll();
  }, []);

  const viewAsPopup = (form) => {
    return <HindranceView form={form} hideTrail={true} />;
  };
  const viewHistory = (data) => {
    return <HindranceHistory data={data} contractor={true} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          className="secondaryBtn"
          aria-expanded={open}
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <HindranceFilterForm
          contractorList={contractorList}
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      </div>
    );
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        filter += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(filter);
  }, [filterStatus]);

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={fields}
                apis={myHindranceApis}
                title="Data"
                pageLink="/contractor-management/my-contractor-hindrance"
                FilterBtn={FilterBtn}
                primaryKey="id"
                hideSnColumn
                pagination
                permissionAddButton={"contractor"}
                getAllFilter={getAllFilter}
                hideAddButton={true}
                ationStatusCol={true}
                viewAsPopup={viewAsPopup}
                viewHistory={viewHistory}
                downloadExcel={exportMyContractorHindrance}
                listname={"hindrance"}
                modalSize={"xl"}
                editFun={onEdit}
                approveFun={onApprove}
                customTableTitle={"My Contractor Hindrance"}
                viewTitle={"Contractor Hindrance Details"}
                actionBtn
                breadCrumb={[
                  {
                    name: "Contractor Hindrance Management",
                    path: "#",
                  },
                  {
                    name: "Contractor Hindrance",
                    path: "/contractor-management/contractor-hindrance/module",
                  },
                  {
                    name: "My Contractor Hindrance",
                    path: "#",
                  },
                ]}
                isNoTabOnPage
                icons
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <h3
            className="title"
            style={{ paddingBottom: "1px", paddingTop: "5px" }}
          >
            <MainBreadcrumb
              routeSegments={[
                {
                  name: "Contractor Hindrance Management",
                  path: "#",
                },
                {
                  path: "/contractor-management/contractor-hindrance/module",
                  name: "Contractor Hindrance",
                },
                {
                  name: "My Contractor Hindrance",
                  path: "#",
                },
              ]}
            />
          </h3>
          <EmptyDataList
            hideAddBtn={true}
            pageLink={"/contractor-management/my-contractor-hindrance"}
            title={"My Contractor Hindrance"}
          />
        </div>
      </div>
    </div>
  );
};

export default MyHindranceList;
