import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import {
  tabUrl,
  fuelDispenseColumnFields,
  fuelManagementFilterForm,
  SidebarData,
  repotedApprovalHistory,
  mobileTabUrl,
} from "./DATA";
import filter from "../../../Assets/Images/filter.svg";
import { useForm } from "../../../_helpers/hooks";
import {
  IS_MOBILE,
  findUserPermissionedTabList,
} from "../../../_helpers/helper";
import { exportFuelDispense } from "../../../services/_fuelDispense/fuelDispense";
import "./../../FuelManagementSystem/fuelmgmt.scss";
import FuelDispenseFilterForm from "../../FuelManagementSystem/FuelDispenseFilterForm";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import {
  getSafetyManagementReportedIncidentHistoryListWithPagination,
  getSafetyManagementReportedIncidentHistoryExcel,
} from "../../../services/_safetyManagement/safetyManagement";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import SafetyManagementView from "../SafetyManagementView";
import SafetyManagementFilterForm from "../SafetyManagementFilterForm";
import { safetyManagementFilterForm } from "../DATA";
import { getPermission } from "../../../_services/Auth/helper";
const MyApprovalReportedIncidentHistoryList = () => {
  let navigate = useNavigate();
  const [filterStatus, setFilterStatus] = useState(0);
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [form, onChange, setForm] = useForm(safetyManagementFilterForm);
  console.log("44444444", safetyManagementFilterForm);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(
      IS_MOBILE()
        ? mobileTabUrl.safetyManagementApprovalHistory
        : tabUrl.safetyManagementApprovalHistory
    )
  );

  const checkPermission = getPermission();

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} hideLeftRightBtn />;
  };

  useEffect(() => {
    let currentUrl = "";
    setCustomTabs((prev) => {
      return prev.map((data) => {
        if (data.to === usrl.pathname) {
          currentUrl = data.to;
          return {
            ...data,
            active: 1,
          };
        }
        return data;
      });
    });
    currentUrl ? navigate(currentUrl) : navigate(customTabs[0].to);
    getAll();
  }, []);

  const onResetFilter = () => {
    setForm({ fuelManagementFilterForm });
    setGetAllFilter();
  };

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    if (!checkPermission["safety_admin"]) return;
    const response =
      await getSafetyManagementReportedIncidentHistoryListWithPagination(
        "page=1&limit=2"
      );

    // console.log("responsees" , response);
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  console.log("111", filterStatus);
  const viewAsPopup = (form) => {
    return <SafetyManagementView form={form} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          aria-controls="example-collapse-text"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          data-testid="filter-button-Fuel-management"
          className="secondaryBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <SafetyManagementFilterForm
          isOpen={open}
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          setOpen={setOpen}
        />
      </div>
    );
  };

  useEffect(() => {
    console.log("hello");
    if (filterStatus === 0) return;
    console.log("form", form);
    let filter = "";
    for (const key in form) {
      const element = form[key];
      console.log("element", element);
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else if (key === "is_investigated" || key === "is_capa")
          filter += `&search[${key}]=${1}`;
        else filter += `&search[${key}]=${element}`;
      }
    }

    setGetAllFilter(filter);
  }, [filterStatus]);

  return (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        pageTitle={"My Approval History"}
        goBack={() =>
          navigate("/mobile/safety-management-system/incident-management")
        }
        SidebarData={SidebarData}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={fuelDispenseColumnFields}
                apis={repotedApprovalHistory}
                title="Data"
                viewTitle={"Incident Details"}
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/incident-management"}
                primaryKey="id"
                pagination
                breadCrumb={[
                  { name: "Safety Management", path: "#" },
                  {
                    name: "Incident Management",
                    path: "/safety-management-system/incident-management",
                  },
                  {
                    name: "My Approval History",
                    path: "#",
                  },
                ]}
                getAllFilter={getAllFilter}
                hideAddButton
                downloadExcel={getSafetyManagementReportedIncidentHistoryExcel}
                childrenAfterAddButton={tabButtons}
                modalSize={"xl"}
                customTableTitle={"Blank"}
                icons
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default MyApprovalReportedIncidentHistoryList;
