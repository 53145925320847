import React, { useState, useEffect, useMemo } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import {
  SidebarData,
  reasonListApisStationCategoryNoise,
  reasonListColumnFields,
} from "./DATA";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import { tabUrl } from "../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import { permissionCheck } from "../../../_services/Auth/helper";
import EmptyDataList from "../../ContractorManagement/ContractorPerformance/EmptyDataList";
import { getAllMasterDropdownStationCategoryNoise } from "../../../services/_reasonList/reasonList";
import { auth_user } from "../../../../src/_helpers/helper";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";

const MasterNoiseList = () => {
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.envConfig)
  );
  const [isSuperAdmin, setIsSuperAdmin] = useState(true);
  const authUser = auth_user();
  const super_admin = useMemo(() => {
    setIsSuperAdmin(authUser?.role?.roleLable);
  }, [authUser?.role?.roleLable]);

  let navigate = useNavigate();
  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    getAll();
  }, []);

  const getAll = async () => {
    const response = await getAllMasterDropdownStationCategoryNoise();
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };
  const onEdit = (id) => {
    navigate("/configuration/environment/noise/" + id);
  };

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} />;
  };

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            {/* <h4>Contractor Performance</h4> */}
            {/* <CustomTabs tabeList={customTabs} /> */}
            <div className="customDataTbl">
              <DataTableWithServer
                fields={reasonListColumnFields}
                pageLink={"/configuration/environment/noise"}
                apis={reasonListApisStationCategoryNoise}
                title="Data"
                // viewAsPopup={viewAsPopup}
                hideSnColumn
                pagination
                hideDeleteButton
                hideEditButton
                // downloadExcel={exportContractorPerformance}
                //   hideAddButton={!permissionCheck(["contractor_module_admin"])}
                primaryKey="id"
                listname={"stationcategory"}
                customTableTitle={"Noise Station Category"}
                //   viewTitle={"Contractor Performance Details"}
                hideSearch={true}
                //   deleteFun
                breadCrumb={[
                  {
                    name: "Environment Management System",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/environment-management/configuration",
                  },
                  {
                    name: "Noise Station Category",
                    path: "#",
                  },
                ]}
                // hideThreeDotButton={isSuperAdmin == 1 ? false : true}
                isNoTabOnPage
                // childrenAfterAddButton={tabButtons}
                editFunButton={onEdit}
                // hideAddButton={!permissionCheck(["super_admin"])}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            title={"Noise Station Category"}
            // tabButtons={tabButtons}
            pageLink={"/configuration/environment/noise"}
            breadCrumb={[
              {
                name: "Environment Management System",
                path: "#",
              },
              {
                name: "Configuration",
                path: "/environment-management/configuration",
              },
              {
                path: "#",
                name: "Noise Station Category",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default MasterNoiseList;
