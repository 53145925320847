import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { KL_TO_L, L_TO_KL, isDecimal } from "../../../_helpers/helper";
import { saveAsExcelXlsx } from "../../../components/export/exportExcel";

export const inventoryWidgetData = (inventoryData, tabValIs, form) => {
  // const endDate = form?.endDate ? DDMMYYYYFormat(form?.endDate) : "";
  console.log("inventoryDatainventoryDatainventoryData", inventoryData);
  const endDate = inventoryData?.openingStock?.createdAt
    ? DDMMYYYYFormat(inventoryData?.openingStock?.createdAt)
    : "";
  let Arr = [
    // {
    //   label: `Opening Stock ${endDate ? "on " + endDate : ""}`,
    //   quantity: !inventoryData?.openingStock?.opening_stock
    //     ? "0 " + tabValIs
    //     : isDecimal(inventoryData?.openingStock?.opening_stock) +
    //       " " +
    //       tabValIs,
    // },
    // {
    //   label: `Closing Stock ${endDate ? "on " + endDate : ""}`,
    //   quantity: !inventoryData?.closingStock?.closing_stock
    //     ? "0 " + tabValIs
    //     : isDecimal(inventoryData?.closingStock?.closing_stock) +
    //       " " +
    //       tabValIs,
    // },
    // {
    //   label: `Dip Data ${endDate ? "on " + endDate : ""}`,
    //   quantity: !inventoryData?.stockDip?.dip
    //     ? "0 " + tabValIs
    //     : isDecimal(inventoryData?.stockDip?.dip) + " " + tabValIs,
    // },
    {
      label: "Total Available",
      quantity: !inventoryData?.getAllFuel
        ? "0 " + tabValIs
        : isDecimal(inventoryData?.getAllFuel) + " " + tabValIs,
    },
    {
      label: "Total Receipt",
      quantity: !inventoryData?.dailyFuelReceipt
        ? "0 " + tabValIs
        : isDecimal(inventoryData?.dailyFuelReceipt) + " " + tabValIs,
    },
    {
      label: "Total Dispense",
      quantity: !inventoryData?.dailyFuelDispense
        ? "0 " + tabValIs
        : isDecimal(inventoryData?.dailyFuelDispense) + " " + tabValIs,
    },
  ];
  return Arr;
};

export const widgetData = (fuelData, tabValIs) => {
  let Arr = [
    {
      label: "Total Fuel Consumed",
      quantity: !fuelData?.totalFuelConsumed
        ? "0 " + tabValIs
        : isDecimal(fuelData?.totalFuelConsumed) + " " + tabValIs,
    },
    {
      label: "Avg. Time b/w refuels",
      quantity: !fuelData?.avgTimeToRefuel
        ? "0 Hrs"
        : isDecimal(fuelData?.avgTimeToRefuel) + " Hrs",
    },
    {
      label: "SFC",
      quantity: !fuelData?.specificFuelConsumed
        ? "0 " + tabValIs + "/Hr"
        : isDecimal(fuelData?.specificFuelConsumed) + " " + tabValIs + "/Hr",
    },
    // {
    //   label: "Fuel Efficiency",
    //   quantity: !fuelData?.fuelEfficiency
    //     ? "0" + tabValIs + "/MCUM"
    //     : isDecimal(fuelData?.fuelEfficiency) + tabValIs + "/MCUM",
    // },

    // {
    //   label: "Avg. Distance b/w refuels",
    //   quantity: !fuelData?.avgDistanceToRefuel
    //     ? "0 KM"
    //     : isDecimal(fuelData?.avgDistanceToRefuel) + " KM",
    // },
  ];
  return Arr;
};

export const widgetDataSingle = (fuelData, tabValIs) => {
  let Arr = [
    {
      label: "Fuel Consumed",
      quantity: !fuelData?.totalFuelConsumedByType
        ? "0 " + tabValIs
        : isDecimal(fuelData?.totalFuelConsumedByType) + " " + tabValIs,
    },
    {
      label: "Avg. Time b/w refuels",
      quantity: !fuelData?.avgTimeToRefuelByType
        ? "0 Hrs"
        : isDecimal(fuelData?.avgTimeToRefuelByType) + " Hrs",
    },
    {
      label: "SFC",
      quantity: !fuelData?.specificFuelConsumedByType
        ? "0 " + tabValIs + "/Hr"
        : isDecimal(fuelData?.specificFuelConsumedByType) +
          " " +
          tabValIs +
          "/Hr",
    },
  ];
  return Arr;
};

export const widgetDataSap = (fuelData, tabValIs) => {
  let Arr = [
    {
      label: "Total Fuel Consumed",
      quantity: !fuelData?.totalFuelConsumed
        ? "0 " + tabValIs
        : isDecimal(fuelData?.totalFuelConsumed) + " " + tabValIs,
    },
    {
      label: "Total Running Hours",
      quantity: !fuelData?.avgTimeToRefuel
        ? "0 Hrs"
        : isDecimal(fuelData?.avgTimeToRefuel) + " Hrs",
    },
    {
      label: "SFC (Per CuM)",
      quantity: !fuelData?.specificFuelConsumed
        ? "0 " + tabValIs + "/Hr"
        : isDecimal(fuelData?.specificFuelConsumed) + " " + tabValIs + "/Hr",
    },
    {
      label: "SFC (Per Hour)",
      quantity: !fuelData?.specificFuelProduction
        ? "0 " + tabValIs + "/Hr"
        : isDecimal(fuelData?.specificFuelProduction) + " " + tabValIs + "/Hr",
    },
  ];
  return Arr;
};

export const widgetDataSapByType = (fuelData, tabValIs) => {
  let Arr = [
    {
      label: "Fuel Consumed",
      quantity: !fuelData?.totalFuelConsumedByType
        ? "0 " + tabValIs
        : isDecimal(fuelData?.totalFuelConsumedByType) + " " + tabValIs,
    },
    {
      label: "Running Hours",
      quantity: !fuelData?.avgTimeToRefuelByType
        ? "0 Hrs"
        : isDecimal(fuelData?.avgTimeToRefuelByType) + " Hrs",
    },
    {
      label: "SFC (Per CuM)",
      quantity: !fuelData?.specificFuelConsumedByType
        ? "0"
        : isDecimal(fuelData?.specificFuelConsumedByType),
    },
    {
      label: "SFC (Per Hour)",
      quantity: !fuelData?.specificFuelProductionByType
        ? "0"
        : isDecimal(fuelData?.specificFuelProductionByType),
    },
  ];
  return Arr;
};

export const FilterTab = [
  { id: 1, filterBy: "Make", value: "make" },
  { id: 3, filterBy: "Capacity", value: "capacity" },
  { id: 4, filterBy: "Model", value: "model" },
  { id: 5, filterBy: "Serial No.", value: "eqpt_id" },
];
export const FilterTabFuel = [
  { id: 1, filterBy: "Make", value: "make" },
  { id: 3, filterBy: "Capacity", value: "capacity" },
  { id: 4, filterBy: "Model", value: "model" },
  { id: 5, filterBy: "Serial No.", value: "eqpt_id" },
];

// export const ChartFilter = [
//   { id: 6, filterBy: "S", value: "S" },
//   { id: 7, filterBy: "D", value: "D" },
//   { id: 8, filterBy: "W", value: "W" },
//   { id: 9, filterBy: "M", value: "M" },
//   { id: 10, filterBy: "Q", value: "Q" },
//   { id: 11, filterBy: "Y", value: "Y" }
// ]
export const ChartFilter = [
  { id: 11, label: "Daily", value: "daily" },
  { id: 12, label: "Weekly", value: "weekly" },
  { id: 13, label: "Monthly", value: "monthly" },
  { id: 14, label: "Quarterly", value: "quarterly" },
  { id: 15, label: "Yearly", value: "yearly" },
];
export const kpiList = [
  { label: "Fuel Consumption", value: "powerConsumption" },
];

export const screenPage = [
  {
    link: `/fuel-management-system/dashboard/1`,
    screen: 1,
  },
  {
    link: `/fuel-management-system/dashboard/2`,
    screen: 2,
  },
];

export const initialFilterForm = {
  siteId: "",
  subsideryId: "",
  eqptType: "",
  startDate: "",
  endDate: "",
  make: "",
  capacity: "",
  modelValue: "",
  eqptId: "",
  inventoryTypeBowser: null,
};

export const inventoryFilterForm = {
  siteId: "",
  subsideryId: "",
  eqptType: "",
  startDate: "",
  endDate: "",
  fuelLocationId: "",
  tankId: "",
  inventoryTypeBowser: null,
};

export const headerChartFilter = {
  kpi: "powerConsumption",
  activeTab: "make",
  filterId: null,
};

export const eqptTypeTabList = (tabs = [], allFlag = false) => {
  let allObj = allFlag && tabs?.length ? [{ value: "all", label: "All" }] : [];
  const filterTabs = tabs?.map((d, i) => ({
    ...d,
  }));
  return [...allObj, ...filterTabs];
};
export const HEMMDetails = [
  {
    name: "Eqpt. ID",
    selector: "hemm_id",
    isShowMobile: true,
  },
  {
    name: "Type_",
    selector: "type",
  },
  {
    name: "Make",
    selector: "make",
  },
  {
    name: "Capacity",
    selector: "capecity",
  },
  {
    name: "Model",
    selector: "model",
  },
  {
    name: "Fueling Date",
    selector: "fuling_date",
    cell: (row) => (row?.fuling_date ? DDMMYYYYFormat(row?.fuling_date) : ""),
  },
  {
    name: "Last Fueling Date",
    selector: "last_fuling_date",
    cell: (row) =>
      row?.last_fuling_date ? DDMMYYYYFormat(row?.last_fuling_date) : "",
  },
  {
    name: "Fueling Qty",
    selector: "fuling_qty",
  },
  {
    name: "Last Fueling Qty ({tabValIs})",
    selector: "last_fuling_qty",
  },
  {
    name: "Incr. WHR",
    selector: "whr",
  },
  {
    name: "Incr.Kms.",
    selector: "kms",
  },
  {
    name: "SFC (Ltr./Hr.)",
    selector: "sfc",
  },
  {
    name: "TBF (Hrs.)",
    selector: "tbf",
  },
  {
    name: "DBF (Kms)",
    selector: "dbf",
  },
];

export const fuelTableTypeObj = {
  hemmData: "hemmData",
};

export async function getFuelTabFirstDashboardTbl(filter) {
  try {
    const response = await RestMethod.GET(
      "/fuel-dispence/dashboard/table-data?" + (filter || "")
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function getFuelTabFirstDashboardTblExcel(filter) {
  try {
    const response = await RestMethod.GET(
      "/fuel-dispence/dashboard/table-data?" + (filter || "")
    );
    await saveAsExcelXlsx(
      response?.data?.data?.hemmData,
      response?.column,
      "fuelDashboardExcel.xlsx"
    );
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export const fuelReceiptDetails = [
  {
    name: "Site (+)",
    selector: "Site",
    isShowMobile: true,
  },
  {
    name: "Supplier",
    selector: "Supplier",
  },
  {
    name: "Receipt Date (-)",
    selector: "Receipt_date",
    cell: (row) => (row?.Receipt_date ? DDMMYYYYFormat(row?.Receipt_date) : ""),
  },
  {
    name: "Receipt Qty",
    selector: "Receipt_Qty",
  },
];

export const fuelDispenseDetails = [
  {
    name: "Site (+)",
    selector: "Site",
    isShowMobile: true,
  },
  {
    name: "Eqpt. ID (-)",
    selector: "EqptId",
  },
  {
    name: "Type",
    selector: "Type",
  },
  {
    name: "Make",
    selector: "Make",
  },
  {
    name: "Capacity",
    selector: "Capacity",
  },
  {
    name: "Model",
    selector: "Model",
  },
  {
    name: "Capacity",
    selector: "Capacity",
  },
  {
    name: "Fueling Date (-)",
    selector: "Fueling_Date",
    cell: (row) => (row?.Fueling_Date ? DDMMYYYYFormat(row?.Fueling_Date) : ""),
  },
  {
    name: "Fueling Qty",
    selector: "Fueling_Qty",
  },
];

export const fuelTypeObj = {
  receiptData: "receiptData",
  fuelData: "fuelData",
  dailyFuelUpdate: "dailyFuelUpdate",
};

export async function getFuelReceiptDashboardTbl(filter) {
  try {
    const response = await RestMethod.GET(
      "/fuel-dispence/dashboard-two/table-data?" + (filter || "")
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function getFuelReceiptSapDashboardTbl(filter) {
  try {
    const response = await RestMethod.GET(
      "/fuel-dispence/sap-dashboard-two/table-data?" + (filter || "")
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export const CONVERT_KL_L_FILTER = (data, tabValIs) => {
  const filterData = {};
  if (tabValIs === "KL") {
    filterData["specificFuelConsumed"] = L_TO_KL(data?.specificFuelConsumed);
    filterData["totalFuelConsumedByType"] = L_TO_KL(
      data?.totalFuelConsumedByType
    );
    filterData["specificFuelConsumedByType"] = L_TO_KL(
      data?.specificFuelConsumedByType
    );
    filterData["totalFuelConsumed"] = L_TO_KL(data?.totalFuelConsumed);
    filterData["fuelEfficiency"] = L_TO_KL(data?.fuelEfficiency);
    filterData["specificFuelProduction"] = L_TO_KL(
      data?.specificFuelProduction
    );
    filterData["consumptionDetails"] = data?.consumptionDetails?.map((ele) => {
      Object.assign(
        ele,
        ele?.last_fuling_qty && {
          last_fuling_qty: L_TO_KL(ele?.last_fuling_qty),
        },
        { fuling_qty: L_TO_KL(ele?.fuling_qty) },
        { sfcProduction: L_TO_KL(ele?.sfcProduction) },

        { sfc: L_TO_KL(ele?.sfc) }
      );
      return ele;
    });

    filterData["consumptionCapacity"] = data?.consumptionCapacity?.map(
      (ele) => {
        return {
          ...ele,
          value: L_TO_KL(ele?.value),
        };
      }
    );

    const capacityAcrossDaysData = data?.capacityAcrossDays?.data?.map(
      (ele) => {
        return {
          ...ele,
          data: ele?.data?.map((o) => isDecimal(L_TO_KL(o))),
          name: ele?.name ? `${ele?.name?.split(" - ")[0]}` : "",
        };
      }
    );
    filterData["capacityAcrossDays"] = {
      ...data?.capacityAcrossDays,
      data: capacityAcrossDaysData,
    };
  } else {
    filterData["specificFuelConsumed"] = KL_TO_L(data?.specificFuelConsumed);
    filterData["totalFuelConsumed"] = KL_TO_L(data?.totalFuelConsumed);
    filterData["fuelEfficiency"] = KL_TO_L(data?.fuelEfficiency);

    filterData["consumptionDetails"] = data?.consumptionDetails?.map((ele) => {
      Object.assign(
        ele,
        ele?.last_fuling_qty && {
          last_fuling_qty: KL_TO_L(ele?.last_fuling_qty),
        },
        { fuling_qty: KL_TO_L(ele?.fuling_qty) },
        { sfcProduction: KL_TO_L(ele?.sfcProduction) },
        { sfc: KL_TO_L(ele?.sfc) }
      );
      return ele;
    });

    filterData["consumptionCapacity"] = data?.consumptionCapacity?.map(
      (ele) => {
        return {
          ...ele,
          value: KL_TO_L(ele?.value),
        };
      }
    );

    const capacityAcrossDaysData = data?.capacityAcrossDays?.data?.map(
      (ele) => {
        return {
          ...ele,
          data: ele?.data?.map((o) => KL_TO_L(o)),
          name: ele?.name ? `${ele?.name?.split(" - ")[0]}` : "",
        };
      }
    );
    filterData["capacityAcrossDays"] = {
      ...data?.capacityAcrossDays,
      data: capacityAcrossDaysData,
    };
  }
  return filterData;
};

export const CONVERT_KL_L_INVENTORY = (data, tabValIs) => {
  const filterData = {};
  if (tabValIs === "KL") {
    filterData["getAllFuel"] = L_TO_KL(data?.getAllFuel);
    filterData["stockDip"] = L_TO_KL(data?.stockDip);
    filterData["openingStock"] = L_TO_KL(data?.openingStock);
    filterData["totalDispense"] = L_TO_KL(data?.totalDispense);
    filterData["totalReceipt"] = L_TO_KL(data?.totalReceipt);
    filterData["closingStock"] = L_TO_KL(data?.closingStock);
    filterData["dailyFuelReceipt"] = L_TO_KL(data?.dailyFuelReceipt);
    filterData["dailyFuelDispense"] = L_TO_KL(data?.dailyFuelDispense);
    filterData["fuelDispnecDetails"] = data?.fuelDispnecDetails?.map((ele) => {
      Object.assign(ele, { Fueling_Qty: L_TO_KL(ele?.Fueling_Qty) });
      return ele;
    });
    filterData["fuelReceiptDetails"] = data?.fuelReceiptDetails?.map((ele) => {
      Object.assign(ele, { Receipt_Qty: L_TO_KL(ele?.Receipt_Qty) });
      return ele;
    });

    filterData["dailyFuelReceiptDetails"] = data?.dailyFuelReceiptDetails?.map(
      (ele) => {
        Object.assign(
          ele,
          { closingStock: L_TO_KL(ele?.closingStock) },
          { dispense: L_TO_KL(ele?.dispense) },
          { openingStock: L_TO_KL(ele?.openingStock) },
          { receipt: L_TO_KL(ele?.receipt) },
          { openingDip: L_TO_KL(ele?.openingDip) },
          { closingDip: L_TO_KL(ele?.closingDip) }
        );
        return ele;
      }
    );

    const fuelInventoryTrendData = data?.fuelInventoryTrend?.data?.map(
      (ele) => {
        return {
          ...ele,
          data: ele?.data?.map((o) => ({ ...o, value: L_TO_KL(o?.value) })),
        };
      }
    );
    filterData["fuelInventoryTrend"] = {
      ...data?.fuelInventoryTrend,
      data: fuelInventoryTrendData,
    };
  } else {
    filterData["getAllFuel"] = KL_TO_L(data?.getAllFuel);
    filterData["stockDip"] = KL_TO_L(data?.stockDip);
    filterData["openingStock"] = KL_TO_L(data?.openingStock);
    filterData["totalDispense"] = KL_TO_L(data?.totalDispense);
    filterData["totalReceipt"] = KL_TO_L(data?.totalReceipt);
    filterData["closingStock"] = KL_TO_L(data?.closingStock);
    filterData["dailyFuelReceipt"] = KL_TO_L(data?.dailyFuelReceipt);
    filterData["dailyFuelDispense"] = KL_TO_L(data?.dailyFuelDispense);
    filterData["fuelDispnecDetails"] = data?.fuelDispnecDetails?.map((ele) => {
      Object.assign(ele, { Fueling_Qty: KL_TO_L(ele?.Fueling_Qty) });
      return ele;
    });
    filterData["fuelReceiptDetails"] = data?.fuelReceiptDetails?.map((ele) => {
      Object.assign(ele, { Receipt_Qty: KL_TO_L(ele?.Receipt_Qty) });
      return ele;
    });
    const fuelInventoryTrendData = data?.fuelInventoryTrend?.data?.map(
      (ele) => {
        return {
          ...ele,
          data: ele?.data?.map((o) => ({ ...o, value: KL_TO_L(o?.value) })),
        };
      }
    );
    filterData["dailyFuelReceiptDetails"] = data?.dailyFuelReceiptDetails?.map(
      (ele) => {
        Object.assign(
          ele,
          { closingStock: KL_TO_L(ele?.closingStock) },
          { dispense: KL_TO_L(ele?.dispense) },
          { openingStock: KL_TO_L(ele?.openingStock) },
          { receipt: KL_TO_L(ele?.receipt) },
          { openingDip: KL_TO_L(ele?.openingDip) },
          { closingDip: KL_TO_L(ele?.closingDip) }
        );
        return ele;
      }
    );
    filterData["fuelInventoryTrend"] = {
      ...data?.fuelInventoryTrend,
      data: fuelInventoryTrendData,
    };
  }
  return filterData;
};
