import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

export const createIncidentReport = async (data) => {
  try {
    const response = await RestMethod.POST("/incident", data);
    return response.data;
  } catch (error) {
    console.error("Error detected while fetching data from api");
    return null;
  }
};

export const updateIncidentReport = async (data, id) => {
  try {
    const response = await RestMethod.PUT("/incident/" + id, data);
    return response.data;
  } catch (error) {
    console.error("Error detected while fetching data from api");
    return null;
  }
};

export const getIncidentReportById = async (id) => {
  try {
    const response = await RestMethod.GET("/incident/" + id);
    return response.data;
  } catch (error) {
    console.error("Error detected while fetching data from api");
    return null;
  }
};
// Incident Config

export const createIncidentKpi = async (data) => {
  let url = "/incidentKpi/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateIncidentKpi = async (data, id) => {
  let url = "/incidentKpi/update/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getAllIncidentKpi = async (filter, lastSegment) => {
  try {
    let url = "/incidentKpi";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllIncidentKpiById = async (id) => {
  let url = "/incidentKpi/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const deleteIncidentKpi = async (id) => {
  let url = "/incidentKpi/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllMasterDropdownViolationsLeading = async () => {
  let url = "/master-data/all/type/violations_leading";
  try {
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      data: data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const addMine = async (data) => {
  let url = "/mine/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateMine = async (data, id) => {
  let url = "/mine/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getAllMine = async (filter) => {
  let url = "/mine";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    console.log("datadatadatadata", data?.site);
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.site || [],
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getMineById = async (id, filter) => {
  let url = "/mine/" + id;
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
