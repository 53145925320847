import React, { useState } from "react";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
// import { NotificationContainer } from "react-notifications";
import toastr from "toastr";
import { CommmonService } from "../../../../_services/CommonService/CommonService";
import { uploadFileOnS3 } from "../../../../services/S3Service";

const IncidentEvidence = ({
  extensions,
  description,
  onChangeFile,
  onChangeDescription,
  index,
  source,
}) => {
  const [loading, setLoading] = useState(false);

  const onChangeHandler = async ({ target }) => {
    const file = target.files[0];
    target.value = null;

    if (!description)
      return toastr.warning("Please enter a description first!");

    if (!file) return;

    const extension = file.name.split(".").pop().toLowerCase();

    if (!extensions.includes(extension))
      return toastr.warning(
        `Only ${extensions
          .map((ext) => ext.toUpperCase())
          .join(", ")} file(s) allowed!`
      );

    setLoading(true);
    // const formData = new FormData();

    // formData.append("alias", description);
    // formData.append("file", file);

    const dataForDoc = await uploadFileOnS3(file);
    // formData.append("s3Url", s3Url);

    const response = await CommmonService.uploadDocument({
      ...dataForDoc,
      alias: description,
      source: source || "Incident",
    });
    setLoading(false);
    if (!response?.status) return toastr.error(response?.message);

    onChangeFile({ file }, response.data);
  };

  return (
    <div className={"form-group mb-1"}>
      {loading && (
        <div className="siteLoader">
          <div className="spinner-bubble spinner-bubble-info m-5" />
        </div>
      )}
      <div className="alias_docUpload">
        <input
          type="text"
          class="form-control"
          placeholder="Description"
          value={description}
          onChange={({ target: { value } }) => onChangeDescription(value)}
        />
        <div className="uploadFileBtn">
          <label htmlFor={`upload${index}`} className="uploadLabel">
            <BsFileEarmarkArrowUp />
          </label>
          <input
            id={`upload${index}`}
            className="uploadFile"
            type="file"
            onChange={onChangeHandler}
          />
        </div>
      </div>
      {/* <NotificationContainer /> */}
    </div>
  );
};

export default IncidentEvidence;
