import React, { useEffect, useState, useCallback } from "react";
import { getAllSites } from "../../../services/_uploadedFiles/uploadedFiles";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "../../../components/Select/Select";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { isArray } from "lodash";
import { SidebarData, formInitialData } from "../FuelManagement/DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { AiOutlineFileExcel } from "react-icons/ai";
import moment from "moment";
import {
  downloadExcelFile,
  exportExcellReports,
} from "../../SafetyManagementSystem/IncidentManagement/UserManagement/DATA";
import { getAllWorkshopMasters } from "../../../services/_reasonList/reasonList";
import { workshopObjForm } from "../DATA";

const FuelReports = () => {
  const [form, setForm] = useState(formInitialData);
  const [sites, setSites] = useState("");
  const [allsiteListDrop, setAllSiteListDrop] = useState({});
  const [allMineListDrop, setAllMineListDrop] = useState({});
  const [workshopData, setWorkshopData] = useState([...workshopObjForm]);

  const getSiteList = async () => {
    let allsiteList = await getAllSites();
    if (allsiteList?.status === 1 && allsiteList?.message === "success") {
      setSites(allsiteList?.data);
      let dataObjSite = [];
      allsiteList?.data?.forEach((data) => {
        if (data.parentSite.id) {
          const labelExists = dataObjSite.some(
            (obj) => obj.label === data.parentSite.name
          );
          if (!labelExists) {
            dataObjSite.push({
              value: data.parentSite.id,
              label: data.parentSite.name,
            });
          }
        }
      });
      setAllSiteListDrop(dataObjSite);
    }
  };

  const onChange = (e, key) => {
    const { name, value } = e.target;
    const obj = { [name]: value };

    if (name === "subsidiary") obj.mine = "";

    if (key) {
      if (
        name === "startDate" &&
        key !== "consolidated_daily_report" &&
        key !== "hsd_consumption_report"
      ) {
        let result = moment(value).add(15, "days");

        // Compare the result with today's date
        if (result.isAfter(moment(), "day")) {
          result = moment().subtract(1, "day"); // If result is greater than or equal to today's date, take yesterday's date
        }

        // Format the result
        result = result.format("YYYY-MM-DD");
        value
          ? Object.assign(obj, { endDate: result })
          : Object.assign(obj, { endDate: "" });
      }
      setForm((prevForm) => {
        return {
          ...prevForm,
          [key]: {
            ...prevForm[key], // Preserve previous values
            ...obj, // Update with new values
          },
        };
      });
    } else setForm({ ...form, ...obj });
  };

  useEffect(() => {
    let dataObjMine = [];
    if (sites.length > 0) {
      sites?.forEach((data) => {
        if (data.siteType === 3) {
          dataObjMine.push({
            value: data?.id,
            label: data?.name,
          });
        }
      });
    }
    setAllMineListDrop(dataObjMine);
  }, [allsiteListDrop]);

  useEffect(() => {
    getSiteList();
    getAllWorkshop();
  }, []);

  const getAllWorkshop = async () => {
    let response = await getAllWorkshopMasters();
    setWorkshopData((prev) => [
      ...prev,
      ...response.data
        .filter((d) => d?.status)
        .map((d) => ({ label: d.name, value: d.id })),
    ]);
  };

  useEffect(() => {
    if (allMineListDrop?.length === 1) {
      setForm((prevForm) => ({ ...prevForm, mine: allMineListDrop[0].value }));
    }
  }, [allMineListDrop]);

  useEffect(() => {
    if (allsiteListDrop.length === 1) {
      setForm((prevForm) => ({
        ...prevForm,
        subsidiary: allsiteListDrop[0].value,
      }));
    }
  }, [allsiteListDrop]);

  const handleDownload = async (e, reportType) => {
    const data = {
      subsidiary: form?.subsidiary,
      mine: form?.mine,
      type: reportType,
      workshop: form?.workshop,
      ...form[reportType],
    };
    await exportExcellReports(data);
  };

  useEffect(() => {
    if (form?.subsidiary && isArray(sites) && sites.length > 0) {
      let dataObjMine = [];
      let mines = sites?.filter((obj) => obj.parentSiteId === form?.subsidiary);
      if (mines.length > 0) {
        mines?.forEach((data) => {
          dataObjMine.push({
            value: data.id,
            label: data.name,
          });
        });
      }
      setAllMineListDrop(dataObjMine);
    } else {
      setAllMineListDrop([]);
      form.mine = "";
    }
  }, [form?.subsidiary]);
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="dashboardWrapper innerDashboards">
              <Row id="VendorMonthDashboard">
                <div className="dashboardHeader breadcrumbWithTitle">
                  <MainBreadcrumb
                    routeSegments={[
                      {
                        path: "#",
                        name: "Fuel Management System",
                      },
                      {
                        name: "Fuel Management",
                        path: "/fuel-management-system",
                      },
                      {
                        name: "Reports",
                        path: "#",
                      },
                    ]}
                  />
                  <h3 class="title mb-0">Reports</h3>
                </div>
                <div style={{ marginBottom: "30px" }}>
                  <Form>
                    <div className="topFilterSec">
                      <div className="">
                        <Row>
                          <Col md={3}>
                            <Select
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                }));
                              }}
                              value={form?.subsidiary}
                              name="subsidiary"
                              options={allsiteListDrop}
                              label={"Subsidiary"}
                              disabled={
                                allsiteListDrop.length === 1 ? true : false
                              }
                            />
                          </Col>

                          <Col md={3}>
                            <Select
                              onChange={onChange}
                              value={form?.mine}
                              name="mine"
                              options={allMineListDrop}
                              label={"Mine"}
                              disabled={
                                allMineListDrop.length === 1 ? true : false
                              }
                            />
                          </Col>

                          <Col md={3}>
                            <Select
                              onChange={onChange}
                              value={form?.workshop}
                              name="workshop"
                              options={workshopData}
                              label={"Workshop"}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </div>

                <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <Form>
                    <div className="topFilterSec">
                      <div
                        className="colindiaCard borderRadius5"
                        style={{
                          position: `relative`,
                          padding: `10px 0px 25px 25px`,
                        }}
                      >
                        <Row>
                          <Col md={3}>
                            <h4 style={{ marginTop: `25px` }}>
                              Equipment Report
                            </h4>
                          </Col>
                          <Col md={3}>
                            <label>Start Date</label>
                            <CustomDatePicker
                              isValidDate={(current) => {
                                return current.isBefore(moment(), "day"); // Allows only past dates including today
                              }}
                              value={form?.equipment_report?.startDate}
                              dateFormat="YYYY-MM-DD"
                              name="startDate"
                              onChangeDate={(e) =>
                                onChange(e, "equipment_report")
                              }
                              label={`Start Date`}
                            />
                          </Col>

                          <Col md={3}>
                            <label>End Date</label>
                            <CustomDatePicker
                              isValidDate={(current) => {
                                if (!form?.equipment_report?.startDate)
                                  return current.isBefore(moment(), "day");
                                const fifteenDaysAhead = moment(
                                  form?.equipment_report?.startDate
                                ).add(15, "days");
                                return (
                                  current.isBefore(moment(), "day") &&
                                  current.isBetween(
                                    moment(form?.equipment_report?.startDate),
                                    fifteenDaysAhead,
                                    "day",
                                    "[]"
                                  )
                                ); // '[]' includes the boundaries
                              }}
                              value={form?.equipment_report?.endDate}
                              dateFormat="YYYY-MM-DD"
                              name="endDate"
                              onChangeDate={(e) =>
                                onChange(e, "equipment_report")
                              }
                              label={`End Date`}
                            />
                          </Col>

                          <Col
                            md={1}
                            style={{
                              position: "absolute",
                              right: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <span
                                onClick={
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.equipment_report?.startDate &&
                                    form?.equipment_report?.endDate
                                  )
                                    ? () => {}
                                    : (e) =>
                                        handleDownload(e, "equipment_report")
                                }
                                className={`primary-button ${
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.equipment_report?.startDate &&
                                    form?.equipment_report?.endDate
                                  )
                                    ? "disabled"
                                    : ""
                                }`}
                                style={{
                                  marginRight: "5px",
                                  marginTop: "25px",
                                }}
                              >
                                Download
                              </span>
                              <span
                                onClick={
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.equipment_report?.startDate &&
                                    form?.equipment_report?.endDate
                                  )
                                    ? () => {}
                                    : (e) =>
                                        handleDownload(e, "equipment_report")
                                }
                                className={`btn-icon excel ${
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.equipment_report?.startDate &&
                                    form?.equipment_report?.endDate
                                  )
                                    ? "disableExcelDownload"
                                    : ""
                                }`}
                                style={{ marginTop: "25px" }}
                              >
                                <AiOutlineFileExcel />
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </div>

                <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <Form>
                    <div className="topFilterSec">
                      <div
                        className="colindiaCard borderRadius5"
                        style={{
                          position: `relative`,
                          padding: `10px 0px 25px 25px`,
                        }}
                      >
                        <Row>
                          <Col md={3}>
                            <h4 style={{ marginTop: `25px` }}>
                              Tank And Bowser Report
                            </h4>
                          </Col>
                          <Col md={3}>
                            <label>Start Date</label>
                            <CustomDatePicker
                              isValidDate={(current) => {
                                return current.isBefore(moment(), "day"); // Allows only past dates including today
                              }}
                              value={form?.tank_bowser_report?.startDate}
                              dateFormat="YYYY-MM-DD"
                              name="startDate"
                              onChangeDate={(e) =>
                                onChange(e, "tank_bowser_report")
                              }
                              label={`Start Date`}
                            />
                          </Col>

                          <Col md={3}>
                            <label>End Date</label>
                            <CustomDatePicker
                              isValidDate={(current) => {
                                if (!form?.tank_bowser_report?.startDate)
                                  return current.isBefore(moment(), "day");
                                const fifteenDaysAhead = moment(
                                  form?.tank_bowser_report?.startDate
                                ).add(15, "days");
                                return (
                                  current.isBefore(moment(), "day") &&
                                  current.isBetween(
                                    moment(form?.tank_bowser_report?.startDate),
                                    fifteenDaysAhead,
                                    "day",
                                    "[]"
                                  )
                                ); // '[]' includes the boundaries
                              }}
                              value={form?.tank_bowser_report?.endDate}
                              dateFormat="YYYY-MM-DD"
                              name="endDate"
                              onChangeDate={(e) =>
                                onChange(e, "tank_bowser_report")
                              }
                              label={`End Date`}
                            />
                          </Col>

                          <Col
                            md={1}
                            style={{
                              position: "absolute",
                              right: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <span
                                onClick={
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.tank_bowser_report?.startDate &&
                                    form?.tank_bowser_report?.endDate
                                  )
                                    ? () => {}
                                    : (e) =>
                                        handleDownload(e, "tank_bowser_report")
                                }
                                className={`primary-button ${
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.tank_bowser_report?.startDate &&
                                    form?.tank_bowser_report?.endDate
                                  )
                                    ? "disabled"
                                    : ""
                                }`}
                                style={{
                                  marginRight: "5px",
                                  marginTop: "25px",
                                }}
                              >
                                Download
                              </span>
                              <span
                                onClick={
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.tank_bowser_report?.startDate &&
                                    form?.tank_bowser_report?.endDate
                                  )
                                    ? () => {}
                                    : (e) =>
                                        handleDownload(e, "tank_bowser_report")
                                }
                                className={`btn-icon excel ${
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.tank_bowser_report?.startDate &&
                                    form?.tank_bowser_report?.endDate
                                  )
                                    ? "disableExcelDownload"
                                    : ""
                                }`}
                                style={{ marginTop: "25px" }}
                              >
                                <AiOutlineFileExcel />
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </div>

                <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <Form>
                    <div className="topFilterSec">
                      <div
                        className="colindiaCard borderRadius5"
                        style={{
                          position: `relative`,
                          padding: `10px 0px 25px 25px`,
                        }}
                      >
                        <Row>
                          <Col md={3}>
                            <h4 style={{ marginTop: `25px` }}>
                              Consolidated Daily Report
                            </h4>
                          </Col>
                          <Col md={3}>
                            <label>Date</label>
                            <CustomDatePicker
                              isValidDate={(current) => {
                                return current.isBefore(moment(), "day"); // Allows only past dates including today
                              }}
                              value={form?.consolidated_daily_report?.startDate}
                              dateFormat="YYYY-MM-DD"
                              name="startDate"
                              onChangeDate={(e) =>
                                onChange(e, "consolidated_daily_report")
                              }
                              label={`Date`}
                            />
                          </Col>

                          <Col
                            md={1}
                            style={{
                              position: "absolute",
                              right: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <span
                                onClick={
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.consolidated_daily_report?.startDate
                                  )
                                    ? () => {}
                                    : (e) =>
                                        handleDownload(
                                          e,
                                          "consolidated_daily_report"
                                        )
                                }
                                className={`primary-button ${
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.consolidated_daily_report?.startDate
                                  )
                                    ? "disabled"
                                    : ""
                                }`}
                                style={{
                                  marginRight: "5px",
                                  marginTop: "25px",
                                }}
                              >
                                Download
                              </span>
                              <span
                                onClick={
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.consolidated_daily_report?.startDate
                                  )
                                    ? () => {}
                                    : (e) =>
                                        handleDownload(
                                          e,
                                          "consolidated_daily_report"
                                        )
                                }
                                className={`btn-icon excel ${
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.consolidated_daily_report?.startDate
                                  )
                                    ? "disableExcelDownload"
                                    : ""
                                }`}
                                style={{ marginTop: "25px" }}
                              >
                                <AiOutlineFileExcel />
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </div>

                <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <Form>
                    <div className="topFilterSec">
                      <div
                        className="colindiaCard borderRadius5"
                        style={{
                          position: `relative`,
                          padding: `10px 0px 25px 25px`,
                        }}
                      >
                        <Row>
                          <Col md={3}>
                            <h4 style={{ marginTop: `25px` }}>
                              Daily HSD Consumption Report
                            </h4>
                          </Col>
                          <Col md={3}>
                            <label>Date</label>
                            <CustomDatePicker
                              isValidDate={(current) => {
                                return current.isBefore(moment(), "day"); // Allows only past dates including today
                              }}
                              value={form?.hsd_consumption_report?.startDate}
                              dateFormat="YYYY-MM-DD"
                              name="startDate"
                              onChangeDate={(e) =>
                                onChange(e, "hsd_consumption_report")
                              }
                              label={`Date`}
                            />
                          </Col>

                          <Col
                            md={1}
                            style={{
                              position: "absolute",
                              right: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <span
                                onClick={
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.hsd_consumption_report?.startDate
                                  )
                                    ? () => {}
                                    : (e) =>
                                        handleDownload(
                                          e,
                                          "hsd_consumption_report"
                                        )
                                }
                                className={`primary-button ${
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.hsd_consumption_report?.startDate
                                  )
                                    ? "disabled"
                                    : ""
                                }`}
                                style={{
                                  marginRight: "5px",
                                  marginTop: "25px",
                                }}
                              >
                                Download
                              </span>
                              <span
                                onClick={
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.hsd_consumption_report?.startDate
                                  )
                                    ? () => {}
                                    : (e) =>
                                        handleDownload(
                                          e,
                                          "hsd_consumption_report"
                                        )
                                }
                                className={`btn-icon excel ${
                                  !(
                                    form?.mine &&
                                    form?.subsidiary &&
                                    form?.workshop !== "" &&
                                    form?.hsd_consumption_report?.startDate
                                  )
                                    ? "disableExcelDownload"
                                    : ""
                                }`}
                                style={{ marginTop: "25px" }}
                              >
                                <AiOutlineFileExcel />
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </div>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default FuelReports;
