import React from "react";

const CustomDatePickerBasic = ({ value, name, error, onChange }) => {
  return (
    <div>
      <input
        type="date"
        value={value}
        dateFormat="YYYY-MM-DD"
        name={name}
        onChange={onChange}
      />
      <div className="small text-danger">{error}</div>
    </div>
  );
};

export default CustomDatePickerBasic;
