export const initialForm = {
  confirmPassword: "",
  password: "",
};
export const restPasswordRules = [
  {
    field_name: "password",
    label: "Password",
    type: "password",
    isRequired: true,
  },
  {
    field_name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    isRequired: true,
  },
];
