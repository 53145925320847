import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import reject from "../../Assets/Images/reject.svg";

const DeletePopup = ({ deleteFun, id, optionTypeValue = null }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="popoverList" onClick={() => setShow(true)}>
        {" "}
        Delete
      </div>

      <Modal
        show={show}
        // onHide={() => setShow(false)}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header
          // closeButton={messagePopupType ? true : false}
          className="border-bottom-0"
        ></Modal.Header>
        <Modal.Body className="p-5 pt-0 text-center">
          <>
            <img src={reject} className="img-fluid" alt="delete" />
            <h2 className="title mt-3">Delete</h2>
            <p className="">Are you sure you want to delete?</p>
            <Button
              className="secondaryBtn mt-3 me-3"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              className="primaryBtn  mt-3"
              onClick={() => deleteFun(id, optionTypeValue)}
            >
              Yes
            </Button>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeletePopup;
