import toastr from "toastr";
import { RestMethod } from "../ApiConfig/RestMethod";

export const CallApi = async (api, Method, data) => {
  try {
    const response = await RestMethod[Method](api, data);
    return response?.data;
  } catch (error) {
    toastr.error(error);
    return {
      status: 0,
      message: error,
    };
  }
};
