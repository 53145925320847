import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  reportTypeListObj,
  SidebarData,
  reportTypeListTableLabel,
  formLabel,
  timeOptions,
  newFormLabel,
} from "../UploadedFiles/DATA";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useForm } from "../../../_helpers/hooks";
import toastr from "toastr";

import { uploadFileById } from "../../../services/_uploadedFiles/uploadedFiles";
import ErrorFields from "./ErrorFields";
import "./environment.scss";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { Input, Label } from "reactstrap";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import { object } from "prop-types";
import Select from "../../../components/Select/Select";

const AirQualityUploadedDataEdit = () => {
  const urlFileType = useLocation().search.replace("?type=", "");
  console.log("4444444444444444",urlFileType);
  const [show, setShow] = useState(false);
  const [dataForError, setDataForError] = useState(false);
  const [fileError, setFileError] = useState({});
  const [form, onChange, setForm] = useForm({
    fileType: reportTypeListObj[urlFileType],
  });
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [file, setFile] = useState("");
  const [showErrorList, setShowErrorList] = useState("");
  const [submit, setSubmit] = useState(false);
  const [errorData, setErrorData] = useState([]);

  let navigate = useNavigate();
  const param = useParams();

  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };
  const closePopup = () => {
    setShow(false);
    navigate("/environment-management/manage/all-data?type=" + urlFileType);
  };
  // const errorList = () => {
  //   if (!dataForError) return;
  //   setShowErrorList(true);
  // };

  const getData = async () => {
    const uploadedData = await RestMethod.GET(
      "/environment-management/uploaded-file/" +
        param.id +
        "?&type=" +
        reportTypeListObj[urlFileType]
    );

    delete uploadedData?.data?.createdAt;
    delete uploadedData?.data?.createdBy;
    delete uploadedData?.data?.deleted;
    delete uploadedData?.data?.site_id;
    delete uploadedData?.data?.updatedAt;
    delete uploadedData?.data?.updatedBy;
    delete uploadedData?.data?.uploaded_file_id;
    delete uploadedData?.data?.id;
    delete uploadedData?.data?.["pm2.5_actual"];
    delete uploadedData?.data?.["pm2.5_limit"];

    setForm({ ...form, ...uploadedData?.data });
  };
  const onChangeInput = ({ target: { name, value } }, labelIs) => {
    if (
      urlFileType === "Ambient-Air-Quality-CAAQMS" ||
      urlFileType === "Ambient-Air-Quality-CMPDIL" ||
      urlFileType === "Micro-Meteorological-CAAQMS" ||
      urlFileType === "Performance-Report-Of-Pollution" ||
      urlFileType === "Water-Quality-CMPDIL" ||
      urlFileType === "Noise-Level-CMPDIL" ||
      urlFileType === "Land-Reclamation-Status-CMPD" ||
      urlFileType === "Plantation-Project" ||
      urlFileType === "Power-Consumption-Report" ||
      urlFileType === "Ground-Water-level-CMPDIL"
    ) {
      if (value === "") {
        setFileError({ ...fileError, [name]: labelIs + " is Required" });
      } else {
        // setFileError({ ...fileError, [name]: null });
        delete fileError[name];
      }
    } else if (urlFileType === "air-quality-data-emr") {
      if (
        name === "station_name" ||
        name === "station_category" ||
        name === "sampling_date" ||
        name === "analysis_date"
      ) {
        if (value === "") {
          setFileError({ ...fileError, [name]: labelIs + " is Required" });
        } else {
          delete fileError[name];
        }
      }
    } else if (urlFileType === "ground-water-level") {
      if (name === "well_no") {
        if (value === "") {
          setFileError({ ...fileError, [name]: labelIs + " is Required" });
        } else {
          // setFileError({ ...fileError, [name]: null });
          delete fileError[name];
        }
      }
    } else if (urlFileType === "overall-weather-data") {
      if (name === "weather_datetime") {
        if (value === "") {
          setFileError({ ...fileError, [name]: labelIs + " is Required" });
        } else {
          // setFileError({ ...fileError, [name]: null });
          delete fileError[name];
        }
      }
    } else if (urlFileType === "plantation-data") {
      if (name === "plantation_date") {
        if (value === "") {
          setFileError({ ...fileError, [name]: labelIs + " is Required" });
        } else {
          delete fileError[name];
        }
      }
    }
    setForm({ ...form, [name]: value });
  };

  const textOrNumber = (d) => {
    if (
      d === "station_name" ||
      d === "project" ||
      d === "subsidiary" ||
      d === "code" ||
      d === "well_type" ||
      d === "station_category" ||
      d === "station_code" ||
      d === "og" ||
      d === "pre_monsoon" ||
      d === "winter" ||
      d === "monsoon" ||
      d === "post_monsoon" ||
      d === "equipment" ||
      d === "type_of_equipment" ||
      d === "remarks" ||
      d === "so2" ||
      d === "spm" ||
      d === "feeder" ||
      d === "quarter" ||
      d === "name" ||
      d === "category"
      // d === "latitude"
    ) {
      return "text";
    } else {
      return "number";
    }
  };

  // let fields = Object.keys(form);
  let fields = newFormLabel[urlFileType]? Object.keys(newFormLabel[urlFileType]):[];

  // fields.includes("stationData") && fields.push("name", "type", "category");
  fields = fields?.filter((item) => {
    return item !== "fileType";
  });

  useEffect(() => {
    getData();
  }, []);

  const setShowError = (e) => {
    setShowErrorList(e);
  };

  Object.assign(
    form,
    form?.siteBasedMasterDropdownsData && {
      total_leased_area: form?.siteBasedMasterDropdownsData?.name,
      feeder: form?.siteBasedMasterDropdownsData?.name,
    },
    form?.stationData && {
      name: form?.stationData?.name,
      category: form?.stationData?.category,
      type: form?.stationData?.type,
      code: form?.stationData?.code,
      latitude: form?.stationData?.lat,
      longitude: form?.stationData?.log,
    },
    form?.equipmentData && {
      equipment: form?.equipmentData?.name,
      type_of_equipment: form?.equipmentTypeData?.name,
    }
  );

  const onSubmit = async () => {
    if (Object.keys(fileError).length > 0) {
      toastr.error(`Please Enter valid values`);
      return;
    }
    setDataForError(false);
    setSubmit(true);
    let formData = {};
    fields.forEach((d) => {
      formData[d] = form[d];
    });
    formData.fileType = reportTypeListObj[urlFileType];

    const response = await uploadFileById(formData, param.id);

    if (response?.response?.data?.status === 1) {
      if (response?.response?.data?.data?.status === 0) {
        toastr.error(
          newFormLabel[urlFileType][
            response?.response?.data?.data?.invalidData[0]?.uniqueArr[0]
          ] +
            " " +
            response?.response?.data?.data?.invalidData[0]?.message
        );
        return;
      }
      toastr.success(`Saved Successfully`);
      navigate("/environment-management/manage/all-data?type=" + urlFileType);
    } else {
      toastr.error(response?.response?.data?.message);
    }
  };
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              className="title"
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Environment Management System",
                    path: "#",
                  },
                  {
                    name: "Environment Management",
                    path: "/environment-management/manage",
                  },
                  {
                    name: reportTypeListTableLabel[urlFileType],
                    path: "/environment-management/manage/" + urlFileType,
                  },
                  {
                    name: "Uploaded File Edit",
                    path: "#",
                  },
                ]}
                // module={ACTIVE_MODULE.incident}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              {reportTypeListTableLabel[urlFileType] + " "}Uploaded File Edit
              &nbsp;
              <span className="blueLabel"></span>
            </h3>
            {/* <h3 className="title d-flex align-items-end">
              Environment Management Creation &nbsp;
              <span className="blueLabel"></span>
            </h3> */}
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <Form>
                  <Row>
                    <Col md={6}>
                      <Label>Subsidiary</Label>
                      <Input
                        disabled
                        name="subsidiary"
                        value={form?.site?.parentSite?.name}
                        // onChange={(e) => onChangeInput(e, i)}
                        type="text"
                        className="form-control mb-3"
                      />
                      {/* {submit && form?.subsidiary == "" && (
                      <p style={{ color: "red" }}>Subsidiary Required</p>
                    )} */}
                    </Col>
                    <Col md={6}>
                      <Label>Project</Label>
                      <Input
                        disabled
                        name="mine"
                        value={form?.site?.name}
                        // onChange={(e) => onChangeInput(e, i)}
                        type="text"
                        className="form-control"
                      />
                      {/* {submit && form?.mine == "" && (
                      <p style={{ color: "red" }}>Mine Required</p>
                    )} */}
                    </Col>
                    <Col md={6}>
                      <Label>File Type</Label>
                      <Input
                        disabled
                        name="fileType"
                        value={form.fileType}
                        // onChange={(e) => onChangeInput(e, i)}
                        type="text"
                        className="form-control"
                      />
                      {/* {submit && form?.mine == "" && (
                      <p style={{ color: "red" }}>Mine Required</p>
                    )} */}
                    </Col>
                    {fields
                      .filter((data) => newFormLabel[urlFileType][data])
                      .map((d) => {
                        return (
                          <>
                            <Col md={6}>
                              <Label>{newFormLabel[urlFileType][d]}</Label>
                              {d === "date" ||
                              d === "plantation_date" ||
                              d === "air_quality_date" ||
                              d === "sample_date" ||
                              d === "date_time" ||
                              d === "sampling_date" ||
                              d === "date_of_sampling" ? (
                                <>
                                  <CustomDatePicker
                                    value={form[d]}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat={
                                      urlFileType === "Power-Consumption-Report"
                                        ? "hh: mm A"
                                        : ""
                                    }
                                    name={d}
                                    data-testid="date"
                                    onChange={(e) =>
                                      onChangeInput(
                                        e,
                                        newFormLabel[urlFileType][d]
                                      )
                                    }
                                    // error={fileError[d]}
                                  />
                                  <p style={{ color: "red" }}>{fileError[d]}</p>
                                </>
                              ) : (
                                <>
                                  <Input
                                    disabled={
                                      d === "subsidiary" ||
                                      d === "project" ||
                                      d === "name" ||
                                      d === "category" ||
                                      d === "total_leased_area" ||
                                      d === "well_type" ||
                                      d === "code" ||
                                      d === "category_of_station" ||
                                      d === "equipment" ||
                                      d === "type_of_equipment" ||
                                      d === "feeder" ||
                                      d === "type" ||
                                      d === "code" ||
                                      d === "latitude" ||
                                      d === "longitude" ||
                                      d === "station_code"
                                    }
                                    name={d}
                                    type={textOrNumber(d)}
                                    value={form[d]}
                                    data-testid="textOrNumber"
                                    onChange={(e) =>
                                      onChangeInput(
                                        e,
                                        newFormLabel[urlFileType][d]
                                      )
                                    }
                                    className="form-control"
                                  />

                                  <p style={{ color: "red" }}>{fileError[d]}</p>
                                </>
                              )}
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                </Form>
                <div className="btnSec">
                  <Button onClick={() => closeFun()} className="secondaryBtn">
                    Close
                  </Button>
                  <div className="d-flex">
                    <Button onClick={() => onSubmit()} className="primaryBtn">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            messagePopupType={messagePopupType}
            show={show}
            setShow={setShow}
            closePopup={closePopup}
          />
          <ErrorFields
            errorData={errorData}
            show={showErrorList}
            setShow={setShowError}
            closePopup={closePopup}
          />
        </div>
      </div>
    </div>
  );
};

export default AirQualityUploadedDataEdit;
