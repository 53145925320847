import groupArray from "group-array";
import {
  basicBarChartWithCustomTooltipDataFormat,
  mapFormatData,
  multiLineChartDataFormat,
  pieChartDataWithModalForTotalVs,
} from "../../../components/Chart/Common/config";
import { reportTypeListUrl } from "../UploadedFiles/DATA";
import AmbientAirQualityCaaqmsDas from "./AmbientAirQualityCaaqmsDas";
import AmbientAirQualityCmpdil from "./AmbientAirQualityCmpdil";
import LandReclamationStatus from "./LandReclamationStatus";
import MicroMeteorologicalCaaqms from "./MicroMeteorologicalCaaqms";
import PlantationProject from "./PlantationProject";
import NoiseLevelCmpdil from "./NoiseLevelCmpdil";
import WaterQualityCmpdil from "./WaterQualityCmpdil";
import PerformanceReportOfPollutionDas from "./PerformanceReportOfPollutionDas";
import GroundWaterLevelCmpdilDas from "./GroundWaterLevelCmpdilDas";
import PowerConsumptionReportDas from "./PowerConsumptionReportDas";
import { yearAgoToCurrentYearList } from "../../../_helpers/helper";

export const screenPageObj = {
  1: [
    reportTypeListUrl?.Ambient_Air_Quality_CAAQMS,
    reportTypeListUrl?.Ambient_Air_Quality_CMPDIL,
    reportTypeListUrl?.Ground_Water_level_CMPDIL,
    reportTypeListUrl?.Land_Reclamation_Status_CMPD,
  ],
  2: [
    reportTypeListUrl?.micro_meteorological_CAAQMS,
    reportTypeListUrl?.Noise_Level_CMPDIL,
    reportTypeListUrl?.Plantation_Project,
    reportTypeListUrl?.Water_Quality_CMPDIL,
  ],
  3: [
    reportTypeListUrl?.performance_report_of_pollution,
    reportTypeListUrl?.power_consumption_report,
  ],
};

export const caaqmsOptionsUnitsObj = {
  pme10: "µg/m3",
  pme25: "µg/m3",
  no: "µg/m3",
  no2: "µg/m3",
  nox: "µg/m3",
  so2: "µg/m3",
  co: "mg/m3",
};

export const airCmpdilUnitObj = {
  pme10: "µg/m3",
  pme25: "µg/m3",
  no2: "µg/m3",
  so2: "µg/m3",
  spm: "µg/m3",
};
export const waterUnitObj = {
  tss: "mg/L",
  ph: "PH",
  o_and_g: "mg/L",
  cod: "mg/L",
  bod: "mg/L",
};

export const micrometrologicalUnitObj = {
  temperature: "°C",
  relative_humidity: "%",
  solar_radiation: "W/m2",
  wind_speed: "m/s",
  wind_direction: "° (Degree)",
  rainfall: "mm",
};

export const noiseFilterOptions = [
  { label: "Day", value: "day" },
  { label: "Night", value: "night" },
];
export const waterFilterOptions = [
  { label: "TSS", value: "tss" },
  { label: "PH", value: "ph" },
  { label: "O and G", value: "o_and_g" },
  { label: "COD", value: "cod" },
  { label: "BOD", value: "bod" },
];
export const caaqmsFilterOptions = [
  { label: "PM 10", value: "pme10" },
  { label: "PM 2.5", value: "pme25" },
  { label: "NO", value: "no" },
  { label: "NO2", value: "no2" },
  { label: "NOx", value: "nox" },
  { label: "SO2", value: "so2" },
  { label: "CO", value: "co" },
];
export const extraFilterOption = [
  { label: "Use Station", value: "useStation" },
  { label: "Use Device", value: "useDevice" },
];
export const micrometrologicalFilterOptions = [
  { label: "Temperature", value: "temperature" },
  { label: "Relative Humidity", value: "relative_humidity" },
  { label: "Solar Radiation", value: "solar_radiation" },
  { label: "Wind Speed", value: "wind_speed" },
  { label: "Wind Direction", value: "wind_direction" },
  { label: "Rainfall", value: "rainfall" },
];
export const airCmpdilFilterOptions = [
  { label: "PM 10", value: "pme10" },
  { label: "PM 2.5", value: "pme25" },
  { label: "SPM", value: "spm" },
  { label: "NO2", value: "no2" },
  { label: "SO2", value: "so2" },
];

export const pollutionFilterOptions = [
  { label: "Working Hours", value: "working_hours" },
  { label: "Idle Hours", value: "idle_hours" },
  { label: "Maintenance Hours", value: "maintenance_hours" },
  { label: "Breakdown Hours", value: "breakdown_hours" },
];

export const powerConsumptionFilterOptions = [
  { label: "Hourly", value: "hourly" },
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Yearly", value: "yearly" },
];

export const CaaqmsPageChartList = [
  {
    type: "map",
    value: "mapData",
  },
  {
    name: "Ambient Air Quality CAAQMS ( Daily )",
    // yAxisName: "Count",
    // xAxisName: "Month",
    value: "lineDashboard",
    type: "multiLine",
    dataFrom: "useStation",
    options: caaqmsFilterOptions,
    unitObj: caaqmsOptionsUnitsObj,
    // hideFilter: true,
    // isMines: true,
    extraFilterOption: extraFilterOption,
    extraFilterKey: "extrafilterType",
  },
  {
    name: "Ambient Air Quality CAAQMS ( Avg )",
    // yAxisName: "Count",
    // xAxisName: "",
    value: "barDashboard",
    type: "barWithCustomTooltip",
    options: caaqmsFilterOptions,
    unitObj: caaqmsOptionsUnitsObj,
  },
];

export const PlantationPageChartList = [
  {
    name: "Plantation Project",
    yAxisName: "Value",
    xAxisName: "Mine",
    value: "barDashboard",
    type: "barWithCustomTooltip",
    // options: caaqmsFilterOptions,
    // hideFilter: true,
    // isMines: true,
  },
];
export const airCmpdilPageChartList = [
  {
    type: "map",
    value: "mapData",
  },
  {
    name: "Ambient Air Quality CMPDIL ( Daily )",
    yAxisName: "Count",
    xAxisName: "Month",
    value: "lineDashboard",
    type: "multiLine",
    options: airCmpdilFilterOptions,
    unitObj: airCmpdilUnitObj,
    // hideFilter: true,
    // isMines: true,
  },
  {
    name: "Ambient Air Quality CMPDIL ( Avg )",
    yAxisName: "Count",
    // xAxisName: "",
    value: "barDashboard",
    type: "barWithCustomTooltip",
    options: airCmpdilFilterOptions,
    unitObj: airCmpdilUnitObj,
  },
];

export const waterCmpdilPageChartList = [
  {
    type: "map",
    value: "mapData",
  },
  {
    name: "Water Quality ( Daily )",
    yAxisName: "Count",
    xAxisName: "Month",
    value: "lineDashboard",
    type: "multiLine",
    options: waterFilterOptions,
    unitObj: waterUnitObj,
    // hideFilter: true,
    // isMines: true,
  },
  {
    name: "Water Quality ( Avg )",
    yAxisName: "Count",
    // xAxisName: "",
    value: "barDashboard",
    type: "barWithCustomTooltip",
    options: waterFilterOptions,
    unitObj: waterUnitObj,
  },
];

export const microPageChartList = [
  {
    type: "map",
    value: "mapData",
  },
  {
    name: "Micro Meteorological ( Daily )",
    yAxisName: "Count",
    xAxisName: "Month",
    value: "lineDashboard",
    type: "multiLine",
    options: micrometrologicalFilterOptions,
    unitObj: micrometrologicalUnitObj,
    // hideFilter: true,
    // isMines: true,
  },
  {
    name: "Temperature ( Avg )",
    yAxisName: micrometrologicalUnitObj.temperature,
    // xAxisName: "",
    value: "temperature",
    type: "barWithCustomTooltip",
    hideFilter: true,
    chartType: "multiBar",
  },
  {
    name: "Relative Humidity ( Avg )",
    yAxisName: micrometrologicalUnitObj.relative_humidity,
    // xAxisName: "",
    value: "relative_humidity",
    type: "barWithCustomTooltip",
    hideFilter: true,
    chartType: "multiBar",
  },
  {
    name: "Rainfall ( Avg )",
    yAxisName: micrometrologicalUnitObj.rainfall,
    // xAxisName: "",
    value: "rainfall",
    type: "barWithCustomTooltip",
    hideFilter: true,
    chartType: "multiBar",
  },
  {
    name: "Solar Radiation ( Avg )",
    yAxisName: micrometrologicalUnitObj.solar_radiation,
    // xAxisName: "",
    value: "solar_radiation",
    type: "barWithCustomTooltip",
    hideFilter: true,
    chartType: "multiBar",
  },
  {
    name: "Wind Speed ( Avg )",
    yAxisName: micrometrologicalUnitObj.wind_speed,
    // xAxisName: "",
    value: "wind_speed",
    type: "barWithCustomTooltip",
    hideFilter: true,
    chartType: "multiBar",
  },
  {
    name: "Wind Direction ( Avg )",
    yAxisName: micrometrologicalUnitObj.wind_direction,
    // xAxisName: "",
    value: "wind_direction",
    type: "barWithCustomTooltip",
    hideFilter: true,
    chartType: "multiBar",
  },
];

export const noiseCmpdilPageChartList = [
  {
    type: "map",
    value: "mapData",
  },
  {
    name: "Noise Level ( Daily )",
    yAxisName: "dB",
    // xAxisName: "Month",
    value: "lineDashboard",
    type: "multiLine",
    options: noiseFilterOptions,
    // hideFilter: true,
    // isMines: true,
  },
  {
    name: "Noise Level Day ( Avg )",
    yAxisName: "dB",
    // xAxisName: "",
    value: "day",
    type: "barWithCustomTooltip",
    hideFilter: true,
    chartType: "multiBar",
  },
  {
    name: "Noise Level Night ( Avg )",
    yAxisName: "dB",
    // xAxisName: "",
    value: "night",
    type: "barWithCustomTooltip",
    hideFilter: true,
    chartType: "multiBar",
  },
];

export const pollutionPageChartList = [
  {
    name: "Performance Report Of Pollution",
    yAxisName: "hr",
    xAxisName: "Month",
    value: "lineDashboard",
    type: "multiLine",
    options: pollutionFilterOptions,
    // hideFilter: true,
    // isMines: true,
  },
  {
    name: "Performance Report Of Pollution",
    yAxisName: "hr",
    // xAxisName: "",
    value: "barDashboard",
    type: "barWithCustomTooltip",
    options: pollutionFilterOptions,
  },
];

export const groundWaterPageChartList = [
  {
    name: "Ground Water level ( Month )",
    yAxisName: "m. bgl.",
    // xAxisName: "Month",
    value: "lineDashboard",
    type: "multiLine",
    hideFilter: true,
    // isMines: true,
  },
  {
    name: "Ground Water level ( Avg )",
    yAxisName: "m. bgl.",
    // xAxisName: "",
    value: "barDashboard",
    type: "barWithCustomTooltip",
    hideFilter: true,
  },
];

export const landReclamationPageChartObj = {
  title: "Land Reclamation Status",
  value: "",
  type: "pieVs",
  isRO: false,
  isHo: false,
};

export const powerConsumptionPageChartObj = {
  unit: {
    name: "Power Consumption Report",
    yAxisName: "Count",
    xAxisName: "Month",
    // value: "unit",
    type: "multiLine",
    options: powerConsumptionFilterOptions,
    // isMines: true,
  },
  power: {
    name: "Power Consumption Report",
    yAxisName: "Count",
    xAxisName: "Month",
    // value: "power",
    type: "multiLine",
    hideFilter: true,
    // isMines: true,
  },
};

export const initialChartFilter = {
  lineDashboard: "pme10",
  barDashboard: "pme10",
};
export const WaterinitialChartFilter = {
  lineDashboard: "tss",
  barDashboard: "tss",
};
export const airCmpdilChartFilter = {
  lineDashboard: "pme10",
  barDashboard: "pme10",
};

export const microChartFilter = {
  lineDashboard: "temperature",
};

export const noiseCmpdilChartFilter = {
  lineDashboard: "day",
};

export const pollutionChartFilter = {
  lineDashboard: "working_hours",
  barDashboard: "working_hours",
};

// export const powerConsumptionChartFilter = {
//   hourly
// };

export const initialFilterFormResidential = {
  siteId: "",
  subsideryId: "",
  startDate: "",
  endDate: "",
  category: "",
};

export const initialFilterForm = {
  siteId: "",
  subsideryId: "",
  startDate: "",
  endDate: "",
  category: "",
};

export const waterQualityFilterForm = {
  siteId: "",
  subsideryId: "",
  startDate: "",
  endDate: "",
};

export const landFilterForm = {
  siteId: "",
  subsideryId: "",
  year: "",
};

export const pollutionFilterForm = {
  siteId: "",
  subsideryId: "",
  startDate: "",
  endDate: "",
};

export const groundWaterFilterForm = {
  siteId: "",
  subsideryId: "",
  well: "",
  startDate: "",
  endDate: "",
};

export const categoryType = [
  {
    value: "Industrial",
    label: "Industrial",
  },
  {
    value: "Residential",
    label: "Residential",
  },
];

export const wellList = [
  {
    value: "Piezometer",
    label: "Piezometer",
  },
  {
    value: "Well",
    label: "Well",
  },
];

export const quarterlyObj = {
  Q1: "Feb",
  Q2: "May",
  Q3: "Aug",
  Q4: "Nov",
};

export const yearsList = () => {
  return yearAgoToCurrentYearList(2)?.map((year) => ({
    value: year,
    label: year,
  }));
};

export const envDasFilterLabelObj = {
  category: "Category",
  well: "Well Category",
  subsideryId: "Subsidiary",
  siteId: "Mine",
  year: "Year",
  startDate: "Start Date",
  endDate: "End Date",
};

export const CHART_TYPE_BY_DATA = (chartType, chartData) => {
  if (!chartData?.length) return [];
  switch (chartType) {
    case "multiLine":
      return multiLineChartDataFormat(chartData);
    case "barWithCustomTooltip":
      return basicBarChartWithCustomTooltipDataFormat(chartData);
    case "pieVs":
      return pieChartDataWithModalForTotalVs(chartData);
    case "map":
      return mapFormatData(chartData);
    default:
      return "No chart";
  }
};

export const PAGE_TYPE_BY_FORM_FILTER = (pageType) => {
  switch (pageType) {
    case reportTypeListUrl.Ambient_Air_Quality_CAAQMS:
      return initialFilterFormResidential;
    case reportTypeListUrl.Ambient_Air_Quality_CMPDIL:
      return initialFilterFormResidential;
    case reportTypeListUrl.Water_Quality_CMPDIL:
      return waterQualityFilterForm;
    case reportTypeListUrl.micro_meteorological_CAAQMS:
      return initialFilterFormResidential;
    case reportTypeListUrl.Noise_Level_CMPDIL:
      return initialFilterForm;
    case reportTypeListUrl.performance_report_of_pollution:
      return pollutionFilterForm;
    case reportTypeListUrl.Land_Reclamation_Status_CMPD:
      return landFilterForm;
    case reportTypeListUrl.Plantation_Project:
      return landFilterForm;
    case reportTypeListUrl.Ground_Water_level_CMPDIL:
      return groundWaterFilterForm;
    case reportTypeListUrl.power_consumption_report:
      return pollutionFilterForm;
    default:
      return {};
  }
};

export const PAGE_TYPE_BY_CHART_FILTER = (pageType) => {
  switch (pageType) {
    case reportTypeListUrl.Ambient_Air_Quality_CAAQMS:
      return initialChartFilter;
    case reportTypeListUrl.Ambient_Air_Quality_CMPDIL:
      return airCmpdilChartFilter;
    case reportTypeListUrl.Water_Quality_CMPDIL:
      return WaterinitialChartFilter;
    case reportTypeListUrl.micro_meteorological_CAAQMS:
      return microChartFilter;
    case reportTypeListUrl.Noise_Level_CMPDIL:
      return noiseCmpdilChartFilter;
    case reportTypeListUrl.performance_report_of_pollution:
      return pollutionChartFilter;
    default:
      return {};
  }
};

export const PAGE_TYPE_BY_CHART_LIST = (pageType) => {
  switch (pageType) {
    case reportTypeListUrl.Ambient_Air_Quality_CAAQMS:
      return CaaqmsPageChartList;
    case reportTypeListUrl.Ambient_Air_Quality_CMPDIL:
      return airCmpdilPageChartList;
    case reportTypeListUrl.Water_Quality_CMPDIL:
      return waterCmpdilPageChartList;
    case reportTypeListUrl.micro_meteorological_CAAQMS:
      return microPageChartList;
    case reportTypeListUrl.Noise_Level_CMPDIL:
      return noiseCmpdilPageChartList;
    case reportTypeListUrl.performance_report_of_pollution:
      return pollutionPageChartList;
    case reportTypeListUrl.Ground_Water_level_CMPDIL:
      return groundWaterPageChartList;
    default:
      return [];
  }
};

export const PAGE_TYPE_BY_DASHBOARD = (
  pageType,
  chartData,
  onChangeChart,
  chartFilterForm,
  onChangeExtra,
  extraChartFilterForm,
  filterForm
) => {
  switch (pageType) {
    case reportTypeListUrl.Ambient_Air_Quality_CAAQMS:
      return (
        <AmbientAirQualityCaaqmsDas
          data={chartData}
          onChange={onChangeChart}
          form={chartFilterForm}
          pageType={pageType}
          extraform={extraChartFilterForm}
          onChangeExtra={onChangeExtra}
          filterForm={filterForm}
        />
      );
    case reportTypeListUrl.Ambient_Air_Quality_CMPDIL:
      return (
        <AmbientAirQualityCmpdil
          data={chartData}
          onChange={onChangeChart}
          form={chartFilterForm}
          pageType={pageType}
        />
      );
    case reportTypeListUrl.Water_Quality_CMPDIL:
      return (
        <WaterQualityCmpdil
          data={chartData}
          onChange={onChangeChart}
          form={chartFilterForm}
          pageType={pageType}
        />
      );
    case reportTypeListUrl.micro_meteorological_CAAQMS:
      return (
        <MicroMeteorologicalCaaqms
          data={chartData}
          onChange={onChangeChart}
          form={chartFilterForm}
          pageType={pageType}
        />
      );
    case reportTypeListUrl.Land_Reclamation_Status_CMPD:
      return <LandReclamationStatus data={chartData} pageType={pageType} />;
    case reportTypeListUrl.Plantation_Project:
      return <PlantationProject data={chartData} pageType={pageType} />;
    case reportTypeListUrl.Noise_Level_CMPDIL:
      return (
        <NoiseLevelCmpdil
          data={chartData}
          onChange={onChangeChart}
          form={chartFilterForm}
          pageType={pageType}
        />
      );
    case reportTypeListUrl.performance_report_of_pollution:
      return (
        <PerformanceReportOfPollutionDas
          data={chartData}
          onChange={onChangeChart}
          form={chartFilterForm}
          pageType={pageType}
        />
      );
    case reportTypeListUrl.Ground_Water_level_CMPDIL:
      return <GroundWaterLevelCmpdilDas data={chartData} pageType={pageType} />;
    case reportTypeListUrl.power_consumption_report:
      return (
        <PowerConsumptionReportDas
          data={chartData}
          onChange={onChangeChart}
          form={chartFilterForm}
          pageType={pageType}
        />
      );
    default:
      return "";
  }
};
