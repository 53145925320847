import {
  deleteUploadedData,
  getAllUploadedData,
  getAllUploadedFiles,
} from "../../../services/_uploadedFiles/uploadedFiles";
import {
  DDMMYYYYHHMMAFormat,
  DDMMYYYYFormat,
} from "../../../_helpers/dateHelper";
import { capitalizeName, fileExtension } from "../../../_helpers/helper";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import linkImg1 from "../../../Assets/Images/selectionIcon1.svg";
import linkImg2 from "../../../Assets/Images/selectionIcon2.svg";
import linkImg3 from "../../../Assets/Images/selectionIcon3.svg";
import linkImg4 from "../../../Assets/Images/selectionIcon4.svg";
import linkImg5 from "../../../Assets/Images/selectionIcon5.svg";
import {
  deleteEnvConfigStation,
  deleteMasterDropdown,
  getAllMasterDropdownReson,
  getAllMasterDropdownstationCategoryAirQualityEmr,
  getAllMasterDropdownStationCategoryNoise,
  getAllMasterDropdownStationTypeWater,
  getAllMasterDropdownstationCategoryWater,
  getAllPollutionControlMachineApis,
  getAllConfig,
  getAllEquipmentApis,
  getAllEquipmentTypeApis,
  getAllPollutionApis,
  getAllLandReclamationApis,
  getAllPowerConsumptionApis,
  deleteFuelConfigWithSiteApis,
  deleteFuelConfigWithoutSiteApis,
  getAllEnvConfigcaaqms,
  getAllEnvConfigcmpdil,
  getAllEnvConfigWaterQuality,
  getAllEnvConfigNoiseLevel,
  getAllEnvConfigGroundWater,
  getAllEnvConfigNoisePermissible,
  getAllEnvConfigcmpdilPermissible,
  getAllEnvConfigcaaqmsPermissible,
  deletePermissibleMasterDropdown,
  getAllSection,
  deleteCoalQualitySap,
  getAllCoalQualitySap,
  getCoalQualitySapListWithPagination,
  exportCoalQualitySapById,
  exportEnvSapById,
  getEnvSapListWithPagination,
  deleteEnvSap,
} from "../../../services/_reasonList/reasonList";

export const envFileType = [
  "Ambient-Air-Quality-CAAQMS",
  "Ambient-Air-Quality-CMPDIL",
  "Ground-Water-level-CMPDIL",
  "Land-Reclamation-Status-CMPD",
  "Micro-Meteorological-CAAQMS",
  "Noise-Level-CMPDIL",
  "Plantation-Project",
  "Water-Quality-CMPDIL",
  "Performance-Report-Of-Pollution",
  "Power-Consumption-Report",
];

export const SidebarData = [
  {
    img: linkImg1,
    title: "Dashboard",
    active: 0,
    activeUrl: "dashboard",
    link: "/environment-management/dashboard/Ambient-Air-Quality-CAAQMS",
    permission: ["environment_management_dashboard"],
  },
  {
    img: linkImg2,
    title: "User Management",
    active: 0,
    activeUrl: "user-management",
    link: "/environment-management/user-management",
    permission: ["env_admin"],
  },
  {
    img: linkImg3,
    title: "Configuration",
    active: 0,
    link: "/environment-management/configuration",
    activeUrl: "/configuration",
    permission: ["env_admin", "super_admin"],
  },
  {
    img: linkImg4,
    title: "Environment Management",
    active: 0,
    link: "/environment-management/manage",
    activeUrl: "/environment-management/manage",
    // activeUrl: "incident-management",
    permission: ["env_admin", "env_user"],
  },
  {
    img: linkImg5,
    title: "Help Manual",
    active: 0,
    activeUrl: "help-manual",
    link: "/environment-management/help-manual",
    permission: [
      "env_admin",
      "environment_management_dashboard",
      "env_user",
      "super_admin",
    ],
  },
];

export const EventFlowDashboardList = [
  {
    img: linkImg1,
    label: "Dasboard",
    // link: "/environment-management/manage/air-quality-data",
    // role: ["contractor_dashboard"],
  },
];

export const EventFlowList = [
  {
    // role: ["contractor_dashboard"],
    img: linkImg1,
    label: "Ambient Air Quality CAAQMS",
    link: "/environment-management/manage/Ambient-Air-Quality-CAAQMS",
  },
  {
    img: linkImg1,
    link: "/environment-management/manage/Ambient-Air-Quality-CMPDIL",
    label: "Ambient Air Quality CMPDIL",
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg1,
    label: "Ground Water level CMPDIL",
    link: "/environment-management/manage/Ground-Water-level-CMPDIL",
    // role: ["contractor_dashboard"],
  },
  {
    // role: ["contractor_dashboard"],
    img: linkImg1,
    label: "Land Reclamation Status CMPD",
    link: "/environment-management/manage/Land-Reclamation-Status-CMPD",
  },
  {
    img: linkImg1,
    label: "Micro Meteorological CAAQMS",
    // role: ["contractor_dashboard"],
    link: "/environment-management/manage/Micro-Meteorological-CAAQMS",
  },
  {
    img: linkImg1,
    link: "/environment-management/manage/Noise-Level-CMPDIL",
    // role: ["contractor_dashboard"],
    label: "Noise Level CMPDIL",
  },
  {
    label: "Plantation Project",
    link: "/environment-management/manage/Plantation-Project",
    img: linkImg1,
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg1,
    label: "Water Quality CMPDIL",
    link: "/environment-management/manage/Water-Quality-CMPDIL",
    // role: ["contractor_dashboard"],
  },
  {
    label: "Performance Report Of Pollution",
    link: "/environment-management/manage/Performance-Report-Of-Pollution",
    // role: ["contractor_dashboard"],
    img: linkImg1,
  },
  {
    // role: ["contractor_dashboard"],
    img: linkImg1,
    label: "Power Consumption Report",
    link: "/environment-management/manage/Power-Consumption-Report",
  },
  {
    // role: ["contractor_dashboard"],
    img: linkImg1,
    label: "Ambient Air Quality CAAQMS (API)",
    link: "/environment-management/manage/sap-coal-quality-import-manuals",
  },
];
export const ConfigurationTabs = [
  {
    img: linkImg1,
    label: "Air Quality EMR Station Category",
    link: "/configuration/environment/air-quality-emr",
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg1,
    label: "Noise Station Category",
    link: "/configuration/environment/noise",
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg1,
    label: "Water Station Type",
    link: "/configuration/environment/sation-type-water",
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg1,
    label: "Water Station Category",
    link: "/configuration/environment/station-category-water",
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg1,
    label: "Ground Water Well",
    link: "/configuration/environment/ground-water/well",
    // role: ["contractor_dashboard"],
  },
  // {
  //   img: linkImg1,
  //   label: "Env Config",
  //   link: "/config/environment/air-quality-emr",
  //   role: ["env_admin", "super_admin"],
  // },
];
export const ConfigurationNewTabs = [
  {
    // role: ["contractor_dashboard"],
    img: linkImg1,
    label: "Pollution Equipment",
    link: "/environment-management/configuration/equipment",
  },
  {
    img: linkImg2,
    label: "Pollution Equipment Type",
    link: "/environment-management/configuration/equipment_type",
    // role: ["contractor_dashboard"],
  },
  {
    label: "Land Reclamation",
    img: linkImg3,
    link: "/environment-management/configuration/land_reclamation",
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg4,
    link: "/environment-management/configuration/power_consumption",
    // role: ["contractor_dashboard"],
    label: "Power Consumption",
  },
  {
    img: linkImg5,
    label: "CAAQMS",
    link: "/environment-management/configuration/station/CAAQMS",
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg1,
    label: "CMPDIL",
    // role: ["contractor_dashboard"],
    link: "/environment-management/configuration/station/CMPDIL",
  },
  {
    img: linkImg2,
    label: "Water Quality",
    link: "/environment-management/configuration/station/water_quality",
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg3,
    label: "Noise Level",
    link: "/environment-management/configuration/station/noise_level",
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg4,
    // role: ["contractor_dashboard"],
    label: "Ground Water",
    link: "/environment-management/configuration/station/ground_water_CMPDIL",
  },
  {
    img: linkImg5,
    label: "CAAQMS Permissible",
    link: "/environment-management/configuration/permissible/CAAQMS_permissible",
    // role: ["contractor_dashboard"],
  },
  {
    label: "CMPDIL Permissible",
    link: "/environment-management/configuration/permissible/CMPDIL_permissible",
    img: linkImg1,
    // role: ["contractor_dashboard"],
  },
  {
    img: linkImg2,
    label: "Noise Permissible",
    // role: ["contractor_dashboard"],
    link: "/environment-management/configuration/permissible/noise_permissible",
  },
  {
    img: linkImg5,
    label: "Device",
    // role: ["contractor_dashboard"],
    link: "/environment-management/configuration/device",
  },
];

export const EventTabList = [
  {
    label: "Incident Info",
    tab: 1,
  },
  {
    label: "General Info",
    tab: 2,
  },
  {
    label: "Personnel Details",
    tab: 3,
  },
  {
    label: "Equipment Details",
    tab: 4,
  },
  {
    label: "Vehicle Details",
    tab: 5,
  },
  {
    label: "Evidence",
    tab: 6,
  },
];

export const uploadedFilesApis = Object.freeze({
  getAll: {
    api: getAllUploadedFiles,
  },
});

export const uploadedDataApis = Object.freeze({
  getAll: {
    api: getAllUploadedData,
  },
  delete: {
    api: deleteUploadedData,
  },
});

export const fields = [
  {
    name: "Subsidiary",
    selector: "name",
    // sortable: true,
    // maxWidth: "50px",
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "name",
    // sortable: true,
    // maxWidth: "50px",
    cell: (row) => row?.site?.name,
  },
  {
    name: "Date",
    sortable: true,
    selector: "createdAt",
    // minWidth: "200px",
    cell: (row) => (row?.createdAt ? DDMMYYYYFormat(row.createdAt) : ""),
  },
  {
    name: "Report Type",
    sortable: true,
    selector: "file_type",
    minWidth: "300px",
    cell: (row) =>
      row?.file_type
        ? reportTypeList.find((item) => item["value"] === row.file_type)?.label
        : "",
  },
  {
    name: "File Type",
    sortable: false,
    // maxWidth: "50px",
    selector: "org_filename",
    cell: (row) => (row?.org_filename ? fileExtension(row.org_filename) : ""),
  },
  {
    name: "Status",
    sortable: false,
    selector: "file_status",
    // minWidth: "150px",
    cell: (row) => (
      <>
        <span className="statusSec draft">
          {row?.file_status ? capitalizeName(row.file_status) : ""}
        </span>
      </>
    ),
    // cell: (row) => (row?.file_status ? capitalizeName(row.file_status) : ""),
    // cell: (row) => <span className="statusSec draft">Submitted</span>,
  },
];

export const envTableData = {
  air_quality_data: [
    {
      name: "Subsidiary",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Mine",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Air Quality Date",
      sortable: true,
      selector: "createdAt",
      // minWidth: "200px",
      cell: (row) =>
        row?.createdAt ? DDMMYYYYHHMMAFormat(row.air_quality_date) : "",
    },

    {
      name: "CO",
      sortable: true,
      selector: "co",
      // maxWidth: "50px",
      cell: (row) => row?.co,
    },
    {
      name: "NO",
      sortable: true,
      selector: "no",
      // maxWidth: "50px",
      cell: (row) => row?.no,
    },
    {
      name: "NO2",
      sortable: true,
      selector: "no2",
      // maxWidth: "50px",
      cell: (row) => row?.no2,
    },
    // {
    //   name:"PM 10 - Concentration",
    //   sortable:true,
    //   selector: "no",
    //   cell: (row) => row?.no,
    // }
  ],

  air_quality_data_emr: [
    {
      name: "Subsidiary",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Mine",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Analysis Date",
      sortable: true,
      selector: "analysis_date",
      // minWidth: "200px",
      cell: (row) =>
        row?.analysis_date ? DDMMYYYYHHMMAFormat(row.analysis_date) : "",
    },

    {
      name: "NOx Actual",
      sortable: true,
      selector: "nox_actual",
      // maxWidth: "50px",
      cell: (row) => row?.nox_actual,
    },
    {
      name: "NOx Limit",
      sortable: true,
      selector: "nox_limit",
      // maxWidth: "50px",
      cell: (row) => row?.nox_limit,
    },
    {
      name: "PM10 Actual",
      sortable: true,
      selector: "pm10_actual",
      // maxWidth: "50px",
      cell: (row) => row?.pm10_actual,
    },
  ],

  ground_water_level: [
    {
      name: "Subsidiary",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Mine",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Well No",
      sortable: true,
      selector: "well_no",
      // maxWidth: "50px",
      cell: (row) => row?.well_no,
    },
    {
      name: "location",
      sortable: true,
      selector: "location",
      // maxWidth: "50px",
      cell: (row) => row?.location,
    },
    {
      name: "Measurement Date",
      sortable: true,
      // maxWidth: "50px",
      selector: "measurement_date",
      cell: (row) =>
        row?.measurement_date ? DDMMYYYYHHMMAFormat(row.measurement_date) : "",
    },
    {
      name: "owner",
      sortable: true,
      selector: "owner",
      // maxWidth: "50px",
      cell: (row) => row?.owner,
    },
  ],

  noise_quality_data_emr: [
    {
      name: "Subsidiary",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Mine",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Analysis Date",
      sortable: true,
      selector: "analysis_date",
      // minWidth: "200px",
      cell: (row) =>
        row?.analysis_date ? DDMMYYYYHHMMAFormat(row.analysis_date) : "",
    },
    {
      name: "Noise Value",
      sortable: true,
      selector: "noise_value",
      // maxWidth: "50px",
      cell: (row) => row?.noise_value,
    },
    {
      name: "Station Category",
      sortable: true,
      selector: "station_category",
      // maxWidth: "50px",
      cell: (row) => row?.station_category,
    },
  ],
  overall_weather_data: [
    {
      name: "Subsidiary",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Mine",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Weather Date",
      sortable: true,
      selector: "weather_datetime",
      // minWidth: "200px",
      cell: (row) =>
        row?.weather_datetime ? DDMMYYYYHHMMAFormat(row.weather_datetime) : "",
    },
    {
      name: "Wind Direction",
      sortable: true,
      selector: "wind_direction",
      // maxWidth: "50px",
      cell: (row) => row?.wind_direction,
    },
    {
      name: "Wind Speed",
      sortable: true,
      selector: "wind_speed",
      // maxWidth: "50px",
      cell: (row) => row?.wind_speed,
    },
  ],
  plantation_data: [
    {
      name: "Subsidiary",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Mine",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Plantation Date",
      sortable: true,
      selector: "plantation_date",
      // minWidth: "200px",
      cell: (row) =>
        row?.plantation_date ? DDMMYYYYHHMMAFormat(row.plantation_date) : "",
    },
    {
      name: "External Dumps",
      sortable: true,
      selector: "external_dumps",
      // maxWidth: "50px",
      cell: (row) => row?.external_dumps,
    },
    {
      name: "Grass Bed",
      sortable: true,
      selector: "grass_bed",
      // maxWidth: "50px",
      cell: (row) => row?.grass_bed,
    },
  ],
  water_quality_data_emr: [
    {
      name: "Subsidiary",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Mine",
      selector: "name",
      // sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Analysis Date",
      sortable: true,
      selector: "analysis_date",
      // minWidth: "200px",
      cell: (row) =>
        row?.analysis_date ? DDMMYYYYHHMMAFormat(row.analysis_date) : "",
    },
    {
      name: "Station Category",
      sortable: true,
      selector: "station_category",
      // maxWidth: "50px",
      cell: (row) => row?.station_category,
    },
    {
      name: "Station Name",
      sortable: true,
      selector: "station_name",
      // maxWidth: "50px",
      cell: (row) => row?.station_name,
    },
  ],
  Ambient_Air_Quality_CAAQMS: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Air Quality Date",
      sortable: true,
      selector: "air_quality_date",
      // minWidth: "200px",
      cell: (row) =>
        row?.air_quality_date ? DDMMYYYYFormat(row.air_quality_date) : "",
    },
    {
      name: "No",
      sortable: true,
      selector: "no",
      // maxWidth: "50px",
      cell: (row) => row?.no,
    },
    {
      name: "No2",
      sortable: true,
      selector: "no2",
      // maxWidth: "50px",
      cell: (row) => row?.no2,
    },
    {
      name: "PM 10",
      sortable: true,
      selector: "pm10",
      // maxWidth: "50px",
      cell: (row) => row?.pm10,
    },
    {
      name: "PM 2.5",
      sortable: true,
      selector: "pm25",
      // maxWidth: "50px",
      cell: (row) => row?.pm25,
    },
    {
      name: "SO2",
      sortable: true,
      selector: "so2",
      // maxWidth: "50px",
      cell: (row) => row?.so2,
    },
    {
      name: "NOx",
      sortable: true,
      selector: "nox",
      // maxWidth: "50px",
      cell: (row) => row?.nox,
    },
    {
      name: "CO",
      sortable: true,
      selector: "CO",
      // maxWidth: "50px",
      cell: (row) => row?.co,
    },
  ],
  micro_meteorological_CAAQMS: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Date",
      sortable: true,
      selector: "sampling_date",
      // minWidth: "200px",
      cell: (row) =>
        row?.sampling_date ? DDMMYYYYFormat(row.sampling_date) : "",
    },
    {
      name: "Temperature",
      sortable: true,
      selector: "temperature",
      // maxWidth: "50px",
      cell: (row) => row?.temperature,
    },
    {
      name: "Relative Humidity",
      sortable: true,
      selector: "relative_humidity",
      // maxWidth: "50px",
      cell: (row) => row?.relative_humidity,
    },
    {
      name: "Solar Radiation",
      sortable: true,
      selector: "solar_radiation",
      // maxWidth: "50px",
      cell: (row) => row?.solar_radiation,
    },
    {
      name: "Wind Speed",
      sortable: true,
      selector: "wind_speed",
      // maxWidth: "50px",
      cell: (row) => row?.wind_speed,
    },
    {
      name: "Wind Direction",
      sortable: true,
      selector: "wind_direction",
      // maxWidth: "50px",
      cell: (row) => row?.wind_direction,
    },
    {
      name: "Rainfall",
      sortable: true,
      selector: "rainfall",
      // maxWidth: "50px",
      cell: (row) => row?.rainfall,
    },
  ],
  performance_report_of_pollution: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Date",
      sortable: true,
      selector: "sample_date",
      // minWidth: "200px",
      cell: (row) => (row?.sample_date ? DDMMYYYYFormat(row.sample_date) : ""),
    },
    {
      name: "Equipment",
      sortable: true,
      selector: "equipmentData",
      // maxWidth: "50px",
      cell: (row) => row?.equipmentData?.name,
    },
    // {
    //   name: "Type Of Equipment",
    //   sortable: true,
    //   selector: "equipmentTypeData",
    //   // maxWidth: "50px",
    //   cell: (row) => row?.equipmentTypeData?.name,
    // },
    {
      name: "Working Hours",
      sortable: true,
      selector: "working_hours",
      // maxWidth: "50px",
      cell: (row) => row?.working_hours,
    },
    {
      name: "Idle Hours",
      sortable: true,
      selector: "working_hours",
      // maxWidth: "50px",
      cell: (row) => row?.idle_hours,
    },
    {
      name: "Maintenance Hours",
      sortable: true,
      selector: "maintenance_hours",
      // maxWidth: "50px",
      cell: (row) => row?.maintenance_hours,
    },
    {
      name: "Breakdown Hours",
      sortable: true,
      selector: "breakdown_hours",
      // maxWidth: "50px",
      cell: (row) => row?.breakdown_hours,
    },
    // {
    //   name: "Remarks",
    //   sortable: true,
    //   selector: "remarks",
    //   // maxWidth: "50px",
    //   cell: (row) => row?.remarks,
    // },
  ],
  Ambient_Air_Quality_CMPDIL: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Date Of Sampling",
      sortable: true,
      selector: "air_quality_date",
      // minWidth: "200px",
      cell: (row) =>
        row?.air_quality_date ? DDMMYYYYFormat(row.air_quality_date) : "",
    },
    {
      name: "SPM - Concentration",
      sortable: true,
      selector: "spm",
      // maxWidth: "50px",
      cell: (row) => row?.spm,
    },
    {
      name: "PM 10 - Concentration",
      sortable: true,
      selector: "pm10",
      // maxWidth: "50px",
      cell: (row) => row?.pm10,
    },
    {
      name: "PM 2.5 - Concentration",
      sortable: true,
      selector: "pm25",
      // maxWidth: "50px",
      cell: (row) => row?.pm25,
    },
    {
      name: "SO2 - Concentration",
      sortable: true,
      selector: "so2",
      // maxWidth: "50px",
      cell: (row) => row?.so2,
    },
    {
      name: "NO2 - Concentration",
      sortable: true,
      selector: "no2",
      // maxWidth: "50px",
      cell: (row) => row?.no2,
    },
  ],
  Water_Quality_CMPDIL: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Date Of Sampling",
      sortable: true,
      selector: "sample_date",
      // minWidth: "200px",
      cell: (row) => (row?.sample_date ? DDMMYYYYFormat(row.sample_date) : ""),
    },
    {
      name: "PH",
      sortable: true,
      selector: "ph",
      // maxWidth: "50px",
      cell: (row) => row?.ph,
    },
    {
      name: "TSS",
      sortable: true,
      selector: "tss",
      // maxWidth: "50px",
      cell: (row) => row?.tss,
    },
    {
      name: "O&G",
      sortable: true,
      selector: "o_and_g",
      // maxWidth: "50px",
      cell: (row) => row?.o_and_g,
    },
    {
      name: "COD",
      sortable: true,
      selector: "cod",
      // maxWidth: "50px",
      cell: (row) => row?.cod,
    },
    {
      name: "BOD",
      sortable: true,
      selector: "bod",
      // maxWidth: "50px",
      cell: (row) => row?.bod,
    },
  ],
  Noise_Level_CMPDIL: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Station Name",
      selector: "stationData.name",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.stationData?.name,
    },
    {
      name: "Category of Station",
      selector: "stationData.category",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.stationData?.category,
    },
    {
      name: "Date Of Sampling",
      sortable: true,
      selector: "date_of_sampling",
      // minWidth: "200px",
      cell: (row) =>
        row?.date_of_sampling ? DDMMYYYYFormat(row.date_of_sampling) : "",
    },
    {
      name: "Day Time - Level",
      sortable: true,
      selector: "day_time",
      // maxWidth: "50px",
      cell: (row) => row?.day_time,
    },
    {
      name: "Night Time - Level",
      sortable: true,
      selector: "night_time",
      // maxWidth: "50px",
      cell: (row) => row?.night_time,
    },
  ],
  Ground_Water_level_CMPDIL: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Station Name",
      sortable: true,
      selector: "stationData",
      // maxWidth: "50px",
      cell: (row) => row?.stationData?.name,
    },
    {
      name: "Well Type",
      sortable: true,
      selector: "stationData",
      // minWidth: "200px",
      cell: (row) => row?.stationData?.category,
    },
    {
      name: "Quarter",
      sortable: true,
      selector: "quarter",
      // maxWidth: "50px",
      cell: (row) => row?.quarter,
    },
    {
      name: "Water Level",
      sortable: true,
      selector: "water_level",
      // maxWidth: "50px",
      cell: (row) => row?.water_level,
    },
    // {
    //   name: "Post Monsoon",
    //   sortable: true,
    //   selector: "post_monsoon",
    //   // maxWidth: "50px",
    //   cell: (row) => row?.post_monsoon,
    // },
  ],
  Land_Reclamation_Status_CMPD: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Total Lease Hold Area",
      sortable: true,
      selector: "total_leased_area",
      // minWidth: "200px",
      cell: (row) => row?.total_leased_area,
    },
    {
      name: "Year",
      sortable: true,
      selector: "year_of_sample",
      // maxWidth: "50px",
      cell: (row) => row?.year_of_sample,
    },
    {
      name: "Area under Active Mining",
      sortable: true,
      selector: "active_area",
      // maxWidth: "50px",
      cell: (row) => row?.active_area,
    },
    {
      name: "Area under Backfilling",
      sortable: true,
      selector: "backfilling_area",
      // maxWidth: "50px",
      cell: (row) => row?.backfilling_area,
    },
    // {
    //   name: "Plantation on Excavated / Backfilled Area",
    //   sortable: true,
    //   selector: "backbackfilled_area_plantation",
    //   // maxWidth: "50px",
    //   cell: (row) => row?.backfilled_area_plantation,
    // },
    {
      name: "Plantation on External Over Burden Dumps",
      sortable: true,
      selector: "burden_dumps_area_Plantation",
      // maxWidth: "50px",
      cell: (row) => row?.burden_dumps_area_Plantation,
    },
    // {
    //   name: "Social Forestry, Avenue",
    //   sortable: true,
    //   selector: "social_forestry",
    //   // maxWidth: "50px",
    //   cell: (row) => row?.social_forestry,
    // },
  ],
  Plantation_Project: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Year",
      sortable: true,
      selector: "year_of_sample",
      // maxWidth: "50px",
      cell: (row) => row?.year_of_sample,
    },
    {
      name: "Plantation on Internal Dumps",
      sortable: true,
      selector: "internal_dumps",
      // minWidth: "200px",
      cell: (row) => row?.internal_dumps,
    },

    {
      name: "Plantation on External Dumps",
      sortable: true,
      selector: "external_dumps",
      // maxWidth: "50px",
      cell: (row) => row?.external_dumps,
    },
    {
      name: "Plantation on Plain Land",
      sortable: true,
      selector: "plain_area",
      // maxWidth: "50px",
      cell: (row) => row?.plain_area,
    },
  ],
  power_consumption_report: [
    {
      name: "Subsidiary",
      selector: "Subsidiary",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.parentSite?.name,
    },
    {
      name: "Project",
      selector: "project",
      sortable: true,
      // maxWidth: "50px",
      cell: (row) => row?.site?.name,
    },
    {
      name: "Feeder",
      sortable: true,
      selector: "feeder",
      // minWidth: "200px",
      cell: (row) => row?.siteBasedMasterDropdownsData?.name,
    },
    {
      name: "Date  And  Time",
      sortable: true,
      selector: "date_time",
      // minWidth: "200px",
      cell: (row) => (row?.date_time ? DDMMYYYYHHMMAFormat(row.date_time) : ""),
    },
    {
      name: "KWH",
      sortable: true,
      selector: "kwh",
      // minWidth: "200px",
      cell: (row) => row?.kwh,
    },
    {
      name: "KVAH",
      sortable: true,
      selector: "kvh",
      // minWidth: "200px",
      cell: (row) => row?.kvh,
    },
    // {
    //   name: "Plantation on External Dumps",
    //   sortable: true,
    //   selector: "plantation_on_external_dumps",
    //   // maxWidth: "50px",
    //   cell: (row) => row?.area_under_active_mining,
    // },
  ],
};

export const viewEnvFields = {
  Ambient_Air_Quality_CAAQMS: [
    { label: "Station Name", value: "stationData.name" },
    { label: "Station Category", value: "stationData.category" },
    { label: "Date", value: "air_quality_date" },
    { label: "PM 10 - Concentration", value: "pm10" },
    { label: "PM 2.5 - Concentration", value: "pm25" },
    { label: "SO2 - Concentration", value: "so2" },
    { label: "NO - Concentration", value: "no" },
    { label: "NO2 - Concentration", value: "no2" },
    { label: "NOx - Concentration", value: "nox" },
    { label: "CO - Concentration", value: "co" },
  ],
  Ambient_Air_Quality_CMPDIL: [
    { label: "Station Code", value: "stationData.code" },
    { label: "Station Name", value: "stationData.name" },
    { label: "Category of Station", value: "stationData.category" },
    { label: "Date of Sampling", value: "air_quality_date" },
    { label: "SPM - Concentration", value: "spm" },
    { label: "PM 10 - Concentration", value: "pm10" },
    { label: "PM 2.5 - Concentration", value: "pm25" },
    { label: "SO2 - Concentration", value: "so2" },
    { label: "NO2 - Concentration", value: "no2" },
  ],
  Ground_Water_level_CMPDIL: [
    { label: "Station Name", value: "stationData.name" },
    { label: "Well Type", value: "stationData.category" },
    { label: "Code", value: "stationData.code" },
    { label: "Quarter", value: "quarter" },
    // { label: "Pre Monsoon", value: "pre_monsoon" },
    // { label: "Monsoon", value: "monsoon" },
    // { label: "Post Monsoon", value: "post_monsoon" },
    // { label: "Winter", value: "winter" },
    { label: "Water Level", value: "water_level" },
  ],
  Land_Reclamation_Status_CMPD: [
    {
      label: "Total Lease Hold Area",
      value: "total_leased_area",
    },
    { label: "Year", value: "year_of_sample" },
    { label: "Area under Active Mining", value: "active_area" },
    { label: "Area under Backfilling", value: "backfilling_area" },
    {
      label: "Plantation on Excavated / Backfilled Area",
      value: "backfilled_area_plantation",
    },
    {
      label: "Plantation on External Over Burden Dumps",
      value: "burden_dumps_area_Plantation",
    },
    { label: "Social Forestry, Avenue", value: "social_forestry" },
  ],
  micro_meteorological_CAAQMS: [
    { label: "Date", value: "sampling_date" },
    { label: "Temperature", value: "temperature" },
    { label: "Relative Humidity", value: "relative_humidity" },
    { label: "Solar Radiation", value: "solar_radiation" },
    { label: "Wind Speed", value: "wind_speed" },
    { label: "Wind Direction", value: "wind_direction" },
    { label: "Rainfall", value: "rainfall" },
  ],
  Noise_Level_CMPDIL: [
    { label: "Station Name", value: "stationData.name" },
    { label: "Station Code", value: "stationData.code" },
    { label: "Category of Station", value: "stationData.category" },
    { label: "Date of Sampling", value: "date_of_sampling" },
    { label: "Day Time - Level", value: "day_time" },
    { label: "Night Time - Level", value: "night_time" },
  ],
  Plantation_Project: [
    { label: "Year", value: "year_of_sample" },
    { label: "Plantation on External Dump", value: "external_dumps" },
    { label: "Plantation on Internal Dump", value: "internal_dumps" },
    { label: "Plantation on Plain Land", value: "plain_area" },
  ],
  Water_Quality_CMPDIL: [
    { label: "Station Code", value: "stationData.code" },
    { label: "Station Name", value: "stationData.name" },
    { label: "Date of Sampling", value: "sample_date" },
    { label: "TSS", value: "tss" },
    { label: "pH", value: "ph" },
    { label: "O&G", value: "o_and_g" },
    { label: "COD", value: "cod" },
    { label: "BOD", value: "bod" },
    { label: "Latitude", value: "stationData.lat" },
    { label: "Longitude", value: "stationData.log" },
  ],
  performance_report_of_pollution: [
    { label: "Equipment", value: "equipmentData.name" },
    { label: "Type of Equipment", value: "equipmentTypeData.name" },
    { label: "Date", value: "sample_date" },
    { label: "Working Hours", value: "working_hours" },
    { label: "Idle Hours", value: "idle_hours" },
    { label: "Maintenance Hours", value: "maintenance_hours" },
    { label: "Breakdown Hours", value: "breakdown_hours" },
    { label: "Remarks", value: "remarks" },
  ],
  power_consumption_report: [
    { label: "Feeder", value: "siteBasedMasterDropdownsData.name" },
    { label: "Date And Time", value: "date_time" },
    { label: "KWH", value: "kwh" },
    { label: "KVAH", value: "kvh" },
  ],
};

export const initialFormField = {
  cluster: "",
  type: "",
  air_quality_date: "",
  co: "",
  no: "",
  no2: "",
  nox: "",
  pm10: "",
  pm25: "",
  so2: "",
};
export const reportTypeListObj = {
  "Ambient-Air-Quality-CAAQMS": "Ambient_Air_Quality_CAAQMS",
  "Ambient-Air-Quality-CMPDIL": "Ambient_Air_Quality_CMPDIL",
  "Ground-Water-level-CMPDIL": "Ground_Water_level_CMPDIL",
  "Land-Reclamation-Status-CMPD": "Land_Reclamation_Status_CMPD",
  "Micro-Meteorological-CAAQMS": "micro_meteorological_CAAQMS",
  "Noise-Level-CMPDIL": "Noise_Level_CMPDIL",
  "Plantation-Project": "Plantation_Project",
  "Water-Quality-CMPDIL": "Water_Quality_CMPDIL",
  "Performance-Report-Of-Pollution": "performance_report_of_pollution",
  "Power-Consumption-Report": "power_consumption_report",
};
export const modelNames = {
  "Ambient-Air-Quality-CAAQMS": "env_ambient_air_quality_caaqms",
  "Ambient-Air-Quality-CMPDIL": "env_ambient_air_quality_cmpdil",
  "Ground-Water-level-CMPDIL": "env_ground_water_lavel_cmpdil",
  "Land-Reclamation-Status-CMPD": "env_land_reclamation_status_cpmd",
  "Micro-Meteorological-CAAQMS": "env_micro_meteorological_caaqms",
  "Noise-Level-CMPDIL": "env_noise_lavel_cmpdil",
  "Plantation-Project": "env_plantation_project",
  "Water-Quality-CMPDIL": "env_water_quality_cmpdil",
  "Performance-Report-Of-Pollution": "env_performance_report_of_pollu",
  "Power-Consumption-Report": "env_power_consumption_reports",
};
export const reportTypeListUrl = {
  Ambient_Air_Quality_CAAQMS: "Ambient-Air-Quality-CAAQMS",
  Ambient_Air_Quality_CMPDIL: "Ambient-Air-Quality-CMPDIL",
  Ground_Water_level_CMPDIL: "Ground-Water-level-CMPDIL",
  Land_Reclamation_Status_CMPD: "Land-Reclamation-Status-CMPD",
  micro_meteorological_CAAQMS: "Micro-Meteorological-CAAQMS",
  Noise_Level_CMPDIL: "Noise-Level-CMPDIL",
  Plantation_Project: "Plantation-Project",
  Water_Quality_CMPDIL: "Water-Quality-CMPDIL",
  performance_report_of_pollution: "Performance-Report-Of-Pollution",
  power_consumption_report: "Power-Consumption-Report",
};

export const reportTypeListTableLabel = {
  "Ambient-Air-Quality-CAAQMS": "Ambient Air Quality CAAQMS",
  "Ambient-Air-Quality-CMPDIL": "Ambient Air Quality CMPDIL",
  "Ground-Water-level-CMPDIL": "Ground Water level",
  "Land-Reclamation-Status-CMPD": "Land Reclamation Status",
  "Micro-Meteorological-CAAQMS": "micro meteorological",
  "Noise-Level-CMPDIL": "Noise Level",
  "Plantation-Project": "Plantation Project",
  "Water-Quality-CMPDIL": "Water Quality",
  "Performance-Report-Of-Pollution": "performance report of pollution",
  "Power-Consumption-Report": "Power Consumption Report",
};

export const categoryTypeObjByUrl = {
  "Ambient-Air-Quality-CAAQMS": "CAAQMS",
  "Ambient-Air-Quality-CMPDIL": "CMPDIL",
  "Micro-Meteorological-CAAQMS": "CAAQMS",
  "Noise-Level-CMPDIL": "noise_level",
};

export const reportTypeList = [
  { value: "air_quality_data", label: "Air Quality" },
  { value: "air_quality_data_emr", label: "Air Quality EMR" },
  { value: "ground_water_level", label: "Ground Water" },
  { value: "noise_quality_data_emr", label: "Noise" },
  { value: "overall_weather_data", label: "Weather" },
  { value: "plantation_data", label: "Plantation" },
  { value: "water_quality_data_emr", label: "Water Quality" },

  { value: "Ambient_Air_Quality_CAAQMS", label: "Ambient Air Quality CAAQMS" },
  { value: "Ambient_Air_Quality_CMPDIL", label: "Ambient Air Quality CMPDIL" },
  { value: "Ground_Water_level_CMPDIL", label: "Ground Water level CMPDIL" },
  {
    value: "Land_Reclamation_Status_CMPD",
    label: "Land Reclamation Status CMPD",
  },
  {
    value: "micro_meteorological_CAAQMS",
    label: "Micro Meteorological CAAQMS",
  },
  { value: "Noise_Level_CMPDIL", label: "Noise Level CMPDIL" },
  { value: "Plantation_Project", label: "Plantation Project" },
  { value: "Water_Quality_CMPDIL", label: "Water Quality CMPDIL" },
  {
    value: "performance_report_of_pollution",
    label: "Performance Report Of Pollution",
  },
  {
    value: "power_consumption_report",
    label: "Power Consumption Report",
  },
  // { value: "power_consumption", label: "Power Consumption" },
];

export const initialFilters = {
  startDate: "",
  endDate: "",
  file_type: "",
};

export const timeOptions = [
  { value: "day", label: "Day" },
  { value: "night", label: "Night" },
];

export const formLabel = {
  "Ambient-Air-Quality-CAAQMS": {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_category: "Station Category",
    air_quality_date: "Date",
    pm10: "PM 10 - Concentration",
    pm25: "PM 2.5 - Concentration",
    so2: "SO2 - Concentration",
    no: "NO - Concentration",
    nox: "NOx - Concentration",
    no2: "NO2 - Concentration",
    co: "CO - Concentration",
  },
  "Micro-Meteorological-CAAQMS": {
    project: "Project",
    subsidiary: "Subsidiary",
    station_name: "Station Name",
    station_category: "Station Category",
    date: "Date",
    temperature: "Temperature",
    relative_humidity: "Relative Humidity",
    solar_radiation: "Solar Radiation",
    wind_speed: "Wind Speed",
    wind_direction: "Wind Direction",
    rainfall: "Rainfall",
  },
  "Performance-Report-Of-Pollution": {
    subsidiary: "Subsidiary",
    project: "Project",
    equipment: "Equipment",
    type_of_equipment: "Type of Equipment",
    date: "Date",
    working_hours: "Working Hours",
    idle_hours: "Idle Hours",
    maintenance_hours: "Maintenance Hours",
    breakdown_hours: "Breakdown Hours",
    remarks: "Remarks",
  },
  "Ambient-Air-Quality-CMPDIL": {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_code: "Station Code",
    station_category: "Category of Station",
    date_of_sampling: "Date of Sampling",
    spm: "SPM - Concentration",
    pm10: "PM 10 - Concentration",
    pm25: "PM 2.5 - Concentration",
    so2: "SO2 - Concentration",
    no2: "NO2 - Concentration",
  },
  "Water-Quality-CMPDIL": {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_code: "Station Code",
    date_of_sampling: "Date of Sampling",
    tss: "TSS",
    ph: "pH",
    og: "O&G",
    cod: "COD",
    bod: "BOD",
    latitude: "Latitude",
    longitude: "Longitude",
  },
  "Noise-Level-CMPDIL": {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_code: "Station Code",
    station_category: "Category of Station",
    date_of_sampling: "Date of Sampling",
    day_time_level: "Day Time - Level",
    night_time_level: "Night Time - Level",
  },
  "Ground-Water-level-CMPDIL": {
    subsidiary: "Subsidiary",
    project: "Project",
    well_type: "Well Type",
    code: "Code",
    quarter: "Quarter",
    pre_monsoon: "Pre-Monsoon",
    monsoon: "Monsoon",
    post_monsoon: "Post Monsoon",
    winter: "Winter",
    water_level: "Water Level",
  },
  "Land-Reclamation-Status-CMPD": {
    subsidiary: "Subsidiary",
    project: "Project",
    total_leased_area: "Total Lease Hold Area",
    year: "Year",
    area_under_active_mining: "Area under Active Mining",
    plantation_on_excavated: "Plantation on Excavated / Backfilled Area",
    plantation_on_external_over_burden_dumps:
      "Plantation on External Over Burden Dumps",
    social_forestry: "Social Forestry, Avenue",
  },
  "Plantation-Project": {
    subsidiary: "Subsidiary",
    project: "Project",
    year: "Year",
    plantation_on_external_dumps: "Plantation on External Dumps",
    plantation_on_internal_dumps: "Plantation on Internal Dumps",
    plantation_on_plain_land: "Plantation on Plain Land",
  },
  "Power-Consumption-Report": {
    subsidiary: "Subsidiary",
    project: "Project",
    feeder: "Feeder",
    date: "Date",
    "7kwh": "7kwh",
    "7kvah": "7kvah",
    "8kwh": "8kwh",
    "8kvah": "8kvah",
    "9kwh": "9kwh",
    "9kvah": "9kvah",
    "10kwh": "10kwh",
    "10kvah": "10kvah",
    "11kwh": "11kwh",
    "11kvah": "11kvah",
    "12kwh": "12kwh",
    "12kvah": "12kvah",
    "13kwh": "13kwh",
    "13kvah": "13kvah",
    "14kwh": "14kwh",
    "14kvah": "14kvah",
    "15kwh": "15kwh",
    "15kvah": "15kvah",
    "16kwh": "16kwh",
    "16kvah": "16kvah",
    "17kwh": "17kwh",
    "17kvah": "17kvah",
    "18kwh": "18kwh",
    "18kvah": "18kvah",
    "19kwh": "19kwh",
    "19kvah": "19kvah",
    "20kwh": "20kwh",
    "20kvah": "20kvah",
    "21kwh": "21kwh",
    "21kvah": "21kvah",
    "22kwh": "22kwh",
    "22kvah": "22kvah",
    "23kwh": "23kwh",
    "23kvah": "23kvah",
    "24kwh": "24kwh",
    "24kvah": "24kvah",
    "1kwh": "1kwh",
    "1kvah": "1kvah",
    "2kwh": "2kwh",
    "2kvah": "2kvah",
    "3kwh": "3kwh",
    "3kvah": "3kvah",
    "4kwh": "4kwh",
    "4kvah": "4kvah",
    "5kwh": "5kwh",
    "5kvah": "5kvah",
    "6kwh": "6kwh",
    "6kvah": "6kvah",
  },
};

export const newFormLabel = {
  "Ambient-Air-Quality-CAAQMS": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    name: "Station Name",
    category: "Station Category",
    // station_name: "Station Name",
    // station_category: "Station Category",
    air_quality_date: "Date",
    pm10: "PM 10 - Concentration",
    pm25: "PM 2.5 - Concentration",
    so2: "SO2 - Concentration",
    no: "NO - Concentration",
    nox: "NOx - Concentration",
    no2: "NO2 - Concentration",
    co: "CO - Concentration",
  },
  "Micro-Meteorological-CAAQMS": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    name: "Station Name",
    category: "Station Category",
    // station_name: "Station Name",
    // station_category: "Station Category",
    sampling_date: "Date",
    temperature: "Temperature",
    relative_humidity: "Relative Humidity",
    solar_radiation: "Solar Radiation",
    wind_speed: "Wind Speed",
    wind_direction: "Wind Direction",
    rainfall: "Rainfall",
  },
  "Performance-Report-Of-Pollution": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    equipment: "Equipment",
    type_of_equipment: "Type of Equipment",
    sample_date: "Date",
    working_hours: "Working Hours",
    idle_hours: "Idle Hours",
    maintenance_hours: "Maintenance Hours",
    breakdown_hours: "Breakdown Hours",
    remarks: "Remarks",
  },
  "Ambient-Air-Quality-CMPDIL": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    name: "Station Name",
    category: "Category Of Station",
    code: "Station Code",
    // station_name: "Station Name",
    // station_code: "Station Code",
    // station_category: "Category of Station",
    air_quality_date: "Date of Sampling",
    spm: "SPM - Concentration",
    pm10: "PM 10 - Concentration",
    pm25: "PM 2.5 - Concentration",
    so2: "SO2 - Concentration",
    no2: "NO2 - Concentration",
  },
  "Water-Quality-CMPDIL": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    name: "Station Name",
    code: "code",
    // station_name: "Station Name",
    // station_code: "Station Code",
    sample_date: "Date of Sampling",
    tss: "TSS",
    ph: "pH",
    o_and_g: "O&G",
    cod: "COD",
    bod: "BOD",
    latitude: "Latitude",
    longitude: "Longitude",
  },
  "Noise-Level-CMPDIL": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    name: "Station Name",
    category: "Category Of Station",
    code: "Station Code",
    // station_name: "Station Name",
    // station_code: "Station Code",
    // station_category: "Category of Station",
    date_of_sampling: "Date of Sampling",
    day_time: "Day Time - Level",
    night_time: "Night Time - Level",
  },
  "Ground-Water-level-CMPDIL": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    category: "Well Type",
    code: "Code",
    quarter: "Quarter",
    // pre_monsoon: "Pre-Monsoon",
    // monsoon: "Monsoon",
    // post_monsoon: "Post Monsoon",
    // winter: "Winter",
    water_level: "Water Level",
  },
  "Land-Reclamation-Status-CMPD": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    total_leased_area: "Total Lease Hold Area",
    year_of_sample: "Year",
    active_area: "Area under Active Mining",
    backfilled_area_plantation: "Plantation on Excavated / Backfilled Area",
    burden_dumps_area_Plantation: "Plantation on External Over Burden Dumps",
    social_forestry: "Social Forestry, Avenue",
  },
  "Plantation-Project": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    year_of_sample: "Year",
    external_dumps: "Plantation on External Dumps",
    internal_dumps: "Plantation on Internal Dumps",
    plain_area: "Plantation on Plain Land",
  },
  "Power-Consumption-Report": {
    // subsidiary: "Subsidiary",
    // project: "Project",
    feeder: "Feeder",
    date_time: "Date",
    kvh: "KWH",
    kwh: "KVAH",
  },
};

export const initialForm = {
  fileType: "",
  mine: "",
  subsidiary: "",
};

export const initialFormWell = {
  subsidiary: "",
  mine: "",
  well: "",
};

export const reasonListApis = Object.freeze({
  getAll: {
    api: getAllMasterDropdownReson,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});
export const reasonListApisStationCategoryAirQualityEmr = Object.freeze({
  getAll: {
    api: getAllMasterDropdownstationCategoryAirQualityEmr,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});
export const PollutionControlMachineApis = Object.freeze({
  getAll: {
    api: getAllPollutionControlMachineApis,
  },
  delete: {
    api: deleteFuelConfigWithSiteApis,
  },
});

export const FuelConfigEquipmentApis = Object.freeze({
  getAll: {
    api: getAllEquipmentApis,
  },
  delete: {
    api: deleteFuelConfigWithoutSiteApis,
  },
});

export const FuelConfigEquipmentTypeApis = Object.freeze({
  getAll: {
    api: getAllEquipmentTypeApis,
  },
  delete: {
    api: deleteFuelConfigWithoutSiteApis,
  },
});

export const FuelConfigPollutionApis = Object.freeze({
  getAll: {
    api: getAllPollutionApis,
  },
  delete: {
    api: deleteFuelConfigWithoutSiteApis,
  },
});

export const FuelConfigLandReclamationApis = Object.freeze({
  getAll: {
    api: getAllLandReclamationApis,
  },
  delete: {
    api: deleteFuelConfigWithSiteApis,
  },
});

export const FuelConfigPowerConsumptionApis = Object.freeze({
  getAll: {
    api: getAllPowerConsumptionApis,
  },
  delete: {
    api: deleteFuelConfigWithSiteApis,
  },
});

export const sectionApis = Object.freeze({
  getAll: {
    api: getAllSection,
  },
  delete: {
    api: deleteFuelConfigWithSiteApis,
  },
});

export const reasonListApisStationCategoryNoise = Object.freeze({
  getAll: {
    api: getAllMasterDropdownStationCategoryNoise,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});

export const reasonListApisStationTypeWater = Object.freeze({
  getAll: {
    api: getAllMasterDropdownStationTypeWater,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});

export const coalQualitySapApis = {
  getAll: {
    api: getCoalQualitySapListWithPagination,
  },
  getById: {
    api: exportCoalQualitySapById,
  },
  delete: {
    api: deleteCoalQualitySap,
  },
};

export const EnvSapApis = {
  getAll: {
    api: getEnvSapListWithPagination,
  },
  getById: {
    api: exportEnvSapById,
  },
  delete: {
    api: deleteEnvSap,
  },
};

export const coalQualitySapColumnFields = [
  {
    name: "Sample No",
    selector: "sample_no",
    sortable: true,
    isShowMobile: true,
    cell: (row) => {
      console.log("rowrowrowrowrowrow", row);
      return row?.sample_no;
    },
  },
  {
    name: "Mode Of Dispatch",
    selector: "mode_of_dispatch",
    sortable: true,
    cell: (row) => row?.mode_of_dispatch,
  },
  {
    name: "Subsidiary",
    selector: "subsidiary",
    sortable: true,
    cell: (row) => row?.subsidiary,
  },
  {
    name: "Area",
    selector: "area",
    sortable: true,
    cell: (row) => row?.area,
  },
  {
    name: "Plant",
    selector: "plant",
    sortable: true,
    cell: (row) => row?.plant,
  },
  {
    name: "Material",
    selector: "material",
    sortable: true,
    cell: (row) => row?.material,
  },
  {
    name: "Material Description",
    selector: "material_description",
    sortable: true,
    cell: (row) => row?.material_description,
  },
  {
    name: "Agency",
    selector: "agency",
    sortable: true,
    cell: (row) => row?.agency,
  },
  {
    name: "Buyer Code",
    selector: "buyer_code",
    sortable: true,
    cell: (row) => row?.buyer_code,
  },
];

export const EnvSapColumnFields = [
  {
    name: "Subsidiary",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Project",
    selector: "site",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  {
    name: "Device Name",
    selector: "devices",
    sortable: true,
    isShowMobile: true,
    cell: (row) => {
      return row?.devices?.deviceName;
    },
  },
  {
    name: "Mine Name",
    selector: "mineName",
    sortable: true,
    cell: (row) => row?.mineName,
  },
  {
    name: "Area Name",
    selector: "areaName",
    sortable: true,
    cell: (row) => row?.areaName,
  },
  {
    name: "Company Name",
    selector: "companyName",
    sortable: true,
    cell: (row) => row?.companyName,
  },
  {
    name: "Creation Date",
    selector: "creationDate",
    sortable: true,
    cell: (row) => (row?.creationDate ? DDMMYYYYFormat(row?.creationDate) : ""),
  },
  // {
  //   name: "PM25",
  //   selector: "pm25",
  //   sortable: true,
  //   cell: (row) => row?.pm25,
  // },
  // {
  //   name: "PM10",
  //   selector: "pm10",
  //   sortable: true,
  //   cell: (row) => row?.pm10,
  // },
  // {
  //   name: "SO2",
  //   selector: "so2",
  //   sortable: true,
  //   cell: (row) => row?.so2,
  // },
];

export const exportCoalQualitySap = async (filter) => {
  try {
    let url = "/environment-management/coal-quality-sap-export";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const exportEnvSap = async (filter) => {
  try {
    let url = "/environment-management/env-sap-export";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const createFuelConfigWithoutSite = async (data) => {
  let url = "/master-data/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateFuelConfigSite = async (id, data) => {
  let url = "/site-based-master-data/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const updateEnvStationConfig = async (id, data) => {
  let url = "/env-station-master-data/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateEnvPermissibleConfig = async (id, data) => {
  let url = "/env-permissible-master-data/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateFuelConfigWithoutSite = async (id, data) => {
  let url = "/master-data/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const createFuelConfigSite = async (data) => {
  let url = "/site-based-master-data/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const createStaationConfig = async (data) => {
  let url = "env-station-master-data/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const createPermissibleConfig = async (data) => {
  let url = "env-permissible-master-data/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const reasonListApisStationCategoryWater = Object.freeze({
  getAll: {
    api: getAllMasterDropdownstationCategoryWater,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});

// station api
export const EnvConfigcaaqms = Object.freeze({
  getAll: {
    api: getAllEnvConfigcaaqms,
  },
  delete: {
    api: deleteEnvConfigStation,
  },
});

export const EnvConfigApiscmpdil = Object.freeze({
  getAll: {
    api: getAllEnvConfigcmpdil,
  },
  delete: {
    api: deleteEnvConfigStation,
  },
});

export const EnvConfigonApisWaterQuality = Object.freeze({
  getAll: {
    api: getAllEnvConfigWaterQuality,
  },
  delete: {
    api: deleteEnvConfigStation,
  },
});

export const EnvConfigionApisNoiseLevel = Object.freeze({
  getAll: {
    api: getAllEnvConfigNoiseLevel,
  },
  delete: {
    api: deleteEnvConfigStation,
  },
});

export const EnvConfigionApisGroundWater = Object.freeze({
  getAll: {
    api: getAllEnvConfigGroundWater,
  },
  delete: {
    api: deleteEnvConfigStation,
  },
});

//permissible API
export const EnvConfigcaaqmsPermissible = Object.freeze({
  getAll: {
    api: getAllEnvConfigcaaqmsPermissible,
  },
  delete: {
    api: deletePermissibleMasterDropdown,
  },
});

export const EnvConfigcmpdilPermissible = Object.freeze({
  getAll: {
    api: getAllEnvConfigcmpdilPermissible,
  },
  delete: {
    api: deletePermissibleMasterDropdown,
  },
});

export const EnvConfigNoisePermissiblePermissible = Object.freeze({
  getAll: {
    api: getAllEnvConfigNoisePermissible,
  },
  delete: {
    api: deletePermissibleMasterDropdown,
  },
});

export const listColumnFields = [
  {
    name: "Well",
    selector: "well",
    cell: (row) => row?.well,
  },
  {
    name: "Subsidiary",
    selector: "siteData",
    // sortable: true,
    cell: (row) => row?.siteData?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "siteData",
    // sortable: true,
    cell: (row) => row?.siteData?.name,
  },
];

export const reasonListColumnFields = [
  {
    name: "Name",
    selector: "name",
    // sortable: true,
    cell: (row) => row?.name,
  },
  {
    name: "Type",
    selector: "type",
    // sortable: true,
    cell: (row) => row?.type,
  },
];

export const siteBasedCollum = [
  {
    name: "Subsidiary",
    selector: "site",
    sortable: true,
    cell: (row) => row?.siteData?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "site",
    sortable: true,
    cell: (row) => row?.siteData?.name,
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
    cell: (row) => row?.name,
  },
];

export const statioinBasedCollumCAAQMS = [
  {
    selector: "site",
    sortable: true,
    cell: (row) => row?.siteData?.name,
    name: "Mine",
  },
  {
    name: "Subsidiary",
    sortable: true,
    selector: "site",
    cell: (row) => row?.siteData?.parentSite?.name,
  },
  {
    cell: (row) => row?.category,
    name: "Category",
    selector: "category",
    sortable: true,
  },
  {
    name: "Station Name",
    selector: "name",
    cell: (row) => row?.name,
    sortable: true,
  },
  {
    name: "Latitude",
    cell: (row) => row?.lat,
    selector: "lat",
    sortable: true,
  },
  {
    selector: "log",
    name: "Longitude",
    sortable: true,
    cell: (row) => row?.log,
  },
];

export const statioinBasedCollumCMPDIL = [
  {
    cell: (row) => row?.siteData?.name,
    name: "Mine",
    selector: "site",
    sortable: true,
  },
  {
    sortable: true,
    name: "Subsidiary",
    selector: "site",
    cell: (row) => row?.siteData?.parentSite?.name,
  },
  {
    selector: "category",
    name: "Category",
    sortable: true,
    cell: (row) => row?.category,
  },
  {
    selector: "name",
    sortable: true,
    cell: (row) => row?.name,
    name: "Station Name",
  },
  {
    name: "Station Code",
    selector: "code",
    cell: (row) => row?.code,
    sortable: true,
  },
  {
    name: "Latitude",
    selector: "lat",
    sortable: true,
    cell: (row) => row?.lat,
  },
  {
    cell: (row) => row?.log,
    selector: "log",
    sortable: true,
    name: "Longitude",
  },
];

export const statioinBasedCollumWaterQuality = [
  {
    name: "Project",
    selector: "site",
    sortable: true,
    cell: (row) => row?.siteData?.name,
  },
  {
    name: "Subsidiary",
    selector: "site",
    sortable: true,
    cell: (row) => row?.siteData?.parentSite?.name,
  },
  {
    name: "Station Name",
    selector: "name",
    cell: (row) => row?.name,
    sortable: true,
  },
  {
    name: "Station Code",
    sortable: true,
    cell: (row) => row?.code,
    selector: "code",
  },
  {
    cell: (row) => row?.lat,
    name: "Latitude",
    selector: "lat",
    sortable: true,
  },
  {
    name: "Longitude",
    selector: "log",
    sortable: true,
    cell: (row) => row?.log,
  },
];

export const statioinBasedCollumNoiseLevel = [
  {
    name: "Project",
    selector: "site",
    sortable: true,
    cell: (row) => row?.siteData?.name || "",
  },
  {
    name: "Subsidiary",
    selector: "site",
    sortable: true,
    cell: (row) => row?.siteData?.parentSite?.name || "",
  },
  {
    name: "Station Category",
    selector: "category",
    sortable: true,
    cell: (row) => row?.category,
  },
  {
    name: "Station Name",
    selector: "name",
    cell: (row) => row?.name,
    sortable: true,
  },
  {
    name: "Station Code",
    selector: "code",
    sortable: true,
    cell: (row) => row?.code,
  },
  {
    name: "Latitude",
    sortable: true,
    selector: "lat",
    cell: (row) => row?.lat,
  },
  {
    name: "Longitude",
    sortable: true,
    cell: (row) => row?.log,
    selector: "log",
  },
];

export const statioinBasedCollumGroundWater = [
  {
    name: "Project",
    selector: "site",
    sortable: true,
    cell: (row) => row?.siteData?.name,
  },
  {
    name: "Subsidiary",
    selector: "site",
    sortable: true,
    cell: (row) => row?.siteData?.parentSite?.name || "",
  },
  {
    cell: (row) => row?.category,
    name: "Well Category",
    selector: "category",
    sortable: true,
  },
  {
    selector: "name",
    sortable: true,
    name: "Village Location",
    cell: (row) => row?.name,
  },
  {
    name: "Code",
    selector: "code",
    cell: (row) => row?.code,
    sortable: true,
  },
];

export const permissibleCAACollum = [
  {
    sortable: true,
    name: "Category",
    selector: "category",
    cell: (row) => row?.category,
  },
  {
    cell: (row) => row?.pm10,
    name: "PM 10",
    selector: "pm10",
    sortable: true,
  },
  {
    selector: "pm25",
    name: "PM 2.5",
    sortable: true,
    cell: (row) => row?.pm25,
  },
  {
    name: "SO2",
    sortable: true,
    selector: "so2",
    cell: (row) => row?.so2,
  },
  {
    name: "NO",
    sortable: true,
    cell: (row) => row?.no,
    selector: "no",
  },

  {
    name: "CO",
    selector: "co",
    sortable: true,
    cell: (row) => row?.co,
  },
  {
    name: "NOX",
    selector: "nox",
    sortable: true,
    cell: (row) => row?.nox,
  },
];

export const permissibleCMPCollum = [
  {
    name: "Category",
    selector: "category",
    sortable: true,
    cell: (row) => row?.category,
  },
  {
    name: "PM 10",
    selector: "pm10",
    sortable: true,
    cell: (row) => row?.pm10,
  },
  {
    name: "PM 2.5",
    selector: "pm25",
    sortable: true,
    cell: (row) => row?.pm25,
  },
  {
    name: "SO2",
    selector: "so2",
    sortable: true,
    cell: (row) => row?.so2,
  },

  {
    name: "NO2",
    selector: "no2",
    sortable: true,
    cell: (row) => row?.no2,
  },
  {
    name: "SPM",
    selector: "spm",
    sortable: true,
    cell: (row) => row?.spm,
  },
];

export const permissibleNoiseCollum = [
  {
    name: "Category",
    selector: "category",
    sortable: true,
    cell: (row) => row?.category,
  },

  {
    name: "Decibel day",
    selector: "dba_day",
    sortable: true,
    cell: (row) => row?.dba_day,
  },
  {
    name: "Decibel night",
    selector: "dba_night",
    sortable: true,
    cell: (row) => row?.dba_night,
  },
];

export const nonSiteBasedCollum = [
  {
    name: "Name",
    selector: "name",
    // sortable: true,
    cell: (row) => row?.name,
  },
];

export const PollutionControlFilterForm = {
  startDate: "",
  endDate: "",
  contractor_id: "",
  status: "",
};

export const getFuelConfigSiteById = async (id) => {
  try {
    const response = await RestMethod.GET("/master-data/" + id);
    return response?.data?.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getFuelConfigWithoutSiteById = async (id) => {
  try {
    const response = await RestMethod.GET("/site-based-master-data/" + id);
    return response?.data?.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getEnvConfigStationById = async (id) => {
  try {
    const response = await RestMethod.GET("/env-station-master-data/" + id);
    return response?.data?.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getEnvConfigPermissibleById = async (id) => {
  try {
    const response = await RestMethod.GET("/env-permissible-master-data/" + id);
    return response?.data?.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const extensions = ["xlsx", "xls"];

export const exportUploadedFile = async (filter) => {
  try {
    let url = "/environment-management/uploaded-files/export";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const exportUploadedFileSap = async (filter) => {
  try {
    let url = "/environment-management/uploaded-files-sap/export";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const exportUploadedFileByType = async (filter) => {
  try {
    let url = "/environment-management/uploaded-files/export-by-file-type";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const getAllWell = async (filter) => {
  let url = "/ground-water-well";
  if (filter) url += "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.well,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const createWell = async (data) => {
  let url = "/ground-water-well/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const UpdateWell = async (data, id) => {
  let url = "/ground-water-well/update/" + id;
  try {
    const response = await RestMethod.PUT(url, data);

    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const WellListApi = Object.freeze({
  getAll: {
    api: getAllWell,
  },
});

export const stationConfigForm = {
  name: "",
  code: "",
  category: "",
  lat: "",
  log: "",
  siteId: "",
};

export const EnvConfigStationRule = [
  {
    field_name: "name",
    label: "Station Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "code",
    type: "string",
    label: "Station Code",
    isRequired: true,
  },

  {
    field_name: "category",
    label: "Category",
    type: "string",
    isRequired: true,
  },

  {
    label: "Longitude",
    type: "number",
    field_name: "log",
    isRequired: true,
  },
  {
    field_name: "siteId",
    label: "Site",
    type: "number",
    isRequired: true,
  },
  {
    field_name: "lat",
    label: "Latitude",
    type: "number",
    isRequired: true,
  },
  {
    field_name: "type",
    label: "type",
    isRequired: true,
    type: "string",
  },
];

export const EnvConfigPermissibleCaaRule = [
  {
    field_name: "category",
    label: "Category",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "pm10",
    label: "PM 10",
    type: "integer",
    isRequired: true,
  },

  {
    field_name: "pm25",
    label: "PM 2.5",
    type: "integer",
    isRequired: true,
  },
  {
    field_name: "so2",
    label: "SO2",
    type: "integer",
    isRequired: true,
  },
  {
    field_name: "no",
    label: "NO",
    type: "integer",
    isRequired: true,
  },
  {
    field_name: "co",
    label: "CO",
    type: "integer",
    isRequired: true,
  },
  {
    field_name: "nox",
    label: "NOX",
    type: "integer",
    isRequired: true,
  },

  {
    field_name: "type",
    label: "type",
    type: "string",
    isRequired: true,
  },
];

export const EnvConfigPermissibleCmpRule = [
  {
    field_name: "category",
    label: "Category",
    type: "string",
    isRequired: true,
  },

  {
    field_name: "pm25",
    label: "PM 2.5",
    type: "integer",
    isRequired: true,
  },
  {
    field_name: "pm10",
    label: "PM 10",
    type: "integer",
    isRequired: true,
  },
  {
    label: "SO2",
    type: "integer",
    field_name: "so2",
    isRequired: true,
  },

  {
    isRequired: true,
    field_name: "no2",
    label: "NO2",
    type: "integer",
  },
  {
    field_name: "type",
    label: "type",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "spm",
    label: "SPM",
    isRequired: true,
    type: "integer",
  },
];

export const EnvConfigPermissibleNoiseRule = [
  {
    isRequired: true,
    field_name: "dba_day",
    label: "Decibel day",
    type: "integer",
  },
  {
    field_name: "category",
    label: "Category",
    type: "string",
    isRequired: true,
  },
  {
    label: "Decibel day",
    type: "integer",
    field_name: "dba_night",
    isRequired: true,
  },
  {
    isRequired: true,
    type: "string",
    field_name: "type",
    label: "type",
  },
];
