import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "react-bootstrap";
import { PARSE_IMG_URL } from "../../_helpers/commonHelper";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import { IS_MOBILE } from "../../_helpers/helper";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfPopup = ({ fileName, hideDownload }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDownload = () => {
    const pdfUrl = PARSE_IMG_URL(URL_CONFIG.DEV_URL, fileName);
    window.open(pdfUrl, "_blank");
  };

  return (
    <div className="pdf-popup">
      <div className="pdf-buttons">
        {!hideDownload && (
          <Button onClick={handleDownload} className="mx-2">
            Download
          </Button>
        )}
        {/* <Button onClick={handlePrint} className='mx-2'>Print</Button> */}
        {currentPage > 1 && (
          <Button onClick={handlePreviousPage} className="mx-2">
            Previous Page
          </Button>
        )}
        {currentPage < numPages && (
          <Button onClick={handleNextPage}>Next Page</Button>
        )}
      </div>
      <div
        className="pdf-container "
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <Document
          file={PARSE_IMG_URL(URL_CONFIG.DEV_URL, fileName)}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={currentPage}
            width={IS_MOBILE() ? 600 : 850}
            height={900}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      </div>
    </div>
  );
};

export default PdfPopup;
