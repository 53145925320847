import { TiInfoLarge, TiInfoOutline } from "react-icons/ti";
import { FiSettings } from "react-icons/fi";
import {
  getMineById,
  getAllMine,
} from "../../../../services/_safetyManagement/incidentServices";
export const initialForm = {
  subsidiary: "",
  name: "",
  siteIdCode: "",
  lin: "",
  ownerName: "",
  mineral: "",
  mineVillageAreaRoad: "",
  minePostOffice: "",
  mineSubDivision: "",
  mineDistrict: "",
  mineState: "",
  minePinCode: "",
  minePoliceStation: "",
  mineRailwayStation: "",
  ownerVillageAreaRoad: "",
  ownerPostOffice: "",
  ownerSubDivision: "",
  ownerDistrict: "",
  ownerState: "",
  ownerPinCode: "",
  ownerPoliceStation: "",
  ownerRailwayStation: "",
  ownerEmail: "",
  ownerTelephone: "",
  ownerFax: "",
  ownerMobile: "",
  nameAgent: "",
  postalAddressAgent: "",
  districtAgent: "",
  stateAgent: "",
  pinAgent: "",
  mobileAgent: "",
  teleAgent: "",
  faxAgent: "",
  emailAgent: "",
  nameManager: "",
  postalAddressManager: "",
  districtManager: "",
  stateManager: "",
  pinManager: "",
  mobileManager: "",
  teleManager: "",
  faxManager: "",
  emailManager: "",
  region: "",
  dgmsMineCode: "",
};
export const mineLabel = {
  siteIdCode: "Mine code",
  name: "Mine Name",
  lin: "Labour Identification Number (LIN)",
  ownerName: "Owner Name",
  mineral: "Mineral",
  mineVillageAreaRoad: "Village/Area/Road",
  minePostOffice: "Post Office",
  mineSubDivision: "Sub-Division (Taluq)/Tehsil",
  mineDistrict: "District",
  mineState: "State",
  minePinCode: "PIN Code",
  minePoliceStation: "Police Station",
  mineRailwayStation: "Railway station (nearest)",
  ownerVillageAreaRoad: "Village/Area/Road",
  ownerPostOffice: "Post Office",
  ownerSubDivision: "Sub-Division (Taluq)/Tehsil",
  ownerDistrict: "District",
  ownerState: "State",
  ownerPinCode: "PIN Code",
  ownerPoliceStation: "Police Station",
  ownerRailwayStation: "Railway station (nearest)",
  ownerEmail: "Email Id",
  ownerTelephone: "Telephone number (land line)",
  ownerFax: "Fax number",
  ownerMobile: "Mobile number(s)",
  subsidiary: "Subsidiary",
  nameAgent: "Name",
  postalAddressAgent: "Postal address",
  districtAgent: "District",
  stateAgent: "State",
  pinAgent: "Pin",
  mobileAgent: "Mobile Number",
  teleAgent: "Telephone Number",
  faxAgent: "Fax Number",
  emailAgent: "Email ID",
  nameManager: "Name",
  postalAddressManager: "Postal address",
  districtManager: "District",
  stateManager: "State",
  pinManager: "Pin",
  mobileManager: "Mobile Number",
  teleManager: "Telephone Number",
  faxManager: "Fax Number",
  emailManager: "Email ID",
  region: "Region",
  dgmsMineCode: "DGMS Mine Code",
};
export const validationRulesMine = [
  {
    label: mineLabel.lin,
    field_name: "lin",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.ownerName,
    field_name: "ownerName",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.region,
    field_name: "region",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.dgmsMineCode,
    field_name: "dgmsMineCode",
    type: "number",
    isRequired: true,
  },
];

export const validationRulesMineParticular = [
  {
    label: mineLabel.mineral,
    field_name: "mineral",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.mineVillageAreaRoad,
    field_name: "mineVillageAreaRoad",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.minePostOffice,
    field_name: "minePostOffice",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.mineSubDivision,
    field_name: "mineSubDivision",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.mineDistrict,
    field_name: "mineDistrict",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.mineState,
    field_name: "mineState",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.minePinCode,
    field_name: "minePinCode",
    type: "number",
    isRequired: true,
  },
  {
    label: mineLabel.minePoliceStation,
    field_name: "minePoliceStation",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.mineRailwayStation,
    field_name: "mineRailwayStation",
    type: "string",
    isRequired: true,
  },
];

export const validationRulesMineOwner = [
  {
    label: mineLabel.ownerVillageAreaRoad,
    field_name: "ownerVillageAreaRoad",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.ownerPostOffice,
    field_name: "ownerPostOffice",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.ownerSubDivision,
    field_name: "ownerSubDivision",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.ownerDistrict,
    field_name: "ownerDistrict",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.ownerState,
    field_name: "ownerState",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.ownerPinCode,
    field_name: "ownerPinCode",
    type: "number",
    isRequired: true,
  },
  {
    label: mineLabel.ownerPoliceStation,
    field_name: "ownerPoliceStation",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.ownerRailwayStation,
    field_name: "ownerRailwayStation",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.ownerEmail,
    field_name: "ownerEmail",
    type: "email",
    isRequired: true,
  },
  {
    label: mineLabel.ownerTelephone,
    field_name: "ownerTelephone",
    type: "number",
    isRequired: true,
  },
  {
    label: mineLabel.ownerFax,
    field_name: "ownerFax",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.ownerMobile,
    field_name: "ownerMobile",
    type: "string",
    isRequired: true,
  },
];

export const validationRulesAgent = [
  {
    label: mineLabel.nameAgent,
    field_name: "nameAgent",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.postalAddressAgent,
    field_name: "postalAddressAgent",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.districtAgent,
    field_name: "districtAgent",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.districtAgent,
    field_name: "districtAgent",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.stateAgent,
    field_name: "stateAgent",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.pinAgent,
    field_name: "pinAgent",
    type: "number",
    isRequired: true,
  },
  {
    label: mineLabel.mobileAgent,
    field_name: "mobileAgent",
    type: "number",
    isRequired: true,
  },
  {
    label: mineLabel.teleAgent,
    field_name: "teleAgent",
    type: "number",
    isRequired: true,
  },
  {
    label: mineLabel.faxAgent,
    field_name: "faxAgent",
    type: "text",
    isRequired: true,
  },
  {
    label: mineLabel.emailAgent,
    field_name: "emailAgent",
    type: "email",
    isRequired: true,
  },
];

export const validationRulesManager = [
  {
    label: mineLabel.nameManager,
    field_name: "nameManager",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.postalAddressManager,
    field_name: "postalAddressManager",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.districtManager,
    field_name: "districtManager",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.districtManager,
    field_name: "districtManager",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.stateManager,
    field_name: "stateManager",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.pinManager,
    field_name: "pinManager",
    type: "number",
    isRequired: true,
  },
  {
    label: mineLabel.mobileManager,
    field_name: "mobileManager",
    type: "number",
    isRequired: true,
  },
  {
    label: mineLabel.teleManager,
    field_name: "teleManager",
    type: "number",
    isRequired: true,
  },
  {
    label: mineLabel.faxManager,
    field_name: "faxManager",
    type: "string",
    isRequired: true,
  },
  {
    label: mineLabel.emailManager,
    field_name: "emailManager",
    type: "email",
    isRequired: true,
  },
];

export const tankApi = {
  getAll: {
    api: getAllMine,
  },
  getById: {
    api: getMineById,
  },
};

export const fuelLocationColumnFields = [
  {
    name: mineLabel.subsidiary,
    selector: "parentSite",
    // sortable: true,
    cell: (row) => row?.parentSite?.name,
  },
  {
    name: mineLabel.name,
    selector: "name",
    sortable: true,
    cell: (row) => row?.name,
  },
  {
    name: mineLabel.siteIdCode,
    selector: "siteIdCode",
    sortable: true,
    cell: (row) => row?.siteIdCode,
  },
  {
    name: mineLabel.lin,
    selector: "lin",
    // sortable: true,
    cell: (row) => row?.siteData?.lin,
    minWidth: "100",
  },
  {
    name: mineLabel.ownerName,
    selector: "ownerName",
    // sortable: true,
    cell: (row) => row?.siteData?.ownerName,
  },
  {
    name: mineLabel.ownerMobile,
    selector: "ownerMobile",
    // sortable: true,
    cell: (row) => row?.siteData?.ownerMobile,
  },
];

export const mineTabList = [
  {
    label: "Mine Details",
    tab: 1,
    tabStatus: false,
    Icon: TiInfoOutline,
  },
  {
    label: "Particulars of the mine",
    tab: 2,
    tabStatus: false,
    Icon: TiInfoLarge,
  },
  {
    label: "Agent Details",
    tab: 3,
    tabStatus: false,
    Icon: FiSettings,
  },
  {
    label: "Manager Details",
    tab: 4,
    tabStatus: false,
    Icon: FiSettings,
  },
  {
    label: "Postal address of owner",
    tab: 5,
    tabStatus: false,
    Icon: FiSettings,
  },
];
