import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import ReportButton from "../../../SafetyManagementSystem/IncidentManagement/components/ReportButton/ReportButton";
import {
  IS_MOBILE,
  IS_USER_GPS_ACTIVE,
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
  auth_user,
} from "../../../../_helpers/helper";
import CustomRadio from "../../../../components/_form/CustomRadio/CustomRadio";
import CheckBoxField from "../../../../components/_form/CheckBoxField/CheckBoxField";
import { useDayPicker } from "react-day-picker";
let Select;
if (IS_MOBILE()) {
  Select =
    require("../../../../components/MobileComponents/Select/Select").default;
} else {
  Select = require("../../../../components/Select/Select").default;
}

const AddFuelStation = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  sites,
  tab,
  fuelLocationOpt,
  onChangeMine,
  subsidiarySite,
  onChangeSubsidiary,
  locationFlag,
  changeDispensedMode,
  dispensedMode,
  maxCapacity,
  isEdit,
  workshopData,
}) => {
  let userData = auth_user();
  let userName = userData.firstName + " " + userData.lastName;

  let widthForCol =
    form?.subsidiary === 3 && dispensedMode === "fuelLocation" ? 3 : 4;

  let filteredOpt = fuelLocationOpt.filter(
    (item) => item.workshop_id === form?.workshop_id
  );

  let useWorkshop =
    form?.subsidiary === 3 && dispensedMode === "fuelLocation" ? true : false;

  return (
    <>
      <div className="mobileFormWrapper">
        {IS_MOBILE() && <h3>Fuel Station Details</h3>}
        <div className="FormWrapper">
          <Form>
            <Row>
              <Col lg={widthForCol}>
                <Select
                  label={"Subsidiary"}
                  name="subsidiary"
                  value={form?.subsidiary}
                  onChange={onChangeSubsidiary}
                  options={subsidiarySite}
                  error={error?.subsidiary}
                  blankSelect={"Select"}
                  disabled={IS_USER_SITE_MINES() || IS_USER_SITE_RO()}
                ></Select>
              </Col>
              <Col lg={widthForCol}>
                <Select
                  label={"Mine"}
                  name="mine"
                  value={form?.mine}
                  onChange={onChangeMine}
                  options={sites}
                  error={error?.mine}
                  // blankSelect={"Select"}
                  disabled={IS_USER_SITE_MINES()}
                ></Select>
              </Col>
              {useWorkshop && (
                <Col lg={widthForCol}>
                  <Select
                    label={"Workshop"}
                    name="workshop_id"
                    value={form?.workshop_id}
                    onChange={onChange}
                    options={workshopData}
                    error={error?.workshop_id}
                    // blankSelect={"Not Available"}
                  ></Select>
                </Col>
              )}

              <Col lg={widthForCol}>
                <span
                  onClick={changeDispensedMode}
                  style={{
                    marginLeft: "150px",
                    marginBottom: "-24px",
                    position: "absolute",
                    zIndex: "999",
                    cursor: "pointer",
                    color: "#2c88d1",
                  }}
                >
                  {dispensedMode === "fuelLocation"
                    ? "Use Bowser"
                    : "Use Fuel Location"}
                </span>
                <Select
                  label={
                    dispensedMode === "fuelLocation" ? "Fuel Station" : "Bowser"
                  }
                  name="fuel_location_id"
                  value={form?.fuel_location_id}
                  onChange={onChange}
                  options={useWorkshop ? filteredOpt : fuelLocationOpt}
                  error={error?.fuel_location_id}
                  KEY_label={
                    dispensedMode === "bowser"
                      ? "hemm_id"
                      : "fuel_location_name"
                  }
                  KEY_value={"id"}
                  data-testid={
                    dispensedMode === "bowser" ? "id" : "fuel_location_id"
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col lg={widthForCol}>
                <FormInputText
                  name="userName"
                  value={isEdit ? form?.fuelOperator : userName}
                  onChange={onChange}
                  label={"Fuel Operator"}
                  type="text"
                  class="form-control"
                  disabled={true}
                  placeholder=""
                  // error={error?.hemm_id}
                />
              </Col>
              <Col lg={widthForCol}></Col>
              {useWorkshop && <Col lg={widthForCol}></Col>}
              {form.fuel_location_id && (
                <Col lg={widthForCol} className="mt-4">
                  {/* {"Fuel Level in Tank (L) "}
                  {maxCapacity ? ": " + maxCapacity : "Not Available"} */}
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </div>
      {!locationFlag && IS_USER_GPS_ACTIVE() && (
        <Row>
          <Col lg={12}>
            <div class="alert alert-danger mb-0 mt-3">
              Location should be enable!
            </div>
          </Col>
        </Row>
      )}
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default AddFuelStation;
