import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { areaLabel } from "./DATA";

const AreaView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <Row>
              <h6>{areaLabel?.area}</h6>
              <Col sm={6}>
                <label>{"Code"}</label>
                <p className="content">{`${form?.code}`}</p>
              </Col>
              <Col sm={6}>
                <label>{"Name"}</label>
                <p className="content">{`${form?.name}`}</p>
              </Col>
              <Col sm={12}>
                <h6>{areaLabel?.subAreaOfOperation}</h6>
                <Row>
                  <Col sm={4}>S.no</Col>
                  <Col sm={4}>Code</Col>
                  <Col sm={4}>Name</Col>
                </Row>
                {form.subAreaOfOperation.map((d, i) => {
                  return (
                    <Row>
                      <Col sm={4}>{i + 1}</Col>
                      <Col sm={4}>{d?.code}</Col>
                      <Col sm={4}>{d?.name}</Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AreaView;
