import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "../../../components/Select/Select";
import FormInputText from "../../../components/FormInputText/FormInputText";
import PerfectScrollbar from "react-perfect-scrollbar";
import { IS_USER_SITE_MINES, auth_user } from "../../../_helpers/helper";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import StatusPopup from "../../../components/MessagePopup/StatusPopup";
import toastr from "toastr";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import {
  SidebarData,
  getFuelConfigSiteById,
  getFuelConfigWithoutSiteById,
  createFuelConfigWithoutSite,
  updateFuelConfigWithoutSite,
  createFuelConfigSite,
  updateFuelConfigSite,
} from "./DATA";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { getAllSites } from "../../../_services/SiteServices/SiteServices";
import { SidebarData as ContractorSideBar } from "../../ContractorManagement/DATA";

const PollutionControlAddEdit = () => {
  let navigate = useNavigate();
  let params = useParams();
  const usrl = useLocation();

  const segments = usrl.pathname.split("/");
  const lastSegment = segments[segments.length - 2];
  const words = lastSegment.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const title = capitalizedWords.join(" ");
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [show, setShow] = useState(false);
  const [messageshow, setMessageShow] = useState(false);
  const [onsave, setOnSave] = useState(false);
  const [pollutionForm, setPollutionForm] = useState([]);
  const [mineList, setMineList] = useState([]);
  const [contractorData, setContractorData] = useState();
  const [hindranceBasicDetailsForm, setHindranceBasicDetailsForm] = useState({
    input_date: null,
  });
  const [hindranceBasicDetailsErrorForm, setHindranceBasicDetailsErrorForm] =
    useState({
      input_date: null,
    });
  const closePopup = () => {
    setShow(false);
    navigate(
      lastSegment === "section"
        ? `/contractor-management/configuration/${lastSegment}`
        : `/environment-management/configuration/${lastSegment}`
    );
  };
  // const closeMessagePopup = () => {
  //   setMessageShow(false);
  // };
  const hideMessagePopup = () => {
    setMessagePopupType(true);
    setShow(false);
  };
  const [formFields, setFormFields] = useState({
    mine: "",
    name: "",
  });

  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj =
      mineList?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setFormFields((prev) => ({
      ...prev,
      mine: siteObj?.value,
    }));
  }, [mineList]);

  const mineLists = async () => {
    let list = [];
    const response = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );

    if (response?.status) {
      list = response?.data?.map((d) => {
        return { value: d.id, label: d.name };
      });
    }
    setMineList(list);
  };
  useEffect(() => {
    mineLists();
  }, [onsave]);

  const getPollutionControlMachine = async () => {
    let response;
    if (
      lastSegment === "power_consumption" ||
      lastSegment === "land_reclamation" ||
      lastSegment === "section"
    )
      response = await getFuelConfigWithoutSiteById(params.id);
    else response = await getFuelConfigSiteById(params.id);

    setFormFields({
      mine: response.site_id,
      name: response.name,
      type: response.type,
    });
    setHindranceBasicDetailsForm((prev) => {
      return {
        ...prev,
        input_date: response?.data?.input_date,
      };
    });
  };

  useEffect(() => {
    params?.id && getPollutionControlMachine();
  }, []);
  const handleFormChange = (event) => {
    setFormFields((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const validatePollution = () => {
    const PollutionError = {};
    if (
      lastSegment === "power_consumption" ||
      lastSegment === "land_reclamation" ||
      lastSegment === "section"
    ) {
      if (formFields.mine === "") {
        PollutionError["mine"] = "Required.";
      }
    }

    if (formFields.name === "") {
      PollutionError["name"] = "Required.";
    }
    return PollutionError;
  };

  const onSubmit = async () => {
    let validate = validatePollution();
    setPollutionForm(validate);
    let toSubmit = true;

    Object.keys(validate).forEach((f) => {
      if (validate[f] !== "") toSubmit = false;
    });

    if (toSubmit) {
      let data = {
        type: lastSegment,
        siteId: formFields?.mine,
        ...formFields,
      };
      let response;
      if (
        lastSegment === "power_consumption" ||
        lastSegment === "land_reclamation" ||
        lastSegment === "section"
      )
        response = params?.id
          ? await updateFuelConfigSite(params.id, data)
          : await createFuelConfigSite(data);
      else {
        response = params?.id
          ? await updateFuelConfigWithoutSite(params.id, data)
          : await createFuelConfigWithoutSite(data);
      }

      if (response?.status === 1) {
        setShow(true);
        setMessagePopupType(false);
        toastr.success(response?.message);
      } else {
        !response?.status && toastr.error(response?.message);
      }
    }
  };

  function handleClick() {
    setShow(true);
    setMessagePopupType(true);
  }
  return (
    <div className="sidebarWrapper">
      <SafetySideBar
        SidebarData={
          lastSegment === "section" ? ContractorSideBar : SidebarData
        }
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              className="title"
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={
                  lastSegment === "section"
                    ? [
                        {
                          name: "Contractor Management",
                          path: "#",
                        },
                        {
                          name: "Configuration",
                          path: "/contractor-management/configuration",
                        },
                        {
                          name: "Section",
                          path: `/contractor-management/configuration/${lastSegment}`,
                        },
                        {
                          name: params.id ? "Edit" : "Add",
                          path: "#",
                        },
                      ]
                    : [
                        {
                          name: "Environment Management",
                          path: "#",
                        },
                        {
                          name: "Configuration",
                          path: "/environment-management/configuration",
                        },
                        {
                          name: title,
                          path: `/environment-management/configuration/${lastSegment}`,
                        },
                        {
                          name: params.id ? "Edit" : "Add",
                          path: "#",
                        },
                      ]
                }
                // module={ACTIVE_MODULE.incident}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              {title} &nbsp;
              {/* <span className="blueLabel">
                {moment().format("DD/MM/YYYY hh:mm A")}
              </span> */}
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="hindranceContent">
                  <div>
                    <div className="hindranceData">
                      <Row>
                        {(lastSegment === "power_consumption" ||
                          lastSegment === "land_reclamation" ||
                          lastSegment === "section") && (
                          <Col md={4}>
                            <Select
                              onChange={(event) => handleFormChange(event)}
                              name="mine"
                              options={mineList}
                              label={"Mine"}
                              placeholder={"Enter Here"}
                              value={formFields?.mine}
                              error={pollutionForm?.mine}
                              data-testid="mineField"
                              disabled={IS_USER_SITE_MINES()}
                            />
                          </Col>
                        )}

                        <Col md={4} lg={6}>
                          <FormInputText
                            type="input"
                            label={"Name"}
                            onChange={(event) => handleFormChange(event)}
                            name="name"
                            value={formFields?.name}
                            error={pollutionForm?.name}
                            data-testid="nameField"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="btnSec">
                    <Button
                      className="secondaryBtn"
                      data-testid="handle-click-for-show"
                      onClick={handleClick}
                    >
                      Close
                    </Button>
                    <div>
                      <Button
                        className="primaryBtn"
                        onClick={onSubmit}
                        data-testid="submit-hindrance"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            messagePopupType={messagePopupType}
            show={show}
            setShow={hideMessagePopup}
            closePopup={closePopup}
          />
          <StatusPopup
            messagePopupType={false}
            show={messageshow}
            setShow={setMessageShow}
            // closePopup={closeMessagePopup}
          />
        </div>
      </div>
    </div>
  );
};

export default PollutionControlAddEdit;
