import FormInputText from "../../../../components/FormInputText/FormInputText";
import { Col, Row } from "react-bootstrap";
import { mineLabel } from "./DATA";

const ManagerDetailsForm = ({ form, error, onChange }) => {
  return (
    <Row>
      <Col lg={3}>
        <FormInputText
          name="nameManager"
          value={form.nameManager}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.nameManager}
          error={error?.nameManager}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="postalAddressManager"
          value={form.postalAddressManager}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.postalAddressManager}
          error={error?.postalAddressManager}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="districtManager"
          value={form.districtManager}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.districtManager}
          error={error?.districtManager}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="stateManager"
          value={form.stateManager}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.stateManager}
          error={error?.stateManager}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="pinManager"
          value={form.pinManager}
          onChange={onChange}
          type="number"
          class="form-control"
          placeholder=""
          label={mineLabel.pinManager}
          error={error?.pinManager}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="mobileManager"
          value={form.mobileManager}
          onChange={onChange}
          type="number"
          class="form-control"
          placeholder=""
          label={mineLabel.mobileManager}
          error={error?.mobileManager}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="teleManager"
          value={form.teleManager}
          onChange={onChange}
          type="number"
          class="form-control"
          placeholder=""
          label={mineLabel.teleManager}
          error={error?.teleManager}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="faxManager"
          value={form.faxManager}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.faxManager}
          error={error?.faxManager}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="emailManager"
          value={form.emailManager}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.emailManager}
          error={error?.emailManager}
        />
      </Col>
    </Row>
  );
};
export default ManagerDetailsForm;
