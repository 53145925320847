import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Container, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toastr from "toastr";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import MessagePopup from "../../../../components/MessagePopup/MessagePopup";
import { areaInitialForm, areaLabel, permissionInitialForm } from "./DATA";
import { useForm } from "../../../../_helpers/hooks";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Label } from "reactstrap";
import { AreaOfOperationService } from "../../../../_services/ConfigurationService/AreaOfOperationService";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { SidebarData } from "../../IncidentManagement/DATA";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import PerfectScrollbar from "react-perfect-scrollbar";

const AreaAddEdit = () => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({
    ...areaInitialForm,
  });

  const [messagePopupType, setMessagePopupType] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);
  const [categoryFormFields, setCategoryFormFields] = useState([
    {
      name: "",
      code: "",
    },
  ]);

  let navigate = useNavigate();
  const params = useParams("id");

  const removeCategoryFields = (index) => {
    categoryFormFields?.splice(index, 1);
    setCategoryFormFields([...categoryFormFields]);
  };

  const areaById = useCallback(async () => {
    const response = await AreaOfOperationService.getAooById(params?.id);
    if (!response?.status) return;
    setForm((prev) => ({
      ...prev,
      ...response?.data,
    }));
    setCategoryFormFields([...(response?.data?.subAreaOfOperation || [])]);
  }, [params?.id, setForm]);

  const onChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!params?.id) return;
    areaById();
  }, [params?.id, areaById]);

  const addCategoryFields = () => {
    let object = {
      name: "",
    };
    setCategoryFormFields([...categoryFormFields, object]);
  };

  const onChangeCategory = (index, name, value) => {
    if (name === "subAreaOfOperation") categoryFormFields[index].name = value;
    if (name === "code") categoryFormFields[index].code = value;
    setCategoryFormFields([...categoryFormFields]);
  };

  const handleSubmit = async () => {
    if (!isInvalid) {
      let data = {
        ...form,
        subAreaOfOperation: categoryFormFields,
        name: form?.name,
      };

      const response = !params?.id
        ? await AreaOfOperationService.addAoo(data)
        : await AreaOfOperationService.updateAoo(params?.id, data);

      if (!response?.status || response?.status === 401)
        return !response?.status && toastr.error(response?.message);
      toastr.success(response?.message || `Updated`);
      setShow(true);
      setMessagePopupType(false);
    }
  };

  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };

  function handleClick() {
    setShow(false);
    navigate("/safety-management-system/configuration/area-of-operation");
  }

  useEffect(() => {
    let fromCategoryCheck = categoryFormFields.some((d) => d?.code?.length > 3);
    if (form?.code?.length > 3 || fromCategoryCheck) {
      setIsInvalid(true);
    } else setIsInvalid(false);
  }, [form, categoryFormFields]);

  return (
    <>
      <div className="wrapper sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <div className="creationWrapper">
              <h3
                className=""
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Safety Management",
                      path: "#",
                    },
                    {
                      name: "Configuration",
                      path: "/safety-management-system/configuration",
                    },
                    {
                      name: "Place of Operation",
                      path: "/safety-management-system/configuration/area-of-operation",
                    },
                    {
                      name: params?.id ? "Edit" : "Add",
                      path: "#",
                    },
                  ]}
                  // module={ACTIVE_MODULE.incident}
                />
              </h3>
              <PerfectScrollbar className="customHeight">
                <h3 className="title">{`${
                  params?.id ? "Edit" : "Add"
                } Place of Operation`}</h3>
                <div className="reportCard">
                  <div className="formWrapper rootCause">
                    <Form>
                      <h6>{areaLabel?.area}</h6>
                      <Row className="mb-3">
                        <Col md={5}>
                          <FormInputText
                            type="number"
                            placeHolder="Enter here"
                            label={areaLabel?.placeOfOpCode}
                            onChange={onChange}
                            name="code"
                            value={form?.code}
                            error={
                              form?.code?.length > 3
                                ? "Max 3 digit code is Allowed"
                                : ""
                            }
                          />
                        </Col>
                        <Col md={5}>
                          <FormInputText
                            type="input"
                            placeHolder="Enter here"
                            label={"Name"}
                            onChange={onChange}
                            name="name"
                            value={form?.name}
                          />
                        </Col>
                      </Row>
                      <h6>{areaLabel.subAreaOfOperation}</h6>
                      {Array.isArray(categoryFormFields) &&
                        categoryFormFields.map((data, index) => {
                          return (
                            <Row key={index} className="align-items-center">
                              <Col md={5}>
                                <div className="form-group">
                                  <FormInputText
                                    label={areaLabel.subAreaOfOpCode}
                                    type="number"
                                    placeHolder="Enter here"
                                    name="code"
                                    onChange={({ target: { name, value } }) =>
                                      onChangeCategory(index, name, value)
                                    }
                                    value={data?.code}
                                    error={
                                      data?.code?.length > 3
                                        ? "Max 3 digit code is Allowed"
                                        : ""
                                    }
                                  />
                                </div>
                              </Col>
                              <Col md={5}>
                                <div className="form-group">
                                  <FormInputText
                                    type="input"
                                    placeHolder="Enter here"
                                    label={"Name"}
                                    name="subAreaOfOperation"
                                    onChange={({ target: { name, value } }) =>
                                      onChangeCategory(index, name, value)
                                    }
                                    value={data?.name}
                                  />
                                </div>
                              </Col>
                              <Col md="2" className="p-0 mt-4">
                                {index !== 0 && (
                                  <span
                                    className="icons font-21 text-danger remove"
                                    data-testid={"remove-branch" + index}
                                    onClick={() => removeCategoryFields(index)}
                                  >
                                    <AiOutlineMinus /> Remove Workings
                                  </span>
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                      <Row className="mb-3">
                        <Col md="4">
                          <span
                            className="addTxt"
                            onClick={() => addCategoryFields()}
                            data-testid="add-branch"
                          >
                            {" "}
                            <AiOutlinePlus />
                            Add Workings
                          </span>
                        </Col>
                      </Row>
                    </Form>
                  </div>

                  <div className="btnSec">
                    <Button
                      className="secondaryBtn"
                      data-testid="handle-click-for-show"
                      onClick={closeFun}
                    >
                      Close
                    </Button>
                    <div>
                      <Button
                        className="primaryBtn"
                        onClick={handleSubmit}
                        data-testid="submit-hindrance"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>

              <MessagePopup
                messagePopupType={messagePopupType}
                show={show}
                setShow={setShow}
                closePopup={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AreaAddEdit;
