import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PARSE_IMG_URL } from "../../_helpers/commonHelper";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import { Button } from "react-bootstrap";
import PdfPopup from "../../components/PdfPopup/PdfPopup";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const HelpManualPopup = ({ form, width }) => {
  return <PdfPopup fileName={form?.help_manual_document_data?.document_name} />;
};

export default HelpManualPopup;
