import React from "react";
import PropTypes from "prop-types";

const PopUpFile = ({ src, title, hideClass, height }) => (
  <div className="video-responsive">
    {/* <iframe
      src={src}
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title || ""}
      initialPlayerParams={{ controls: false }}
    /> */}
    <video
      width="100%"
      height={height}
      controls
      autoplay
      controlsList="nodownload"
      oncontextmenu="return false;"
      allowFullScreen
    >
      <source src={src} type="video/mp4" />
    </video>
  </div>
);

PopUpFile.propTypes = {
  src: PropTypes.string.isRequired,
  // title: PropTypes.string.isRequired,
};

export default PopUpFile;
