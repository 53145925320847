import React, { useMemo, useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { useForm } from "../../../../_helpers/hooks";
import UserManagementFilterForm from "./UserManagementFilterForm";
import {
  userFilterForm,
  userListApis,
  addLink,
  customTabsData,
  userLabel,
  IncidentuserListApis,
} from "./DATA";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import {
  capitalizeName,
  findUserPermissionedTabList,
} from "../../../../_helpers/helper";
import { useLocation } from "react-router-dom";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import {
  CONTRACTOR_ROLE_OBJECT,
  Env_ROLE_OBJECT,
  USER_MANAGEMENT_ROLE,
  isContractorModuleAdmin,
} from "../../../../_services/Auth/helper";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { SidebarData } from "../../../EnvironmentManagement/UploadedFiles/DATA";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import EnvUserPermissionForm from "./EnvUserPermissionForm";
import EnvUserView from "./EnvUserView";
import PermissionsFormEdit from "./PermissionsFormEdit";
import UserManagementView from "./UserManagementView";

const EnvUserManagementList = () => {
  let newCustomTabsData = customTabsData;
  let newAddLink = "/environment-management/user-management";

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          // rootClose
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>

        <UserManagementFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          isUserType
        />
      </div>
    );
  };

  let newFilterBtn = FilterBtn;
  if (isContractorModuleAdmin()) {
    newCustomTabsData = customTabsData.filter((d, i) => {
      if (d.label === "User List") {
        return false;
      }
      return true;
    });
    newAddLink = "/environment-management/user-management";
    // newFilterBtn = null;
  }

  const [getAllFilter, setGetAllFilter] = useState(`&type=users`);
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(userFilterForm);
  const [isListData, setIsListData] = useState(true);
  const [customTabList, setCustomTabList] = useState(
    findUserPermissionedTabList(newCustomTabsData)
  );

  const getAll = async () => {
    const response = await IncidentuserListApis?.getAll.api(
      "&type=users&page=1&limit=2"
    );
    if (Array.isArray(response?.data) && response?.data?.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    setCustomTabList((prev) => {
      return prev.map((data) => {
        if (data.to === pathname) {
          return {
            ...data,
            active: 1,
          };
        }
        return data;
      });
    });
  }, [pathname]);

  const onResetFilter = () => {
    setGetAllFilter(`&type=users`);
    setForm({ ...userFilterForm });
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];

      if (element) {
        filter += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(`&type=users` + filter);
  }, [filterStatus]);

  const columnFields = useMemo(
    () => [
      {
        selector: "name",
        name: userLabel?.name,
        sortable: true,
        minWidth: "200px",
        cell: (row) => {
          return capitalizeName(
            `${row?.firstName ? row?.firstName : ""} ${
              row?.lastName ? row?.lastName : ""
            }`
          );
        },
      },
      {
        name: userLabel?.roleId,
        selector: "roleId",
        cell: (row) => {
          return row?.role?.title || "";
        },
        sortable: true,
      },
      {
        selector: "siteId",
        sortable: true,
        name: userLabel?.siteId,
        cell: (row) => {
          return row?.site?.name || "";
        },
      },
      {
        name: userLabel?.email,
        selector: "email",
        sortable: true,
      },
      {
        name: "Permissions",
        selector: "accessPermissions",
        cell: (row) => {
          let role = row.accessPermissions?.map(
            (d) => Env_ROLE_OBJECT?.environmentManagement[d.permission]
          );
          let roleStr = role?.filter((val) => val !== undefined);
          let roleStrNew = roleStr?.join(",").substring(0, 20);
          if (roleStrNew.length > 19) {
            roleStrNew = roleStrNew + "...";
          }
          return roleStrNew;
        },
        sortable: false,
        minWidth: "200px",
      },
    ],
    []
  );

  const viewAsPopup = (form) => {
    return (
      <UserManagementView form={form} USER_MANAGEMENT_ROLE={Env_ROLE_OBJECT} />
    );
  };

  const editAsPopup = (form, setShowAddEdit, getAll) => {
    return (
      <PermissionsFormEdit
        formData={form}
        setShowAddEdit={setShowAddEdit}
        getAll={getAll}
        USER_MANAGEMENT_ROLE_OBJECT={Env_ROLE_OBJECT}
      />
    );
  };
  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  fields={columnFields}
                  apis={IncidentuserListApis}
                  title={" User"}
                  // hideAddButton={true}
                  viewTitle="User Details"
                  editTitle="Edit Permissions"
                  customTableTitle={"User Management"}
                  FilterBtn={newFilterBtn}
                  hideEditButton
                  viewAsPopup={viewAsPopup}
                  editAsPopup={editAsPopup}
                  hideDeleteButton
                  hideSnColumn
                  pageLink={newAddLink}
                  primaryKey="id"
                  pagination
                  getAllFilter={getAllFilter}
                  // editFunButton={onEdit}
                  breadCrumb={[
                    {
                      path: "#",
                      name: "Environment Management",
                    },
                    {
                      name: "User Management",
                      path: "#",
                    },
                  ]}
                  isNoTabOnPage
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              // pageLink={newAddLink}
              customTableTitle={"Blank"}
              hideAddBtn={true}
              breadCrumb={[
                {
                  path: "#",
                  name: "Environment Management",
                },
                {
                  path: "#",
                  name: "User Management",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EnvUserManagementList;
