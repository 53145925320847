import { getCoalGradeListWithPagination } from "../../services/_coalGrade/coalGrade";
import { samplePrepareData } from "./CoalSamplePreparation/DATA";
import { refereeLabResultData } from "./RefereeLabResult/DATA";
import { sampleCodingData } from "./SampleCoding/DATA";
import { sampleDecodingData } from "./SampleDecoding/DATA";
import { thirdPartyLabData } from "./ThirdPartyLabResult/DATA";
import selectionIcon1 from "../../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../../Assets/Images/selectionIcon3.svg";
import selectionIcon4 from "../../Assets/Images/selectionIcon4.svg";
import selectionIcon5 from "../../Assets/Images/selectionIcon5.svg";
import { DDMMYYYYFormat } from "../../_helpers/dateHelper";
import SamplePreparationFilterForm from "./CoalSamplePreparation/SamplePreparationFilterForm";
import SampleCodingFilterForm from "./SampleCoding/SampleCodingFilterForm";
import SampleDecodingFilterForm from "./SampleDecoding/SampleDecodingFilterForm";
import SampleThirdPartyFilterForm from "./CoalSamplePreparation/SampleThirdPartyFilterForm";
export const thirdPartyLabResultInitialForm = {
  id: "",
  third_party_on_air_dry_bases_moisture: "",
  third_party_on_air_dry_bases_ash: "",
  third_party_on_air_dry_bases_vm: "",
  third_party_on_air_dry_bases_gcv: "",
  third_party_on_em_bases_moisture: "",
  third_party_on_em_bases_ash: "",
  third_party_on_em_bases_vm: "",
  third_party_on_em_bases_gcv: "",
  third_party_analyzed_grade: "",
  third_party_total_moisture: "",
};

export const refereeLabResultInitialForm = {
  id: "",
  third_party_on_air_dry_bases_moisture: "",
  third_party_on_air_dry_bases_ash: "",
  third_party_on_air_dry_bases_vm: "",
  third_party_on_air_dry_bases_gcv: "",
  third_party_on_em_bases_moisture: "",
  third_party_on_em_bases_ash: "",
  third_party_on_em_bases_vm: "",
  third_party_on_em_bases_gcv: "",
  third_party_analyzed_grade: "",
  third_party_total_moisture: "",
};

export const coalGradeLabel = {
  id: "ID",
  sample_id: "Sample ID",
  mode_of_dispatch: "Dispatch Mode",
  rr_or_do_date: "Collection Date",
  rr_or_do_number: "Delivery No",
  declared_grade: "Declared Grade",
  site_id: "Site ID",
  date_of_sample_sent_to_lab: "Date of Sample sent to CIL lab",
  code_given_to_sample: "Code given to Sample",
  sample_coding_done_by: "Sample coding done by",
  date_of_sample_receipt_in_lab: "Date of Sample receipt in CIL lab",
  date_given_for_test: "Date given for Test",
  date_of_test_result_recieved: "Date of Test result received",
  sample_sender_name: "Sample sender Name",
  decoded_by: "Decoded By",
  lab_result_checked_by: "Checked by",
  lab_result_tested_by: "Tested by",
  result_reporting_to_customer: "Result reporting date to customer",
  lab_on_air_dry_bases_moisture: "Moisture",
  lab_on_air_dry_bases_ash: "Ash",
  lab_on_air_dry_bases_vm: "VM",
  lab_on_air_dry_bases_gcv: "GCV",
  lab_on_em_bases_moisture: "Moisture",
  lab_on_em_bases_ash: "Ash",
  lab_on_em_bases_vm: "VM",
  lab_on_em_bases_gcv: "GCV",
  lab_analyzed_grade: "CIL Lab analyzed grade",
  name_of_referee_agency: "Name of Referee Lab",
  referee_on_air_dry_bases_moisture: "Moisture",
  referee_on_air_dry_bases_ash: "Ash",
  referee_on_air_dry_bases_vm: "VM",
  referee_on_air_dry_bases_gcv: "GCV",
  referee_on_em_bases_moisture: "Moisture",
  referee_on_em_bases_ash: "Ash",
  referee_on_em_bases_vm: "VM",
  referee_on_em_bases_gcv: "GCV",
  referee_total_moisture: "Total Moisture",
  referee_analyzed_grade: "Referee Lab analyzed grade",
  referee_date_of_result: "Date of the result",
  name_of_sampling_agency: "Name of Sampling agency",
  third_party_on_air_dry_bases_moisture: "Moisture",
  third_party_on_air_dry_bases_ash: "Ash",
  third_party_on_air_dry_bases_vm: "VM",
  third_party_on_air_dry_bases_gcv: "GCV",
  third_party_on_em_bases_moisture: "Moisture",
  third_party_on_em_bases_ash: "Ash",
  third_party_on_em_bases_vm: "VM",
  third_party_on_em_bases_gcv: "GCV",
  third_party_total_moisture: "Total moisture",
  third_party_analyzed_grade: "Third party lab analyzed grade",
  third_party_date_of_result: "Date of the Result",
  dispute_raised_by: "Dispute raised by",
  dispute_raised_date: "Dispute Date",
  referee_status: "Referee Status",
};

export const coalGradeColumnFields = [
  {
    name: coalGradeLabel?.sample_id,
    selector: "sample_id",
    sortable: true,
    minWidth: "210px",
  },
  {
    name: coalGradeLabel?.mode_of_dispatch,
    selector: "mode_of_dispatch",
    sortable: true,
    isSearchable: true,
    minWidth: "170px",
    cell: (row) =>
      row?.mode_of_dispatch
        ? modeOfDispatchLabelConsolidated[row?.mode_of_dispatch]
        : "",
  },
  {
    name: coalGradeLabel?.rr_or_do_date,
    selector: "rr_or_do_date",
    sortable: true,
    minWidth: "170px",
    cell: (row) =>
      row?.rr_or_do_date ? DDMMYYYYFormat(row?.rr_or_do_date) : "",
  },
  {
    name: coalGradeLabel?.rr_or_do_number,
    selector: "rr_or_do_number",
    sortable: true,
    isSearchable: true,
    minWidth: "150px",
  },
  {
    name: coalGradeLabel?.declared_grade,
    selector: "declared_grade",
    sortable: true,
    minWidth: "170px",
    isSearchable: true,
  },
  {
    name: coalGradeLabel?.name_of_sampling_agency,
    selector: "sampling_agency.name",
    // sortable: true,
    cell: (row) => row.sampling_agency?.name,
    minWidth: "230px",
  },
  {
    name: coalGradeLabel?.date_of_sample_receipt_in_lab,
    selector: "date_of_sample_receipt_in_lab",
    sortable: true,
    cell: (row) =>
      row?.date_of_sample_receipt_in_lab
        ? DDMMYYYYFormat(row?.date_of_sample_receipt_in_lab)
        : "",
    minWidth: "290px",
  },
  {
    name: coalGradeLabel?.sample_sender_name,
    selector: "sample_sender_name",
    sortable: true,
    minWidth: "210px",
  },
  {
    name: coalGradeLabel?.code_given_to_sample,
    selector: "code_given_to_sample",
    sortable: true,
    isSearchable: true,
    minWidth: "210px",
  },
  {
    name: coalGradeLabel?.sample_coding_done_by,
    selector: "sample_coding_users.firstName",
    // sortable: true,
    cell: (row) => row?.sample_coding_users?.firstName,
    minWidth: "210px",
  },
  {
    name: coalGradeLabel?.date_given_for_test,
    selector: "date_given_for_test",
    sortable: true,
    minWidth: "200px",
    cell: (row) =>
      row?.date_given_for_test ? DDMMYYYYFormat(row?.date_given_for_test) : "",
  },
  {
    name: coalGradeLabel?.date_of_test_result_recieved,
    selector: "date_of_test_result_recieved",
    sortable: true,
    minWidth: "250px",
    cell: (row) =>
      row?.date_of_test_result_recieved
        ? DDMMYYYYFormat(row?.date_of_test_result_recieved)
        : "",
  },
  {
    name: coalGradeLabel?.decoded_by,
    selector: "decoded_by",
    sortable: true,
    minWidth: "180px",
  },
  {
    name: coalGradeLabel?.lab_result_checked_by,
    selector: "lab_result_checked_by",
    sortable: true,
    minWidth: "160px",
  },
  {
    name: coalGradeLabel?.lab_result_tested_by,
    selector: "lab_result_tested_by",
    sortable: true,
    minWidth: "150px",
  },
  {
    name: coalGradeLabel?.result_reporting_to_customer,
    selector: "result_reporting_to_customer",
    sortable: true,
    minWidth: "290px",
    cell: (row) =>
      row?.result_reporting_to_customer
        ? DDMMYYYYFormat(row?.result_reporting_to_customer)
        : "",
  },
  {
    name: "On Dry Air Moisture %",
    selector: "lab_on_air_dry_bases_moisture",
    sortable: true,
    minWidth: "180px",
  },
  {
    name: "On Dry Air Ash %",
    selector: "lab_on_air_dry_bases_ash",
    sortable: true,
    minWidth: "170px",
  },
  {
    name: "On Dry Air VM %",
    selector: "lab_on_air_dry_bases_vm",
    minWidth: "170px",
    sortable: true,
  },
  {
    name: "On Dry Air GCV (kCal/kg)",
    selector: "lab_on_air_dry_bases_gcv",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "On EM Moisture %",
    selector: "lab_on_em_bases_moisture",
    sortable: true,
    minWidth: "160px",
  },
  {
    name: "On EM Ash %",
    selector: "lab_on_em_bases_ash",
    sortable: true,
    minWidth: "120px",
  },
  {
    name: "On EM VM %",
    selector: "lab_on_em_bases_vm",
    sortable: true,
  },
  {
    name: "On EM GCV (kCal/kg)",
    selector: "lab_on_em_bases_gcv",
    sortable: true,
    minWidth: "230px",
  },
  {
    name: coalGradeLabel?.lab_analyzed_grade,
    selector: "lab_analyzed_grade",
    sortable: true,
    minWidth: "220px",
    isSearchable: true,
  },
  {
    name: "Lab Data Approval",
    selector: "sample_decoding_approval",
    sortable: true,
    isSearchable: true,
    minWidth: "200px",
  },
  {
    name: "On Dry Air Moisture %",
    selector: "third_party_on_air_dry_bases_moisture",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "On Dry Air Ash %",
    selector: "third_party_on_air_dry_bases_ash",
    sortable: true,
    minWidth: "180px",
  },
  {
    name: "On Dry Air VM %",
    selector: "third_party_on_air_dry_bases_vm",
    sortable: true,
    minWidth: "180px",
  },
  {
    name: "On Dry Air GCV (kCal/kg)",
    selector: "third_party_on_air_dry_bases_gcv",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "On EM Moisture %",
    selector: "third_party_on_em_bases_moisture",
    sortable: true,
    minWidth: "160px",
  },
  {
    name: "On EM Ash %",
    selector: "third_party_on_em_bases_ash",
    sortable: true,
    minWidth: "120px",
  },
  {
    name: "On EM VM %",
    selector: "third_party_on_em_bases_vm",
  },
  {
    name: "On EM GCV (kCal/kg)",
    selector: "third_party_on_em_bases_gcv",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: coalGradeLabel?.third_party_analyzed_grade,
    selector: "third_party_analyzed_grade",
    sortable: true,
    isSearchable: true,
    minWidth: "270px",
  },
  {
    name: coalGradeLabel?.third_party_total_moisture,
    selector: "third_party_total_moisture",
    sortable: true,
    minWidth: "160px",
  },
  {
    name: "Third Party Data Approval",
    selector: "third_party_approval",
    sortable: true,
    minWidth: "240px",
  },
  {
    name: coalGradeLabel?.name_of_referee_agency,
    selector: "name_of_referee_agency",
    sortable: true,
    minWidth: "220px",
  },
  {
    name: "On Dry Air Moisture %",
    selector: "referee_on_air_dry_bases_moisture",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "On Dry Air Ash %",
    selector: "referee_on_air_dry_bases_ash",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "On Dry Air VM %",
    selector: "referee_on_air_dry_bases_vm",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "On Dry Air GCV (kCal/kg)",
    selector: "referee_on_air_dry_bases_gcv",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "On EM moisture %",
    selector: "referee_on_em_bases_moisture",
    sortable: true,
    minWidth: "160px",
  },
  {
    name: "On EM ash %",
    selector: "referee_on_em_bases_ash",
    sortable: true,
    minWidth: "120px",
  },
  {
    name: "On EM VM %",
    selector: "referee_on_em_bases_vm",
    sortable: true,
    minWidth: "120px",

  },
  {
    name: "On EM GCV (kCal/kg)",
    selector: "referee_on_em_bases_gcv",
    sortable: true,
    minWidth: "200px",
  },
  {
    name: coalGradeLabel?.referee_analyzed_grade,
    selector: "referee_analyzed_grade",
    sortable: true,
    isSearchable: true,
    minWidth: "260px",
  },
  {
    name: coalGradeLabel?.referee_total_moisture,
    selector: "referee_total_moisture",
    sortable: true,
    minWidth: "160px",
  },
  {
    name: "Referee Data Approval",
    selector: "referee_lab_approval",
    sortable: true,
    minWidth: "210px",
  },
  // {
  //   name: "Status",
  //   selector: "status",
  //   sortable: true,
  //   cell: (status) => {
  //     return <span className="statusSec draft">Drafted</span>;
  //   },
  // },
  // {
  //   name: "System Feedback",
  //   selector: (row) => <GiGolfFlag className="feedbackIcon" />,
  //   center: true,
  // },
];

export const consolidatedListFilterForm = {
  startDate: "",
  endDate: "",
  mode_of_dispatch: "",
};

export const consolidatedListData = {
  tableColumn: coalGradeColumnFields,
  filterForm: consolidatedListFilterForm,
  pageLink: "/coal-grade/consolidated",
  tableTittle: "Consolidated",
  type: "consolidated",
  role: ["area_quality_manager", "coal_grade_admin"],
};

export const coalGrateTypeObj = {
  samplePrepare: "sample-prepare",
  sampleCoding: "sample-coding",
  sampleDecoding: "sample-decoding",
  refereeLabResult: "referee-lab-result",
  thirdPartyLabResult: "third-party-lab-result",
  consolidated: "consolidated",
};

export const coalGradeSelectionList = [
  {
    img: selectionIcon1,
    label: "Sample Prepare",
    link: "/coal-grade/sample-prepare",
    role: "chemist",
  },
  {
    img: selectionIcon1,
    label: "Sample Coding",
    link: "/coal-grade/sample-coding",
    role: "lab_quality_manager",
  },
  {
    img: selectionIcon2,
    label: "Sample De-Coding",
    link: "/coal-grade/sample-decoding",
    role: "lab_quality_manager",
  },
  {
    img: selectionIcon2,
    label: "Third Party Lab Result",
    link: "/coal-grade/third-party-lab-result",
    role: "area_quality_manager",
  },
  {
    img: selectionIcon1,
    label: "Refree Lab Result",
    link: "/coal-grade/referee-lab-result",
    role: "area_quality_manager",
  },
  {
    img: selectionIcon2,
    label: "Consolidated Mine",
    link: "/coal-grade/consolidated",
    role: "coal_grade_admin",
  },
];

export const COAL_GRADE_ROLE_BY_DATA = [
  {
    role: "chemist",
    ...samplePrepareData,
  },
  {
    role: "lab_quality_manager",
    ...sampleCodingData,
  },
  {
    role: "lab_quality_manager",
    ...sampleDecodingData,
  },
  {
    role: "area_quality_manager",
    ...refereeLabResultData,
  },
  {
    role: "area_quality_manager",
    ...thirdPartyLabData,
  },
  {
    role: "coal_grade_admin",
    ...consolidatedListData,
  },
];

export const defaultPage = {
  chemist: coalGrateTypeObj?.samplePrepare,
  lab_quality_manager: coalGrateTypeObj?.sampleCoding,
  area_quality_manager: coalGrateTypeObj?.thirdPartyLabResult,
  coal_grade_admin: coalGrateTypeObj?.consolidated,
};

export const customTabsData = [
  {
    to: samplePrepareData?.pageLink,
    label: samplePrepareData?.tableTittle,
    type: samplePrepareData?.type,
    role: samplePrepareData?.role,
    active: 0,
  },
  {
    to: sampleCodingData?.pageLink,
    label: sampleCodingData?.tableTittle,
    type: sampleCodingData?.type,
    role: sampleCodingData?.role,
    active: 0,
  },
  {
    to: sampleDecodingData?.pageLink,
    label: sampleDecodingData?.tableTittle,
    type: sampleDecodingData?.type,
    role: sampleDecodingData?.role,
    active: 0,
  },
  {
    to: thirdPartyLabData?.pageLink,
    label: thirdPartyLabData?.tableTittle,
    type: thirdPartyLabData?.type,
    role: thirdPartyLabData?.role,
    active: 0,
  },
  {
    to: refereeLabResultData?.pageLink,
    label: refereeLabResultData?.tableTittle,
    type: refereeLabResultData?.type,
    role: refereeLabResultData?.role,
    active: 0,
  },
  {
    to: consolidatedListData?.pageLink,
    label: consolidatedListData?.tableTittle,
    type: consolidatedListData?.type,
    role: consolidatedListData?.role,
    active: 0,
  },
];

export const submitStatus = {
  drafted: "Drafted",
  submitted: "Submitted",
};

export const filterComponent = (
  type,
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  open,
  setOpen
) => {
  switch (type) {
    case coalGrateTypeObj?.samplePrepare:
      return (
        <SamplePreparationFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      );
    case coalGrateTypeObj?.sampleCoding:
      return (
        <SampleCodingFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      );
    case coalGrateTypeObj?.sampleDecoding:
      return (
        <SampleDecodingFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      );
    case coalGrateTypeObj?.refereeLabResult:
      return (
        <SamplePreparationFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      );
    case coalGrateTypeObj?.thirdPartyLabResult:
      return (
        <SampleThirdPartyFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      );
    case coalGrateTypeObj?.consolidated:
      return (
        <SamplePreparationFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      );
    default:
      return "";
  }
};

export const coalGradeStatus = {
  "sample-prepare": "sample_prepare_status",
  "sample-coding": "sample_coding_status",
  "sample-decoding": "sample_decoding_status",
  "third-party-lab-result": "third_party_status",
  "referee-lab-result": "referee_lab_status",
};

export const allGrades = {
  G1: "7000",
  G2: "6701 - 7000",
  G3: "6401 - 6700",
  G4: "6101 - 6400",
  G5: "5801 - 6100",
  G6: "5501 - 5800",
  G7: "5201 - 5500",
  G8: "4901 - 5200",
  G9: "4601 - 4900",
  G10: "4301 - 4600",
  G11: "4001 - 4300",
  G12: "3701 - 4000",
  G13: "3401 - 3700",
  G14: "3101 - 3400",
  G15: "2801 - 3100",
  G16: "2501 - 2800",
  G17: "2201 - 2500",
};

export const coalGradeApis = Object.freeze({
  getAll: {
    api: getCoalGradeListWithPagination,
  },
});

export const SidebarData = [
  {
    img: selectionIcon1,
    title: "Dashboard",
    active: 0,
    link: "/coal-grade/dashboard/1",
    activeUrl: "dashboard",
    permission: ["coal_grade_dashboard"],
  },
  {
    img: selectionIcon1,
    title: "SAP Report",
    active: 0,
    link: "/coal-grade/sap-report",
    activeUrl: "sap-report",
    permission: ["quick_sight_dashboard"],
  },
  {
    img: selectionIcon2,
    title: "User Management",
    link: "/coal-grade/user-management",
    activeUrl: "user-management",
    active: 0,
    permission: ["coal_grade_admin"],
  },
  {
    img: selectionIcon3,
    title: "Configuration",
    active: 0,
    link: "/coal-grade/configuration",
    activeUrl: "configuration",
    permission: ["coal_grade_admin", "super_admin"],
  },
  {
    img: selectionIcon4,
    title: "Coal Quality",
    active: 0,
    link: "/coal-grade/list",
    activeUrl: [
      "list",
      "sample-prepare",
      "sample-coding",
      "sample-decoding",
      "third-party-lab-result",
      "referee-lab-result",
      "consolidated",
    ],
    permission: [
      "coal_grade_admin",
      "chemist",
      "lab_quality_manager",
      "area_quality_manager",
      "coal_grade_admin",
    ],
  },
  {
    img: selectionIcon5,
    title: "Help Manual",
    active: 0,
    link: "/coal-grade/help-manual",
    activeUrl: "help-manual",
    // permission: ["coal_grade_admin", "chemist", "lab_quality_manager", "area_quality_manager", "coal_grade_admin"],
  },
];

export const activityFlowList = [
  {
    img: selectionIcon1,
    label: "Activity",
    link: "activity",
  },
  {
    img: selectionIcon1,
    label: "Area Of Operation",
    link: "area-of-operation",
  },
];

export const coalGradeConfig = [
  {
    img: selectionIcon1,
    label: "Sampling Agency",
    link: "sampling-agency",
  },
  {
    img: selectionIcon2,
    label: "Import SAP Data",
    link: "sap-import",
  },
  {
    img: selectionIcon3,
    label: "API Status Data",
    link: "api-status",
  },
  {
    img: selectionIcon4,
    label: "Sample Collected Data",
    link: "sample-data",
  },
];

export const subModuleList = [
  {
    link: `${samplePrepareData?.pageLink}/actions/list`,
    label: "Sample Preparation",
    type: samplePrepareData?.type,
    role: samplePrepareData?.role,
    active: 0,
    img: selectionIcon1,
  },
  {
    link: `${sampleCodingData?.pageLink}/actions/list`,
    label: "Sample Coding (CIL Lab)",
    type: sampleCodingData?.type,
    role: sampleCodingData?.role,
    active: 0,
    img: selectionIcon2,
  },
  {
    link: `${sampleDecodingData?.pageLink}/actions/list`,
    label: "Sample Decoding (CIL Lab)",
    type: sampleDecodingData?.type,
    role: sampleDecodingData?.role,
    // role: [...(sampleDecodingData?.role || []), "area_quality_manager"],
    active: 0,
    img: selectionIcon3,
  },
  {
    link: `${thirdPartyLabData?.pageLink}/actions/list`,
    label: "Third Party Lab",
    type: thirdPartyLabData?.type,
    role: thirdPartyLabData?.role,
    active: 0,
    img: selectionIcon4,
  },
  {
    link: `${refereeLabResultData?.pageLink}/actions/list`,
    label: "Referee Lab",
    type: refereeLabResultData?.type,
    role: refereeLabResultData?.role,
    active: 0,
    img: selectionIcon5,
  },
  {
    link: consolidatedListData?.pageLink,
    label: "Consolidated Report",
    type: consolidatedListData?.type,
    role: consolidatedListData?.role,
    active: 0,
    img: selectionIcon5,
  },
];

export const coalQualityActionsList = [
  {
    img: selectionIcon1,
    label: "Add Report",
    link: "/coal-grade/:type/create",
  },
  {
    img: selectionIcon3,
    label: "View History",
    link: "/coal-grade/:type?status=Submitted",
  },
  {
    img: selectionIcon4,
    label: "Pending Actions",
    link: "/coal-grade/:type?status=Drafted",
  },
];

export const tabUrl = {
  "sample-decoding": [
    {
      to: "/coal-grade/sample-decoding?status=Drafted",
      label: "My Drafts",
      active: 0,
      permission: ["lab_quality_manager"],
    },
    {
      to: "/coal-grade/sample-decoding/approval-action",
      label: "My Approval Action",
      active: 0,
      permission: ["area_quality_manager"],
    },
  ],
  "third-party-lab-result": [
    {
      to: "/coal-grade/third-party-lab-result?status=Drafted",
      label: "My Drafts",
      active: 0,
      permission: ["area_quality_manager"],
    },
    {
      to: "/coal-grade/third-party-lab-result/approval-action",
      label: "My Approval Action",
      active: 0,
      permission: ["area_quality_manager"],
    },
  ],
  "referee-lab-result": [
    {
      to: "/coal-grade/referee-lab-result?status=Drafted",
      label: "My Drafts",
      active: 0,
      permission: ["area_quality_manager"],
    },
    {
      to: "/coal-grade/referee-lab-result/approval-action",
      label: "My Approval Action",
      active: 0,
      permission: ["area_quality_manager"],
    },
  ],
};

export const approvalRequired = [
  "/coal-grade/sample-decoding?status=Drafted",
  "/coal-grade/third-party-lab-result?status=Drafted",
  "/coal-grade/referee-lab-result?status=Drafted",
];

export const modeOfDispatchLabel = {
  SHRA: "Rail",
  SHRO: "Road",
  SHMG: "Merry Go Round",
};
export const modeOfDispatchLabelTable = {
  SHRA: "Rail",
  SHRO: "Road",
  MGR: "Merry Go Round",
  SHMG: "Merry Go Round",
};

const modeOfDispatchLabelConsolidated = {
  SHRA: "Rail",
  SHRO: "Road",
  SHMG: "Merry Go Round",
};
