import React, { useRef, useState } from "react";
import "./customTabs.scss";
import { IS_MOBILE } from "../../_helpers/helper";

const CustomTabsComponent = ({ tabeList, setTab, tab ,fontSize}) => {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  // const slide = (shift) => {
  //   scrl.current.scrollLeft += shift;
  //   setscrollX(scrollX + shift);

  //   if (
  //     Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
  //     scrl.current.offsetWidth
  //   ) {
  //     setscrolEnd(true);
  //   } else {
  //     setscrolEnd(false);
  //   }
  // };

  return IS_MOBILE() ? (
    <div className="misReportList" data-testid="misReportList">
      <div className="dashboardTab">
        <ul ref={scrl}>
          {Array.isArray(tabeList) &&
            tabeList?.map((d, i) => {
              const { Icon } = d;
              return (
                <li key={i}>
                  <span
                    className={`tabBtn ${tab === d.tab ? "active" : ""} ${
                      d?.tabStatus ? "filled" : "notfilled"
                    }`}
                    onClick={() => setTab(d.tab)}
                  >
                    {Icon ? <Icon /> : ""}
                    {/* <span
                      className={`tabActivation ${
                        d?.tabStatus ? "green" : "red"
                      }`}
                    ></span> */}
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  ) : (
    <div className="misReportList" data-testid="misReportList">
      <div className="dashboardTab" style={{fontSize:fontSize}}>
        <ul ref={scrl}>
          {Array.isArray(tabeList) &&
            tabeList?.map((d, i) => {
              return (
                <li key={i}>
                  <span
                    className={`tabBtn ${tab === d.tab ? "active" : ""}`}
                    onClick={() => setTab(d.tab)}
                    data-testid={"tabsBtn" + i}
                  >
                    {d.label}
                    <span
                      className={`tabActivation ${
                        d?.tabStatus ? "green" : "red"
                      }`}
                    ></span>
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default CustomTabsComponent;
