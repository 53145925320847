import moment from "moment";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import { getAllSites } from "../../_services/SiteServices/SiteServices";
import { getAllWorkshopMasters } from "../_reasonList/reasonList";

export const getFuelDispenseListWithPagination = async (filter) => {
  let url = "/fuel-dispence";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;

    const d = {
      status,
      message,
      totalCount: data?.data?.totalCount,
      data: data?.data?.fuelDipences,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getFuelStockListWithPagination = async (filter) => {
  let url = "/fuel-dispence/stock";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;

    const d = {
      status,
      message,
      totalCount: data?.data?.totalCount,
      data: data?.data?.fuelStock,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getUserListWithPagination = async (filter) => {
  let url = `users/all-dispenser-user?${filter || ""}`;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      totalCount: response?.data?.data?.length,
      data: response?.data?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const exportFuelDispense = async (filter) => {
  try {
    let url = "/fuel-dispence/export-fuel-dispense";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const exportFuelStock = async (filter) => {
  try {
    let url = "/fuel-dispence/export-fuel-stock";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const exportFuelDispenseById = async (id) => {
  let url = "/fuel-dispence/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const deleteFuelDispense = async (filter) => {
  let url = "/fuel-dispence/delete";

  if (filter) url += "/" + filter;
  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteFuelReceipt = async (filter) => {
  let url = "/fuel-receipt/delete";

  if (filter) url += "/" + filter;
  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const exportMaxFuelDispenseQuantity = async (id, capacityFor, date) => {
  let url = "/fuel-dispence/max-capacity/" + id + "?capacityFor=" + capacityFor;
  if (date) url = url + "&date=" + moment(date).format("DD-MM-YYYY");
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data.response;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getPreviousMeter = async (id, capacityFor, date) => {
  let url = "/fuel-dispence/previousMeter/" + id + "?type=" + capacityFor;
  if (date) url = url + "&date=" + moment(date).format("DD-MM-YYYY");
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const exportMaxFuelSpaceAvailableQuantity = async (id, filter) => {
  let url = "/fuel-dispence/available-space/" + id;
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);

    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const createFuelDispence = async (data) => {
  let url = "/fuel-dispence/add";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateFuelDispence = async (data, id) => {
  let url = "/fuel-dispence/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const exportFuelLocationBySiteId = async (id) => {
  let url = "/fuel-location/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const createFuelLocation = async (data) => {
  let url = "/fuel-location/add";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getAllFuelLocation = async (filter) => {
  let url = "/fuel-location";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.fuelLocation,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const exportFuelLocation = async (filter) => {
  try {
    let url = "/fuel-location/export";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const getAllHemm = async (filter) => {
  let url = "/fuel-dispence/hemm-list";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllFuelSensorHemm = async (filter) => {
  let url = "/fuel-dispence/fuel-sensor-hemm-list";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllHemmChartData = async (filter) => {
  let url = "/fuel-dispence/fuel-sensor-chart";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllTank = async (filter) => {
  let url = "/fuel-config-tank";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.count,
      data: data?.rows,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllSupplier = async (filter) => {
  let url = "/fuel-supplier";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.count,
      data: data?.rows,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllFuelStock = async (filter) => {
  let url = "/fuel-stock?typeFor=tank";

  if (filter) url += "&" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.count,
      data: data?.rows,
      lastSync: response.data.data.lastSync,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllBowserStock = async (filter) => {
  let url = "/fuel-stock?typeFor=bowser";

  if (filter) url += "&" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.count,
      data: data?.rows,
      lastSync: response.data.data.lastSync,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllReceiptDispensed = async (filter) => {
  let url = "/fuel-stock/dispense-receipt";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const exportStockBowser = async (filter) => {
  try {
    let url = "/fuel-stock/export?typeFor=bowser";
    if (filter) url += "&" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const exportStockTank = async (filter) => {
  try {
    let url = "/fuel-stock/export?typeFor=tank";
    if (filter) url += "&" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const getAllReceipt = async (filter) => {
  let url = "/fuel-receipt";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.count,
      data: data?.rows,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllFuelSensor = async (filter) => {
  let url = "/fuel-sensor";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.count,
      data: data?.rows,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getFuelSensorMinMaxDate = async () => {
  let url = "/fuel-sensor/min-max-date";

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data: data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const exportFuelReceipt = async (filter) => {
  try {
    let url = "/fuel-receipt/export";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const exportMyFuelReceipt = async (filter) => {
  try {
    let url = "/fuel-receipt/my-export";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const getMyReceipt = async (filter) => {
  let url = "/fuel-receipt/my";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.count,
      data: data?.rows,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const exportHemmExcel = async (filter) => {
  try {
    let url = "/fuel-dispence/hemm-list-export";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const updateFuelLocation = async (data, id) => {
  let url = "/fuel-location/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const addHemm = async (data) => {
  let url = "/fuel-dispence/hemm-create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateHemm = async (data, id) => {
  let url = "/fuel-dispence/hemm-upate/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const addTank = async (data) => {
  let url = "/fuel-config-tank/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const addSupplier = async (data) => {
  let url = "/fuel-supplier/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const addFuelStock = async (data) => {
  let url = "/fuel-stock/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getStockData = async (filter) => {
  let url = "/fuel-stock/stock-for/?" + filter;
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const addReceipt = async (data) => {
  let url = "/fuel-receipt/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateTank = async (data, id) => {
  let url = "/fuel-config-tank/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const updateSupplier = async (data, id) => {
  let url = "/fuel-supplier/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const updateFuelStock = async (data, id) => {
  let url = "/fuel-stock/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const updateReceipt = async (data, id) => {
  let url = "/fuel-receipt/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getFuelLocationById = async (id) => {
  let url = "/fuel-location/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getFuelLocationByEditId = async (id) => {
  let url = "/fuel-location/site/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getHsdConsumptionData = async (filter) => {
  let url = `/fuel-dispence/dashboard?${filter}`;
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getHsdConsumptionSapData = async (filter) => {
  let url = `/fuel-dispence/sap-dashboard?${filter}`;
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getHsdInventoryData = async (filter) => {
  let url = `/fuel-dispence/dashboard-two?${filter}`;
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {}
};

export const getHsdInventorySapData = async (filter) => {
  let url = `/fuel-dispence/sap-dashboard-two?${filter}`;
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {}
};

export const getHemmDataById = async (id, filter) => {
  let url = "/fuel-dispence/hemm/" + id;
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getHemmDataBydataId = async (id) => {
  let url = "/fuel-dispence/hemm-id/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data, getLastDispensedDate } = response.data;
    const d = {
      status,
      message,
      data,
      getLastDispensedDate,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getTankDataBydataId = async (id, filter) => {
  let url = "/fuel-config-tank/" + id;
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getSupplierById = async (id, filter) => {
  let url = "/fuel-supplier/" + id;
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getStockDataBydataId = async (id, filter) => {
  let url = "/fuel-stock/" + id;
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getReceiptDataBydataId = async (id, filter) => {
  let url = "/fuel-receipt/" + id;
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getFuelSensorDataBydataId = async (id, filter) => {
  let url = "/fuel-sensor/" + id;
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const deleteSensor = async (filter) => {
  let url = "/fuel-sensor/delete";

  if (filter) url += "/" + filter;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllTypeList = async () => {
  let url = "/fuel-dispence/fuel_filter_option";
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getAllTypeListFiltered = async (filter) => {
  let url = "/fuel-dispence/filtered_option";
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getHemmAndMachineSerialList = async (id, filter) => {
  let url = "/fuel-dispence/hemm-serialNo-list/" + id;
  if (filter) url = url + "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getAllFuelingLocation = async (filter) => {
  let url = "/fuel-dispence/fueling_location_option?" + filter;
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

function toRadians(val) {
  let PI = 3.1415926535;
  return (val / 180.0) * PI;
}

export const findDistance = ({ lat1, lat2, lon1, lon2 }) => {
  let R = 6371e3; // R is earth’s radius
  let lat1radians = toRadians(lat1);
  let lat2radians = toRadians(lat2);

  let latRadians = toRadians(lat2 - lat1);
  let lonRadians = toRadians(lon2 - lon1);

  let a =
    Math.sin(latRadians / 2) * Math.sin(latRadians / 2) +
    Math.cos(lat1radians) *
      Math.cos(lat2radians) *
      Math.sin(lonRadians / 2) *
      Math.sin(lonRadians / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  let d = R * c;

  return d;
};

export const fetchData = async (id) => {
  // Implement your dummy API logic here
  // Return the mock data you want to use for testing
  return Promise.resolve({
    status: true,
    data: {
      make: "Test Make",
      model: "Test Model",
      capacity: "Test Capacity",
      hemm_id: "Test HEMM ID",
      fms_vehicle_type: "Test FMS Vehicle Type",
      fms_vehicle_name: "Test FMS Vehicle Name",
    },
  });
};

export const getAllMineLists = async () => {
  let allSites = await getAllSites(
    "filter[siteType]=1,2,3&filter[activeStatus]=active"
  );

  if (!allSites?.data) return;
  const list = allSites.data
    ?.filter((d) => d.siteType === 3)
    .map((d) => {
      return { value: d.id, label: d.name };
    });
  return list;
};

export const getAllWorkshops = async () => {
  let allWorkshops = await getAllWorkshopMasters();
  if (allWorkshops.status) {
    let workshopList = allWorkshops.data.map((d) => {
      return {
        value: d.id,
        label: d.name,
        status: d.status,
      };
    });
    return workshopList;
  }
};

export const getAllBowsers = async () => {
  let filter = "isBowser=1";
  let allBOwser = await getAllHemm(filter);
  if (allBOwser.status) {
    let browserList = allBOwser.data.map((d) => {
      return {
        value: d.id,
        label: d.hemm_id,
        status: d.status,
        site_id: d.site_id,
      };
    });
    return browserList;
  }
};

export const getAllTanks = async () => {
  let tankData = await getAllTank();
  if (tankData.status) {
    let tankList = tankData.data.map((d) => {
      return {
        value: d.id,
        label: d.name,
        status: d.status,
        site_id: d.site_id,
      };
    });
    return tankList;
  }
};

export const fuelWorkshop = async () => {
  let fuelLocation = await getAllFuelLocation();
  if (fuelLocation.status) {
    const workshopTankMap = {};
    fuelLocation.data.forEach((entry) => {
      const { workshop_id, tank_id } = entry;
      if (!workshopTankMap[workshop_id]) {
        workshopTankMap[workshop_id] = [tank_id];
      } else {
        workshopTankMap[workshop_id].push(tank_id);
      }
    });
    return workshopTankMap;
  }
};
