import React, { useCallback, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import CheckBoxField from "../../../../components/_form/CheckBoxField/CheckBoxField";
import { useForm } from "../../../../_helpers/hooks";
import {
  MODULE_OBJECT_VIEW,
  USER_MANAGEMENT_ROLE,
  ROLE_OBJECT_VIEW,
} from "../../../../_services/Auth/helper";
import { UserService } from "../../../../_services/UserService/UserService";
import { permissionInitialForm, userInitialForm } from "./DATA";
import toastr from "toastr";

const PermissionsFormEdit = ({
  formData,
  setShowAddEdit,
  getAll,
  USER_MANAGEMENT_ROLE_OBJECT,
}) => {
  const [form, onChange, setForm] = useForm({ ...userInitialForm });
  const [permissionForm, onChangePermission, setPermissionForm] = useForm({
    ...permissionInitialForm,
  });

  const userById = useCallback(async () => {
    const response = await UserService.getUserByID(formData?.id);
    if (!response?.status) return;
    setForm((prev) => ({
      ...prev,
      ...response?.data,
    }));
    setPermissionForm((prev) => ({
      ...prev,
      ...response?.data?.userPermission,
    }));
  }, [formData?.id, setForm, setPermissionForm]);

  useEffect(() => {
    if (!formData?.id) return;
    userById();
  }, [formData?.id, userById]);

  const handleSubmit = async () => {
    const formObj = { ...form, userPermission: permissionForm };

    const response = await UserService.updateUser(formData?.id, formObj);

    if (!response?.status) return toastr.error(response?.message);
    toastr.success(response?.message);
    setShowAddEdit(false);
    getAll();
  };
  return (
    <>
      <Modal.Body className="">
        <div className="preview py-5">
          <div className="">
            <Form>
              <Row>
                {Object.keys(USER_MANAGEMENT_ROLE_OBJECT)?.map(
                  (module, idx) => {
                    const roleData = Object.keys(
                      USER_MANAGEMENT_ROLE_OBJECT[module]
                    )
                      // ?.filter((d) => form?.roleId === 4 && d !== "contractor")
                      ?.map((role, i) => {
                        return (
                          <Col key={i} md={6}>
                            <CheckBoxField
                              name={role}
                              checked={permissionForm?.[role]}
                              onChange={onChangePermission}
                              label={ROLE_OBJECT_VIEW?.[role]}
                              id={`${idx}check${i}`}
                              // disabled={
                              //   form?.roleId === 4 && role === "contractor"
                              // }
                            />
                          </Col>
                        );
                      });
                    return (
                      <>
                        <h6 className="mt-2" key={idx}>
                          {MODULE_OBJECT_VIEW[module]}
                        </h6>{" "}
                        {roleData}
                      </>
                    );
                  }
                )}
              </Row>
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        {/* <Button onClick={() => setShowAddEdit(false)} className="secondaryBtn">
          Close
        </Button> */}
        <Button onClick={() => handleSubmit()} className="primaryBtn">
          Save
        </Button>
      </Modal.Footer>
    </>
  );
};

export default PermissionsFormEdit;
