import React, { useCallback, useEffect, useState } from "react";
import { getAllSites } from "../../../services/_uploadedFiles/uploadedFiles";
import { getDashboardDataNit } from "../../../services/_contractorPerformance/contractorPerformance";
import MultiSelectWithCheckbox from "../../../components/SearchableSelect/MultiSelectCkeckOption";
import {
  Col,
  Container,
  Form,
  Row,
  Card,
  Modal,
  Button,
} from "react-bootstrap";
import { getAllContractor } from "../../../services/_contractorPerformance/contractorPerformance";
import { useForm } from "../../../_helpers/hooks";
import { BasicBarChartCardWithCustomToolTip } from "../../../components/ChartCard/ChartCard";
import RadarChart from "../../../components/Common/RadarChart";
import { useNavigate } from "react-router-dom";
import { getContractorById } from "../../../services/_contractorPerformance/contractorPerformance";
import {
  getAllMonthOptionsData,
  getScoreCardMonthDashboard,
} from "../../../services/_contractorScore/contractorScore";
import {
  formFieldDataError,
  contractorPerformanceDashboardForm,
  radorListVendor,
  formatMonthYearArray,
} from "./DATA";
import Select from "../../../components/Select/Select";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import {
  basicBarChartWithCustomTooltipDataFormat,
  radarDataFormat,
} from "../../../components/Chart/Common/config";
import NoRecord from "../../../components/Common/NoRecord";
import PleaseSelect from "../../../components/Common/PleaseSelect";
import FilesExport from "../../../components/Common/FilesExport";
import { isArray } from "lodash";
import { permissionCheck } from "../../../_services/Auth/helper";
import { useDispatch, useSelector } from "react-redux";
import { setContractorDashFilter } from "../../../redux/actions/UserActions";

const ContractorPerformanceScoreCardMonthDashboard = () => {
  const [form, onChange, setForm] = useForm({
    ...contractorPerformanceDashboardForm,
  });
  const [sites, setSites] = useState("");
  const [allsiteListDrop, setAllSiteListDrop] = useState({});
  const [contractorData, setContractorData] = useState("");
  const [barChart, setBarChart] = useState({});
  const [radorChart, setRadorChart] = useState({});
  const [contractorList, setContractorList] = useState([]);
  const [allMineListDrop, setAllMineListDrop] = useState({});
  const [allContractorList, setAllContractorList] = useState([]);
  const [months, setMonths] = useState([]);
  const [storeFlag, setStoreFlag] = useState(true);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  // const getContractor = async () => {
  //   let contractor = await getContractorById(form?.contractor_id);
  //   if (contractor.status) setContractorData(contractor.data);
  // };

  const storeFilter = useSelector(
    (state) => state?.dashboardFilter?.contractorDash
  );

  const getSiteList = async () => {
    let allsiteList = await getAllSites();
    if (allsiteList?.status === 1 && allsiteList?.message === "success") {
      setSites(allsiteList?.data);
      let dataObjSite = [];
      allsiteList?.data?.forEach((data) => {
        if (data.parentSite.id) {
          const labelExists = dataObjSite.some(
            (obj) => obj.label === data.parentSite.name
          );
          if (!labelExists) {
            dataObjSite.push({
              value: data.parentSite.id,
              label: data.parentSite.name,
            });
          }
        }
      });
      setAllSiteListDrop(dataObjSite);
    }
  };

  const getMonthOptionsData = async () => {
    let res = await getAllMonthOptionsData();
    const formattedMonths = formatMonthYearArray(res?.data?.data);
    setMonths(formattedMonths);
  };

  // useEffect(() => {
  //   if (form?.contractor_id) getContractor();
  // }, [form?.contractor_id]);

  useEffect(() => {
    let dataObjMine = [];
    if (sites.length > 0) {
      sites?.forEach((data) => {
        if (data.siteType === 3) {
          dataObjMine.push({
            value: data?.id,
            label: data?.name,
          });
        }
      });
    }
    setAllMineListDrop(dataObjMine);
  }, [allsiteListDrop]);

  useEffect(() => {
    getSiteList();
    getMonthOptionsData();
    getAll();
    getNitForDashboard();
  }, []);

  useEffect(() => {
    if (allMineListDrop?.length === 1 && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({ ...prevForm, mine: allMineListDrop[0].value }));
    }
  }, [allMineListDrop]);

  useEffect(() => {
    if (allsiteListDrop.length === 1 && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({
        ...prevForm,
        subsidiary: allsiteListDrop[0].value,
      }));
    }
  }, [allsiteListDrop]);

  const getDashboardData = async () => {
    if (!form.month) return;

    let queryString = "";

    if (form.month) {
      queryString += `month=${form.month}&`;
    }

    if (form.vendor) {
      queryString += `vendor=${form.vendor}&`;
    }
    if (form.mine) {
      queryString += `mine=${form.mine}&`;
    }
    if (form.startDate) {
      queryString += `startDate=${form.startDate}&`;
    }
    if (form.endDate) {
      queryString += `endDate=${form.endDate}`;
    }
    let response = await getScoreCardMonthDashboard(queryString);
    if (response?.status) {
      if (response?.data?.data?.barChart.length > 0) {
        let barChart = basicBarChartWithCustomTooltipDataFormat(
          response?.data?.data?.barChart
        );
        setBarChart(barChart);
      } else {
        setBarChart({});
      }
      if (response?.data?.data?.radarChart.length > 0) {
        let radorData = radarDataFormat(
          response?.data?.data?.radarChart,
          "Month",
          radorListVendor
        );
        setRadorChart(radorData);
      } else setRadorChart({});
    }
  };

  const getAll = async () => {
    const allContractorList = await getAllContractor();
    if (Array.isArray(allContractorList) && allContractorList.length) {
      setAllContractorList(allContractorList);
      const uniqueContractorList = allContractorList
        ?.filter((ele) => ele?.contractor?.status)
        ?.reduce((uniqueList, d) => {
          const contractorId = String(d.contractor.id);
          const idExists = uniqueList.some(
            (item) => item.value === contractorId
          );
          if (!idExists) {
            uniqueList.push({
              value: contractorId,
              label: d.contractor.contractor,
            });
          }
          return uniqueList;
        }, []);
      setContractorList(uniqueContractorList);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [form]);

  useEffect(() => {
    if (form?.mine) {
      let mines = allContractorList
        ?.filter((ele) => ele?.contractor?.status)
        ?.filter((obj) => obj.site_id === form?.mine);
      let dataObjMine = [];
      if (mines.length > 0) {
        mines?.forEach((data) => {
          const contractorId = data.contractor.id;

          const idExists = dataObjMine.some(
            (item) => item.value === contractorId
          );

          if (!idExists) {
            dataObjMine.push({
              value: contractorId,
              label: data.contractor.contractor,
            });
          }
        });
      }
      setContractorList(dataObjMine);
    } else setContractorList([]);
    // form.month = "";
  }, [allContractorList, form?.mine]);

  useEffect(() => {
    if (form?.subsidiary && isArray(sites) && sites.length > 0) {
      let dataObjMine = [];
      let mines = sites?.filter((obj) => obj.parentSiteId === form?.subsidiary);
      if (mines.length > 0) {
        mines?.forEach((data) => {
          dataObjMine.push({
            value: data.id,
            label: data.name,
          });
        });
      }
      setAllMineListDrop(dataObjMine);
    } else {
      setAllMineListDrop([]);
      // form.mine = "";
    }
  }, [form?.subsidiary, sites]);

  // To get mine vendor subsidiary for which data available
  const getNitForDashboard = async () => {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    let res = await getDashboardDataNit("score_month", auth_user?.siteId);
    if (res.data != null && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({
        ...prevForm,
        month: String(res?.data?.data?.date),
        mine: res?.data?.data?.siteId || prevForm.mine,
        subsidiary:
          res?.data?.data?.siteData?.parentSite?.id || prevForm.subsidiary,
      }));
    }
  };

  useEffect(() => {
    if (!storeFlag) return;
    if (!Object.keys(storeFilter)?.length) {
      setStoreFlag(false);
      return;
    }
    setForm((prev) => ({
      ...prev,
      mine: storeFilter?.mine,
      subsidiary: storeFilter?.subsidiary,
      vendor: storeFilter?.vendor,
      // ? storeFilter?.vendor?.toString()
      // : storeFilter?.vendor,
    }));
    setStoreFlag(false);
  }, [setForm, storeFilter, storeFlag]);

  useEffect(() => {
    if (storeFlag) return;
    let formObj = {};
    Object.keys(form)?.forEach((d) => {
      if (d !== "month") formObj[d] = +form?.[d];
    });
    dispatch(setContractorDashFilter({ ...storeFilter, ...formObj }));
  }, [dispatch, form, storeFlag]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="dashboardTabs">
            <ul>
              {permissionCheck(["contractor_hindrance_dashboard"]) && (
                <li
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/hindrance`
                    )
                  }
                >
                  Contractor Hindrance
                </li>
              )}

              {permissionCheck(["performance_production_dashboard"]) && (
                <li
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/production-performance`
                    )
                  }
                >
                  Production Performance
                </li>
              )}
              {permissionCheck([
                "contractor_performance_score_card_dashboard",
              ]) && (
                <li
                  className="active"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/performance-score-card-vendor`
                    )
                  }
                >
                  Contractor Score Card
                </li>
              )}

              {permissionCheck(["contractor_performance_dashboard"]) && (
                <li
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/performance`
                    )
                  }
                >
                  Contractor Performance
                </li>
              )}
            </ul>
            <div className="pdfExport noFltr">
              <FilesExport id={`#${"VendorMonthDashboard"}`} fullScreen />
            </div>
            {/* <div className="dashboardFilter" onClick={handleShow}></div> */}
          </div>
          <Container fluid>
            <div className="dashboardWrapper innerDashboards">
              <Row id="VendorMonthDashboard">
                <div className="dashboardHeader breadcrumbWithTitle d-none">
                  <MainBreadcrumb
                    routeSegments={[
                      {
                        name: "Contractor Management",
                        path: "#",
                      },
                      {
                        name: "Contractor Score Card Dashboard",
                        path: "#",
                      },
                    ]}
                  />
                  <h3 class="title mb-0">Contractor Score Card Dashboard</h3>
                </div>
                <div>
                  <Form>
                    <div className="topFilterSec">
                      <div className="colindiaCard borderRadius5">
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <label class="form-label">Month</label>
                              <MultiSelectWithCheckbox
                                options={form?.mine && months}
                                value={form?.month
                                  ?.split(",")
                                  ?.map((role) => {
                                    const found = months?.find(
                                      (obj) => obj.value === role
                                    );
                                    return found ? { ...found } : null;
                                  })
                                  ?.filter((d) => !!d)}
                                onChange={(selectedRoles) => {
                                  onChange({
                                    target: {
                                      name: "month",
                                      value: selectedRoles
                                        ?.map((obj) => obj.value)
                                        .join(","),
                                    },
                                  });
                                }}
                                hasSelectAll={false}
                                disableSearch={false}
                              />
                            </div>
                          </Col>

                          <Col md={3}>
                            <Select
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  month: "",
                                  mine: "",
                                }));
                              }}
                              value={form?.subsidiary}
                              name="subsidiary"
                              options={allsiteListDrop}
                              label={"Subsidiary"}
                              disabled={
                                allsiteListDrop.length === 1 ? true : false
                              }
                            />
                          </Col>

                          <Col md={3}>
                            <Select
                              onChange={onChange}
                              value={form?.mine}
                              name="mine"
                              options={allMineListDrop}
                              label={"Mine"}
                              disabled={
                                allMineListDrop.length === 1 ? true : false
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <Select
                              onChange={onChange}
                              value={form?.vendor}
                              name="vendor"
                              options={contractorList}
                              label={"Vendor"}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {/* data here */}
                    {/* contractorData */}
                    {form.month ? (
                      <Row className="pt-3">
                        <Col md={5}>
                          <Card className="chartCard chartCardBtmSpace ">
                            <Card.Header>
                              Composite Scores across Months
                            </Card.Header>
                            <Card.Body className="pb-5">
                              {Array.isArray(barChart?.categoryData) &&
                              Array.isArray(barChart?.data) ? (
                                <BasicBarChartCardWithCustomToolTip
                                  height="558px"
                                  //yAxisName="Composite Scores across Months"
                                  xAxisName=""
                                  category={barChart.categoryData}
                                  seriesData={barChart.data}
                                  customToolTip={barChart.dataList}
                                />
                              ) : (
                                <NoRecord />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={7}>
                          <Card className="chartCard chartCardBtmSpace">
                            <Card.Header>
                              Vendor Performance across focus Areas
                            </Card.Header>
                            <Card.Body>
                              {Array.isArray(radorChart?.radorDataFormat) &&
                              Array.isArray(radorChart?.radarIndicator) ? (
                                <RadarChart
                                  seriesData={radorChart.radorDataFormat}
                                  radarIndicator={radorChart.radarIndicator}
                                  height="550px"
                                />
                              ) : (
                                <NoRecord />
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      <div className="text-center " style={{ height: "230px" }}>
                        <PleaseSelect type={"Month"} />
                      </div>
                    )}
                  </Form>
                </div>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ContractorPerformanceScoreCardMonthDashboard;
