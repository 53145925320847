import { Button } from "react-bootstrap";
import { submitStatus } from "../../_pages/CoalGrade/DATA";

export const StepButton = ({
  tab,
  tabFun,
  onSubmit,
  nextStapeFun,
  hideSaveBtn,
}) => {
  return (
    <div className="d-flex">
      {tab === 2 && (
        <Button 
        data-testid="back-btn"

        onClick={() => tabFun(1)} className="backBtnWithSave">
          Back
        </Button>
      )}
      {tab === 1 ? (
        <Button
          data-testid="next-btn"
          onClick={() => nextStapeFun()}
          className="primaryBtn"
        >
          Next
        </Button>
      ) : (
        <div className="mobBtn d-flex">
          {!hideSaveBtn && (
            <Button
              onClick={() => onSubmit(submitStatus?.drafted)}
              className="primaryBtn"
              data-testid="saveBtn"
            >
              Save
            </Button>
          )}
          <Button
            data-testid="submitBtn"
            onClick={() => onSubmit(submitStatus?.submitted)}
            className="primaryBtn ms-2"
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};
