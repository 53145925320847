import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import Login from "./_pages/Login/Login";
import ForgotPassword from "./_pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./_pages/ResetPassword/ResetPassword";
import PrivateRoute from "./components/Routing/PrivateRoute";
import { Provider } from "react-redux";
import configureStore from "./redux/Store";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./Assets/Styles/App.scss";
import Error404 from "./_pages/Error404/Error404";
import AwsCognito from "./_pages/AwsCognito/AwsCognito";
import MasterLoginPage from "./_pages/Login/MasterLoginPage";
import { UserService } from "./_services/UserService/UserService";
import { USER_PERMISSIONS } from "./redux/reducers/CONSTANT";
import toastr from "toastr";
import { useEffect, useState } from "react";
import { isLogin } from "./_helpers/helper";

let { store, Store } = configureStore();

function App() {
  const [isBusy, setBusy] = useState(true);

  const getPermission = async () => {
    const response = await UserService.getPermissionsByUser();
    if (!response?.status || response?.status === 401) {
      !response?.status && toastr.error(response?.message);
      return;
    }
    const permissionData = JSON.parse(response?.data);
    Store.dispatch({
      type: USER_PERMISSIONS,
      data: permissionData,
    });
    setBusy(false);
  };

  useEffect(() => {
    if (!isLogin()) {
      setBusy(false);
      return;
    }
    getPermission();
  }, []);

  return (
    !isBusy && (
      <>
        <Provider store={store}>
          <Routes>
            <Route path="/auth/token/:token" element={<MasterLoginPage />} />
            <Route path="/mobile/auth/login" element={<Login />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/reset-password/:id" element={<ResetPassword />} />
            <Route path="/auth/forgotpassword" element={<ForgotPassword />} />
            <Route path="/auth/callback" element={<AwsCognito />} />
            <Route path="/error/404" element={<Error404 />} />
            <Route path="/*" element={<PrivateRoute />} />
          </Routes>
        </Provider>
      </>
    )
  );
}

export default App;
