import React from "react";
import { Col, Form } from "react-bootstrap";
import Select from "../../../../components/Select/Select";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import moment from "moment";
import { getArraayofKeys } from "../Helper";
import { capaPriority } from "../../DATA";
import AsterikOnly from "../../../../components/Common/Asterik/AstrikOnly";
const CapasComponent = ({
  form,
  capas = [],
  error = {},
  capasErrors = {},
  onChangeCapa = () => {},
  addCapa = () => {},
  deleteCapa = () => {},
  users = [],
  departments = [],
  incidentsOnly,
  viewOnly,
  area = [],
  capaowner = [],
  approveCapa,
  approverLevel,
  showReject = false,
  siteId,
}) => {
  console.log("caaaapaaaas", capas);
  return (
    <div className="FormWrapper">
      <Form>
        <Col md="12">
          <div className="formWrapper rootCause">
            <div className="table-responsive overflow-inherit">
              <table className="table table-bordered">
                <thead className="tableHead-bg">
                  <tr>
                    <th>S.No.</th>
                    <th>
                      CAPA Type
                      <AsterikOnly />
                    </th>
                    <th style={{ minWidth: "150px" }}>
                      CAPA Description
                      <AsterikOnly />
                    </th>
                    <th>
                      CAPA Owner
                      <AsterikOnly />
                    </th>
                    <th>
                      CAPA Priority
                      <AsterikOnly />
                    </th>
                    <th>
                      Due Date
                      <AsterikOnly />
                    </th>
                    <th>
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {capas.length ? (
                    capas.map((x, index) => {
                      return x.deleted ? null : (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Select
                              name="capaType"
                              value={x.capaType}
                              onChange={({ target: { name, value } }) =>
                                onChangeCapa({ name, value, index })
                              }
                              id="id"
                              options={["Corrective", "Preventive"]}
                              error={capasErrors[index]?.capaType}
                              init="none"
                              array="single"
                            ></Select>
                          </td>
                          <td>
                            <FormInputText
                              type="text"
                              name="remarks"
                              data-testid="onChangeCapaField"
                              onChange={({ target: { name, value } }) =>
                                onChangeCapa({
                                  index,
                                  name,
                                  value,
                                })
                              }
                              value={x.remarks}
                              className="form-control"
                              error={capasErrors[index]?.remarks}
                            />
                          </td>
                          <td>
                            <Select
                              name="ownerId"
                              value={x.ownerId}
                              onChange={({ target: { name, value } }) =>
                                onChangeCapa({
                                  index,
                                  name,
                                  value,
                                })
                              }
                              options={users}
                              error={capasErrors[index]?.ownerId}
                              KEY_label={"name_email_employeeId"}
                              placeholder={"Search by Name / Email Id"}
                              KEY_value={"id"}
                              blankSelect={"none"}
                            ></Select>
                          </td>
                          <td>
                            <Select
                              name="priority"
                              data-testid="capa_assigned_change"
                              value={x.priority}
                              onChange={({ target: { name, value } }) =>
                                onChangeCapa({
                                  index,
                                  name,
                                  value,
                                })
                              }
                              options={getArraayofKeys(capaPriority)}
                              object={{
                                low: "Low",
                                medium: "Medium",
                                high: "High",
                              }}
                              error={capasErrors[index]?.priority}
                              blankSelect={"none"}
                            ></Select>
                          </td>

                          <td style={{ borderRight: "3px solid #dee2e6" }}>
                            <CustomDatePicker
                              data-testid={"customDate"}
                              value={x.dueDate}
                              name="dueDate"
                              onChange={({ target: { name, value } }) =>
                                onChangeCapa({
                                  index,
                                  name,
                                  value,
                                })
                              }
                              dateFormat="YYYY-MM-DD"
                              error={capasErrors[index]?.dueDate}
                              isValidDate={(day) =>
                                moment(
                                  !form?.capa_start_time
                                    ? moment().format("YYYY-MM-DD HH:mm")
                                    : form?.capa_start_time
                                ).isBefore(moment(day))
                              }
                            />
                          </td>
                          <td className="text-center">
                            <div className="actionIconsGroup">
                              <span
                                data-testid={"remove-leaf" + index}
                                onClick={() => deleteCapa(index)}
                                className="icons font-21 text-danger"
                              >
                                <AiOutlineMinusCircle
                                  size="24"
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center"></td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="12">
                      <span
                        className="addTxt"
                        onClick={addCapa}
                        data-testid="add-leaf"
                      >
                        <AiOutlinePlus />
                        Add
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Form>
    </div>
  );
};

export default CapasComponent;
