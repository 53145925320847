import React from "react";
import ReactEcharts from "echarts-for-react";
import NoRecord from "./NoRecord";
import { isDecimal } from "../../_helpers/helper";
import { DDMMYYYYFormat } from "../../_helpers/dateHelper";

const StackWithLineChart = ({
  data,
  onlyBar,
  series,
  category,
  yAxisName,
  xAxisName,
  customTooltip,
  hideXaxis,
  customCoalGradeTooltip,
}) => {
  
  const chartData = data
    ? data?.map((item) => {
        return {
          name: item?.name,
          type: item?.type || "bar",
          stack: "total",
          label: {
            show: true,
          },
          emphasis: {
            focus: "series",
          },
          data: item?.value,
        };
      })
    : [];

  const barData = chartData?.filter((item) => item?.type === "bar") || [];
  const lineData = chartData?.filter((item) => item?.type === "line") || [];
  const seriesData =
    onlyBar === true ? [...chartData] : [...barData, ...lineData];

  const option = {
    tooltip: customCoalGradeTooltip
      ? {
          formatter: function (params, ticket, callback) {
            let type =
              params.name === "SHRA"
                ? "Rail"
                : params.name === "SHRO"
                ? "Road"
                : params.name === "SHMG"
                ? "Merry Go Round"
                : "";
            let string = "";
            string =
              `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
              params.color +
              `;\"></span>${params.seriesName} : ${isDecimal(
                params.value
              )} ${yAxisName}<br>`;
            if (type) {
              string += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${type}<br>`;
            }

            return "<span > " + string + "</span>";
          },
        }
      : customTooltip
      ? {
          formatter: function (params, ticket, callback) {
            let string = "";
            if (hideXaxis === true) {
              string =
                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params.color +
                `;\"></span>${params.name}<br>` + // Include x-axis value
                `<span style="color:${params.color}">●</span> ${
                  params.seriesName
                } : ${isDecimal(params.value)} ${yAxisName}<br>
                <span style="color:${
                  params.color
                }">●</span> <span>Date Duration: (${DDMMYYYYFormat(
                  params?.data?.min_date
                )} - ${DDMMYYYYFormat(params?.data?.max_date)})</span>
                `;
            } else {
              string =
                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params.color +
                `;\"></span>${params.seriesName} : ${isDecimal(
                  params.value
                )} ${yAxisName}<br>`;
            }
            return "<span > " + string + "</span>";
          },
        }
      : {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
    legend: {
      to: 100,
      type: "scroll",
      itemGap: 20,
      // horizontalAlign: 'center',
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: category || data?.map((d) => d?.date),
      name: xAxisName ? xAxisName : "",
      axisLabel: {
        show: hideXaxis === true ? false : true,
      },
    },
    yAxis: {
      type: "value",
      name: yAxisName ? yAxisName : "",
      min: 0,
      minInterval: 1,
      nameTextStyle: {
        fontWeight: "bold",
      },
    },
    series: series || seriesData,
  };

  return (
    <>
      {Array.isArray(series || seriesData) &&
      (series.length || seriesData.length) > 0 ? (
        <ReactEcharts
          option={option}
          style={{ height: "300px" }}
          notMerge={true}
          replaceMerge={["series", "legend"]}
        />
      ) : (
        <NoRecord />
      )}
    </>
  );
};

export default StackWithLineChart;
