import React, { useEffect, useState } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import {
  uploadedFilesApis,
  fields,
  initialFilters,
  exportUploadedFile,
  reportTypeListObj,
  SidebarData,
  reportTypeListTableLabel,
} from "./DATA";
import { Button } from "reactstrap";
import filter from "../../../Assets/Images/filter.svg";
import { useForm } from "../../../_helpers/hooks";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import UploadFileView from "./UploadFileView";
import { getAllUploadedFiles } from "../../../../src/services/_uploadedFiles/uploadedFiles";
import UploadFileFilterForm from "../../../../src/_pages/EnvironmentManagement/UploadedFiles/UploadFileFilter";
import { permissionCheck } from "../../../_services/Auth/helper";
import { useLocation } from "react-router-dom";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { PARSE_IMG_URL } from "../../../_helpers/commonHelper";
import { URL_CONFIG } from "../../../_constants/Config/URL_CONFIG";

const UploadedFileList = () => {
  const [form, onChange, setForm] = useForm(initialFilters);
  const [filterStatus, setFilterStatus] = useState(0);
  const [getAllFilter, setGetAllFilter] = useState();
  const [isListData, setIsListData] = useState(true);
  const urlFileType = useLocation().search.replace("?type=", "");
  const viewAsPopup = (form) => {
    return <UploadFileView form={form} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    const onSubmitFilter = () => {
      setGetAllFilter("&fileType=" + reportTypeListObj[urlFileType]);
      setFilterStatus((prev) => prev + 1);
    };

    const onResetFilter = () => {
      setFilterStatus(0);
    };
    const getAll = async () => {
      const response = await getAllUploadedFiles(
        "fileType=" + reportTypeListObj[urlFileType]
      );
      if (Array.isArray(response?.data) && response?.data.length > 0) {
        setIsListData(true);
      } else setIsListData(false);
    };

    useEffect(() => {
      getAll();
      setGetAllFilter();
      setGetAllFilter("&fileType=" + reportTypeListObj[urlFileType]);
      if (filterStatus === 0) {
        // setGetAllFilter();
        setForm(initialFilters);
      } else {
        let filter = "";
        for (const key in form) {
          const element = form[key];

          if (element) {
            if (key == "startDate" || key == "endDate")
              filter += `&${key}=${element}`;
            else filter += `&search[${key}]=${element}`;
          }
        }
        setGetAllFilter(getAllFilter + filter);
      }
    }, [filterStatus]);
    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="filterBtn"
          data-testid="filter-button-upload-list"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <UploadFileFilterForm
          isOpen={open}
          setOpen={setOpen}
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
        />
      </div>
    );
  };
  const exportUploadedFiles = (filter, row) => {
    let a = document.createElement("a");
    const downloadURL = PARSE_IMG_URL(URL_CONFIG.DEV_URL, row?.file_path);
    a.href = downloadURL;
    a.download = row.org_filename;
    a.click();
  };

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={fields}
                apis={uploadedFilesApis}
                title={reportTypeListTableLabel[urlFileType]}
                pageLink="/environment-management/uploaded-files"
                tableTitle="Environment Management"
                FilterBtn={FilterBtn}
                primaryKey="id"
                getAllFilter={getAllFilter}
                hideAddButton={true}
                hideSnColumn={true}
                // viewAsPopup={viewAsPopup}
                downloadExcel={exportUploadedFiles}
                customTableTitle={`${reportTypeListTableLabel[urlFileType]} Uploaded Files`}
                isNoTabOnPage
                breadCrumb={[
                  {
                    name: "Environment Management System",
                    path: "#",
                  },
                  {
                    name: "Environment Management",
                    path: "/environment-management/manage",
                  },
                  {
                    name: reportTypeListTableLabel[urlFileType],
                    path: "/environment-management/manage/" + urlFileType,
                  },
                  {
                    name: "Upload File",
                    path: "#",
                  },
                ]}
                excelFilterOption={
                  "file_type=" + reportTypeListObj[urlFileType]
                }
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            // pageLink={"/environment-management/uploaded-files"}
            title={`${reportTypeListTableLabel[urlFileType]} Uploaded Files`}
            hideAddBtn={true}
            breadCrumb={[
              {
                name: "Environment Management System",
                path: "#",
              },
              {
                name: "Environment Management",
                path: "/environment-management/manage",
              },
              {
                name: reportTypeListTableLabel[urlFileType],
                path: "/environment-management/manage/" + urlFileType,
              },
              {
                name: "Upload File",
                path: "#",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadedFileList;
