import React from "react";
import { Button, Modal } from "react-bootstrap";
import success_img from "../../Assets/Images/success_img.svg";

const MessagePopup = ({
  show,
  setShow,
  closePopup,
  messagePopupType,
  title,
}) => {
  // console.log("000000000000000",show);
  // console.log("000000000000000messagePopupType",messagePopupType);
  return (
    <Modal show={show} onHide={() => setShow(false)} centered backdrop="static">
      <Modal.Header
        closeButton={messagePopupType ? true : false}
        className="border-bottom-0"
      ></Modal.Header>
      <Modal.Body className="p-5 text-center">
        {!messagePopupType ? (
          <>
            {/* {console.log("commmminggggggg3333",messagePopupType)} */}
            <img src={success_img} className="img-fluid" alt="successful" />
            <h2 className="title mt-5">{`Successfully ${
              title || "submitted"
            } data!`}</h2>
            <Button
              className="primaryBtn mt-3"
              data-testid="message-popp-button"
              onClick={() => closePopup()}
            >
              Okay
            </Button>
          </>
        ) : (
          <>
            <h2 className="title">Close</h2>
            <p className="">Are you sure you want to close?</p>
            <Button
              className="secondaryBtn mt-3 me-3"
              data-testid="hide-message-popup"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              className="primaryBtn  mt-3"
              data-testid="message-popp-button"
              onClick={() => closePopup()}
            >
              Yes
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MessagePopup;
