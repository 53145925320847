import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "../../../components/Select/Select";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { Button, Col, Row } from "react-bootstrap";
import ScreenButton from "../../../components/ScreenButton/ScreenButton";
import MultiSelectWithCheckbox from "../../../components/SearchableSelect/MultiSelectCkeckOption";
import {
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
  idByCheckMine,
  subsidiaryListByMine,
} from "../../../_helpers/helper";
const CoalGradeDashboardFilter = ({
  title,
  screens,
  isActive,
  form,
  onChange,
  company,
  mine,
  customer,
  samplingAgency,
  dispatchMode,
  measuredGrade,
  resetFun,
  applyFun,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const applyFilter = () => {
    setShow(false);
    applyFun();
  };
  if (company.length === 1) {
    form.companyId = company[0].id;
  }
  if (mine.length === 1) {
    form.mineId = mine[0].id;
  }

  return (
    <>
      <div className="dashboardHeader">
        <h3 className="title mb-0">{title}</h3>
        <ScreenButton
          isActive={isActive}
          screens={screens}
          handleShow={handleShow}
        />
      </div>
      <Modal
        className="filterModal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="px-3">
          <h5 className="modal-title">Filter</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <div className="form-group mb-3">
              <Select
                options={company}
                label={"Company"}
                name="companyId"
                value={form?.companyId || ""}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
                onChange={onChange}
                disabled={
                  // !!params?.id ||
                  IS_USER_SITE_MINES() || IS_USER_SITE_RO()
                }
              />
            </div>
            <div className="form-group mb-3">
              <Select
                blankSelect={"Select"}
                label={"Mine"}
                name="mineId"
                value={form?.mineId || ""}
                onChange={onChange}
                options={mine}
                KEY_label={"name"}
                KEY_value={"id"}
                disabled={IS_USER_SITE_MINES()}
                menuPortalTarget={null}
              />
            </div>

            <div className="form-group mb-3">
              {/* <Select
                label={"Dispatch Mode"}
                name="dispatchModeId"
                value={form?.dispatchModeId || ""}
                onChange={onChange}
                options={dispatchMode}
                // error={error?.areaId}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
              /> */}
              <label class="form-label">Dispatch Mode</label>
              <MultiSelectWithCheckbox
                options={dispatchMode}
                onChange={(selectedRoles) => {
                  onChange({
                    target: {
                      name: "dispatchModeId",
                      value: selectedRoles?.map((obj) => obj.value).join(","),
                    },
                  });
                }}
                value={form?.dispatchModeId
                  ?.split(",")
                  ?.map((role) => {
                    const foundObj = dispatchMode?.find(
                      (obj) => obj.value === role
                    );
                    return foundObj ? { ...foundObj } : null;
                  })
                  ?.filter((d) => !!d)}
                disableSearch={false}
                hasSelectAll={false}
              />
            </div>
            <div className="form-group mb-3">
              <label class="form-label">Customer</label>
              <MultiSelectWithCheckbox
                options={customer}
                onChange={(selectedRoles) => {
                  onChange({
                    target: {
                      name: "customerId",
                      value: selectedRoles?.map((obj) => obj.value).join(","),
                    },
                  });
                }}
                value={form?.customerId
                  ?.split(",")
                  ?.map((role) => {
                    const foundObj = customer?.find(
                      (obj) => obj.value === role
                    );
                    return foundObj ? { ...foundObj } : null;
                  })
                  ?.filter((d) => !!d)}
                disableSearch={false}
                hasSelectAll={false}
              />
            </div>
            {/* 

            <Select
                label={"Sapling Agency"}
                name="samplingAgencyId"
                value={form?.samplingAgencyId || ""}
                onChange={onChange}
                options={samplingAgency}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
              /> */}

            <div className="form-group mb-3">
              <label class="form-label">Sapling Agency</label>
              <MultiSelectWithCheckbox
                options={samplingAgency}
                onChange={(selectedRoles) => {
                  onChange({
                    target: {
                      name: "samplingAgencyId",
                      value: selectedRoles?.map((obj) => obj.value).join(","),
                    },
                  });
                }}
                value={form?.samplingAgencyId
                  ?.split(",")
                  ?.map((role) => {
                    const foundObj = samplingAgency?.find(
                      (obj) => +obj.value === +role
                    );
                    return foundObj ? { ...foundObj } : null;
                  })
                  ?.filter((d) => !!d)}
                disableSearch={false}
                hasSelectAll={false}
              />
            </div>

            <div className="form-group mb-3">
              <Select
                label={"Measured / Analyzed Grade"}
                name="measuredGradeId"
                value={form?.measuredGradeId || ""}
                onChange={onChange}
                options={measuredGrade}
                // error={error?.areaId}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
              />
            </div>
            <Row>
              <label className="pb-2">Date Duration</label>
              <Col lg={12}>
                <CustomDatePicker
                  onChangeDate={onChange}
                  name="startDate"
                  value={form?.startDate}
                  dateFormat="YYYY-MM-DD"
                  placeholder={"Start Date"}
                />
              </Col>
              <Col lg={12}>
                <CustomDatePicker
                  dateFormat="YYYY-MM-DD"
                  // className={"cndrPosition"}
                  value={form?.endDate}
                  name="endDate"
                  onChangeDate={onChange}
                  placeholder={"End Date"}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="filterBtn">
            <Button
              className="secondaryBtn"
              style={{ minWidth: "auto" }}
              onClick={() => resetFun()}
            >
              Reset
            </Button>
            <Button
              className="primaryBtn"
              style={{ minWidth: "auto" }}
              data-testid="close-message-popup"
              onClick={() => applyFilter()}
            >
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CoalGradeDashboardFilter;
