import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "../../SafetyManagementSystem/IncidentManagement/Dashboard/Dashboard.scss";
import FuelCard from "./components/FuelCard";
import { Container, Card, Col, Row, Table } from "react-bootstrap";
import { getHsdInventoryData } from "../../../services/_fuelDispense/fuelDispense";
import {
  CONVERT_KL_L_INVENTORY,
  fuelDispenseDetails,
  fuelReceiptDetails,
  fuelTypeObj,
  getFuelReceiptDashboardTbl,
  inventoryWidgetData,
} from "./DATA";
import StackWithLineChart from "../../../components/Common/StackWithLineChart";
import { FuelDispencestackChartDataFormat } from "../../../components/Chart/Common/config";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import DataTableComponent from "../../../components/DataTableComponent/DataTableComponent";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import {
  IS_MOBILE,
  KL_TO_L,
  L_TO_KL,
  isDecimal,
} from "../../../_helpers/helper";

const HsdInventory = ({ filterForm, cref, tabValIs, form, setMinMaxDate }) => {
  const [inventoryData, setInventoryData] = useState({});
  const [filterDataBy, setFilterDataBy] = useState();
  const [pageTable1, setPageTable1] = useState(1);
  const [receiptDataList, setReceiptDataList] = useState([]);
  const [fuelDataList, setFuelDataList] = useState([]);
  const [dailyFuelDataList, setDailyFuelDataList] = useState([]);
  const [pageTable2, setPageTable2] = useState(1);
  const tableRef1 = useRef(null);
  const tableRef2 = useRef(null);
  const fetchData = useCallback(
    async (filter) => {
      let response = await getHsdInventoryData(filter);
      if (response?.status === 1) {
        setPageTable2(1);
        setPageTable1(1);
        setFuelDataList(response?.data?.fuelDispnecDetails || []);
        setReceiptDataList(response?.data?.fuelReceiptDetails || []);
        setDailyFuelDataList(response?.data?.dailyFuelReceiptDetails || []);
        const fuelInventoryTrendData = FuelDispencestackChartDataFormat(
          response?.data?.fuelInventoryTrend || {}
        );

        const FinalFilterData =
          tabValIs === "KL"
            ? CONVERT_KL_L_INVENTORY(
                {
                  ...response?.data,
                  fuelInventoryTrend: fuelInventoryTrendData,
                },
                tabValIs
              )
            : {};

        if (tabValIs === "L") {
          FinalFilterData["fuelInventoryTrend"] = {
            ...fuelInventoryTrendData,
          };
        }
        setInventoryData({
          ...response?.data,
          ...FinalFilterData,
          // fuelInventoryTrend: fuelInventoryTrendData,
        });
        setMinMaxDate(response?.data?.minMax_date || {});
      }
    },
    [tabValIs]
  );

  useImperativeHandle(
    cref,
    () => ({
      dashboardDataFun: () => fetchData(filterForm),
    }),
    [fetchData, filterForm]
  );

  useEffect(() => {
    fetchData(filterForm);
  }, [fetchData]);

  useEffect(() => {
    const FinalFilterData = CONVERT_KL_L_INVENTORY(inventoryData, tabValIs);
    setInventoryData((prev) => ({ ...prev, ...FinalFilterData }));
  }, [tabValIs]);

  const fuelReceiptDashboardTblFunc = async (filter, type, setPage) => {
    const response = await getFuelReceiptDashboardTbl(filter);

    if (!response?.status) {
      setPage((pre) => pre - 1);
      return;
    }
    let appendData = response?.data?.fuelReceiptDatas || [];
    if (type === fuelTypeObj?.receiptData) {
      if (tabValIs === "KL") {
        appendData = appendData?.map((eleObj) => {
          Object.assign(eleObj, {
            Receipt_Qty: isDecimal(eleObj?.Receipt_Qty / 1000),
          });
          return eleObj;
        });
      }
      setReceiptDataList((pre) => [...pre, ...appendData]);
    }
    if (type === fuelTypeObj?.dailyFuelUpdate) {
      if (tabValIs === "KL") {
        appendData = appendData?.map((eleObj) => {
          Object.assign(
            eleObj,
            { closingStock: isDecimal(eleObj?.closingStock / 1000) },
            { dispense: isDecimal(eleObj?.dispense / 1000) },
            { openingStock: isDecimal(eleObj?.openingStock / 1000) },
            { receipt: isDecimal(eleObj?.receipt / 1000) },
            { openingDip: isDecimal(eleObj?.openingDip / 1000) }
          );
          return eleObj;
        });
      }
      setDailyFuelDataList((pre) => [...pre, ...appendData]);
    } else {
      if (tabValIs === "KL") {
        appendData = appendData?.map((ele) => {
          Object.assign(ele, {
            Fueling_Qty: isDecimal(ele?.Fueling_Qty / 1000),
          });
          return ele;
        });
      }
      setFuelDataList((prev) => [...prev, ...appendData]);
    }
  };

  const dailyFuelDetails = [
    {
      selector: "date",
      name: "Date",
      cell: (row) => (row?.date ? DDMMYYYYFormat(row?.date) : ""),
      isShowMobile: true,
    },
    {
      selector: "tank",
      name: form?.inventoryTypeBowser ? "Bowser" : "Tank",
      isShowMobile: true,
    },
    {
      selector: "openingStock",
      name: "Opening Stock",
      cell: (row) =>
        tabValIs === "L"
          ? isDecimal(row?.openingStock)
          : isDecimal(row?.openingStock / 1000),
    },
    {
      selector: "receipt",
      name: "Receipt",
      cell: (row) =>
        tabValIs === "L"
          ? isDecimal(row?.receipt)
          : isDecimal(row?.receipt / 1000),
    },
    {
      selector: "dispense",
      name: "Dispense",
      cell: (row) =>
        tabValIs === "L"
          ? isDecimal(row?.dispense)
          : isDecimal(row?.dispense / 1000),
    },
    {
      selector: "closingStock",
      name: "Closing Stock",
      cell: (row) =>
        tabValIs === "L"
          ? isDecimal(row?.closingStock)
          : isDecimal(row?.closingStock / 1000),
    },
    {
      selector: "openingDip",
      name: "Opening Dip",
      cell: (row) =>
        tabValIs === "L"
          ? isDecimal(row?.openingDip)
          : isDecimal(row?.openingDip / 1000),
    },
    {
      selector: "closingDip",
      name: "Closing Dip",
      cell: (row) =>
        tabValIs === "L"
          ? isDecimal(row?.closingDip)
          : isDecimal(row?.closingDip / 1000),
    },
  ];

  const queryString = Object.keys(form)
    .filter((key) => form[key] !== "") // Exclude empty values
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(form[key]))
    .join("&");

  return (
    <div className="dashboardWrapper">
      <Col>
        <div className="dashCards">
          <div
            className={`${
              !IS_MOBILE() ? "d-flex" : ""
            } justify-content-between`}
          >
            {inventoryWidgetData(inventoryData, tabValIs, form).map(
              (item, i) => (
                <div
                  className={`dashSmallCard ${
                    !IS_MOBILE() ? "top-card" : "mb-3"
                  } `}
                >
                  <FuelCard
                    vertical={true}
                    key={i}
                    label={item?.label}
                    quantity={item?.quantity}
                    additionalClass={!IS_MOBILE() && "horzText"}
                    maxDate={
                      form.endDate
                        ? form.endDate
                        : inventoryData?.minMax_date?.maxDate
                    }
                  />
                </div>
              )
            )}
          </div>
        </div>
      </Col>
      <Row id="screen2">
        <Col className="dashboardRightCharts" id={"zoomSecFuelInventoryTrend"}>
          <Card className="chartCard chartCardBtmSpace">
            <Card.Header className="d-flex justify-content-between align-items-center">
              Daily Fuel Inventory Trend{" "}
              {form?.inventoryTypeBowser ? "(Bowser)" : "(Tank)"}
              <ZoomScreen
                id={`zoomSecFuelInventoryTrend`}
                cardSizeClass={"dashboardRightCharts col"}
              />
            </Card.Header>
            <Card.Body>
              <StackWithLineChart
                customTooltip
                series={inventoryData?.fuelInventoryTrend?.data || []}
                yAxisName={tabValIs}
                category={inventoryData?.fuelInventoryTrend?.categoryData}
              />
            </Card.Body>
          </Card>
          <Row>
            <Col>
              <Card className="chartCard tableCard totalValueTbl">
                <Card.Header>
                  Daily Fuel Details{" "}
                  {form?.inventoryTypeBowser ? "(Bowser)" : "(Tank)"}
                </Card.Header>
                <Card.Body
                  className="p-0"
                  style={{ marginBottom: IS_MOBILE() ? "40px" : "" }}
                >
                  <DataTableComponent
                    data={dailyFuelDataList}
                    columns={dailyFuelDetails}
                    page={pageTable2}
                    setPage={setPageTable2}
                    ref={tableRef2}
                    tableName={fuelTypeObj?.dailyFuelUpdate}
                    totalCount={inventoryData?.totalCountFuel}
                    matchCount={dailyFuelDataList?.length}
                    onCallScrollFunc={fuelReceiptDashboardTblFunc}
                    filterData={filterForm}
                    fixedHeader={true}
                    getApi={getFuelReceiptDashboardTbl}
                    filterDataBy={"&type=dailyFuelUpdate&" + queryString}
                    dashboardType="fuelDashboard2"
                    tabValIs={tabValIs}
                  />
                  <div
                    className="tblTotalData"
                    style={{ display: IS_MOBILE() ? "block" : "" }}
                  >
                    <div className={IS_MOBILE() ? `mb-3` : ""}>
                      <label>Total Dispensed:</label>
                      <strong className="me-5">
                        {(isDecimal(inventoryData?.dailyFuelDispense) || 0) +
                          " " +
                          tabValIs}
                      </strong>
                    </div>
                    <div>
                      <label>Total Receipt:</label>
                      <strong>
                        {(isDecimal(inventoryData?.dailyFuelReceipt) || 0) +
                          " " +
                          tabValIs}
                      </strong>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default HsdInventory;
