import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomRadio from "../../../../../components/_form/CustomRadio/CustomRadio";
import FormInputText from "../../../../../components/FormInputText/FormInputText";
import Select from "../../../../../components/Select/Select";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import {
  BODY_PART_LIST,
  STAFFEMPLOYER,
  checkValidCount,
  injuryCodeObj,
  viewStaffType,
} from "../../DATA";
import ReportButton from "../ReportButton/ReportButton";
import { getArraayofKeys } from "../../Helper";
import MultiSelectWithCheckbox from "../../../../../components/SearchableSelect/MultiSelectCkeckOption";
import {
  checkNumberString,
  digitCheckAfterDecimal,
} from "../../../../../_helpers/helper";
import Asterik from "../../../../../components/Common/Asterik/Asterik";
import CustomDatePickerBasic from "../../../../../components/DateTimeCustom/CutomDatePickerBasic";

const PersonnelDetailsTab = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  personalDetails,
  onChangePersonalDetails,
  personalDetailsErrors,
  onChangePersonalDetailsEmployee,
  deleteRowPersonalDetails,
  addRowPersonalDetails,
  users,
  contractors,
  employeeList,
  contractorsStaff,
  tab,
  designationList,
  asterikRules,
  personalAsterikRules,
  outcomeOpt,
}) => {
  return (
    <>
      <div className="FormWrapper">
        <Form>
          <Row>
            <Col lg={4}>
              <CustomRadio
                type="checkbox"
                name="isPersonalInjury"
                onChange={onChange}
                title={"Personnel Affected"}
                acceptTitle={"Yes"}
                rejectTitle={"No"}
                customClass={"customRadioClass"}
                value={
                  form.isPersonalInjury === null ? false : form.isPersonalInjury
                }
              />
              <span className="small text-danger">
                {error?.isPersonalInjury}
              </span>
            </Col>
          </Row>
          <Row>
            {form.isPersonalInjury && (
              <>
                <Col lg={3}>
                  <FormInputText
                    readOnly={!form.isPersonalInjury}
                    name="peopleCount"
                    value={form.peopleCount}
                    onChange={(event) =>
                      event.target.value >= 0 &&
                      event.target.value <= 99999 &&
                      onChange(event)
                    }
                    type="number"
                    label={"Number of people involved"}
                    error={error?.peopleCount}
                    asterikRules={asterikRules}
                  />
                </Col>
                <Col lg={2}>
                  <FormInputText
                    type={"number"}
                    label={"Injuries Count"}
                    name="number_of_injuries"
                    value={form?.number_of_injuries}
                    onChange={(event) =>
                      event.target.value >= 0 &&
                      checkValidCount(
                        +form.peopleCount,
                        +event.target.value,
                        +form?.number_of_fatality,
                        form?.category,
                        event.target.name
                      ) &&
                      onChange(event)
                    }
                    // onChange={onChange}
                    error={error?.number_of_injuries}
                    minLength={0}
                    maxLength={+form.peopleCount}
                    asterikRules={asterikRules}
                    disabled={true}
                  ></FormInputText>
                </Col>
                <Col lg={2}>
                  <FormInputText
                    type={"number"}
                    label={"Fatality Count"}
                    name="number_of_fatality"
                    value={form?.number_of_fatality}
                    onChange={(event) =>
                      event.target.value >= 0 &&
                      checkValidCount(
                        +form.peopleCount,
                        +form?.number_of_injuries,
                        +event.target.value,
                        form?.category,
                        event.target.name
                      ) &&
                      onChange(event)
                    }
                    error={error?.number_of_fatality}
                    minLength={0}
                    asterikRules={asterikRules}
                    disabled={true}
                  ></FormInputText>
                </Col>
                <Col lg={5}>
                  <FormInputText
                    label={"Location of Accident/Incident"}
                    name="place_of_incident"
                    value={form?.place_of_incident}
                    onChange={onChange}
                    error={error?.place_of_incident}
                    asterikRules={asterikRules}
                  ></FormInputText>
                </Col>
                <Col md="12">
                  <div className="formWrapper rootCause">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        style={{ minHeight: 350 }}
                      >
                        <thead className="tableHead-bg">
                          <tr>
                            <th>S.No.</th>
                            <th style={{ minWidth: 180 }}>Type</th>
                            <th style={{ minWidth: 300 }}>
                              Name
                              <Asterik
                                fieldName={"name"}
                                requiredRules={personalAsterikRules}
                              />
                            </th>
                            <th style={{ minWidth: 180 }}>
                              Employer / Contractor
                            </th>
                            {/* <th style={{ minWidth: 180 }}>Employer</th>
                        <th style={{ minWidth: 50 }}>ID</th> */}
                            <th style={{ minWidth: 180 }}>
                              Designation
                              <Asterik
                                fieldName={"designation"}
                                requiredRules={personalAsterikRules}
                              />
                            </th>
                            <th style={{ minWidth: 150 }}>
                              Gender
                              <Asterik
                                fieldName={"gender"}
                                requiredRules={personalAsterikRules}
                              />
                            </th>
                            <th style={{ minWidth: 100 }}>
                              Age
                              <Asterik
                                fieldName={"age"}
                                requiredRules={personalAsterikRules}
                              />
                            </th>
                            <th style={{ minWidth: 100 }}>
                              Hours at Work
                              <Asterik
                                fieldName={"hoursAtWork"}
                                requiredRules={personalAsterikRules}
                              />
                            </th>
                            <th style={{ minWidth: 150 }}>IME Date</th>
                            <th style={{ minWidth: 150 }}>PME Date</th>
                            <th style={{ minWidth: 150 }}>VTC Date</th>
                            <th style={{ minWidth: 150 }}>Form A No.</th>
                            <th style={{ minWidth: 150 }}>
                              Outcome
                              <Asterik
                                fieldName={"outcome"}
                                requiredRules={personalAsterikRules}
                              />
                            </th>
                            <th style={{ minWidth: 200 }}>
                              Injury Code
                              <Asterik
                                fieldName={"injuryCode"}
                                requiredRules={personalAsterikRules}
                              />
                            </th>
                            <th style={{ minWidth: 270 }}>
                              Body Parts Involved
                            </th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {personalDetails.length ? (
                            personalDetails.map((x, index) => {
                              return x.deleted ? null : (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>
                                    <Select
                                      name="type"
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                      value={x.type}
                                      options={getArraayofKeys(viewStaffType)}
                                      object={viewStaffType}
                                      KEY_label={"name"}
                                      KEY_value={"id"}
                                      init="none"
                                      array="single"
                                    ></Select>
                                  </td>
                                  <td>
                                    {x.type && x.type !== "users" ? (
                                      <FormInputText
                                        name="name"
                                        type="text"
                                        onChange={({
                                          target: { name, value },
                                        }) =>
                                          onChangePersonalDetails(
                                            index,
                                            name,
                                            value
                                          )
                                        }
                                        value={x.name}
                                        className="form-control"
                                        error={
                                          personalDetailsErrors[index]?.name
                                        }
                                      />
                                    ) : (
                                      <Select
                                        name={"userId"}
                                        data-testid="userId"
                                        options={users?.map((employee) => ({
                                          label: employee.name_email_employeeId,
                                          value: employee.id,
                                          optionDetails: employee,
                                        }))}
                                        value={x.userId || x.name}
                                        onChange={({
                                          target: { name, value },
                                        }) => {
                                          onChangePersonalDetailsEmployee(
                                            index,
                                            value
                                            // optionDetails.name_email_employeeId,
                                            // optionDetails.id,
                                            // //optionDetails.contactNumber,
                                            // optionDetails.dob
                                          );
                                        }}
                                        placeholder={
                                          "Search by Name / Email Id"
                                        }
                                        blankSelect={"none"}
                                        isCreatable
                                      />
                                    )}
                                    {/* <span className="small text-danger">
                                {personalDetailsErrors[index]?.name}
                              </span> */}
                                  </td>
                                  <td>
                                    {x.type !== "others" ? (
                                      <Select
                                        name={"employer"}
                                        options={
                                          x.type === "contractor"
                                            ? contractorsStaff
                                            : STAFFEMPLOYER
                                        }
                                        value={checkNumberString(x.employer)}
                                        onChange={({
                                          target: { name, value },
                                        }) => {
                                          onChangePersonalDetails(
                                            index,
                                            name,
                                            value
                                          );
                                        }}
                                        // placeholder={
                                        //   "Search by Name / Email Id"
                                        // }
                                        blankSelect={"none"}
                                        KEY_label={
                                          x.type === "contractor" &&
                                          "contractor"
                                        }
                                        KEY_value={
                                          x.type === "contractor" && "id"
                                        }
                                      />
                                    ) : (
                                      <FormInputText
                                        name="employer"
                                        type="text"
                                        onChange={({
                                          target: { name, value },
                                        }) =>
                                          onChangePersonalDetails(
                                            index,
                                            name,
                                            value
                                          )
                                        }
                                        value={x.employer}
                                        disabled={
                                          x.type === "contractor" ? false : true
                                        }
                                        className="form-control"
                                        error={
                                          personalDetailsErrors[index]?.employer
                                        }
                                      />
                                    )}
                                  </td>
                                  {/* <td>
                                <Select
                                  name="employer"
                                  value={
                                    x.type === "Contractor"
                                      ? +x.employer
                                      : x.employer
                                  }
                                  options={
                                    x.type === "Contractor"
                                      ? contractors
                                      : STAFFEMPLOYER
                                  }
                                  KEY_label={
                                    x.type === "Contractor"
                                      ? "contractorLegalEntity"
                                      : "contactPerson"
                                  }
                                  KEY_value={"id"}
                                  blankSelect={"Select"}
                                  disabled={true}
                                ></Select>
                              </td>
                              <td>
                                <FormInputText
                                  name="userId"
                                  type="text"
                                  value={x.userId || ""}
                                  // className="form-control"
                                  disabled
                                />
                              </td> */}
                                  {/* <td>
                              <Input
                                name="phone"
                                type="number"
                                onChange={({ target: { name, value } }) =>
                                  value >= 0 &&
                                  value <= 9999999999 &&
                                  onChangePersonalDetails(index, name, value)
                                }
                                value={x.phone}
                                className="form-control"
                                error={personalDetailsErrors[index]?.phone}
                              />
                              <span className="small text-danger">
                                {personalDetailsErrors[index]?.phone}
                              </span>
                            </td> */}
                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    <Select
                                      // label={"Incident Severity"}
                                      name="designation"
                                      value={x?.designation}
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                      options={designationList.sort((a, b) => +a.code - +b.code)}
                                      error={
                                        personalDetailsErrors[index]
                                          ?.designation
                                      }
                                      KEY_label={"name"}
                                      KEY_value={"id"}
                                    ></Select>
                                  </td>
                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    <Select
                                      // label={"Incident Severity"}
                                      name="gender"
                                      value={x?.gender}
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                      options={["Male", "Female"]}
                                      init="Select"
                                      array="single"
                                      error={
                                        personalDetailsErrors[index]?.gender
                                      }
                                      KEY_label={"name"}
                                      KEY_value={"id"}
                                    ></Select>
                                  </td>
                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    <FormInputText
                                      name="age"
                                      type="number"
                                      data-testid="age"
                                      onChange={({ target: { name, value } }) =>
                                        value >= 0 &&
                                        value <= 200 &&
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                      value={x.age}
                                      // className="form-control"
                                      error={personalDetailsErrors[index]?.age}
                                    />
                                    {/* <span className="small text-danger">
                                {personalDetailsErrors[index]?.age}
                              </span> */}
                                  </td>
                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    <FormInputText
                                      name="hoursAtWork"
                                      type="number"
                                      onChange={({ target: { name, value } }) =>
                                        value >= 0 &&
                                        digitCheckAfterDecimal(value, 2) &&
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                      value={x.hoursAtWork}
                                      // className="form-control"
                                      error={
                                        personalDetailsErrors[index]
                                          ?.hoursAtWork
                                      }
                                    />
                                  </td>

                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    {/* <input
                                      type="date"
                                      value={x?.imeDate}
                                      dateFormat="YYYY-MM-DD"
                                      name="imeDate"
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                    /> */}
                                    <CustomDatePickerBasic
                                      value={x?.imeDate}
                                      dateFormat="YYYY-MM-DD"
                                      name="imeDate"
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                    />
                                  </td>

                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    {/* <input
                                      type="date"
                                      value={x?.pmeDate}
                                      dateFormat="YYYY-MM-DD"
                                      name="pmeDate"
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                    /> */}
                                    <CustomDatePickerBasic
                                      value={x?.pmeDate}
                                      dateFormat="YYYY-MM-DD"
                                      name="pmeDate"
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                    />
                                  </td>

                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    {/* <input
                                      type="date"
                                      value={x?.vtcDate}
                                      dateFormat="YYYY-MM-DD"
                                      name="vtcDate"
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                    /> */}

                                    <CustomDatePickerBasic
                                      name="vtcDate"
                                      dateFormat="YYYY-MM-DD"
                                      value={x?.vtcDate}
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <FormInputText
                                      name="formA_No"
                                      data-testid="formA_No"
                                      type="text"
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                      value={x.formA_No}
                                      className="form-control"
                                      // error={
                                      //   personalDetailsErrors[index]?.employer
                                      // }
                                    />
                                  </td>
                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    <Select
                                      // label={"Incident Severity"}
                                      name="outcome"
                                      value={x?.outcome}
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                      options={outcomeOpt}
                                      init="Select"
                                      array="single"
                                      error={
                                        personalDetailsErrors[index]?.outcome
                                      }
                                      KEY_label={"name"}
                                      KEY_value={"id"}
                                    ></Select>
                                  </td>
                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    <Select
                                      // label={"Incident Severity"}
                                      name="injuryCode"
                                      value={x?.injuryCode}
                                      onChange={({ target: { name, value } }) =>
                                        onChangePersonalDetails(
                                          index,
                                          name,
                                          value
                                        )
                                      }
                                      options={getArraayofKeys(injuryCodeObj)}
                                      object={injuryCodeObj}
                                      error={
                                        personalDetailsErrors[index]?.injuryCode
                                      }
                                    ></Select>
                                  </td>

                                  <td
                                    style={{ borderRight: "3px solid #dee2e6" }}
                                  >
                                    <MultiSelectWithCheckbox
                                      options={BODY_PART_LIST.map((d) => ({
                                        ...d,
                                        disabled:
                                          d.value === "notApplicable" &&
                                          x?.bodyParts === "notApplicable"
                                            ? false
                                            : x?.bodyParts ===
                                                "Not Applicable" ||
                                              !x?.bodyParts ||
                                              x?.bodyParts !== "notApplicable"
                                            ? false
                                            : true,
                                      }))}
                                      value={x?.bodyParts
                                        ?.split(",")
                                        ?.map((role) => {
                                          const found = BODY_PART_LIST?.find(
                                            (obj) => obj.value === role
                                          );
                                          return found ? { ...found } : null;
                                        })
                                        ?.filter((d) => !!d)}
                                      onChange={(selectedSites) => {
                                        onChangePersonalDetails(
                                          index,
                                          "bodyParts",
                                          selectedSites
                                            .map((site) => site.value)
                                            .join(",")
                                        );
                                      }}
                                      // disabled={completionFlag || mmFlag}
                                      hasSelectAll={false}
                                      disableSearch={true}
                                      className={"customMulti"}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <div className="actionIconsGroup">
                                      <span
                                        data-testid={"remove-leaf"}
                                        onClick={() =>
                                          deleteRowPersonalDetails(index)
                                        }
                                        className="icons font-21 text-danger"
                                      >
                                        <AiOutlineMinusCircle
                                          size="24"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  {/* <td>
                              <div className="actionIconsGroup">
                                <CustomTooltip tooltip="Delete">
                                  <a
                                    href
                                    className="icons text-danger pointer font-20"
                                    onClick={() => deleteRowPersonalDetails(index)}
                                  >
                                    <FiMinusCircle />
                                  </a>
                                </CustomTooltip>
                              </div>
                            </td> */}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="12" align="center">
                                {/* <div className="alert alert-danger d-inline-block m-0">
                                                                <strong>Empty</strong>
                                                            </div> */}
                              </td>
                            </tr>
                          )}
                          {form.peopleCount &&
                            +form.peopleCount > +personalDetails?.length && (
                              <tr>
                                <td colSpan="12">
                                  <span
                                    className="addTxt"
                                    onClick={addRowPersonalDetails}
                                    data-testid="add-leaf"
                                  >
                                    <AiOutlinePlus />
                                    Add
                                  </span>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </div>
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default PersonnelDetailsTab;
