import axios from "axios";
import localStorageService from "./localStorageService";
import { UserService } from "../_services/UserService/UserService";
import toastr from "toastr";
import { USER_PERMISSIONS } from "../redux/reducers/CONSTANT";

class JwtAuthService {
  loginWithEmailAndPassword = async (email, password, isMobile) => {
    let obj = {
      email: email,
      password: password,
      isMobile: isMobile,
    };

    let response = await UserService.login(obj);
    if (response.status === 401) {
      toastr.error(response.message.error || "unauthorized");
      return response.message;
    }
    if (response && response.status && response.status !== 401) {
      let data = response.data;
      this.setSession(data.accessToken);
      this.setUser(data);
      return data;
    } else if (response.message) {
      throw new Error(JSON.stringify(response.message)); // rejects the promise
    }
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then((data) => {
      this.setSession(data?.token);
      this.setUser(data);
      return data;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };

  setTokenId = (token) => {
    localStorageService.setItem("id_token", token);
  };
  setAccessToken = (token) => {
    localStorageService.setItem("access_token", token);
  };
  setRefreshToken = (token) => {
    localStorageService.setItem("refresh_token", token);
  };
  setExpireIn = (token) => {
    localStorageService.setItem("expires_in", token);
  };
  setTokenType = (token) => {
    localStorageService.setItem("token_type", token);
  };

  removeUser = () => {
    localStorage.removeItem("auth_user");
    localStorage.removeItem("siteIds");
    localStorage.removeItem("permissions");
    localStorage.removeItem("employeeListData");
    localStorage.removeItem("contractorPersonListData");
    localStorage.removeItem("contractorListData");
    localStorage.removeItem("storeFuelDispense");

    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("token_type");
  };
}

export default new JwtAuthService();
