import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
// import FilterResponse from '../../_helpers/FilterResponse'
// import toastr from 'toastr'
export const AreaOfOperationService = {
  addAoo,
  getAooById,
  updateAoo,
  deleteAoo,
  getAllAoo,
};

async function addAoo(data) {
  try {
    const response = await RestMethod.POST(
      "/master-data/area-of-operation",
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function updateAoo(id, data) {
  try {
    const response = await RestMethod.PUT(
      "/master-data/area-of-operation/" + id,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function deleteAoo(id, data) {
  try {
    const response = await RestMethod.DELETE(
      "/master-data/area-of-operation/" + id
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAooById(id) {
  try {
    const response = await RestMethod.GET(
      "/master-data/area-of-operation/" + id
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllAoo(filter) {
  try {
    const filter_key=filter?filter:"";
    const response = await RestMethod.GET("master-data/area-of-operation?"+filter_key);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      data: data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
