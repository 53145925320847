import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import ReportButton from "../../../SafetyManagementSystem/IncidentManagement/components/ReportButton/ReportButton";
import CheckBoxField from "../../../../components/_form/CheckBoxField/CheckBoxField";
import { IS_MOBILE, IS_ONLY_BARCODE_SCAN } from "../../../../_helpers/helper";
import QrScanner from "../../../../components/MobileComponents/QrScanner/QrScanner";
import { BsFillInfoCircleFill } from "react-icons/bs";
import CustomPopover from "../../../../components/Common/CustomPopover";

const AddHmmVehicle = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  tab,
  onNewScanResult,
  disableHemmDetailInput,
  handleSuggestionClick,
  selectedSuggestion,
  suggestions,
  changeRecipientType,
}) => {
  return (
    <>
      <div className="mobileFormWrapper">
        {IS_MOBILE() && <h3>HEMM Vehicle Details</h3>}
        <div className="FormWrapper">
          <Form>
            <Row>
              {/* <Col lg={2} className="mt-4">
               
              </Col> */}
              <Col lg={4} className="">
                <Form.Label className="d-flex">
                  {form?.isBowser ? "Bowser ID " : "HEMM ID"}
                  <CustomPopover
                    // click
                    placement={IS_MOBILE() ? "bottom" : "right"}
                    tooltip={
                      <>
                        Enter {form?.isBowser ? "Bowser ID" : "HEMM ID"} or
                        Machine Serial number to search
                      </>
                    }
                  >
                    <BsFillInfoCircleFill style={{ marginLeft: "2px" }} />
                  </CustomPopover>
                  <CheckBoxField
                    id={"isBowser"}
                    name={"isBowser"}
                    checked={form?.isBowser ? 1 : 0}
                    onChange={changeRecipientType}
                    label={"Select Bowser"}
                    className="form-control"
                    labelStyleML={IS_MOBILE() ? "50px" : "70px"}
                    labelStyleMB={!IS_MOBILE() ? "-3px" : ""}
                  />
                </Form.Label>
                <div className="scannerIcon suggestion-input-container">
                  <FormInputText
                    name="hemm_id"
                    value={form.hemm_id}
                    onChange={onChange}
                    type="text"
                    class="form-control"
                    data-testid={"hemm_idId"}
                    disabled={IS_ONLY_BARCODE_SCAN()}
                    placeholder=""
                    error={
                      error?.hemm_id
                        ? form?.isBowser
                          ? "Bowser is Required."
                          : "HEMM is Required."
                        : ""
                    }
                  />
                  {/* {IS_MOBILE() && ( */}
                  <QrScanner qrCodeSuccessCallback={onNewScanResult} />
                  {/* )} */}

                  <ul className="suggestion-list">
                    {suggestions?.map((suggestion) => (
                      <li
                        style={{ listStyleType: "none" }}
                        key={suggestion}
                        onClick={() => handleSuggestionClick(suggestion)}
                        data-testid={"handleSuggestionClickId"}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>

              <Col lg={4}>
                <FormInputText
                  name="fms_vehicle_name"
                  value={form.fms_vehicle_name}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={form.isBowser ? "Bowser Name" : "FMS Vehicle Name"}
                  error={
                    error?.fms_vehicle_name
                      ? form?.isBowser
                        ? "Bowser Name is Required."
                        : "FMS Vehicle Name is Required."
                      : ""
                  }
                  disabled={disableHemmDetailInput}
                />
              </Col>
              <Col lg={4}>
                <FormInputText
                  name="fms_vehicle_type"
                  value={form.fms_vehicle_type}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={form.isBowser ? "Bowser Type" : "FMS Vehicle Type"}
                  error={
                    error?.fms_vehicle_type
                      ? form?.isBowser
                        ? "Bowser Type is Required."
                        : "FMS Vehicle Type is Required."
                      : ""
                  }
                  disabled={disableHemmDetailInput}
                />
              </Col>

              {/* <Col lg={4}>
                <FormInputText
                  name="equipment_type"
                  value={form.equipment_type}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"Equipment Type"}
                  disabled={disableHemmDetailInput}
                  // error={error?.fms_hemm_latitude}
                />
              </Col> */}
              <Col lg={4}>
                <FormInputText
                  name="make"
                  value={form.make}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"Make"}
                  disabled={disableHemmDetailInput}
                  error={error?.make}
                />
              </Col>
              <Col lg={4}>
                <FormInputText
                  name="capacity"
                  value={form.capacity}
                  onChange={({ target: { name, value } }) =>
                    value >= 0 && onChange({ target: { name, value } })
                  }
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"Tank Capacity"}
                  disabled={disableHemmDetailInput}
                  error={error?.capacity}
                />
              </Col>
              <Col lg={4}>
                <FormInputText
                  name="loadCapacity"
                  value={form.loadCapacity}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"Load Capacity"}
                  disabled={disableHemmDetailInput}
                  error={error?.loadCapacity}
                />
              </Col>
              <Col lg={4}>
                <FormInputText
                  name="model"
                  value={form.model}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"Model"}
                  disabled={disableHemmDetailInput}
                  error={error?.model}
                />
              </Col>
              {/* <Col lg={4}>
              <FormInputText
                name="fms_odometer_reading"
                value={form.fms_odometer_reading}
                onChange={onChange}
                type="text"
                class="form-control"
                placeholder=""
                label={"FMS Odometer Reading"}
                error={error?.fms_odometer_reading}
              />
            </Col> */}
              <Col lg={4}>
                <FormInputText
                  name="hemm_fms_verify"
                  value={form?.hemm_fms_verify === 1 ? "Yes" : "No"}
                  // onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={
                    form.isBowser
                      ? "Is Bowser Verified"
                      : "Is HEMM`s FMS Verified"
                  }
                  disabled="true"
                  // error={error?.hemm_fms_verify}
                />
              </Col>
              {form.fms_hemm_latitude && (
                <Col lg={4}>
                  <FormInputText
                    name="fms_hemm_latitude"
                    value={form.fms_hemm_latitude}
                    onChange={onChange}
                    type="text"
                    class="form-control"
                    placeholder=""
                    label={"Latitude"}
                    disabled={disableHemmDetailInput}
                    error={error?.fms_hemm_latitude}
                  />
                </Col>
              )}
              {form.fms_hemm_longitude && (
                <Col lg={4}>
                  <FormInputText
                    name="fms_hemm_longitude"
                    value={form.fms_hemm_longitude}
                    onChange={onChange}
                    type="text"
                    class="form-control"
                    placeholder=""
                    label={"Longitude"}
                    disabled={disableHemmDetailInput}
                    error={error?.fms_hemm_longitude}
                  />
                </Col>
              )}
              {form.latitude_captured_date && (
                <Col lg={4}>
                  <FormInputText
                    name="latitude_captured_date"
                    value={form.latitude_captured_date}
                    onChange={onChange}
                    type="text"
                    class="form-control"
                    placeholder=""
                    label={"Latitude Captured Date"}
                    disabled={disableHemmDetailInput}
                    error={error?.latitude_captured_date}
                  />
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </div>
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default AddHmmVehicle;
