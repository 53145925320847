import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../components/FormInputText/FormInputText";
import LabelCustom from "../../../components/LabelCustom/LabelCustom";
import { coalGradeLabel } from "../DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../components/Select/Select";

function LabQualityForm({ form, onChange, error, gradeList }) {
  return (
    <div className="creationWrapper">
      <Form>
        <h6>On Air Dry Basis</h6>
        <Row>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_on_air_dry_bases_moisture + ` %`}
              onChange={onChange}
              name="lab_on_air_dry_bases_moisture"
              value={form?.lab_on_air_dry_bases_moisture}
              error={error?.lab_on_air_dry_bases_moisture}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_on_air_dry_bases_ash + ` %`}
              onChange={onChange}
              name="lab_on_air_dry_bases_ash"
              value={form?.lab_on_air_dry_bases_ash}
              error={error?.lab_on_air_dry_bases_ash}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_on_air_dry_bases_vm + ` %`}
              onChange={onChange}
              name="lab_on_air_dry_bases_vm"
              value={form?.lab_on_air_dry_bases_vm}
              error={error?.lab_on_air_dry_bases_vm}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_on_air_dry_bases_gcv + ` (kCal/kg)`}
              onChange={onChange}
              name="lab_on_air_dry_bases_gcv"
              value={form?.lab_on_air_dry_bases_gcv}
              error={error?.lab_on_air_dry_bases_gcv}
            />
          </Col>
        </Row>
        <div className="seperator"></div>
        <h6>On EM Basis ​</h6>
        <Row>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_on_em_bases_moisture + ` %`}
              onChange={onChange}
              name="lab_on_em_bases_moisture"
              value={form?.lab_on_em_bases_moisture}
              error={error?.lab_on_em_bases_moisture}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_on_em_bases_ash + ` %`}
              onChange={onChange}
              name="lab_on_em_bases_ash"
              value={form?.lab_on_em_bases_ash}
              error={error?.lab_on_em_bases_ash}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_on_em_bases_vm + ` %`}
              onChange={onChange}
              name="lab_on_em_bases_vm"
              value={form?.lab_on_em_bases_vm}
              error={error?.lab_on_em_bases_vm}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_on_em_bases_gcv + ` (kCal/kg)`}
              onChange={onChange}
              name="lab_on_em_bases_gcv"
              value={form?.lab_on_em_bases_gcv}
              error={error?.lab_on_em_bases_gcv}
            />
          </Col>
        </Row>
        <div className="seperator"></div>
        <Row>
          <Col md={6}>
            <Select
              onChange={onChange}
              value={form?.lab_analyzed_grade}
              name="lab_analyzed_grade"
              options={gradeList}
              error={error?.lab_analyzed_grade}
              label={coalGradeLabel?.lab_analyzed_grade}
            />
          </Col>

          <Col md={6}>
            <LabelCustom>
              {" "}
              {coalGradeLabel?.result_reporting_to_customer}{" "}
            </LabelCustom>
            <CustomDatePicker
              value={form?.result_reporting_to_customer}
              name="result_reporting_to_customer"
              onChange={onChange}
              error={error?.result_reporting_to_customer}
            />
            {/* <InputError 
            error={error?.result_reporting_to_customer} 
            /> */}
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_result_tested_by}
              onChange={onChange}
              name="lab_result_tested_by"
              value={form?.lab_result_tested_by}
              error={error?.lab_result_tested_by}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.lab_result_checked_by}
              onChange={onChange}
              name="lab_result_checked_by"
              value={form?.lab_result_checked_by}
              error={error?.lab_result_checked_by}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default LabQualityForm;
