import React from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { GiGolfFlag } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { Row } from "reactstrap";
import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../_helpers/dateHelper";
import { submitStatus } from "../DATA";
import { thirdParyLabLabel } from "./DATA";
import { modeOfDispatchLabel } from "../DATA";
import CreatedByCreatedAt from "../../../components/Common/CreatedByCreatedAt";

const ThirdPartyLabResultView = ({ form }) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">Coal Dispatch Related Data</p>
            <Row>
              <Col sm={6}>
                <label>{thirdParyLabLabel?.mode_of_dispatch}</label>
                <p className="content">
                  {form?.mode_of_dispatch &&
                    modeOfDispatchLabel[form?.mode_of_dispatch]}
                </p>
              </Col>
              <Col sm={6}>
                <label>{thirdParyLabLabel?.rr_or_do_number}</label>
                <p className="content">{form?.rr_or_do_number}</p>
              </Col>
              <Col sm={6}>
                <label>{thirdParyLabLabel?.rr_or_do_date}</label>
                <p className="content">
                  {form?.rr_or_do_date
                    ? DDMMYYYYFormat(form?.rr_or_do_date)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{thirdParyLabLabel?.declared_grade}</label>
                <p className="content">{form?.declared_grade}</p>
              </Col>
              <Col sm={6}>
                <label>{thirdParyLabLabel?.name_of_sampling_agency}</label>
                <p className="content">{form?.sampling_agency?.name}</p>
              </Col>
            </Row>
          </div>
          <div className="previewContent">
            <p className="head">Third Party Lab Quality Results</p>
            <div className="">
              <h6>On Air Dry Basis</h6>
              <Row>
                <Col sm={6}>
                  <label>
                    {`${thirdParyLabLabel?.third_party_on_air_dry_bases_moisture} %`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_air_dry_bases_moisture}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {`${thirdParyLabLabel?.third_party_on_air_dry_bases_ash} %`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_air_dry_bases_ash}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {`${thirdParyLabLabel?.third_party_on_air_dry_bases_vm} %`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_air_dry_bases_vm}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {`${thirdParyLabLabel?.third_party_on_air_dry_bases_gcv} (kCal/kg)`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_air_dry_bases_gcv}
                  </p>
                </Col>
              </Row>
              <div className="seperator"></div>
              <h6>On EM Basis ​</h6>
              <Row>
                <Col sm={6}>
                  <label>
                    {`${thirdParyLabLabel?.third_party_on_em_bases_moisture} %`}
                  </label>
                  <p className="content">
                    {form?.third_party_on_em_bases_moisture}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {`${thirdParyLabLabel?.third_party_on_em_bases_ash} %`}
                  </label>
                  <p className="content">{form?.third_party_on_em_bases_ash}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {`${thirdParyLabLabel?.third_party_on_em_bases_vm} %`}
                  </label>
                  <p className="content">{form?.third_party_on_em_bases_vm}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {`${thirdParyLabLabel?.third_party_on_em_bases_gcv} (kCal/kg)`}
                  </label>
                  <p className="content">{form?.third_party_on_em_bases_gcv}</p>
                </Col>
              </Row>
              <div className="seperator"></div>
              <Row>
                <Col sm={6}>
                  <label>{thirdParyLabLabel?.third_party_date_of_result}</label>
                  <p className="content">
                    {form?.third_party_date_of_result
                      ? DDMMYYYYHHMMAFormat(form?.third_party_date_of_result)
                      : ""}
                  </p>
                </Col>
                <Col sm={6}>
                  <label>
                    {`${thirdParyLabLabel?.third_party_total_moisture} %`}
                  </label>
                  <p className="content">{form?.third_party_total_moisture}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {thirdParyLabLabel?.third_party_analyzed_grade}​
                  </label>
                  <p className="content">{form?.third_party_analyzed_grade}</p>
                </Col>
              </Row>
            </div>
          </div>
          {form?.referee_lab_required == "1" &&
            form?.third_party_status == submitStatus?.submitted &&
            form?.referee_lab_status !== submitStatus?.submitted && (
              <div className="previewContent">
                <h5>
                  Referee Lab Data <GiGolfFlag className="flagIcon" size={25} />
                </h5>
                <p className="mb-4">
                  Sample Data from referee lab analysis, Click on Add Referee
                  Data button.
                </p>
                <Button
                  className="primaryBtn"
                  onClick={() =>
                    navigate(`/coal-grade/referee-lab-result/${form?.id}`)
                  }
                >
                  Add Referee Data
                </Button>
              </div>
            )}
          <CreatedByCreatedAt id={form?.id} table={"coal_grade_quality_data"} />
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default ThirdPartyLabResultView;
