import React from "react";
import { Container } from "react-bootstrap";
import SelectionFlowCard from "./SelectionFlowCard";

function SelectionFlow() {
  return (
    <Container fluid>
      <div className="selectionWrapper">
        <ul className="selectionItem">
          <SelectionFlowCard />
        </ul>
      </div>
    </Container>
  );
}

export default SelectionFlow;
