import React from "react";
import ReactEcharts from "echarts-for-react";
import NoRecord from "../../Common/NoRecord";
import moment from "moment";
import { isDecimal } from "../../../_helpers/helper";

class EchartMultipleBarChart extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.seriesData !== this.props.seriesData) {
      // console.log("forceUpdate");
      this.forceUpdate();
    }
  }

  render() {
    let {
      category,
      seriesData,
      xAxis,
      yAxis,
      xAxisName,
      yAxisName,
      customToolTip,
      legendFlag,
      noRecordText,
      minMaxData,
      customFuelToolTip,
      inverseType,
      customTooltipCoalGrade,
      filterType,
    } = this.props;

    let filterLegend =
      filterType?.lineChart === "D"
        ? "Date"
        : filterType?.lineChart === "W"
        ? "Week"
        : filterType?.lineChart === "M"
        ? "Month"
        : filterType?.lineChart === "Q"
        ? "Quarter"
        : "Year";



    let echartMultipleBar2Option = {
      tooltip: customTooltipCoalGrade
        ? {
            formatter: function (params, ticket, callback) {
              let string =
                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params?.color +
                `;\"></span>GCV : ${isDecimal(params?.value)} ${yAxisName}<br>
                <span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params?.color +
                `;\"></span> ${filterLegend} : ${params?.name}<br>
                
                `;
              return "<span > " + string + "</span>";
            },
          }
        : customToolTip
        ? {
            formatter: function (params, ticket, callback) {
              let string =
                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params.color +
                `;\"></span>${params.seriesName} : ${params.value} ${yAxisName}<br>`;
              return "<span > " + string + "</span>";
            },
          }
        : customFuelToolTip
        ? {
            formatter: function (params, ticket, callback) {
              let series = seriesData[params.seriesIndex];
              let dateString = category[params.dataIndex]; // Access date from category array
              let string =
                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params.color +
                `;\"></span>${params.seriesName} : ${params.value} ${yAxisName}<br>
              <span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params.color +
                `;\"></span> Model : ${series.model} <br>
              <span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params.color +
                `;\"></span> Make : ${series.make} <br>
              <span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params.color +
                `;\"></span> Time: ${moment(dateString).format("hh:mm A")} <br>
              `;
              return "<span > " + string + "</span>";
            },
          }
        : {
            trigger: "axis",
          },
      yAxis: yAxis
        ? yAxis
        : {
            name: yAxisName ? yAxisName : "",
            nameLocation: inverseType ? "start" : "end",
            type: "value",
            min: 0, // minimum value to start
            minInterval: 1, // for integer axis value
            nameTextStyle: {
              fontWeight: "bold",
            },
            inverse: inverseType ? true : false,
          },
      xAxis: xAxis
        ? {
            ...xAxis,
            nameTextStyle: {
              color: "white",
            },
          }
        : {
            data: category ? category : [],
            name: xAxisName ? xAxisName : "",
            nameRotate: 90,
            nameTextStyle: {
              fontWeight: "bold",
              color: "white",
            },
            type: "category",
            nameLocation: "end",
            axisLabel: {
              formatter: function (value) {
                const date = new Date(value.split("-").reverse().join("-"));
                return customFuelToolTip
                  ? moment(date).format("DD-MM-YYYY")
                  : value;
              },
            },
          },
      legend:
        legendFlag == false
          ? false
          : {
              to: 100,
              type: "scroll",
              itemGap: 20,
              // horizontalAlign: 'center',
            },
      series: seriesData ? seriesData : [],
      dataZoom: [
        {
          start: 0,
          type: "inside",
        },
        {
          start: 0,
        },
      ],
    };

    if (this.props.grid) {
      echartMultipleBar2Option.grid = this.props.grid;
    }
    if (this.props.isLegeng) {
      echartMultipleBar2Option.legend = this.props.isLegeng;
    }
    let name =
      echartMultipleBar2Option.xAxis &&
      Object.keys(echartMultipleBar2Option.xAxis).length > 0 &&
      echartMultipleBar2Option.xAxis.name
        ? echartMultipleBar2Option.xAxis.name
        : null;


    return (
      <React.Fragment>
        {
          Array.isArray(seriesData) && seriesData.length > 0 ? (
            <>
              <ReactEcharts
                style={{
                  height: this.props.height ? this.props.height : "270px",
                }}
                option={echartMultipleBar2Option}
                notMerge={true}
                replaceMerge={["series", "legend"]}
              />
              <div className="text-center" style={{ marginTop: -30 }}>
                <span
                  className="font-weight-bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {name}
                </span>
                {minMaxData && (
                  <div className="minMaxSec">
                    <div className="contentData">
                      <label>{"Min"}:</label>
                      <p>{`${
                        Math.round(minMaxData?.min * 100) / 100 || 0
                      } ${yAxisName}`}</p>
                    </div>
                    <div className="contentData">
                      <label>{"Max"}:</label>
                      <p>{`${
                        Math.round(minMaxData?.max * 100) / 100 || 0
                      } ${yAxisName}`}</p>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : !noRecordText ? (
            <NoRecord />
          ) : (
            ""
          )
          // <div className="noRecordFound"><TiInfoOutline /> No Records found...!</div>
        }
      </React.Fragment>
    );
  }
}

export default EchartMultipleBarChart;
