import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "../../../../components/Select/Select";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import { AiOutlinePlus } from "react-icons/ai";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  createHindrance,
  getHinderanceById,
  updateHindranceStatus,
  getContractorById,
} from "../../../../services/_hindrance/hindrance";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MessagePopup from "../../../../components/MessagePopup/MessagePopup";
import StatusPopup from "../../../../components/MessagePopup/StatusPopup";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import { disableFutureDate } from "../../../../_helpers/dateHelper";
import toastr from "toastr";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import {
  SidebarData,
  createPollutionControlMachine,
  getPollutionControlMachineById,
  getFuelConfigSiteById,
  getEnvConfigStationById,
  createFuelConfigSite,
  createStaationConfig,
  updateEnvStationConfig,
  EnvConfigStationRule,
} from "../DATA";
import { useForm } from "../.././../../_helpers/hooks";
import { Validation } from "../../../../_helpers/validation/Validation";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";
import { IS_USER_SITE_MINES, auth_user } from "../../../../_helpers/helper";

const EnvConfigStationAddEdit = () => {
  let navigate = useNavigate();
  const usrl = useLocation();

  const segments = usrl.pathname.split("/");
  const lastSegment = segments[segments.length - 2];
  const words = lastSegment.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  let params = useParams();
  const title = capitalizedWords.join(" ");
  const [onsave, setOnSave] = useState(false);
  const [pollutionForm, setPollutionForm] = useState([]);
  const [mineList, setMineList] = useState([]);
  const [contractorData, setContractorData] = useState();
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [show, setShow] = useState(false);
  const [messageshow, setMessageShow] = useState(false);
  const [hindranceBasicDetailsForm, setHindranceBasicDetailsForm] = useState({
    input_date: null,
  });

  const [hindranceBasicDetailsErrorForm, setHindranceBasicDetailsErrorForm] =
    useState({
      input_date: null,
    });

  const hideMessagePopup = () => {
    setMessagePopupType(true);
    setShow(false);
  };

  const closeMessagePopup = () => {
    setMessageShow(false);
  };

  const closePopup = () => {
    setShow(false);
    navigate(`/environment-management/configuration/station/${lastSegment}`);
  };

  const [formFields, setFormFields] = useState({
    name: "",
    code: "",
    category: "",
    lat: "",
    log: "",
    siteId: "",
  });
  const [error, setError] = useState({
    ...formFields,
  });

  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj =
      mineList?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setFormFields((prev) => ({
      ...prev,
      siteId: siteObj?.value,
    }));
  }, [mineList]);

  const mineLists = async () => {
    let list = [];
    const response = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );

    if (response?.status) {
      list = response?.data?.map((d) => {
        return { value: d.id, label: d.name };
      });
    }
    setMineList(list);
  };
  useEffect(() => {
    mineLists();
  }, [onsave]);

  const getConfigById = async () => {
    let response;
    response = await getEnvConfigStationById(params?.id);
    
    setFormFields({
      siteId: response?.siteId,
      name: response?.name,
      code: response?.code,
      lat: response?.lat,
      category: response?.category,
      log: response?.log,
    });
    setHindranceBasicDetailsForm((prev) => {
      return {
        ...prev,
        input_date: response?.data?.input_date,
      };
    });
  };

  const onSubmit = async () => {
    formFields.type = lastSegment;
    if (lastSegment === "water_quality") formFields.category = "not selected";
    if (lastSegment === "CAAQMS") formFields.code = "0";
    if (lastSegment === "ground_water_CMPDIL") {
      formFields.lat = "0";
      formFields.log = "0";
    }
    console.log("yyyyyyyyyyyyyyyyyyyy",formFields);
    const errorObj = Validation(formFields, EnvConfigStationRule);
    setError(errorObj);

    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }

    let response;

    response = params?.id
      ? await updateEnvStationConfig(params.id, formFields)
      : await createStaationConfig(formFields);

    if (response?.status === 1) {
      setShow(true);
      setMessagePopupType(false);
      toastr.success(response?.message);
    } else {
      toastr.error(response?.message);
    }
  };

  useEffect(() => {
    params?.id && getConfigById();
  }, []);

  const handleFormChange = (event) => {
    setFormFields((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  function handleClick() {
    setShow(true);
    setMessagePopupType(true);
  }
  const category = [
    {
      value: "Piezometer",
      label: "Piezometer",
    },
    {
      value: "Well",
      label: "Well",
    },
  ];
  const categoryType = [
    {
      label: "Industrial",
      value: "Industrial",
    },
    {
      value: "Residential",
      label: "Residential",
    },
  ];
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              <MainBreadcrumb
                // module={ACTIVE_MODULE.incident}
                routeSegments={[
                  {
                    name: "Environment Management",
                    path: "#",
                  },
                  {
                    path: "/environment-management/configuration",
                    name: "Configuration",
                  },
                  {
                    name: title,
                    path: `/environment-management/configuration/station/${lastSegment}`,
                  },
                  {
                    name: params.id ? "Edit" : "Add",
                    path: "#",
                  },
                ]}
              />
            </h3>
            <h3 className="title d-flex align-items-end">{title} &nbsp;</h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="hindranceContent">
                  <div>
                    <div className="hindranceData">
                      <Row>
                        {/* {(lastSegment === "power_consumption" ||
                          lastSegment === "land_reclamation") && ( */}
                        <Col md={4} lg={6}>
                          <Select
                            onChange={(event) => handleFormChange(event)}
                            name="siteId"
                            options={mineList}
                            label={
                              lastSegment === "water_quality" ||
                              lastSegment === "ground_water_CMPDIL"
                                ? "Project"
                                : "Mine"
                            }
                            placeholder={"Enter Here"}
                            value={formFields?.siteId}
                            error={error?.siteId}
                            disabled={IS_USER_SITE_MINES()}
                          />
                        </Col>
                        {/* )} */}

                        <Col md={4} lg={6}>
                          <FormInputText
                            type="input"
                            label={
                              lastSegment === "ground_water_CMPDIL"
                                ? "Village Location"
                                : "Station Name"
                            }
                            onChange={(event) => handleFormChange(event)}
                            name="name"
                            data-testid="inputTextName"
                            value={formFields?.name}
                            error={error?.name}
                          />
                        </Col>
                        {lastSegment !== "CAAQMS" && (
                          <Col md={4} lg={6}>
                            <FormInputText
                              type="input"
                              label={
                                lastSegment === "ground_water_CMPDIL"
                                  ? "Code"
                                  : "Station  Code"
                              }
                              onChange={(event) => handleFormChange(event)}
                              name="code"
                              value={formFields?.code}
                              error={error?.code}
                            />
                          </Col>
                        )}

                        {(lastSegment === "CAAQMS" ||
                          lastSegment === "CMPDIL" ||
                          lastSegment === "noise_level") && (
                          <Col md={4} lg={6}>
                            <Select
                              onChange={(event) => handleFormChange(event)}
                              name="category"
                              options={categoryType}
                              label={
                                lastSegment === "noise_level"
                                  ? "Station category"
                                  : "Well category"
                              }
                              placeholder={"Enter Here"}
                              value={formFields?.category}
                              error={error?.category}
                            />
                          </Col>
                        )}
                        {lastSegment === "ground_water_CMPDIL" && (
                          <Col md={4} lg={6}>
                            <Select
                              onChange={(event) => handleFormChange(event)}
                              name="category"
                              options={category}
                              label={"Well category"}
                              placeholder={"Enter Here"}
                              value={formFields?.category}
                              error={error?.category}
                            />
                          </Col>
                        )}
                        {lastSegment !== "ground_water_CMPDIL" && (
                          <>
                            {" "}
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="input"
                                label={"Lattitude"}
                                onChange={(event) => handleFormChange(event)}
                                name="lat"
                                value={formFields?.lat}
                                error={error?.lat}
                              />
                            </Col>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="input"
                                label={"Longitude"}
                                onChange={(event) => handleFormChange(event)}
                                name="log"
                                value={formFields?.log}
                                error={error?.log}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </div>

                  <div className="btnSec">
                    <Button
                      onClick={handleClick}
                      className="secondaryBtn"
                      data-testid="handle-click-for-show"
                    >
                      Close
                    </Button>
                    <div>
                      <Button
                        className="primaryBtn"
                        onClick={onSubmit}
                        data-testid="submit-hindrance"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            setShow={hideMessagePopup}
            messagePopupType={messagePopupType}
            show={show}
            closePopup={closePopup}
          />
          <StatusPopup
            closePopup={closeMessagePopup}
            messagePopupType={false}
            show={messageshow}
            setShow={setMessageShow}
          />
        </div>
      </div>
    </div>
  );
};

export default EnvConfigStationAddEdit;
