import {
  getAllDevice,
  getDeviceByDataId,
} from "../../../../../services/_uploadedFiles/uploadedFiles";

export const initialForm = {
  site_id: "",
  deviceName: "",
  station_id: "",
};
export const deviceLabel = {
  site_id: "Mine",
  deviceName: "Device Name",
  station_id: "Station Name",
  mineName: "Source Mine",
};
export const validationRules = [
  {
    label: deviceLabel.name,
    type: "string",
    isRequired: true,
    field_name: "name",
  },
  {
    label: deviceLabel.tankId,
    type: "string",
    isRequired: true,
    field_name: "tankId",
  },
  {
    field_name: "site_id",
    label: deviceLabel.site_id,
    isRequired: true,
    type: "string",
  },
  {
    label: deviceLabel.maxCapacity,
    field_name: "maxCapacity",
    type: "number",
    isRequired: true,
  },
  {
    label: deviceLabel.closingDip,
    field_name: "closingDip",
    type: "number",
    isRequired: true,
  },
  // {
  //   label: deviceLabel.closingStock,
  //   field_name: "closingStock",
  //   type: "number",
  //   isRequired: true,
  // },
];

export const deviceApi = {
  getAll: {
    api: getAllDevice,
  },
  getById: {
    api: getDeviceByDataId,
  },
};

export const fuelLocationColumnFields = [
  {
    name: deviceLabel.mineName,
    selector: "mineName",
    sortable: true,
    cell: (row) => row?.mineName,
  },
  {
    name: deviceLabel.deviceName,
    selector: "deviceName",
    sortable: true,
    cell: (row) => row?.deviceName,
  },
  {
    name: deviceLabel.site_id,
    selector: "mine_id",
    sortable: true,
    cell: (row) => row?.site?.name,
  },

  {
    name: deviceLabel.station_id,
    selector: "station_id",
    sortable: true,
    cell: (row) => row?.station?.name,
  },
];
