import React, { useMemo, useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { useForm } from "../../../../_helpers/hooks";
import UserManagementFilterForm from "./UserManagementFilterForm";
import {
  userFilterForm,
  userListApis,
  addLink,
  customTabsData,
  userLabel,
  IncidentuserListApis,
} from "./DATA";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import {
  LOGGED_USER_ID,
  auth_user,
  capitalizeName,
  findUserPermissionedTabList,
} from "../../../../_helpers/helper";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import UserManagementView from "./UserManagementView";
import PermissionsFormEdit from "./PermissionsFormEdit";
import {
  SAFETY_ROLE_OBJECT,
  ROLE_OBJECT_VIEW,
  USER_MANAGEMENT_ROLE,
  isContractorModuleAdmin,
} from "../../../../_services/Auth/helper";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";

const IncidentUserManagementList = () => {
  let newCustomTabsData = customTabsData;
  let newAddLink = "/safety-management-system/user-management";
  const [getAllFilter, setGetAllFilter] = useState(`&type=users`);
  const [form, onChange, setForm] = useForm(userFilterForm);
  const [filterStatus, setFilterStatus] = useState(0);
  const [isListData, setIsListData] = useState(true);
  const [customTabList, setCustomTabList] = useState(
    findUserPermissionedTabList(newCustomTabsData)
  );

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          aria-expanded={open}
          onClick={() => setOpen(!open)}
          // rootClose
          aria-controls="example-collapse-text"
          className="secondaryBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <UserManagementFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      </div>
    );
  };
  const { pathname } = useLocation();

  let newFilterBtn = FilterBtn;
  if (isContractorModuleAdmin()) {
    newCustomTabsData = customTabsData.filter((d, i) => {
      if (d.label === "User List") {
        return false;
      }
      return true;
    });
    newAddLink = "/safety-management-system/user-management";
    // newFilterBtn = null;
  }

  const getAll = async () => {
    const response = await IncidentuserListApis.getAll.api(
      `&type=users&page=1&limit=10`
    );
    if (Array.isArray(response?.data) && response?.data?.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    setCustomTabList((prev) => {
      return prev.map((d) => {
        if (d.to === pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
  }, [pathname]);

  useEffect(() => {
    getAll();
  }, []);

  const onResetFilter = () => {
    setForm({ ...userFilterForm });
    setGetAllFilter(`&type=users`);
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];

      if (element) {
        filter += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(`&type=users` + filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  // const tabButtons = () => {
  //   return <CustomTabs tabeList={customTabList} />;
  // };

  const columnFields = useMemo(
    () => [
      {
        sortable: true,
        name: userLabel?.name,
        selector: "firstName",
        minWidth: "200px",
        cell: (row) => {
          return capitalizeName(
            `${row?.firstName ? row?.firstName : ""} ${
              row?.lastName ? row?.lastName : ""
            }`
          );
        },
      },
      {
        name: userLabel?.roleId,
        selector: "roleId",
        sortable: true,
        cell: (row) => {
          return row?.role?.title || "";
        },
      },
      {
        sortable: true,
        name: userLabel?.siteId,
        selector: "siteId",
        cell: (row) => {
          return row?.site?.name || "";
        },
      },
      {
        name: userLabel?.email,
        selector: "email",
        sortable: true,
      },
      {
        sortable: false,
        name: "Permissions",
        selector: "accessPermissions",
        cell: (row) => {
          let role = row.accessPermissions?.map(
            (d) => SAFETY_ROLE_OBJECT?.safetyManagement[d.permission]
          );
          let roleStr = role.filter((val) => val !== undefined);
          // if (role && role.length > 0) {
          let roleStrNew = roleStr.join(",").substring(0, 20);
          if (roleStrNew.length > 19) {
            roleStrNew = roleStrNew + "...";
          }
          // }
          //  else {
          //   roleStr = "";
          // }
          return roleStrNew;
        },
        minWidth: "200px",
      },
    ],
    []
  );

  const viewAsPopup = (form) => {
    return (
      <UserManagementView
        form={form}
        USER_MANAGEMENT_ROLE={SAFETY_ROLE_OBJECT}
      />
    );
  };

  const editAsPopup = (form, setShowAddEdit, getAll) => {
    return (
      <PermissionsFormEdit
        setShowAddEdit={setShowAddEdit}
        getAll={getAll}
        USER_MANAGEMENT_ROLE_OBJECT={SAFETY_ROLE_OBJECT}
        formData={form}
      />
    );
  };

  const statusByOptions = (row) => {
    return !!(+row?.id !== LOGGED_USER_ID());
  };
  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  fields={columnFields}
                  apis={IncidentuserListApis}
                  title={"User"}
                  // hideAddButton={true}
                  viewTitle="User Details"
                  editTitle="Edit Permissions"
                  customTableTitle="User Management"
                  FilterBtn={newFilterBtn}
                  viewAsPopup={viewAsPopup}
                  editAsPopup={editAsPopup}
                  hideSnColumn
                  pageLink={newAddLink}
                  primaryKey="id"
                  pagination
                  hideDeleteButton
                  hideEditButton
                  getAllFilter={getAllFilter}
                  breadCrumb={[
                    {
                      name: "Safety Management",
                      path: "#",
                    },
                    {
                      name: "User Management",
                      path: "#",
                    },
                  ]}
                  isNoTabOnPage
                  // editFunButton={onEdit}
                  statusByOptions={statusByOptions}
                  enableMobileIcon
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              // tabButtons={tabButtons}
              hideAddBtn={true}
              customTableTitle="User Management"
              pageLink={newAddLink}
              breadCrumb={[
                {
                  name: "Safety Management",
                  path: "#",
                },
                {
                  name: "User Management",
                  path: "#",
                },
              ]}
            />
          </div>
        </div>
      </div>
      ) : ( )
    </>
  );
};

export default IncidentUserManagementList;
