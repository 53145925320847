import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { SimpleBarChart } from "../../../components/Common/SimpleBarChart";

const PlantationProject = ({ data, form, onChange, pageType }) => {
  const minMaxArray =
    data?.series?.map((d) => {
      const minMaxObj = {};
      minMaxObj["min"] = d?.data?.length ? Math.min(...(d?.data || [])) : 0;
      minMaxObj["max"] = d?.data?.length ? Math.max(...(d?.data || [])) : 0;
      return minMaxObj;
    }) || [];

  const updatedSeries = data?.series?.map((d) => {
    d.data = d.data.map((n) => Math.round(n * 100) / 100 || 0);
    return d;
  });

  const min = Math.min(...(minMaxArray?.map((d) => d.min) || []));
  const max = Math.max(...(minMaxArray?.map((d) => d.max) || []));
  const minMaxData = { min, max };
  return (
    <>
      <div className="dashboardWrapper" id={`${"screen" + pageType}`}>
        <Row>
          <Col md={"12"}>
            <Card className="chartCard chartCardBtmSpace">
              <Card.Header>{"Plant Project"}</Card.Header>
              <Card.Body className="pieCartOuter">
                <SimpleBarChart
                  yAxisName={"Area (Hectare)"}
                  xAxis={data?.xAxis}
                  // yAxis={data?.yAxis}
                  series={updatedSeries}
                  legend={data?.legend}
                  // minMaxData={minMaxData}
                  isPlantationGraph={true}
                  customTooltip
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PlantationProject;
