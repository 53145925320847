import React from "react";
import { Col, Row } from "react-bootstrap";

const VehicleDetailsView = ({ form }) => {

  return (
    <>
      {form?.vehicleDetails?.length ? (
        form?.vehicleDetails?.map((obj, idx) => {
          return (
            <>
              <Row>
                <Col lg={4} key={"idx"}>
                  <label>Vehicle No.</label>
                  <p>{obj?.number}</p>
                </Col>
                <Col lg={4}>
                  <label>Vehicle Type</label>
                  <p>{obj?.type}</p>
                </Col>
                <Col lg={4}>
                  <label>Vehicle Owner</label>
                  <p>{obj?.owner}</p>
                </Col>
                <Col lg={4}>
                  <label>Driver Name</label>
                  <p>{obj?.driverName}</p>
                </Col>
                <Col lg={4}>
                  <label>Observation</label>
                  <p>{obj?.observation}</p>
                </Col>
                <Col lg={4}>
                  <label>Insurance Status</label>
                  <p>{obj?.insuranceStatus}</p>
                </Col>
                {form?.vehicleDetails?.length - 1 !== idx ? (
                  <div className="seperatorLine"></div>
                ) : (
                  ""
                )}
              </Row>
            </>
          );
        })
      ) : (
        <span className="text-danger">Not Applicable</span>
      )}
    </>
  );
};

export default VehicleDetailsView;
