import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import CheckBoxField from "../../../components/_form/CheckBoxField/CheckBoxField";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { capitalizeName } from "../../../_helpers/helper";
import {
  MODULE_OBJECT_VIEW,
  COAL_GRADE_VIEW,
  ROLE_OBJECT_VIEW,
} from "../../../_services/Auth/helper";
import { userLabel } from "../../SafetyManagementSystem/IncidentManagement/UserManagement/DATA";

const CoalGradeUserView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            {/* <p className="head">Sample Coding Register Data</p> */}
            <Row>
              <Col sm={6}>
                <label>{userLabel?.name}</label>
                <p className="content" name="user-name">
                  {capitalizeName(
                    `${form?.firstName ? form?.firstName : ""} ${
                      form?.lastName ? form?.lastName : ""
                    }`
                  )}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.roleId}</label>
                <p name="user-role" className="content">
                  {form?.role?.title || ""}
                </p>
              </Col>
              {form?.contractor ? (
                <Col sm={6}>
                  <label>{userLabel?.contractor_id}</label>
                  <p className="content">
                    {form?.contractor?.contractor || ""}
                  </p>
                </Col>
              ) : (
                ""
              )}
              <Col sm={6}>
                <label>{userLabel?.email}</label>
                <p name="email" className="content">
                  {form?.email ? form?.email : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.contactNumber}</label>
                <p className="content">
                  {form?.contactNumber ? form?.contactNumber : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.dob}</label>
                <p className="content">
                  {!form?.dob ? "" : DDMMYYYYFormat(form.dob)}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.siteId}</label>
                <p className="content" name="user-site">
                  {!form?.site?.name ? "" : form?.site?.name}
                </p>
              </Col>
            </Row>
            {form?.roleId === 4 && (
              <>
                <h6 className="mt-2">Permissions​</h6>
                <div className="seperator mt-0"></div>
                <Row>
                  {Object.keys(COAL_GRADE_VIEW)?.map((module, idx) => {
                    const roleData = Object.keys(COAL_GRADE_VIEW[module])?.map(
                      (role, i) => {
                        const permissionkey =
                          (Array.isArray(form?.accessPermissions) &&
                            form?.accessPermissions?.find(
                              (roleObj) => roleObj?.permission === role
                            )) ||
                          "";
                        return (
                          <Col key={i} md={6}>
                            <CheckBoxField
                              name={role}
                              checked={permissionkey ? true : false}
                              label={ROLE_OBJECT_VIEW?.[role]}
                              id={`${idx}check${i}`}
                              disabled={true}
                            />
                          </Col>
                        );
                      }
                    );
                    return (
                      <>
                        <h6 className="mt-2" key={idx}>
                          {MODULE_OBJECT_VIEW[module]}
                        </h6>{" "}
                        {roleData}
                      </>
                    );
                  })}
                </Row>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default CoalGradeUserView;
