import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomRadio from "../../../../../components/_form/CustomRadio/CustomRadio";
import FormInputText from "../../../../../components/FormInputText/FormInputText";
import Select from "../../../../../components/Select/Select";
import CustomDatePicker from "../../../../../components/DateTimeCustom/CustomDateTime";
import { compensationObjLavel, injuryCodeObj } from "../../DATA";
import { getArraayofKeys } from "../../Helper";
import { digitCheckAfterDecimal } from "../../../../../_helpers/helper";
import Asterik from "../../../../../components/Common/Asterik/Asterik";
import AsterikOnly from "../../../../../components/Common/Asterik/AstrikOnly";
import CustomDatePickerBasic from "../../../../../components/DateTimeCustom/CutomDatePickerBasic";

const CompensationInfor = ({
  form = {},
  onChange,
  informatinsDetails,
  onChangeInformation,
  errors,
  pendingFormC,
}) => {
  
  return (
    <div className="FormWrapper">
      <Form>
        {!pendingFormC ? (
          <Row>
            <Col lg={6}>
              <CustomRadio
                type="checkbox"
                name="isCompensationInfor"
                onChange={onChange}
                title={"Compensation Information"}
                acceptTitle={"Yes"}
                rejectTitle={"No"}
                customClass={"customRadioClass"}
                value={
                  form.isCompensationInfor === null
                    ? false
                    : form.isCompensationInfor
                }
              />
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          {form.isCompensationInfor && (
            <Col md="12">
              <h6>
                Personnel Info <AsterikOnly />
              </h6>
              <div className="formWrapper rootCause">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="tableHead-bg">
                      <tr>
                        <th style={{ minWidth: 200 }}>
                          {compensationObjLavel.outcome}
                        </th>
                        <th style={{ minWidth: 220 }}>
                          {compensationObjLavel.injuryCode}
                        </th>
                        <th style={{ minWidth: 180 }}>
                          {compensationObjLavel.dateOfReturnDuty}
                        </th>
                        <th style={{ minWidth: 180 }}>
                          {compensationObjLavel.returnForRegular}
                        </th>
                        <th style={{ minWidth: 150 }}>
                          {compensationObjLavel.compensationPaid}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.exGratiaPaid}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.otherBenefits}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.statutoryCompensationPaid}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.totalExperience}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.jobDoneAtAccidentTime}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.experienceInJobAtAccidentTime}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.bodyPartsLoss}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.bodyPartsGoneOut}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.vocationallyTrained}
                        </th>
                        <th style={{ minWidth: 207 }}>
                          {compensationObjLavel.initialVocationTraining}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.dateOfRefresherTraining}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {compensationObjLavel.jobNature}
                        </th>
                        {!pendingFormC ? (
                          <th style={{ minWidth: 100 }}>
                            {compensationObjLavel.causeOfDeath}
                          </th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {informatinsDetails.length ? (
                        informatinsDetails.map((x, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <Select
                                  // label={"Incident Severity"}
                                  name="outcome"
                                  value={x?.outcome}
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  options={[
                                    "Minor Injury",
                                    "Serious Injury",
                                    "Fatality",
                                  ]}
                                  init="Select"
                                  array="single"
                                  error={errors[index]?.outcome}
                                  KEY_label={"name"}
                                  KEY_value={"id"}
                                  disabled
                                ></Select>
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                {/* <Select
                                  // label={"Incident Severity"}
                                  name="injuryCode"
                                  value={x?.injuryCode}
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  options={getArraayofKeys(injuryCodeObj)}
                                  object={injuryCodeObj}
                                  error={errors[index]?.injuryCode}
                                  disabled
                                ></Select> */}
                                <FormInputText
                                  name="name"
                                  type="text"
                                  value={x.name}
                                  disabled={true}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                {x?.outcome !== "Fatality" ? (
                                  <>
                                    <CustomDatePickerBasic
                                      value={x?.dateOfReturnDuty}
                                      dateFormat="YYYY-MM-DD"
                                      name="dateOfReturnDuty"
                                      onChange={({ target: { name, value } }) =>
                                        onChangeInformation(index, name, value)
                                      }
                                      error={errors[index]?.dateOfReturnDuty}
                                    />
                                  </>
                                ) : (
                                  <FormInputText
                                    name="dateOfReturnDuty"
                                    type="text"
                                    value={x.dateOfReturnDuty}
                                    error={errors[index]?.dateOfReturnDuty}
                                    disabled
                                  />
                                )}
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <Select
                                  // label={"Incident Severity"}
                                  name="returnForRegular"
                                  value={x?.returnForRegular}
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  options={
                                    x?.outcome !== "Fatality"
                                      ? ["Regular", "Other"]
                                      : ["Not Applicable"]
                                  }
                                  init="Select"
                                  array="single"
                                  error={errors[index]?.returnForRegular}
                                  KEY_label={"name"}
                                  KEY_value={"id"}
                                  disabled={
                                    x?.outcome !== "Fatality" ? false : true
                                  }
                                ></Select>
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="compensationPaid"
                                  type="number"
                                  onChange={({ target: { name, value } }) =>
                                    value >= 0 &&
                                    digitCheckAfterDecimal(value, 2) &&
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.compensationPaid}
                                  // className="form-control"
                                  error={errors[index]?.compensationPaid}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="exGratiaPaid"
                                  type="number"
                                  onChange={({ target: { name, value } }) =>
                                    value >= 0 &&
                                    digitCheckAfterDecimal(value, 2) &&
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.exGratiaPaid}
                                  // className="form-control"
                                  error={errors[index]?.exGratiaPaid}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="otherBenefits"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.otherBenefits}
                                  error={errors[index]?.otherBenefits}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="statutoryCompensationPaid"
                                  type="number"
                                  onChange={({ target: { name, value } }) =>
                                    value >= 0 &&
                                    digitCheckAfterDecimal(value, 2) &&
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.statutoryCompensationPaid}
                                  error={
                                    errors[index]?.statutoryCompensationPaid
                                  }
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="totalExperience"
                                  type="number"
                                  onChange={({ target: { name, value } }) =>
                                    value >= 0 &&
                                    digitCheckAfterDecimal(value, 2) &&
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.totalExperience}
                                  // className="form-control"
                                  error={errors[index]?.totalExperience}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="jobDoneAtAccidentTime"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.jobDoneAtAccidentTime}
                                  error={errors[index]?.jobDoneAtAccidentTime}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="experienceInJobAtAccidentTime"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.experienceInJobAtAccidentTime}
                                  error={
                                    errors[index]?.experienceInJobAtAccidentTime
                                  }
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="bodyPartsLoss"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.bodyPartsLoss}
                                  error={errors[index]?.bodyPartsLoss}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="bodyPartsGoneOut"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.bodyPartsGoneOut}
                                  error={errors[index]?.bodyPartsGoneOut}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <CustomRadio
                                  type="checkbox"
                                  name="vocationallyTrained"
                                  onChange={({
                                    target: { name, checked: value },
                                  }) => onChangeInformation(index, name, value)}
                                  title={""}
                                  acceptTitle={"Yes"}
                                  rejectTitle={"No"}
                                  checkBox={true}
                                  customClass={"customRadioClass"}
                                  value={
                                    x.vocationallyTrained === null
                                      ? false
                                      : x.vocationallyTrained
                                  }
                                />
                                <span className="small text-danger">
                                  {errors[index]?.vocationallyTrained}
                                </span>
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <CustomRadio
                                  type="checkbox"
                                  name="initialVocationTraining"
                                  onChange={({
                                    target: { name, checked: value },
                                  }) => onChangeInformation(index, name, value)}
                                  title={""}
                                  acceptTitle={"Yes"}
                                  rejectTitle={"No"}
                                  checkBox={true}
                                  customClass={"customRadioClass"}
                                  value={
                                    x.initialVocationTraining === null
                                      ? false
                                      : x.initialVocationTraining
                                  }
                                />
                                <span
                                  className="small text-danger"
                                  style={{ marginLeft: "30px" }}
                                >
                                  {errors[index]?.initialVocationTraining}
                                </span>
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <CustomDatePickerBasic
                                  value={x?.dateOfRefresherTraining}
                                  dateFormat="YYYY-MM-DD"
                                  name="dateOfRefresherTraining"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  error={errors[index]?.dateOfRefresherTraining}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <CustomRadio
                                  type="checkbox"
                                  name="jobNature"
                                  onChange={({
                                    target: { name, checked: value },
                                  }) => onChangeInformation(index, name, value)}
                                  title={""}
                                  acceptTitle={"Contractual"}
                                  rejectTitle={"Departmental"}
                                  checkBox={true}
                                  customClass={"customRadioClass"}
                                  value={
                                    x.jobNature === null ? false : x.jobNature
                                  }
                                />
                                <span
                                  className="small text-danger"
                                  style={{ marginLeft: "30px" }}
                                >
                                  {errors[index]?.jobNature}
                                </span>
                              </td>
                              {!pendingFormC ? (
                                <td
                                  style={{ borderRight: "3px solid #dee2e6" }}
                                >
                                  {x?.outcome === "Fatality" && (
                                    <FormInputText
                                      name="causeOfDeath"
                                      type="text"
                                      onChange={({ target: { name, value } }) =>
                                        onChangeInformation(index, name, value)
                                      }
                                      value={x.causeOfDeath}
                                      error={errors[index]?.causeOfDeath}
                                    />
                                  )}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12" align="center"></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default CompensationInfor;
