import { useLocation } from "react-router";
import { Navigate, useNavigate } from "react-router-dom";
import { IS_MOBILE, auth_user, isLogin } from "../../_helpers/helper";
import RoutePermission, {
  COAL_GRADE_TYPE_ACTION_ROUTE,
  COAL_GRADE_TYPE_ROUTE,
  ENV_DASHBOARD_TYPE_ROUTE,
  COAL_GRADE_RESULT_APPROVE,
} from "../Auth/RoutePermision";
import { permissionCheck } from "./helper";
import { logout, logoutUser } from "../../services/logoutService";

export function removeExtraKeys(temp) {
  if (!temp) return "";

  const re =
    /:landTyeId|:individualId|:id|:ExId|:wellInfoId|:leaseId|:projectId|:civilWorkStatusId|:opdId|:landOwnerId|:casingId|:wellId|:nptId|:LAQId|:CId|:HfId|:drId|:ActivityId|:sId|:GSSId|:casingRowId|:casingDataDowId|:rigId|:workoverDetailsId|:dprId|:dpId|:siteId|:type/gi;
  const newStr = temp.replace(re, (match) => "");

  return newStr;
}
export const RequireAuth = ({ children, redirectTo }) => {
  let currentUrl = useLocation();
  const navigate = useNavigate();
  const authUser = auth_user();

  const isAuthenticated = isLogin();
  console.log("5555555555555555", isAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to={IS_MOBILE() ? "/mobile/auth/login" : "/auth/login"} />;
  }

  /**
   * Route permission check
   */
  let authenticated = true;
  let matched = null;
  currentUrl = currentUrl?.pathname;
  const IsPathNumeric = currentUrl?.match(/\d+/g);
  if (IsPathNumeric && IsPathNumeric.length) {
    currentUrl = currentUrl?.replace(/\d+/g, "");

    matched = RoutePermission.find((r) => {
      if (removeExtraKeys(r.path) === currentUrl) return currentUrl;
    });
  } else {
    if (COAL_GRADE_TYPE_ROUTE?.includes(currentUrl)) {
      matched = RoutePermission.find((r) => r?.routeKey === "coalGrade");
    } else if (COAL_GRADE_TYPE_ACTION_ROUTE?.includes(currentUrl)) {
      matched = RoutePermission.find((r) => r?.routeKey === "coalGrade");
    } else if (COAL_GRADE_RESULT_APPROVE?.includes(currentUrl)) {
      matched = RoutePermission.find((r) => r?.routeKey === "coalGradeApprove");
    } else if (ENV_DASHBOARD_TYPE_ROUTE?.includes(currentUrl)) {
      matched = RoutePermission.find((r) => r?.routeKey === "envDas");
    } else matched = RoutePermission.find((r) => r.path == currentUrl);
  }

  if (!matched) {
    return <Navigate to={"/error/404"} />;
  }

  if (matched?.permission) {
    const permissionStatus = !Array.isArray(matched?.permission)
      ? matched?.permission === authUser?.role?.roleLable
        ? true
        : false
      : permissionCheck(matched.permission);
    if (!permissionStatus) {
      authenticated = false;
    }
  }

  if (!authenticated) {
    // logout();
    // return <Navigate to={"/auth/login"} />;
    (async () => {
      const isLoggedOut = await logoutUser();
      return isLoggedOut && navigate("/auth/login");
    })();
  } else return children;
};
