import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import { PermissionReducer } from "./PermissionReducer";
import userData from "./userData.reducer";
import { DashboardReducer } from "./DashboardReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  PermissionReducer,
  dashboardFilter: DashboardReducer,
  // user: UserReducer,
  // layout: LayoutReducer,
  // scrumboard: ScrumBoardReducer,
  // notification: NotificationReducer,
  userData: userData,
  // export: ExportReducer,
  // filterReducerTraining
});

export default RootReducer;
