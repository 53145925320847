import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import {
  hindranceApis,
  fields,
  initialFilters,
  SidebarData,
  exportContractorHindrance,
} from "./DATA";
import EmptyDataList from "./EmptyDataList";
import HindranceFilterForm from "../Hindrance/HindranceFilterForms";
import { getAllHindrance } from "../../../services/_hindrance/hindrance";
import { useForm } from "../../../_helpers/hooks";
import HindranceView from "./HindranceView";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import "./hindrance.scss";

import { getAllContractor } from "../../../../src/services/_hindrance/hindrance";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import { tabUrl } from "../../../_constants/Constant";
import { permissionCheck } from "../../../_services/Auth/helper";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { getAllContractorByNit } from "../../../services/_contractorPerformance/contractorPerformance";
const HindranceList = () => {
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(initialFilters);
  const [contractorList, setContractorList] = useState([]);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.contractorPerformance)
  );

  useEffect(() => {
    setCustomTabs((prev) => {
      return Array.isArray(prev)
        ? prev.map((d) => {
            if (d.to === usrl.pathname) {
              return {
                ...d,
                active: 1,
              };
            }
            return d;
          })
        : {};
    });
  }, []);

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const onEdit = (id) => {
    navigate("/contractor-management/contractor-hindrance/update/" + id);
  };

  // const onApprove = (id) => {
  //   navigate(
  //     "/contractor-management/contractor-hindrance/approve/representative/" + id
  //   );
  // };

  const onResetFilter = () => {
    setForm({});
    setGetAllFilter();
  };

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    const response = await getAllHindrance();
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else {
      setIsListData(false);
    }

    const allContractorList = await getAllContractorByNit();
    setContractorList(
      allContractorList.map((d) => {
        return { value: d.id, label: d.contractor };
      })
    );
  };

  const viewAsPopup = (form) => {
    return <HindranceView form={form} />;
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        filter += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(filter);
  }, [filterStatus]);

  // const tabButtons = () => {
  //   return <CustomTabs tabeList={customTabs} />;
  // };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
        >
          <img className="img-fluid" src={filter} alt="fliterIcon" />
        </Button>
        <HindranceFilterForm
          onChange={onChange}
          form={form}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          contractorList={contractorList}
        />
      </div>
    );
  };

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                title="Data"
                pageLink="/contractor-management/contractor-hindrance"
                fields={fields}
                apis={hindranceApis}
                FilterBtn={FilterBtn}
                primaryKey="id"
                hideSnColumn
                ationStatusCol={true}
                pagination
                listname={"hindrance"}
                permissionAddButton={"contractor"}
                getAllFilter={getAllFilter}
                hideAddButton={false}
                viewAsPopup={viewAsPopup}
                // childrenAfterAddButton={tabButtons}
                downloadExcel={exportContractorHindrance}
                modalSize={"xl"}
                editFun={onEdit}
                // approveFun={onApprove}
                customTableTitle={"Contractor Hindrance"}
                viewTitle={"Contractor Hindrance Details"}
                // actionBtn
                breadCrumb={[
                  {
                    name: "Contractor Hindrance Management",
                    path: "#",
                  },
                  {
                    name: "Contractor Hindrance",
                    path: "/contractor-management/contractor-hindrance/module",
                  },
                  {
                    name: "View Contractor Hindrance",
                    path: "#",
                  },
                ]}
                hideEditIcon
                isNoTabOnPage
                icons
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <h3
            style={{ paddingBottom: "1px", paddingTop: "5px" }}
            className="title"
          >
            <MainBreadcrumb
              routeSegments={[
                {
                  path: "#",
                  name: "Contractor Hindrance Management",
                },
                {
                  name: "Contractor Hindrance",
                  path: "/contractor-management/contractor-hindrance/module",
                },
                {
                  name: "View Contractor Hindrance",
                  path: "#",
                },
              ]}
            />
          </h3>
          <EmptyDataList
            // tabButtons={tabButtons}
            pageLink={"/contractor-management/contractor-hindrance"}
            hideAddBtn={!permissionCheck(["contractor"])}
            title={"Contractor Hindrance"}
          />
        </div>
      </div>
    </div>
  );
};

export default HindranceList;
