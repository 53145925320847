import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { samplePrepareRegisterLabel } from "./DATA";
import { modeOfDispatchLabel } from "../DATA";
import CreatedByCreatedAt from "../../../components/Common/CreatedByCreatedAt";

const SamplePrepareRegisterView = ({
  form,
  hideClass,
  noPadding,
  noCreatedBy,
}) => {
  return (
    <>
      <Modal.Body className={`${noPadding ? "" : "p-0"}`}>
        <div className={`${hideClass || "preview"}`}>
          <div className="previewContent">
            <p className="head">Coal Dispatch Related Data</p>
            <Row>
              <Col sm={6}>
                <label>{samplePrepareRegisterLabel?.mode_of_dispatch}</label>
                <p className="content">
                  {form?.mode_of_dispatch &&
                    modeOfDispatchLabel[form?.mode_of_dispatch]}
                </p>
              </Col>
              <Col sm={6}>
                <label>{samplePrepareRegisterLabel?.rr_or_do_number}</label>
                <p className="content">{form?.rr_or_do_number}</p>
              </Col>
              <Col sm={6}>
                <label>{samplePrepareRegisterLabel?.rr_or_do_date}</label>
                <p className="content">
                  {form?.rr_or_do_date
                    ? DDMMYYYYFormat(form?.rr_or_do_date)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{samplePrepareRegisterLabel?.declared_grade}</label>
                <p className="content">{form?.declared_grade}</p>
              </Col>
            </Row>
          </div>
          <div className="previewContent">
            <p className="head">Sample Related Data</p>
            <Row>
              <Col sm={6}>
                <label>{samplePrepareRegisterLabel?.sample_id}</label>
                <p className="content">{form?.sample_id}</p>
              </Col>
              <Col sm={6}>
                <label>{samplePrepareRegisterLabel?.date_of_collection}</label>
                <p className="content">
                  {form?.date_of_collection
                    ? DDMMYYYYFormat(form?.date_of_collection)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>
                  {samplePrepareRegisterLabel?.name_of_sampling_agency}
                </label>
                <p className="content">{form?.sampling_agency?.name}</p>
              </Col>
              <Col sm={6}>
                <label>{samplePrepareRegisterLabel?.date_of_prepartaion}</label>
                <p className="content">
                  {form?.date_of_prepartaion
                    ? DDMMYYYYFormat(form?.date_of_prepartaion)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{samplePrepareRegisterLabel?.ncl_seal_number}</label>
                <p className="content">{form?.ncl_seal_number}</p>
              </Col>
              <Col sm={6}>
                <label>
                  {samplePrepareRegisterLabel?.date_of_sample_sent_to_lab}
                </label>
                <p className="content">
                  {form?.date_of_sample_sent_to_lab
                    ? DDMMYYYYFormat(form?.date_of_sample_sent_to_lab)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{samplePrepareRegisterLabel?.referee_seal_number}</label>
                <p className="content">{form?.referee_seal_number}</p>
              </Col>
              <Col sm={6}>
                <label>
                  {samplePrepareRegisterLabel?.name_of_ncl_representative}
                </label>
                <p className="content">{form?.name_of_ncl_representative}</p>
              </Col>
              <Col sm={6}>
                <label>
                  {
                    samplePrepareRegisterLabel?.name_of_sampling_agency_representative
                  }
                </label>
                <p className="content">
                  {form?.name_of_sampling_agency_representative}
                </p>
              </Col>
            </Row>
          </div>
        </div>
        {!noCreatedBy ? (
          <CreatedByCreatedAt
            id={form?.id}
            table={"coal_grade_quality_data"}
            border={true}
          />
        ) : (
          ""
        )}
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default SamplePrepareRegisterView;
