import React, { useState } from "react";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { PARSE_IMG_URL } from "../../../../../_helpers/commonHelper";
import { URL_CONFIG } from "../../../../../_constants/Config/URL_CONFIG";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { evidenceTypes } from "../../DATA";
import EvidenceVideo from "../EvidenceVideo";
import PopUpFile from "../../../../../components/PopUpFile/PopUpFile";
import { IoPlayCircleOutline } from "react-icons/io5";
import { Document, Page, pdfjs } from "react-pdf";
import PdfPopup from "../../../../../components/PdfPopup/PdfPopup";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const EvidenceView = ({ form }) => {
  const [videoShowManageModal, setVideoShowManageModal] = useState(false);
  const [imageShowManageModal, setImageShowManageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");

  return (
    <>
      <Row>
        <Col lg={6} key={"idx"}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Evidence
          </label>
        </Col>
      </Row>
      {form?.length ? (
        form?.map((obj, idx) => {
          return (
            <>
              <Row>
                <Col lg={6} key={"idx"}>
                  <label>Type</label>
                  <p>{evidenceTypes[obj?.type]}</p>
                </Col>
                <Col lg={6}>
                  <label>Description</label>
                  <p>{obj?.media?.alias}</p>
                </Col>
                <Col lg={3}>
                  <label>File View</label>
                  <div className="safetyMedia">
                    <div className="safetyImgSec">
                      {obj?.media?.type === "video/mp4" ? (
                        <div
                          data-testid="videoSec"
                          className="videoSec"
                          onClick={() => {
                            setVideoShowManageModal(true);
                            setVideoUrl(
                              PARSE_IMG_URL(
                                URL_CONFIG.DEV_URL,
                                obj?.media?.document_name
                              )
                            );
                          }}
                        >
                          <PopUpFile
                            src={PARSE_IMG_URL(
                              URL_CONFIG.DEV_URL,
                              obj?.media?.document_name
                            )}
                            title={obj?.real_document_name}
                            height="105"
                            hideClass
                          />

                          <div className="svgSec">
                            <IoPlayCircleOutline />
                          </div>
                        </div>
                      ) : obj?.type === "photo" ? (
                        <img
                          onClick={() => {
                            setImageShowManageModal(true);
                            setImageUrl(
                              PARSE_IMG_URL(
                                URL_CONFIG.DEV_URL,
                                obj?.media?.document_name
                              )
                            );
                          }}
                          // src={URL_CONFIG.IMG_URL + d.media.document_name}
                          src={PARSE_IMG_URL(
                            URL_CONFIG.DEV_URL,
                            obj?.media?.document_name
                          )}
                          className="img-fluid "
                          alt="IMG"
                        />
                      ) : (
                        <Document
                          data-testid="pdfDoc"
                          file={PARSE_IMG_URL(
                            URL_CONFIG.DEV_URL,
                            obj?.media?.document_name
                          )}
                          // onLoadSuccess={onDocumentLoadSuccess}
                          onClick={() => {
                            setImageShowManageModal(true);
                            setImageUrl("");
                            setPdfUrl(obj?.media?.document_name);
                          }}
                        >
                          <Page
                            pageNumber={1}
                            width={225}
                            height={200}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                          />
                        </Document>
                      )}
                    </div>
                  </div>
                </Col>
                {form?.incidentMedias?.length - 1 !== idx ? (
                  <div className="seperatorLine"></div>
                ) : (
                  ""
                )}
              </Row>
            </>
          );
        })
      ) : (
        <span className="text-danger">Not Applicable</span>
      )}
      <Modal
        size="lg"
        show={imageShowManageModal}
        onHide={() => setImageShowManageModal(false)}
        centered
      >
        <ModalBody className="safetyModal">
          <div
            data-testid="filter-button"
            onClick={() => {
              setImageShowManageModal(false);
            }}
            className="closeIcon"
          >
            <IoIosCloseCircleOutline />
          </div>
          {imageUrl ? (
            <div className="popupImgVideo text-center">
              <img className="img-fluid" src={imageUrl} alt="" />
            </div>
          ) : (
            <PdfPopup fileName={pdfUrl} hideDownload />
          )}
        </ModalBody>
      </Modal>
      <Modal
        centered
        show={videoShowManageModal}
        onHide={() => setVideoShowManageModal(false)}
        size="lg"
      >
        <ModalBody>
          <div
            data-testid="closeVideo"
            className="closeIcon"
            onClick={() => {
              setVideoShowManageModal(false);
            }}
          >
            <IoIosCloseCircleOutline />
          </div>
          <div className="popupImgVideo">
            <PopUpFile
              // title={d?.real_document_name}
              src={videoUrl}
              hideClass
              height="460"
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EvidenceView;
