import Dashboard from "../../_pages/Dashboard";
import SampleCodingRegister from "../../_pages/CoalGrade/SampleCoding/SampleCodingRegister";
import SampleDecodingRegister from "../../_pages/CoalGrade/SampleDecoding/SampleDecodingRegister";
import RefereeLabResult from "../../_pages/CoalGrade/RefereeLabResult/RefereeLabResult";
import ThirdPartyLabResult from "../../_pages/CoalGrade/ThirdPartyLabResult/ThirdPartyLabResult";
import SamplePrepareRegisterAdd from "../../_pages/CoalGrade/CoalSamplePreparation/SamplePrepareRegisterAdd";
import CoalGradeList from "../../_pages/CoalGrade/CoalGradeList";
import HindranceList from "../../_pages/ContractorManagement/Hindrance/HindranceList";
import ContractorPerformanceAdd from "../../_pages/ContractorManagement/ContractorPerformance/ContractorPerformanceAdd";
import ContractorPerformanceDashboard from "../../_pages/ContractorManagement/ContractorPerformance/ContractorPerformanceDashboard";
import ContractorPerformanceScoreCardDashboard from "../../_pages/ContractorManagement/ContractorPerformance/ContractorPerformanceScoreCardVendorDashboard";
import ContractorPerformanceScoreCardVendorDashboard from "../../_pages/ContractorManagement/ContractorPerformance/ContractorPerformanceScoreCardVendorDashboard";
import ContractorPerformanceScoreCardMonthDashboard from "../../_pages/ContractorManagement/ContractorPerformance/ContractorPerformanceScoreCardMonthDashboard";

import ContractorHindranceCardDashboard from "../../_pages/ContractorManagement/ContractorPerformance/ContractorHindranceDashboard";
import ContractorPerformanceSapDashboard from "../../_pages/ContractorManagement/ContractorPerformance/ContractorPerformanceSapDashboard";
import MasterListAdd from "../../_pages/ContractorManagement/ReasonList/MasterListAdd";
import ContractorAdd from "../../_pages/ContractorManagement/ContractorList/ContractorAdd";
import HindranceAdd from "../../_pages/ContractorManagement/Hindrance/HindranceAdd";
import ContractorPerformanceList from "../../_pages/ContractorManagement/ContractorPerformance/ContractorPerformanceList";
import UploadedFileList from "../../_pages/EnvironmentManagement/UploadedFiles/UploadedFileList";
import HindranceApprove from "../../_pages/ContractorManagement/Hindrance/HindranceApprove";
import FuelManagementList from "../../_pages/FuelManagementSystem/FuelManagementList";
import FuelManagementAdd from "../../_pages/FuelManagementSystem/FuelManagementAdd";
import FuelManagement from "../../_pages/FuelManagementSystem/FuelManagement/FuelManagement";
import SafetyManagementEdit from "../../_pages/SafetyManagementSystem/SafetyManagementEdit";
import SafetyManagementFormC from "../../_pages/SafetyManagementSystem/SafetyManagementFormC";
import UploadFileAdd from "../../_pages/EnvironmentManagement/UploadedFiles/UploadFileAdd";

import UserList from "../../_pages/FuelManagementSystem/UserList";
import ViewProfile from "../../components/Profile/ViewProfile";
import ChangePassword from "../../components/Profile/ChangePassword";

import ReasonList from "../../_pages/ContractorManagement/ReasonList/ReasonList";
import SupplierList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Supplier/SupplierList";
import SupplierAdd from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Supplier/SupplierAdd";
import ContractorList from "../../_pages/ContractorManagement/ContractorList/ContractorList";
import UserManagementList from "../../_pages/UserManagement/UserManagementList";
import UserAddEdit from "../../_pages/UserManagement/UserAddEdit";
import CPDashboard from "../../components/Common/dashboard/ModuleDashboard/ContractorPerformanceDashboard";
import FuelMgmtDashboard from "../../components/Common/dashboard/ModuleDashboard/FuelManagemtDashboard";
import SafetyMgmtDashboard from "../../components/Common/dashboard/ModuleDashboard/SafetyManagementDashboard";
import CGDashboard from "../../components/Common/dashboard/ModuleDashboard/CoalGradeDashboard";
import EnvironmentDashboard from "../../components/Common/dashboard/ModuleDashboard/EnvironmentDashboard";
import HemmCockpitDashboard from "../../components/Common/dashboard/ModuleDashboard/HemmCockpitDashboard";
import MasterAirQualityEmr from "../../_pages/EnvironmentManagement/UploadedFiles/MasterAirQualityEmr";
import MasterNoise from "../../_pages/EnvironmentManagement/UploadedFiles/MasterNoise";
import MasterStationTypeWater from "../../_pages/EnvironmentManagement/UploadedFiles/MasterStationTypeWater";
import MasterStationCategoryWater from "../../_pages/EnvironmentManagement/UploadedFiles/MasterStationCategoryWater";
import MasterAirQualityEmrList from "../../_pages/EnvironmentManagement/UploadedFiles/MasterAirQualityEmrList";
import MasterNoiseList from "../../_pages/EnvironmentManagement/UploadedFiles/MasterNoiseList";
import MasterStationTypeWaterList from "../../_pages/EnvironmentManagement/UploadedFiles/MasterStationTypeWaterList";
import MasterStationCategoryWaterList from "../../_pages/EnvironmentManagement/UploadedFiles/MasterStationCategoryWaterList";

import MasterGroundWaterWell from "../../_pages/EnvironmentManagement/UploadedFiles/MasterGroundWaterWell";
import MasterGroundWaterWellList from "../../_pages/EnvironmentManagement/UploadedFiles/MasterGroundWaterWellList";
import IncidentManagement from "../../_pages/SafetyManagementSystem/IncidentManagement/IncidentManagement";
import Configuration from "../../_pages/SafetyManagementSystem/Configuration/Configuration";
import ActivityList from "../../_pages/SafetyManagementSystem/Configuration/Activity/ActivityList";
import ActivityAddEdit from "../../_pages/SafetyManagementSystem/Configuration/Activity/ActivityAddEdit";

import ViolationsList from "../../_pages/SafetyManagementSystem/Configuration/Violations/ViolationsList";
import ViolationsCreate from "../../_pages/SafetyManagementSystem/Configuration/Violations/ViolationsCreate";

import AreaAddEdit from "../../_pages/SafetyManagementSystem/Configuration/AreaOfOperation/AreaAddEdit";
import AreaList from "../../_pages/SafetyManagementSystem/Configuration/AreaOfOperation/AreaList";
import IncidentKpiList from "../../_pages/SafetyManagementSystem/Configuration/IncidentKpi/IncidentKpiList";
import IncidentKpiAddEdit from "../../_pages/SafetyManagementSystem/Configuration/IncidentKpi/IncidentKpi";
import IncidentReport from "../../_pages/SafetyManagementSystem/IncidentManagement/IncidentReport";
import EnvironmentManagement from "../../_pages/EnvironmentManagement/UploadedFiles/EnvironmentManagement";
import ManageEnvironmentReport from "../../_pages/EnvironmentManagement/UploadedFiles/ManageEnvironmentReport";
import EnvironmentReportManagement from "../../_pages/EnvironmentManagement/UploadedFiles/EnvironmentReportManagement";
import AirQualityUploadedData from "../../_pages/EnvironmentManagement/UploadedFiles/AirQualityUploadedData";
import ReportedIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/ReportedIncidentList";
import EnvConfiguration from "../../_pages/EnvironmentManagement/UploadedFiles/Configuration/EnvConfiguration";
import MyIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyIncidentList";
// import MyIncidentApprovalHistoryList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyIncidentApprovalHistoryList";
import InvestigationIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/InvestigationIncidentList";
import SafetyManagementList from "../../_pages/SafetyManagementSystem/SafetyManagementList";
import CapaAssignedIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/CapaAssignedIncidentList";
import ViewIncidentList from "../../_pages/SafetyManagementSystem/IncidentManagement/ViewIncidentList";
import MyApprovalReportedIncidentHistoryList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyApprovalReportedIncidentHistoryList ";
import MyApprovalInvestigatorIncidentHistoryList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyApprovalInvestigatorIncidentHistoryList";
import MyApprovalCapOwnerIncidentHistoryList from "../../_pages/SafetyManagementSystem/IncidentManagement/MyApprovalCapOwnerIncidentHistoryList";
import IncidentUserManagementList from "../../_pages/SafetyManagementSystem/IncidentManagement/UserManagement/IncidentUserManagementList";
import SafetyManagementDashboard from "../../_pages/SafetyManagementSystem/IncidentManagement/SafetyManagementDashboard";
import AirQualityUploadedDataEdit from "../../_pages/EnvironmentManagement/UploadedFiles/AirQualityUploadedDataEdit";
import EnvUserManagementList from "../../_pages/SafetyManagementSystem/IncidentManagement/UserManagement/EnvUserManagementList";
import CapaEdit from "../../_pages/SafetyManagementSystem/CapaEdit";
import CoalGradeDashboard from "../../_pages/CoalGrade/Dashboard/CoalGradeDashboard";
import CoalGradeUserList from "../../_pages/CoalGrade/UserManagement/CoalGradeUserList";
import CoalGradeConfiguration from "../../_pages/CoalGrade/Configuration/CoalGradeConfiguration";
// import CoalGradeConfigurationActivity from "../../_pages/CoalGrade/Configuration/Activity/CoalGradeConfigurationActivity";
// import CoalGradeConfigurationAreaOfOperation from "../../_pages/CoalGrade/Configuration/AreaOfOperation/CoalGradeConfigurationAreaOfOperation";
// import CoalGradeConfigurationAreaAddEdit from "../../_pages/CoalGrade/Configuration/AreaOfOperation/CoalGradeConfigurationAreaAddEdit";
import SamplingAgencyAddEdit from "../../_pages/CoalGrade/Configuration/SamplingAgency/SamplingAgencyAddEdit";
import SamplingAgency from "../../_pages/CoalGrade/Configuration/SamplingAgency/SamplingAgency";
// import CoalGradeConfigurationActivityAddEdit from "../../_pages/CoalGrade/Configuration/Activity/CoalGradeConfigurationActivityAddEdit";
import CoalGradeTestList from "../../_pages/CoalGrade/CoalGradeTestList";
import CoalGradeActionList from "../../_pages/CoalGrade/CoalGradeActionList";
import ApproveLabResultList from "../../_pages/CoalGrade/ApproveLabResult/ApproveLabResultList";
import CoalQualityList from "../../_pages/CoalGrade/CoalQualityList";
import ContractorManagementDashboard from "../../_pages/ContractorManagement/ContractorManagementDashboard";
import ContractorPerformanceManagement from "../../_pages/ContractorManagement/ContractorPerformance/ContractorPerformanceManagement";
import MyContractorPerformanceList from "../../_pages/ContractorManagement/ContractorPerformance/MyContractorPerformanceList";
import ContractorHindranceManagement from "../../_pages/ContractorManagement/Hindrance/ContractorHindranceManagement";
import MyHindranceList from "../../_pages/ContractorManagement/Hindrance/MyHindranceList";
import MyApprovalHindrance from "../../_pages/ContractorManagement/Hindrance/MyApprovalHindrance";
import { MOBILE_ROUTE } from "./MobileRoute";

import ContractorScoreAdd from "../../_pages/ContractorManagement/ContractorScore/ContractorScoreAdd";
import ContractorScoreList from "../../_pages/ContractorManagement/ContractorScore/ContractorScoreList";
import ContractorScoreManagement from "../../_pages/SafetyManagementSystem/IncidentManagement/ContractorScore";
import HelpManualCoalGrade from "../../_pages/CoalGrade/HelpManual";
import HelpManual from "../../_pages/SafetyManagementSystem/HelpManual/HelpManual";
import FuelManagementHelpManual from "../../_pages/FuelManagementSystem/FuelManagement/HelpManualFuelManagement";
import ContractorUserManagementList from "../../_pages/SafetyManagementSystem/IncidentManagement/UserManagement/ContractorUserManagementList";
import FuelManagementConfiguration from "../../_pages/FuelManagementSystem/FuelManagementConfiguration";
import ConfigurationFuelLocationAdd from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/FuelLocation/FuelLocationAdd";
import ConfigurationFuelLocationList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/FuelLocation/FuelLocationList";
import HemmAdd from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Hemm/HemmAdd";
import HemmList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Hemm/HemmList";
import HelpManualSafetyManagement from "../../_pages/SafetyManagementSystem/IncidentManagement/HelpManualSafetyManagement";
import HelpManualEnv from "../../_pages/EnvironmentManagement/UploadedFiles/HelpManualEnv";
import ConfigurationManagement from "../../_pages/ContractorManagement/Configuration/ConfigurationManagement";
import ContractorConUserManagement from "../../_pages/UserManagement/ContractorConUserManagement";
import ContractorConUserManagementAddEdit from "../../_pages/UserManagement/ContractorConUserManagementAddEdit";
import EnvNewConfiguration from "../../_pages/EnvironmentManagement/UploadedFiles/Configuration/EnvNewConfiguration";
import PollutionControlMachineList from "../../_pages/EnvironmentManagement/UploadedFiles/PollutionControlMachineList";
import EnvConfigStation from "../../_pages/EnvironmentManagement/UploadedFiles/Configuration/EnvConfigStation";
import EnvConfigStationAddEdit from "../../_pages/EnvironmentManagement/UploadedFiles/Configuration/EnvConfigStationAddEdit";
import PollutionControlAddEdit from "../../_pages/EnvironmentManagement/UploadedFiles/PollutionControlAddEdit";

import EnvConfigPermission from "../../_pages/EnvironmentManagement/UploadedFiles/Configuration/EnvConfigPermission";
import EnvConfigPermissionAddEdit from "../../_pages/EnvironmentManagement/UploadedFiles/Configuration/EnvConfigPermissionAddEdit";
import IncidentManagementDashboard from "../../_pages/SafetyManagementSystem/IncidentManagement/Dashboard/IncidentManagementDashboard";
import NitList from "../../_pages/ContractorManagement/NitList/NitList";
import NitAdd from "../../_pages/ContractorManagement/NitList/NitAdd";
import EnvironmentManagementDashboard from "../../_pages/EnvironmentManagement/EnvironmentManagementDashboard/EnvironmentManagementDashboard";
import FuelManagementDashboard from "../../_pages/FuelManagementSystem/Dashboard/FuelManagementDashboard";
import FuelManagementSapDashboard from "../../_pages/FuelManagementSystem/Dashboard/FuelManagementSapDashboard";
import { reportTypeListUrl } from "../../_pages/EnvironmentManagement/UploadedFiles/DATA";
import FuelUserList from "../../_pages/FuelManagementSystem/FuelManagement/FuelUserList";
import MyScoreCardList from "../../_pages/ContractorManagement/ContractorScore/MyScoreCardList";
import HelpManualView from "../../_pages/UserManagement/HelpManualView";
import HelpManualEdit from "../../_pages/UserManagement/HelpManualEdit";
import ImportSapConfig from "../../_pages/SapImport/ImportSap";
import ImportSapList from "../../_pages/SapImport/ImportSapList";
import ImportSapUpload from "../../_pages/SapImport/ImportSapAdd";
import ImportSapUploaded from "../../_pages/SapImport/ImportSapList";
import ImportSapContractorAdd from "../../_pages/SapImport/ImportSapContractorAdd";
import SafetyDocument from "../../_pages/SafetyManagementSystem/SafetyDocument/SafetyDocument";
import SafetyDocumentUpload from "../../_pages/SafetyManagementSystem/SafetyDocument/SafetyDocumentUpload";
import SafetyDocumentList from "../../_pages/SafetyManagementSystem/SafetyDocument/SafetyDocumentList";
import FuelManagementStock from "../../_pages/FuelManagementSystem/FuelManagementStock";
import QuickSightDashboard from "../../_pages/Dashboard/QuickSightDashboard";
import SapUpdate from "../../_pages/CoalGrade/Configuration/SapUpdate/SapUpdate";
import SapData from "../../_pages/CoalGrade/Configuration/SapData/SapData";
import TankList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Tank/TankList";
import TankAdd from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Tank/TankAdd";
import StockList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/StockUpdate/StockList";
import ReceiptList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Receipt/ReceiptList";
import ReceiptAdd from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Receipt/ReceiptAdd";
import StockAdd from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/StockUpdate/StockAdd";
import MyReceiptList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/Receipt/MyReceiptList";
import CsisDataList from "../../_pages/SafetyManagementSystem/CsisData/CsisDataList";
import DeviceList from "../../_pages/EnvironmentManagement/UploadedFiles/Configuration/Device/DeviceList";
import DeviceAdd from "../../_pages/EnvironmentManagement/UploadedFiles/Configuration/Device/DeviceAdd";
import MineDetailsAdd from "../../_pages/SafetyManagementSystem/Configuration/MineDetails/MineDetailsAdd";
import MineDetailsList from "../../_pages/SafetyManagementSystem/Configuration/MineDetails/MineDetailsList";
import PoEdit from "../../_pages/ContractorManagement/PoList/PoEdit";
import PoList from "../../_pages/ContractorManagement/PoList/PoList";
import FuelReports from "../../_pages/FuelManagementSystem/FuelReports/FuelReports";
import ContractorHindranceDashboardSap from "../../_pages/ContractorManagement/ContractorPerformance/ContractorHindranceDashboardSap";
import ContractorSapData from "../../_pages/SapImport/ContractorSapData";
import EnvImportSap from "../../_pages/EnvironmentManagement/UploadedFiles/EnvImportSap";
import FuelSensorList from "../../_pages/FuelManagementSystem/FuelManagementConfiguration/FuelSensor/FuelSensorList";
import FuelSensorGraph from "../../_pages/FuelManagementSystem/Dashboard/FuelSensorGraph";
import SettingList from "../../_pages/UserManagement/SettingsList";
import SettingAdd from "../../_pages/UserManagement/SettingsAdd";
import EditMineDetailsByIncident from "../../_pages/SafetyManagementSystem/IncidentManagement/EditMineDetailsByIncident";

const RoutePermission = [
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/coal-grade/:type/approval-action",
    element: <ApproveLabResultList />,
    routeKey: "coalGradeApprove",
    permission: ["area_quality_manager"],
  },
  {
    path: "/coal-grade/sample-prepare/create",
    element: <SamplePrepareRegisterAdd />,
    permission: ["chemist"],
  },

  {
    path: "/coal-grade/sample-prepare/:id",
    element: <SamplePrepareRegisterAdd />,
    permission: ["chemist"],
  },
  {
    path: "/coal-grade/third-party-lab-result/create",
    element: <ThirdPartyLabResult />,
    permission: ["area_quality_manager"],
  },
  {
    path: "/coal-grade/third-party-lab-result/:id",
    element: <ThirdPartyLabResult />,
    permission: ["area_quality_manager"],
  },
  {
    path: "/coal-grade/referee-lab-result/create",
    element: <RefereeLabResult />,
    permission: ["area_quality_manager"],
  },
  {
    path: "/coal-grade/referee-lab-result/:id",
    element: <RefereeLabResult />,
    permission: ["area_quality_manager"],
  },
  {
    path: "/coal-grade/sample-decoding/create",
    element: <SampleDecodingRegister />,
    permission: ["lab_quality_manager"],
  },
  {
    path: "/coal-grade/sample-decoding/:id",
    element: <SampleDecodingRegister />,
    permission: ["lab_quality_manager"],
  },
  {
    path: "/coal-grade/sample-coding/create",
    element: <SampleCodingRegister />,
    permission: ["lab_quality_manager"],
  },
  {
    path: "/coal-grade/sample-coding/:id",
    element: <SampleCodingRegister />,
    permission: ["lab_quality_manager"],
  },
  {
    path: "/coal-grade/dashboard/:id",
    element: <CoalGradeDashboard />,
    permission: ["coal_grade_dashboard"],
  },
  {
    path: "/coal-grade/user-management",
    element: <CoalGradeUserList />,
    permission: ["coal_grade_admin"],
  },
  {
    path: "/coal-grade/user-management/create",
    element: <UserAddEdit />,
  },
  {
    path: "/coal-grade/configuration",
    element: <CoalGradeConfiguration />,
  },

  // Here we have to these routes for the reference they are not used
  // *******************************************************************************

  // {
  //   path: "/coal-grade/configuration/activity",
  //   element: <CoalGradeConfigurationActivity />,
  // },
  // {
  //   path: "/coal-grade/configuration/activity/create",
  //   element: <CoalGradeConfigurationActivityAddEdit />,
  // },
  // {
  //   path: "/coal-grade/configuration/activity/:id",
  //   element: <CoalGradeConfigurationActivityAddEdit />,
  // },
  // {
  //   path: "/coal-grade/configuration/area-of-operation",
  //   element: <CoalGradeConfigurationAreaOfOperation />,
  // },
  // {
  //   path: "/coal-grade/configuration/area-of-operation/create",
  //   element: <CoalGradeConfigurationAreaAddEdit />,
  // },
  // {
  //   path: "/coal-grade/configuration/area-of-operation/:id",
  //   element: <CoalGradeConfigurationAreaAddEdit />,
  // },

  // *************************************************************************************
  {
    path: "/coal-grade/configuration/sampling-agency",
    element: <SamplingAgency />,
    permission: ["coal_grade_admin", "super_admin"],
  },
  {
    path: "/coal-grade/configuration/sampling-agency/create",
    element: <SamplingAgencyAddEdit />,
    permission: ["coal_grade_admin", "super_admin"],
  },
  {
    path: "/coal-grade/configuration/sampling-agency/:id",
    element: <SamplingAgencyAddEdit />,
    permission: ["coal_grade_admin", "super_admin"],
  },
  {
    path: "/coal-grade/list",
    element: <CoalGradeTestList />,
    permission: [
      "chemist",
      "lab_quality_manager",
      "area_quality_manager",
      "coal_grade_admin",
    ],
  },
  {
    path: "/coal-grade/:type/actions/list",
    element: <CoalGradeActionList />,
    routeKey: "coalGrade",
  },

  {
    path: "/coal-grade/:type",
    element: <CoalQualityList />,
    permission: [
      "chemist",
      "lab_quality_manager",
      "area_quality_manager",
      "coal_grade_admin",
    ],
    routeKey: "coalGrade",
  },
  {
    path: "/contractor-management/contractor-performances/create",
    element: <ContractorPerformanceAdd />,
    permission: ["section_incharge", "contractor_performance"],
  },
  {
    path: "/contractor-management/configuration/reason-list/create",
    element: <MasterListAdd />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/other-reason-list/create",
    element: <MasterListAdd />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/section",
    element: <PollutionControlMachineList />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/section/create",
    element: <PollutionControlAddEdit />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/section/:id",
    element: <PollutionControlAddEdit />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration",
    element: <ConfigurationManagement />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/contractor-list/create",
    element: <ContractorAdd />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/nit-list/create",
    element: <NitAdd />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/contractor-list/edit/:id",
    element: <ContractorAdd />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/nit-list/edit/:id",
    element: <NitAdd />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/po-list/edit/:id",
    element: <PoEdit />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/reason-list/edit/:id",
    element: <MasterListAdd />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/other-reason-list/edit/:id",
    element: <MasterListAdd />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/contractor-performances/edit/:id",
    element: <ContractorPerformanceAdd />,
    permission: ["section_incharge", "contractor_performance"],
  },
  {
    path: "/contractor-management/contractor-performances",
    element: <ContractorPerformanceList />,
    permission: ["contractor_module_admin"],
  },
  {
    path: "/contractor-management/configuration/reason-list",
    element: <ReasonList />,
    permission: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/contractor-management/configuration/other-reason-list",
    element: <ReasonList />,
    permission: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/contractor-management/configuration/contractor-list",
    element: <ContractorList />,
    permission: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/contractor-management/configuration/nit-list",
    element: <NitList />,
    permission: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/contractor-management/configuration/po-list",
    element: <PoList />,
    permission: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/create",
    element: <FuelManagementAdd />,
    permission: ["dispenser", "fuel_admin"],
  },
  {
    path: "/fuel-management-system/:id",
    element: <FuelManagementAdd />,
    permission: ["dispenser", "fuel_admin"],
  },
  {
    path: "/fuel-management-system/fuel-management-list",
    element: <FuelManagementList />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/:id",
    element: <FuelManagementList />,
    permission: ["dispenser", "fuel_admin"],
  },
  // {
  //   path: "/fuel-management-system/fuel-management-list-bowser",
  //   element: <FuelManagementList type={"bowser"} />,
  //   permission: ["fuel_admin", "dispenser"],
  // },
  // {
  //   path: "/fuel-management-system/fuel-management-list-bowser/:id",
  //   element: <FuelManagementList type={"bowser"} />,
  //   permission: ["dispenser", "fuel_admin"],
  // },
  {
    path: "/fuel-management-system/fuel-stock-list",
    element: <FuelManagementStock />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/user-list",
    element: <UserList />,
    permission: ["fuel_admin"],
  },
  // {
  //   path: "/incident-management",
  //   element: <SafetyManagementList />,
  //   permission: ["safety_admin"],
  // },
  {
    path: "/incident-management",
    element: <IncidentManagement />,
  },
  {
    path: "/environment-management/dashboard/:type",
    element: <EnvironmentManagementDashboard />,
    permission: ["environment_management_dashboard"],
    routeKey: "envDas",
  },
  {
    path: "/environment-management/dashboard",
    element: <EnvironmentManagement />,
    permission: ["environment_management_dashboard"],
  },

  {
    path: "/environment-management/help-manual",
    element: <HelpManualEnv />,
    permission: [
      "env_admin",
      "environment_management_dashboard",
      "env_user",
      "super_admin",
    ],
  },
  {
    path: "/environment-management/manage/air-quality-data",
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
  },
  // {
  //   path: "/contractor-management/contractor-performance/add",
  //   element: <ContractorPerformanceAdd />,
  //   permission: ["section_incharge", "contractor"],
  // },
  {
    element: <MyContractorPerformanceList />,
    path: "/contractor-management/contractor-performance/my-contractor-performance",
    permission: ["section_incharge", "contractor_performance"],
  },
  // {
  //   permission: ["section_incharge", "contractor"],
  //   path: "/contractor-management/contractor-performance/view-contractor-performance",
  //   element: <ContractorPerformanceList />,
  // },
  {
    path: "/environment-management/manage/air-quality-data-emr",
    permission: ["env_admin", "env_user"],
    element: <EnvironmentReportManagement />,
  },
  {
    path: "/environment-management/manage/ground-water-level",
    permission: ["env_admin", "env_user"],
    element: <EnvironmentReportManagement />,
  },
  {
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
    path: "/environment-management/manage/noise-quality-data-emr",
  },
  {
    path: "/environment-management/manage/overall-weather-data",
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
  },
  {
    path: "/environment-management/manage/plantation-data",
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
  },
  {
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
    path: "/environment-management/manage/water-quality-data-emr",
  },

  {
    path: "/environment-management/manage/Ambient-Air-Quality-CAAQMS",
    permission: ["env_admin", "env_user"],
    element: <EnvironmentReportManagement />,
  },
  {
    path: "/environment-management/manage/Ambient-Air-Quality-CMPDIL",
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
  },
  {
    path: "/environment-management/manage/Ground-Water-level-CMPDIL",
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
  },
  {
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
    path: "/environment-management/manage/Land-Reclamation-Status-CMPD",
  },
  {
    path: "/environment-management/manage/Micro-Meteorological-CAAQMS",
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
  },
  {
    element: <EnvironmentReportManagement />,
    path: "/environment-management/manage/Noise-Level-CMPDIL",
    permission: ["env_admin", "env_user"],
  },
  {
    permission: ["env_admin", "env_user"],
    path: "/environment-management/manage/Plantation-Project",
    element: <EnvironmentReportManagement />,
  },
  {
    path: "/environment-management/manage/Water-Quality-CMPDIL",
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
  },
  {
    path: "/environment-management/manage/Performance-Report-Of-Pollution",
    element: <EnvironmentReportManagement />,
    permission: ["env_admin", "env_user"],
  },
  {
    permission: ["env_admin", "env_user"],
    path: "/environment-management/manage/Power-Consumption-Report",
    element: <EnvironmentReportManagement />,
  },

  {
    permission: ["env_admin", "env_user"],
    path: "/environment-management/manage/all-data",
    element: <AirQualityUploadedData />,
  },
  {
    path: "/environment-management/manage/all-data/edit/:id",
    element: <AirQualityUploadedDataEdit />,
    permission: ["env_admin", "env_user"],
  },
  {
    path: "/environment-management/manage",
    element: <ManageEnvironmentReport />,
    permission: ["env_admin", "env_user"],
  },
  {
    path: "/environment-management/manage/sap-coal-quality-import-manuals",
    element: <EnvImportSap />,
    permission: ["env_admin", "env_user"],
  },
  // {
  //   path: "/environment-management/configuration",
  //   element: <EnvNewConfiguration />,
  //   permission: ["env_admin", "super_admin"],
  // },
  {
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration/pollution_control_machine",
    element: <PollutionControlMachineList />,
  },
  {
    path: "/environment-management/configuration/station/CAAQMS",
    element: <EnvConfigStation />,
    permission: ["env_admin", "super_admin"],
  },
  {
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration/station/CAAQMS/create",
    element: <EnvConfigStationAddEdit />,
  },
  {
    path: "/environment-management/configuration/station/CAAQMS/:id",
    element: <EnvConfigStationAddEdit />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/environment-management/configuration/station/CMPDIL",
    element: <EnvConfigStation />,
    permission: ["env_admin", "super_admin"],
  },
  {
    element: <EnvConfigStationAddEdit />,
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration/station/CMPDIL/create",
  },
  {
    path: "/environment-management/configuration/station/CMPDIL/:id",
    element: <EnvConfigStationAddEdit />,
    permission: ["env_admin", "super_admin"],
  },

  {
    path: "/environment-management/configuration/station/water_quality",
    permission: ["env_admin", "super_admin"],
    element: <EnvConfigStation />,
  },
  {
    path: "/environment-management/configuration/station/water_quality/create",
    element: <EnvConfigStationAddEdit />,
    permission: ["env_admin", "super_admin"],
  },
  {
    element: <EnvConfigStationAddEdit />,
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration/station/water_quality/:id",
  },

  {
    path: "/environment-management/configuration/station/noise_level",
    element: <EnvConfigStation />,
    permission: ["env_admin", "super_admin"],
  },
  {
    element: <EnvConfigStationAddEdit />,
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration/station/noise_level/create",
  },
  {
    path: "/environment-management/configuration/station/noise_level/:id",
    element: <EnvConfigStationAddEdit />,
    permission: ["env_admin", "super_admin"],
  },

  //env config permission
  {
    element: <EnvConfigPermission />,
    path: "/environment-management/configuration/permissible/CAAQMS_permissible",
    permission: ["env_admin", "super_admin"],
  },
  {
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration/permissible/CAAQMS_permissible/create",
    element: <EnvConfigPermissionAddEdit />,
  },
  {
    path: "/environment-management/configuration/permissible/CAAQMS_permissible/:id",
    element: <EnvConfigPermissionAddEdit />,
    permission: ["env_admin", "super_admin"],
  },

  {
    path: "/environment-management/configuration/permissible/CMPDIL_permissible",
    permission: ["env_admin", "super_admin"],
    element: <EnvConfigPermission />,
  },
  {
    path: "/environment-management/configuration/permissible/CMPDIL_permissible/create",
    element: <EnvConfigPermissionAddEdit />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/environment-management/configuration/permissible/CMPDIL_permissible/:id",
    element: <EnvConfigPermissionAddEdit />,
    permission: ["env_admin", "super_admin"],
  },

  {
    element: <EnvConfigPermission />,
    path: "/environment-management/configuration/permissible/noise_permissible",
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/environment-management/configuration/permissible/noise_permissible/create",
    permission: ["env_admin", "super_admin"],
    element: <EnvConfigPermissionAddEdit />,
  },
  {
    path: "/environment-management/configuration/permissible/noise_permissible/:id",
    permission: ["env_admin", "super_admin"],
    element: <EnvConfigPermissionAddEdit />,
  },

  {
    element: <EnvConfigStation />,
    path: "/environment-management/configuration/station/ground_water_CMPDIL",
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/environment-management/configuration/station/ground_water_CMPDIL/create",
    element: <EnvConfigStationAddEdit />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/environment-management/configuration/station/ground_water_CMPDIL/:id",
    element: <EnvConfigStationAddEdit />,
    permission: ["env_admin", "super_admin"],
  },
  //
  {
    path: "/environment-management/configuration/equipment",
    element: <PollutionControlMachineList />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/environment-management/configuration/equipment_type",
    element: <PollutionControlMachineList />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/environment-management/configuration/land_reclamation",
    element: <PollutionControlMachineList />,
    permission: ["env_admin", "super_admin"],
  },
  {
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration/power_consumption",
    element: <PollutionControlMachineList />,
  },

  {
    path: "/environment-management/configuration/pollution_control_machine/create",
    element: <PollutionControlAddEdit />,
    permission: ["super_admin", "env_admin"],
  },
  {
    element: <PollutionControlAddEdit />,
    permission: ["super_admin", "env_admin"],
    path: "/environment-management/configuration/equipment/create",
  },

  {
    path: "/environment-management/configuration/equipment_type/create",
    element: <PollutionControlAddEdit />,
    permission: ["super_admin", "env_admin"],
  },
  {
    element: <PollutionControlAddEdit />,
    permission: ["super_admin", "env_admin"],
    path: "/environment-management/configuration/land_reclamation/create",
  },
  {
    path: "/environment-management/configuration/power_consumption/create",
    permission: ["super_admin", "env_admin"],
    element: <PollutionControlAddEdit />,
  },
  {
    element: <PollutionControlAddEdit />,
    path: "/environment-management/configuration/pollution_control_machine/:id",
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/environment-management/configuration/equipment/:id",
    permission: ["super_admin", "env_admin"],
    element: <PollutionControlAddEdit />,
  },
  {
    path: "/environment-management/configuration/equipment_type/:id",
    element: <PollutionControlAddEdit />,
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/environment-management/configuration/land_reclamation/:id",
    element: <PollutionControlAddEdit />,
    permission: ["super_admin", "env_admin"],
  },
  {
    element: <PollutionControlAddEdit />,
    permission: ["super_admin", "env_admin"],
    path: "/environment-management/configuration/power_consumption/:id",
  },
  {
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration",
    element: <EnvNewConfiguration />,
  },
  {
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration/device",
    element: <DeviceList />,
  },
  {
    permission: ["env_admin", "super_admin"],
    path: "/environment-management/configuration/device/edit/:id",
    element: <DeviceAdd />,
  },
  {
    path: "/incident-management/edit/:id",
    element: <SafetyManagementEdit />,
  },
  {
    path: "/incident-management/form-c/edit/:id",
    element: <SafetyManagementFormC />,
  },
  {
    path: "/mobile/incident-management/form-c/edit/:id",
    element: <SafetyManagementFormC />,
  },
  // {
  //   path: "/coal-grade",
  //   element: <CoalGradeSelectionCard />,
  //   permission: [
  //     "chemist",
  //     "lab_quality_manager",
  //     "area_quality_manager",
  //     "coal_grade_admin",
  //   ],
  // },

  {
    path: "/contractor-management/contractor-dashboard",
    element: <ContractorManagementDashboard />,
    permission: ["contractor_dashboard"],
  },
  {
    path: "/contractor-management/contractor-dashboard/performance",
    element: <ContractorPerformanceDashboard />,
    permission: ["contractor_performance_dashboard"],
  },
  {
    path: "/contractor-management/contractor-dashboard/performance-score-card-vendor",
    element: <ContractorPerformanceScoreCardVendorDashboard />,
    permission: ["contractor_performance_score_card_dashboard"],
  },
  {
    path: "/contractor-management/contractor-dashboard/performance-score-card-month",
    element: <ContractorPerformanceScoreCardMonthDashboard />,
    permission: ["contractor_performance_score_card_dashboard"],
  },
  // {
  //   path: "/contractor-management/contractor-dashboard/hindrance",
  //   element: <ContractorHindranceCardDashboard />, //old
  //   permission: ["contractor_hindrance_dashboard"],
  // },
  {
    path: "/contractor-management/contractor-dashboard/hindrance",
    element: <ContractorHindranceDashboardSap />,
    permission: ["contractor_hindrance_dashboard"],
  },
  {
    path: "/contractor-management/contractor-dashboard/production-performance",
    element: <ContractorPerformanceSapDashboard />,
    permission: ["performance_production_dashboard"],
  },
  // {
  //   path: "/contractor-management/contractor-hindrance",
  //   element: <HindranceList />,
  //   permission: [
  //     "contractor",
  //     "representative",
  //     "section_incharge",
  //     "colliery_manager",
  //     "contractor_module_admin",
  //   ],
  // },
  {
    path: "/contractor-management/contractor-hindrance",
    element: <HindranceList />,
    permission: [
      // "representative",
      // "section_incharge",
      // "colliery_manager",
      "contractor_module_admin",
      // "contractor",
    ],
  },
  {
    path: "/contractor-management/my-approval-action-contractor-hindrance",
    element: <MyApprovalHindrance />,
    permission: [
      // "contractor",
      "representative",
      "section_incharge",
      "colliery_manager",
      // "contractor_module_admin",
    ],
  },
  {
    path: "/contractor-management/my-approval-history-contractor-hindrance",
    element: <MyApprovalHindrance />,
    permission: [
      // "contractor",
      "representative",
      "section_incharge",
      "colliery_manager",
      // "contractor_module_admin",
    ],
  },
  {
    path: "/contractor-management/my-contractor-hindrance",
    element: <MyHindranceList />,
    permission: [
      // "contractor",
      "contractor_hindrance",
      // "representative",
      // "section_incharge",
      // "colliery_manager",
      // "contractor_module_admin",
    ],
  },
  {
    path: "/contractor-management/contractor-hindrance/module",
    element: <ContractorHindranceManagement />,
    permission: [
      "representative",
      "section_incharge",
      "colliery_manager",
      "contractor_module_admin",
      // "contractor",
      "contractor_hindrance",
    ],
  },
  {
    path: "/contractor-management/contractor-hindrance/create",
    element: <HindranceAdd />,
    permission: [
      // "representative",
      // "section_incharge",
      // "colliery_manager",
      // "contractor_module_admin",
      // "contractor",
      "contractor_hindrance",
    ],
  },
  {
    path: "/contractor-management/contractor-hindrance/update/:id",
    element: <HindranceAdd />,
    permission: [
      // "representative",
      // "section_incharge",
      // "colliery_manager",
      // "contractor_module_admin",
      // "contractor",
      "contractor_hindrance",
    ],
  },
  {
    path: "/contractor-management/contractor-hindrance/approve/representative/:id",
    element: <HindranceApprove />,
    permission: [
      "representative",
      "section_incharge",
      "colliery_manager",
      "contractor_module_admin",
      // "contractor",
      "contractor_hindrance",
    ],
  },
  {
    path: "/environment-management/manage/uploaded-files",
    element: <UploadedFileList />,
    permission: ["env_admin", "env_user"],
  },
  {
    path: "/environment-management/manage/upload",
    element: <UploadFileAdd />,
    permission: ["env_admin", "env_user"],
  },
  // {
  //   path: "/environment-management/uploaded-files/create",
  //   element: <UploadFileAdd />,
  //   permission: ["env_admin", "env_user"],
  // },
  {
    path: "/view-profile",
    element: <ViewProfile />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/user-management/user-list",
    element: <UserManagementList />,
    permission: 1,
  },
  {
    path: "/user-management/settings",
    element: <SettingList />,
    permission: 1,
  },
  {
    path: "/user-management/settings/create",
    element: <SettingAdd />,
    permission: 1,
  },
  {
    path: "/user-management/settings/:id",
    element: <SettingAdd />,
    permission: 1,
  },
  {
    path: "/user-management/user-list/create",
    element: <UserAddEdit />,
    permission: 1,
  },
  {
    path: "/user-management/user-list/:id",
    element: <UserAddEdit />,
    permission: 1,
  },
  {
    path: "/super-admin/help-manual",
    element: <HelpManualView />,
    permission: ["super_admin"],
  },
  {
    path: "/super-admin/help-manual/:id",
    element: <HelpManualEdit />,
    permission: ["super_admin"],
  },
  {
    path: "/super-admin/help-manual/create",
    element: <HelpManualEdit />,
    permission: ["super_admin"],
  },
  {
    path: "/user-management/help-manual/edit",
    element: <HelpManualEdit />,
    permission: ["super_admin"],
  },
  {
    path: "/contractor-management/configuration/contractor-users/create",
    element: <ContractorConUserManagementAddEdit />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/contractor-users/:id",
    element: <ContractorConUserManagementAddEdit />,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    path: "/contractor-management/user-list",
    element: <ContractorUserManagementList />,
    permission: ["contractor_module_admin"],
  },
  {
    path: "/contractor-management/configuration/contractor-users",
    element: <ContractorConUserManagement />,
    permission: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/contractor-management/user-list/create",
    element: <UserAddEdit />,
    permission: ["contractor_module_admin", "section_incharge"],
  },
  {
    path: "/contractor-management/user-list/:id",
    element: <UserAddEdit />,
    permission: ["contractor_module_admin"],
  },

  // Dashboard urls
  // {
  //   path: "/dashboardtwo",
  //   element: <HomeDashboard />,
  // },
  {
    path: "/dashboard/contractor-performance-dashboard",
    element: <CPDashboard />,
    permission: ["contractor_performance_dashboard"],
  },
  {
    path: "/dashboard/fuel-management-dashboard",
    element: <FuelMgmtDashboard />,
    // permission: ["fuel_management_dashbaord"],
  },
  {
    path: "/fuel-management-system/dashboard",
    element: <FuelMgmtDashboard />,
    // permission: ["fuel_management_dashbaord"],
  },
  {
    path: "/fuel-management-system/dashboard/:id",
    element: <FuelManagementDashboard />,
    permission: ["fuel_management_dashbaord"],
  },
  {
    path: "/fuel-management-system/fuel-sensor-graph",
    element: <FuelSensorGraph />,
    permission: ["fuel_admin"],
  },
  {
    path: "/fuel-management-system/sap-dashboard/:id",
    element: <FuelManagementSapDashboard />,
    permission: ["fuel_management_dashbaord"],
  },
  {
    path: "/dashboard/safety-management-dashboard",
    element: <SafetyMgmtDashboard />,
    // permission: ["safety_management_dashbaord"],
  },
  {
    path: "/dashboard/coal-grade-dashboard",
    element: <CGDashboard />,
    permission: ["coal_grade_dashboard"],
  },
  {
    path: "/dashboard/environment-management-dashboard",
    element: <EnvironmentDashboard />,
    permission: ["environment_management_dashboard"],
  },
  {
    path: "/dashboard/HEMM-Cockpit-dashboard",
    element: <HemmCockpitDashboard />,
    permission: ["hemm_cockpit_dashboard"],
  },
  {
    path: "/configuration/environment/air-quality-emr/create",
    element: <MasterAirQualityEmr />,
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/configuration/environment/air-quality-emr/:id",
    element: <MasterAirQualityEmr />,
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/configuration/environment/noise/create",
    element: <MasterNoise />,
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/configuration/environment/noise/:id",
    element: <MasterNoise />,
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/configuration/environment/sation-type-water/create",
    element: <MasterStationTypeWater />,
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/configuration/environment/sation-type-water/:id",
    element: <MasterStationTypeWater />,
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/configuration/environment/station-category-water/create",
    element: <MasterStationCategoryWater />,
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/configuration/environment/station-category-water/:id",
    element: <MasterStationCategoryWater />,
    permission: ["super_admin", "env_admin"],
  },
  {
    path: "/configuration/environment/station-category-water",
    element: <MasterStationCategoryWaterList />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/configuration/environment/sation-type-water",
    element: <MasterStationTypeWaterList />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/configuration/environment/noise",
    element: <MasterNoiseList />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/configuration/environment/air-quality-emr",
    element: <MasterAirQualityEmrList />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/configuration/environment/ground-water/well/create",
    element: <MasterGroundWaterWell />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/configuration/environment/ground-water/well/:id",
    element: <MasterGroundWaterWell />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/configuration/environment/ground-water/well",
    element: <MasterGroundWaterWellList />,
    permission: ["env_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/incident-management",
    element: <IncidentManagement />,
    permission: ["safety_admin", "safety_user", "super_admin"],
  },
  {
    path: "/contractor-management/contractorscore-management",
    element: <ContractorScoreManagement />,
    permission: ["contractor_module_admin", "section_incharge"],
  },
  {
    path: "/safety-management-system/user-management",
    element: <IncidentUserManagementList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/user-management/create",
    element: <UserAddEdit />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/csis-data",
    element: <CsisDataList />,
    permission: ["safety_admin", "safety_user", "super_admin"],
  },
  {
    path: "/fuel-management-system/all-user-list",
    element: <FuelUserList />,
    permission: ["fuel_admin"],
  },
  {
    path: "/fuel-management-system/all-user-list/create",
    element: <UserAddEdit />,
    permission: ["fuel_admin"],
  },
  {
    path: "/environment-management/user-management",
    element: <EnvUserManagementList />,
    permission: ["env_admin"],
  },
  {
    path: "/environment-management/user-management/create",
    element: <UserAddEdit />,
    permission: ["env_admin"],
  },
  {
    path: "/safety-management-system/incident-management/my-approval-action",
    element: <SafetyManagementList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/incident-management/incident-reported",
    element: <ReportedIncidentList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/incident-management/capa/edit/:id",
    element: <CapaEdit />,
    permission: ["safety_user", "safety_admin"],
  },
  {
    path: "/safety-management-system/incident-management/incident-investigation",
    element: <InvestigationIncidentList />,
    permission: ["safety_user", "safety_admin"],
  },
  {
    path: "/safety-management-system/dashboard",
    element: <SafetyManagementDashboard />,
    permission: ["safety_management_dashbaord"],
  },
  {
    path: "/safety-management-system/dashboard/:id",
    element: <IncidentManagementDashboard />,
    permission: ["safety_management_dashbaord"],
  },
  {
    path: "/safety-management-system/incident-management/incident-capa",
    element: <CapaAssignedIncidentList />,
    permission: ["safety_user", "safety_admin"],
  },
  {
    element: <ViewIncidentList />,
    path: "/safety-management-system/incident-management/view-incidents",
    permission: ["safety_admin", "super_admin"],
  },
  {
    element: <EditMineDetailsByIncident />,
    path: "/safety-management-system/incident-management/mine-details-by-incident/:id",
    permission: ["super_admin"],
  },
  {
    element: <MyIncidentList />,
    path: "/safety-management-system/incident-management/my-incidents",
    permission: ["safety_user", "safety_admin"],
  },
  // {
  //   path: "/safety-management-system/incident-management/incident-approval-history",
  //   element: <MyIncidentApprovalHistoryList />,
  //   permission: [
  //     "safety_admin",
  //     "safety_user",
  //     "safety_management_dashbaord",
  //     "super_admin",
  //   ],
  // },
  {
    path: "/safety-management-system/incident-management/incident-reported-approval_history",
    element: <MyApprovalReportedIncidentHistoryList />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/incident-management/incident-investigator-approval_history",
    element: <MyApprovalInvestigatorIncidentHistoryList />,
    permission: ["safety_user", "safety_admin"],
  },
  {
    path: "/safety-management-system/incident-management/incident-capa-owner-approval_history",
    element: <MyApprovalCapOwnerIncidentHistoryList />,
    permission: ["safety_user", "safety_admin"],
  },
  {
    path: "/safety-management-system/configuration",
    element: <Configuration />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/activity",
    element: <ActivityList />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/violations-leading",
    element: <ViolationsList />,
    permission: ["safety_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/configuration/designation",
    element: <ReasonList />,
    permission: ["safety_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/configuration/designation/create",
    element: <MasterListAdd />,
    permission: ["safety_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/configuration/designation/edit/:id",
    element: <MasterListAdd />,
    permission: ["safety_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/configuration/violations-leading/create",
    element: <ViolationsCreate />,
    permission: ["safety_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/configuration/violations-leading/edit/:id",
    element: <ViolationsCreate />,
    permission: ["safety_admin", "super_admin"],
  },

  {
    path: "/safety-management-system/configuration/area-of-operation",
    element: <AreaList />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/area-of-operation/create",
    element: <AreaAddEdit />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/activity/create",
    element: <ActivityAddEdit />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/incident-kpi",
    element: <IncidentKpiList />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/incident-kpi/create",
    element: <IncidentKpiAddEdit />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/incident-kpi/:id",
    element: <IncidentKpiAddEdit />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/activity/:id",
    element: <ActivityAddEdit />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/area-of-operation/:id",
    element: <AreaAddEdit />,
    permission: [
      "safety_admin",
      // "safety_user",
      // "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/configuration/upload-files",
    element: <SafetyDocumentList />,
    permission: ["safety_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/configuration/upload-files/create",
    element: <SafetyDocumentUpload />,
    permission: ["safety_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/configuration/upload-files/:id",
    element: <SafetyDocumentUpload />,
    permission: ["safety_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/incident-management/report/add",
    element: <IncidentReport />,
    permission: ["safety_user", "safety_admin"],
  },
  {
    path: "/safety-management-system/incident-management/report/edit/:id",
    element: <IncidentReport />,
    permission: ["safety_user", "safety_admin"],
  },
  {
    path: "/fuel-management-system",
    element: <FuelManagement />,
    permission: ["fuel_admin", "dispenser"],
  },

  {
    path: "/contractor-management/contractor-performance",
    element: <ContractorPerformanceManagement />,
    permission: [
      "section_incharge",
      "contractor_module_admin",
      "contractor_performance",
    ],
  },
  {
    path: "/contractor-management/contractor-score",
    element: <ContractorScoreList />,
    permission: ["contractor_module_admin"],
  },
  {
    path: "/contractor-management/my-contractor-score",
    element: <MyScoreCardList />,
    permission: ["section_incharge"],
  },
  {
    path: "/contractor-management/contractor-score/create",
    element: <ContractorScoreAdd />,
    permission: ["section_incharge"],
  },
  {
    path: "/contractor-management/contractor-score/edit/:id",
    element: <ContractorScoreAdd />,
    permission: ["section_incharge"],
  },
  {
    path: "/coal-grade/help-manual",
    element: <HelpManualCoalGrade />,
  },
  {
    path: "/safety-management-system/help-manual",
    element: <HelpManualSafetyManagement />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/safety-management-system/documents",
    element: <SafetyDocument />,
    permission: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  {
    path: "/contractor-management/help-manual",
    element: <HelpManual />,
    permission: [
      "environment_management_dashboard",
      "super_admin",
      "representative",
      "section_incharge",
      "colliery_manager",
      "contractor",
      "contractor_module_admin",
    ],
  },
  {
    path: "/fuel-management-system/help-manual",
    element: <FuelManagementHelpManual />,
  },
  {
    path: "/fuel-management-system/configuration",
    element: <FuelManagementConfiguration />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/fuel-location",
    element: <ConfigurationFuelLocationList />,
    permission: ["super_admin", "fuel_admin"],
  },
  {
    path: "/fuel-management-system/configuration/fuel-location/create",
    element: <ConfigurationFuelLocationAdd />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/tank-master/create",
    element: <TankAdd />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/tank-master/:id",
    element: <TankAdd />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/fuel-location/:id",
    element: <ConfigurationFuelLocationAdd />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/hemm-data",
    element: <HemmList />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/tank-master",
    element: <TankList />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/safety-management-system/configuration/mine-details/create",
    element: <MineDetailsAdd />,
    permission: ["super_admin", "safety_admin"],
  },
  {
    path: "/safety-management-system/configuration/mine-details/:id",
    element: <MineDetailsAdd />,
    permission: ["super_admin", "safety_admin"],
  },
  {
    path: "/safety-management-system/configuration/mine-details",
    element: <MineDetailsList />,
    permission: ["super_admin", "safety_admin"],
  },
  {
    path: "/fuel-management-system/configuration/hemm-data/importExcelFile",
    element: <ImportSapConfig />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/hemm-data/importExcelFile/create",
    element: <ImportSapContractorAdd />,
    permission: ["fuel_admin", "super_admin"],
  },

  {
    path: "/fuel-management-system/configuration/hemm-data/importExcelFile/uploaded",
    element: <ImportSapList />,
    permission: ["fuel_admin", "super_admin"],
  },

  {
    path: "/fuel-management-system/configuration/hemm-data/create",
    element: <HemmAdd />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/hemm-data/:id",
    element: <HemmAdd />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/coal-grade/configuration/sap-import",
    element: <ImportSapConfig />,
    role: ["super_admin", "coal_grade_admin"],
  },
  {
    path: "/contractor-management/configuration/sap-import",
    element: <ImportSapConfig />,
    role: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/fuel-management-system/configuration/sap-import",
    element: <ImportSapConfig />,
    role: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/sap-import/create",
    element: <ImportSapContractorAdd />,
    role: ["fuel_admin", "super_admin"],
  },
  {
    path: "/coal-grade/configuration/sap-import/create",
    element: <ImportSapUpload />,
    role: ["super_admin", "coal_grade_admin"],
  },
  {
    path: "/fuel-management-system/configuration/workshop-master",
    element: <ReasonList />,
    role: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/workshop-master/create",
    element: <MasterListAdd />,
    role: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/workshop-master/:id",
    element: <MasterListAdd />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/supplier",
    element: <SupplierList />,
    role: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/supplier/create",
    element: <SupplierAdd />,
    role: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/configuration/supplier/:id",
    element: <SupplierAdd />,
    permission: ["fuel_admin", "super_admin"],
  },
  {
    path: "/contractor-management/configuration/sap-import/create",
    element: <ImportSapContractorAdd />,
    role: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/coal-grade/configuration/sap-import/uploaded",
    element: <ImportSapList />,
    role: ["super_admin", "coal_grade_admin"],
  },
  {
    path: "/contractor-management/configuration/sap-import/uploaded",
    element: <ImportSapList />,
    role: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/contractor-management/configuration/sap-import/uploaded-data",
    element: <ContractorSapData />,
    role: ["super_admin", "contractor_module_admin"],
  },
  {
    path: "/fuel-management-system/configuration/sap-import/uploaded",
    element: <ImportSapList />,
    role: ["fuel_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/sap-report",
    element: <QuickSightDashboard dashboardId={"Fuel_Management_Dashboard"} />,
    role: ["quick_sight_dashboard"],
  },
  {
    path: "/coal-grade/sap-report",
    element: <QuickSightDashboard dashboardId={"Coal_Grade_Dashboard"} />,
    role: ["quick_sight_dashboard"],
  },
  {
    path: "/contractor-management/sap-report",
    element: (
      // <QuickSightDashboard dashboardId={"Contractor_Performance_Dashboard"} />
      <QuickSightDashboard dashboardId={"Contractor_Performance_Actual"} />
    ),
    permission: ["quick_sight_dashboard"],
  },
  {
    path: "/contractor-management/sap-production",
    element: (
      <QuickSightDashboard dashboardId={"Contractor_Performance_Actual"} />
    ),
    permission: ["quick_sight_dashboard"],
  },
  {
    path: "/coal-grade/configuration/api-status",
    element: <SapUpdate />,
    permission: ["coal_grade_admin", "super_admin"],
  },
  {
    path: "/coal-grade/configuration/sample-data",
    element: <SapData />,
    permission: ["coal_grade_admin", "super_admin"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/fuel-receipt",
    element: <ReceiptList />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/my-fuel-receipt",
    element: <MyReceiptList />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/fuel-sensor",
    element: <FuelSensorList />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/reports",
    element: <FuelReports />,
    permission: ["fuel_admin"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/fuel-receipt/create",
    element: <ReceiptAdd />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/fuel-receipt/:id",
    element: <ReceiptAdd />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/stock",
    element: <StockList type={"tank"} />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/bowser-stock",
    element: <StockList type={"bowser"} />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/stock/create",
    element: <StockAdd />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/mobile/fuel-management-system/fuel-management-list/stock/create",
    element: <StockAdd />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/fuel-management-list/stock/:id",
    element: <StockAdd />,
    permission: ["fuel_admin", "dispenser"],
  },
  {
    path: "/fuel-management-system/configuration/tank-master",
    element: <TankList />,
    permission: ["fuel_admin", "dispenser"],
  },
  // {
  //   path: "/fuel-management-system/sap-report",
  //   element: <QuickSightDashboard dashboardId={"Fuel_Management_Dashboard"} />,
  //   role: ["quick_sight_dashboard"],
  // },
  // {
  //   path: "/coal-grade/sap-report",
  //   element: <QuickSightDashboard dashboardId={"Coal_Grade_Dashboard"} />,
  //   role: ["quick_sight_dashboard"],
  // },
  // {
  //   path: "/contractor-management/sap-report",
  //   element: (
  //     <QuickSightDashboard dashboardId={"Contractor_Performance_Dashboard"} />
  //   ),
  //   permission: ["quick_sight_dashboard"],
  // },
  //Mobile Route
  ...MOBILE_ROUTE,
];

export default RoutePermission;

export const COAL_GRADE_TYPE_ROUTE = [
  "/coal-grade/sample-prepare",
  "/coal-grade/sample-coding",
  "/coal-grade/sample-decoding",
  "/coal-grade/third-party-lab-result",
  "/coal-grade/referee-lab-result",
  "/coal-grade/consolidated",
];
export const COAL_GRADE_RESULT_APPROVE = [
  "/coal-grade/sample-decoding/approval-action",
  "/coal-grade/third-party-lab-result/approval-action",
  "/coal-grade/referee-lab-result/approval-action",
];
export const COAL_GRADE_TYPE_ACTION_ROUTE = [
  "/coal-grade/sample-prepare/actions/list",
  "/coal-grade/sample-coding/actions/list",
  "/coal-grade/sample-decoding/actions/list",
  "/coal-grade/third-party-lab-result/actions/list",
  "/coal-grade/referee-lab-result/actions/list",
  "/coal-grade/consolidated/actions/list",
];

export const ENV_DASHBOARD_TYPE_ROUTE = Object.keys(reportTypeListUrl).map(
  (d) => "/environment-management/dashboard/" + reportTypeListUrl[d]
);
