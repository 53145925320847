import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./customTabs.scss";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";

const CustomTabsForList = ({ tabeList, apiChange }) => {
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  let scrl = useRef(null);

  //Slide click
  // const slide = (shift) => {
  //   scrl.current.scrollLeft += shift;
  //   setscrollX(scrollX + shift);

  //   if (
  //     Math.floor(scrl.current?.scrollWidth - scrl.current?.scrollLeft) <=
  //     scrl.current?.offsetWidth
  //   ) {
  //     setscrolEnd(true);
  //   } else {
  //     setscrolEnd(false);
  //   }
  // };

  return (
    <div className="misReportList" data-testid="misReportList">
      <div className="miscroll approvalHistoryTabs">
        {/* {!hideLeftRightBtn && scrollX !== 0 && tabeList?.length >= 3 && (
          <button className="prev">
            <IoChevronBack
              data-testid="leftSlide"
              onClick={() => slide(-100)}
            />
          </button>
        )} */}

        <ul className="" ref={scrl}>
          {Array.isArray(tabeList) &&
            tabeList?.map((d, i) => {
              return (
                <li key={i}>
                  {/* <Link className={d.activ````````e ? "active" : ""} to={d.to}> */}
                  <span
                    className={d.active === 1 ? "active listing" : "listing"}
                    // color: #2c88d1; : ""}
                    onClick={() => apiChange(d.value, d.type)}
                  >
                    {d.label}
                  </span>
                  {/* </Link> */}
                </li>
              );
            })}
        </ul>

        {/* <ul className="" ref={scrl}>
          {Array.isArray(tabeList) &&
            tabeList?.map((d, i) => {
              return (
                <li key={i}>
                  <Link
                    className={d.active ? "active" : ""}
                    style={{ pointerEvents: "none" }}
                  >
                    <span onClick={() => apiChange(d.value, d.type)}>
                      {d.label}
                    </span>
                  </Link>
                </li>
              );
            })}
        </ul> */}

        {/* {!hideLeftRightBtn &&
          (!scrolEnd || scrollX === 0) &&
          tabeList?.length >= 3 && (
            <button className="next">
              <IoChevronForward
                data-testid="rightSlide"
                onClick={() => slide(+100)}
              />
            </button>
          )} */}
      </div>
    </div>
  );
};

export default CustomTabsForList;
