import { Card, Col, Row } from "react-bootstrap";
import "./Dashboard.scss";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { safetyDashboard3 } from "../../../../services/_safetyManagement/safetyManagement";
import { pageThreeChartList } from "./DATA";
import PieChartWithModal from "../../../../components/PieChartWithModal/PieChartWithModal";
import {
  basicBarChartWithCustomTooltipDataFormat,
  pieChartDataWithModalForTotalVs,
} from "../../../../components/Chart/Common/config";
import { BasicBarChartCardWithCustomToolTip } from "../../../../components/ChartCard/ChartCard";
import { incidentTypesObj } from "../DATA";

const IncidentDashboard3 = ({ filterForm, cref, setMinMaxDate }) => {
  const [dashboardData, setDashboardData] = useState({});
  const [chart, setChart] = useState({});

  const dashboardDataFun = useCallback(async (filter) => {
    let response = await safetyDashboard3(filter);
    if (response && response?.status && response?.data) {
      const resData = response.data;
      console.log("resDataresDataresData",resData);
      let holdNull = pageThreeChartList?.forEach((item) => {
        setChart((prev) => ({
          ...prev,
          [item?.value]:
            item?.type === "pieVs"
              ? pieChartDataWithModalForTotalVs(resData[item?.value])
              : item?.type == "barWithCustomTooltip"
              ? basicBarChartWithCustomTooltipDataFormat(  resData[item?.value]?.map((d) =>
              item?.value === "incident_type"
                ? { ...d, name: incidentTypesObj[d?.name] ,fullName: incidentTypesObj[d?.name]  }
                : { ...d }
            ))
              : "No chart",
        }));
      });

      setDashboardData(resData);
      setMinMaxDate(resData?.minMax_date || {});
    }
  }, []);

  useEffect(() => {
    dashboardDataFun(filterForm);
  }, [dashboardDataFun]);

  useImperativeHandle(
    cref,
    () => ({
      dashboardDataFun: () => dashboardDataFun(filterForm),
    }),
    [dashboardDataFun, filterForm]
  );

  return (
    <div className="dashboardWrapper">
      <Row id="screen3">
        {pageThreeChartList?.map((d, idx) => {
          return (
            <Col md={4} key={idx}>
              <Card className="chartCard chartCardBtmSpace">
                <Card.Header>{d?.title}</Card.Header>
                <Card.Body className={`${d?.className || ""} minePieChart`}>
                  {d?.type === "pieVs" && (
                    <PieChartWithModal
                      pieChartColour={false}
                      seriesData={chart[d?.value] || []}
                      customLabelFalse={true}
                      isCustomLegend={true}
                    />
                  )}
                  {d?.type === "barWithCustomTooltip" && (
                    <BasicBarChartCardWithCustomToolTip
                      yAxisName={d?.yAxisName}
                      xAxisName={d?.xAxisName}
                      category={chart[d?.value]?.categoryData}
                      seriesData={chart[d?.value]?.data}
                      customToolTip={chart[d?.value]?.dataList}
                      noRecordText={
                        d?.value === "capa_status"
                          ? "No Outstanding CAPA"
                          : false
                      }
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default IncidentDashboard3;
