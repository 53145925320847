import React from "react";
import { Button, Container } from "react-bootstrap";
import invite from "../../Assets/Images/invite.svg";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import MainBreadcrumb from "../Breadcrumb/MainBreadcrumb";
import { IS_MOBILE } from "../../_helpers/helper";

const EmptyDataList = ({
  pageLink,
  title,
  hideAddBtn,
  tabButtons,
  customTableTitle,
  breadCrumb,
}) => {
  const navigate = useNavigate();
  return (
    <Container fluid>
      {!IS_MOBILE() ? (
        !!breadCrumb ? (
          <MainBreadcrumb
            routeSegments={breadCrumb}
            // module={ACTIVE_MODULE.incident}
          />
        ) : (
          <h3 className="title">{title}</h3>
        )
      ) : (
        ""
      )}

      {/* <h3 className="title">{title}</h3> */}
      <div className="tabSection emptyTabSection">
        {tabButtons && tabButtons()}
      </div>
      <div className="registerForm">
        <img src={invite} className="img-fluid" alt="registerImg" />
        <h3 className="title" data-testid="no-record">No Record is added yet!</h3>
        {!hideAddBtn && (
          <div>
            <Button
              onClick={() => navigate(`${pageLink}/create`)}
              className="primaryBtn"
              style={{ padding: "10px 24px" }}
            >
              <AiOutlinePlus /> Create Data
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default EmptyDataList;
