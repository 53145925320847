import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

export const getAllContractorList = async (filter) => {
  try {
    const response = await RestMethod.GET(
      "/contractor/getAllSitesContractor?" + filter
    );
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.count,
      data: data?.rows,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteContractor = async (id) => {
  let url = "/contractor/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const createContractor = async (data) => {
  let url = "/contractor/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateContractor = async (data, id) => {
  let url = "/contractor/update/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getAllSapList = async (filter) => {
  try {
    const response = await RestMethod.GET("/contractor/sap/data?" + filter);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.data?.count,
      data: data?.data?.rows,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};


export const exportContractorSapExport = async (filter) => {
  try {
    let url = "/contractor/sap-export";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;

    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const exportPO = async (filter) => {
  try {
    let url = "/contractor/po-export";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;

    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};