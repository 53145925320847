import React, { useState, useEffect } from "react";
import { SidebarData, subModuleList } from "./DATA";
import { getPermission } from "../../_services/Auth/helper";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import ManageCardTab from "../../components/Common/ManageCardTab/ManageCardTab";

const CoalGradeTestList = () => {
  const [tabs, setTabs] = useState([]);

  const rolePermission = getPermission();

  useEffect(() => {
    setTabs(
      subModuleList?.filter((obj) =>
        obj?.role?.find((role) => rolePermission?.[role])
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <ManageCardTab
          breadCrumbData={[
            {
              name: "Coal Grade",
              path: "/",
            },
            {
              name: "Coal Quality",
              path: "#",
            },
          ]}
          flowList={tabs}
        />
      </div>
    </>
  );
};

export default CoalGradeTestList;
