import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { tankApi, fuelLocationColumnFields } from "./DATA";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MineDetailsView from "./MineDetailsView";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { SidebarData } from "../../../SafetyManagementSystem/IncidentManagement/DATA";
import { getAllMine } from "../../../../services/_safetyManagement/incidentServices";

const MineDetailsList = () => {
  const params = useParams();
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    let response;
    if (params?.id)
      response = await getAllMine("page=1&limit=2&createdBy=" + params?.id);
    else response = await getAllMine("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const viewAsPopup = (form) => {
    return <MineDetailsView form={form} />;
  };

  const onEdit = (id) => {
    navigate("/safety-management-system/configuration/mine-details/" + id);
  };

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={fuelLocationColumnFields}
                apis={tankApi}
                title="Data"
                // FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={
                  "/safety-management-system/configuration/mine-details"
                }
                primaryKey="id"
                pagination={true}
                hideDeleteButton
                hideEditButton
                hideAddButton
                getAllFilter={getAllFilter}
                breadCrumb={[
                  {
                    path: "/",
                    name: "Safety Management",
                  },
                  {
                    name: "Configuration",
                    path: "/safety-management-system/configuration",
                  },
                  {
                    name: "Mine Details",
                    path: "#",
                  },
                ]}
                customTableTitle={"Mine Details"}
                editFunButton={onEdit}
                viewTitle={"Mine Details"}
                isNoTabOnPage
                modalSize={"xl"}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            // hideAddButton={false}
            pageLink={"/safety-management-system/configuration/mine-details"}
            title={"Mine Details"}
          />
        </div>
      </div>
    </div>
  );
};

export default MineDetailsList;
