import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "../../../components/Select/Select";
import FormInputText from "../../../components/FormInputText/FormInputText";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  createHindrance,
  getHinderanceById,
  updateHindranceStatus,
  getContractorById,
} from "../../../services/_hindrance/hindrance";
import { checkContractStatus } from "../../../_helpers/helper";
import {
  DDMMYYYYFormat,
  checkDatesIsBetween,
} from "../../../_helpers/dateHelper";
import { useNavigate, useParams } from "react-router-dom";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import StatusPopup from "../../../components/MessagePopup/StatusPopup";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { disableFutureDate } from "../../../_helpers/dateHelper";
import toastr from "toastr";
import { getAllMasterResonForDropdown } from "../../../services/_reasonList/reasonList";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData, shiftOpt, checkHrsOfHindrance } from "./DATA";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import {
  getNitById,
  getNitList,
} from "../../../services/_contractorPerformance/contractorPerformance";
import { checkDecimal } from "../../../_helpers/helper";
import { getAllPoList } from "../../../services/_poList/poList";
import { findElementByArray } from "../../../_helpers/commonHelper";

const HindranceAdd = () => {
  let navigate = useNavigate();
  let params = useParams();
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [show, setShow] = useState(false);
  const [messageshow, setMessageShow] = useState(false);
  const [onsave, setOnSave] = useState(false);
  const [hindranceForm, sethindranceForm] = useState([]);
  const [poList, setPoList] = useState([]);
  const [contractorStatus, setContractorStatus] = useState("");
  const [reasonList, setReasonList] = useState([]);
  const [contractorData, setContractorData] = useState();
  const [allNit, setAllNit] = useState([]);
  const [hindranceBasicDetailsForm, setHindranceBasicDetailsForm] = useState({
    input_date: null,
    po: null,
  });
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [showStatusPopUp, setShowStatusPopUp] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const [nitList, setNitList] = useState([]);
  const [hindranceBasicDetailsErrorForm, setHindranceBasicDetailsErrorForm] =
    useState({
      input_date: null,
      nit_number: null,
    });
  const closePopup = () => {
    setShow(false);
    navigate("/contractor-management/my-contractor-hindrance");
  };
  const closeMessagePopup = () => {
    setShowStatusPopUp(false);
  };
  const hideMessagePopup = () => {
    setMessagePopupType(true);

    setShow(false);
  };
  const [formFields, setFormFields] = useState([
    {
      id: null,
      reason: "",
      hrs_of_hindrance: "",
      vendor_comments: "",
      shift: "",
      place: "",
    },
  ]);
  const getHinderance = async () => {
    let response = await getHinderanceById(params.id);

    if (
      response?.data?.current_label !== "draft" &&
      response?.data?.current_label !== ""
    ) {
      navigate("/contractor-management/my-contractor-hindrance");
    }

    setFormFields(
      response?.data?.contractor_hindrance_reasons?.map((d) => {
        return {
          id: d.id,
          reason: d.reason,
          hrs_of_hindrance: d.hrs_of_hindrance,
          vendor_comments: d.vendor_comments,
          shift: +d.shift,
        };
      })
    );
    setHindranceBasicDetailsForm((prev) => {
      return {
        ...prev,
        input_date: response?.data?.input_date,
        nit_id: response?.data?.nit_id,
        po: response?.data?.po_id,
      };
    });
  };

  const getAllNitList = async (contractor_id) => {
    const allNitList = await getNitList(contractor_id);
    if (allNitList.status) {
      setNitList(
        allNitList?.data
          ?.filter((ele) => ele?.status)
          ?.map((d) => {
            return {
              value: d.id,
              label: `${d?.nit}`,
              endDate: d.endDate && DDMMYYYYFormat(d.endDate),
              startDate: d.startDate && DDMMYYYYFormat(d.startDate),
            };
          })
      );
      setAllNit(allNitList?.data);
    }
  };

  const getNit = async () => {
    let nit = await getNitById(hindranceBasicDetailsForm?.nit_id);
    if (nit.status)
      setContractorData({
        ...contractorData,
        poDetails: nit.data.poDetails,
        new_nit_number: nit.data.nit,
        new_tender_id: nit.data.tender_id,
        nit_mine: nit.data.siteData?.name,
        nit_subsidiary: nit.data.siteData?.parentSite?.name,
        nit_section: nit?.data?.sectionData?.name,
      });
  };

  const getHinderanceOnSave = async () => {
    if (!params?.id) return false;
    let response = await getHinderanceById(params.id);

    setFormFields(
      response?.data?.contractor_hindrance_reasons?.map((d) => {
        return {
          id: d.id,
          reason: d.reason,
          hrs_of_hindrance: d.hrs_of_hindrance,
          vendor_comments: d.vendor_comments,
          shift: +d.shift,
          place: d.place,
        };
      })
    );
    setHindranceBasicDetailsForm((prev) => {
      return {
        ...prev,
        input_date: response?.data?.input_date,
        nit_id: response?.data?.nit_id,
        po: response?.data?.po_id,
      };
    });
  };

  const reasonsList = async () => {
    let list = [];
    const response = await getAllMasterResonForDropdown();
    if (response.status) {
      list = response?.data?.data
        ?.filter((ele) => ele?.status)
        ?.map((d) => {
          return { value: d.id, label: d.name };
        });
    }
    setReasonList(list);
  };
  useEffect(() => {
    getHinderanceOnSave();
    reasonsList();
  }, [onsave]);

  const getContractor = async () => {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    if (!auth_user) return false;
    let contractor_data = await getContractorById(auth_user?.contractor_id);
    if (contractor_data?.status) {
      setContractorData(contractor_data?.data);
      getAllNitList(contractor_data?.data?.id);
    }
  };
  const getPo = async () => {
    let res = await getAllPoList();
    let poData = res.data.map((d) => {
      return {
        nit_id: d?.nit_id,
        id: d?.id,
        label: d?.po,
        nit_label: d?.nitNumber?.nit,
      };
    });
    setPoList(poData);
  };
  useEffect(() => {
    params?.id && getHinderance();
    getContractor();
    getPo();
  }, []);

  const addFields = () => {
    let object = {
      reason: "",
      hrs_of_hindrance: "",
      vendor_comments: "",
      shift: "",
    };
    setFormFields([...formFields, object]);
  };
  const removeFields = () => {
    const updatedFormFields = [...formFields];
    updatedFormFields.pop();
    setFormFields(updatedFormFields);
  };
  const handleFormChange = (event, index) => {
    let data = [...formFields];
    if (event.target.name === "hrs_of_hindrance") {
      if (checkDecimal(event.target.value)) {
        const minNum = event.target.value?.toString()?.split(".");
        event.target.value = +minNum[1] <= 59 ? event.target.value : +minNum[0];
      }
      if (event.target.value > 8 && data[index]["shift"] != 4) {
        event.target.value = 8;
      }
      if (event.target.value > 24 && data[index]["shift"] == 4) {
        event.target.value = 24;
      }
      if (event.target.value < 0) {
        event.target.value = 0;
      }
    }
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };
  const onChange = (event, index) => {
    setHindranceBasicDetailsErrorForm((prev) => {
      return {
        ...prev,
        [event.target.name]: "",
      };
    });
    setHindranceBasicDetailsForm((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  useEffect(() => {
    if (hindranceBasicDetailsForm?.nit_id) {
      getNit();
    }
    if (!hindranceBasicDetailsForm?.nit_id) return;
    setContractorStatus(
      checkContractStatus(nitList, hindranceBasicDetailsForm?.nit_id)
    );
  }, [hindranceBasicDetailsForm?.nit_id]);

  const onChangeNit = (e, idx) => {
    const { name, value } = e.target;
    setHindranceBasicDetailsErrorForm((prev) => {
      return {
        ...prev,
        [e.target.name]: "",
      };
    });
    setHindranceBasicDetailsForm({
      ...hindranceBasicDetailsForm,
      [name]: value,
    });
  };

  const validateHindrance = () => {
    const hindranceErrorMain = [];
    if (formFields.length) {
      formFields.forEach((obj, i) => {
        const hindranceError = {};
        if (obj.reason === "") {
          hindranceError["reason"] = "Required.";
        }
        if (obj.hrs_of_hindrance === "") {
          hindranceError["hrs_of_hindrance"] = "Required.";
        } else if (obj.hrs_of_hindrance < 0) {
          hindranceError["hrs_of_hindrance"] = "Can't be negative.";
        }
        if (obj.vendor_comments === "") {
          hindranceError["vendor_comments"] = "Required.";
        }
        if (obj.shift === "") {
          hindranceError["shift"] = "Required.";
        }
        if (obj.vendor_comments.length > 300) {
          hindranceError["vendor_comments"] = "Max 300 characters are allowed.";
        }
        hindranceErrorMain.push(hindranceError);
      });
    }
    return hindranceErrorMain;
  };
  const saveValidateHindrance = () => {
    const hindranceErrorMain = [];
    if (formFields.length) {
      formFields.forEach((obj, i) => {
        const hindranceError = {};
        if (obj.reason === "") {
          hindranceError["reason"] = "Required.";
        }
        if (
          obj.vendor_comments != "" &&
          obj.vendor_comments.toString().length > 150
        ) {
          hindranceError["vendor_comments"] = "Max 150 characters are allowed.";
        }
        hindranceErrorMain.push(hindranceError);
      });
    }
    return hindranceErrorMain;
  };
  const validateHindranceBasicDetails = () => {
    const hindranceError = {};
    if (
      hindranceBasicDetailsForm.input_date === "" ||
      !hindranceBasicDetailsForm.input_date
    ) {
      hindranceError["input_date"] = "Date is required.";
    }

    if (hindranceBasicDetailsForm.po === "" || !hindranceBasicDetailsForm.po) {
      hindranceError["po"] = "PO is required.";
    } else hindranceError["po"] = "";

    if (
      hindranceBasicDetailsForm.nit_id === "" ||
      !hindranceBasicDetailsForm.nit_id
    ) {
      hindranceError["nit_id"] = "NIT is required.";
    } else hindranceError["nit_id"] = "";

    return hindranceError;
  };

  useEffect(() => {
    if (
      hindranceBasicDetailsForm.nit_id === null ||
      !hindranceBasicDetailsForm.nit_id
    )
      setHindranceBasicDetailsForm((prev) => ({
        ...prev,
        po: null,
      }));
  }, [hindranceBasicDetailsForm.nit_id]);
  const onSubmit = async () => {
    let validate = validateHindrance();

    let validateBasicDetails = validateHindranceBasicDetails();

    sethindranceForm(validate);

    setHindranceBasicDetailsErrorForm(validateBasicDetails);

    let toSubmit = true;

    Object.keys(validateBasicDetails).forEach((f) => {
      if (validateBasicDetails[f] != "") {
        toSubmit = false;
      }
    });

    validate.forEach((d) => {
      return Object.keys(d).forEach((f) => {
        if (d[f] != "") toSubmit = false;
      });
    });

    if (toSubmit) {
      let data = {
        current_label: "representative",
        reasons: formFields,
        nit_id: hindranceBasicDetailsForm.nit_id,
        input_date: hindranceBasicDetailsForm.input_date,
        siteId: hindranceBasicDetailsForm?.siteId,
        po_id: hindranceBasicDetailsForm?.po,
      };
      let checkHrsValidation = checkHrsOfHindrance(
        formFields,
        "hrs_of_hindrance"
      );
      if (checkHrsValidation !== "validated") {
        toastr.error(checkHrsValidation);
        return;
      }
      let response = params?.id
        ? await updateHindranceStatus(params.id, data)
        : await createHindrance(data);

      if (response?.status == 1 && response?.message == "success") {
        setShow(true);
        setMessagePopupType(false);
      } else {
        toastr.error(response?.message);
      }
    }
  };

  const onSave = async () => {
    let validate = saveValidateHindrance();
    sethindranceForm(validate);
    let validateBasicDetails = validateHindranceBasicDetails();
    setHindranceBasicDetailsErrorForm(validateBasicDetails);
    let toSubmit = true;
    Object.keys(validateBasicDetails).forEach((f) => {
      if (validateBasicDetails[f] != "") {
        toSubmit = false;
      }
    });
    validate.forEach((d) => {
      return Object.keys(d).forEach((f) => {
        if (d[f] != "") {
          toSubmit = false;
        }
      });
    });

    if (toSubmit) {
      let data = {
        input_date: hindranceBasicDetailsForm.input_date,
        nit_id: hindranceBasicDetailsForm.nit_id,
        current_label: "draft",
        reasons: formFields,
        siteId: hindranceBasicDetailsForm?.siteId,
        po_id: hindranceBasicDetailsForm?.po,
      };
      let response = params?.id
        ? await updateHindranceStatus(params?.id, data)
        : await createHindrance(data);
      if (response.status) {
        setShowStatusPopUp(true);
        setOnSave((pre) => !pre);
        !params?.id &&
          navigate(
            "/contractor-management/contractor-hindrance/update/" +
              response.data.id
          );
      } else {
        toastr.error(response?.message);
      }
    }
  };

  function handleClick() {
    setShow(true);
    setMessagePopupType(true);
  }

  useEffect(() => {
    if (hindranceBasicDetailsForm.nit_id) {
      let site = allNit?.find(
        (obj) => obj.id === hindranceBasicDetailsForm.nit_id
      );
      hindranceBasicDetailsForm.siteId = site?.site_id;
    }
  }, [allNit, hindranceBasicDetailsForm]);

  useEffect(() => {
    let nit = [];
    if (
      hindranceBasicDetailsForm?.input_date &&
      hindranceBasicDetailsForm.nit_id
    ) {
      nit = nitList.filter((d) => {
        return d.value === hindranceBasicDetailsForm.nit_id;
      });
      if (nit[0]?.startDate && nit[0]?.endDate) {
        let dateCheck = checkDatesIsBetween(
          DDMMYYYYFormat(hindranceBasicDetailsForm?.input_date),
          nit[0]?.startDate,
          nit[0]?.endDate
        );
        if (!dateCheck) {
          setShowStatusPopUp(true);
          setInvalidData(true);
        }
      }
    }
  }, [hindranceBasicDetailsForm.input_date, hindranceBasicDetailsForm.nit_id]);
  const closeInvalidPopUp = () => {
    setShowStatusPopUp(false);
    hindranceBasicDetailsForm.input_date = "";
    setInvalidData(false);
  };

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              className="title"
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Contractor Hindrance Management",
                    path: "#",
                  },
                  {
                    name: "Contractor Hindrance",
                    path: "/contractor-management/contractor-hindrance/module",
                  },
                  {
                    name: "Add Contractor Hindrance",
                    path: "#",
                  },
                ]}
                // module={ACTIVE_MODULE.incident}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              Create Contractor Hindrance &nbsp;
              <span className="blueLabel">
                {/* {moment().format("DD/MM/YYYY hh:mm A")} */}
              </span>
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="hindranceContent" data-testid="hindrance-add">
                  <h4 className="font18">Contractor Details</h4>

                  {/* <div data-testid="params-data" id={`${params?.id}`}>{`${params?.id}`}</div> */}
                  <div className="hindranceData" data-testid="form">
                    <Form>
                      <Row>
                        <Col md={3} className="rightCalendar">
                          <label className="form-label-input">
                            Contractor Code
                          </label>
                          <p className="content">
                            {contractorData?.contractor_code}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className="form-label-input">Contractor</label>
                          <p className="content">
                            {contractorData?.contractor}
                          </p>
                        </Col>
                        <Col md={3}>
                          <label className="form-label-input">
                            Contractor Person
                          </label>
                          <p className="content">
                            {contractorData?.contractor_person}
                          </p>
                        </Col>
                        {contractorData &&
                          hindranceBasicDetailsForm?.nit_id && (
                            <>
                              <Col md={3}>
                                <label className="form-label-input">
                                  NIT / PO
                                </label>
                                <p className="content">
                                  {`${
                                    contractorData?.new_nit_number
                                  } / ${findElementByArray(
                                    poList,
                                    hindranceBasicDetailsForm?.po,
                                    "id",
                                    "label"
                                  )} 
                                   `}
                                </p>
                              </Col>
                              <Col md={3}>
                                <label className="form-label-input">
                                  Subsidiary
                                </label>
                                <p className="content">
                                  {contractorData?.nit_subsidiary}
                                </p>
                              </Col>
                              <Col md={3}>
                                <label className="form-label-input">Mine</label>
                                <p className="content">
                                  {contractorData?.nit_mine}
                                </p>
                              </Col>
                            </>
                          )}
                      </Row>
                    </Form>
                  </div>
                  <h4 className="font18">Add Details</h4>

                  <div className="hindranceData" id="form">
                    <Form>
                      <Row>
                        <Col md={4}>
                          <Select
                            onChange={onChangeNit}
                            value={hindranceBasicDetailsForm?.nit_id}
                            name="nit_id"
                            options={nitList}
                            label={"NIT"}
                            error={hindranceBasicDetailsErrorForm?.nit_id}
                            // disabled={!!params?.id}
                          />
                        </Col>
                        <Col md={4}>
                          <Select
                            onChange={onChangeNit}
                            value={hindranceBasicDetailsForm?.po}
                            name="po"
                            options={poList.filter(
                              (d) =>
                                d.nit_id === hindranceBasicDetailsForm?.nit_id
                            )}
                            error={hindranceBasicDetailsErrorForm?.po}
                            label={"PO"}
                            KEY_value={"id"}
                            // disabled={!!params?.id}
                          />
                        </Col>
                        <Col md={4} className="rightCalendar">
                          <Form.Label className="form-label-input">
                            Date
                          </Form.Label>
                          <CustomDatePicker
                            value={hindranceBasicDetailsForm?.input_date}
                            isValidDate={(date) => disableFutureDate(date)}
                            dateFormat="YYYY-MM-DD"
                            name="input_date"
                            onChangeDate={onChange}
                            error={hindranceBasicDetailsErrorForm?.input_date}
                            // disabled={!!params?.id}
                          />
                          {/* <InputError error={hindranceBasicDetailsErrorForm?.input_date} /> */}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                  {hindranceBasicDetailsForm?.nit_id && (
                    <span className="text-danger">{contractorStatus}</span>
                  )}
                  <div>
                    {Array.isArray(formFields) &&
                      formFields.map((form, index) => {
                        return (
                          <div
                            className="hindranceData mt-3"
                            data-testid="reason-data"
                            key={index}
                          >
                            <Row>
                              <Col md={2}>
                                <Select
                                  onChange={(event) =>
                                    handleFormChange(event, index)
                                  }
                                  name="shift"
                                  options={shiftOpt}
                                  label={"Shift"}
                                  placeholder={"Select"}
                                  value={form?.shift}
                                  error={hindranceForm[index]?.shift}
                                />
                              </Col>
                              <Col md={2}>
                                <Select
                                  onChange={(event) =>
                                    handleFormChange(event, index)
                                  }
                                  name="reason"
                                  options={reasonList}
                                  label={"Reason"}
                                  placeholder={"Enter"}
                                  value={form?.reason}
                                  error={hindranceForm[index]?.reason}
                                />
                              </Col>
                              <Col md={4} lg={2}>
                                <Form.Label>Hours of Hindrance</Form.Label>
                                <FormInputText
                                  type="number"
                                  onChange={(event) =>
                                    handleFormChange(event, index)
                                  }
                                  name="hrs_of_hindrance"
                                  value={form?.hrs_of_hindrance}
                                  maxLength={24}
                                  minLength={0}
                                  error={hindranceForm[index]?.hrs_of_hindrance}
                                  className={"hour"}
                                />
                              </Col>
                              <Col md={4} lg={2}>
                                <FormInputText
                                  type="input"
                                  label={"Place"}
                                  onChange={(event) =>
                                    handleFormChange(event, index)
                                  }
                                  name="place"
                                  value={form?.place}
                                  error={hindranceForm[index]?.place}
                                />
                              </Col>
                              <Col md={2} lg={4}>
                                <FormInputText
                                  type="input"
                                  label={"Comment"}
                                  onChange={(event) =>
                                    handleFormChange(event, index)
                                  }
                                  name="vendor_comments"
                                  value={form?.vendor_comments}
                                  error={hindranceForm[index]?.vendor_comments}
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </div>
                  <div className="btnSec">
                    <p
                      className="addTxt addmore"
                      data-testid="add-new"
                      onClick={() => addFields()}
                      role="button"
                    >
                      <AiOutlinePlus /> Add New
                    </p>
                    {formFields?.length > 1 && (
                      <p
                        className="addTxt addmore"
                        data-testid="remove"
                        onClick={() => removeFields()}
                        role="button"
                      >
                        <AiOutlineMinus /> Remove Last
                      </p>
                    )}
                  </div>
                  <div className="btnSec">
                    <Button
                      className="secondaryBtn"
                      data-testid="handle-click-for-show"
                      onClick={handleClick}
                    >
                      Close
                    </Button>
                    <div className="d-flex">
                      <Button
                        className="primaryBtn"
                        data-testid="submit-hindrance-draft"
                        onClick={onSave}
                      >
                        Save
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="primaryBtn"
                        onClick={onSubmit}
                        data-testid="submit-hindrance"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            messagePopupType={messagePopupType}
            show={show}
            setShow={hideMessagePopup}
            closePopup={closePopup}
          />
          <StatusPopup
            messagePopupType={messagePopupType}
            show={showStatusPopUp}
            setShow={setMessageShow}
            closePopup={closeMessagePopup}
            invalidData={invalidData}
            closeInvalidPopUp={closeInvalidPopUp}
          />
        </div>
      </div>
    </div>
  );
};

export default HindranceAdd;
