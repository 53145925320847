import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../components/FormInputText/FormInputText";
import LabelCustom from "../../../components/LabelCustom/LabelCustom";
import { coalGradeLabel } from "../DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../components/Select/Select";
import { modeOfDispatchList } from "../CoalSamplePreparation/DATA";
import moment from "moment";

function CoalDispatchRelatedForm({
  rrDDNoOList,
  declaredGradeList,
  form,
  onChange,
  error,
  setForm,
  ModeOfDispatchOnChangeFn,
  RrDdNoOnChangeFn,
  RrDdDateOnChangeFn,
  assignId,
  disputeUser,
}) {
  return (
    <div className="creationWrapper">
      <Form>
        <Row>
          <Col md={6}>
            <Select
              onChange={(e) => {
                onChange(e);
                ModeOfDispatchOnChangeFn(e);
              }}
              error={error?.mode_of_dispatch}
              name="mode_of_dispatch"
              options={modeOfDispatchList}
              label={coalGradeLabel?.mode_of_dispatch}
              value={form?.mode_of_dispatch}
            />
          </Col>
          <Col md={6}>
            <LabelCustom>
              {/* {form?.mode_of_dispatch === "SHRA"
                ? "RR date"
                : "Gross Weight Date"}{" "} */}
              Collection Date
            </LabelCustom>
            <CustomDatePicker
              name="rr_or_do_date"
              error={error?.rr_or_do_date}
              onChange={(e) => {
                onChange(e);
                RrDdDateOnChangeFn(e);
              }}
              value={form?.rr_or_do_date}
            />
            {/* <InputError error={error?.rr_or_do_date} /> */}
          </Col>
          <Col md={6}>
            <Select
              value={form?.rr_or_do_number}
              name="rr_or_do_number"
              options={rrDDNoOList}
              error={error?.rr_or_do_number}
              label={coalGradeLabel?.rr_or_do_number}
              onChange={(e) => {
                onChange(e);
                RrDdNoOnChangeFn(e);
              }}
            />
          </Col>

          <Col md={6}>
            <Select
              onChange={(e) => {
                onChange(e);
                assignId(e);
              }}
              value={form?.declared_grade}
              name="declared_grade"
              options={declaredGradeList}
              error={error?.declared_grade}
              label={coalGradeLabel?.declared_grade}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.name_of_referee_agency}
              onChange={onChange}
              name="name_of_referee_agency"
              value={form?.name_of_referee_agency}
              error={error?.name_of_referee_agency}
            />
          </Col>
          <Col md={6}>
            <LabelCustom>{coalGradeLabel?.referee_date_of_result}</LabelCustom>
            <CustomDatePicker
              isValidDate={(day) => day.isBefore(moment())}
              value={
                form.referee_date_of_result ? form.referee_date_of_result : ""
              }
              error={error?.referee_date_of_result}
              name="referee_date_of_result"
              onChange={(e) => {
                onChange(e);
              }}
              timeFormat={"HH:mm A"}
              dateFormat="YYYY-MM-DD"
            />
          </Col>
          <Col md={6}>
            <Select
              onChange={onChange}
              value={form?.dispute_raised_by}
              name="dispute_raised_by"
              options={disputeUser}
              error={error?.dispute_raised_by}
              label={coalGradeLabel?.dispute_raised_by}
              KEY_label={"name_email_employeeId"}
              KEY_value={"disputeUserId"}
            />
          </Col>
          <Col md={6}>
            <LabelCustom>{coalGradeLabel?.dispute_raised_date}</LabelCustom>
            <CustomDatePicker
              value={form?.dispute_raised_date}
              error={error?.dispute_raised_date}
              name="dispute_raised_date"
              onChange={(e) => {
                onChange(e);
              }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CoalDispatchRelatedForm;
