import { createBrowserHistory } from "history";
import React from "react";
import { BsGridFill, BsPersonFill, BsLayersHalf } from "react-icons/bs";
import { TiHome } from "react-icons/ti";
import { Link } from "react-router-dom";
import { FIND_DASBOARD_ROUTE, MODULE_URL_NAME } from "./DATA";
import RoutePermission from "../../_services/Auth/RoutePermision";
import { fuelDashboardLinkMobile } from "../../_services/CommonService/CommonService";
import { useSelector } from "react-redux";

function MobFooter({ activeLink }) {
  const { location } = createBrowserHistory();
  const storeFilter = useSelector((state) => state?.dashboardFilter?.fuelDash);
  let fuelDashboardUrlMobile = fuelDashboardLinkMobile(storeFilter?.source);

  const moduleName =
    MODULE_URL_NAME.find(
      (name) => location?.pathname.includes(name) || name === location?.state
    ) || {};

  const filterRoute = RoutePermission.find(
    (routeObj) => routeObj?.path?.includes(moduleName) && routeObj?.isDashboard
  );

  return (
    <div className="appBottomMenu">
      <Link
        to={"/mobile"}
        className={`item ${activeLink === "/mobile" ? "active" : ""}`}
      >
        <div className="col">
          <div className="nav-icon">
            <TiHome size={26} />
          </div>
          <strong>Home</strong>
        </div>
      </Link>

      {FIND_DASBOARD_ROUTE(filterRoute) ===
        "/mobile/fuel-management-system/dashboard/1" ||
      FIND_DASBOARD_ROUTE(filterRoute) ===
        "/mobile/fuel-management-system/sap-dashboard/1" ? (
        <Link
          to={fuelDashboardUrlMobile || "#"}
          className={`item ${
            activeLink?.includes("dashboard") ? "active" : ""
          }`}
        >
          <div className="col">
            <div className="nav-icon">
              <BsGridFill size={22} />
            </div>
            <strong>Dashboard</strong>
          </div>
        </Link>
      ) : (
        <Link
          to={FIND_DASBOARD_ROUTE(filterRoute) || "#"}
          className={`item ${
            activeLink?.includes("dashboard") ? "active" : ""
          }`}
        >
          <div className="col">
            <div className="nav-icon">
              <BsGridFill size={22} />
            </div>
            <strong>Dashboard</strong>
          </div>
        </Link>
      )}

      <Link
        to={"/mobile/profile"}
        state={moduleName}
        className={`item ${activeLink === "/mobile/profile" ? "active" : ""}`}
      >
        <div className="col">
          <div className="nav-icon">
            <BsPersonFill size={26} />
          </div>
          <strong>Account</strong>
        </div>
      </Link>
    </div>
  );
}

export default MobFooter;
