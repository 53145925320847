import React, { Component } from "react";
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { ForgetPasswordService } from "../../_services/ForgotPassword/ForgetPasswordService";
import toastr from 'toastr'
import { Validation } from "../../../src/_helpers/validation/Validation";
import "./ForgotPassword.scss"
import logo from "../../Assets/Images/logo.png";
class ForgotPassword extends Component {
  state = {
    email: "",
    error: {},
    submitted: false,
    mailSent: false
  };

  // handleChange = event => {
  //   event.persist();
  //   this.setState({ [event.target.name]: event.target.value });
  // };

  // handleSubmit = (value, { isSubmitting }) => {
  //   this.props.loginWithEmailAndPassword(value);
  // };

  setEmail = (e) => {
    this.setState({
      email: e.target.value
    }, () => {
      this.checkValidation()
    })
  }

  onSubmit = async () => {
    let data = {
      "email": this.state.email
    }
    let errobj = {};
    errobj = Validation(this.state, [{
      field_name: 'email',
      label: 'Email',
      type: "email",
      isRequired: true
    }]);
    this.setState({ error: errobj, submitted: true });
    if (errobj && errobj.email === "") {
      let response = await ForgetPasswordService.ForgetPassword(data);
      if (response && response.status === 1) {
        toastr.success("Please Check Reset Password Link on Your Registered Email");
        this.setState({ mailSent: true, });
      } else {
        toastr.warning(response.message)
      }
    }
  }

  checkValidation = () => {
    if (this.state.submitted) {
      let errobj = {};
      errobj = Validation(this.state, [{
        field_name: 'email',
        label: 'Email',
        type: "email",
        isRequired: true
      }]);
      this.setState({ error: errobj, submitted: true });
    }
  }

  render() {
    if (this.state.mailSent) {
      return <Navigate to={"/auth/login"} replace />;
    }
    return (
      <Card className="forgotWrapper">
        <img src={logo} className="img-fluid DigicoalLogo" alt="DigicoalLogo" />
        <h3 className="forgotTitle">Forgot Password</h3>
        <p className="mb-4">Enter your email that is associated with your account we will send you a link to reset your password.</p>
        <div className="row">
          <div className="col-md-12 form-group">
            {/* <label>Email</label> */}
            <input
              name="name"
              type="text"
              className="form-control"
              placeholder="Enter Email"
              value={this.state.email}
              onChange={this.setEmail}
            />
            {this.state.submitted && this.state.error && this.state.error.email !== "" && (
              <div className="errorMssg text-danger">{this.state.error.email}</div>)}
          </div>
          <div className="loginBtnSec">
            <Button className="btn loginBtn" onClick={() => this.onSubmit()}>Submit</Button>
          </div>
        </div>
        <div className="signUp">
          <p>Back to ? <Link to="/session/signin">Login</Link></p>
        </div>
      </Card>
    );
  }
}


const mapStateToProps = state => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  user: state.user
});

export default connect(mapStateToProps, {
  loginWithEmailAndPassword
})(ForgotPassword);
