import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { reasonListApis, reasonListColumnFields } from "./DATA";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import { tabUrl2 } from "../../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../../_helpers/helper";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import { getAllMasterDropdownSamplingAgency } from "../../../../services/_reasonList/reasonList";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../DATA";
import { changeStatus } from "../../../../_services/CommonService/CommonService";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import toastr from "toastr";

const SamplingAgencyList = () => {
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl2.contractorPerformance)
  );
  const cref = useRef(null);

  let navigate = useNavigate();
  const getAll = async () => {
    const response = await getAllMasterDropdownSamplingAgency();
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else {
      setIsListData(false);
    }
  };

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} />;
  };

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });

    getAll();
  }, []);

  const onEdit = (id) => {
    navigate("/coal-grade/configuration/sampling-agency/" + id);
  };

  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: `masterDropdowns`,
        id: id,
        status: status
      }
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const finalColumnFields = useMemo(
    () => [
      ...reasonListColumnFields,
      {
        name: "Status",
        selector: "status",
        cell: (row) => {
          return (
            (row.status === 1 && (
              <span
                href="#"
                className="icons text-success pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOn
                  size="20"
                  data-testid={"toogle-user-list-on-" + row.id}
                />
              </span>
            )) ||
            (row.status === 0 && (
              <span
                href="#"
                className="icons text-danger pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOff
                  size="20"
                  data-testid={"toogle-user-list-off-" + row.id}
                />
              </span>
            ))
          );
        },
      },
    ],
    []
  );

  return isListData ? (
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              {/* <h4>Contractor Performance</h4> */}
              {/* <CustomTabs tabeList={customTabs} /> */}
              <div className="customDataTbl">
                <DataTableWithServer
                  customTableTitle={"Sampling Agency List"}
                  viewTitle={"Contractor Performance Details"}
                  hideSearch={true}
                  editFunButton={onEdit}
                  cref={cref}
                  apis={reasonListApis}
                  title="Data"
                  // viewAsPopup={viewAsPopup}
                  fields={finalColumnFields}
                  hideSnColumn
                  pageLink={"/coal-grade/configuration/sampling-agency"}
                  pagination
                  hideDeleteButton
                  hideEditButton
                  listname={"Sampling Agency"}
                  deleteFun
                  primaryKey="id"
                  breadCrumb={[
                    {
                      name: "Coal Grade",
                      path: "/",
                    },
                    {
                      name: "Configuration",
                      path: "/coal-grade/configuration",
                    },
                    {
                      name: "Sampling Agency List",
                      path: "#",
                    },
                  ]}
                  isNoTabOnPage
                />

                {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
              </div>
            </Container>
          </div>
        </div>
      </div>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              pageLink={"/coal-grade/configuration/sampling-agency"}
              // tabButtons={tabButtons}
              title={"Sampling Agency"}
              breadCrumb={[
                {
                  name: "Coal Grade",
                  path: "/",
                },
                {
                  name: "Configuration",
                  path: "/coal-grade/configuration",
                },
                {
                  name: "Sampling Agency List",
                  path: "#",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SamplingAgencyList;
