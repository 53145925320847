import { auth_user } from "../../_helpers/helper";
import configureStore from "../../redux/Store";
let { Store } = configureStore();

export const getPermission = () => {
  const permissions = Store.getState()?.PermissionReducer?.permission;
  return permissions;
};

export const permissionCheck = (permission) => {
  const permissions = getPermission();
  if (Array.isArray(permission)) {
    let flag = false;
    for (const iterator of permission) {
      if (permissions && permissions[iterator]) {
        flag = true;
        break;
      }
    }
    return flag;
  } else {
    return true;
  }
};

export const isChemist = () => {
  return getPermission()?.chemist;
};

export const isLabQualityManager = () => {
  return getPermission()?.lab_quality_manager;
};

export const isAreaQualityManager = () => {
  return getPermission()?.area_quality_manager;
};

export const isContractorModuleAdmin = () => {
  return getPermission()?.contractor_module_admin;
};

export const isLoggedSite = () => {
  return auth_user()?.siteId || "";
};

export const SAFETY_ROLE_OBJECT = {
  safetyManagement: {
    safety_admin: "safety_admin",
    safety_management_dashbaord: "safety_management_dashbaord",
    safety_user: "safety_user",
  },
};
export const ROLE_OBJECT = {
  coalGradeQuality: {
    chemist: "chemist",
    lab_quality_manager: "lab_quality_manager",
    area_quality_manager: "area_quality_manager",
    coal_grade_admin: "coal_grade_admin",
    coal_grade_dashboard: "coal_grade_dashboard",
  },
  contractorManagement: {
    contractor: "contractor",
    representative: "representative",
    section_incharge: "section_incharge",
    colliery_manager: "colliery_manager",
    contractor_module_admin: "contractor_module_admin",
    // contractor_dashboard: "contractor_dashboard",
    contractor_performance_dashboard: "contractor_performance_dashboard",
    performance_production_dashboard: "performance_production_dashboard",
    contractor_performance_score_card_dashboard:
      "contractor_performance_score_card_dashboard",
    contractor_hindrance_dashboard: "contractor_hindrance_dashboard",
  },
  safetyManagement: {
    safety_admin: "safety_admin",
    safety_management_dashbaord: "safety_management_dashbaord",
    safety_user: "safety_user",
  },
  fuelManagement: {
    dispenser: "dispenser",
    fuel_admin: "fuel_admin",
    fuel_management_dashbaord: "fuel_management_dashbaord",
  },
  environmentManagement: {
    env_admin: "env_admin",
    env_user: "env_user",
    environment_management_dashboard: "environment_management_dashboard",
  },
  hemm_cockpit: {
    hemm_cockpit_admin: "hemm_cockpit_admin",
    hemm_cockpit_dashboard: "hemm_cockpit_dashboard",
  },
  sap_import: {
    sap_import: "Import Sap",
  },
};

export const CONTRACTOR_ROLE_OBJECT = {
  userManagement: {
    representative: "representative",
    section_incharge: "section_incharge",
    contractor: "contractor",
    contractor_module_admin: "contractor_module_admin",
    // contractor_dashboard: "contractor_dashboard",
    contractor_performance_dashboard: "contractor_performance_dashboard",
    performance_production_dashboard: "performance_production_dashboard",
    contractor_performance_score_card_dashboard:
      "contractor_performance_score_card_dashboard",
    contractor_hindrance_dashboard: "contractor_hindrance_dashboard",
    colliery_manager: "colliery_manager",
  },
};

export const CONTRACTOR_PERMISSION_OBJECT = {
  contractorManagement: {
    contractor_performance: "contractor_performance",
    contractor_hindrance: "contractor_hindrance",
  },
};

export const ADMIN_ROLE_OBJECT = {
  userManagement: {
    representative: "representative",
    section_incharge: "section_incharge",
    colliery_manager: "colliery_manager",
    dispenser: "dispenser",
    chemist: "chemist",
    lab_quality_manager: "lab_quality_manager",
    area_quality_manager: "area_quality_manager",
    coal_grade_admin: "coal_grade_admin",
    contractor: "contractor",
    contractor_module_admin: "contractor_module_admin",
    safety_admin: "safety_admin",
    fuel_admin: "fuel_admin",
    env_admin: "env_admin",
    env_user: "env_user",
    coal_grade_dashboard: "coal_grade_dashboard",
    // contractor_dashboard: "contractor_dashboard",
    contractor_performance_dashboard: "contractor_performance_dashboard",
    performance_production_dashboard: "performance_production_dashboard",
    contractor_performance_score_card_dashboard:
      "contractor_performance_score_card_dashboard",
    contractor_hindrance_dashboard: "contractor_hindrance_dashboard",
    safety_management_dashbaord: "safety_management_dashbaord",
    fuel_management_dashbaord: "fuel_management_dashbaord",
    environment_management_dashboard: "environment_management_dashboard",
    hemm_cockpit_admin: "hemm_cockpit_admin",
    hemm_cockpit_dashboard: "hemm_cockpit_dashboard",
    safety_user: "safety_user",
    sap_import: "Import Sap",
  },
};

export const Env_ROLE_OBJECT = {
  environmentManagement: {
    env_admin: "env_admin",
    env_user: "Environment User",
    environment_management_dashboard: "Environment Management Dashboard",
  },
};

export const Fuel_ROLE_OBJECT = {
  fuelManagement: {
    dispenser: "dispenser",
    fuel_admin: "fuel_admin",
    fuel_management_dashbaord: "fuel_management_dashbaord",
  },
};

export const ROLE_OBJECT_VIEW = {
  representative: "Representative",
  section_incharge: "Section Incharge",
  colliery_manager: "Colliery Manager",
  dispenser: "Dispenser",
  chemist: "Chemist",
  lab_quality_manager: "Lab Quality Manager",
  area_quality_manager: "Area Quality Manager",
  coal_grade_admin: "Coal Grade Admin",
  contractor: "Contractor",
  contractor_module_admin: "Contractor Module Admin",
  safety_admin: "Safety Admin",
  fuel_admin: "Fuel Admin",
  env_admin: "Environment Admin",
  env_user: "Environment User",
  coal_grade_dashboard: "Coal Grade Dashboard",
  // contractor_dashboard: "Contractor Dashboard",
  contractor_performance_dashboard: "Contractor Performance Dashboard",
  performance_production_dashboard: "Production Performance Dashboard",
  contractor_performance_score_card_dashboard:
    "Contractor Score Card Dashboard",
  contractor_hindrance_dashboard: "Contractor Hindrance Dashboard",
  safety_management_dashbaord: "Safety Management Dashboard",
  fuel_management_dashbaord: "Fuel Management Dashboard",
  environment_management_dashboard: "Environment Management Dashboard",
  hemm_cockpit_admin: "HEMM Cockpit Admin",
  hemm_cockpit_dashboard: "HEMM Cockpit Dashboard",
  safety_user: "Safety User",
  sap_import: "Import Sap",
  contractor_performance: "Contractor Performance",
  contractor_hindrance: "Contractor Hindrance",
};

export const MODULE_OBJECT_VIEW = {
  coalGradeQuality: "Coal Grade Quality",
  contractorManagement: "Contractor Management",
  safetyManagement: "Safety Management",
  fuelManagement: "Fuel Management",
  environmentManagement: "Environment Management",
  hemm_cockpit: "HEMM Cockpit",
  sap_import: "Sap",
};

export const COAL_GRADE_VIEW = {
  userManagement: {
    lab_quality_manager: "lab_quality_manager",
    chemist: "chemist",
    area_quality_manager: "area_quality_manager",
    coal_grade_admin: "coal_grade_admin",
  },
};
