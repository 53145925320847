import React, { useEffect, useRef, useState } from "react";
// import "../fuelmgmt.scss";
import { Container } from "react-bootstrap";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import CoalGradeDashboardFilter from "./CoalGradeDashboardFilter";
import {
  PAGE_TYPE_BY_DASHBOARD,
  initialFilterForm,
  screenPage,
  Tabs,
  PAGE_TYPE_BY_CHART_FILTER,
} from "./DATA";
import { useForm } from "../../../_helpers/hooks";
import { useNavigate, useParams } from "react-router-dom";
import TabFilterButton from "../../../components/TabFilterButton/TabFilterButton";
import { getAllSites } from "../../../_services/SiteServices/SiteServices";
import {
  IS_USER_SITE_HO,
  IS_USER_SITE_MINES,
  auth_user,
} from "../../../_helpers/helper";
import { UserService } from "../../../_services/UserService/UserService";
import FilesExport from "../../../components/Common/FilesExport";
import { SelectedDateLable } from "../../SafetyManagementSystem/IncidentManagement/Dashboard/DATA";
import { getAllMasterDropdownSamplingAgency } from "../../../services/_reasonList/reasonList";

const CoalGradeDashboard = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const cref = useRef(null);
  const [form, onChange, setForm] = useForm(initialFilterForm);
  const [subsidery, setSubsidery] = useState([]);
  const [sites, setSites] = useState([]);
  const [finalSiteList, setFinalSiteList] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [samplingAgency, setSamplingAgency] = useState([]);
  const [dispatchMode, setDispatchMode] = useState([]);
  const [measuredGrade, setMeasuredGrade] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [selectedTab, setSelectedTab] = useState("3rdparty");
  const [appliedFilters, setAppliedFilters] = useState();
  const [minMaxDate, setMinMaxDate] = useState({});

  const getSamplingAgency = async () => {
    let data = await getAllMasterDropdownSamplingAgency();
    let opts = data.data.map((d) => {
      return {
        value: d?.id,
        label: d?.name,
      };
    });
    setSamplingAgency(opts);
  };

  const resetFun = () => {
    setForm(initialFilterForm);
    setIsReset(true);
  };

  const applyFun = () => {
    cref?.current?.fetchData();
  };

  useEffect(() => {
    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        filter += `&${key}=${element}`;
      }
    }
    setAppliedFilters(filter);
    isReset && setFilterReset(true);
  }, [form, isReset]);

  useEffect(() => {
    if (!isReset || !filterReset) return;
    if (isReset && filterReset) cref?.current?.fetchData();
    setIsReset(false);
    setFilterReset(false);
  }, [filterReset, isReset]);

  const handleChange = (val) => {
    setSelectedTab(val);
  };

  useEffect(() => {
    getAllSites("filter[siteType]=1,2,3&filter[activeStatus]=active")?.then(
      (response) => {
        if (!response?.data) return;
        let subsideryData = [];
        const siteData = response.data.filter((d) => d?.siteType === 3);
        setFinalSiteList(siteData);
        if (IS_USER_SITE_MINES()) {
          subsideryData = [
            {
              id: response.data?.length
                ? response.data?.[0]?.parentSite?.id
                : "",
              name: response.data?.length
                ? response.data?.[0]?.parentSite?.name
                : "",
            },
          ];
        } else {
          subsideryData = response.data.filter((d) => d?.siteType === 2);
        }
        setSubsidery(subsideryData);
        setSites(siteData);
      }
    );

    UserService?.getAllCustomer()?.then((response) => {
      if (!response?.data) return;
      setCustomer(
        response?.data?.map((d) => ({
          value: String(d?.id),
          label: d?.name + " (" + d?.id + ")",
        }))
      );
    });
    getSamplingAgency();
  }, []);

  useEffect(() => {
    if (IS_USER_SITE_HO()) return;
    const siteObj = sites?.find((d) => d.id === auth_user()?.siteId) || {};
    setForm((prev) => {
      let siteDataObj = {};
      if (prev.hasOwnProperty("siteId")) {
        siteDataObj = {
          subsideryId: IS_USER_SITE_MINES()
            ? siteObj?.parentSite?.id
            : auth_user()?.siteId,
          siteId: IS_USER_SITE_MINES() ? siteObj?.id : "",
        };
      }
      return {
        ...prev,
        ...siteDataObj,
      };
    });
  }, [sites, id]);

  useEffect(() => {
    setFinalSiteList(
      form?.companyId
        ? sites?.filter((d) => d?.parentSiteId === +form?.companyId)
        : sites
    );
  }, [form?.companyId, sites]);

  useEffect(() => {
    form.mineId = "";
  }, [form.companyId]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar
        SidebarData={SidebarData}
        moduleTitle={"Coal Grade"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="dashboardTabs coalGradeScreenTabs">
            <ul>
              <li
                className={`${+id === 1 ? "active" : ""}`}
                onClick={() => navigate(`/coal-grade/dashboard/1`)}
              >
                Grade Dispatch Distribution
              </li>
              <li
                className={`${+id === 2 ? "active" : ""}`}
                onClick={() => navigate(`/coal-grade/dashboard/2`)}
              >
                Trend for Coal Grade
              </li>
              <li
                className={`${+id === 3 ? "active" : ""}`}
                onClick={() => navigate(`/coal-grade/dashboard/3`)}
              >
                Grade Slippage Report
              </li>
              <li
                className={`${+id === 4 ? "active" : ""}`}
                onClick={() => navigate(`/coal-grade/dashboard/4`)}
              >
                Referee Data
              </li>
            </ul>
            <SelectedDateLable
              form={form}
              minMaxDate={minMaxDate}
              // coalGrade={true}
            />
            {form?.dispatchModeId && (
              <span className="blueLabel">
                Mode:
                {" " +
                  form.dispatchModeId
                    .replace("SHRA", " Rail ")
                    .replace("SHRO", " Road ")
                    .replace("SHMG", " Merry Go Round ")}
              </span>
            )}
            <div>
              <div className="tabFilter">
                {+id !== 4 &&
                  Tabs?.map((tab) => (
                    <TabFilterButton
                      label={tab?.label}
                      id={tab?.id}
                      toggleValue={tab?.value}
                      handleChange={() => handleChange(tab.value)}
                      selectedTab={selectedTab}
                    />
                  ))}
                <FilesExport id={`#${"screen" + id}`} fullScreen />
              </div>

              <CoalGradeDashboardFilter
                // title={"Coal Grade/ Quality Monitoring"}
                screens={screenPage}
                isActive={id}
                form={form}
                onChange={onChange}
                company={subsidery}
                mine={finalSiteList}
                customer={customer}
                samplingAgency={samplingAgency}
                dispatchMode={[
                  {
                    label: "Rail",
                    value: "SHRA",
                  },
                  {
                    label: "Road",
                    value: "SHRO",
                  },
                  {
                    label: "Merry Go Round",
                    value: "SHMG",
                  },
                ]}
                measuredGrade={[
                  {
                    id: "G1",
                    name: "G1",
                  },
                  {
                    id: "G2",
                    name: "G2",
                  },
                  {
                    name: "G3",
                    id: "G3",
                  },
                  {
                    name: "G4",
                    id: "G4",
                  },
                  {
                    id: "G5",
                    name: "G5",
                  },
                  {
                    id: "G6",
                    name: "G6",
                  },
                  {
                    name: "G7",
                    id: "G7",
                  },
                  {
                    id: "G8",
                    name: "G8",
                  },
                  {
                    name: "G9",
                    id: "G9",
                  },
                  {
                    id: "G10",
                    name: "G10",
                  },
                  {
                    name: "G11",
                    id: "G11",
                  },
                  {
                    id: "G12",
                    name: "G12",
                  },
                  {
                    name: "G13",
                    id: "G13",
                  },
                  {
                    id: "G14",
                    name: "G14",
                  },
                  {
                    name: "G15",
                    id: "G15",
                  },
                  {
                    id: "G16",
                    name: "G16",
                  },
                  {
                    id: "G17",
                    name: "G17",
                  },
                ]}
                resetFun={resetFun}
                applyFun={applyFun}
              />
            </div>
          </div>
          <Container fluid>
            {PAGE_TYPE_BY_DASHBOARD(
              id,
              cref,
              form,
              appliedFilters,
              customer,
              selectedTab,
              setMinMaxDate
            )}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default CoalGradeDashboard;
