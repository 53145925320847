import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { useForm } from "../../../_helpers/hooks";
import {
  contractorPerformanceApis,
  contractorPerformanceColumnFields,
  contractorPerformanceFilterForm,
  myContractorPerformanceApis,
  EditLink,
} from "./DATA";
import ContractorPerformanceView from "./ContractorPerformanceView";
import ContractorPerformanceFilterForm from "./ContractorPerformanceFilterForm";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import {
  exportContractorPerformance,
  getAllContractorByNit,
  getMyContractorPerformancesListWithPagination,
} from "../../../services/_contractorPerformance/contractorPerformance";
import EmptyDataList from "./EmptyDataList";
import "./contractorPerformance.scss";
import filter from "../../../Assets/Images/filter.svg";
import { tabUrl } from "../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import { permissionCheck } from "../../../_services/Auth/helper";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import ContractorTenderDetails from "../CommonComponent/ContractorTenderDetails";

const MyContractorPerformanceList = () => {
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [contractorList, setContractorList] = useState([]);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.contractorPerformance)
  );
  const [form, onChange, setForm] = useForm(contractorPerformanceFilterForm);
  const [filterStatus, setFilterStatus] = useState(0);
  let navigate = useNavigate();
  const getAll = async () => {
    const response = await getMyContractorPerformancesListWithPagination(
      "page=1&limit=2"
    );
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
    const allContractorList = await getAllContractorByNit();
    setContractorList(
      allContractorList?.map((d) => {
        return { value: d.id, label: d.contractor };
      })
    );
  };

  // const onEdit = (id) => {
  //   navigate("/contractor-management/contractor-performances/edit/" + id);
  // };

  useEffect(() => {
    if (filterStatus === 0) return;
    
    let filter = "";
    for (const key in form) {
      // console.log("forminnng" , form);
      // console.log('form' , form[key]);
      const element = form[key];
      // console.log("element" ,element);
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }

    setGetAllFilter(filter);
  }, [filterStatus]);

  useEffect(() => {
    // setCustomTabs((prev) => {
    //   return prev?.map((d) => {
    //     // console.log("d", d, usrl.pathname);
    //     if (d.to === usrl.pathname) {
    //       return {
    //         ...d,
    //         active: 1,
    //       };
    //     }
    //     return d;
    //   });
    // });
    getAll();
  }, []);

  const onSubmitFilter = () => setFilterStatus((prev) => prev + 1);

  const onResetFilter = () => {
    setForm(contractorPerformanceFilterForm);
    setGetAllFilter();
  };

  const viewAsPopup = (form) => {
    return <ContractorPerformanceView form={form} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filterBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <ContractorPerformanceFilterForm
          isOpen={open}
          setOpen={setOpen}
          contractorList={contractorList}
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
        />
      </div>
    );
  };

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            {/* <h4>Contractor Performance</h4> */}
            {/* <CustomTabs tabeList={customTabs} /> */}
            <div className="customDataTbl">
              <DataTableWithServer
                getAllFilter={getAllFilter}
                // childrenAfterAddButton={tabButtons}
                downloadExcel={exportContractorPerformance}
                fields={contractorPerformanceColumnFields}
                apis={myContractorPerformanceApis}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/contractor-management/contractor-performances"}
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                // hideAddButton={!permissionCheck(["section_incharge"])}
                hideAddButton
                // editContractorPerformanceFun={onEdit}
                viewTitle={"Contractor Performance Details"}
                breadCrumb={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Contractor Performance",
                    path: "/contractor-management/contractor-performance",
                  },
                  {
                    name: "My Contractor Performance",
                    path: "#",
                  },
                ]}
                isNoTabOnPage
                icons
                updatePageLink={EditLink}
                listname={"performance"}
                customTableTitle={"My Contractor Performance"}
                excelFilterOption={"listType=my"}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <h3
            className="title"
            style={{ paddingBottom: "1px", paddingTop: "5px" }}
          >
            <MainBreadcrumb
              routeSegments={[
                {
                  name: "Contractor Management",
                  path: "#",
                },
                {
                  name: "Contractor Performance",
                  path: "/contractor-management/contractor-performance",
                },
                {
                  name: "My Contractor Performance",
                  path: "#",
                },
              ]}
              // module={ACTIVE_MODULE.incident}
            />
          </h3>
          <EmptyDataList
            title={"My Contractor Performance"}
            pageLink={"/contractor-management/contractor-performances"}
            hideAddBtn={!permissionCheck(["section_incharge"])}
            // tabButtons={tabButtons}
          />
        </div>
      </div>
    </div>
  );
};

export default MyContractorPerformanceList;
