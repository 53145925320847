import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import { exportCoalGrade } from "../../../services/_coalGrade/coalGrade";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import filter from "../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../../_helpers/hooks";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import {
  COAL_GRADE_ROLE_BY_DATA,
  SidebarData,
  coalGradeApis,
  coalGrateTypeObj,
  tabUrl,
} from "../DATA";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import { getPermission } from "../../../_services/Auth/helper";
import { converEachFirstWordCapitalize } from "../../../_helpers/commonHelper";
import SampleDecodingView from "../SampleDecoding/SampleDecodingView";
import RefereeLabResultView from "../RefereeLabResult/RefereeLabResultView";
import ThirdPartyLabResultView from "../ThirdPartyLabResult/ThirdPartyLabResultView";
import { getApproveReject } from "../../../services/_coalGrade/coalGrade";
import toastr from "toastr";
import { filterComponent } from "../DATA";
const ApproveLabResultList = () => {
  const params = useParams();
  let navigate = useNavigate();
  const usrl = useLocation();
  const rolePermission = getPermission();
  const [isListData, setIsListData] = useState(true);
  const [allData, setAllData] = useState([]);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl[params?.type])
  );
  const [roleByData, setRoleByData] = useState([]);
  const [form, onChange, setForm] = useForm(roleByData?.filterForm);

  useEffect(() => {
    if (!params?.type) return;
    setRoleByData(
      COAL_GRADE_ROLE_BY_DATA?.find((obj) =>
        obj?.role?.find(
          (role) => rolePermission[role] && obj.type == params?.type
        )
      )
    );
    setGetAllFilter("&approvalAction=true&status=Submitted");
    getAll();
  }, [params?.type]);

  const getAll = async () => {
    let payload = `page=1&limit=10&type=${params?.type}&approvalAction=true&status=Submitted`;
    const response = await coalGradeApis.getAll.api(payload);
    if (Array.isArray(response?.data) && response?.data?.length > 0) {
      setAllData(response?.data);
      setIsListData(true);
    } else setIsListData(false);
  };

  const onApproveReject = async (data, type) => {
    const dataObj = { ...data, type: params?.type };
    const response = await getApproveReject(dataObj, dataObj?.id);
    if (!response?.status) return;
    toastr.success(response?.message);
    // return response;
  };

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev?.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
  }, [usrl.pathname]);

  const onResetFilter = () => {
    setForm({ ...roleByData?.filterForm });
    setGetAllFilter("&approvalAction=true&status=Submitted");
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const viewAsPopup = (form) => {
    switch (params?.type) {
      case coalGrateTypeObj?.sampleDecoding:
        return <SampleDecodingView form={form} />;
      case coalGrateTypeObj?.refereeLabResult:
        return <RefereeLabResultView form={form} />;
      case coalGrateTypeObj?.thirdPartyLabResult:
        return <ThirdPartyLabResultView form={form} />;
      default:
        return "";
    }
  };

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} hideLeftRightBtn />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        {filterComponent(
          params?.type,
          form,
          onChange,
          onResetFilter,
          onSubmitFilter,
          open,
          setOpen
        )}
      </div>
    );
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "approvalAction=true&status=Submitted";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else if (key === "status") filter += `status=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }

    setGetAllFilter(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={roleByData?.tableColumn?.filter(
                  (field) => field.name !== "System Feedback"
                )}
                apis={coalGradeApis}
                title="Data"
                hideSnColumn
                downloadExcel={exportCoalGrade}
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                pageLink={roleByData?.pageLink}
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                getAllFilter={getAllFilter}
                hideAddButton
                breadCrumb={[
                  {
                    name: "Coal Grade",
                    path: "/",
                  },
                  {
                    name: "Coal Quality",
                    path: "/coal-grade/list",
                  },
                  {
                    name: `${converEachFirstWordCapitalize(params?.type, "-")}`,
                    path: `/coal-grade/${params?.type}/actions/list`,
                  },
                  {
                    name: "Pending Actions",
                    path: "#",
                  },
                ]}
                childrenAfterAddButton={tabButtons}
                // downloadExcel={exportFuelDispense}
                viewTitle={"Coal Grade/Quality"}
                isNoTabOnPage
                coalGradeType={params?.type}
                customTableTitle={"Blank"}
                showApproveButton
                showRejectButton
                hideThreeDotButton
                onApproveReject={onApproveReject}
                // statusByOptions={statusByOptions}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ApproveLabResultList;
