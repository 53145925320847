import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { responsiblePersonLabel } from "../../DATA";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import CustomRadio from "../../../../../components/_form/CustomRadio/CustomRadio";
import Select from "../../../../../components/Select/Select";
import FormInputText from "../../../../../components/FormInputText/FormInputText";
import AsterikOnly from "../../../../../components/Common/Asterik/AstrikOnly";

const ResponsiblePersonInfo = ({
  form = {},
  onChange,
  responsiblePersonForm,
  onChangeInformation,
  errors,
  addNewField,
  removeLastField,
  designations,
}) => {
  return (
    <div className={`FormWrapper ${form?.isCompensationInfor ? "mt-4" : ""}`}>
      <Form>
        <Row>
          <Col lg={6}>
            <CustomRadio
              type="checkbox"
              name="isResponsiblePersonInfo"
              onChange={onChange}
              title={"Responsibility for Accident"}
              acceptTitle={"Yes"}
              rejectTitle={"No"}
              customClass={"customRadioClass"}
              value={
                form.isResponsiblePersonInfo === null
                  ? false
                  : form.isResponsiblePersonInfo
              }
            />
          </Col>
        </Row>
        <Row>
          {form.isResponsiblePersonInfo && (
            <Col md="12">
              <h6>
                Responsibility for Accident
                <AsterikOnly />
              </h6>
              <div className="formWrapper rootCause">
                <div className="table-responsive">
                  <table
                    className="table table-bordered"
                    // style={{ minHeight: 350 }}
                  >
                    <thead className="tableHead-bg">
                      <tr>
                        <th style={{ minWidth: 180 }}>
                          {responsiblePersonLabel?.responsiblePerson}
                        </th>

                        <th style={{ minWidth: 100 }}>
                          {responsiblePersonLabel?.designationCode}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {responsiblePersonLabel?.designation}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {responsiblePersonLabel?.rulesConverted}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {responsiblePersonLabel?.natureOfContravention}
                        </th>
                        <th style={{ minWidth: 100 }}>
                          {responsiblePersonLabel?.actionTaken}
                        </th>
                        <th style={{ minWidth: 100 }}>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {responsiblePersonForm?.length ? (
                        responsiblePersonForm?.map((x, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="responsiblePerson"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.responsiblePerson}
                                  error={errors[index]?.responsiblePerson}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <Select
                                  name="designationCode"
                                  value={x?.designationCode}
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  options={designations}
                                  error={errors[index]?.designationCode}
                                  KEY_label={"code"}
                                  KEY_value={"code"}
                                ></Select>
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="designation"
                                  type="text"
                                  // onChange={({ target: { name, value } }) =>
                                  //   onChangeInformation(index, name, value)
                                  // }
                                  value={x.designation}
                                  error={errors[index]?.designation}
                                  disabled={true}
                                />
                              </td>

                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="rulesConverted"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.rulesConverted}
                                  error={errors[index]?.rulesConverted}
                                />
                              </td>

                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="natureOfContravention"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.natureOfContravention}
                                  error={errors[index]?.natureOfContravention}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <FormInputText
                                  name="actionTaken"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangeInformation(index, name, value)
                                  }
                                  value={x.actionTaken}
                                  error={errors[index]?.actionTaken}
                                />
                              </td>
                              <td style={{ borderRight: "3px solid #dee2e6" }}>
                                <div className="actionIconsGroup d-flex justify-content-center">
                                  <span
                                    onClick={() => removeLastField(index)}
                                    className="icons font-21 text-danger"
                                  >
                                    <AiOutlineMinusCircle
                                      size="24"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12" align="center"></td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan="12">
                          <span className="addTxt" onClick={addNewField}>
                            <AiOutlinePlus />
                            Add
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default ResponsiblePersonInfo;
