import { removeExtraKeys } from "../../_services/Auth/RequireAuth";

export const MODULE_URL_NAME = [
  "safety-management-system",
  "fuel-management-system",
];

export const FIND_DASBOARD_ROUTE = (routeObj) => {
  console.log("routeObjrouteObjrouteObj",routeObj);
  return routeObj?.firstPage
    ? removeExtraKeys(routeObj?.path) + routeObj?.firstPage
    : routeObj?.path;
};
