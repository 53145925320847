import React, { useMemo } from "react";
import { Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { getPermission } from "../../../_services/Auth/helper";
import { SidebarData } from "./Data";
import './HomeDashboard.scss'

const Sidebar = () => {
    const navigate = useNavigate();
    const url = useLocation();
    const userPermission = getPermission();

    const list = useMemo(() => {
        return SidebarData
            // eslint-disable-next-line array-callback-return
            ?.filter((obj) => {
                if (obj.link === "/") return true
                else if (obj?.permission?.find((role) => userPermission[role])) {
                    return true
                }
            }).map((row) => {
                if (row.link === url.pathname) return { ...row, active: 1 }
                else return row;
            })
    }, [url.pathname, userPermission]);

    return (
        <Nav className="nav-menu">
            <div className="divider"></div>
            <div className="nav-items">
                {list.map((row, key) => {
                    return (
                        <>
                            <Nav.Item key={row.id} className={row.active ? "active" : ""} onClick={() => { navigate(row.link) }} >
                                <Nav.Link eventKey={row.activeKey}>
                                    <img src={row.img} alt="linkImg1" />
                                    <span>{row.title} </span>
                                </Nav.Link>
                            </Nav.Item>
                        </>
                    )
                })}
            </div>
        </Nav >
    );
};

export default Sidebar