import { URL_CONFIG } from "../../../../_constants/Config/URL_CONFIG";
import Sidebar from "../Sidebar";
import SafetySideBar from "../../../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import {
  SidebarData,
  mobileFuelSidebarData,
} from "../../../../_pages/FuelManagementSystem/FuelManagement/DATA";
import { IS_MOBILE } from "../../../../_helpers/helper";
import { useNavigate } from "react-router-dom";
const FuelMgmtDashboard = () => {
  let navigate = useNavigate();
  return (
    <>
      <div
        className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
      >
        <SafetySideBar
          SidebarData={IS_MOBILE() ? mobileFuelSidebarData : SidebarData}
          // pageTitle={"Dashboard"}
          moduleTitle={"Fuel Management"}
          goBack={() => navigate(-1)}
        />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <iframe
              src={`${URL_CONFIG.AWS_QUICKSIGHT_URL}e74e8efc-fb50-44c5-b9d4-c79017eb9818?directory_alias=cil-dev-si-bi`}
              title="Loading..."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelMgmtDashboard;
