import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { IS_MOBILE, auth_user } from "../../_helpers/helper";
import { getPermission } from "../../_services/Auth/helper";
import {
  contractorManagementUrl,
  mobileSelectionFlowList,
  permissionWisePage,
  selectionFlowList,
} from "./DATA";
import {
  filterUrlFunc,
  fuelDashboardLink,
  fuelDashboardLinkMobile,
} from "../../_services/CommonService/CommonService";
import { SidebarData } from "../ContractorManagement/DATA";
import {
  SidebarData as fuelSidebarData,
  mobileFuelSidebarData,
} from "../../_pages/FuelManagementSystem/FuelManagement/DATA";
import {
  mobileSidebarData,
  SidebarData as safetySideBar,
} from "./../SafetyManagementSystem/IncidentManagement/DATA";
import { SidebarData as coalGradeSideBar } from "./../CoalGrade/DATA";
import { SidebarData as envSideBar } from "./../EnvironmentManagement/UploadedFiles/DATA";
import { useSelector } from "react-redux";
function SelectionFlowCard() {
  let userPermission = getPermission();
  const authUser = auth_user();

  const finalSelectionFlowList = useMemo(() => {
    const sideBarObject = IS_MOBILE()
      ? {
          contractorManagement: SidebarData,
          fuelManagement: mobileFuelSidebarData,
          safetyManagement: mobileSidebarData,
          coalManagement: coalGradeSideBar,
          envManagement: envSideBar,
          userManagement: "",
        }
      : {
          contractorManagement: SidebarData,
          fuelManagement: fuelSidebarData,
          safetyManagement: safetySideBar,
          coalManagement: coalGradeSideBar,
          envManagement: envSideBar,
          userManagement: "",
        };

    const finalFlowList = IS_MOBILE()
      ? mobileSelectionFlowList
      : selectionFlowList;
    let list = finalFlowList
      ?.map((k) => {
        if (k?.link === "/coal-grade/") {
          return { ...k, link: k?.link + permissionWisePage() };
        }
        // if (k?.link === "/contractor-management/") {
        //   return { ...k, link: k?.link + contractorManagementUrl() };
        // }
        return k;
      })
      ?.filter((obj) => {
        if (!obj?.role) return true;
        else {
          const isRole = !Array.isArray(obj?.role)
            ? obj?.role === authUser?.role?.roleLable
              ? true
              : false
            : obj?.role?.find((role) => userPermission[role]);
          return isRole ? true : false;
        }
      })
      ?.map((k) => {
        if (k.cardName === "userManagement") return k;
        const sideBarUrl =
          (IS_MOBILE()
            ? k.isMobileActiveLink && k.link
            : k.cardName &&
              (filterUrlFunc(sideBarObject[k.cardName])[0]?.link ?? "/")) ??
          "/";
        return {
          ...k,
          link: sideBarUrl,
        };
      });

    return list.filter((obj, index) => {
      if (obj.label === "Dashboard")
        return list.findIndex((item) => item.label === obj.label) === index;
      else return obj;
    });
  }, [authUser?.role?.roleLable, userPermission]);

  const storeFilter = useSelector((state) => state?.dashboardFilter?.fuelDash);

  let fuelDashboardUrl = fuelDashboardLink(storeFilter?.source);

  return (
    <>
      {finalSelectionFlowList?.map((d, idx) => {
        const { link } = d;
        return (
          <li key={idx}>
            {link === "/fuel-management-system/dashboard/1" ||
            link === "/fuel-management-system/sap-dashboard/1" ? (
              <Link to={fuelDashboardUrl}>
                <div className="selectionItemContent">
                  <div className="imgArea">
                    <img
                      src={d.img}
                      className="img-fluid"
                      alt="selectionIcon1"
                    />
                  </div>
                  <label>{d.label}</label>
                </div>
              </Link>
            ) : (
              <Link to={typeof link === "string" ? link : link()}>
                <div className="selectionItemContent">
                  <div className="imgArea">
                    <img
                      src={d.img}
                      className="img-fluid"
                      alt="selectionIcon1"
                    />
                  </div>
                  <label>{d.label}</label>
                </div>
              </Link>
            )}
          </li>
        );
      })}
    </>
  );
}

export default SelectionFlowCard;
