import React, { Fragment } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import mapIcon from "../../Assets/Images/mapImg.png";
const L = require("leaflet");

const MapComponet = ({ zoom = 10, className, data = [] }) => {
  const position = [24.1222222222222, 82.6845];
  const myIcon = L.icon({
    iconUrl: mapIcon,
    iconSize: [28, 28],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });
  const items = data?.map((d, i) => {
    return (
      <Marker key={i} position={d?.position} icon={myIcon}>
        <Popup>
          <div className="d-flex mb-1">
            <strong>Station Name:</strong>
            <span>{d?.stationName}</span>
          </div>
          <div className="d-flex mb-1">
            <strong>Station Code:</strong>
            <span>{d?.stationCode}</span>
          </div>
        </Popup>
      </Marker>
    );
  });
  return (
    <div className="cusReaflet-map">
      <MapContainer
        className={className}
        center={position}
        zoom={zoom}
        style={{ height: "300px", width: "100%" }}
        // scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Fragment>{items}</Fragment>
      </MapContainer>
    </div>
  );
};

export default MapComponet;
