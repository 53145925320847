import React from "react";
import { Button, Modal } from "react-bootstrap";
import reject from "../../Assets/Images/reject.svg";
const StatusPopup = ({
  show,
  setShow,
  closePopup,
  messagePopupType,
  invalidData = false,
  closeInvalidPopUp,
  invalidDate = false,
}) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} centered backdrop="static">
      <Modal.Header
        closeButton={messagePopupType ? true : false}
        className="border-bottom-0"
      ></Modal.Header>
      <Modal.Body className="p-5 text-center">
        {invalidData ? (
          <>
            <img src={reject} className="img-fluid" alt="reject" />
            {/* <h2 className="title mt-5">Invalid Date!</h2> */}
            <p className="pt-5">
              Selected {invalidDate ? "month" : "date"} is not within the
              contract period.
            </p>
            <Button
              className="primaryBtn mt-3"
              data-testid="close-invalid-popup"
              onClick={() => closeInvalidPopUp()}
            >
              Okay
            </Button>
          </>
        ) : !messagePopupType ? (
          <>
            {/* <img src={success_img} className="img-fluid" alt="successful" /> */}
            <h2 className="title mt-5">Data Saved Successfully!</h2>
            <Button
              className="primaryBtn mt-3"
              data-testid="close-message-popup"
              onClick={() => closePopup()}
            >
              Okay
            </Button>
          </>
        ) : (
          <>
            <h2 className="title">Close</h2>
            <p className="">Are you sure you want to close?</p>
            <Button
              className="secondaryBtn mt-3 me-3"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              className="primaryBtn  mt-3"
              data-testid="close-message-popup"
              onClick={() => closePopup()}
            >
              Yes
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default StatusPopup;
