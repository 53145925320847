import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Filter from "../../../../Assets/Images/filter.svg";
import { Link } from "react-router-dom";
// import Select from "../../../../components/Select/Select";
// import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import { Button, Col, Row } from "react-bootstrap";
import ScreenButton from "../../../../components/ScreenButton/ScreenButton";
import {
  IS_MOBILE,
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
} from "../../../../_helpers/helper";
import { SelectedDateLable } from "./DATA";
import { getArraayofKeys } from "../Helper";
import { incidentTypesObj } from "../DATA";
let Select;
let CustomDatePicker;
if (IS_MOBILE()) {
  Select =
    require("../../../../components/MobileComponents/Select/Select").default;
  CustomDatePicker =
    require("../../../../components/MobileComponents/DateTimeCustom/CustomDateTime").default;
} else {
  Select = require("../../../../components/Select/Select").default;
  CustomDatePicker =
    require("../../../../components/DateTimeCustom/CustomDateTime").default;
}

const DashboardFilter = ({
  title,
  screens,
  isActive,
  form,
  onChange,
  area,
  subsidery,
  sites,
  resetFun,
  applyFun,
  minMaxDate,
}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const applyFilter = () => {
    setShow(false);
    applyFun();
  };
  return (
    <>
      <div className="dashboardHeader">
        <h3 className="title mb-0">{title}</h3>
        {!IS_MOBILE() && (
          <SelectedDateLable form={form} minMaxDate={minMaxDate} />
        )}
        <ScreenButton
          isActive={isActive}
          handleShow={handleShow}
          screens={screens}
          title={title}
          safetyDashboard={true}
        />
      </div>
      {IS_MOBILE() && (
        <div className="d-flex justify-content-center">
          <SelectedDateLable form={form} minMaxDate={minMaxDate} />
        </div>
      )}

      <Modal
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="filterModal"
        show={show}
      >
        <Modal.Header closeButton className="px-3">
          <h5 className="modal-title">Filter</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <div className="form-group mb-3">
              <Select
                name="subsideryId"
                value={form?.subsideryId || ""}
                onChange={onChange}
                options={subsidery}
                label={"Subsidiary"}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                disabled={IS_USER_SITE_MINES() || IS_USER_SITE_RO()}
                menuPortalTarget={null}
              />
            </div>
            <div className="form-group mb-3">
              <Select
                label={"Mine"}
                name="siteId"
                value={form?.siteId || ""}
                disabled={IS_USER_SITE_MINES()}
                onChange={onChange}
                options={sites}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
              />
            </div>
            <div className="form-group mb-3">
              <Select
                name="category"
                value={form?.category}
                onChange={onChange}
                options={getArraayofKeys(incidentTypesObj)}
                object={incidentTypesObj}
                menuPortalTarget={null}
                label={"Incident/Accident Type"}
                blankSelect={"Select"}
              />
            </div>
            {/* <div className="form-group mb-3">
              <Select
                label={"Area"}
                name="areaId"
                value={form?.areaId || ""}
                onChange={onChange}
                options={area}
                // error={error?.areaId}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
              />
            </div> */}
            <Row>
              <label className="pb-2">Date Duration</label>
              <Col lg={12}>
                <CustomDatePicker
                  value={form?.startDate}
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  onChangeDate={onChange}
                  customClass={"modalInnerDatePicker"}
                  placeholder={"Start Date"}
                />
              </Col>
              <Col lg={12}>
                <CustomDatePicker
                  value={form?.endDate}
                  dateFormat="YYYY-MM-DD"
                  name="endDate"
                  onChangeDate={onChange}
                  // className={"cndrPosition"}
                  customClass={"modalInnerDatePicker"}
                  placeholder={"End Date"}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="filterBtn">
            <Button
              className="secondaryBtn"
              style={{ minWidth: "auto" }}
              onClick={() => resetFun()}
            >
              Reset
            </Button>
            <Button
              className="primaryBtn"
              data-testid="close-message-popup"
              style={{ minWidth: "auto" }}
              onClick={() => applyFilter()}
            >
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardFilter;
