import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import Select from "../../../../components/Select/Select";
import { userLabel } from "./DATA";
import {
  getAllSites,
  getAllRoles,
} from "../../../../_services/SiteServices/SiteServices";
import MultiSelectWithCheckbox from "../../../../components/SearchableSelect/MultiSelectCkeckOption";
import { ROLE_OBJECT_VIEW } from "../../../../_services/Auth/helper";
import FilterFormBtn from "../../../../components/Common/FilterFormBtn/FilterFormBtn";

const UserManagementFilterForm = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
  isUserType,
  noHO,
}) => {
  const [siteList, setSiteList] = useState([]);
  const [userType, setUserType] = useState([]);

  const allSites = async () => {
    const response = await getAllSites(
      "filter[siteType]=1,2,3&filter[activeStatus]=active"
    );
    if (!response?.status) return;
    setSiteList(response?.data);
  };

  const allRoles = async () => {
    const response = await getAllRoles();
    if (!response?.status) return;
    setUserType(response?.data);
  };

  useEffect(() => {
    allSites();
    allRoles();
  }, []);
  const permissionList = Object.keys(ROLE_OBJECT_VIEW).map((key) => ({
    label: ROLE_OBJECT_VIEW[key],
    value: key,
  }));
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        {/* <Form.Label>{"Date"}</Form.Label> */}
        <Row>
          <Col md={12}>
            <Select
              onChange={onChange}
              value={form?.siteId}
              name="siteId"
              options={
                noHO ? siteList.filter((d) => d.name !== "HO") : siteList
              }
              label={userLabel?.siteId}
              KEY_label={"name"}
              KEY_value={"id"}
            />
          </Col>
          {isUserType && (
            <Col md={12}>
              <Select
                onChange={onChange}
                value={form?.userType}
                name="userType"
                options={userType?.filter((d) => d?.id !== 1 && isUserType)}
                label={userLabel?.userType}
                KEY_label={"title"}
                KEY_value={"id"}
              />
            </Col>
          )}
          {/* <Col md={12}>
            <Form.Label>Permission</Form.Label>
            <MultiSelectWithCheckbox
              options={permissionList}
              value={form?.permissions
                ?.split(",")
                ?.map((role) => {
                  const found = permissionList.find(
                    (obj) => obj.value === role
                  );
                  return found ? { ...found } : null;
                })
                ?.filter((d) => !!d)}
              onChange={(selectedRoles) => {
                onChange({
                  target: {
                    name: "permissions",
                    value: selectedRoles?.map((obj) => obj.value).join(","),
                  },
                });
              }}
              // disabled={completionFlag || mmFlag}
              hasSelectAll={false}
              disableSearch={true}
            />
          </Col> */}
        </Row>
        <FilterFormBtn
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          setOpen={setOpen}
        />
      </div>
    </Collapse>
  );
};

export default UserManagementFilterForm;
