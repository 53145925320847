import React, { useMemo, useState, useCallback, useRef } from "react";
import { Container } from "react-bootstrap";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import {
  activityListApis,
  addLink,
} from "./DATA";
import { capitalizeName } from "../../../../_helpers/helper";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import ActivityView from "./ActivityView";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { SidebarData } from "../../IncidentManagement/DATA";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../../_services/CommonService/CommonService";

const ActivityList = () => {
  // let newCustomTabsData = customTabsData;
  let newAddLink = addLink;
  
  const [getAllFilter, setGetAllFilter] = useState();
  const [isListData, setIsListData] = useState(true);
  const cref = useRef(null);


  const onEdit = (id) => {
    navigate(`${newAddLink}/${id}`);
  };


  const navigate = useNavigate();



  const viewAsPopup = (form) => {
    return <ActivityView form={form} />;
  };

  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: `activity_master`,
        id: id,
        status: status
      }
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const columnFields = useMemo(
    () => [
      {
        selector: "name",
        name: "Name",
        sortable: true,
        // minWidth: "200px",
        cell: (row) => {
          return capitalizeName(`${row?.name ? row?.name : ""}`);
        },
      },
      {
        name: "Status",
        selector: "status",
        cell: (row) => {
          return (
            (row.status === 1 && (
              <span
                href="#"
                className="icons text-success pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOn
                  size="20"
                  data-testid={"toogle-user-list-on-" + row.id}
                />
              </span>
            )) ||
            (row.status === 0 && (
              <span
                href="#"
                className="icons text-danger pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOff
                  size="20"
                  data-testid={"toogle-user-list-off-" + row.id}
                />
              </span>
            ))
          );
        },
      },
    ],
    []
  );

  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  // hideSearch
                  fields={columnFields}
                  apis={activityListApis}
                  viewTitle="Activity"
                  editTitle="Edit Permissions"
                  customTableTitle="Activity"
                  // FilterBtn={newFilterBtn}
                  viewAsPopup={viewAsPopup}
                  // editAsPopup={editAsPopup}
                  hideSnColumn
                  pageLink={newAddLink}
                  primaryKey="id"
                  pagination
                  hideEditButton
                  getAllFilter={getAllFilter}
                  cref={cref}
                  deleteFun
                  // childrenAfterAddButton={tabButtons}
                  hideDeleteButton
                  breadCrumb={[
                    {
                      name: "Safety Management",
                      path: "#",
                    },
                    {
                      name: "Configuration",
                      path: "/safety-management-system/configuration",
                    },
                    {
                      name: "Activity",
                      path: "#",
                    },
                  ]}
                  isNoTabOnPage
                  title="Data"
                  editFunButton={onEdit}
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <EmptyDataList
                customTableTitle="Activity"
                breadCrumb={[
                  {
                    name: "Safety Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/safety-management-system/configuration",
                  },
                  {
                    name: "Activity",
                    path: "#",
                  },
                ]}
                pageLink={newAddLink}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ActivityList;
