import Modal from "react-bootstrap/Modal";
import Select from "../../../components/Select/Select";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { Button, Col, Row } from "react-bootstrap";
import { categoryType, wellList, yearsList } from "./DATA";
import { IS_USER_SITE_MINES, IS_USER_SITE_RO } from "../../../_helpers/helper";

const EnvDashboardFilter = ({
  form,
  onChange,
  subsidery,
  sites,
  resetFun,
  applyFun,
  categoryList,
}) => {
  return (
    <>
      <Modal.Body>
        <div className="p-3">
          {form.hasOwnProperty("category") && (
            <div className="form-group mb-3">
              <Select
                label={"Category"}
                name="category"
                value={form?.category || ""}
                onChange={onChange}
                options={categoryList}
                blankSelect={"Select"}
                menuPortalTarget={null}
                KEY_label={"category"}
                KEY_value={"category"}
              />
            </div>
          )}
          {form.hasOwnProperty("well") && (
            <div className="form-group mb-3">
              <Select
                label={"Well Category"}
                name="well"
                value={form?.well || ""}
                onChange={onChange}
                options={wellList}
                blankSelect={"Select"}
                menuPortalTarget={null}
              />
            </div>
          )}
          {form.hasOwnProperty("subsideryId") && (
            <div className="form-group mb-3">
              <Select
                label={"Subsidiary"}
                name="subsideryId"
                value={form?.subsideryId || ""}
                onChange={onChange}
                options={subsidery}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
                disabled={IS_USER_SITE_MINES() || IS_USER_SITE_RO()}
              />
            </div>
          )}
          {form.hasOwnProperty("siteId") && (
            <div className="form-group mb-3">
              <Select
                label={"Mine"}
                name="siteId"
                value={form?.siteId || ""}
                onChange={onChange}
                options={sites}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
                disabled={IS_USER_SITE_MINES()}
              />
            </div>
          )}
          {form.hasOwnProperty("year") && (
            <div className="form-group mb-3">
              <Select
                label={"Year"}
                name="year"
                value={form?.year || ""}
                onChange={onChange}
                options={yearsList()}
                blankSelect={"Select"}
                menuPortalTarget={null}
              />
            </div>
          )}
          {form.hasOwnProperty("startDate") && (
            <Row>
              <label className="pb-2">Date Duration</label>
              <Col lg={12}>
                <CustomDatePicker
                  value={form?.startDate}
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  onChangeDate={onChange}
                  placeholder={"Start Date"}
                />
              </Col>
              <Col lg={12}>
                <CustomDatePicker
                  value={form?.endDate}
                  dateFormat="YYYY-MM-DD"
                  name="endDate"
                  onChangeDate={onChange}
                  // className={"cndrPosition"}
                  placeholder={"End Date"}
                />
              </Col>
            </Row>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="filterBtn">
          <Button
            className="secondaryBtn"
            style={{ minWidth: "auto" }}
            data-testid="reset-btn"
            onClick={() => resetFun()}
          >
            Reset
          </Button>
          <Button
            className="primaryBtn"
            data-testid="close-message-popup"
            style={{ minWidth: "auto" }}
            onClick={() => applyFun()}
          >
            Apply
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EnvDashboardFilter;
