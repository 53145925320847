import { USER_DATA_CONSTANT } from "../../_constants/Redux/userData.constant";
import { createBrowserHistory } from "history";
import jwtAuthService from "../../services/jwtAuthService";
import localStorageService from "../../services/localStorageService";
import {
  CONTRACTOR_DASHBOARD_FILTER,
  FUEL_DASHBOARD_FILTER,
} from "../reducers/CONSTANT";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const ADD_SITE_IDS = "ADD_SITE_IDS";
export const FILTER_STATUS_ADD = "FILTER_STATUS_ADD";
export const ADD_FUEL_DISPENSE = "ADD_FUEL_DISPENSE";
export const CLEAR_FUEL_DISPENSE_DATA = "CLEAR_FUEL_DISPENSE_DATA";

export function setUserData(user) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export function setFuelDispense(data) {
  localStorageService.setItem("storeFuelDispense", data);
}

export function setContractorDashFilter(data) {
  return (dispatch) => {
    dispatch({
      type: CONTRACTOR_DASHBOARD_FILTER,
      data: data,
    });
  };
}

export function setFuelDashFilter(data) {
  return (dispatch) => {
    dispatch({
      type: FUEL_DASHBOARD_FILTER,
      data: data,
    });
  };
}
