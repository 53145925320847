import React, { useEffect, useState } from "react";
import {
  initialForm,
  validationRules,
  supplierApi,
  supplierLabel,
} from "./DATA";
import { Col, Form, Row, Button } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { useNavigate, useParams } from "react-router-dom";
import { SidebarData } from "../../FuelManagement/DATA";
import { useForm } from "../../../../_helpers/hooks";
import { Validation } from "../../../../_helpers/validation/Validation";
import {
  updateSupplier,
  addSupplier,
} from "../../../../services/_fuelDispense/fuelDispense";
import toastr from "toastr";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";

const SupplierAdd = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form, onChange, setForm] = useForm(initialForm);
  const [error, setError] = useState({
    ...initialForm,
  });
  const onSubmit = async () => {
    const errorObj = Validation(form, validationRules);

    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }
    if (form.supplierCode.length > 8) {
      toastr.error("Supplier short code can't be more than 8 character");
      return;
    }

    let response = params?.id
      ? await updateSupplier(form, params.id)
      : await addSupplier(form);

    if (!response?.status) {
      return toastr.error(response?.message || "something went wrong");
    }
    if (response?.status) toastr.success(`Saved Successfully`);
    navigate("/fuel-management-system/configuration/supplier");
  };

  async function fetchData() {
    let response = await supplierApi.getById.api(params?.id);
    if (response?.status) {
      setForm(() => {
        return {
          supplierCode: response?.data?.supplierCode,
          supplierName: response?.data?.supplierName,
        };
      });
    }
  }

  useEffect(() => {
    if (params.id) fetchData();
  }, [params.id, setForm]);

  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    path: "/",
                    name: "Fuel Management",
                  },
                  {
                    name: "Configuration",
                    path: "/fuel-management-system/configuration",
                  },
                  {
                    path: "#",
                    name: "Add Supplier",
                  },
                ]}
              />
            </h3>
            <div className="reportCard">
              <div className="formWrapper">
                <div className="maverickFormCard">
                  <div className="creationWrapper">
                    <Form>
                      <Row>
                        <Col lg={6}>
                          <FormInputText
                            name="supplierName"
                            value={form.supplierName}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={supplierLabel.supplierName}
                            error={error?.supplierName}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="supplierCode"
                            value={form.supplierCode}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={supplierLabel.supplierCode}
                            error={error?.supplierCode}
                          />
                        </Col>
                      </Row>
                    </Form>
                    <div className="btnSec">
                      <Button
                        data-testid="next-btn"
                        onClick={() =>
                          navigate(
                            `/fuel-management-system/configuration/supplier`
                          )
                        }
                        className="secondaryBtn iconBtnForm"
                      >
                        Back
                      </Button>
                      <Button
                        data-testid="next-btn-submit"
                        onClick={() => onSubmit()}
                        className="primaryBtn iconBtnForm"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierAdd;
