import React, { useMemo, useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../Assets/Images/filter.svg";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { useForm } from "../../_helpers/hooks";
import UserManagementFilterForm from "./UserManagementFilterForm";
import {
  userFilterForm,
  userListApisContractorStaff,
  customTabsData,
  userLabel,
  confAddLink,
} from "./DATA";
import EmptyDataList from "../../components/EmptyDataList/EmptyDataList";
import {
  capitalizeName,
  findUserPermissionedTabList,
} from "../../_helpers/helper";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import UserManagementView from "./UserManagementView";
import PermissionsFormEdit from "./PermissionsFormEdit";
import {
  ROLE_OBJECT_VIEW,
  isContractorModuleAdmin,
} from "../../_services/Auth/helper";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../ContractorManagement/DATA";

const ContractorConUserManagement = () => {
  let newCustomTabsData = customTabsData;
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(userFilterForm);
  const [customTabList, setCustomTabList] = useState(
    findUserPermissionedTabList(newCustomTabsData)
  );

  let newAddLink = confAddLink;

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          // rootClose
          className="secondaryBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>

        <UserManagementFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          setOpen={setOpen}
          isOpen={open}
        />
      </div>
    );
  };

  let newFilterBtn = FilterBtn;
  if (isContractorModuleAdmin()) {
    // console.log("hello");
    newCustomTabsData = customTabsData.filter((d, i) => {
      if (d.label === "User List") {
        return false;
      }
      return true;
    });
    newAddLink = "/contractor-management/configuration/contractor-users";
    newFilterBtn = null;
  }

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getAll = async () => {
    const response = await userListApisContractorStaff.getAll.api(`page=1&limit=10&contractorUser=true`);
    if (Array.isArray(response?.data) && response?.data?.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const onEdit = (id) => {
    navigate(`${newAddLink}/${id}`);
  };

  useEffect(() => {
    setCustomTabList((prev) => {
      return prev.map((d) => {
        if (d.to === pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
  }, [pathname]);

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const onResetFilter = () => {
    setForm({ ...userFilterForm });
    setGetAllFilter();
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];

      console.log("element" , element);

      if (element) {
        filter += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const viewAsPopup = (form) => {
    return <UserManagementView form={form} />;
  };

  const editAsPopup = (form, setShowAddEdit, getAll) => {
    return (
      <PermissionsFormEdit
        formData={form}
        setShowAddEdit={setShowAddEdit}
        getAll={getAll}
      />
    );
  };

  const columnFields = useMemo(
    () => [
      {
        minWidth: "200px",
        selector: "firstName",
        name: userLabel?.name,
        sortable: true,
        cell: (row) => {
          return capitalizeName(
            `${row?.firstName ? row?.firstName : ""} ${
              row?.lastName ? row?.lastName : ""
            }`
          );
        },
      },
      {
        cell: (row) => {
          return row?.role?.title || "";
        },
        name: userLabel?.roleId,
        selector: "roleId",
        sortable: true,
      },
      {
        name: "Mine",
        sortable: true,
        cell: (row) => {
          return row?.site?.name || "";
        },
        selector: "siteId",
      },
      {
        name: userLabel?.email,
        sortable: true,
        selector: "email",
      },
      {
        // sortable: true,
        name: "Permissions",
        selector: "accessPermissions",
        minWidth: "200px",
        cell: (row) => {
          let role = row.accessPermissions?.map(
            (d) => ROLE_OBJECT_VIEW[d.permission]
          );
          let roleStr;
          roleStr = role?.join(",").substring(0, 20);
          if (roleStr.length > 19) {
            roleStr = roleStr + "...";
          }
          return roleStr;
        },
      },
    ],
    []
  );

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            {/* <h4>Contractor Performance</h4> */}
            {/* <CustomTabs tabeList={customTabs} /> */}
            <div className="customDataTbl">
              <DataTableWithServer
                fields={columnFields}
                apis={userListApisContractorStaff}
                title={"User"}
                viewTitle="User Details"
                editTitle="Edit Permissions"
                customTableTitle="Contractor User List"
                FilterBtn={newFilterBtn}
                viewAsPopup={viewAsPopup}
                editAsPopup={editAsPopup}
                hideSnColumn
                pageLink={newAddLink}
                primaryKey="id"
                getAllFilter={getAllFilter}
                // childrenAfterAddButton={tabButtons}
                editFunButton={onEdit}
                breadCrumb={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/contractor-management/configuration",
                  },
                  {
                    name: "Contractor User List",
                    path: "#",
                  },
                ]}
                isNoTabOnPage
                pagination
                hideDeleteButton
                hideEditButton
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              pageLink={newAddLink}
              title={"User Management"}
              breadCrumb={[
                {
                  name: "Contractor Management",
                  path: "#",
                },
                {
                  name: "Configuration",
                  path: "/contractor-management/configuration",
                },
                {
                  name: "Contractor User List",
                  path: "#",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorConUserManagement;
