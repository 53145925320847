import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  navigateUrl,
  StatusUpdateRules,
  initialCapa,
  capaPriority,
  capaRules,
  startCloseDateTimeLabel,
  checkPreviousDateKey,
} from "./DATA";
import toastr from "toastr";
import { useForm } from "../../_helpers/hooks";
import { Validation } from "../../_helpers/validation/Validation";
import {
  changeCapaStatus,
  changeIncidentStatus,
  getCapaById,
} from "../../services/_safetyManagement/safetyManagement";
import { DDMMYYYYFormat, DDMMYYYYHHMMAFormat } from "../../_helpers/dateHelper";
import { Button, Col, Row } from "react-bootstrap";

import SafetySideBar from "./SafetySideBar/SafetySideBar";
import { SidebarData, initialEvidence } from "./IncidentManagement/DATA";
import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";

import moment from "moment";
import IncidentEvidence from "./IncidentManagement/components/IncidentEvidence";
import CustomRadio from "../../components/_form/CustomRadio/CustomRadio";
import EvidencePreview from "./IncidentManagement/components/EvidencePreview";
import MultipleFileUpload from "../../components/FileUpload/MultipleFileUpload";
import { IS_MOBILE } from "../../_helpers/helper";
import AsterikOnly from "../../components/Common/Asterik/AstrikOnly";

let CustomDatePicker;
if (IS_MOBILE()) {
  CustomDatePicker =
    require("../../components/MobileComponents/DateTimeCustom/CustomDateTime").default;
} else {
  CustomDatePicker =
    require("../../components/DateTimeCustom/CustomDateTime").default;
}

const CapaEdit = () => {
  const params = useParams("id");
  let navigate = useNavigate();
  const [form, onChange, setForm] = useForm({
    ...initialCapa,
  });
  const [error, setError] = useState({});
  const [completed, setCompleted] = useState(false);
  const [evidences, setEvidences] = useState(
    JSON.parse(JSON.stringify(initialEvidence))
  );

  const navigator = useNavigate();

  useEffect(() => {
    (async () => {
      let response = await getCapaById(params?.id);
      setForm((prev) => {
        return { ...prev, ...response };
      });
    })();
  }, [params?.id]);

  const handleSubmit = async () => {
    let errorObj = Validation(form, capaRules);
    // console.log("errorObj", errorObj);
    // console.log("form", form);
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    // let errorCount = 0;
    // if (Object.values(errorObj).filter((d) => d !== "").length) return;
    // if (errorCount === 1) return;
    let data = {
      ...form,
      capaStatus: completed ? "completed" : form.capaStatus,
    };
    // console.log("hell");
    let response = await changeCapaStatus(data);
    // console.log("resssponse" , response);
    if (!response?.status) return toastr.error(response?.message);
    toastr.success(response?.message || `Updated`);
    navigator(
      `${
        IS_MOBILE() ? "/mobile" : ""
      }/safety-management-system/incident-management/incident-capa`
    );
  };

  const onFileChange = (_event, responseData) => {
    setForm((prev) => ({
      ...prev,
      completionProofId: responseData.id,
      completionProof: responseData,
    }));
  };
  const deleteDocumentFile = (_event, _index) => {
    setForm((prev) => ({
      ...prev,
      completionProofId: null,
      completionProof: null,
    }));
  };
  const handleCheckbox = ({ target: { name, checked } }) => {
    setCompleted(checked);
  };

  return (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={"CAPA Edit"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            {!IS_MOBILE() && (
              <h3
                className=""
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    { name: "Safety Management", path: "#" },
                    {
                      name: "Incident Management",
                      path: "/safety-management-system/incident-management",
                    },
                    {
                      name: "My Approval Actions",
                      path: "/safety-management-system/incident-management/incident-reported",
                    },
                    { name: "Edit", path: "#" },
                  ]}
                  // module={ACTIVE_MODULE.incident}
                />
              </h3>
            )}
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="formWrapper">
                  <div className="maverickFormCard">
                    <div className="FormWrapper">
                      <Row className="safetyEdit">
                        <Col md={4}>
                          <label>Incident ID</label>
                          <p className="content">
                            {form?.incidentData?.incident_uid}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>CAPA ID</label>
                          <p className="content">{form?.capaId}</p>
                        </Col>
                        <Col md={4}>
                          <label>CAPA Type</label>
                          <p className="content">{form?.capaType}</p>
                        </Col>
                        <Col md={4}>
                          <label>CAPA Status</label>
                          <p className="content">{form?.capaStatus}</p>
                        </Col>
                        <Col md={4}>
                          <label>CAPA Priority</label>
                          <p className="content">
                            {capaPriority[form?.priority]}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>CAPA Owner</label>
                          <p className="content">
                            {form?.owner?.firstName +
                              " " +
                              form?.owner?.lastName}
                          </p>
                        </Col>

                        <Col md={4}>
                          <label>Due Date</label>
                          <p className="content">
                            {DDMMYYYYFormat(form?.dueDate)}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>CAPA Description</label>
                          <p className="content">{form?.remarks}</p>
                        </Col>
                        <Col md={4}>
                          <label>Created Date</label>
                          <p className="content">
                            {DDMMYYYYFormat(form?.createdAt)}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>Subsidiary</label>
                          <p className="content">
                            {form?.site?.parentSite?.name}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>Mine</label>
                          <p className="content">{form?.site?.name}</p>
                        </Col>
                        <Col md={4}>
                          <label>
                            {
                              startCloseDateTimeLabel[
                                form?.incidentData?.status
                              ]
                            }
                          </label>
                          <p className="content">
                            {DDMMYYYYHHMMAFormat(
                              form?.incidentData?.capa_start_time
                            )}
                          </p>
                        </Col>
                        {form?.incidentData?.dbms_id ? (
                          <Col md={4}>
                            <label>DGMS ID</label>
                            <p className="content">
                              {form?.incidentData?.dbms_id}
                            </p>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <div className="form-group viewbox">
                            <h5 className="w-500 mb-1">Completed</h5>
                            <div className="" style={{ display: "flex" }}>
                              <Col lg={4} style={{ display: "flex" }}>
                                <CustomRadio
                                  type="checkbox"
                                  name="isPersonalInjury"
                                  onChange={handleCheckbox}
                                  acceptTitle={"Yes"}
                                  rejectTitle={"No"}
                                  customClass={"customRadioClass"}
                                  value={completed}
                                />
                              </Col>
                            </div>

                            {/* <span className="small text-danger">{errorForm.capaStatus}</span> */}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="form-group viewbox">
                            <h5 className="w-500 mb-1">Completion Proof</h5>
                            <MultipleFileUpload
                              source={"Incident Capa"}
                              uploadLimit={1}
                              isLabel={false}
                              clsNameCustom={"col-md-12 pl-0"}
                              labelName={"Document"}
                              onFileChange={onFileChange}
                              alias={form.completionProof?.alias || ""}
                              isDisable={!completed}
                              deleteDocumentFile={deleteDocumentFile}
                              selectedFileArray={
                                form.completionProof
                                  ? [form.completionProof]
                                  : []
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="form-group viewbox">
                            <h5 className="w-500 mb-1">
                              Completion Date
                              <AsterikOnly />
                            </h5>
                            <CustomDatePicker
                              isValidDate={(day) =>
                                day.isBefore(moment()) &&
                                day.isAfter(
                                  moment(
                                    form?.incidentData?.capa_start_time
                                  ).add(-1, "days")
                                )
                              }
                              value={
                                form.completionDate ? form.completionDate : ""
                              }
                              name="completionDate"
                              onChange={onChange}
                              dateFormat="YYYY-MM-DD"
                              disabled={!completed}
                              error={error?.completionDate}
                            />
                            {/* <span className="small text-danger">{errorForm.completionDate}</span> */}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="form-group viewbox">
                            <h5 className="w-500 mb-1">
                              Describe Actions Taken or Resolution Provided
                              <AsterikOnly />
                            </h5>
                            <textarea
                              class="form-control h-120"
                              value={form.actionsOrResolutions}
                              name="actionsOrResolutions"
                              onChange={onChange}
                            />
                            <span className="small text-danger">
                              {error?.actionsOrResolutions}
                            </span>
                          </div>
                        </Col>
                      </Row>

                      <div className="btnSec  justify-content-end">
                        <Button
                          onClick={handleSubmit}
                          data-testid="capa-submit"
                          className="primaryBtn"
                        >
                          Complete CAPA
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapaEdit;
