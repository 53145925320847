import React, { useState, useEffect } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { SidebarData, WellListApi, listColumnFields } from "./DATA";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import { tabUrl } from "../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import EmptyDataList from "../../ContractorManagement/ContractorPerformance/EmptyDataList";
import { getAllWell } from "./DATA";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
const MasterGroundWaterWellList = () => {
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.envConfig)
  );
  let navigate = useNavigate();

  const onEdit = (id) => {
    navigate("/configuration/environment/ground-water/well/" + id);
  };

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} />;
  };

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    getAll();
  }, []);

  const getAll = async () => {
    const response = await getAllWell();
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={listColumnFields}
                apis={WellListApi}
                title="Data"
                // viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/configuration/environment/ground-water/well"}
                primaryKey="id"
                pagination
                hideDeleteButton
                breadCrumb={[
                  {
                    name: "Environment Management System",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/environment-management/configuration",
                  },
                  {
                    name: "Ground Water Well Data lists",
                    path: "#",
                  },
                ]}
                hideEditButton
                // childrenAfterAddButton={tabButtons}
                // downloadExcel={exportContractorPerformance}
                //   hideAddButton={!permissionCheck(["contractor_module_admin"])}
                listname={"stationcategory"}
                customTableTitle={"Ground Water Well Data lists"}
                //   viewTitle={"Contractor Performance Details"}
                hideSearch={true}
                isNoTabOnPage
                editFunButton={onEdit}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            pageLink={"/configuration/environment/ground-water/well"}
            // tabButtons={tabButtons}
            title={"Ground Water Well"}
            //   hideAddBtn={!permissionCheck(["contractor_module_admin"])}
            breadCrumb={[
              {
                name: "Environment Management System",
                path: "#",
              },
              {
                path: "/environment-management/configuration",
                name: "Configuration",
              },
              {
                name: "Ground Water Well Data lists",
                path: "#",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default MasterGroundWaterWellList;
