import SideBarComponent from "../../../components/Common/SideBarComponent";

const SafetySideBar = ({ SidebarData, pageTitle, goBack, moduleTitle }) => {
  return (
    <SideBarComponent
      SidebarData={SidebarData}
      pageTitle={pageTitle}
      goBack={goBack}
      moduleTitle={moduleTitle}
    />
  );
};

export default SafetySideBar;
