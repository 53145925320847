import { GiGolfFlag } from "react-icons/gi";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { submitStatus, modeOfDispatchLabel } from "../DATA";

export const thirdParyLabLabel = {
  site_id: "Site ID",
  date_of_sample_sent_to_lab: "Date of sample sent to lab",
  code_given_to_sample: "Code given to sample",
  sample_coding_done_by: "Sample coding done by",
  date_of_sample_receipt_in_lab: "Date of sample receipt in lab",
  date_given_for_test: "Date given for test",
  date_of_test_result_recieved: "Date of test result recieved",
  sample_sender_name: "Sample sender name",
  decoded_by: "Decoded By",
  id: "ID",
  sample_id: "Sample ID",
  mode_of_dispatch: "Mode of Dispatch",
  rr_or_do_date: "Collection Date",
  rr_or_do_number: "Delivery No",
  declared_grade: "Declared Grade",
  lab_result_checked_by: "Checked by",
  lab_result_tested_by: "Tested by",
  result_reporting_to_customer: "Result reporting date to customer",
  lab_on_air_dry_bases_moisture: "Moisture",
  lab_on_em_bases_ash: "Ash",
  lab_on_em_bases_vm: "VM",
  lab_on_em_bases_gcv: "GCV",
  lab_analyzed_grade: "Lab analyzed grade",
  name_of_referee_agency: "Name of referee agency",
  referee_on_air_dry_bases_moisture: "Moisture",
  referee_on_air_dry_bases_ash: "Ash",
  referee_on_air_dry_bases_vm: "VM",
  referee_on_air_dry_bases_gcv: "GCV",
  lab_on_air_dry_bases_ash: "Ash",
  lab_on_air_dry_bases_vm: "VM",
  lab_on_air_dry_bases_gcv: "GCV",
  lab_on_em_bases_moisture: "Moisture",
  referee_on_em_bases_moisture: "Moisture",
  referee_on_em_bases_ash: "Ash",
  referee_on_em_bases_vm: "VM",
  referee_on_em_bases_gcv: "GCV",
  referee_total_moisture: "Total Moisture",
  referee_analyzed_grade: "Referee Lab analyzed grade",
  name_of_sampling_agency: "Name of sampling agency",
  third_party_on_air_dry_bases_moisture: "Moisture",
  third_party_on_air_dry_bases_ash: "Ash",
  third_party_on_air_dry_bases_vm: "VM",
  third_party_on_air_dry_bases_gcv: "GCV",
  third_party_on_em_bases_moisture: "Moisture",
  third_party_on_em_bases_ash: "Ash",
  third_party_analyzed_grade: "Third party lab analyzed grade",
  third_party_on_em_bases_vm: "VM",
  third_party_on_em_bases_gcv: "GCV",
  third_party_total_moisture: "Total moisture",
  third_party_date_of_result: "Date of the result",
};

export const CoalDispatchRelatedRules = [
  {
    label: thirdParyLabLabel?.mode_of_dispatch,
    field_name: "mode_of_dispatch",
    isRequired: true,
    type: "string",
  },
  {
    label: thirdParyLabLabel?.rr_or_do_date,
    field_name: "rr_or_do_date",
    isRequired: true,
    type: "string",
  },
  {
    label: thirdParyLabLabel?.rr_or_do_number,
    field_name: "rr_or_do_number",
    isRequired: true,
    type: "number",
  },
  {
    label: thirdParyLabLabel?.declared_grade,
    field_name: "declared_grade",
    isRequired: true,
    type: "string",
  },
  {
    label: thirdParyLabLabel?.name_of_sampling_agency,
    field_name: "name_of_sampling_agency",
    isRequired: true,
    type: "string",
  },
  {
    label: thirdParyLabLabel?.third_party_date_of_result,
    field_name: "third_party_date_of_result",
    isRequired: true,
    type: "string",
  },
];

export const ThirdPartyRules = [
  {
    label: thirdParyLabLabel?.third_party_total_moisture,
    field_name: "third_party_total_moisture",
    isRequired: true,
    type: "number",
    maxNumber: 100,
    minNumber: 0,
  },
  // {
  //   label: thirdParyLabLabel?.third_party_on_air_dry_bases_moisture,
  //   field_name: "third_party_on_air_dry_bases_moisture",
  //   isRequired: true,
  //   type: "number",
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  // {
  //   label: thirdParyLabLabel?.third_party_on_air_dry_bases_ash,
  //   field_name: "third_party_on_air_dry_bases_ash",
  //   isRequired: true,
  //   type: "number",
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  // {
  //   label: thirdParyLabLabel?.third_party_on_air_dry_bases_vm,
  //   field_name: "third_party_on_air_dry_bases_vm",
  //   isRequired: true,
  //   type: "number",
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  // {
  //   label: thirdParyLabLabel?.third_party_on_air_dry_bases_gcv,
  //   field_name: "third_party_on_air_dry_bases_gcv",
  //   isRequired: true,
  //   type: "number",
  //   minNumber: 0,
  //   maxNumber: 100,
  // },
  {
    label: thirdParyLabLabel?.third_party_on_em_bases_moisture,
    field_name: "third_party_on_em_bases_moisture",
    isRequired: true,
    type: "number",
    // maxNumber: 100,
    minNumber: 0,
  },
  {
    label: thirdParyLabLabel?.third_party_on_em_bases_ash,
    field_name: "third_party_on_em_bases_ash",
    isRequired: true,
    type: "number",
    // maxNumber: 100,
    minNumber: 0,
  },
  // {
  //   label: thirdParyLabLabel?.third_party_on_em_bases_vm,
  //   field_name: "third_party_on_em_bases_vm",
  //   isRequired: true,
  //   type: "number",
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  {
    label: thirdParyLabLabel?.third_party_on_em_bases_gcv,
    field_name: "third_party_on_em_bases_gcv",
    // maxNumber: 100,
    minNumber: 0,
    isRequired: true,
    type: "number",
  },
  {
    label: thirdParyLabLabel?.third_party_analyzed_grade,
    field_name: "third_party_analyzed_grade",
    isRequired: true,
    type: "string",
  },
  {
    label: thirdParyLabLabel?.third_party_date_of_result,
    field_name: "third_party_date_of_result",
    isRequired: true,
    type: "string",
  },
];

export const ThirdPartyInitialForm = {
  mode_of_dispatch: "",
  rr_or_do_number: "",
  rr_or_do_date: "",
  declared_grade: "",
  name_of_sampling_agency: "",
  third_party_on_air_dry_bases_moisture: "",
  third_party_on_air_dry_bases_ash: "",
  third_party_on_air_dry_bases_vm: "",
  third_party_on_air_dry_bases_gcv: "",
  third_party_on_em_bases_moisture: "",
  third_party_on_em_bases_ash: "",
  third_party_on_em_bases_vm: "",
  third_party_on_em_bases_gcv: "",
  third_party_total_moisture: "",
  third_party_analyzed_grade: "",
  third_party_date_of_result: "",
};

export const thirdPartyLabColumnFields = [
  {
    name: "Sample ID",
    selector: "sample_id",
    sortable: true,
  },
  {
    selector: "mode_of_dispatch",
    sortable: true,
    isSearchable: true,
    name: "Mode of Dispatch",
    cell: (row) =>
      row?.mode_of_dispatch ? modeOfDispatchLabel[row?.mode_of_dispatch] : "",
  },
  {
    name: "Delivery No",
    selector: "rr_or_do_number",
    sortable: true,
    isSearchable: true,
  },
  {
    name: "Collection Date",
    selector: "rr_or_do_date",
    sortable: true,
    cell: (row) =>
      row?.rr_or_do_date ? DDMMYYYYFormat(row?.rr_or_do_date) : "",
  },
  {
    name: "Declared Grade",
    selector: "declared_grade",
    sortable: true,
    isSearchable: true,
  },
  {
    selector: "name",
    sortable: true,
    name: "Name of Sampling agency",
    cell: (row) => row?.sampling_agency?.name,
  },
  {
    name: "Status",
    // selector: "third_party_approval",
    // sortable: true,
    minWidth: "160px",
    cell: (row) => {
      if (row?.third_party_approval === "Pending") {
        if (row?.third_party_status === submitStatus?.submitted) {
          return (
            <span className="statusSec submit">{submitStatus?.submitted}</span>
          );
        } else {
          return (
            <span className="statusSec draft">{submitStatus?.drafted}</span>
          );
        }
      } else {
        return (
          <span className="statusSec draft">{row?.third_party_approval}</span>
        );
      }
    },
  },
  {
    name: "Referee Action",
    selector: (row) =>
      row?.referee_lab_required === "1" ? (
        row?.referee_lab_status === "Submitted" ? (
          <GiGolfFlag className="feedbackIcon green" />
        ) : (
          <GiGolfFlag className="feedbackIcon red" />
        )
      ) : (
        ""
      ),
    center: true,
  },
];

export const thirdPartyLabFilterForm = {
  startDate: "",
  endDate: "",
  mode_of_dispatch: "",
};

export const thirdPartyLabData = {
  tableColumn: thirdPartyLabColumnFields,
  filterForm: thirdPartyLabFilterForm,
  pageLink: "/coal-grade/third-party-lab-result",
  tableTittle: "Third Party Lab Results Register",
  type: "third-party-lab-result",
  role: ["area_quality_manager"],
};
