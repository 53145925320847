import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

export const getAllNitList = async (filter) => {
  let filter_key = filter ? filter : "";
  let url = "/contractor-nit?" + filter_key;

  try {
    const response = await RestMethod.GET(url);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteNit = async (id) => {
  let url = "/contractor-nit/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const createNit = async (data) => {
  let url = "/contractor-nit/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateNit = async (data, id) => {
  let url = "/contractor-nit/update/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
