import {
  deleteMasterDropdown,
  getAllMasterDropdownSamplingAgency,
} from "../../../../services/_reasonList/reasonList";

export const reasonListColumnFields = [
  {
    name: "Name",
    selector: "name",
    // sortable: true,
    cell: (row) => row?.name,
  },
];

export const reasonListApis = Object.freeze({
  getAll: {
    api: getAllMasterDropdownSamplingAgency,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});
