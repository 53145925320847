import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { contractorScoreFlowList } from "./DATA";
import { SidebarData } from "../../../_pages/ContractorManagement/DATA";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import "./IncidentMgmt.scss";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { filterUrlFunc } from "../../../_services/CommonService/CommonService";
const ContractorScoreManagement = () => {
  let authUser = localStorage.getItem("auth_user");
  let user = authUser ? JSON.parse(authUser) : "";

  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <h3
                className="title"
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Contractor Management",
                      path: "/safety-management-system/incident-management",
                    },
                    {
                      name: "Contractor score card",
                      path: "#",
                    },
                  ]}
                // module={ACTIVE_MODULE.incident}
                />
              </h3>
              <div className="MgmtWrapper pt-4">
                <Row className="dashboardItem">
                  {filterUrlFunc(contractorScoreFlowList)?.map((d, idx) => {
                    return (
                      <Col lg={4} key={idx} className="mb-4">
                          <Link to={d?.link}>
                            <div className="dashboardItemContent">
                              <div className="imgArea">
                                <img
                                  src={d.img}
                                  className="img-fluid"
                                  alt="dashboardItemIcons"
                                />
                              </div>
                              <label>{d.label}</label>
                            </div>
                          </Link>
                        {/* )} */}
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorScoreManagement;
