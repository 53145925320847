import React from "react";
import { SidebarData } from "./DATA";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import Select from "../../components/Select/Select";
import { useState, useEffect } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import FormInputText from "../../components/FormInputText/FormInputText";

import toastr from "toastr";
// import { uploadFile } from '../../services/_uploadedFiles/uploadedFiles';
import { uploadFileOnS3 } from "../../services/S3Service";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import { useNavigate, useParams } from "react-router-dom";
import { CommmonService } from "../../_services/CommonService/CommonService";
import HelpManualFileUpload from "./HelpMaualFileUpload";
import "../../../src/components/_form/UploadFile/uploadfile.scss";
import deleteIcon from "../../../src/Assets/Images/delete.svg";
import corruptMssg from "../../../src/Assets/Images/corruptMssg.svg";
import corruptFile from "../../../src/Assets/Images/corruptfile.svg";
import { BsFileEarmarkArrowUp, BsFileEarmarkArrowUpFill } from "react-icons/bs";
import PerfectScrollbar from "react-perfect-scrollbar";
import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";

export const uploadFile = async (data, id) => {
  // let url = "environment-management/uploaded-files/upload/" + id;
  let url = "/master-data/help-manual-master";
  try {
    const response = await RestMethod.POST(url, data);
    return {
      response,
    };
  } catch (error) {
    console.log("error detected while sending data from api", error);
    return {
      data: [],
    };
  }
};

const HelpManualEdit = ({
  parsedData,
  isEdit,
  SidebarMenu = false,
  isSafetyDocuments,
}) => {
  const param = useParams();

  const [selected, setSelected] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [dataForError, setDataForError] = useState(false);

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");

  const [submit, setSubmit] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [serverErrorData, setServerErrorData] = useState([]);
  const [FileError, setFileError] = useState("");

  const navigate = useNavigate();

  const [form, setForm] = useState({
    module: isSafetyDocuments ? "Safety Document" : "",
    label: "",
    sequence: isSafetyDocuments ? 0 : "",
    document_id: "",
  });
  useEffect(() => {
    setFileError("");
  }, [file]);
  useEffect(() => {
    if (param.id) getData(param.id);
    else return;
  }, []);

  const getData = async (id) => {
    let url = "/master-data/help-manual-master";
    try {
      const response = await RestMethod.GET(url + "/" + id);
      const { message, status, data } = response.data;
      setFileName(data?.help_manual_document_data?.real_document_name || "");
      setForm((prev) => {
        const updatedForm = {
          ...prev,
          module: data.module,
          label: data.label,
          // url: data.help_manual_document_data,
          sequence: data.sequence,
          document_id: data.document_id,
        };
        return updatedForm;
      });
    } catch (error) {
      console.log("error detected while sending data from api", error);
      return {
        data: [],
      };
    }
  };
  const updateFile = async (data, id) => {
    // let url = "environment-management/uploaded-files/upload/" + id;
    let url = "/master-data/help-manual-master/" + id;
    try {
      const response = await RestMethod.PUT(url, data);
      return {
        response,
      };
    } catch (error) {
      console.log("error detected while sending data from api", error);
      return {
        data: [],
      };
    }
  };
  const change = (e) => {
    setSelected(e.target.value);
    e.target.name === "sequence"
      ? setForm((prev) => ({
          ...prev,
          [e.target.name]: parseInt(e.target.value),
        }))
      : setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const selectChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const moduleList = [
    "Contractor Management",
    "Fuel Management",
    "Safety Management",
    "Coal Grade",
    "Environment Management",
  ];
  const moduleOptions = Array.isArray(moduleList)
    ? moduleList.map((value) => ({
        value: value,
        label: value,
      }))
    : [{ value: "", label: "" }];

  const removeFile = () => {
    setFile("");
    setFileError("");
    setForm((prev) => ({
      ...prev,
      document_id: "",
    }));
  };
  const onChangeFile = async (e, extensions) => {
    if (!extensions?.includes("pdf")) {
      setForm((d) => ({ ...d, document_id: "" }));
      return;
    }
    setFile(e.file);
    const s3UrlData = await uploadFileOnS3(e.file);
    const commonResponse = await CommmonService.uploadDocument({
      ...s3UrlData,
      source: "User-Manual",
    });
    setForm((prev) => {
      const updatedForm = {
        ...prev,
        document_id: commonResponse?.data.id,
      };
      return updatedForm;
    });
  };
  const onSubmit = async () => {
    setDataForError(false);
    setSubmit(true);
    if (
      form.module != "" &&
      form.label != "" &&
      // form?.fileType != "" &&
      form.document_id != "" &&
      (form.sequence != "" || isSafetyDocuments)
    ) {
      setIsloading(true);

      const response = !param.id
        ? await uploadFile(form)
        : await updateFile(form, param.id);

      if (response?.response?.data?.status === 1) {
        if (response?.response?.data?.data?.invalidData) {
          setServerErrorData(response?.response?.data?.data?.invalidData);
          // setUploadAreaQualityFlag(true);
          setDataForError(true);
          let output = {};
          let res = response?.response?.data?.data;
          for (let item of res["invalidData"]) {
            if (output[item["index"]]) {
              output[item["index"]]["message"] =
                output[item["index"]]["message"] + ", " + item["message"];
            } else {
              output[item["index"]] = {
                index: item["index"],
                row: item["row"],
                message: item["message"],
              };
            }
          }
          setErrorData(output);
          setFileError(response?.response?.data?.data?.message);
        }
        setIsloading(false);
        navigate(
          isSafetyDocuments
            ? "/safety-management-system/configuration/upload-files"
            : "/super-admin/help-manual"
        );
      } else {
        toastr.error(response?.response?.data?.message || "Error");
        setFileError(response?.response?.data?.message);
      }
      if (response) setIsloading(false);
    }
  };
  const closeFun = () => {
    navigate("/super-admin/help-manual");
  };
  return (
    <>
      <div className=" sidebarWrapper">
        <SafetySideBar SidebarData={SidebarMenu ? SidebarMenu : SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <div className="creationWrapper">
              {isSafetyDocuments && (
                <h3
                  className=""
                  style={{ paddingBottom: "1px", paddingTop: "5px" }}
                >
                  <MainBreadcrumb
                    routeSegments={[
                      {
                        name: "Safety Management",
                        path: "#",
                      },
                      {
                        name: "Configuration",
                        path: "/safety-management-system/configuration",
                      },
                      {
                        name: "Upload Document",
                        path: "/safety-management-system/configuration/upload-files",
                      },
                      {
                        name: param?.id ? "Edit" : "Add",
                        path: "#",
                      },
                    ]}
                    // module={ACTIVE_MODULE.incident}
                  />
                </h3>
              )}
              <h3 className="title d-flex align-items-end">
                {isSafetyDocuments ? "Document" : "Help Manual"}{" "}
                {isEdit ? "Edit" : "Upload"}
              </h3>

              <PerfectScrollbar className="customHeight">
                <div className="reportCard">
                  <Row>
                    {!isSafetyDocuments && (
                      <Col>
                        <Select
                          label={"Module"}
                          value={form.module}
                          options={moduleOptions}
                          onChange={selectChange}
                          name="module"
                          // KEY_label={}
                        />
                        {submit && form?.module == "" && (
                          <p style={{ color: "red" }}>Module Required</p>
                        )}
                      </Col>
                    )}
                    <Col>
                      <FormInputText
                        label={"Title"}
                        value={form.label}
                        onChange={change}
                        name="label"
                      />
                      {submit && form?.label == "" && (
                        <p style={{ color: "red" }}>Title Required</p>
                      )}
                    </Col>
                  </Row>

                  <HelpManualFileUpload
                    extensions={"pdf"}
                    onChangeFile={onChangeFile}
                    removeFile={removeFile}
                    // addFile={fileName}
                    // addFile={form.url.s3UrlData.fileName}
                    addFile={fileName}
                  />
                  {/* ============================================================================================================== */}

                  {/* {form && form?.document_id && (
                    <div className="uploadFileSec">
                      <div className="uploadFileValue">
                        <div>
                          {(
                            file?.name ||
                            fileName ||
                            form?.help_manual_document_data
                              ?.real_document_name ||
                            ""
                          )
                            .split(".")
                            .pop()
                            .toLowerCase() !== "pdf" ? (
                            <img src={corruptFile} alt="corrupt File" />
                          ) : (
                            <img src={downarrow} alt="PDF File" />
                            <BsFileEarmarkArrowUp
                              style={{ fontSize: "54px", color: "blue" }}
                            />
                          )}
                          <span className='mb-5'>PDF File</span>
                        </div>
                        <div onClick={removeFile}>
                          <label>Delete File</label>
                          <img
                            src={deleteIcon}
                            onClick={removeFile}
                            alt="delete"
                            className="deleteIcon"
                          />
                        </div>
                      </div>
                      {(
                        file?.name ||
                        fileName ||
                        form?.help_manual_document_data?.real_document_name ||
                        ""
                      )
                        .split(".")
                        .pop()
                        .toLowerCase() !== "pdf" && (
                        <div className="errorBox">
                          <span className="errorMSsg">
                            <img src={corruptMssg} alt="corrupt File" />
                            We found some errors while reading the file; please
                            cross-check and re-upload the correct file.
                          </span>
                        </div>
                      )}
                    </div>
                  )} */}

                  {isloading && <div className="loader"></div>}
                  {submit && form?.document_id == "" && !isloading ? (
                    <p
                      style={{ color: "red" }}
                      className={`${file ? "error_box" : ""}`}
                    >
                      File Required
                    </p>
                  ) : (
                    <></>
                  )}
                  {!isSafetyDocuments && (
                    <FormInputText
                      label={"Sequence"}
                      onChange={change}
                      type={"number"}
                      name="sequence"
                      value={form.sequence}
                      // options={}
                    />
                  )}
                  {!isSafetyDocuments && submit && form?.sequence == "" && (
                    <p style={{ color: "red" }}>Sequence Required</p>
                  )}

                  <div className="btnSec mb-4">
                    <Button onClick={closeFun} className="secondaryBtn">
                      Close
                    </Button>
                    <Button
                      className="primaryBtn ms-2 btn btn-primary"
                      onClick={onSubmit}
                    >
                      {param.id ? "Update" : "Submit"}
                    </Button>
                  </div>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpManualEdit;
