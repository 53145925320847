import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";

const EnvConfigStationView = ({ form }) => {
  const words = form?.type.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const title = capitalizedWords.join(" ");
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">
              Environment Configuration for station {title}
            </p>
            <Row>
              <Col sm={4}>
                <label>
                  {form?.type === "noise_level" ||
                  form?.type === "ground_water_CMPDIL"
                    ? "Project"
                    : "Mine"}
                </label>
                <p className="content">{form?.siteData?.name}</p>
              </Col>
              <Col sm={4}>
                <label>Subsidiary</label>
                <p className="content">{form?.siteData?.parentSite?.name}</p>
              </Col>
              <Col sm={4}>
                <label>
                  {form?.type === "ground_water_CMPDIL"
                    ? "Well Category"
                    : "Category"}
                </label>
                <p className="content">{form?.category}</p>
              </Col>
              <Col sm={4}>
                <label>
                  {form?.type === "ground_water_CMPDIL"
                    ? "Village Location"
                    : "Station Name"}
                </label>
                <p className="content">{form?.name}</p>
              </Col>
              {form?.type !== "CAAQMS" && (
                <Col sm={4}>
                  <label>
                    {form?.type === "ground_water_CMPDIL"
                      ? "Code"
                      : "Station Code"}
                  </label>
                  <p className="content">{form?.code}</p>
                </Col>
              )}
              {form?.type !== "ground_water_CMPDIL" && (
                <>
                  <Col sm={4}>
                    <label>Latitude</label>
                    <p className="content">{form?.lat}</p>
                  </Col>
                  <Col sm={4}>
                    <label>Longitude</label>
                    <p className="content">{form?.log}</p>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default EnvConfigStationView;
