import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../_helpers/dateHelper";

const CsisView = ({ form }) => {
  return (
    <Modal.Body className="p-0">
      <div className="preview">
        <div className="previewDasContent">
          <Row>
            <Col md={4}>
              <label>Subsidiary</label>
              <p className="content">{form?.sub_code}</p>
            </Col>
            <Col md={4}>
              <label>Mine</label>
              <p className="content">{form?.site?.name}</p>
            </Col>
            <Col md={4}>
              <label>Mine name</label>
              <p className="content">{form?.mine_name}</p>
            </Col>
            <Col md={4}>
              <label>Accident ID</label>
              <p className="content">{form?.accident_id}</p>
            </Col>
            <Col md={4}>
              <label>Category Name</label>
              <p className="content">{form?.category_name}</p>
            </Col>
            <Col md={4}>
              <label>Area Name</label>
              <p className="content">{form?.area_name}</p>
            </Col>
            <Col md={4}>
              <label>Accident Date</label>
              <p className="content">
                {DDMMYYYYFormat(form?.date_of_accident)}
              </p>
            </Col>
            <Col md={4}>
              <label>Creation Date Time</label>
              <p className="content">
                {DDMMYYYYHHMMAFormat(form?.creation_datetime)}
              </p>
            </Col>
            <Col md={4}>
              <label>CreatedAt</label>
              <p className="content">{DDMMYYYYFormat(form?.createdAt)}</p>
            </Col>
          </Row>
          <p className="head">Description</p>
          <div className="previewContent">
            {form?.descriptionData?.map((obj, idx) => {
              return (
                <Row className="p-2" key={idx}>
                  <Col lg={3}>
                    <label>Person Name</label>
                    <p>{obj?.name_of_person}</p>
                  </Col>
                  <Col lg={3}>
                    <label>Place</label>
                    <p>{obj?.place}</p>
                  </Col>
                  <Col lg={3}>
                    <label>Employment Type</label>
                    <p>{obj?.type_of_employment}</p>
                  </Col>
                  <Col lg={3}>
                    <label>Accident Cause Code</label>
                    <p>{obj?.accident_cause_code}</p>
                  </Col>
                  <Col lg={3}>
                    <label>Accident Cause Description</label>
                    <p>{obj?.accident_cause_description}</p>
                  </Col>
                  <Col lg={9}>
                    <label>Accident Description</label>
                    <p>{obj?.accident_description}</p>
                  </Col>
                  {form?.descriptionData?.length - 1 !== idx ? (
                    <div className="seperatorLine"></div>
                  ) : (
                    ""
                  )}
                </Row>
              );
            })}
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

export default CsisView;
