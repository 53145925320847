import { IS_MOBILE } from "../../../_helpers/helper";

import {
  getContractorScoreListWithPagination,
  getMyContractorScoreListWithPagination,
  getContractorScoreById,
} from "../../../services/_contractorScore/contractorScore";
import { DDMMYYYYFormat, MonthYearFormat } from "../../../_helpers/dateHelper";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import { sections } from "../ContractorPerformance/DATA";
import { TenderIdColumn } from "../../../_helpers/helper";
export const performanceStatus = {
  draft: "Draft",
  Submitted: "Submitted",
};

export const initialForm = {
  contractorId: "",
  siteId: "",
  avgScore: 0,
  date: "",
  status: "Submitted",
  po: "",
  nit_id: "",
};

export const validationRules = [
  {
    isRequired: true,
    field_name: "date",
    label: "Date",
    type: "string",
  },
  {
    field_name: "contractorId",
    label: "Contractor",
    type: "number",
    isRequired: true,
  },
  {
    isRequired: true,
    field_name: "nit_id",
    label: "NIT Number",
    type: "number",
  },
  {
    isRequired: true,
    field_name: "po",
    label: "PO",
    type: "number",
  },
  {
    label: "",
    type: "number",
    isRequired: true,
    field_name: "siteId",
  },
  // {
  //   minNumber: 0,
  //   field_name: "quantityData",
  //   isRequired: true,
  //   label: "Quantity Data",
  //   type: "number",
  //   maxNumber: 100,
  // },
  // {
  //   field_name: "availabilityData",
  //   label: "Availability Data",
  //   type: "number",
  //   isRequired: true,
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  // {
  //   field_name: "utilizationData",
  //   label: "Utilization Data",
  //   type: "number",
  //   isRequired: true,
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  // {
  //   field_name: "attributableToContractorData",
  //   label: "Attributable To Contractor Data",
  //   type: "number",
  //   isRequired: true,
  //   maxNumber: 100,
  //   minNumber: 0,
  // },
  // {
  //   field_name: "hseObservationData",
  //   label: "hseObservation Data",
  //   type: "number",
  //   // isRequired: true,
  //   // maxNumber: 100,
  //   // minNumber: 0,
  // },
  // {
  //   field_name: "hseObservationScore",
  //   type: "number",
  //   maxNumber: 5,
  //   minNumber: 0,
  // },
];

export const contractorScoreApi = Object.freeze({
  getAll: {
    api: getContractorScoreListWithPagination,
  },
  getById: {
    api: getContractorScoreById,
  },
});

export const myContractorScoreApi = Object.freeze({
  getAll: {
    api: getMyContractorScoreListWithPagination,
  },
  getById: {
    api: getContractorScoreById,
  },
});

export const contractorScoreColumnFields = [
  {
    name: "Contractor",
    selector: "contractorId",
    sortable: true,
    cell: (row) => row?.contractorData?.contractor,
  },
  {
    name: "Mine",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.siteData?.name,
  },
  {
    name: "NIT / PO",
    selector: "nit",
    // sortable: true,
    minWidth: "250px",
    cell: (row) => TenderIdColumn(row),
  },
  // {
  //   name: "Quantity Data",
  //   selector: "quantityData",
  //   sortable: true,
  //   cell: (row) => row?.quantityData,
  // },
  // {
  //   name: "Utilization Data",
  //   selector: "utilizationData",
  //   sortable: true,
  //   cell: (row) => row?.utilizationData,
  // },

  // {
  //   name: "hseObservationData",
  //   selector: "hseObservationData",
  //   sortable: true,
  //   cell: (row) => row?.hseObservationData,
  // },

  {
    name: "Average Score",
    selector: "avgScore",
    sortable: true,
    cell: (row) => row?.avgScore?.toFixed(2),
  },
  // {
  //   name: "Subsidiary",
  //   selector: "site_id",
  //   sortable: true,
  //   cell: (row) => row?.siteData?.parentSite?.name,
  // },

  {
    name: "Month",
    selector: "date",
    sortable: true,
    minWidth: "130px",
    cell: (row) => (row?.date ? MonthYearFormat(row?.date) : ""),
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    minWidth: "180px",
    cell: (row) =>
      row?.status ? (
        <span className="statusSec draft">
          {performanceStatus[row?.status]}
        </span>
      ) : (
        ""
      ),
  },
];

export const exportContractorScoreCard = async (filter) => {
  try {
    let url = "/contractor/score-card-excel/export-score-card";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;

    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const exportMyContractorScoreCard = async (filter) => {
  try {
    let url = "/contractor/score-card-excel/my-export-score-card";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;

    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const contractorScoreColumnFieldsMy = [
  {
    selector: "contractorId",
    sortable: true,
    cell: (row) => row?.contractorData?.contractor,
    name: "Contractor",
  },
  {
    selector: "site_id",
    sortable: true,
    name: "Mine",
    cell: (row) => row?.siteData?.name,
  },
  // {
  //   name: "Quantity Data",
  //   selector: "quantityData",
  //   cell: (row) => row?.quantityData,
  //   sortable: true,
  // },

  {
    name: "NIT / PO",
    selector: "nit",
    // sortable: true,
    minWidth: "250px",
    cell: (row) => TenderIdColumn(row),
  },
  // {
  //   name: "Utilization Data",
  //   cell: (row) => row?.utilizationData,
  //   selector: "utilizationData",
  //   sortable: true,
  // },
  // {
  //   selector: "attributableToContractorData",
  //   sortable: true,
  //   cell: (row) => row?.attributableToContractorData,
  //   name: "Attributable To Contractor Data",
  // },

  // {
  //   selector: "hseObservationData",
  //   sortable: true,
  //   cell: (row) => row?.hseObservationData,
  //   name: "hseObservationData",
  // },

  {
    name: "Average Score",
    selector: "avgScore",
    sortable: true,
    cell: (row) => row?.avgScore?.toFixed(2),
  },
  {
    name: "Month",
    cell: (row) => (row?.date ? MonthYearFormat(row?.date) : ""),
    selector: "date",
    sortable: true,
    minWidth: "130px",
  },
  {
    sortable: true,
    name: "Status",
    selector: "status",
    minWidth: "180px",
    cell: (row) =>
      row?.status ? (
        <span className="statusSec draft">
          {performanceStatus[row?.status]}
        </span>
      ) : (
        ""
      ),
  },
];

export const statusListObj = {
  draft: "draft",
};
export const EditLink = `${
  IS_MOBILE() ? "/mobile" : ""
}/contractor-management/contractor-score/edit`;

export const filterForm = {
  startDate: "",
  endDate: "",
};

export const vendorScoreDataFields = [
  "quantityData",
  "availabilityData",
  "utilizationData",
  "attributableToContractorData",
  "hseObservationData",
  "benchData",
  "biometricData",
  "distributionSafetyGadgetData",
  "dustSuppressionData",
  "environmentData",
  "equipmentRunningData",
  "equipmentRollData",
  "fireProtectionData",
  "haulRoadData",
  "imePmeData",
  "paymentClipData",
  "safetyFeatureData",
  "vtcCertificateData",
];
