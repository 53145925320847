import Modal from "react-bootstrap/Modal";
import Select from "../../../components/Select/Select";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { Button, Col, Row } from "react-bootstrap";
import { IS_USER_SITE_MINES, IS_USER_SITE_RO } from "../../../_helpers/helper";
import MultiSelectWithCheckbox from "../../../components/SearchableSelect/MultiSelectCkeckOption";

const HsdConsumptionFilter = ({
  form,
  onChange,
  subsidery,
  sites,
  resetFun,
  applyFun,
  handleClose,
  show,
  optionsList,
  hideInventoryFilter,
}) => {
  let allowedSites = sites;
  if (form.subsideryId)
    allowedSites = sites.filter((d) => d.parentSiteId === form.subsideryId);

  let requiredObj = {
    siteId: form?.siteId,
    eqptType: form?.eqptType,
    make: form?.make,
    capacity: form?.capacity,
    modelValue: form?.modelValue,
  };
  let hemmList = optionsList?.hemm_data?.filter((e) => {
    return (
      e?.site_id === requiredObj?.siteId &&
      requiredObj?.eqptType?.includes(e?.fms_vehicle_type) &&
      e?.make === requiredObj?.make &&
      e?.capacity === requiredObj?.capacity &&
      e?.model === requiredObj?.modelValue
    );
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="filterModal"
      >
        <Modal.Header closeButton className="px-3">
          <h5 className="modal-title">Filter</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <div className="form-group mb-3">
              <Select
                label={"Subsidiary"}
                name="subsideryId"
                value={form?.subsideryId || ""}
                onChange={onChange}
                options={subsidery}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
                disabled={IS_USER_SITE_MINES() || IS_USER_SITE_RO()}
              />
            </div>
            <div className="form-group mb-3">
              <Select
                label={"Site"}
                name="siteId"
                value={form?.siteId || ""}
                onChange={onChange}
                options={allowedSites}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                menuPortalTarget={null}
                disabled={IS_USER_SITE_MINES()}
              />
            </div>
            {!hideInventoryFilter ? (
              <>
                {" "}
                <div className="form-group mb-3">
                  <label class="form-label">Equipment Type</label>
                  <MultiSelectWithCheckbox
                    options={optionsList?.equipment_type || []}
                    value={
                      form?.eqptType
                        ? form?.eqptType
                            ?.map((role) => {
                              const found = optionsList?.equipment_type?.find(
                                (obj) => obj.value === role
                              );
                              return found ? { ...found } : null;
                            })
                            ?.filter((d) => !!d)
                        : []
                    }
                    onChange={(selectedRoles) => {
                      onChange({
                        target: {
                          name: "eqptType",
                          value: selectedRoles?.map((obj) => obj.value),
                        },
                      });
                    }}
                    // disabled={completionFlag || mmFlag}
                    // hasSelectAll={false}
                    disableSearch={true}
                  />
                </div>
                <div className="form-group mb-3">
                  <Select
                    label={"Make"}
                    name="make"
                    value={form?.make || ""}
                    onChange={onChange}
                    options={optionsList?.make_data || []}
                    blankSelect={"Select"}
                    menuPortalTarget={null}
                  />
                </div>
                <div className="form-group mb-3">
                  <Select
                    label={"Capacity"}
                    name="capacity"
                    value={form?.capacity || ""}
                    onChange={onChange}
                    options={optionsList?.capacity_data || []}
                    blankSelect={"Select"}
                    menuPortalTarget={null}
                  />
                </div>
                <div className="form-group mb-3">
                  <Select
                    label={"Model"}
                    name="modelValue"
                    value={form?.modelValue || ""}
                    onChange={onChange}
                    options={optionsList?.model_data || []}
                    blankSelect={"Select"}
                    menuPortalTarget={null}
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label">Hemm ID</label>
                  <div>
                    <MultiSelectWithCheckbox
                      options={hemmList || []}
                      value={
                        form?.eqptId
                          ? form?.eqptId
                              ?.map((role) => {
                                const found = hemmList?.find(
                                  (obj) => obj.value === role
                                );
                                return found ? { ...found } : null;
                              })
                              ?.filter((d) => !!d)
                          : []
                      }
                      onChange={(selectedRoles) => {
                        onChange({
                          target: {
                            name: "eqptId",
                            value: selectedRoles?.map((obj) => obj.value),
                          },
                        });
                      }}
                      disableSearch={true}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            <Row>
              <label className="pb-2">Date Duration</label>
              <Col lg={12}>
                <CustomDatePicker
                  value={form?.startDate}
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  onChangeDate={onChange}
                  placeholder={"Start Date"}
                />
              </Col>
              <Col lg={12}>
                <CustomDatePicker
                  value={form?.endDate}
                  dateFormat="YYYY-MM-DD"
                  name="endDate"
                  onChangeDate={onChange}
                  // className={"cndrPosition"}
                  placeholder={"End Date"}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="filterBtn">
            <Button
              className="secondaryBtn"
              data-testid="resetFunId"
              style={{ minWidth: "auto" }}
              onClick={() => resetFun()}
            >
              Reset
            </Button>
            <Button
              className="primaryBtn"
              data-testid="close-message-popup"
              onClick={() => applyFun()}
              style={{ minWidth: "auto" }}
            >
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HsdConsumptionFilter;
