import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../../../../components/FormInputText/FormInputText";
import Select from "../../../../../../components/MobileComponents/Select/Select";
import { AiOutlinePlus } from "react-icons/ai";
import ReportButton from "../../ReportButton/ReportButton";
import { CustomSwitch } from "../../../../../../components/CustomSwitch/CustomSwitch";
import { getArraayofKeys } from "../../../Helper";
import {
  BODY_PART_LIST,
  STAFFEMPLOYER,
  checkValidCount,
  injuryCodeObj,
  viewStaffType,
} from "../../../DATA";
import { IoIosCloseCircleOutline } from "react-icons/io";
import MultiSelectWithCheckbox from "../../../../../../components/MobileComponents/SearchableSelect/MultiSelectCkeckOption";
import CustomDatePicker from "../../../../../../components/MobileComponents/DateTimeCustom/CustomDateTime";
import {
  checkNumberString,
  digitCheckAfterDecimal,
} from "../../../../../../_helpers/helper";
// import MultiSelectWithCheckbox from "../../../../../../components/SearchableSelect/MultiSelectCkeckOption";

const PersonnelDetailsTab = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  personalDetails,
  onChangePersonalDetails,
  personalDetailsErrors,
  onChangePersonalDetailsEmployee,
  deleteRowPersonalDetails,
  addRowPersonalDetails,
  users,
  contractors,
  employeeList,
  contractorsStaff,
  tab,
  designationList,
  asterikRules,
  personalAsterikRules,
}) => {
  return (
    <>
      <div className="mobileFormWrapper">
        <h3>Personnel Details</h3>
        <div className="ehsFormWrapper p-0">
          <div className="customSwitchSec">
            <CustomSwitch
              name="isPersonalInjury"
              value={
                form.isPersonalInjury === null ? false : form.isPersonalInjury
              }
              onChange={onChange}
              title="Personnel Affected"
            />
          </div>
          <p className="text-danger">{error?.isPersonalInjury}</p>
          <Form>
            <Row>
              {form.isPersonalInjury && (
                <>
                  <Col lg={3}>
                    <FormInputText
                      name="peopleCount"
                      readOnly={!form.isPersonalInjury}
                      value={form.peopleCount}
                      onChange={(event) =>
                        event.target.value >= 0 &&
                        event.target.value <= 99999 &&
                        onChange(event)
                      }
                      label={"Number of people involved"}
                      error={error?.peopleCount}
                      type="number"
                      asterikRules={asterikRules}
                    />
                  </Col>
                  <Col lg={3}>
                    <FormInputText
                      error={error?.number_of_injuries}
                      label={"Injuries Count"}
                      name="number_of_injuries"
                      value={form?.number_of_injuries}
                      // onChange={(event) =>
                      //   event.target.value >= 0 &&
                      //   checkValidCount(
                      //     +form.peopleCount,
                      //     +event.target.value,
                      //     +form?.number_of_fatality,
                      //     form?.category,
                      //     event.target.name
                      //   ) &&
                      //   onChange(event)
                      // }
                      // onChange={onChange}
                      minLength={0}
                      maxLength={+form.peopleCount}
                      type={"number"}
                      asterikRules={asterikRules}
                      disabled={true}
                    ></FormInputText>
                  </Col>
                  <Col lg={3}>
                    <FormInputText
                      type={"number"}
                      label={"Fatality Count"}
                      name="number_of_fatality"
                      value={form?.number_of_fatality}
                      // onChange={(event) =>
                      //   event.target.value >= 0 &&
                      //   checkValidCount(
                      //     +form.peopleCount,
                      //     +form?.number_of_injuries,
                      //     +event.target.value,
                      //     form?.category,
                      //     event.target.name
                      //   ) &&
                      //   onChange(event)
                      // }
                      minLength={0}
                      error={error?.number_of_fatality}
                      // disabled={form?.category !== "fatality"}
                      asterikRules={asterikRules}
                      disabled={true}
                    ></FormInputText>
                  </Col>
                  <Col lg={3}>
                    <FormInputText
                      label={"Location of Accident/Incident"}
                      name="place_of_incident"
                      value={form?.place_of_incident}
                      onChange={onChange}
                      error={error?.place_of_incident}
                      asterikRules={asterikRules}
                    ></FormInputText>
                  </Col>
                  <Col md="12">
                    {personalDetails?.length ? (
                      personalDetails?.map((x, index) => {
                        return x.deleted ? null : (
                          <div className="addDataformCards" key={index}>
                            <div className="formFields">
                              <Select
                                name="type"
                                onChange={({ target: { name, value } }) =>
                                  onChangePersonalDetails(index, name, value)
                                }
                                value={x.type}
                                options={getArraayofKeys(viewStaffType)}
                                object={viewStaffType}
                                KEY_label={"name"}
                                KEY_value={"id"}
                                init="none"
                                array="single"
                                label={"Type"}
                              ></Select>
                            </div>
                            <div className="formFields">
                              {x.type && x.type !== "users" ? (
                                <FormInputText
                                  name="name"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangePersonalDetails(index, name, value)
                                  }
                                  value={x.name}
                                  className="form-control"
                                  error={personalDetailsErrors[index]?.name}
                                  label={"Name"}
                                  asterikRules={personalAsterikRules}
                                />
                              ) : (
                                <Select
                                  label={"Name"}
                                  name={"userId"}
                                  options={users?.map((employee) => ({
                                    label: employee.name_email_employeeId,
                                    value: employee.id,
                                    optionDetails: employee,
                                  }))}
                                  value={x.userId || x.name}
                                  onChange={({ target: { name, value } }) => {
                                    onChangePersonalDetailsEmployee(
                                      index,
                                      value
                                      // optionDetails.name_email_employeeId,
                                      // optionDetails.id,
                                      // //optionDetails.contactNumber,
                                      // optionDetails.dob
                                    );
                                  }}
                                  placeholder={"Search by Name / Email Id"}
                                  blankSelect={"none"}
                                  isCreatable
                                />
                              )}
                              {/* <span className="small text-danger">
                                {personalDetailsErrors[index]?.name}
                              </span> */}
                            </div>
                            <div className="formFields">
                              {x.type !== "others" ? (
                                <Select
                                  label={"Employer / Contractor"}
                                  name={"employer"}
                                  options={
                                    x.type === "contractor"
                                      ? contractorsStaff
                                      : STAFFEMPLOYER
                                  }
                                  value={checkNumberString(x.employer)}
                                  onChange={({ target: { name, value } }) => {
                                    onChangePersonalDetails(index, name, value);
                                  }}
                                  // placeholder={
                                  //   "Search by Name / Email Id"
                                  // }
                                  blankSelect={"none"}
                                  KEY_label={
                                    x.type === "contractor" && "contractor"
                                  }
                                  KEY_value={x.type === "contractor" && "id"}
                                />
                              ) : (
                                <FormInputText
                                  label={"Employer / Contractor"}
                                  name="employer"
                                  type="text"
                                  onChange={({ target: { name, value } }) =>
                                    onChangePersonalDetails(index, name, value)
                                  }
                                  value={x.employer}
                                  disabled={
                                    x.type === "contractor" ? false : true
                                  }
                                  className="form-control"
                                  error={personalDetailsErrors[index]?.employer}
                                />
                              )}
                            </div>
                            <div className="formFields">
                              {/* <label>Designation</label> */}
                              <Select
                                label={"Designation"}
                                name="designation"
                                value={x?.designation}
                                onChange={({ target: { name, value } }) =>
                                  onChangePersonalDetails(index, name, value)
                                }
                                options={designationList}
                                error={
                                  personalDetailsErrors[index]?.designation
                                }
                                KEY_label={"name"}
                                KEY_value={"id"}
                                asterikRules={personalAsterikRules}
                              ></Select>
                            </div>
                            <div className="formFields">
                              <Select
                                label={"Gender"}
                                name="gender"
                                value={x?.gender}
                                onChange={({ target: { name, value } }) =>
                                  onChangePersonalDetails(index, name, value)
                                }
                                options={["Male", "Female"]}
                                init="Select"
                                array="single"
                                error={personalDetailsErrors[index]?.gender}
                                KEY_label={"name"}
                                KEY_value={"id"}
                                asterikRules={personalAsterikRules}
                              ></Select>
                            </div>
                            <div className="formFields">
                              {/* <label>Age</label> */}
                              <FormInputText
                                label={"Age"}
                                name="age"
                                type="number"
                                onChange={({ target: { name, value } }) =>
                                  value >= 0 &&
                                  value <= 200 &&
                                  onChangePersonalDetails(index, name, value)
                                }
                                value={x.age}
                                // className="form-control"
                                error={personalDetailsErrors[index]?.age}
                                asterikRules={personalAsterikRules}
                              />
                              {/* <span className="small text-danger">
                                {personalDetailsErrors[index]?.age}
                              </span> */}
                            </div>
                            <div className="formFields">
                              <FormInputText
                                label={"Hours at Work"}
                                name="hoursAtWork"
                                type="number"
                                onChange={({ target: { name, value } }) =>
                                  value >= 0 &&
                                  digitCheckAfterDecimal(value, 2) &&
                                  onChangePersonalDetails(index, name, value)
                                }
                                value={x.hoursAtWork}
                                // className="form-control"
                                error={
                                  personalDetailsErrors[index]?.hoursAtWork
                                }
                                asterikRules={personalAsterikRules}
                              />
                            </div>
                            <div className="formFields">
                              <label>IME Date</label>
                              <CustomDatePicker
                                value={x?.imeDate}
                                dateFormat="YYYY-MM-DD"
                                name="imeDate"
                                onChange={({ target: { name, value } }) =>
                                  onChangePersonalDetails(index, name, value)
                                }
                              />
                              {/* <span className="small text-danger">
                                {personalDetailsErrors[index]?.age}
                              </span> */}
                            </div>
                            <div className="formFields">
                              <label>PME Date</label>
                              <CustomDatePicker
                                value={x?.pmeDate}
                                dateFormat="YYYY-MM-DD"
                                name="pmeDate"
                                onChange={({ target: { name, value } }) =>
                                  onChangePersonalDetails(index, name, value)
                                }
                              />
                              {/* <span className="small text-danger">
                                {personalDetailsErrors[index]?.age}
                              </span> */}
                            </div>
                            <div className="formFields">
                              <label>VTC Date</label>
                              <CustomDatePicker
                                value={x?.vtcDate}
                                dateFormat="YYYY-MM-DD"
                                name="vtcDate"
                                onChange={({ target: { name, value } }) =>
                                  onChangePersonalDetails(index, name, value)
                                }
                              />
                              {/* <span className="small text-danger">
                                {personalDetailsErrors[index]?.age}
                              </span> */}
                            </div>
                            <div className="formFields">
                              <label>Form A No.</label>
                              <FormInputText
                                name="formA_No"
                                type="text"
                                onChange={({ target: { name, value } }) =>
                                  onChangePersonalDetails(index, name, value)
                                }
                                value={x.formA_No}
                                className="form-control"
                                // error={
                                //   personalDetailsErrors[index]?.employer
                                // }
                              />
                              {/* <span className="small text-danger">
                                {personalDetailsErrors[index]?.age}
                              </span> */}
                            </div>
                            <div className="formFields">
                              <Select
                                label={"Outcome"}
                                name="outcome"
                                value={x?.outcome}
                                onChange={({ target: { name, value } }) =>
                                  onChangePersonalDetails(index, name, value)
                                }
                                options={[
                                  "Minor Injury",
                                  "Serious Injury",
                                  "Fatality",
                                ]}
                                init="Select"
                                array="single"
                                error={personalDetailsErrors[index]?.outcome}
                                KEY_label={"name"}
                                KEY_value={"id"}
                                asterikRules={personalAsterikRules}
                              ></Select>
                            </div>
                            <div className="formFields">
                              <Select
                                label={"Injury Code"}
                                name="injuryCode"
                                value={x?.injuryCode}
                                onChange={({ target: { name, value } }) =>
                                  onChangePersonalDetails(index, name, value)
                                }
                                options={getArraayofKeys(injuryCodeObj)}
                                object={injuryCodeObj}
                                error={personalDetailsErrors[index]?.injuryCode}
                                asterikRules={personalAsterikRules}
                              ></Select>
                            </div>
                            <div className="formFields">
                              <label>Body Parts Involved</label>
                              {console.log(x, "llllllll")}

                              <MultiSelectWithCheckbox
                                options={BODY_PART_LIST.map((d) => ({
                                  ...d,
                                  disabled:
                                    d.value === "notApplicable" &&
                                    x?.bodyParts === "notApplicable"
                                      ? false
                                      : x?.bodyParts === "Not Applicable" ||
                                        !x?.bodyParts ||
                                        x?.bodyParts !== "notApplicable"
                                      ? false
                                      : true,
                                }))}
                                value={x?.bodyParts
                                  ?.split(",")
                                  ?.map((role) => {
                                    const found = BODY_PART_LIST?.find(
                                      (obj) => obj.value === role
                                    );
                                    return found ? { ...found } : null;
                                  })
                                  ?.filter((d) => !!d)}
                                onChange={(selectedSites) => {
                                  onChangePersonalDetails(
                                    index,
                                    "bodyParts",
                                    selectedSites
                                      .map((site) => site.value)
                                      .join(",")
                                  );
                                }}
                                // disabled={completionFlag || mmFlag}
                                hasSelectAll={false}
                                disableSearch={true}
                                headerLabel={"Body Parts Involved"}
                              />
                            </div>
                            <div className="actionIconsGroup">
                              <span
                                data-testid={"remove-leaf" + index}
                                onClick={() => deleteRowPersonalDetails(index)}
                                className="icons font-21 text-danger"
                              >
                                <IoIosCloseCircleOutline
                                  size="26"
                                  style={{ cursor: "pointer" }}
                                />
                                {/* <AiOutlineMinusCircle
                                  size="24"
                                  style={{ cursor: "pointer" }}
                                /> */}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div></div>
                    )}
                    {form.peopleCount &&
                      +form.peopleCount > +personalDetails?.length && (
                        <div className="addMoreBtn">
                          <span
                            className="addTxt"
                            onClick={addRowPersonalDetails}
                            data-testid="add-leaf"
                          >
                            <AiOutlinePlus />
                            Add
                          </span>
                        </div>
                      )}
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </div>
      </div>
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default PersonnelDetailsTab;
