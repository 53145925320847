import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomRadio from "../../../../../components/_form/CustomRadio/CustomRadio";
import Select from "../../../../../components/Select/Select";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import ReportButton from "../ReportButton/ReportButton";
import Asterik from "../../../../../components/Common/Asterik/Asterik";

const EquipmentDetailsTab = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  assetDetails,
  matDetails,
  // onChangeAssetDetailsSku,
  // onChangeAssetDetailsSkuInput,
  mats,
  assetDetailsErrors,
  onChangeAssetDetails,
  deleteRowAssetDetails,
  addRowAssetDetails,
  tab,
  asterikRules,
}) => {
  return (
    <>
      <div className="FormWrapper">
        <Form>
          <Row>
            <Col lg={4}>
              <CustomRadio
                type="checkbox"
                name="isAssetIncident"
                onChange={onChange}
                title={"Equipment Affected"}
                acceptTitle={"Yes"}
                rejectTitle={"No"}
                customClass={"customRadioClass"}
                value={
                  form.isAssetIncident === null ? false : form.isAssetIncident
                }
              />
              <span className="small text-danger">
                {error?.isAssetIncident}
              </span>
            </Col>
            {form.isAssetIncident && (
              <Col md="12">
                <div className="formWrapper rootCause">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="tableHead-bg">
                        <tr>
                          <th>S.No.</th>

                          <th style={{ minWidth: 180 }}>
                            Equipment SKU / Id
                            <Asterik
                              fieldName={"sku"}
                              requiredRules={asterikRules}
                            />
                          </th>
                          <th style={{ minWidth: 180 }}>
                            Equipment Type
                            <Asterik
                              fieldName={"type"}
                              requiredRules={asterikRules}
                            />
                          </th>
                          <th style={{ minWidth: 160 }}>
                            Equipment Name
                            <Asterik
                              fieldName={"name"}
                              requiredRules={asterikRules}
                            />
                          </th>
                          <th style={{ minWidth: 230 }}>
                            Equipment Incident Details
                            <Asterik
                              fieldName={"details"}
                              requiredRules={asterikRules}
                            />
                          </th>
                          <th style={{ minWidth: 230 }}>
                            Equipment Damage (Y/N)
                          </th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {assetDetails.length ? (
                          assetDetails.map((x, index) => {
                            return x.deleted ? null : (
                              <tr key={index}>
                                <th>{index + 1}</th>

                                <td>
                                  <input
                                    name="sku"
                                    value={x.sku}
                                    data-testid="sku"
                                    onChange={({ target: { name, value } }) =>
                                      onChangeAssetDetails(index, name, value)
                                    }
                                    type="text"
                                    className="form-control"
                                  />
                                  <span className="small text-danger">
                                    {assetDetailsErrors[index]?.sku}
                                  </span>
                                </td>
                                <td>
                                  {x.hemm_id ? (
                                    <input
                                      name="type"
                                      value={x.type}
                                      data-testid="type"
                                      onChange={({ target: { name, value } }) =>
                                        onChangeAssetDetails(index, name, value)
                                      }
                                      type="text"
                                      className="form-control"
                                      disabled={true}
                                    />
                                  ) : (
                                    <Select
                                      name="type"
                                      options={matDetails.map((obj) => ({
                                        label: obj.name,
                                        value: obj.id,
                                      }))}
                                      value={+x.type}
                                      onChange={({ target: { name, value } }) =>
                                        onChangeAssetDetails(index, name, value)
                                      }
                                      error={assetDetailsErrors[index]?.type}
                                    />
                                  )}
                                </td>
                                <td>
                                  <input
                                    name="name"
                                    data-testid="name"
                                    value={x.name}
                                    onChange={({ target: { name, value } }) =>
                                      onChangeAssetDetails(index, name, value)
                                    }
                                    type="text"
                                    className="form-control"
                                    disabled={x.hemm_id ? true : false}
                                  />
                                  <span className="small text-danger">
                                    {assetDetailsErrors[index]?.name}
                                  </span>
                                </td>
                                <td>
                                  <input
                                    name="details"
                                    data-testid="details"
                                    value={x.details}
                                    onChange={({ target: { name, value } }) =>
                                      onChangeAssetDetails(index, name, value)
                                    }
                                    type="text"
                                    className="form-control"
                                  />
                                  <span className="small text-danger">
                                    {assetDetailsErrors[index]?.details}
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <label className="radio radio-primary form-check-inline mr-3">
                                      <input
                                        data-testid={`damage-${index}`}
                                        onChange={({
                                          target: { name, value },
                                        }) =>
                                          onChangeAssetDetails(
                                            index,
                                            name.split("-")[0],
                                            value
                                          )
                                        }
                                        type="radio"
                                        name={`damage-${index}`}
                                        value="Yes"
                                        checked={x.damage === "Yes"}
                                      />
                                      <span>Yes</span>
                                      <span className="checkmark" />
                                    </label>
                                    <label className="radio radio-primary form-check-inline mr-3">
                                      <input
                                        onChange={({
                                          target: { name, value },
                                        }) =>
                                          onChangeAssetDetails(
                                            index,
                                            name.split("-")[0],
                                            value
                                          )
                                        }
                                        data-testid={`damage-${index}`}
                                        type="radio"
                                        name={`damage-${index}`}
                                        value="No"
                                        checked={x.damage === "No"}
                                      />
                                      <span>No</span>
                                      <span className="checkmark" />
                                    </label>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="actionIconsGroup">
                                    <span
                                      data-testid={"remove-leaf" + index}
                                      onClick={() =>
                                        deleteRowAssetDetails(index)
                                      }
                                      className="icons font-21 text-danger"
                                    >
                                      <AiOutlineMinusCircle
                                        size="24"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  </div>
                                </td>
                                {/* <td>
                            <div className="actionIconsGroup">
                              <CustomTooltip tooltip={"Delete"}>
                                <a
                                  href
                                  className="icons text-danger pointer font-20"
                                  onClick={() => deleteRowAssetDetails(index)}
                                >
                                  <FiMinusCircle />
                                </a>
                              </CustomTooltip>
                            </div>
                          </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" align="center">
                              {/* <div className="alert alert-danger d-inline-block m-0">
                                                            <strong>Empty</strong>
                                                        </div> */}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="10">
                            <span
                              className="addTxt"
                              onClick={addRowAssetDetails}
                              data-testid="add-leaf"
                            >
                              <AiOutlinePlus />
                              Add
                            </span>
                            {/* <CustomTooltip tooltip={"Add Row"}>
                        <Button className="addRowPluseIcon" onClick={addRowAssetDetails}>
                          <FiPlusCircle />
                        </Button>
                      </CustomTooltip> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </div>
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default EquipmentDetailsTab;
