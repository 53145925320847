import linkImg1 from "../../../Assets/Images/selectionIcon1.svg"
import linkImg2 from "../../../Assets/Images/selectionIcon2.svg"
import linkImg3 from "../../../Assets/Images/selectionIcon3.svg"
import linkImg4 from "../../../Assets/Images/selectionIcon4.svg"
import linkImg5 from "../../../Assets/Images/selectionIcon5.svg"
import linkImg6 from "../../../Assets/Images/selectionIcon6.svg"
import linkImg7 from "../../../Assets/Images/work-flow.png"
import { urlAccordingTabPermissions } from "../../../_pages/ContractorManagement/DATA"


export const SidebarData = [
    {
        img: linkImg1,
        title: 'Contractor Performance ',
        active: 0,
        // link: '/dashboard/contractor-performance-dashboard',
        // permission: ["contractor_performance_dashboard", "performance_production_dashboard", "contractor_performance_score_card_dashboard", "contractor_hindrance_dashboard"]
        link: urlAccordingTabPermissions(),
        permission: ["contractor_performance_dashboard", "performance_production_dashboard", "contractor_performance_score_card_dashboard", "contractor_hindrance_dashboard"],
    },
    {
        img: linkImg2,
        title: 'Fuel Management',
        active: 0,
        link: '/dashboard/fuel-management-dashboard',
        permission: ["fuel_management_dashbaord"],
    },
    {
        img: linkImg3,
        title: 'Safety Management',
        active: 0,
        link: '/dashboard/safety-management-dashboard',
        permission: ["safety_management_dashbaord"],
    },
    {
        img: linkImg4,
        title: 'Coal Grade/ Quality Monitoring',
        active: 0,
        link: '/dashboard/coal-grade-dashboard',
        permission: [
            "coal_grade_dashboard"
        ],
    },
    {
        img: linkImg5,
        title: 'Environment Management',
        active: 0,
        link: '/dashboard/environment-management-dashboard',
        permission: ["environment_management_dashboard"],
    },
    {
        img: linkImg6,
        title: 'HEMM Cockpit : Predictive Analytics Models',
        active: 0,
        link: '/dashboard/HEMM-Cockpit-dashboard',
        permission: ["hemm_cockpit_dashboard"],
    },
    {
        img: linkImg7,
        title: 'Work Flow',
        active: 0,
        link: '/'
    }
];