import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import CheckBoxField from "../../../../../components/_form/CheckBoxField/CheckBoxField";
import FormInputText from "../../../../../components/FormInputText/FormInputText";
import FormTextArea from "../../../../../components/FormTextArea/FormTextAres";
import ReportButton from "../ReportButton/ReportButton";
import { IS_MOBILE } from "../../../../../_helpers/helper";
import { incidentTypesObjKeys } from "../../DATA";
import CustomRadio from "../../../../../components/_form/CustomRadio/CustomRadio";
let Select;
let CustomDatePicker;
if (IS_MOBILE()) {
  Select =
    require("../../../../../components/MobileComponents/Select/Select").default;
  CustomDatePicker =
    require("../../../../../components/MobileComponents/DateTimeCustom/CustomDateTime").default;
} else {
  Select = require("../../../../../components/Select/Select").default;
  CustomDatePicker =
    require("../../../../../components/DateTimeCustom/CustomDateTime").default;
}

const GeneralInfoTab = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  tab,
  violationsLeadingList,
  asterikRules,
}) => {
  return (
    <>
      <div className="mobileFormWrapper">
        {IS_MOBILE() && <h3>General Info</h3>}
        <div className="FormWrapper">
          <Form>
            <Row className="mb-3">
              <Col lg={4}>
                <CheckBoxField
                  type="checkbox"
                  name="fireInvolved"
                  checked={form.fireInvolved}
                  onChange={onChange}
                  label={"Fire Involved"}
                  id={"fireInvolved"}
                />
              </Col>
              <Col lg={4}>
                <CheckBoxField
                  type="checkbox"
                  name="electricShock"
                  checked={form.electricShock}
                  onChange={onChange}
                  label={"Ground Movement / Slope Stability"}
                  id={"electricShock"}
                />
              </Col>
              <Col lg={4}>
                <CheckBoxField
                  type="checkbox"
                  name="lighting"
                  checked={form.lighting}
                  onChange={onChange}
                  label={"Lighting"}
                  id={"lighting"}
                />
              </Col>
              <Col lg={4}>
                <CheckBoxField
                  type="checkbox"
                  name="movingEquipmet"
                  checked={form.movingEquipmet}
                  onChange={onChange}
                  label={"Due to moving equipment"}
                  id={"movingEquipmet"}
                />
              </Col>
              <Col lg={4}>
                <CheckBoxField
                  type="checkbox"
                  name="explosion"
                  checked={form.explosion}
                  onChange={onChange}
                  label={"Explosive Involved"}
                  id={"explosion"}
                />
              </Col>
              <Col lg={4}>
                <CheckBoxField
                  type="checkbox"
                  name="fallFromHeight"
                  checked={form.fallFromHeight}
                  onChange={onChange}
                  label={"Fall from Height / Level"}
                  id={"fallFromHeight"}
                />
              </Col>
              <Col lg={4}>
                <CheckBoxField
                  type="checkbox"
                  name="vehicleCollision"
                  checked={form.vehicleCollision}
                  onChange={onChange}
                  label={"Vehicle Collision/toppling"}
                  id={"vehicleCollision"}
                />
              </Col>
              <Col lg={4}>
                <CheckBoxField
                  type="checkbox"
                  name="fallOfObject"
                  checked={form.fallOfObject}
                  onChange={onChange}
                  label={"Fall of object"}
                  id={"fallOfObject"}
                />
              </Col>
              <Col lg={4}>
                <CheckBoxField
                  type="checkbox"
                  name="inundation"
                  checked={form.inundation}
                  onChange={onChange}
                  label={"Electrical Accident"}
                  id={"inundation"}
                />
              </Col>

              <Col lg={6}>
                <div className={IS_MOBILE() ? "" : "otherWthFld"}>
                  <CheckBoxField
                    type="checkbox"
                    name="othersInvolved"
                    checked={form.othersInvolved}
                    onChange={onChange}
                    label={"Others"}
                    id={"othersInvolved"}
                  />
                  {form?.othersInvolved ? (
                    <Col lg={4}>
                      <FormInputText
                        // label={"Others Info"}
                        name="othersInfo"
                        value={form.othersInfo}
                        onChange={onChange}
                        type="text"
                        class="form-control"
                        placeholder=""
                        error={error?.othersInfo}
                        formGroupClass="mb-0"
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
            {form.category === "fatality" && (
              <Row>
                <Col lg={12}>
                  <CustomRadio
                    type="checkbox"
                    name="fatalityCategory"
                    onChange={onChange}
                    title={"Fatality Category"}
                    acceptTitle={"Statistical"}
                    rejectTitle={"Non-Statistical"}
                    checkBox={true}
                    customClass={
                      "customRadioClass " +
                      (IS_MOBILE() ? "d-block" : "form-label-input mb-2")
                    }
                    value={
                      form.fatalityCategory === null
                        ? false
                        : form.fatalityCategory
                    }
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={3}>
                <Select
                  name="indirectLosses"
                  value={form.indirectLosses}
                  onChange={onChange}
                  options={[
                    "Loss of Production",
                    "Loss of Manshifts",
                    "Loss of Production & Manshift",
                    "Not Applicable",
                  ]}
                  blankSelect="Select"
                  array="single"
                  label={"Indirect Losses"}
                  error={error?.indirectLosses}
                  asterikRules={asterikRules}
                ></Select>
              </Col>
              <Col lg={3}>
                <Select
                  name="facilityStatus"
                  value={form.facilityStatus}
                  onChange={onChange}
                  options={["Operating", "Breakdown"]}
                  init="Select"
                  array="single"
                  label={"Mine - Activity Operations Status"}
                  error={error?.facilityStatus}
                  asterikRules={asterikRules}
                ></Select>
              </Col>

              <Col lg={6}>
                <Select
                  name="similarIncidentHappened"
                  value={form.similarIncidentHappened}
                  onChange={onChange}
                  options={["Yes", "No", "Not Aware"]}
                  init="Select"
                  array="single"
                  label={
                    "Whether similar incident happened in the past at same location / mine"
                  }
                  error={error?.similarIncidentHappened}
                  asterikRules={asterikRules}
                ></Select>
              </Col>

              <Col lg={3}>
                <FormInputText
                  name="costOfAccident"
                  value={form.costOfAccident}
                  label={"Cost of the accident"}
                  error={error?.costOfAccident}
                  onChange={onChange}
                  type="number"
                  asterikRules={asterikRules}
                ></FormInputText>
              </Col>
              <Col lg={3}>
                <Select
                  onChange={onChange}
                  value={form?.violationsLeading}
                  name="violationsLeading"
                  options={violationsLeadingList.sort(
                    (a, b) => +a.code - +b.code
                  )}
                  label={"Incident/Accident Cause"}
                  KEY_label={"name"}
                  KEY_value={"id"}
                  error={error?.violationsLeading}
                  asterikRules={asterikRules}
                />
              </Col>
              <Col lg={6}>
                <FormInputText
                  name="summary"
                  value={form.summary}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"Incident/Accident Short Summary"}
                  error={error?.summary}
                  asterikRules={asterikRules}
                />
              </Col>

              <Col lg={3}>
                <div className="form-group">
                  <Form.Label className="form-label-input">
                    Date of Immediate Last Accident
                  </Form.Label>
                  <CustomDatePicker
                    value={form?.dateOfImmediate}
                    dateFormat="YYYY-MM-DD"
                    name="dateOfImmediate"
                    onChange={onChange}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <Select
                  onChange={onChange}
                  value={form?.immediateLastAccident}
                  name="immediateLastAccident"
                  options={["Fatal", "Serious", "Not Applicable"]}
                  init="Select"
                  array="single"
                  label={"Immediate Last Accident"}
                  error={error?.immediateLastAccident}
                  asterikRules={asterikRules}
                />
              </Col>

              <Col lg={3}>
                <FormInputText
                  name="distanceFromFace"
                  value={form.distanceFromFace}
                  onChange={onChange}
                  type="number"
                  class="form-control"
                  placeholder=""
                  label={"Distance from the face (m)"}
                />
              </Col>
              <Col lg={3}>
                <FormInputText
                  name="pitIncline"
                  value={form.pitIncline}
                  onChange={onChange}
                  type="number"
                  class="form-control"
                  placeholder=""
                  label={"Pit / Incline No"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <FormInputText
                  name="seam"
                  value={form.seam}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"Seam"}
                />
              </Col>
              <Col lg={3}>
                <FormInputText
                  name="level"
                  value={form.level}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"Level"}
                />
              </Col>
              <Col lg={2}>
                <FormInputText
                  name="dip"
                  value={form.dip}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"Dip number"}
                />
              </Col>

              <Col lg={4}>
                <FormInputText
                  name="districtPanel"
                  value={form.districtPanel}
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder=""
                  label={"District / Panel / Bench outside working district"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormTextArea
                  name="briefDescription"
                  value={form.briefDescription}
                  onChange={onChange}
                  type="text"
                  className="form-control h-120"
                  placeholder=""
                  label={"Brief Description of Cause"}
                  error={error?.briefDescription}
                  asterikRules={asterikRules}
                />
              </Col>
              <Col lg={6}>
                <FormTextArea
                  name="detailsNotCovered"
                  value={form.detailsNotCovered}
                  onChange={onChange}
                  type="text"
                  className="form-control h-120"
                  placeholder=""
                  label={" Details not covered above(if within district)"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormTextArea
                  name="description"
                  value={form.description}
                  onChange={onChange}
                  className="form-control h-120"
                  label={"Incident/Accident – Detailed Description"}
                  error={error?.description}
                  asterikRules={asterikRules}
                />
              </Col>
              <Col lg={6}>
                <FormTextArea
                  name="measuresOrActions"
                  value={form.measuresOrActions}
                  onChange={onChange}
                  className="form-control h-120"
                  label={
                    "Immediate Measures/Action Taken (Do not enter CAPA here)"
                  }
                  error={error?.measuresOrActions}
                  asterikRules={asterikRules}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default GeneralInfoTab;
