import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container, Modal } from "react-bootstrap";
import {
  fuelDispenseApis,
  tabUrl,
  fuelDispenseColumnFields,
  fuelManagementFilterForm,
  SidebarData,
  investigatorApprovalHistory,
  capaOwnerApprovalHistory,
  IncidentCapaColumnFields,
  capaFilterForm,
  IncidentHistoryCapaColumnFields,
  mobileTabUrl,
} from "./DATA";
import filter from "../../../Assets/Images/filter.svg";
import { useForm } from "../../../_helpers/hooks";
import {
  IS_MOBILE,
  findUserPermissionedTabList,
} from "../../../_helpers/helper";
import { exportFuelDispense } from "../../../services/_fuelDispense/fuelDispense";
import "./../../FuelManagementSystem/fuelmgmt.scss";
import FuelDispenseView from "../../FuelManagementSystem/FuelDispenseView";
import FuelDispenseFilterForm from "../../FuelManagementSystem/FuelDispenseFilterForm";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import {
  getSafetyManagementCapaOwnerIncidentListWithPagination,
  getSafetyManagementInvestigatorIncidentListWithPagination,
  getSafetyManagementReportedIncidentListWithPagination,
  getSafetyManagementCapaOwnerIncidentExcel,
} from "../../../services/_safetyManagement/safetyManagement";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import SafetyManagementView from "../SafetyManagementView";
import CapasView from "./components/CapasView";
import CapaDetailsView from "./components/CapaDetailsView";
import CapaFilterForm from "./components/CapaFilterForm";
const MyApprovalCapOwnerIncidentHistoryList = () => {
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(capaFilterForm);

  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(
      IS_MOBILE()
        ? mobileTabUrl.safetyManagementApprovalHistory
        : tabUrl.safetyManagementApprovalHistory
    )
  );
  useEffect(() => {
    let currentUrl = "";
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          currentUrl = d.to;
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    currentUrl ? navigate(currentUrl) : navigate(customTabs[0].to);
    getAll();
  }, []);

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} hideLeftRightBtn />;
  };
  const getAll = async () => {
    const response =
      await getSafetyManagementCapaOwnerIncidentListWithPagination(
        "page=1&limit=2"
      );

    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const onResetFilter = () => {
    setForm({ fuelManagementFilterForm });
    setGetAllFilter();
  };


  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const viewAsPopup = (form) => {
    return <CapaDetailsView form={form} />;
  };

  const FilterBtn = () => {
    const [openModel, setOpenModel] = useState(false);

    return (
      <div className="filter">
        <Button
          aria-controls="example-collapse-text"
          onClick={() => setOpenModel(!openModel)}
          aria-expanded={openModel}
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <CapaFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={openModel}
          setOpen={setOpenModel}
        />
      </div>
    );
  };
  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const isElement = form[key];
      if (isElement) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${isElement}`;
        else filter += `&search[${key}]=${isElement}`;
      }
    }

    setGetAllFilter(filter);
  }, [filterStatus]);

  return (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        pageTitle={"My Approval History"}
        SidebarData={SidebarData}
        goBack={() =>
          navigate("/mobile/safety-management-system/incident-management")
        }
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={IncidentHistoryCapaColumnFields}
                apis={capaOwnerApprovalHistory}
                title="Data"
                viewTitle={"CAPA Details"}
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/incident-management"}
                primaryKey="id"
                pagination
                getAllFilter={getAllFilter}
                hideAddButton
                downloadExcel={getSafetyManagementCapaOwnerIncidentExcel}
                childrenAfterAddButton={tabButtons}
                breadCrumb={[
                  {
                    name: "Safety Management",
                    path: "#",
                  },
                  {
                    name: "Incident Management",
                    path: "/safety-management-system/incident-management",
                  },
                  {
                    name: "My Approval History",
                    path: "#",
                  },
                ]}
                customTableTitle={"Blank"}
                icons
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default MyApprovalCapOwnerIncidentHistoryList;
