import React, { useCallback, useMemo, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Srcollbar from "react-perfect-scrollbar";
import { PieChartCard } from "../ChartCard/ChartCard";

const PieChartWithModal = React.memo(function PieChartWithModal({
  title,
  seriesData,
  id,
  isMobile,
  pieChartColour,
  height,
  unit,
  customTitle,
}) {
  const [modelChart, setModelChart] = useState({});
  const [shows, setShows] = useState(false);

  const handleClose = useCallback(() => {
    setShows((prev) => !prev);
  }, []);

  const getPieElementData = useCallback(
    (e) => {
      // console.log("eeeee", e);
      setModelChart(e?.data?.modalCharts || []);
      handleClose();
    },
    [handleClose]
  );
  const hoSiteData = useMemo(
    () => modelChart?.charts?.find((item) => item?.hoSite),
    [modelChart?.charts]
  );

 

  return (
    <>
      <PieChartCard
        title={title}
        seriesData={seriesData || []}
        customLabelFalse={true}
        isCustomLegend={true}
        id={id}
        customTitle={customTitle}
        isMobile={isMobile}
       
        {...{ dataFromChartCard: getPieElementData }}
        pieChartColour={pieChartColour}
        height={height}
        unit={unit}
      />

      <Modal size={"lg"} show={shows} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>{modelChart?.label}</Modal.Header>

        <Modal.Body style={{ overflow: "hidden" }}>
          <Srcollbar className="modalTableFixedheight">
            <div className="card" style={{ overflow: "visible" }}>
              <div className="card-body">
                <div className="row justify-content-center">
                 
                </div>
                <Row className="screenOne">
                  {modelChart?.charts?.map(
                    (item, idx) =>
                      !item?.hoSite && (
                        <Col
                          lg={6}
                          id={`modal_pie_chart_${idx}`}
                          key={item?.label}
                        >
                          <PieChartCard
                            id={`modal_pie_chart_${idx}`}
                            title={item?.label}
                            isMobile={isMobile}
                            seriesData={item.chartData}
                            dataFromChartCard={getPieElementData}
                            isClick={false}
                            customLabelFalse={true}
                            isCustomLegend={true}
                          />
                        </Col>
                      )
                  )}
                </Row>
              </div>
            </div>
          </Srcollbar>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default PieChartWithModal;
