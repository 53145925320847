import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import { safetyManagementFilterForm } from "../DATA";
import filter from "../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../../_helpers/hooks";
import {
  exportAccidentCiData,
  getAllCsisDataListWithPagination,
} from "../../../services/_safetyManagement/safetyManagement";
import SafetyManagementFilterForm from "../SafetyManagementFilterForm";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import { SidebarData, filterOptionFunc } from "../IncidentManagement/DATA";
import { csisDataApis, columnFields } from "./DATA";
import CsisView from "./CsisView";

const CsisDataList = () => {
  const [getAllFilter, setGetAllFilter] = useState();
  const [isListData, setIsListData] = useState(true);
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(safetyManagementFilterForm);
  const [lastSync, setLastSync] = useState({});

  const getAll = async () => {
    const response = await getAllCsisDataListWithPagination("page=1&limit=2");
    setLastSync(response?.lastSync?.data);
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    setGetAllFilter(filterOptionFunc(form));
  }, [filterStatus]);

  const onResetFilter = () => {
    setGetAllFilter();
    setForm(safetyManagementFilterForm);
  };

  const viewAsPopup = (form) => {
    return <CsisView form={form} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          className="secondaryBtn"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          data-testid="filter-button"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <SafetyManagementFilterForm
          onResetFilter={onResetFilter}
          form={form}
          onChange={onChange}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          csisFilter
        />
      </div>
    );
  };

  return isListData ? (
    <div className={`sidebarWrapper`}>
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={columnFields}
                apis={csisDataApis}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                // viewLink
                hideSnColumn
                breadCrumb={[
                  { name: "Safety Management", path: "#" },
                  {
                    name: "CSIS Data",
                    path: "#",
                  },
                ]}
                isNoTabOnPage
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                getAllFilter={getAllFilter}
                hideAddButton
                downloadExcel={exportAccidentCiData}
                customTableTitle={"CSIS Data"}
                viewTitle={"CSIS Details"}
                modalSize={"xl"}
                hideThreeDotButton
                apiUpdateView={lastSync}
                hideApiUpdateStatus={true}
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className={`sidebarWrapper`}>
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            hideAddBtn
            breadCrumb={[
              { name: "Safety Management", path: "#" },
              {
                name: "CSIS Data",
                path: "#",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CsisDataList;
