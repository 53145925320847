import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYHHMMAExcelFormat } from "../../_helpers/dateHelper";

const FuelStockView = ({ form }) => {

  return (
    <>
    <Modal.Body className="p-0">
      <div className="preview">
        <div className="previewContent">
          <p className="head">Stock Details</p>
          <Row>
            <Col sm={6}>
              <label>Receipt Date</label>
              <p className="content">{form?.receipt_date ? DDMMYYYYHHMMAExcelFormat(form?.receipt_date) : ""}</p>
            </Col>
            <Col sm={6}>
              <label>Subsidiary</label>
              <p className="content">{form?.fuelLocationCode?.site?.parentSite?.name}</p>
            </Col>
            <Col sm={6}>
              <label>Mine</label>
              <p className="content">{form?.fuelLocationCode?.site?.name}</p>
            </Col>
            <Col sm={6}>
              <label>Fuel Station Name</label>
              <p className="content">{form?.fuelLocationCode?.fuel_location_name}</p>
            </Col>
            <Col sm={6}>
              <label>Fuel Station Code</label>
              <p className="content">{form?.fuel_location_code}</p>
            </Col>
            <Col sm={6}>
              <label>Receipt Quantity</label>
              <p className="content">{form?.total_receipt_quantity}</p>
            </Col>
            <Col sm={6}>
              <label>DIP Data</label>
              <p className="content">{form?.fuel_inventory_sap_data[0]?.dip}</p>
            </Col>
            <Col sm={6}>
              <label>Opening Stock</label>
              <p className="content">{form?.fuel_inventory_sap_data[0]?.opening_stock}</p>
            </Col>
            <Col sm={6}>
              <label>Closing Stock</label>
              <p className="content">{form?.fuel_inventory_sap_data[0]?.closing_stock}</p>
            </Col>
          </Row>
        </div>
      </div>
    </Modal.Body>
  </>
  );
};

export default FuelStockView;
