import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import {
  PollutionControlMachineApis,
  reasonListColumnFields,
  SidebarData,
  siteBasedCollum,
  nonSiteBasedCollum,
  FuelConfigEquipmentApis,
  FuelConfigEquipmentTypeApis,
  FuelConfigLandReclamationApis,
  FuelConfigPowerConsumptionApis,
  FuelConfigPollutionApis,
  sectionApis,
} from "./DATA";
import filter from "../../../Assets/Images/filter.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { tabUrl } from "../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import { permissionCheck } from "../../../_services/Auth/helper";
import EmptyDataList from "../../ContractorManagement/ContractorPerformance/EmptyDataList";
import {
  getAllConfig,
  getAllConfigSite,
} from "../../../services/_reasonList/reasonList";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import PollutionControlFilterForm from "./Configuration/PollutionControlFilterForm";
import { useForm } from "../../../_helpers/hooks";
import { Button } from "reactstrap";
import { SidebarData as ContractorSideBar} from "../../ContractorManagement/DATA";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../_services/CommonService/CommonService";
import toastr from "toastr";

const PollutionControlMachineList = () => {
  const usrl = useLocation();
  const segments = usrl.pathname.split("/");
  const lastSegment = segments[segments.length - 1];
  console.log("4545454545",lastSegment);
  const words = lastSegment.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const title = capitalizedWords.join(" ");
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.envConfig)
  );
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const cref = useRef(null);
  let navigate = useNavigate();

  const onEdit = (id) => {
    navigate(lastSegment === "section"?`/contractor-management/configuration/${lastSegment}/${id}`:`/environment-management/configuration/${lastSegment}/${id}`);
  };

  const getAll = async () => {
    let response;
    if (
      lastSegment === "power_consumption" ||
      lastSegment === "land_reclamation"||
      lastSegment === "section"
    )
      response = await getAllConfigSite("page=1&limit=2", lastSegment);
    else response = await getAllConfig("page=1&limit=2", lastSegment);
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  // const onSubmitFilter = () => setFilterStatus((prev) => prev + 1);

  const [form, onChange, setForm] = useForm(PollutionControlFilterForm);
  const [filterStatus, setFilterStatus] = useState(0);

  // useEffect(() => {
  //   if (filterStatus === 0) return;

  //   let filter = "";
  //   for (const key in form) {
  //     const element = form[key];
  //     if (element) {
  //       if (key === "startDate" || key === "endDate")
  //         filter += `&${key}=${element}`;
  //       else filter += `&search[${key}]=${element}`;
  //     }
  //   }

  //   setGetAllFilter(filter);
  // }, [filterStatus]);

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d?.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    getAll();
  }, []);
  const fields =
    lastSegment === "land_reclamation" || lastSegment === "power_consumption"||lastSegment === "section"
      ? siteBasedCollum
      : nonSiteBasedCollum;

  let api;

  let modelNameIs;

  if (lastSegment === "equipment") {
    api = FuelConfigEquipmentApis;
    modelNameIs=`masterDropdowns`;
  } else if (lastSegment === "equipment_type") {
    api = FuelConfigEquipmentTypeApis;
    modelNameIs=`masterDropdowns`;
  } else if (lastSegment === "land_reclamation") {
    api = FuelConfigLandReclamationApis;
    modelNameIs=`siteBasedMasterDropdowns`;
  } else if (lastSegment === "power_consumption") {
    api = FuelConfigPowerConsumptionApis;
    modelNameIs=`siteBasedMasterDropdowns`;
  }
  else if (lastSegment === "section") {
    api = sectionApis;
    modelNameIs=`siteBasedMasterDropdowns`;
  } else if (lastSegment === "pollution_control_machine") {
    api = FuelConfigPollutionApis;
    modelNameIs=`siteBasedMasterDropdowns`;
  }

  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: modelNameIs,
        id: id,
        status: status
      }
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const finalFields = useMemo(
    () => [
      ...fields,
      {
        name: "Status",
        selector: "status",
        cell: (row) => {
          return (
            (row.status === 1 && (
              <span
                href="#"
                className="icons text-success pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOn
                  size="20"
                  data-testid={"toogle-user-list-on-" + row.id}
                />
              </span>
            )) ||
            (row.status === 0 && (
              <span
                href="#"
                className="icons text-danger pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOff
                  size="20"
                  data-testid={"toogle-user-list-off-" + row.id}
                />
              </span>
            ))
          );
        },
      },
    ],
    []
  );

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={lastSegment === "section"?ContractorSideBar:SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                hideSnColumn
                fields={finalFields}
                apis={api}
                title="Data"
                pageLink={lastSegment === "section"?`/contractor-management/configuration/${lastSegment}`:`/environment-management/configuration/${lastSegment}`}
                primaryKey="id"
                pagination
                hideEditButton
                // FilterBtn={FilterBtn}
                getAllFilter={getAllFilter}
                // hideAddButton={!permissionCheck(["super_admin", "env_admin"])}
                listname={"stationcategory"}
                hideDeleteButton
                cref={cref}
                breadCrumb={lastSegment === "section"?[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/contractor-management/configuration",
                  },
                  {
                    name: "Section",
                    path: "#",
                  },
                ]:[
                  {
                    name: "Environment Management System",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/environment-management/configuration",
                  },
                  {
                    name: title,
                    path: "#",
                  },
                ]}
                isNoTabOnPage
                deleteFun
                customTableTitle={title}
                editFunButton={onEdit}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={lastSegment === "section"?ContractorSideBar:SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            // hideAddBtn={!permissionCheck(["super_admin", "env_admin"])}
            breadCrumb={lastSegment === "section"?[
              {
                name: "Contractor Management",
                path: "#",
              },
              {
                name: "Configuration",
                path: "/contractor-management/configuration",
              },
              {
                name: "Section",
                path: "#",
              },
            ]:[
              {
                name: "Environment Management System",
                path: "#",
              },
              {
                name: "Configuration",
                path: "/environment-management/configuration",
              },
              {
                name: title,
                path: "#",
              },
            ]}
            pageLink={lastSegment === "section"?`/contractor-management/configuration/${lastSegment}`:`/environment-management/configuration/${lastSegment}`}
            title={title}
          />
        </div>
      </div>
    </div>
  );
};

export default PollutionControlMachineList;
