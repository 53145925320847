import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../_helpers/dateHelper";
import { getAllCsisDataListWithPagination } from "../../../services/_safetyManagement/safetyManagement";

export const csisDataApis = Object.freeze({
  getAll: {
    api: getAllCsisDataListWithPagination,
  },
});

export const columnFields = [
  {
    name: "Subsidiary",
    selector: "sub_code",
    sortable: true,
    //   cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  {
    name: "Accident ID",
    selector: "accident_id",
    sortable: true,
    // omit: true,
  },
  {
    selector: "category_name",
    name: "Category Name",
    sortable: true,
    //   minWidth: "150px",
  },
  {
    selector: "area_name",
    name: "Area Name",
    sortable: true,
  },
  {
    name: "Accident Date",
    selector: "date_of_accident",
    cell: (row) => DDMMYYYYFormat(row?.date_of_accident),
    sortable: true,
  },
  {
    name: "Creation Date Time",
    selector: "creation_datetime",
    cell: (row) =>
      row?.creation_datetime
        ? DDMMYYYYHHMMAFormat(row?.creation_datetime)
        :   "Not Available",
    sortable: true,
  },
];
