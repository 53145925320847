import React, { useState, useEffect } from "react";
import filter from "../../../../Assets/Images/filter.svg";
import { Container } from "react-bootstrap";
import { Button } from "reactstrap";
import { receiptApi, receiptFields, receiptFilterForm } from "./DATA";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../FuelManagement/DATA";
import {
  exportFuelReceipt,
  getAllTank,
} from "../../../../services/_fuelDispense/fuelDispense";
import "../../fuelmgmt.scss";
import ReceiptView from "./ReceiptView";
import { useForm } from "../../../../_helpers/hooks";
import ReceiptFilter from "./ReceiptFilter";
import {
  IS_MOBILE,
  isDateFromThreeDaysBack,
} from "../../../../_helpers/helper";

const ReceiptList = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [isListData, setIsListData] = useState(true);
  const [filterStatus, setFilterStatus] = useState(0);
  const [getAllFilter, setGetAllFilter] = useState();
  const [form, onChange, setForm] = useForm(receiptFilterForm);

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    let response;
    if (params?.id)
      response = await getAllTank("page=1&limit=2&createdBy=" + params?.id);
    else response = await getAllTank("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);
  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }
    if (params?.id) filter += `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [filterStatus]);
  const viewAsPopup = (form) => {
    return <ReceiptView form={form} />;
  };

  const onEdit = (id) => {
    navigate("/fuel-management-system/fuel-management-list/fuel-receipt/" + id);
  };

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);

  const onResetFilter = () => {
    setForm({ receiptFilterForm });
    if (!params?.id) setGetAllFilter();
    else setGetAllFilter("&createdBy=" + params?.id);
  };
  const FilterBtn = () => {
    const [open, setOpen] = useState(false);
    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <ReceiptFilter
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          setForm={setForm}
          showFuelFilterOptions={"true"}
          hideBowser={true}
          // hideWorkshop={true}
        />
      </div>
    );
  };
  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const statusByOptions = (row) => {
    if (isDateFromThreeDaysBack(row?.receiptDate) === true && !params?.id) {
      return true;
    } else {
      return false;
    }
  };

  return isListData ? (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={"View Receipt"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={receiptFields}
                apis={receiptApi}
                title="Data"
                FilterBtn={FilterBtn}
                downloadExcel={exportFuelReceipt}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={
                  "/fuel-management-system/fuel-management-list/fuel-receipt"
                }
                primaryKey="id"
                pagination={true}
                hideDeleteButton
                hideEditButton
                hideAddButton
                getAllFilter={getAllFilter}
                breadCrumb={[
                  {
                    path: "#",
                    name: "Fuel Management System",
                  },
                  {
                    name: "Fuel Management",
                    path: "/fuel-management-system",
                  },
                  {
                    name: "View Receipt List",
                    path: "#",
                  },
                ]}
                customTableTitle={"View Receipt List"}
                editFunButton={onEdit}
                viewTitle={"Receipt Details"}
                isNoTabOnPage
                statusByOptions={statusByOptions}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            hideAddButton
            pageLink={
              "/fuel-management-system/fuel-management-list/fuel-receipt"
            }
            title={"Receipt List"}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceiptList;
