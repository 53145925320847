import React, { useEffect, useState } from "react";
import {
  initialForm,
  validationRules,
  receiptApi,
  receiptLabel,
  initialMaxObj,
} from "./DATA";
import { Col, Form, Row, Button } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { SidebarData } from "../../FuelManagement/DATA";
import {
  auth_user,
  digitCheckAfterDecimal,
  IS_MOBILE,
  IS_USER_SITE_MINES,
} from "../../../../_helpers/helper";
import { useForm } from "../../../../_helpers/hooks";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import { Validation } from "../../../../_helpers/validation/Validation";
import {
  updateReceipt,
  addReceipt,
  getTankDataBydataId,
  exportMaxFuelDispenseQuantity,
  exportMaxFuelSpaceAvailableQuantity,
  getAllSupplier,
} from "../../../../services/_fuelDispense/fuelDispense";
import toastr from "toastr";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import Select from "../../../../components/Select/Select";
import { getAllMasterDropdownSupplier } from "../../../../services/_reasonList/reasonList";

const ReceiptAdd = () => {
  const params = useParams();
  const [mineList, setMineList] = useState([]);
  const [tankOption, setTankOption] = useState([]);
  const [supplierOption, setSupplierOption] = useState([]);
  const [maxAvailable, setMaxAvailable] = useState(initialMaxObj);
  const navigate = useNavigate();
  const [form, onChange, setForm] = useForm(initialForm);
  const [error, setError] = useState({
    ...initialForm,
  });
  let isMobile = IS_MOBILE();
  const onSubmit = async () => {
    const errorObj = Validation(form, validationRules);
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }

    let response = params?.id
      ? await updateReceipt(form, params.id)
      : await addReceipt(form);

    if (!response?.status) {
      return toastr.error(response?.message || "something went wrong");
    }
    if (response?.status) toastr.success(`Saved Successfully`);

    if (isMobile)
      navigate(
        "/mobile/fuel-management-system/fuel-management-list/my-fuel-receipt"
      );
    else
      navigate("/fuel-management-system/fuel-management-list/my-fuel-receipt");
  };

  async function fetchData() {
    let response = await receiptApi.getById.api(params?.id);
    if (response?.status) {
      setForm(() => {
        return {
          site_id: response?.data?.site_id,
          tank_id: response?.data?.tank_id,
          supplier_id: response?.data?.supplier_id,
          receiptDate: response?.data?.receiptDate,
          receiptQyt: response?.data?.receiptQyt,
          invoiceNumber: response?.data?.invoiceNumber,
          invoiceDate: response?.data?.invoiceDate,
        };
      });
    }
  }

  const mineLists = async () => {
    let list = [];
    const response = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );

    if (response.status) {
      list = response.data?.map((d) => {
        return { value: d.id, label: d.name };
      });
    }
    setMineList(list);
  };
  useEffect(() => {
    mineLists();
  }, []);
  useEffect(() => {
    if (form?.site_id) {
      let filter = `site_id=${form?.site_id}`;
      let id = null;
      getTankDataBydataId(id, filter).then((response) => {
        if (!response?.data) return;
        setTankOption(
          response.data
            .filter((ele) => ele?.status)
            ?.map((d) => {
              return { label: d.name, value: d.id };
            })
        );
      });
    }
  }, [form?.site_id]);
  useEffect(() => {
    if (params.id) fetchData();
  }, [params.id, setForm]);

  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj =
      mineList?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setForm((prev) => ({
      ...prev,
      site_id: siteObj?.value,
    }));
  }, [mineList]);

  let date = `date=${form?.receiptDate}`;

  const getMaxReceiptCapacity = async () => {
    let response = await exportMaxFuelSpaceAvailableQuantity(
      form?.tank_id,
      date
    );
    if (response.data.status) setMaxAvailable(response.data.data);
    else setMaxAvailable(initialMaxObj);
  };

  useEffect(() => {
    if (form?.tank_id && form?.receiptDate) getMaxReceiptCapacity();
  }, [form?.tank_id, form?.receiptDate]);

  useEffect(() => {
    getAllSupplier().then((response) => {
      if (!response?.status) return;
      setSupplierOption(
        response.data
          ?.filter((ele) => ele?.status)
          ?.map((d) => {
            return { label: d.supplierCode, value: d.id };
          })
      );
    });
  }, []);

  useEffect(() => {
    if (!params.id)
      setForm((prev) => {
        return {
          ...prev,
          tankAvailableCapacity: maxAvailable?.availableCapacity,
          tankLevel: maxAvailable?.tankLevel,
        };
      });
  }, [maxAvailable]);

  return (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={"Add Receipt"}
        goBack={() => navigate(-1)}
      />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              {!isMobile ? (
                <MainBreadcrumb
                  routeSegments={[
                    {
                      path: "#",
                      name: "Fuel Management System",
                    },
                    {
                      name: "Fuel Management",
                      path: "/fuel-management-system",
                    },
                    {
                      path: "#",
                      name: params?.id ? "Edit Receipt" : "Add Receipt",
                    },
                  ]}
                />
              ) : (
                ""
              )}
            </h3>

            <div className="reportCard">
              <div className="formWrapper">
                <div className="maverickFormCard">
                  <div className="creationWrapper">
                    <Form>
                      <Row>
                        <Col lg={6}>
                          <Select
                            onChange={onChange}
                            name="site_id"
                            options={mineList}
                            label={receiptLabel.site_id}
                            value={form?.site_id}
                            error={error?.site_id}
                            disabled={IS_USER_SITE_MINES()}
                          />
                        </Col>
                        <Col lg={6}>
                          <Select
                            onChange={onChange}
                            name="tank_id"
                            options={tankOption}
                            label={receiptLabel.tank_id}
                            value={form?.tank_id}
                            error={error?.tank_id}
                          />
                        </Col>
                        <Col lg={6}>
                          <Select
                            onChange={onChange}
                            name="supplier_id"
                            options={supplierOption}
                            label={"Supplier"}
                            value={form?.supplier_id}
                            error={error?.supplier_id}
                          />
                        </Col>
                        <Col lg={6}>
                          <Form.Label className="form-label-input">
                            {receiptLabel.receiptDate}
                          </Form.Label>
                          <CustomDatePicker
                            value={form.receiptDate}
                            isValidDate={(day) =>
                              day.isSameOrAfter(moment().subtract(3, "d")) &&
                              day.isSameOrBefore(moment())
                            }
                            name="receiptDate"
                            onChange={onChange}
                            dateFormat="YYYY-MM-DD"
                            error={error.receiptDate}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="invoiceNumber"
                            value={form.invoiceNumber}
                            onChange={onChange}
                            type="string"
                            class="form-control"
                            placeholder=""
                            label={receiptLabel.invoiceNumber}
                            error={error?.invoiceNumber}
                          />
                        </Col>
                        <Col lg={6}>
                          <Form.Label className="form-label-input">
                            {receiptLabel.invoiceDate}
                          </Form.Label>
                          <CustomDatePicker
                            value={form.invoiceDate}
                            isValidDate={(day) =>
                              day.isSameOrAfter(moment().subtract(3, "M")) &&
                              day.isSameOrBefore(moment())
                            }
                            name="invoiceDate"
                            onChange={onChange}
                            dateFormat="YYYY-MM-DD"
                            error={error.invoiceDate}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="receiptQyt"
                            value={form.receiptQyt}
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              digitCheckAfterDecimal(value, 2) &&
                              onChange({ target: { name, value } })
                            }
                            type="number"
                            class="form-control"
                            placeholder=""
                            label={receiptLabel.receiptQyt}
                            error={error?.receiptQyt}
                          />
                        </Col>
                      </Row>

                      {form.tank_id && (
                        <Row>
                          <Col lg={4} className="mt-3">
                            <p>
                              Tank Max Capacity (L) :{" "}
                              {maxAvailable?.maxCapacity}
                            </p>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <p>
                              Available Fuel Capacity in Tank (L) :{" "}
                              {maxAvailable?.availableCapacity}
                            </p>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <p>
                              Fuel Level in Tank (L) : {maxAvailable?.tankLevel}
                            </p>
                          </Col>
                        </Row>
                      )}
                    </Form>
                    <div className="btnSec">
                      <Button
                        data-testid="next-btn"
                        onClick={() =>
                          !isMobile
                            ? navigate(`/fuel-management-system`)
                            : navigate(`/mobile/fuel-management-system`)
                        }
                        className="secondaryBtn iconBtnForm"
                      >
                        Back
                      </Button>
                      <Button
                        data-testid="next-btn-submit"
                        onClick={() => onSubmit()}
                        className="primaryBtn iconBtnForm"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptAdd;
