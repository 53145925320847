import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import "./error.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();
  const history = createBrowserHistory();
  useEffect(() => {
    history.push("/error/404");
  }, [history]);

  return (
    <div className="errorWrapper">
      <div className="errorContent">
        <h1>404</h1>
        <h3>Look like you're lost</h3>
        <h6>The page you are looking for not available!</h6>
        {/* <h2>Go back to Home <BsArrowRight /> </h2> */}
        <Button className="primaryBtn" onClick={() => navigate("/")}>
          Go back to Home
        </Button>
      </div>
    </div>
  );
};

export default Error404;
