import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainBreadcrumb from "../../Breadcrumb/MainBreadcrumb";
import { Link } from "react-router-dom";

const ManageCardTab = ({ breadCrumbData, flowList = [] }) => {
  return (
    <div className="content" id="page-content-wrapper">
      <div className="contentArea">
        <Container fluid>
          <h3 style={{ paddingBottom: "1px", paddingTop: "5px" }}>
            <MainBreadcrumb
              routeSegments={breadCrumbData}
              // module={ACTIVE_MODULE.incident}
            />
          </h3>
          <div className="MgmtWrapper pt-4">
            <Row className="dashboardItem">
              {flowList?.map((d, idx) => {
                return (
                  <Col lg={4} key={idx} className="mb-4">
                    <Link to={d?.link}>
                      <div className="dashboardItemContent">
                        <div className="imgArea">
                          <img
                            src={d.img}
                            className="img-fluid"
                            alt="dashboardItemIcons"
                          />
                        </div>
                        <label>{d.label}</label>
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ManageCardTab;
