import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
const EnvImportSapView = ({ form }) => {
  return (
    <Modal.Body className="p-0">
      <div className="preview">
        <div className="previewContent">
          <Row>
            <Col md={6}>
              <label>Subsidiary</label>
              <p className="content">{form?.site?.parentSite?.name}</p>
            </Col>
            <Col md={6}>
              <label>Project</label>
              <p className="content">{form?.site?.name}</p>
            </Col>

            <Col md={6}>
              <label>Device Name</label>
              <p className="content">{form?.devices?.deviceName}</p>
            </Col>
            <Col md={6}>
              <label>Mine Name</label>
              <p className="content">{form?.mineName}</p>
            </Col>

            <Col md={6}>
              <label>Area Name</label>
              <p className="content">{form?.areaName}</p>
            </Col>
            {/* <Col md={6}>
              <label>Plant</label>
              <p className="content">{form?.plant}</p>
            </Col> */}

            <Col md={6}>
              <label>Company Name</label>
              <p className="content">{form?.companyName}</p>
            </Col>
            <Col md={6}>
              <label>PM2.5</label>
              <p className="content">{form?.pm25}</p>
            </Col>

            <Col md={6}>
              <label>PM10</label>
              <p className="content">{form?.pm10}</p>
            </Col>
            <Col md={6}>
              <label>SO2</label>
              <p className="content">{form?.so2}</p>
            </Col>

            <Col md={6}>
              <label>CO</label>
              <p className="content">{form?.co}</p>
            </Col>
            <Col md={6}>
              <label>NO</label>
              <p className="content">{form?.no}</p>
            </Col>

            <Col md={6}>
              <label>Creation Date</label>
              <p className="content">
                {form?.creationDate ? DDMMYYYYFormat(form?.creationDate) : ""}
              </p>
            </Col>
            <Col md={6}>
              <label>Created At</label>
              <p className="content">
                {form?.createdAt ? DDMMYYYYFormat(form?.createdAt) : ""}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </Modal.Body>
  );
};

export default EnvImportSapView;
