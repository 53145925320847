import React from "react";
import { EventFlowList, SidebarData } from "./DATA";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import "../../SafetyManagementSystem/IncidentManagement/IncidentMgmt.scss";
import ManageCardTab from "../../../components/Common/ManageCardTab/ManageCardTab";
import { isNclHoUser } from "../../../_services/CommonService/CommonService";

const ManageEnvironmentReport = () => {
  let filteredFlowList = [];
  let isNclOrHo = isNclHoUser();
  if (!isNclOrHo)
    filteredFlowList = EventFlowList.filter(
      (d) => d.label !== "Ambient Air Quality CAAQMS (API)"
    );
  else filteredFlowList = EventFlowList;
  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <ManageCardTab
          breadCrumbData={[
            {
              name: "Environment Management System",
              path: "/environment-management/manage",
            },
          ]}
          flowList={filteredFlowList}
        />
      </div>
    </>
  );
};

export default ManageEnvironmentReport;
