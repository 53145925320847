import {
  createIncidentKpi,
  updateIncidentKpi,
  getAllIncidentKpi,
  getAllIncidentKpiById,
  deleteIncidentKpi,
} from "../../../../services/_safetyManagement/incidentServices";

export const initialForm = {
  site_id: "",
  total_hrs: "",
  kpi_year: "",
};

export const addLink = "/safety-management-system/configuration/incident-kpi";

export const incidentKpiLocationRules = [
  {
    field_name: "site_id",
    label: "Mine",
    type: "integer",
    isRequired: true,
  },
  {
    field_name: "total_hrs",
    label: "Total hours",
    type: "integer",
    isRequired: true,
  },
  {
    field_name: "kpi_year",
    label: "KPI year",
    type: "integer",
    isRequired: true,
  },
];

export const incidentKpiApi = {
  getAll: {
    api: getAllIncidentKpi,
  },
  getById: {
    api: getAllIncidentKpiById,
  },
  delete: {
    api: deleteIncidentKpi,
  },
};
