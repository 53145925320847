import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Container, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toastr from "toastr";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import MessagePopup from "../../../../components/MessagePopup/MessagePopup";
import { activityInitialForm, activityLabel } from "./DATA";
import { ActivityService } from "../../../../_services/ConfigurationService/ActivityService";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Label } from "reactstrap";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { SidebarData } from "../../IncidentManagement/DATA";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import PerfectScrollbar from "react-perfect-scrollbar";

const ActivityAddEdit = () => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({
    ...activityInitialForm,
  });

  const [messagePopupType, setMessagePopupType] = useState(false);
  const [categoryFormFields, setCategoryFormFields] = useState([
    {
      name: "",
    },
  ]);

  const params = useParams("id");
  let navigate = useNavigate();

  const onChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const removeCategoryFields = (index) => {
    categoryFormFields?.splice(index, 1);
    setCategoryFormFields([...categoryFormFields]);
  };

  const activityById = useCallback(async () => {
    const response = await ActivityService.getActivityById(params?.id);
    if (!response?.status) return;
    setForm((prev) => ({
      ...prev,
      ...response?.data,
    }));
    const subActivityData = response?.data?.subActivity || [];
    setCategoryFormFields([...subActivityData]);
  }, [params?.id, setForm]);

  useEffect(() => {
    if (!params?.id) return;
    activityById();
  }, [params?.id, activityById]);

  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };

  const onChangeCategory = (e, index) => {
    const { value } = e.target;
    categoryFormFields[index].name = value;
    setCategoryFormFields([...categoryFormFields]);
  };

  const addCategoryFields = () => {
    let object = {
      name: "",
    };
    setCategoryFormFields([...categoryFormFields, object]);
  };

  function handleClick() {
    setShow(false);
    navigate("/safety-management-system/configuration/activity");
  }

  const handleSubmit = async () => {
    let data = {
      ...form,
      name: form?.name,
      subActivity: categoryFormFields,
    };

    const response = !params?.id
      ? await ActivityService.addActivity(data)
      : await ActivityService.updateActivity(params?.id, data);

    if (!response?.status || response?.status === 401)
      return !response?.status && toastr.error(response?.message);
    toastr.success(response?.message || `Updated`);
    setShow(true);
    setMessagePopupType(false);
  };

  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <div className="creationWrapper">
              <h3 style={{ paddingBottom: "1px", paddingTop: "5px" }}>
                <MainBreadcrumb
                  routeSegments={[
                    {
                      path: "#",
                      name: "Safety Management",
                    },
                    {
                      name: "Configuration",
                      path: "/safety-management-system/configuration",
                    },
                    {
                      path: "/safety-management-system/configuration/activity",
                      name: "Activity",
                    },
                    {
                      name: params?.id ? "Edit" : "Add",
                      path: "#",
                    },
                  ]}
                />
              </h3>
              <PerfectScrollbar className="customHeight">
                <h3 className="title">{`${
                  params?.id ? "Edit" : "Add"
                } Activity`}</h3>
                <div className="reportCard">
                  <div className="formWrapper rootCause">
                    <Form>
                      <Row className="mb-3">
                        <Col md={12}>
                          <FormInputText
                            placeHolder="Enter here"
                            label={activityLabel?.activity}
                            onChange={onChange}
                            name="name"
                            value={form?.name}
                            type="input"
                          />
                        </Col>
                        <Label>{activityLabel?.subActivity}</Label>
                        {Array.isArray(categoryFormFields) &&
                          categoryFormFields.map((data, index) => {
                            return (
                              <Row key={index} className="align-items-center">
                                {/* <Col md={index === 0 ? "12" : "10"}> */}
                                <Col md={6}>
                                  <div className="form-group">
                                    {/* <Label>{"Branch Number : " + (index + 1)}</Label> */}
                                    <FormInputText
                                      value={data?.name}
                                      type="input"
                                      placeHolder="Enter here"
                                      // label={activityLabel?.subActivity}
                                      name="subActivity"
                                      onChange={(e) =>
                                        onChangeCategory(e, index)
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col md="2" className="p-0 mt-4">
                                  {index !== 0 && (
                                    <span
                                      data-testid={"remove-branch" + index}
                                      className="icons font-21 text-danger remove"
                                      onClick={() =>
                                        removeCategoryFields(index)
                                      }
                                    >
                                      <AiOutlineMinus /> Remove Sub Activity
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            );
                          })}
                        <Row className="mb-3">
                          <Col md="4">
                            <span
                              onClick={() => addCategoryFields()}
                              data-testid="add-branch"
                              className="addTxt"
                            >
                              {" "}
                              <AiOutlinePlus />
                              Add Sub Activity
                            </span>
                          </Col>
                        </Row>
                      </Row>
                    </Form>
                  </div>

                  <div className="btnSec">
                    <Button
                      data-testid="handle-click-for-show"
                      className="secondaryBtn"
                      onClick={closeFun}
                    >
                      Close
                    </Button>
                    <div>
                      <Button
                        className="primaryBtn"
                        data-testid="submit-hindrance"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>

              <MessagePopup
                messagePopupType={messagePopupType}
                closePopup={handleClick}
                show={show}
                setShow={setShow}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityAddEdit;
