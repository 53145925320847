import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  CategoryRules,
  editStatusDropdown,
  initialForm,
  navigateUrl,
  safetyManagementApis,
  statusList,
  StatusUpdateRules,
  initialCapa,
  capaFormRules,
  startCloseDateTimeLabel,
  startCloseDateTimeKey,
  checkStartCloseDateTimeByStatus,
  checkPreviousDateKey,
  incidentCloseLabel,
  assignToRules,
} from "./DATA";
import toastr from "toastr";
import { useForm } from "../../_helpers/hooks";
import { Validation } from "../../_helpers/validation/Validation";
import FormTextArea from "../../components/FormTextArea/FormTextAres";
import { changeIncidentStatus } from "../../services/_safetyManagement/safetyManagement";
import { DDMMYYYYFormat, DDMMYYYYHHMMAFormat } from "../../_helpers/dateHelper";
import RootCause from "./RootCause";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { UserService } from "../../_services/UserService/UserService";
import {
  IS_MOBILE,
  NUMBER_BY_TRUE_FALSE,
  TRUE_FALSE_BY_NUMBER,
  capitalizeName,
} from "../../_helpers/helper";
import SafetySideBar from "./SafetySideBar/SafetySideBar";
import {
  SidebarData,
  incidentTypesObj,
  initialEvidence,
  roleData,
  informatinsInitialObj,
  informationRules,
  responsiblePersonForm,
  responsibilityRules,
} from "./IncidentManagement/DATA";
import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";
import IncidentEvidences from "./IncidentManagement/components/IncidentEvidences";
import CustomRadio from "../../components/_form/CustomRadio/CustomRadio";
import FormInputText from "../../components/FormInputText/FormInputText";
// import CapasComponent from "./IncidentManagement/components/CapasComponent";
// import CustomDatePicker from "../../components/DateTimeCustom/CustomDateTime";
import moment from "moment";
import { getAllMasterDropdownDesignation } from "../../services/_reasonList/reasonList";
import AsterikOnly from "../../components/Common/Asterik/AstrikOnly";
let CompensationInfor;
if (IS_MOBILE()) {
  CompensationInfor =
    require("./IncidentManagement/components/mobile/ReportForm/CompensationInfor").default;
} else {
  CompensationInfor =
    require("./IncidentManagement/components/ReportForm/CompensationInfor").default;
}

let Select;
let CustomDatePicker;
if (IS_MOBILE()) {
  Select = require("../../components/MobileComponents/Select/Select").default;
  CustomDatePicker =
    require("../../components/MobileComponents/DateTimeCustom/CustomDateTime").default;
} else {
  Select = require("../../components/Select/Select").default;
  CustomDatePicker =
    require("../../components/DateTimeCustom/CustomDateTime").default;
}

let CapasComponent;
if (IS_MOBILE()) {
  CapasComponent =
    require("./IncidentManagement/components/mobile/ReportForm/CapasComponent").default;
} else {
  CapasComponent =
    require("./IncidentManagement/components/CapasComponent").default;
}

let ResponsiblePersonInfo;
if (IS_MOBILE()) {
  ResponsiblePersonInfo =
    require("./IncidentManagement/components/mobile/ReportForm/ResponsibleInfoMobile").default;
} else {
  ResponsiblePersonInfo =
    require("./IncidentManagement/components/ReportForm/ResponsiblePeronInfo").default;
}

// let IncidentEvidenceTab;
// if (IS_MOBILE()) {
//   IncidentEvidenceTab =
//     require("../../components/mobile/ReportForm/IncidentEvidenceTab").default;
// } else {
//   IncidentEvidenceTab =
//     require("./IncidentManagement/components/ReportForm/IncidentEvidenceTab").default;
// }

const SafetyManagementEdit = () => {
  const params = useParams("id");
  let navigate = useNavigate();
  const [form, onChange, setForm] = useForm({
    ...initialForm,
  });
  const [usersList, setUsersList] = useState([]);
  const [error, setError] = useState({});
  const [rootCauseErr, setRootCauseErr] = useState([]);
  const [commentFlag, setCommentFlag] = useState(false);
  const [editStatusList, setEditStatusList] = useState([]);
  const [rootCause, setRootCause] = useState(true);
  const [rootDesc, setRootDesc] = useState("");
  const [rootDescErr, setRootDescErr] = useState("");
  const [capas, setCapas] = useState([JSON.parse(JSON.stringify(initialCapa))]);
  const [capasErrors, setCapasErrors] = useState([]);
  const [informatinsDetails, setInformatinsDetails] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [
    informationResponsibilityDetails,
    setInformationResponsibilityDetails,
  ] = useState([JSON.parse(JSON.stringify(responsiblePersonForm))]);

  const [informationErrors, setInformationErrors] = useState([]);
  const [responsibilityErrors, setResponsibilityErrors] = useState([]);
  const [categoryFormFields, setCategoryFormFields] = useState([
    {
      id: null,
      branch_number: "",
      branch: "",
      leafOb: [{ id: null, leaf_number: "", leaf: "" }],
    },
  ]);
  const [evidences, setEvidences] = useState([
    JSON.parse(JSON.stringify(initialEvidence)),
  ]);
  const [evidencesErrors, setEvidencesErrors] = useState([]);

  let rootCauseValue = 0;

  const removeCategoryFields = (index) => {
    categoryFormFields?.splice(index, 1);
    rootCauseErr?.splice(index, 1);
    setCategoryFormFields([...categoryFormFields]);
  };

  const addCategoryFields = () => {
    if (categoryFormFields.length < 10) {
      let object = {
        id: null,
        branch_number: "",
        branch: "",
        leafOb: [{ id: null, leaf_number: "", leaf: "" }],
      };
      setCategoryFormFields([...categoryFormFields, object]);
    } else {
      toastr.error("Maximum 10 Branch Allowed");
    }
  };

  const addFields = (index) => {
    if (categoryFormFields[index].leafOb.length < 3) {
      let object = {
        id: null,
        leaf_number: "",
        leaf: "",
      };
      categoryFormFields[index].leafOb = [
        ...categoryFormFields[index].leafOb,
        object,
      ];
      setCategoryFormFields([...categoryFormFields]);
    } else {
      toastr.error("Maximum 3 Leaf Allowed");
    }
  };

  const removeFields = (index, i) => {
    rootCauseErr[index]?.leaf?.splice(i, 1);
    categoryFormFields[index]?.leafOb?.splice(i, 1);
    setCategoryFormFields([...categoryFormFields]);
  };

  const onChangeSubField = (e, index, i) => {
    const { value } = e.target;
    categoryFormFields[index].leafOb[i].leaf = value;
    setCategoryFormFields([...categoryFormFields]);
  };

  const onChangeCategory = (e, index) => {
    const { value } = e.target;
    categoryFormFields[index].branch = value;
    setCategoryFormFields([...categoryFormFields]);
  };
  const onChangeRootDesc = (e, index) => {
    const { value } = e.target;
    setRootDesc(value);
    setRootDescErr("");
  };

  const onRootCauseChange = (e, index) => {
    setRootCauseErr([]);
    setRootDescErr("");
    const { checked } = e.target;
    if (checked === true) {
      setCategoryFormFields([
        {
          id: null,
          branch_number: "",
          branch: "",
          leafOb: [{ id: null, leaf_number: "", leaf: "" }],
        },
      ]);
    } else {
      setCategoryFormFields([]);
      setRootDesc("");
    }
    setRootCause(checked);
  };
  const navigator = useNavigate();

  const getAll = async (filter) => {
    let response = await safetyManagementApis.getAll.api(filter);
    return response?.data || [];
  };

  useEffect(() => {
    if (!params?.id) return;
    (async () => {
      let status;
      let response = await getAll(`search[id]=${params?.id}`);

      response?.map((data) => {
        status = data?.status;
        setForm((prev) => {
          return {
            ...prev,
            ...data,
            dgmsFilled: data.dbms_id ? true : false,
            isCompensationInfor: TRUE_FALSE_BY_NUMBER(
              data?.isCompensationInfor
            ),
          };
        });
        const compenInforData = data?.personalDetails?.map((d) => ({
          id: d?.id,
          outcome: d?.outcome,
          injuryCode: d?.injuryCode,
          ...informatinsInitialObj,
          dateOfReturnDuty: d?.outcome === "Fatality" ? "Not Applicable" : "",
          returnForRegular: d?.outcome === "Fatality" ? "Not Applicable" : "",
          name: d?.name,
        }));
        setInformatinsDetails(compenInforData);
      });

      setEditStatusList(
        Object.keys(statusList)
          .filter((d, i) => editStatusDropdown[status].find((s) => s === d))
          .map((data) => {
            return { label: statusList[data], value: data };
          })
      );
    })();
  }, [params?.id]);
  useEffect(() => {
    if (form?.site_id) {
      UserService.getUsersByRole(
        `filter[status]=1&site_id=${form.site_id}`,
        roleData
      ).then((response) => {
        if (!response?.data) return;
        setUsersList(
          response?.data.map((d, i) => ({
            ...d,
            name_email_employeeId: `${capitalizeName(
              d.firstName + " " + d.lastName
            )} / ${d.email}`,
          }))
        );
      });
    }
  }, [form.site_id]);

  const handleSubmit = async () => {
    let rootCauseSubErr = [];
    let capaFlag = 0;
    let inforFlag = 0;
    let responsibilitiesFlag = 0;
    let dgmsId = 0;

    let finalStatusUpdateRules = StatusUpdateRules;
    if (form?.change_status === "investigation_in_progress") {
      !finalStatusUpdateRules?.find(
        (d) => d?.field_name === "currentAssignedUser"
      ) && finalStatusUpdateRules.push(assignToRules);
    } else {
      finalStatusUpdateRules = finalStatusUpdateRules?.filter(
        (d) => d?.field_name !== "currentAssignedUser"
      );
    }
    let errorObj = Validation(form, finalStatusUpdateRules);
    setError(errorObj);

    if (
      form?.change_status === "incident_closed" &&
      form.isDgmsReportable &&
      (form.dbms_id === null || form.dbms_id === "")
    ) {
      dgmsId = 1;
      setError((prev) => {
        return {
          ...prev,
          dbms_id: "DGMS ID required",
        };
      });
    } else {
      dgmsId = 0;
      setError((prev) => {
        return {
          ...prev,
          dbms_id: "",
        };
      });
    }

    if (
      form?.change_status === "investigation_completed" &&
      rootCause === true
    ) {
      if (rootDesc === "") {
        setRootDescErr("Root Cause Description is Required");
      }
    }
    categoryFormFields.forEach((d, i) => {
      rootCauseSubErr = [];
      rootCauseErr[i] = Validation(d, CategoryRules);
      d.leafOb.forEach((data, index) => {
        if (data.leaf === "") {
          rootCauseSubErr[index] = { leaf: "Leaf is Required." };
        } else {
          rootCauseSubErr[index] = { leaf: "" };
        }
      });
      rootCauseErr[i].leaf = rootCauseSubErr;
    });

    const capaErrorArray = capas.map((data) =>
      data.deleted ? {} : Validation(data, capaFormRules)
    );

    setCapasErrors(capaErrorArray);
    let finalInformationRules = informationRules?.filter(
      (d) =>
        (form?.category !== "fatality" &&
          d?.field_name !== "compensationPaid") ||
        form?.category === "fatality"
    );
    const informationErrorArray = informatinsDetails.map((data) => {
      if (data.outcome !== "Fatality") {
        let updatedValidationRule = finalInformationRules.filter(
          (d) =>
            d.field_name !== "causeOfDeath" &&
            d.field_name !== "dateOfReturnDuty" &&
            d.field_name !== "returnForRegular" &&
            d.field_name !== "exGratiaPaid" &&
            d.field_name !== "otherBenefits" &&
            d.field_name !== "statutoryCompensationPaid"
        );
        return Validation(data, updatedValidationRule);
      }
      return Validation(data, finalInformationRules);
    });
    setInformationErrors(informationErrorArray);

    const responsibleArrayError = informationResponsibilityDetails.map(
      (data) => {
        return Validation(data, responsibilityRules);
      }
    );
    setResponsibilityErrors(responsibleArrayError);

    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    if (form?.change_status === "capa_assigned" && !capas?.length) {
      toastr.error("At least one capa should be there.");
      return;
    }

    for (const errObj of capaErrorArray)
      for (const key in errObj)
        if (errObj[key]) {
          capaFlag = 1;
          break;
        }
    if (form?.change_status === "capa_assigned" && capaFlag) return;

    for (const errObj of informationErrorArray)
      for (const key in errObj)
        if (errObj[key]) {
          inforFlag = 1;
          break;
        }

    if (
      (form?.change_status === "incident_closed" &&
        form?.isCompensationInfor &&
        inforFlag) ||
      dgmsId
    ) {
      return;
    }

    for (const errObj of responsibleArrayError)
      for (const key in errObj)
        if (errObj[key]) {
          responsibilitiesFlag = 1;
          break;
        }
    if (
      form?.change_status === "incident_closed" &&
      form?.isResponsiblePersonInfo &&
      responsibilitiesFlag
    )
      return;

    let errorCount = 0;
    if (
      form?.change_status === "investigation_completed" &&
      rootCause === true
    ) {
      if (rootDesc === "") {
        setRootDescErr("Root Cause Description is Required");
        errorCount = 1;
      }
      rootCauseErr.forEach((data, index) => {
        if (data.branch !== "") {
          errorCount = 1;
        } else {
          data.leaf.forEach((d, i) => {
            if (d.leaf !== "") {
              errorCount = 1;
            }
          });
        }
      });
    } else {
      setCategoryFormFields([]);
    }

    if (Object.values(errorObj).filter((d) => d !== "").length) return;
    if (errorCount === 1) return;

    if (rootCause === false) {
      rootCauseValue = 0;
    }
    if (rootCause === true) {
      rootCauseValue = 1;
    }

    let branchCounter = 1;
    let leafCounter = 1;
    const categoryFormFieldsNew = categoryFormFields.map((data) => {
      data.branch_number = branchCounter;
      data.leafOb.forEach((d) => {
        d.leaf_number = leafCounter;
        leafCounter++;
      });
      branchCounter++;
      return data;
    });

    let dueDateFlag = 0;
    if (form?.change_status === "capa_assigned") {
      capas?.forEach((d) => {
        if (
          !moment(
            !form?.capa_start_time
              ? moment().format("YYYY-MM-DD HH:mm")
              : form?.capa_start_time
          ).isBefore(moment(d?.dueDate))
        )
          dueDateFlag++;
      });
    }

    if (dueDateFlag) {
      toastr.error("Due Date should be greater from CAPA start date");
      return;
    }

    const startCloseDateObj = checkStartCloseDateTimeByStatus(
      form?.status,
      form?.change_status,
      form
    );
    let data = {
      ...form,
      dgmsId: !form?.isDgmsReportable ? "" : form?.dbms_id,
      status: form?.change_status,
      comment: form?.change_comment,
      branches: categoryFormFieldsNew,
      root_desc: rootDesc,
      root_cause: rootCauseValue,
      capas: form?.change_status == "capa_assigned" ? capas : [],
      currentAssignedUser:
        form?.change_status == "capa_assigned"
          ? null
          : form?.currentAssignedUser,
      evidences: evidences.map((d) => ({
        ...d,
        incidentId: params?.id,
      })),
      isCompensationInfor:
        form?.change_status == "incident_closed"
          ? NUMBER_BY_TRUE_FALSE(form?.isCompensationInfor)
          : 0,
      informatinsDetailData:
        form?.change_status == "incident_closed" && form?.isCompensationInfor
          ? informatinsDetails?.map((d) => ({
              ...d,
              dateOfReturnDuty:
                d?.dateOfReturnDuty === "Not Applicable"
                  ? null
                  : d?.dateOfReturnDuty,
            }))
          : [],
      responsibilitiesToAccident:
        form?.change_status == "incident_closed" &&
        form?.isResponsiblePersonInfo
          ? informationResponsibilityDetails
          : [],
      ...startCloseDateObj,
    };

    let response = await changeIncidentStatus(data);
    if (!response?.status) return toastr.error(response?.message);
    toastr.success(response?.message || `Updated`);
    navigator(navigateUrl);
  };

  const setCommentFlagFn = (e) => {
    if (e?.target?.value !== "investigation_completed") {
      setCategoryFormFields([]);
      setRootCauseErr([]);
      setRootDescErr("");
    }
    if (!e?.target?.value) setCommentFlag(false);
    else setCommentFlag(true);
  };
  const deleteCapa = (index) => {
    setCapas((prev) => {
      if (prev[index].id) prev[index].deleted = 1;
      else prev.splice(index, 1);

      return [...prev];
    });
  };
  const addCapa = () => {
    setCapas((prev) => [...prev, JSON.parse(JSON.stringify(initialCapa))]);
  };
  const onChangeCapa = ({ index, name, value }) => {
    setCapas((prev) =>
      prev.map((d, idx) => {
        if (idx !== index) return d;
        d[name] = value;

        return d;
      })
    );
  };
  useEffect(() => {
    UserService.getUsersByRole("filter[status]=1", roleData).then(
      (response) => {
        if (!response?.data) return;
        setUsersList(
          response?.data.map((d, i) => ({
            ...d,
            name_email_employeeId: `${capitalizeName(
              d.firstName + " " + d.lastName
            )} / ${d.email}`,
          }))
        );
      }
    );
    getDesignationOpt();
  }, []);
  const getDesignationOpt = async () => {
    let response = await getAllMasterDropdownDesignation();

    if (response.status) {
      setDesignations(response.data?.filter((d) => d?.status));
    }
  };
  const addEvidence = () =>
    setEvidences((previous) => [
      ...previous,
      JSON.parse(JSON.stringify({ ...initialEvidence })),
    ]);

  const onChangeEvidence = ({ name, value, type, checked, index }) => {
    setEvidences((prevEvidences) =>
      prevEvidences.map((data, idx) => {
        if (idx !== index) return data;

        data[name] = type === "checkbox" ? checked : value;
        return data;
      })
    );
  };

  const onChangeEvidenceFile = ({ responseData, index }) => {
    setEvidences((prevEvidences) =>
      prevEvidences.map((data, idx) => {
        if (idx !== index) return data;

        data.file = responseData;
        data.fileId = responseData.id;

        return data;
      })
    );
  };

  const deleteEvidence = (index) =>
    setEvidences((previous) =>
      previous
        .map((data, idx) =>
          idx !== index ? data : data.id ? { ...data, deleted: 1 } : null
        )
        .filter((data) => !!data)
    );

  const onChangeInformation = (index, name, value) => {
    setInformatinsDetails((prev) =>
      prev.map((d, idx) => {
        if (idx !== index) return d;
        d[name] = value;
        return d;
      })
    );
  };
  const onChangeResponsibilityInformation = (index, name, value) => {
    if (name === "designationCode") {
      let designation = designations.filter((d) => d.code === value);

      setInformationResponsibilityDetails((prev) =>
        prev.map((d, idx) => {
          if (idx === index) {
            d["designation"] = designation[0]?.name || "";
            // if (!("designation" in d)) {
            //   d["designation"] = designation[0]?.name || "";
            // }
            //  else {
            //   d["designation"] = designation[0]?.name || "";
            // }
          }
          return d;
        })
      );
    }
    setInformationResponsibilityDetails((prev) =>
      prev.map((d, idx) => {
        d[name] = value;
        // if (idx === index) {
        //   if (!(name in d)) {
        //     d[name] = value;
        //   }
        //   else {
        //     d[name] = value;
        //   }
        // }
        return d;
      })
    );
  };

  const addNewFieldResponsibleForm = () => {
    setInformationResponsibilityDetails((prev) => [
      ...prev,
      JSON.parse(JSON.stringify(responsiblePersonForm)),
    ]);
  };

  const removeLastResponsibleForm = (index) => {
    setInformationResponsibilityDetails((prev) =>
      prev.filter((_, i) => i !== index)
    );
  };
  return (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        pageTitle={"Edit"}
        goBack={() => navigate(-1)}
        SidebarData={SidebarData}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            {!IS_MOBILE() && (
              <h3 style={{ paddingBottom: "1px", paddingTop: "5px" }}>
                <MainBreadcrumb
                  routeSegments={[
                    { name: "Safety Management", path: "#" },
                    {
                      path: "/safety-management-system/incident-management",
                      name: "Incident Management",
                    },
                    {
                      name: "My Approval Actions",
                      path: "/safety-management-system/incident-management/incident-reported",
                    },
                    { name: "Edit", path: "#" },
                  ]}
                />
              </h3>
            )}
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="formWrapper">
                  <div className="maverickFormCard">
                    <div className="FormWrapper">
                      <Row className="border-bottom-0 safetyEdit">
                        <Col md={4}>
                          <label>Subsidiary</label>
                          <p className="content">
                            {form?.site?.parentSite?.name}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>Mine</label>
                          <p className="content">{form?.site?.name}</p>
                        </Col>
                        <Col md={4}>
                          <label>{startCloseDateTimeLabel[form?.status]}</label>
                          <p className="content">
                            {[
                              "investigation_completed",
                              "incident_closed",
                              "capa_closed",
                            ]?.includes(form?.status)
                              ? DDMMYYYYFormat(
                                  form?.[checkPreviousDateKey[form?.status]]
                                )
                              : DDMMYYYYHHMMAFormat(
                                  form?.[checkPreviousDateKey[form?.status]]
                                )}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>Department</label>
                          <p className="content">{form?.department}</p>
                        </Col>
                        <Col md={4}>
                          <label>Incident Category</label>
                          <p className="content">
                            {incidentTypesObj[form?.category]}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>Current Status</label>
                          <p className="content">{statusList[form?.status]}</p>
                        </Col>

                        {form?.status && form?.status !== "incident_closed" && (
                          <>
                            <Col md={4}>
                              <Select
                                onChange={(e) => {
                                  onChange(e);
                                  setCommentFlagFn(e);
                                }}
                                value={form?.change_status}
                                name="change_status"
                                options={editStatusList}
                                error={error?.change_status}
                                label={`Change Status`}
                                data-testid="change-status"
                              />
                            </Col>
                            {form?.change_status && (
                              <Col md={4}>
                                <Form.Label>
                                  {startCloseDateTimeLabel[form?.change_status]}
                                </Form.Label>
                                <CustomDatePicker
                                  isValidDate={(day) =>
                                    day.isBefore(moment()) &&
                                    day.isAfter(
                                      moment(
                                        form?.[
                                          checkPreviousDateKey[form?.status]
                                        ]
                                      ).add(-1, "days")
                                    )
                                  }
                                  value={
                                    form?.[
                                      startCloseDateTimeKey[form?.change_status]
                                    ]
                                      ? form?.[
                                          startCloseDateTimeKey[
                                            form?.change_status
                                          ]
                                        ]
                                      : moment().format("YYYY-MM-DD HH:mm")
                                  }
                                  name={
                                    startCloseDateTimeKey[form?.change_status]
                                  }
                                  onChange={onChange}
                                  timeFormat={
                                    [
                                      "investigation_completed",
                                      "incident_closed",
                                    ]?.includes(form?.change_status)
                                      ? false
                                      : "HH:mm"
                                  }
                                  dateFormat="YYYY-MM-DD"
                                  // error={error?.incident_reportdatetime}
                                />
                              </Col>
                            )}
                            {commentFlag &&
                              (["investigation_in_progress"].includes(
                                form?.change_status
                              )
                                ? true
                                : false) && (
                                <Col md={4}>
                                  <label>
                                    Assign To
                                    <AsterikOnly />
                                  </label>
                                  <Select
                                    // label={`Assign To`}
                                    name="currentAssignedUser"
                                    value={form?.currentAssignedUser}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    disabled={[
                                      "investigation_in_progress",
                                      "capa_assigned",
                                    ].includes(form?.status)}
                                    options={usersList}
                                    error={error?.currentAssignedUser}
                                    KEY_label={"name_email_employeeId"}
                                    placeholder={"Search by Name / Email Id"}
                                    KEY_value={"id"}
                                    blankSelect={"none"}
                                  ></Select>
                                </Col>
                              )}
                          </>
                        )}
                        {form?.change_status === "investigation_completed" && (
                          <Col md={4}>
                            <FormInputText
                              label={`Person responsible for the incident`}
                              name="personResponsible"
                              value={form?.personResponsible}
                              onChange={onChange}
                            ></FormInputText>
                          </Col>
                        )}

                        {(form?.category === "fatality" ||
                          form?.category === "dangerousOccurrence" ||
                          form?.category === "seriousInjury") &&
                        form?.change_status ? (
                          <>
                            {!form.dgmsFilled ? (
                              <>
                                {form?.change_status !==
                                  "investigation_in_progress" && (
                                  <Col lg={4}></Col>
                                )}
                                <Col lg={4}>
                                  <CustomRadio
                                    textStyle={"67px"}
                                    type="checkbox"
                                    name="isDgmsReportable"
                                    onChange={onChange}
                                    title={"DGMS Reportable"}
                                    acceptTitle={"Yes"}
                                    rejectTitle={"No"}
                                    customClass={`customRadioClass pt-4 ${
                                      IS_MOBILE() ? "d-block" : ""
                                    }`}
                                    value={form.isDgmsReportable ? true : false}
                                  />
                                </Col>
                                <Col md={4}>
                                  <FormInputText
                                    label={"DGMS ID"}
                                    name="dbms_id"
                                    value={
                                      form.isDgmsReportable ? form.dbms_id : ""
                                    }
                                    onChange={onChange}
                                    disabled={!form.isDgmsReportable}
                                    error={error?.dbms_id}
                                  />
                                </Col>
                              </>
                            ) : (
                              form?.change_status && (
                                <Col md={4}>
                                  <FormInputText
                                    label={"DGMS ID"}
                                    name="dbms_id"
                                    value={
                                      form.isDgmsReportable ? form.dbms_id : ""
                                    }
                                    onChange={onChange}
                                    disabled={true}
                                    error={error?.dbms_id}
                                  />
                                </Col>
                              )
                            )}
                          </>
                        ) : null}

                        {form?.change_status === "incident_closed" && (
                          <>
                            {form?.personalDetails?.length ? (
                              <CompensationInfor
                                form={form}
                                errors={informationErrors}
                                onChange={onChange}
                                onChangeInformation={onChangeInformation}
                                informatinsDetails={informatinsDetails}
                              />
                            ) : (
                              ""
                            )}
                            <ResponsiblePersonInfo
                              form={form}
                              errors={responsibilityErrors}
                              onChange={onChange}
                              onChangeInformation={
                                onChangeResponsibilityInformation
                              }
                              responsiblePersonForm={
                                informationResponsibilityDetails
                              }
                              addNewField={addNewFieldResponsibleForm}
                              removeLastField={removeLastResponsibleForm}
                              designations={designations}
                            />
                            <Col md={4}>
                              <FormInputText
                                label={`Loss of production (in tonnes)`}
                                name="lossOfProduction"
                                value={form?.lossOfProduction}
                                onChange={onChange}
                                type="number"
                              ></FormInputText>
                            </Col>
                            <Col md={4}>
                              <FormInputText
                                label={`Loss of manshifts – hours (Indirect loss) `}
                                name="lossOfManshifts"
                                value={form?.lossOfManshifts}
                                onChange={onChange}
                                type="number"
                              ></FormInputText>
                            </Col>
                            <Col md={4}>
                              <FormInputText
                                label={`Action taken to prevent similar accident`}
                                name="actionsToPrevent"
                                value={form?.actionsToPrevent}
                                onChange={onChange}
                                type="text"
                              ></FormInputText>
                            </Col>
                          </>
                        )}
                        {form?.change_status == "capa_assigned" && (
                          <CapasComponent
                            capas={capas}
                            // capasErrors={capasErrors}
                            onChangeCapa={onChangeCapa}
                            addCapa={addCapa}
                            deleteCapa={deleteCapa}
                            users={usersList}
                            form={form}
                            capasErrors={capasErrors}
                            capaFormRules={capaFormRules}
                            // departments={departments}
                            // area={area}
                            // incidentsOnly
                            // capaowner={capaowner}
                            // viewOnly={viewOnly}
                            // approveCapa={approveCapa}
                            // form={form}
                            // siteId={siteId}
                            // showReject
                          />
                        )}
                        {commentFlag && (
                          <Col md={12}>
                            <FormTextArea
                              label={
                                form?.change_status === "incident_closed"
                                  ? "Comments/Recommendation by ISO or Any Other"
                                  : "Comment"
                              }
                              error={error?.change_comment}
                              name={"change_comment"}
                              value={form?.change_comment}
                              onChange={onChange}
                              rows={3}
                              asterikRules={StatusUpdateRules}
                            />
                          </Col>
                        )}
                        {form?.change_status === "incident_closed" ? (
                          <Col md={12}>
                            <FormTextArea
                              label={
                                "How Accident Could have been avoided? (Optional)"
                              }
                              error={error?.howAvoided}
                              name={"howAvoided"}
                              value={form?.howAvoided}
                              onChange={onChange}
                              rows={3}
                              asterikRules={StatusUpdateRules}
                            />
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>

                      {form?.change_status === "investigation_completed" && (
                        <>
                          <Row>
                            <Col lg={4}>
                              <CustomRadio
                                type="checkbox"
                                name="investigationEvidencesRequired"
                                onChange={onChange}
                                title={"Evidence Available"}
                                acceptTitle={"Yes"}
                                rejectTitle={"No"}
                                customClass={"customRadioClass"}
                                value={!!form?.investigationEvidencesRequired}
                              />
                              <span className="small text-danger">
                                {error?.isAssetIncident}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            {!!form.investigationEvidencesRequired && (
                              <Col md="12">
                                <div className="formWrapper rootCause">
                                  <IncidentEvidences
                                    dataArray={evidences}
                                    errorArray={evidencesErrors}
                                    onChange={onChangeEvidence}
                                    onChangeFile={onChangeEvidenceFile}
                                    onAdd={addEvidence}
                                    source={"Investigation Evidence"}
                                    onDelete={deleteEvidence}
                                  />
                                </div>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <RootCause
                              categoryFormFields={categoryFormFields}
                              rootDesc={rootDesc}
                              removeCategoryFields={removeCategoryFields}
                              addCategoryFields={addCategoryFields}
                              addFields={addFields}
                              removeFields={removeFields}
                              onChangeSubField={onChangeSubField}
                              onChangeCategory={onChangeCategory}
                              onRootCauseChange={onRootCauseChange}
                              rootCause={rootCause}
                              errorCategory={rootCauseErr}
                              onChangeRootDesc={onChangeRootDesc}
                              rootDescErr={rootDescErr}
                              asterikRulesRoot={CategoryRules}
                            />
                          </Row>

                          <Row>
                            <Col lg={12}>
                              <FormTextArea
                                label={incidentCloseLabel.conclusion}
                                error={error?.conclusion}
                                name={"conclusion"}
                                value={form?.conclusion}
                                onChange={onChange}
                                rows={3}
                                asterikRules={StatusUpdateRules}
                              />
                            </Col>
                            <Col lg={12}>
                              <FormTextArea
                                label={incidentCloseLabel.averted}
                                error={error?.averted}
                                name={"averted"}
                                value={form?.averted}
                                onChange={onChange}
                                rows={3}
                                asterikRules={StatusUpdateRules}
                              />
                            </Col>
                            <Col lg={12}>
                              <FormTextArea
                                label={incidentCloseLabel.responsibilities}
                                error={error?.responsibilities}
                                name={"responsibilities"}
                                value={form?.responsibilities}
                                onChange={onChange}
                                rows={3}
                                asterikRules={StatusUpdateRules}
                              />
                            </Col>
                            <Col lg={12}>
                              <FormTextArea
                                label={incidentCloseLabel.recommendation}
                                error={error?.recommendation}
                                name={"recommendation"}
                                value={form?.recommendation}
                                onChange={onChange}
                                rows={3}
                                asterikRules={StatusUpdateRules}
                              />
                            </Col>
                            <Col lg={12}>
                              <FormTextArea
                                label={incidentCloseLabel.actionsTaken}
                                error={error?.actionsTaken}
                                name={"actionsTaken"}
                                value={form?.actionsTaken}
                                onChange={onChange}
                                rows={3}
                                asterikRules={StatusUpdateRules}
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      {form?.status && form?.status !== "incident_closed" && (
                        <div className="btnSec  justify-content-end">
                          <Button onClick={handleSubmit} className="primaryBtn">
                            Submit
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetyManagementEdit;
