import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";
import {
  getAllReceipt,
  getMyReceipt,
  getReceiptDataBydataId,
} from "../../../../services/_fuelDispense/fuelDispense";
export const initialForm = {
  tank_id: "",
  site_id: "",
  supplier_id: "",
  receiptDate: "",
  receiptQyt: null,
  invoiceDate: "",
  invoiceNumber: null,
};
export const initialMaxObj = {
  availableCapacity: "",
  maxCapacity: "",
  tankLevel: "",
};
export const receiptLabel = {
  tank_id: "Tank Name",
  site_id: "Mine",
  supplier: "Supplier",
  receiptDate: "Receipt Date",
  invoiceDate: "Invoice Date",
  invoiceNumber: "Invoice Number",
  receiptQyt: "Receipt Quantity (L)",
  tankMaxCapacity: "Tank Max Capacity (L)",
  tankLevelAfterReceipt: "Tank Level After Receipt (L)",
  availableTankCapacityAfterReceipt: "Tank Capacity After Receipt (L)",
};
export const validationRules = [
  {
    label: receiptLabel.tank_id,
    type: "string",
    isRequired: true,
    field_name: "tank_id",
  },
  {
    field_name: "site_id",
    label: receiptLabel.site_id,
    isRequired: true,
    type: "string",
  },
  {
    label: receiptLabel.supplier,
    field_name: "supplier_id",
    type: "string",
    isRequired: true,
  },
  {
    label: receiptLabel.receiptDate,
    field_name: "receiptDate",
    type: "string",
    isRequired: true,
  },
  {
    label: receiptLabel.invoiceDate,
    field_name: "invoiceDate",
    type: "string",
    isRequired: true,
  },
  {
    label: receiptLabel.receiptQyt,
    field_name: "receiptQyt",
    type: "number",
    isRequired: true,
  },
  {
    label: receiptLabel.invoiceNumber,
    field_name: "invoiceNumber",
    type: "string",
    isRequired: true,
  },
];

export const receiptApi = {
  getAll: {
    api: getAllReceipt,
  },
  getById: {
    api: getReceiptDataBydataId,
  },
};

export const myReceiptApi = {
  getAll: {
    api: getMyReceipt,
  },
  getById: {
    api: getReceiptDataBydataId,
  },
};

export const receiptFields = [
  {
    name: receiptLabel.site_id,
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.name,
    minWidth: "150px",
  },
  {
    name: receiptLabel.tank_id,
    selector: "tank",
    sortable: true,
    cell: (row) => row?.tank?.name,
    minWidth: "150px",
    isShowMobile: true,
  },
  {
    name: receiptLabel.supplier,
    selector: "supplier",
    sortable: true,
    cell: (row) => row?.supplier?.supplierCode,
    minWidth: "150px",
  },
  {
    name: receiptLabel.receiptDate,
    selector: "receiptDate",
    sortable: true,
    cell: (row) => row?.receiptDate && DDMMYYYYFormat(row?.receiptDate),
    minWidth: "150px",
  },
  {
    name: receiptLabel.tankMaxCapacity,
    selector: "maxCapacity",
    sortable: true,
    cell: (row) => row?.tank?.maxCapacity,
    minWidth: "250px",
  },
  {
    name: receiptLabel.receiptQyt,
    selector: "receiptQyt",
    sortable: true,
    cell: (row) => row?.receiptQyt,
    minWidth: "250px",
  },
  {
    name: receiptLabel.availableTankCapacityAfterReceipt,
    selector: "availableTankCapacityAfterReceipt",
    sortable: true,
    cell: (row) => row?.availableTankCapacityAfterReceipt,
    minWidth: "300px",
  },
  {
    name: receiptLabel.tankLevelAfterReceipt,
    selector: "tankLevelAfterReceipt",
    sortable: true,
    cell: (row) => row?.tankLevelAfterReceipt,
    minWidth: "250px",
  },
];

export const receiptFilterForm = {
  startDate: "",
  endDate: "",
};
