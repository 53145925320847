import { RestMethod } from "../../../../_helpers/ApiConfig/RestMethod";
import { UserService } from "../../../../_services/UserService/UserService";
import * as xlsx from "xlsx";
import { saveAs } from "file-saver";
import { downloadFileByURL } from "../../../../_helpers/commonHelper";
import moment from "moment";
import { URL_CONFIG } from "../../../../_constants/Config/URL_CONFIG";

export const addLink = "/coal-grade/user-management";
export const userFilterForm = {
  startDate: "",
  endDate: "",
  siteId: "",
  userType: "",
  permissions: "",
};

export const userLabel = {
  name: "Name",
  email: "Email",
  dob: "Date of birth",
  firstName: "First Name",
  lastName: "Last Name",
  contactNumber: "Mobile No.",
  siteId: "Mine",
  userType: "User Type",
  contractor_id: "Contractor",
  roleId: "User Type",
};

export const userInitialForm = {
  firstName: "",
  lastName: "",
  email: "",
  dob: "",
  contactNumber: "",
  userPermission: {},
  roleId: "",
  siteId: "",
  // userType: "",
  contractor_id: null,
};

export const permissionInitialForm = {
  representative: false,
  section_incharge: false,
  colliery_manager: false,
  dispenser: false,
  chemist: false,
  lab_quality_manager: false,
  area_quality_manager: false,
  coal_grade_admin: false,
  contractor: false,
  contractor_module_admin: false,
  safety_admin: false,
  fuel_admin: false,
  env_admin: false,
  env_user: false,
};

export const customTabsData = [
  {
    // permission: ["section_incharge", "contractor_module_admin"]
    to: addLink,
    label: "User List",
    active: 0,
  },
  {
    label: "Reason List",
    to: "/contractor-management/configuration/reason-list",
    active: 0,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    permission: ["contractor_module_admin", "super_admin"],
    to: "/contractor-management/configuration/contractor-list",
    label: "Contractor List",
    active: 0,
  },
  {
    permission: ["contractor_module_admin"],
    to: "/contractor-management/user-list",
    label: "Contractor User List",
    active: 0,
  },
];

export const userFormRules = [
  {
    type: "string",
    field_name: "firstName",
    label: userLabel?.firstName,
    isRequired: true,
  },
  {
    field_name: "lastName",
    label: userLabel?.lastName,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "contactNumber",
    label: userLabel?.contactNumber,
    type: "number",
    isRequired: true,
  },
  {
    field_name: "email",
    isRequired: true,
    label: userLabel?.email,
    type: "email",
  },
  {
    label: userLabel?.dob,
    type: "string",
    isRequired: true,
    field_name: "dob",
  },
  {
    field_name: "siteId",
    label: userLabel?.siteId,
    type: "string",
    isRequired: true,
  },
  // {
  //   field_name: "userType",
  //   label: userLabel?.userType,
  //   type: "string",
  //   isRequired: true,
  // },
  {
    label: userLabel?.contractor_id,
    type: "string",
    isRequired: false,
    field_name: "contractor_id",
  },
  {
    field_name: "roleId",
    label: userLabel?.roleId,
    isRequired: true,
    type: "string",
  },
];

export const IncidentuserListApis = Object.freeze({
  getAll: {
    api: UserService.getAllSafetyUsersWithServer,
  },
  delete: {
    api: UserService.deleteUser,
  },
});

export const userListApis = Object.freeze({
  getAll: {
    api: UserService.getAllUsersWithServer,
  },
  delete: {
    api: UserService.deleteUser,
  },
});

export async function exportExcellReports(filter) {
  const payloadString = Object.keys(filter)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
    )
    .join("&");
  let url =
    URL_CONFIG.DEV_URL + "fuel-dispence/export-reports?" + payloadString;
  try {
    let fileName = "";
    if (filter.startDate)
      fileName += `${moment(new Date(filter.startDate)).format("DD-MM-YYYY")}`;
    if (filter.endDate)
      fileName += ` - ${moment(new Date(filter.endDate)).format("DD-MM-YYYY")}`;
    downloadFileByURL(url, fileName + "_" + filter?.type + ".xlsx");
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export function downloadExcelFile(tables, fileName) {
  // Create a new worksheet
  const worksheet = xlsx.utils.aoa_to_sheet([]);

  let currentRow = 1; // Start from row 1

  tables.forEach((table, index) => {
    // Extract headers from the first row of the current table
    if (table?.length > 0) {
      const tableHeaders = Object.keys(table[0]);
      const headerRow = [tableHeaders];
      xlsx.utils.sheet_add_aoa(worksheet, headerRow, {
        origin: `A${currentRow}`,
      });
      currentRow++;

      // Add rows for the current table
      const rows = table.map((row) => Object.values(row));
      xlsx.utils.sheet_add_aoa(worksheet, rows, { origin: `A${currentRow}` });

      if (tables.length > 1) {
        currentRow += table.length;

        let skipRows;
        index === 1 ? (skipRows = 3) : (skipRows = 1);

        // Calculate totals for each column
        const totals = Array(tableHeaders.length - 1).fill(0); // Initialize an array to hold totals for each column
        table.forEach((row) => {
          tableHeaders?.slice(skipRows)?.forEach((header, columnIndex) => {
            const value = parseInt(row[header]); // Parse the string value to an integer
            if (!isNaN(value)) {
              // Check if the parsed value is not NaN
              totals[columnIndex] += value;
            }
          });
        });

        // Add the total row to the worksheet
        let totalRow;
        index === 1
          ? (totalRow = [["", "", "Total"]])
          : (totalRow = [["Total"]]);
        totals.forEach((total, i) => {
          if (index !== 1 || i === 0) totalRow[0].push(total); // Push each total value to the total row
        });

        xlsx.utils.sheet_add_aoa(worksheet, totalRow, {
          origin: `A${currentRow}`,
        });
        currentRow += table.length + 2; // Add  for an empty row as a separator
      }
    }
  });

  // Create a new workbook
  const workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet");

  // Generate Excel file
  const excelFileBuffer = xlsx.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  // Convert buffer to Blob
  const blob = new Blob([excelFileBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Save file
  saveAs(blob, fileName);
}
