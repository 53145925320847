import React, { useMemo, useState } from "react";
import {
  DDMMYYYYHHMMAExcelFormat,
  DDMMYYYYFormatBackslash,
} from "../../../../src/_helpers/dateHelper";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "./environment.scss";

const ErrorFields = ({ show, setShow, closePopup, errorData }) => {
  const [showRow, setShowRow] = useState();
  const onShow = (id) => {
    if (!id) return false;
    if (showRow == id) {
      setShowRow("");
    } else {
      setShowRow(id);
    }
  };

  const tableLIst = useMemo(
    () =>
      Object.values(errorData)
        .filter((d) => d.index != null)
        .map((d, i) => {
          d.sid = i + 1;
          return d;
        }),
    [errorData, showRow]
  );
  const header = useMemo(
    () => [
      {
        name: "S.No.",
        selector: "sid",
        sortable: true,
      },
      {
        name: "File Data",
        cell: (row) => {
          return (
            <div className="fieldDataSec">
              {Object.keys(row.row).map((d, i) => {
                if (i > 1 && showRow != row.sid) return false;
                {
                  if (d != "undefined")
                    return (
                      <p key={d}>
                        {d == "weather_datetime" &&
                        DDMMYYYYHHMMAExcelFormat(row.row[d]) !=
                          "Invalid date" &&
                        isNaN(row.row[d]) ? (
                          <>
                            <span>{d}: </span>{" "}
                            <span>{DDMMYYYYHHMMAExcelFormat(row.row[d])}</span>
                          </>
                        ) : (d == "air_quality_date" ||
                            d == "plantation_date" ||
                            d == "sampling_date" ||
                            d == "analysis_date") &&
                          DDMMYYYYHHMMAExcelFormat(row.row[d]) !=
                            "Invalid date" &&
                          isNaN(row.row[d]) ? (
                          <>
                            <span>{d}: </span>{" "}
                            <span>{DDMMYYYYFormatBackslash(row.row[d])}</span>
                          </>
                        ) : (
                          <>
                            <span>{d}: </span> <span>{row.row[d]}</span>
                          </>
                        )}
                      </p>
                    );
                  return "";
                }
              })}
              <span
                className="span-link"
                onClick={() => onShow(row.sid)}
                name="span_link"
              >
                {" "}
                {showRow == row.sid ? "Show Less" : "Show More"}
              </span>
            </div>
          );
        },
      },
      {
        name: "Status",
        // selector: "key",
        // sortable: true,
        cell: (row) => {
          return <span className="badge badge-danger">Error</span>;
        },
      },
      {
        name: "Message",
        selector: "message",
        sortable: true,
        cell: (row) => {
          return (
            <ul className="text-danger error-content">
              {row.message.split(",").map((item, key) => (
                <li index={key}>{item}</li>
              ))}
            </ul>
          );
        },
      },
    ],
    [showRow, errorData]
  );
  return (
    <Modal
      size="xl"
      show={show}
      onHide={() => setShow(false)}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton={true} className="">
        <h5 className="modal-title">Error Data</h5>
      </Modal.Header>
      <Modal.Body className="p-2 text-center">
        <DataTable
          columns={header}
          data={tableLIst}
          highlightOnHover
          pagination
        />
        <Button
          className="secondaryBtn mt-3 me-3"
          onClick={() => setShow(false)}
        >
          Back
        </Button>
        {/* <Button className="primaryBtn  mt-3" onClick={() => closePopup()}>
          Yes
        </Button> */}
      </Modal.Body>
    </Modal>
  );
};

export default ErrorFields;
