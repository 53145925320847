import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import {
  fuelDispenseApis,
  tabUrl,
  fuelDispenseColumnFields,
  fuelManagementFilterForm,
  investionAssined,
  SidebarData,
  mobileTabUrl,
  statusUpdateLink,
} from "./DATA";
import {
  IS_MOBILE,
  findUserPermissionedTabList,
} from "../../../_helpers/helper";

import filter from "../../../Assets/Images/filter.svg";
import { useForm } from "../../../_helpers/hooks";
import { exportFuelDispense } from "../../../services/_fuelDispense/fuelDispense";
import "./../../FuelManagementSystem/fuelmgmt.scss";
import FuelDispenseView from "../../FuelManagementSystem/FuelDispenseView";
import FuelDispenseFilterForm from "../../FuelManagementSystem/FuelDispenseFilterForm";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import {
  getSafetyManagementInvestigationAssignedIncidentListWithPagination,
  getSafetyManagementInvestigationAssignedIncidentExcel,
} from "../../../services/_safetyManagement/safetyManagement";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import SafetyManagementView from "../SafetyManagementView";
import SafetyManagementFilterForm from "../SafetyManagementFilterForm";
const InvestigationIncidentList = () => {
  let navigate = useNavigate();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(fuelManagementFilterForm);
  const usrl = useLocation();
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(
      IS_MOBILE() ? mobileTabUrl.safetyManagement : tabUrl.safetyManagement
    )
  );

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} hideLeftRightBtn />;
  };

  useEffect(() => {
    let currentUrl = "";
    setCustomTabs((prev) => {
      return prev.map((data) => {
        if (data?.to === usrl.pathname) {
          currentUrl = data?.to;
          return {
            ...data,
            active: 1,
          };
        }
        return data;
      });
    });
    currentUrl ? navigate(currentUrl) : navigate(customTabs[0].to);
    getAll();
  }, []);

  const onResetFilter = () => {
    setGetAllFilter();
    setForm(fuelManagementFilterForm);
  };

  const getAll = async () => {
    const response =
      await getSafetyManagementInvestigationAssignedIncidentListWithPagination(
        "page=1&limit=2"
      );
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const viewAsPopup = (form) => {
    return <SafetyManagementView form={form} />;
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else if (key === "is_investigated" || key === "is_capa")
          filter += `&search[${key}]=${1}`;
        else filter += `&search[${key}]=${element}`;
      }
    }

    setGetAllFilter(filter);
  }, [filterStatus]);

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          data-testid="filter-button-Fuel-management"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
        >
          <img className="img-fluid" src={filter} alt="fliterIcon" />
        </Button>
        <SafetyManagementFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      </div>
    );
  };

  return (
    <>
      <div
        className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
      >
        <SafetySideBar
          SidebarData={SidebarData}
          pageTitle={"My Approval Actions"}
          goBack={() =>
            navigate("/mobile/safety-management-system/incident-management")
          }
        />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  childrenAfterAddButton={tabButtons}
                  fields={fuelDispenseColumnFields}
                  apis={investionAssined}
                  title="Data"
                  viewTitle={"Incident Details"}
                  FilterBtn={FilterBtn}
                  viewAsPopup={viewAsPopup}
                  hideSnColumn
                  pageLink={statusUpdateLink}
                  primaryKey="id"
                  pagination
                  getAllFilter={getAllFilter}
                  hideAddButton
                  downloadExcel={
                    getSafetyManagementInvestigationAssignedIncidentExcel
                  }
                  breadCrumb={[
                    { name: "Safety Management", path: "#" },
                    {
                      name: "Incident Management",
                      path: "/safety-management-system/incident-management",
                    },
                    {
                      name: "My Approval Actions",
                      path: "#",
                    },
                  ]}
                  listname="incident_investigation"
                  modalSize={"xl"}
                  customTableTitle={"Blank"}
                  icons
                  updatePageLink={statusUpdateLink}
                  updateStatus
                />

                {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestigationIncidentList;
