import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat, MonthYearFormat } from "../../../_helpers/dateHelper";
import ContractorTenderDetails from "../CommonComponent/ContractorTenderDetails";
import CreatedByCreatedAt from "../../../components/Common/CreatedByCreatedAt";
const ContractorScoreView = ({ form }) => {
  const bg = { backgroundColor: "#eef9ff" };
  const font = { fontSize: "16", fontWeight: "600" };
  return (
    <>
      <ContractorTenderDetails form={form} />
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="webformViewContent">
            <div className="d-flex justify-content-between">
              <h3 className="font18">Score Details</h3>
              <span className="d-flex ms-auto">
                <label className="font18">Date:-</label>
                <p className="content">{MonthYearFormat(form?.date)}</p>
              </span>
            </div>

            <div className="webformCardContent"></div>
          </div>
          <div className="webformViewContent">
            <div className="webformCardContent">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "47%" }}>
                  <table cellPadding={10}>
                    <thead>
                      <tr>
                        <td className="text-center">Target</td>
                        <td className="text-center">Data</td>
                        <td className="text-center">Score</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={bg}>
                          <label style={font}>Bench Condition</label>
                        </th>
                        <td>{form?.benchData}</td>
                        <td>
                          {form?.benchScore?.toFixed(2) +
                            ` ( ${form?.benchData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>Biometric Attendance</label>
                        </th>
                        <td>{form?.biometricData}</td>
                        <td>
                          {form?.biometricScore?.toFixed(2) +
                            ` ( ${form?.biometricData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>
                            Distribution of Safety Gadget
                          </label>
                        </th>
                        <td>{form?.distributionSafetyGadgetData}</td>
                        <td>
                          {form.distributionSafetyGadgetScore?.toFixed(2) +
                            ` ( ${form?.distributionSafetyGadgetData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>Dust Suppression Measures</label>
                        </th>
                        <td>{form?.dustSuppressionData}</td>
                        <td>
                          {form.dustSuppressionScore?.toFixed(2) +
                            ` ( ${form?.dustSuppressionData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>Environment Compliance</label>
                        </th>
                        <td>{form?.environmentData}</td>
                        <td>
                          {form?.environmentScore?.toFixed(2) +
                            ` ( ${form?.environmentData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>Equipment in Running Order</label>
                        </th>
                        <td>{form?.equipmentRunningData}</td>
                        <td>
                          {form?.equipmentRunningScore?.toFixed(2) +
                            ` ( ${form?.equipmentRunningData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>Equipment on Roll</label>
                        </th>
                        <td>{form?.equipmentRollData}</td>
                        <td>
                          {form?.equipmentRollScore?.toFixed(2) +
                            ` ( ${form?.equipmentRollData * 2} % )`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style={{ width: "47%" }}>
                  <table cellPadding={10}>
                    <thead>
                      <tr>
                        <td className="text-center">Target</td>
                        <td className="text-center">Data</td>
                        <td className="text-center">Score</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={bg}>
                          <label style={font}>Fire Protection System</label>
                        </th>
                        <td>{form?.fireProtectionData}</td>
                        <td>
                          {form.fireProtectionScore?.toFixed(2) +
                            ` ( ${form?.fireProtectionData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>Haul Road Parameters</label>
                        </th>
                        <td>{form?.haulRoadData}</td>
                        <td>
                          {form.haulRoadScore?.toFixed(2) +
                            ` ( ${form?.haulRoadData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>IME/PME Status</label>
                        </th>
                        <td>{form?.imePmeData}</td>
                        <td>
                          {form.imePmeScore?.toFixed(2) +
                            ` ( ${form?.imePmeData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>Payment through Clip</label>
                        </th>
                        <td>{form?.paymentClipData}</td>
                        <td>
                          {form.paymentClipScore?.toFixed(2) +
                            ` ( ${form?.paymentClipData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>
                            Safety Feature in Equipment
                          </label>
                        </th>
                        <td>{form?.safetyFeatureData}</td>
                        <td>
                          {form.safetyFeatureScore?.toFixed(2) +
                            ` ( ${form?.safetyFeatureData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>VTC Certificate Status</label>
                        </th>
                        <td>{form?.vtcCertificateData}</td>
                        <td>
                          {form.vtcCertificateScore?.toFixed(2) +
                            ` ( ${form?.vtcCertificateData * 2} % )`}
                        </td>
                      </tr>

                      <tr>
                        <th style={bg}>
                          <label style={font}>Illumination</label>
                        </th>
                        <td>{form?.illuminationData}</td>
                        <td>
                          {form.illuminationScore?.toFixed(2) +
                            ` ( ${form?.illuminationData * 2} % )`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <table cellPadding={10}>
                <tbody>
                  <tr>
                    <td>
                      <label>Composite Vendor score</label>
                    </td>
                    <td>Average score</td>
                    <td>{form?.avgScore?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <CreatedByCreatedAt id={form?.id} table={"contractor_score_cards"} />
        </div>
      </Modal.Body>
    </>
  );
};

export default ContractorScoreView;
