import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  CategoryRules,
  editStatusDropdown,
  initialForm,
  navigateUrl,
  safetyManagementApis,
  statusList,
  StatusUpdateRules,
  initialCapa,
  capaFormRules,
  startCloseDateTimeLabel,
  checkStartCloseDateTimeByStatus,
  checkPreviousDateKey,
} from "./DATA";
import toastr from "toastr";
import { useForm } from "../../_helpers/hooks";
import { Validation } from "../../_helpers/validation/Validation";
import {
  changeIncidentFormCStatus,
  changeIncidentStatus,
} from "../../services/_safetyManagement/safetyManagement";
import { DDMMYYYYFormat, DDMMYYYYHHMMAFormat } from "../../_helpers/dateHelper";
import { Button, Col, Row } from "react-bootstrap";
import { UserService } from "../../_services/UserService/UserService";
import {
  IS_MOBILE,
  NUMBER_BY_TRUE_FALSE,
  TRUE_FALSE_BY_NUMBER,
  capitalizeName,
} from "../../_helpers/helper";
import SafetySideBar from "./SafetySideBar/SafetySideBar";
import {
  SidebarData,
  incidentTypesObj,
  initialEvidence,
  roleData,
  informatinsInitialObj,
  informationRules,
  responsiblePersonForm,
  responsibilityRules,
} from "./IncidentManagement/DATA";
import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";
import moment from "moment";
import { getAllMasterDropdownDesignation } from "../../services/_reasonList/reasonList";
let CompensationInfor;
if (IS_MOBILE()) {
  CompensationInfor =
    require("./IncidentManagement/components/mobile/ReportForm/CompensationInfor").default;
} else {
  CompensationInfor =
    require("./IncidentManagement/components/ReportForm/CompensationInfor").default;
}

let Select;
let CustomDatePicker;
if (IS_MOBILE()) {
  Select = require("../../components/MobileComponents/Select/Select").default;
  CustomDatePicker =
    require("../../components/MobileComponents/DateTimeCustom/CustomDateTime").default;
} else {
  Select = require("../../components/Select/Select").default;
  CustomDatePicker =
    require("../../components/DateTimeCustom/CustomDateTime").default;
}

let CapasComponent;
if (IS_MOBILE()) {
  CapasComponent =
    require("./IncidentManagement/components/mobile/ReportForm/CapasComponent").default;
} else {
  CapasComponent =
    require("./IncidentManagement/components/CapasComponent").default;
}

let ResponsiblePersonInfo;
if (IS_MOBILE()) {
  ResponsiblePersonInfo =
    require("./IncidentManagement/components/mobile/ReportForm/ResponsibleInfoMobile").default;
} else {
  ResponsiblePersonInfo =
    require("./IncidentManagement/components/ReportForm/ResponsiblePeronInfo").default;
}

const SafetyManagementFormC = () => {
  const params = useParams("id");
  let navigate = useNavigate();
  const [form, onChange, setForm] = useForm({
    ...initialForm,
  });
  const [usersList, setUsersList] = useState([]);
  const [error, setError] = useState({});
  const [rootCauseErr, setRootCauseErr] = useState([]);
  const [commentFlag, setCommentFlag] = useState(false);
  const [editStatusList, setEditStatusList] = useState([]);
  const [rootCause, setRootCause] = useState(true);
  const [rootDesc, setRootDesc] = useState("");
  const [rootDescErr, setRootDescErr] = useState("");
  const [capas, setCapas] = useState([JSON.parse(JSON.stringify(initialCapa))]);
  const [capasErrors, setCapasErrors] = useState([]);
  const [informatinsDetails, setInformatinsDetails] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [
    informationResponsibilityDetails,
    setInformationResponsibilityDetails,
  ] = useState([JSON.parse(JSON.stringify(responsiblePersonForm))]);

  const [informationErrors, setInformationErrors] = useState([]);
  const [responsibilityErrors, setResponsibilityErrors] = useState([]);
  const [categoryFormFields, setCategoryFormFields] = useState([
    {
      id: null,
      branch_number: "",
      branch: "",
      leafOb: [{ id: null, leaf_number: "", leaf: "" }],
    },
  ]);
  const [evidences, setEvidences] = useState([
    JSON.parse(JSON.stringify(initialEvidence)),
  ]);

  let rootCauseValue = 0;

  const navigator = useNavigate();

  const getAll = async (filter) => {
    let response = await safetyManagementApis.getAll.api(filter);
    return response?.data || [];
  };

  useEffect(() => {
    if (!params?.id) return;
    (async () => {
      let status;
      let response = await getAll(`search[id]=${params?.id}`);

      response?.map((data) => {
        status = data?.status;
        setForm((prev) => {
          return {
            ...prev,
            ...data,
            isCompensationInfor: TRUE_FALSE_BY_NUMBER(
              data?.isCompensationInfor
            ),
          };
        });
        const compenInforData = data?.personalDetails?.map((d) => ({
          id: d?.id,
          outcome: d?.outcome,
          injuryCode: d?.injuryCode,
          ...d,
          dateOfReturnDuty: d?.outcome === "Fatality" ? "Not Applicable" : "",
          returnForRegular: d?.outcome === "Fatality" ? "Not Applicable" : "",
          name: d?.name,
        }));
        let filteredData = compenInforData.filter(
          (d) => d.outcome !== "Fatality"
        );
        setInformatinsDetails(filteredData);
      });

      setEditStatusList(
        Object.keys(statusList)
          .filter((d, i) => editStatusDropdown[status].find((s) => s === d))
          .map((data) => {
            return { label: statusList[data], value: data };
          })
      );
    })();
  }, [params?.id]);
  useEffect(() => {
    if (form?.site_id) {
      UserService.getUsersByRole(
        `filter[status]=1&site_id=${form.site_id}`,
        roleData
      ).then((response) => {
        if (!response?.data) return;
        setUsersList(
          response?.data.map((d, i) => ({
            ...d,
            name_email_employeeId: `${capitalizeName(
              d.firstName + " " + d.lastName
            )} / ${d.email}`,
          }))
        );
      });
    }
  }, [form.site_id]);

  const handleSubmit = async () => {
    let nonFetalData = informatinsDetails.filter(
      (d) => d.outcome !== "Fatality"
    );
    const informationErrorArray = nonFetalData.map((data) => {
      if (data.outcome !== "Fatality") {
        let updatedValidationRule = informationRules.filter(
          (d) => d.field_name !== "causeOfDeath"
        );
        return Validation(data, updatedValidationRule);
      }
      return Validation(data, informationRules);
    });

    setInformationErrors(informationErrorArray);
    let showError = false;
    for (let obj of informationErrorArray) {
      for (let key in obj) {
        if (obj[key] !== "") {
          showError = true;
          break;
        }
      }
      if (showError) {
        break;
      }
    }

    if (showError) {
      toastr.error("All Fields are required");
      return;
    }

    let data = {
      id: params?.id,
      personnelDetails: nonFetalData,
    };
    console.log("hit api", data);

    let response = await changeIncidentFormCStatus(data);
    if (!response?.status) return toastr.error(response?.message);
    toastr.success(response?.message || `Updated`);
    navigator(navigateUrl);
  };

  useEffect(() => {
    UserService.getUsersByRole("filter[status]=1", roleData).then(
      (response) => {
        if (!response?.data) return;
        setUsersList(
          response?.data.map((d, i) => ({
            ...d,
            name_email_employeeId: `${capitalizeName(
              d.firstName + " " + d.lastName
            )} / ${d.email}`,
          }))
        );
      }
    );
    getDesignationOpt();
  }, []);
  const getDesignationOpt = async () => {
    let response = await getAllMasterDropdownDesignation();

    if (response.status) {
      setDesignations(response.data?.filter((d) => d?.status));
    }
  };

  const onChangeInformation = (index, name, value) => {
    setInformatinsDetails((prev) =>
      prev.map((d, idx) => {
        if (idx !== index) return d;
        d[name] = value;
        return d;
      })
    );
  };
  return (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        pageTitle={"Edit"}
        goBack={() => navigate(-1)}
        SidebarData={SidebarData}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            {!IS_MOBILE() && (
              <h3 style={{ paddingBottom: "1px", paddingTop: "5px" }}>
                <MainBreadcrumb
                  routeSegments={[
                    { name: "Safety Management", path: "#" },
                    {
                      path: "/safety-management-system/incident-management",
                      name: "Incident Management",
                    },
                    {
                      name: "View Incidents",
                      path: "/safety-management-system/incident-management/view-incidents",
                    },
                    { name: "Edit", path: "#" },
                  ]}
                />
              </h3>
            )}
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="formWrapper">
                  <div className="maverickFormCard">
                    <div className="FormWrapper">
                      <Row className="border-bottom-0 safetyEdit">
                        <Col md={4}>
                          <label>Subsidiary</label>
                          <p className="content">
                            {form?.site?.parentSite?.name}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>Mine</label>
                          <p className="content">{form?.site?.name}</p>
                        </Col>
                        <Col md={4}>
                          <label>{startCloseDateTimeLabel[form?.status]}</label>
                          <p className="content">
                            {[
                              "investigation_completed",
                              "incident_closed",
                              "capa_closed",
                            ]?.includes(form?.status)
                              ? DDMMYYYYFormat(
                                  form?.[checkPreviousDateKey[form?.status]]
                                )
                              : DDMMYYYYHHMMAFormat(
                                  form?.[checkPreviousDateKey[form?.status]]
                                )}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>Department</label>
                          <p className="content">{form?.department}</p>
                        </Col>
                        <Col md={4}>
                          <label>Incident Category</label>
                          <p className="content">
                            {incidentTypesObj[form?.category]}
                          </p>
                        </Col>
                        <Col md={4}>
                          <label>Current Status</label>
                          <p className="content">{statusList[form?.status]}</p>
                        </Col>

                        <CompensationInfor
                          form={form}
                          errors={informationErrors}
                          onChange={onChange}
                          onChangeInformation={onChangeInformation}
                          informatinsDetails={informatinsDetails.filter(
                            (d) => d.outcome !== "Fatality"
                          )}
                          pendingFormC={true}
                        />
                      </Row>

                      <div className="btnSec  justify-content-end">
                        <Button onClick={handleSubmit} className="primaryBtn">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetyManagementFormC;
