import { isArray } from "lodash";
import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import CheckBoxField from "../../components/_form/CheckBoxField/CheckBoxField";
import { DDMMYYYYFormat } from "../../_helpers/dateHelper";
import { capitalizeName } from "../../_helpers/helper";
import {
  ADMIN_ROLE_OBJECT,
  CONTRACTOR_PERMISSION_OBJECT,
  MODULE_OBJECT_VIEW,
  ROLE_OBJECT,
  ROLE_OBJECT_VIEW,
} from "../../_services/Auth/helper";
import { siteTypeObj, userLabel } from "./DATA";

const UserManagementView = ({ form }) => {
  let finalRoleObject=ROLE_OBJECT;
  if(form?.roleId===3)finalRoleObject=CONTRACTOR_PERMISSION_OBJECT;
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            {/* <p className="head">Sample Coding Register Data</p> */}
            <Row>
              <Col sm={6}>
                <label>{userLabel?.name}</label>
                <p name="user-name" className="content">
                  {capitalizeName(
                    `${form?.firstName ? form?.firstName : ""} ${
                      form?.lastName ? form?.lastName : ""
                    }`
                  )}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.roleId}</label>
                <p name="user-role" className="content">
                  {form?.role?.title || ""}
                </p>
              </Col>
              {form?.contractor ? (
                <Col sm={6}>
                  <label>{userLabel?.contractor_id}</label>
                  <p className="content">
                    {form?.contractor?.contractor || ""}
                  </p>
                </Col>
              ) : (
                ""
              )}
              <Col sm={6}>
                <label>{userLabel?.email}</label>
                <p name="email" className="content">
                  {form?.email}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.contactNumber}</label>
                <p className="content">{form?.contactNumber}</p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.dob}</label>
                <p className="content">
                  {form?.dob ? DDMMYYYYFormat(form?.dob) : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.siteId}</label>
                <p name="user-site" className="content">
                  {form?.site?.name || ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.siteType}</label>
                <p name="user-site" className="content">
                  {siteTypeObj[form?.site?.siteType] || ""}
                </p>
              </Col>
            </Row>
            {(form?.roleId === 4||form?.roleId === 3) && (
              <>
                <h6 className="mt-2">Permissions​</h6>
                <div className="seperator mt-0"></div>
                <Row>
                  {Object.keys(finalRoleObject)?.map((module, idx) => {
                    const finalRoleData = Object.keys(finalRoleObject[module])?.map(
                      (role, i) => {
                        const permissionkey =
                          (Array.isArray(form?.accessPermissions) &&
                            form?.accessPermissions?.find(
                              (roleObj) => roleObj?.permission === role
                            )) ||
                          "";
                        return (
                          <Col key={i} md={6}>
                            <CheckBoxField
                              id={`${idx}check${i}`}
                              name={role}
                              checked={permissionkey ? true : false}
                              label={ROLE_OBJECT_VIEW?.[role]}
                              disabled={true}
                            />
                          </Col>
                        );
                      }
                    );
                    return (
                      <>
                        <h6 className="mt-2" key={idx}>
                          {MODULE_OBJECT_VIEW[module]}
                        </h6>{" "}
                        {finalRoleData}
                      </>
                    );
                  })}
                </Row>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default UserManagementView;
