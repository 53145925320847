import { RestMethod } from "../../../../_helpers/ApiConfig/RestMethod";

export const BulkUploadService = {
  addAreaQualityExcel,
};

async function addAreaQualityExcel(data) {
  try {
    const response = await RestMethod.POST(
      "environment-management/save-env-file-data",
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
