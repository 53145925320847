import React from "react";
import { Col, Modal, Button } from "react-bootstrap";
import { Row } from "reactstrap";
import HindranceAudit from "./HindranceAudit";
import { hindranceHistory } from "../../../services/_hindrance/hindrance";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import ContractorTenderDetails from "../CommonComponent/ContractorTenderDetails";
import { useEffect } from "react";
import { useState } from "react";
import { shiftsName } from "./DATA";
import CreatedByCreatedAt from "../../../components/Common/CreatedByCreatedAt";
const HindranceView = ({ form, hideTrail = false }) => {
  const [historyData, setHistoryData] = useState([]);
  const [showAudit, setshowAudit] = useState(false);

  const getHistoryData = async () => {
    let response = await hindranceHistory(form?.id);
    setHistoryData(response?.data);
  };
  useEffect(() => {
    getHistoryData();
  }, [form]);
  return (
    <>
      <ContractorTenderDetails form={form} hindranceDate />
      <Modal.Body className="p-0 pb-5">
        <>
          {form?.contractor_hindrance_reasons?.map((data, idx) => (
            <div key={idx}>
              <div className="preview hindrancePreview pt-0">
                <div className="previewContent">
                  <Row>
                    <Col lg={2}>
                      <div className="form-group">
                        <label>Shift:</label>
                        <p>{data?.shift && shiftsName[data?.shift]}</p>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="form-group">
                        <label>Reason:</label>
                        <p>{data?.reasons_label?.name}</p>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <label>Vendor Hours:</label>
                      <p className="content">{data?.hrs_of_hindrance}</p>
                    </Col>
                    <Col lg={3}>
                      <div className="form-group">
                        <label>Place:</label>
                        <p>{data?.place}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="form-group">
                        <label>Vendor comment:</label>
                        <p>{data?.vendor_comments}</p>
                      </div>
                    </Col>
                  </Row>

                  {(form.current_label === "section_incharge" ||
                    form.current_label === "colliery_manager" ||
                    form.current_label === "approved") && (
                    <Row>
                      <Col lg={6}></Col>
                      <Col lg={3}>
                        <label>Representative Hours</label>
                        <p className="content">
                          {data?.hrs_of_hindrance_by_representative}
                        </p>
                      </Col>
                      <Col lg={3}>
                        <label>Representative Comment</label>
                        <p className="content">
                          {data?.representative_comments}
                        </p>
                      </Col>
                    </Row>
                  )}
                  {(form.current_label === "colliery_manager" ||
                    form.current_label === "approved") && (
                    <Row>
                      <Col lg={6}></Col>
                      <Col lg={3}>
                        <label>Section Incharge Hours</label>
                        <p className="content">
                          {data?.hrs_of_hindrance_by_section_incharge}
                        </p>
                      </Col>
                      <Col lg={3}>
                        <label>Section Incharge Comment</label>
                        <p className="content">
                          {data?.section_incharge_comments}
                        </p>
                      </Col>
                    </Row>
                  )}
                  {form.current_label === "approved" && (
                    <Row>
                      <Col lg={6}></Col>
                      <Col lg={3}>
                        <label>Colliery Manager Hours</label>
                        <p className="content">
                          {data?.hrs_of_hindrance_by_colliery_manager}
                        </p>
                      </Col>
                      <Col lg={3}>
                        <label>Colliery Manager Comment</label>
                        <p className="content">
                          {data?.colliery_manager_comments}
                        </p>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
        {historyData?.length > 0 && !hideTrail ? (
        <div className="btnSec">
          <Button
            className="primaryBtn"
            style={{ marginLeft: "60px" }}
            onClick={() => setshowAudit(!showAudit)}
          >
            {showAudit ? "Hide History Log" : "Show History Log"}
          </Button>
        </div>
      ) : (
        ""
      )}
      {showAudit && <HindranceAudit historyData={historyData} />}
      <CreatedByCreatedAt
        id={form?.id}
        table={"contractor_hindrances"}
        border={true}
      />
      </Modal.Body>
   
    </>
  );
};

export default HindranceView;
