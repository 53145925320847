import React, { useState, useEffect, useMemo, useCallback } from "react";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import { SidebarData } from "../../ContractorManagement/DATA";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { Button, Col, Form, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { FaArrowRight } from "react-icons/fa";
import "./helpManual.scss";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import HelpManualPopup from "../../UserManagement/HelpManualPopup";
import HelpManualCommonComponent from "../../ContractorManagement/CommonComponent/HelpManualCommonComponent";
const HelpManual = () => {
 
  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
    <HelpManualCommonComponent moduleName={"Contractor Management"} />
    </div>
  );
};

export default HelpManual;