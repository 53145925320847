import React, { useMemo, useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { useForm } from "../../../../_helpers/hooks";
import UserManagementFilterForm from "./UserManagementFilterForm";
import {
  userFilterForm,
  userListApis,
  addLink,
  customTabsData,
  userLabel,
  IncidentuserListApis,
} from "./DATA";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import {
  capitalizeName,
  findUserPermissionedTabList,
} from "../../../../_helpers/helper";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import UserManagementView from "./UserManagementView";
import PermissionsFormEdit from "./PermissionsFormEdit";
import {
  CONTRACTOR_ROLE_OBJECT,
  ROLE_OBJECT_VIEW,
  isContractorModuleAdmin,
} from "../../../../_services/Auth/helper";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { SidebarData } from "../../../ContractorManagement/DATA";

const ContractorUserManagementList = () => {
  let newCustomTabsData = customTabsData;
  const [getAllFilter, setGetAllFilter] = useState(`&type=users`);
  const [isListData, setIsListData] = useState(true);
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(userFilterForm);
  const [customTabList, setCustomTabList] = useState(
    findUserPermissionedTabList(newCustomTabsData)
  );

  let newAddLink = addLink;
  // console.log("custom1" , customTabsData);

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          className="secondaryBtn"
          // rootClose
          data-testid="open-btn"
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>

        <UserManagementFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          setOpen={setOpen}
          isOpen={open}
          // isUserType
          noHO
        />
      </div>
    );
  };

  let newFilterBtn = FilterBtn;
  if (isContractorModuleAdmin()) {
    newCustomTabsData = customTabsData.filter((d, i) => {
      if (d.label === "User List") {
        return false;
      }
      return true;
    });
    newAddLink = "/contractor-management/user-list";
    // newFilterBtn = null;
  }

  useEffect(() => {
    getAll();
  }, []);

  const { pathname } = useLocation();

  const getAll = async () => {
    const response = await IncidentuserListApis.getAll.api(
      `&type=users&page=1&limit=10`
    );
    if (Array.isArray(response?.data) && response?.data?.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const onResetFilter = () => {
    setGetAllFilter(`&type=users`);
    setForm({ ...userFilterForm });
  };

  useEffect(() => {
    setCustomTabList((prev) => {
      return prev.map((d) => {
        if (d.to === pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
  }, [pathname]);

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];

      if (element) {
        filter += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(`&type=users${filter}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const columnFields = useMemo(
    () => [
      {
        minWidth: "200px",
        name: userLabel?.name,
        selector: "firstName",
        sortable: true,
        cell: (row) => {
          return capitalizeName(
            `${row?.firstName ? row?.firstName : ""} ${
              row?.lastName ? row?.lastName : ""
            }`
          );
        },
      },
      {
        selector: "roleId",
        name: userLabel?.roleId,
        sortable: true,
        cell: (row) => {
          return row?.role?.title || "";
        },
      },
      {
        name: userLabel?.siteId,
        cell: (row) => {
          return row?.site?.name || "";
        },
        selector: "siteId",
        sortable: true,
      },
      {
        name: userLabel?.email,
        selector: "email",
        sortable: true,
      },
      {
        name: "Permissions",
        selector: "accessPermissions",
        cell: (row) => {
          let role = row.accessPermissions?.map(
            (d) => CONTRACTOR_ROLE_OBJECT?.userManagement[d.permission]
          );
          let roleStr = role.filter((val) => val !== undefined);
          let roleStrNew = roleStr.join(",").substring(0, 20);
          if (roleStrNew.length > 19) {
            roleStrNew = roleStrNew + "...";
          }
          return roleStrNew;
        },
        minWidth: "200px",
        sortable: false,
      },
    ],
    []
  );

  // const tabButtons = () => {
  //   return <CustomTabs tabeList={customTabList} />;
  // };

  const viewAsPopup = (form) => {
    return (
      <UserManagementView
        form={form}
        USER_MANAGEMENT_ROLE={CONTRACTOR_ROLE_OBJECT}
      />
    );
  };

  const editAsPopup = (form, setShowAddEdit, getAll) => {
    return (
      <PermissionsFormEdit
        formData={form}
        setShowAddEdit={setShowAddEdit}
        getAll={getAll}
        USER_MANAGEMENT_ROLE_OBJECT={CONTRACTOR_ROLE_OBJECT}
      />
    );
  };
  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  FilterBtn={newFilterBtn}
                  viewAsPopup={viewAsPopup}
                  editAsPopup={editAsPopup}
                  fields={columnFields}
                  apis={IncidentuserListApis}
                  title={"User"}
                  // hideAddButton={true}
                  viewTitle="User Details"
                  editTitle="Edit Permissions"
                  customTableTitle="User Management"
                  hideSnColumn
                  pageLink={newAddLink}
                  primaryKey="id"
                  pagination
                  hideDeleteButton
                  getAllFilter={getAllFilter}
                  breadCrumb={[
                    {
                      name: "Contractor Management",
                      path: "#",
                    },
                    {
                      name: "User Management",
                      path: "#",
                    },
                  ]}
                  isNoTabOnPage
                  // editFunButton={onEdit}
                  hideEditButton
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              hideAddBtn={true}
              // tabButtons={tabButtons}
              // pageLink={newAddLink}
              breadCrumb={[
                {
                  name: "Contractor Management",
                  path: "#",
                },
                {
                  name: "User Management",
                  path: "#",
                },
              ]}
              customTableTitle="User Management"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorUserManagementList;
