import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { submitStatus } from "../DATA";

export const sampleCodingFields = [
  {
    type: "string",
    label: "Sample ID",
    field_name: "sample_id",
    isRequired: true,
  },
  {
    field_name: "date_of_sample_sent_to_lab",
    label: "Date of sample sent to lab",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "code_given_to_sample",
    isRequired: true,
    type: "string",
    label: "Code given to sample",
  },
  {
    label: "Sample coding done by",
    field_name: "sample_coding_done_by",
    isRequired: true,
    type: "string",
  },
  {
    label: "Date of sample receipt in lab",
    field_name: "date_of_sample_receipt_in_lab",
    isRequired: true,
    type: "string",
  },
  {
    isRequired: true,
    label: "Date given for test",
    field_name: "date_given_for_test",
    type: "string",
  },
  {
    label: "Sample sender name",
    isRequired: true,
    type: "string",
    field_name: "sample_sender_name",
  },
];

export const sampleCodingDraftRules = [
  {
    label: "Sample ID",
    field_name: "sample_id",
    isRequired: true,
    type: "string",
  },
  {
    label: "Date of sample sent to lab",
    field_name: "date_of_sample_sent_to_lab",
    isRequired: true,
    type: "string",
  },
  {
    label: "Code given to sample",
    field_name: "code_given_to_sample",
    isRequired: true,
    type: "string",
  },
];

export const sampleCodingRegisterInitialForm = {
  date_of_sample_sent_to_lab: "",
  sample_id: "",
  date_of_sample_receipt_in_lab: "",
  code_given_to_sample: "",
  sample_coding_done_by: "",
  date_given_for_test: "",
  sample_sender_name: "",
};

export const sampleCodingColumnFields = [
  {
    name: "Sample ID",
    selector: "sample_id",
    sortable: true,
  },
  {
    name: "Code given to Sample​",
    selector: "code_given_to_sample",
    sortable: true,
    isSearchable: true,
  },
  {
    name: "Date of Sample sent to CIL lab",
    selector: "date_of_sample_sent_to_lab",
    sortable: true,
    cell: (row) => {
      return row?.date_of_sample_sent_to_lab
        ? DDMMYYYYFormat(row?.date_of_sample_sent_to_lab)
        : "";
    },
  },
  {
    name: "Sample coding done by",
    selector: "sample_coding_done_by",
    cell: (row) => {
      return row?.sample_coding_users?.firstName;
    },
  },
  {
    name: "Date of Sample receipt in CIL lab",
    selector: "date_of_sample_receipt_in_lab",
    sortable: true,
    cell: (row) => {
      return row?.date_of_sample_receipt_in_lab
        ? DDMMYYYYFormat(row?.date_of_sample_receipt_in_lab)
        : "";
    },
  },
  {
    name: "Date given for Test",
    selector: "date_given_for_test",
    sortable: true,
    cell: (row) => {
      return row?.date_given_for_test
        ? DDMMYYYYFormat(row?.date_given_for_test)
        : "";
    },
  },
  {
    name: "Status",
    selector: "sample_coding_status",
    sortable: true,
    cell: (row) => {
      if (row?.sample_coding_status == submitStatus?.submitted) {
        return (
          <span className="statusSec submit">{submitStatus?.submitted}</span>
        );
      } else {
        return <span className="statusSec draft">{submitStatus?.drafted}</span>;
      }
    },
  },
  // {
  //   name: "System Feedback",
  //   selector: (row) => <GiGolfFlag className="feedbackIcon" />,
  //   center: true,
  // },
];

export const sampleCodingFilterForm = {
  startDate: "",
  endDate: "",
  // mode_of_dispatch: "",
};

export const sampleCodingData = {
  tableColumn: sampleCodingColumnFields,
  filterForm: sampleCodingFilterForm,
  pageLink: "/coal-grade/sample-coding",
  tableTittle: "Sample Coding Register",
  type: "sample-coding",
  role: ["lab_quality_manager"],
  // viewComponent: SamplePrepareRegisterView,
};
