import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../ContractorManagement/Hindrance/EmptyDataList";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import {
  SidebarData,
  contractorSapApis,
  contractorSapColumnFields,
} from "../ContractorManagement/DATA";
import ContractorSapView from "./ContractorSapView";
import { exportContractorSapExport, getAllSapList } from "../../services/_contractorList/contractorList";

const ContractorSapData = () => {
  const [isListData, setIsListData] = useState(true);

  const getAll = async () => {
    const response = await getAllSapList("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0)
      setIsListData(true);
    else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);
  const viewAsPopup = (form) => {
    return <ContractorSapView form={form} />;
  };

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                breadCrumb={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/contractor-management/configuration",
                  },
                  {
                    name: "SAP data List",
                    path: "#",
                  },
                ]}
                downloadExcel={exportContractorSapExport}
                hideAddButton={true}
                fields={contractorSapColumnFields}
                apis={contractorSapApis}
                title="Data"
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={""}
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                listname={"sapDAta"}
                customTableTitle={"SAP DATA List"}
                isNoTabOnPage
                viewTitle={"SAP DATA List Details"}
                // hideSearch={true}

                deleteFun
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              hideAddBtn={true}
              pageLink={
                "/contractor-management/configuration/sap-import/uploaded-data"
              }
              breadCrumb={[
                {
                  name: "Contractor Management",
                  path: "#",
                },
                {
                  name: "Configuration",
                  path: "/contractor-management/configuration",
                },
                {
                  name: "SAP data List",
                  path: "#",
                },
              ]}
              title={"SAP Data List"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorSapData;
