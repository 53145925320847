import { IS_MOBILE } from "../../../_helpers/helper";
import {
  getContractorPerformancesListWithPagination,
  getMyContractorPerformancesListWithPagination,
} from "../../../services/_contractorPerformance/contractorPerformance";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { TenderIdColumn } from "../../../_helpers/helper";
export const performanceStatus = {
  draft: "Draft",
  submitted: "Submitted",
};
export const sections = {
  east: "East",
  west: "West",
};
export const reasonType = [
  {
    label: "Contractor",
    value: "contractorReason",
  },
  {
    label: "Other",
    value: "otherReason",
  },
];
export const contractorPerformanceColumnFields = [
  {
    name: "Subsidiary",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  {
    name: "Contractor",
    selector: "contractor",
    sortable: true,
    minWidth: "180px",
    cell: (row) => row?.contractor?.contractor,
  },
  {
    name: "NIT / PO",
    selector: "nit",
    minWidth: "260px",
    cell: (row) => TenderIdColumn(row),
  },

  {
    name: "Date",
    selector: "input_date",
    sortable: true,
    minWidth: "130px",
    cell: (row) => (row?.input_date ? DDMMYYYYFormat(row?.input_date) : ""),
  },

  {
    name: "Status",
    selector: "status",
    sortable: false,
    minWidth: "180px",
    cell: (row) =>
      row?.status ? (
        <span className="statusSec draft">
          {performanceStatus[row?.status]}
        </span>
      ) : (
        ""
      ),
  },
];

export const contractorPerformanceInitialForm = {
  id: null,
  status: "",
  contractor_id: "",
  input_date: "",
  contractor_performance_data: [],
  po_id: "",
  nit_id: "",
};

export const availabilityWaterTankerInitialObj = {
  type: "available",
  equipment_name: "Water Tanker",
  shift3_available: "",
  committed_count: "",
  shift1_available: "",
  shift2_available: "",
};
export const availabilityCrusherInitialObj = {
  type: "available",
  equipment_name: "Water Tanker",
  shift3_available: "",
  committed_count: "",
  shift1_available: "",
  shift2_available: "",
};

export const availabilityPayLoaderInitialObj = {
  type: "available",
  equipment_name: "Pay Loader",
  shift3_available: "",
  committed_count: "",
  shift1_available: "",
  shift2_available: "",
};

export const availabilityGraderInitialObj = {
  type: "available",
  equipment_name: "Grader",
  shift3_available: "",
  committed_count: "",
  shift1_available: "",
  shift2_available: "",
};

export const utilizedWaterTankerInitialObj = {
  type: "utilized",
  equipment_name: "Water Tanker",
  shift3_available: "",
  committed_count: "",
  shift1_available: "",
  shift2_available: "",
};

export const utilizedCrusherInitialObj = {
  type: "utilized",
  equipment_name: "Crusher",
  shift3_available: "",
  committed_count: "",
  shift1_available: "",
  shift2_available: "",
};

export const utilizedPayLoaderInitialObj = {
  type: "utilized",
  equipment_name: "Pay Loader",
  shift3_available: "",
  committed_count: "",
  shift1_available: "",
  shift2_available: "",
};

export const utilizedGraderInitialObj = {
  type: "utilized",
  equipment_name: "Grader",
  shift3_available: "",
  committed_count: "",
  shift1_available: "",
  shift2_available: "",
};

export const availableInitialData = [
  {
    type: "available",
    equipment_name: "Excavator",
    shift3_available: "",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    validationKey: "excavator",
  },
  {
    type: "available",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
    equipment_name: "Tipper",
    validationKey: "tipper",
  },
  {
    committed_count: "",
    type: "available",
    equipment_name: "Drill",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
    validationKey: "drill",
  },
  {
    equipment_name: "Dozer",
    committed_count: "",
    type: "available",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
    validationKey: "dozer",
  },
  {
    type: "available",
    equipment_name: "Water Tanker",
    shift3_available: "",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    validationKey: "waterTanker",
  },
  {
    type: "available",
    equipment_name: "Pay Loader",
    shift3_available: "",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    validationKey: "payLoader",
  },
  {
    type: "available",
    equipment_name: "Grader",
    shift3_available: "",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    validationKey: "grader",
  },
  {
    type: "available",
    equipment_name: "Crusher",
    shift3_available: "",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    validationKey: "crusher",
  },
];
export const availableInitialDataError = [
  {
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
  },
  {
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
  },
  {
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
  },
  {
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
  },
];

export const utilizedInitialData = [
  {
    type: "utilized",
    equipment_name: "Excavator",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
    validationKey: "excavator",
  },
  {
    type: "utilized",
    equipment_name: "Tipper",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
    validationKey: "tipper",
  },
  {
    type: "utilized",
    equipment_name: "Drill",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
    validationKey: "drill",
  },
  {
    type: "utilized",
    equipment_name: "Dozer",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
    validationKey: "dozer",
  },
  {
    type: "utilized",
    equipment_name: "Water Tanker",
    shift3_available: "",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    validationKey: "waterTanker",
  },
  {
    type: "utilized",
    equipment_name: "Pay Loader",
    shift3_available: "",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    validationKey: "payLoader",
  },
  {
    type: "utilized",
    equipment_name: "Grader",
    shift3_available: "",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    validationKey: "grader",
  },
  {
    type: "utilized",
    equipment_name: "Crusher",
    shift3_available: "",
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    validationKey: "crusher",
  },
];
export const utilizedInitialDataError = [
  {
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
  },
  {
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
  },
  {
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
  },
  {
    committed_count: "",
    shift1_available: "",
    shift2_available: "",
    shift3_available: "",
  },
];
export const formFieldDataError = {
  contractor_id: "",
  input_date: "",
};

export const contractorPerformanceLabel = {
  id: null,
  contractor_id: "Contractor Id",
  input_date: "Input Date",
  contractor_performance_data: "Contractor Performance Data",
};
export const contractorPerformanceViewLabel = {
  type: "Type",
  equipment_name: "Equipment Name",
  committed_count: "Committed Count",
  shift1_available: "Shift1 Available",
  shift2_available: "Shift2 Available",
  shift3_available: "Shift3 Available",
};

export const contractorPerformanceRelatedRules = [
  {
    field_name: "contractor_id",
    label: contractorPerformanceLabel?.contractor_id,
    type: "number",
    isRequired: true,
  },
  {
    field_name: "input_date",
    label: contractorPerformanceLabel?.input_date,
    type: "string",
    isRequired: true,
  },
];

export const contractorPerformanceFieldRules = [
  {
    field_name: "committed_count",
    // label: "Committed Count",
    type: "number",
    isRequired: true,
  },
  {
    field_name: "shift1_available",
    // label: "Shift1 Available",
    type: "number",
    isRequired: true,
  },
  {
    field_name: "shift2_available",
    // label: "Shift2 Available",
    type: "number",
    isRequired: true,
  },
  {
    field_name: "shift3_available",
    // label: "Shift3 Available",
    type: "number",
    isRequired: true,
  },
];

export const contractorPerformanceApis = Object.freeze({
  getAll: {
    api: getContractorPerformancesListWithPagination,
  },
});
export const myContractorPerformanceApis = Object.freeze({
  getAll: {
    api: getMyContractorPerformancesListWithPagination,
  },
});

export const contractorPerformanceFilterForm = {
  startDate: "",
  endDate: "",
  contractor_id: "",
  status: "",
};

export const contractorPerformanceDashboardForm = {
  month: "",
  subsidiary: "",
  mine: "",
  vendor: "",
};

export const contractorPerformanceScoreDashboardForm = {
  subsidiary: "",
  mine: "",
  tender: "",
  vendor: "",
  po: "",
  topReasonType: "contractorReason",
  reasonType: "contractorReason",
};
export const EditLink = `${
  IS_MOBILE() ? "/mobile" : ""
}/contractor-management/contractor-performances/edit`;

export const radorListVendor = {
  benchScore: "Bench Condition",
  biometricScore: "Biometric Attendance",
  distributionSafetyGadgetScore: "Distribution of Safety Gadget",
  dustSuppressionScore: "Dust Suppression Measures",
  environmentScore: "Environment Compliance",
  equipmentRunningScore: "Equipment in Running Order",
  equipmentRollScore: "Equipment on Roll",
  fireProtectionScore: "Fire Protection System",
  haulRoadScore: "Haul Road Parameters",
  imePmeScore: "IME/PME Status",
  paymentClipScore: "Payment through Clip",
  safetyFeatureScore: "Safety Feature in Equipment",
  vtcCertificateScore: "VTC Certificate Status",
  illuminationScore: "Illumination Score",
};

export const months = [
  { value: "7-2022", label: "Jul 2022" },
  { value: "8-2022", label: "Aug 2022" },
  { value: "9-2022", label: "Sep 2022" },
  { value: "10-2022", label: "Oct 2022" },
  { value: "11-2022", label: "Nov 2022" },
  { value: "12-2022", label: "Dec 2022" },
  { value: "1-2023", label: "Jan 2023" },
  { value: "2-2023", label: "Feb 2023" },
  { value: "3-2023", label: "Mar 2023" },
  { value: "4-2023", label: "Apr 2023" },
  { value: "5-2023", label: "May 2023" },
  { value: "6-2023", label: "Jun 2023" },
];

export function formatMonthYearArray(monthYearArray) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return monthYearArray.map((item) => {
    const [month, year] = item.date.split("-");
    const formattedMonth = months[parseInt(month) - 1];
    const label = formattedMonth + " " + year;
    return { value: item.date, label: label };
  });
}
