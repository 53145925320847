import {
  barChartDataFormat,
  basicBarChartWithCustomTooltipDataFormat,
  multiLineChartDataFormat,
  multipleBarChartDataFormat,
  pieChartDataWithModalForTotalVs,
  stackChartDataFormat,
} from "../../../components/Chart/Common/config";
import CGDispatchDistribution from "./CGDispatchDistribution";
import CGDispatchTrend from "./CGDispatchTrend";
import CGSlippageReport from "./CGSlippageReport";
import RefreeData from "./RefreeData";

export const Tabs = [
  { id: 1, label: "3rd Party", value: "3rdparty" },
  { id: 2, label: "CIL Lab", value: "cil" },
];
export const nestedChartTabs = [
  {
    id: 3,
    label: "No. of Grade Slippage Instance",
    value: "slippage_instance",
  },
  { id: 4, label: "Dispatch Quantity", value: "dispatch_quantity" },
];
export const screenPage = [
  {
    link: `/coal-grade/dashboard/1`,
    screen: 1,
  },
  {
    link: `/coal-grade/dashboard/2`,
    screen: 2,
  },
  {
    link: `/coal-grade/dashboard/3`,
    screen: 3,
  },
  {
    link: `/coal-grade/dashboard/4`,
    screen: 4,
  },
];
export const initialFilterForm = {
  companyId: "",
  mineId: "",
  dispatchModeId: "",
  customerId: "",
  samplingAgencyId: "",
  measuredGradeId: "",
  startDate: "",
  endDate: "",
  //   tab: "3rdparty",
};
export const chartFilter = [
  { id: 4, label: "D", value: "D" },
  { id: 5, label: "W", value: "W" },
  { id: 6, label: "M", value: "M" },
  { id: 7, label: "Q", value: "Q" },
  { id: 8, label: "Y", value: "Y" },
];
export const gradeSlippageFilterOptions = [
  { label: "Rahul", value: "Rahul" },
  { label: "Rohan", value: "Rohan" },
];

export const gradeSlippageFilterOptions2 = [
  { label: "Select All", value: "" },
  { label: "Road", value: "SHRO" },
  { label: "Rail", value: "SHRA" },
  { label: "Merry Go Round", value: "SHMG" },
];

export const CGDistributionPageChartFilter = {
  stackChart: "",
};
export const trendPageChartFilter = {
  stackChart: "D",
  lineChart: "D",
};
export const CGSlippagePageChartFilter = {
  //   lineDashboard: "3rd Party Lab",
  //   barDashboard: "Dispatch Mode",
  coalDispatchedbarChart: "Dispatch Mode",
  MultibarChart: "Dispatch Mode",
  coalGradeSlippagebarChart: "",
  pieNesthart: "slippage_instance",
};
export const PAGE_TYPE_BY_CHART_FILTER = (screen) => {
  switch (+screen) {
    case 1:
      return CGDistributionPageChartFilter;
    case 2:
      return trendPageChartFilter;
    case 3:
      return CGSlippagePageChartFilter;
    default:
      return {};
  }
};
export const CHART_TYPE_BY_DATA = (screen, chartData) => {
  if (!chartData?.length) return [];
  switch (screen) {
    case "multiLine":
      return multiLineChartDataFormat(chartData);
    case "multiBar":
      return multipleBarChartDataFormat(chartData);
    case "barWithCustomTooltip":
      return basicBarChartWithCustomTooltipDataFormat(chartData);
    case "pieVs":
      return pieChartDataWithModalForTotalVs(chartData);
    case "line":
      return barChartDataFormat(chartData);
    case "stack":
      return stackChartDataFormat(chartData);
    case "nestPie":
      return chartData;
    default:
      return "No Chart";
  }
};
export const CGDistributionPageChartList = [
  {
    name: "Gradewise Dispatch Distribution - Declared vs Analyzed",
    yAxisName: "Count",
    xAxisName: "Month",
    value: "barChart",
    type: "multiBar",
    // options: "",
    hideFilter: true,
  },
  {
    name: "Analysed Coal Grade Distribution",
    // yAxisName: "Count",
    // xAxisName: "",
    value: "pieChart",
    type: "pieVs",
    hideFilter: true,
    //   options: "",
  },
  {
    name: "Customer wise Distribution for Coal Grade",
    yAxisName: "Count",
    xAxisName: "Month",
    value: "stackChart",
    type: "stack",
    options: [],
    hideXaxis: true,
    // hideFilter: true,
  },
];
export const CGDistributionTrendPageChartList = [
  {
    name: "Trend for Coal Grade",
    // yAxisName: "Count",
    // xAxisName: "Month",
    value: "stackChart",
    type: "stack",
    options: chartFilter,
    // hideFilter: true,
    // isMines: true,
  },
  {
    name: "Weighted Averaged of Measured GCV",
    yAxisName: "kCal/kg",
    // xAxisName: "",
    value: "lineChart",
    type: "line",
    // options: "",
  },
];
export const CGSlippagePageChartList = [
  {
    name: "Coal dispatched with grade slippage",
    yAxisName: "Tonne",
    // xAxisName: "Month",
    value: "coalDispatchedbarChart",
    type: "stack",
    options: [],
    // hideFilter: true,
    hideTabFilter: true,
  },
  {
    name: "Coal dispatched with grade upgradation",
    yAxisName: "Tonne",
    // xAxisName: "",
    value: "MultibarChart",
    type: "multiBar",
    options: [],
    // hideFilter: true,
    hideTabFilter: true,
  },
  {
    name: "Grade Slippage (%) till date",
    yAxisName: "%",
    // xAxisName: "Month",
    value: "coalGradeSlippagebarChart",
    type: "barWithCustomTooltip",
    options: gradeSlippageFilterOptions2,
    // hideFilter: true,
  },
  {
    name: "Grade Slippage (3rd Party lab-wise comparison)",
    yAxisName: "Count",
    // xAxisName: "",
    value: "pieNesthart",
    type: "nestPie",
    // options: "",
    hideFilter: true,
    hideTabFilter: true,
    switchTab: true,
    className: "nestChartFltr",
  },
];
export const RefreeChartList = [
  {
    name: "Referred By",
    value: "referredBy",
    type: "pieVs",
  },
  {
    name: "Referred To",
    value: "referredTo",
    type: "pieVs",
  },
];

export const PAGE_TYPE_BY_CHART_LIST = (screen) => {
  switch (+screen) {
    case 1:
      return CGDistributionPageChartList;
    case 2:
      return CGDistributionTrendPageChartList;
    case 3:
      return CGSlippagePageChartList;
    case 4:
      return RefreeChartList;
    default:
      return [];
  }
};

export const PAGE_TYPE_BY_DASHBOARD = (
  page,
  cref,
  form,
  appliedFilters,
  customerList,
  selectedTab,
  setMinMaxDate
) => {
  switch (+page) {
    case 1:
      return (
        <CGDispatchDistribution
          cref={cref}
          form={form}
          pageType={page}
          appliedFilters={appliedFilters}
          customerList={customerList}
          topSelectedTab={selectedTab}
          setMinMaxDate={setMinMaxDate}
        />
      );
    case 2:
      return (
        <CGDispatchTrend
          cref={cref}
          form={form}
          pageType={page}
          appliedFilters={appliedFilters}
          topSelectedTab={selectedTab}
          setMinMaxDate={setMinMaxDate}
        />
      );
    case 3:
      return (
        <CGSlippageReport
          cref={cref}
          form={form}
          pageType={page}
          appliedFilters={appliedFilters}
          customerList={customerList}
          topSelectedTab={selectedTab}
          setMinMaxDate={setMinMaxDate}
        />
      );
    case 4:
      return (
        <RefreeData
          cref={cref}
          form={form}
          pageType={page}
          appliedFilters={appliedFilters}
          customerList={customerList}
          topSelectedTab={selectedTab}
          setMinMaxDate={setMinMaxDate}
        />
      );
    default:
      return "";
  }
};
