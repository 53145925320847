export const tabUrl = {
  contractorHindrance: [
    {
      to: "/contractor-management/my-approval-action-contractor-hindrance",
      label: "Representative",
      value: "representative",
      type: 2,
      active: 1,
      permission: ["representative"],
    },
    {
      to: "/contractor-management/my-approval-action-contractor-hindrance",
      label: "Section Incharge",
      value: "section_incharge",
      type: 1,
      active: 0,
      permission: ["section_incharge"],
    },
    {
      to: "/contractor-management/my-approval-action-contractor-hindrance",
      label: "Colliery Manager",
      value: "colliery_manager",
      type: 3,
      active: 0,
      permission: ["colliery_manager"],
    },
  ],
  contractorHindranceHistory: [
    {
      to: "/contractor-management/my-approval-history-contractor-hindrance",
      label: "Representative",
      value: "representative",
      type: 2,
      active: 1,
      permission: ["representative"],
    },
    {
      to: "/contractor-management/my-approval-history-contractor-hindrance",
      label: "Section Incharge",
      value: "section_incharge",
      type: 1,
      active: 0,
      permission: ["section_incharge"],
    },
    {
      to: "/contractor-management/my-approval-history-contractor-hindrance",
      label: "Colliery Manager",
      value: "colliery_manager",
      type: 3,
      active: 0,
      permission: ["colliery_manager"],
    },
  ],
  envConfig: [
    {
      to: "/configuration/environment/air-quality-emr",
      label: "Air Quality EMR  Station Category",
      active: 0,
      permission: ["env_admin", "super_admin"],
    },
    {
      to: "/configuration/environment/noise",
      label: "Noise Station Category",
      active: 0,
      permission: ["env_admin", "super_admin"],
    },
    {
      to: "/configuration/environment/sation-type-water",
      label: "Water Station Type",
      active: 0,
      permission: ["env_admin", "super_admin"],
    },
    {
      to: "/configuration/environment/station-category-water",
      label: "Water Station Category",
      active: 0,
      permission: ["env_admin", "super_admin"],
    },
    {
      to: "/configuration/environment/ground-water/well",
      label: "Ground Water Well",
      active: 0,
      permission: ["env_admin"],
    },
  ],
};
export const tabUrl2 = {
  contractorPerformance: [
    {
      to: "/contractor-management/configuration/reason-list",
      label: "Reason List",
      active: 0,
      permission: ["contractor_module_admin"],
    },
    {
      to: "/contractor-management/configuration/contractor-list",
      label: "Contractor List",
      active: 0,
      permission: ["contractor_module_admin"],
    },
    {
      to: "/contractor-management/user-list",
      label: "Contractor User List",
      active: 0,
      permission: ["contractor_module_admin"],
    },
  ],
};
