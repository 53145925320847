import React, { useMemo, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import { settingApis, settingKeys } from "./DATA";
// import AreaView from "./AreaView";
import { SidebarData } from "./DATA";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { capitalizeName } from "../../_helpers/helper";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../ContractorManagement/Hindrance/EmptyDataList";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { changeStatus } from "../../_services/CommonService/CommonService";

const SettingList = () => {
  const [isListData, setIsListData] = useState(true);

  const navigate = useNavigate();

  let newAddLink = "/user-management/settings";

  const onEdit = (id) => {
    navigate(`${newAddLink}/${id}`);
  };

  const cref = useRef(null);
  //   const activeInactive = useCallback(async (e, id, status) => {
  //     try {
  //       e.preventDefault();
  //       const data = {
  //         modelName: `area_of_operations`,
  //         id: id,
  //         status: status
  //       }
  //       const response = await changeStatus(data);
  //       console.log("ress", response);
  //       if (response?.status) {
  //         toastr.success(response?.message);
  //         cref.current.getAll();
  //       }
  //     } catch (error) {
  //       toastr.error(error);
  //       return {
  //         status: 0,
  //         message: error,
  //       };
  //     }
  //   }, []);

  const columnFields = useMemo(
    () => [
      {
        name: "Setting Name",
        selector: "settingName",
        sortable: true,
        // minWidth: "200px",
        cell: (row) => {
          return settingKeys[row?.settingName];
        },
      },
      {
        name: "Setting Value",
        selector: "settingValue",
        sortable: true,
        // minWidth: "200px",
        cell: (row) => {
          return capitalizeName(`${row?.settingValue}`);
        },
      },
      //   {
      //     name: "Status",
      //     selector: "status",
      //     cell: (row) => {
      //       return (
      //         (row.status === 1 && (
      //           <span
      //             href="#"
      //             className="icons text-success pointer"
      //             onClick={(e) =>
      //               activeInactive(e, row.id, row.status, "status")
      //             }
      //           >
      //             <FaToggleOn
      //               size="20"
      //               data-testid={"toogle-user-list-on-" + row.id}
      //             />
      //           </span>
      //         )) ||
      //         (row.status === 0 && (
      //           <span
      //             href="#"
      //             className="icons text-danger pointer"
      //             onClick={(e) =>
      //               activeInactive(e, row.id, row.status, "status")
      //             }
      //           >
      //             <FaToggleOff
      //               size="20"
      //               data-testid={"toogle-user-list-off-" + row.id}
      //             />
      //           </span>
      //         ))
      //       );
      //     },
      //   },
    ],
    []
  );

  //   const viewAsPopup = (form) => {
  //     return <AreaView form={form} />;
  //   };

  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  apis={settingApis}
                  primaryKey="id"
                  viewTitle="Settings"
                  editTitle="Edit Settings"
                  customTableTitle="Settings"
                  // hideSearch
                  // FilterBtn={newFilterBtn}
                  //   viewAsPopup={viewAsPopup}
                  // editAsPopup={editAsPopup}
                  hideSnColumn
                  pageLink={newAddLink}
                  pagination
                  hideAddButton={true}
                  hideDeleteButton
                  hideEditButton
                  cref={cref}
                  fields={columnFields}
                  deleteFun
                  // childrenAfterAddButton={tabButtons}
                  editFunButton={onEdit}
                  breadCrumb={[
                    {
                      name: "Super Admin",
                      path: "/",
                    },
                    {
                      name: "Settings",
                      path: "#",
                    },
                  ]}
                  title="Data"
                  isNoTabOnPage
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <EmptyDataList
                // tabButtons={tabButtons}
                customTableTitle="Settings"
                pageLink={newAddLink}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SettingList;
