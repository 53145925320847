import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import toastr from "toastr";
import { useNavigate, useParams, useLocation } from "react-router";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import FormInputText from "../../../components/FormInputText/FormInputText";
import {
    createMasterDropdown,
    updateMasterDropdown,
    updatePOMaster,
} from "../../../services/_reasonList/reasonList";
import { SidebarData as incidentSidebarData } from "../../SafetyManagementSystem/IncidentManagement/DATA";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData, isCodeApplied, validationRules } from "../DATA";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { FuelSidebarData } from "../../FuelManagementSystem/FuelManagement/DATA";
import { Validation } from "../../../_helpers/validation/Validation";
import Select from "../../../components/Select/Select";
import { getAllNit, getAllNitBySiteIdAndContractor, getNitList } from "../../../services/_contractorPerformance/contractorPerformance";

const PoEdit = ({ flag }) => {
    const [messagePopupType, setMessagePopupType] = useState(false);
    const [formFieldError, setFormFieldError] = useState({
        name: "",
        type: "",
        code: "",
    });
    const [showMessagePopUp, setShowMessagePopUp] = useState(false);
    const [form, setForm] = useState({
        name: "",
        type: "",
    });
    const [nitOptions, setNitOptions] = useState([]);
    const [tab, setTab] = useState(1);
    const param = useParams();
    const navigate = useNavigate();
    const usrl = useLocation();
    let showCode = true;

    showCode =
        usrl.pathname.includes("/violations-leading") ||
        usrl.pathname.includes("/designation");

    const getData = useCallback(async () => {
        const response = await RestMethod.GET("/contractor/po/" + param.id);
        setForm({ ...response?.data, nit: response?.data?.nit_id, mine: response?.data?.site?.name, subsidiary: response?.data?.site?.parentSite?.name });
        const nitOptionsData = await getAllNitBySiteIdAndContractor(`site_id=${response?.data?.site_id}&contractor_id=${response?.data?.contractor_id}`);
        if (nitOptionsData?.status && nitOptionsData?.data?.data?.length > 0) setNitOptions(nitOptionsData?.data?.data.map(item => {
            return {
                name: item.nit,
                id: item.id
            };
        }));
    }, [param.id]);


    const onChange = (e) => {
        const { name, value } = e.target;
        formFieldError[name] = "";
        setForm({ ...form, [name]: value });
    };

    const closeMessagePopup = () => {
        setShowMessagePopUp(false);
        navigate("/contractor-management/configuration/po-list");
    };

    const tabFun = (n) => {
        setTab(n);
    };

    useEffect(() => {
        getData();
    }, [getData, param.id]);

    const onSubmit = async () => {
        let response = "";
        let data = { nit_id: form?.nit, site_id: form?.site_id };

        if (form?.name === "") return;
        if (form?.code?.length > 3) {
            setFormFieldError({
                name: "",
                code: "Max 3 digit code is Allowed",
            });
            return;
        }
        response = await updatePOMaster(data, param.id);

        if (!response?.data?.status || response?.data?.status === 401) {
            !response?.data?.status && toastr.error(response?.data?.message);
        } else {
            toastr.success("data saved successfully");
            setShowMessagePopUp(true);
        }
    };
    return (
        <div className="sidebarWrapper">
            <SafetySideBar
                SidebarData={SidebarData}
            />
            <div className="content" id="page-content-wrapper">
                <div className="contentArea">
                    <Container fluid>
                        <h3
                            style={{ paddingBottom: "1px", paddingTop: "5px" }}
                            className="title"
                        >
                            <MainBreadcrumb
                                routeSegments={
                                    [
                                        {
                                            name: "Contractor Management",
                                            path: "#",
                                        },
                                        {
                                            path: "/contractor-management/configuration/po-list",
                                            name: "Configuration",
                                        },
                                        {
                                            name: "Edit PO",
                                            path: "#",
                                        },
                                    ]
                                }
                            />
                        </h3>
                        <h3 className="title d-flex align-items-end">
                            {"Edit"}{" "}
                            {"PO"}
                        </h3>
                        <PerfectScrollbar className="customHeight">
                            <div className="reportCard">
                                <Form>
                                    <Row>
                                        <Col sm={6}>
                                            <FormInputText
                                                type="text"
                                                placeHolder="Enter here"
                                                label={"Subsidiary"}
                                                onChange={onChange}
                                                name="subsidiary"
                                                error={formFieldError?.subsidiary}
                                                value={form?.subsidiary}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <FormInputText
                                                type="text"
                                                placeHolder="Enter here"
                                                label={"Mine"}
                                                onChange={onChange}
                                                name="mine"
                                                error={formFieldError?.mine}
                                                value={form?.mine}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <FormInputText
                                                type="text"
                                                placeHolder="Enter here"
                                                label={"Contractor"}
                                                onChange={onChange}
                                                name="contractor"
                                                error={formFieldError?.contractor?.contractor}
                                                value={form?.contractor?.contractor}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <FormInputText
                                                type="text"
                                                placeHolder="Enter here"
                                                label={"PO"}
                                                onChange={onChange}
                                                name="contractor"
                                                error={formFieldError?.po}
                                                value={form?.po}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Select
                                                label={"NIT Number"}
                                                name="nit"
                                                value={form?.nit}
                                                onChange={onChange}
                                                options={nitOptions}
                                                KEY_label={"name"}
                                                KEY_value={"id"}
                                                error={formFieldError?.nit}
                                                blankSelect={"Select"}
                                            ></Select>
                                        </Col>
                                    </Row>
                                </Form>

                                <div className="btnSec">
                                    <Button
                                        className="secondaryBtn"
                                        onClick={() =>
                                            navigate(
                                                "/contractor-management/configuration/po-list"
                                            )
                                        }
                                    >
                                        Back
                                    </Button>
                                    <div className="d-flex">
                                        <Button
                                            className="primaryBtn"
                                            tab={tab}
                                            // nextStapeFun={nextStapeFun}
                                            tabFun={tabFun}
                                            onClick={onSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </Container>
                    <MessagePopup
                        closePopup={closeMessagePopup}
                        setShow={setShowMessagePopUp}
                        messagePopupType={messagePopupType}
                        show={showMessagePopUp}
                    />
                </div>
            </div>
        </div>
    );
};

export default PoEdit;
