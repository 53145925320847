import React, { useCallback, useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UploadFile from "../../../components/_form/UploadFile/UploadFile";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  initialForm,
  reportTypeList,
  reportTypeListObj,
  extensions,
  SidebarData,
  reportTypeListTableLabel,
} from "../UploadedFiles/DATA";
import { useForm } from "../../../_helpers/hooks";
import toastr from "toastr";

import Select from "../../../components/Select/Select";
import {
  getAllSites,
  uploadFile,
} from "../../../services/_uploadedFiles/uploadedFiles";
import ErrorFields from "./ErrorFields";
import "./environment.scss";
import BulkUploadAirQuality from "./BulkUploads/BulkUploadAirQuality";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import {
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
  auth_user,
  idByCheckMine,
  subsidiaryListByMine,
} from "../../../_helpers/helper";
import { uploadFileOnS3 } from "../../../services/S3Service";

const UploadFileAdd = () => {
  const params = useLocation();
  const selectedFileType = new URLSearchParams(params?.search).get("type");
  const [isloading, setIsloading] = useState(false);
  const [show, setShow] = useState(false);
  const [dataForError, setDataForError] = useState(false);
  const [FileError, setFileError] = useState("");
  const [form, onChange, setForm] = useForm(initialForm);
  const [allsiteListDrop, setAllSiteListDrop] = useState([]);
  const [allMineListDrop, setAllMineListDrop] = useState([]);
  const [messagePopupType, setMessagePopupType] = useState(false);

  const [uploadAreaQualityFlag, setUploadAreaQualityFlag] = useState(false);
  const [uploadFiles, setUploadFiles] = useState(false);
  const [uploadAreaQualityList, setUploadAreaQualityList] = useState([]);

  const [file, setFile] = useState("");
  const [sites, setSites] = useState([]);
  const [showErrorList, setShowErrorList] = useState("");
  const [submit, setSubmit] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [serverErrorData, setServerErrorData] = useState([]);
  const [s3url, setS33url] = useState("");
  let navigate = useNavigate();
  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };
  const closePopup = () => {
    setShow(false);
    navigate("/environment-management/manage");
  };
  const errorList = () => {
    if (!dataForError) return;
    setShowErrorList(true);
  };
  const urlFileType = useLocation().search.replace("?type=", "");

  const backToTable = useCallback(() => {
    setUploadAreaQualityFlag((prev) => !prev);
  }, []);

  // useEffect(() => {
  //   if (!IS_USER_SITE_MINES()) return;
  //   console.log("pppppiiiiiii++++", allMineListDrop);
  //   const siteObj =
  //     allMineListDrop?.find((d) => +d?.value === +auth_user()?.siteId) || {};
  //   console.log("ppppp", siteObj);
  //   setForm((prev) => ({
  //     ...prev,
  //     siteId: siteObj?.value,
  //   }));
  // }, [allMineListDrop]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      mine: idByCheckMine(sites)?.siteId,
      subsidiary: idByCheckMine(sites)?.subsideryId,
    }));
  }, [sites]);

  const getSiteList = async () => {
    let allsiteList = await getAllSites(
      "filter[siteType]=1,2,3&filter[activeStatus]=active"
    );
    if (allsiteList.status === 1 && allsiteList.message == "success") {
      setSites(allsiteList?.data);
      setAllSiteListDrop(subsidiaryListByMine(allsiteList?.data));

      // let dataObjSite = [];
      // allsiteList?.data?.forEach((data) => {
      //   if (data.siteType === 2)
      //     dataObjSite.push({
      //       value: data.id,
      //       label: data.name,
      //     });
      //   else if (data.siteType === 3) {
      //     dataObjSite.push({
      //       value: data?.parentSite?.id,
      //       label: data?.parentSite?.name,
      //     });
      //   }
      // });
      // setAllSiteListDrop(dataObjSite);
    }
  };

  useEffect(() => {
    setAllMineListDrop(
      form?.subsidiary
        ? sites?.filter((d) => d?.parentSiteId === +form?.subsidiary)
        : sites
    );
  }, [form?.subsidiary, sites]);

  useEffect(() => {
    let dataObjMine = [];

    if (sites.length > 0) {
      sites?.forEach((data) => {
        if (data.siteType === 3) {
          dataObjMine.push({
            id: data.id,
            name: data.name,
          });
        }
      });
    }
    setAllMineListDrop(dataObjMine);
  }, [sites]);

  const onChangeFile = (e) => {
    setFile(e.file);
  };

  useEffect(() => {
    setFileError("");
  }, [file]);

  useEffect(() => {
    getSiteList();
  }, []);
  const setShowError = (e) => {
    setShowErrorList(e);
  };

  const onSubmit = async () => {
    setDataForError(false);
    setSubmit(true);
    if (
      form.subsidiary != "" &&
      form.mine != "" &&
      form?.fileType != "" &&
      file != ""
    ) {
      setIsloading(true);
      // const formData = new FormData();
      // formData.append("fileType", form.fileType);
      // formData.append("mine", form.mine);
      // formData.append("subsidiary", form.subsidiary);
      const s3UrlData = await uploadFileOnS3(file, "xlx/xlsx");
      // formData.append("file", s3UrlData);
      setS33url(s3UrlData);
      const response = await uploadFile({
        fileType: form.fileType,
        mine: form.mine,
        subsidiary: form.subsidiary,
        file: s3UrlData,
      });
      if (
        response?.response?.data?.status === 1
        // ||
        // response?.response?.data?.message === "File validation failed"
      ) {
        if (response?.response?.data?.data?.invalidData) {
          setServerErrorData(response?.response?.data?.data?.invalidData);
          // setUploadAreaQualityFlag(true);
          setDataForError(true);
          let output = {};
          let res = response?.response?.data?.data;
          for (let item of res["invalidData"]) {
            if (output[item["index"]]) {
              output[item["index"]]["message"] =
                output[item["index"]]["message"] + ", " + item["message"];
            } else {
              output[item["index"]] = {
                index: item["index"],
                row: item["row"],
                message: item["message"],
              };
            }
          }
          setErrorData(output);
          setFileError(response?.response?.data?.data?.message);
        } else {
          setUploadAreaQualityList(
            response?.response?.data?.data?.fileData || []
          );
          setUploadFiles(response?.response?.data?.data || {});
          setUploadAreaQualityFlag(true);
        }
        // setShow(true);
        // setMessagePopupType(false);
        setIsloading(false);
      } else {
        toastr.error(response?.response?.data?.message || "Error");
        setFileError(response?.response?.data?.message);
      }
      if (response) setIsloading(false);
    }
  };

  const removeFile = () => {
    setFile("");
    setFileError("");
  };
  useEffect(() => {
    // let authUser = localStorage.getItem("auth_user");
    // // let user_site = authUser ? JSON.parse(JSON.parse(authUser)?.siteId) : "";
    // // form.mine = user_site;
    form.fileType = reportTypeListObj[urlFileType];
    if (!form.fileType) {
      navigate("/environment-management/manage");
    }
  }, [form, urlFileType]);
  // if (sites.length > 0) {
  //   sites?.forEach((data) => {
  //     if (data.id === form.mine) {
  //       form.subsidiary = data.parentSiteId;
  //     }
  //   });
  // }
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        {uploadAreaQualityFlag ? (
          <>
            <div className="contentArea">
              <Container fluid>
                <h3
                  className="mb-2"
                  style={{ paddingBottom: "1px", paddingTop: "5px" }}
                >
                  <MainBreadcrumb
                    routeSegments={[
                      {
                        name: "Environment Management System",
                        path: "#",
                      },
                      {
                        name: "Environment Management",
                        path: "/environment-management/manage",
                      },
                      {
                        name: reportTypeListTableLabel[urlFileType],
                        path: "/environment-management/manage/" + urlFileType,
                      },
                      {
                        name: "Upload File",
                        path: "#",
                      },
                    ]}
                    // module={ACTIVE_MODULE.incident}
                  />
                </h3>
                <h3 className="title mb-2 d-flex align-items-end">
                  {reportTypeListTableLabel[urlFileType] + " "}Upload File
                  &nbsp;
                  <span className="blueLabel"></span>
                </h3>
                <PerfectScrollbar className="customHeight">
                  <div className="reportCard">
                    <BulkUploadAirQuality
                      s3UrlData={s3url}
                      bulkDataList={uploadAreaQualityList}
                      uploadFile={uploadFiles}
                      back={backToTable}
                      serverErrorData={serverErrorData}
                      // trainingOption={trainingOption}
                      // siteList={siteList}
                      isLoading={isloading}
                    />
                  </div>
                </PerfectScrollbar>
              </Container>
            </div>
          </>
        ) : (
          <div className="contentArea">
            <Container fluid>
              <h3
                className=""
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Environment Management System",
                      path: "#",
                    },
                    {
                      name: "Environment Management",
                      path: "/environment-management/manage",
                    },
                    {
                      name: reportTypeListTableLabel[urlFileType],
                      path: "/environment-management/manage/" + urlFileType,
                    },
                    {
                      name: "Upload File",
                      path: "#",
                    },
                  ]}
                  // module={ACTIVE_MODULE.incident}
                />
              </h3>
              <h3 className="title d-flex align-items-end">
                {reportTypeListTableLabel[urlFileType] + " "}Upload File &nbsp;
                <span className="blueLabel"></span>
              </h3>
              <PerfectScrollbar className="customHeight">
                <div className="reportCard">
                  <Form>
                    <Row>
                      <Col md={4}>
                        <Select
                          disabled={IS_USER_SITE_MINES() || IS_USER_SITE_RO()}
                          onChange={onChange}
                          value={form?.subsidiary}
                          name="subsidiary"
                          options={allsiteListDrop}
                          label={"Subsidiary"}
                          KEY_label={"name"}
                          KEY_value={"id"}
                        />
                        {submit && form?.subsidiary == "" && (
                          <p style={{ color: "red" }}>Subsidiary Required</p>
                        )}
                      </Col>
                      <Col md={4}>
                        <Select
                          // disabled
                          onChange={onChange}
                          value={form?.mine}
                          name="mine"
                          options={allMineListDrop}
                          label={"Mine"}
                          disabled={IS_USER_SITE_MINES()}
                          KEY_label={"name"}
                          KEY_value={"id"}
                        />
                        {submit && form?.mine == "" && (
                          <p style={{ color: "red" }}>Mine Required</p>
                        )}
                      </Col>

                      <Col md={4}>
                        <Select
                          onChange={onChange}
                          value={form?.fileType}
                          disabled={true}
                          name="fileType"
                          options={reportTypeList}
                          label={"Report Type"}
                        />
                        {submit && form?.fileType == "" ? (
                          <p style={{ color: "red" }}>Report Type Required</p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>

                    <UploadFile
                      extensions={extensions}
                      removeFile={removeFile}
                      sapmleFile={form?.fileType}
                      onChangeFile={onChangeFile}
                    />
                    {isloading && <div className="loader"></div>}
                    {submit && file == "" && !isloading ? (
                      <p
                        style={{ color: "red" }}
                        className={`${file ? "error_box" : ""}`}
                      >
                        File Required
                      </p>
                    ) : (
                      // : submit && file != "" && !isloading ? (
                      //   <p
                      //     className={` ${FileError ? "error_box" : ""}`}
                      //     onClick={errorList}
                      //   >
                      //     {FileError ? (
                      //       <>
                      //         <BiError className="erroricon" />
                      //         {!dataForError ? (
                      //           <>Details:-{FileError.replace(/_/g, " ")}</>
                      //         ) : (
                      //           <>Details:-{FileError}</>
                      //         )}
                      //       </>
                      //     ) : (
                      //       ""
                      //     )}
                      //   </p>
                      // )
                      <></>
                    )}
                  </Form>
                  <div className="btnSec">
                    <Button
                      onClick={() => closeFun()}
                      className="secondaryBtn"
                      data-testid="handle-click-for-show"
                    >
                      Close
                    </Button>
                    <div className="d-flex">
                      <Button
                        onClick={() => onSubmit()}
                        className="primaryBtn"
                        disabled={isloading}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </Container>
            <MessagePopup
              messagePopupType={messagePopupType}
              show={show}
              setShow={setShow}
              closePopup={closePopup}
            />
            {/* <ErrorFields
              errorData={errorData}
              show={showErrorList}
              setShow={setShowError}
              closePopup={closePopup}
            /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadFileAdd;
