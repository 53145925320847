import {
  getAllTank,
  getTankDataBydataId,
} from "../../../../services/_fuelDispense/fuelDispense";
export const initialForm = {
  name: "",
  site_id: "",
  maxCapacity: null,
  openingStock: null,
  closingStock: null,
  openingDip: null,
  closingDip: null,
  tankId: "",
};
export const tankLabel = {
  site_id: "Mine",
  name: "Tank Name",
  maxCapacity: "Max Capacity (L)",
  openingStock: "Opening Stock",
  openingDip: "Opening Dip (L)",
  closingDip: "Tank Fuel Level at Start (L)",
  tankId: "Tank Id",
};
export const validationRules = [
  {
    label: tankLabel.name,
    type: "string",
    isRequired: true,
    field_name: "name",
  },
  {
    label: tankLabel.tankId,
    type: "string",
    isRequired: true,
    field_name: "tankId",
  },
  {
    field_name: "site_id",
    label: tankLabel.site_id,
    isRequired: true,
    type: "string",
  },
  {
    label: tankLabel.maxCapacity,
    field_name: "maxCapacity",
    type: "number",
    isRequired: true,
  },
  {
    label: tankLabel.closingDip,
    field_name: "closingDip",
    type: "number",
    isRequired: true,
  },
  // {
  //   label: tankLabel.closingStock,
  //   field_name: "closingStock",
  //   type: "number",
  //   isRequired: true,
  // },
];

export const tankApi = {
  getAll: {
    api: getAllTank,
  },
  getById: {
    api: getTankDataBydataId,
  },
};

export const fuelLocationColumnFields = [
  {
    name: tankLabel.site_id,
    selector: "mine_id",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  {
    name: tankLabel.name,
    selector: "name",
    sortable: true,
    cell: (row) => row?.name,
  },
  {
    name: tankLabel.tankId,
    selector: "tankId",
    sortable: true,
    cell: (row) => row?.tankId,
  },

  {
    name: tankLabel.maxCapacity,
    selector: "maxCapacity",
    sortable: true,
    cell: (row) => row?.maxCapacity,
  },
  // {
  //   name: tankLabel.openingStock,
  //   selector: "openingStock",
  //   sortable: true,
  //   cell: (row) => row?.fms_vehicle_type,
  // },
  // {
  //   name: tankLabel.closingStock,
  //   selector: "closingStock",
  //   sortable: true,
  //   cell: (row) => row?.closingStock,
  // },
  // {
  //   name: tankLabel.openingDip,
  //   selector: "openingDip",
  //   sortable: true,
  //   cell: (row) => row?.make,
  // },
  {
    name: tankLabel.closingDip,
    selector: "closingDip",
    sortable: true,
    cell: (row) => row?.closingDip,
  },
];
