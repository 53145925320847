import React, { useEffect, useState } from "react";
import {
  SidebarData,
  settingApis,
  settingInitialForm,
  settingRules,
  settingTypeOpt,
  settingKeys,
  settingKeysType,
} from "./DATA";
import { Button } from "react-bootstrap";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toastr from "toastr";
import FormInputText from "../../components/FormInputText/FormInputText";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { Validation } from "../../_helpers/validation/Validation";
import { useForm } from "../../_helpers/hooks";
import Select from "../../components/Select/Select";
import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";
import { UserService } from "../../_services/UserService/UserService";
import {
  checkType,
  findType,
} from "../../_services/CommonService/CommonService";

const SettingAdd = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form, onChange, setForm] = useForm(settingInitialForm);
  const [error, setError] = useState({
    ...settingInitialForm,
  });
  const onSubmit = async () => {
    const errorObj = Validation(form, settingRules);
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }
    let typeCheck = checkType(form.settingValue, form.settingType);
    if (!typeCheck) {
      setError((prev) => {
        return {
          ...prev,
          settingValue: "Please select correct Setting Type",
        };
      });
      return;
    }

    let response = params?.id
      ? await UserService.updateSetting(form, params.id)
      : await UserService.createSettings(form);
    if (!response?.status)
      return toastr.error(
        response?.message ? response?.message : "something went wrong"
      );

    if (response?.status) toastr.success(`Saved Successfully`);
    navigate("/user-management/settings");
  };

  const handleFormChange = (event) => {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  async function fetchData() {
    if (!params.id) return;
    let response = await settingApis.getById.api(params?.id);
    if (response?.status) {
      let data = response?.data?.data;

      setForm(() => {
        return {
          settingName: data?.settingName,
          settingValue: data?.settingValue,
          // settingType: findType(data?.settingValue),
          settingType: settingKeysType[data?.settingName],
        };
      });
    }
  }
  useEffect(() => {
    fetchData();
  }, [params.id]);

  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              className="title"
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Super Admin",
                    path: "/",
                  },
                  {
                    name: "Settings",
                    path: "/user-management/settings",
                  },
                  {
                    name: "Add Settings",
                    path: "#",
                  },
                ]}
              />
            </h3>
            <div className="reportCard">
              <div className="formWrapper">
                <div className="maverickFormCard">
                  <div className="creationWrapper">
                    <Form>
                      <Row>
                        <Col lg={4}>
                          <FormInputText
                            name="settingName"
                            value={settingKeys[form.settingName]}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Setting Name"}
                            error={error?.settingName}
                            disabled={true}
                          />
                        </Col>
                        <Col lg={4}>
                          <FormInputText
                            name="settingValue"
                            value={form.settingValue}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Setting Value"}
                            error={error?.settingValue}
                          />
                        </Col>
                        {/* <Col lg={4}>
                          <Select
                            onChange={(event) => handleFormChange(event)}
                            data-testid="select-btn"
                            name="settingType"
                            options={settingTypeOpt}
                            label={"Setting Type"}
                            placeholder={"Enter Here"}
                            value={form?.settingType}
                            error={error?.settingType}
                            KEY_label={"name"}
                            KEY_value={"value"}
                          />
                        </Col> */}
                      </Row>
                    </Form>
                    <div className="btnSec">
                      <Button
                        className="secondaryBtn iconBtnForm"
                        data-testid="next-btn"
                        onClick={() => navigate(`/user-management/settings`)}
                      >
                        Back
                      </Button>
                      <Button
                        className="primaryBtn iconBtnForm"
                        data-testid="next-btn-submit"
                        onClick={() => onSubmit()}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingAdd;
