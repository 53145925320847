import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { coalGradeLabel } from "../DATA";
import CreatedByCreatedAt from "../../../components/Common/CreatedByCreatedAt";

const SampleCodingView = ({ form, allData }) => {
  let data = allData?.find(
    (data) => data?.sample_coding_users?.id == form?.sample_coding_done_by
  );

  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">Sample Coding Register Data</p>
            <Row>
              <Col sm={6}>
                <label>{coalGradeLabel?.date_of_sample_sent_to_lab}</label>
                <p className="content">
                  {form?.date_of_sample_sent_to_lab
                    ? DDMMYYYYFormat(form?.date_of_sample_sent_to_lab)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.code_given_to_sample}</label>
                <p className="content">{form?.code_given_to_sample}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.sample_id}</label>
                <p className="content">{form?.sample_id}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.sample_coding_done_by}</label>
                <p className="content">
                  {data?.sample_coding_users?.firstName}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.date_of_sample_receipt_in_lab}</label>
                <p className="content">
                  {form?.date_of_sample_receipt_in_lab
                    ? DDMMYYYYFormat(form?.date_of_sample_receipt_in_lab)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.date_given_for_test}</label>
                <p className="content">
                  {!form?.date_given_for_test
                    ? ""
                    : DDMMYYYYFormat(form?.date_given_for_test)}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.sample_sender_name}</label>
                <p className="content">{form?.sample_sender_name}</p>
              </Col>
            </Row>
          </div>
          <CreatedByCreatedAt id={form?.id} table={"coal_grade_quality_data"}  />
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default SampleCodingView;
