import React from "react";
import { Button } from "react-bootstrap";
import { statusListObj } from "../../../DATA";
import { BsArrowLeft, BsArrowRight, BsFileEarmarkCheck } from "react-icons/bs";
import { IS_MOBILE } from "../../../../../_helpers/helper";
import { FiEdit } from "react-icons/fi";
import { AiOutlineFileDone } from "react-icons/ai";

const ReportButton = ({ setTab, tab, onSubmit, onDraft, id ,loaderValue}) => {
  return (
    <div className={`btnSec ${tab === 1 ? "firstTab" : ""}`}>
      {tab !== 1 && (
        <Button onClick={() => setTab(tab - 1)} className="secondaryBtn iconBtnForm">
          {IS_MOBILE() ? <BsArrowLeft /> : "Back"}
        </Button>
      )}
      {tab !== 6 && !onSubmit ? (
        <Button
          data-testid="next-btn"
          onClick={() => setTab(tab + 1)}
          className="primaryBtn iconBtnForm"
        >
          {IS_MOBILE() ? <BsArrowRight /> : "Next"}
        </Button>
      ) : (
        <>
          {!id ? (
            <>
              {" "}
              {!onDraft ? (
                <Button
                  data-testid="next-btn"
                  onClick={() => onSubmit(statusListObj.draft, false)}
                  className="primaryBtn iconBtnForm"
                >
                  {IS_MOBILE() ? <FiEdit size={20} /> : "Save"}
                </Button>
              ) : (
                <Button
                  data-testid="next-btn"
                  onClick={() => onDraft()}
                  className="primaryBtn iconBtnForm"
                >
                  {IS_MOBILE() ? <FiEdit size={20} /> : "Save"}
                </Button>
              )}
            </>
          ) : (
            <></>
          )}

          <Button
            data-testid="next-btn"
            onClick={() => onSubmit()}
            className="primaryBtn iconBtnForm"
            disabled={loaderValue}
          >
            {IS_MOBILE() ? <BsFileEarmarkCheck size={22} /> : "Submit"}
          </Button>
        </>
      )}
    </div>
  );
};

export default ReportButton;
