import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AiFillMobile } from "react-icons/ai";
import { Link } from "react-router-dom";

const ConfirmationPopup = ({
  title,
  subTitle,
  callFunc = () => {},
  id,
  data,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    callFunc(id, data);
    setShow(false);
  };

  return (
    <>
      <span onClick={() => setShow(true)} style={{ marginRight: "10px" }}>
        <Link className="icons text-cyanBlue">
          <AiFillMobile
            style={{ fontSize: "18px" }}
            title={title}
            color={data ? "#09b507" : "#1a50a3"}
          />
        </Link>
      </span>

      <Modal
        show={show}
        // onHide={() => setShow(false)}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header
          // closeButton={messagePopupType ? true : false}
          className="border-bottom-0"
        ></Modal.Header>
        <Modal.Body className="p-5 text-center">
          <>
            <h2 className="title mt-3">{title}</h2>
            <p className="">{subTitle}</p>
            <Button
              className="secondaryBtn mt-3 me-3"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button className="primaryBtn  mt-3" onClick={() => handleClose()}>
              Yes
            </Button>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmationPopup;
