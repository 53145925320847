import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import LabelCustom from "../../../components/LabelCustom/LabelCustom";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import FormInputText from "../../../components/FormInputText/FormInputText";
import Select from "../../../components/Select/Select";
import { coalGradeLabel } from "../DATA";
function RefereeLabResultForm({ form, onChange, error, gradeList }) {
  return (
    <div className="creationWrapper">
      <Form>
        <h6>On Air Dry Basis</h6>
        <Row>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.referee_on_air_dry_bases_moisture + ` %`}
              onChange={onChange}
              name="referee_on_air_dry_bases_moisture"
              value={form?.referee_on_air_dry_bases_moisture}
              error={error?.referee_on_air_dry_bases_moisture}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.referee_on_air_dry_bases_ash + ` %`}
              onChange={onChange}
              name="referee_on_air_dry_bases_ash"
              value={form?.referee_on_air_dry_bases_ash}
              error={error?.referee_on_air_dry_bases_ash}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.referee_on_air_dry_bases_vm + ` %`}
              onChange={onChange}
              name="referee_on_air_dry_bases_vm"
              value={form?.referee_on_air_dry_bases_vm}
              error={error?.referee_on_air_dry_bases_vm}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={
                coalGradeLabel?.referee_on_air_dry_bases_gcv + ` (kCal/kg)`
              }
              onChange={onChange}
              name="referee_on_air_dry_bases_gcv"
              value={form?.referee_on_air_dry_bases_gcv}
              error={error?.referee_on_air_dry_bases_gcv}
            />
          </Col>
        </Row>
        <div className="seperator"></div>
        <h6>On EM Basis ​</h6>
        <Row>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.referee_on_em_bases_moisture + ` %`}
              onChange={onChange}
              name="referee_on_em_bases_moisture"
              value={form?.referee_on_em_bases_moisture}
              error={error?.referee_on_em_bases_moisture}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.referee_on_em_bases_ash + ` %`}
              onChange={onChange}
              name="referee_on_em_bases_ash"
              value={form?.referee_on_em_bases_ash}
              error={error?.referee_on_em_bases_ash}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.referee_on_em_bases_vm + ` %`}
              onChange={onChange}
              name="referee_on_em_bases_vm"
              value={form?.referee_on_em_bases_vm}
              error={error?.referee_on_em_bases_vm}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.referee_on_em_bases_gcv + ` (kCal/kg)`}
              onChange={onChange}
              name="referee_on_em_bases_gcv"
              value={form?.referee_on_em_bases_gcv}
              error={error?.referee_on_em_bases_gcv}
            />
          </Col>
        </Row>
        <div className="seperator"></div>
        <Row>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.referee_total_moisture + ` %`}
              onChange={onChange}
              name="referee_total_moisture"
              value={form?.referee_total_moisture}
              error={error?.referee_total_moisture}
            />
          </Col>
          <Col md={6}>
            <Select
              onChange={onChange}
              value={form?.referee_analyzed_grade}
              // value={
              //   form?.referee_analyzed_grade
              //     ? form?.referee_analyzed_grade
              //     : String(getGValue(form?.referee_on_em_bases_gcv))
              // }
              name="referee_analyzed_grade"
              options={gradeList}
              error={error?.referee_analyzed_grade}
              label={coalGradeLabel?.referee_analyzed_grade}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default RefereeLabResultForm;
