import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Container, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import FormInputText from "../../components/FormInputText/FormInputText";
import MessagePopup from "../../components/MessagePopup/MessagePopup";
import { useForm } from "../../_helpers/hooks";
import {
  permissionInitialForm,
  userFormRules,
  userInitialForm,
  userLabel,
} from "./DATA";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomDatePicker from "../../components/DateTimeCustom/CustomDateTime";
import { StepButton } from "../../components/_button/StepButton";
import toastr from "toastr";
import { Validation } from "../../_helpers/validation/Validation";
import { UserService } from "../../_services/UserService/UserService";
import PermissionsForm from "./PermissionsForm";
import Select from "../../components/Select/Select";
import { getAllSites } from "../../_services/SiteServices/SiteServices";
import { getAllContractorList } from "../../services/_contractorPerformance/contractorPerformance";
import { calaculateDatesDifference } from "../../_helpers/dateHelper";
import {
  isContractorModuleAdmin,
  isLoggedSite,
} from "../../_services/Auth/helper";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../ContractorManagement/DATA";
import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";
import { IS_USER_SITE_MINES } from "../../_helpers/helper";

const ContractorConUserManagementAddEdit = () => {
  const [form, setForm] = useState({
    ...userInitialForm,
    siteId: isContractorModuleAdmin() ? isLoggedSite() : "",
    // roleId: isContractorModuleAdmin() ? 3 : "",
    roleId: 3,
  });
  const [show, setShow] = useState(false);
  const [permissionForm, onChangePermission, setPermissionForm] = useForm({
    ...permissionInitialForm,
  });
  const [siteList, setSiteList] = useState([]);
  const [error, setError] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [contractorList, setContractorList] = useState([]);
  const [messagePopupType, setMessagePopupType] = useState(false);

  let navigate = useNavigate();
  const params = useParams("id");

  const allSites = async () => {
    const response = await getAllSites();
    if (!response?.status) return;
    setSiteList(
      response?.data
        ?.filter((d) => d?.siteType === 3)
        .map((s) => {
          return { ...s, name: s.name + " ( " + s.parentSite.name + " ) " };
        })
    );
  };

  const allRoles = async () => {
    const response = await UserService.getAllRoles();
    if (!response?.status) return;
    setRoleList(response?.data);
  };

  const allContractor = async () => {
    const response = await getAllContractorList();
    if (!response) return;
    // let dataObjMine = [];
    // if (response.length > 0) {
    //   response?.forEach((data) => {
    //     const contractorId = data.contractor.id;

    //     const idExists = dataObjMine.some(
    //       (item) => item.value === contractorId
    //     );

    //     if (!idExists) {
    //       dataObjMine.push({
    //         id: contractorId,
    //         contractor: data.contractor.contractor,
    //       });
    //     }
    //   });
    // }
    setContractorList(response);
  };

  const onChange = ({ target: { name, value } }) => {
    if (name === "roleId" && value === 4) {
      setPermissionForm({ ...permissionForm, contractor: false });
    } else if (value === 3 && name === "roleId") {
      setPermissionForm({ ...permissionInitialForm, contractor: true });
    } else if (name === "roleId") {
      setPermissionForm({ ...permissionInitialForm });
    }
    setForm((pre) => ({ ...pre, [name]: value }));
  };

  const userById = useCallback(async () => {
    const response = await UserService.getUserByID(params?.id);
    if (!response?.status) return;
    setForm((prev) => ({
      ...prev,
      ...response?.data,
      // contractor_id: response?.data?.contractor_id
      //   ? response?.data?.contractor_id
      //   : "",
    }));
    setPermissionForm((prev) => ({
      ...prev,
      ...response?.data?.userPermission,
    }));
  }, [params?.id, setForm, setPermissionForm]);

  useEffect(() => {
    if (!params?.id) return;
    userById();
  }, [params?.id, userById]);

  useEffect(() => {
    allContractor();
    allSites();
    allRoles();
  }, []);

  function handleClick() {
    setShow(false);
    navigate("/contractor-management/configuration/contractor-users");
    if (isContractorModuleAdmin()) {
      navigate("/contractor-management/configuration/contractor-users");
    }
  }

  const handleSubmit = async () => {
    form.siteType = 3;
    let errorObj = Validation(
      { ...form, contractor_id: form?.contractor_id ?? "" },
      userFormRules?.map((d) => {
        if (d.field_name === "contractor_id") {
          return { ...d, isRequired: form?.roleId === 3 ? true : false };
        } else return d;
      })
    );

    if (form?.dob) {
      let age = calaculateDatesDifference(form?.dob);
      if (age < 18) {
        errorObj.dob = "DOB should be at least 18 years.";
      }
    }
    if (form.firstName.indexOf(" ") !== -1 && errorObj.firstName === "")
      errorObj.firstName = "Name can not have spaces";
    if (form.lastName.indexOf(" ") !== -1 && errorObj.lastName === "")
      errorObj.lastName = "Name can not have spaces";

    setError(errorObj);

    if (Object.values(errorObj).filter((data) => data !== "")?.length) return;

    let contractorPermission = {
      contractor: true,
    };

    let permissions = {};
    if (form?.roleId === 3) permissions = contractorPermission;
    else permissions = permissionForm;

    const formObj = {
      ...form,
      contractor_id: form?.contractor_id ? form?.contractor_id : null,
      userPermission: permissions,
    };

    const response = params?.id
      ? await UserService.updateUser(params?.id, formObj)
      : await UserService.addUser(formObj);

    if (!response?.status) return toastr.error(response?.message);
    setMessagePopupType(false);
    setShow(true);
  };

  const closeFun = () => {
    setMessagePopupType(true);
    setShow(true);
  };

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              className="title"
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/contractor-management/configuration",
                  },
                  {
                    name: "Contractor User List",
                    path: "/contractor-management/configuration/contractor-users",
                  },
                  {
                    name: params.id ? "Edit Reason" : "Add Reason",
                    path: "#",
                  },
                ]}
                // module={ACTIVE_MODULE.incident}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              {params.id ? "Edit" : "Add"} Contractor User
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <Form>
                  <Row>
                    <>
                      <Col sm={6}>
                        <Select
                          onChange={onChange}
                          value={form?.roleId}
                          name="roleId"
                          options={roleList}
                          error={error?.roleId}
                          label={userLabel?.roleId}
                          KEY_label={"title"}
                          KEY_value={"id"}
                          disabled={true}
                        />
                      </Col>
                      {form?.roleId === 3 && (
                        <Col sm={6}>
                          <Select
                            onChange={onChange}
                            value={form?.contractor_id}
                            name="contractor_id"
                            options={contractorList}
                            error={error?.contractor_id}
                            label={userLabel?.contractor_id}
                            KEY_label={"contractor"}
                            KEY_value={"id"}
                          />
                        </Col>
                      )}
                      <Col sm={6}>
                        <Select
                          onChange={onChange}
                          value={form?.siteId}
                          name="siteId"
                          options={siteList}
                          error={error?.siteId}
                          label={"Mine"}
                          KEY_label={"name"}
                          KEY_value={"id"}
                          // disabled={isContractorModuleAdmin()}
                          disabled={IS_USER_SITE_MINES()}
                        />
                      </Col>
                    </>
                    <Col md={6}>
                      <FormInputText
                        type="input"
                        placeHolder="Enter here"
                        label={userLabel?.firstName}
                        onChange={onChange}
                        name="firstName"
                        value={form?.firstName}
                        error={error?.firstName}
                        labelClass={"form-label-input"}
                      />
                    </Col>
                    <Col md={6}>
                      <FormInputText
                        type="input"
                        placeHolder="Enter here"
                        label={userLabel?.lastName}
                        onChange={onChange}
                        name="lastName"
                        value={form?.lastName}
                        error={error?.lastName}
                        labelClass={"form-label-input"}
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label className="form-label-input">
                        {userLabel?.dob}
                      </Form.Label>
                      <CustomDatePicker
                        value={form?.dob}
                        name="dob"
                        onChange={onChange}
                        error={error?.dob}
                      />
                    </Col>
                    <Col md={6}>
                      <FormInputText
                        type="email"
                        placeHolder="Enter here"
                        label={userLabel?.email}
                        onChange={onChange}
                        name="email"
                        value={form?.email}
                        error={error?.email}
                        labelClass={"form-label-input"}
                      />
                    </Col>
                    <Col md={6}>
                      <FormInputText
                        type="input"
                        placeHolder="Enter here"
                        label={userLabel?.contactNumber}
                        onChange={onChange}
                        name="contactNumber"
                        value={form?.contactNumber}
                        error={error?.contactNumber}
                        labelClass={"form-label-input"}
                      />
                    </Col>
                  </Row>
                  {!params?.id && form?.roleId && form?.roleId === 4 && ( // NOSONAR - Ignore all issues on this comment
                    <>
                      <PermissionsForm
                        form={form}
                        onChange={onChangePermission}
                      />
                    </>
                  )}
                </Form>

                <div className="btnSec">
                  <Button onClick={closeFun} className="secondaryBtn">
                    Close
                  </Button>
                  <StepButton
                    tab={3}
                    tabFun={""}
                    onSubmit={handleSubmit}
                    nextStapeFun={""}
                    hideSaveBtn
                  />
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            messagePopupType={messagePopupType}
            show={show}
            setShow={setShow}
            closePopup={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractorConUserManagementAddEdit;
