import React from "react";
import ReactEcharts from "echarts-for-react";
import NoRecord from "./NoRecord";

class RadarChart extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.seriesData !== this.props.seriesData) {
      this.forceUpdate();
    }
  }

  render() {
    const {
      category,
      seriesData,
      legendFlag,
      radarIndicator, // Array of objects defining radar indicators
    } = this.props;
    const radarIndicatorWithMax = radarIndicator.map((indicator) => ({
      ...indicator,
      max: 5,
    }));
    const echartRadarOption = {
      tooltip: {
        trigger: "axis",
      },
      legend:
        legendFlag === false
          ? false
          : {
            type: "scroll",
            itemGap: 20,
            to: 100,
            bottom: 10, // Move the legend to the bottom
          },
      radar: {
        indicator: radarIndicatorWithMax, // Updated radar indicators with 'max' property

        axisLine: {
          lineStyle: {
            color: '#ccc',
          },
        },
        axisName: {
          show: true,
          color: "black"
        },
      },
      series: [
        {
          type: "radar",
          data: seriesData ? seriesData : [],
        },
      ],
      dataZoom: [
        {
          start: 0,
          type: "inside",
        },
        {
          start: 0,
        },
      ],
    };

    if (this.props.isLegeng) {
      echartRadarOption.legend = this.props.isLegeng;
    }
    if (this.props.grid) {
      echartRadarOption.grid = this.props.grid;
    }

    return (
      <React.Fragment>
        {Array.isArray(seriesData) && seriesData.length > 0 ? (
          <>
            <ReactEcharts
              style={{
                height: this.props.height ? this.props.height : "270px",
              }}
              option={echartRadarOption}
            />
          </>
        ) : (
          <NoRecord />
        )}
      </React.Fragment>
    );
  }
}

export default RadarChart;
