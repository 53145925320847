import React from "react";
import ReactECharts from "echarts-for-react";
import NoRecord from "./NoRecord";
import { Col } from "react-bootstrap";
import { isDecimal } from "../../_helpers/helper";

export function SimpleBarChart({
  xAxis,
  yAxis,
  series,
  legend,
  height,
  yAxisName,
  customTooltip,
  minMaxData,
  isPlantationGraph = false,
  coalGradeToolTip,
}) {
  const options = {
    tooltip: coalGradeToolTip
      ? {
          formatter: function (params, ticket, callback) {
            let type =
              params.name === "SHRA"
                ? "Rail"
                : params.name === "SHRO"
                ? "Road"
                : params.name === "SHMG"
                ? "Merry Go Round"
                : "";
            let string = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
              params.color
            };"></span>${params.seriesName} : ${isDecimal(
              params.value
            )} ${yAxisName}<br>`;
            if (type) {
              string += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${type}<br>`;
            }

            return "<span > " + string + "</span>";
          },
        }
      : customTooltip
      ? {
          formatter: function (params, ticket, callback) {
            console.log("params++++", params, ticket);
            let string2 = "";
            let string1 = `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;\"></span>${params.name}<br>`;
            let string =
              `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
              params.color +
              `;\"></span>${params.seriesName} : ${isDecimal(
                params.value
              )} ${yAxisName}<br>`;
            if (isPlantationGraph) {
              string2 =
                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params.color +
                `;\"></span>Plantation Count : ${isDecimal(
                  params.value * 2500
                )}<br>`;
            }
            return "<span > " + string1 + string + string2 + "</span>";
          },
        }
      : {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: xAxis ? xAxis : {},
    yAxis: yAxis
      ? yAxis
      : {
          type: "value",
          name: yAxisName ? yAxisName : "",
          min: 0, // minimum value to start
          minInterval: 1, // for integer axis value
          nameTextStyle: {
            fontWeight: "bold",
          },
        },
    legend: legend ? legend : {},
    series: series ? series : [],
  };
  return (
    <>
      {Array.isArray(series) && series.length > 0 ? (
        <>
          <ReactECharts
            notMerge={true}
            replaceMerge={["series", "legend"]}
            option={options}
            style={{ height: height ? height : "400px" }}
          />
          <div className="text-center" style={{ marginTop: -15 }}>
            {minMaxData && (
              <div className="minMaxSec">
                <div className="contentData">
                  <label>{"Min"}:</label>
                  <p>{`${
                    Math.round(minMaxData?.min * 100) / 100 || 0
                  } ${yAxisName}`}</p>
                </div>
                <div className="contentData">
                  <label>{"Max"}:</label>
                  <p>{`${
                    Math.round(minMaxData?.max * 100) / 100 || 0
                  } ${yAxisName}`}</p>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <NoRecord />
      )}
    </>
  );
}
