import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./Dashboard.css";
import TabFilterButton from "../../../components/TabFilterButton/TabFilterButton";
import {
  CGDistributionTrendPageChartList,
  CHART_TYPE_BY_DATA,
  PAGE_TYPE_BY_CHART_FILTER,
  PAGE_TYPE_BY_CHART_LIST,
  Tabs,
  chartFilter,
} from "./DATA";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import { MultipleBarChartCard } from "../../../components/ChartCard/ChartCard";
import {
  coalGradeDispatchTrend,
  coalGradeSingleChart,
} from "../../../services/_coalGrade/coalGrade";
import { BiCalendar } from "react-icons/bi";
import StackWithLineChart from "../../../components/Common/StackWithLineChart";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";

const CGDispatchTrend = ({
  onChange,
  cref,
  form,
  pageType,
  appliedFilters,
  topSelectedTab,
  setMinMaxDate,
}) => {
  const [chartData, setChartData] = useState({});
  const [chartFilterForm, setChartFilterForm] = useState(
    PAGE_TYPE_BY_CHART_FILTER(+pageType)
  );
  const [actionChartName, setActionChartName] = useState("");

  const fetchData = useCallback(
    async (appliedFilters, isClickApply) => {
      if (isClickApply) {
        setChartFilterForm(PAGE_TYPE_BY_CHART_FILTER(+pageType));
        setActionChartName("");
      }
      let response = await coalGradeDispatchTrend(appliedFilters);
      if (!response?.status) {
        setChartData({});
        return;
      }
      const resData = response.data;
      setMinMaxDate(resData?.minMax_date || {});
      CGDistributionTrendPageChartList?.forEach((item) => {
        setChartData((prev) => ({
          ...prev,
          [item?.value]: CHART_TYPE_BY_DATA(item?.type, resData?.[item?.value]),
        }));
      });
    },
    [pageType]
  );

  const singleDashboardFun = useCallback(
    async (filter) => {
      if (!actionChartName) return;
      let response = await coalGradeSingleChart(`${+pageType}`, filter);
      if (!response?.status) return;
      const chartTypeObj =
        PAGE_TYPE_BY_CHART_LIST(+pageType).find(
          (d) => d?.value === actionChartName
        ) || {};
      setChartData((pre) => ({
        ...pre,
        [actionChartName]: CHART_TYPE_BY_DATA(
          chartTypeObj?.type,
          response?.data
        ),
      }));
    },
    [actionChartName, pageType]
  );

  useEffect(() => {
    if (!actionChartName) return;
    let filter = "";
    filter = `graphName=${actionChartName}&graphKey=${
      chartFilterForm[actionChartName]
    }${appliedFilters || ""}&tab=${topSelectedTab}`;
    singleDashboardFun(filter);
  }, [actionChartName, chartFilterForm, singleDashboardFun]);

  const onChangeChart = ({ target: { name, value } }) => {
    setChartFilterForm((prev) => ({ ...prev, [name]: value }));
    setActionChartName(name);
  };

  useEffect(() => {
    let isClickApply = true;
    let finalFltr = `${appliedFilters || ""}&tab=${topSelectedTab}`;
    fetchData(finalFltr, isClickApply);
  }, [fetchData, topSelectedTab]);

  useImperativeHandle(
    cref,
    () => {
      let finalFltr = `${appliedFilters || ""}&tab=${topSelectedTab}`;
      let isClickApply = true;
      return {
        fetchData: () => fetchData(finalFltr, isClickApply),
      };
    },
    [appliedFilters, topSelectedTab, fetchData]
  );

  return (
    <div className="dashboardWrapper">
      <Row className="chart-listing" id={`${"screen" + pageType}`}>
        {PAGE_TYPE_BY_CHART_LIST(pageType)?.map((d, idx) => {
          // console.log("asdkjsdhsduy", chartData[d?.value]);

          return (
            <Col md={12} key={idx} id={`zoomSec_${idx}`}>
              <Card className="chartCard chartCardBtmSpace CAAQMS_chart">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>{d?.name}</span>
                  <div className="filterZoomSec">
                    {!d?.hideFilter && (
                      <Col className="mbRemove">
                        <Dropdown className="fuelConsumptionDays">
                          <Dropdown.Toggle>
                            <BiCalendar />
                          </Dropdown.Toggle>
                          {chartFilterForm?.[d?.value]
                            ?.charAt(0)
                            ?.toUpperCase()}
                          <Dropdown.Menu>
                            <div className="tab alphabetToggle">
                              {chartFilter.map((option, i) => (
                                <TabFilterButton
                                  data-testid="tabFilter"
                                  key={i}
                                  label={option?.label}
                                  id={`${option?.id}_${idx + 1}`}
                                  toggleValue={option?.value}
                                  handleChange={() =>
                                    onChangeChart({
                                      target: {
                                        name: d?.value,
                                        value: option?.value,
                                      },
                                    })
                                  }
                                  selectedTab={chartFilterForm?.[d?.value]}
                                />
                              ))}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    )}
                  <ZoomScreen id={`zoomSec_${idx}`} cardSizeClass="col-md-12" />
                  </div>

                </Card.Header>
                <Card.Body className="pb-4">
                  {d?.type === "stack" && (
                    <StackWithLineChart
                      yAxisName={"Tonne"}
                      onlyBar={true}
                      category={chartData[d?.value]?.categoryData}
                      series={chartData[d?.value]?.data || []}
                      customTooltip
                    />
                  )}
                  {d?.type === "line" && (
                    <MultipleBarChartCard
                      yAxisName={d?.yAxisName}
                      // xAxisName={d?.xAxisName}
                      category={chartData[d?.value]?.categoryData}
                      seriesData={[
                        {
                          data: chartData[d?.value]?.data,
                          type: "line",
                        },
                      ]}
                      // customToolTip
                      customTooltipCoalGrade
                      filterType={chartFilterForm}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default CGDispatchTrend;
