import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  BasicBarChartCardWithCustomToolTip,
  MultipleBarChartCard,
} from "../../../components/ChartCard/ChartCard";
import { PAGE_TYPE_BY_CHART_LIST } from "./DATA";
import { isArray } from "lodash";
import Select from "../../../components/Select/Select";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import MapComponet from "../../../components/MapComponet/MapComponet";
import { isNclHoUser } from "../../../_services/CommonService/CommonService";
import { SimpleBarChart } from "../../../components/Common/SimpleBarChart";

const AmbientAirQualityCaaqmsDas = ({
  data,
  form,
  onChange,
  pageType,
  extraform,
  onChangeExtra,
  filterForm,
}) => {
  let isNclOrHo = isNclHoUser();
  return (
    <div className="dashboardWrapper">
      <Row id={`${"screen" + pageType}`}>
        {PAGE_TYPE_BY_CHART_LIST(pageType)?.map((d, idx) => {
          return d?.type === "map" ? (
            <Col md={12}>
              <Card className="chartCard mb-3">
                <MapComponet data={data?.mapData} />
              </Card>
            </Col>
          ) : (
            <Col md={6} key={idx} id={`zoomSec_${idx}`}>
              <Card className="chartCard chartCardBtmSpace CAAQMS_chart">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>{d?.name} </span>
                  {d?.extraFilterOption &&
                  isNclOrHo &&
                  filterForm.subsideryId !== 3 ? (
                    <>
                      <Col className="mbRemove" style={{ display: "contents" }}>
                        <Select
                          width={"150px"}
                          data-testid="graphDataTestId"
                          name={d?.extraFilterKey}
                          value={extraform?.[d?.extraFilterKey]}
                          onChange={onChangeExtra}
                          options={d?.extraFilterOption}
                          blankSelect={"none"}
                        />
                      </Col>
                      <div className="filterZoomSec">
                        {!d?.hideFilter && (
                          <Col className="mbRemove">
                            <Select
                              data-testid="graphDataTestId"
                              // label={"Incident Type"}
                              name={d?.value}
                              value={form?.[d?.value]}
                              onChange={onChange}
                              options={
                                extraform?.[d?.extraFilterKey] === "useDevice"
                                  ? d?.options.filter(
                                      (option) =>
                                        option.label !== "NOx" &&
                                        option.label !== "NO2"
                                    )
                                  : d?.options
                              }
                              blankSelect={"none"}
                              width={"130px"}
                            />
                          </Col>
                        )}
                        <ZoomScreen id={`zoomSec_${idx}`} />
                      </div>
                    </>
                  ) : (
                    <div className="filterZoomSec">
                      {!d?.hideFilter && (
                        <Col className="mbRemove">
                          <Select
                            data-testid="graphDataTestId"
                            // label={"Incident Type"}
                            name={d?.value}
                            value={form?.[d?.value]}
                            onChange={onChange}
                            options={d?.options}
                            blankSelect={"none"}
                            width={"130px"}
                          />
                        </Col>
                      )}
                      <ZoomScreen id={`zoomSec_${idx}`} />
                    </div>
                  )}
                </Card.Header>
                <Card.Body className="pb-3">
                  {d?.type === "multiLine" && (
                    <>
                      <MultipleBarChartCard
                        yAxisName={d?.unitObj[form?.[d?.value]]}
                        // xAxisName={item?.xAxisName}
                        category={data[d?.value]?.categoryData}
                        seriesData={
                          isArray(data[d?.value]?.data) &&
                          data[d?.value]?.data.map((d) => ({
                            ...d,
                            // type: item.type === "multiLine" ? d.type : item.type,
                          }))
                        }
                        minMaxData={data?.[d?.value]?.minMaxData}
                        customToolTip
                      />
                    </>
                  )}
                  {d?.type === "barWithCustomTooltip" && (
                    <SimpleBarChart
                      yAxisName={d?.unitObj[form?.[d?.value]]}
                      xAxis={data[d?.value]?.xAxis}
                      series={data[d?.value]?.series}
                      legend={data[d?.value]?.legend}
                      customTooltip
                      height="270px"
                    />

                    // <BasicBarChartCardWithCustomToolTip
                    //   yAxisName={d?.unitObj[form?.[d?.value]]}
                    //   // xAxisName={d?.xAxisName}
                    //   category={data[d?.value]?.categoryData}
                    //   seriesData={data[d?.value]?.data}
                    //   customToolTip={data[d?.value]?.dataList}
                    //   valueWithUnit={d?.unitObj[form?.[d?.value]]}
                    //   minMaxData={data?.[d?.value]?.minMaxData}
                    // />
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default AmbientAirQualityCaaqmsDas;
