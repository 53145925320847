import React from "react";
// import "../../FuelManagementSystem/fuelmgmt.scss";

import { Link, useNavigate } from "react-router-dom";
import { IS_MOBILE, auth_user, capitalizeName } from "../../_helpers/helper";
import SafetySideBar from "../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { BsFillPersonFill } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { logout, logoutUser } from "../../services/logoutService";
import { createBrowserHistory } from "history";

const MobProfile = () => {
  let navigate = useNavigate();
  let data = auth_user();
  const { location } = createBrowserHistory();

  const signOut = async () => {
    (await logoutUser()) && navigate("/mobile/auth/login");
  };

  return (
    <>
      <div
        className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
      >
        <SafetySideBar
          pageTitle={
            data
              ? capitalizeName(`${data.firstName} ${data?.lastName ?? ""}`)
              : ""
          }
          goBack={() => navigate(-1)}
        />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea p-3">
            <div className="iq-card shadow-none m-0">
              <div className="iq-card-body p-0">
                <Link
                  to={"/mobile/view-profile"}
                  className="iq-sub-card iq-bg-primary-hover"
                  state={location?.state}
                >
                  <div className="media">
                    <div className=" iq-card-icon iq-bg-primary">
                      <BsFillPersonFill />
                    </div>
                    <div className="media-body ml-3">
                      <h5 className="mb-0 ">View Profile</h5>
                      <p className="mb-0 font-size-12">
                        View personal profile details.
                      </p>
                    </div>
                  </div>
                </Link>

                <div className="d-inline-block w-100 text-center p-3">
                  <button
                    className="iq-bg-danger iq-sign-btn"
                    onClick={signOut}
                  >
                    Sign out <FiLogOut />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobProfile;
