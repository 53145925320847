import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

// Get Coal Grade Quality List With Server Side Pagination
export const getContractorPerformancesListWithPagination = async (filter) => {
  let url = "/contractor-performance";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      totalCount: response.data?.data?.totalCount,
      data: response?.data?.data?.contractorPerformances,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getMyContractorPerformancesListWithPagination = async (filter) => {
  let url = "/contractor-performance/my";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      totalCount: response.data?.data?.totalCount,
      data: response?.data?.data?.contractorPerformances,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllContractor = async (filter) => {
  let url = "/contractor";

  try {
    const response = await RestMethod.GET(url);
    return response.data?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllContractorList = async (filter) => {
  let url = "/contractor/all-contractor";

  try {
    const response = await RestMethod.GET(url);
    return response.data?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllContractorByNit = async (filter) => {
  let url = "/contractor/contractor-list";
  if (filter) url += "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    return response.data?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getNitList = async (contractor_id) => {
  let url = "/contractor-nit/contractor-id/" + contractor_id;

  try {
    const response = await RestMethod.GET(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

// Update Sample Coding

export const createContractorPerformance = async (data) => {
  let url = "/contractor-performance/add";
  try {
    const response = await RestMethod.POST(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateContractorPerformance = async (data, id) => {
  let url = "/contractor-performance/update/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const exportContractorPerformance = async (filter) => {
  try {
    let url = "/contractor-performance/export-contractor-performance";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;

    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const getAllNit = async (filter) => {
  try {
    let url = "/contractor-nit";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;

    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const getAllNitBySiteIdAndContractor = async (filter) => {
  try {
    let url = "/contractor-nit/by-siteId";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;

    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const getContractorById = async (id) => {
  let url = "/contractor/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;

    return {
      status,
      message,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getNitById = async (id) => {
  let url = "/contractor-nit/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;

    return {
      status,
      message,
      // column: response?.data?.data?.column,
      data: response?.data?.ContractorNit || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getPerformanceDashboard = async (filter) => {
  let url = "/contractor-performance/dashboard";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      data: response?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getPerformanceProductionDashboard = async (filter) => {
  let url = "/contractor-sap";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      data: response?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const performanceSuggestion = async (filter) => {
  let url = "/contractor/score-card/suggest";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      message,
      status,
      data: response?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getDashboardDataNit = async (type, site) => {
  let url = `/common/dashboard-data?type=${type}&site=${site}`;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      message,
      status,
      data: response?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
