import React, { useEffect, useState } from "react";
import InputError from "../_form/InputError/InputError";
import { Col, Form, Row } from "react-bootstrap";
import Asterik from "../Common/Asterik/Asterik";

function FormInputText(props) {
  const {
    label,
    error,
    errorClassName,
    id,
    name,
    type,
    className,
    value,
    disabled,
    onChange,
    minLength,
    maxLength,
    checked,
    placeholder,
    placeHolder,
    multiple,
    accept,
    readOnly,
    formGroupClass,
    labelClass,
    NA = false,
    asterikRules,
  } = props;
  const [naDisabled, setNaDisabled] = useState(false);

  useEffect(() => {
    if (!NA) return;
    if (value === null) {
      setNaDisabled(true);
      if (naDisabled) onChange({ target: { name, value: "NA" } });
    }
  }, [NA, naDisabled, name, onChange, value]);
  return (
    <Form.Group className={formGroupClass || "mb-3"}>
      {label && !NA && (
        <Form.Label className={labelClass ? labelClass : "form-label-input"}>
          {label}
          <Asterik fieldName={name} requiredRules={asterikRules} />
        </Form.Label>
      )}
      {NA && (
        <Row
          style={{ marginBottom: "-1px" }}
          className={labelClass ? labelClass : "form-label-input"}
        >
          <Col md="9"> {label}</Col>
          <Col md="2" className="d-flex">
            <input
              style={{ marginTop: "-9px", marginRight: "5px" }}
              type="checkbox"
              name={`${name}-checkbox`}
              id={`${name}-checkbox`}
              value={naDisabled}
              checked={naDisabled}
              onChange={() => {
                setNaDisabled(!naDisabled);
                if (!naDisabled) onChange({ target: { name, value: "NA" } });
                else onChange({ target: { name, value: "" } });
              }}
            />
            <label htmlFor={`${name}-checkbox`}>N/A</label>
          </Col>
        </Row>
      )}

      <Form.Control
        id={id}
        name={name}
        type={type}
        className={className}
        value={value}
        disabled={naDisabled ? naDisabled : disabled}
        onChange={onChange}
        min={minLength}
        max={maxLength}
        checked={checked}
        placeholder={placeholder || placeHolder}
        multiple={multiple}
        accept={accept}
        readOnly={readOnly}
        data-testid={name}
      />
      <InputError error={error} className={errorClassName} />
    </Form.Group>
  );
}

export default FormInputText;
