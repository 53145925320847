export function Rules(field, ruleObj) {
  let errorMsg = "";

  if (
    field === "" ||
    field === undefined ||
    field === null ||
    (Array.isArray(field) && field.length === 0)
  ) {
    if (ruleObj.label !== undefined) {
      errorMsg = ruleObj.label + " is Required.";
    } else {
      errorMsg = "Required";
    }
  } else {
    if (field && field.toString().charAt(0) === " ") {
      errorMsg = "Space is not Allowed in Starting of " + ruleObj.label;
    } else if (
      ruleObj["removeSpecialCharacter"] !== undefined &&
      ruleObj["removeSpecialCharacter"] === true
    ) {
      let fieldDataS = field ? field.toString().split("-") : [];
      if (fieldDataS.length > 1) {
        errorMsg = "Minus not Allowed";
      }

      let format = /[`!@#$%&*()_+\-=\[\]{};':"\\|,.<>?~]/;
      if (format.test(field.toString())) {
        errorMsg = "Special Character not Allowed";
      }
    } else if (ruleObj.type === "password") {
      let password = /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
      let specialCharacter = /^(?=.*[!@#$%^&*])/;
      let len = /^(?=.{8,})/;
      let number = /^(?=.*[0-9])/;
      let caps = /^(?=.*[A-z])/;
      if (password.test(field) === false) {
        errorMsg = "Please enter valid password";
      }
      if (specialCharacter.test(field) === false) {
        errorMsg = "Please enter at least one special character";
      }
      if (len.test(field) === false) {
        errorMsg = "Min length 8 is required";
      }
      if (number.test(field) === false) {
        errorMsg = "Please enter at least one Number";
      }
      if (caps.test(field) === false) {
        errorMsg = "Please enter at least one Capital Letter";
      }
    } else if (ruleObj.type === "number") {
      if (
        ruleObj["max"] !== undefined &&
        field.toString().length > ruleObj.max
      ) {
        errorMsg = "Max " + ruleObj.max + " digit required";
      }
      if (
        ruleObj["maxNumber"] !== undefined &&
        !isNaN(field) &&
        field > ruleObj.maxNumber
      ) {
        errorMsg = "Max Number must be " + ruleObj.maxNumber + "!";
      }
      if (
        ruleObj["minNumber"] !== undefined &&
        !isNaN(field) &&
        field < ruleObj.minNumber
      ) {
        errorMsg = "Min Number must be " + ruleObj.minNumber + "!";
      }
      if (ruleObj["toFixed"] !== undefined && !isNaN(field)) {
        let temp = field.toString().split(".");
        let toFixedVal = ruleObj["toFixedVal"] ? ruleObj["toFixedVal"] : 2;
        if (temp.length > 1 && temp[temp.length - 1].length > toFixedVal)
          errorMsg = "Only " + toFixedVal + " Decimal Values are Allowed!";
      }
      if (
        ruleObj["min"] !== undefined &&
        field.toString().length < ruleObj.min
      ) {
        errorMsg = "Min " + ruleObj.min + " digit required";
      }
      let fieldData = field ? field.toString().split(".") : [];
      if (
        ruleObj["integer"] !== undefined &&
        ruleObj["integer"] === true &&
        fieldData.length > 1
      ) {
        errorMsg = "Please Enter a Valid Integer Number";
      }
      if (isNaN(field)) {
        errorMsg = "Please enter a valid number";
      }
      if (ruleObj.zero) {
        if (field == 0) {
          errorMsg = "Value must be greater than 0";
        }
      }
    } else if (ruleObj.type === "email") {
      let regEmail =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (regEmail.test(field) === false) {
        // errorMsg = ruleObj.label + " is invalid email"
        errorMsg = "Please Enter valid email";
      }
    } else if (ruleObj.type === "date") {
      let pattern =
        /(0[1-9]|[12][0-9]|3[01])[- -.](0[1-9]|1[012])[- -.](19|20)\d\d/;
      if (pattern.test(field) === false) {
        errorMsg = "Date pattern should be dd-mm-yyyy";
      }
    } else if (ruleObj.type === "ifsc") {
      if (field.toString().length != 11) {
        errorMsg = "Enter 11 Digit Code";
      }
    } else if (ruleObj.type === "string") {
      errorMsg = "";
      let fieldLength = field.length;

      if (
        ruleObj["maxLength"] !== undefined &&
        fieldLength > ruleObj["maxLength"]
      ) {
        errorMsg = ruleObj["maxLength"] + " Character Limit";
      }
    } else if (ruleObj.type === "volume") {
      if (
        ruleObj["max"] !== undefined &&
        field.toString().length > ruleObj.max
      ) {
        errorMsg = "Max " + ruleObj.max + " digit required";
      }
      if (
        ruleObj["min"] !== undefined &&
        field.toString().length < ruleObj.min
      ) {
        errorMsg = "Min " + ruleObj.min + " digit required";
      }
      if (field == 0) {
        errorMsg = "Volume must be greater than 0";
      }

      if (isNaN(field)) {
        errorMsg = "Please enter a valid number";
      }
    }
  }
  return errorMsg;
}
