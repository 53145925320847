import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import Select from "../../../components/Select/Select";
import { modeOfDispatchList, refereeStatus } from "./DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { coalGradeLabel } from "../DATA";
import { useSearchParams, useLocation } from "react-router-dom";

const SampleThirdPartyFilterForm = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
}) => {
  const [searchParams] = useSearchParams();
  console.log(
    "searchParamssearchParams",
    searchParams.get("status") === `Submitted`
  );
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>{coalGradeLabel?.rr_or_do_date}</Form.Label>
        <Row>
          <Col sm={6}>
            <CustomDatePicker
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
              label={coalGradeLabel?.rr_or_do_date}
            />
          </Col>
          <Col sm={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              name="endDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={12}>
            <Select
              onChange={onChange}
              value={form?.mode_of_dispatch}
              name="mode_of_dispatch"
              options={modeOfDispatchList}
              label={coalGradeLabel?.mode_of_dispatch}
            />
          </Col>
          {searchParams.get("status") === `Submitted` && (
            <Col md={12}>
              <Select
                onChange={onChange}
                value={form?.referee_lab_status}
                name="referee_lab_status"
                options={refereeStatus}
                label={coalGradeLabel?.referee_status}
              />
            </Col>
          )}
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              type="button"
              onClick={onResetFilter}
              className="secondaryBtn"
              data-testid="reset-filter-button"
            >
              Clear all
            </Button>
          </Col>
          <Col className="justify-content-end d-flex">
            <Button
              type="button"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0"
              data-testid="submit-filter-button"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default SampleThirdPartyFilterForm;
