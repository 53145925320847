import FormInputText from "../../../../components/FormInputText/FormInputText";
import { Col, Row } from "react-bootstrap";
import { mineLabel } from "./DATA";

const MineDetailsForm = ({ form, error, onChange }) => {
  return (
    <Row>
      <Col lg={3}>
        <FormInputText
          name="mineral"
          value={form.mineral}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.mineral}
          error={error?.mineral}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="mineVillageAreaRoad"
          value={form.mineVillageAreaRoad}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.mineVillageAreaRoad}
          error={error?.mineVillageAreaRoad}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="minePostOffice"
          value={form.minePostOffice}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.minePostOffice}
          error={error?.minePostOffice}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="mineSubDivision"
          value={form.mineSubDivision}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.mineSubDivision}
          error={error?.mineSubDivision}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="mineDistrict"
          value={form.mineDistrict}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.mineDistrict}
          error={error?.mineDistrict}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="mineState"
          value={form.mineState}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.mineState}
          error={error?.mineState}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="minePinCode"
          value={form.minePinCode}
          onChange={onChange}
          type="number"
          class="form-control"
          placeholder=""
          label={mineLabel.minePinCode}
          error={error?.minePinCode}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="minePoliceStation"
          value={form.minePoliceStation}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.minePoliceStation}
          error={error?.minePoliceStation}
        />
      </Col>
      <Col lg={3}>
        <FormInputText
          name="mineRailwayStation"
          value={form.mineRailwayStation}
          onChange={onChange}
          type="text"
          class="form-control"
          placeholder=""
          label={mineLabel.mineRailwayStation}
          error={error?.mineRailwayStation}
        />
      </Col>
    </Row>
  );
};
export default MineDetailsForm;
