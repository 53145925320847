import { isArray } from "lodash";
import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import CheckBoxField from "../../../../components/_form/CheckBoxField/CheckBoxField";
import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";
import { capitalizeName } from "../../../../_helpers/helper";
import {
  MODULE_OBJECT_VIEW,
  ROLE_OBJECT_VIEW,
} from "../../../../_services/Auth/helper";
import { userLabel } from "./DATA";

const UserManagementView = ({ form, USER_MANAGEMENT_ROLE }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <Row>
              <Col sm={6}>
                <label>{userLabel?.name}</label>
                <p className="content" name="user-name">
                  {capitalizeName(
                    `${form?.firstName ? form?.firstName : ""} ${
                      form?.lastName ? form?.lastName : ""
                    }`
                  )}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.roleId}</label>
                <p className="content" name="user-role">
                  {form?.role?.title || ""}
                </p>
              </Col>
              {form?.contractor ? (
                <Col sm={6}>
                  <label>{userLabel?.contractor_id}</label>
                  <p className="content">
                    {form?.contractor?.contractor || ""}
                  </p>
                </Col>
              ) : (
                ""
              )}
              <Col sm={6}>
                <label>{userLabel?.email}</label>
                <p className="content" name="email">
                  {form?.email}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.contactNumber}</label>
                <p name="contactNumber" className="content">
                  {form?.contactNumber}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.dob}</label>
                <p className="content">
                  {form?.dob ? DDMMYYYYFormat(form?.dob) : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{userLabel?.siteId}</label>
                <p name="user-site" className="content">
                  {form?.site?.name || ""}
                </p>
              </Col>
            </Row>
            {form?.roleId === 4 && (
              <>
                <h6 className="mt-2">Permissions​</h6>
                <div className="mt-0 seperator"></div>
                <Row>
                  {Object.keys(USER_MANAGEMENT_ROLE)?.map((module, index) => {
                    const roleData = Object.keys(
                      USER_MANAGEMENT_ROLE[module]
                    )?.map((role, i) => {
                      const permissionkey =
                        (Array.isArray(form?.accessPermissions) &&
                          form?.accessPermissions?.find(
                            (roleObj) => roleObj?.permission === role
                          )) ||
                        "";
                      return (
                        <Col key={i} md={6}>
                          <CheckBoxField
                            name={role}
                            checked={permissionkey ? true : false}
                            label={ROLE_OBJECT_VIEW?.[role]}
                            id={`${index}check${i}`}
                            disabled={true}
                          />
                        </Col>
                      );
                    });
                    return (
                      <>
                        <h6 className="mt-2" key={index}>
                          {MODULE_OBJECT_VIEW[module]}
                        </h6>{" "}
                        {roleData}
                      </>
                    );
                  })}
                </Row>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default UserManagementView;
