import React, { useEffect, useState } from "react";
import { initialForm, validationRules, tankApi, tankLabel } from "./DATA";
import { Col, Form, Row, Button } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { useNavigate, useParams } from "react-router-dom";
import { SidebarData } from "../../FuelManagement/DATA";
import {
  auth_user,
  digitCheckAfterDecimal,
  IS_USER_SITE_MINES,
  isDecimal,
  isDecimalCheck,
} from "../../../../_helpers/helper";
import { useForm } from "../../../../_helpers/hooks";
import { Validation } from "../../../../_helpers/validation/Validation";
import {
  updateTank,
  addTank,
} from "../../../../services/_fuelDispense/fuelDispense";
import toastr from "toastr";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import Select from "../../../../components/Select/Select";

const TankAdd = () => {
  const params = useParams();
  const [mineList, setMineList] = useState([]);
  const navigate = useNavigate();
  const [form, onChange, setForm] = useForm(initialForm);
  const [error, setError] = useState({
    ...initialForm,
  });
  const onSubmit = async () => {
    const errorObj = Validation(form, validationRules);

    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }
    if (isDecimalCheck(form.maxCapacity) || 0 > +form.maxCapacity) {
      setError((prev) => {
        return { ...prev, maxCapacity: "Please enter a valid number." };
      });
    }
    if (isDecimalCheck(form.closingDip) || 0 > +form.closingDip) {
      setError((prev) => {
        return { ...prev, closingDip: "Please enter a valid number." };
      });
    }

    if (
      isDecimalCheck(form.maxCapacity) ||
      0 > +form.maxCapacity ||
      isDecimalCheck(form.closingDip) ||
      0 > +form.closingDip
    )
      return;

    let response = params?.id
      ? await updateTank(form, params.id)
      : await addTank(form);

    if (!response?.status) {
      return toastr.error(response?.message || "something went wrong");
    }
    if (response?.status) toastr.success(`Saved Successfully`);
    navigate("/fuel-management-system/configuration/tank-master");
  };

  async function fetchData() {
    let response = await tankApi.getById.api(params?.id);
    if (response?.status) {
      setForm(() => {
        return {
          site_id: response?.data?.site_id,
          tankId: response?.data?.tankId,
          name: response?.data?.name,
          maxCapacity: response?.data?.maxCapacity,
        };
      });
    }
  }

  const mineLists = async () => {
    let list = [];
    const response = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );

    if (response.status) {
      list = response.data?.map((d) => {
        return { value: d.id, label: d.name };
      });
    }
    setMineList(list);
  };
  useEffect(() => {
    mineLists();
  }, []);
  useEffect(() => {
    if (params.id) fetchData();
  }, [params.id, setForm]);

  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj =
      mineList?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setForm((prev) => ({
      ...prev,
      site_id: siteObj?.value,
    }));
  }, [mineList]);
  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    path: "/",
                    name: "Fuel Management",
                  },
                  {
                    name: "Configuration",
                    path: "/fuel-management-system/configuration",
                  },
                  {
                    path: "#",
                    name: "Add Tank",
                  },
                ]}
              />
            </h3>
            <div className="reportCard">
              <div className="formWrapper">
                <div className="maverickFormCard">
                  <div className="creationWrapper">
                    <Form>
                      <Row>
                        <Col lg={6}>
                          <Select
                            onChange={onChange}
                            name="site_id"
                            options={mineList}
                            label={tankLabel.site_id}
                            value={form?.site_id}
                            error={error?.site_id}
                            disabled={IS_USER_SITE_MINES()}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="tankId"
                            value={form.tankId}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={tankLabel.tankId}
                            error={error?.tankId}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="name"
                            value={form.name}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={tankLabel.name}
                            error={error?.name}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="maxCapacity"
                            value={form.maxCapacity}
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              digitCheckAfterDecimal(value, 2) &&
                              onChange({ target: { name, value } })
                            }
                            type="number"
                            class="form-control"
                            placeholder=""
                            label={tankLabel.maxCapacity}
                            error={error?.maxCapacity}
                          />
                        </Col>
                        {!params.id && (
                          <>
                            {" "}
                            {/* <Col lg={6}>
                              <FormInputText
                                name="closingStock"
                                value={form.closingStock}
                                onChange={onChange}
                                type="text"
                                class="form-control"
                                placeholder=""
                                label={tankLabel.closingStock}
                                error={error?.closingStock}
                              />
                            </Col> */}
                            <Col lg={6}>
                              <FormInputText
                                name="closingDip"
                                value={form.closingDip}
                                onChange={({ target: { name, value } }) =>
                                  value >= 0 &&
                                  digitCheckAfterDecimal(value, 2) &&
                                  onChange({ target: { name, value } })
                                }
                                type="number"
                                class="form-control"
                                placeholder=""
                                label={tankLabel.closingDip}
                                error={error?.closingDip}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </Form>
                    <div className="btnSec">
                      <Button
                        data-testid="next-btn"
                        onClick={() =>
                          navigate(
                            `/fuel-management-system/configuration/tank-master`
                          )
                        }
                        className="secondaryBtn iconBtnForm"
                      >
                        Back
                      </Button>
                      <Button
                        data-testid="next-btn-submit"
                        onClick={() => onSubmit()}
                        className="primaryBtn iconBtnForm"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TankAdd;
