import {
  createFuelLocation,
  getAllHemm,
  updateFuelLocation,
  getFuelLocationById,
  getHemmDataBydataId,
} from "../../../../services/_fuelDispense/fuelDispense";
export const initialForm = {
  hemm_id: "",
  fms_vehicle_type: "",
  make: "",
  capacity: "",
  model: "",
  fms_vehicle_name: "",
  machine_serial: "",
  isBowser: 0,
  reason: "",
  appliedFrom: "",
  hmrReading: "",
  loadCapacity: "",
  site_id:""
};
export const capacityRules = {
  label: "Max Capacity (L)",
  type: "string",
  isRequired: true,
  field_name: "capacity",
};
export const validationRules = [
  {
    label: "HEMM Id",
    type: "string",
    isRequired: true,
    field_name: "hemm_id",
  },
  {
    label: "Mine",
    type: "string",
    isRequired: true,
    field_name: "site_id",
  },
  {
    field_name: "fms_vehicle_type",
    label: "FMS Vehicle Type",
    isRequired: true,
    type: "string",
  },
  {
    label: "Make",
    type: "string",
    isRequired: true,
    field_name: "make",
  },
  {
    label: "Capacity",
    field_name: "capacity",
    type: "string",
    isRequired: true,
  },
  {
    label: "Load Capacity",
    field_name: "loadCapacity",
    type: "string",
    isRequired: true,
  },
  {
    label: "Model",
    type: "string",
    isRequired: true,
    field_name: "model",
  },
  {
    type: "string",
    field_name: "fms_vehicle_name",
    label: "FMS Vehicle Name",
    isRequired: true,
  },
];

export const validationRulesHmr = [
  {
    label: "Reason",
    type: "string",
    isRequired: true,
    field_name: "reason",
  },
  {
    field_name: "appliedFrom",
    label: "Applied From",
    isRequired: true,
    type: "string",
  },
  {
    label: "HMR Reading",
    type: "number",
    isRequired: true,
    minNumber: 0,
    field_name: "hmrReading",
  },
];
export const hemmApi = {
  getAll: {
    api: getAllHemm,
  },
  getById: {
    api: getHemmDataBydataId,
  },
};

export const reasonList = [
  { value: "meterChange", label: "Meter Replacement" },
  { value: "readingChange", label: "Manual Correction" },
];

export const fuelLocationColumnFields = [
  {
    name: "HEMM / Bowser Id",
    selector: "hemm_id",
    sortable: true,
    cell: (row) => row?.hemm_id,
    minWidth: "200px",
  },
  {
    name: "Is Bowser",
    selector: "isBowser",
    sortable: false,
    cell: (row) => (row?.isBowser === "1" ? "Yes" : "No"),
  },
  {
    name: "Mine",
    selector: "mine_id",
    minWidth: "170px",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  {
    name: "Machine Serial Number",
    selector: "machine_serial",
    sortable: true,
    cell: (row) => row?.machine_serial,
    minWidth: "220px",
  },
  {
    name: "FMS Vehicle Type",
    selector: "fms_vehicle_type",
    sortable: true,
    cell: (row) => row?.fms_vehicle_type,
    minWidth: "220px",
  },
  {
    name: "FMS Vehicle Name",
    selector: "fms_vehicle_name",
    sortable: true,
    cell: (row) => row?.fms_vehicle_name,
    minWidth: "220px",
  },
  {
    name: "Make",
    selector: "make",
    sortable: true,
    cell: (row) => row?.make,
    minWidth: "200px",
  },
  {
    name: "Model",
    selector: "model",
    sortable: true,
    cell: (row) => row?.model,
    minWidth: "200px",
  },
  {
    name: "Tank Capacity",
    selector: "capacity",
    sortable: true,
    cell: (row) => row?.capacity,
    minWidth: "250px",
  },
  {
    name: "Load Capacity",
    selector: "loadCapacity",
    sortable: true,
    cell: (row) => row?.loadCapacity,
    minWidth: "220px",
  },
];
