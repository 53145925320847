import { deletePo, getAllPoList } from "../../../services/_poList/poList";

export const poListApis = Object.freeze({
    getAll: {
        api: getAllPoList,
    },
    delete: {
        api: deletePo,
    },
});

export const poListColumnFields = [
    {
        name: "Subsidiary",
        selector: "siteData",
        // sortable: true,
        cell: (row) => row?.site?.parentSite?.name,
    },
    {
        name: "Mine",
        selector: "siteData",
        // sortable: true,
        cell: (row) => row?.site?.name,
    },
    {
        name: "Contractor",
        cell: (row) => row?.contractor?.contractor,
        sortable: true,
    },
    {
        name: "NIT",
        cell: (row) => row?.nitNumber?.nit,
        sortable: true,
    },
    {
        name: "PO",
        selector: "po",
        sortable: true,
    },
    // {
    //   name: "NIT Number",
    //   selector: "nit_number",
    // },
    // {
    //   name: "Mine",
    //   selector: "siteData",
    //   // sortable: true,
    //   cell: (row) => row?.siteData?.name,
    // },
    // {
    //   name: "Subsidiary",
    //   selector: "siteData",
    //   // sortable: true,
    //   cell: (row) => row?.siteData?.parentSite?.name,
    // },
];