import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../components/FormInputText/FormInputText";
import { performanceStatus, commenStatus } from "./DATA";
import { shiftsName } from "./DATA";
const HindranceApproverData = (data) => {
  return (
    <>
      <div className="hindranceContent" data-testid="acceptedHours-error">
        <h4 className="font18">{data?.myKey + 1}</h4>

        <div className="hindranceContentData">
          <Row>
            <Col lg={2}>
              <div className="form-group">
                <label>Shift:</label>
                <p>{data?.data?.shift && shiftsName[data?.data?.shift]}</p>
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Reason:</label>
                <p>{data?.data?.reasons_label?.name}</p>
              </div>
            </Col>
            <Col lg={2}>
              <label>Vendor Hours</label>
              <p className="content">{data?.data?.hrs_of_hindrance}</p>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Place:</label>
                <p>{data?.data?.place}</p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Vendor comment:</label>
                <p>{data?.data?.vendor_comments}</p>
              </div>
            </Col>
          </Row>
          {(data?.presentStatus === "section_incharge" ||
            data?.presentStatus === "colliery_manager") && (
            <Row>
              <Col lg={4}></Col>
              <Col lg={4}>
                <label>Representative Hours</label>
                <p className="content">
                  {data?.data?.hrs_of_hindrance_by_representative}
                </p>
              </Col>
              <Col lg={4}>
                <label>Representative Comment</label>
                <p className="content">{data?.data?.representative_comments}</p>
              </Col>
            </Row>
          )}
          {data?.presentStatus === "colliery_manager" && (
            <Row>
              <Col lg={4}></Col>
              <Col lg={4}>
                <label>Section Incharge Hours</label>
                <p className="content">
                  {data?.data?.hrs_of_hindrance_by_section_incharge}
                </p>
              </Col>
              <Col lg={4}>
                <label>Section Incharge Comment</label>
                <p className="content">
                  {data?.data?.section_incharge_comments}
                </p>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={4}>
              <Form.Label>
                {performanceStatus[data?.presentStatus]} Accepted Hours
              </Form.Label>
              <FormInputText
                type="number"
                // maxLength={24}
                // minLength={0}
                onChange={(e) => {
                  data?.onChange(e.target, data?.data?.id, data?.data?.shift);
                }}
                value={data?.data?.acceptedHours}
                name="acceptedHours"
                data-testid="acceptedHours"
                error={data?.errorMsg?.acceptedHours}
                className={"hour"}
              />
            </Col>
            <Col md={4} lg={7}>
              <FormInputText
                type="input"
                data-testid="acceptedHours"
                label={performanceStatus[data?.presentStatus] + " Comment"}
                name={commenStatus[data?.presentStatus]}
                value={
                  data?.presentStatus &&
                  data?.data?.[commenStatus[data?.presentStatus]]
                }
                onChange={(e) => {
                  data?.onChange(e.target, data?.data?.id);
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default HindranceApproverData;
