import * as xlsx from "xlsx";
import toastr from "toastr";

export const saveAsExcelXlsx = async (data, column, fileName) => {

    const workSheet = xlsx.utils.json_to_sheet(data);
    const workBook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workBook, workSheet);

    // Generate buffer
    xlsx.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    xlsx.write(workBook, { bookType: "xlsx", type: "binary" });

    xlsx.writeFile(workBook, fileName);

    toastr.success(`Successfully downloded`);
}
