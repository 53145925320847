import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import IncidentEvidences from "../../IncidentEvidences";
import ReportButton from "../../ReportButton/ReportButton";
import { CustomSwitch } from "../../../../../../components/CustomSwitch/CustomSwitch";
import Select from "../../../../../../components/MobileComponents/Select/Select";
import { evidenceTypes, evidenceTypesObj } from "../../../DATA";
import IncidentEvidence from "../../IncidentEvidence";
import EvidencePreview from "../../EvidencePreview";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Asterik from "../../../../../../components/Common/Asterik/Asterik";

const IncidentEvidenceTab = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  evidences,
  evidencesErrors,
  onChangeEvidence,
  onChangeEvidenceFile,
  addEvidence,
  deleteEvidence,
  tab,
  asterikRules,
  isEdit,
}) => {
  return (
    <>
      <div className="mobileFormWrapper">
        <h3>Evidence</h3>
        <div className="ehsFormWrapper p-0">
          <div className="customSwitchSec">
            <CustomSwitch
              name="reportEvidencesRequired"
              value={
                form.reportEvidencesRequired === null
                  ? false
                  : form.reportEvidencesRequired
              }
              onChange={onChange}
              title="Evidence Available"
            />
          </div>
          <p className="text-danger">{error?.reportEvidencesRequired}</p>
          <Form>
            <Row>
              {form.reportEvidencesRequired && (
                <Col md="12">
                  {evidences.length > 0 ? (
                    evidences.map((x, index) => {
                      return x.deleted ? null : (
                        <div className="addDataformCards" key={index}>
                          <div className="formFields mb-3">
                            <Select
                              label={"Type"}
                              name="type"
                              value={x.type}
                              onChange={({ target: { name, value } }) =>
                                onChangeEvidence({ name, value, index })
                              }
                              // className="form-control"
                              disabled={!!x.fileId}
                              options={Object.keys(evidenceTypes)}
                              object={evidenceTypes}
                              init="none"
                              blankSelect="none"
                            ></Select>
                          </div>
                          <div className="formFields">
                            <label>
                              Description
                              <Asterik
                                fieldName={"description"}
                                requiredRules={asterikRules}
                              />
                            </label>

                            <IncidentEvidence
                              onChangeFile={({ file }, responseData) => {
                                onChangeEvidenceFile({
                                  file,
                                  responseData,
                                  index,
                                });
                              }}
                              onChangeDescription={(value) =>
                                onChangeEvidence({
                                  index,
                                  name: "description",
                                  value,
                                })
                              }
                              description={x.description}
                              fileId={x.fileId}
                              index={index}
                              extensions={
                                evidenceTypes[x.type] === evidenceTypes.photo
                                  ? ["jpg", "jpeg", "png"]
                                  : evidenceTypes[x.type] ===
                                    evidenceTypes.video
                                  ? ["mp4"]
                                  : x.type === "Document" ||
                                    x.type === "document"
                                  ? ["pdf"]
                                  : // [
                                    //     "doc",
                                    //     "html",
                                    //     "pdf",
                                    //     "txt",
                                    //     "csv",
                                    //     "xls",
                                    //     "xlsx",
                                    //   ]
                                    []
                              }
                            />
                            <span className="small text-danger">
                              {evidencesErrors[index]?.description ||
                                evidencesErrors[index]?.fileId}
                            </span>
                          </div>
                          <div className="formFields">
                            <label>File View</label>
                            {x.file && (
                              <EvidencePreview
                                videoHight={460}
                                file={x.file}
                                type={x.type}
                              />
                            )}
                          </div>
                          <div className="actionIconsGroup">
                            <span
                              data-testid={"remove-leaf" + index}
                              onClick={() => deleteEvidence(index)}
                              className="icons font-21 text-danger"
                            >
                              <IoIosCloseCircleOutline
                                size="26"
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                  <div className="addMoreBtn">
                    <span
                      className="addTxt"
                      onClick={addEvidence}
                      data-testid="add-leaf"
                    >
                      <AiOutlinePlus />
                      Add
                    </span>
                  </div>

                  {/* <div className="formWrapper rootCause">
                    <IncidentEvidences
                      dataArray={evidences}
                      errorArray={evidencesErrors}
                      onChange={onChangeEvidence}
                      onChangeFile={onChangeEvidenceFile}
                      onAdd={addEvidence}
                      onDelete={deleteEvidence}
                    />
                  </div> */}
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </div>
      <ReportButton
        setTab={setTab}
        tab={tab}
        onSubmit={onSubmit}
        id={isEdit && form.status === "reported"}
      />
    </>
  );
};

export default IncidentEvidenceTab;
