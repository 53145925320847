import React, { useState } from "react";
import { Col, Modal, ModalBody } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Row } from "reactstrap";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import PopUpFile from "../../components/PopUpFile/PopUpFile";
import { IoPlayCircleOutline } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  incidentCloseLabel,
  startCloseDateTimeKey,
  startCloseDateTimeLabel,
  statusList,
} from "./DATA";
import { DDMMYYYYFormat, DDMMYYYYHHMMAFormat } from "../../_helpers/dateHelper";
import { PARSE_IMG_URL } from "../../_helpers/commonHelper";
import {
  INCIDENT_VIEW_COMPONENT,
  compensationObjLavel,
  incidentTabList,
  incidentTypesObj,
  injuryCodeObj,
  objStaffType,
  responsiblePersonLabel,
  shiftObj,
  viewStaffType,
} from "./IncidentManagement/DATA";
import { YES_NO_BY_NUMBER, employeeDropdownLabel } from "../../_helpers/helper";
import InvestigationView from "./IncidentManagement/components/InvestigationView";
import CapasView from "./IncidentManagement/components/CapasView";
import EvidenceView from "./IncidentManagement/components/ReportFormView/EvidenceView";
import CreatedByCreatedAt from "../../components/Common/CreatedByCreatedAt";

const SafetyManagementView = ({ form }) => {
  const [videoShowManageModal, setVideoShowManageModal] = useState(false);
  const [imageShowManageModal, setImageShowManageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewDasContent">
            <Row>
              <Col md={4}>
                <label>Incident ID</label>
                <p className="content">{form?.incident_uid}</p>
              </Col>
              <Col md={4}>
                <label>Status</label>
                <p className="content">{statusList[form?.status]}</p>
              </Col>
              <Col md={4}>
                <label>Subsidiary</label>
                <p className="content">{form?.site?.parentSite?.name}</p>
              </Col>
              <Col md={4}>
                <label>Mine</label>
                <p className="content">{form?.site?.name}</p>
              </Col>
              <Col md={4}>
                <label>Incident/Accident Time</label>
                <p className="content">
                  {DDMMYYYYHHMMAFormat(form?.incident_datetime)}
                </p>
              </Col>
              <Col md={4}>
                <label>Recording / Report Date and Time</label>
                <p className="content">
                  {DDMMYYYYHHMMAFormat(form?.incident_reportdatetime)}
                </p>
              </Col>
              <Col md={4}>
                <label>Incident/Accident Type</label>
                <p className="content">{incidentTypesObj[form?.category]}</p>
              </Col>
              {form?.category === "others" && (
                <Col md={4}>
                  <label>Other Incident Type</label>
                  <p className="content">{form?.category_other}</p>
                </Col>
              )}
              <Col md={4}>
                <label>Incident/Accident Severity</label>
                <p className="content">{form?.severity}</p>
              </Col>
              <Col md={4}>
                <label>Reported By</label>
                <p className="content">
                  {viewStaffType[form?.staffType] || form?.staffType}
                </p>
              </Col>
              {form?.staffType !== "Not Applicable" && (
                <>
                  <Col md={4}>
                    <label>Reported by - Name</label>
                    <p className="content">
                      {form?.staffType !== objStaffType.users
                        ? form?.otherStaffName
                        : form?.staffData
                        ? employeeDropdownLabel(form?.staffData)
                        : form?.staffName}
                    </p>
                  </Col>
                  {form?.staffType !== objStaffType.others && (
                    <Col md={4}>
                      <label>{`${
                        form?.staffType === objStaffType.users
                          ? "Employer"
                          : "Contractor"
                      }`}</label>
                      <p className="content">
                        {form?.staffType === objStaffType.users
                          ? form?.staffEmployer
                          : form?.contractorData?.contractor ||
                            form?.staffEmployer}
                      </p>
                    </Col>
                  )}
                </>
              )}
              <Col md={4}>
                <label>Department</label>
                <p className="content">{form?.department}</p>
              </Col>
              <Col md={4}>
                <label>Place</label>
                <p className="content">
                  {form?.area?.code + " - " + form?.area?.name}
                </p>
              </Col>
              <Col md={4}>
                <label>Workings</label>
                <p className="content">
                  {form?.subAreaId === 999
                    ? "Other"
                    : form?.subArea?.code + " - " + form?.subArea?.name}
                </p>
              </Col>

              <Col md={4}>
                <label>Shift</label>
                <p className="content">{shiftObj[form?.shift]}</p>
              </Col>
              <Col md={4}>
                <label>Activity</label>
                <p className="content">
                  {form?.activity?.name || "Not Applicable"}
                </p>
              </Col>
              <Col md={4}>
                <label>Sub Activity</label>
                <p className="content">
                  {form?.subActivity?.name || "Not Applicable"}
                </p>
              </Col>
            </Row>
            <Accordion defaultActiveKey="0">
              {incidentTabList?.map((tabs) => {
                return tabs?.tab !== 1 ? (
                  <Accordion.Item eventKey={tabs?.tab}>
                    <Accordion.Header>{tabs?.label}</Accordion.Header>
                    <Accordion.Body>
                      {INCIDENT_VIEW_COMPONENT(tabs?.tab, form)}
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  ""
                );
              })}
              {Array.isArray(form?.incidentHistory) &&
                form?.incidentHistory?.map((row, key) => {
                  if (row?.status === "investigation_completed") {
                    return (
                      <>
                        <Accordion.Item eventKey={`id_${key}`}>
                          <Accordion.Header>
                            {statusList[row?.status]}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col lg={6}>
                                <label>Comment</label>
                                <p>{row?.comment || ""}</p>
                              </Col>
                              <Col lg={6}>
                                <label>
                                  {startCloseDateTimeLabel[row?.status]}
                                </label>
                                <p>
                                  {form?.[startCloseDateTimeKey[row?.status]]
                                    ? DDMMYYYYFormat(
                                        form?.[
                                          startCloseDateTimeKey[row?.status]
                                        ]
                                      )
                                    : ""}
                                </p>
                              </Col>
                              <Col lg={6}>
                                <label>Investigator Name</label>
                                <p>
                                  {employeeDropdownLabel(
                                    form?.investigatorData
                                  )}
                                </p>
                              </Col>
                              <Col lg={6}>
                                <label>
                                  Person responsible for the incident
                                </label>
                                <p>{form?.personResponsible}</p>
                              </Col>
                            </Row>
                            <EvidenceView form={form?.evidences} />
                            <InvestigationView form={form} />

                            <Col lg={12}>
                              <label>{incidentCloseLabel.conclusion} </label>
                              <p>{form?.conclusion}</p>
                            </Col>
                            <Col lg={12}>
                              <label>{incidentCloseLabel.averted} </label>
                              <p>{form?.averted}</p>
                            </Col>
                            <Col lg={12}>
                              <label>
                                {incidentCloseLabel.responsibilities}{" "}
                              </label>
                              <p>{form?.responsibilities}</p>
                            </Col>
                            <Col lg={12}>
                              <label>
                                {incidentCloseLabel.recommendation}{" "}
                              </label>
                              <p>{form?.recommendation}</p>
                            </Col>
                            <Col lg={12}>
                              <label>{incidentCloseLabel.actionsTaken} </label>
                              <p>{form?.actionsTaken}</p>
                            </Col>
                          </Accordion.Body>
                        </Accordion.Item>
                      </>
                    );
                  } else if (row?.status === "capa_assigned") {
                    return (
                      <>
                        <Accordion.Item eventKey={`id_${key}`}>
                          <Accordion.Header>
                            {statusList[row?.status]}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col lg={4}>
                                <label>Comment</label>
                                <p>{row?.comment}</p>
                              </Col>
                              <Col lg={4}>
                                <label>
                                  {startCloseDateTimeLabel[row?.status]}
                                </label>
                                <p>
                                  {form?.[startCloseDateTimeKey[row?.status]]
                                    ? DDMMYYYYHHMMAFormat(
                                        form?.[
                                          startCloseDateTimeKey[row?.status]
                                        ]
                                      )
                                    : ""}
                                </p>
                              </Col>
                              {form?.capa_close_time && (
                                <Col lg={4}>
                                  <label>
                                    {startCloseDateTimeLabel?.capa_closed}
                                  </label>
                                  <p>{DDMMYYYYFormat(form?.capa_close_time)}</p>
                                </Col>
                              )}
                            </Row>
                            {+form?.is_capa ? <CapasView form={form} /> : ""}
                          </Accordion.Body>
                        </Accordion.Item>
                      </>
                    );
                  } else if (
                    row?.status !== "reported" &&
                    row?.status !== "draft"
                  ) {
                    return (
                      <Accordion.Item eventKey={`id_${key}`}>
                        <Accordion.Header>
                          {statusList[row?.status]}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col lg={6}>
                              <>
                                <label>
                                  {row?.status === "incident_closed"
                                    ? "Comments/Recommendation by ISO or Any Other"
                                    : "Comment"}
                                </label>
                                <p>{row?.comment}</p>
                              </>
                            </Col>
                            <Col lg={3}>
                              <>
                                <label>
                                  {startCloseDateTimeLabel[row?.status]}
                                </label>
                                <p>
                                  {form?.[startCloseDateTimeKey[row?.status]]
                                    ? row?.status === "incident_closed"
                                      ? DDMMYYYYFormat(
                                          form?.[
                                            startCloseDateTimeKey[row?.status]
                                          ]
                                        )
                                      : DDMMYYYYHHMMAFormat(
                                          form?.[
                                            startCloseDateTimeKey[row?.status]
                                          ]
                                        )
                                    : ""}
                                </p>
                              </>
                            </Col>
                          </Row>
                          {row?.status === "incident_closed" && (
                            <>
                              <Row>
                                <Col lg={6}>
                                  <label>DGMS Reportable</label>
                                  <p>{form?.isDgmsReportable ? "Yes" : "No"}</p>
                                </Col>
                                <Col lg={6}>
                                  <label>DGMS ID</label>
                                  <p>{form?.dbms_id}</p>
                                </Col>
                                <Col lg={6}>
                                  <label>Loss of production (in tonnes)</label>
                                  <p>{form?.lossOfProduction}</p>
                                </Col>
                                <Col lg={6}>
                                  <label>
                                    Loss of manshifts – hours (Indirect loss)
                                  </label>
                                  <p>{form?.lossOfManshifts}</p>
                                </Col>
                                <Col lg={6}>
                                  <label>
                                    Action taken to prevent similar accident
                                  </label>
                                  <p>{form?.actionsToPrevent}</p>
                                </Col>
                                <Col lg={6}>
                                  <label>Compensation Information</label>
                                  <p>
                                    {YES_NO_BY_NUMBER(
                                      form?.isCompensationInfor
                                    )}
                                  </p>
                                </Col>
                                <Col lg={6}>
                                  <label>Responsibilities Information</label>
                                  <p>
                                    {form?.incidentResponsibilities?.length
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </Col>
                                <Col lg={6}>
                                  <label>
                                    How Accident Could have been avoided?
                                    (Optional)
                                  </label>
                                  <p>{form?.howAvoided}</p>
                                </Col>
                              </Row>

                              {form?.personalDetails?.length ? (
                                <>
                                  {form?.isCompensationInfor ? (
                                    <h6 className="mb-1">Personnel Info</h6>
                                  ) : (
                                    ""
                                  )}
                                  {form?.isCompensationInfor
                                    ? form?.personalDetails?.map((obj, idx) => {
                                        return (
                                          <>
                                            <Row className="previewContent">
                                              <Col lg={3}>
                                                <label>
                                                  {compensationObjLavel.outcome}
                                                </label>
                                                <p>{obj?.outcome}</p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.injuryCode
                                                  }
                                                </label>
                                                <p>
                                                  {
                                                    // injuryCodeObj[
                                                    obj?.name
                                                    // ]
                                                  }
                                                </p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.dateOfReturnDuty
                                                  }
                                                </label>
                                                <p>
                                                  {obj?.dateOfReturnDuty
                                                    ? DDMMYYYYFormat(
                                                        obj?.dateOfReturnDuty
                                                      )
                                                    : "Not Applicable"}
                                                </p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.returnForRegular
                                                  }
                                                </label>
                                                <p>{obj?.returnForRegular}</p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.compensationPaid
                                                  }
                                                </label>
                                                <p>{obj?.compensationPaid}</p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.exGratiaPaid
                                                  }
                                                </label>
                                                <p>{obj?.exGratiaPaid}</p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.otherBenefits
                                                  }
                                                </label>
                                                <p>{obj?.otherBenefits}</p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.statutoryCompensationPaid
                                                  }
                                                </label>
                                                <p>
                                                  {
                                                    obj?.statutoryCompensationPaid
                                                  }
                                                </p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.totalExperience
                                                  }
                                                </label>
                                                <p>{obj?.totalExperience}</p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.jobDoneAtAccidentTime
                                                  }
                                                </label>
                                                <p>
                                                  {obj?.jobDoneAtAccidentTime}
                                                </p>
                                              </Col>

                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.experienceInJobAtAccidentTime
                                                  }
                                                </label>
                                                <p>
                                                  {
                                                    obj?.experienceInJobAtAccidentTime
                                                  }
                                                </p>
                                              </Col>

                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.bodyPartsLoss
                                                  }
                                                </label>
                                                <p>{obj?.bodyPartsLoss}</p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.bodyPartsGoneOut
                                                  }
                                                </label>
                                                <p>{obj?.bodyPartsGoneOut}</p>
                                              </Col>

                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.vocationallyTrained
                                                  }
                                                </label>
                                                <p>
                                                  {obj?.vocationallyTrained
                                                    ? "Yes"
                                                    : "No"}
                                                </p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.initialVocationTraining
                                                  }
                                                </label>
                                                <p>
                                                  {obj?.initialVocationTraining
                                                    ? "Yes"
                                                    : "No"}
                                                </p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.dateOfRefresherTraining
                                                  }
                                                </label>
                                                <p>
                                                  {obj?.dateOfRefresherTraining
                                                    ? DDMMYYYYFormat(
                                                        obj?.dateOfRefresherTraining
                                                      )
                                                    : "Not Applicable"}
                                                </p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.jobNature
                                                  }
                                                </label>
                                                <p>
                                                  {obj?.jobNature === "1"
                                                    ? "Contractual"
                                                    : "Departmental"}
                                                </p>
                                              </Col>
                                              <Col lg={3}>
                                                <label>
                                                  {
                                                    compensationObjLavel.causeOfDeath
                                                  }
                                                </label>
                                                <p>
                                                  {obj?.causeOfDeath
                                                    ? obj?.causeOfDeath
                                                    : "Not Applcable"}
                                                </p>
                                              </Col>
                                            </Row>
                                          </>
                                        );
                                      })
                                    : ""}
                                </>
                              ) : (
                                ""
                              )}

                              {form?.incidentResponsibilities?.length ? (
                                <h6 className="mb-1 mt-5">
                                  Responsibility for Accident
                                </h6>
                              ) : (
                                ""
                              )}
                              {form?.incidentResponsibilities
                                ? form?.incidentResponsibilities?.map(
                                    (obj, idx) => {
                                      return (
                                        <>
                                          <Row className="previewContent">
                                            <Col lg={4}>
                                              <label>
                                                {
                                                  responsiblePersonLabel.responsiblePerson
                                                }
                                              </label>
                                              <p>{obj?.responsiblePerson}</p>
                                            </Col>
                                            <Col lg={4}>
                                              <label>
                                                {
                                                  responsiblePersonLabel.designationCode
                                                }
                                              </label>
                                              <p>{obj?.designationCode}</p>
                                            </Col>
                                            <Col lg={4}>
                                              <label>
                                                {
                                                  responsiblePersonLabel.designation
                                                }
                                              </label>
                                              <p>{obj?.designation}</p>
                                            </Col>
                                            <Col lg={4}>
                                              <label>
                                                {
                                                  responsiblePersonLabel.rulesConverted
                                                }
                                              </label>
                                              <p>{obj?.rulesConverted}</p>
                                            </Col>

                                            <Col lg={4}>
                                              <label>
                                                {
                                                  responsiblePersonLabel.natureOfContravention
                                                }
                                              </label>
                                              <p>
                                                {obj?.natureOfContravention}
                                              </p>
                                            </Col>
                                            <Col lg={4}>
                                              <label>
                                                {
                                                  responsiblePersonLabel.actionTaken
                                                }
                                              </label>
                                              <p>{obj?.actionTaken}</p>
                                            </Col>
                                          </Row>
                                        </>
                                      );
                                    }
                                  )
                                : ""}
                            </>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  }
                })}
            </Accordion>
          </div>
          <CreatedByCreatedAt id={form?.id} table={"incidents"} />
        </div>
      </Modal.Body>
      <Modal
        show={imageShowManageModal}
        onHide={() => setImageShowManageModal(false)}
        size="lg"
        centered
      >
        <ModalBody className="safetyModal">
          <div
            className="closeIcon"
            data-testid="filter-button"
            onClick={() => {
              setImageShowManageModal(false);
            }}
          >
            <IoIosCloseCircleOutline />
          </div>
          <div className="popupImgVideo text-center">
            <img className="img-fluid" src={imageUrl} />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        show={videoShowManageModal}
        onHide={() => setVideoShowManageModal(false)}
        size="lg"
        centered
      >
        <ModalBody>
          <div
            className="closeIcon"
            onClick={() => {
              setVideoShowManageModal(false);
            }}
          >
            <IoIosCloseCircleOutline />
          </div>
          <div className="popupImgVideo">
            <PopUpFile
              src={videoUrl}
              // title={d?.real_document_name}
              height="460"
              hideClass
            />
          </div>
        </ModalBody>
      </Modal>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default SafetyManagementView;
