import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { Button } from "reactstrap";
import { NitMasterFilterForm, nitListApis, nitListColumnFields } from "./DATA";
import filter from "../../../Assets/Images/filter.svg";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyDataList from "../ContractorPerformance/EmptyDataList";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import { getAllNitList } from "../../../services/_nitList/nitList";
import NitListView from "./NitListView";
import NitFilterForm from "./NitFilterForm";
import { getAllContractor } from "../../../services/_hindrance/hindrance";
import { useForm } from "../../../_helpers/hooks";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../_services/CommonService/CommonService";
import toastr from "toastr";

const NitList = () => {
  const [isListData, setIsListData] = useState(true);
  const [contractorList, setContractorList] = useState([]);
  const [getAllFilter, setGetAllFilter] = useState();
  const cref = useRef(null);
  const [form, onChange, setForm] = useForm();

  const [filterStatus, setFilterStatus] = useState(0);

  let navigate = useNavigate();

  const getAll = async () => {
    const response = await getAllNitList();
    if (Array.isArray(response?.data) && response?.data?.length > 0)
      setIsListData(true);
    else setIsListData(false);
    const allContractorList = await getAllContractor();
    setContractorList(
      allContractorList?.map((d) => {
        return { value: d.id, label: d.contractor };
      })
    );
  };

  const onSubmitFilter = () => setFilterStatus((prev) => prev + 1);

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate"|| key === "startDateEnd"|| key === "endDateEnd")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      } 
    }

    setGetAllFilter(filter);
  }, [filterStatus]);

  const onResetFilter = () => {
    setForm(NitMasterFilterForm);
    setGetAllFilter();
  };

  const viewAsPopup = (form) => {
    return <NitListView form={form} />;
  };

  const onEdit = (id) => {
    navigate("/contractor-management/configuration/nit-list/edit/" + id);
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: `contractor_nit_numbers`,
        id: id,
        status: status
      }
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const finalNitListColumnFields = useMemo(
    () => [
      ...nitListColumnFields,
      {
        name: "Status",
        selector: "status",
        cell: (row) => {
          return (
            (row.status === 1 && (
              <span
                href="#"
                className="icons text-success pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOn
                  size="20"
                  data-testid={"toogle-user-list-on-" + row.id}
                />
              </span>
            )) ||
            (row.status === 0 && (
              <span
                href="#"
                className="icons text-danger pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOff
                  size="20"
                  data-testid={"toogle-user-list-off-" + row.id}
                />
              </span>
            ))
          );
        },
      },
    ],
    []
  );

  // const FilterBtn = () => {
  //   const [open, setOpen] = useState(false);

  //   return (
  //     <div className="filter">
  //       <Button
  //         onClick={() => setOpen(!open)}
  //         aria-controls="example-collapse-text"
  //         aria-expanded={open}
  //         data-testid="filterBtn"
  //         className="secondaryBtn"
  //       >
  //         <img src={filter} className="img-fluid" alt="fliterIcon" />
  //       </Button>
  //       <NitFilterForm
  //         form={form}
  //         onChange={onChange}
  //         onResetFilter={onResetFilter}
  //         onSubmitFilter={onSubmitFilter}
  //         isOpen={open}
  //         setOpen={setOpen}
  //         contractorList={contractorList}
  //       />
  //     </div>
  //   );
  // };
  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <NitFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          setForm={setForm}
       
        />
      </div>
    );
  };
  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={finalNitListColumnFields}
                apis={nitListApis}
                title="Data"
                viewAsPopup={viewAsPopup}
                FilterBtn={FilterBtn}
                hideSnColumn
                pageLink={"/contractor-management/configuration/nit-list"}
                primaryKey="id"
                pagination
                hideDeleteButton
                cref={cref}
                hideEditButton
                getAllFilter={getAllFilter}
                listname={"nitList"}
                customTableTitle={"NIT Master List"}
                viewTitle={"NIT Master Details"}
                // hideSearch={true}
                editFunButton={onEdit}
                deleteFun
                breadCrumb={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/contractor-management/configuration",
                  },
                  {
                    name: "NIT Master List",
                    path: "#",
                  },
                ]}
                isNoTabOnPage
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              title={"NIT Master "}
              pageLink={"/contractor-management/configuration/nit-list"}
              breadCrumb={[
                {
                  name: "Contractor Management",
                  path: "#",
                },
                {
                  name: "Configuration",
                  path: "/contractor-management/configuration",
                },
                {
                  name: "NIT Master List",
                  path: "#",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NitList;
