import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

export const getContractorScoreListWithPagination = async (filter) => {
  let url = "/contractor/score-card";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      totalCount: response.data?.data?.totalCount,
      data: response?.data?.data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const createContractorScore = async (data) => {
  let url = "contractor/score-card/create";
  try {
    const response = await RestMethod.POST(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateContractorScore = async (id, data) => {
  let url = "contractor/score-card/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getContractorScoreById = async (id) => {
  let url = "contractor/score-card/" + id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    return {
      status,
      message,
      data: response?.data?.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getMyContractorScoreListWithPagination = async (filter) => {
  let url = "/contractor/my-score-card";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      totalCount: response.data?.data?.totalCount,
      data: response?.data?.data?.incident,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};


export const getScoreCardMonthDashboard = async (filter) => {
  let url = "contractor/score-card-dashboard-month";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      data: response?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getScoreCardVendorDashboard = async (filter) => {
  let url = "contractor/score-card-dashboard-vendor";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      data: response?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllMonthOptionsData = async (filter) => {
  let url = "contractor/score-card-dashboard/month-options";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      data: response?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};