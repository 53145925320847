import React, { useState, useEffect, useMemo } from "react";
import { Nav, Tab } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import HelpManualPopup from "../../UserManagement/HelpManualPopup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";

const HelpManualCommonComponent = ({ moduleName, isSafetyDocuments }) => {
  const [activeKey, setActiveKey] = useState();
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let url = "/master-data/get-all/help-manual-master";
    try {
      const response = await RestMethod.GET(url);
      const { message, status, data } = response.data;
      setFormData(data?.helpManualData);
    } catch (error) {
      console.log("Error occurred while fetching data from the API", error);
    }
  };
  // console.log(formData,"formData");

  const filtdata = useMemo(() => {
    return formData?.filter((item) => item.module === moduleName);
  }, [formData]);

  function TabTitle({ title, active }) {
    return (
      <div className="d-flex justify-content-between pointer">
        <div>{title}</div>
        <div className={active ? "tabActive" : "mr-tab"}>
          <FaArrowRight />
        </div>
      </div>
    );
  }

  let defaultSelected = formData
    ?.filter((item) => item.module === moduleName)
    .sort((a, b) => a.sequence - b.sequence)[0]?.id;
  return (
    <>
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3 style={{ paddingBottom: "1px", paddingTop: "5px" }}>
              <MainBreadcrumb
                routeSegments={[
                  {
                    path: "/",
                    name: isSafetyDocuments ? "Safety Management" : moduleName,
                  },
                  {
                    name: isSafetyDocuments ? "Document" : "Help Manual",
                    path: "#",
                  },
                ]}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              {isSafetyDocuments ? "Document" : "Help Manual"}
            </h3>
            {defaultSelected && (
              <Tab.Container
                defaultActiveKey={defaultSelected}
                activeKey={activeKey}
                onSelect={setActiveKey}
              >
                <Row>
                  <Col xs={12} sm={6} md={3} lg={3} className="px-0 my-1">
                    <Nav
                      variant="pills"
                      className="flex-column p-1 border rounded mx-1"
                      style={{ minHeight: "73.5vh" }}
                    >
                      {filtdata?.length > 0 ? (
                        <>
                          {filtdata
                            ?.sort((a, b) => a.sequence - b.sequence)
                            .map((item, index) => (
                              <Nav.Item
                                key={item.id}
                                style={{ color: "#284d83" }}
                              >
                                <Nav.Link eventKey={item.id}>
                                  <TabTitle
                                    title={item.label}
                                    active={activeKey === item.id}
                                  />
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                        </>
                      ) : (
                        <>
                          <h5 className="text-center">No Data exists</h5>
                        </>
                      )}
                    </Nav>
                  </Col>
                  <Col xs={12} sm={6} md={9} lg={9} className="px-0 my-1">
                    <Tab.Content
                      className="p-2 border rounded mx-1"
                      style={{ minHeight: 500 }}
                    >
                      {filtdata?.length > 0 ? (
                        <>
                          {filtdata.map((item) => (
                            <Tab.Pane eventKey={item.id} key={item.id}>
                              <div className="customHeight">
                                <HelpManualPopup form={item} width={600} />
                              </div>
                            </Tab.Pane>
                          ))}
                        </>
                      ) : (
                        <>
                          <h5 className="text-center">No Data exists</h5>
                        </>
                      )}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpManualCommonComponent;
