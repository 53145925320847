import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../components/Select/Select";
const NitFilterForm = ({
  onSubmitFilter,
  form,
  onChange,
  isOpen,
  contractorList,
  setOpen,
  onResetFilter,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>Start Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              name="startDate"
              dateFormat="YYYY-MM-DD"
              onChangeDate={onChange}
              value={form?.startDate}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              name="endDate"
              dateFormat="YYYY-MM-DD"
              value={form?.endDate}
              onChangeDate={onChange}
            />
          </Col>
        </Row>
        <Form.Label>End Date</Form.Label>
        <Row>
        
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              name="endDateEnd"
              dateFormat="YYYY-MM-DD"
              value={form?.endDateEnd}
              onChangeDate={onChange}
            />
          </Col>
          <Col md={6}>
            <CustomDatePicker
              name="startDateEnd"
              dateFormat="YYYY-MM-DD"
              onChangeDate={onChange}
              value={form?.startDateEnd}
            />
          </Col>
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              type="button"
              className="secondaryBtn"
              onClick={onResetFilter}
            >
              Clear all
            </Button>
          </Col>
          <Col>
            <Button
              className="primaryBtn me-0"
              type="button"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default NitFilterForm;
