import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import LabelCustom from "../../../components/LabelCustom/LabelCustom";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import FormInputText from "../../../components/FormInputText/FormInputText";
import Select from "../../../components/Select/Select";
import { coalGradeLabel } from "../DATA";

export const ThirdPartyLabResultForm = ({
  form,
  onChange,
  error,
  gradeList,
}) => {
  return (
    <div className="creationWrapper">
      <Form>
        <h6>On Air Dry Basis</h6>
        <Row>
          <Col md={6}>
            <FormInputText
              type="input"
              label={
                coalGradeLabel?.third_party_on_air_dry_bases_moisture + ` %`
              }
              onChange={onChange}
              name="third_party_on_air_dry_bases_moisture"
              value={form?.third_party_on_air_dry_bases_moisture}
              error={error?.third_party_on_air_dry_bases_moisture}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.third_party_on_air_dry_bases_ash + ` %`}
              onChange={onChange}
              name="third_party_on_air_dry_bases_ash"
              value={form?.third_party_on_air_dry_bases_ash}
              error={error?.third_party_on_air_dry_bases_ash}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.third_party_on_air_dry_bases_vm + ` %`}
              onChange={onChange}
              name="third_party_on_air_dry_bases_vm"
              value={form?.third_party_on_air_dry_bases_vm}
              error={error?.third_party_on_air_dry_bases_vm}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={
                coalGradeLabel?.third_party_on_air_dry_bases_gcv + ` (kCal/kg)`
              }
              onChange={onChange}
              name="third_party_on_air_dry_bases_gcv"
              value={form?.third_party_on_air_dry_bases_gcv}
              error={error?.third_party_on_air_dry_bases_gcv}
            />
          </Col>
        </Row>
        <div className="seperator"></div>
        <h6>On EM Basis ​</h6>
        <Row>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.third_party_on_em_bases_moisture + ` %`}
              onChange={onChange}
              name="third_party_on_em_bases_moisture"
              value={form?.third_party_on_em_bases_moisture}
              error={error?.third_party_on_em_bases_moisture}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.third_party_on_em_bases_ash + ` %`}
              onChange={onChange}
              name="third_party_on_em_bases_ash"
              value={form?.third_party_on_em_bases_ash}
              error={error?.third_party_on_em_bases_ash}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.third_party_on_em_bases_vm + ` %`}
              onChange={onChange}
              name="third_party_on_em_bases_vm"
              value={form?.third_party_on_em_bases_vm}
              error={error?.third_party_on_em_bases_vm}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.third_party_on_em_bases_gcv + ` (kCal/kg)`}
              onChange={onChange}
              name="third_party_on_em_bases_gcv"
              value={form?.third_party_on_em_bases_gcv}
              error={error?.third_party_on_em_bases_gcv}
            />
          </Col>
        </Row>
        <div className="seperator"></div>
        <Row>
          <Col md={6}>
            <FormInputText
              type="input"
              label={coalGradeLabel?.third_party_total_moisture + ` %`}
              onChange={onChange}
              name="third_party_total_moisture"
              value={form?.third_party_total_moisture}
              error={error?.third_party_total_moisture}
            />
          </Col>
          <Col md={6}>
            <Select
              onChange={onChange}
              value={form?.third_party_analyzed_grade}
              name="third_party_analyzed_grade"
              options={gradeList}
              error={error?.third_party_analyzed_grade}
              label={coalGradeLabel?.third_party_analyzed_grade}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
