import React from "react";
import { DaysBetweenDates } from "../../../_helpers/helper";
import { isDecimal } from "../../../_helpers/helper";
import { Col, Row } from "react-bootstrap";

import { DDMMYYYYFormatBackslash } from "../../../_helpers/dateHelper";

import { capitalizeName } from "../../../_helpers/helper";

const DashboardDetailCard = ({ nitData, unit, achievedTillDate }) => {
  return (
    <Row className="pt-3">
      <Col lg={12}>
        <div className="colindiaCard contractorPerformanceTopTbl">
          <Row>
            <Col md={4} className="d-flex">
              <strong>Contractor</strong>
              <p>{nitData?.contractor?.contractor}</p>
            </Col>
            <Col className="d-flex" md={2}>
              <strong>QPD Plan</strong>
              <p>
                {unit === "Tonnes"
                  ? nitData?.qpd_proposed &&
                    isDecimal(nitData?.qpd_proposed / 30)
                  : nitData?.compliance && isDecimal(nitData?.compliance / 30)}
                ({unit})
              </p>
            </Col>
            <Col md={2} className="d-flex">
              <strong>Start Date</strong>
              <p>
                {nitData?.startDate
                  ? DDMMYYYYFormatBackslash(nitData?.startDate)
                  : ""}
              </p>
            </Col>
            <Col md={2} className="d-flex">
              <strong>End Date</strong>
              <p>{DDMMYYYYFormatBackslash(nitData?.endDate)}</p>
            </Col>
            <Col className="d-flex" md={2}>
              <strong>Achieved till Date</strong>
              <p>
                {(achievedTillDate && isDecimal(achievedTillDate)) || 0}({unit})
              </p>
            </Col>

            <Col md={2} className="d-flex">
              <strong>NIT</strong>
              <p>{nitData?.nit}</p>
            </Col>
            <Col md={2} className="d-flex">
              <strong>Contract ID</strong>
              <p>{nitData?.tender_id}</p>
            </Col>
            <Col md={2} className="d-flex">
              <strong>Tenure</strong>
              <p>
                {DaysBetweenDates(nitData?.startDate, nitData?.endDate)} days
              </p>
            </Col>
            {nitData?.sectionData?.name && (
              <Col md={2} className="d-flex">
                <strong>Section / Patch</strong>
                <p>
                  {" "}
                  {nitData?.sectionData?.name
                    ? capitalizeName(nitData?.sectionData?.name)
                    : "Not Applicable"}
                </p>
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default DashboardDetailCard;
