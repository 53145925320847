import React from "react";
import "./loader.scss";

const Loading = () => {
  return (
    <div className="loaderScreen" data-testid='test-loader'>
      <div className="loginLoader">
      </div>
    </div>
  );
};

export default Loading;
