import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import {
  SidebarData,
  permissibleBasedCollum,
  permissibleNoiseCollum,
  permissibleCMPCollum,
  permissibleCAACollum,
  EnvConfigcaaqmsPermissible,
  EnvConfigcmpdilPermissible,
  EnvConfigNoisePermissiblePermissible,
} from "../DATA";
import EnvConfigPermissibleView from "./EnvConfigPermissionView";
import filter from "../../../../Assets/Images/filter.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { tabUrl } from "../../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../../_helpers/helper";
import { permissionCheck } from "../../../../_services/Auth/helper";
import EmptyDataList from "../../../ContractorManagement/ContractorPerformance/EmptyDataList";
import {
  getAllEnvConfigPermissible,
  deleteEnvConfigStationApis,
} from "../../../../services/_reasonList/reasonList";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../../_services/CommonService/CommonService";
import toastr from "toastr";

const EnvConfigPermission = () => {
  const [getAllFilter, setGetAllFilter] = useState();
  const cref = useRef(null);
  const usrl = useLocation();
  let navigate = useNavigate();
  const segments = usrl.pathname.split("/");
  const lastSegment = segments[segments.length - 1];
  const words = lastSegment.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const title = capitalizedWords.join(" ");
  const [isListData, setIsListData] = useState(true);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.envConfig)
  );

  const onEdit = (id) => {
    navigate(
      `/environment-management/configuration/permissible/${lastSegment}/${id}`
    );
  };

  const getAll = async () => {
    const response = await getAllEnvConfigPermissible(
      "page=1&limit=2",
      lastSegment
    );
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  //   const [form, onChange, setForm] = useForm(PollutionControlFilterForm);
  const [filterStatus, setFilterStatus] = useState(0);

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    getAll();
  }, []);

  // const onSubmitFilter = () => setFilterStatus((prev) => prev + 1);

  //   useEffect(() => {
  //     if (filterStatus === 0) return;

  //     let filter = "";
  //     for (const key in form) {
  //       const element = form[key];
  //       if (element) {
  //         if (key === "startDate" || key === "endDate")
  //           filter += `&${key}=${element}`;
  //         else filter += `&search[${key}]=${element}`;
  //       }
  //     }

  //     setGetAllFilter(filter);
  //   }, [filterStatus]);

  let api;

  if (lastSegment === "CAAQMS_permissible") {
    api = EnvConfigcaaqmsPermissible;
  } else if (lastSegment === "CMPDIL_permissible") {
    api = EnvConfigcmpdilPermissible;
  } else if (lastSegment === "noise_permissible") {
    api = EnvConfigNoisePermissiblePermissible;
  }

  let column;

  if (lastSegment === "CAAQMS_permissible") {
    column = permissibleCAACollum;
  } else if (lastSegment === "CMPDIL_permissible") {
    column = permissibleCMPCollum;
  } else if (lastSegment === "noise_permissible") {
    column = permissibleNoiseCollum;
  }

  const viewAsPopup = (form) => {
    return <EnvConfigPermissibleView form={form} />;
  };

  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: 'envPermissibleMasters',
        id: id,
        status: status
      }
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const finalTabelColumns= useMemo(
    () => [
      ...column,
      {
        name: "Status",
        selector: "status",
        cell: (row) => {
          return (
            (row.status === 1 && (
              <span
                href="#"
                className="icons text-success pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOn
                  size="20"
                  data-testid={"toogle-user-list-on-" + row.id}
                />
              </span>
            )) ||
            (row.status === 0 && (
              <span
                href="#"
                className="icons text-danger pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOff
                  size="20"
                  data-testid={"toogle-user-list-off-" + row.id}
                />
              </span>
            ))
          );
        },
      },
    ],
    []
  );

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                apis={api}
                fields={finalTabelColumns}
                title="Data"
                tableTitle={title}
                viewAsPopup={viewAsPopup}
                pageLink={`/environment-management/configuration/permissible/${lastSegment}`}
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                cref={cref}
                hideSnColumn
                // FilterBtn={FilterBtn}
                getAllFilter={getAllFilter}
                hideAddButton={!permissionCheck(["super_admin", "env_admin"])}
                listname={"stationcategory"}
                customTableTitle={title}
                editFunButton={onEdit}
                isNoTabOnPage
                deleteFun
                breadCrumb={[
                  {
                    name: "Environment Management System",
                    path: "#",
                  },
                  {
                    path: "/environment-management/configuration",
                    name: "Configuration",
                  },
                  {
                    name: title,
                    path: "#",
                  },
                ]}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <EmptyDataList
            hideAddBtn={!permissionCheck(["super_admin", "env_admin"])}
            pageLink={`/environment-management/configuration/permissible/${lastSegment}`}
            title={title}
            breadCrumb={[
              {
                path: "#",
                name: "Environment Management System",
              },
              {
                name: "Configuration",
                path: "/environment-management/configuration",
              },
              {
                path: "#",
                name: title,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default EnvConfigPermission;
