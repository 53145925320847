import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router";
import MessagePopup from "../../../../components/MessagePopup/MessagePopup";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import {
  createMasterDropdown,
  updateMasterDropdown,
} from "../../../../services/_reasonList/reasonList";
import { RestMethod } from "../../../../_helpers/ApiConfig/RestMethod";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../DATA";

import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";

const SampleAgencyAddEdit = () => {
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [formFieldError, setFormFieldError] = useState({ name: "", type: "" });
  const [form, setForm] = useState({
    name: "",
    type: "",
  });
  const [tab, setTab] = useState(1);

  const navigate = useNavigate();
  const param = useParams();

  const getData = useCallback(async () => {
    const response = await RestMethod.GET("/master-data/" + param.id);
    setForm({ ...response?.data?.data });
  }, [param.id]);

  const tabFun = (n) => {
    setTab(n);
  };

  const validation = (row) => {
    if (row.name === "") {
      return { name: "Name is Required" };
    } else {
      return { name: "" };
    }
  };

  const onSubmit = async () => {
    let data = { type: "sampling_agency" };
    let response = "";
    data.name = form.name;
    setFormFieldError(validation(form));
    if (form.name === "") return;

    if (param.id) response = await updateMasterDropdown(data, param.id);
    else {
      response = await createMasterDropdown(data);
    }

    if (!response?.data?.status) {
      toastr.error(response?.data?.message);
    } else {
      setShowMessagePopUp(true);
      toastr.success("data saved successfully");
    }
  };

  const onChange = (e, idx) => {
    const { name, value } = e.target;
    formFieldError[name] = "";
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (!param.id) return;
    getData();
  }, [getData, param.id]);

  const closeMessagePopup = () => {
    setShowMessagePopUp(false);
    navigate("/coal-grade/configuration/sampling-agency");
  };

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              className="title"
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Coal grade Configuration",
                    path: "/",
                  },
                  {
                    name: "Configuration",
                    path: "/coal-grade/configuration",
                  },
                  {
                    name: param.id
                      ? "Edit Sampling Agency"
                      : "Add Sampling Agency",
                    path: "#",
                  },
                ]}
                // module={ACTIVE_MODULE.incident}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              {param.id ? "Edit" : "Add"} Sampling Agency
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <Form>
                  <Row>
                    <Col sm={6}>
                      <FormInputText
                        placeHolder="Enter here"
                        type="text"
                        label={"Sampling Agency Name"}
                        onChange={onChange}
                        error={formFieldError?.name}
                        value={form?.name}
                        name="name"
                      />
                    </Col>
                    {/* <Col sm={6}>
                  <FormInputText
                    type="text"
                    placeHolder="Enter here"
                    label={"Type"}
                    onChange={onChange}
                    name="type"
                    error={formFieldError?.type}
                    //   value={form?.type}
                  />
                </Col> */}
                  </Row>
                </Form>

                <div className="btnSec">
                  <Button
                  data-testid="secondaryBtn"
                    onClick={() =>
                      navigate("/coal-grade/configuration/sampling-agency")
                    }
                    className="secondaryBtn"
                  >
                    Back
                  </Button>
                  <div className="d-flex">
                    <Button
                      onClick={onSubmit}
                      className="primaryBtn"
                      tab={tab}
                      tabFun={tabFun}
                      // nextStapeFun={nextStapeFun}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            setShow={setShowMessagePopUp}
            messagePopupType={messagePopupType}
            show={showMessagePopUp}
            closePopup={closeMessagePopup}
          />
        </div>
      </div>
    </div>
  );
};

export default SampleAgencyAddEdit;
