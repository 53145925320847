import React from "react";
import ReactEcharts from "echarts-for-react";
import NoRecord from "./NoRecord";
import { isDecimal } from "../../_helpers/helper";

class MixedLineBarChart extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.seriesData !== this.props.seriesData) {
      this.forceUpdate();
    }
  }

  render() {
    const {
      category,
      lineData,
      barData,
      barData2,
      legendFlag,
      yAxisLeft,
      yAxisRight,
      lineName,
      barName,
      barName2,
      customToolTip,
    } = this.props;

    const echartOption = {
      tooltip: customToolTip
        ? {
            formatter: function (params, ticket, callback) {
              let string = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                params?.color
              };"></span>${params?.seriesName} : ${isDecimal(params?.value)}  ${
                params?.seriesName === "% Achvd" ? yAxisRight : yAxisLeft
              }<br>`;
              return "<span> " + string + "</span>";
            },
          }
        : {
            trigger: "axis",
          },
      legend:
        legendFlag === false
          ? false
          : {
              type: "scroll",
              itemGap: 20,
              to: 100,
            },
      xAxis: {
        type: "category",
        data: category ? category : [],
      },
      yAxis: [
        {
          type: "value",
          position: "right",
          name: yAxisRight,
        },
        {
          type: "value",
          position: "left",
          name: yAxisLeft,
        },
      ],
      series: [
        {
          name: lineName ? lineName : "Line",
          type: "line",
          yAxisIndex: 1, // Link this series to the left y-axis
          data: lineData ? lineData : [],
        },
        {
          name: barName ? barName : "Bar",
          type: "bar",
          yAxisIndex: 1, // Link this series to the right y-axis
          data: barData ? barData : [],
        },
        {
          name: barName2 ? barName2 : "Bar2",
          type: "bar",
          yAxisIndex: 1, // Link this series to the right y-axis
          data: barData2 ? barData2 : [],
        },
      ],
      dataZoom: [
        {
          start: 0,
          type: "inside",
        },
        {
          start: 0,
        },
      ],
    };

    if (this.props.isLegeng) {
      echartOption.legend = this.props.isLegeng;
    }
    if (this.props.grid) {
      echartOption.grid = this.props.grid;
    }

    return (
      <React.Fragment>
        {Array.isArray(lineData) && lineData.length > 0 ? (
          <>
            <ReactEcharts
              style={{
                height: this.props.height ? this.props.height : "270px",
                width: this.props.width ? this.props.width : "270px",
              }}
              option={echartOption}
            />
          </>
        ) : (
          <NoRecord />
        )}
      </React.Fragment>
    );
  }
}

export default MixedLineBarChart;
