import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import SampleRelatedForm from "./SampleRelatedForm";
import {
  SidebarData,
  allGrades,
  coalGradeApis,
  coalGrateTypeObj,
  submitStatus,
} from "../DATA";
import { useForm } from "../../../_helpers/hooks";
import LabQualityForm from "./LabQualityForm";
import { useNavigate, useParams } from "react-router-dom";
import { sampleDeodingRegister } from "../../../services/_coalGrade/coalGrade";
import {
  labQualityRules,
  sampleDecodingRegisterInitialForm,
  sampleRelatedDataRules,
} from "./DATA";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import { StepButton } from "../../../components/_button/StepButton";
import toastr from "toastr";
import { Validation } from "../../../_helpers/validation/Validation";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { employeeNameEmailLabel } from "../../../_helpers/helper";
import { getGValue } from "../../../_helpers/helper";
const SampleDecodingRegister = () => {
  const [tab, setTab] = useState(1);
  const [codeGivenToSampleList, setCodeGivenToSampleList] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState({});
  const [messagePopupType, setMessagePopupType] = useState(false);
  let navigate = useNavigate();
  const [form, onChange, setForm] = useForm({
    ...sampleDecodingRegisterInitialForm,
  });
  const [filterObj, setFilterObj] = useState(
    `type=${coalGrateTypeObj?.sampleDecoding}&search[sample_coding_status]=${submitStatus?.submitted}`
  );
  const params = useParams("id");

  const [gradeList, setGradeList] = useState(
    Object.keys(allGrades).map((key) => {
      return { value: key, label: key };
    })
  );

  const tabFun = (n) => {
    setTab(n);
  };

  const getAll = async (filter = "") => {
    filter += `&${filterObj}`;

    const response = await coalGradeApis.getAll.api(filter);
    return response?.data;
  };

  const handleSubmit = async (submitType) => {
    let errorObj = {};
    let data = {};

    if (submitType == submitStatus?.drafted) {
      data = { ...form, sample_decoding_status: submitStatus?.drafted };
    } else {
      data = { ...form, sample_decoding_status: submitStatus?.submitted };

      errorObj = Validation(form, labQualityRules);
      setError(errorObj);
    }

    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    const response = await sampleDeodingRegister(data);
    if (!response?.status) return toastr.error(response?.message);

    if (submitType == submitStatus?.drafted) {
      toastr.success(`Saved Successfully`);
    } else {
      setShow(true);
      setMessagePopupType(false);
    }
  };

  useEffect(() => {
    if (!form?.code_given_to_sample) return;

    setForm((prev) => {
      return { ...prev, id: form?.code_given_to_sample };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.code_given_to_sample]);

  function handleClick() {
    setShow(false);
    navigate("/coal-grade/sample-decoding/actions/list");
  }

  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };

  const nextStapeFun = () => {
    let errorObj = Validation(form, sampleRelatedDataRules);
    console.log("1211121212121212",errorObj);
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) return;
    setTab(2);
  };

  const assignCodeGivenToSampleList = async (value) => {
    let data = await getAll(
      `search[date_given_for_test]=${value}&status_filter=true`
    );

    let list = data
      .filter((row) => row?.sample_decoding_status != submitStatus?.submitted)
      .map((row) => {
        return { label: row.code_given_to_sample, value: row.id };
      });

    setCodeGivenToSampleList(list);
  };

  const onChangeCodeGivenToSample = async (e) => {
    await assignCodeGivenToSampleList(e?.target?.value);
  };

  useEffect(() => {
    (async () => {
      if (!params?.id) return;
      let data = await getAll(`search[id]=${params?.id}&status_filter=true`);

      if (data[0]?.sample_decoding_status == submitStatus?.submitted) {
        toastr.error("Unauthorized");
        navigate("/coal-grade/sample-decoding/actions/list");
      }

      data.map((row) => {
        setForm((prev) => {
          return { ...prev, ...row, code_given_to_sample: row.id };
        });
      });
      await assignCodeGivenToSampleList(data[0]?.date_given_for_test);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    let data = employeeNameEmailLabel();
    if (!params?.id)
      setForm((prev) => {
        return { ...prev, decoded_by: data?.nameEmail };
      });
  }, []);
  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        lab_analyzed_grade: String(getGValue(form?.lab_on_em_bases_gcv)),
      };
    });
  }, [form?.lab_on_em_bases_gcv]);
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            {/* <Container fluid> */}
            <h3
              className=""
              style={{ paddingTop: "5px", paddingBottom: "1px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Coal Grade",
                    path: "/",
                  },
                  {
                    path: "/coal-grade/list",
                    name: "Coal Quality",
                  },
                  {
                    name: "Sample Decoding",
                    path: "/coal-grade/sample-decoding/actions/list",
                  },
                  {
                    path: "#",
                    name: "Sample Decoding Register",
                  },
                ]}
              />
            </h3>
            <h3 className="title">Sample De-Coding Register</h3>
            <div className="creationCard">
              <div className="formStepperWrapper">
                <ul>
                  <li
                    className={`coalDispatchStep ${
                      tab === 1 ? "activeStep" : "doneStep"
                    }`}
                  >
                    <div className="stepIcon">
                      <span></span>{" "}
                    </div>
                    <label className="mt-3">
                      Sample De-Coding Related Data
                    </label>
                  </li>
                  <li
                    className={`sampleRelatedStep ${
                      tab === 2 ? "activeStep" : "initialStep"
                    }`}
                  >
                    <div className="stepIcon">
                      <span></span>{" "}
                    </div>
                    <label className="mt-3">CIL Lab Quality Results</label>
                  </li>
                </ul>
              </div>
              <div className="formWrapper">
                <div className="maverickFormCard">
                  {tab === 1 && (
                    <SampleRelatedForm
                      codeGivenToSampleList={codeGivenToSampleList}
                      onChangeCodeGivenToSample={onChangeCodeGivenToSample}
                      form={form}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                  {tab === 2 && (
                    <LabQualityForm
                      form={form}
                      data-testid="tabTwo"
                      onChange={onChange}
                      error={error}
                      setTab={tabFun}
                      gradeList={gradeList}
                    />
                  )}
                </div>
              </div>
              <div className="btnSec">
                <Button onClick={closeFun} className="secondaryBtn">
                  Close
                </Button>
                <div className="d-flex">
                  <StepButton
                    tab={tab}
                    data-testid="stepBtn"
                    tabFun={tabFun}
                    onSubmit={handleSubmit}
                    nextStapeFun={nextStapeFun}
                  />
                </div>
              </div>
            </div>
            {/* </Container> */}

            <MessagePopup
              messagePopupType={messagePopupType}
              show={show}
              setShow={setShow}
              closePopup={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleDecodingRegister;
