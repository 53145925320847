import linkImg1 from "../../Assets/Images/selectionIcon1.svg";
import {
  getAllUploadedFiles,
  getAllSapFiles,
} from "../../services/_uploadedFiles/uploadedFiles";
import { capitalizeName, fileExtension } from "../../_helpers/helper";
import { DDMMYYYYFormat } from "../../_helpers/dateHelper";
import linkImg2 from "../../Assets/Images/selectionIcon2.svg";
import linkImg3 from "../../Assets/Images/selectionIcon3.svg";
import {
  deleteSapUploadedData,
  getAllSapUploadedData,
} from "../../services/_uploadedFiles/uploadedFiles";
export const SidebarData = [
  {
    img: linkImg3,
    title: "Config",
    active: 0,
    activeUrl: "configuration",
    link: "/sap-management/configuration",
    role: ["sap_import"],
  },
];
export const EventFlowList = {
  "Coal Grade": [
    {
      img: linkImg1,
      label: "Upload SAP Data",
      link: "/coal-grade/configuration/sap-import/create",
      // role: ["contractor_dashboard"],
    },
    {
      img: linkImg2,
      label: "Uploaded SAP Data",
      link: "/coal-grade/configuration/sap-import/uploaded",
      // role: ["contractor_dashboard"],
    },
  ],
  "Contractor Management": [
    {
      img: linkImg1,
      label: "Upload SAP Data",
      link: "/contractor-management/configuration/sap-import/create",
      // role: ["contractor_dashboard"],
    },
    {
      img: linkImg2,
      label: "Uploaded SAP File",
      link: "/contractor-management/configuration/sap-import/uploaded",
      // role: ["contractor_dashboard"],
    },
    {
      img: linkImg2,
      label: "Uploaded SAP Data",
      link: "/contractor-management/configuration/sap-import/uploaded-data",
      // role: ["contractor_dashboard"],
    },
  ],
  "Fuel Management": [
    {
      img: linkImg1,
      label: "Upload SAP Data",
      link: "/fuel-management-system/configuration/sap-import/create",
      // role: ["contractor_dashboard"],
    },
    {
      img: linkImg2,
      label: "Uploaded SAP Data",
      link: "/fuel-management-system/configuration/sap-import/uploaded",
      // role: ["contractor_dashboard"],
    },
  ],
  "HEMM Data": [
    {
      img: linkImg1,
      label: "Upload HEMM / Bowser File",
      link: "/fuel-management-system/configuration/hemm-data/importExcelFile/create",
      // role: ["contractor_dashboard"],
    },
    {
      img: linkImg2,
      label: "Uploaded HEMM / Bowser File",
      link: "/fuel-management-system/configuration/hemm-data/importExcelFile/uploaded",
      // role: ["contractor_dashboard"],
    },
  ],
};
export const reportTypeList = {
  "Coal Grade": [
    {
      label: "Road",
      value: "SHRO",
    },
    {
      label: "Rail",
      value: "SHRA",
    },
    {
      label: "Merry Go Round",
      value: "SHMG",
    },
  ],
};
export const subModule = [
  {
    id: "contractor_hindrance",
    name: "Contractor Hindrance",
  },
  {
    id: "contractor_performance",
    name: "Contractor Performance",
  },
];
export const fileTypeOpt = [
  {
    id: "contractor_hindrance",
    name: "Contractor Hindrance SAP Data",
  },
  // {
  //   id: "contractor_performance",
  //   name: "Contractor SAP Data",
  // },
];

export let fileTypeFuelOpt = [
  {
    id: "fuel_inventory_sap_data",
    name: "Fuel Inventory SAP Data",
  },
  {
    id: "fuel_receipt_sap_datas",
    name: "Fuel Receipt SAP Data",
  },
  {
    id: "sap_fuel_dispense",
    name: "Fuel Dispense SAP Data",
  },
  {
    id: "fuel_sensor_sap_data",
    name: "Fuel Sensor SAP Data",
  },
  {
    id: "hemm_datas",
    name: "HEMM DATA",
  },
  // {
  //   id: "",
  //   name: "SAP Dispense",
  // },
];
export const extensionsAllowed = ["xlsx", "xls"];
export const airQualityFormLabel = {
  SHRA: {
    sample_no: "Sample No",
    mode_of_dispatch: "Mode of dispatch",
    subsidiary: "Subsidiary",
    area: "Area",
    plant: "plant",
    siding: "Siding",
    rake_no: "Rake no",
    sales_order: "Sales order",
    rr_no: "RR no",
    fnote_dnote_no: "Fnote Dnote no",
    fnote_dnote_date: "Fnote Dnote date",
    rr_date: "RR date",
    material: "Material",
    material_description: "Material description",
    agency: "Agency",
    buyer_code: "Buyer code",
    buyer_name: "Buyer name",
    delivery_no: "Delivery no",
    invoice: "Invoice",
    invoice_line_item: "Invoice line item",
    invoice_date: "Invoice date",
    bill_quantity: "Bill quantity",
    collection_date: "Collection date",
    preparation_date: "Prepration date",
    tpa_result_submission_date: "Tpa result submission date",
    ref_result_submission_date: "Ref result submission date",
    declared_grade: "Declare Grade",
  },
  SHRO: {
    mode_of_dispatch: "Mode of dispatch",
    sample_no: "Sample No",
    area: "Area",
    subsidiary: "Subsidiary",
    siding: "Siding",
    declared_grade: "Declare Grade",
    plant: "Plant",
    rake_no: "Rake no",
    rr_no: "RR no",
    sales_order: "Sales order",
    rr_date: "RR date",
    material_description: "Material description",
    material: "Material",
    agency: "Agency",
    buyer_name: "Buyer name",
    buyer_code: "Buyer code",
    delivery_no: "Delivery no",
    invoice_line_item: "Invoice line item",
    invoice: "Invoice",
    invoice_date: "Invoice date",
    collection_date: "Collection date",
    bill_quantity: "Bill quantity",
    preparation_date: "Prepration date",
    ref_result_submission_date: "Ref result submission date",
    tpa_result_submission_date: "Tpa result submission date",
  },
  SHMG: {
    sample_no: "Sample No",
    mode_of_dispatch: "Mode of dispatch",
    subsidiary: "Subsidiary",
    area: "Area",
    plant: "Plant",
    siding: "Siding",
    rake_no: "Rake no",
    sales_order: "Sales order",
    rr_no: "RR no",
    rr_date: "RR date",
    material: "Material",
    material_description: "Material description",
    agency: "Agency",
    buyer_code: "Buyer code",
    buyer_name: "Buyer name",
    delivery_no: "Delivery no",
    invoice: "Invoice",
    invoice_line_item: "Invoice line item",
    invoice_date: "Invoice date",
    bill_quantity: "Bill quantity",
    collection_date: "Collection date",
    preparation_date: "Prepration date",
    tpa_result_submission_date: "Tpa result submission date",
    ref_result_submission_date: "Ref result submission date",
    declared_grade: "Declare Grade",
  },
  contractor_hindrance: {
    tender_id: "Tender Id",
    target: "Target",
    actual: "Actual",
    input_date: "Date",
  },
  hemm_datas: {
    hemm_id: "Hemm Id",
    isBowser: "Is Bowser",
    site_id: "Mine",
    machine_serial: "Machine Serial",
    fms_vehicle_type: "FMS Vehicle Type",
    make: "Make",
    capacity: "Tank Capacity",
    loadCapacity: "Load Capacity",
    model: "Model",
    fms_vehicle_name: "FMS Vehicle Name",
  },
  fuel_inventory_sap_data: {
    fuel_location_code: "Fuel Location Code",
    closing_stock: "Closing Stock",
    dip: "DIP",
    opening_stock: "Opening Stock",
    inventory_date: "Inventory Date",
  },
  fuel_sensor_sap_data: {
    hemm_id: "Hemm Id",
    date: "Date",
    time: "Time",
    fuel_level: "Fuel Level",
  },
  fuel_receipt_sap_datas: {
    fuel_location_code: "fuel Location Code",
    receipt_quantity: "Receipt Quantity",
    receipt_date: "Receipt Date",
    supplier: "Supplier",
  },
  contractor_performance: {
    date: "Date",
    nit_id: "NIT Id",
    materialType: "Material Type",
    shift_tire: "Shift Tire",
    plan: "Plan",
    actual: "Actual",
  },
  fuel_dispense_sap_datas: {
    subsidiary: "Subsidiary",
    mine: "Mine",
    is_person_gps_mandatory: "Is gps mandatory",
    person_latitude: "Person latitude",
    person_longitude: "Person longitude",
    fuel_location_id: "Fuel location code",
    hemm_id: "Hemm code",
    hemm_fms_verify: "Hemm fms verify",
    fms_vehicle_type: "Fms vehicle type",
    fms_vehicle_name: "Fms vehicle name",
    fms_hemm_latitude: "Fms hemm latitude",
    fms_hemm_longitude: "Fms hemm longitude",
    fms_hmr_reading: "Fms hmr reading",
    fms_odometer_reading: "Fms odometer reading",
    user_entered_hmr_reading: "User entered hmr reading",
    user_entered_odometer_reading: "User entered odometer reading",
    fuel_meter: "Fuel meter before",
    fuel_meter_after: "Fuel meter after",
    latitude_captured_date: "Latitude captured date",
    fuel_dispensing_qty: "Fuel dispensing qty",
    last_user_odometer_reading: "Last user odometer reading",
    last_fueling_date: "Last fueling date",
  },
};
export const formLabel = {
  "Ambient-Air-Quality-CAAQMS": {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_category: "Station Category",
    air_quality_date: "Date",
    pm10: "PM 10 - Concentration",
    pm25: "PM 2.5 - Concentration",
    so2: "SO2 - Concentration",
    no: "NO - Concentration",
    nox: "NOx - Concentration",
    no2: "NO2 - Concentration",
    co: "CO - Concentration",
  },
};
export const reportTypeListUrl = {
  Ambient_Air_Quality_CAAQMS: "Ambient-Air-Quality-CAAQMS",
  Ambient_Air_Quality_CMPDIL: "Ambient-Air-Quality-CMPDIL",
  Ground_Water_level_CMPDIL: "Ground-Water-level-CMPDIL",
  Land_Reclamation_Status_CMPD: "Land-Reclamation-Status-CMPD",
  micro_meteorological_CAAQMS: "Micro-Meteorological-CAAQMS",
  Noise_Level_CMPDIL: "Noise-Level-CMPDIL",
  Plantation_Project: "Plantation-Project",
  Water_Quality_CMPDIL: "Water-Quality-CMPDIL",
  performance_report_of_pollution: "Performance-Report-Of-Pollution",
  power_consumption_report: "Power-Consumption-Report",
};
export const breadCrumb = (route) => {
  if (
    route === "/coal-grade/configuration/sap-import/uploaded" || //list sap page link
    route === "/coal-grade/configuration/sap-import/create" || //create sap page link
    route === "/coal-grade/configuration/sap-import" //sap page upload or uploaded list link
  )
    return [
      "Coal Grade", // module name
      "/coal-grade/configuration", //module config name
      "/coal-grade/configuration/sap-import", //sap module link according to module
      "/coal-grade/configuration/sap-import/uploaded",
      "&fileType=SHRO&fileType=SHRA&fileType=SHMG",
    ];
  if (
    route === "/contractor-management/configuration/sap-import/create" ||
    route === "/contractor-management/configuration/sap-import/uploaded" ||
    route === "/contractor-management/configuration/sap-import"
  )
    return [
      "Contractor Management", // module name
      "/contractor-management/configuration", //module config name
      "/contractor-management/configuration/sap-import", //sap module link according to module
      "/contractor-management/configuration/sap-import/uploaded",
      "&fileType=contractor_hindrance&fileType=contractor_performance",
    ];
  if (
    route === "/fuel-management-system/configuration/sap-import/create" ||
    route === "/fuel-management-system/configuration/sap-import/uploaded" ||
    route === "/fuel-management-system/configuration/sap-import"
  )
    return [
      "Fuel Management", // module name
      "/fuel-management-system/configuration", //module config name
      "/fuel-management-system/configuration/sap-import", //sap module link according to module
      "/fuel-management-system/configuration/sap-import",
      "&fileType=fuel_inventory_sap_data&fileType=fuel_receipt_sap_datas&fileType=fuel_dispense_sap_datas&fileType=sap_fuel_dispense&fileType=fuel_sensor_sap_data",
    ];

  if (
    route ===
      "/fuel-management-system/configuration/hemm-data/importExcelFile" ||
    "/fuel-management-system/configuration/hemm-data/importExcelFile/create"
  )
    return [
      "HEMM Data", // module name
      "/fuel-management-system/configuration", //module config name
      "/fuel-management-system/configuration/hemm-data/importExcelFile", //sap module link according to module
      "/fuel-management-system/configuration/hemm-data/importExcelFile",
      "&fileType=hemm_datas",
    ];
};

export const sapDataFieldsMine = [
  {
    name: "Subsidiary",
    selector: "name",
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "name",
    cell: (row) => row?.site?.name,
  },
  {
    name: "Date",
    sortable: true,
    selector: "createdAt",
    cell: (row) => (row?.createdAt ? DDMMYYYYFormat(row.createdAt) : ""),
  },
  {
    name: "Report Type",
    sortable: true,
    selector: "file_type",
    cell: (row) => (row?.file_type ? modeOfDispatchLabel[row?.file_type] : ""),
  },
  {
    name: "Min Collection Date",
    center: true,
    selector: "collection_date",
    cell: (row) =>
      row?.sapDatamax[0]?.collection_date
        ? DDMMYYYYFormat(row?.sapDatamax[0]?.collection_date)
        : "",
  },
  {
    name: "Max Collection Date",
    center: true,
    selector: "collection_date",
    cell: (row) =>
      row?.sapDatamin[0]?.collection_date
        ? DDMMYYYYFormat(row?.sapDatamin[0]?.collection_date)
        : "",
  },
  {
    name: "File Type",
    sortable: false,
    selector: "org_filename",
    cell: (row) => (row?.org_filename ? fileExtension(row.org_filename) : ""),
  },
  {
    name: "Status",
    sortable: false,
    selector: "file_status",
    cell: (row) => (
      <>
        <span className="statusSec draft">
          {row?.file_status ? capitalizeName(row.file_status) : ""}
        </span>
      </>
    ),
  },
];
export const sapDataFields = [
  // {
  //   name: "Subsidiary",
  //   selector: "name",
  //   cell: (row) => row?.site?.parentSite?.name,
  // },
  // {
  //   name: "Mine",
  //   selector: "name",
  //   cell: (row) => row?.site?.name,
  // },
  {
    name: "Report Type",
    sortable: true,
    selector: "file_type",
    cell: (row) => (row?.file_type ? modeOfDispatchLabel[row?.file_type] : ""),
  },
  {
    name: "Date",
    sortable: true,
    selector: "createdAt",
    cell: (row) => (row?.createdAt ? DDMMYYYYFormat(row.createdAt) : ""),
  },
  {
    name: "File Type",
    sortable: false,
    selector: "org_filename",
    cell: (row) => (row?.org_filename ? fileExtension(row.org_filename) : ""),
  },
  {
    name: "Status",
    sortable: false,
    selector: "file_status",
    cell: (row) => (
      <>
        <span className="statusSec draft">
          {row?.file_status ? capitalizeName(row.file_status) : ""}
        </span>
      </>
    ),
  },
];

export const uploadedSapDataApis = Object.freeze({
  getAll: {
    api: getAllSapFiles,
  },
  delete: {
    api: deleteSapUploadedData,
  },
});

export const initialFilters = {
  startDate: "",
  endDate: "",
  file_type: "",
};

export const requiredColumn = {
  SHRA: [
    "mode_of_dispatch",
    "fnote_dnote_date",
    "collection_date",
    "declared_grade",
    "fnote_dnote_no",
    "bill_quantity",
    "sales_order",
    "delivery_no",
    "subsidiary",
    "buyer_code",
    "buyer_name",
    "material",
    "invoice",
    "rr_date",
    "rr_no",
    "plant",
  ],
  SHRO: [
    "mode_of_dispatch",
    "collection_date",
    "declared_grade",
    "bill_quantity",
    "sales_order",
    "delivery_no",
    "subsidiary",
    "buyer_code",
    "buyer_name",
    "material",
    "invoice",
    "plant",
  ],
  SHMG: [
    "mode_of_dispatch",
    "collection_date",
    "declared_grade",
    "bill_quantity",
    "sales_order",
    "delivery_no",
    "subsidiary",
    "buyer_code",
    "buyer_name",
    "material",
    "invoice",
    "rr_no",
    "plant",
  ],
  contractor_hindrance: ["tender_id", "target", "actual", "input_date"],
  fuel_inventory_sap_data: [
    "fuel_location_code",
    "closing_stock",
    "dip",
    "opening_stock",
    "inventory_date",
  ],
  fuel_sensor_sap_data: ["hemm_id", "date", "time", "fuel_level"],
  fuel_receipt_sap_datas: [
    "fuel_location_code",
    "receipt_quantity",
    "receipt_date",
    "supplier",
  ],
  contractor_performance: [
    "date",
    "nit_id",
    "materialType",
    "shift_tire",
    "plan",
    "actual",
  ],
  fuel_dispense_sap_datas: [
    "subsidiary",
    "mine",
    "is_person_gps_mandatory",
    "person_latitude",
    "person_longitude",
    "fuel_location_id",
    "hemm_id",
    "hemm_fms_verify",
    "fms_vehicle_type",
    "fms_vehicle_name",
    "fms_hemm_latitude",
    "fms_hemm_longitude",
    "fms_hmr_reading",
    "fms_odometer_reading",
    "user_entered_hmr_reading",
    "user_entered_odometer_reading",
    "fuel_meter",
    "fuel_meter_after",
    "latitude_captured_date",
    "fuel_dispensing_qty",
    "last_user_odometer_reading",
    "last_fueling_date",
  ],
  hemm_datas: [
    "hemm_id",
    "isBowser",
    "site_id",
    "fms_vehicle_type",
    "make",
    // "capacity",
    "model",
    "fms_vehicle_name",
  ],
};

export const validateFile = (fileData, fileType) => {
  const requiredColumns = requiredColumn[fileType];
  let invalid = false;
  let index = "";
  let exist = false;
  let invalidColumn = "";
  for (let i = 0; i < fileData.length; i++) {
    const d = fileData[i];
    for (const column of requiredColumns) {
      invalidColumn = column;
      if (d[column] === "") {
        invalid = true;
        index = i;
        exist = true;
        break;
      }
    }
    if (exist) break;
  }
  return invalid
    ? `Data required for row ${index + 1} for ${
        airQualityFormLabel[fileType][invalidColumn]
      } column`
    : false;
};
export const modeOfDispatchLabel = {
  SHRA: "Rail",
  SHRO: "Road",
  SHMG: "Merry Go Round",
  contractor_performance: "Contractor Performance",
  contractor_hindrance: "Contractor Hindrance",
  fuel_receipt_sap_datas: "Fuel Receipt",
  fuel_inventory_sap_data: "Fuel Inventory",
  fuel_sensor_sap_data: "Fuel Sensor",
  fuel_dispense_sap_datas: "Fuel Dispense",
  hemm_datas: "HEMM",
  sap_fuel_dispense: "Fuel Dispense SAP Data",
};

export const sapFormObj = {
  fileType: "",
  mine: "",
  site_id: "",
};

export const contractorSapDataApis = Object.freeze({
  getAll: {
    api: getAllSapFiles,
  },
  delete: {
    api: deleteSapUploadedData,
  },
});
