import React from "react";
import { BsFileEarmarkArrowDown } from "react-icons/bs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaRegFilePdf } from "react-icons/fa";

class FilesExport extends React.Component {
  state = {
    exporting: false,
  };

  exportJpg = async () => {
    const { id, infoDivId, global } = this.props;

    const chartDiv = document.querySelector(id);
    const infoDiv = document.getElementById(infoDivId)?.cloneNode(true);
    const chartInnerDiv = !global
      ? chartDiv?.querySelector(".card-body")
      : chartDiv;

    if (infoDiv) {
      infoDiv.classList.remove("d-none");
      chartInnerDiv.insertAdjacentElement("afterbegin", infoDiv);
    }
    const canvasOptions = !this.props?.fullScreen
      ? {
          scrollY: -window.scrollY + 100,
          useCORS: true,
          allowTaint: true,
          height: chartDiv?.scrollHeight + chartDiv?.scrollHeight / 2,
          width: chartDiv?.scrollWidth + chartDiv?.scrollWidth / 7,
          removeContainer: true,
        }
      : {
          scrollY: -window.scrollY,
          useCORS: true,
          allowTaint: true,
          height: chartDiv?.scrollHeight + chartDiv?.scrollHeight / 16,
          width: chartDiv?.scrollWidth + chartDiv?.scrollWidth / 7,
          removeContainer: true,
        };

    if (global) {
      this.props?.setExportProcessing(true);
      canvasOptions.x = chartDiv?.scrollWidth / 10;
      canvasOptions.y = chartDiv?.scrollWidth;
    }
    this.setState({ exporting: true });
    try {
      const canvas = await html2canvas(chartDiv, canvasOptions);
      var a = document.createElement("a");
      a.href = canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      a.download = new Date().toLocaleString() + ".jpg";
      a.click();

      if (infoDiv) chartInnerDiv.removeChild(infoDiv);
      if (global) this.props.setExportProcessing(false);
    } catch (error) {
      console.error("Error exporting JPG:", error);
    } finally {
      this.setState({ exporting: false });
    }

    // this.setState({ exporting: true }, () => {
    //   html2canvas(chartDiv, canvasOptions).then((canvas) => {
    //     var a = document.createElement("a");

    //     a.href = canvas
    //       .toDataURL("image/jpeg")
    //       .replace("image/jpeg", "image/octet-stream");
    //     a.download = new Date().toLocaleString() + ".jpg";
    //     a.click();
    //     this.setState({ exporting: false });
    //     if (infoDiv) chartInnerDiv.removeChild(infoDiv);
    //     if (global) this.props.setExportProcessing(false);
    //   });
    // });
  };

  printDocumentPdf = async () => {
    const { id, infoDivId, global } = this.props;
    const svgElements = document.querySelector(id)?.querySelectorAll("svg");
    svgElements?.forEach(function (item) {
      item.setAttribute("font-size", "200px");
    });
    const chartDiv = document.querySelector(id);
    const infoDiv = document.getElementById(infoDivId)?.cloneNode(true);

    const chartInnerDiv = !global
      ? chartDiv?.querySelector(".card-body")
      : chartDiv;

    if (infoDiv) {
      infoDiv.classList.remove("d-none");
      chartInnerDiv.insertAdjacentElement("afterbegin", infoDiv);
    }

    // Extra Width & Height to prevent Blackish effect.
    const canvasOptions = {
      scrollY: -window.scrollY,
      useCORS: true,
      allowTaint: true,
      height: 1000,
      width: 1800,
      removeContainer: true,
      pdfScale: 1,
    };

    if (global) {
      this.props.setExportProcessing(true);
      // canvasOptions.x = 55;
    }
    this.setState({ exporting: true });
    try {
      const canvas = await html2canvas(chartDiv, canvasOptions);

      const { width, height } = canvas;
      const orientation = "portrait";

      // KEEP WIDTH AND HEIGHT SIZES IN PIXELS (PX);
      const a4Width = 500.276; // A4 paper width in pixels at 72 DPI
      const a4Height = 800.89; // A4 paper height in pixels at 72 DPI

      const imgData = canvas.toDataURL("image/jpeg", 100);

      let imageHeight = height;
      let imageWidth = width;

      const a4HeightView = a4Height;
      const a4WidthView = a4Width;

      if (imageHeight < a4Height && imageWidth < a4Width) {
        imageWidth = a4WidthView;
        imageHeight = imageHeight * (imageWidth / width);
      }

      if (imageWidth > a4WidthView) {
        imageWidth = a4WidthView;
        imageHeight = height * (imageWidth / width);
      }

      if (imageHeight > a4HeightView) {
        imageHeight = a4HeightView;
        imageWidth = width * (imageHeight / height);
      }

      // X and Y according to Width and Height of Image & PDF
      const x = 10;
      const y = 10;

      const pdf = new jsPDF(orientation, "px", [a4Width, a4Height]);
      pdf.addImage(imgData, "JPEG", x, y, imageWidth, imageHeight);
      pdf.save(new Date().toLocaleString() + ".pdf");

      if (infoDiv) chartInnerDiv.removeChild(infoDiv);
    } catch (error) {
      console.error("Error exporting JPG:", error);
    } finally {
      this.setState({ exporting: false });
      if (global) this.props.setExportProcessing(false);
    }

    // this.setState({ exporting: true }, () => {
    //   html2canvas(chartDiv, canvasOptions).then((canvas) => {
    //     const { width, height } = canvas;
    //     const orientation = "portrait";

    //     // KEEP WIDTH AND HEIGHT SIZES IN PIXELS (PX);
    //     const a4Width = 500.276; // A4 paper width in pixels at 72 DPI
    //     const a4Height = 800.89; // A4 paper height in pixels at 72 DPI

    //     const imgData = canvas.toDataURL("image/jpeg", 100);

    //     let imageHeight = height;
    //     let imageWidth = width;

    //     const a4HeightView = a4Height;
    //     const a4WidthView = a4Width;

    //     if (imageHeight < a4Height && imageWidth < a4Width) {
    //       imageWidth = a4WidthView;
    //       imageHeight = imageHeight * (imageWidth / width);
    //     }

    //     if (imageWidth > a4WidthView) {
    //       imageWidth = a4WidthView;
    //       imageHeight = height * (imageWidth / width);
    //     }

    //     if (imageHeight > a4HeightView) {
    //       imageHeight = a4HeightView;
    //       imageWidth = width * (imageHeight / height);
    //     }

    //     // X and Y according to Width and Height of Image & PDF
    //     const x = 10;
    //     const y = 10;

    //     const pdf = new jsPDF(orientation, "px", [a4Width, a4Height]);
    //     pdf.addImage(imgData, "JPEG", x, y, imageWidth, imageHeight);
    //     pdf.save(new Date().toLocaleString() + ".pdf");

    //     this.setState({ exporting: false });
    //     if (global) this.props.setExportProcessing(false);
    //     if (infoDiv) chartInnerDiv.removeChild(infoDiv);
    //   });
    // });
  };

  render() {
    return (
      <div
        className={
          this.state.exporting || this.props.processing ? "d-none" : "d-flex"
        }
      >
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-topeee">Download Image</Tooltip>}
        >
          <button
            onClick={this.exportJpg}
            style={{ background: "transparent", border: "0" }}
            className="btn btn-icon p-0 exportJpgBtn"
          >
            <BsFileEarmarkArrowDown color="#00b5ef" size="25" />
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-topeee">Download Pdf</Tooltip>}
        >
          <button
            style={{ background: "transparent", border: "0" }}
            className="btn btn-icon p-0"
            onClick={this.printDocumentPdf}
          >
            <FaRegFilePdf color="#00b5ef" size="25" />
          </button>
        </OverlayTrigger>
      </div>
    );
  }
}

export default FilesExport;
