import jwtAuthService from "../../services/jwtAuthService";
import {
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  RESET_PASSWORD,
  USER_PERMISSIONS,
} from "../reducers/CONSTANT";
import {
  setContractorDashFilter,
  setFuelDashFilter,
  setUserData,
} from "./UserActions";
import configureStore from "../../redux/Store";
let { Store } = configureStore();

export function loginWithEmailAndPassword({ email, password, isMobile }) {
  return (dispatch) => {
    jwtAuthService
      .loginWithEmailAndPassword(email, password, isMobile)
      .then((user) => {
        const permissionObj =
          user && user?.permissions ? JSON.parse(user?.permissions) : {};

        Store.dispatch({
          type: USER_PERMISSIONS,
          data: permissionObj,
        });
        dispatch(setUserData(user));
        dispatch(setContractorDashFilter({}));
        dispatch(setFuelDashFilter({}));
        dispatch({
          type: LOGIN_LOADING,
        });
        return dispatch({
          type: LOGIN_SUCCESS,
          payload: user,
        });
      })
      .catch((error) => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  };
}

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    });
  };
}
