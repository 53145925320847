import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import Select from "../../../../../../components/MobileComponents/Select/Select";
import ReportButton from "../../ReportButton/ReportButton";
import { CustomSwitch } from "../../../../../../components/CustomSwitch/CustomSwitch";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Asterik from "../../../../../../components/Common/Asterik/Asterik";

const VehicleDetailsTab = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  vehicleDetails,
  vehicleDetailsErrors,
  onChangeVehicleDetails,
  deleteRowVehicleDetails,
  addRowVehicleDetails,
  tab,
  asterikRules,
}) => {
  return (
    <>
      <div className="mobileFormWrapper">
        <h3>Vehicle Details</h3>
        <div className="ehsFormWrapper p-0">
          <div className="customSwitchSec">
            <CustomSwitch
              name="isVehicleIncident"
              value={
                form.isVehicleIncident === null ? false : form.isVehicleIncident
              }
              onChange={onChange}
              title="Vehicles Involved"
            />
          </div>
          <p className="text-danger">{error?.isVehicleIncident}</p>
          <Form>
            <Row>
              {form.isVehicleIncident && (
                <Col md="12">
                  {vehicleDetails.length ? (
                    vehicleDetails.map((x, index) => {
                      return x.deleted ? null : (
                        <div className="addDataformCards" key={index}>
                          <div className="formFields mb-3">
                            <label>
                              Vehicle No.{" "}
                              <Asterik
                                fieldName={"number"}
                                requiredRules={asterikRules}
                              />
                            </label>
                            <input
                              value={x.number}
                              onChange={({ target: { name, value } }) =>
                                onChangeVehicleDetails(index, name, value)
                              }
                              name="number"
                              type="text"
                              className="form-control"
                            />
                            <span className="small text-danger">
                              {vehicleDetailsErrors[index]?.number}
                            </span>
                          </div>
                          <div className="formFields">
                            <Select
                              label={"Vehicle Type"}
                              value={x.type}
                              onChange={({ target: { name, value } }) =>
                                onChangeVehicleDetails(index, name, value)
                              }
                              name="type"
                              // className="form-control"
                              options={[
                                "LMV",
                                "Buyers Truck",
                                "Dumper",
                                "Disel Tanker",
                                "Troller",
                                "Crane",
                                "Tractor",
                                "Two-wheeler",
                                "Truck",
                                "Explosive Van",
                              ]}
                              init="none"
                              array="single"
                            ></Select>
                            <span className="small text-danger">
                              {vehicleDetailsErrors[index]?.type}
                            </span>
                          </div>
                          <div className="formFields">
                            <Select
                              label={"Vehicle Owner"}
                              value={x.owner}
                              onChange={({ target: { name, value } }) =>
                                onChangeVehicleDetails(index, name, value)
                              }
                              name="owner"
                              // className="form-control"
                              options={["Company", "Contractor", "Others"]}
                              init="none"
                              array="single"
                            ></Select>
                          </div>
                          <div className="formFields mb-3">
                            <label>
                              Driver Name{" "}
                              <Asterik
                                fieldName={"driverName"}
                                requiredRules={asterikRules}
                              />
                            </label>
                            <input
                              value={x.driverName}
                              onChange={({ target: { name, value } }) =>
                                onChangeVehicleDetails(index, name, value)
                              }
                              name="driverName"
                              type="text"
                              className="form-control"
                            />
                            <span className="small text-danger">
                              {vehicleDetailsErrors[index]?.driverName}
                            </span>
                          </div>
                          <div className="formFields mb-3">
                            <label>
                              Observation{" "}
                              <Asterik
                                fieldName={"observation"}
                                requiredRules={asterikRules}
                              />
                            </label>
                            <input
                              value={x.observation}
                              onChange={({ target: { name, value } }) =>
                                onChangeVehicleDetails(index, name, value)
                              }
                              name="observation"
                              type="text"
                              className="form-control"
                            />
                            <span className="small text-danger">
                              {vehicleDetailsErrors[index]?.observation}
                            </span>
                          </div>
                          <div className="formFields">
                            <Select
                              label={"Insurance Status"}
                              value={x.insuranceStatus}
                              onChange={({ target: { name, value } }) =>
                                onChangeVehicleDetails(index, name, value)
                              }
                              name="insuranceStatus"
                              // className="form-control"
                              options={[
                                "Insured",
                                "Not Insured",
                                "Not Applicable",
                                "Not Known",
                              ]}
                              init="none"
                              array="single"
                            ></Select>
                          </div>
                          <div className="actionIconsGroup">
                            <span
                              data-testid={"remove-leaf" + index}
                              onClick={() => deleteRowVehicleDetails(index)}
                              className="icons font-21 text-danger"
                            >
                              <IoIosCloseCircleOutline
                                size="26"
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                  <div className="addMoreBtn">
                    <span
                      className="addTxt"
                      onClick={addRowVehicleDetails}
                      data-testid="add-leaf"
                    >
                      <AiOutlinePlus />
                      Add
                    </span>
                  </div>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </div>
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default VehicleDetailsTab;
