import React, { useCallback, useEffect, useState } from "react";
import { multipleBarChartDataFormat } from "../../../components/Chart/Common/config";
import { FaAngleDown } from "react-icons/fa";
import { SimpleBarChart } from "../../../components/Common/SimpleBarChart";
import { getAllContractorByNit } from "../../../services/_contractorPerformance/contractorPerformance";
import { useForm } from "../../../_helpers/hooks";
import { DaysBetweenDates } from "../../../_helpers/helper";
import { isDecimal } from "../../../_helpers/helper";
import {
  Col,
  Container,
  Form,
  Row,
  Card,
  Table,
  FormGroup,
  Button,
  Modal,
} from "react-bootstrap";
import {
  basicBarChartWithCustomTooltipDataFormat,
  mixLineBarDataFormat,
} from "../../../components/Chart/Common/config";
import { BasicBarChartCardWithCustomToolTip } from "../../../components/ChartCard/ChartCard";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { Link, Navigate, useNavigate } from "react-router-dom";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import {
  getContractorById,
  getNitList,
  getNitById,
} from "../../../services/_contractorPerformance/contractorPerformance";
import MixedLineBarChart from "../../../components/Common/MixBarChart";
import { getAllSites } from "../../../services/_uploadedFiles/uploadedFiles";
import { getPerformanceProductionDashboard } from "../../../services/_contractorPerformance/contractorPerformance";
import {
  formFieldDataError,
  contractorPerformanceScoreDashboardForm,
} from "./DATA";
import Select from "../../../components/Select/Select";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import PleaseSelect from "../../../components/Common/PleaseSelect";
import NoRecord from "../../../components/Common/NoRecord";
import PieChartWithModal from "../../../components/PieChartWithModal/PieChartWithModal";
import { pieChartDataWithModalForTotalVs } from "../../../components/Chart/Common/config";
import FilesExport from "../../../components/Common/FilesExport";
import { SelectedDateLable } from "../../SafetyManagementSystem/IncidentManagement/Dashboard/DATA";
import { sections } from "../../ContractorManagement/ContractorPerformance/DATA";
import { capitalizeName } from "../../../_helpers/helper";
import { getDashboardDataNit } from "../../../services/_contractorPerformance/contractorPerformance";
import { permissionCheck } from "../../../_services/Auth/helper";
import { getAllPoList } from "../../../services/_poList/poList";
import DashboardDetailCard from "./DashboardDetailCard";
import { useDispatch, useSelector } from "react-redux";
import { setContractorDashFilter } from "../../../redux/actions/UserActions";

const ContractorPerformanceSapDashboard = () => {
  const [allMineListDrop, setAllMineListDrop] = useState({});
  const [allsiteListDrop, setAllSiteListDrop] = useState({});
  const [form, onChange, setForm] = useForm({
    ...contractorPerformanceScoreDashboardForm,
  });
  const [sites, setSites] = useState([]);
  const [productionType, setProductionType] = useState("");
  const [poList, setPoList] = useState([]);
  const [contractorData, setContractorData] = useState("");
  const [pichart, setPichart] = useState({});
  const [nitList, setNitList] = useState([]);
  const [nitData, setNitData] = useState();
  const [allNitList, setallNitList] = useState([]);
  const [show, setShow] = useState(false);
  const [dashboard, setDashboard] = useState({});
  const [chartData, setChartData] = useState({});
  const [applyFilter, setApplyFilter] = useState(false);
  const [contractorList, setContractorList] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const [allowedTypes, setAllowedTypes] = useState([]);
  const [minMaxDate, setMinMaxDate] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [achievedTillDate, setAchievedTillDate] = useState({});
  const [storeFlag, setStoreFlag] = useState(true);

  const storeFilter = useSelector(
    (state) => state?.dashboardFilter?.contractorDash
  );

  const getAllNitList = async () => {
    const allNitListData = await getNitList(form?.vendor);
    if (allNitListData?.status) {
      setallNitList(allNitListData?.data);
      setNitList(
        allNitListData?.data
          ?.filter(
            (entry) => entry?.site_id === form?.mine && entry?.status === 1
          )
          ?.map((d) => {
            return { value: d.id, label: d.nit };
          })
      );
    }
  };

  const getNit = async () => {
    let nit = await getNitById(form?.nit_id);
    if (nit?.status)
      setContractorData({
        ...contractorData,
        nit_mine: nit.data.siteData?.name,
        nit_subsidiary: nit.data.siteData?.parentSite?.name,
        new_nit_number: nit.data.nit,
      });
  };

  const ventorList = async () => {
    if (!form?.mine) return;
    let site = `site_id=${form?.mine}`;
    const allContractorList = await getAllContractorByNit(site);
    setContractorList(
      allContractorList?.map((d) => {
        return { value: d.id, label: d.contractor };
      })
    );
  };

  const getSiteList = async () => {
    let allsiteList = await getAllSites();
    if (allsiteList?.status === 1 && allsiteList?.message == "success") {
      setSites(allsiteList?.data);
      let dataObjSite = [];
      allsiteList?.data?.forEach((data) => {
        if (data.parentSite.id) {
          const labelExists = dataObjSite.some(
            (obj) => obj.label === data.parentSite.name
          );
          if (!labelExists) {
            dataObjSite.push({
              value: data.parentSite.id,
              label: data.parentSite.name,
            });
          }
        }
      });
      setAllSiteListDrop(dataObjSite);
    }
  };

  const getPoList = async () => {
    let res = await getAllPoList();
    let poOpt = res.data.map((d) => {
      return {
        value: d?.id,
        nit: d?.nit_id,
        label: d?.po,
        nitNumber: d?.nitNumber?.nit,
      };
    });
    setPoList(poOpt);
  };

  //side filter controls
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setApplyFilter(true);
    setShow(false);
  };
  const resetFilter = () => {
    setForm((prevForm) => ({
      ...prevForm,
      startDate: "",
      endDate: "",
    }));
    // handleClose();
  };
  const getDashboardData = useCallback(async () => {
    if (!form.vendor) return;
    let queryString = "";

    if (form.vendor) {
      queryString += `&contractor_id=${form.vendor}`;
    }
    if (productionType) {
      queryString += `&selected_type=${productionType}`;
    }
    if (form.tender) {
      queryString += `&tender=${form.tender}`;
    }
    if (form.startDate) {
      queryString += `&startDate=${form.startDate}`;
    }
    if (form.endDate) {
      queryString += `&endDate=${form.endDate}`;
    }
    if (form.po) {
      queryString += `&po=${form.po}`;
    }
    let response = await getPerformanceProductionDashboard(queryString);
    if (response?.status) {
      let pichat = pieChartDataWithModalForTotalVs(
        response?.data?.data?.pichart
      );
      setDashboard(response?.data?.data);
      setPichart(pichat);
      setDateRange(response?.data?.data?.dateRange);
      setMinMaxDate(response?.data?.data?.minMax_date || {});
      setAchievedTillDate(response?.data?.data?.achievedTillDate);
    }
  }, [
    form.endDate,
    form.startDate,
    form.tender,
    form.vendor,
    form.po,
    productionType,
  ]);

  // To get mine vendor subsidiary for which data available
  const getNitForDashboard = async () => {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    let res = await getDashboardDataNit(
      "contractor_production",
      auth_user?.siteId
    );

    if (res.data != null && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({
        ...prevForm,
        // tender: +res?.data?.data?.nit,
        vendor: +res?.data?.data?.contractor_id,
        mine: res?.data?.data?.siteData?.id || prevForm.mine,
        subsidiary:
          res?.data?.data?.siteData?.parentSite?.id || prevForm.subsidiary,
      }));
    }
  };

  useEffect(() => {
    const chartFormatedData = [];
    dashboard?.data?.forEach((item) => {
      chartFormatedData.push({
        name: item.shift,
        name1: "Plan",
        value: item.plan,
      });

      chartFormatedData.push({
        name: item.shift,
        name1: "Actual",
        value: item.actual,
      });
    });
    setChartData(multipleBarChartDataFormat(chartFormatedData));
  }, [dashboard]);

  useEffect(() => {
    // if (form.tender) setProductionType("");
    // if (!form.tender) getDashboardData();
  }, [form.tender]);

  useEffect(() => {
    getDashboardData();
  }, [form.mine, form.vendor, productionType, form?.po, form.tender]);

  useEffect(() => {
    if (!applyFilter) return;
    getDashboardData();
    setApplyFilter(false);
  }, [applyFilter]);

  useEffect(() => {
    if (form?.nit_id) {
      getNit();
    }
  }, [form?.nit_id]);

  useEffect(() => {
    let dataObjMine = [];
    if (sites.length > 0) {
      sites?.forEach((data) => {
        if (data.siteType === 3) {
          dataObjMine.push({
            value: data.id,
            label: data.name,
          });
        }
      });
    }
    setAllMineListDrop(dataObjMine);
    if (allsiteListDrop.length === 1 && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({
        ...prevForm,
        subsidiary: allsiteListDrop[0].value,
      }));
    }
  }, [allsiteListDrop]);

  useEffect(() => {
    getSiteList();
    getNitForDashboard();
    getPoList();
  }, []);

  useEffect(() => {
    if (allMineListDrop.length === 1 && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({ ...prevForm, mine: allMineListDrop[0].value }));
    }
  }, [allMineListDrop]);

  useEffect(() => {
    if (allNitList) {
      const foundObject = allNitList.find((obj) => obj.id === form?.tender);
      if (foundObject) {
        setNitData(foundObject);
      }
    }
    setNitData(allNitList?.find((obj) => obj.id === form?.tender));
  }, [allNitList, form?.tender]);

  useEffect(() => {
    if (form?.mine) {
      ventorList();
      let mines = allNitList?.filter((obj) => obj.site_id === form?.mine);
      let dataObjMine = [];
      if (mines?.length > 0) {
        mines?.forEach((data) => {
          dataObjMine.push({
            label: data.tender_id + " / " + data?.dataValue?.po,
            value: data.tender_id,
          });
        });
      }
      setNitList(dataObjMine);
    } else {
      setNitList([]);
      // form.tender = "";
    }
    // setForm((prevForm) => ({
    //   ...prevForm,
    //   // vendor: "",
    //   tender: "",
    // }));
  }, [form?.mine]);

  useEffect(() => {
    if (form?.subsidiary && sites.length) {
      let mines = sites?.filter((obj) => obj.parentSiteId === form?.subsidiary);
      let dataObjMine = [];
      if (mines.length > 0) {
        mines?.forEach((data) => {
          dataObjMine.push({
            label: data.name,
            value: data.id,
          });
        });
      }
      setAllMineListDrop(dataObjMine);
    } else {
      setAllMineListDrop([]);
      // form.mine = "";
    }
  }, [form?.subsidiary, sites]);

  useEffect(() => {
    if (form.vendor) getAllNitList();
    // form.tender = "";
  }, [form?.vendor]);

  useEffect(() => {
    setProductionType(
      nitData?.production_type === "1"
        ? "Coal"
        : nitData?.production_type === "2"
        ? "OB"
        : "Coal"
    );
  }, [nitData]);

  useEffect(() => {
    if (!storeFlag) return;
    if (!Object.keys(storeFilter)?.length) {
      setStoreFlag(false);
      return;
    }
    setForm((prev) => ({ ...prev, ...storeFilter }));
    setStoreFlag(false);
  }, [setForm, storeFilter, storeFlag]);

  useEffect(() => {
    if (storeFlag) return;
    let formObj = {};
    Object.keys(form)?.forEach((d) => {
      if (d !== "startDate" && d !== "endDate") formObj[d] = form?.[d];
    });
    dispatch(setContractorDashFilter(formObj));
  }, [dispatch, form, storeFlag]);

  // console.log("setForm", form);
  // console.log("allNitList++++", allNitList);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <div className="dashboardTabs coalGradeScreenTabs">
            <ul>
              {permissionCheck(["contractor_hindrance_dashboard"]) && (
                <li
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/hindrance`
                    )
                  }
                >
                  Contractor Hindrance
                </li>
              )}

              {permissionCheck(["performance_production_dashboard"]) && (
                <li
                  className="active scoreCardDashboards"
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/production-performance`
                    )
                  }
                >
                  Production Performance
                </li>
              )}
              {permissionCheck([
                "contractor_performance_score_card_dashboard",
              ]) && (
                <li
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/performance-score-card-vendor`
                    )
                  }
                  data-testid="nav-link-3"
                >
                  Contractor Score Card
                </li>
              )}

              {permissionCheck(["contractor_performance_dashboard"]) && (
                <li
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/performance`
                    )
                  }
                >
                  Contractor Performance
                </li>
              )}
            </ul>
            <SelectedDateLable form={form} minMaxDate={minMaxDate} />
            <FilesExport id={`#${"ProductionPerformance"}`} fullScreen />
            <div
              className="dashboardFilter"
              onClick={handleShow}
              data-testid="filter"
            ></div>
          </div>
          <Container fluid>
            <div className="dashboardWrapper innerDashboards">
              <Row id="ProductionPerformance">
                <div class="dashboardHeader breadcrumbWithTitle d-none">
                  <MainBreadcrumb
                    routeSegments={[
                      {
                        path: "#",
                        name: "Contractor Management",
                      },
                      {
                        path: "#",
                        name: "Contractor Hindrance Dashboard",
                      },
                    ]}
                  />
                  <h3 class="title mb-0">Contractor Hindrance Dashboard</h3>
                </div>

                <div>
                  <Form>
                    <div className="topFilterSec">
                      <div className="colindiaCard borderRadius5">
                        <Row>
                          <Col lg={2}>
                            <Select
                              name="subsidiary"
                              value={form?.subsidiary}
                              options={allsiteListDrop}
                              label={"Subsidiary"}
                              disabled={
                                allsiteListDrop.length === 1 ? true : false
                              }
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  vendor: "",
                                }));
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            <Select
                              label={"Mine"}
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  vendor: "",
                                  tender: "",
                                }));
                              }}
                              value={form?.mine}
                              name="mine"
                              options={allMineListDrop}
                              disabled={
                                allMineListDrop.length === 1 ? true : false
                              }
                            />
                          </Col>

                          <Col lg={4}>
                            <Select
                              options={form?.mine ? contractorList : []}
                              onChange={onChange}
                              value={form?.vendor}
                              name="vendor"
                              label={"Vendor"}
                            />
                          </Col>
                          <Col lg={2}>
                            <Select
                              options={form?.vendor ? nitList : []}
                              onChange={onChange}
                              value={form?.tender}
                              name="tender"
                              label={"NIT"}
                            />
                          </Col>
                          <Col lg={2}>
                            <Select
                              blankSelect="Select All"
                              onChange={onChange}
                              value={form?.po}
                              name="po"
                              options={
                                form?.tender
                                  ? poList.filter(
                                      (item) => item.nit === form?.tender
                                    )
                                  : []
                              }
                              label={"PO"}
                            />
                          </Col>
                        </Row>
                      </div>{" "}
                    </div>
                    {form?.tender &&
                    form?.subsidiary &&
                    form?.vendor &&
                    form?.mine &&
                    form?.tender ? (
                      <DashboardDetailCard
                        nitData={nitData}
                        unit={productionType === "Coal" ? "Tonne" : "CuM"}
                        achievedTillDate={achievedTillDate?.achievedTillDate}
                      />
                    ) : (
                      ""
                    )}
                    {form?.vendor &&
                    form?.subsidiary &&
                    form?.mine &&
                    form?.tender ? (
                      <Row className="pt-3">
                        <div className="dashboardTabs coalGradeScreenTabs ">
                          <ul>
                            {(nitData?.production_type === "1" ||
                              nitData?.production_type === "3") && (
                              <li
                                className={`scoreCardDashboards ${
                                  productionType === "Coal" ? "active" : ""
                                }`}
                                onClick={() => setProductionType("Coal")}
                              >
                                Coal
                              </li>
                            )}
                            {(nitData?.production_type === "2" ||
                              nitData?.production_type === "3") && (
                              <li
                                className={`scoreCardDashboards ${
                                  productionType === "OB" ? "active" : ""
                                }`}
                                onClick={() => setProductionType("OB")}
                              >
                                OB
                              </li>
                            )}
                          </ul>
                          <div className="d-flex justify-content-center">
                            {(dateRange?.minDate || dateRange?.maxDate) && (
                              <label className="blueLabel">
                                Data Available:{" "}
                                {DDMMYYYYFormat(dateRange?.minDate)} to{" "}
                                {DDMMYYYYFormat(dateRange?.maxDate)}
                              </label>
                            )}
                          </div>
                        </div>

                        <Col md={4}>
                          <Card className="chartCard mb-3">
                            <Card.Header>
                              Production Performance{" "}
                              {productionType !== "Coal" ? "(CuM)" : "(Tonne)"}
                            </Card.Header>
                            <Card.Body className="p-0">
                              <div className="webformCardContent table-responsive">
                                <table
                                  className="avgTblData table table-striped table-bordered table-hover"
                                  style={{
                                    height:
                                      Array.isArray(dashboard?.data) &&
                                      dashboard?.data?.length > 0
                                        ? "300px"
                                        : "",
                                  }}
                                  // style={{ height: "300px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>Plan</th>
                                      <th>Actual</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Array.isArray(dashboard?.data) &&
                                      dashboard?.data?.length > 0 && (
                                        <>
                                          <tr>
                                            <td>Overall</td>
                                            <td>
                                              {dashboard.sumTotalPlan &&
                                                isDecimal(
                                                  dashboard.sumTotalPlan
                                                )}
                                            </td>
                                            <td>
                                              {dashboard.sumTotalActual &&
                                                isDecimal(
                                                  dashboard.sumTotalActual
                                                )}
                                            </td>
                                          </tr>
                                          {dashboard?.data.map((d, i) => (
                                            <tr key={i}>
                                              <td>{d?.shift}</td>
                                              <td>
                                                {d?.plan &&
                                                  isDecimal(Number(d?.plan))}
                                              </td>
                                              <td>
                                                {d?.actual &&
                                                  isDecimal(Number(d?.actual))}
                                              </td>
                                            </tr>
                                          ))}
                                        </>
                                      )}
                                  </tbody>
                                </table>
                                {Array.isArray(dashboard?.data) &&
                                  !dashboard?.data?.length > 0 && (
                                    <span className="text-center">
                                      <NoRecord />
                                    </span>
                                  )}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="chartCard mb-3">
                            <Card.Header>
                              Production Performance Graph{" "}
                              {productionType !== "Coal" ? "(CuM)" : "(Tonne)"}
                            </Card.Header>
                            <Card.Body
                              className="p-0"
                              style={{ height: "300px" }}
                            >
                              <SimpleBarChart
                                xAxis={{
                                  type: "category",
                                  data: chartData?.categoryData,
                                }}
                                yAxis={{
                                  type: "value",
                                  boundaryGap: [0, 0.01],
                                }}
                                series={chartData?.data}
                                height={"300px"}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={4}>
                          <Card className="chartCard mb-3">
                            <Card.Header>
                              Production By Shifts{" "}
                              {productionType !== "Coal" ? "(CuM)" : "(Tonne)"}
                            </Card.Header>
                            <Card.Body
                              className="p-0"
                              style={{
                                height:
                                  Array.isArray(pichart) && pichart?.length > 0
                                    ? "300px"
                                    : "",
                              }}
                            >
                              <PieChartWithModal
                                pieChartColour={false}
                                seriesData={pichart || []}
                                customLabelFalse={true}
                                isCustomLegend={true}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Form>
                  {!form?.vendor ||
                  !form?.subsidiary ||
                  !form?.mine ||
                  !form?.tender ? (
                    <div className="text-center">
                      <PleaseSelect type={"NIT"} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      {/* Filter Modal */}
      <Modal
        keyboard={false}
        onHide={handleClose}
        backdrop="static"
        show={show}
        className="filterModal"
      >
        <Modal.Header closeButton className="px-3">
          <h5 className="modal-title">Filter</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <Row>
              <label className="pb-2">Date Duration</label>
              <Col lg={12}>
                <CustomDatePicker
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  value={form?.startDate}
                  onChangeDate={onChange}
                  placeholder="Start Date"
                />
              </Col>
              <Col lg={12}>
                <CustomDatePicker
                  dateFormat="YYYY-MM-DD"
                  value={form?.endDate}
                  name="endDate"
                  onChangeDate={onChange}
                  placeholder="End Date"
                  //className={"cndrPosition"}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="filterBtn">
            <Button
              className="secondaryBtn"
              style={{ minWidth: "auto" }}
              onClick={() => resetFilter()}
              data-testid="reset"
            >
              Reset
            </Button>
            <Button
              onClick={() => handleClose()}
              className="primaryBtn"
              style={{ minWidth: "auto" }}
              data-testid="close-message-popup"
            >
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContractorPerformanceSapDashboard;
