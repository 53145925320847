import React, { useEffect, useState } from "react";
import { initialForm, fuelLocationRules } from "./DATA";
import { Button } from "react-bootstrap";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { useNavigate, useParams } from "react-router-dom";
import { SidebarData } from "../../FuelManagement/DATA";
import { useForm } from "../../../../_helpers/hooks";
import { Validation } from "../../../../_helpers/validation/Validation";
import { fuelLocationApi } from "./DATA";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";
import {
  createFuelLocation,
  getAllFuelLocation,
  updateFuelLocation,
  getFuelLocationById,
  getFuelLocationByEditId,
  getTankDataBydataId,
} from "../../../../services/_fuelDispense/fuelDispense";
// import { exportFuelLocationBySiteId } from "../../services/_fuelDispense/fuelDispense";
import toastr from "toastr";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import Select from "../../../../components/Select/Select";
import { IS_USER_SITE_MINES, auth_user } from "../../../../_helpers/helper";
import { getAllWorkshopMasters } from "../../../../services/_reasonList/reasonList";
import { filterOptionsOnStatusBasis } from "../../../../_services/CommonService/CommonService";

const ConfigurationFuelLocationAdd = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form, onChange, setForm] = useForm(initialForm);
  const [sites, setSites] = useState();
  const [workshopData, setWorkshopData] = useState();
  const [tankOption, setTankOption] = useState([]);
  const [userSite, setUserSite] = useState();
  const [mineList, setMineList] = useState([]);
  const [error, setError] = useState({
    ...initialForm,
  });
  const onSubmit = async () => {
    const errorObj = Validation(form, fuelLocationRules);
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }
    let response = params?.id
      ? await updateFuelLocation(form, params.id)
      : await createFuelLocation(form);
    if (!response?.status)
      return toastr.error(
        response?.message ? response?.message : "something went wrong"
      );

    if (response?.status) toastr.success(`Saved Successfully`);
    navigate("/fuel-management-system/configuration/fuel-location");
  };

  const handleFormChange = (event) => {
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  async function fetchData() {
    if (!params.id) return;
    let response = await fuelLocationApi.getById.api(params?.id);
    if (response?.status) {
      setForm(() => {
        return {
          site_id: response?.data?.site_id,
          tank_id: response?.data?.tank_id,
          lat: response?.data?.lat,
          long: response?.data?.long,
          workshop_id: response?.data?.workshop_id,
          fuel_location_name: response?.data?.fuel_location_name,
          fuel_location_code: response?.data?.fuel_location_code,
        };
      });
    }
  }

  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj =
      mineList?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setForm((prev) => ({
      ...prev,
      site_id: siteObj?.value,
    }));
  }, [mineList]);

  const mineLists = async () => {
    let list = [];
    const response = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );

    if (response?.status) {
      list = response?.data?.map((d) => {
        return { value: d.id, label: d.name };
      });
    }
    setMineList(list);
  };
  useEffect(() => {
    mineLists();
  }, []);

  useEffect(() => {
    fetchData();
  }, [params.id]);

  useEffect(() => {
    getAllSites("filter[status]=1&filter[activeStatus]=active").then(
      (response) => {
        if (!response?.data) return;
        setSites(response.data);
      }
    );
    getAllWorkshopMasters().then((response) => {
      if (!response?.data) return;
      setWorkshopData(
        filterOptionsOnStatusBasis(response?.data)?.map((d) => {
          return { label: d.name, value: d.id };
        })
      );
    });
  }, []);

  useEffect(() => {
    if (form?.site_id) {
      let filter = `site_id=${form?.site_id}`;
      let id = null;
      getTankDataBydataId(id, filter).then((response) => {
        if (!response?.data) return;
        setTankOption(
          response.data.map((d) => {
            return { label: d.name, value: d.id };
          })
        );
      });
    }
  }, [form?.site_id]);
  useEffect(() => {
    let authUser = localStorage.getItem("auth_user");
    let user = authUser ? JSON.parse(authUser) : "";
    let user_site = authUser ? JSON.parse(JSON.parse(authUser)?.siteId) : "";
    setUserSite(user_site);
    let mine = "";
    // if (params.id) {
    //   mine = sites?.find((site) => site?.id === form.site_id);
    // } else
    mine = sites?.find((site) => site?.id === user_site);
    // const subsidiary = sites?.find((site) => site?.id === mine.parentSiteId);
    // setForm((prev) => {
    //   return {
    //     ...prev,
    // mine: mine?.siteId,
    // site_id: mine?.id,
    // };
    // });
  }, [sites]);

  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              className="title"
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Fuel Management",
                    path: "/",
                  },
                  {
                    name: "Configuration",
                    path: "/fuel-management-system/configuration",
                  },
                  {
                    name: "Add Fuel Location",
                    path: "#",
                  },
                ]}
              />
            </h3>
            <div className="reportCard">
              <div className="formWrapper">
                <div className="maverickFormCard">
                  <div className="creationWrapper">
                    <Form>
                      <Row>
                        <Col lg={6}>
                          <Select
                            onChange={(event) => handleFormChange(event)}
                            data-testid="select-btn"
                            name="site_id"
                            options={mineList}
                            label={"Mine"}
                            placeholder={"Enter Here"}
                            value={form?.site_id}
                            error={error?.site_id}
                            disabled={IS_USER_SITE_MINES()}
                          />
                        </Col>
                        <Col lg={6}>
                          <Select
                            onChange={(event) => handleFormChange(event)}
                            data-testid="select-btn"
                            name="tank_id"
                            options={tankOption}
                            label={"Tank"}
                            placeholder={"Enter Here"}
                            value={form?.tank_id}
                            error={error?.tank_id}
                          />
                        </Col>
                        <Col lg={6}>
                          <Select
                            onChange={(event) => handleFormChange(event)}
                            data-testid="select-btn"
                            name="workshop_id"
                            options={workshopData}
                            label={"Workshop Id"}
                            placeholder={"Enter Here"}
                            value={form?.workshop_id}
                            error={error?.workshop_id}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="fuel_location_name"
                            value={form.fuel_location_name}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Fuel Location Name"}
                            error={error?.fuel_location_name}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="fuel_location_code"
                            value={form.fuel_location_code}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Fuel Location Code"}
                            error={error?.fuel_location_code}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="lat"
                            value={form.lat}
                            onChange={onChange}
                            type="number"
                            class="form-control"
                            placeholder=""
                            label={"Lattitude"}
                            error={error?.lat}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormInputText
                            name="long"
                            value={form.long}
                            onChange={onChange}
                            type="number"
                            class="form-control"
                            placeholder=""
                            label={"Longitude"}
                            error={error?.long}
                          />
                        </Col>
                      </Row>
                    </Form>
                    <div className="btnSec">
                      <Button
                        className="secondaryBtn iconBtnForm"
                        data-testid="next-btn"
                        onClick={() =>
                          navigate(
                            `/fuel-management-system/configuration/fuel-location`
                          )
                        }
                      >
                        Back
                      </Button>
                      <Button
                        className="primaryBtn iconBtnForm"
                        data-testid="next-btn-submit"
                        onClick={() => onSubmit()}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationFuelLocationAdd;
