export function SetPermission({ permission, data, global }) {
  let auth_user = JSON.parse(localStorage.getItem("auth_user"));

  let permissions =
    auth_user && auth_user.permissions
      ? JSON.parse(auth_user.permissions)
      : null;
  if (permissions && permissions[permission]) {
    return data;
  } else {
    return "";
  }
}
