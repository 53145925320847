import React, { useState, useEffect } from "react";
import InputError from "../../../components/_form/InputError/InputError";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import HindranceAudit from "./HindranceAudit";
import { hindranceHistory } from "../../../services/_hindrance/hindrance";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  getHinderanceById,
  updateHindrance,
  rejectHindrance,
} from "../../../services/_hindrance/hindrance";
import { useParams, useNavigate } from "react-router-dom";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import HindranceApproverData from "./HindranceApproverData";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import { useToggle } from "../../../_helpers/hooks";
import toastr from "toastr";
import { pastSatus, checkHrsOfHindrance, hrsToCheck } from "./DATA";
import { checkDecimal } from "../../../_helpers/helper";
const HindranceApprove = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const [reasons, setreasons] = useState([]);
  const [dataKey, setDataKey] = useState([]);
  const [show, setShow] = useState(false);
  const [noRecord, setNoRecord] = useState(true);
  const [validationCheck, setValidationCheck] = useState(false);
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [presentStatus, setPresentStatus] = useState("");
  const [nextStatus, setNextStatus] = useState("");
  const [allData, setAllData] = useState({});
  const [errorArr, setErrorArr] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  let dateTime = new Date().toLocaleString();
  const [approvalMessage, setApprovalMessage] = useState("");
  const [approvalMessageErr, setApprovalMessageErr] = useState("");
  const [showApprove, toggleShowApprove] = useToggle(false);
  const [historyData, setHistoryData] = useState([]);
  const [showAudit, setshowAudit] = useState(false);

  const getAllById = async () => {
    const response = await getHinderanceById(id);
    if (response) {
      if (!response.status) {
        setNoRecord(false);
      }
      setAllData(response?.data);
      let hindranceReason = [];
      if (response?.data?.current_label == "representative") {
        hindranceReason = response?.data?.contractor_hindrance_reasons?.map(
          (d) => {
            return {
              ...d,
              acceptedHours: d.hrs_of_hindrance,
              repersentative_comments: d.repersentative_comments,
              hrs_of_hindrance_by_representative: d.hrs_of_hindrance,
            };
          }
        );
      }
      if (response?.data?.current_label == "section_incharge") {
        hindranceReason = response?.data?.contractor_hindrance_reasons?.map(
          (d) => {
            return {
              ...d,
              acceptedHours: d.hrs_of_hindrance_by_representative,
              section_incharge_comments: d.section_incharge_comments,
              hrs_of_hindrance_by_section_incharge:
                d.hrs_of_hindrance_by_representative,
            };
          }
        );
      }
      if (response?.data?.current_label == "colliery_manager") {
        hindranceReason = response?.data?.contractor_hindrance_reasons?.map(
          (d) => {
            return {
              ...d,
              acceptedHours: d.hrs_of_hindrance_by_section_incharge,
              colliery_manager_comments: d.colliery_manager_comments,
              hrs_of_hindrance_by_colliery_manager:
                d.hrs_of_hindrance_by_section_incharge,
            };
          }
        );
      }
      setErrorMsg(
        hindranceReason.map((d) => {
          return {
            id: d.id,
            acceptedHours: "",
          };
        })
      );
      setreasons(hindranceReason);
    }
  };

  useEffect(() => {
    getAllById();
  }, []);

  useEffect(() => {
    setPresentStatus(allData?.current_label);
    if (presentStatus == "representative") setNextStatus("Vendor");
    if (presentStatus == "section_incharge") setNextStatus("Representative");
    if (presentStatus == "colliery_manager") setNextStatus("Section incharge");
  }, [allData, presentStatus]);

  const editHours = (i, k, shift) => {
    if (!i?.value) {
      setDataKey((oldArray) => [...oldArray, k]);
    }
    if (i?.value) {
      let arr = dataKey.filter((item) => item !== k);
      setDataKey(arr);
    }
    setErrorMsg((prev) => {
      return prev.map((d, p) => {
        if (d.id === k) {
          return {
            ...d,
            acceptedHours: "",
          };
        }
        return { ...d };
      });
    });

    if (+i?.value < 0) {
      i = 0;
    }
    if (+i?.value > 24 && +shift === 4) {
      i = 24;
    }
    if (+i?.value > 8 && +shift !== 4) {
      i = 8;
    }
    if (+i?.value > 0 && +i?.value < 8 && checkDecimal(+i?.value)) {
      const minNum = i?.value?.toString()?.split(".");
      if (+minNum[1] > 59) {
        i.value = ++minNum[0];
      }
    }

    if (allData?.current_label == "section_incharge") {
      setreasons((prev) =>
        prev.map((d) => {
          if (k == d.id) {
            return {
              ...d,
              [i?.name]: i?.value,
              hrs_of_hindrance_by_section_incharge:
                i?.name === "acceptedHours"
                  ? +i?.value
                  : d?.hrs_of_hindrance_by_section_incharge,
            };
          } else {
            return d;
          }
        })
      );
    }
    if (allData?.current_label == "representative") {
      setreasons((prev) =>
        prev.map((d) => {
          if (k == d.id) {
            return {
              ...d,
              [i?.name]: i?.value,
              hrs_of_hindrance_by_representative:
                i?.name === "acceptedHours"
                  ? +i?.value
                  : d?.hrs_of_hindrance_by_representative,
            };
          } else {
            return d;
          }
        })
      );
    }
    if (allData?.current_label == "colliery_manager") {
      setreasons((prev) =>
        prev.map((d) => {
          if (k == d.id) {
            return {
              ...d,
              [i?.name]: i?.value,
              hrs_of_hindrance_by_colliery_manager:
                i?.name === "acceptedHours"
                  ? +i?.value
                  : d?.hrs_of_hindrance_by_colliery_manager,
            };
          } else {
            return d;
          }
        })
      );
    }
  };

  // const validate = (i, k) => {
  //   let obj = { ...errorArr };
  //   obj[k] = i;
  //   setErrorArr(obj);
  // };

  const onSubmit = async () => {
    setValidationCheck(true);
    let submit = true;
    setErrorMsg(
      reasons.map((d) => {
        if (d.acceptedHours === "") {
          submit = false;
          return {
            id: d.id,
            acceptedHours: "Required",
          };
        } else {
          return {
            id: d.id,
            acceptedHours: "",
          };
        }
      })
    );

    if (!submit) {
      return;
    }
    let checkHrsValidation = checkHrsOfHindrance(
      reasons,
      hrsToCheck[presentStatus]
    );
    if (checkHrsValidation !== "validated") {
      toastr.error(checkHrsValidation);
      return;
    }
    let data = {
      reasons,
    };

    let response = await updateHindrance(id, data);
    if (response?.status == 1 && response?.message == "success") {
      setShow(true);
      setMessagePopupType(false);
    } else console.log("error");
  };

  const closePopup = () => {
    setShow(false);
    navigate("/contractor-management/my-approval-action-contractor-hindrance");
  };

  function handleClick() {
    setShow(true);
    setMessagePopupType(true);
  }

  const handleCloseApprove = () => {
    setApprovalMessage("");
    setApprovalMessageErr("");
    toggleShowApprove();
  };
  const reject = async () => {
    let data = {
      level: presentStatus?.replace("_", " "),
      reason: approvalMessage,
    };
    let response = await rejectHindrance(id, data);
    if (response.status) {
      setShow(true);
      setMessagePopupType(false);
    } else {
      toastr.error(response?.message);
    }
  };
  const onReject = () => {
    if (approvalMessage) {
      reject();
    }
    if (!approvalMessage) setApprovalMessageErr("Reason is Required");
  };

  const getHistoryData = async () => {
    let response = await hindranceHistory(id);
    setHistoryData(response?.data);
  };
  useEffect(() => {
    getHistoryData();
  }, [id]);

  return noRecord ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div
          className="HindranceApproveWrapper"
          data-testid="HindranceApproveWrapper"
        >
          <div className="contentArea">
            <Container fluid>
              <h3 className="title d-flex align-items-end">
                Hindrance Approve Details​ &nbsp;
                <span className="blueLabel">{dateTime}</span>
              </h3>
              <div className="hindranceContent">
                <PerfectScrollbar className="customHeight">
                  <div className="hindranceContentWrapper">
                    <div className="hindranceContent">
                      <div className="hindranceContentData">
                        <Row>
                          <Col>
                            <div className="form-group">
                              <label>Contractor Code</label>
                              <p>{allData?.contractor?.contractor_code}</p>
                            </div>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Contractor</label>
                              <p>{allData?.contractor?.contractor}</p>
                            </div>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Contractor Person</label>
                              <p>{allData?.contractor?.contractor_person}</p>
                            </div>
                          </Col>{" "}
                          <Col>
                            <div className="form-group">
                              <label>NIT / PO</label>
                              <p>
                                {allData?.nit?.nit +
                                  " / " +
                                  allData?.po?.po}
                              </p>
                            </div>
                          </Col>
                          <Col>
                            <label>Subsidiary</label>
                            <p className="">
                              {allData?.nit?.siteData?.parentSite?.name || null}
                            </p>
                          </Col>
                          <Col>
                            <label>Mine</label>
                            <p className="">
                              {allData?.nit?.siteData?.name || null}
                            </p>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Date</label>
                              <p>{DDMMYYYYFormat(allData?.input_date)}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {Array.isArray(reasons) &&
                      reasons.map((e, i) => (
                        <HindranceApproverData
                          key={i}
                          myKey={i}
                          data={reasons[i]}
                          onChange={editHours}
                          presentStatus={presentStatus}
                          nextStatus={nextStatus}
                          // validate={validate}
                          dataKey={dataKey}
                          errorMsg={
                            Array.isArray(errorMsg) && errorMsg.length > 0
                              ? errorMsg[i]
                              : ""
                          }
                          validationCheck={validationCheck}
                        />
                      ))}
                    <div className="btnSec">
                      <Button
                        className="secondaryBtn"
                        data-testid="handle-click-for-show"
                        onClick={handleClick}
                      >
                        Close
                      </Button>
                      <Button
                        className="primaryBtn"
                        data-testid="submit-hindrance"
                        onClick={handleCloseApprove}
                        style={{
                          color: "#FFFFFF",
                          borderColor: "#B71C1C",
                          backgroundColor: "#FD7660",
                        }}
                      >
                        Return to {pastSatus[presentStatus]}
                      </Button>
                      <Button
                        className="primaryBtn"
                        data-testid="submit-hindrance"
                        onClick={onSubmit}
                      >
                        Submit
                      </Button>
                    </div>

                    <div className="btnSec">
                      <Button
                        className="primaryBtn"
                        onClick={() => setshowAudit(!showAudit)}
                      >
                        {showAudit ? "Hide History Log" : "Show History Log"}
                      </Button>
                    </div>
                  </div>
                </PerfectScrollbar>
              </div>
            </Container>

            <Modal
              toggle={handleCloseApprove}
              isOpen={showApprove}
              size="lg"
              // centered
            >
              <ModalHeader toggle={handleCloseApprove}>{`Return`}</ModalHeader>
              <ModalBody className="pb-2">
                <div className="preview">
                  <h6>Are you sure to return this record ?</h6>
                  <div className="form-group my-2">
                    <textarea
                      data-testid="approveMssg"
                      className="form-control"
                      type="text"
                      value={approvalMessage}
                      onChange={(event) =>
                        setApprovalMessage(event.target.value)
                      }
                      placeholder="Reason for returning the record (or any message)."
                    />
                    <InputError error={approvalMessageErr} />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <Button onClick={handleCloseApprove} className="secondaryBtn">
                  No
                </Button>
                <Button
                  onClick={onReject}
                  className="primaryBtn"
                  data-testid="onRejectDataId"
                >
                  Yes
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              toggle={() => setshowAudit(!showAudit)}
              isOpen={showAudit}
              size="xl"
              // centered
            >
              <ModalHeader
                toggle={() => setshowAudit(!showAudit)}
              >{`History Log`}</ModalHeader>
              <ModalBody className="pb-1">
                <div className="preview">
                  <div className="form-group my-2">
                    <HindranceAudit historyData={historyData} />
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <MessagePopup
              messagePopupType={messagePopupType}
              show={show}
              setShow={setShow}
              closePopup={closePopup}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <EmptyDataList
                pageLink={
                  "/contractor-management/my-approval-action-contractor-hindrance"
                }
                title={"Hindrance List"}
                hideAddBtn={true}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default HindranceApprove;
