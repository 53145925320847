import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { fuelSensorLabel } from "./DATA";
import CreatedByCreatedAt from "../../../../components/Common/CreatedByCreatedAt";
import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";

const FuelSensorView = ({ form }) => {
  return (
      <Modal.Body className="p-0">
        <div className="preview">
          <Row>
            <Col sm={6}>
              <label>{fuelSensorLabel.hemm_id}</label>
              <p className="content">{form?.hemm_id}</p>
            </Col>
            <Col sm={6}>
              <label>{fuelSensorLabel.date}</label>
              <p className="content">{form?.date && DDMMYYYYFormat(form?.date)}</p>
            </Col>
            <Col sm={6}>
              <label>{fuelSensorLabel.time}</label>
              <p className="content">{form?.time}</p>
            </Col>
            <Col sm={6}>
              <label>{fuelSensorLabel.fuel_level}</label>
              <p className="content">
                {form?.fuel_level}
              </p>
            </Col>
            <Col sm={6}>
              <label>{fuelSensorLabel.createdAt}</label>
              <p className="content">{form?.createdAt && DDMMYYYYFormat(form?.createdAt)}</p>
            </Col>
          </Row>
        </div>
        {/* </div> */}
      </Modal.Body>
  );
};

export default FuelSensorView;
