import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { receiptLabel } from "./DATA";
import CreatedByCreatedAt from "../../../../components/Common/CreatedByCreatedAt";
import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";

const ReceiptView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <Row>
            <Col sm={6}>
              <label>{receiptLabel.site_id}</label>
              <p className="content">{form?.site?.name}</p>
            </Col>
            <Col sm={6}>
              <label>{receiptLabel.tank_id}</label>
              <p className="content">{form?.tank?.name}</p>
            </Col>
            <Col sm={6}>
              <label>{receiptLabel.supplier}</label>
              <p className="content">{form?.supplier?.supplierCode}</p>
            </Col>
            <Col sm={6}>
              <label>{receiptLabel.receiptDate}</label>
              <p className="content">
                {form?.receiptDate && DDMMYYYYFormat(form?.receiptDate)}
              </p>
            </Col>
            <Col sm={6}>
              <label>{receiptLabel.receiptQyt}</label>
              <p className="content">{form?.receiptQyt}</p>
            </Col>
            <Col sm={6}>
              <label>{receiptLabel.availableTankCapacityAfterReceipt}</label>
              <p className="content">
                {form?.availableTankCapacityAfterReceipt}
              </p>
            </Col>

            <Col sm={6}>
              <label>{receiptLabel.invoiceNumber}</label>
              <p className="content">{form?.invoiceNumber}</p>
            </Col>
            <Col sm={6}>
              <label>{receiptLabel.invoiceDate}</label>
              <p className="content">
                {form?.invoiceDate && DDMMYYYYFormat(form?.invoiceDate)}
              </p>
            </Col>
            <Col sm={6}>
              <label>{receiptLabel.tankLevelAfterReceipt}</label>
              <p className="content">{form?.tankLevelAfterReceipt}</p>
            </Col>
          </Row>
          <CreatedByCreatedAt
            id={form?.id}
            table={"fuel_receipts"}
            border={false}
          />
        </div>
        {/* </div> */}
      </Modal.Body>
    </>
  );
};

export default ReceiptView;
