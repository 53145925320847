import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../components/Select/Select";
import { performanceStatus } from "./DATA";
const ContractorScoreFilterForm = ({
  onSubmitFilter,
  form,
  onChange,
  onResetFilter,
  isOpen,
  contractorList,
  setOpen,
  id,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>Input Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
              value={form?.startDate}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              name="endDate"
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={12}>
            <Select
              onChange={onChange}
              value={form?.contractorId}
              name="contractorId"
              options={contractorList}
              label={"Contractor"}
            />
          </Col>

          <Col md={12}>
            <Select
              value={form?.status}
              onChange={onChange}
              name="status"
              label={"Status"}
              options={Object.keys(performanceStatus)?.map((key) => ({
                value: key,
                label: performanceStatus[key],
              }))}
            />
          </Col>
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              type="button"
              className="secondaryBtn"
              onClick={onResetFilter}
            >
              Clear all
            </Button>
          </Col>
          <Col>
            <Button
              className="primaryBtn me-0"
              type="button"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default ContractorScoreFilterForm;
