import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Container } from "react-bootstrap";
import {
  tankApi,
  fuelLocationColumnFields,
  stockFilterForm,
  tabUrl,
  tankLabel,
  bowserApi,
} from "./DATA";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StockView from "./StockView";
import filter from "../../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../../../_helpers/hooks";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../FuelManagement/DATA";
import {
  exportStockTank,
  exportStockBowser,
  getAllFuelStock,
  getAllTank,
} from "../../../../services/_fuelDispense/fuelDispense";
import "../../fuelmgmt.scss";
import StockFilter from "./stockFilter";
import {
  findUserPermissionedTabList,
  isDateFromThreeDaysBack,
} from "../../../../_helpers/helper";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import StockViewBowser from "./StockViewBowser";
const StockList = ({ type }) => {
  const params = useParams();
  const usrl = useLocation();
  let navigate = useNavigate();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(stockFilterForm);
  const [lastSync, setLastSync] = useState({});
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.stock)
  );

  useEffect(() => {
    setCustomTabs((pre) => {
      return pre.map((d) => {
        if (d.to === usrl?.pathname) {
          return {
            ...d,
            active: 1,
          };
        } else {
          return {
            ...d,
            active: 0,
          };
        }
      });
    });
  }, [usrl.pathname]);

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} hideLeftRightBtn />;
  };

  const getAll = async () => {
    let response;
    if (params?.id)
      response = await getAllFuelStock(
        "page=1&limit=2&createdBy=" + params?.id
      );
    else response = await getAllFuelStock("page=1&limit=2");
    setLastSync(response?.lastSync?.data);

    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const viewAsPopup = (form, setShowView, getAll) => {
    return type === "tank" ? (
      <StockView form={form} setShowView={setShowView} getAll={getAll} />
    ) : (
      <StockViewBowser
        form={form}
        setShowView={setShowView}
        getAll={getAll}
      />
    );
  };

  const onEdit = (id) => {
    navigate("/fuel-management-system/fuel-management-list/stock/" + id);
  };

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);

  const statusByOptions = (row) => {
    if (isDateFromThreeDaysBack(row?.date) === true && !params?.id) {
      return true;
    } else {
      return false;
    }
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <StockFilter
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          setForm={setForm}
          showFuelFilterOptions={"true"}
          hideBowser={type === "tank" ? true : false}
          hideTank={type === "tank" ? false : true}
          hideWorkshop={type === "tank" ? false : true}
        />
      </div>
    );
  };

  const onResetFilter = () => {
    setForm({ stockFilterForm });
  };
  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };
  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }
    setGetAllFilter(filter);
  }, [filterStatus]);

  const updatedFuelLocationColumnFields = fuelLocationColumnFields.map(
    (field) => {
      if (field.selector === "tank" && type === "bowser") {
        return {
          ...field,
          name: tankLabel?.hemm_id,
          selector: "hemm_id",
          cell: (row) => row?.bowser?.hemm_id,
        };
      }
      if (field.selector === "tank" && type === "tank") {
        return {
          ...field,
          name: tankLabel.tank_id,
          selector: "tank",
          cell: (row) => row?.tank?.name,
        };
      }
      return field;
    }
  );

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={updatedFuelLocationColumnFields}
                apis={type === "tank" ? tankApi : bowserApi}
                title="Data"
                FilterBtn={FilterBtn}
                childrenAfterAddButton={tabButtons}
                downloadExcel={
                  type === "tank" ? exportStockTank : exportStockBowser
                }
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/fuel-management-system/fuel-management-list/stock"}
                primaryKey="id"
                pagination={true}
                hideDeleteButton
                hideEditButton
                hideAddButton
                getAllFilter={getAllFilter}
                breadCrumb={[
                  {
                    path: "/",
                    name: "Fuel Management system",
                  },
                  {
                    name: "Fuel Management",
                    path: "/fuel-management-system",
                  },
                  {
                    path: "#",
                    name:
                      type === "tank" ? "Tank Stock List" : "Bowser Stock List",
                  },
                ]}
                // customTableTitle={"Stock List"}
                editFunButton={onEdit}
                customTableTitle={"Blank"}
                viewTitle={
                  type === "tank"
                    ? "Tank Stock Details"
                    : "Bowser Stock Details"
                }
                isNoTabOnPage
                statusByOptions={statusByOptions}
                apiUpdateView={lastSync}
                hideApiUpdateStatus={true}
                modalSize={"xl"}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            hideAddButton
            pageLink={"/fuel-management-system/fuel-management-list/stock"}
            title={type === "tank" ? "Tank Stock List" : "Bowser Stock List"}
          />
        </div>
      </div>
    </div>
  );
};

export default StockList;
