import {
  createFuelLocation,
  getAllFuelLocation,
  updateFuelLocation,
  getFuelLocationById,
  getFuelLocationByEditId,
} from "../../../../services/_fuelDispense/fuelDispense";
export const initialForm = {
  fuel_location_name: "",
  fuel_location_code: "",
  lat: "",
  long: "",
  site_id: "",
  tank_id: "",
};

export const fuelLocationRules = [
  {
    field_name: "site_id",
    label: "Site",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "tank_id",
    label: "Tank",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "lat",
    label: "Lattitude",
    type: "number",
    isRequired: true,
  },
  {
    field_name: "long",
    label: "Longitude",
    type: "number",
    isRequired: true,
  },
  {
    field_name: "fuel_location_name",
    label: "Fuel Location Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "fuel_location_code",
    label: "Fuel Location Code",
    type: "string",
    isRequired: true,
  },
];

export const fuelLocationApi = {
  getAll: {
    api: getAllFuelLocation,
  },
  getById: {
    api: getFuelLocationByEditId,
  },
};

export const fuelLocationColumnFields = [
  {
    name: "Mine",
    selector: "site",
    sortable: false,
    cell: (row) => row?.site?.name,
  },
  {
    name: "Tank Name",
    selector: "tank",
    sortable: true,
    cell: (row) => row?.tank?.name,
  },
  {
    name: "Fuel Station",
    selector: "fuel_location_name",
    sortable: true,
    cell: (row) => row?.fuel_location_name,
  },
  {
    name: "Fuel Location Code",
    selector: "fuel_location_code",
    sortable: true,
    cell: (row) => row?.fuel_location_code,
  },
  {
    name: "Workshop Id",
    selector: "workshop_id",
    sortable: true,
    cell: (row) => row?.workshopData?.name || "Not Available",
  },
  {
    name: "Latitude",
    selector: "lat",
    sortable: true,
    // cell: (row) => row?.site?.name,
  },
  {
    name: "Longitude",
    selector: "long",
    sortable: true,
    // cell: (row) => row?.site?.name,
  },
];
