import { useParams } from "react-router-dom";
import { GrUpload } from "react-icons/gr";
import React, { useEffect, useState } from "react";

import "../../../src/components/_form/UploadFile/uploadfile.scss";
import deleteIcon from "../../../src/Assets/Images/delete.svg";
import corruptMssg from "../../../src/Assets/Images/corruptMssg.svg";
import corruptFile from "../../../src/Assets/Images/corruptfile.svg";
import excelIcon from "../../../src/Assets/Images/excelIcon.svg";
import pdfIcon from "../../../src/Assets/Images/pdfIcon.png";
import PopUpFile from "../../components/PopUpFile/PopUpFile";
import toastr from "toastr";

const HelpManualFileUpload = ({
  extensions,
  description,
  onChangeFile,
  removeFile,
  sapmleFile,
  addFile,
}) => {
  const [fileName, setFileName] = useState("");
  const [errorFile, setErrorFile] = useState(false);
  const { param } = useParams();
  useEffect(() => {
    if (addFile) {
      setFileName(addFile);
    }
  }, [addFile]);

  const onChangeHandler = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const extension = file.name.split(".").pop().toLowerCase();
    setFileName(file?.name);
    if (!extensions?.includes(extension)) {
      setErrorFile(true);
      toastr.warning("Only PDF file(s) allowed!");
    } else {
      setErrorFile(false);
      onChangeFile({ file }, extension);
      // Clear the value of the input field
      e.target.value = null;
    }
  };
  

  const deleteFile = () => {
    setFileName("");
    removeFile();
  };

  return (
    <div className={"form-group mb-1"}>
      <div className="docUpload">
        <div>
          <div className="uploadFileBtn">
            <label htmlFor="upload-single-file">
              <GrUpload />
              {/* <h6>Enter file to upload</h6>
          <p>or</p> */}
              <p className="browse">Browse</p>
            </label>
            <input
              className="uploadFile"
              type="file"
              onChange={onChangeHandler}
              id="upload-single-file"
              //   value={}
            />
          </div>
          {fileName && (
            <div className="uploadFileSec">
              <label className="form-label">File Type</label>
              <div className="uploadFileValue">
                <div>
                  {errorFile ? (
                    <img src={corruptFile} alt="corrupt File" />
                  ) : (
                    <img
                      src={pdfIcon}
                      alt="PDF File"
                      style={{ width: "25px" }}
                    />
                  )}
                  <span>&nbsp;{fileName || addFile}</span>
                </div>
                <img
                  src={deleteIcon}
                  onClick={deleteFile}
                  alt="delete"
                  className="deleteIcon"
                />
              </div>
              {/* {errorFile && (
                <div className="errorBox">
                  <span className="errorMSsg">
                    <img src={corruptMssg} alt="corrupt File" />
                    We found some errors while reading the file; please
                    cross-check and re-upload the correct file.
                  </span>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpManualFileUpload;
