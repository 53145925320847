import { useCallback, useEffect, useMemo, useState } from "react";

export const debounce = (func, delay = 100) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export function useDebounce(func = () => {}, dependencies = [], delay = 500) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const funcToRun = useCallback(func, [...dependencies]);
  const debouncedFunction = useMemo(
    () => debounce(funcToRun, delay),
    [funcToRun, delay]
  );

  return debouncedFunction;
}

export function useForm(initialForm = {}, fields) {
  const [form, setForm] = useState(() => initialForm);

  const onChange = useCallback(
    (e) => {
      const event = { ...e };
      const { name, value, type, checked, files } = event.target;

      setForm((prev) => {
        const field = fields?.find(
          (f) => (f.selectorForm || f.selector) === name
        );
        const extraToChange = field?.extraToChange?.(prev, event) || {};

        const newForm = {
          ...prev,
          [name]:
            type === "checkbox" ? checked : type === "file" ? files[0] : value,
          ...extraToChange,
        };

        // "prev[name] !== value" Because Selected option could be the same as Previous, so No Need to reset!
        if (prev[name] !== value && field?.dependencies)
          field.dependencies.forEach((f) => {
            if (f.selector) newForm[f.selector] = "";
            if (f.selectorForm) newForm[f.selectorForm] = "";
          });

        return newForm;
      });
    },
    [fields]
  );

  return [form, onChange, setForm];
}

export function useToggle(initialValue = false) {
  const [value, setValue] = useState(() => initialValue);

  const toggle = useCallback(
    (value) => setValue((prev) => (typeof value === "boolean" ? value : !prev)),
    []
  );

  return [value, toggle, setValue];
}

export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};
