import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";

const FuelLocationView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">Location Detail</p>
            <Row>
              <Col sm={6}>
                <label>Mine</label>
                <p className="content">{form?.site?.name}</p>
              </Col>
              <Col sm={6}>
                <label>Tank Name</label>
                <p className="content">{form?.tank?.name}</p>
              </Col>
              <Col sm={6}>
                <label>Fuel Location Name</label>
                <p className="content">{form?.fuel_location_name}</p>
              </Col>
              <Col sm={6}>
                <label>Fuel Location Code</label>
                <p className="content">{form?.fuel_location_code}</p>
              </Col>
              <Col sm={6}>
                <label>Workshop Id</label>
                <p className="content">{form?.workshopData?.name}</p>
              </Col>
              <Col sm={6}>
                <label>Latitude</label>
                <p className="content">{form?.lat}</p>
              </Col>
              <Col sm={6}>
                <label>Longitude</label>
                <p className="content">{form?.long}</p>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default FuelLocationView;
