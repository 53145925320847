import React from 'react'
import ContractorScoreList from './ContractorScoreList'
const MyScoreCardList = () => {
    return (
        <>
            <ContractorScoreList
                params={true}
            />
        </>
    )
}

export default MyScoreCardList