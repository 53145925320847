import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import FormInputText from "../../../components/FormInputText/FormInputText";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import Select from "../../../components/Select/Select";
import { getAllSites } from "../../../services/_uploadedFiles/uploadedFiles";
import { contractorRules, formFields } from "./DATA";
import { Validation } from "../../../_helpers/validation/Validation";
import {
  createContractor,
  updateContractor,
} from "../../../services/_contractorList/contractorList";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import PerfectScrollbar from "react-perfect-scrollbar";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import { IS_USER_SITE_MINES, auth_user } from "../../../_helpers/helper";

const SamplePrepareRegisterAdd = () => {
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [form, setForm] = useState(formFields);
  const [formFieldError, setFormFieldError] = useState(formFields);
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [duplicateContractorCode, setDuplicateContractorCode] = useState("");
  const navigate = useNavigate();
  const param = useParams();

  console.log("55555555555555555",param);

  const getData = useCallback(async () => {
    const response = await RestMethod.GET("/contractor/" + param.id);
    setForm({ ...response?.data?.data });
  }, [param.id]);
  const onChange = (e, idx) => {
    const { name, value } = e.target;
    formFieldError[name] = "";
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj =
      siteList?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setForm((prev) => ({
      ...prev,
      site_id: siteObj?.value,
    }));
  }, [siteList]);

  const getSiteList = async () => {
    let list = "";
    const response = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );
    if (response.status) {
      list = response.data?.map((d) => {
        return { value: d.id, label: d.name };
      });
    }
    setSiteList(list);
    return list;
  };

  const closeMessagePopup = () => {
    setShowMessagePopUp(false);
    navigate("/contractor-management/configuration/contractor-list");
  };

  useEffect(() => {
    (async () => {
      getSiteList();
    })();
  }, []);

  useEffect(() => {
    if (!param.id) return;
    getData();
  }, [getData, param.id]);

  const onSubmit = async () => {
    let response = "";
    let errorObj = {};
    let data = {};
    form.siteType = 3;
    console.log("formformform",form);
    errorObj = Validation(form, contractorRules);
    console.log("errorObjerrorObj",errorObj);
    setFormFieldError(errorObj);
  
    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    data = { ...form };

    if (param.id) {
      response = await updateContractor(data, param.id);
    } else {
      response = await createContractor(data);
    }

    if (!response?.data?.status) {
      setDuplicateContractorCode("Code already exists");
      toastr.error(response?.data?.message);
    } else {
      setDuplicateContractorCode("");
      setShowMessagePopUp(true);
      toastr.success("data saved successfully");
    }
  };

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    path: "#",
                    name: "Contractor Management",
                  },
                  {
                    name: "Configuration",
                    path: "/contractor-management/configuration",
                  },
                  {
                    path: "/contractor-management/configuration/contractor-list",
                    name: "Contractor User List",
                  },
                  {
                    name: param.id ? "Edit Master" : "Add Master",
                    path: "#",
                  },
                ]}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              {param.id ? "Edit" : "Add"} Master
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <Form>
                  <Row>
                    <Col sm={6}>
                      <FormInputText
                        placeHolder="Enter here"
                        type="text"
                        label={"Contractor"}
                        onChange={onChange}
                        name="contractor"
                        error={formFieldError?.contractor}
                        value={form?.contractor}
                      />
                    </Col>
                    <Col sm={6}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Contractor Person"}
                        onChange={onChange}
                        name="contractor_person"
                        error={formFieldError?.contractor_person}
                        value={form?.contractor_person}
                      />
                    </Col>
                    {/* <Col sm={6}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"NIT Number"}
                        onChange={onChange}
                        name="nit_number"
                        error={formFieldError?.nit_number}
                        value={form?.nit_number}
                      />
                    </Col>
                    <Col sm={6}>
                      <Select
                        onChange={onChange}
                        name="site_id"
                        options={siteList}
                        label={"Site"}
                        placeholder={"Enter Here"}
                        value={form?.site_id}
                        error={formFieldError?.site_id}
                        disabled={IS_USER_SITE_MINES()}
                      />
                    </Col> */}
                    <Col sm={6}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Contractor Code"}
                        onChange={onChange}
                        name="contractor_code"
                        error={formFieldError?.contractor_code}
                        value={form?.contractor_code}
                      />
                      <p style={{ color: "red" }}>{duplicateContractorCode}</p>
                    </Col>
                  </Row>
                </Form>

                <div className="btnSec">
                  <Button
                    onClick={() =>
                      navigate(
                        "/contractor-management/configuration/contractor-list"
                      )
                    }
                    className="secondaryBtn"
                  >
                    Back
                  </Button>
                  <div className="d-flex">
                    <Button
                      onClick={onSubmit}
                      // nextStapeFun={nextStapeFun}
                      className="primaryBtn iconBtnForm"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            messagePopupType={messagePopupType}
            show={showMessagePopUp}
            setShow={setShowMessagePopUp}
            closePopup={closeMessagePopup}
          />
        </div>
      </div>
    </div>
  );
};

export default SamplePrepareRegisterAdd;
