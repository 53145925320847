import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import toastr from "toastr";
import { useNavigate, useParams } from "react-router";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import FormInputText from "../../../components/FormInputText/FormInputText";
import {
  createMasterDropdown,
  updateMasterDropdown,
} from "../../../services/_reasonList/reasonList";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "./DATA";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";

const MasterAirQualityEmr = () => {
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [form, setForm] = useState({
    name: "",
    type: "",
  });
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [formFieldError, setFormFieldError] = useState({ name: "", type: "" });
  const [tab, setTab] = useState(1);

  const navigate = useNavigate();
  const param = useParams();

  const onSubmit = async () => {
    let data = { type: "station_category_air_quality_emr" };
    data.name = form.name.trim();
    let response = "";
    setFormFieldError(validation(form));
    if (form.name === "" || form.name.trim() === "") return;
    if (param.id) {
      response = await updateMasterDropdown(data, param.id);
    } else response = await createMasterDropdown(data);

    if (!response?.data?.status) toastr.error(response?.data?.message);
    else {
      setShowMessagePopUp(true);
      toastr.success("data saved successfully");
    }
  };

  const tabFun = (n) => {
    setTab(n);
  };

  const getData = useCallback(async () => {
    const response = await RestMethod.GET("/master-data/" + param.id);

    setForm({ ...response?.data?.data });
  }, [param.id]);

  const onChange = (e, idx) => {
    const { name, value } = e.target;
    formFieldError[name] = "";
    setForm({ ...form, [name]: value });
  };

  const closeMessagePopup = () => {
    setShowMessagePopUp(false);
    navigate("/configuration/environment/air-quality-emr");
  };

  useEffect(() => {
    if (!param.id) return;
    getData();
  }, [getData, param.id]);

  const validation = (row) => {
    if (row.name === "" || row.name.trim() === "") {
      return { name: "Station category is required" };
    } else {
      return { name: "" };
    }
  };
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <Container fluid>
              <h3 style={{ paddingBottom: "1px", paddingTop: "5px" }}>
                <MainBreadcrumb
                  routeSegments={[
                    {
                      path: "#",
                      name: "Environment Management",
                    },
                    {
                      name: "Configuration",
                      path: "/environment-management/configuration",
                    },
                    {
                      path: "/configuration/environment/air-quality-emr",
                      name: "Air Quality Emr Station Quality",
                    },
                    {
                      path: "#",
                      name: param?.id ? "Edit" : "Add",
                    },
                  ]}
                  // module={ACTIVE_MODULE.incident}
                />
              </h3>
              <h3 className="title">
                {param?.id ? "Edit" : "Add"} Air Quality Emr Station Quality
              </h3>
              <div className="reportCard">
                <div className="formWrapper">
                  <Form>
                    <Row>
                      <Col sm={6}>
                        <FormInputText
                          placeHolder="Enter here"
                          label={"Name"}
                          type="text"
                          onChange={onChange}
                          name="name"
                          data-testid={"nameDataId"}
                          value={form?.name}
                          error={formFieldError?.name}
                        />
                      </Col>
                      {/* <Col sm={6}>
                  <FormInputText
                    type="text"
                    placeHolder="Enter here"
                    label={"Type"}
                    onChange={onChange}
                    name="type"
                    error={formFieldError?.type}
                    //   value={form?.type}
                  />
                </Col> */}
                    </Row>
                  </Form>
                </div>
              </div>
              <div className="btnSec">
                <Button
                  onClick={() =>
                    navigate("/configuration/environment/air-quality-emr")
                  }
                  className="secondaryBtn"
                >
                  Back
                </Button>
                <div className="d-flex">
                  <Button
                    // nextStapeFun={nextStapeFun}
                    className="primaryBtn"
                    tabFun={tabFun}
                    onClick={onSubmit}
                    tab={tab}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Container>
            <MessagePopup
              closePopup={closeMessagePopup}
              messagePopupType={messagePopupType}
              setShow={setShowMessagePopUp}
              show={showMessagePopUp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterAirQualityEmr;
