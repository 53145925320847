import React, { useEffect } from "react";
import EchartPieChart from "../Chart/Echarts/EchartPieChart";
import BasicBarChartWithCustomToolTip from "../Chart/Echarts/BasicBarChartWithCustomTooltip";
import BasicBarChartWithCustomToolTipYaxis from "../Chart/Echarts/BasicBarChartWithCustomTooltipYaxis";

import EchartMultipleBarChart from "../Chart/Echarts/EchartMultipleBarChart";
import BasicBarChartWithLabel from "../Chart/Echarts/BasicBarChartWithLabel";

export const PieChartCard = React.memo(function PieChartCard({
  isClick,
  dataFromChartCard,
  childfun,
  seriesData,
  title,
  id,
  customLabelFalse,
  isCustomLegend,
  isMobile = 0,
  pieChartColour,
  height,
  unit,
  customTitle,
}) {
  let seriesList = [];
  if (Array.isArray(seriesData)) {
    seriesList = seriesData.filter((d) => d.value != 0 && d.value != null);
  }
  const getPieElementData = (e) =>
    isClick && dataFromChartCard ? dataFromChartCard(e) : null;

  return (
    <EchartPieChart
      pieChartColour={pieChartColour}
      seriesData={seriesList}
      customTitle={customTitle}
      tooltip={{
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },

        formatter: function (param, index) {
          let text =
            param.name +
            "<br />" +
            param.marker +
            param.seriesName +
            ": " +
            param.data.value;

          if (typeof unit !== "undefined" && unit !== null && unit !== "") {
            text += unit;
          }

          text += " (" + param.percent + "%)";
          return text;
        },
      }}
      customLabel={
        customLabelFalse
          ? {
              show: true,
              type: "scroll",
              formatter: "{c}",
            }
          : true
      }
      customLegend={
        isCustomLegend
          ? {
              type: "scroll",
              orient: "horizontal",
              bottom: "bottom",
              // formatter: (name) => {
              //   let strLength = name?.length;
              //   let strText = name?.substring(0, 15);
              //   return strLength < 16 ? strText : strText + "...";
              // },
            }
          : { left: "left" }
      }
      childfun={getPieElementData}
      isClickAble={isClick}
      height={height}
    ></EchartPieChart>
  );
});

export function BasicBarChartCardWithCustomToolTip(props) {
  return (
    <BasicBarChartWithCustomToolTip
      width={props.width}
      height={props.height}
      customToolTip={props.customToolTip}
      legendFlag={props.legendFlag}
      yAxisName={props.yAxisName}
      xAxisName={props.xAxisName}
      category={props.category ? props.category : []}
      seriesData={props.seriesData}
      valueWithUnit={props.valueWithUnit}
      minMaxData={props.minMaxData}
      noRecordText={props.noRecordText}
    />
  );
}

export function BasicBarChartCardWithCustomToolTipYaxis(props) {
  return (
    <BasicBarChartWithCustomToolTipYaxis
      height={props.height}
      customToolTip={props.customToolTip}
      legendFlag={props.legendFlag}
      yAxisName={props.yAxisName}
      xAxisName={props.xAxisName}
      category={props.category ? props.category : []}
      seriesData={props.seriesData}
    />
  );
}

export function MultipleBarChartCard(props) {
  return (
    <div className="MultipleBarChart">
      <EchartMultipleBarChart
        height={props.height}
        customToolTip={props.customToolTip}
        legendFlag={props.legendFlag}
        yAxisName={props.yAxisName}
        xAxisName={props.xAxisName}
        minMaxData={props.minMaxData}
        customFuelToolTip={props.customFuelToolTip}
        category={props.category ? props.category : []}
        customTooltipCoalGrade={props?.customTooltipCoalGrade}
        inverseType={props.inverseType}
        filterType={props.filterType}
        seriesData={
          props.seriesData
            ? props.seriesData
            : [
                // {
                //   color: "#5f6bc2",
                //   name: "Planned Wells",
                //   type: "bar",
                //   barGap: 0,
                //   label: {
                //     normal: {
                //       show: false,
                //       position: "insideBottom",
                //       distance: 5,
                //       align: "left",
                //       verticalAlign: "middle",
                //       rotate: 90,
                //       formatter: "{c}  {name|{a}}",
                //       fontSize: 14,
                //       fontWeight: "bold",
                //       rich: {
                //         name: {
                //           color: "#fff",
                //         },
                //       },
                //     },
                //   },
                //   data: [320, 332, 301, 334, 390, 350, 215],
                // },
              ]
        }
      />
    </div>
  );
}

export function BasicBarChartWithLabelCard(props) {
  return (
    <BasicBarChartWithLabel
      tooltip={props.customToolTip}
      customeTool={props.customeTool}
      legendFlag={props.legendFlag}
      yAxisName={props.yAxisName}
      xAxisName={props.xAxisName}
      category={props.category ? props.category : []}
      seriesData={
        props.seriesData
          ? props.seriesData
          : [
              // {
              //   color: "#5f6bc2",
              //   name: "Planned Wells",
              //   type: "bar",
              //   barGap: 0,
              //   label: {
              //     normal: {
              //       show: false,
              //       position: "insideBottom",
              //       distance: 5,
              //       align: "left",
              //       verticalAlign: "middle",
              //       rotate: 90,
              //       formatter: "{c}  {name|{a}}",
              //       fontSize: 14,
              //       fontWeight: "bold",
              //       rich: {
              //         name: {
              //           color: "#fff",
              //         },
              //       },
              //     },
              //   },
              //   data: [320, 332, 301, 334, 390, 350, 215],
              // },
            ]
      }
    />
  );
}
