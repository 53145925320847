import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import {
  fuelDispenseApis,
  tabUrl,
  fuelDispenseColumnFields,
  fuelManagementFilterForm,
  SidebarData,
  mobileTabUrl,
  mobileSidebarData,
  statusUpdateLink,
  reportEditLink,
} from "./DATA";
import filter from "../../../Assets/Images/filter.svg";
import { useForm } from "../../../_helpers/hooks";
import {
  IS_MOBILE,
  findUserPermissionedTabList,
} from "../../../_helpers/helper";
import "./../../FuelManagementSystem/fuelmgmt.scss";
import FuelDispenseView from "../../FuelManagementSystem/FuelDispenseView";
import FuelDispenseFilterForm from "../../FuelManagementSystem/FuelDispenseFilterForm";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import {
  getSafetyManagementReportedIncidentListWithPagination,
  getSafetyManagementReportedIncidentExcel,
} from "../../../services/_safetyManagement/safetyManagement";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import SafetyManagementView from "../SafetyManagementView";
import SafetyManagementFilterForm from "../SafetyManagementFilterForm";
import { getPermission, permissionCheck } from "../../../_services/Auth/helper";
const ReportedIncidentList = () => {
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(fuelManagementFilterForm);

  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(
      IS_MOBILE() ? mobileTabUrl.safetyManagement : tabUrl.safetyManagement
    )
  );

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const checkPermission = getPermission();

  const getAll = async () => {
    if (!checkPermission["safety_admin"]) return;
    const response =
      await getSafetyManagementReportedIncidentListWithPagination(
        "page=1&limit=2"
      );

    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} hideLeftRightBtn />;
  };

  const onResetFilter = () => {
    setForm({ fuelManagementFilterForm });
    setGetAllFilter();
  };

  useEffect(() => {
    getAll();
  }, []);

  const viewAsPopup = (form) => {
    return <SafetyManagementView form={form} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
          aria-expanded={open}
        >
          <img className="img-fluid" alt="fliterIcon" src={filter} />
        </Button>
        <SafetyManagementFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          isOpen={open}
          setOpen={setOpen}
          onSubmitFilter={onSubmitFilter}
        />
      </div>
    );
  };

  useEffect(() => {
    let currentUrl = "";
    setCustomTabs((pre) => {
      return pre.map((d) => {
        if (d.to === usrl?.pathname) {
          currentUrl = d.to;
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    currentUrl ? navigate(currentUrl) : navigate(customTabs[0].to);
    getAll();
  }, []);

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else if (key === "is_investigated" || key === "is_capa")
          filter += `&search[${key}]=${1}`;
        else filter += `&search[${key}]=${element}`;
      }
    }

    setGetAllFilter(filter);
  }, [filterStatus]);

  const statusByOptions = (row) => {
    return row?.status === "reported" && permissionCheck(["safety_admin"]);
  };
  return (
    <div className={IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}>
      <SafetySideBar
        SidebarData={IS_MOBILE() ? mobileSidebarData : SidebarData}
        pageTitle={"My Approval Actions"}
        goBack={() =>
          navigate("/mobile/safety-management-system/incident-management")
        }
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={fuelDispenseColumnFields}
                apis={fuelDispenseApis}
                title="Data"
                viewTitle={"Incident Details"}
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                // pageLink={statusUpdateLink}
                primaryKey="id"
                pagination
                getAllFilter={getAllFilter}
                hideAddButton
                downloadExcel={getSafetyManagementReportedIncidentExcel}
                childrenAfterAddButton={tabButtons}
                breadCrumb={[
                  { name: "Safety Management", path: "#" },
                  {
                    name: "Incident Management",
                    path: "/safety-management-system/incident-management",
                  },
                  {
                    name: "My Approval Actions",
                    path: "#",
                  },
                ]}
                listname="incident"
                modalSize={"xl"}
                customTableTitle={"Blank"}
                icons
                statusByOptions={statusByOptions}
                updatePageLink={statusUpdateLink}
                editPageLink={reportEditLink}
                updateStatus
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ReportedIncidentList;
