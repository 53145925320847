import { Validation } from "../../../../_helpers/validation/Validation";

export const airQualityFormLabel = {
  Ambient_Air_Quality_CAAQMS: {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_category: "Station Category",
    air_quality_date: "Air Quality Date",
    pm10: "PM 10 - Concentration",
    pm25: "PM 2.5 - Concentration",
    so2: "SO2 - Concentration",
    no: "NO - Concentration",
    nox: "NOx - Concentration",
    no2: "NO2 - Concentration",
    co: "CO - Concentration",
  },
  micro_meteorological_CAAQMS: {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_category: "Station Category",
    date: "Date",
    temperature: "Temperature",
    relative_humidity: "Relative Humidity",
    solar_radiation: "Solar Radiation",
    wind_speed: "Wind Speed",
    wind_direction: "Wind Direction",
    rain_fall: "Rainfall",
  },
  performance_report_of_pollution: {
    subsidiary: "Subsidiary",
    project: "Project",
    equipment: "Equipment",
    type_of_equipment: "Type of Equipment",
    date: "Date",
    working_hours: "Working Hours",
    idle_hours: "Idle Hours",
    maintenance_hours: "Maintenance Hours",
    breakdown_hours: "Breakdown Hours",
    remarks: "Remarks",
  },
  Ambient_Air_Quality_CMPDIL: {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_code: "Station Code",
    station_category: "Category of Station",
    air_quality_date: "Date of Sampling",
    spm: "SPM - Concentration",
    pm10: "PM 10 - Concentration",
    pm25: "PM 2.5 - Concentration",
    so2: "SO2 - Concentration",
    no2: "NO2 - Concentration",
  },
  Water_Quality_CMPDIL: {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_code: "Station Code",
    // tss_permissible: "TSS - Permissible Limit",
    date_of_sampling: "Date of Sampling",
    tss: "TSS",
    ph: "pH",
    og: "O&G",
    cod: "COD",
    bod: "BOD",
    latitude: "Latitude",
    longitude: "Longitude",
  },
  Noise_Level_CMPDIL: {
    subsidiary: "Subsidiary",
    project: "Project",
    station_name: "Station Name",
    station_code: "Station Code",
    station_category: "Category of Station",
    date_of_sampling: "Date of Sampling",
    day_time_level: "Day Time - Level",
    night_time_level: "Night Time - Level",
  },
  Ground_Water_level_CMPDIL: {
    subsidiary: "Subsidiary",
    project: "Project",
    well_type: "Well Type",
    code: "Code",
    quarter: "Quarter",
    // pre_monsoon: "Pre-Monsoon",
    // monsoon: "Monsoon",
    // post_monsoon: "Post Monsoon",
    // winter: "Winter",
    water_level: "Water Level",
  },
  Land_Reclamation_Status_CMPD: {
    subsidiary: "Subsidiary",
    project: "Project",
    total_lease_hold_area: "Total Lease Hold Area",
    year: "Year",
    active_area: "Area under Active Mining",
    backfilling_area: "Area under Back Filling",
    backfilled_area_plantation: "Plantation on Excavated / Backfilled Area",
    burden_dumps_area_Plantation: "Plantation on External Over Burden Dumps",
    social_forestry: "Social Forestry, Avenue",
  },
  Plantation_Project: {
    subsidiary: "Subsidiary",
    project: "Project",
    year: "Year",
    plantation_on_external_dumps: "Plantation on External Dumps",
    plantation_on_internal_dumps: "Plantation on Internal Dumps",
    plantation_on_plain_land: "Plantation on Plain Land",
  },
  power_consumption_report: {
    subsidiary: "Subsidiary",
    project: "Project",
    feeder: "Feeder",
    date: "Power Consumption Date",
    "7kwh": "07:00 kwh",
    "7kvah": "07:00 kvah",
    "8kwh": "08:00 kwh",
    "8kvah": "08:00 kvah",
    "9kwh": "09:00 kwh",
    "9kvah": "09:00 kvah",
    "10kwh": "10:00 kwh",
    "10kvah": "10:00 kvah",
    "11kwh": "11:00 kwh",
    "11kvah": "11:00 kvah",
    "12kwh": "12:00 kwh",
    "12kvah": "12:00 kvah",
    "13kwh": "13:00 kwh",
    "13kvah": "13:00 kvah",
    "14kwh": "14:00 kwh",
    "14kvah": "14:00 kvah",
    "15kwh": "15:00 kwh",
    "15kvah": "15:00 kvah",
    "16kwh": "16:00 kwh",
    "16kvah": "16:00 kvah",
    "17kwh": "17:00 kwh",
    "17kvah": "17:00 kvah",
    "18kwh": "18:00 kwh",
    "18kvah": "18:00 kvah",
    "19kwh": "19:00 kwh",
    "19kvah": "19:00 kvah",
    "20kwh": "20:00 kwh",
    "20kvah": "20:00 kvah",
    "21kwh": "21:00 kwh",
    "21kvah": "21:00 kvah",
    "22kwh": "22:00 kwh",
    "22kvah": "22:00 kvah",
    "23kwh": "23:00 kwh",
    "23kvah": "23:00 kvah",
    "24kwh": "24:00 kwh",
    "24kvah": "24:00 kvah",
    "1kwh": "01:00 kwh",
    "1kvah": "01:00 kvah",
    "2kwh": "02:00 kwh",
    "2kvah": "02:00 kvah",
    "3kwh": "03:00 kwh",
    "3kvah": "03:00 kvah",
    "4kwh": "04:00 kwh",
    "4kvah": "04:00 kvah",
    "5kwh": "05:00 kwh",
    "5kvah": "05:00 kvah",
    "6kwh": "06:00 kwh",
    "6kvah": "06:00 kvah",
  },
};

export const airQualityFieldRules = [
  {
    field_name: "air_quality_date",
    label: airQualityFormLabel.air_quality_date,
    type: "text",
    isRequired: true,
  },
  {
    field_name: "cluster",
    label: airQualityFormLabel.cluster,
    type: "text",
    isRequired: true,
  },
  {
    field_name: "mine",
    label: airQualityFormLabel.mine,
    type: "text",
    isRequired: true,
  },
];

export const areaQualityValidation = (form) => {
  let errorObj = Validation(form, airQualityFieldRules);
  return errorObj;
};
