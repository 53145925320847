import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { sections } from "../ContractorPerformance/DATA";
import { production_types } from "./DATA";
import { capitalizeName } from "../../../_helpers/helper";
const NitListView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">Nit Master Details</p>
            <Row>
              <Col>
                <label>Subsidiary</label>
                <p className="content">{form?.siteData?.parentSite?.name}</p>
              </Col>
              <Col>
                <label>Mine</label>
                <p className="content">{form?.siteData?.name}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Contractor Code</label>
                <p className="content">{form?.contractor?.contractor_code}</p>
              </Col>
              <Col>
                <label>Contractor</label>
                <p className="content">{form?.contractor?.contractor}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Contractor Person</label>
                <p className="content">{form?.contractor?.contractor_person}</p>
              </Col>
              {/* <Col>
                <label>NIT Number</label>
                <p className="content">{form?.nit}</p>
              </Col> */}

              <Col>
                <label>NIT / PO</label>
                <p className="content">
                  {form?.nit} /{" "}
                  {form?.poDetails?.map((item) => item?.po).toString()}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Start Date</label>
                <p className="content">{DDMMYYYYFormat(form?.startDate)}</p>
              </Col>
              <Col>
                <label>End Date</label>
                <p className="content">{DDMMYYYYFormat(form?.endDate)}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <label>Contract ID</label>
                <p className="content">{form?.tender_id}</p>
              </Col>
            
              <Col md={6}>
                <label>Production Type</label>
                <p className="content">
                  {form?.production_type
                    ? production_types[form?.production_type]
                    : ""}
                </p>
              </Col>

              <Col md={6}>
                <label>QPD Proposed OB (Monthly)</label>
                <p className="content">{form?.compliance}</p>
              </Col>
              <Col md={6}>
                <label>QPD Proposed Coal (Monthly)</label>
                <p className="content">{form?.qpd_proposed}</p>
              </Col>
              <Col md={6}>
                <label>Section</label>
                <p className="content">
                  {form?.sectionData?.name
                    ? capitalizeName(form?.sectionData?.name)
                    : "Not Applicable"}
                </p>
              </Col>
              <Col md={6}>
                <label>Excavator</label>
                <p className="content">
                  {form?.excavator !== null ? form?.excavator : "N/A"}
                </p>
              </Col>
              <Col md={6}>
                <label>Tipper</label>
                <p className="content">
                  {form?.tipper !== null ? form?.tipper : "N/A"}
                </p>
              </Col>

              <Col md={6}>
                <label>Drill</label>
                <p className="content">
                  {form?.drill !== null ? form?.drill : "N/A"}
                </p>
              </Col>
              <Col md={6}>
                <label>Dozer</label>
                <p className="content">
                  {form?.dozer !== null ? form?.dozer : "N/A"}
                </p>
              </Col>
              <Col md={6}>
                <label>Water Tanker</label>
                <p className="content">
                  {form?.waterTanker !== null ? form?.waterTanker : "N/A"}
                </p>
              </Col>

              <Col md={6}>
                <label>Grader</label>
                <p className="content">
                  {form?.grader !== null ? form?.grader : "N/A"}
                </p>
              </Col>

              <Col md={6}>
                <label>Pay Loader</label>
                <p className="content">
                  {form?.payLoader !== null ? form?.payLoader : "N/A"}
                </p>
              </Col>
              <Col md={6}>
                <label>Crusher</label>
                <p className="content">
                  {form?.crusher !== null ? form?.crusher : "N/A"}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default NitListView;
