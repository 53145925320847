import React, { useState } from "react";
import { Col, Modal, ModalBody } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat, DDMMYYYYHHMMAFormat } from "../../_helpers/dateHelper";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { PARSE_IMG_URL } from "../../_helpers/commonHelper";
import CreatedByCreatedAt from "../../components/Common/CreatedByCreatedAt";

const FuelDispenseView = ({ form }) => {
  const [imageShowManageModal, setImageShowManageModal] = useState(false);
  const [imageShowManageUrl, setImageShowManageUrl] = useState("");

  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">Person Details</p>
            <Row>
              <Col sm={4}>
                <label>Person GPS Mandatory</label>
                <p className="content">
                  {!!form?.is_person_gps_mandatory ? "Yes" : "No"}
                </p>
              </Col>
              <Col sm={4}>
                <label>Person Name</label>
                <p className="content">
                  {form?.dispenser_data?.firstName}{" "}
                  {form?.dispenser_data?.lastName}
                </p>
              </Col>
              <Col sm={4}>
                <label>Person Email</label>
                <p className="content">{form?.dispenser_data?.email}</p>
              </Col>
              <Col sm={4}>
                <label>Person Latitude</label>
                <p className="content">{form?.person_latitude}</p>
              </Col>
              <Col sm={4}>
                <label>Person Longitude</label>
                <p className="content">{form?.person_longitude}</p>
              </Col>
            </Row>
          </div>
          {form.bower_location === null ? (
            <div className="previewContent">
              <p className="head">Fuel Station Details</p>
              <Row>
                <Col sm={4}>
                  <label>Tank Name</label>
                  <p className="content">{form?.tank?.name}</p>
                </Col>
                {form.site.parentSite.name === "SECL" &&
                  form.dispensedByBowser === null && (
                    <Col sm={4}>
                      <label>Workshop</label>
                      <p className="content">
                        {form?.workshop?.name || "Not Available"}
                      </p>
                    </Col>
                  )}
                <Col sm={4}>
                  <label>Fuel Station</label>
                  <p className="content">
                    {form?.fuel_location?.fuel_location_name}
                  </p>
                </Col>
                <Col sm={4}>
                  <label>Fuel Station Latitude</label>
                  <p className="content">{form?.fuel_location?.lat}</p>
                </Col>
                <Col sm={4}>
                  <label>Fuel Station Longitude</label>
                  <p className="content">{form?.fuel_location?.long}</p>
                </Col>
                <Col sm={4}>
                  <label>Mine</label>
                  <p className="content">{form?.site?.name}</p>
                </Col>
                <Col sm={4}>
                  <label>Subsidiary</label>
                  <p className="content">{form?.site?.parentSite?.name}</p>
                </Col>
                <Col sm={4}>
                  <label>Person's Distance From Fuel Location (M)</label>
                  <p
                    className={
                      form?.personDistance > 10
                        ? "content text-danger"
                        : "content"
                    }
                  >
                    {form?.personDistance}
                  </p>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="previewContent">
              <p className="head">Fuel Station Details (Bowser) </p>
              <Row>
                <Col sm={4}>
                  <label>Tank Name</label>
                  <p className="content">{form?.tank?.name}</p>
                </Col>
                <Col sm={4}>
                  <label>Bowser ID</label>
                  <p className="content">{form?.bower_location?.hemm_id}</p>
                </Col>
                <Col sm={4}>
                  <label>Equipment Type</label>
                  <p className="content">
                    {form?.bower_location?.fms_vehicle_type}
                  </p>
                </Col>
                <Col sm={4}>
                  <label>Make</label>
                  <p className="content">{form?.bower_location?.make}</p>
                </Col>
                <Col sm={4}>
                  <label>Model</label>
                  <p className="content">{form?.bower_location?.model}</p>
                </Col>

                <Col sm={4}>
                  <label>Bowser Type</label>
                  <p className="content">
                    {form?.bower_location?.fms_vehicle_type}
                  </p>
                </Col>
                <Col sm={4}>
                  <label>Bowser Name</label>
                  <p className="content">
                    {form?.bower_location?.fms_vehicle_name}
                  </p>
                </Col>
              </Row>
            </div>
          )}

          <div className="previewContent">
            <p className="head">
              {form?.fuel_dispense_hemm_datas?.isBowser === "1"
                ? "Bowser Vehicle Details"
                : "HEMM Vehicle Details"}
            </p>
            <Row>
              <Col sm={4}>
                <label>
                  {form?.fuel_dispense_hemm_datas?.isBowser === "1"
                    ? "Bowser ID"
                    : "HEMM ID"}
                </label>
                <p className="content">
                  {form?.fuel_dispense_hemm_datas?.hemm_id}
                </p>
              </Col>
              <Col sm={4}>
                <label>Equipment Type</label>
                <p className="content">
                  {form?.fuel_dispense_hemm_datas?.fms_vehicle_type}
                </p>
              </Col>
              <Col sm={4}>
                <label>Make</label>
                <p className="content">
                  {form?.fuel_dispense_hemm_datas?.make}
                </p>
              </Col>
              <Col sm={4}>
                <label>Model</label>
                <p className="content">
                  {form?.fuel_dispense_hemm_datas?.model}
                </p>
              </Col>

              <Col sm={4}>
                <label>
                  {form?.fuel_dispense_hemm_datas?.isBowser === "1"
                    ? "Bowser Type"
                    : "FMS Vehicle Type"}
                </label>
                <p className="content">
                  {form?.fuel_dispense_hemm_datas?.fms_vehicle_type}
                </p>
              </Col>
              <Col sm={4}>
                <label>
                  {" "}
                  {form?.fuel_dispense_hemm_datas?.isBowser === "1"
                    ? "Bowser Name"
                    : "FMS Vehicle Name"}
                </label>
                <p className="content">
                  {form?.fuel_dispense_hemm_datas?.fms_vehicle_name}
                </p>
              </Col>
              <Col sm={4}>
                <label>Is This Bowser</label>
                <p className="content">
                  {form?.fuel_dispense_hemm_datas?.isBowser === "0"
                    ? "No"
                    : "Yes"}
                </p>
              </Col>
              {!!form?.hemm_fms_verify && (
                <>
                  <Col sm={4}>
                    <label>
                      {form?.fuel_dispense_hemm_datas?.isBowser === "1"
                        ? "Bowser Verify"
                        : "HEMM FMS Verify"}
                    </label>
                    <p
                      className="content"
                      style={{ textTransform: "capitalize" }}
                    >
                      {!!form?.hemm_fms_verify ? "Yes" : ""}{" "}
                    </p>
                  </Col>
                  <Col sm={4}>
                    <label>
                      {form?.fuel_dispense_hemm_datas?.isBowser === "1"
                        ? "Bower Latitude"
                        : "FMS HEMM Latitude"}
                    </label>
                    <p className="content">{form?.fms_hemm_latitude}</p>
                  </Col>
                  <Col sm={4}>
                    <label>
                      {form?.fuel_dispense_hemm_datas?.isBowser === "1"
                        ? "FMS Bowser Longitude"
                        : "FMS Hemm Longitude"}
                    </label>
                    <p className="content">{form?.fms_hemm_longitude}</p>
                  </Col>
                  <Col sm={4}>
                    <label>Last Captured Date</label>
                    <p className="content">
                      {DDMMYYYYFormat(form?.latitude_captured_date)}
                    </p>
                  </Col>
                  {/* <Col sm={4}>
                    <label>FMS HEMM's Distance From Fuel Location (M)</label>
                    <p
                      className={
                        form?.vehicleDistance > 10
                          ? "content text-danger"
                          : "content"
                      }
                    >
                      {form?.vehicleDistance}
                    </p>
                  </Col>
                  <Col sm={4}>
                    <label>FMS Hmr Reading</label>
                    <p className="content">{form?.fms_hmr_reading}</p>
                  </Col>
                  <Col sm={4}>
                    <label>FMS Odometer Reading</label>
                    <p className="content">{form?.fms_odometer_reading}</p>
                  </Col> */}
                </>
              )}
            </Row>
          </div>
          <div className="previewContent">
            <p className="head">Before fuel dispensed details</p>
            <Row>
              <Col sm={4}>
                <label>Current HMR Reading</label>
                <p className="content">
                  {form?.user_entered_hmr_reading
                    ? form?.user_entered_hmr_reading
                    : "N/A"}
                </p>
              </Col>
              <Col sm={4}>
                <label>Last HMR Reading</label>
                <p className="content">
                  {form?.last_hmr_reading ? form?.last_hmr_reading : "N/A"}
                </p>
              </Col>
              <Col sm={4}>
                <label>HMR Hours</label>
                <p className="content">
                  {form?.user_entered_hmr_hours
                    ? form?.user_entered_hmr_hours
                    : "N/A"}
                </p>
              </Col>
              <Col sm={4}>
                <label>Current Odometer Reading</label>
                <p className="content">
                  {form?.user_entered_odometer_reading
                    ? form?.user_entered_odometer_reading
                    : "N/A"}
                </p>
              </Col>
              <Col sm={4}>
                <label>Last Odometer Reading</label>
                <p className="content">
                  {form?.last_odometer_reading
                    ? form?.last_odometer_reading
                    : "N/A"}
                </p>
              </Col>
              <Col sm={4}>
                <label>Odometer Kms</label>
                <p className="content">
                  {form?.user_entered_odometer_kms
                    ? form?.user_entered_odometer_kms
                    : "N/A"}
                </p>
              </Col>
              <Col sm={4}>
                <label>Fuel Meter Reading</label>
                <p className="content">
                  {form?.fuel_meter ? form?.fuel_meter : "N/A"}
                </p>
              </Col>
              <Col sm={6}>
                <div className="fuelImgSec">
                  {form?.dispancer_pre_image_data?.document_name && (
                    <img
                      onClick={() => {
                        setImageShowManageModal(true);
                        setImageShowManageUrl(
                          PARSE_IMG_URL(
                            URL_CONFIG.DEV_URL,
                            form?.dispancer_pre_image_data?.document_name
                          )
                        );
                      }}
                      src={PARSE_IMG_URL(
                        URL_CONFIG.DEV_URL,
                        form?.dispancer_pre_image_data?.document_name
                      )}
                      className="img-fluid"
                      alt="IMG"
                      data-testid="dispancer-pre-image-data"
                    />
                  )}
                </div>
              </Col>
              <Col sm={6}>
                <div className="fuelImgSec">
                  {form?.hemm_pre_image_data?.document_name && (
                    <img
                      onClick={() => {
                        setImageShowManageModal(true);
                        setImageShowManageUrl(
                          PARSE_IMG_URL(
                            URL_CONFIG.DEV_URL,
                            form?.hemm_pre_image_data?.document_name
                          )
                        );
                      }}
                      // src={form?.dispancer_pre_image_data?.document_name}
                      src={PARSE_IMG_URL(
                        URL_CONFIG.DEV_URL,
                        form?.hemm_pre_image_data?.document_name
                      )}
                      className="img-fluid"
                      alt="IMG"
                      data-testid="hemm-pre-image-data"
                    />
                  )}
                </div>
              </Col>
              {form?.fuel_meter && (
                <Col sm={12} className="mt-2">
                  <label>Fuel Meter Reading</label>
                  <p className="content">{form?.fuel_meter}</p>
                </Col>
              )}
              <Col sm={6}>
                <div className="fuelImgSec">
                  {form?.fuel_meter_img_data?.document_name && (
                    <img
                      onClick={() => {
                        setImageShowManageModal(true);
                        setImageShowManageUrl(
                          PARSE_IMG_URL(
                            URL_CONFIG.DEV_URL,
                            form?.fuel_meter_img_data?.document_name
                          )
                        );
                      }}
                      src={PARSE_IMG_URL(
                        URL_CONFIG.DEV_URL,
                        form?.fuel_meter_img_data?.document_name
                      )}
                      className="img-fluid"
                      alt="IMG"
                      data-testid="dispancer-pre-image-data"
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div className="previewContent">
            <p className="head">Dispensed details</p>
            <Row>
              <Col sm={6}>
                <label>Dispensed Quantity</label>
                <p className="content">{form?.fuel_dispensing_qty}</p>
              </Col>
              <Col sm={6}>
                <label>Dispensed Date</label>
                <p className="content">
                  {DDMMYYYYHHMMAFormat(form?.createdAt)}
                </p>
              </Col>

              <Col sm={6}>
                <label>Receiver</label>
                <p className="content">{form?.receiver}</p>
              </Col>
              <Col sm={6}>
                <label>Issuer</label>
                <p className="content">{form?.issuer}</p>
              </Col>
            </Row>
          </div>

          <div className="previewContent">
            <p className="head">After fuel dispensed details</p>
            <Row>
              {form?.dispancer_post_image_data?.document_name && (
                <Col sm={6}>
                  <div className="fuelImgSec">
                    <img
                      onClick={() => {
                        setImageShowManageModal(true);
                        setImageShowManageUrl(
                          PARSE_IMG_URL(
                            URL_CONFIG.DEV_URL,
                            form?.dispancer_post_image_data?.document_name
                          )
                        );
                      }}
                      src={PARSE_IMG_URL(
                        URL_CONFIG.DEV_URL,
                        form?.dispancer_post_image_data?.document_name
                      )}
                      className="img-fluid"
                      alt="IMG"
                      data-testid="dispancer-post-image-data"
                    />
                  </div>
                </Col>
              )}
              {form?.hemm_post_image_data?.document_name && (
                <Col sm={6}>
                  <div className="fuelImgSec">
                    <img
                      onClick={() => {
                        setImageShowManageModal(true);
                        setImageShowManageUrl(
                          PARSE_IMG_URL(
                            URL_CONFIG.DEV_URL,
                            form?.hemm_post_image_data?.document_name
                          )
                        );
                      }}
                      src={PARSE_IMG_URL(
                        URL_CONFIG.DEV_URL,
                        form?.hemm_post_image_data?.document_name
                      )}
                      className="img-fluid"
                      alt="IMG"
                      data-testid="hemm-post-image-data"
                    />
                  </div>
                </Col>
              )}
              {form?.fuel_meter_after && (
                <Col sm={6} className="mt-2">
                  <label>Fuel Meter Reading</label>
                  <p className="content">{form?.fuel_meter_after}</p>
                </Col>
              )}
              {form?.fuel_meter_img_after_data?.document_name && (
                <Col sm={6}>
                  <div className="fuelImgSec">
                    <img
                      onClick={() => {
                        setImageShowManageModal(true);
                        setImageShowManageUrl(
                          PARSE_IMG_URL(
                            URL_CONFIG.DEV_URL,
                            form?.fuel_meter_img_after_data?.document_name
                          )
                        );
                      }}
                      src={PARSE_IMG_URL(
                        URL_CONFIG.DEV_URL,
                        form?.fuel_meter_img_after_data?.document_name
                      )}
                      className="img-fluid"
                      alt="IMG"
                      data-testid="dispancer-pre-image-data"
                    />
                  </div>
                </Col>
              )}
              <Col sm={6} className="mt-3">
                <label>After Fuel Dispense</label>
                <p className="content">{form?.afterFuelDispense}</p>
              </Col>
            </Row>
          </div>
          <CreatedByCreatedAt id={form?.id} table={"fuel_dispense_datas"} />
        </div>
      </Modal.Body>

      <Modal
        show={imageShowManageModal}
        onHide={() => setImageShowManageModal(false)}
        size="lg"
        centered
      >
        <ModalBody className="safetyModal">
          <div
            className="closeIcon"
            onClick={() => {
              setImageShowManageModal(false);
            }}
            data-testid="close-popup"
          >
            <IoIosCloseCircleOutline />
          </div>
          <div className="popupImgVideo text-center">
            <img className="img-fluid" src={imageShowManageUrl} />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FuelDispenseView;
