import SafetySideBar from "../../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { SidebarData } from "./DATA";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { FaArrowRight } from "react-icons/fa";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import HelpManualPopup from "../../UserManagement/HelpManualPopup";
import HelpManualCommonComponent from "../../ContractorManagement/CommonComponent/HelpManualCommonComponent";


const HelpManualEnv = () => {
 
  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
     <HelpManualCommonComponent moduleName={"Environment Management"} />
    </div>
  );
};

export default HelpManualEnv;
