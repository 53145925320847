import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

export const getAllUploadedFiles = async (filter) => {
  let url = "/environment-management/uploaded-files";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      totalCount: data?.totalCount ? data.totalCount : 0,
      data: data?.uploadedFiles ? data.uploadedFiles : [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllUploadedSapFiles = async (filter) => {
  let sapUrl = "/environment-management/uploaded-files";
  if (filter) sapUrl += "?" + filter;
  else return;

  try {
    const response = await RestMethod.GET(sapUrl);
    const { message, status, data } = response.data;
    return {
      data: data?.uploadedFiles ? data.uploadedFiles : [],
      totalCount: data?.totalCount ? data.totalCount : 0,
      status,
      message,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllSapFiles = async (filter) => {
  let url = "/common/sap-files";
  if (filter) url += "?" + filter;
  else return;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      totalCount: data?.totalCount ? data.totalCount : 0,
      data: data?.uploadedFiles ? data.uploadedFiles : [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllUploadedData = async (filter) => {
  let sapUploadedFilesUrl = "environment-management/uploaded-files-all-data";

  if (filter) sapUploadedFilesUrl = sapUploadedFilesUrl + "?" + filter;

  try {
    const res = await RestMethod.GET(sapUploadedFilesUrl);
    const { message, status, data } = res.data;
    return {
      data: data?.records ? data.records : [],
      status,
      totalCount: data?.totalCount ? data.totalCount : 0,
      message,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteUploadedData = async (id, fileType) => {
  let url =
    "/environment-management/uploaded-files-data-delete/" +
    id +
    "?fileType=" +
    fileType;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getAllSites = async (filter) => {
  let url = "/site";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      data: data,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

// export const uploadFile = async (data) => {
//   let url = "/environment-management/uploaded-files/create";
//   try {
//     const response = await RestMethod.POST(url, data);
//     return {
//       response,
//     };
//   } catch (error) {
//     console.log("error detected while sending data from api", error);
//     return {
//       data: [],
//     };
//   }
// };
export const uploadFile = async (data) => {
  let url = "environment-management/uploaded-files/upload";
  try {
    const response = await RestMethod.POST(url, data);
    return {
      response,
    };
  } catch (error) {
    console.log("error detected while sending data from api", error);
    return {
      data: [],
    };
  }
};
export const uploadFileById = async (data, id) => {
  let url = "environment-management/uploaded-files/upload/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return {
      response,
    };
  } catch (error) {
    console.log("error detected while sending data from api", error);
    return {
      data: [],
    };
  }
};

export async function envDashboard(fileType, filter) {
  try {
    const response = await RestMethod.GET(
      `/environment-management/env-dashboard/${fileType}?${
        filter ? filter : ""
      }`
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function envSingleDashboard(fileType, filter) {
  try {
    const response = await RestMethod.GET(
      `/environment-management/single-env-dashboard/${fileType}?${filter}`
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export const uploadSapFile = async (data) => {
  let url = "/common/upload-sap-data";
  if (data?.fileType === "contractor_hindrance") url = "/contractor/upload-sap";
  if (
    [
      "fuel_inventory_sap_data",
      "fuel_receipt_sap_datas",
      "sap_fuel_dispense",
    ].includes(data?.fileType)
  )
    url = "/fuel-dispence/upload-sap";
  try {
    const response = await RestMethod.POST(url, data);
    return {
      response,
    };
  } catch (error) {
    console.log("error detected while sending data from api", error);
    return {
      data: [],
    };
  }
};

export const SapUploadService = {
  sapBlukUpload,
};

async function sapBlukUpload(data) {
  try {
    const response = await RestMethod.POST("common/save-sap-data", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export const getAllSapUploadedData = async (filter) => {
  let sapUrlFileData = "common/uploaded-files-sap-all-data";

  if (filter) sapUrlFileData += "?" + filter;

  try {
    const sapData = await RestMethod.GET(sapUrlFileData);
    const { message, data, status } = sapData.data;
    return {
      totalCount: data?.totalCount ? data.totalCount : 0,
      status,
      message,
      data: data?.records ? data.records : [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deleteSapUploadedData = async (id, fileType) => {
  let url =
    "/environment-management/uploaded-files-data-delete/" +
    id +
    "?fileType=" +
    fileType;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllDevice = async (filter) => {
  let url = "/environment-management/env-device";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      totalCount: data?.data?.totalCount,
      data: data?.data?.accident,
      lastSync: response?.data?.data?.data?.lastSync,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const exportExportDevice = async (filter) => {
  try {
    let url = URL_CONFIG.DEV_URL + "environment-management/env-device-export";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const getDeviceByDataId = async (id, filter) => {
  let url = "/environment-management/env-device/" + id;
  if (filter) url = url + "?" + filter;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    const d = {
      status,
      message,
      data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateDevice = async (data, id) => {
  let url = "/environment-management/env-device/" + id;

  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export async function getCategoryByType(fileType) {
  try {
    const response = await RestMethod.GET(
      `/environment-management/station-category-by-type/${fileType}`
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
