import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../_helpers/dateHelper";
import { coalGradeLabel, modeOfDispatchLabel } from "../DATA";
import "./refreeLabResult.scss";
import CreatedByCreatedAt from "../../../components/Common/CreatedByCreatedAt";

const RefereeLabResultView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">Coal Dispatch Related Data</p>
            <Row>
              <Col sm={6}>
                <label>{coalGradeLabel?.mode_of_dispatch}</label>
                <p className="content">
                  {form?.mode_of_dispatch &&
                    modeOfDispatchLabel[form?.mode_of_dispatch]}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.rr_or_do_number}</label>
                <p className="content">{form?.rr_or_do_number}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.rr_or_do_date}</label>
                <p className="content">
                  {form?.rr_or_do_date
                    ? DDMMYYYYFormat(form?.rr_or_do_date)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.declared_grade}</label>
                <p className="content">{form?.declared_grade}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.name_of_referee_agency}</label>
                <p className="content">{form?.name_of_referee_agency}</p>
              </Col>
            </Row>
          </div>
          <div className="previewContent">
            <h6>Referee Lab Quality Results</h6>

            {/* <div className="previewContent"> */}
            <h6>On Air Dry Basis</h6>
            <Row>
              <Col sm={6}>
                <label>
                  {coalGradeLabel?.referee_on_air_dry_bases_moisture + ` %`}
                </label>
                <p className="content">
                  {form?.referee_on_air_dry_bases_moisture}
                </p>
              </Col>
              <Col sm={6}>
                <label>
                  {`${coalGradeLabel?.referee_on_air_dry_bases_ash} %`}
                </label>
                <p className="content">{form?.referee_on_air_dry_bases_ash}</p>
              </Col>
              <Col sm={6}>
                <label>
                  {coalGradeLabel?.referee_on_air_dry_bases_vm + ` %`}
                </label>
                <p className="content">{form?.referee_on_air_dry_bases_vm}</p>
              </Col>
              <Col sm={6}>
                <label>
                  {`${coalGradeLabel?.referee_on_air_dry_bases_gcv} (kCal/kg)`}
                </label>
                <p className="content">{form?.referee_on_air_dry_bases_gcv}</p>
              </Col>
            </Row>
            <div className="seperator"></div>
            <h6>On EM Basis ​</h6>
            <Row>
              <Col sm={6}>
                <label>
                  {`${coalGradeLabel?.referee_on_em_bases_moisture} %`}
                </label>
                <p className="content">{form?.referee_on_em_bases_moisture}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.referee_on_em_bases_ash + ` %`}​</label>
                <p className="content">{form?.referee_on_em_bases_ash}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.referee_on_em_bases_vm + ` %`}</label>
                <p className="content">{form?.referee_on_em_bases_vm}</p>
              </Col>
              <Col sm={6}>
                <label>
                  {`${coalGradeLabel?.referee_on_em_bases_gcv} (kCal/kg)`}
                </label>
                <p className="content">{form?.referee_on_em_bases_gcv}</p>
              </Col>
            </Row>
            <div className="seperator"></div>
            <Row>
              <Col sm={6}>
                <label>{coalGradeLabel?.referee_date_of_result}</label>
                <p className="content">
                  {form?.referee_date_of_result
                    ? DDMMYYYYHHMMAFormat(form?.referee_date_of_result)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{`${coalGradeLabel?.referee_total_moisture} %`}</label>
                <p className="content">{form?.referee_total_moisture}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.referee_analyzed_grade}​</label>
                <p className="content">{form?.referee_analyzed_grade}</p>
              </Col>
            </Row>
            <div className="seperator"></div>
            <Row>
              <Col sm={6}>
                <label>{coalGradeLabel?.dispute_raised_by}​</label>
                <p className="content">
                  {form?.dispute_created_by?.firstName +
                    form?.dispute_created_by?.lastName}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.dispute_raised_date}​</label>
                <p className="content">
                  {form?.dispute_raised_date
                    ? DDMMYYYYFormat(form?.dispute_raised_date)
                    : ""}
                </p>
              </Col>
            </Row>
            {/* </div> */}
          </div>
          <CreatedByCreatedAt id={form?.id} table={"coal_grade_quality_data"} />
        </div>
      </Modal.Body>
    </>
  );
};

export default RefereeLabResultView;
