import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

export const getAllPoList = async (filter) => {
  try {
    const response = await RestMethod.GET(
      "/contractor/po/getAllpo?" + filter
    );
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.count,
      data: data?.rows,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const deletePo = async (id) => {
  let url = "/contractor/po/delete/" + id;

  try {
    const response = await RestMethod.DELETE(url);
    return response?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

// export const createContractor = async (data) => {
//   let url = "/contractor/create";
//   try {
//     const response = await RestMethod.POST(url, data);
//     return response;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// };

export const updatePo = async (data, id) => {
  let url = "/contractor/po/update/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
