import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import {
  PollutionControlMachineApis,
  SidebarData,
  reasonListColumnFields,
  siteBasedCollum,
  nonSiteBasedCollum,
  statioinBasedCollumCAAQMS,
  statioinBasedCollumCMPDIL,
  statioinBasedCollumWaterQuality,
  statioinBasedCollumNoiseLevel,
  statioinBasedCollumGroundWater,
  EnvConfigcaaqms,
  EnvConfigApiscmpdil,
  EnvConfigonApisWaterQuality,
  EnvConfigionApisNoiseLevel,
  EnvConfigionApisGroundWater,
} from "../DATA";
import EnvConfigStationView from "./EnvConfigStationView";
import filter from "../../../../Assets/Images/filter.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { tabUrl } from "../../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../../_helpers/helper";
import { permissionCheck } from "../../../../_services/Auth/helper";
import EmptyDataList from "../../../ContractorManagement/ContractorPerformance/EmptyDataList";
import {
  getAllEnvConfigStation,
} from "../../../../services/_reasonList/reasonList";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
// import PollutionControlFilterForm from "../../UploadedFiles/Configuration/";
import toastr from "toastr";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../../_services/CommonService/CommonService";

const EnvConfigStation = () => {
  const usrl = useLocation();
  const segments = usrl.pathname.split("/");
  const lastSegment = segments[segments.length - 1];
  const words = lastSegment.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const title = capitalizedWords.join(" ");
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const cref = useRef(null);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.envConfig)
  );
  let navigate = useNavigate();

  const getAll = async () => {
    const response = await getAllEnvConfigStation(
      "page=1&limit=2",
      lastSegment
    );
    console.log("response" , response);
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const onEdit = (id) => {
    navigate(
      `/environment-management/configuration/station/${lastSegment}/${id}`
    );
  };

  //   const [form, onChange, setForm] = useForm(PollutionControlFilterForm);
  // const [filterStatus, setFilterStatus] = useState(0);

  // const onSubmitFilter = () => setFilterStatus((prev) => prev + 1);

  //   useEffect(() => {
  //     if (filterStatus === 0) return;

  //     let filter = "";
  //     for (const key in form) {
  //       const element = form[key];
  //       if (element) {
  //         if (key === "startDate" || key === "endDate")
  //           filter += `&${key}=${element}`;
  //         else filter += `&search[${key}]=${element}`;
  //       }
  //     }

  //     setGetAllFilter(filter);
  //   }, [filterStatus]);

  // const onResetFilter = () => {
  //   // setForm(PollutionControlFilterForm);
  //   setGetAllFilter();
  // };

  // const FilterBtn = () => {
  //   const [open, setOpen] = useState(false);

  //   return (
  //     <div className="filter">
  //       <Button
  //         onClick={() => setOpen(!open)}
  //         aria-controls="example-collapse-text"
  //         aria-expanded={open}
  //         className="secondaryBtn"
  //       >
  //         <img src={filter} className="img-fluid" alt="fliterIcon" />
  //       </Button>
  //       {/* <PollutionControlFilterForm
  //         form={form}
  //         onChange={onChange}
  //         onResetFilter={onResetFilter}
  //         onSubmitFilter={onSubmitFilter}
  //         isOpen={open}
  //         setOpen={setOpen}
  //       /> */}
  //     </div>
  //   );
  // };

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    getAll();
  }, []);

  let api;

  console.log("customTabs" , customTabs);

  if (lastSegment === "CAAQMS") {
    api = EnvConfigcaaqms;
  } else if (lastSegment === "CMPDIL") {
    api = EnvConfigApiscmpdil;
  } else if (lastSegment === "water_quality") {
    api = EnvConfigonApisWaterQuality;
  } else if (lastSegment === "noise_level") {
    api = EnvConfigionApisNoiseLevel;
  } else if (lastSegment === "ground_water_CMPDIL") {
    api = EnvConfigionApisGroundWater;
  }

  const viewAsPopup = (form) => {
    return <EnvConfigStationView form={form} />;
  };
  let tabelColumns;
  if (lastSegment === "CAAQMS") tabelColumns = statioinBasedCollumCAAQMS;
  else if (lastSegment === "CMPDIL") tabelColumns = statioinBasedCollumCMPDIL;
  else if (lastSegment === "water_quality")
    tabelColumns = statioinBasedCollumWaterQuality;
  else if (lastSegment === "noise_level")
    tabelColumns = statioinBasedCollumNoiseLevel;
  else if (lastSegment === "ground_water_CMPDIL")
    tabelColumns = statioinBasedCollumGroundWater;

    const activeInactive = useCallback(async (e, id, status) => {
      try {
        e.preventDefault();
        const data = {
          modelName: 'envStationMasters',
          id: id,
          status: status
        }
        const response = await changeStatus(data);
        console.log("ress", response);
        if (response?.status) {
          toastr.success(response?.message);
          cref.current.getAll();
        }
      } catch (error) {
        toastr.error(error);
        return {
          status: 0,
          message: error,
        };
      }
    }, []);
  
    const finalTabelColumns= useMemo(
      () => [
        ...tabelColumns,
        {
          name: "Status",
          selector: "status",
          cell: (row) => {
            return (
              (row.status === 1 && (
                <span
                  href="#"
                  className="icons text-success pointer"
                  onClick={(e) =>
                    activeInactive(e, row.id, row.status, "status")
                  }
                >
                  <FaToggleOn
                    size="20"
                    data-testid={"toogle-user-list-on-" + row.id}
                  />
                </span>
              )) ||
              (row.status === 0 && (
                <span
                  href="#"
                  className="icons text-danger pointer"
                  onClick={(e) =>
                    activeInactive(e, row.id, row.status, "status")
                  }
                >
                  <FaToggleOff
                    size="20"
                    data-testid={"toogle-user-list-off-" + row.id}
                  />
                </span>
              ))
            );
          },
        },
      ],
      []
    );

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={finalTabelColumns}
                apis={api}
                tableTitle={title}
                title="Data"
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={`/environment-management/configuration/station/${lastSegment}`}
                primaryKey="id"
                pagination
                cref={cref}
                hideDeleteButton
                hideEditButton
                // FilterBtn={FilterBtn}
                getAllFilter={getAllFilter}
                hideAddButton={!permissionCheck(["super_admin", "env_admin"])}
                listname={"stationcategory"}
                customTableTitle={title}
                editFunButton={onEdit}
                breadCrumb={[
                  {
                    name: "Environment Management System",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/environment-management/configuration",
                  },
                  {
                    name: title,
                    path: "#",
                  },
                ]}
                isNoTabOnPage
                deleteFun
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            pageLink={`/environment-management/configuration/station/${lastSegment}`}
            title={title}
            hideAddBtn={!permissionCheck(["super_admin", "env_admin"])}
            breadCrumb={[
              {
                name: "Environment Management System",
                path: "#",
              },
              {
                name: "Configuration",
                path: "/environment-management/configuration",
              },
              {
                name: title,
                path: "#",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default EnvConfigStation;
