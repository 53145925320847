import SafetySideBar from "../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData as coalGradeSideBar } from "../../_pages/CoalGrade/DATA";
import { uploadSapFile } from "../../services/_uploadedFiles/uploadedFiles";
import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";
import { getAllSites } from "../../_services/SiteServices/SiteServices";
import UploadFile from "../../components/_form/UploadFile/UploadFile";
import MessagePopup from "../../components/MessagePopup/MessagePopup";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import {
  reportTypeList,
  extensionsAllowed,
  breadCrumb,
  sapFormObj,
} from "./DATA";
import { useNavigate, useLocation } from "react-router-dom";
import { uploadFileOnS3 } from "../../services/S3Service";
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from "../../components/Select/Select";
import { useForm } from "../../_helpers/hooks";
import BulkUploadSap from "./SapBulkUpload";
import toastr from "toastr";
import {
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
  subsidiaryListByMine,
} from "../../_helpers/helper";
const ImportSapUpload = () => {
  const [uploadAreaQualityFlag, setUploadAreaQualityFlag] = useState(false);
  const [uploadAreaQualityList, setUploadAreaQualityList] = useState([]);
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [serverErrorData, setServerErrorData] = useState([]);
  const [uploadFiles, setUploadFiles] = useState(false);
  const [finalSiteList, setFinalSiteList] = useState();
  const [dataForError, setDataForError] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [subsidiary, setSubsidiary] = useState([]);
  const [FileError, setFileError] = useState("");
  const [mineData, setMineData] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [form, onChange, setForm] = useForm(sapFormObj);
  const [show, setShow] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [file, setFile] = useState("");
  const [sites, setSites] = useState();

  const closeFun = () => {
    setMessagePopupType(true);
    setShow(true);
  };
  let navigate = useNavigate();
  const closePopup = () => {
    setShow(false);
    navigate("/coal-grade/configuration/sap-import");
  };
  const urlFileType = useLocation();
  const breadcrumbText = breadCrumb(urlFileType.pathname);
  const removeSelctedFile = () => {
    setFileError("");
    setFile("");
  };

  const onSubmit = async () => {
    setDataForError(false);
    setSubmit(true);
    console.log("form.site_id", form.site_id);
    console.log("form.mine", form.mine);
    console.log("fileType", form?.fileType);
    console.log("filefile", file);

    if (
      form.site_id != "" &&
      form.mine != "" &&
      form?.fileType != "" &&
      file != ""
    ) {
      setIsloading(true);
      const s3UrlData = await uploadFileOnS3(file, "xlx/xlsx");
      const response = await uploadSapFile({
        subsidiary: form?.mine,
        fileType: form.fileType,
        mine: form?.site_id,
        file: s3UrlData,
      });
      if (response?.response?.data?.status === 1) {
        setUploadAreaQualityList(
          response?.response?.data?.data?.fileData || []
        );
        setUploadAreaQualityFlag(true);
        setUploadFiles(response?.response?.data?.data || {});
      } else {
        toastr.error(response?.response?.data?.message || "Error");
        setFileError(response?.response?.data?.message);
      }
      if (response) setIsloading(false);
    }
  };

  const backToTable = useCallback(() => {
    setUploadAreaQualityFlag((prev) => !prev);
  }, []);

  const onChangeFile = (e) => {
    setFile(e.file);
  };

  useEffect(() => {
    getAllSites("filter[siteType]=1,2,3&filter[activeStatus]=active").then(
      (response) => {
        if (!response?.data) return;
        setAllSites(response.data);
        const siteData = response.data.filter((d) => d?.siteType === 3);
        setFinalSiteList(siteData);
        setSites(siteData);
        setSubsidiary(subsidiaryListByMine(response?.data));
      }
    );
  }, []);

  useEffect(() => {
    setFinalSiteList(
      form?.mine ? sites?.filter((d) => d?.parentSiteId === +form?.mine) : sites
    );
  }, [form?.mine, sites]);

  if (subsidiary.length === 1) {
    form.mine = subsidiary[0]?.id;
  }

  useEffect(() => {
    if (
      finalSiteList &&
      finalSiteList[0] &&
      finalSiteList[0].id !== undefined &&
      finalSiteList[0].id !== null &&
      finalSiteList?.length === 1
    )
      form.site_id = finalSiteList[0].id;
  }, [finalSiteList, form]);
  const breadcrumbLinks = [
    {
      name: breadcrumbText[0],
      path: "#",
    },
    {
      name: "Configuration",
      path: breadcrumbText[1],
    },
    {
      name: "Import SAP",
      path: breadcrumbText[2],
    },
    {
      name: "Create",
      path: "#",
    },
  ];
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={coalGradeSideBar} />
      <div className="content" id="page-content-wrapper">
        {!uploadAreaQualityFlag ? (
          <div className="contentArea">
            <Container fluid>
              <h3
                className=""
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb routeSegments={breadcrumbLinks} />
              </h3>
              <h3 className="title d-flex align-items-end">
                Create SAP
                <span className="blueLabel"></span>
              </h3>
              <PerfectScrollbar className="customHeight">
                <div className="reportCard">
                  <Form>
                    <Row>
                      <Col md={4}>
                        <Select
                          label={"Subsidiary"}
                          name="mine"
                          value={form?.mine}
                          onChange={onChange}
                          options={subsidiary}
                          KEY_label={"name"}
                          KEY_value={"id"}
                          // error={error?.mine}
                          disabled={IS_USER_SITE_MINES() || IS_USER_SITE_RO()}
                          blankSelect={"Select"}
                        />
                        {submit && form?.mine == "" && (
                          <p style={{ color: "red" }}>Subsidiary Required</p>
                        )}
                      </Col>
                      <Col md={4}>
                        <Select
                          label={"Mine"}
                          name="site_id"
                          value={form?.site_id}
                          onChange={onChange}
                          options={finalSiteList}
                          KEY_label={"name"}
                          KEY_value={"id"}
                          // error={error?.site_id}
                          disabled={IS_USER_SITE_MINES()}
                          blankSelect={"Select"}
                        ></Select>
                        {submit && form?.site_id == "" && (
                          <p style={{ color: "red" }}>Mine Required</p>
                        )}
                      </Col>
                      <Col md={4}>
                        <Select
                          onChange={onChange}
                          value={form?.fileType}
                          name="fileType"
                          options={reportTypeList[breadcrumbText[0]]}
                          label={"File type"}
                        />
                        {submit && form?.fileType == "" && (
                          <p style={{ color: "red" }}>File Type Required</p>
                        )}
                      </Col>
                    </Row>

                    <UploadFile
                      sapmleFile={form?.fileType}
                      extensions={extensionsAllowed}
                      removeFile={removeSelctedFile}
                      onChangeFile={onChangeFile}
                    />

                    {isloading && <div className="loader"></div>}
                    {submit && file === "" && !isloading ? (
                      <p
                        className={`${file ? "error_box" : ""}`}
                        style={{ color: "red" }}
                      >
                        File Required
                      </p>
                    ) : (
                      <></>
                    )}
                  </Form>
                  <div className="btnSec">
                    <Button
                      className="secondaryBtn"
                      onClick={() => closeFun()}
                      data-testid="handle-click-for-show"
                    >
                      Close
                    </Button>
                    <div className="d-flex">
                      <Button
                        className="primaryBtn"
                        disabled={isloading}
                        data-testid={"saveButtonID"}
                        onClick={() => onSubmit()}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </Container>
            <MessagePopup
              setShow={setShow}
              messagePopupType={messagePopupType}
              show={show}
              closePopup={closePopup}
            />
          </div>
        ) : (
          <div className="contentArea">
            <Container fluid>
              <h3
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
                className=""
              >
                <MainBreadcrumb routeSegments={breadcrumbLinks} />
              </h3>
              <h3 className="title d-flex align-items-end">
                Create SAP
                <span className="blueLabel"></span>
              </h3>

              <PerfectScrollbar className="customHeight">
                <div className="reportCard">
                  <BulkUploadSap
                    isLoading={isloading}
                    bulkDataList={uploadAreaQualityList}
                    uploadFile={uploadFiles}
                    back={backToTable}
                    rediectTo={breadcrumbText[2]}
                    serverErrorData={serverErrorData}
                  />
                </div>
              </PerfectScrollbar>
            </Container>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportSapUpload;
