import { CallApi } from "../../_helpers/CallApi/CallApi";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
// import FilterResponse from '../../_helpers/FilterResponse'
// import toastr from 'toastr'
export const UserService = {
  getUsers,
  addUser,
  getUserByID,
  updateUser,
  deleteUser,
  login,
  getUserNotifications,
  getAllUsersWithServer,
  getAllUsersUsersExport,
  getAllRoles,
  getAllSafetyUsersWithServer,
  getUsersByRole,
  getAllCustomer,
  updateNotificationReadStatus,
  signOutUser,
  getPermissionsByUser,
  enabledMobileByUser,
  getCreatedBy,
  getAllContractorUser,
  getSettings,
  deleteSetting,
  createSettings,
  updateSetting,
  getByIdSettings,
};

async function getUserNotifications(filter) {
  let filter_key = filter ? filter : "";
  try {
    const response = await RestMethod.GET(
      "/users/user-notifications?" + filter_key
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function updateNotificationReadStatus(data) {
  try {
    const response = await RestMethod.POST(
      "/users/user-notifications-update",
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getUsers(filter) {
  let filter_key = filter ? filter : "";
  try {
    const response = await RestMethod.GET("/users?" + filter_key);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getUsersByRole(filter, data) {
  let filter_key = filter ? filter : "";
  try {
    const response = await RestMethod.POST(
      "/users/users-by-role?" + filter_key,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

/************************************ User Management**************************************************/

async function addUser(data) {
  try {
    const response = await RestMethod.POST("/user-management/add-user", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function login(data) {
  try {
    const response = await RestMethod.POST("/users/login", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function updateUser(id, data) {
  try {
    const response = await RestMethod.PUT(
      "/user-management/update-user/" + id,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function deleteUser(id, data) {
  try {
    const response = await RestMethod.DELETE(
      "/user-management/delete-user/" + id
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function deleteSetting(id, data) {
  try {
    console.log("delete Setting");
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getUserByID(id) {
  try {
    const response = await RestMethod.GET("/users/" + id);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function signOutUser(data) {
  try {
    const response = await RestMethod.POST("/users/account/sign-out", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllSafetyUsersWithServer(filter) {
  let filter_key = filter ? filter : "";
  try {
    const response = await RestMethod.GET("/incident/user-table?" + filter_key);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllUsersWithServer(filter) {
  let filter_key = filter ? filter : "";
  try {
    console.log("getAllUsersWithServer", filter_key);
    const response = await RestMethod.GET("/users/user-table?" + filter_key);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllContractorUser(filter) {
  let filter_key = filter ? filter : "";
  filter_key = filter_key + "&contractorUser=true";
  try {
    const response = await RestMethod.GET("/users/user-table?" + filter_key);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      totalCount: data?.totalCount,
      data: data.data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllUsersUsersExport(filter) {
  try {
    let url = "/users/export-users";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
}

async function getAllRoles(id, data) {
  try {
    const response = await RestMethod.GET("/user-management/roles");
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllCustomer(filter) {
  let filter_key = filter ? filter : "";
  try {
    const response = await RestMethod.GET("/customer-master?" + filter_key);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getPermissionsByUser() {
  try {
    const response = await RestMethod.GET("/users/permissions-by-user");
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function enabledMobileByUser(id, data) {
  try {
    const response = await RestMethod.PUT("/users/enable-mobile/" + id, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getCreatedBy(id, table) {
  try {
    const response = await RestMethod.GET(
      `/users/createdBy?id=${id}&table=${table}`
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getSettings(filter) {
  let filter_key = filter ? filter : "";
  try {
    const response = await RestMethod.GET("/settings?" + filter_key);
    const { status, message } = response.data;
    return {
      status,
      message,
      totalCount: response?.data?.data?.data?.totalCount,
      data: response?.data?.data?.data?.settings || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
async function createSettings(data) {
  try {
    const response = await RestMethod.POST("/settings/create", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function updateSetting(data, id) {
  try {
    const response = await RestMethod.PUT("/settings/" + id, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getByIdSettings(data) {
  try {
    const response = await RestMethod.GET("/settings/" + data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
