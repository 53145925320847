import React, { useEffect, useState } from "react";
import { auth_user } from "../../../_helpers/helper";
import { Button, Container } from "react-bootstrap";
import {
  SidebarData,
  allGrades,
  coalGradeApis,
  coalGrateTypeObj,
  submitStatus,
} from "../DATA";
import { useForm } from "../../../_helpers/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { refereeLabResult } from "../../../services/_coalGrade/coalGrade";
import {
  RefereeLabRules,
  RefereeLabResultInitialForm,
  CoalDispatchRelatedRules,
} from "./DATA";
import CoalDispatchRelatedForm from "./CoalDispatchRelatedForm";
import RefereeLabResultForm from "./RefereeLabResultForm";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import { Validation } from "../../../_helpers/validation/Validation";
import { StepButton } from "../../../components/_button/StepButton";
import toastr from "toastr";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { employeeNameEmailLabel } from "../../../_helpers/helper";
import { getGValue } from "../../../_helpers/helper";
import { UserService } from "../../../_services/UserService/UserService";
import { capitalizeName } from "../../../_helpers/helper";

const RefereeLabResult = () => {
  const [tab, setTab] = useState(1);
  const [show, setShow] = useState(false);
  const [error, setError] = useState({});
  const [messagePopupType, setMessagePopupType] = useState(false);
  let navigate = useNavigate();
  const [form, onChange, setForm] = useForm({ ...RefereeLabResultInitialForm });
  const [rrDDNoOList, setRrDDNOList] = useState([]);
  const [disputeUser, setDisputeUser] = useState([]);
  const [filterObj, setFilterObj] = useState(
    `type=${coalGrateTypeObj?.refereeLabResult}&search[third_party_status]=${submitStatus?.submitted}`
  );
  const params = useParams("id");
  const [gradeList, setGradeList] = useState(
    Object.keys(allGrades).map((key) => {
      return { value: key, label: key };
    })
  );
  const [declaredGradeList, setDeclaredGradeList] = useState([]);

  const tabFun = (n) => {
    setTab(n);
  };

  const getAll = async (filter = "") => {
    filter += `&${filterObj}`;

    const response = await coalGradeApis.getAll.api(filter);
    return response?.data;
  };

  const handleSubmit = async (submitType) => {
    let errorObj = {};
    let data = {};
    if (submitType == submitStatus?.drafted) {
      data = { ...form, referee_lab_status: submitStatus?.drafted };
    } else {
      data = { ...form, referee_lab_status: submitStatus?.submitted };

      errorObj = Validation(form, RefereeLabRules);
      setError(errorObj);
    }

    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    const response = await refereeLabResult(data);
    if (!response?.status) return toastr.error(response?.message);

    if (submitType == submitStatus?.drafted) {
      toastr.success(`Saved Successfully`);
    } else {
      setShow(true);
      setMessagePopupType(false);
    }
  };

  function handleClick() {
    setShow(false);
    navigate("/coal-grade/referee-lab-result/actions/list");
  }

  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };

  const nextStapeFun = () => {
    const errorObj = Validation(form, CoalDispatchRelatedRules);

    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) return;
    setTab(2);
  };

  const ModeOfDispatchOnChangeFn = async (e) => {
    let data = await getAll(
      `search[mode_of_dispatch]=${e?.target?.value}&status_filter=true`
    );
    assignRrOrDoNoList(data);
    setForm((prev) => {
      return {
        ...prev,
        id: "",
        rr_or_do_number: "",
        rr_or_do_date: "",
        declared_grade: "",
      };
    });
  };

  const assignRrOrDoNoList = (coalGradeData) => {
    setRrDDNOList(
      coalGradeData
        .filter((data) => data.referee_lab_status != submitStatus.submitted)
        .map((row) => {
          return { label: row.rr_or_do_number, value: row.rr_or_do_number };
        })
    );
  };

  const assignDeclaredGrade = (coalGradeData) => {
    setDeclaredGradeList(
      coalGradeData
        .filter((data) => data.referee_lab_status != submitStatus.submitted)
        .map((row) => {
          return { label: row.declared_grade, value: row.id };
        })
    );
  };

  const RrDdNoOnChangeFn = async (e) => {
    if (!form?.mode_of_dispatch) return;

    let data = await getAll(
      `search[mode_of_dispatch]=${form?.mode_of_dispatch}&search[rr_or_do_number]=${e?.target?.value}&status_filter=true`
    );

    assignDeclaredGrade(data);
    setForm((prev) => {
      return { ...prev, id: "", declared_grade: "" };
    });
  };

  const RrDdDateOnChangeFn = async (e) => {
    if (!form?.mode_of_dispatch || !form?.rr_or_do_number) return;

    let coalGradeData = await getAll(
      `search[mode_of_dispatch]=${form?.mode_of_dispatch}&search[rr_or_do_number]=${form?.rr_or_do_number}&search[date_of_collection]=${e?.target?.value}&status_filter=true`
    );
    setDeclaredGradeList(
      coalGradeData
        .filter((data) => data.referee_lab_status != submitStatus.submitted)
        .map((row) => {
          return { label: row.declared_grade, value: row.id };
        })
    );

    setForm((prev) => {
      return { ...prev, declared_grade: "" };
    });
  };

  useEffect(() => {
    (async () => {
      if (!params?.id) return;

      let data = await getAll(`search[id]=${params?.id}&status_filter=true`);

      if (data[0]?.referee_lab_status == submitStatus?.submitted) {
        toastr.error("Unauthorized");
        navigate("/coal-grade/referee-lab-result/actions/list");
      }

      data.map((row) => {
        setForm((prev) => {
          return { ...prev, ...row, declared_grade: row.id };
        });
      });
      assignRrOrDoNoList(data);
      assignDeclaredGrade(data);
    })();
  }, [params?.id]);

  const assignId = (value) => {
    setForm((prev) => {
      return { ...prev, id: value };
    });
  };

  let UserData = employeeNameEmailLabel();
  useEffect(() => {
    if (!params.id) {
      setForm((prev) => {
        return {
          ...prev,
          referee_analyzed_grade: String(
            getGValue(form?.referee_on_em_bases_gcv)
          ),
        };
      });
    }
  }, [form?.referee_on_em_bases_gcv]);
  useEffect(() => {
    let roles = { roles: ["area_quality_manager"] };
    UserService.getUsersByRole("filter[status]=1", roles).then((response) => {
      if (!response?.data) return;
      setDisputeUser(
        response?.data.map((d, i) => ({
          disputeUserId: d.id,
          name_email_employeeId: `${capitalizeName(
            d.firstName + " " + d.lastName
          )} / ${d.email}`,
        }))
      );
    });
    if (!params.id)
      setForm((prev) => ({
        ...prev,
        dispute_raised_by: UserData?.id,
      }));
  }, []);

  useEffect(() => {
    if (declaredGradeList.length && !params.id) {
      setForm((prev) => {
        return {
          ...prev,
          declared_grade: declaredGradeList[0]?.value,
        };
      });
      assignId(declaredGradeList[0]?.value);
    }
  }, [form.rr_or_do_number, declaredGradeList]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <div className="creationWrapper">
              {/* <Container fluid> */}
              <h3
                className=""
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Coal Grade",
                      path: "/",
                    },
                    {
                      name: "Coal Quality",
                      path: "/coal-grade/list",
                    },
                    {
                      name: "Referee Lab Result",
                      path: "/coal-grade/referee-lab-result/actions/list",
                    },
                    {
                      name: "Referee Lab Results Register",
                      path: "#",
                    },
                  ]}
                  // module={ACTIVE_MODULE.incident}
                />
              </h3>
              <h3 className="title">Referee Lab Results Register</h3>
              <div className="creationCard">
                <div className="formStepperWrapper">
                  <ul>
                    <li
                      className={`coalDispatchStep ${
                        tab === 1 ? "activeStep" : "doneStep"
                      }`}
                    >
                      <div className="stepIcon">
                        <span></span>{" "}
                      </div>
                      <label className="mt-3">Coal Dispatch Related Data</label>
                    </li>
                    <li
                      className={`sampleRelatedStep ${
                        tab === 2 ? "activeStep" : "initialStep"
                      }`}
                    >
                      <div className="stepIcon">
                        <span></span>{" "}
                      </div>
                      <label className="mt-3">
                        Referee Lab Quality Results
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="formWrapper">
                  <div className="maverickFormCard">
                    {tab === 1 && (
                      <CoalDispatchRelatedForm
                        form={form}
                        onChange={onChange}
                        error={error}
                        setForm={setForm}
                        ModeOfDispatchOnChangeFn={ModeOfDispatchOnChangeFn}
                        RrDdNoOnChangeFn={RrDdNoOnChangeFn}
                        RrDdDateOnChangeFn={RrDdDateOnChangeFn}
                        assignId={assignId}
                        rrDDNoOList={rrDDNoOList}
                        declaredGradeList={declaredGradeList}
                        disputeUser={disputeUser}
                      />
                    )}
                    {tab === 2 && (
                      <RefereeLabResultForm
                        form={form}
                        onChange={onChange}
                        error={error}
                        setTab={tabFun}
                        gradeList={gradeList}
                      />
                    )}
                  </div>
                </div>
                <div className="btnSec">
                  <Button onClick={closeFun} className="secondaryBtn">
                    Close
                  </Button>
                  <div>
                    <StepButton
                      tab={tab}
                      tabFun={tabFun}
                      onSubmit={handleSubmit}
                      nextStapeFun={nextStapeFun}
                    />
                  </div>
                </div>
              </div>
              {/* </Container> */}

              <MessagePopup
                messagePopupType={messagePopupType}
                show={show}
                setShow={setShow}
                closePopup={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefereeLabResult;
