import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../../../../components/FormInputText/FormInputText";
import Select from "../../../../../../components/MobileComponents/Select/Select";
import { CustomSwitch } from "../../../../../../components/CustomSwitch/CustomSwitch";
import { getArraayofKeys } from "../../../Helper";
import { compensationObjLavel, injuryCodeObj } from "../../../DATA";
import CustomDatePicker from "../../../../../../components/MobileComponents/DateTimeCustom/CustomDateTime";
import { digitCheckAfterDecimal } from "../../../../../../_helpers/helper";
import CustomRadio from "../../../../../../components/_form/CustomRadio/CustomRadio";
import AsterikOnly from "../../../../../../components/Common/Asterik/AstrikOnly";

const CompensationInfor = ({
  form = {},
  onChange,
  informatinsDetails,
  onChangeInformation,
  errors,
  pendingFormC,
}) => {
  return (
    <div className="mobileFormWrapper">
      <div className="ehsFormWrapper p-0">
        <div className="customSwitchSec">
          {!pendingFormC ? (
            <CustomSwitch
              name="isCompensationInfor"
              value={
                form.isCompensationInfor === null
                  ? false
                  : form.isCompensationInfor
              }
              onChange={onChange}
              title={"Compensation Information"}
            />
          ) : (
            ""
          )}
        </div>
        <Form>
          <Row>
            {form.isCompensationInfor && (
              <Col md="12">
                <h6 className="mt-4 ms-3">
                  Personnel Info
                  <AsterikOnly />
                </h6>
                {informatinsDetails?.length ? (
                  informatinsDetails?.map((x, index) => {
                    return (
                      <div className="addDataformCards" key={index}>
                        <div className="formFields">
                          <Select
                            label={compensationObjLavel.outcome}
                            name="outcome"
                            value={x?.outcome}
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            options={[
                              "Minor Injury",
                              "Serious Injury",
                              "Fatality",
                            ]}
                            init="Select"
                            array="single"
                            error={errors[index]?.outcome}
                            KEY_label={"name"}
                            KEY_value={"id"}
                            disabled
                          ></Select>
                        </div>
                        <div className="formFields">
                          {/* <Select
                            label={compensationObjLavel.injuryCode}
                            name="injuryCode"
                            value={x?.injuryCode}
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            options={getArraayofKeys(injuryCodeObj)}
                            object={injuryCodeObj}
                            error={errors[index]?.injuryCode}
                            disabled
                          ></Select> */}
                          <FormInputText
                            label={compensationObjLavel.name}
                            name="name"
                            type="text"
                            disabled={true}
                            value={x.name}
                          />
                        </div>
                        <div className="formFields">
                          {x?.outcome !== "Fatality" ? (
                            <>
                              <label>
                                {compensationObjLavel.dateOfReturnDuty}
                              </label>
                              <CustomDatePicker
                                value={x?.dateOfReturnDuty}
                                dateFormat="YYYY-MM-DD"
                                name="dateOfReturnDuty"
                                onChange={({ target: { name, value } }) =>
                                  onChangeInformation(index, name, value)
                                }
                                error={errors[index]?.dateOfReturnDuty}
                              />
                            </>
                          ) : (
                            <FormInputText
                              label={compensationObjLavel.dateOfReturnDuty}
                              name="dateOfReturnDuty"
                              type="text"
                              value={x.dateOfReturnDuty}
                              error={errors[index]?.dateOfReturnDuty}
                              disabled
                            />
                          )}
                        </div>
                        <div className="formFields">
                          <Select
                            label={compensationObjLavel.returnForRegular}
                            name="returnForRegular"
                            value={x?.returnForRegular}
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            options={
                              x?.outcome !== "Fatality"
                                ? ["Regular", "Other"]
                                : ["Not Applicable"]
                            }
                            init="Select"
                            array="single"
                            error={errors[index]?.returnForRegular}
                            KEY_label={"name"}
                            KEY_value={"id"}
                            disabled={x?.outcome !== "Fatality" ? false : true}
                          ></Select>
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={compensationObjLavel.compensationPaid}
                            name="compensationPaid"
                            type="number"
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              digitCheckAfterDecimal(value, 2) &&
                              onChangeInformation(index, name, value)
                            }
                            value={x.compensationPaid}
                            // className="form-control"
                            error={errors[index]?.compensationPaid}
                          />
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={compensationObjLavel.exGratiaPaid}
                            name="exGratiaPaid"
                            type="number"
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              digitCheckAfterDecimal(value, 2) &&
                              onChangeInformation(index, name, value)
                            }
                            value={x.exGratiaPaid}
                            error={errors[index]?.exGratiaPaid}
                          />
                        </div>

                        <div className="formFields">
                          <FormInputText
                            label={compensationObjLavel.otherBenefits}
                            name="otherBenefits"
                            type="text"
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            value={x.otherBenefits}
                            error={errors[index]?.otherBenefits}
                          />
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={
                              compensationObjLavel.statutoryCompensationPaid
                            }
                            name="statutoryCompensationPaid"
                            type="number"
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              digitCheckAfterDecimal(value, 2) &&
                              onChangeInformation(index, name, value)
                            }
                            value={x.statutoryCompensationPaid}
                            error={errors[index]?.statutoryCompensationPaid}
                          />
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={compensationObjLavel.totalExperience}
                            name="totalExperience"
                            type="number"
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              digitCheckAfterDecimal(value, 2) &&
                              onChangeInformation(index, name, value)
                            }
                            value={x.totalExperience}
                            // className="form-control"
                            error={errors[index]?.totalExperience}
                          />
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={compensationObjLavel.jobDoneAtAccidentTime}
                            name="jobDoneAtAccidentTime"
                            type="text"
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            value={x.jobDoneAtAccidentTime}
                            error={errors[index]?.jobDoneAtAccidentTime}
                          />
                        </div>
                        <div className="formFields">
                          <FormInputText
                            label={
                              compensationObjLavel.experienceInJobAtAccidentTime
                            }
                            name="experienceInJobAtAccidentTime"
                            type="text"
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            value={x.experienceInJobAtAccidentTime}
                            error={errors[index]?.experienceInJobAtAccidentTime}
                          />
                        </div>

                        <div className="formFields">
                          <CustomRadio
                            type="checkbox"
                            name="vocationallyTrained"
                            onChange={({ target: { name, checked: value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            title={"Vocationally Trained"}
                            acceptTitle={"Yes"}
                            rejectTitle={"No"}
                            checkBox={true}
                            customClass={"customRadioClass d-block"}
                            value={
                              x.vocationallyTrained === null
                                ? false
                                : x.vocationallyTrained
                            }
                          />
                        </div>

                        <div className="formFields">
                          <CustomRadio
                            type="checkbox"
                            name="initialVocationTraining"
                            onChange={({ target: { name, checked: value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            title={"Initial Vocation Training"}
                            acceptTitle={"Yes"}
                            rejectTitle={"No"}
                            checkBox={true}
                            customClass={"customRadioClass d-block"}
                            value={
                              x.initialVocationTraining === null
                                ? false
                                : x.initialVocationTraining
                            }
                          />
                        </div>
                        <div className="formFields">
                          <label
                            className="mt-3"
                            style={{ fontSize: "inherit" }}
                          >
                            {compensationObjLavel.dateOfRefresherTraining}
                          </label>
                          <CustomDatePicker
                            value={x?.dateOfRefresherTraining}
                            dateFormat="YYYY-MM-DD"
                            name="dateOfRefresherTraining"
                            onChange={({ target: { name, value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            error={errors[index]?.dateOfRefresherTraining}
                          />
                        </div>
                        <div className="formFields">
                          <CustomRadio
                            type="checkbox"
                            name="jobNature"
                            onChange={({ target: { name, checked: value } }) =>
                              onChangeInformation(index, name, value)
                            }
                            title={"Job Nature"}
                            acceptTitle={"Contractual"}
                            rejectTitle={"Departmental"}
                            checkBox={true}
                            customClass={"customRadioClass d-block"}
                            optionNewLine={true}
                            value={x.jobNature === null ? false : x.jobNature}
                          />
                        </div>
                        <div className="formFields">
                          {x?.outcome === "Fatality" && (
                            <FormInputText
                              name="causeOfDeath"
                              label="Cause of Death"
                              type="text"
                              onChange={({ target: { name, value } }) =>
                                onChangeInformation(index, name, value)
                              }
                              value={x.causeOfDeath}
                              error={errors[index]?.causeOfDeath}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </Col>
            )}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CompensationInfor;
