import { ActivityService } from "../../../../_services/ConfigurationService/ActivityService";

export const activityLabel = {
  activity: "Activity",
  subActivity: "Sub Activity",
  //   email: "Email",
  //   dob: "Date of birth",
  //   firstName: "First Name",
  //   lastName: "Last Name",
  //   contactNumber: "Mobile No.",
  //   siteId: "Location",
  //   userType: "Users Type",
  //   contractor_id: "Contractor",
  //   roleId: "Users Type",
};

export const activityFilterForm = {
  startDate: "",
  endDate: "",
  siteId: "",
  userType: "",
  permissions: "",
};

export const permissionInitialForm = {
  representative: false,
  section_incharge: false,
  colliery_manager: false,
  dispenser: false,
  chemist: false,
  lab_quality_manager: false,
  area_quality_manager: false,
  coal_grade_admin: false,
  contractor: false,
  contractor_module_admin: false,
  safety_admin: false,
  fuel_admin: false,
  env_admin: false,
  env_user: false,
};

export const activityInitialForm = {
  name: "",
  subActivity: "",
};
export const addLink = "/safety-management-system/configuration/activity";

export const activityListApis = Object.freeze({
  getAll: {
    api: ActivityService.getAllActivity,
  },
  delete: {
    api: ActivityService.deleteActivity,
  },
});

export const activityFormRules = [
  {
    field_name: "lastName",
    label: activityLabel?.subActivity,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "firstName",
    label: activityLabel?.activity,
    type: "string",
    isRequired: true,
  },
];
