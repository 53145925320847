import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../components/Select/Select";
import { performanceStatus } from "./DATA";
const HindranceFilterForm = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
  contractorList,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>Input Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
              value={form?.startDate}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              onChangeDate={onChange}
              dateFormat="YYYY-MM-DD"
              value={form?.endDate}
              name="endDate"
            />
          </Col>
          <Col md={12}>
            <Select
              onChange={onChange}
              value={form?.contractor_id}
              name="contractor_id"
              options={contractorList}
              label={"Contractor"}
            />
          </Col>
          <Col md={12}>
            <Select
              onChange={onChange}
              value={form?.current_label || ""}
              name="current_label"
              options={Object.keys(performanceStatus)?.map((key) => ({
                label: performanceStatus[key],
                value: key,
              }))}
              label={"Status"}
            />
          </Col>
        </Row>
        <Row className="btnArea">
          <Col md={6}>
            <Button
              type="button"
              data-testid="reset-filter"
              onClick={onResetFilter}
              className="secondaryBtn"
            >
              Clear all
            </Button>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <Button
              type="button"
              data-testid="submit-filter-button"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0 hindrance-filter-button"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default HindranceFilterForm;
