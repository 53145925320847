import { SapUploadService } from "../../services/_uploadedFiles/uploadedFiles";
import CustomDatePicker from "../../components/DateTimeCustom/CustomDateTime";
import FormInputText from "../../components/FormInputText/FormInputText";
import {
  airQualityFormLabel,
  formLabel,
  requiredColumn,
  validateFile,
} from "./DATA";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { uploadFileOnS3 } from "../../services/S3Service";
import { Button } from "react-bootstrap";
import toastr from "toastr";
import Select from "../../components/Select/Select";

function BulkUploadSap({
  siteList,
  bulkDataList,
  back,
  rediectTo,
  uploadFile,
  serverErrorData,
  hemmOptions,
}) {
  let errorInitialValue = [];
  errorInitialValue = uploadFile?.fileData?.map((d) => {
    return {};
  });
  const [error, setError] = useState(errorInitialValue);
  const [errorMessage, setErrorMessage] = useState([]);
  const [filterForm, setFilterForm] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [errorShow, setErrorShow] = useState(0);
  let [form, setForm] = useState([]);
  const [flag, setFlag] = useState(1);
  const recordsPerPage = 50;
  const [visibleRecords, setVisibleRecords] = useState(50);
  const navigate = useNavigate();
  let [serverErrorDatas, setServerErrorDatas] = useState(serverErrorData);

  const urlFileType = useLocation().search.replace("?type=", "");

  let uniqueArray = [];
  serverErrorDatas &&
    serverErrorDatas[0]?.uniqueArr?.map((data) => {
      uniqueArray.push(formLabel[urlFileType][data]);
    });

  const textOrNumber = (d) => {
    if (d === "sales_order" || d === "invoice_line_item" || d === "invoice") {
      return "number";
    } else {
      return "text";
    }
  };

  useEffect(() => {
    if (!searchText) {
      setForm(filterForm);
      return;
    }
  }, [filterForm, searchText]);

  const setFormData = useMemo(() => {
    const errorArray = [];
    return bulkDataList?.map((d) => {
      let extraToChange = {};

      if (
        siteList
          ?.filter((d) => d.siteType === 3)
          .map((d) => d.id)
          .includes(+d.currentWorkLocation)
      ) {
        extraToChange.mineTag = "yes";
      } else {
        extraToChange.mineTag = "no";
      }
      errorArray.push({
        currentWorkLocation: d?.isExistMesssage ? d?.isExistMesssage : "",
      });
      setErrorMessage(errorArray);

      return {
        ...d,
        ...extraToChange,
        siteType:
          siteList?.find((site) => +site.id === +d.currentWorkLocation)
            ?.siteType || "",
      };
    });
  }, [bulkDataList, siteList]);

  useEffect(() => {
    setForm(setFormData);
    // setFilterForm(setFormData);
  }, [setFormData]);

  function batchUpdates(arr, updateFunction) {
    return arr.reduce(updateFunction, []);
  }

  /********************* Form Function ***************************/
  const onChangeInput = ({ target: { name, value } }, index, isLabel) => {
    console.log("ssssssssssssssssssssssssss", name, value);
    setForm((prev) => {
      return batchUpdates(prev, (newArr, f, i) => {
        if (i === index) {
          newArr.push({ ...f, [name]: value });
        } else {
          newArr.push(f);
        }
        return newArr;
      });
    });
  };

  const onSubmit = async (e) => {
    if (uploadFile?.fileType === "fuel_sensor_sap_data") {
      // Extract the hemm_ids from the second array
      const allowedIds = hemmOptions.map((item) => item.value);

      form.forEach((item) => {
        if (!allowedIds.includes(item.hemm_id.toString())) {
          item.hemm_id = "";
        }
      });
    }

    //Check for Submit validation
    let validation = validateFile(form, uploadFile?.fileType);

    if (validation) {
      toastr.error(validation);
      return;
    }
    //Replacing 0 or empty values of input to null
    const fileDataIs = form.map((d) => {
      if (d.rr_date === "" || d.rr_date === 0) d.rr_date = null;
      if (
        d.ref_result_submission_date === "" ||
        d.ref_result_submission_date === 0
      )
        d.ref_result_submission_date = null;
      if (d.collection_date === "" || d.collection_date === 0)
        d.collection_date = null;
      if (d.preparation_date === "" || d.preparation_date === 0)
        d.preparation_date = null;
      if (
        d.tpa_result_submission_date === "" ||
        d.tpa_result_submission_date === 0
      )
        d.tpa_result_submission_date = null;
      return d;
    });
    const s3UrlData = await uploadFileOnS3(uploadFile);

    const urlName = s3UrlData?.s3Url.split("/");
    urlName.pop(urlName.length - 1);
    const finalUrl = urlName.join("/") + "/" + uploadFile?.fileName;

    let response = await SapUploadService.sapBlukUpload({
      fileData: fileDataIs,
      filePath: finalUrl,
      fileName: uploadFile?.fileName,
      fileType: uploadFile?.fileType,
      filename: uploadFile?.filename,
      header: uploadFile?.header,
      mine: uploadFile?.mine,
      originalname: uploadFile?.originalname,
      subsidiary: uploadFile?.subsidiary,
      uploadPath: uploadFile?.uploadPath,
    });

    if (!response?.status) {
      setServerErrorDatas(response?.data?.invalidData);

      return toastr.error(response?.message);
    }

    toastr.success(response.message || "Success.");
    navigate(rediectTo);
  };

  const tableHeader = uploadFile?.header?.map((d) => {
    return d.replace(".", "");
  });
  const tableRef = useRef(null);

  const handleScroll = () => {
    const tableElement = tableRef.current;
    if (tableElement) {
      const { scrollTop, clientHeight, scrollHeight } = tableElement;
      if (scrollTop + clientHeight === scrollHeight) {
        loadMore();
      }
    }
  };

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableElement) {
        tableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const loadMore = () => {
    // setTimeout(() => {
    setVisibleRecords((prevVisible) => prevVisible + recordsPerPage);
    // }, 500);
  };
  return (
    <>
      <div
        className="table-responsive"
        ref={tableRef}
        style={{ maxHeight: "500px", overflowY: "auto" }}
      >
        <table className="table table-bordered">
          <thead>
            <tr className="tableTh">
              <th>S.No.</th>
              {tableHeader?.map((d) => (
                <>
                  <th style={{ minWidth: "150px" }}>
                    {airQualityFormLabel[uploadFile?.fileType]?.[d]}
                  </th>
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(form) &&
              form.slice(0, visibleRecords).map((x, i) => {
                return (
                  <>
                    <tr
                      key={x.employeeId}
                      style={x.isExist ? { "background-color": "#f9b9b9" } : {}}
                    >
                      <th>{i + 1}</th>

                      {uploadFile?.header?.map((d) => (
                        <td>
                          {d === "fnote_dnote_date" ||
                          d === "rr_date" ||
                          d === "invoice_date" ||
                          d === "collection_date" ||
                          d === "preparation_date" ||
                          d === "tpa_result_submission_date" ||
                          d === "input_date" ||
                          d === "receipt_date" ||
                          d === "inventory_date" ||
                          d === "date" ||
                          d === "latitude_captured_date" ||
                          d === "last_fueling_date" ||
                          d === "ref_result_submission_date" ? (
                            <CustomDatePicker
                              disabled={d === "collection_date"}
                              value={x[d]}
                              dateFormat="YYYY-MM-DD"
                              name={d}
                              timeFormat={
                                d === "receipt_date" ||
                                d === "last_fueling_date"
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                onChangeInput(
                                  e,
                                  i,
                                  airQualityFormLabel[uploadFile?.fileType]?.[d]
                                )
                              }
                              error={
                                x[d] === "" &&
                                requiredColumn[uploadFile?.fileType].includes(d)
                                  ? airQualityFormLabel[uploadFile?.fileType]?.[
                                      d
                                    ] + " required"
                                  : ""
                              }
                            />
                          ) : d === "hemm_id" ? (
                            <Select
                              name={d}
                              value={x[d]}
                              onChange={(e) =>
                                onChangeInput(
                                  e,
                                  i,
                                  airQualityFormLabel[uploadFile?.fileType]?.[d]
                                )
                              }
                              options={hemmOptions}
                              // Add necessary props for Select component
                            >
                              {/* Add options for Select component */}
                            </Select>
                          ) : (
                            <>
                              <FormInputText
                                name={d}
                                type={textOrNumber(d)}
                                data-testid={"textOrNumber"}
                                value={x[d]}
                                onChange={(e) =>
                                  onChangeInput(
                                    e,
                                    i,
                                    airQualityFormLabel[uploadFile?.fileType]?.[
                                      d
                                    ]
                                  )
                                }
                                className="form-control"
                                error={
                                  x[d] === "" &&
                                  requiredColumn[uploadFile?.fileType].includes(
                                    d
                                  )
                                    ? airQualityFormLabel[
                                        uploadFile?.fileType
                                      ]?.[d] + " required"
                                    : ""
                                }
                              />
                              {/* {Array.isArray(error) && error[i] && (
                                <span className="fromerror">
                                  {error[i]?.[d]}
                                </span>
                              )} */}
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
        {visibleRecords <= form.length && (
          <div style={{ textAlign: "center", padding: "10px" }}>
            <span>Loading...</span>
          </div>
        )}
      </div>

      <div className="btnSec">
        <span onClick={back} className="btn secondaryBtn btn-primary  mr-2">
          Back
        </span>

        <Button
          onClick={onSubmit}
          type="submit"
          variant="primary"
          data-testid="stButton"
          className="m-1 text-capitalize primaryBtn"
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default React.memo(BulkUploadSap);
