import { URL_CONFIG } from "../_constants/Config/URL_CONFIG";
import toastr from "toastr";
import { useEffect } from "react";
import selectionIcon1 from "../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../Assets/Images/selectionIcon3.svg";
import selectionIcon4 from "../Assets/Images/selectionIcon4.svg";
import selectionIcon5 from "../Assets/Images/selectionIcon5.svg";
import { createBrowserHistory } from "history";
import { SidebarData as coalSideBar } from "../_pages/CoalGrade/DATA";
import { SidebarData as environmentSidebar } from "../_pages/EnvironmentManagement/UploadedFiles/DATA";
import { SidebarData as fuelSidebar } from "../_pages/FuelManagementSystem/FuelManagement/DATA";
import { SidebarData as contractorSidebar } from "../_pages/ContractorManagement/DATA";
import { DDMMYYYYHHMMAFormat } from "./dateHelper";
import { isDecimal } from "./helper";
export const safetySidebar = [
  {
    title: "Dashboard",
    link: "/safety-management-system/dashboard/1",
    img: selectionIcon1,
    active: 0,
    permission: ["safety_management_dashbaord"],
    activeUrl: "dashboard",
  },
  {
    img: selectionIcon2,
    // link: "/safety-management-system/user-management",
    link: "/safety-management-system/user-management",
    activeUrl: "user-management",
    active: 0,
    permission: ["safety_admin"],
    title: "User Management",
  },
  {
    permission: ["safety_admin", "super_admin"],
    img: selectionIcon3,
    title: "Configuration",
    active: 0,
    link: "/safety-management-system/configuration",
    activeUrl: "configuration",
  },
  {
    active: 0,
    title: "Incident Management",
    link: "/safety-management-system/incident-management",
    activeUrl: "incident-management",
    img: selectionIcon4,
    permission: ["safety_admin", "safety_user"],
  },
  {
    img: selectionIcon3,
    activeUrl: "documents",
    title: "Document",
    active: 0,
    link: "/safety-management-system/documents",
  },
  {
    link: "/safety-management-system/help-manual",
    title: "Help Manual",
    img: selectionIcon5,
    active: 0,
    activeUrl: "help-manual",
  },
];
export const getQueryParam = (key) => {
  const query = new URLSearchParams(window.location.search);
  return decodeURIComponent(query.get(key)) === "null"
    ? null
    : decodeURIComponent(query.get(key));
};

export const downloadFileByURL = async (url, title) => {
  const token = localStorage.getItem("jwt_token");
  fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title || "contractorPersons.xls");

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);

      // toastr message

      toastr.success(`Successfully downloded`);
    });
};

export const isString = (str) => {
  try {
    return typeof str === "string";
  } catch (e) {
    return false;
  }
};

export const PARSE_IMG_URL = (baseUrl, link) => {
  if (isString(link) && (link.includes("http") || link.includes("https"))) {
    // check for s3 credential
    if (link.includes("amazonaws.com")) {
      const jwt_token = localStorage.getItem("jwt_token");
      return `${URL_CONFIG.DEV_URL}aws/read-s3-file?url=${link}&token=${jwt_token}`;
    }

    return link;
  }
  return baseUrl + link;
};

export const converEachFirstWordCapitalize = (string, seperator) => {
  let arr = string?.split(seperator);

  for (var i = 0; i < arr?.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str = arr?.join(" ");
  return str;
};
// export const IS_OFFLINE = () => {
//   const { pathname } = createBrowserHistory().location;
//   if (pathname.includes("offline")) {
//     return 0;
//   } else {
//     return 1;
//   }
// };

// export const IsNavigatorOnline = () => {
//   const { pathname } = createBrowserHistory().location;
//   const newPath = pathname.replace("/offline", "");
//   redirect(newPath);
// };

// export const useOnlineStatus = () => {
//   let isOnline = navigator.onLine;

//   useEffect(() => {
//     const handleOnline = () => {
//       isOnline = true;
//     };

//     const handleOffline = () => {
//       isOnline = false;
//     };

//     window.addEventListener("online", handleOnline);
//     window.addEventListener("offline", handleOffline);

//     return () => {
//       window.removeEventListener("online", handleOnline);
//       window.removeEventListener("offline", handleOffline);
//     };
//   }, []);

//   return isOnline;
// };

//for user create component module check
export const handleURL = (url) => {
  switch (url) {
    case "/contractor-management/user-list/create":
      return {
        module_admin_user_create: 1,
        back: "/contractor-management/user-list",
        sidebar: contractorSidebar,
        permission: "contractorManagement",
      };
    case "/fuel-management-system/all-user-list/create":
      return {
        module_admin_user_create: 1,
        back: "/fuel-management-system/all-user-list",
        sidebar: fuelSidebar,
        permission: "fuelManagement",
      };
    case "/safety-management-system/user-management/create":
      return {
        module_admin_user_create: 1,
        back: "/safety-management-system/user-management",
        sidebar: safetySidebar,
        permission: "safetyManagement",
      };
    case "/coal-grade/user-management/create":
      return {
        module_admin_user_create: 1,
        back: "/coal-grade/user-management",
        sidebar: coalSideBar,
        permission: "coalGradeQuality",
      };
    case "/environment-management/user-management/create":
      return {
        module_admin_user_create: 1,
        back: "/environment-management/user-management",
        sidebar: environmentSidebar,
        permission: "environmentManagement",
      };
    default:
      return { module_admin_user_create: 0, back: null, sidebar: null };
  }
};

export const ApiStatusUpdateView = (data) => {
  return (
    <span
      className="me-3 fw-lighter small blueLabel d-flex justify-content-around"
      style={{ width: "470px" }}
    >
      <span> Last Sync Time: {DDMMYYYYHHMMAFormat(data?.data?.dateTime)}</span>
      {!data?.hideApiUpdateStatus && (
        <span>
          Status: {converEachFirstWordCapitalize(data?.data?.cronStatus)}
        </span>
      )}
    </span>
  );
};

export const findElementByArray = (arr = [], value, key, returnKey) => {
  let foundData = arr.find((obj) => obj[key] === value);
  return foundData ? foundData[returnKey] : "";
};

export const avgValueOfArray = ({ data, days }) => {
  const sums = {};
  const counts = {};

  data.forEach((item) => {
    const { name, value } = item;
    sums[name] = (sums[name] || 0) + value;
    counts[name] = (counts[name] || 0) + 1;
  });
  console.log("sumssumssumssumssums", sums);
  console.log("countscountscounts", counts);
  const averages = [];
  for (const name in sums) {
    const avg = sums[name] / days;
    averages.push({ name, value: isDecimal(avg) });
  }
  return averages;
};

export const minMaxDate = ({ array }) => {
  try {
    function parseDate(dateString) {
      const [day, month, year] = dateString.split("-");
      return new Date(`${year}-${month}-${day}`);
    }

    const minDate = new Date(
      Math.min(...array.map((item) => parseDate(item.date)))
    );
    const maxDate = new Date(
      Math.max(...array.map((item) => parseDate(item.date)))
    );
    return {
      minDate: minDate.toISOString().split("T")[0],
      maxDate: maxDate.toISOString().split("T")[0],
    };
  } catch (error) {
    console.log("error is minMaxDate ", error.message);
  }
};

export const removeDigitsAfterTwo = (number) => {
  let decimalIndex = number.toString().indexOf(".");
  if (
    decimalIndex !== -1 &&
    number.toString().substring(decimalIndex + 1).length > 2
  ) {
    let truncatedNumber = parseFloat(number.toString().slice(0, decimalIndex + 3));
    return truncatedNumber;
  } else {
    return number;
  }
};


export const isSpecialChar = (value) => {
  const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

  return specialCharRegex.test(value);
};
