import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { usePagination } from "./DATA";
const CustomPagination = (props) => {
  const {
    onChangePage,
    rowCount,
    siblingCount = 1,
    currentPage,
    rowsPerPage,
  } = props;

  const DOTS = "dots";
  const paginationRange = usePagination(
    currentPage,
    rowCount,
    rowsPerPage,
    siblingCount
  );

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onChangePage(currentPage + 1);
  };

  const onPrevious = () => {
    onChangePage(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange?.length - 1];
  return (
    <ul className={"pagination-container mt-2"}>
      {/* Left navigation arrow */}
      <button
        className="leftpagination"
        disabled={currentPage === 1}
        onClick={onPrevious}
        data-testid="left-test"
      >
        <BiChevronLeft />
      </button>
      {paginationRange?.map((pageNumber, idx) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li key={idx} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={idx}
            className={pageNumber === currentPage ? "active" : ""}
            onClick={() => onChangePage(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <button
        className="rightpagination"
        disabled={currentPage === lastPage}
        onClick={onNext}
        data-testid="right-test"
      >
        <BiChevronRight />
      </button>
    </ul>
  );
};

export default CustomPagination;
