import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../SafetyManagementSystem/IncidentManagement/Dashboard/Dashboard.scss";
import FuelCard from "./components/FuelCard";
import {
  Container,
  Dropdown,
  Card,
  Col,
  Row,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import TreemapChart from "../../../components/Common/TreemapChart";
import { MultipleBarChartCard } from "../../../components/ChartCard/ChartCard";
import Select from "../../../components/Select/Select";
import {
  getHsdConsumptionData,
  getHsdConsumptionSapData,
} from "../../../services/_fuelDispense/fuelDispense";
import {
  CONVERT_KL_L_FILTER,
  ChartFilter,
  FilterTab,
  FilterTabFuel,
  HEMMDetails,
  eqptTypeTabList,
  fuelTableTypeObj,
  getFuelTabFirstDashboardTbl,
  headerChartFilter,
  kpiList,
  options,
  widgetDataSap,
  widgetDataSapByType,
  widgetDataSingle,
} from "./DATA";
import { isArray } from "lodash";
import { multiLineChartDataFormat } from "../../../components/Chart/Common/config";
import { BiCalendar } from "react-icons/bi";
import { useForm } from "../../../_helpers/hooks";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import DataTableComponent from "../../../components/DataTableComponent/DataTableComponent";
import {
  IS_MOBILE,
  KL_TO_L,
  L_TO_KL,
  isDecimal,
} from "../../../_helpers/helper";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import MultiSelectWithCheckbox from "../../../components/SearchableSelect/MultiSelectCkeckOption";

const HsdConsumptionSap = ({
  filterForm,
  cref,
  optionsList = [],
  form,
  tabValIs,
  setMinMaxDate,
}) => {
  const [fuelData, setFuelData] = useState({});
  const [tabValue, setTabValue] = useState("daily");
  const [headerFilter, setHeaderFilter] = useState("");
  const [activeEqptTab, setActiveEqptTab] = useState("");
  const [tabFilterOption, setTabFilterOption] = useState([]);
  const [pageTable1, setPageTable1] = useState(1);
  const [hemmDataList, sethemmDataList] = useState([]);
  const [finalColumns, setFinalColumns] = useState([]);
  const tableRef1 = useRef(null);
  const [filterDataBy, setFilterDataBy] = useState();

  const [headerForm, onHandleChange, setHeaderForm] = useForm({
    ...headerChartFilter,
  });
  useEffect(() => {
    let filter = "";
    for (const key in headerForm) {
      const element = headerForm[key];
      if (element) {
        filter += `&${key}=${element}`;
      }
    }
    setHeaderFilter(filter);
  }, [headerForm]);

  const TabChange = (val) => setTabValue(val);

  const fetchData = useCallback(
    async (filter) => {
      let response = await getHsdConsumptionSapData(filter);
      if (response?.status === 1 && response?.data) {
        sethemmDataList(response?.data?.consumptionDetails || []);
        setPageTable1(1);
        const consumptionChart = multiLineChartDataFormat(
          response.data?.capacityAcrossDaya
        );
        const FinalFilterData =
          tabValIs === "KL"
            ? CONVERT_KL_L_FILTER(
                { ...response?.data, capacityAcrossDays: consumptionChart },
                tabValIs
              )
            : {};

        if (tabValIs === "L") {
          const capacityAcrossDaysData = consumptionChart?.data?.map(
            (eleObj) => {
              return {
                ...eleObj,
                // name: eleObj?.name + tabValIs,
                data: eleObj?.data?.map((o) => isDecimal(o)),
                name: eleObj?.name ? `${eleObj?.name?.split(" - ")[0]}` : "",

                // name: eleObj?.name
                //   ? `${eleObj?.name?.split(" - ")[0]} - ${isDecimal(
                //       +eleObj?.name?.split(" - ")[1]
                //     )}${tabValIs}`
                //   : "",
              };
            }
          );
          FinalFilterData["capacityAcrossDays"] = {
            ...consumptionChart,
            data: capacityAcrossDaysData,
          };
        }
        setFuelData({
          ...response.data,
          ...FinalFilterData,
        });
        setMinMaxDate(response?.data?.minMax_date || {});
      }
    },
    [tabValIs]
  );

  useEffect(() => {
    const FinalFilterData = CONVERT_KL_L_FILTER(fuelData, tabValIs);
    setFuelData((prev) => ({ ...prev, ...FinalFilterData }));
  }, [tabValIs]);

  const HEMMDetail = [
    {
      isShowMobile: true,
      name: "Eqpt. ID",
      selector: "hemm_id",
    },
    {
      name: "Type",
      selector: "type",
    },
    {
      selector: "make",
      name: "Make",
    },
    {
      name: "Tank Capacity",
      selector: "capacity",
      cell: (row) =>
      tabValIs === "L"
        ? isDecimal(row?.capacity)
        : isDecimal(row?.capacity / 1000),

    },
    {
      name: "Load Capacity",
      selector: "loadCapacity",
    },
    {
      selector: "model",
      name: "Model",
    },
    {
      cell: (row) => (row?.fuling_date ? DDMMYYYYFormat(row?.fuling_date) : ""),
      name: "Date",
      selector: "fuling_date",
    },
    {
      name: "Fueling Qty " + tabValIs,
      selector: "fuling_qty",
      cell: (row) =>
      tabValIs === "L"
        ? isDecimal(row?.fuling_qty)
        : isDecimal(row?.fuling_qty / 1000),
      isShowMobile: true,
    },
    {
      name: "Running Hours",
      selector: "runningHours",
   
    },
    {
      name: "SFC (Per Hour)",
      selector: "sfc",
      cell: (row) =>
      tabValIs === "L"
        ? isDecimal(row?.sfc)
        : isDecimal(row?.sfc / 1000),
    },
    {
      name: "SFC (Per CuM)",
      selector: "sfcProduction",
      cell: (row) =>
      tabValIs === "L"
        ? isDecimal(row?.sfcProduction)
        : isDecimal(row?.sfcProduction / 1000),
    },
  ];

  const fuelDashboardTblFunc = async (filter, type, setPage) => {
    const response = await getFuelTabFirstDashboardTbl(filter);

    if (!response?.status) {
      setPage((pre) => pre - 1);
      return;
    }

    if (tabValIs === "KL") {
      response.data.hemmData = response?.data?.hemmData?.map((ele) => {
        Object.assign(
          ele,
          ele?.last_fuling_qty && {
            last_fuling_qty: ele?.last_fuling_qty / 1000,
          },
          { fuling_qty: ele?.fuling_qty / 1000 }
        );
        return ele;
      });
    }

    const appendData = response?.data.hemmData || [];
    if (type === fuelTableTypeObj?.hemmData) {
      sethemmDataList((prev) => [...prev, ...appendData]);
    }
  };

  useEffect(() => {
    if (!activeEqptTab) return;
    const chartFltr = `&fuelConsumptionAcrossDays=${tabValue}`;
    const subEqptFltr = `&subEqptType=${activeEqptTab}`;
    const finalFilter = `${filterForm}${headerFilter}${chartFltr}${subEqptFltr}`;
    setFilterDataBy(finalFilter);
    fetchData(finalFilter);
  }, [activeEqptTab, fetchData, headerFilter, tabValue]);

  useImperativeHandle(
    cref,
    () => {
      const chartFltr = `&fuelConsumptionAcrossDays=${tabValue}`;
      const subEqptFltr = `&subEqptType=${activeEqptTab}`;
      const finalFilter = `${filterForm}${headerFilter}${chartFltr}${subEqptFltr}`;
      return {
        dashboardDataFun: () => fetchData(finalFilter),
      };
    },
    [activeEqptTab, fetchData, filterForm, headerFilter, tabValue]
  );

  const onClickTab = (activeTab) => {
    setActiveEqptTab(activeTab);
  };

  const finalTabList = useMemo(() => {
    return eqptTypeTabList(optionsList?.equipment_type)?.map((tabObj) => {
      const isVisibal =
        form?.eqptType && form?.eqptType?.length
          ? form?.eqptType?.includes(tabObj?.value)
          : true;
      return { ...tabObj, visibalTab: isVisibal };
    });
  }, [form?.eqptType, optionsList?.equipment_type]);

  useEffect(() => {
    let activeTabValue = "";
    if (form?.eqptType && form?.eqptType?.length) {
      activeTabValue = form?.eqptType[0];
    } else
      activeTabValue = optionsList?.equipment_type?.length
        ? optionsList?.equipment_type?.[0]?.value
        : "";
    setActiveEqptTab(activeTabValue);
  }, [form?.eqptType, optionsList?.equipment_type]);

  useEffect(() => {
    let options = fuelData.consumptionCapacity?.map((d) => ({
      name: d?.name ? `${d?.name?.split(" - ")[0]}` : "",
      label: d?.name ? `${d?.name?.split(" - ")[0]}` : "",
      value: d?.name ? `${d?.name?.split(" - ")[0]}` : "",
    }));
    setTabFilterOption(options);
  }, [fuelData.consumptionCapacity, tabValIs]);

  useEffect(() => {
    setHeaderForm((prev) => {
      return {
        ...prev,
        filterId: "",
      };
    });
  }, [headerForm?.activeTab, activeEqptTab]);

  const queryString = Object.keys(form)
    .filter((key) => form[key] !== "") // Exclude empty values
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(form[key]))
    .join("&");
  return (
    <div className="dashboardWrapper">
      <Row>
        <Col className="dashboardLeftWidgets">
          <div className={IS_MOBILE() ? "" : `dashCards`}>
            <div
              className={`${
                !IS_MOBILE() ? "d-flex" : ""
              } justify-content-between`}
            >
              {widgetDataSap(fuelData, tabValIs).map((item, i) => (
                <div
                  className={`dashSmallCard ${
                    !IS_MOBILE() ? "top-card-sap" : "mb-3"
                  } `}
                >
                  <FuelCard
                    key={i}
                    label={item.label}
                    quantity={item.quantity}
                    vertical={true}
                    additionalClass={!IS_MOBILE() && "horzText"}
                  />
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row id="screen1">
        <Col className="dashboardRightCharts">
          <div className="tabSection w-100">
            <CustomTabs
              tabeList={finalTabList}
              hideLeftRightBtn
              noLink
              onClickTab={onClickTab}
              activeTab={activeEqptTab}
            />
          </div>
          <Card className="fuelConsumptionCards">
            <div className="secondaryFilter">
              <Row className="align-items-center">
                <Col md={10}>
                  <div className={`tab-option ${!IS_MOBILE() ? "d-flex" : ""}`}>
                    <div className={`${!IS_MOBILE() ? "d-flex" : "d-none"}`}>
                      {widgetDataSapByType(fuelData, tabValIs).map(
                        (item, idx) => (
                          <div className="me-5">
                            <FuelCard
                              key={idx}
                              label={item.label}
                              quantity={item.quantity}
                              vertical={true}
                            />
                          </div>
                        )
                      )}
                    </div>
                    <div
                      className={
                        !IS_MOBILE() ? "smallTabBtn" : "mobSmallTabBtn"
                      }
                    >
                      {FilterTab.map((option, i) => (
                        <ToggleButtonGroup
                          key={"FilterTab" + option.id}
                          type="checkbox"
                          value={headerForm?.activeTab}
                          onChange={() =>
                            onHandleChange({
                              target: {
                                name: "activeTab",
                                value: option.value,
                              },
                            })
                          }
                        >
                          <ToggleButton
                            id={"FilterTab" + option.id}
                            value={option.value}
                          >
                            <span>{option.filterBy}</span>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col
                  md={2}
                  style={{
                    marginLeft: IS_MOBILE() ? "" : "-45px",
                    marginBottom: IS_MOBILE() ? "" : "14px",
                  }}
                >
                  <MultiSelectWithCheckbox
                    label={
                      "All " +
                      FilterTabFuel.filter(
                        (d) => d.value === headerForm?.activeTab
                      )[0].filterBy
                    }
                    className={
                      IS_MOBILE() ? "multiSelectWthDashboard" : "multiSelectWth"
                    }
                    options={tabFilterOption || []}
                    onChange={(selectedRoles) => {
                      onHandleChange({
                        target: {
                          name: "filterId",
                          value: selectedRoles
                            ?.map((obj) => obj.name.replace(",", " "))
                            .join(","),
                        },
                      });
                    }}
                    value={headerForm?.filterId
                      ?.split(",")
                      ?.map((role) => {
                        const foundObj = (tabFilterOption || [])?.find(
                          (obj) => obj.name === role
                        );
                        return foundObj ? { ...foundObj } : null;
                      })
                      ?.filter((d) => !!d)}
                    disableSearch={false}
                    hasSelectAll={false}
                  />
                  {/* <Select
                    label={""}
                    name="filterId"
                    value={headerForm?.filterId}
                    onChange={onHandleChange}
                    options={tabFilterOption}
                    KEY_label={"name"}
                    KEY_value={"value"}
                    blankSelect={
                      FilterTabFuel.filter(
                        (d) => d.value === headerForm?.activeTab
                      )[0].filterBy
                    }
                  /> */}
                </Col>
                {IS_MOBILE() ? (
                  <Col md={12} className="mt-3">
                    {widgetDataSapByType(fuelData, tabValIs).map(
                      (item, idx) => (
                        <FuelCard
                          key={idx}
                          label={item.label}
                          quantity={item.quantity}
                          vertical={true}
                          additionalClass={"mobBgClrTxt"}
                        />
                      )
                    )}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>

            <Row>
              <Col md={6} id="fuelSapChart2">
                <Card className="chartCard chartCardBtmSpace ">
                  <Card.Header className="d-flex justify-content-between">
                    <span>Fuel Dispensed</span>
                    <ZoomScreen
                      id={`fuelSapChart2`}
                      cardSizeClass={"col-md-6"}
                    />
                  </Card.Header>
                  <Card.Body>
                    <TreemapChart
                      height={300}
                      dataArray={
                        headerForm?.filterId
                          ? fuelData?.consumptionCapacity
                              ?.filter(
                                (d) =>
                                  d?.name?.split(" - ")[0] ===
                                  headerForm?.filterId
                              )
                              .map((d) => ({
                                ...d,
                                name: d?.name
                                  ? `${d?.name?.split(" - ")[0]} - ${isDecimal(
                                      d?.value
                                    )} ${tabValIs}`
                                  : "",
                              }))
                          : fuelData?.consumptionCapacity?.map((d) => ({
                              ...d,
                              name: d?.name
                                ? `${d?.name?.split(" - ")[0]} - ${isDecimal(
                                    d?.value
                                  )} ${tabValIs}`
                                : "",
                            }))
                      }
                      sameColorGradient={true}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} id="fuelSapCHart1">
                <Card className="chartCard chartCardBtmSpace">
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <span>Fuel Dispensed Across Days</span>
                    <span className="d-flex">
                      <span className="me-3">
                        <Dropdown className="fuelConsumptionDays">
                          <Dropdown.Toggle>
                            <BiCalendar />
                          </Dropdown.Toggle>
                          {tabValue?.charAt(0)?.toUpperCase()}
                          <Dropdown.Menu>
                            <div className="tab alphabetToggle">
                              {ChartFilter.map((option, i) => (
                                <ToggleButtonGroup
                                  key={i}
                                  type="checkbox"
                                  value={tabValue}
                                  onChange={() => TabChange(option.value)}
                                >
                                  <ToggleButton
                                    id={option.id}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              ))}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </span>
                      <ZoomScreen
                        id={`fuelSapCHart1`}
                        cardSizeClass={"col-md-6"}
                      />
                    </span>
                  </Card.Header>
                  <Card.Body className="pb-5">
                    <MultipleBarChartCard
                      category={fuelData?.capacityAcrossDays?.categoryData}
                      seriesData={
                        isArray(fuelData?.capacityAcrossDays?.data) &&
                        headerForm?.filterId
                          ? fuelData?.capacityAcrossDays?.data
                              ?.filter(
                                (d) =>
                                  d?.name?.split(" - ")[0] ===
                                  headerForm?.filterId
                              )
                              .map((d) => ({
                                ...d,
                              }))
                          : fuelData?.capacityAcrossDays?.data?.map((d) => ({
                              ...d,
                            }))
                      }
                      yAxisName={tabValIs}
                      height={294}
                      // customToolTip
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card>

          <Row className="first-row" style={{ paddingRight: 0 }}>
            <Col>
              <Card className="chartCard tableCard">
                <Card.Header> 
                  HSD Dispensing and Consumption details
                </Card.Header>

                <Card.Body
                  className="p-0"
                  style={{ marginBottom: IS_MOBILE() ? "40px" : "" }}
                >
                  <DataTableComponent
                    columns={HEMMDetail}
                    data={hemmDataList}
                    page={pageTable1}
                    setPage={setPageTable1}
                    ref={tableRef1}
                    tableName={fuelTableTypeObj?.hemmData}
                    totalCount={fuelData?.totalCount}
                    matchCount={hemmDataList?.length}
                    filterData={`&subEqptType=${activeEqptTab}` + filterForm}
                    onCallScrollFunc={fuelDashboardTblFunc}
                    fixedHeader={true}
                    filterDataBy={filterDataBy + "&" + queryString}
                    getApi={getHsdConsumptionSapData}
                    dashboardType="fuelSapDashboard1"
                    
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default HsdConsumptionSap;
