import React, { useState } from "react";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { capaPriority } from "../../DATA";
import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";
import { PARSE_IMG_URL } from "../../../../_helpers/commonHelper";
import { URL_CONFIG } from "../../../../_constants/Config/URL_CONFIG";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CreatedByCreatedAt from "../../../../components/Common/CreatedByCreatedAt";

const CapaDetailsView = ({ form }) => {
  const [imageShowManageModal, setImageShowManageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <Row>
              <Col lg={4}>
                <label>Incident ID</label>
                <p>{form?.incidentData?.incident_uid}</p>
              </Col>
              <Col lg={4}>
                <label>CAPA ID</label>
                <p>{form?.capaId}</p>
              </Col>
              <Col lg={4} key={"idx"}>
                <label>CAPA Type</label>
                <p>{form?.capaType}</p>
              </Col>
              <Col lg={4}>
                <label>CAPA Description</label>
                <p>{form?.remarks}</p>
              </Col>
              <Col lg={4}>
                <label>CAPA Owner</label>
                <p>{form?.owner?.firstName + " " + form?.owner?.lastName}</p>
              </Col>
              <Col lg={4}>
                <label>CAPA Priority</label>
                <p>{capaPriority[form?.priority]}</p>
              </Col>
              <Col lg={4}>
                <label>Due Date</label>
                <p>{DDMMYYYYFormat(form?.dueDate)}</p>
              </Col>
              <Col lg={4}>
                <label>Created Date</label>
                <p>{DDMMYYYYFormat(form?.createdAt)}</p>
              </Col>
              <Col lg={4}>
                <label>Subsidiary</label>
                <p>{form?.site?.parentSite?.name}</p>
              </Col>
              <Col lg={4}>
                <label>Mine</label>
                <p>{form?.site?.name}</p>
              </Col>

              <Col lg={8}>
                <label>CAPA Status</label>
                <p>{form?.capaStatus}</p>
              </Col>
              {form?.capaStatus === "completed" && (
                <>
                  <Col lg={4}>
                    <label>Completion Date</label>
                    <p>
                      {form?.completionDate
                        ? DDMMYYYYFormat(form?.completionDate)
                        : ""}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <label>Describe Actions Taken or Resolution Provided</label>
                    <p>{form?.actionsOrResolutions}</p>
                  </Col>
                  {form?.completionProof && (
                    <>
                      <Col lg={4}>
                        <label>Completion Proof</label>
                        <p>{form?.completionProof?.alias}</p>
                      </Col>
                      <Col lg={4}>
                        <label>File View</label>
                        <div className="safetyMedia">
                          <div className="safetyImgSec">
                            <img
                              onClick={() => {
                                setImageShowManageModal(true);
                                setImageUrl(
                                  PARSE_IMG_URL(
                                    URL_CONFIG.DEV_URL,
                                    form?.completionProof?.document_name
                                  )
                                );
                              }}
                              src={PARSE_IMG_URL(
                                URL_CONFIG.DEV_URL,
                                form?.completionProof?.document_name
                              )}
                              className="img-fluid "
                              alt="IMG"
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Row>
          </div>
          <CreatedByCreatedAt id={form?.id} table={"capas"} border={false} />
        </div>
      </Modal.Body>

      <Modal
        show={imageShowManageModal}
        onHide={() => setImageShowManageModal(false)}
        size="lg"
        centered
      >
        <ModalBody className="safetyModal">
          <div
            className="closeIcon"
            data-testid="filter-button"
            onClick={() => {
              setImageShowManageModal(false);
            }}
          >
            <IoIosCloseCircleOutline />
          </div>
          <div className="popupImgVideo text-center">
            <img className="img-fluid" src={imageUrl} alt="" />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CapaDetailsView;
