import React from "react";
import ReactEcharts from "echarts-for-react";
import NoRecord from "./NoRecord";
import { IS_MOBILE } from "../../_helpers/helper";

const TreemapChart = ({ dataArray, height, sameColorGradient }) => {
  const getOption = () => {
    const getMaxValue = () => {
      return Math.max(...dataArray.map((item) => item.value));
    };

    const max = getMaxValue();

    const getColor = (value) => {
      const saturation = 100;
      const lightness = 50 - (value / (max * 1.5)) * 50;
      return `hsl(210, ${saturation}%, ${lightness}%)`;
    };

    return {
      series: [
        {
          type: "treemap",
          data: sameColorGradient
            ? dataArray.map((item) => ({
                ...item,
                itemStyle: {
                  color: getColor(item.value),
                },
              }))
            : dataArray,
          breadcrumb: {
            show: false,
          },
        },
      ],
      textStyle: {
        fontSize: 15,
      },
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          return `${params.name}`;
        },
      },
    };
  };
  return (
    <React.Fragment>
      {Array.isArray(dataArray) && dataArray.length > 0 ? (
        <ReactEcharts
          option={getOption()}
          style={{ height: IS_MOBILE() ? "150px" : height ? height : "300px" }}
          className="treemap-chart"
        />
      ) : (
        <NoRecord />
      )}
    </React.Fragment>
  );
};

export default TreemapChart;
