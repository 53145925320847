import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import FormInputText from "../../../components/FormInputText/FormInputText";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import Select from "../../../components/Select/Select";
import { getAllSites } from "../../../services/_uploadedFiles/uploadedFiles";
import { getAllSection as getAllSectionApi } from "../../../services/_reasonList/reasonList";
import { capitalizeName } from "../../../_helpers/helper";
import {
  contractorRules,
  formFields,
  nitRules,
  production_option,
} from "./DATA";
import { Validation } from "../../../_helpers/validation/Validation";
import {
  createContractor,
  updateContractor,
} from "../../../services/_contractorList/contractorList";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import PerfectScrollbar from "react-perfect-scrollbar";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { Label } from "reactstrap";
import { createNit, updateNit } from "../../../services/_nitList/nitList";
import {
  getAllContractor,
  getAllContractorList,
} from "../../../services/_contractorPerformance/contractorPerformance";
import { IS_USER_SITE_MINES, auth_user } from "../../../_helpers/helper";
import { checkAllowedString } from "../../../_helpers/helper";
const NitAdd = () => {
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [form, setForm] = useState(formFields);
  const [formFieldError, setFormFieldError] = useState(formFields);
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [contractorList, setContractorList] = useState([]);
  const [sectionList, setSectionList] = useState([
    { value: 0, label: "Not Applicable" },
  ]);
  const [siteList, setSiteList] = useState([]);

  const [duplicateContractorCode, setDuplicateContractorCode] = useState("");
  const navigate = useNavigate();
  const param = useParams();

  const getData = useCallback(async () => {
    const response = await RestMethod.GET("/contractor-nit/" + param.id);
    setForm({ ...response?.data?.ContractorNit });
  }, [param.id]);

  const onChange = (e, idx) => {
    const { name, value } = e.target;
    formFieldError[name] = "";
    setForm({ ...form, [name]: value });
  };

  const getSiteList = async () => {
    let list = "";
    const response = await getAllSites();
    if (response?.status) {
      list = response?.data?.map((d) => {
        return { value: d.id, label: d.name };
      });
    }
    setSiteList(list);
    return list;
  };

  let list2 = [{ value: 0, label: "Not Applicable" }];
  const getAllSection = async () => {
    let list = [];
    let filter = `site_id=${form?.site_id}`;
    const response = await getAllSectionApi(filter);
    if (response?.status) {
      list = response?.data
        ?.filter((ele) => ele?.status)
        ?.map((d) => {
          return { value: d.id, label: capitalizeName(d.name) };
        });
    }
    setSectionList([...list, ...list2]);
    return list;
  };

  useEffect(() => {
    if (!form?.site_id) {
      setSectionList([{ value: 0, label: "Not Applicable" }]);
      return;
    }
    getAllSection();
  }, [form?.site_id]);

  const getAll = async () => {
    const allContractorList = await getAllContractorList();

    setContractorList(allContractorList);
  };
  useEffect(() => {
    setFormFieldError({});
    getAll();
  }, []);

  const closeMessagePopup = () => {
    setShowMessagePopUp(false);
    navigate("/contractor-management/configuration/nit-list");
  };
  useEffect(() => {
    if (!param.id) return;
    getData();
  }, [getData, param.id]);

  useEffect(() => {
    (async () => {
      getSiteList();
    })();
  }, []);

  const onSubmit = async () => {
    let response = "";
    let errorObj = {};
    let data = {};
    for (let data in form) {
      nitRules.forEach((d) => {
        if (d.field_name === data) {
          if (form[data] === "NA") {
            d.isRequired = false;
            d.type = "text";
          } else if (
            [
              "payLoader",
              "grader",
              "crusher",
              "excavator",
              "tipper",
              "drill",
              "dozer",
              "waterTanker",
            ].includes(d.field_name)
          ) {
            d.isRequired = true;
            d.type = "number";
          }
        }
      });
    }

    errorObj = Validation(form, nitRules);
    if (form?.nit && !checkAllowedString(form?.nit))
      errorObj.nit = "Invalid Id";
    if (form?.tender_id && !checkAllowedString(form?.tender_id))
      errorObj.tender_id = "Invalid Id";

    console.log(checkAllowedString(form?.nit));

    setFormFieldError(errorObj);

    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    data = { ...form };
    if (
      data.crusher === "NA" &&
      data.dozer === "NA" &&
      data.excavator === "NA" &&
      data.grader === "NA" &&
      data.payLoader === "NA" &&
      data.tipper === "NA" &&
      data.waterTanker === "NA" &&
      data.drill === "NA"
    ) {
      toastr.error("Atleast one NIT Spec is required.");
      return;
    }
    if (param.id) {
      response = await updateNit(data, param.id);
    } else {
      response = await createNit(data);
    }

    if (!response?.data?.status || response?.data?.status === 401) {
      setDuplicateContractorCode("Code already exists");
      !response?.data?.status && toastr.error(response?.data?.message);
    } else {
      setDuplicateContractorCode("");
      setShowMessagePopUp(true);
      toastr.success("data saved successfully");
    }
  };
  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;

    const siteObj =
      (siteList && siteList?.find((d) => +d?.value === +auth_user()?.siteId)) ||
      {};
    setForm((prev) => ({
      ...prev,
      site_id: siteObj?.value,
    }));
  }, [siteList]);
  useEffect(() => {
    if (form.production_type === "1") setForm({ ...form, compliance: "" });
    if (form.production_type === "2") setForm({ ...form, qpd_proposed: "" });
  }, [form.production_type]);
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              className="title"
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/contractor-management/configuration",
                  },
                  {
                    name: "NIT Master List",
                    path: "/contractor-management/configuration/nit-list",
                  },
                  {
                    name: param.id ? "Edit NIT Master" : "Add NIT Master",
                    path: "#",
                  },
                ]}
                // module={ACTIVE_MODULE.incident}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              {param.id ? "Edit" : "Add"} NIT Master
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <Form>
                  <Row>
                    <Col sm={3}>
                      <Select
                        onChange={onChange}
                        name="site_id"
                        options={siteList}
                        label={"Mine"}
                        placeholder={"Enter Here"}
                        value={form?.site_id}
                        error={formFieldError?.site_id}
                        disabled={IS_USER_SITE_MINES()}
                      />
                    </Col>
                    <Col md={3}>
                      <Select
                        onChange={onChange}
                        value={form?.contractor_id}
                        name="contractor_id"
                        options={contractorList}
                        label={"Contractor"}
                        error={formFieldError?.contractor_id}
                        KEY_label={"contractor"}
                        KEY_value={"id"}
                        // disabled={!!param.id}
                      />
                    </Col>
                    {param.id && (
                      <Col sm={3}>
                        <FormInputText
                          type="text"
                          placeHolder="NA"
                          label={"PO"}
                          onChange={onChange}
                          name="po"
                          error={formFieldError?.poDetails?.po}
                          value={form?.poDetails?.po}
                          disabled
                        />
                      </Col>
                    )}
                    <Col md={3}>
                      <Select
                        onChange={onChange}
                        value={form?.section}
                        name="section"
                        options={sectionList}
                        label={"Section / Patch"}
                        error={formFieldError?.section}
                        // KEY_label={"section"}
                        // KEY_value={"id"}
                        // disabled={!!param.id}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormInputText
                        type={"text"}
                        placeHolder="Enter here"
                        label={"NIT Number"}
                        onChange={onChange}
                        name="nit"
                        error={formFieldError?.nit}
                        value={form?.nit}
                      />
                    </Col>
                    <Col sm={3}>
                      <Label className="form-label-input">Start Date</Label>
                      <CustomDatePicker
                        type="text"
                        placeHolder="Enter here"
                        label={"Start Date"}
                        onChange={onChange}
                        name="startDate"
                        error={formFieldError?.startDate}
                        value={form?.startDate}
                      />
                    </Col>
                    <Col sm={3}>
                      <Label className="form-label-input">End Date</Label>
                      <CustomDatePicker
                        type="text"
                        placeHolder="Enter here"
                        label={"End Date"}
                        onChange={onChange}
                        name="endDate"
                        error={formFieldError?.endDate}
                        value={form?.endDate}
                      />
                    </Col>
                    <Col sm={3}>
                      <Select
                        onChange={onChange}
                        name="production_type"
                        options={production_option}
                        label={"Production Type"}
                        placeholder={"Enter Here"}
                        value={form?.production_type}
                        error={formFieldError?.production_type}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormInputText
                        type="text"
                        placeHolder="Contract ID"
                        label={"Contract ID"}
                        onChange={onChange}
                        name="tender_id"
                        error={formFieldError?.tender_id}
                        value={form?.tender_id}
                      />
                    </Col>
                    {/* <Col sm={3}>
                      <FormInputText
                        onChange={onChange}
                        name="contractor_patch"
                        options={siteList}
                        label={"Contractor Patch"}
                        placeholder={"Enter Here"}
                        value={form?.contractor_patch}
                        error={formFieldError?.contractor_patch}
                      />
                    </Col> */}
                    <Col sm={3}>
                      <FormInputText
                        type="number"
                        placeHolder="Enter here"
                        label={"QPD Proposed OB (Monthly)"}
                        onChange={onChange}
                        name="compliance"
                        error={formFieldError?.compliance}
                        value={form?.compliance}
                        disabled={form?.production_type === "1"}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormInputText
                        type="number"
                        placeHolder="Enter here"
                        label={"QPD Proposed Coal (Monthly)"}
                        onChange={onChange}
                        name="qpd_proposed"
                        error={formFieldError?.qpd_proposed}
                        value={form?.qpd_proposed}
                        disabled={form?.production_type === "2"}
                      />
                    </Col>
                    <Col sm={3}></Col>
                    <Col sm={3}></Col>
                    {/* new fields for nit */}
                  </Row>
                  <div className={"seperator mt-0"}></div>
                  <h5>NIT Specs</h5>
                  <Row className="previewContent">
                    <Col sm={3}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Excavator"}
                        onChange={onChange}
                        name="excavator"
                        error={formFieldError?.excavator}
                        value={form?.excavator}
                        NA={true}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Tipper"}
                        onChange={onChange}
                        name="tipper"
                        error={formFieldError?.tipper}
                        value={form?.tipper}
                        NA={true}
                      />
                    </Col>{" "}
                    <Col sm={3}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Drill"}
                        onChange={onChange}
                        name="drill"
                        error={formFieldError?.drill}
                        value={form?.drill}
                        NA={true}
                      />
                    </Col>{" "}
                    <Col sm={3}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Dozer"}
                        onChange={onChange}
                        name="dozer"
                        error={formFieldError?.dozer}
                        value={form?.dozer}
                        NA={true}
                      />
                    </Col>{" "}
                    <Col sm={3}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Water Tanker"}
                        onChange={onChange}
                        name="waterTanker"
                        error={formFieldError?.waterTanker}
                        value={form?.waterTanker}
                        NA={true}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Grader"}
                        onChange={onChange}
                        name="grader"
                        error={formFieldError?.grader}
                        value={form?.grader}
                        NA={true}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Pay Loader"}
                        onChange={onChange}
                        name="payLoader"
                        error={formFieldError?.payLoader}
                        value={form?.payLoader}
                        NA={true}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormInputText
                        type="text"
                        placeHolder="Enter here"
                        label={"Crusher"}
                        onChange={onChange}
                        name="crusher"
                        error={formFieldError?.crusher}
                        value={form?.crusher}
                        NA={true}
                      />
                    </Col>
                  </Row>
                </Form>

                <div className="btnSec">
                  <Button
                    onClick={() =>
                      navigate("/contractor-management/configuration/nit-list")
                    }
                    className="secondaryBtn"
                  >
                    Back
                  </Button>
                  <div className="d-flex">
                    <Button
                      className="primaryBtn"
                      onClick={onSubmit}
                      // nextStapeFun={nextStapeFun}
                      data-testid="submit-nit"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            messagePopupType={messagePopupType}
            show={showMessagePopUp}
            setShow={setShowMessagePopUp}
            closePopup={closeMessagePopup}
          />
        </div>
      </div>
    </div>
  );
};

export default NitAdd;
