import moment from "moment";
import { permissionCheck } from "../_services/Auth/helper";
import { createBrowserHistory } from "history";

export const findUserPermissionedTabList = (urlList) => {
  return urlList?.filter((d) => {
    return permissionCheck(d?.permission);
  });
};

let monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function diff(from, to) {
  let arr = [];
  let datFrom = new Date(from);
  let datTo = new Date(to);
  let fromYear = datFrom.getFullYear();
  let toYear = datTo.getFullYear();
  let diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

  for (let i = datFrom.getMonth(); i <= diffYear; i++) {
    arr.push(monthNames[i % 12] + " " + Math.floor(fromYear + i / 12));
  }

  return arr;
}

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isString = (str) => {
  try {
    return typeof str === "string";
  } catch (e) {
    return false;
  }
};

export const parseJson = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return null;
  }
};

export const isLogin = () => {
  return !!localStorage.getItem("jwt_token");
};

export const auth_user = () => {
  const auth_user = JSON.parse(localStorage.getItem("auth_user"));
  return auth_user ? auth_user : false;
};

export const LOGGED_USER_ID = () => {
  return +auth_user()?.id || "";
};

export const LOGGED_USER_SITE_ID = () => {
  return +auth_user()?.siteId || "";
};

export const IS_USER_GPS_ACTIVE = () => {
  return !!auth_user()?.gps_active;
};

export const IS_ONLY_BARCODE_SCAN = () => {
  return !!auth_user()?.only_barcode_scan;
};

export const IS_USER_SITE_HO = () => {
  return +auth_user()?.site?.siteType === 1;
};

export const IS_USER_SITE_RO = () => {
  return +auth_user()?.site?.siteType === 2;
};

export const IS_USER_SITE_MINES = () => {
  return +auth_user()?.site?.siteType === 3;
};

export const subsidiaryListByMine = (mines = []) => {
  let subsideryData = [];
  if (IS_USER_SITE_MINES()) {
    subsideryData = [
      {
        id: mines?.length ? mines?.[0]?.parentSite?.id : "",
        name: mines?.length ? mines?.[0]?.parentSite?.name : "",
      },
    ];
  } else {
    subsideryData = mines?.filter((d) => d?.siteType === 2);
  }
  return subsideryData;
};

export const idByCheckMine = (sites = []) => {
  let siteDataObj = { siteId: "", subsideryId: "" };
  if (IS_USER_SITE_HO()) return siteDataObj;
  const siteObj = sites?.find((d) => d.id === auth_user()?.siteId) || {};
  siteDataObj.siteId = IS_USER_SITE_MINES() ? siteObj?.id : "";
  siteDataObj.subsideryId = IS_USER_SITE_MINES()
    ? siteObj?.parentSite?.id
    : auth_user()?.siteId;
  return siteDataObj;
};

export const customSearchFilter = (searchText, searchColumn, obj) => {
  let flag = 0;
  for (const key in obj)
    if (searchColumn.includes(key)) {
      if (
        obj[key] &&
        obj[key]
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchText?.toString()?.toLowerCase())
      )
        flag++;
    }
  return flag ? true : false;
};

export const searchPlaceHolderText = (searchColumn, searchLabel) => {
  const showText = [];
  for (const key in searchLabel)
    if (searchColumn.includes(key)) showText.push(searchLabel[key]);
  return "Search by " + showText?.join(", ");
};

export const addMinutes = (time, minuteCount) => {
  return moment(time, "HH:mm").add(minuteCount, "minutes").format("HH:mm");
};

export const isAfterHoursMinutes = (selectedDay) => {
  return moment(selectedDay).isSameOrBefore(moment(), "minute");
};

export const capitalizeName = (name = "") => {
  const arr = name?.split(" ");

  if (arr?.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const newName = arr.join(" ");
    return newName;
  } else return "";
};

export const capitalizeNameLower = (name = "") => {
  const arr = name.split(" ");

  if (arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toLowerCase() + arr[i].slice(1);
    }
    const newName = arr.join(" ");
    return newName;
  } else return "";
};

export const fileExtension = (name) => {
  const arr = name.split(".");
  const length = arr.length;
  if (length > 1) return arr[length - 1];
  else return "";
};

export function getTimeDifference(date) {
  let difference =
    moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
      moment(date, "DD/MM/YYYY HH:mm:ss")
    ) / 1000;

  if (difference < 60) return `${Math.floor(difference)} seconds`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} days`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} months`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export const NUMBER_BY_TRUE_FALSE = (value) => {
  return value ? 1 : 0;
};

export const YES_NO_BY_NUMBER = (value) => {
  return +value === 1 ? "Yes" : "No";
};

export const TRUE_FALSE_BY_NUMBER = (value) => {
  return +value ? true : false;
};

export function employeeDropdownLabel(employee) {
  return `${capitalizeName(employee?.firstName + " " + employee?.lastName)} / ${
    employee?.email
  }`;
}

export const IS_MOBILE = () => {
  const { pathname } = createBrowserHistory().location;
  return !!pathname.includes("mobile");
};

export function decimalPointNumber(x, n) {
  return parseFloat(Number.parseFloat(x).toFixed(n));
}

export const yearAgoToCurrentYearList = (YearsAgo) => {
  const currentYear = moment().year();
  const YearsAgoValue = moment().subtract(YearsAgo, "years").year();

  const yearsArray = [];
  for (let year = YearsAgoValue; year <= currentYear; year++) {
    yearsArray.push(year);
  }
  return yearsArray;
};

export const isDecimal = (num, fixed = 2) => {
  let value = +num;
  if ((value ^ 0) !== value) {
    return value?.toFixed(fixed);
  } else return value;
};

export const checkDecimal = (value) => {
  return !!(+value ^ 0) != +value;
};

// convert value liter to kilo liter
export const L_TO_KL = (value) => {
  return isDecimal(+value ? +value / 1000 : 0);
};

// convert value kilo liter to liter
export const KL_TO_L = (value) => {
  return isDecimal(+value ? +value * 1000 : 0);
};

export const checkAllowedString = (inputString) => {
  const pattern = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~`\\\-/"']+$/;

  return pattern.test(inputString) && !/^(?:\s|\s)$/.test(inputString);
};

export const checkNumberString = (mixedValue) => {
  if (+mixedValue > 0) return +mixedValue;
  else return mixedValue;
};

export const siteId = {
  1: "HO",
  2: "NCL",
  3: "SECL",
  4: "Nigahi",
  5: "Jayant",
  6: "Dudhichua",
  7: "Khadia",
  8: "Kusmunda",
  9: "Gevra",
  11: "Dipka",
};
export const employeeNameEmailLabel = () => {
  const auth_user = JSON.parse(localStorage.getItem("auth_user"));
  let SitesId = siteId;
  return {
    nameEmail:
      (auth_user?.firstName || "") +
      " " +
      (auth_user?.lastName || "") +
      " / " +
      (auth_user?.email || ""),
    id: auth_user?.id || null,
    mine: SitesId[auth_user?.siteId] || null,
    subsidiary:
      auth_user?.siteId === 1
        ? ""
        : [7, 5, 6, 7].includes(auth_user?.siteId)
        ? "NCL"
        : "SECL",
    name: (auth_user?.firstName || "") + " " + (auth_user?.lastName || ""),
    email: auth_user?.email || "",
    contactNumber: auth_user?.contactNumber,
  };
};

export const getGValue = (inputValue) => {
  const valueRanges = [
    { min: 2200, max: 2500, gValue: "G17" },
    { min: 2500, max: 2800, gValue: "G16" },
    { min: 2800, max: 3100, gValue: "G15" },
    { min: 3100, max: 3400, gValue: "G14" },
    { min: 3400, max: 3700, gValue: "G13" },
    { min: 3700, max: 4000, gValue: "G12" },
    { min: 4000, max: 4300, gValue: "G11" },
    { min: 4300, max: 4600, gValue: "G10" },
    { min: 4600, max: 4900, gValue: "G9" },
    { min: 4900, max: 5200, gValue: "G8" },
    { min: 5200, max: 5500, gValue: "G7" },
    { min: 5500, max: 5800, gValue: "G6" },
    { min: 5800, max: 6100, gValue: "G5" },
    { min: 6100, max: 6400, gValue: "G4" },
    { min: 6400, max: 6700, gValue: "G3" },
    { min: 6700, max: 7000, gValue: "G2" },
  ];

  for (const range of valueRanges) {
    if (inputValue > range.min && inputValue <= range.max) {
      return range.gValue;
    }
  }

  if (inputValue >= 7000) {
    return "G1";
  }

  return null;
};

//Contractor nit check
export const checkContractStatus = (data, id) => {
  const today = new Date();
  const contractor = data.find((item) => item.value === id);

  const parseDate = (dateString) => {
    if (dateString) {
      const [day, month, year] = dateString.split("-").map(Number);
      return new Date(year, month - 1, day + 1);
    }
  };

  const startDate = parseDate(contractor?.startDate);
  const endDate = parseDate(contractor?.endDate);
  const sixMonthsLater = new Date(today); // Create a date 6 months later than today
  sixMonthsLater.setMonth(today.getMonth() + 6);

  if (today > endDate) {
    return "Contract has expired.";
  } else if (endDate < sixMonthsLater) {
    return "Contract is expiring within the next 6 months.";
  }
};

//Adding one day
export const addOneDayAndFormat = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateString);
    return null;
  }
  return date.setDate(date.getDate() + 1);
};

export const nitTenderIdColumn = (row) => {
  const poNumbers = row?.poDetails?.map((item) => item?.po);
  return row?.nit + " / " + poNumbers.toString();
};
export const TenderIdColumn = (row) => {
  return row?.nit?.nit + " / " + row?.po?.po;
};

export const UserPrivilege = () => {
  const auth_user = JSON.parse(localStorage.getItem("auth_user"));
  let SitesId = {
    1: "CIL HQ",
    2: "NCL HQ",
    3: "SECL HQ",
    4: "Nigahi",
    5: "Jayant",
    6: "Dudhichua",
    7: "Khadia",
    8: "Kusmunda",
    9: "Gevra",
    11: "Dipka",
  };

  let data;
  auth_user?.permissions && (data = JSON.parse(auth_user?.permissions));
  if (data?.super_admin) {
    return "Super Admin";
  } else {
    let site = SitesId[auth_user?.siteId];
    if (auth_user?.contractor?.contractor)
      return auth_user?.contractor?.contractor + " / " + site;
    else return site;
  }
};

export const DaysBetweenDates = (dateStr1, dateStr2) => {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  const timeDifference = Math.abs(date2 - date1);

  const millisecondsInADay = 24 * 60 * 60 * 1000;

  // Calculate the number of days
  const days = Math.floor(timeDifference / millisecondsInADay) + 1;

  return days;
};

export const isDateFromThreeDaysBack = (givenDateString) => {
  const date = new Date(givenDateString);
  const currentDate = new Date();
  const differenceInMs = currentDate - date;
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  return differenceInDays <= 2;
};

export const isDecimalCheck = (num) => {
  return +num % 1;
};

export const isNotInteger = (num) => {
  return num && num?.toString().includes(".");
};

export const digitCheckAfterDecimal = (value, digitNum) => {
  const afterDecimalValue = value.split(".")[1];
  return !!!(afterDecimalValue && afterDecimalValue.length > digitNum);
};

export const isObject = (variable) => {
  return variable instanceof Object && !Array.isArray(variable);
};

export function isNegative(value) {
  return +value < 0;
}

export const getUniqueQueryParams = (queryString) => {
  const params = new URLSearchParams(queryString);
  const uniqueParams = new Map();

  for (const [key, value] of params) {
    uniqueParams.set(key, value);
  }

  const uniqueQueryParams = new URLSearchParams(uniqueParams).toString();
  return uniqueQueryParams;
};
