import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import toastr from "toastr";
import { useNavigate, useParams, useLocation } from "react-router";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import FormInputText from "../../../components/FormInputText/FormInputText";
import {
  createMasterDropdown,
  updateMasterDropdown,
} from "../../../services/_reasonList/reasonList";
import { SidebarData as incidentSidebarData } from "../../SafetyManagementSystem/IncidentManagement/DATA";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData, isCodeApplied, validationRules } from "../DATA";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { FuelSidebarData } from "../../FuelManagementSystem/FuelManagement/DATA";
import { Validation } from "../../../_helpers/validation/Validation";

const SamplePrepareRegisterAdd = ({ flag }) => {
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [formFieldError, setFormFieldError] = useState({
    name: "",
    type: "",
    code: "",
  });
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [form, setForm] = useState({
    name: "",
    type: "",
  });
  const [tab, setTab] = useState(1);
  const param = useParams();
  const navigate = useNavigate();
  const usrl = useLocation();
  let showCode = true;

  showCode =
    usrl.pathname.includes("/violations-leading") ||
    usrl.pathname.includes("/designation");

  const getData = useCallback(async () => {
    const response = await RestMethod.GET("/master-data/" + param.id);
    setForm({ ...response?.data?.data });
  }, [param.id]);

  const validation = (row) => {
    if (row?.name === "") {
      return { name: "Name is Required" };
    } else {
      return { name: "" };
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    formFieldError[name] = "";
    setForm({ ...form, [name]: value });
  };

  const closeMessagePopup = () => {
    setShowMessagePopUp(false);
    if (flag)
      navigate("/safety-management-system/configuration/violations-leading");
    else if (usrl.pathname.includes("/workshop-master"))
      navigate("/fuel-management-system/configuration/workshop-master");
    else if (usrl.pathname.includes("/supplier"))
      navigate("/fuel-management-system/configuration/supplier");
    else if (usrl.pathname.includes("/designation"))
      navigate("/safety-management-system/configuration/designation");
    else if (usrl.pathname.includes("/other-reason-list"))
      navigate("/contractor-management/configuration/other-reason-list");
    else navigate("/contractor-management/configuration/reason-list");
  };

  const tabFun = (n) => {
    setTab(n);
  };

  useEffect(() => {
    if (!param.id) return;
    getData();
  }, [getData, param.id]);

  const onSubmit = async () => {
    let response = "";
    let data = "";
    if (usrl.pathname.includes("/workshop-master"))
      data = { type: "workshop_id" };
    else if (usrl.pathname.includes("/designation"))
      data = { type: "designation" };
    else if (usrl.pathname.includes("/supplier")) data = { type: "supplier" };
    else if (usrl.pathname.includes("/other-reason-list"))
      data = { type: "other_reason_list" };
    else data = { type: "reason_list" };
    if (flag) data.type = "violations_leading";
    data.name = form?.name;
    if (showCode) {
      data.code = form?.code;
    }
    if (showCode) {
      let error = Validation(data, validationRules);
      setFormFieldError(error);
      if (Object.values(error).filter((d) => d !== "").length) return;
    } else {
      setFormFieldError(validation(form));
    }

    if (form?.name === "") return;
    if (form?.code?.length > 4) {
      setFormFieldError({
        name: "",
        code: "Max 4 digit code is Allowed",
      });
      return;
    }
    if (param.id) response = await updateMasterDropdown(data, param.id);
    else {
      response = await createMasterDropdown(data);
    }

    if (!response?.data?.status || response?.data?.status === 401) {
      !response?.data?.status && toastr.error(response?.data?.message);
    } else {
      toastr.success("data saved successfully");
      setShowMessagePopUp(true);
    }
  };
  return (
    <div className="sidebarWrapper">
      <SafetySideBar
        SidebarData={
          flag || usrl.pathname.includes("/designation")
            ? incidentSidebarData
            : usrl.pathname.includes("/workshop-master") ||
              usrl.pathname.includes("/supplier")
            ? FuelSidebarData
            : SidebarData
        }
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              <MainBreadcrumb
                routeSegments={
                  flag
                    ? [
                        {
                          name: "Safety Management",
                          path: "#",
                        },
                        {
                          path: "/safety-management-system/configuration",
                          name: "Configuration",
                        },
                        {
                          name: param.id
                            ? "Edit Incident/Accident Causes"
                            : "Add Incident/Accident Causes",
                          path: "#",
                        },
                      ]
                    : usrl.pathname.includes("/workshop-master")
                    ? [
                        {
                          name: "Fuel Management",
                          path: "#",
                        },
                        {
                          path: "/fuel-management-system/configuration/workshop-master",
                          name: "Configuration",
                        },
                        {
                          name: param.id ? "Edit Workshop" : "Add Workshop",
                          path: "#",
                        },
                      ]
                    : usrl.pathname.includes("/supplier")
                    ? [
                        {
                          name: "Fuel Management",
                          path: "#",
                        },
                        {
                          path: "/fuel-management-system/configuration/supplier",
                          name: "Configuration",
                        },
                        {
                          name: param.id ? "Edit Supplier" : "Add Supplier",
                          path: "#",
                        },
                      ]
                    : usrl.pathname.includes("/designation")
                    ? [
                        {
                          name: "Safety Management",
                          path: "#",
                        },
                        {
                          path: "/safety-management-system/configuration",
                          name: "Configuration",
                        },
                        {
                          name: param.id
                            ? "Edit Designation"
                            : "Add Designation",
                          path: "#",
                        },
                      ]
                    : usrl.pathname.includes("/other-reason-list")
                    ? [
                        {
                          name: "Contractor Management",
                          path: "#",
                        },
                        {
                          path: "/contractor-management/configuration/other-reason-list",
                          name: "Configuration",
                        },
                        {
                          name: param.id
                            ? "Edit Other Reason"
                            : "Add Other Reason",
                          path: "#",
                        },
                      ]
                    : [
                        {
                          name: "Contractor Management",
                          path: "#",
                        },
                        {
                          path: "/contractor-management/configuration/reason-list",
                          name: "Configuration",
                        },
                        {
                          name: param.id
                            ? "Edit Contractor Reason"
                            : "Add Contractor Reason",
                          path: "#",
                        },
                      ]
                }
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              {param.id ? "Edit" : "Add"}{" "}
              {flag
                ? "Incident/Accident Causes"
                : usrl.pathname.includes("/workshop-master")
                ? "Workshop"
                : usrl.pathname.includes("/supplier")
                ? "Supplier"
                : usrl.pathname.includes("/designation")
                ? "Designation"
                : "Reason"}
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <Form>
                  <Row>
                    {showCode ? (
                      <Col sm={6}>
                        <FormInputText
                          type="number"
                          placeHolder="Enter here"
                          label={"Code"}
                          onChange={onChange}
                          name="code"
                          error={formFieldError?.code}
                          value={form?.code}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col sm={6}>
                      <FormInputText
                        placeHolder="Enter here"
                        type="text"
                        label={
                          usrl.pathname.includes("/workshop-master")
                            ? "Workshop Id"
                            : "Name"
                        }
                        onChange={onChange}
                        error={formFieldError?.name}
                        value={form?.name}
                        name="name"
                        labelClass={"form-label-input"}
                      />
                    </Col>
                  </Row>
                </Form>

                <div className="btnSec">
                  <Button
                    className="secondaryBtn"
                    onClick={() =>
                      flag
                        ? navigate(
                            "/safety-management-system/configuration/violations-leading"
                          )
                        : usrl.pathname.includes("/workshop-master")
                        ? navigate(
                            "/fuel-management-system/configuration/workshop-master"
                          )
                        : usrl.pathname.includes("/supplier")
                        ? navigate(
                            "/fuel-management-system/configuration/supplier"
                          )
                        : usrl.pathname.includes("/designation")
                        ? navigate(
                            "/safety-management-system/configuration/designation"
                          )
                        : usrl.pathname.includes("/other-reason-list")
                        ? navigate(
                            "/contractor-management/configuration/other-reason-list"
                          )
                        : navigate(
                            "/contractor-management/configuration/reason-list"
                          )
                    }
                  >
                    Back
                  </Button>
                  <div className="d-flex">
                    <Button
                      className="primaryBtn"
                      tab={tab}
                      // nextStapeFun={nextStapeFun}
                      tabFun={tabFun}
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            closePopup={closeMessagePopup}
            setShow={setShowMessagePopUp}
            messagePopupType={messagePopupType}
            show={showMessagePopUp}
          />
        </div>
      </div>
    </div>
  );
};

export default SamplePrepareRegisterAdd;
