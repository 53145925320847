import React, { useState, useEffect } from "react";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { Button, Container } from "reactstrap";
import filter from "../../Assets/Images/filter.svg";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import SamplePrepareRegisterView from "./CoalSamplePreparation/SamplePrepareRegisterView";
import {
  coalGradeApis,
  coalGradeStatus,
  coalGrateTypeObj,
  COAL_GRADE_ROLE_BY_DATA,
  filterComponent,
  submitStatus,
  SidebarData,
  tabUrl,
  mobileTabUrl,
  approvalRequired,
} from "./DATA";
import { useForm } from "../../_helpers/hooks";
import { exportCoalGrade } from "../../services/_coalGrade/coalGrade";
import { getPermission } from "../../_services/Auth/helper";
import EmptyDataList from "../../components/EmptyDataList/EmptyDataList";
import { useNavigate, useParams } from "react-router";
import ThirdPartyLabResultView from "./ThirdPartyLabResult/ThirdPartyLabResultView";
import SampleDecodingView from "./SampleDecoding/SampleDecodingView";
import SampleCodingView from "./SampleCoding/SampleCodingView";
import RefereeLabResultView from "./RefereeLabResult/RefereeLabResultView";
import ConsolidatedView from "./ConsolidatedView";
import { useSearchParams, useLocation } from "react-router-dom";
import toastr from "toastr";
import { converEachFirstWordCapitalize } from "../../_helpers/commonHelper";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { IS_MOBILE, findUserPermissionedTabList } from "../../_helpers/helper";

const CoalQualityList = () => {
  const rolePermission = getPermission();
  const params = useParams("type");
  const navigate = useNavigate();
  const [roleByData, setRoleByData] = useState([]);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [isListData, setIsListData] = useState(true);
  const [form, onChange, setForm] = useForm(roleByData?.filterForm);
  const [allData, setAllData] = useState([]);
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const usrl = useLocation();
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl[params.type])
  );
  const [breadCrumb, setBreadCrumb] = useState([
    {
      name: "Coal Grade",
      path: "/",
    },
    {
      name: "Coal Quality",
      path: "/coal-grade/list",
    },
  ]);
  const getAll = async () => {
    let payload = `page=1&limit=10&type=${params?.type}`;
    if (searchParams.get("status"))
      payload += `&status=${searchParams.get("status")}`;

    const response = await coalGradeApis.getAll.api(payload);

    if (Array.isArray(response?.data) && response?.data?.length > 0) {
      setAllData(response?.data);
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    if (!params?.type) return;
    let status = searchParams.get("status");

    if (status && status !== "Drafted" && status !== "Submitted") {
      toastr.error("Unauthorized");
      navigator(`/coal-grade/${params?.type}/actions/list`);
    }

    if (status) {
      setForm((prev) => {
        return { ...prev, status: status };
      });
      setGetAllFilter(`status=${status}`);
      setBreadCrumb((prev) => [
        ...prev,
        {
          name: `${converEachFirstWordCapitalize(params?.type, "-")}`,
          path: `/coal-grade/${params?.type}/actions/list`,
        },
        {
          name: `${
            searchParams.get("status") === `Submitted`
              ? `View History`
              : `My Pending Actions`
          }`,
          path: `#`,
        },
      ]);
    }

    if (params?.type === "consolidated") {
      setBreadCrumb((prev) => [
        ...prev,
        {
          name: `${converEachFirstWordCapitalize(params?.type, "-")}`,
          path: `#`,
        },
      ]);
    }

    setRoleByData(
      COAL_GRADE_ROLE_BY_DATA?.find((obj) =>
        obj?.role?.find(
          (role) => rolePermission[role] && obj?.type == params?.type
        )
      )
    );
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.type, searchParams]);

  useEffect(() => {
    if (!roleByData) navigate("/");
  }, [navigate, roleByData]);

  const onResetFilter = () => {
    setForm({ ...roleByData?.filterForm });
    setForm((prev) => {
      return { status: searchParams.get("status"), ...prev };
    });
    setGetAllFilter(`status=${searchParams.get("status")}`);
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else if (key === "status") filter += `status=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }

    setGetAllFilter(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  useEffect(() => {
    if (approvalRequired.includes(usrl.pathname + usrl.search)) {
      // /coal-grade/${params?.type}/actions/list

      let currentUrl = "";
      setCustomTabs((prev) => {
        return prev?.map((d) => {
          if (d.to === usrl?.pathname + usrl?.search) {
            currentUrl = d.to;
            return {
              ...d,
              active: 1,
            };
          }
          return d;
        });
      });
      // currentUrl ? navigate(currentUrl) : navigate(customTabs[0].to);
    }
  }, []);
  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} hideLeftRightBtn />;
  };

  const viewAsPopup = (form) => {
    switch (params?.type) {
      case coalGrateTypeObj?.samplePrepare:
        return <SamplePrepareRegisterView form={form} />;
      case coalGrateTypeObj?.sampleCoding:
        return <SampleCodingView form={form} allData={allData} />;
      case coalGrateTypeObj?.sampleDecoding:
        return <SampleDecodingView form={form} />;
      case coalGrateTypeObj?.thirdPartyLabResult:
        return <ThirdPartyLabResultView form={form} />;
      case coalGrateTypeObj?.refereeLabResult:
        return <RefereeLabResultView form={form} />;
      case coalGrateTypeObj?.consolidated:
        return <ConsolidatedView form={form} />;
      default:
        return "";
    }
  };

  const onEdit = (id) => {
    navigate(`${roleByData?.pageLink}/${id}`);
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);
    return (
      <div className="filter">
        <Button
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filterBtn"
          onClick={() => setOpen(!open)}
        >
          <img src={filter} alt="fliterIcon" className="img-fluid" />
        </Button>

        {filterComponent(
          params?.type,
          form,
          onChange,
          onResetFilter,
          onSubmitFilter,
          open,
          setOpen
        )}
      </div>
    );
  };

  const statusByOptions = (row) => {
    let statusKey = coalGradeStatus[params?.type];
    switch (params?.type) {
      case coalGrateTypeObj?.samplePrepare:
        return row?.[statusKey] === submitStatus.drafted ? true : false;
      case coalGrateTypeObj?.sampleCoding:
        return row?.[statusKey] === submitStatus.drafted ? true : false;
      case coalGrateTypeObj?.sampleDecoding:
        return row?.[statusKey] === submitStatus.drafted ? true : false;
      case coalGrateTypeObj?.refereeLabResult:
        return row?.[statusKey] === submitStatus.drafted ? true : false;
      case coalGrateTypeObj?.thirdPartyLabResult:
        return row?.[statusKey] === submitStatus.drafted ? true : false;
      default:
        return false;
    }
  };

  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
              
                <DataTableWithServer
                  fields={
                    approvalRequired.includes(usrl.pathname + usrl.search)
                      ? roleByData?.tableColumn?.filter(
                          (field) => field.name !== "System Feedback"
                        )
                      : roleByData?.tableColumn
                  }
                  apis={coalGradeApis}
                  title="Data"
                  viewTitle={roleByData?.tableTittle}
                  customTableTitle={
                    !approvalRequired.includes(usrl.pathname + usrl.search)
                      ? `Coal Grade / Quality`
                      : "Blank"
                  }
                  // customTableTitle={"Blank"}
                  FilterBtn={FilterBtn}
                  viewAsPopup={viewAsPopup}
                  hideSnColumn
                  childrenAfterAddButton={
                    approvalRequired.includes(usrl.pathname + usrl.search) &&
                    tabButtons
                  }
                  pageLink={roleByData?.pageLink}
                  primaryKey="id"
                  pagination
                  hideDeleteButton
                  hideEditButton
                  coalGradeType={params?.type}
                  hideAddButton
                  //   hideAddButton={params?.type === "consolidated" ? true : false}
                  getAllFilter={getAllFilter}
                  downloadExcel={exportCoalGrade}
                  // editFunButton={onEdit}
                  statusByOptions={statusByOptions}
                  breadCrumb={[
                    {
                      name: "Coal Grade",
                      path: "/",
                    },
                    {
                      name: "Coal Quality",
                      path: "/coal-grade/list",
                    },
                    // {
                    //   name: "Sample Prepare",
                    //   path: "/coal-grade/referee-lab-result/actions/list",
                    // },
                    {
                      name: `${converEachFirstWordCapitalize(
                        params?.type,
                        "-"
                      )}`,
                      path:
                        params?.type === "consolidated"
                          ? "/coal-grade/list"
                          : `/coal-grade/${params?.type}/actions/list`,
                    },
                    {
                      name:
                        form?.status === "Drafted" ? "Pending Actions" : "View",
                      path: "/coal-grade/referee-lab-result/actions/list",
                    },
                  ]}
                  isNoTabOnPage
                  updateStatus={params?.type === "consolidated" ? false : true}
                  icons
                  updatePageLink={`${roleByData?.pageLink}`}
                  coalStatus={coalGradeStatus[params?.type]}
                  customHeader={params?.type === "consolidated" ? true : false}
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoalQualityList;
