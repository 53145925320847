import React, { useState } from "react";
import DateTime from "react-datetime";
import moment from "moment";
import InputError from "../../_form/InputError/InputError";
import { Button, Modal } from "react-bootstrap";
import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../_helpers/dateHelper";

const CustomDateTime = ({
  name = null,
  format = "YYYY-MM-DD",
  value = "",
  placeholder = "Date",
  timeFormat = false,
  onChange,
  onChangeDate,
  inputProps,
  disabled = false,
  isValidDate = () => true,
  renderInput = "",
  closeOnSelect = true,
  readOnly = false,
  timeConstraints = false,
  initialViewDate,
  className,
  error,
  customClass='',
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="mb-3">
      <div>
        <input
          onClick={() => setShow(!disabled && true)}
          disabled={disabled ? disabled : false}
          value={
            value
              ? timeFormat
                ? DDMMYYYYHHMMAFormat(value)
                : DDMMYYYYFormat(value)
              : ""
          }
          type="text"
          className={"form-control fieldCalenderIco"}
          placeholder={placeholder}
          data-testid="dateValue"
        />
        <InputError error={error} />
      </div>

      <Modal
        className={`singleSelectModal customDatepickerModal ${customClass}`}
        show={show}
        onHide={handleClose}
      >
        {/* <Modal.Header closeButton>
          <h5>{"Datepicker"}</h5>
        </Modal.Header> */}
        <SelectModal
          timeFormat={timeFormat}
          dateFormat={
            ["DD-MM-YYYY", "YYYY-MM-DD"].includes(format)
              ? "DD-MM-YYYY"
              : format
          }
          name={name}
          value={
            ["DD-MM-YYYY", "YYYY-MM-DD"].includes(format) && value
              ? moment(value)
              : value
          }
          onChange={onChange}
          onChangeDate={onChangeDate}
          inputProps={
            inputProps || {
              placeholder,
              disabled,
              readOnly,
            }
          }
          setShow={setShow}
          isValidDate={isValidDate}
          renderInput={renderInput}
          closeOnSelect={closeOnSelect}
          timeConstraints={timeConstraints}
          className={className}
        />
      </Modal>
    </div>
  );
};

const SelectModal = ({
  timeFormat,
  dateFormat,
  name,
  value,
  inputProps,
  renderInput,
  closeOnSelect,
  timeConstraints,
  setShow,
  onChangeDate,
  onChange,
  isValidDate,
  className,
}) => {
  const [selectValue, setSelectValue] = useState(() => value);

  const saveFun = () => {
    if (selectValue) {
      if (onChange)
        onChange({
          target: {
            name: name,
            value:
              selectValue instanceof moment
                ? selectValue.format(
                    !timeFormat ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
                  )
                : "",
          },
        });

      if (onChangeDate)
        onChangeDate({
          target: {
            name,
            value:
              selectValue instanceof moment
                ? selectValue.format(
                    !timeFormat ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
                  )
                : "",
          },
        });

      // if (onChange) onChange(selectValue, name);
      // if (onChangeDate)
      //   onChangeDate({
      //     target: {
      //       name,
      //       value: selectValue instanceof moment ? selectValue?.format("YYYY-MM-DD") : "",
      //     },
      //   });
    }
    setShow(false);
  };

  const cancelFun = () => {
    setShow(false);
  };

  const handleOnChange = (ob) => {
    setSelectValue(ob);
  };
  return (
    <>
      <Modal.Body className="p-0">
        <div>
          <DateTime
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            name={name}
            value={selectValue}
            //   onChange={(moment) => onChange?.(moment, name)}
            onChange={handleOnChange}
            inputProps={inputProps}
            isValidDate={isValidDate}
            // renderInput={renderInput}
            renderInput={(props) => {
              return <input {...props} value={value ? props.value : ""} />;
            }}
            closeOnSelect={closeOnSelect}
            timeConstraints={timeConstraints}
            className={className || ""}
            // closeOnTab={true}
            popperProps={{ strategy: "fixed" }}
            open={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="backBtn" onClick={cancelFun}>
          Cancel
        </Button>
        <Button className="primaryBtn" onClick={saveFun}>
          Select
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CustomDateTime;
