import React from "react";
import ReactEcharts from "echarts-for-react";
import NoRecord from "../../Common/NoRecord";

class BasicBarChartWithLabel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      xAxis,
      yAxis,
      series,
      tooltip,
      customeTool,
      xAxisName,
      categoryData,
      yAxisName,
      seriesData,
      seriesName,
      toolbox,
      legend,
    } = this.props;

    const echartBasicBarOption = {
      grid: {
        top: "30",
        right: "0",
        bottom: "25",
        left: "05",
        containLabel: true,
      },
      // color: ['#5f6bc2'],
      tooltip: customeTool
        ? {
            formatter: function (params, ticket, callback) {
              // custumToolTipData[seriesName][0];
              let string =
                `<span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:` +
                params.color +
                `;\"></span>${tooltip[params.seriesName][0].mineFullName} : ${
                  params.value
                }<br>`;
              return "<span > " + string + "</span>";
            },
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }
        : {
            show: true,
            backgroundColor: "#fff",
          },
      toolbox: toolbox ? toolbox : {},
      // legend: categoryData ? categoryData : {},
      legend: {
        type: "scroll",
        // z: 3,
        data: categoryData,
        // left: 90,
        right: "auto",
        bottom: 0,
        padding: [
          25, // up
          25, // right
          0, // down
          25, // left
        ],
      },
      xAxis: xAxis
        ? xAxis
        : {
            name: xAxisName ? xAxisName : "",
            // nameLocation: 'middle',
            bottom: "10px",
            nameTextStyle: {
              fontWeight: "bold",
              // verticalAlign: "bottom",
              padding: [15, 0, 0, 0],
            },
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              rotate: 30,
            },
            type: "category",
            data: categoryData ? categoryData : [],
          },
      yAxis: yAxis
        ? yAxis
        : {
            name: yAxisName ? yAxisName : "",
            nameLocation: "end",
            type: "value",
            min: 0, // minimum value to start
            minInterval: 1, // for integer axis value
            nameTextStyle: {
              fontWeight: "bold",
            },
          },
      series: seriesData
        ? seriesData
        : [
            {
              name: seriesName ? seriesName : "",
              data: seriesData ? seriesData : [],
              type: "bar",
            },
          ],
    };

    return (
      <React.Fragment>
        {(Array.isArray(seriesData) && seriesData.length > 0) ||
        (Array.isArray(series) && series.length > 0) ? (
          <ReactEcharts
            notMerge={true}
            replaceMerge={["series", "legend"]}
            style={{ height: "250px", margin: "0 auto" }}
            option={echartBasicBarOption}
          />
        ) : (
          <NoRecord></NoRecord>
        )}
      </React.Fragment>
    );
  }
}

export default BasicBarChartWithLabel;
