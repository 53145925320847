import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { coalGradeLabel } from "../DATA";

const SampleCodingFilterForm = ({
  onSubmitFilter,
  form,
  onChange,
  onResetFilter,
  isOpen,
  setOpen,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>{coalGradeLabel?.date_of_sample_receipt_in_lab}</Form.Label>
        <Row>
          <Col sm={6}>
            <CustomDatePicker
              onChangeDate={onChange}
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
            />
          </Col>
          <Col sm={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDate}
              name="endDate"
              dateFormat="YYYY-MM-DD"
              onChangeDate={onChange}
            />
          </Col>
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              onClick={onResetFilter}
              className="secondaryBtn"
              type="button"
              data-testid="reset-filter-button"
            >
              Clear all
            </Button>
          </Col>
          <Col className="justify-content-end d-flex">
            <Button
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0"
              type="button"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default SampleCodingFilterForm;
