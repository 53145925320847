import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  fuelFlowList,
  mobileFuelFlowList,
  mobileFuelSidebarData,
  SidebarData,
} from "./DATA";
import SafetySideBar from "../../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import "./FuelMgmt.scss";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { IS_MOBILE } from "../../../_helpers/helper";
import { filterUrlFunc } from "../../../_services/CommonService/CommonService";
const FuelManagement = () => {
  let authUser = localStorage.getItem("auth_user");
  let user = authUser ? JSON.parse(authUser) : "";
  return (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={IS_MOBILE() ? mobileFuelSidebarData : SidebarData}
        moduleTitle={"Fuel Management"}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            {!IS_MOBILE() && (
              <h3
                className=""
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Fuel Management",
                      path: "/",
                    },
                    {
                      name: "Fuel Management",
                      path: "#",
                    },
                  ]}
                  // module={ACTIVE_MODULE.incident}
                />
              </h3>
            )}
            <div className="fuelMgmtWrapper">
              <Row className="dashboardItem">
                {(IS_MOBILE()
                  ? filterUrlFunc(mobileFuelFlowList)
                  : filterUrlFunc(fuelFlowList)
                )?.map((d, idx) => {
                  return (
                    <Col lg={4} key={idx} className="mb-4">
                      {d?.label === "My Fuel Dispense" ? (
                        <Link to={d?.link + user.id}>
                          <div className="dashboardItemContent">
                            <div className="imgArea">
                              <img
                                src={d.img}
                                className="img-fluid"
                                alt="dashboardItemIcons"
                              />
                            </div>
                            <label>{d.label}</label>
                          </div>
                        </Link>
                      ) : (
                        <Link to={d?.link}>
                          <div className="dashboardItemContent">
                            <div className="imgArea">
                              <img
                                src={d.img}
                                className="img-fluid"
                                alt="dashboardItemIcons"
                              />
                            </div>
                            <label>{d.label}</label>
                          </div>
                        </Link>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default FuelManagement;
