import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.jpg";
import { IoNotificationsOutline } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";
import { auth_user, capitalizeName } from "../../_helpers/helper";
import { logout } from "../../services/logoutService";

const MobHeader = () => {
  const navigate = useNavigate();

  let data = auth_user();
  const firstChar = data?.email?.charAt(0)?.toUpperCase();

  // const signOut = (e) => {
  //   const res = logout();
  //   res && navigate("/mobile/auth/login");
  // };
  return (
    <div className="mobileHeader align-items-center justify-content-between">
      <Link to="/mobile">
        <img src={logo} className="mainLogo" alt="logo" height={40} />
      </Link>

      <div className="d-flex align-items-center">
        <Dropdown className="notificationIcon">
          <Dropdown.Toggle id="dropdown-basic">
            <IoNotificationsOutline size={28} className="" />
          </Dropdown.Toggle>
        </Dropdown>

        {/* <Dropdown className="userLogo">
          <Dropdown.Toggle id="dropdown-basic">
            <h4 className="userName mb-0">{firstChar}</h4>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Header>
              {data
                ? capitalizeName(`${data.firstName} ${data?.lastName ?? ""}`)
                : ""}
            </Dropdown.Header>
            <Dropdown.Item
              data-testid="logOut"
              href="#"
              onClick={() => signOut()}
            >
              Log out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
    </div>
  );
};

export default MobHeader;
