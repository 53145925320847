import selectionIcon1 from "../../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../../Assets/Images/selectionIcon3.svg";
import selectionIcon4 from "../../Assets/Images/selectionIcon4.svg";
import selectionIcon5 from "../../Assets/Images/selectionIcon5.svg";
import selectionIcon6 from "../../Assets/Images/selectionIcon6.svg";
import selectionIcon8 from "../../Assets/Images/selectionIcon8.svg";
import selectionIcon9 from "../../Assets/Images/SelectionIcon9.png";
import { getPermission, permissionCheck } from "../../_services/Auth/helper";
import { defaultPage } from "../CoalGrade/DATA";

export const permissionWisePage = () => {
  const rolePermission = getPermission();
  let check = Object.keys(rolePermission).find(
    (data) =>
      Object.keys(defaultPage)?.includes(data) && rolePermission[data] === true
  );
  console.log(check);
  return defaultPage[check] || "";
};

export const contractorManagementUrl = () => {
  const isPermission = permissionCheck([
    "section_incharge",
    "contractor_module_admin",
  ]);
  const useLink = `${
    isPermission ? "contractor-performances" : "contractor-hindrance"
  }`;
  return useLink;
};

export const selectionFlowList = [
  // {
  //   img: selectionIcon9,
  //   label: "Dashboard",
  //   link: "/dashboard/contractor-performance-dashboard",
  //   role: ["contractor_dashboard"],
  // },
  {
    img: selectionIcon1,
    label: "Contractor Management",
    cardName: "contractorManagement",
    link: "/contractor-management/contractor-dashboard/performance",
    role: [
      "contractor",
      "representative",
      "section_incharge",
      "colliery_manager",
      "contractor_module_admin",
      "super_admin",
      "contractor_performance_dashboard",
      "performance_production_dashboard",
      "contractor_performance_score_card_dashboard",
      "contractor_hindrance_dashboard",
      "quick_sight_dashboard",
    ],
  },
  // {
  //   img: selectionIcon8,
  //   label: "Contractor Config",
  //   link: "/reason-list",
  //   role: ["contractor_module_admin"],
  // },
  // {
  //   img: selectionIcon9,
  //   label: "Dashboard",
  //   link: "/dashboard/fuel-management-dashboard",
  //   role: ["fuel_management_dashbaord"],
  // },
  {
    img: selectionIcon2,
    label: "Fuel Management System​",
    link: "/fuel-management-system/dashboard/1",
    cardName: "fuelManagement",
    role: [
      "fuel_admin",
      "dispenser",
      "fuel_management_dashbaord",
      "super_admin",
      "quick_sight_dashboard",
    ],
  },
  // {
  //   img: selectionIcon9,
  //   label: "Dashboard",
  //   link: "/dashboard/safety-management-dashboard",
  //   role: ["safety_management_dashbaord"],
  // },
  {
    img: selectionIcon3,
    label: "Safety Management System: Workflow Management",
    link: "/safety-management-system/dashboard/1",
    cardName: "safetyManagement",
    role: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  // {
  //   img: selectionIcon9,
  //   label: "Dashboard",
  //   link: "/dashboard/coal-grade-dashboard",
  //   role: ["coal_grade_dashboard"],
  // },
  {
    img: selectionIcon4,
    label: "Coal Grade/ Quality Monitoring",
    link: "/coal-grade/dashboard/1",
    cardName: "coalManagement",
    role: [
      "chemist",
      "lab_quality_manager",
      "area_quality_manager",
      "coal_grade_admin",
      "super_admin",
      "coal_grade_dashboard",
      "quick_sight_dashboard",
    ],
  },
  // {
  //   img: selectionIcon9,
  //   label: "Dashboard",
  //   link: "/dashboard/environment-management-dashboard",
  //   role: ["environment_management_dashboard"],
  // },
  {
    img: selectionIcon5,
    label: "Environment Management System",
    link: "/environment-management/dashboard/Ambient-Air-Quality-CAAQMS",
    cardName: "envManagement",
    role: [
      "env_admin",
      "env_user",
      "environment_management_dashboard",
      "super_admin",
    ],
  },
  // {
  //   img: selectionIcon9,
  //   label: "Dashboard",
  //   link: "/dashboard/HEMM-Cockpit-dashboard",
  //   role: ["hemm_cockpit_dashboard"],
  // },
  {
    img: selectionIcon6,
    label: "HEMM Cockpit : Predictive Analytics Models",
    // link: '/coal-grade',
    role: ["hemm_cockpit_admin"],
  },
  {
    img: selectionIcon8,
    label: "Super Admin",
    link: "/user-management/user-list",
    cardName: "userManagement",
    role: ["super_admin"],
  },
  // {
  //   img: selectionIcon8,
  //   label: "Env Config",
  //   link: "/config/environment/air-quality-emr",
  //   role: ["env_admin", "super_admin"],
  // },
];

export const mobileSelectionFlowList = [
  // {
  //   img: selectionIcon1,
  //   label: "Contractor Management",
  //   cardName: "contractorManagement",
  //   link: "/mobile/contractor-management/contractor-dashboard",
  //   role: [
  //     "contractor",
  //     "representative",
  //     "section_incharge",
  //     "colliery_manager",
  //     "contractor_module_admin",
  //     "super_admin",
  //     "contractor_dashboard",
  //   ],
  // },
  // {
  //   img: selectionIcon8,
  //   label: "Contractor Config",
  //   link: "/mobile/reason-list",
  //   role: ["contractor_module_admin"],
  // },
  {
    img: selectionIcon2,
    label: "Fuel Management System​",
    link: "/mobile/fuel-management-system",
    cardName: "fuelManagement",
    isMobileActiveLink: true,
    role: [
      "fuel_admin",
      "dispenser",
      "fuel_management_dashbaord",
      "super_admin",
    ],
  },
  {
    img: selectionIcon3,
    label: "Safety Management System: Workflow Management",
    link: "/mobile/safety-management-system/incident-management",
    cardName: "safetyManagement",
    isMobileActiveLink: true,
    role: [
      "safety_admin",
      "safety_user",
      "safety_management_dashbaord",
      "super_admin",
    ],
  },
  // {
  //   img: selectionIcon4,
  //   label: "Coal Grade/ Quality Monitoring",
  //   link: "/mobile/coal-grade/dashboard",
  //   cardName: "coalManagement",
  //   role: [
  //     "chemist",
  //     "lab_quality_manager",
  //     "area_quality_manager",
  //     "coal_grade_admin",
  //   ],
  // },
  // {
  //   img: selectionIcon5,
  //   label: "Environment Management System",
  //   link: "/mobile/environment-management/dashboard",
  //   cardName: "envManagement",
  //   role: [
  //     "env_admin",
  //     "env_user",
  //     "environment_management_dashboard",
  //     "super_admin",
  //   ],
  // },
  // {
  //   img: selectionIcon6,
  //   label: "HEMM Cockpit : Predictive Analytics Models",
  //   // link: '/coal-grade',
  //   role: ["hemm_cockpit_admin"],
  // },
  // {
  //   img: selectionIcon8,
  //   label: "User Management",
  //   link: "/mobile/user-management/user-list",
  //   cardName: "userManagement",
  //   role: ["super_admin"],
  // },
];
