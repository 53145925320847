import React from "react";
import FormInputText from "../../FormInputText/FormInputText";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";

const CreateWebform = ({
  name,
  form,
  formFields,
  formFieldType,
  onChange,
  label,
  error,
  addWaterTankerRow = () => {},
  removeWaterTankerRow = () => {},
  addGraderRow = () => {},
  removeGraderRow = () => {},
  addPayLoaderRow = () => {},
  removePayLoaderRow = () => {},
  disableAvailable,
  showNitRequirement = false,
  showColumnName = false,
  contractorData = "",
  updateDisable = false,
  nitDataFetched,
}) => {


  return (
    <>
      <h4
        className="font18 d-flex justify-content-center"
        style={{ marginLeft: label === "Available" ? "36%" : "" }}
      >
        Equipment {label}{" "}
      </h4>

      <div className="webformCardContent">
        <table cellPadding={10}>
          <thead>
            <tr>
              <th></th>
              {showNitRequirement && <th>NIT Specs</th>}

              {showColumnName && (
                <>
                  <th></th>
                  <th></th>
                </>
              )}
              <th>1st Shift</th>
              <th>2nd Shift</th>
              <th>Night Shift</th>
            </tr>
          </thead>
          <tbody>
            {formFields?.map((d, i) => {
              return d?.deleted === 1 ||
                (!d.committed_count && nitDataFetched) ? null : (
                <tr key={i}>
                  <th>
                    <label>{showColumnName && d?.equipment_name}</label>
                  </th>
                  {showNitRequirement && (
                    <td>
                      <FormInputText
                        name={"committed_count"}
                        onChange={(e) => onChange(e, i)}
                        value={d?.committed_count}
                        type="number"
                        error={error?.[i]?.committed_count}
                        minLength={0}
                        disabled={
                          updateDisable ? updateDisable : disableAvailable
                        }
                      />
                    </td>
                  )}
                  {showColumnName && (
                    <>
                      <th></th>
                      <th></th>
                    </>
                  )}

                  <td>
                    <FormInputText
                      name={"shift1_available"}
                      onChange={(e) => onChange(e, i)}
                      value={d?.shift1_available}
                      type="number"
                      error={error?.[i]?.shift1_available}
                      minLength={0}
                    />
                  </td>
                  <td>
                    <FormInputText
                      name={"shift2_available"}
                      onChange={(e) => onChange(e, i)}
                      value={d?.shift2_available}
                      type="number"
                      error={error?.[i]?.shift2_available}
                      minLength={0}
                    />
                  </td>
                  <td>
                    <FormInputText
                      name={"shift3_available"}
                      onChange={(e) => onChange(e, i)}
                      value={d?.shift3_available}
                      type="number"
                      error={error?.[i]?.shift3_available}
                      minLength={0}
                    />
                  </td>
                  {/* {isWaterTanker &&
                    d?.equipment_name === "Water Tanker" &&
                    formFieldType === "utilized" && (
                      <td className="text-center">
                        <div className="actionIconsGroup">
                          <span
                            data-testid="removeWaterId"
                            onClick={removeWaterTankerRow}
                            className="icons font-21 text-danger"
                          >
                            <AiOutlineMinusCircle
                              size="24"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </div>
                      </td>
                    )}
                  {isGrader &&
                    d?.equipment_name === "Grader" &&
                    formFieldType === "utilized" && (
                      <td className="text-center">
                        <div className="actionIconsGroup">
                          <span
                            onClick={removeGraderRow}
                            className="icons font-21 text-danger"
                            data-testid={"removeGraderId"}
                          >
                            <AiOutlineMinusCircle
                              size="24"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </div>
                      </td>
                    )}
                  {payLoader &&
                    d?.equipment_name === "Pay Loader" &&
                    formFieldType === "utilized" && (
                      <td className="text-center">
                        <div className="actionIconsGroup">
                          <span
                            onClick={removePayLoaderRow}
                            className="icons font-21 text-danger"
                            data-testid="removePayLoaderRowId"
                          >
                            <AiOutlineMinusCircle
                              size="24"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </div>
                      </td>
                    )} */}
                </tr>
              );
            })}
            {/* {formFieldType === "available" && (
              <tr>
                <td colSpan="12">
                  {!isWaterTanker && contractorData.waterTanker !== null && (
                    <span
                      className="addTxt me-3"
                      onClick={addWaterTankerRow}
                      data-testid="waterTankerId"
                    >
                      <AiOutlinePlus />
                      Add Water Tanker
                    </span>
                  )}
                  {!isGrader && contractorData.grader !== null && (
                    <span
                      className="addTxt me-3"
                      onClick={addGraderRow}
                      data-testid="graderId"
                    >
                      <AiOutlinePlus />
                      Add Grader
                    </span>
                  )}
                  {!payLoader && contractorData.payLoader !== null && (
                    <span
                      className="addTxt"
                      data-testid="addPayLoaderRowId"
                      onClick={addPayLoaderRow}
                    >
                      <AiOutlinePlus />
                      Add Pay Loader
                    </span>
                  )}
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CreateWebform;
