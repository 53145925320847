import React, { useEffect, useState } from "react";
import { Col, Form, Row, Container, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import FormInputText from "../../../components/FormInputText/FormInputText";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import Select from "../../../components/Select/Select";
import { sampleCodingRegister } from "../../../services/_coalGrade/coalGrade";
import { employeeNameEmailLabel } from "../../../_helpers/helper";
import { useForm } from "../../../_helpers/hooks";
import {
  SidebarData,
  coalGradeApis,
  coalGradeLabel,
  coalGrateTypeObj,
  submitStatus,
} from "../DATA";
import {
  sampleCodingDraftRules,
  sampleCodingFields,
  sampleCodingRegisterInitialForm,
} from "./DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import LabelCustom from "../../../components/LabelCustom/LabelCustom";
import { StepButton } from "../../../components/_button/StepButton";
import toastr from "toastr";
import { UserService } from "../../../_services/UserService/UserService";
import { Validation } from "../../../_helpers/validation/Validation";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { dataValueList } from "../../SafetyManagementSystem/IncidentManagement/DATA";

const SampleCodingRegister = () => {
  const [show, setShow] = useState(false);
  const [sampleIdOption, setSampleIdOption] = useState([]);
  const [form, onChange, setForm] = useForm({
    ...sampleCodingRegisterInitialForm,
  });
  const [error, setError] = useState({});
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [filterObj, setFilterObj] = useState(
    `type=${coalGrateTypeObj?.sampleCoding}&search[sample_prepare_status]=${submitStatus?.submitted}`
  );
  const [allUsers, setAllUsers] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  let navigate = useNavigate();
  const params = useParams("id");

  const getAll = async (filter = "") => {
    filter += `&${filterObj}`;

    const response = await coalGradeApis.getAll.api(filter);
    return response?.data;
  };

  const getAllUsers = async (id) => {
    let filter = `site_id=${id}`;
    const data = employeeNameEmailLabel();
    const response = await UserService.getUsers(filter);
    if (response?.status && response?.data) {
      const updatedUsers = response.data.map((userData) => {
        return { label: userData?.firstName, value: userData?.id };
      });
      updatedUsers.unshift({ label: data?.nameEmail, value: data?.id });
      setAllUsers(updatedUsers);
    }
    if (!params?.id)
      setForm((prev) => {
        return { ...prev, sample_coding_done_by: data?.id };
      });
  };

  useEffect(() => {
    (async () => {
      if (!form?.date_of_sample_sent_to_lab) return;
      let data = await getAll(
        `search[date_of_sample_sent_to_lab]=${form?.date_of_sample_sent_to_lab}&status_filter=true`
      );

      setSampleIdOption(
        data
          .filter(
            (data) => data?.sample_coding_status != submitStatus?.submitted
          )
          .map((row) => {
            return { label: row.sample_id, value: row.id };
          })
      );
      setSampleData(data);
    })();
  }, [form?.date_of_sample_sent_to_lab]);

  const handleSubmit = async (submitType) => {
    let data = {};
    let validationFields = {};

    if (submitType == submitStatus?.drafted) {
      validationFields = sampleCodingDraftRules;
      data = { ...form, sample_coding_status: submitStatus?.drafted };
    } else {
      data = { ...form, sample_coding_status: submitStatus?.submitted };
      validationFields = sampleCodingFields;
    }

    let errorObj = Validation(form, validationFields);
    setError(errorObj);

    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    const response = await sampleCodingRegister(data);

    if (!response?.status) return toastr.error(response?.message);

    if (submitType == submitStatus?.drafted) {
      toastr.success(`Saved Successfully`);
    } else {
      setShow(true);
      setMessagePopupType(false);
    }
  };

  useEffect(() => {
    if (!form?.sample_id) return;

    setForm((prev) => {
      return { ...prev, id: form?.sample_id };
    });
  }, [form?.sample_id]);

  useEffect(() => {
    (async () => {
      if (!params?.id) return;

      let data = await getAll(`search[id]=${params?.id}&status_filter=true`);

      if (data[0]?.sample_coding_status == submitStatus?.submitted) {
        toastr.error("Unauthorized");
        navigate("/coal-grade/sample-coding/actions/list");
      }
      data.map((row) => {
        setForm((prev) => {
          return { ...prev, ...row, sample_id: row.id };
        });
      });
    })();
  }, [params?.id]);

  function handleClick() {
    setShow(false);
    navigate("/coal-grade/sample-coding/actions/list");
  }

  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };
  useEffect(() => {
    if (form?.sample_id) {
      let selectedSample = [];
      for (const element of sampleData) {
        if (element.id === form?.sample_id) {
          selectedSample = element;
          break;
        }
      }
      getAllUsers(selectedSample?.site_id);
    }
  }, [form?.sample_id, sampleData]);

  useEffect(() => {
    if (params.id) return;
    let UserData = employeeNameEmailLabel();
    setForm((prev) => {
      return { ...prev, sample_sender_name: UserData?.nameEmail };
    });
  }, []);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <Container fluid>
              <h3
                className="title"
                style={{ paddingBottom: "1px", paddingTop: "5px" }}
              >
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Coal Grade",
                      path: "/",
                    },
                    {
                      name: "Coal Quality",
                      path: "/coal-grade/list",
                    },
                    {
                      name: "Sample Coding",
                      path: "/coal-grade/sample-coding/actions/list",
                    },
                    {
                      // name: `${converEachFirstWordCapitalize(type, "-")}`,
                      name: "Sample Coding Register",
                      path: "#",
                    },
                  ]}
                />
              </h3>
              <h3 className="title">Sample Coding Register</h3>
              <div className="creationCard">
                <div className="formWrapper">
                  <div className="maverickFormCard">
                    <div className="creationWrapper">
                      <Form>
                        <Row>
                          <Col md={6}>
                            <LabelCustom>
                              {" "}
                              {coalGradeLabel?.date_of_sample_sent_to_lab}{" "}
                            </LabelCustom>
                            <CustomDatePicker
                              value={form?.date_of_sample_sent_to_lab}
                              name="date_of_sample_sent_to_lab"
                              onChange={onChange}
                              error={error?.date_of_sample_sent_to_lab}
                            />
                            {/* <InputError error={error?.date_of_sample_sent_to_lab} /> */}
                          </Col>
                          <Col md={6}>
                            <FormInputText
                              type="input"
                              placeHolder="Enter here"
                              label={coalGradeLabel?.code_given_to_sample}
                              onChange={onChange}
                              name="code_given_to_sample"
                              value={form?.code_given_to_sample}
                              error={error?.code_given_to_sample}
                            />
                          </Col>
                          <Col md={6}>
                            <Select
                              onChange={onChange}
                              value={form?.sample_id}
                              name="sample_id"
                              options={sampleIdOption}
                              error={error?.sample_id}
                              label={coalGradeLabel?.sample_id}
                            />
                          </Col>
                          <Col md={6}>
                            <Select
                              onChange={onChange}
                              value={form?.sample_coding_done_by}
                              name="sample_coding_done_by"
                              options={allUsers}
                              error={error?.sample_coding_done_by}
                              label={coalGradeLabel?.sample_coding_done_by}
                            />
                          </Col>
                          <Col md={6}>
                            <LabelCustom>
                              {" "}
                              {
                                coalGradeLabel?.date_of_sample_receipt_in_lab
                              }{" "}
                            </LabelCustom>
                            <CustomDatePicker
                              value={form?.date_of_sample_receipt_in_lab}
                              name="date_of_sample_receipt_in_lab"
                              onChange={onChange}
                              error={error?.date_of_sample_receipt_in_lab}
                            />
                            {/* <InputError
                        error={error?.date_of_sample_receipt_in_lab}
                      /> */}
                          </Col>
                          <Col md={6}>
                            <LabelCustom>
                              {" "}
                              {coalGradeLabel?.date_given_for_test}{" "}
                            </LabelCustom>
                            <CustomDatePicker
                              value={form?.date_given_for_test}
                              name="date_given_for_test"
                              onChange={onChange}
                              error={error?.date_given_for_test}
                            />
                            {/* <InputError error={error?.date_given_for_test} /> */}
                          </Col>
                          <Col md={6}>
                            <FormInputText
                              type="input"
                              placeHolder="Enter here"
                              label={coalGradeLabel?.sample_sender_name}
                              onChange={onChange}
                              name="sample_sender_name"
                              value={form?.sample_sender_name}
                              error={error?.sample_sender_name}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="btnSec">
                  <Button onClick={closeFun} className="secondaryBtn">
                    Close
                  </Button>
                  <StepButton
                    tab={3}
                    tabFun={""}
                    onSubmit={handleSubmit}
                    nextStapeFun={""}
                  />
                </div>
              </div>
            </Container>

            <MessagePopup
              messagePopupType={messagePopupType}
              show={show}
              setShow={setShow}
              closePopup={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleCodingRegister;
