import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import {
  SidebarData,
  allGrades,
  coalGradeApis,
  coalGrateTypeObj,
  submitStatus,
} from "../DATA";
import { useForm } from "../../../_helpers/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { thirdPartyResult } from "../../../services/_coalGrade/coalGrade";
import {
  ThirdPartyRules,
  ThirdPartyInitialForm,
  CoalDispatchRelatedRules,
} from "./DATA";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import { Validation } from "../../../_helpers/validation/Validation";
import { StepButton } from "../../../components/_button/StepButton";
import { ThirdPartyLabResultForm } from "./ThirdPartyLabResultForm";
import { CoalDispatchRelatedForm } from "./CoalDispatchRelatedForm";
import toastr from "toastr";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import { getGValue } from "../../../_helpers/helper";
const ThirdPartyLabResult = () => {
  const [tab, setTab] = useState(1);
  const [coalGradeAllData, setCoalGradeAllData] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState({});
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [form, onChange, setForm] = useForm({ ...ThirdPartyInitialForm });
  const [rrDDNoOList, setRrDDNOList] = useState([]);
  const [filterObj, setFilterObj] = useState(
    `type=${coalGrateTypeObj?.thirdPartyLabResult}&search[sample_decoding_status]=${submitStatus?.submitted}`
  );
  const [gradeList, setGradeList] = useState(
    Object.keys(allGrades).map((key) => {
      return { value: key, label: key };
    })
  );
  const [declaredGradeList, setDeclaredGradeList] = useState([]);
  const params = useParams("id");
  let navigate = useNavigate();

  const tabFun = (n) => {
    setTab(n);
  };

  const getAll = async (filter = "") => {
    filter += `&${filterObj}`;
    const response = await coalGradeApis.getAll.api(filter);

    if (response?.data && response?.status) {
      setCoalGradeAllData(response?.data);
    }

    return response?.data;
  };

  const handleSubmit = async (submitType) => {
    let errorObj = {};
    let data = {};

    if (submitType == submitStatus?.drafted) {
      data = { ...form, third_party_status: submitStatus?.drafted };
    } else {
      data = { ...form, third_party_status: submitStatus?.submitted };

      errorObj = Validation(form, ThirdPartyRules);
      setError(errorObj);
    }

    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    const response = await thirdPartyResult(data);

    if (!response?.status) return toastr.error(response?.message);

    if (submitType == submitStatus?.drafted) {
      toastr.success(`Saved Successfully`);
    } else {
      setMessagePopupType(false);
      setShow(true);
    }
  };

  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };

  function handleClick() {
    setShow(false);
    navigate("/coal-grade/third-party-lab-result/actions/list");
  }

  const nextStapeFun = () => {
    const errorObj = Validation(form, CoalDispatchRelatedRules);

    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) return;
    setTab(2);
  };

  const assignRrOrDoNoList = (coalGradeData, value) => {
    setRrDDNOList(
      coalGradeData
        .filter((data) => data.third_party_status != submitStatus?.submitted)
        .map((row) => {
          return { label: row.rr_or_do_number, value: row.rr_or_do_number };
        })
    );
  };

  const ModeOfDispatchOnChangeFn = async (e) => {
    let data = await getAll(
      `search[mode_of_dispatch]=${e?.target?.value}&status_filter=true`
    );
    assignRrOrDoNoList(data);

    setForm((prev) => {
      return {
        ...prev,
        id: "",
        rr_or_do_number: "",
        rr_or_do_date: "",
        declared_grade: "",
        name_of_sampling_agency: "",
      };
    });
  };

  const RrDdNoOnChangeFn = async (e) => {
    if (!form?.mode_of_dispatch) return;

    let data = await getAll(
      `search[mode_of_dispatch]=${form?.mode_of_dispatch}&search[rr_or_do_number]=${e?.target?.value}&status_filter=true`
    );

    assignDeclaredGrade(data);

    setForm((prev) => {
      return {
        ...prev,
        id: "",
        // rr_or_do_date: "",
        declared_grade: "",
        name_of_sampling_agency: "",
      };
    });
  };

  const assignDeclaredGrade = (coalGradeData) => {
    setDeclaredGradeList(
      coalGradeData
        .filter((data) => data.third_party_status != submitStatus?.submitted)
        .map((row) => {
          return { label: row.declared_grade, value: row.id };
        })
    );
  };

  const RrDdDateOnChangeFn = async (e) => {
    if (!form?.mode_of_dispatch || !form?.rr_or_do_number) return;

    let coalGradeData = await getAll(
      `search[mode_of_dispatch]=${form?.mode_of_dispatch}&search[rr_or_do_number]=${form?.rr_or_do_number}&search[date_of_collection]=${e?.target?.value}&status_filter=true`
    );

    setDeclaredGradeList(
      coalGradeData
        .filter((data) => data.third_party_status != submitStatus?.submitted)
        .map((row) => {
          return { label: row.declared_grade, value: row.id };
        })
    );

    setForm((prev) => {
      return {
        ...prev,
        id: "",
        declared_grade: "",
        name_of_sampling_agency: "",
      };
    });
  };

  const assignNameOfSamplingAgency = (value) => {
    let nameOfSamplingAgency = coalGradeAllData.find(
      (data) => data.id == value
    );
    setForm((prev) => {
      return {
        ...prev,
        id: value,
        name_of_sampling_agency: nameOfSamplingAgency?.sampling_agency?.name,
      };
    });
  };

  useEffect(() => {
    (async () => {
      if (!params?.id) return;

      let data = await getAll(`search[id]=${params?.id}&status_filter=true`);

      if (data[0]?.third_party_status == submitStatus?.submitted) {
        toastr.error("Unauthorized");
        navigate("/coal-grade/third-party-lab-result/actions/list");
      }

      data.map((row) => {
        setForm((prev) => {
          return { ...prev, ...row, declared_grade: row.id };
        });
      });
      assignRrOrDoNoList(data);
      assignDeclaredGrade(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    if (!params.id)
      setForm((prev) => {
        return {
          ...prev,
          third_party_analyzed_grade: String(
            getGValue(form?.third_party_on_em_bases_gcv)
          ),
        };
      });
  }, [form?.third_party_on_em_bases_gcv]);

  useEffect(() => {
    if (declaredGradeList.length && !params.id) {
      setForm((prev) => {
        return { ...prev, declared_grade: declaredGradeList[0]?.value };
      });
      assignNameOfSamplingAgency(declaredGradeList[0]?.value);
    }
  }, [form.rr_or_do_number, declaredGradeList]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            {/* <Container fluid> */}
            <h3
              className=""
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Coal Grade",
                    path: "/",
                  },
                  {
                    name: "Coal Quality",
                    path: "/coal-grade/list",
                  },
                  {
                    name: "Third Party Lab Result",
                    path: "/coal-grade/third-party-lab-result/actions/list",
                  },
                  {
                    name: "Third Party Lab Results Register",
                    path: "#",
                  },
                ]}
                // module={ACTIVE_MODULE.incident}
              />
            </h3>
            <h3 className="title">Third Party Lab Results Register</h3>
            <div className="creationCard">
              <div className="formStepperWrapper">
                <ul>
                  <li
                    className={`coalDispatchStep ${
                      tab !== 1 ? "doneStep" : "activeStep"
                    }`}
                  >
                    <div className="stepIcon">
                      <span></span>{" "}
                    </div>
                    <label className="mt-3">Coal Dispatch Related Data</label>
                  </li>
                  <li
                    className={`sampleRelatedStep ${
                      tab === 2 ? "activeStep" : "initialStep"
                    }`}
                  >
                    <div className="stepIcon">
                      <span></span>{" "}
                    </div>
                    <label className="mt-3">
                      Third Party Lab Quality Results
                    </label>
                  </li>
                </ul>
              </div>
              <div className="formWrapper">
                <div className="maverickFormCard">
                  {tab === 1 && (
                    <CoalDispatchRelatedForm
                      form={form}
                      onChange={onChange}
                      error={error}
                      ModeOfDispatchOnChangeFn={ModeOfDispatchOnChangeFn}
                      RrDdNoOnChangeFn={RrDdNoOnChangeFn}
                      RrDdDateOnChangeFn={RrDdDateOnChangeFn}
                      assignNameOfSamplingAgency={assignNameOfSamplingAgency}
                      rrDDNoOList={rrDDNoOList}
                      declaredGradeList={declaredGradeList}
                    />
                  )}
                  {tab === 2 && (
                    <ThirdPartyLabResultForm
                      gradeList={gradeList}
                      form={form}
                      onChange={onChange}
                      error={error}
                      setTab={tabFun}
                    />
                  )}
                </div>
              </div>
              <div className="btnSec">
                <Button onClick={closeFun} className="secondaryBtn">
                  Close
                </Button>
                <div className="d-flex">
                  <StepButton
                    tabFun={tabFun}
                    onSubmit={handleSubmit}
                    nextStapeFun={nextStapeFun}
                    tab={tab}
                  />
                </div>
              </div>
            </div>
            {/* </Container> */}

            <MessagePopup
              show={show}
              setShow={setShow}
              messagePopupType={messagePopupType}
              closePopup={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdPartyLabResult;
