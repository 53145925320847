
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import jwtAuthService from "../../services/jwtAuthService";
import Loading from "../../components/Loader/Loading";


const MasterLoginPage = () => {

    const { token } = useParams();
    const navigate = useNavigate();
    const { search } = useLocation();
    let queryParams = new URLSearchParams(search).get("redirect_url");
    queryParams ??= "/";

    useEffect(() => {

        setTimeout(() => {
            !!token && (async () => {
                try {
                    const response = await RestMethod.GET(`/users/token-login/${token}`);
                    response?.data?.data && jwtAuthService.setSession(token);
                    response?.data?.data && jwtAuthService.setUser(response?.data?.data);
                    navigate(queryParams)
                    return response.data;
                } catch (error) {
                    console.log("error detected while fetching data from api", error);
                    return null;
                }
            })()
        }, 2500);
    }, [token])

    return (
        <>
            {<Loading />}
        </>
    )
};

export default MasterLoginPage;