import React, { useState } from "react";
import { RiFullscreenLine, RiFullscreenExitLine } from "react-icons/ri";

const ZoomScreen = ({ id, cardSizeClass }) => {
  const [isActive, setIsActive] = useState(false);
  const zoomOutScreen = () => {
    // console.log("hellllllllllllllllooooooo");
    const element = document.getElementById(id);
    element.className = !isActive
      ? "activeZoomScreen"
      : cardSizeClass
      ? cardSizeClass
      : "col-md-6";
    setIsActive(!isActive);
  };
  return (
    <>
      <span data-testid="ZoomScreen" onClick={() => zoomOutScreen()}>
        {!isActive ? <RiFullscreenLine /> : <RiFullscreenExitLine />}
      </span>
    </>
  );
};

export default ZoomScreen;
