import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../components/FormInputText/FormInputText";
import Select from "../../../components/Select/Select";
import {
  modeOfDispatchList,
  rrDoNoList,
  samplePrepareRegisterLabel,
} from "./DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { getAllSites } from "../../../_services/SiteServices/SiteServices";
import {
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
  idByCheckMine,
  subsidiaryListByMine,
} from "../../../_helpers/helper";
import { useParams } from "react-router-dom";

function CoalDispatchForm({
  setTab,
  form,
  onChange,
  error,
  gradeList,
  rrListOption,
  gradeListOption,
  samplingAgencyOpt,
}) {
  const params = useParams();
  const [sites, setSites] = useState();
  const [allSites, setAllSites] = useState([]);
  const [subsidery, setSubsidery] = useState([]);
  const [finalSiteList, setFinalSiteList] = useState();

  useEffect(() => {
    getAllSites("filter[siteType]=1,2,3&filter[activeStatus]=active").then(
      (response) => {
        if (!response?.data) return;
        setAllSites(response.data);
        const siteData = response.data.filter((d) => d?.siteType === 3);
        setSites(siteData);
        setFinalSiteList(siteData);
        setSubsidery(subsidiaryListByMine(response.data));
      }
    );
  }, []);
  useEffect(() => {
    setFinalSiteList(
      form?.mine ? sites?.filter((d) => d?.parentSiteId === +form?.mine) : sites
    );
  }, [form?.mine, sites]);

  return (
    <div className="creationWrapper">
      <Form>
        <Row>
          <Col sm={6}>
            <Select
              label={"Subsidiary"}
              name="mine"
              value={form?.mine}
              onChange={onChange}
              options={subsidery}
              KEY_label={"name"}
              KEY_value={"id"}
              error={error?.mine}
              disabled={
                // !!params?.id ||
                IS_USER_SITE_MINES() || IS_USER_SITE_RO()
              }
              blankSelect={"Select"}
            ></Select>
          </Col>
          <Col sm={6}>
            <Select
              label={"Mine"}
              name="site_id"
              value={form?.site_id}
              onChange={onChange}
              options={finalSiteList}
              KEY_label={"name"}
              KEY_value={"id"}
              error={error?.site_id}
              disabled={IS_USER_SITE_MINES()}
              blankSelect={"Select"}
            ></Select>
            {/* <FormInputText
                            name="mine"
                            value={form?.site_id ? sites?.find(d => form?.site_id === d.id)?.parentSite?.name : ''}
                            onChange={onChange}
                            type="text"
                            class="form-control"
                            placeholder=""
                            label={"Subsidiary"}
                            // error={error?.mine}
                            disabled={true}
                          /> */}
          </Col>
          <Col sm={6}>
            <Select
              label={samplePrepareRegisterLabel?.mode_of_dispatch}
              name="mode_of_dispatch"
              value={form?.mode_of_dispatch}
              onChange={onChange}
              options={modeOfDispatchList}
              error={error?.mode_of_dispatch}
            />
          </Col>
          <Col sm={6}>
            <Form.Label>
              {/* {form?.mode_of_dispatch === "SHRA"
                ? "RR date"
                : "Gross Weight Date"} */}
              Collection Date
            </Form.Label>
            <CustomDatePicker
              // isValidDate={(day) => day.isBefore(moment())}
              value={form?.rr_or_do_date ? form?.rr_or_do_date : ""}
              dateFormat="YYYY-MM-DD"
              name="rr_or_do_date"
              onChangeDate={onChange}
              error={error?.rr_or_do_date}
            />
          </Col>
          <Col md={6}>
            <Select
              onChange={onChange}
              value={form?.name_of_sampling_agency}
              name="name_of_sampling_agency"
              options={samplingAgencyOpt}
              error={error?.name_of_sampling_agency}
              label={samplePrepareRegisterLabel?.name_of_sampling_agency}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="text"
              placeholder="Enter here"
              label={
                samplePrepareRegisterLabel?.name_of_sampling_agency_representative
              }
              onChange={onChange}
              name="name_of_sampling_agency_representative"
              value={form?.name_of_sampling_agency_representative}
              error={error?.name_of_sampling_agency_representative}
            />
          </Col>
          <Col sm={6}>
            <Select
              onChange={onChange}
              value={form?.rr_or_do_number}
              name="rr_or_do_number"
              options={rrListOption}
              error={error?.rr_or_do_number}
              label={"Delivery No"}
            />
          </Col>
          <Col sm={6}>
            <Select
              onChange={onChange}
              value={form?.rr_or_do_number ? form?.declared_grade : ""}
              name="declared_grade"
              options={gradeListOption}
              error={error?.declared_grade}
              label={samplePrepareRegisterLabel?.declared_grade}
              disabled={true}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CoalDispatchForm;
