import React from "react";
import DateTime from "react-datetime";
import moment from "moment";
import InputError from "../_form/InputError/InputError";

const CustomDateTime = ({
  name = null,
  format = "YYYY-MM-DD",
  value = "",
  placeholder = "Date",
  timeFormat = false,
  onChange,
  onChangeDate,
  inputProps,
  disabled = false,
  isValidDate = () => true,
  renderInput = "",
  closeOnSelect = true,
  readOnly = false,
  timeConstraints = false,
  initialViewDate,
  className,
  error,
}) => {
  const handleOnChange = (date) => {
    if (onChange)
      onChange({
        target: {
          name: name,
          value:
            date instanceof moment
              ? date.format(!timeFormat ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm")
              : "",
        },
      });

    if (onChangeDate)
      onChangeDate({
        target: {
          name,
          value:
            date instanceof moment
              ? date.format(!timeFormat ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm")
              : "",
        },
      });
  };

  return (
    <div className="mb-3">
      <DateTime
        timeFormat={timeFormat}
        dateFormat={
          ["DD-MM-YYYY", "YYYY-MM-DD"].includes(format) ? "DD-MM-YYYY" : format
        }
        name={name}
        value={
          ["DD-MM-YYYY", "YYYY-MM-DD"].includes(format) && value
            ? moment(value)
            : value
        }
        //   onChange={(moment) => onChange?.(moment, name)}
        onChange={handleOnChange}
        inputProps={
          inputProps || {
            placeholder,
            disabled,
            readOnly,
          }
        }
        isValidDate={isValidDate}
        // renderInput={renderInput}
        renderInput={(props) => {
          return <input {...props} value={value ? props.value : ""} />;
        }}
        closeOnSelect={closeOnSelect}
        timeConstraints={timeConstraints}
        className={className || ""}
        // closeOnTab={true}
        popperProps={{ strategy: "fixed" }}
      />
      <InputError error={error} />
    </div>
  );
};

export default CustomDateTime;
