import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import { exportUploadedFileSap } from "../../_pages/EnvironmentManagement/UploadedFiles/DATA";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData as coalGradeSideBar } from "../../_pages/CoalGrade/DATA";
import EmptyDataList from "../../components/EmptyDataList/EmptyDataList";
import { SidebarData as contractorSideBar } from "../../_pages/ContractorManagement/DATA";
import { SidebarData as fuelManagementSideBar } from "../../_pages/FuelManagementSystem/FuelManagement/DATA";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import filter from "../../Assets/Images/filter.svg";
import { Container } from "react-bootstrap";
import {
  breadCrumb,
  sapDataFields,
  uploadedSapDataApis,
  sapDataFieldsMine,
  modeOfDispatchLabel,
} from "./DATA";
import { PARSE_IMG_URL } from "../../_helpers/commonHelper";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import { useForm } from "../../_helpers/hooks";
import DateFilter from "./DateFilter";

const ImportSapList = () => {
  const urlFileType = useLocation();
  let breadcrumbText = breadCrumb(urlFileType.pathname);
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState(breadcrumbText[4]);
  const [form, onChange, setForm] = useForm();
  const [filterStatus, setFilterStatus] = useState(0);

  let finalSapDataFields = sapDataFields;

  const successErrorFields = [
    {
      name: "New Count",
      sortable: true,
      selector: "file_type",
      cell: (row) =>
        row?.file_type === "fuel_sensor_sap_data"
          ? "NA"
          : row?.success_record_count
          ? row?.success_record_count
          : 0,
    },
    {
      name: "Duplicate Count",
      sortable: true,
      selector: "file_type",
      cell: (row) =>
        row?.file_type === "fuel_sensor_sap_data"
          ? "NA"
          : row?.duplicate_count
          ? row?.duplicate_count
          : 0,
    },
    {
      name: "Error Count",
      sortable: true,
      selector: "file_type",
      cell: (row) =>
        row?.file_type === "fuel_sensor_sap_data"
          ? "NA"
          : row?.error_record_count
          ? row?.error_record_count
          : 0,
    },
  ];

  if (
    breadcrumbText[0] === "Contractor Management" ||
    breadcrumbText[0] === "Fuel Management"
  ) {
    finalSapDataFields = sapDataFields.concat(successErrorFields);
  }

  const exportUploadedFiles = (filter, row) => {
    if (!row) {
      const res = exportUploadedFileSap(filter);
      return res;
    }
    let a = document.createElement("a");
    const downloadURL = PARSE_IMG_URL(URL_CONFIG.DEV_URL, row?.file_path);
    a.href = downloadURL;
    a.download = row.org_filename;
    a.click();
  };

  const checkData = async () => {
    let res = await uploadedSapDataApis.getAll.api(getAllFilter);
    if (Array.isArray(res?.data) && res?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    checkData();
  }, []);

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <DateFilter
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          setForm={setForm}
       
        />
      </div>
    );
  };
  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = breadcrumbText[4];
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }
    setGetAllFilter(filter);
  }, [filterStatus]);
  const onResetFilter = () => {
    setForm({  });
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };
  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar
        SidebarData={
          breadcrumbText[0] === "Contractor Management"
            ? contractorSideBar
            : breadcrumbText[0] === "Coal Grade"
            ? coalGradeSideBar
            : breadcrumbText[0] === "Fuel Management" || "HEMM Data"
            ? fuelManagementSideBar
            : null
        }
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                // hideThreeDotButton={true}
                downloadExcel={exportUploadedFiles}
                fields={
                  breadcrumbText[0] === "Coal Grade"
                    ? sapDataFieldsMine
                    : finalSapDataFields
                }
                apis={uploadedSapDataApis}
                title={breadcrumbText[0]}
                FilterBtn={FilterBtn}
                pageLink={breadcrumbText[0]}
                tableTitle={breadcrumbText[0]}
                primaryKey="id"
                getAllFilter={getAllFilter}
                hideAddButton={true}
                hideSnColumn={true}
                customTableTitle={`${breadcrumbText[0]} SAP Files`}
                isNoTabOnPage
                breadCrumb={[
                  {
                    name: breadcrumbText[0],
                    path: "/",
                  },
                  {
                    name: "Configuration",
                    path: breadcrumbText[1],
                  },
                  {
                    name:
                      breadcrumbText[0] === "HEMM Data"
                        ? "Import Excel File"
                        : "Import SAP",
                    path: breadcrumbText[2],
                  },
                  {
                    name:
                      breadcrumbText[0] === "HEMM Data"
                        ? "Import Excel File List"
                        : "Import SAP List",
                    path: "/",
                  },
                ]}
                excelFilterOption={getAllFilter}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar
        SidebarData={
          breadcrumbText[0] === "Contractor Management"
            ? contractorSideBar
            : breadcrumbText[0] === "Coal Grade"
            ? coalGradeSideBar
            : breadcrumbText[0] === "Fuel Management" || "HEMM Data"
            ? fuelManagementSideBar
            : null
        }
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            // pageLink={"/environment-management/uploaded-files"}
            title={breadcrumbText[0]}
            hideAddBtn={true}
            breadCrumb={[
              {
                name: breadcrumbText[0],
                path: "/",
              },
              {
                name: "Configuration",
                path: breadcrumbText[1],
              },
              {
                name:
                  breadcrumbText[0] === "HEMM Data"
                    ? "Import Excel File"
                    : "Import SAP",
                path: breadcrumbText[2],
              },
              {
                name:
                  breadcrumbText[0] === "HEMM Data"
                    ? "Import Excel File List"
                    : "Import SAP List",
                path: "/",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ImportSapList;
