import React, { useState, useEffect } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { poListApis, poListColumnFields } from "./DATA";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyDataList from "../ContractorPerformance/EmptyDataList";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import { getAllPoList } from "../../../services/_poList/poList";
import { exportPO } from "../../../services/_contractorList/contractorList";

const PoList = () => {
  const [isListData, setIsListData] = useState(true);
  let navigate = useNavigate();

  const getAll = async () => {
    const response = await getAllPoList("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0)
      setIsListData(true);
    else setIsListData(false);
  };

  const onEdit = (id) => {
    navigate("/contractor-management/configuration/po-list/edit/" + id);
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const statusByOptions = (row) => {
    return true;
  };
  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                breadCrumb={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/contractor-management/configuration",
                  },
                  {
                    name: "Po List",
                    path: "#",
                  },
                ]}
                hideAddButton={true}
                downloadExcel={exportPO}
                fields={poListColumnFields}
                apis={poListApis}
                title="Data"
                // viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/contractor-management/configuration/po-list"}
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                listname={"poList"}
                customTableTitle={"Po List"}
                isNoTabOnPage
                viewTitle={"Po List Details"}
                // hideSearch={true}
                editFunButton={onEdit}
                deleteFun
                hideExceDownloadByRow={statusByOptions}
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              hideAddBtn={true}
              pageLink={"/contractor-management/configuration/po-list"}
              breadCrumb={[
                {
                  name: "Contractor Management",
                  path: "#",
                },
                {
                  name: "Configuration",
                  path: "/contractor-management/configuration",
                },
                {
                  name: "Po List",
                  path: "#",
                },
              ]}
              title={"Po"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PoList;
