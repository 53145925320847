import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
// import FilterResponse from '../../_helpers/FilterResponse'
// import toastr from 'toastr'

export const ForgetPasswordService = {
  ForgetPassword,
  changePassword,
  resetPassword,
};

async function ForgetPassword(data) {
  try {
    const response = await RestMethod.POST("/users/forgot-password", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function resetPassword(data) {
  try {
    const response = await RestMethod.POST("/users/reset-password", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function changePassword(data) {
  console.log("+++++" , data );
  try {
    const response = await RestMethod.POST("/users/change-password", data);
    return response.data;
  } catch (error) {
    console.log(error);
    console.log("error detected while fetching data from api");
    return null;
  }
}
