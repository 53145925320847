import React from "react";
import { Button, Container } from "react-bootstrap";
import invite from "../../../Assets/Images/invite.svg";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { capitalizeNameLower } from "../../../_helpers/helper";

const EmptyDataList = ({ pageLink, title, hideAddBtn, tabButtons }) => {
  const navigate = useNavigate();
  return (
    <Container fluid>
      <h3 className="title" data-testid="empty-data-list">{`${title}`}</h3>
      <div className="tabSection emptyTabSection">{tabButtons && tabButtons()}</div>
      <div className="registerForm">
        <img src={invite} className="img-fluid" alt="registerImg" />

        {!hideAddBtn ? (
          <h3 className="title">
            You haven’t registered any {capitalizeNameLower(title)} yet, <br />
            Click on create data button
          </h3>
        ) : (<h3 className="title">
          No body registered any {capitalizeNameLower(title)} yet. <br />
        </h3>)}
        {!hideAddBtn && (
          <div>
            <Button
              onClick={() => navigate(`${pageLink}/create`)}
              className="primaryBtn"
              style={{ padding: "10px 24px" }}
              data-testid="create-data-button"
            >
              <AiOutlinePlus /> Create Data
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default EmptyDataList;
