import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

export const getAllMyHindrance = async (filter) => {
  let url = "/contractor-hindrance/my";

  // filter += "&tableName=action";
  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.contractorHindrances,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllMyActionHindrance = async (filter) => {
  let url = "/contractor-hindrance/my";

  filter += "&tableName=action";
  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.contractorHindrances,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllMyHistoryHindrance = async (filter) => {
  let url = "/contractor-hindrance/my";

  filter += "&tableName=history";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.contractorHindrances,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};
export const getAllHindrance = async (filter) => {
  let url = "/contractor-hindrance";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      totalCount: data?.totalCount,
      data: data?.contractorHindrances,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getHinderanceById = async (id) => {
  let url = "/contractor-hindrance/";
  if (id) url += id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      data: data,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const createHindrance = async (data) => {
  let url = "/contractor-hindrance/register-hindrance";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const updateHindrance = async (id, data) => {
  let url = "contractor-hindrance/update-flow/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const rejectHindrance = async (id, data) => {
  let url = "contractor-hindrance/reject/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const hindranceHistory = async (id, data) => {
  let url = "contractor-hindrance/history/";
  if (id) url += id;
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const updateHindranceStatus = async (id, data) => {
  let url = "contractor-hindrance/update/";
  if (id) url += id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};
export const getAllContractor = async () => {
  let url = "/contractor";

  try {
    const response = await RestMethod.GET(url);
    return response?.data?.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getContractorById = async (id) => {
  let url = "/contractor/";
  if (id) url += id;
  try {
    const response = await RestMethod.GET(url);
    const { status, message, data } = response.data;
    return {
      status,
      message,
      data: data,
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getHindranceDashboard = async (filter) => {
  let url = "/contractor-hindrance/dashboard";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      data: response?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const getHindranceSapDashboard = async (filter) => {
  let url = "/contractor-hindrance/dashboard-sap";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    const d = {
      status,
      message,
      data: response?.data,
    };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

export const exportHindranceTable = async (filter) => {
  try {
    let url = "/contractor-hindrance/dashboard";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      data: response?.data?.data || [],
      message,
      status,
      fileName,
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};


export const exportHindranceSapTable = async (filter) => {
  try {
    let url = "/contractor-hindrance/dashboard-sap";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      data: response?.data?.data || [],
      message,
      status,
      fileName,
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
