import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
// import FilterResponse from '../../_helpers/FilterResponse'
// import toastr from 'toastr'
export const ActivityService = {
  addActivity,
  getActivityById,
  updateActivity,
  deleteActivity,
  getAllActivity,
};

async function addActivity(data) {
  try {
    const response = await RestMethod.POST("/master-data/activity", data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function updateActivity(id, data) {
  try {
    const response = await RestMethod.PUT("/master-data/activity/" + id, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function deleteActivity(id, data) {
  try {
    const response = await RestMethod.DELETE("/master-data/activity/" + id);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getActivityById(id) {
  try {
    const response = await RestMethod.GET("/master-data/activity/" + id);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

async function getAllActivity(filter) {
  try {
    let filter_key = filter ? filter : "";
    const response = await RestMethod.GET("master-data/activity?"+filter_key);
    const { message, status, data } = response.data;
    return {
      message,
      status,
      data: data || [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
