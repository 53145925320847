import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getIncidentReportById } from "../../../services/_safetyManagement/incidentServices";
import MineDetailsAdd from "../Configuration/MineDetails/MineDetailsAdd";

const EditMineDetailsByIncident = () => {
  const [fetchIncident, setFetchIncident] = useState();
  const params = useParams();

  const fetchData = useCallback(async () => {
    let response = await getIncidentReportById(params?.id);
    if (!response?.status) return;
    setFetchIncident(response?.data);
  }, [params?.id]);

  useEffect(() => {
    if (!params.id) return;
    fetchData();
  }, [fetchData, params.id]);

  return <MineDetailsAdd fetchIncident={fetchIncident} incidentBy />;
};

export default EditMineDetailsByIncident;
