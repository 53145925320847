import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { capitalizeName } from "../../_helpers/helper";
import { DDMMYYYYFormat } from "../../_helpers/dateHelper";
import { contractorSapData } from "../ContractorManagement/DATA";

const ContractorSapView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <Row>
              <Col sm={6}>
                <label>{contractorSapData?.subsidiary}</label>
                <p name="email" className="content">
                  {form?.site?.name}
                </p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.mine}</label>
                <p className="content">{form?.site?.parentSite?.name}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.plant}</label>
                <p className="content">{form?.plant}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.productionDate}</label>
                <p className="content">
                  {DDMMYYYYFormat(form?.productionDate)}
                </p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.materialNumber}</label>
                <p className="content">{form?.materialNumber}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.purchasingDocument}</label>
                <p className="content">{form?.purchasingDocument}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.optr}</label>
                <p className="content">{form?.oprtrVend}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.name}</label>
                <p className="content">{form?.contractor?.contractor}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.nitAgreement}</label>
                <p className="content">{form?.nitAgreement}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.relDate}</label>
                <p className="content">{DDMMYYYYFormat(form?.relDate)}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.targetQnt}</label>
                <p className="content">{form?.trgtQty}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.achiQty}</label>
                <p className="content">{form?.achiQty}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData?.shortfall}</label>
                <p className="content">{form?.shortFall}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData.shortFallBycontractor}</label>
                <p className="content">{form?.shrtDueContractor}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData.reasonByCont1}</label>
                <p className="content">{form?.rasonByCont1}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData.reasonByCont2}</label>
                <p className="content">{form?.rasonByCont2}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData.reasonByOther1}</label>
                <p className="content">{form?.rasonByOther1}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData.reasonByOther2}</label>
                <p className="content">{form?.rasonByOther2}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData.remarks}</label>
                <p className="content">{form?.remarks}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData.createdBy}</label>
                <p className="content">{form?.createdByData}</p>
              </Col>
              <Col sm={6}>
                <label>{contractorSapData.createdOn}</label>
                <p className="content">{DDMMYYYYFormat(form?.createdOnData)}</p>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ContractorSapView;
