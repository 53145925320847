import React from "react";
import AmbientAirQualityCaaqmsDas from "./AmbientAirQualityCaaqmsDas";

const AmbientAirQualityCmpdil = ({ data, onChange, form, pageType }) => {
  return (
    <>
      <AmbientAirQualityCaaqmsDas
        data={data}
        onChange={onChange}
        form={form}
        pageType={pageType}
      />
    </>
  );
};

export default AmbientAirQualityCmpdil;
