import groupArray from "group-array";
import moment from "moment";
import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAExcelFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../_helpers/dateHelper";
export const pieChartDataWithModal = (data) => {
  const gData = groupArray(data, "name");

  const gData2 = groupArray(data, "name", "parent");

  const result = [];
  for (const key in gData) {
    const element = gData[key];
    const modalCharts = {
      label: key,
      charts: Object.keys(gData2[key]).map((k) => {
        return {
          label: k,
          chartData: gData2[key][k].map((d) => {
            return {
              name: d.mine,
              value: +d?.value,
              siteType: d.siteType,
            };
          }),
          hoSite: gData2[key][k].find((d) => d.siteType === 1) ? true : false,
          count: gData2[key][k].reduce(
            (previous, current) => previous + current.value,
            0
          ),
        };
      }),
    };

    result.push({
      name: key,
      value: element.reduce((previous, current) => previous + current.value, 0),
      modalCharts,
    });
  }

  return result;
};
export const pieChartDataWithModalForTotalVs = (data) => {
  const gData = groupArray(data, "name");

  const gData2 = groupArray(data, "name", "parent");

  const result = [];
  for (const key in gData) {
    const element = gData[key];
    const modalCharts = {
      label: key,
      charts: [
        {
          label: key,
          chartData: Object.keys(gData2[key]).map((d1) => {
            return {
              name: d1,
              value: gData2[key][d1].reduce(
                (previous, current) => previous + current.value,
                0
              ),
              // siteType: d.siteType,
            };
          }),
          // hoSite: Object.keys(gData2[key]).find((d) => gData2[d].siteType === 1) ? true : false,
          // count: Object.keys(gData2[key]).reduce(
          //   (previous, current) => previous + current.value,
          //   0
          // ),
        },
      ],
    };

    result.push({
      name: key,
      value: element.reduce((previous, current) => previous + current.value, 0),
      modalCharts,
    });
  }

  return result;
};

export const FuelDispencestackChartDataFormat = (data3, changeDate = true) => {
  const data = [];
  data3["Receipt"].forEach((element) => {
    data.push({
      ...element,
      value: +element.value,
      name: element.date,
      name1: "Receipt",
    });
  });
  data3["dip"].forEach((element) => {
    data.push({
      ...element,
      value: +element.value,
      name1: "dip",
      name: element.date,
    });
  });
  data3["Consumption"].forEach((element) => {
    data.push({
      ...element,
      name1: "Consumption",
      name: element.date,
      value: +element.value,
    });
  });
  data3["Opening Stock"].forEach((element) => {
    data.push({
      ...element,
      value: +element.value,
      name1: "Opening Stock",
      name: element.date,
    });
  });
  data3["Closing Stock"].forEach((element) => {
    data.push({
      ...element,
      value: +element.value,
      name: element.date,
      name1: "Closing Stock",
    });
  });
  const gData = groupArray(data, "name");
  const gData1 = groupArray(data, "name1");

  const holdChartData = [];
  const catergoryData = Object.keys(gData)
    .map((item) => item)
    ?.sort(); // NOSONAR - Ignore all issues on this comment
  for (const [key, value] of Object.entries(gData1)) {
    const severityWiseIncidentMMData = {
      name: key,
      type: "bar",
      stack: [
        "Opening Stock",
        "Closing Stock",
        "Receipt",
        "Consumption",
      ].includes(key)
        ? ""
        : "Ad",
      emphasis: {
        focus: "series",
      },
      // data: catergoryData
      //   ?.map((item) => value.find((d) => (d.name === item ? d.value : 0)))
      //   .filter((d) => !!d),

      data: catergoryData
        ?.map((item) => {
          let valIs;
          valIs = value.find((d) => d.name === item);
          const val2 = {
            value: null,
            date: item,
            name1: key,
            name: item,
          };
          return valIs ? valIs : val2;
        })
        .filter((d) => !!d),
    };
    holdChartData.push(severityWiseIncidentMMData);
  }
  const formattedCategoryData = catergoryData.map((d) => DDMMYYYYFormat(d));
  return {
    data: holdChartData,
    categoryData: !changeDate ? catergoryData : formattedCategoryData,
  };
};

export const stackChartDataFormat = (data) => {
  const gData = groupArray(data, "name");
  const gData1 = groupArray(data, "name1");

  const holdChartData = [];
  const catergoryData = Object.keys(gData).map((item) => item);
  for (const [key, value] of Object.entries(gData1)) {
    const severityWiseIncidentMMData = {
      name: key,
      type: "bar",
      stack: "Ad",
      emphasis: {
        focus: "series",
      },
      data: catergoryData?.map((item) =>
        value.find((d) => (d.name === item ? d.value : 0))
      ),
    };
    holdChartData.push(severityWiseIncidentMMData);
  }
  //holdChartData

  function customSort(a, b) {
    const numA = parseInt(a.name?.slice(1));
    const numB = parseInt(b.name?.slice(1));

    if (numA !== numB) {
      return numA - numB;
    }

    return a.name.localeCompare(b.name);
  }

  holdChartData.sort(customSort);

  return { data: holdChartData, categoryData: catergoryData };
};
export const multipleBarChartDataFormat = (data) => {
  const gData = groupArray(data, "name");
  const gData1 = groupArray(data, "name1");

  const holdChartData = [];
  const catergoryData = Object.keys(gData)?.map((item) => item);

  for (const [key, value] of Object.entries(gData1)) {
    const severityWiseIncidentMMData = {
      name: key,
      type: "bar",
      data: catergoryData?.map((item) => {
        const foundItem = value.find((d) => d.name === item);
        if (foundItem && typeof foundItem.value === "number") {
          return foundItem.value.toFixed(2);
        } else {
          return foundItem;
        }
      }),
    };
    holdChartData.push(severityWiseIncidentMMData);
  }

  return { data: holdChartData, categoryData: catergoryData };
};
export const BasicBarChartWithLabelDataFormat = (data) => {
  // console.log(data);
  const gData = groupArray(data, "name");

  const holdChartData = [];
  const legend = [];
  // const catergoryData = Object.keys(gData).map((item) => item);
  for (const [key, value] of Object.entries(gData)) {
    legend.push(key);
    const severityWiseIncidentMMData = {
      name: key,
      type: "bar",
      // stack: "Ad",
      // emphasis: {
      //   focus: "series",
      // },
      barGap: "0.5",
      label: {
        normal: {
          distance: 5,
          show: false,
          position: "insideBottom",
          align: "left",
          verticalAlign: "middle",
          fontWeight: "bold",
          rotate: 90,
          formatter: "{c}  {name|{a}}",
          rich: {
            name: {
              color: "#fff",
            },
          },
          fontSize: 14,
        },
      },
      data: [value[0]?.value],
    };
    holdChartData.push(severityWiseIncidentMMData);
  }
  return { data: holdChartData, customToolTip: gData, categoryData: legend };
};

export const BasicBarChartWithLabelMonthDataFormat = (data) => {
  // console.log(data);
  const gData = groupArray(data, "name");
  // const gData1 = groupArray(data, "name1");

  // console.log("chart", gData);
  const holdChartData = [];
  const legend = [];
  // const catergoryData = Object.keys(gData).map((item) => item);
  for (const [key, value] of Object.entries(gData)) {
    // console.log(key, value, "asdsdsfdsfsdf");
    legend.push(key);
    const severityWiseIncidentMMData = {
      name: moment(key, "YYYY-MM").format("MMM YYYY"),
      type: "bar",
      // stack: "Ad",
      // emphasis: {
      //   focus: "series",
      // },
      barGap: "0.5",
      label: {
        normal: {
          show: false,
          position: "insideBottom",
          distance: 5,
          align: "left",
          verticalAlign: "middle",
          rotate: 90,
          formatter: "{c}  {name|{a}}",
          fontSize: 14,
          fontWeight: "bold",
          rich: {
            name: {
              color: "#fff",
            },
          },
        },
      },
      data: [value[0]?.value],
    };
    holdChartData.push(severityWiseIncidentMMData);
  }
  // console.log("legend*/*/8***4*4*74*48498494", holdChartData);
  // return;
  return { data: holdChartData, categoryData: legend };
};
export const BasicBarChartWithLabelDataFormatForCustomToolTip = (data) => {
  // console.log(data);
  const gData = groupArray(data, "name");
  // const gData1 = groupArray(data, "name1");

  // console.log("chart", gData);
  const holdChartData = [];
  const tooltipData = [];
  const legend = [];
  // const catergoryData = Object.keys(gData).map((item) => item);
  for (const [key, value] of Object.entries(gData)) {
    // console.log(key, value, "asdsdsfdsfsdf");
    legend.push(key);
    const severityWiseIncidentMMData = {
      name: key,
      type: "bar",
      // stack: "Ad",
      // emphasis: {
      //   focus: "series",
      // },
      barGap: "0.5",
      label: {
        normal: {
          show: false,
          position: "insideBottom",
          distance: 5,
          align: "left",
          verticalAlign: "middle",
          rotate: 90,
          formatter: "{c}  {name|{a}}",
          fontSize: 14,
          fontWeight: "bold",
          rich: {
            name: {
              color: "#fff",
            },
          },
        },
      },
      data: [value[0]?.value],
    };
    // tooltipData.push(gData[key]);
    holdChartData.push(severityWiseIncidentMMData);
  }

  // console.log("legend*/*/8***4*4*74*48498494", holdChartData);
  // return;
  return {
    data: holdChartData,
    custumToolTipData: gData,
    categoryData: legend,
  };
};
export const basicBarChartWithCustomTooltipDataFormatForMedical = (data) => {
  // console.log(data);
  data = data?.map((d) => {
    return {
      ...d,
      name1: d.date,
      mineFullName: d.name,
    };
  });
  const gData = groupArray(data, "date");
  const gData1 = groupArray(data, "name");

  const holdChartData = [];
  const catergoryData = Object.keys(gData).map((item) => item);
  for (const [key, value] of Object.entries(gData1)) {
    // console.log("sdsfssssssssssssssssss", gData1);
    // console.log(key, value, "asdsdsfdsfsdf");
    const severityWiseIncidentMMData = {
      name: key,
      type: "bar",
      stack: "Ad",
      emphasis: {
        focus: "series",
      },
      data: catergoryData?.map((item) =>
        value.find((d) => (d.date === item ? d.value : 0))
      ),
    };
    holdChartData.push(severityWiseIncidentMMData);
  }
  // console.log("categoryData", catergoryData);

  return { data: holdChartData, categoryData: catergoryData };
};
export const basicBarChartWithCustomTooltipDataFormat = (data) => {
  const gData = groupArray(data, "name");
  const outstandingCAPAsRoData = {
    name: Object?.values(gData)
      ?.map((item) =>
        item.map((d) =>
          d?.parentSiteName != null ? d?.parentSiteName : d?.siteName
        )
      )
      .filter((d, idx) => idx == 0)
      .join(""),
    type: "bar",
    color: "#5f6bc2",
    emphasis: {
      focus: "series",
    },
    data: Object?.values(gData)?.map(
      (item) => +item.map((d) => d?.value).join()
    ),
  };

  const catergoryData = Object?.values(gData)?.map((item) =>
    item.map((d) => d?.name).join()
  );

  const min = Math.min(...(outstandingCAPAsRoData?.data || []));
  const max = Math.max(...(outstandingCAPAsRoData?.data || []));
  const minMaxData = { min, max };

  return {
    data: [outstandingCAPAsRoData],
    categoryData: catergoryData,
    dataList: gData,
    minMaxData,
  };
};
export const mixLineBarDataFormat = (data) => {
  const line_data = data?.map((item) => item.line_value);
  const bar_data = data?.map((item) => item.bar_value);
  const bar_data2 = data?.map((item) => item.bar_value2);
  const type = data?.map((item) => item.category);
  return {
    line_data: line_data,
    bar_data: bar_data,
    bar_data2: bar_data2,
    type: type,
  };
};
export const radarDataFormat = (data, type, radorListVendor) => {
  const radorDataFormat = data.map((item) => ({
    name: item.entity,
    value: [
      item.benchScore,
      item.biometricScore,
      item.distributionSafetyGadgetScore,
      item.dustSuppressionScore,
      item.environmentScore,
      item.equipmentRunningScore,
      item.equipmentRollScore,
      item.fireProtectionScore,
      item.haulRoadScore,
      item.imePmeScore,
      item.paymentClipScore,
      item.safetyFeatureScore,
      item.vtcCertificateScore,
      item.illuminationScore,
    ],
  }));

  const maxValues = Object.keys(data[0]).reduce((maxObj, key) => {
    if (key !== "entity") {
      const max = Math.max(...data.map((item) => item[key]));
      maxObj[key] = max;
    }
    return maxObj;
  }, {});

  const radarIndicator = Object.entries(maxValues).map(([key, max]) => ({
    name: radorListVendor[key],
    max: max,
  }));
  return {
    radorDataFormat: radorDataFormat,
    radarIndicator: radarIndicator,
  };
};
export const barChartDataFormat = (data) => {
  const gData = groupArray(data, "name");
  const outstandingCAPAsRoData = Object?.values(gData)?.map((item) => {
    return +item
      ?.map((d) => d.value)
      .reduce((total, num) => {
        return total + +num;
      });
  });

  const catergoryData = Object?.keys(gData);
  return { data: outstandingCAPAsRoData, categoryData: catergoryData };
};

export const multiLineChartDataFormat = (data) => {
  const gData = groupArray(data, "name");
  const gData1 = groupArray(data, "date");
  let catergoryData = Object.keys(gData1);
  console.log("gData+++++", gData);
  console.log("gData1++++", gData1);

  function isDate(string) {
    return !isNaN(Date.parse(string));
  }
  catergoryData.length > 0 && isDate(catergoryData[0]) && catergoryData.sort(); // NOSONAR - Ignore all issues on this comment
  const multiData = Object.keys(gData).map((d, idx) => {
    const arrayData = gData[d];
    return {
      name: d,
      data: catergoryData?.map((dataIs) => {
        let naObj = {
          value: 0,
          symbol: "circle",
          symbolSize: 5,
          label: { show: true, formatter: "NA" },
        };
        let bdlObj = {
          value: 0,
          symbol: "circle",
          symbolSize: 5,
          label: { show: true, formatter: "BDL" },
        };
        let existData = arrayData.find((d) => d.date === dataIs);
        return existData
          ? existData.value === "NA"
            ? naObj
            : existData.value === "BDL"
            ? bdlObj
            : existData.value
          : "";
      }),
      type: "line",
      emphasis: {
        focus: "series",
      },
      connectNulls: true,
    };
  });

  if (catergoryData.length > 0 && isDate(catergoryData[0])) {
    catergoryData = catergoryData?.map((d) => {
      return d.includes("-") ? DDMMYYYYFormat(d) : d;
    });
  }

  const minMaxArray = multiData?.map((d) => {
    const minMaxObj = {};
    minMaxObj["min"] = Math.min(...(d?.data || []));
    minMaxObj["max"] = Math.max(...(d?.data || []));
    return minMaxObj;
  });

  const min = Math.min(...(minMaxArray?.map((d) => d.min) || []));
  const max = Math.max(...(minMaxArray?.map((d) => d.max) || []));
  const minMaxData = { min, max };

  return { data: multiData, categoryData: catergoryData, minMaxData };
};

export const multiLineChartDataDateTimeFormat = (data) => {
  const gData = groupArray(data, "name");
  const gData1 = groupArray(data, "date");
  let catergoryData = Object.keys(gData1);

  function isDate(string) {
    return !isNaN(Date.parse(string));
  }
  catergoryData.length > 0 && isDate(catergoryData[0]) && catergoryData.sort(); // NOSONAR - Ignore all issues on this comment
  const multiData = Object.keys(gData).map((d, idx) => {
    const arrayData = gData[d];
    console.log("asddas", arrayData);
    return {
      name: d,
      make: arrayData[0]?.make,
      model: arrayData[0]?.model,
      // date: moment(arrayData[0]?.date).format("HH:mm:ss"),
      data: catergoryData?.map((dataIs) =>
        arrayData.find((d) => d.date === dataIs)
          ? arrayData.find((d) => d.date === dataIs).value
          : ""
      ),
      type: "line",
      emphasis: {
        focus: "series",
      },
    };
  });

  if (catergoryData.length > 0 && isDate(catergoryData[0])) {
    catergoryData = catergoryData?.map((d) => {
      return d.includes("-") ? DDMMYYYYHHMMAExcelFormat(d) : d;
    });
  }

  const minMaxArray = multiData?.map((d) => {
    const minMaxObj = {};
    minMaxObj["min"] = Math.min(...(d?.data || []));
    minMaxObj["max"] = Math.max(...(d?.data || []));
    return minMaxObj;
  });

  const min = Math.min(...(minMaxArray?.map((d) => d.min) || []));
  const max = Math.max(...(minMaxArray?.map((d) => d.max) || []));
  const minMaxData = { min, max };
  console.log("multiDatamultiDatamultiData", multiData);
  return { data: multiData, categoryData: catergoryData, minMaxData };
};

export const pyramidIncidentType = {
  fatality: "Fatality",
  seriousBInjury: "Serious Bodily Injury",
  lti: "LTI",
  nearMissMti: "Near Miss + MTI",
  unsafeActUnsafeCondition: "UA + UC",
};

export const pyramidChartDataFormat = (data) => {
  const gData = groupArray(data, "name");
  const catergoryData = Object.keys(gData);

  const pyramidIncidentType = {
    unsafeActUnsafeCondition: "UA + UC",
    nearMissMti: "NM + MTI",
    lti: "LTI",
    seriousBInjury: "Serious Bodily Injury",
    fatality: "Fatality",
  };

  const pyramidKeyOrder = [
    "unsafeActUnsafeCondition",
    "nearMissMti",
    "lti",
    "seriousBInjury",
    "fatality",
  ];

  // console.log("/*/*/////////", catergoryData);

  const result = [];

  for (const key of pyramidKeyOrder) {
    const element = gData[key];
    if (element) {
      result.push({
        name: pyramidIncidentType[key],
        label: {
          show: false,
          position: "inside",
        },
        value: element.reduce(
          (previous, current) => previous + current.value,
          0
        ),
      });
    }
  }
  return { data: result, categoryData: catergoryData };
};

export const mapFormatData = (data) => {
  return data?.map((d) => {
    const position = [d?.lat, d?.log];
    return {
      ...d,
      position: position,
    };
  });
};

export const multiColorBarChartFormate = (data) => {
  const legendName = [];
  const finalData = data?.map((d) => {
    legendName.push(d?.name);
    return {
      name: d?.name,
      data: [d?.value],
      barGap: 0,
      emphasis: {
        focus: "series",
      },
      type: "bar",
    };
  });

  const legend = { data: legendName };
  const xAxis = { type: "category", data: ["Reason"] };

  return {
    data: finalData,
    legend,
    xAxis,
  };
};
