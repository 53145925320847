import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import {
  MyIncidentApis,
  MyIncidentColumnFields,
  safetyAdminPermission,
  safetyManagementApis,
  safetyManagementColumnFields,
  safetyManagementFilterForm,
  statusListObj,
  tabUrl,
} from "./../DATA";
import filter from "../../../Assets/Images/filter.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../../_helpers/hooks";
import {
  exportMySafetyManagement,
  exportSafetyManagement,
  getMyIncidentListWithPagination,
  getSafetyManagementListWithPagination,
} from "../../../services/_safetyManagement/safetyManagement";
import SafetyManagementView from "./../SafetyManagementView";
import SafetyManagementFilterForm from "./../SafetyManagementFilterForm";
// import "./../safety.scss";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import {
  IS_MOBILE,
  findUserPermissionedTabList,
} from "../../../_helpers/helper";
import SafetySideBar from "../SafetySideBar/SafetySideBar";
import {
  SidebarData,
  filterOptionFunc,
  mobileSidebarData,
  reportEditLink,
} from "./DATA";
import { permissionCheck } from "../../../_services/Auth/helper";

const MyIncidentList = () => {
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.safetyManagement)
  );

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} />;
  };

  const getAll = async () => {
    const response = await getMyIncidentListWithPagination("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(safetyManagementFilterForm);

  const FilterBtn = () => {
    const [openModel, setOpenModel] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpenModel(!openModel)}
          aria-controls="example-collapse-text"
          className="secondaryBtn"
          aria-expanded={openModel}
          data-testid="filter-button"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>

        <SafetyManagementFilterForm
          form={form}
          onChange={onChange}
          onSubmitFilter={onSubmitFilter}
          isOpen={openModel}
          onResetFilter={onResetFilter}
          setOpen={setOpenModel}
        />
      </div>
    );
  };

  const onResetFilter = () => {
    setForm(safetyManagementFilterForm);
    setGetAllFilter();
  };

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    getAll();
  }, []);

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = filterOptionFunc(form);
    setGetAllFilter(filter);
  }, [filterStatus]);

  const statusByOptions = (row) => {
    return !!(row?.status === statusListObj?.reported) && permissionCheck(["safety_admin"]);
  };

  const viewAsPopup = (form) => {
    return <SafetyManagementView form={form} />;
  };
  const onEdit = (id) => {
    navigate(`${reportEditLink}/${id}`);
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  return isListData ? (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={IS_MOBILE() ? mobileSidebarData : SidebarData}
        pageTitle={"My Incidents"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                viewAsPopup={viewAsPopup}
                fields={MyIncidentColumnFields}
                apis={MyIncidentApis}
                title="Data"
                FilterBtn={FilterBtn}
                hideSnColumn
                pageLink={
                  "/safety-management-system/incident-management/my-incidents"
                }
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                getAllFilter={getAllFilter}
                hideAddButton
                downloadExcel={exportMySafetyManagement}
                breadCrumb={[
                  { name: "Safety Management", path: "#" },
                  {
                    name: "Incident Management",
                    path: "/safety-management-system/incident-management",
                  },
                  {
                    name: "My Incidents",
                    path: "#",
                  },
                ]}
                isNoTabOnPage
                modalSize={"xl"}
                viewTitle={"Incident Details"}
                // editFunButton={onEdit}
                statusByOptions={statusByOptions}
                customTableTitle={"My Incidents"}
                icons
                updatePageLink={reportEditLink}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={IS_MOBILE() ? mobileSidebarData : SidebarData}
        pageTitle={"My Incidents"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            title={"Incident Management"}
            pageLink={"/incident-management"}
            hideAddBtn
          />
        </div>
      </div>
    </div>
  );
};

export default MyIncidentList;
