import { URL_CONFIG } from "../../../_constants/Config/URL_CONFIG";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "./DATA";

const EnvironmentManagement = () => {
  return (
    <div className="sidebarWrapper">
      {/* <Sidebar /> */}
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <iframe
            src={`${URL_CONFIG.AWS_QUICKSIGHT_URL}e74e8efc-fb50-44c5-b9d4-c79017eb9818?directory_alias=cil-dev-si-bi`}
            title="Loading..."
          />
        </div>
      </div>
    </div>
  );
};

export default EnvironmentManagement;
