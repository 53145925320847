import selectionIcon1 from "../../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../../Assets/Images/selectionIcon3.svg";

import {
  getFuelDispenseListWithPagination,
  getUserListWithPagination,
  exportFuelDispenseById,
  findDistance,
  getFuelStockListWithPagination,
  deleteFuelDispense,
} from "../../services/_fuelDispense/fuelDispense";
import { DDMMYYYYFormat, DDMMYYYYHHMMAFormat } from "../../_helpers/dateHelper";
import { IS_MOBILE } from "../../_helpers/helper";
import { BiSearchAlt } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { TiInfoLarge, TiInfoOutline } from "react-icons/ti";
export const Status = {
  Draft: "Draft",
  Submitted: "Submitted",
};

export const pageLink = `${
  IS_MOBILE() ? "/mobile" : ""
}/fuel-management-system/fuel-management-list`;

export const fuelDispenseColumnFields = [
  {
    name: "HEMM ID",
    selector: "hemm_id",
    sortable: true,
    isShowMobile: true,
    cell: (row) => row?.fuel_dispense_hemm_datas?.hemm_id,
    minWidth: "150px",
  },
  {
    name: "Subsidiary",
    selector: "site",
    sortable: true,
    cell: (row) => row?.site?.parentSite?.name,
    minWidth: "150px",
  },
  {
    name: "Mine",
    selector: "site",
    sortable: true,
    cell: (row) => row?.site?.name,
  },
  {
    name: "Equipment Type",
    selector: "isBowser",
    // sortable: true,
    cell: (row) => row?.fuel_dispense_hemm_datas?.fms_vehicle_type,
    minWidth: "170px",
  },
  {
    name: "Machine Serial No.",
    selector: "machine_serial",
    sortable: true,
    cell: (row) => row?.fuel_dispense_hemm_datas?.machine_serial,
    minWidth: "250px",
  },
 
  {
    name: "Tank / Bowser",
    selector: "tank",
    sortable: true,
    cell: (row) =>
      row?.dispensedByBowser === null
        ? row?.tank?.name
        : row?.bower_location?.hemm_id,
    minWidth: "200px",
  },
  {
    name: "Fuel Station",
    selector: "fuel_location",
    sortable: true,
    cell: (row) =>
      row?.dispensedByBowser === null
        ? row?.fuel_location?.fuel_location_name
        : "Not Available",
    minWidth: "200px",
  },
  {
    name: "Fuel (Ltrs)",
    selector: "fuel_dispensing_qty",
    sortable: true,
    // cell: (row) => row?.site?.name,
    minWidth: "170px",
  },
  {
    name: "Dispensed Date",
    selector: "createdAt",
    sortable: true,
    cell: (row) => (row?.createdAt ? DDMMYYYYHHMMAFormat(row?.createdAt) : ""),
    minWidth: "250px",
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    minWidth: "180px",
    cell: (row) =>
      row?.status ? (
        <span className="statusSec draft">{Status[row?.status]}</span>
      ) : (
        ""
      ),
  },
];

export const fuelStockColumnFields = [
  {
    name: "Receipt Date",
    selector: "receipt_date",
    sortable: true,
    cell: (row) => (row?.receipt_date ? DDMMYYYYFormat(row?.receipt_date) : ""),
  },
  {
    name: "Subsidiary",
    selector: "parentSite",
    // sortable: true,
    cell: (row) => row?.fuelLocationCode?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "site",
    // sortable: true,
    cell: (row) => row?.fuelLocationCode?.site?.name,
  },
  {
    name: "Fuel Station Name",
    selector: "fuelLocationCode.fuel_location_name",
    // sortable: true,
    cell: (row) => row?.fuelLocationCode?.fuel_location_name,
  },
  {
    name: "Fuel Station Code",
    selector: "fuel_location_code",
    // sortable: true,
    cell: (row) => row?.fuel_location_code,
  },
];

export const fuelDispenseApis = {
  getAll: {
    api: getFuelDispenseListWithPagination,
  },
  getById: {
    api: exportFuelDispenseById,
  },
  delete: {
    api: deleteFuelDispense,
  },
};

export const fuelStocksApis = {
  getAll: {
    api: getFuelStockListWithPagination,
  },
  getById: {
    api: exportFuelDispenseById,
  },
};

export const UserApis = Object.freeze({
  getAll: {
    api: getUserListWithPagination,
  },
});

export const fuelManagementFilterForm = {
  startDate: "",
  endDate: "",
  // contractor_id: "",
};
export const userFilterForm = {
  status: "",
};

export const gpsStatus = {
  Active: "1",
  InActive: "0",
};

export const tabUrl = {
  fuelManagement: [
    {
      to: "/fuel-management-system/fuel-management-list",
      label: "Dispense List",
      active: 0,
      permission: ["fuel_admin", "dispenser"],
    },
    {
      to: "/fuel-management-system/user-list",
      label: "Dispenser User List",
      active: 0,
      permission: ["fuel_admin"],
    },
  ],
};

export const userTabUrl = {
  fuelManagement: [
    {
      to: "/fuel-management-system/all-user-list",
      label: "All User List",
      active: 0,
      permission: ["fuel_admin"],
    },
    {
      to: "/fuel-management-system/user-list",
      label: "Dispense User List",
      active: 0,
      permission: ["fuel_admin", "dispenser"],
    },
  ],
};

export const incidentTabList = [
  {
    label: "Fuel Station Details",
    tab: 1,
    tabStatus: false,
    Icon: TiInfoOutline,
  },
  {
    label: "HEMM Vehicle Details",
    tab: 2,
    tabStatus: false,
    Icon: TiInfoLarge,
  },
  {
    label: "Before Fuel Dispensed Details",
    tab: 3,
    tabStatus: false,
    Icon: FiSettings,
  },
  {
    label: "After Fuel Dispensed Details",
    tab: 4,
    tabStatus: false,
    Icon: BiSearchAlt,
  },
];

export const hmrReadingRuls = {
  field_name: "user_entered_hmr_reading",
  label: "Meter Reading",
  type: "number",
  isRequired: true,
  tab: 3,
  minNumber: 0,
};

export const odometerReadingRuls = {
  field_name: "user_entered_odometer_reading",
  label: "Meter Reading",
  type: "number",
  isRequired: true,
  tab: 3,
  minNumber: 0,
};

export const fuelMeterAfterRuls = {
  field_name: "fuel_meter_after",
  label: "Meter Reading",
  type: "number",
  isRequired: true,
  tab: 4,
};

export const fuelManagementRules = [
  {
    type: "number",
    label: "Fuel Station",
    isRequired: true,
    field_name: "fuel_location_id",
    tab: 1,
  },
  {
    field_name: "mine",
    label: "Mine",
    type: "string",
    isRequired: true,
    tab: 1,
  },
  {
    field_name: "fms_vehicle_name",
    label: "FMS Vehicle Name",
    type: "string",
    isRequired: true,
    tab: 2,
  },
  {
    field_name: "subsidiary",
    label: "Subsidiary",
    type: "string",
    isRequired: true,
    tab: 1,
  },
  {
    label: "HEMM ID",
    type: "string",
    field_name: "hemm_id",
    isRequired: true,
    tab: 2,
  },
  {
    field_name: "fms_vehicle_type",
    label: "FMS Vehicle Type",
    type: "string",
    isRequired: true,
    tab: 2,
  },
  {
    field_name: "make",
    label: "Make",
    type: "string",
    isRequired: true,
    tab: 2,
  },
  {
    field_name: "model",
    label: "Model",
    type: "string",
    isRequired: true,
    tab: 2,
  },
  // {
  //   type: "string",
  //   field_name: "capacity",
  //   label: "Capacity",
  //   // isRequired: true,
  // },
  hmrReadingRuls,
  odometerReadingRuls,
  // {
  //   // isRequired: true,
  //   field_name: "dispancer_post_image_id",
  //   label: "Image",
  //   type: "number",
  // },

  // {
  //   label: "Meter Reading",
  //   field_name: "fuel_meter",
  //   type: "number",
  //   isRequired: true,
  // },
  // {
  //   field_name: "fuel_meter_after",
  //   label: "Meter Reading",
  //   type: "number",
  //   isRequired: true,
  // },

  // {
  //   field_name: "fuel_meter_img_id",
  //   label: "Image",
  //   type: "number",
  //   isRequired: true,
  // },
  // {
  //   field_name: "fuel_meter_img_after_id",
  //   label: "Image",
  //   type: "number",
  //   isRequired: true,
  // },
  // {
  //   // isRequired: true,
  //   field_name: "hemm_pre_image_id",
  //   label: "3Image",
  //   type: "number",
  // },
  // {
  //   label: "2Image",
  //   field_name: "dispancer_pre_image_id",
  //   type: "number",
  //   // isRequired: true,
  // },
  {
    isRequired: true,
    field_name: "fuel_dispensing_qty",
    label: "Dispensed Quantity (L)",
    type: "number",
    tab: 4,
    minNumber: 0,
  },
  // {
  //   field_name: "hemm_post_image_id",
  //   type: "number",
  //   // isRequired: true,
  //   label: "1Image",
  // },
];

export const initialForm = {
  afterFuelDispense: "",
  make: "",
  capacity: "",
  model: "",
  isBowser: 0,
  fuel_location_id: "", 
  mine: "",
  subsidiary: "",
  hemm_id: "",
  fms_vehicle_type: "",
  fms_vehicle_name: "",
  user_entered_hmr_reading: null,
  fuel_dispensing_qty: "",
  dispancer_post_image_data: "",
  dispancer_pre_image_data: "",
  hemm_post_image_data: "",
  hemm_pre_image_data: "",
  hemm_fms_verify: 0,
  hemm_pre_image_id: null,
  dispancer_pre_image_id: null,
  hemm_post_image_id: null,
  dispancer_post_image_id: null,
  user_entered_odometer_reading: null,
  fuel_meter: "",
  fuel_meter_after: "",
  fuel_meter_img: "",
  fuel_meter_img_id: "",
  fuel_meter_img_after_id: "",
  status: "Submitted",
  hmr_reading_check_box: 0,
  odometer_reading_check_box: 1,
  hmr_hours_check_box: 0,
  last_hmr_reading_check_box: 0,
  last_odometer_reading_check_box: 1,
  odometer_kms_check_box: 1,
  fuel_reading_check_box: 0,
  workshop_id: null,
  issuer: "",
  receiver: "",
};

export const statusListObj = {
  draft: "Draft",
};

export const dispensedType = [
  {
    value: "dispensedInBowser",
    label: "In Bowser",
  },
  {
    value: "dispensedByBowser",
    label: "From Bowser",
  },
  {
    value: "dispensedByFuelLocation",
    label: "From Fuel Location",
  },
  {
    value: "dispensedInHemm",
    label: "In HEMM",
  },
];
export const configuration = [
  {
    img: selectionIcon1,
    label: "Fuel Location",
    link: "fuel-location",
  },
  {
    img: selectionIcon2,
    label: "HEMM / Bowser",
    link: "hemm-data",
  },
  {
    img: selectionIcon3,
    label: "Workshop Master",
    link: "workshop-master",
  },
  {
    img: selectionIcon3,
    label: "Import SAP Data",
    link: "sap-import",
  },
  {
    img: selectionIcon1,
    label: "Tank Master",
    link: "tank-master",
  },
  // {
  //   img: selectionIcon2,
  //   label: "Add Fuel Receipt",
  //   link: "fuel-receipt",
  // },
  // {
  //   img: selectionIcon3,
  //   label: "Daily Fuel Stock Update",
  //   link: "stock",
  // },
  {
    img: selectionIcon1,
    label: "Supplier",
    link: "supplier",
  },
];

export const latiLongituteData = (locationList, userLoc, locationFlag) => {
  if (locationList.length > 0) {
    let minimumIs;
    let distanceDiff = 0;
    let minimumIndex;
    let nearestFuelStation = [];
    locationList.forEach((d) => {
      distanceDiff = findDistance({
        lat1: userLoc.latitude,
        lat2: d.lat,
        lon1: userLoc.longitude,
        lon2: d.long,
      });
      nearestFuelStation.push(distanceDiff);
    });
    const numericNumbers = nearestFuelStation.filter((e) => !isNaN(e));
    minimumIs = Math.min(...numericNumbers);
    minimumIndex = nearestFuelStation.indexOf(minimumIs);

    // if (minimumIs <= 10) {
    return {
      locationId: locationList[minimumIndex]?.id || "",
      mineId: locationList[minimumIndex]?.site_id || "",
      subsidiaryId: locationList[minimumIndex]?.site?.parentSite?.id || "",
    };
    // } else return {};
  }
};

export const workshopObjForm = [
  {
    label: "Not Available",
    value: null,
  },
];
