import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, Link } from "react-router-dom";
import toastr from "toastr";
import { Validation } from "../../_helpers/validation/Validation";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../Assets/Images/logo.png";
import loginImg from "../../Assets/Images/loginImg.jpg";
import error from "../../Assets/Images/error.svg";
import { isLogin } from "../../_helpers/helper";
import "./login.scss";
import Loading from "../../../src/components/Loader/Loading";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import CryptoJS from "crypto-js";

const SigninSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid Email id")
    .required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be 6 character long")
    .required("Please enter the Password"),
});

const rules = [
  {
    field_name: "email",
    label: "Email",
    type: "email",
    isRequired: true,
  },
  {
    field_name: "first_name",
    label: "First Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "last_name_1",
    label: "Last Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "organisation_name",
    label: "Oraganisation Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "phone",
    label: "Phone",
    type: "number",
    max: "10",
    min: "10",
    isRequired: true,
  },
  {
    field_name: "password",
    label: "Password",
    type: "password",
    isRequired: true,
  },
];

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      first_name: "",
      last_name_1: "",
      phone: "",
      organisation_name: "",
      signinDisplay: false,
      signupDisplay: false,
      otpDisplay: false,
      companyArr: [],
      data: {},
      otp: "",
      logo: "",
      Applicationlogo: "",
      emailOtp: "",
      error: {},
      isError: false,
      showPass: false,
      textIdx: 0,
      loading: 0,
      submitted: 0,
      formSubmit: 0,
    };
    this.signin = this.signin.bind(this);
    this.signup = this.signup.bind(this);
    this.callback = this.callback.bind(this);
    this.resetData = this.resetData.bind(this);
    this.setOTP = this.setOTP.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("noBodypad");
    const isMobile = window.location.href.split("/")[3] === "mobile";

    this.setState({ isMobile });

    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 5000);
  }

  // componentDidUnmount() {
  //   clearInterval(this.timeout);
  // }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  handleChange = (event) => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleEncrypt = (password) => {
    const secretKey = "ldkKD93m9KnGls3ndsKEL22sleK";
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  };

  handleSubmit = (value, { isSubmitting }) => {
    value = { ...value, password: this.handleEncrypt(value.password) };
    this.state.formSubmit = 1;
    this.props.loginWithEmailAndPassword({
      ...value,
      isMobile: this.state.isMobile ? 1 : 0,
    });
    this.state.submitted = 1;
  };

  setOTP(e) {
    if (e.target.value.length <= 6)
      this.setState({
        [e.target.name]: e.target.value,
      });
  }

  callback(value) {
    if (value) {
      toastr.success("You have Signup Successfully, Please verify your email.");
      this.resetData();
      this.signin();
    }
  }

  signin() {
    this.setState({
      signinDisplay: !this.state.signinDisplay,
      signupDisplay: false,
      otpDisplay: false,
    });
  }

  signup() {
    this.setState({
      signupDisplay: !this.state.signupDisplay,
      signinDisplay: false,
      otpDisplay: false,
    });
  }
  // handleCutCopyPaste = (e) => {
  //   e.preventDefault();
  //   alert('Cut, copy, and paste actions are not allowed.');
  // };

  resetData() {
    this.setState({
      email: "",
      password: "",
      first_name: "",
      last_name_1: "",
      phone: "",
      organisation_name: "",
      signinDisplay: false,
      signupDisplay: false,
      otp: "",
      emailOtp: "",
      isError: false,
    });
  }

  render() {
    if (isLogin()) {
      return <Navigate to={this.state.isMobile ? "/mobile" : "/"} replace />;
    }
    
    if (this.state.submitted) {
      if (
        this?.props?.login?.error?.password ||
        this?.props?.login?.error?.username
      ) {
        this.state.loading = 0;
      } else this.state.loading = 1;
    }

    return (
      <React.Fragment>
        {this?.state?.loading ? <Loading /> : ""}
        {this.state.isMobile ? (
          <Container fluid className="loginScreen">
            <div className="loginHeader">
              <img src={logo} className="img-fluid" alt="DigicoalLogo" />
              {/* <Button className="secondaryBtn">About Us</Button> */}
            </div>
            <div className="loginContent">
              <Row className="align-items-center">
                <Col md={6} className="">
                  <div className="loginFormSec">
                    <div className="loginForms">
                      <div className="loginIntro">
                        <h5 className="loginIntroTxt">Welcome to,</h5>
                        <h5 className="loginIntroTxt">Digicoal</h5>
                      </div>
                      <Formik
                        initialValues={this.state}
                        validationSchema={SigninSchema}
                        onSubmit={this.handleSubmit}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          redirectOnAws,
                        }) => (
                          <form
                            className="formSigninNew"
                            onSubmit={handleSubmit}
                          >
                            <div className="form-group">
                              <label className="emailSigninNew" htmlFor="email">
                                Enter User Name
                              </label>
                              <input
                                className="form-control-SigninNew form-control"
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                placeholder="Enter Here"
                              />
                              {isSubmitting &&
                              this.props.login &&
                              this.props.login.error.username ? (
                                <div className="errorMssg">
                                  {this.props.login.error.username}
                                </div>
                              ) : (
                                <React.Fragment>
                                  {errors.email && (
                                    <div className="errorMssg">
                                      {errors.email}
                                    </div>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                            <div className="form-group">
                              <div
                                className="d-flex justify-content-between"
                                style={{ width: "500px" }}
                              >
                                <label
                                  className="emailSigninNew"
                                  htmlFor="password"
                                >
                                  Enter Password
                                </label>
                              </div>
                              <input
                                className="form-control-SigninNew form-control"
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Enter Here"
                                data-testid="password"
                              />

                              {errors.password ? (
                                <div className="errorMssg">
                                  <img
                                    src={error}
                                    className="img-fluid"
                                    alt="errorImg"
                                  />{" "}
                                  {errors?.password}
                                </div>
                              ) : (
                                <React.Fragment>
                                  {isSubmitting &&
                                    this.props.login &&
                                    this.props.login?.error?.password && (
                                      <div className="errorMssg">
                                        {this.props.login?.error?.password}
                                      </div>
                                    )}
                                </React.Fragment>
                              )}
                            </div>

                            {/* <p className="forgotPassword">
                              <Link to="/auth/forgotpassword">
                                Forgot Password?
                              </Link>
                            </p> */}
                            <div className="loginBtnSec">
                              <button type="submit" className="btn loginBtn">
                                Login
                              </button>

                              <h5
                                style={{ textAlign: "center" }}
                                className="mt-2"
                              >
                                ----OR----
                              </h5>

                              <a
                                className="btn loginBtn"
                                href={URL_CONFIG?.AWS_COGNITO_LOGIN_URL}
                              >
                                Login with Aws Cognito{" "}
                              </a>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        ) : (
          <Container fluid className="loginScreen">
            <div className="loginHeader">
              <img src={logo} className="img-fluid" alt="DigicoalLogo" />
              {/* <Button className="secondaryBtn">About Us</Button> */}
            </div>
            <div className="loginContent">
              <Row className="align-items-center">
                <Col md={6} className="loginLeftSec">
                  <div className="loginImg">
                    <img src={loginImg} alt="login-img" />
                  </div>
                </Col>

                <Col md={6} className="">
                  <div className="loginFormSec">
                    <div className="loginForms">
                      <div className="loginIntro">
                        <h5 className="loginIntroTxt">Welcome to,</h5>
                        <h5 className="loginIntroTxt">Digicoal</h5>
                      </div>
                      <Formik
                        validationSchema={SigninSchema}
                        onSubmit={this.handleSubmit}
                        initialValues={this.state}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          redirectOnAws,
                        }) => (
                          <form
                            className="formSigninNew"
                            onSubmit={handleSubmit}
                          >
                            <div className="form-group">
                              <label className="emailSigninNew" htmlFor="email">
                                Enter User Name
                              </label>
                              <input
                                className="form-control-SigninNew form-control"
                                placeholder="Enter Here"
                                type="email"
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={handleChange}
                                // autocomplete="off"
                                // onCut={this.handleCutCopyPaste}
                                // onCopy={this.handleCutCopyPaste}
                                // onPaste={this.handleCutCopyPaste}
                              />
                              {isSubmitting &&
                              this.state.formSubmit &&
                              this.props.login &&
                              this.props.login.error.username ? (
                                <div className="errorMssg">
                                  {this.props.login.error.username}
                                </div>
                              ) : (
                                <React.Fragment>
                                  {errors?.email && (
                                    <div className="errorMssg">
                                      {this.state.formSubmit === 1
                                        ? errors?.email
                                        : ""}
                                    </div>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                            <div className="form-group">
                              <div
                                style={{ width: "500px" }}
                                className="d-flex justify-content-between"
                              >
                                <label
                                  className="emailSigninNew"
                                  htmlFor="password"
                                >
                                  Enter Password
                                </label>
                              </div>
                              <input
                                className="form-control-SigninNew form-control"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Enter Here"
                                data-testid="password"
                                // autocomplete="off"
                                type="password"
                              />

                              {errors.password ? (
                                <div className="errorMssg">
                                  {this.state.formSubmit === 1 ? (
                                    <img
                                      src={error}
                                      alt="errorImg"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <></>
                                  )}{" "}
                                  {this.state.formSubmit === 1
                                    ? errors.password
                                    : ""}
                                </div>
                              ) : (
                                <React.Fragment>
                                  {isSubmitting &&
                                    this.props.login &&
                                    this.props.login.error.password && (
                                      <div className="errorMssg">
                                        {this.state.formSubmit === 1
                                          ? this.props.login.error.password
                                          : ""}
                                      </div>
                                    )}
                                </React.Fragment>
                              )}
                            </div>

                            <p className="forgotPassword">
                              <Link to="/auth/forgotpassword">
                                Forgot Password?
                              </Link>
                            </p>
                            <div className="loginBtnSec">
                              <button className="btn loginBtn" type="submit">
                                Login
                              </button>

                              <h5
                                style={{ textAlign: "center" }}
                                className="mt-2"
                              >
                                ----OR----
                              </h5>

                              <a
                                href={URL_CONFIG?.AWS_COGNITO_LOGIN_URL}
                                className="btn loginBtn"
                              >
                                Login with Aws Cognito{" "}
                              </a>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginWithEmailAndPassword: PropTypes.func.isRequired,
    user: state.user,
    login: state.login,
  };
};

export default connect(mapStateToProps, {
  loginWithEmailAndPassword,
})(Login);
