import React, { useState, useEffect, useCallback, useRef } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import toastr from "toastr";
import {
  designationListApi,
  listWithCode,
  masterListApis,
  reasonListApis,
  reasonListColumnFields,
  reasonListOtherApis,
  supplierListApis,
  violationsLeadingListApis,
  workshopIdListColumnFields,
} from "./DATA";
import { useLocation, useNavigate } from "react-router-dom";
import { tabUrl2 } from "../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import { permissionCheck } from "../../../_services/Auth/helper";
import EmptyDataList from "../ContractorPerformance/EmptyDataList";
import {
  getAllMasterDropdownDesignation,
  getAllMasterDropdownReson,
  getAllMasterDropdownSupplier,
  getAllWorkshopMasters,
} from "../../../services/_reasonList/reasonList";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData as incidentSidebarData } from "../../SafetyManagementSystem/IncidentManagement/DATA";
import { SidebarData, isCodeApplied } from "../DATA";
import { getAllMasterDropdownViolationsLeading } from "../../../services/_safetyManagement/incidentServices";
import { FuelSidebarData } from "../../FuelManagementSystem/FuelManagement/DATA";
import { changeStatus } from "../../../_services/CommonService/CommonService";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";

const ReasonList = ({ flag }) => {
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl2.contractorPerformance)
  );
  let showCode = true;
  showCode =
    usrl.pathname.includes("/violations-leading") ||
    usrl.pathname.includes("/designation");

  let navigate = useNavigate();
  const cref = useRef(null);
  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: `masterDropdowns`,
        id: id,
        status: status,
      };
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);
  const getAll = async () => {
    const response = !flag
      ? usrl.pathname.includes("/workshop-master")
        ? await getAllWorkshopMasters()
        : usrl.pathname.includes("/supplier")
        ? await getAllMasterDropdownSupplier()
        : usrl.pathname.includes("/designation")
        ? await getAllMasterDropdownDesignation()
        : await getAllMasterDropdownReson()
      : await getAllMasterDropdownViolationsLeading();
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const onEdit = (id) => {
    if (flag)
      navigate(
        "/safety-management-system/configuration/violations-leading/edit/" + id
      );
    else if (usrl.pathname.includes("/workshop-master"))
      navigate("/fuel-management-system/configuration/workshop-master/" + id);
    else if (usrl.pathname.includes("/supplier"))
      navigate("/fuel-management-system/configuration/supplier/" + id);
    else if (usrl.pathname.includes("/designation"))
      navigate(
        "/safety-management-system/configuration/designation/edit/" + id
      );
    else if (usrl.pathname.includes("/other-reason-list"))
      navigate(
        "/contractor-management/configuration/other-reason-list/edit/" + id
      );
    else
      navigate("/contractor-management/configuration/reason-list/edit/" + id);
  };

  const finalListWithCode = [
    ...listWithCode,
    {
      name: "Status",
      selector: "status",
      cell: (row) => {
        return (
          (row.status === 1 && (
            <span
              href="#"
              className="icons text-success pointer"
              onClick={(e) => activeInactive(e, row.id, row.status, "status")}
            >
              <FaToggleOn
                size="20"
                data-testid={"toogle-user-list-on-" + row.id}
              />
            </span>
          )) ||
          (row.status === 0 && (
            <span
              href="#"
              className="icons text-danger pointer"
              onClick={(e) => activeInactive(e, row.id, row.status, "status")}
            >
              <FaToggleOff
                size="20"
                data-testid={"toogle-user-list-off-" + row.id}
              />
            </span>
          ))
        );
      },
    },
  ];

  const finalReasonListColumnFields = [
    ...reasonListColumnFields,
    {
      name: "Status",
      selector: "status",
      cell: (row) => {
        return (
          (row.status === 1 && (
            <span
              href="#"
              className="icons text-success pointer"
              onClick={(e) => activeInactive(e, row.id, row.status, "status")}
            >
              <FaToggleOn
                size="20"
                data-testid={"toogle-user-list-on-" + row.id}
              />
            </span>
          )) ||
          (row.status === 0 && (
            <span
              href="#"
              className="icons text-danger pointer"
              onClick={(e) => activeInactive(e, row.id, row.status, "status")}
            >
              <FaToggleOff
                size="20"
                data-testid={"toogle-user-list-off-" + row.id}
              />
            </span>
          ))
        );
      },
    },
  ];

  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });

    getAll();
  }, []);
  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar
          SidebarData={
            flag || usrl.pathname.includes("/designation")
              ? incidentSidebarData
              : usrl.pathname.includes("/workshop-master") ||
                usrl.pathname.includes("/supplier")
              ? FuelSidebarData
              : SidebarData
          }
        />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  title="Data"
                  customTableTitle={
                    flag
                      ? "Incident/Accident Causes"
                      : usrl.pathname.includes("/workshop-master")
                      ? "Workshop List"
                      : usrl.pathname.includes("/supplier")
                      ? "Supplier List"
                      : usrl.pathname.includes("/designation")
                      ? "Designation List"
                      : usrl.pathname.includes("/other-reason-list")
                      ? "Other than contractor reason  list"
                      : "Contractor Reason List"
                  }
                  // viewTitle={"Contractor Performance Details"}
                  // hideSearch={true}
                  editFunButton={onEdit}
                  apis={
                    flag
                      ? violationsLeadingListApis
                      : usrl.pathname.includes("/workshop-master")
                      ? masterListApis
                      : usrl.pathname.includes("/supplier")
                      ? supplierListApis
                      : usrl.pathname.includes("/designation")
                      ? designationListApi
                      : usrl.pathname.includes("/other-reason-list")
                      ? reasonListOtherApis
                      : reasonListApis
                  }
                  // viewAsPopup={viewAsPopup}
                  fields={
                    showCode ? finalListWithCode : finalReasonListColumnFields
                  }
                  hideSnColumn
                  pageLink={`${
                    flag
                      ? "/safety-management-system/configuration/violations-leading"
                      : usrl.pathname.includes("/workshop-master")
                      ? "/fuel-management-system/configuration/workshop-master"
                      : usrl.pathname.includes("/supplier")
                      ? "/fuel-management-system/configuration/supplier"
                      : usrl.pathname.includes("/designation")
                      ? "/safety-management-system/configuration/designation"
                      : usrl.pathname.includes("/other-reason-list")
                      ? "/contractor-management/configuration/other-reason-list"
                      : "/contractor-management/configuration/reason-list"
                  }`}
                  pagination
                  cref={cref}
                  hideDeleteButton
                  hideEditButton
                  listname={"reasonlist"}
                  primaryKey="id"
                  breadCrumb={
                    flag
                      ? [
                          {
                            path: "#",
                            name: "Safety Management",
                          },
                          {
                            name: "Configuration",
                            path: "/safety-management-system/configuration",
                          },
                          {
                            path: "#",
                            name: "Incident/Accident Causes",
                          },
                        ]
                      : usrl.pathname.includes("/workshop-master") ||
                        usrl.pathname.includes("/supplier")
                      ? [
                          {
                            name: "Fuel Management",
                            path: "#",
                          },
                          {
                            name: "Configuration",
                            path: "/fuel-management-system/configuration",
                          },
                          {
                            name: usrl.pathname.includes("/supplier")
                              ? "Supplier List"
                              : "Workshop List",
                            path: "#",
                          },
                        ]
                      : usrl.pathname.includes("/designation")
                      ? [
                          {
                            path: "#",
                            name: "Safety Management",
                          },
                          {
                            name: "Configuration",
                            path: "/safety-management-system/configuration",
                          },
                          {
                            path: "#",
                            name: "Designation",
                          },
                        ]
                      : usrl.pathname.includes("/other-reason-list")
                      ? [
                          {
                            name: "Contractor Management",
                            path: "#",
                          },
                          {
                            path: "/contractor-management/configuration",
                            name: "Configuration",
                          },
                          {
                            path: "#",
                            name: "Other than contractor reason  list",
                          },
                        ]
                      : [
                          {
                            name: "Contractor Management",
                            path: "#",
                          },
                          {
                            path: "/contractor-management/configuration",
                            name: "Configuration",
                          },
                          {
                            path: "#",
                            name: "Contractor Reason List",
                          },
                        ]
                  }
                  isNoTabOnPage
                  deleteFun
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar
          SidebarData={
            flag || usrl.pathname.includes("/designation")
              ? incidentSidebarData
              : usrl.pathname.includes("/workshop-master") ||
                usrl.pathname.includes("/supplier")
              ? FuelSidebarData
              : SidebarData
          }
        />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              hideAddBtn={
                !permissionCheck([
                  "contractor_module_admin",
                  "super_admin",
                  "safety_admin",
                  "fuel_admin",
                ])
              }
              pageLink={
                flag
                  ? "/safety-management-system/configuration/violations-leading"
                  : usrl.pathname.includes("/workshop-master")
                  ? "/fuel-management-system/configuration/workshop-master"
                  : usrl.pathname.includes("/supplier")
                  ? "/fuel-management-system/configuration/supplier"
                  : usrl.pathname.includes("/designation")
                  ? "/safety-management-system/configuration/designation"
                  : usrl.pathname.includes("/other-reason-list")
                  ? "/contractor-management/configuration/other-reason-list"
                  : "/contractor-management/configuration/reason-list"
              }
              breadCrumb={
                flag
                  ? [
                      {
                        name: "Safety Management",
                        path: "#",
                      },
                      {
                        name: "Configuration",
                        path: "/safety-management-system/configuration",
                      },
                      {
                        name: "Incident/Accident Causes",
                        path: "#",
                      },
                    ]
                  : usrl.pathname.includes("/workshop-master") ||
                    usrl.pathname.includes("/supplier")
                  ? [
                      {
                        name: "Fuel Management",
                        path: "#",
                      },
                      {
                        path: "/fuel-management-system/configuration",
                        name: "Configuration",
                      },
                      {
                        name: usrl.pathname.includes("/supplier")
                          ? "Supplier List"
                          : "Workshop List",
                        path: "#",
                      },
                    ]
                  : usrl.pathname.includes("/designation")
                  ? [
                      {
                        name: "Safety Management",
                        path: "#",
                      },
                      {
                        name: "Configuration",
                        path: "/safety-management-system/configuration",
                      },
                      {
                        name: "Designation",
                        path: "#",
                      },
                    ]
                  : usrl.pathname.includes("/other-reason-list")
                  ? [
                      {
                        name: "Contractor Management",
                        path: "#",
                      },
                      {
                        name: "Configuration",
                        path: "/contractor-management/configuration",
                      },
                      {
                        name: "Other Reason List",
                        path: "#",
                      },
                    ]
                  : [
                      {
                        name: "Contractor Management",
                        path: "#",
                      },
                      {
                        name: "Configuration",
                        path: "/contractor-management/configuration",
                      },
                      {
                        name: "Contractor Reason List",
                        path: "#",
                      },
                    ]
              }
              title={
                flag
                  ? "Incident/Accident Causes"
                  : usrl.pathname.includes("/workshop-master")
                  ? "Workshop"
                  : usrl.pathname.includes("/supplier")
                  ? "Supplier"
                  : usrl.pathname.includes("/designation")
                  ? "Designation"
                  : usrl.pathname.includes("/other-reason-list")
                  ? "Other Reason"
                  : "Contractor Reason"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReasonList;
