import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import CustomRadio from "../../../../../components/_form/CustomRadio/CustomRadio";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import Select from "../../../../../components/Select/Select";
import ReportButton from "../ReportButton/ReportButton";
import Asterik from "../../../../../components/Common/Asterik/Asterik";

const VehicleDetailsTab = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  vehicleDetails,
  vehicleDetailsErrors,
  onChangeVehicleDetails,
  deleteRowVehicleDetails,
  addRowVehicleDetails,
  tab,
  asterikRules,
}) => {
  return (
    <>
      <div className="FormWrapper">
        <Form>
          <Row>
            <Col lg={4}>
              <CustomRadio
                type="checkbox"
                name="isVehicleIncident"
                onChange={onChange}
                title={"Vehicles Involved"}
                acceptTitle={"Yes"}
                rejectTitle={"No"}
                customClass={"customRadioClass"}
                value={
                  form.isVehicleIncident === null
                    ? false
                    : form.isVehicleIncident
                }
              />
              <span className="small text-danger">
                {error?.isVehicleIncident}
              </span>
            </Col>
            {form.isVehicleIncident && (
              <Col md="12">
                <div className="formWrapper rootCause">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="tableHead-bg">
                        <tr>
                          <th>S.No.</th>
                          <th>
                            Vehicle No.
                            <Asterik
                              fieldName={"number"}
                              requiredRules={asterikRules}
                            />
                          </th>
                          <th style={{ minWidth: "150px" }}>Vehicle Type</th>
                          <th>Vehicle Owner</th>
                          <th>
                            Driver Name
                            <Asterik
                              fieldName={"driverName"}
                              requiredRules={asterikRules}
                            />
                          </th>
                          <th>
                            Observation
                            <Asterik
                              fieldName={"observation"}
                              requiredRules={asterikRules}
                            />
                          </th>
                          <th style={{ minWidth: "150px" }}>
                            Insurance Status
                          </th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vehicleDetails.length ? (
                          vehicleDetails.map((x, index) => {
                            return x.deleted ? null : (
                              <tr key={index}>
                                <th>{index + 1}</th>
                                <td>
                                  <input
                                    value={x.number}
                                    onChange={({ target: { name, value } }) =>
                                      onChangeVehicleDetails(index, name, value)
                                    }
                                    name="number"
                                    data-testid="vehicle-number-input"
                                    type="text"
                                    className="form-control"
                                  />
                                  <span className="small text-danger">
                                    {vehicleDetailsErrors[index]?.number}
                                  </span>
                                </td>
                                <td>
                                  <Select
                                    value={x.type}
                                    onChange={({ target: { name, value } }) =>
                                      onChangeVehicleDetails(index, name, value)
                                    }
                                    name="type"
                                    // className="form-control"
                                    options={[
                                      "LMV",
                                      "Buyers Truck",
                                      "Dumper",
                                      "Disel Tanker",
                                      "Troller",
                                      "Crane",
                                      "Tractor",
                                      "Two-wheeler",
                                      "Truck",
                                      "Explosive Van",
                                      "Others",
                                    ]}
                                    init="none"
                                    array="single"
                                  ></Select>
                                  <span className="small text-danger">
                                    {vehicleDetailsErrors[index]?.type}
                                  </span>
                                </td>
                                <td>
                                  <Select
                                    value={x.owner}
                                    onChange={({ target: { name, value } }) =>
                                      onChangeVehicleDetails(index, name, value)
                                    }
                                    name="owner"
                                    // className="form-control"
                                    options={[
                                      "Company",
                                      "Contractor",
                                      "Others",
                                    ]}
                                    init="none"
                                    array="single"
                                  ></Select>
                                </td>
                                <td>
                                  <input
                                    value={x.driverName}
                                    onChange={({ target: { name, value } }) =>
                                      onChangeVehicleDetails(index, name, value)
                                    }
                                    name="driverName"
                                    data-testid="driver-name-input"
                                    type="text"
                                    className="form-control"
                                  />
                                  <span className="small text-danger">
                                    {vehicleDetailsErrors[index]?.driverName}
                                  </span>
                                </td>
                                <td>
                                  <input
                                    value={x.observation}
                                    onChange={({ target: { name, value } }) =>
                                      onChangeVehicleDetails(index, name, value)
                                    }
                                    name="observation"
                                    data-testid="observation-input"
                                    type="text"
                                    className="form-control"
                                  />
                                  <span className="small text-danger">
                                    {vehicleDetailsErrors[index]?.observation}
                                  </span>
                                </td>
                                <td>
                                  <Select
                                    value={x.insuranceStatus}
                                    onChange={({ target: { name, value } }) =>
                                      onChangeVehicleDetails(index, name, value)
                                    }
                                    name="insuranceStatus"
                                    // className="form-control"
                                    options={[
                                      "Insured",
                                      "Not Insured",
                                      "Not Applicable",
                                      "Not Known",
                                    ]}
                                    init="none"
                                    array="single"
                                  ></Select>
                                </td>
                                <td className="text-center">
                                  <div className="actionIconsGroup">
                                    <span
                                      data-testid={"remove-leaf" + index}
                                      onClick={() =>
                                        deleteRowVehicleDetails(index)
                                      }
                                      className="icons font-21 text-danger"
                                    >
                                      <AiOutlineMinusCircle
                                        size="24"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" align="center">
                              {/* <div className="alert alert-danger d-inline-block m-0">
                                                      <strong>Empty</strong>
                                                  </div> */}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="10">
                            <span
                              className="addTxt"
                              onClick={addRowVehicleDetails}
                              data-testid="add-leaf"
                            >
                              <AiOutlinePlus />
                              Add
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </div>
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default VehicleDetailsTab;
