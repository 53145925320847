import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";

const FuelLocationView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">
              {+form?.isBowser ? "Bowser Details" : "HEMM Details"}
            </p>
            <Row>
              <Col sm={6}>
                <label> {+form?.isBowser ? "Bowser Id" : "HEMM Id"} </label>
                <p className="content">{form?.hemm_id}</p>
              </Col>
              <Col sm={6}>
                <label>Mine</label>
                <p className="content">{form?.site?.name}</p>
              </Col>
              {form?.machine_serial && (
                <Col sm={6}>
                  <label>Machine Serial Number</label>
                  <p className="content">{form?.machine_serial}</p>
                </Col>
              )}
              <Col sm={6}>
                <label>
                  {+form?.isBowser ? "Bowser Type" : "FMS Vehicle Type"}
                </label>
                <p className="content">{form?.fms_vehicle_type}</p>
              </Col>
              <Col sm={6}>
                <label>
                  {+form?.isBowser ? "Bowser Name" : "FMS Vehicle Name"}
                </label>
                <p className="content">{form?.fms_vehicle_name}</p>
              </Col>
              <Col sm={6}>
                <label>Make</label>
                <p className="content">{form?.make}</p>
              </Col>
              <Col sm={6}>
                <label>Model</label>
                <p className="content">{form?.model}</p>
              </Col>
              <Col sm={6}>
                <label> Tank Capacity</label>
                <p className="content">{form?.capacity}</p>
              </Col>
              <Col sm={6}>
                <label>Load Capacity</label>
                <p className="content">{form?.loadCapacity}</p>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default FuelLocationView;
