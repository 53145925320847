import React, { useState, useEffect, useMemo, useCallback } from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { FaArrowRight } from "react-icons/fa";
import "./../SafetyManagementSystem/HelpManual/helpManual.scss";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "./DATA";
import HelpManualForm from "./HelpManualFrom";
import axios from "axios";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import { useNavigate } from "react-router-dom";
import PopUpFile from "../../components/PopUpFile/PopUpFile";
import HelpManualPopup from "./HelpManualPopup";

const HelpManualView = ({ isSafetyDocuments = false, SidebarMenu }) => {
  const [activeKey, setActiveKey] = useState();
  const [data, setData] = useState({});

  // let data = [

  //   {
  //     title: "Contractor Management",
  //     forAdmins: 0,
  //     src: ["contractor_hindrance", "contractor_performance", "contractor_score"],
  //     subList:["Contractor Hindrance","Contractor Performance","Contractor Score"]
  //   },
  //   {
  //     title: "Fuel Management",
  //     forAdmins: 0,
  //     src:[ "fuel_performance"],
  //     subList:["Fuel Management"]

  //   },

  //   {
  //     title: "Safety Management",
  //     forAdmins: 0,
  //     src: ["safety_management"],
  //     subList:["Safety Management"]

  //   },
  //   {
  //     title: "Coal Grade",
  //     forAdmins: 0,
  //     src: ["coal_grade"],
  //     subList:["Coal Grade"]

  //   },
  //      {
  //     title: "Environment Management",
  //     forAdmins: 0,
  //     src: ["env_management"],
  //     subList:["Environment Management"]

  //   },
  // ];
  // useEffect(() => {
  //   axios.get("/HelpManualUpload.json")
  //     .then(response => {
  //       console.log(response.data.data);
  //       setData(Object.values(response.data.data))
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }, []);

  // useEffect(()=>{
  //   getData();

  // },[])

  const navigate = useNavigate();
  const viewAsPopup = (form) => {
    // return <HelpManualForm parsedData={form} isEdit={true} />;
    return <HelpManualPopup form={form} />;
  };

  const fields = [
    {
      name: "Module",
      selector: "module",
      cell: (row) => row?.module,
    },
    {
      name: "Title",
      selector: "label",
      cell: (row) => row?.label,
    },
    {
      name: "File Name",
      selector: "document_name",
      cell: (row) => row?.help_manual_document_data?.real_document_name,
    },
    // {
    //   name: "URL",
    //   selector: "url",
    //   cell: (row) => row?.url
    // },
    // Add more fields here if needed
  ];
  const link = isSafetyDocuments
    ? "/safety-management-system/configuration/upload-files"
    : "/super-admin/help-manual";

  const onEdit = (id) => {
    navigate(link + "/" + id);
  };
  const getData = async (isType) => {
    // let url = "environment-management/uploaded-files/upload/" + id;
    let url = "/master-data/get-all/help-manual-master";
    try {
      const response = await RestMethod.GET(url);
      const { message, status, data } = response.data;
      // console.log(data.helpManualData);
      return {
        message,
        status,
        data:
          data?.helpManualData?.filter((d) =>
            isType
              ? d?.module === "Safety Document"
              : d?.module !== "Safety Document"
          ) || [],
      };
    } catch (error) {
      console.log("error detected while sending data from api", error);
      return {
        data: [],
      };
    }
  };
  const deleteHelpManual = async (id) => {
    let url = "/master-data/help-manual-master/delete/" + id;

    try {
      const response = await RestMethod.DELETE(url);
      return response?.data;
    } catch (error) {
      console.log("error detected while fetching data from api", error);
      return {
        data: [],
      };
    }
  };

  const userListApis = Object.freeze({
    getAll: {
      api: () => getData(isSafetyDocuments),
    },
    delete: {
      api: deleteHelpManual,
    },
  });

  const finalField = useMemo(() => {
    return isSafetyDocuments
      ? fields?.filter((d) => d.selector !== "module")
      : fields?.filter((d) => d.selector !== "document_name");
  }, [fields, isSafetyDocuments]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarMenu ? SidebarMenu : SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={finalField}
                customTableTitle={
                  isSafetyDocuments
                    ? "Document Upload List"
                    : "Help Manual Upload List"
                }
                hideSearch={true}
                editTitle={"Edit"}
                title={isSafetyDocuments ? "Document" : "Help-Manual"}
                viewTitle={
                  isSafetyDocuments ? "Document Preview" : "Help-Manual Preview"
                }
                apis={userListApis}
                pageLink={link}
                pagination={false}
                viewAsPopup={viewAsPopup}
                tableTitle={"User Manual"}
                // customTableTitle={"Preview"}
                editFunButton={onEdit}
                primaryKey="id"
                icons
                hideEditIcon
                hideDeleteButton
                updatePageLink
                deleteFun
                isNoTabOnPage
                breadCrumb={
                  isSafetyDocuments
                    ? [
                        {
                          name: "Safety Management",
                          path: "#",
                        },
                        {
                          name: "Configuration",
                          path: "/safety-management-system/configuration",
                        },
                        {
                          name: "Upload Document",
                          path: "#",
                        },
                      ]
                    : null
                }
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default HelpManualView;
