import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  getAllContractor,
  getContractorById,
  getNitList,
  performanceSuggestion,
} from "../../../services/_contractorPerformance/contractorPerformance";
import { sections } from "../../ContractorManagement/ContractorPerformance/DATA";
import {
  getContractorScoreById,
  createContractorScore,
  updateContractorScore,
} from "../../../services/_contractorScore/contractorScore";
import StatusPopup from "../../../components/MessagePopup/StatusPopup";
import SafetySideBar from "../../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import toastr from "toastr";
import FormInputText from "../../../components/FormInputText/FormInputText";
import { initialForm, validationRules, vendorScoreDataFields } from "./DATA";
import CustomYearMonth from "../../../components/DateTimeCustom/CustomYearMonth";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Select from "../../../components/Select/Select";
import { useForm } from "../../../_helpers/hooks";
import { Validation } from "../../../_helpers/validation/Validation";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import PerfectScrollbar from "react-perfect-scrollbar";
import { SidebarData } from "../../../_pages/ContractorManagement/DATA";
import {
  DDMMYYYYFormat,
  checkMonthYearIsBetween,
} from "../../../_helpers/dateHelper";
import { capitalizeName, isDecimal } from "../../../_helpers/helper";
import { checkContractStatus } from "../../../_helpers/helper";
import { BsFillInfoCircleFill } from "react-icons/bs";
import CustomPopover from "../../../components/Common/CustomPopover";
import { getAllPoList } from "../../../services/_poList/poList";
import { findElementByArray } from "../../../_helpers/commonHelper";
const ContractorScoreAdd = () => {
  const [form, setForm] = useState(initialForm);
  const [error, setError] = useState();
  const [contractorList, setContractorList] = useState([]);
  const [contractorData, setContractorData] = useState("");
  const [contractorStatus, setContractorStatus] = useState("");
  const [contractorDataNit, setContractorDataNit] = useState("");
  const [nitList, setNitList] = useState([]);
  const [poList, setPoList] = useState([]);
  const [avgScore, setAvgScore] = useState();
  const [allNit, setAllNit] = useState([]);
  const [allContractorData, setAllContractorData] = useState([]);
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [showStatusPopUp, setShowStatusPopUp] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const param = useParams();
  const navigate = useNavigate();
  const url = useLocation();
  const onDraft = () => {
    form.status = "draft";
    onSubmitForm();
  };
  const onSubmit = () => {
    form.status = "Submitted";
    onSubmitForm();
  };

  const onChangeNit = (e, idx) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onChangeForm = (e, idx) => {
    const { name, value } = e.target;

    if (vendorScoreDataFields.includes(name)) {
      if (!isNaN(value) && value >= 0 && value <= 50) {
        // If value is within the range, update the state with the entered value
        setForm({ ...form, [name]: value });
        setError(null); // Clear any previous error
        return;
      } else {
        // If value is not within the range, replace it with 0
        setForm({ ...form, [name]: 0 });
        setError("Please enter a number between 0 and 50");
        return;
      }
    }

    setForm({ ...form, [name]: value });
  };

  const onChangeContractorId = (e, idx) => {
    const { name, value } = e.target;
    name === "contractorId" && setNitList([]);
    const nit_id = name === "contractorId" ? "" : form?.nit_id;
    setForm({ ...form, [name]: value, nit_id });
  };

  const onSubmitForm = async () => {
    let error = Validation(form, validationRules);
    setError(error);
    if (Object.values(error).filter((d) => d !== "")?.length) return;
    let response;
    if (param?.id) {
      response = await updateContractorScore(param?.id, form);
    } else {
      response = await createContractorScore(form);
    }
    if (!response?.data?.status) {
      toastr.error(response?.data?.message || "something went wrong");
      return;
    } else {
      toastr.success(response?.message || "successfully added");
      navigate(`/contractor-management/my-contractor-score`);
    }
  };

  const getAll = async () => {
    const allContractorList = await getAllContractor();
    if (Array.isArray(allContractorList)) {
      const uniqueContractorList = [];
      const addedContractorIds = [];
      allContractorList
        ?.filter((ele) => ele?.contractor?.status)
        ?.forEach((d) => {
          if (!addedContractorIds.includes(d?.contractor?.id)) {
            uniqueContractorList.push({
              value: d?.contractor?.id,
              label: d?.contractor?.contractor,
            });
            addedContractorIds.push(d.contractor?.id);
          }
        });
      setContractorList(uniqueContractorList);
      setAllContractorData(allContractorList);
    }
  };

  const getAllNitList = async (contractor_id) => {
    const allNitList = await getNitList(contractor_id);
    if (allNitList?.status) {
      setNitList(
        allNitList?.data
          ?.filter((ele) => ele?.status)
          ?.map((d) => {
            return {
              value: d.id,
              label: `${d.nit}`,

              endDate: d.endDate && DDMMYYYYFormat(d.endDate),
              startDate: d.startDate && DDMMYYYYFormat(d.startDate),
            };
          })
      );
      setAllNit(allNitList?.data);
    }
  };

  const getPoList = async () => {
    let res = await getAllPoList();
    let poData = res.data.map((d) => {
      return {
        nit_id: d?.nit_id,
        id: d?.id,
        label: d?.po,
        nit_label: d?.nitNumber?.nit,
      };
    });
    setPoList(poData);
  };

  useEffect(() => {
    getAll();
    getPoList();
  }, []);
  async function fetchData() {
    if (!param?.id) return;
    let response = await getContractorScoreById(param?.id);
    if (response?.status) {
      setForm(() => {
        return {
          contractorId: response?.data?.contractorData.id,
          siteId: response?.data?.contractorData.site_id,
          avgScore: response?.data?.avgScore,
          date: response?.data?.date,
          status: response?.data?.status,
          nit_id: response?.data?.nit?.id,
          benchData: response?.data?.benchScore,
          benchScore: response?.data?.benchScore,
          biometricData: response?.data?.biometricData,
          biometricScore: response?.data?.biometricScore,
          distributionSafetyGadgetData:
            response?.data?.distributionSafetyGadgetData,
          distributionSafetyGadgetScore:
            response?.data?.distributionSafetyGadgetScore,
          dustSuppressionData: response?.data?.dustSuppressionData,
          dustSuppressionScore: response?.data?.dustSuppressionScore,
          environmentData: response?.data?.environmentData,
          environmentScore: response?.data?.environmentScore,
          equipmentRunningData: response?.data?.equipmentRunningData,
          equipmentRunningScore: response?.data?.equipmentRunningScore,
          equipmentRollData: response?.data?.equipmentRollData,
          equipmentRollScore: response?.data?.equipmentRollScore,
          fireProtectionData: response?.data?.fireProtectionData,
          fireProtectionScore: response?.data?.fireProtectionScore,
          haulRoadData: response?.data?.haulRoadData,
          haulRoadScore: response?.data?.haulRoadScore,
          imePmeData: response?.data?.imePmeData,
          imePmeScore: response?.data?.imePmeScore,
          paymentClipData: response?.data?.paymentClipData,
          paymentClipScore: response?.data?.paymentClipScore,
          safetyFeatureData: response?.data?.safetyFeatureData,
          safetyFeatureScore: response?.data?.safetyFeatureScore,
          vtcCertificateData: response?.data?.vtcCertificateData,
          vtcCertificateScore: response?.data?.vtcCertificateScore,
          illuminationScore: response?.data?.illuminationScore,
          illuminationData: response?.data?.illuminationData,
          po: response?.data?.po_id,
        };
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, [param.id, setForm]);

  const getContractor = async () => {
    let contractor = await getContractorById(form?.contractorId);
    if (contractor?.status) {
      setContractorData(contractor?.data);
    }
  };

  useEffect(() => {
    if (form?.contractorId) {
      getContractor();
      getAllNitList(form?.contractorId);
    }
  }, [form?.contractorId]);

  useEffect(() => {
    form.benchScore = form.benchData / 10;
    form.biometricScore = form.biometricData / 10;
    form.distributionSafetyGadgetScore = form.distributionSafetyGadgetData / 10;
    form.dustSuppressionScore = form.dustSuppressionData / 10;
    form.environmentScore = form.environmentData / 10;
    form.equipmentRunningScore = form.equipmentRunningData / 10;
    form.equipmentRollScore = form.equipmentRollData / 10;
    form.fireProtectionScore = form.fireProtectionData / 10;
    form.haulRoadScore = form.haulRoadData / 10;
    form.imePmeScore = form.imePmeData / 10;
    form.paymentClipScore = form.paymentClipData / 10;
    form.safetyFeatureScore = form.safetyFeatureData / 10;
    form.vtcCertificateScore = form.vtcCertificateData / 10;
    form.illuminationScore = form.illuminationData / 10;

    form.avgScore =
      (form.benchScore +
        form.biometricScore +
        form.distributionSafetyGadgetScore +
        form.dustSuppressionScore +
        form.environmentScore +
        form.equipmentRunningScore +
        form.equipmentRollScore +
        form.fireProtectionScore +
        form.haulRoadScore +
        form.imePmeScore +
        form.paymentClipScore +
        form.safetyFeatureScore +
        form.illuminationScore +
        isDecimal(form.vtcCertificateScore)) /
      14;
    setAvgScore(isDecimal(form?.avgScore));
  }, [form]);

  useEffect(() => {
    if (form.nit_id) {
      let site = allNit?.find((obj) => obj.id === form.nit_id);
      form.siteId = site?.site_id;
      setContractorDataNit({
        new_nit_number: site?.nit,
        new_tender_id: site?.tender_id,
        nit_subsidiary: site?.siteData?.name,
        nit_mine: site?.siteData?.parentSite?.name,
        section: site?.sectionData,
        po: site?.poDetails?.po || "",
      });
    }
  }, [allNit, form]);

  const getSuggestionData = async () => {
    let query = `contractorId=${form.contractorId}&nit_id=${form.nit_id}&date=${form.date}`;
    let response = await performanceSuggestion(query);
    if (response.status)
      setForm((prev) => {
        return {
          ...prev,
          quantityData: response?.data?.data?.quantityData,
          availabilityData: response?.data?.data?.availabilityData,
          utilizationData: response?.data?.data?.utilizationData,
          attributableToContractorData:
            response?.data?.data?.attributableToContractorData,
        };
      });
    else
      setForm((prev) => {
        return {
          ...prev,
          quantityData: "",
          availabilityData: "",
          utilizationData: "",
          attributableToContractorData: "",
          hseObservationData: "",
          hseObservationScore: "",
        };
      });
  };
  useEffect(() => {
    if (url.pathname === "/contractor-management/contractor-score/create") {
      if (!form.contractorId || !form.nit_id || !form.date) {
        setForm((prev) => {
          return {
            ...prev,
            quantityData: "",
            availabilityData: "",
            utilizationData: "",
            attributableToContractorData: "",
          };
        });
        return;
      } else getSuggestionData();
    }
  }, [form.contractorId, form.nit_id, form.date]);
  useEffect(() => {
    if (!form?.nit_id) return;
    setContractorStatus(checkContractStatus(nitList, form?.nit_id));
  }, [form.nit_id]);

  useEffect(() => {
    let nit = [];
    if (form.date) {
      nit = nitList.filter((d) => {
        return d.value === form.nit_id;
      });
      let dateCheck = checkMonthYearIsBetween(
        form.date && DDMMYYYYFormat(form.date),
        nit[0]?.startDate,
        nit[0]?.endDate
      );

      if (!dateCheck && nit[0]?.startDate && nit[0]?.endDate) {
        setShowStatusPopUp(true);
        setInvalidData(true);
      }
    }
  }, [form.date]);

  const closeInvalidPopUp = () => {
    setShowStatusPopUp(false);
    form.date = "";
    setInvalidData(false);
  };
  const hideStatusPopup = () => {
    setShowStatusPopUp(false);
    setMessagePopupType(false);
  };
  const closeStatusPopup = () => {
    setShowMessagePopUp(false);
    setShowStatusPopUp(false);
    setMessagePopupType(false);
  };
  useEffect(() => {
    if (form.nit_id === "" || form.nit_id === null)
      setForm((prev) => ({ ...prev, po: "" }));
  }, [form.nit_id]);
  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              className=""
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Contractor Management",
                    path: "/",
                  },
                  {
                    name: "Contractor Score",
                    path: "/contractor-management/contractorscore-management",
                  },
                  {
                    name: "Add Contractor Score",
                    path: "#",
                  },
                ]}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              Create Contractor Score
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <Form>
                  <Row>
                    <Col md={3}>
                      <Select
                        onChange={onChangeContractorId}
                        value={form?.contractorId}
                        name="contractorId"
                        options={contractorList}
                        label={"Contractor"}
                        error={error?.contractorId}
                        disabled={!!param.id}
                      />
                    </Col>
                    <Col md={3}>
                      <Select
                        onChange={onChangeNit}
                        value={form?.nit_id}
                        name="nit_id"
                        options={nitList}
                        label={"NIT"}
                        error={error?.nit_id}
                        disabled={!!param.id}
                      />
                    </Col>
                    <Col md={3}>
                      <Select
                        onChange={onChangeNit}
                        value={form?.po}
                        name="po"
                        options={poList.filter(
                          (d) => d.nit_id === form?.nit_id
                        )}
                        label={"PO"}
                        KEY_value={"id"}
                        error={error?.po}
                        disabled={!!param.id}
                      />
                    </Col>
                    <Col md={3} className="">
                      <Form.Label className="form-label-input">
                        Month
                      </Form.Label>
                      <CustomYearMonth
                        value={form?.date}
                        name="date"
                        onChange={onChangeForm}
                        error={error?.date}
                      />
                    </Col>
                  </Row>
                  {contractorData && (
                    <div className="contractorForm">
                      <strong className="mb-0">Contractor Details</strong>
                      <Row>
                        {form?.contractorId && (
                          <>
                            <Col>
                              <label className="form-label-input">
                                Contractor Code
                              </label>
                              <p className="content">
                                {contractorData?.contractor_code}
                              </p>
                            </Col>
                            <Col>
                              <label className="form-label-input">
                                Contractor
                              </label>
                              <p className="content">
                                {contractorData?.contractor}
                              </p>
                            </Col>

                            <Col>
                              <label className="form-label-input">
                                Contractor Person
                              </label>
                              <p className="content">
                                {contractorData?.contractor_person}
                              </p>
                            </Col>
                          </>
                        )}
                        {form?.nit_id && (
                          <>
                            <Col>
                              <label className="form-label-input">NIT/PO</label>
                              <p className="content">
                                {`${
                                  contractorDataNit?.new_nit_number
                                } / ${findElementByArray(
                                  poList,
                                  form?.po,
                                  "id",
                                  "label"
                                )} `}
                              </p>
                            </Col>

                            <Col>
                              <label className="form-label-input">
                                Subsidiary
                              </label>
                              <p className="content">
                                {contractorDataNit?.nit_subsidiary}
                              </p>
                            </Col>
                            <Col>
                              <label className="form-label-input">Mine</label>
                              <p className="content">
                                {contractorDataNit?.nit_mine}
                              </p>
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  )}
                  {form?.nit_id && (
                    <span className="text-danger">{contractorStatus}</span>
                  )}
                  <>
                    {/* <h4 className="font18 mt-3">Equipment (Avg)</h4> */}
                    <div className="reportformCardContent">
                      <div style={{ width: "47%" }}>
                        <table className="table reportTble">
                          <thead>
                            <tr>
                              <th style={{ width: "60%" }}>Target</th>
                              <th style={{ width: "20%" }}>
                                Data{" "}
                                <CustomPopover
                                  // click
                                  placement={"top"}
                                  tooltip={
                                    <span>
                                      It should be between 0 to 50 on the basis
                                      of contractor performance. 50 means
                                      highest. Values lower than 0 or higher
                                      than 50 are not accepted.
                                    </span>
                                  }
                                >
                                  <BsFillInfoCircleFill />
                                </CustomPopover>
                              </th>
                              <th style={{ width: "20%" }}>Score</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>
                                <label>Bench Condition</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"benchData"}
                                  onChange={onChangeForm}
                                  value={form?.benchData}
                                  type="number"
                                  error={error?.benchData}
                                  minLength={0}
                                  maxLength={50}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"benchScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form?.benchData &&
                                    (form?.benchData / 10).toFixed(1) +
                                      ` ( ${form?.benchData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.benchScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>Biometric Attendance</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"biometricData"}
                                  onChange={onChangeForm}
                                  value={form?.biometricData}
                                  type="number"
                                  error={error?.biometricData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"biometricScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form?.biometricData &&
                                    (form?.biometricData / 10).toFixed(1) +
                                      ` ( ${form?.biometricData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.biometricScore}
                                  minLength={0}
                                  maxLength={100}
                                  disabled
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>Distribution of Safety Gadget</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"distributionSafetyGadgetData"}
                                  onChange={onChangeForm}
                                  value={form?.distributionSafetyGadgetData}
                                  type="number"
                                  error={error?.distributionSafetyGadgetData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"distributionSafetyGadgetScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form?.distributionSafetyGadgetData &&
                                    (
                                      form?.distributionSafetyGadgetData / 10
                                    ).toFixed(1) +
                                      ` ( ${
                                        form?.distributionSafetyGadgetData * 2
                                      }% )`
                                  }
                                  type="text"
                                  error={error?.distributionSafetyGadgetScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>Dust Suppression Measures</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"dustSuppressionData"}
                                  onChange={onChangeForm}
                                  value={form?.dustSuppressionData}
                                  type="number"
                                  error={error?.dustSuppressionData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"dustSuppressionScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form.dustSuppressionData &&
                                    (form?.dustSuppressionData / 10).toFixed(
                                      1
                                    ) + ` ( ${form?.dustSuppressionData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.dustSuppressionScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>Environment Compliance</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"environmentData"}
                                  onChange={onChangeForm}
                                  value={form?.environmentData}
                                  type="number"
                                  error={error?.environmentData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"environmentScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form?.environmentData &&
                                    (form?.environmentData / 10).toFixed(1) +
                                      ` ( ${form?.environmentData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.environmentScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <label>Equipment in Running Order</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"equipmentRunningData"}
                                  onChange={onChangeForm}
                                  value={form?.equipmentRunningData}
                                  type="number"
                                  error={error?.equipmentRunningData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"equipmentRunningScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form?.equipmentRunningData &&
                                    (form?.equipmentRunningData / 10).toFixed(
                                      1
                                    ) +
                                      ` ( ${form?.equipmentRunningData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.equipmentRunningScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>Equipment on Roll</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"equipmentRollData"}
                                  onChange={onChangeForm}
                                  value={form?.equipmentRollData}
                                  type="number"
                                  error={error?.equipmentRollData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"equipmentRollScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form?.equipmentRollData &&
                                    (form?.equipmentRollData / 10).toFixed(1) +
                                      ` ( ${form?.equipmentRollData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.equipmentRollScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div style={{ width: "47%" }}>
                        <table className="table reportTble">
                          <thead>
                            <tr>
                              <th style={{ width: "60%" }}>Target</th>
                              <th style={{ width: "20%" }}>
                                Data{" "}
                                <CustomPopover
                                  // click
                                  placement={"top"}
                                  tooltip={
                                    <span>
                                      It should be between 0 to 50 on the basis
                                      of contractor performance. 50 means
                                      highest. Values lower than 0 or higher
                                      than 50 are not accepted.
                                    </span>
                                  }
                                >
                                  <BsFillInfoCircleFill />
                                </CustomPopover>
                              </th>
                              <th style={{ width: "20%" }}>Score</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>
                                <label>Fire Protection System</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"fireProtectionData"}
                                  onChange={onChangeForm}
                                  value={form?.fireProtectionData}
                                  type="number"
                                  error={error?.fireProtectionData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"fireProtectionScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form?.fireProtectionData &&
                                    (form?.fireProtectionData / 10).toFixed(1) +
                                      ` ( ${form?.fireProtectionData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.fireProtectionScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>Haul Road Parameters</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"haulRoadData"}
                                  onChange={onChangeForm}
                                  value={form?.haulRoadData}
                                  type="number"
                                  error={error?.haulRoadData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"haulRoadScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form?.haulRoadData &&
                                    (form?.haulRoadData / 10).toFixed(1) +
                                      ` ( ${form?.haulRoadData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.haulRoadScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>IME/PME Status</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"imePmeData"}
                                  onChange={onChangeForm}
                                  value={form?.imePmeData}
                                  type="number"
                                  error={error?.imePmeData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"imePmeScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form.imePmeData &&
                                    (form?.imePmeData / 10).toFixed(1) +
                                      ` ( ${form?.imePmeData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.imePmeScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>Payment through Clip </label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"paymentClipData"}
                                  onChange={onChangeForm}
                                  value={form?.paymentClipData}
                                  type="number"
                                  error={error?.paymentClipData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"paymentClipScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form.paymentClipData &&
                                    (form?.paymentClipData / 10).toFixed(1) +
                                      ` ( ${form?.paymentClipData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.paymentClipScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>Safety Feature in Equipment</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"safetyFeatureData"}
                                  onChange={onChangeForm}
                                  value={form?.safetyFeatureData}
                                  type="number"
                                  error={error?.safetyFeatureData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"safetyFeatureScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form.safetyFeatureData &&
                                    (form?.safetyFeatureData / 10).toFixed(1) +
                                      ` ( ${form?.safetyFeatureData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.safetyFeatureScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>VTC Certificate Status</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"vtcCertificateData"}
                                  onChange={onChangeForm}
                                  value={form?.vtcCertificateData}
                                  type="number"
                                  error={error?.vtcCertificateData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"vtcCertificateScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form.vtcCertificateData &&
                                    (form?.vtcCertificateData / 10).toFixed(1) +
                                      ` ( ${form?.vtcCertificateData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.vtcCertificateScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>

                            <tr>
                              <th>
                                <label>Illumination</label>
                              </th>
                              <td>
                                <FormInputText
                                  name={"illuminationData"}
                                  onChange={onChangeForm}
                                  value={form?.illuminationData}
                                  type="number"
                                  error={error?.illuminationData}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                />
                              </td>
                              <td>
                                <FormInputText
                                  name={"illuminationScore"}
                                  onChange={onChangeForm}
                                  value={
                                    form.illuminationData &&
                                    (form?.illuminationData / 10).toFixed(1) +
                                      ` ( ${form?.illuminationData * 2}% )`
                                  }
                                  type="text"
                                  error={error?.illuminationScore}
                                  minLength={0}
                                  maxLength={100}
                                  formGroupClass={"mb-0"}
                                  disabled
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                  <Row>
                    <Col md={8} className="text-center mt-3">
                      <Form.Label>
                        <h4>Composite Vendor score</h4>
                      </Form.Label>
                    </Col>
                    <Col md={4} className="mt-3">
                      {/* <Form.Label>Average of above score</Form.Label> */}
                      <FormInputText
                        name={"avgScore"}
                        // onChange={onChange}
                        value={
                          (form.benchScore ||
                            form.biometricScore ||
                            form.distributionSafetyGadgetScore ||
                            form.dustSuppressionScore ||
                            form.environmentScore ||
                            form.equipmentRunningScore ||
                            form.equipmentRollScore ||
                            form.fireProtectionScore ||
                            form.haulRoadScore ||
                            form.imePmeScore ||
                            form.paymentClipScore ||
                            form.safetyFeatureScore ||
                            form.illuminationScore ||
                            form.vtcCertificateScore) &&
                          avgScore
                        }
                        type="number"
                        placeholder="Average of above score"
                        disabled
                      />
                    </Col>
                  </Row>
                </Form>

                <div className="btnSec">
                  <Button
                    className="secondaryBtn"
                    onClick={() =>
                      navigate(
                        `/contractor-management/contractorscore-management`
                      )
                    }
                  >
                    Back
                  </Button>
                  <div className="d-flex">
                    <Button
                      name="save"
                      onClick={() => onDraft()}
                      data-testid="submit-scoreSave"
                      className="primaryBtn"
                    >
                      Save
                    </Button>
                  </div>
                  <div>
                    <Button
                      name="submit"
                      onClick={() => onSubmit()}
                      data-testid="submit-scoreSubmit"
                      className="primaryBtn"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
            <StatusPopup
              messagePopupType={messagePopupType}
              show={showStatusPopUp}
              setShow={hideStatusPopup}
              closePopup={closeStatusPopup}
              invalidData={invalidData}
              invalidDate={true}
              closeInvalidPopUp={closeInvalidPopUp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorScoreAdd;
