import React, { useCallback, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import CheckBoxField from "../../../components/_form/CheckBoxField/CheckBoxField";
import { useForm } from "../../../_helpers/hooks";
import {
  MODULE_OBJECT_VIEW,
  COAL_GRADE_VIEW,
  ROLE_OBJECT_VIEW,
} from "../../../_services/Auth/helper";
import { UserService } from "../../../_services/UserService/UserService";
import toastr from "toastr";
import {
  permissionInitialForm,
  userInitialForm,
} from "../../SafetyManagementSystem/IncidentManagement/UserManagement/DATA";

const PermissionsFormEdit = ({ formData, setShowAddEdit, getAll }) => {
  const [permissionForm, onChangePermission, setPermissionForm] = useForm({
    ...permissionInitialForm,
  });
  const [form, onChange, setForm] = useForm({ ...userInitialForm });

  const userById = useCallback(async () => {
    const response = await UserService.getUserByID(formData?.id);
    if (!response?.status) return;
    setForm((prev) => ({
      ...prev,
      ...response?.data,
    }));
    setPermissionForm((prev) => ({
      ...prev,
      ...response?.data?.userPermission,
    }));
  }, [formData?.id, setForm, setPermissionForm]);

  const handleSubmit = async () => {
    const formObj = { ...form, userPermission: permissionForm };

    const response = await UserService.updateUser(formData?.id, formObj);

    if (!response?.status) return toastr.error(response?.message);
    toastr.success(response?.message);
    setShowAddEdit(false);
    getAll();
  };

  useEffect(() => {
    if (!formData?.id) return;
    userById();
  }, [formData?.id, userById]);

  return (
    <>
      <Modal.Body>
        <div className="preview py-5">
          <div className="">
            <Form>
              <Row>
                {Object.keys(COAL_GRADE_VIEW)?.map((module, idx) => {
                  const roleData = Object.keys(COAL_GRADE_VIEW[module])
                    ?.filter((d) => form?.roleId === 4 && d !== "contractor")
                    ?.map((role, i) => {
                      return (
                        <Col key={i} md={6}>
                          <CheckBoxField
                            id={`${idx}check${i}`}
                            name={role}
                            checked={permissionForm?.[role]}
                            onChange={onChangePermission}
                            disabled={
                              form?.roleId === 4 && role === "contractor"
                            }
                            label={ROLE_OBJECT_VIEW?.[role]}
                          />
                        </Col>
                      );
                    });
                  return (
                    <>
                      <h6 key={idx} className="mt-2">
                        {MODULE_OBJECT_VIEW[module]}
                      </h6>{" "}
                      {roleData}
                    </>
                  );
                })}
              </Row>
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button className="primaryBtn" onClick={() => handleSubmit()}>
          Save
        </Button>
      </Modal.Footer>
    </>
  );
};

export default PermissionsFormEdit;
