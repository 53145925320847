import React from "react";
import ReactEcharts from "echarts-for-react";
import NoRecord from "../../Common/NoRecord";

class BasicBarChartWithCustomToolTipYaxis extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.seriesData !== this.props.seriesData) {
      // console.log("forceUpdate");
      this.forceUpdate();
    }
  }

  render() {
    let {
      category,
      seriesData,
      xAxis,
      yAxis,
      xAxisName,
      yAxisName,
      customToolTip,
      legendFlag,
    } = this.props;

    // console.log("seriesData", seriesData);
    let echartMultipleBar2Option = {
      tooltip: {
        formatter: (params) => {
          if (
            customToolTip &&
            customToolTip[params?.name] &&
            customToolTip[params?.name].length > 0
          ) {
            return `${params?.marker}${"&nbsp"}${
              customToolTip[params?.name][0]?.fullName
            } : ${params?.value}<br/>`;
          }
          return ""; // Return an empty string if the customToolTip data is not available
        },
      },
      xAxis: xAxis
        ? xAxis
        : {
            name: xAxisName ? xAxisName : "",
            nameLocation: "end",
            type: "value",
            min: 0, // minimum value to start
            minInterval: 1, // for integer axis value
            nameTextStyle: {
              fontWeight: "bold",
            },
          },
      yAxis: yAxis
        ? {
            ...yAxis,
            nameTextStyle: {
              color: "white",
            },
          }
        : {
            name: yAxisName ? yAxisName : "",
            nameLocation: "end",
            nameRotate: 90,
            nameTextStyle: {
              fontWeight: "bold",
              color: "white",
            },
            type: "category",
            data: category ? category : [],
          },
      legend:
        legendFlag == false
          ? false
          : {
              type: "scroll",
              // horizontalAlign: 'center',
              itemGap: 20,
              to: 100,
            },
      series: seriesData ? seriesData : [],
      grid: [
        {
          top: 10,
          right: 15,
        },
      ],
      dataZoom: [
        {
          start: 0,
          type: "inside",
        },
        {
          start: 0,
        },
      ],
    };

    let name =
      echartMultipleBar2Option.yAxis &&
      Object.keys(echartMultipleBar2Option.yAxis).length > 0 &&
      echartMultipleBar2Option.yAxis.name
        ? echartMultipleBar2Option.yAxis.name
        : null;
    if (this.props.isLegeng) {
      echartMultipleBar2Option.legend = this.props.isLegeng;
    }
    if (this.props.grid) {
      echartMultipleBar2Option.grid = this.props.grid;
    }

    return (
      <React.Fragment>
        {Array.isArray(seriesData) && seriesData.length > 0 ? (
          <>
            <ReactEcharts
              style={{
                height: this.props.height ? this.props.height : "270px",
              }}
              option={echartMultipleBar2Option}
            />
            <div className="text-center" style={{ marginTop: -30 }}>
              <span
                style={{ textTransform: "capitalize" }}
                className="font-weight-bold"
              >
                {name}
              </span>
            </div>
          </>
        ) : (
          <NoRecord />
        )}
      </React.Fragment>
    );
  }
}

export default BasicBarChartWithCustomToolTipYaxis;
