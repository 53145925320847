import React from "react";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { ConfigurationNewTabs, ConfigurationTabs, SidebarData } from "../DATA";
import ManageCardTab from "../../../../components/Common/ManageCardTab/ManageCardTab";
import { isNclHoUser } from "../../../../_services/CommonService/CommonService";

const EnvNewConfiguration = () => {
  let isNclHoUserFlag = isNclHoUser();
  console.log("ConfigurationNewTabsConfigurationNewTabs", ConfigurationNewTabs);
  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <ManageCardTab
          breadCrumbData={[
            {
              name: "Environment Management",
              path: "#",
            },
            {
              name: "Configuration",
              path: "#",
            },
          ]}
          flowList={
            isNclHoUserFlag
              ? ConfigurationNewTabs
              : ConfigurationNewTabs.filter((d) => d.label !== "Device")
          }
        />
      </div>
    </>
  );
};

export default EnvNewConfiguration;
