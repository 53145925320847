import React from "react";
import ReactEcharts from "echarts-for-react";
import { IS_MOBILE, decimalPointNumber } from "../../../_helpers/helper";
import NoRecord from "../../Common/NoRecord";

class EchartPieChart extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      isClickAble,
      childfun,
      category,
      seriesName,
      tooltip,
      seriesData,
      xAxisName,
      yAxisName,
      labelFlag,
      customLabel,
      customLegend,
      pieChartColour,
      height,
      customTitle,
    } = this.props;
    if (Array.isArray(seriesData) && seriesData.length > 0) {
      seriesData = seriesData.map((data) => {
        if (data.value) {
          data.value = decimalPointNumber(data.value, 2);
        }
        return data;
      });
    }

    const pieColor = [
      "#28b873",
      "#f5cb39",
      "#e7404a",
      "#25cde8",
      "#E3701A",
      "#8B7500",
      "#CDC9A5",
      "#3237cb",
      "#d31bdf",
      "#3300FF",
      "#FF0066",
      "#DE8DF3",
      "#FF4500",
      "#7F00FF",
      "#5C5C9A",
      "#71A1E6",
    ];

    const echartBasicPie = {
      tooltip: tooltip ? tooltip : false,
      color: pieChartColour ? [...pieChartColour] : [...pieColor],
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      legend: customLegend ? customLegend : false,
      xAxis: [
        {
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],

      series: [
        {
          name: seriesName ? seriesName : "value",
          type: "pie",
          radius: "75%",
          center: ["50%", "50%"],
          data: seriesData ? seriesData : [],
          bottom: 20,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              formatter: "{b} \n{c} ({d}%)",
            },
          },
          label: customLabel
            ? customLabel
            : {
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: "13",
                    fontWeight: "bold",
                  },
                  formatter: "{b} \n{c} ({d}%)",
                },
              },
        },
      ],
    };

    customTitle &&
      Object.assign(echartBasicPie, {
        title: {
          subtext: customTitle,
          left: "left",
        },
      });

    return (
      <React.Fragment>
        {Array.isArray(seriesData) && seriesData.length > 0 ? (
          <ReactEcharts
            //style={{ height: height ? height : "250px" }}
            style={{
              height: IS_MOBILE() ? "200px" : height ? height : "250px",
            }}
            option={echartBasicPie}
            onEvents={{ click: (e) => (isClickAble ? childfun(e) : "") }}
          />
        ) : (
          <NoRecord />
        )}
      </React.Fragment>
    );
  }
}

export default EchartPieChart;
