import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import "./Dashboard.css";
import {
  CGDistributionPageChartList,
  CHART_TYPE_BY_DATA,
  PAGE_TYPE_BY_CHART_FILTER,
  PAGE_TYPE_BY_CHART_LIST,
  Tabs,
} from "./DATA";
import { Card, Col, Row } from "react-bootstrap";
import PieChartWithModal from "../../../components/PieChartWithModal/PieChartWithModal";
import StackWithLineChart from "../../../components/Common/StackWithLineChart";
import { SimpleBarChart } from "../../../components/Common/SimpleBarChart";
import {
  coalGradeDispatchDistribution,
  coalGradeSingleChart,
} from "../../../services/_coalGrade/coalGrade";
import Select from "../../../components/Select/Select";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import { getUrlKeyValue, minMaxDate } from "../../../_helpers/commonHelper";

const CGDispatchDistribution = ({
  onChange,
  cref,
  form,
  pageType,
  appliedFilters,
  customerList = [],
  topSelectedTab,
  setMinMaxDate,
}) => {
  const [chartData, setChartData] = useState({});
  const [chartFilterForm, setChartFilterForm] = useState(
    PAGE_TYPE_BY_CHART_FILTER(+pageType)
  );
  const [actionChartName, setActionChartName] = useState("");
  const [stackChartType, setStackChartType] = useState("customer");
  const [optionId, setOptionId] = useState([]);
  const [singleChartDate, setSingleChartDate] = useState({});
  const [samplingAgency, setSamplingAgency] = useState([]);
  const singleDashboardFun = useCallback(
    async (filter) => {
      if (!actionChartName) return;
      let response = await coalGradeSingleChart(`${+pageType}`, filter);
      if (!response?.status) return;
      const chartTypeObj =
        PAGE_TYPE_BY_CHART_LIST(+pageType)?.find(
          (obj) => obj?.value === actionChartName
        ) || {};
      setChartData((prev) => ({
        ...prev,
        [actionChartName]: CHART_TYPE_BY_DATA(
          chartTypeObj?.type,
          response?.data
        ),
      }));
      setSingleChartDate(response.date);
    },
    [actionChartName, pageType]
  );

  const fetchData = useCallback(
    async (appliedFilters, isClickApply) => {
      if (isClickApply) {
        setChartFilterForm(PAGE_TYPE_BY_CHART_FILTER(+pageType));
        setActionChartName("");
      }
      appliedFilters = appliedFilters || "";
      let response = await coalGradeDispatchDistribution(appliedFilters);
      if (!response?.status) {
        setChartData({});
        return;
      }
      function extractNumber(name) {
        return parseInt(name.match(/\d+/)[0]);
      }
      response.data.barChart.sort(
        (a, b) => extractNumber(a.name) - extractNumber(b.name)
      );
      const resData = response.data;
      if (stackChartType === "customer") {
        const buyerIds = response?.data?.stackChart?.map(
          (item) => item.buyerId
        );
        setOptionId(buyerIds);
      }
      if (stackChartType === "samplingAgency") {
        const buyerIds = response?.data?.stackChart?.map(
          (item) => item.buyerId
        );
        setOptionId(buyerIds);
        const transformedArray = response?.data?.samplingData.map((item) => ({
          value: item.id.toString(),
          label: item.name,
        }));
        setSamplingAgency(transformedArray);
      }
      setMinMaxDate(resData?.minMax_date || {});
      CGDistributionPageChartList?.forEach((item) => {
        setChartData((prev) => ({
          ...prev,
          [item?.value]: CHART_TYPE_BY_DATA(item?.type, resData?.[item?.value]),
        }));
      });
    },
    [pageType, stackChartType]
  );

  useEffect(() => {
    if (!actionChartName) return;
    let filter = "";
    filter = `graphName=${actionChartName}&graphKey=${
      chartFilterForm[actionChartName]
    }${appliedFilters || ""}&tab=${topSelectedTab}&type=${stackChartType}`;
    singleDashboardFun(filter);
  }, [actionChartName, chartFilterForm, singleDashboardFun]);

  useEffect(() => {
    let isClickApply = true;
    let finalFltr = `${
      appliedFilters || ""
    }&tab=${topSelectedTab}&type=${stackChartType}`;
    fetchData(finalFltr, isClickApply);
  }, [fetchData, topSelectedTab, stackChartType]);

  const onChangeChart = ({ target: { name, value } }) => {
    setChartFilterForm((prev) => ({ ...prev, [name]: value }));
    setActionChartName(name);
  };

  useImperativeHandle(
    cref,
    () => {
      let finalFltr = `${appliedFilters || ""}&tab=${topSelectedTab}`;
      let isClickApply = true;
      return {
        fetchData: () => fetchData(finalFltr, isClickApply),
        setStackChartType: () => setStackChartType("customer"),
      };
    },
    [appliedFilters, topSelectedTab, fetchData]
  );

  const finalChartList = useMemo(() => {
    let filteredData = [];
    if (stackChartType === "customer") {
      filteredData = customerList.filter((item) =>
        optionId.includes(item.value)
      );
    }
    if (stackChartType === "samplingAgency") {
      filteredData = samplingAgency.filter((item) =>
        optionId.includes(String(item.value))
      );
    }
    filteredData.unshift({ value: "", label: "Select All" });
    return PAGE_TYPE_BY_CHART_LIST(pageType)?.map((d, idx) => {
      return d?.value === "stackChart" ? { ...d, options: filteredData } : d;
    });
  }, [pageType, customerList, optionId, samplingAgency]);

  useEffect(() => {
    const params = new URLSearchParams(appliedFilters);
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");
    if (startDate && endDate) setSingleChartDate({});
  }, [appliedFilters]);

  useEffect(() => {
    setSingleChartDate({});
  }, [stackChartType]);

  return (
    <div className="dashboardWrapper">
      <Row className="chart-listing" id={`${"screen" + pageType}`}>
        {finalChartList?.map((d, idx) => {
          return (
            <Col
              md={d?.type === "stack" ? 12 : 6}
              key={idx}
              id={`zoomSec_${idx}`}
            >
              <Card className="chartCard chartCardBtmSpace CAAQMS_chart">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>
                    {d?.name !== "Customer wise Distribution for Coal Grade"
                      ? d?.name
                      : `${
                          stackChartType === "customer"
                            ? "Customer"
                            : "Sampling Agency"
                        } wise Distribution for Coal Grade`}
                  </span>

                  {d?.name === "Customer wise Distribution for Coal Grade" &&
                  singleChartDate?.max &&
                  chartFilterForm.stackChart &&
                  singleChartDate?.min ? (
                    <span className="blueLabel">
                      Date Duration: (
                      {moment(singleChartDate?.min, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )}{" "}
                      -{" "}
                      {moment(singleChartDate?.max, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )}
                      )
                    </span>
                  ) : (
                    ""
                  )}

                  <div className="filterZoomSec">
                    {!d?.hideFilter && (
                      <>
                        <div className="dashboardTabs coalGradeScreenTabs ">
                          <ul className="paddingTop">
                            <li
                              className={`scoreCardDashboards ${
                                stackChartType === "customer" ? "active" : ""
                              }`}
                              onClick={() => setStackChartType("customer")}
                            >
                              Customer Wise
                            </li>
                            <li
                              className={`scoreCardDashboards ${
                                stackChartType === "samplingAgency"
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                setStackChartType("samplingAgency")
                              }
                            >
                              Sampling Agency Wise
                            </li>
                          </ul>
                        </div>
                        <Col className="mbRemove">
                          <Select
                            name={d?.value}
                            value={chartFilterForm?.[d?.value]}
                            onChange={onChangeChart}
                            options={d?.options}
                            blankSelect={"none"}
                            className={"distributionfltr"}
                          />
                        </Col>
                      </>
                    )}
                    <ZoomScreen
                      id={`zoomSec_${idx}`}
                      cardSizeClass={
                        d?.type === "stack" ? "col-md-12" : "col-md-6"
                      }
                    />
                  </div>
                </Card.Header>
                <Card.Body
                  style={{ height: d?.type === "pieVs" ? "340px" : "" }}
                  className="pb-4"
                >
                  {d?.type === "multiBar" && (
                    <SimpleBarChart
                      yAxisName={"Tonne"}
                      height={"315px"}
                      xAxis={{
                        type: "category",
                        data: chartData[d?.value]?.categoryData,
                      }}
                      yAxis={{
                        type: "value",
                        boundaryGap: [0, 0.01],
                        name: "Tonne",
                      }}
                      series={chartData[d?.value]?.data}
                      customTooltip
                    />
                  )}
                  {d?.type === "pieVs" && (
                    <PieChartWithModal
                      pieChartColour={false}
                      seriesData={
                        chartData[d?.value]?.sort((a, b) => {
                          const numA = parseInt(a?.name.substring(1), 10);
                          const numB = parseInt(b?.name.substring(1), 10);
                          return numA - numB;
                        }) || []
                      }
                      customLabelFalse={true}
                      isCustomLegend={true}
                      height={"300px"}
                      unit={" Tonne"}
                    />
                  )}
                  {d?.type === "stack" && (
                    <StackWithLineChart
                      yAxisName={"Tonne"}
                      onlyBar={true}
                      category={chartData[d?.value]?.categoryData}
                      series={chartData[d?.value]?.data || []}
                      customTooltip
                      hideXaxis={d?.hideXaxis === true ? true : false}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default CGDispatchDistribution;
