import { ForgetPasswordService } from "../../_services/ForgotPassword/ForgetPasswordService";
import FormInputText from "../../components/FormInputText/FormInputText";
import { UserService } from "../../_services/UserService/UserService";
import { Validation } from "../../_helpers/validation/Validation";
import { Card, Col, Button, Form, Row } from "react-bootstrap";
import { logout, logoutUser } from "../../services/logoutService";
import React, { useEffect, useState } from "react";
import logo from "../../Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../_helpers/hooks";
import toastr from "toastr";
import "./viewProfile.scss";

const ChangePassword = () => {
  const [userData, setUserData] = useState({});
  let navigate = useNavigate();
  const [form, onChange, setForm] = useForm({
    oldPassword: "",
    newPassword: "",
    reNewPassword: "",
  });
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
    reNewPassword: "",
  });
  const passwordRules = [
    {
      field_name: "oldPassword",
      label: "Old Password",
      //   type: "password",
      isRequired: true,
    },
    {
      field_name: "newPassword",
      label: "New Password",
      type: "password",
      isRequired: true,
    },
    {
      field_name: "reNewPassword",
      label: "Confirm New Password",
      type: "password",
      isRequired: true,
    },
  ];
  const changePassword = async () => {
    let obj = {
      oldPass: form.oldPassword,
      newPass: form.reNewPassword,
    };
    let res = await ForgetPasswordService.changePassword(obj);
    console.log("999999", res);
    if (res.status === 1) {
      toastr.success(res?.message);
      (await logoutUser(true)) && navigate("/auth/login");
    } else {
      if (res?.message === "Old Password is not correct")
        setError((prev) => ({
          ...prev,
          oldPassword: res?.message,
        }));
      toastr.error(res.message || "Something went wrong");
    }
  };
  const onSubmit = () => {
    let errorObj = Validation(form, passwordRules);
    if (form.newPassword !== form.reNewPassword) {
      errorObj["reNewPassword"] =
        "New password and confirm password should be same";
    }
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length === 0)
      changePassword();
  };


  return (
    <Card className="changeWrapper">
      <img src={logo} className="img-fluid DigicoalLogo" alt="DigicoalLogo" />
      <h3 className="changeTitle">Change Password</h3>
      <div className="row">
        <div className="col-md-12 form-group">
          <Form>
            <Row>
              <Col lg={12}>
                <FormInputText
                  name="oldPassword"
                  value={form?.oldPassword}
                  onChange={onChange}
                  type="password"
                  class="form-control"
                  placeholder=""
                  label={"Old Password"}
                  error={error?.oldPassword}
                />
              </Col>
              <Col lg={12}>
                <FormInputText
                  name="newPassword"
                  value={form?.newPassword}
                  onChange={onChange}
                  type="password"
                  class="form-control"
                  placeholder=""
                  label={"New Password"}
                  error={error?.newPassword}
                />
              </Col>
              <Col lg={12}>
                <FormInputText
                  name="reNewPassword"
                  value={form?.reNewPassword}
                  onChange={onChange}
                  type="password"
                  class="form-control"
                  placeholder=""
                  label={"Confirm New Password"}
                  error={error?.reNewPassword}
                />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="loginBtnSec d-flex justify-content-between">
          <Button
            data-testid="next-btn"
            onClick={() => navigate(`/`)}
            className="secondaryBtn iconBtnForm"
          >
            Back
          </Button>
          <Button
            data-testid="next-btn-submit"
            onClick={() => onSubmit()}
            className="primaryBtn iconBtnForm"
          >
            Update Password
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ChangePassword;
