import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import { DDMMYYYYFormat } from "../../_helpers/dateHelper";
import moment from "moment";
import "./CustomDate.css";
import InputError from "../_form/InputError/InputError";

function CustomYearMonth({ name, value, error, onChange }) {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (value) {
      setSelectedDate(moment(value, "YYYY-MM"));
    } else {
      setSelectedDate(null);
    }
  }, [value]);

  const handleDateChange = (date) => {
    if (onChange) {
      const formattedDate = date instanceof moment ? date.format("YYYY-MM") : "";
      setSelectedDate(date);
      onChange({
        target: {
          name: name,
          value: formattedDate,
        },
      });
    }
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const disableDatesAfterToday = (currentDate) => {
    return currentDate.isBefore(moment(), "day");
  };

  return (
    <div>
      <Datetime
        name={name}
        value={selectedDate}
        onChange={handleDateChange}
        dateFormat="YYYY-MM"
        timeFormat={false}
        isValidDate={disableDatesAfterToday}
        renderYear={(props, year) => (
          <td
            {...props}
            className={currentYear >= year ? "valid" : "invalid"}
          >
            {year}
          </td>
        )}
        renderMonth={(props, month, year) => (
          <td
            {...props}
            className={
              currentYear > year ||
              (currentYear === year && currentMonth > month)
                ? "valid"
                : "invalid"
            }
          >
            {months[month]}
          </td>
        )}
        renderInput={(props) => {
          return <input {...props} value={value ? props.value : ""} />;
        }}
      />
      <InputError error={error} />
    </div>
  );
}

export default CustomYearMonth;
