import React, { useEffect, useRef, useState } from "react";
import "../fuelmgmt.scss";

import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useForm } from "../../../.././src/_helpers/hooks";
import FilesExport from "../../../components/Common/FilesExport";
import { initialFilterForm, inventoryFilterForm } from "./DATA";
import {
  IS_MOBILE,
  IS_USER_SITE_HO,
  IS_USER_SITE_MINES,
  auth_user,
  capitalizeName,
} from "../../../_helpers/helper";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import HsdInventoryFilter from "./HsdInventoryFilter";
import {
  SidebarData,
  Tabs,
  mobileFuelSidebarData,
  sourceOptions,
  unitOptions,
} from "../../FuelManagementSystem/FuelManagement/DATA";
import HsdInventory from "./HsdInventory";
import HsdConsumption from "./HsdConsumption";
import HsdConsumptionFilter from "./HsdConsumptionFilter";
import { getAllSites } from "../../../_services/SiteServices/SiteServices";
import {
  getAllFuelingLocation,
  getAllHemm,
  getAllTank,
  getAllTypeListFiltered,
} from "../../../services/_fuelDispense/fuelDispense";
import TabFilterButton from "../../../components/TabFilterButton/TabFilterButton";
import Filter from "../../../Assets/Images/filter.svg";
import Select from "../../../components/Select/Select";
import {
  SelectedDateLable,
  SelectedMineSubsidiary,
} from "../../SafetyManagementSystem/IncidentManagement/Dashboard/DATA";
import { getAllWorkshopMasters } from "../../../services/_reasonList/reasonList";
import { setFuelDashFilter } from "../../../redux/actions/UserActions";
import { useDispatch } from "react-redux";

const FuelManagementDashboard = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const cref = useRef(null);
  const [form, onChange, setForm] = useForm(
    +id === 1 ? initialFilterForm : inventoryFilterForm
  );
  const [filterForm, setFilterForm] = useState("");
  const [finalSiteList, setFinalSiteList] = useState([]);
  const [fuelingLocations, setFuelingLocations] = useState([]);
  const [tankOpt, setTankOpt] = useState([]);
  const [bowserOpt, setBowserOpt] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [optionsList, setOptionsList] = useState({});
  const [subsidery, setSubsidery] = useState([]);
  const [sites, setSites] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [show, setShow] = useState(false);
  const [selectForm, setSelectForm] = useState({ unit: "L", source: "fuel" });
  const [selectedTab, setSelectedTab] = useState("L");
  const [minMaxDate, setMinMaxDate] = useState({});

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSelectedTab(e?.target?.value);
    setSelectForm((prev) => {
      return { ...prev, unit: e?.target?.value };
    });
  };

  const onChangeSource = (e) => {
    setSelectForm((prev) => {
      return { ...prev, source: e?.target?.value };
    });
    dispatch(setFuelDashFilter({ source: e?.target?.value }));
    if (e?.target?.value === "sap")
      navigate(
        `${IS_MOBILE() ? "/mobile" : ""}/fuel-management-system/sap-dashboard/1`
      );
    else
      navigate(
        `${IS_MOBILE() ? "/mobile" : ""}/fuel-management-system/dashboard/1`
      );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkMineObj = () => {
    let siteDataObj = {};
    if (IS_USER_SITE_HO()) return siteDataObj;
    const siteObj = sites?.find((d) => d.id === auth_user()?.siteId) || {};
    siteDataObj = {
      siteId: IS_USER_SITE_MINES() ? siteObj?.id : "",
      subsideryId: IS_USER_SITE_MINES()
        ? siteObj?.parentSite?.id
        : auth_user()?.siteId,
    };
    return siteDataObj;
  };

  useEffect(() => {
    let filter = "type=dispense";
    getAllTypeListFiltered(filter).then((response) => {
      if (!response?.data) return;
      let optionsObj = {};
      Object.keys(response?.data)?.forEach((d) => {
        optionsObj[d] = response?.data[d]?.map((o) => ({
          value: o.name,
          label: capitalizeName(o.name),
        }));
      });
      setOptionsList(optionsObj);
    });

    getAllSites("filter[siteType]=1,2,3&filter[activeStatus]=active").then(
      (response) => {
        if (!response?.data) return;
        const siteData = response?.data?.filter((d) => d?.siteType === 3);
        let subsideryData = [];
        if (IS_USER_SITE_MINES()) {
          subsideryData = [
            {
              id: response.data?.length
                ? response.data?.[0]?.parentSite?.id
                : "",
              name: response.data?.length
                ? response.data?.[0]?.parentSite?.name
                : "",
            },
          ];
        } else {
          subsideryData = response?.data?.filter((d) => d?.siteType === 2);
        }
        setSubsidery(subsideryData);
        setFinalSiteList(siteData);
        setSites(siteData);
      }
    );
    getAllTanksData();
    getAllBowsersData();
  }, []);

  useEffect(() => {
    getAllFuelingLocation("workshop_id=" + form?.workshopId).then(
      (response) => {
        if (!response?.data) return;
        setFuelingLocations(response?.data?.fueling_location || []);
      }
    );
    getAllWorkshopMasters().then((response) => {
      if (!response?.data) return;
      const workshopOptions = response?.data?.map((d) => {
        return {
          label: d?.name,
          value: d?.id,
        };
      });
      setWorkshops(workshopOptions || []);
    });
  }, [form?.workshopId]);

  const getAllTanksData = async () => {
    let tankRes = await getAllTank();
    if (tankRes.status) {
      let options = tankRes.data
        .filter((item) => item.status === 1)
        .map((d) => {
          return {
            name: d.name,
            id: d.id,
            site: d.site_id,
          };
        });
      setTankOpt(options);
    }
  };

  const getAllBowsersData = async () => {
    let filter = "isBowser=1";
    let response = await getAllHemm(filter);
    if (response?.status) {
      let options = response?.data
        .filter((item) => item.status === 1)
        .map((d) => {
          return {
            name: d.hemm_id,
            id: d.id,
            site: d.site_id,
          };
        });
      setBowserOpt(options);
    }
  };

  useEffect(() => {
    setFinalSiteList(
      form?.workshopId
        ? sites?.filter((d) => d?.parentSiteId === +form?.subsideryId)
        : sites
    );
  }, [form?.subsideryId, sites]);

  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        ...checkMineObj(),
      };
    });
  }, [sites]);

  useEffect(() => {
    if (![1, 2]?.includes(+id)) navigate("/");
  }, [id, navigate]);

  useEffect(() => {
    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "eqptType" && element?.length) {
          filter += `&${key}=${JSON.stringify(element)}`;
        } else if (key !== "eqptType") filter += `&${key}=${element}`;
      }
    }
    setFilterForm(filter);
    isReset && setFilterReset(true);
  }, [form, isReset]);

  const resetFun = () => {
    setForm(
      +id === 1
        ? { ...initialFilterForm, ...checkMineObj() }
        : { ...inventoryFilterForm, ...checkMineObj() }
    );
    setIsReset(true);
  };

  useEffect(() => {
    if (!isReset || !filterReset) return;
    if (isReset && filterReset) cref?.current?.dashboardDataFun();
    setFilterReset(false);
    setIsReset(false);
  }, [filterReset, isReset]);

  const applyFun = () => {
    cref?.current?.dashboardDataFun();
    setShow(false);
  };

  return (
    <>
      <div
        className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
      >
        <SafetySideBar
          SidebarData={IS_MOBILE() ? mobileFuelSidebarData : SidebarData}
          moduleTitle={"Fuel Management"}
          goBack={() => navigate(-1)}
        />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <div className="dashboardTabs coalGradeScreenTabs">
              <ul>
                <li
                  onClick={() =>
                    navigate(
                      `${
                        IS_MOBILE() ? "/mobile" : ""
                      }/fuel-management-system/dashboard/1`
                    )
                  }
                  className={`${+id === 1 ? "active" : ""}`}
                >
                  HSD Consumption
                </li>
                <li
                  onClick={() =>
                    navigate(
                      `${
                        IS_MOBILE() ? "/mobile" : ""
                      }/fuel-management-system/dashboard/2`
                    )
                  }
                  className={`${+id === 2 ? "active" : ""}`}
                >
                  HSD Inventory
                </li>
              </ul>
              <div className={IS_MOBILE() ? "mt-2" : ""}>
                <SelectedDateLable form={form} minMaxDate={minMaxDate} />
              </div>
              <SelectedMineSubsidiary form={form} />

              <div className="tabsSelectsFilter">
                <div className="tabFilter">
                  <Select
                    name="source"
                    value={selectForm?.source || ""}
                    onChange={(e) => onChangeSource(e)}
                    label={"Source"}
                    options={sourceOptions}
                    KEY_label={"name"}
                    KEY_value={"id"}
                    menuPortalTarget={null}
                    blankSelect={"none"}
                  />

                  <Select
                    name="unit"
                    value={selectForm?.unit || ""}
                    onChange={(e) => handleChange(e)}
                    options={unitOptions}
                    KEY_label={"name"}
                    KEY_value={"id"}
                    // menuPortalTarget={null}
                    blankSelect={"none"}
                    label={"Unit"}
                    data-testid="Unit"
                  />

                  {!IS_MOBILE() && (
                    <FilesExport id={`#${"screen" + id}`} fullScreen />
                  )}
                </div>
                <div className="dashboardHeader">
                  <div className="dashboardOptions">
                    <div
                      className="dashboardFilter"
                      onClick={handleShow}
                      data-testid="handleShowId"
                    >
                      <img src={Filter} className="img-fluid" alt="filter" />
                    </div>
                  </div>
                </div>

                {+id === 2 && (
                  <HsdInventoryFilter
                    sites={finalSiteList}
                    fuelingLocations={fuelingLocations}
                    workshopIds={workshops}
                    subsidery={subsidery}
                    form={form}
                    onChange={onChange}
                    optionsList={optionsList}
                    resetFun={resetFun}
                    applyFun={applyFun}
                    handleClose={handleClose}
                    show={show}
                    hideInventoryFilter={true}
                    tankOpt={tankOpt}
                    bowserOpt={bowserOpt}
                  />
                )}
                {+id === 1 && (
                  <HsdConsumptionFilter
                    onChange={onChange}
                    show={show}
                    subsidery={subsidery}
                    sites={finalSiteList}
                    optionsList={optionsList}
                    hideInventoryFilter={true}
                    form={form}
                    resetFun={resetFun}
                    applyFun={applyFun}
                    handleClose={handleClose}
                  />
                )}
              </div>
            </div>
            <Container fluid>
              {+id === 1 && (
                <HsdConsumption
                  cref={cref}
                  filterForm={filterForm}
                  form={form}
                  tabValIs={selectedTab}
                  optionsList={optionsList}
                  setMinMaxDate={setMinMaxDate}
                />
              )}
              {+id === 2 && (
                <HsdInventory
                  filterForm={filterForm}
                  tabValIs={selectedTab}
                  cref={cref}
                  form={form}
                  setMinMaxDate={setMinMaxDate}
                />
              )}
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelManagementDashboard;
