import { URL_CONFIG } from "../../../../_constants/Config/URL_CONFIG";
import Sidebar from "../Sidebar";

const CGDashboard = () => {
    return (
        <div className="wrapper">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="content" id="page-content-wrapper">
                <div className="contentArea">
                    <iframe src={`${URL_CONFIG.AWS_QUICKSIGHT_URL}9936fef5-5015-416e-804a-646bcc2ad02a?directory_alias=cil-dev-si-bi`} title="Loading..." />
                </div>
            </div>
        </div>
    )
};

export default CGDashboard