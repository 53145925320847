import React, { useState } from "react";
import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { capaPriority } from "../../DATA";
import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";
import { IoIosCloseCircleOutline } from "react-icons/io";
import PopUpFile from "../../../../components/PopUpFile/PopUpFile";
import { PARSE_IMG_URL } from "../../../../_helpers/commonHelper";
import { URL_CONFIG } from "../../../../_constants/Config/URL_CONFIG";
import { IoPlayCircleOutline } from "react-icons/io5";

const CapasView = ({ form }) => {
  const [videoShowManageModal, setVideoShowManageModal] = useState(false);
  const [imageShowManageModal, setImageShowManageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  return (
    <>
      {form?.capas?.length ? (
        form?.capas?.map((obj, idx) => {
          return (
            <>
              <Row>
                <Col lg={4}>
                  <label>CAPA ID</label>
                  <p>{obj?.capaId}</p>
                </Col>
                <Col lg={4} key={"idx"}>
                  <label>CAPA Type</label>
                  <p>{obj?.capaType}</p>
                </Col>
                <Col lg={4}>
                  <label>CAPA Description</label>
                  <p>{obj?.remarks}</p>
                </Col>
                <Col lg={4}>
                  <label>CAPA Owner</label>
                  <p>{obj?.owner?.firstName + " " + obj?.owner?.lastName}</p>
                </Col>
                <Col lg={4}>
                  <label>CAPA Priority</label>
                  <p>{capaPriority[obj?.priority]}</p>
                </Col>
                <Col lg={4}>
                  <label>Due Date</label>
                  <p>{obj?.dueDate ? DDMMYYYYFormat(obj?.dueDate) : ""}</p>
                </Col>
                <Col lg={4}>
                  <label>CAPA Status</label>
                  <p>{obj?.capaStatus}</p>
                </Col>
                {obj?.capaStatus === "completed" && (
                  <>
                    <Col lg={4}>
                      <label>Completion Date</label>
                      <p>
                        {obj?.completionDate
                          ? DDMMYYYYFormat(obj?.completionDate)
                          : ""}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <label>Completion Proof</label>
                      {!obj?.completionProof ? (
                        <p>Not Available</p>
                      ) : (
                        <div className="safetyMedia">
                          <div className="safetyImgSec">
                            {obj?.completionProof?.type === "video/mp4" ? (
                              <div
                                className="videoSec"
                                onClick={() => {
                                  setVideoShowManageModal(true);
                                  setVideoUrl(
                                    PARSE_IMG_URL(
                                      URL_CONFIG.DEV_URL,
                                      obj?.completionProof?.document_name
                                    )
                                  );
                                }}
                              >
                                <PopUpFile
                                  src={PARSE_IMG_URL(
                                    URL_CONFIG.DEV_URL,
                                    obj?.completionProof?.document_name
                                  )}
                                  title={
                                    obj?.completionProof?.real_document_name
                                  }
                                  height="105"
                                  hideClass
                                />

                                <div className="svgSec">
                                  <IoPlayCircleOutline />
                                </div>
                              </div>
                            ) : (
                              <img
                                onClick={() => {
                                  setImageShowManageModal(true);
                                  setImageUrl(
                                    PARSE_IMG_URL(
                                      URL_CONFIG.DEV_URL,
                                      obj?.completionProof?.document_name
                                    )
                                  );
                                }}
                                // src={URL_CONFIG.IMG_URL + d.media.document_name}
                                src={PARSE_IMG_URL(
                                  URL_CONFIG.DEV_URL,
                                  obj?.completionProof?.document_name
                                )}
                                className="img-fluid "
                                alt="IMG"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Col>
                  </>
                )}
                {form?.capas?.length - 1 !== idx ? (
                  <div className="seperatorLine"></div>
                ) : (
                  ""
                )}
              </Row>
            </>
          );
        })
      ) : (
        <span className="text-danger">Not Applicable</span>
      )}

      <Modal
        show={imageShowManageModal}
        onHide={() => setImageShowManageModal(false)}
        size="lg"
        centered
      >
        <ModalBody className="safetyModal">
          <div
            className="closeIcon"
            data-testid="filter-button"
            onClick={() => {
              setImageShowManageModal(false);
            }}
          >
            <IoIosCloseCircleOutline />
          </div>
          <div className="popupImgVideo text-center">
            <img className="img-fluid" src={imageUrl} alt="" />
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={videoShowManageModal}
        onHide={() => setVideoShowManageModal(false)}
        size="lg"
        centered
      >
        <ModalBody>
          <div
            className="closeIcon"
            onClick={() => {
              setVideoShowManageModal(false);
            }}
          >
            <IoIosCloseCircleOutline />
          </div>
          <div className="popupImgVideo">
            <PopUpFile
              src={videoUrl}
              // title={d?.real_document_name}
              height="460"
              hideClass
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CapasView;
