import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";

export async function getAllSites(filter) {
  let url = "/site";

  if (filter) url += `?${filter}`;

  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

// export async function getAllSitesWithAccess(filter) {
//   let url = "/global-configuration/ehs-module/available-site?";

//   if (filter) url += filter;

//   try {
//     const response = await RestMethod.GET(url);
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }

// export async function createSite(data) {
//   try {
//     const response = await RestMethod.POST(
//       "/global-configuration/ehs-module/create-site",
//       data
//     );
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }

// export async function updateSite(id, data) {
//   try {
//     const response = await RestMethod.PUT(
//       "/global-configuration/ehs-module/update-site/" + id,
//       data
//     );
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }

// export async function deleteSite(id) {
//   try {
//     const response = await RestMethod.DELETE(
//       "/global-configuration/ehs-module/delete-site/" + id
//     );
//     return response.data;
//   } catch (error) {
//     console.log("error detected while fetching data from api");
//     return null;
//   }
// }

export async function getAllRoles(filter) {
  let url = "/user-management/roles";
  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
