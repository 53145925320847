import { Card, Col, Row, Table } from "react-bootstrap";
import "./Dashboard.scss";
import {
  getIncidentDashboardTbl,
  safetyDashboard2,
} from "../../../../services/_safetyManagement/safetyManagement";
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";
import {
  boxStyles,
  breakArrayIntoGroups,
  data,
  incidentSummary,
  incidentTableTypeObj,
} from "./DATA";
import { isArray } from "lodash";
import { statusList } from "../../DATA";
import TreemapChart from "../../../../components/Common/TreemapChart";
import NoRecord from "../../../../components/Common/NoRecord";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";

const IncidentDashboard2 = ({ filterForm, cref, setMinMaxDate }) => {
  const [dashboardData, setDashboardData] = useState({});
  const [rcaBrachData, setRcaBrachData] = useState([]);
  const [rcaLeafData, setRcaLeafData] = useState([]);
  const [incidentSmmryList, setIncidentSmmryList] = useState([]);
  const [investigationSmmryList, setInvestigationSmmryList] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [pageTable1, setPageTable1] = useState(1);
  const [pageTable2, setPageTable2] = useState(1);
  const tableRef1 = useRef(null);
  const tableRef2 = useRef(null);

  const dashboardDataFun = useCallback(async (filter) => {
    let response = await safetyDashboard2(filter);
    if (response && response?.status && response?.data) {
      setPageTable1(1);
      setPageTable2(1);
      const resData = response.data;
      setDashboardData(resData);
      setIncidentSmmryList(resData?.incidentData?.incident_summary || []);
      setInvestigationSmmryList(
        resData?.investigationData?.investigation_summary || []
      );
      // console.log("helloooo");
      setRcaBrachData(
        (isArray(resData?.rca_branch) &&
          breakArrayIntoGroups(resData?.rca_branch, 5)) ||
          []
      );
      setRcaLeafData(
        (isArray(resData?.rca_leaf) &&
          breakArrayIntoGroups(resData?.rca_leaf, 6)) ||
          []
      );
      setMinMaxDate(resData?.minMax_date || {});
    }
  }, []);

  useEffect(() => {
    dashboardDataFun(filterForm);
  }, [dashboardDataFun]);

  useEffect(() => {
    let filter = "";
    for (const key in filterForm) {
      const element = filterForm[key];
      if (element) {
        filter += `&${key}=${element}`;
      }
    }
    setFilterData(filter);
  }, [filterForm]);

  const incidentDashboardTblFunc = async (filter, type, setPage) => {
    const response = await getIncidentDashboardTbl(filter);
    if (!response?.status) {
      setPage((prev) => prev - 1);
      return;
    }
    const appendData = response?.data[type] || [];
    if (type === incidentTableTypeObj?.incidentSummuryData) {
      setIncidentSmmryList((prev) => [...prev, ...appendData]);
    } else {
      setInvestigationSmmryList((prev) => [...prev, ...appendData]);
    }
  };

  useImperativeHandle(
    cref,
    () => ({
      dashboardDataFun: () => dashboardDataFun(filterForm),
    }),
    [dashboardDataFun, filterForm]
  );

  let rcaBrachDataArray = [];
  let rcaLeafDataArray = [];

  rcaBrachData?.forEach((groupData, index) => {
    groupData?.map((d, idx) => {
      return rcaBrachDataArray.push({
        name: "B" + d?.branch_number + " :  " + d?.branch_count,
        value: d?.branch_count,
      });
    });
  });

  rcaLeafData?.forEach((groupData, index) => {
    groupData?.map((d, idx) => {
      return rcaLeafDataArray.push({
        name: "L" + d?.leaf_number + " :  " + d?.branch_count,
        value: d?.branch_count,
      });
    });
  });
const queryString = Object.keys(filterForm)
    .filter(key => filterForm[key] !== "") // Exclude empty values
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(filterForm[key]))
    .join("&");
  return (
    <div className="dashboardWrapper" id="screen2">
      <Row>
        <Col md={6}>
          <Card className="chartCard tableCard shadow-none">
            <Card.Header>Incident/Accident Summary</Card.Header>
            <Card.Body className="p-0">
              <DataTableComponent
                columns={incidentSummary}
                data={incidentSmmryList}
                page={pageTable1}
                setPage={setPageTable1}
                ref={tableRef1}
                tableName={incidentTableTypeObj?.incidentSummuryData}
                totalCount={dashboardData?.incidentData?.totalCount}
                matchCount={incidentSmmryList?.length}
                filterData={filterData}
                onCallScrollFunc={incidentDashboardTblFunc}
                fixedHeader={true}
                filterDataBy={`&type=incidentSummuryData&`+queryString}
                getApi={getIncidentDashboardTbl}
                dashboardType="incidentAccident"
              />
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="chartCard tableCard shadow-none">
            <Card.Header>Investigation Summary</Card.Header>
            <Card.Body className="p-0">
              <DataTableComponent
                columns={incidentSummary}
                data={investigationSmmryList}
                page={pageTable2}
                setPage={setPageTable2}
                ref={tableRef2}
                tableName={
                  incidentTableTypeObj?.incidentInvestigationSummuryData
                }
                totalCount={dashboardData?.investigationData?.totalCount}
                matchCount={investigationSmmryList?.length}
                filterData={filterData}
                onCallScrollFunc={incidentDashboardTblFunc}
                fixedHeader={true}
                filterDataBy={`&type=incidentInvestigationSummuryData&`+queryString}
                getApi={getIncidentDashboardTbl}
                dashboardType="investigation"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card className="chartCard chartCardBtmSpace">
            <Card.Header>RCA Branch</Card.Header>
            <Card.Body style={{ padding: "0" }}>
              <TreemapChart dataArray={rcaBrachDataArray} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="chartCard chartCardBtmSpace">
            <Card.Header>RCA Leaf</Card.Header>
            <Card.Body style={{ padding: "0" }}>
              <TreemapChart dataArray={rcaLeafDataArray} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default IncidentDashboard2;
