import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../SafetyManagementSystem/IncidentManagement/Dashboard/Dashboard.scss";
import FuelCard from "./components/FuelCard";
import {
  Container,
  Dropdown,
  Card,
  Col,
  Row,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import TreemapChart from "../../../components/Common/TreemapChart";
import { MultipleBarChartCard } from "../../../components/ChartCard/ChartCard";
import Select from "../../../components/Select/Select";
import { getHsdConsumptionData } from "../../../services/_fuelDispense/fuelDispense";
import {
  CONVERT_KL_L_FILTER,
  ChartFilter,
  FilterTab,
  FilterTabFuel,
  HEMMDetails,
  eqptTypeTabList,
  fuelTableTypeObj,
  getFuelTabFirstDashboardTbl,
  getFuelTabFirstDashboardTblExcel,
  headerChartFilter,
  kpiList,
  options,
  widgetData,
  widgetDataSingle,
} from "./DATA";
import { isArray } from "lodash";
import { multiLineChartDataFormat } from "../../../components/Chart/Common/config";
import { BiCalendar } from "react-icons/bi";
import { useForm } from "../../../_helpers/hooks";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import DataTableComponent from "../../../components/DataTableComponent/DataTableComponent";
import {
  IS_MOBILE,
  KL_TO_L,
  L_TO_KL,
  isDecimal,
} from "../../../_helpers/helper";
import { FaAngleDown } from "react-icons/fa";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import { AiOutlineFileExcel } from "react-icons/ai";
import MultiSelectWithCheckbox from "../../../components/SearchableSelect/MultiSelectCkeckOption";

const HsdConsumption = ({
  cref,
  form,
  optionsList = [],
  tabValIs,
  filterForm,
  setMinMaxDate,
}) => {
  const [tabValue, setTabValue] = useState("daily");
  const [fuelData, setFuelData] = useState({});
  const [headerFilter, setHeaderFilter] = useState("");
  const [finalColumns, setFinalColumns] = useState([]);
  const [activeEqptTab, setActiveEqptTab] = useState("");
  const [pageTable1, setPageTable1] = useState(1);
  const [hemmDataList, sethemmDataList] = useState([]);
  const [tabFilterOption, setTabFilterOption] = useState([]);
  const [tableType, setTableType] = useState("");
  const tableRef1 = useRef(null);
  const [filterDataBy, setFilterDataBy] = useState();
  const [headerForm, onHandleChange, setHeaderForm] = useForm({
    ...headerChartFilter,
    multiSubEqptType: null,
  });

  const TabChange = (val) => setTabValue(val);

  const fetchData = useCallback(
    async (filter) => {
      let response = await getHsdConsumptionData(filter);
      if (response?.status === 1 && response?.data) {
        setPageTable1(1);
        sethemmDataList(response?.data?.consumptionDetails || []);
        const consumptionChart = multiLineChartDataFormat(
          response.data?.capacityAcrossDaya
        );
        const FinalFilterData =
          tabValIs === "KL"
            ? CONVERT_KL_L_FILTER(
                { ...response?.data, capacityAcrossDays: consumptionChart },
                tabValIs
              )
            : {};

        if (tabValIs === "L") {
          const capacityAcrossDaysData = consumptionChart?.data?.map((ele) => {
            return {
              ...ele,
              // name: ele?.name + tabValIs,
              data: ele?.data?.map((o) => isDecimal(o)),
              name: ele?.name ? `${ele?.name?.split(" - ")[0]}` : "",
            };
          });
          FinalFilterData["capacityAcrossDays"] = {
            ...consumptionChart,
            data: capacityAcrossDaysData,
          };
        }
        setFuelData({
          ...response.data,
          ...FinalFilterData,
          // capacityAcrossDays: consumptionChart,
        });
        setMinMaxDate(response?.data?.minMax_date || {});
      }
    },
    [tabValIs]
  );

  useEffect(() => {
    let filter = "";
    for (const key in headerForm) {
      const element = headerForm[key];
      if (element) {
        filter += `&${key}=${element}`;
      }
    }
    setHeaderFilter(filter);
  }, [headerForm]);

  useEffect(() => {
    const FinalFilterData = CONVERT_KL_L_FILTER(fuelData, tabValIs);
    setFuelData((prev) => ({ ...prev, ...FinalFilterData }));
  }, [tabValIs]);

  const HEMMDetail = [
    {
      isShowMobile: true,
      name: "ID",
      selector: "hemm_id",
    },
    {
      name: "Type",
      selector: "type",
      minWidth: "130px",
    },
    {
      selector: "make",
      name: "Make",
    },
    {
      name: "Tank Capacity",
      selector: "capecity",
      cell: (row) =>
        tabValIs === "L"
          ? isDecimal(row?.capecity)
          : isDecimal(row?.capecity / 1000),
    },
    {
      name: "Load Capacity",
      selector: "loadCapacity",
    },
    {
      name: "Model",
      selector: "model",
    },
    {
      name: "Date",
      selector: "fuling_date",
      minWidth: "110px",
      cell: (row) => (row?.fuling_date ? DDMMYYYYFormat(row?.fuling_date) : ""),
    },
    {
      name: "Last Fueling Date",
      selector: "last_fuling_date",
      minWidth: "130px",
      cell: (row) =>
        row?.last_fuling_date ? DDMMYYYYFormat(row?.last_fuling_date) : "",
    },
    {
      selector: "fuling_qty",
      name: "Qty " + tabValIs,
      cell: (row) =>
        tabValIs === "L"
          ? isDecimal(row?.fuling_qty)
          : isDecimal(row?.fuling_qty / 1000),
      // cell: (row) => (row?.fuling_qty ? isDecimal(row?.fuling_qty) : ""),
      minWidth: "110px",
      isShowMobile: true,
    },
    {
      name: "Last Qty " + tabValIs,
      selector: "last_fuling_qty",
      minWidth: "140px",
      cell: (row) =>
        tabValIs === "L"
          ? isDecimal(row?.last_fuling_qty)
          : isDecimal(row?.last_fuling_qty / 1000),

      // cell: (row) =>
      //   row?.last_fuling_qty ? isDecimal(row?.last_fuling_qty) : "",
    },
    {
      selector: "sfc",
      name: "SFC (" + tabValIs + "/Hr.)",
      cell: (row) =>
        row?.sfc !== "N/A"
          ? tabValIs === "L"
            ? isDecimal(row?.sfc)
            : isDecimal(row?.sfc / 1000)
          : row?.sfc,

      minWidth: "110px",
    },
    {
      name: "Running Hrs",
      selector: "tbf",
    },
  ];

  useEffect(() => {
    if (!activeEqptTab || !headerFilter) return;
    const chartFltr = `&fuelConsumptionAcrossDays=${tabValue}`;
    const subEqptFltr = `&subEqptType=${activeEqptTab}`;
    const finalFilter = `${filterForm}${headerFilter}${chartFltr}${subEqptFltr}`;
    setFilterDataBy(finalFilter);
    fetchData(finalFilter);
  }, [activeEqptTab, fetchData, headerFilter, tabValue]);

  const fuelDashboardTblFunc = async (filter, type, setPage) => {
    const response = await getFuelTabFirstDashboardTbl(filter);

    if (!response?.status) {
      setPage((prev) => prev - 1);
      return;
    }

    if (tabValIs === "KL") {
      response.data.hemmData = response?.data?.hemmData?.map((ele) => {
        Object.assign(
          ele,
          ele?.last_fuling_qty && {
            last_fuling_qty: ele?.last_fuling_qty / 1000,
          },
          { fuling_qty: ele?.fuling_qty / 1000 },
          { sfc: ele?.sfc / 1000 }
        );
        return ele;
      });
    }

    const appendData = response?.data.hemmData || [];
    if (type === fuelTableTypeObj?.hemmData) {
      sethemmDataList((prev) => [...prev, ...appendData]);
    }
  };

  useImperativeHandle(
    cref,
    () => {
      const subEqptFltr = `&subEqptType=${activeEqptTab}`;
      const chartFltr = `&fuelConsumptionAcrossDays=${tabValue}`;
      const finalFilter = `${filterForm}${headerFilter}${chartFltr}${subEqptFltr}`;
      return {
        dashboardDataFun: () => fetchData(finalFilter),
      };
    },
    [activeEqptTab, fetchData, filterForm, headerFilter, tabValue]
  );

  useEffect(() => {
    let activeTabValue = "";
    if (form?.eqptType && form?.eqptType?.length) {
      activeTabValue = form?.eqptType[0];
    } else
      activeTabValue = optionsList?.equipment_type?.length
        ? optionsList?.equipment_type?.[0]?.value
        : "";
    setActiveEqptTab(activeTabValue);
  }, [form?.eqptType, optionsList?.equipment_type]);

  const onClickTab = (activeTab) => {
    let activeTabObj = {};
    setHeaderFilter("");
    setHeaderForm((prev) => {
      if (activeTab === "all") activeTabObj.activeTab = null;
      else activeTabObj.activeTab = prev.activeTab || "make";
      return { ...prev, ...activeTabObj, multiSubEqptType: null };
    });
    setActiveEqptTab(activeTab);
  };

  const finalTabList = useMemo(() => {
    return eqptTypeTabList(optionsList?.equipment_type, true)?.map((tabObj) => {
      const isVisibal =
        form?.eqptType && form?.eqptType?.length
          ? form?.eqptType?.includes(tabObj?.value)
          : true;
      return { ...tabObj, visibalTab: isVisibal };
    });
  }, [form?.eqptType, optionsList?.equipment_type]);

  const downloadExcelFn = async () => {
    await getFuelTabFirstDashboardTblExcel(
      `&subEqptType=${activeEqptTab}&excelDownload=true&dispensedType=${tableType}&tabValIs=${tabValIs}` +
        filterForm
    );
  };

  useEffect(() => {
    let options = fuelData.consumptionCapacity?.map((d) => ({
      name: d?.name ? `${d?.name?.split(" - ")[0]}` : "",
      label: d?.name ? `${d?.name?.split(" - ")[0]}` : "",
      value: d?.name ? `${d?.name?.split(" - ")[0]}` : "",
    }));
    setTabFilterOption(options);
  }, [fuelData.consumptionCapacity, tabValIs]);

  useEffect(() => {
    setHeaderForm((prev) => {
      return {
        ...prev,
        filterId: null,
      };
    });
  }, [headerForm?.activeTab, activeEqptTab]);
  const queryString = Object.keys(form)
    .filter((key) => form[key] !== "") // Exclude empty values
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(form[key]))
    .join("&");
  return (
    <div className="dashboardWrapper">
      <Row id="screen1">
        <Col md={12} className="">
          <div className="tabSection w-100">
            <div
              className={`${
                !IS_MOBILE() ? "d-flex" : ""
              } justify-content-between`}
            >
              {widgetData(fuelData, tabValIs).map((item, idx) => (
                <div
                  className={`dashSmallCard ${
                    !IS_MOBILE() ? "top-card" : "mb-3"
                  } `}
                >
                  <FuelCard
                    key={idx}
                    label={item.label}
                    quantity={item.quantity}
                    vertical={true}
                    additionalClass={!IS_MOBILE() && "horzText"}
                  />
                </div>
              ))}
            </div>
            <div className="mb-3">
              <CustomTabs
                hideLeftRightBtn
                fuelCustomTab
                onClickTab={onClickTab}
                noLink
                activeTab={activeEqptTab}
                tabeList={finalTabList}
              />
            </div>
          </div>

          <Card className="fuelConsumptionCards">
            <div className="secondaryFilter">
              <Row className="align-items-center">
                <Col md={10}>
                  <div className={`tab-option ${!IS_MOBILE() ? "d-flex" : ""}`}>
                    <div className={`${!IS_MOBILE() ? "d-flex" : "d-none"}`}>
                      {widgetDataSingle(fuelData, tabValIs).map((item, idx) => (
                        <div className="me-5">
                          <FuelCard
                            key={idx}
                            label={item.label}
                            quantity={item.quantity}
                            vertical={true}
                          />
                        </div>
                      ))}
                    </div>
                    {activeEqptTab !== "all" && (
                      <div
                        className={
                          !IS_MOBILE() ? "smallTabBtn" : "mobSmallTabBtn"
                        }
                      >
                        {FilterTabFuel?.map((option, i) => (
                          <ToggleButtonGroup
                            type="checkbox"
                            key={"FilterTab" + option.id}
                            onChange={() =>
                              onHandleChange({
                                target: {
                                  name: "activeTab",
                                  value: option?.value,
                                },
                              })
                            }
                            value={headerForm?.activeTab}
                          >
                            <ToggleButton
                              id={"FilterTab" + option?.id}
                              value={option?.value}
                            >
                              <span>{option?.filterBy}</span>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        ))}
                      </div>
                    )}
                  </div>
                </Col>
                <Col
                  md={2}
                  style={{
                    marginLeft: IS_MOBILE() ? "" : "-45px",
                    marginBottom: IS_MOBILE() ? "" : "14px",
                  }}
                >
                  {activeEqptTab === "all" ? (
                    <MultiSelectWithCheckbox
                      label={"All FMS Vehicle Type"}
                      className={
                        IS_MOBILE()
                          ? "multiSelectWthDashboard"
                          : "multiSelectWth"
                      }
                      options={optionsList?.equipment_type || []}
                      onChange={(selectedRoles) => {
                        onHandleChange({
                          target: {
                            name: "multiSubEqptType",
                            value: selectedRoles
                              ?.map((obj) => obj.label.replace(",", " "))
                              .join(","),
                          },
                        });
                      }}
                      value={headerForm?.multiSubEqptType
                        ?.split(",")
                        ?.map((role) => {
                          const foundObj = (
                            optionsList?.equipment_type || []
                          )?.find((obj) => obj.label === role);
                          return foundObj ? { ...foundObj } : null;
                        })
                        ?.filter((d) => !!d)}
                      disableSearch={false}
                      hasSelectAll={false}
                    />
                  ) : (
                    <MultiSelectWithCheckbox
                      label={
                        "All " +
                        FilterTabFuel.filter(
                          (d) => d?.value === headerForm?.activeTab
                        )[0]?.filterBy
                      }
                      className={
                        IS_MOBILE()
                          ? "multiSelectWthDashboard"
                          : "multiSelectWth"
                      }
                      options={tabFilterOption || []}
                      onChange={(selectedRoles) => {
                        onHandleChange({
                          target: {
                            name: "filterId",
                            value: selectedRoles
                              ?.map((obj) => obj.name.replace(",", " "))
                              .join(","),
                          },
                        });
                      }}
                      value={headerForm?.filterId
                        ?.split(",")
                        ?.map((role) => {
                          const foundObj = (tabFilterOption || [])?.find(
                            (obj) => obj.name === role
                          );
                          return foundObj ? { ...foundObj } : null;
                        })
                        ?.filter((d) => !!d)}
                      disableSearch={false}
                      hasSelectAll={false}
                    />
                  )}

                  {/* <Select
                    label={""}
                    name="filterId"
                    value={headerForm?.filterId}
                    onChange={onHandleChange}
                    options={tabFilterOption}
                    KEY_label={"name"}
                    KEY_value={"value"}
                    blankSelect={
                      "All " +
                      FilterTabFuel.filter(
                        (d) => d.value === headerForm?.activeTab
                      )[0].filterBy
                    }
                  /> */}
                  <div className={`${!IS_MOBILE() ? "d-none" : "mt-3"}`}>
                    {widgetDataSingle(fuelData, tabValIs).map((item, idx) => (
                      <FuelCard
                        key={idx}
                        label={item.label}
                        quantity={item.quantity}
                        vertical={true}
                        additionalClass={"mobBgClrTxt"}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </div>

            <Row>
              <Col md={6} id={"zoomSecFuel"}>
                <Card className="chartCard chartCardBtmSpace">
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    Fuel Dispensed{" "}
                    <ZoomScreen id={`zoomSecFuel`} cardSizeClass={"col-md-6"} />
                  </Card.Header>
                  <Card.Body>
                    <TreemapChart
                      dataArray={
                        headerForm?.filterId
                          ? fuelData?.consumptionCapacity
                              ?.filter((d) => {
                                const filterIdArray =
                                  headerForm?.filterId?.split(",");
                                return filterIdArray.includes(
                                  d?.name?.split(" - ")[0]
                                );
                              })
                              .map((d) => ({
                                ...d,
                                name: d?.name
                                  ? `${d?.name?.split(" - ")[0]} - ${isDecimal(
                                      d?.value
                                    )} ${tabValIs}`
                                  : "",
                              }))
                          : fuelData?.consumptionCapacity?.map((d) => ({
                              ...d,
                              name: d?.name
                                ? `${d?.name?.split(" - ")[0]} - ${isDecimal(
                                    d?.value
                                  )} ${tabValIs}`
                                : "",
                            }))
                      }
                      height={300}
                      sameColorGradient={true}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} id={"zoomSecFuelChart"}>
                <Card className="chartCard chartCardBtmSpace">
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <span>Fuel Dispensed Across Days</span>
                    <div className="d-flex">
                      <Dropdown className="fuelConsumptionDays me-3">
                        <Dropdown.Toggle>
                          <BiCalendar />
                        </Dropdown.Toggle>
                        {tabValue?.charAt(0)?.toUpperCase()}
                        <Dropdown.Menu>
                          <div className="tab alphabetToggle">
                            {ChartFilter.map((option, i) => (
                              <ToggleButtonGroup
                                type="checkbox"
                                key={i}
                                onChange={() => TabChange(option.value)}
                                value={tabValue}
                              >
                                <ToggleButton
                                  id={option.id}
                                  value={option.value}
                                >
                                  {option.label}
                                </ToggleButton>
                              </ToggleButtonGroup>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      <ZoomScreen
                        id={`zoomSecFuelChart`}
                        cardSizeClass={"col-md-6"}
                      />
                    </div>
                  </Card.Header>
                  <Card.Body className="pb-5">
                    <MultipleBarChartCard
                      height={294}
                      category={fuelData?.capacityAcrossDays?.categoryData}
                      seriesData={
                        isArray(fuelData?.capacityAcrossDays?.data) &&
                        headerForm?.filterId
                          ? fuelData?.capacityAcrossDays?.data
                              ?.filter((d) => {
                                const filterIdArray =
                                  headerForm?.filterId?.split(",");
                                return filterIdArray.includes(
                                  d?.name?.split(" - ")[0]
                                );
                              })
                              .map((d) => ({
                                ...d,
                              }))
                          : fuelData?.capacityAcrossDays?.data?.map((d) => ({
                              ...d,
                            }))
                      }
                      yAxisName={tabValIs}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card>

          <Row className="first-row" style={{ paddingRight: 0 }}>
            <Col>
              <Card className="chartCard tableCard">
                <Card.Header style={{ height: IS_MOBILE() ? "54px" : "44px" }}>
                  <span
                    className={`justify-content-between${
                      !IS_MOBILE() ? " d-flex" : ""
                    }`}
                  >
                    HSD Dispensing and Consumption details
                    <div
                      className="d-flex"
                      style={{ marginTop: "-6px", height: "39px" }}
                    >
                      <div
                        className="dashboardTabs coalGradeScreenTabs mt-1"
                        style={{
                          width: "225px",
                          marginRight: "40px",
                          marginBottom: "0px",
                          margin: IS_MOBILE() ? "auto" : "",
                        }}
                      >
                        <ul>
                          <li
                            className="active scoreCardDashboards text-center"
                            style={{ width: "200px" }}
                          >
                            <span>
                              {tableType === "fuel_location"
                                ? "Fuel Location"
                                : tableType === "bowser"
                                ? "Bowser"
                                : "All"}
                            </span>
                            <FaAngleDown />
                            <div className="dashboardSubmenu">
                              {tableType !== "" && (
                                <label
                                  onClick={() => setTableType("")}
                                  data-testid="nav-link-1"
                                >
                                  All
                                </label>
                              )}
                              {tableType !== "bowser" && (
                                <label
                                  onClick={() => setTableType("bowser")}
                                  data-testid="nav-link-1"
                                >
                                  Bowser
                                </label>
                              )}
                              {tableType !== "fuel_location" && (
                                <label
                                  data-testid="nav-link-2"
                                  onClick={() => setTableType("fuel_location")}
                                >
                                  Fuel Location
                                </label>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                      {!IS_MOBILE() ? (
                        <span className="btn-icon excel">
                          <AiOutlineFileExcel
                            onClick={() => downloadExcelFn()}
                            title="Download excel"
                            // style={{ fontSize: "px" }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </span>
                </Card.Header>
                <Card.Body
                  className="p-0"
                  style={{ marginBottom: IS_MOBILE() ? "70px" : "" }}
                >
                  <DataTableComponent
                    fixedHeader={true}
                    tabValIs={tabValIs}
                    data={
                      tableType === "fuel_location"
                        ? hemmDataList.filter((d) => !d?.dispensedByBowser)
                        : tableType === "bowser"
                        ? hemmDataList.filter((d) => d?.dispensedByBowser)
                        : hemmDataList
                    }
                    page={pageTable1}
                    columns={HEMMDetail}
                    setPage={setPageTable1}
                    tableName={fuelTableTypeObj?.hemmData}
                    ref={tableRef1}
                    totalCount={fuelData?.totalCount}
                    matchCount={
                      tableType === "fuel_location"
                        ? hemmDataList.filter((d) => !d?.dispensedByBowser)
                            .length
                        : tableType === "bowser"
                        ? hemmDataList.filter((d) => d?.dispensedByBowser)
                            .length
                        : hemmDataList.length
                    }
                    onCallScrollFunc={fuelDashboardTblFunc}
                    filterData={`&subEqptType=${activeEqptTab}` + filterForm}
                    filterDataBy={filterDataBy + "&" + queryString}
                    getApi={getFuelTabFirstDashboardTbl}
                    dashboardType="fuelDashboard1"
                    dataFilter={
                      tableType === "fuel_location"
                        ? "dispensedByBowser=null"
                        : tableType === "bowser"
                        ? "dispensedByBowser=notNull"
                        : "dispensedByBowser=both"
                    }
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default HsdConsumption;
