import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
// import Select from "../../../../../components/Select/Select";
import {
  INCIDENTTYPESEVERITY_4_5,
  STAFFEMPLOYER,
  dataValueList,
  departmentList,
  highSeverities,
  incidentTypes,
  incidentTypesObj,
  incidentTypesTooltip,
  lowSeverities,
  objStaffType,
  // permitShifts,
  shiftObj,
  staffType,
  viewStaffType,
} from "../../DATA";
import { getArraayofKeys } from "../../Helper";
import moment from "moment";
import FormInputText from "../../../../../components/FormInputText/FormInputText";
import ReportButton from "../ReportButton/ReportButton";
import {
  IS_MOBILE,
  IS_USER_SITE_MINES,
  checkNumberString,
} from "../../../../../_helpers/helper";
import { BsFillInfoCircleFill } from "react-icons/bs";
import CustomPopover from "../../../../../components/Common/CustomPopover";
import Asterik from "../../../../../components/Common/Asterik/Asterik";

let Select;
let CustomDatePicker;
if (IS_MOBILE()) {
  Select =
    require("../../../../../components/MobileComponents/Select/Select").default;
  CustomDatePicker =
    require("../../../../../components/MobileComponents/DateTimeCustom/CustomDateTime").default;
} else {
  Select = require("../../../../../components/Select/Select").default;
  CustomDatePicker =
    require("../../../../../components/DateTimeCustom/CustomDateTime").default;
}

const incidentInfoTab = ({
  form = {},
  onChange,
  error = {},
  onChangeDate,
  sites,
  countries,
  states = [],
  departments,
  setTab,
  onSubmit,
  area,
  subArea,
  activity,
  subActivity,
  users,
  disableEmployer,
  contractors,
  contractorsStaff,
  employeeList,
  tab,
  isStaffVisible,
  isStaffTextBox,
  showSubAreaText,
  asterikRules,
}) => {
  return (
    <>
      <div className="mobileFormWrapper">
        {IS_MOBILE() && <h3>Incident/Accident Info</h3>}
        <div className="FormWrapper">
          <Form>
            <Row>
              <Col sm={4}>
                <Form.Label className="form-label-input">
                  {"Incident/Accident Type"}
                  <Asterik
                    fieldName="category"
                    requiredRules={asterikRules}
                  />{" "}
                  <CustomPopover
                    // click
                    placement={IS_MOBILE() ? "bottom" : "right"}
                    tooltip={
                      <>
                        <table className="table table-bordered tooltipTable">
                          <thead>
                            <tr>
                              <th style={{ minWidth: "140px" }}>
                                Incident/Accident Type
                              </th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {incidentTypesTooltip.map((x, i) => {
                              return (
                                <tr key={"dd" + i}>
                                  <td>{x.label}</td>
                                  <td>{x.tooltip}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    }
                  >
                    <BsFillInfoCircleFill />
                  </CustomPopover>
                </Form.Label>
                <Select
                  // label={"Incident Type"}
                  name="category"
                  value={form?.category}
                  onChange={onChange}
                  options={getArraayofKeys(incidentTypesObj)}
                  object={incidentTypesObj}
                  error={error?.category}
                />
              </Col>
              {form?.category === "others" && (
                <Col sm={4}>
                  <FormInputText
                    label={`Other Incident Type`}
                    name="category_other"
                    value={form?.category_other}
                    onChange={onChange}
                    error={error?.category_other}
                    asterikRules={asterikRules}
                  ></FormInputText>
                </Col>
              )}
              <Col lg={4}>
                <div className="form-group">
                  <Form.Label className="form-label-input">
                    {"Incident/Accident Severity"}
                    <Asterik
                      fieldName="severity"
                      requiredRules={asterikRules}
                    />{" "}
                    <CustomPopover
                      // click
                      placement={IS_MOBILE() ? "bottom" : "right"}
                      tooltip={
                        <>
                          <table className="table table-bordered tooltipTable">
                            <thead>
                              <tr>
                                <th style={{ minWidth: "140px" }}>
                                  Incident/Accident Severity
                                </th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {highSeverities
                                .filter((d) => d.value !== "Not Applicable")
                                .map((x, i) => {
                                  return (
                                    <tr key={"highSeverities" + i}>
                                      <td>{x.label}</td>
                                      <td>{x.tooltip}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      }
                    >
                      <BsFillInfoCircleFill />
                    </CustomPopover>
                  </Form.Label>
                  <Select
                    // label={"Incident Severity"}
                    name="severity"
                    value={form?.severity}
                    onChange={onChange}
                    options={highSeverities}
                    error={error?.severity}
                    KEY_value={"value"}
                  ></Select>
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <Form.Label className="form-label-input">
                    Incident/Accident Date and Time
                    <Asterik
                      fieldName="incident_datetime"
                      requiredRules={asterikRules}
                    />
                  </Form.Label>
                  <CustomDatePicker
                    isValidDate={(day) => day.isBefore(moment())}
                    value={
                      form.incident_datetime
                        ? form.incident_datetime
                        : moment().format("YYYY-MM-DD HH:mm")
                    }
                    name="incident_datetime"
                    onChange={onChange}
                    timeFormat={"HH:mm"}
                    dateFormat="YYYY-MM-DD"
                    // timeConstraints={hoursMinutes}
                  />
                  <span className="small text-danger">
                    {error.incident_datetime}
                  </span>
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <Form.Label className="form-label-input">
                    Recording / Report Date and Time
                    <Asterik
                      fieldName="incident_reportdatetime"
                      requiredRules={asterikRules}
                    />
                  </Form.Label>
                  <CustomDatePicker
                    isValidDate={(day) =>
                      day.isBefore(moment()) &&
                      day.isAfter(
                        moment(form.incident_datetime).add(-1, "days")
                      )
                    }
                    value={
                      form.incident_reportdatetime
                        ? form.incident_reportdatetime
                        : moment().format("YYYY-MM-DD HH:mm")
                    }
                    name="incident_reportdatetime"
                    onChange={onChange}
                    timeFormat={"HH:mm"}
                    dateFormat="YYYY-MM-DD"
                    error={error?.incident_reportdatetime}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <Select
                  label={"Mine"}
                  name="siteId"
                  value={form?.siteId}
                  onChange={onChange}
                  options={sites}
                  error={error?.siteId}
                  KEY_label={"name"}
                  KEY_value={"id"}
                  blankSelect={"Select"}
                  disabled={IS_USER_SITE_MINES()}
                  asterikRules={asterikRules}
                ></Select>
              </Col>
              <Col lg={4}>
                <FormInputText
                  readOnly={true}
                  name="subsidaryId"
                  value={form.roName}
                  type="text"
                  label={"Subsidiary"}
                  error={error?.subsidaryId}
                  asterikRules={asterikRules}
                />
                <FormInputText
                  readOnly={true}
                  name="subsidaryId"
                  value={form.subsidaryId}
                  type="hidden"
                  error={error?.subsidaryId}
                />
              </Col>
              <Col lg={4}>
                <Select
                  label={"Reported By"}
                  name="staffType"
                  value={form?.staffType}
                  onChange={onChange}
                  options={getArraayofKeys({
                    ...viewStaffType,
                    "Not Applicable": "Not Applicable",
                  })}
                  object={{
                    ...viewStaffType,
                    "Not Applicable": "Not Applicable",
                  }}
                  error={error?.staffType}
                  KEY_label={"name"}
                  KEY_value={"id"}
                  blankSelect={"Select"}
                  asterikRules={asterikRules}
                ></Select>
              </Col>
              <Col lg={4}>
                {form?.staffType === objStaffType.others ||
                form?.staffType === objStaffType.contractor ? (
                  <FormInputText
                    label={`Reported by - Name`}
                    name="otherStaffName"
                    value={form?.otherStaffName}
                    onChange={onChange}
                    error={error?.otherStaffName}
                    KEY_label={"firstName"}
                    KEY_value={"id"}
                    asterikRules={asterikRules}
                  ></FormInputText>
                ) : (
                  <Select
                    label={`Reported by - Name`}
                    name="staffName"
                    value={checkNumberString(form?.staffName)}
                    onChange={onChange}
                    options={users}
                    error={error?.staffName}
                    KEY_label={"name_email_employeeId"}
                    placeholder={"Search by Name / Email Id"}
                    KEY_value={"id"}
                    blankSelect={"none"}
                    isCreatable
                    disabled={form?.staffType === "Not Applicable"}
                    asterikRules={asterikRules}
                  ></Select>
                )}
              </Col>
              {
                form?.staffType !== objStaffType.others && (
                  // (form?.staffType === objStaffType.contractor ? (
                  <Col lg={4}>
                    <Select
                      label={`${
                        form?.staffType === objStaffType.contractor
                          ? "Contractor"
                          : "Employer"
                      }`}
                      name="staffEmployer"
                      value={checkNumberString(form?.staffEmployer)}
                      onChange={onChange}
                      options={
                        form?.staffType === objStaffType.contractor
                          ? contractorsStaff
                          : STAFFEMPLOYER
                      }
                      error={error?.staffEmployer}
                      KEY_label={
                        form?.staffType === objStaffType.contractor &&
                        "contractor"
                      }
                      KEY_value={
                        form?.staffType === objStaffType.contractor && "id"
                      }
                      // placeholder={"Search by Name / Email Id"}
                      blankSelect={"none"}
                      disabled={form?.staffType === "Not Applicable"}
                      asterikRules={asterikRules}
                    ></Select>
                  </Col>
                )
                // ) : (
                //   <Col lg={4}>
                //     <FormInputText
                //       label={`Employer`}
                //       name="staffEmployer"
                //       value={form?.staffEmployer}
                //       onChange={onChange}
                //       error={error?.staffEmployer}
                //       disabled={
                //         !form?.staffType ||
                //         form?.staffType === objStaffType.users ||
                //         form?.staffType === objStaffType.notApplicable
                //       }
                //     ></FormInputText>
                //   </Col>
                // ))
              }

              <Col lg={4}>
                <Select
                  label={"Department"}
                  name="department"
                  value={form?.department}
                  onChange={onChange}
                  options={departmentList}
                  error={error?.department}
                  blankSelect={"Select"}
                  asterikRules={asterikRules}
                ></Select>
              </Col>
              <Col lg={4}>
                <Select
                  label={"Place"}
                  name="areaId"
                  value={form?.areaId}
                  onChange={onChange}
                  options={area.sort((a, b) => +a.code - +b.code)}
                  error={error?.areaId}
                  KEY_label={"name"}
                  KEY_value={"id"}
                  blankSelect={"Select"}
                  asterikRules={asterikRules}
                ></Select>
              </Col>
              <Col lg={4}>
                <Select
                  label={"Workings"}
                  name="subAreaId"
                  value={+form?.subAreaId}
                  onChange={onChange}
                  options={subArea.sort((a, b) => +a.code - +b.code)}
                  error={error?.subAreaId}
                  KEY_label={"name"}
                  KEY_value={"id"}
                  blankSelect={"Select"}
                  asterikRules={asterikRules}
                ></Select>
              </Col>
              {showSubAreaText && (
                <Col lg={4}>
                  <FormInputText
                    label={"Other Sub Area"}
                    name="otherSubArea"
                    value={form?.otherSubArea}
                    onChange={onChange}
                    error={error?.otherSubArea}
                    KEY_label={"name"}
                    KEY_value={"id"}
                    asterikRules={asterikRules}
                  ></FormInputText>
                </Col>
              )}
              <Col lg={4}>
                <Select
                  label={"Shift"}
                  name="shift"
                  value={form?.shift}
                  onChange={onChange}
                  options={getArraayofKeys(shiftObj)}
                  object={shiftObj}
                  // array={"single"}
                  error={error?.shift}
                  blankSelect={"Select"}
                  asterikRules={asterikRules}
                ></Select>
              </Col>
              <Col lg={4}>
                <Select
                  label={"Activity"}
                  name="activityId"
                  value={form?.activityId}
                  onChange={onChange}
                  options={activity}
                  error={error?.activityId}
                  KEY_label={"name"}
                  KEY_value={"id"}
                  blankSelect={"Select"}
                  asterikRules={asterikRules}
                ></Select>
              </Col>
              <Col lg={4}>
                <Select
                  label={"Sub Activity"}
                  name="subActivityId"
                  value={form?.subActivityId}
                  onChange={onChange}
                  options={subActivity}
                  error={error?.subActivityId}
                  KEY_label={"name"}
                  KEY_value={"id"}
                  blankSelect={"Select"}
                  asterikRules={asterikRules}
                ></Select>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default incidentInfoTab;
