import React from "react";

import {
  SidebarData,
} from "./DATA";

import "./../FuelManagementSystem/fuelmgmt.scss";

import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";

import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
const ContractorManagementDashboard = () => {
  return <>
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="content" id="page-content-wrapper">
            <div className="contentArea">
              <iframe
                src={`${URL_CONFIG.AWS_QUICKSIGHT_URL}e74e8efc-fb50-44c5-b9d4-c79017eb9818?directory_alias=cil-dev-si-bi`}
                title="Loading..."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default ContractorManagementDashboard;
