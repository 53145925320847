import { Card, Col, Container, Row, Table } from "react-bootstrap";
import "./Dashboard.scss";
import DashboardFilter from "./DashboardFilter";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import {
  safetyDashboard1,
  safetyDashboard4,
} from "../../../../services/_safetyManagement/safetyManagement";
import {
  pageFourChartList,
  pageOneChartList,
  wigetList1,
  wigetList2,
} from "./DATA";
import { pieChartDataWithModalForTotalVs } from "../../../../components/Chart/Common/config";
import PieChartWithModal from "../../../../components/PieChartWithModal/PieChartWithModal";
import { bodyLabel, incidentTypesObj, shiftObj } from "../DATA";

const IncidentDashboard = ({
  filterForm,
  cref,
  isScreenFour,
  screen,
  setMinMaxDate,
}) => {
  const [dashboardData, setDashboardData] = useState({});
  // const [widgetData1, setWidgetData1] = useState(wigetList1);
  // const [widgetData2, setWidgetData2] = useState(wigetList2);
  const [chart, setChart] = useState({});

  const dashboardDataFun = useCallback(async (filter) => {
    let response = isScreenFour
      ? await safetyDashboard4(filter)
      : await safetyDashboard1(filter);
    if (response && response?.status && response?.data) {
      const resData = response.data;

      let holdNull = (
        isScreenFour ? pageFourChartList : pageOneChartList
      )?.forEach((item) => {
        setChart((prev) => ({
          ...prev,
          [item?.value]:
            item?.type === "pieVs"
              ? pieChartDataWithModalForTotalVs(
                  resData[item?.value]?.map((d) =>
                    item?.value === "incident_shift"
                      ? { ...d, name: shiftObj[d?.name] }
                      : item?.value === "incident_bodyParts"
                      ? { ...d, name: bodyLabel[d?.name] }
                      : { ...d }
                  )
                )
              : "No chart",
        }));
      });

      // if (!isScreenFour) {
      //   setWidgetData1((prev) => {
      //     return prev.map((d) => ({
      //       ...d,
      //       value: resData?.[d.key],
      //     }));
      //   });

      //   setWidgetData2((prev) => {
      //     return prev.map((d) => ({
      //       ...d,
      //       value: resData?.[d.key] + " Hrs",
      //     }));
      //   });
      // }

      setDashboardData(resData);
      setMinMaxDate(resData?.minMax_date || {});
    }
  }, []);

  useEffect(() => {
    dashboardDataFun(filterForm);
  }, [dashboardDataFun]);

  useImperativeHandle(
    cref,
    () => ({
      dashboardDataFun: () => dashboardDataFun(filterForm),
    }),
    [dashboardDataFun, filterForm]
  );

  // const finalwidgetData = widgetData1.concat(widgetData2);

  return (
    <div className="dashboardWrapper">
      <Row id={"screen" + screen}>
        {!isScreenFour && (
          <Col md={3} className="dashboardLeftWidgets">
            <div className="dashCards">
              {Object.keys(incidentTypesObj)?.map((d, idx) => {
                return (
                  d !== "others" && (
                    <div className="dashSmallCard" key={idx}>
                      <p>{incidentTypesObj[d]}</p>
                      <h4>{dashboardData?.incidentTypeDataObj?.[d] || 0}</h4>
                    </div>
                  )
                );
              })}
              <div className="dashSmallCard">
                <p>{incidentTypesObj?.others}</p>
                <h4>{dashboardData?.incidentTypeDataObj?.others || 0}</h4>
              </div>
            </div>
          </Col>
        )}
        <Col
          md={isScreenFour ? 12 : 9}
          className={!isScreenFour ? "dashboardRightCharts" : ""}
        >
          <Row>
            {(isScreenFour ? pageFourChartList : pageOneChartList)?.map(
              (d, idx) => {
                return (
                  <Col md={4} key={idx}>
                    <Card className="chartCard chartCardBtmSpace">
                      <Card.Header>{d?.title}</Card.Header>
                      <Card.Body className="pieCartOuter">
                        <PieChartWithModal
                          pieChartColour={false}
                          seriesData={chart[d?.value] || []}
                          customLabelFalse={true}
                          isCustomLegend={true}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                );
              }
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default IncidentDashboard;
