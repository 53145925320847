import React, { useMemo, useState, useCallback, useRef } from "react";
import { Container } from "react-bootstrap";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import { areaListApis, areaAddLink } from "./DATA";
import { capitalizeName } from "../../../../_helpers/helper";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import AreaView from "./AreaView";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { SidebarData } from "../../IncidentManagement/DATA";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../../_services/CommonService/CommonService";

const AreaList = () => {
  const [isListData, setIsListData] = useState(true);
  
  const navigate = useNavigate();
  
  let newAddLink = areaAddLink;
  
  const onEdit = (id) => {
    navigate(`${newAddLink}/${id}`);
  };
  
  const cref = useRef(null);
  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: `area_of_operations`,
        id: id,
        status: status
      }
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const columnFields = useMemo(
    () => [
      {
        name: "Code",
        selector: "name",
        sortable: false,
        // minWidth: "200px",
        cell: (row) => {
          return row?.code;
        },
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        // minWidth: "200px",
        cell: (row) => {
          return capitalizeName(`${row?.name ? row?.name : ""}`);
        },
      },
      {
        name: "Status",
        selector: "status",
        cell: (row) => {
          return (
            (row.status === 1 && (
              <span
                href="#"
                className="icons text-success pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOn
                  size="20"
                  data-testid={"toogle-user-list-on-" + row.id}
                />
              </span>
            )) ||
            (row.status === 0 && (
              <span
                href="#"
                className="icons text-danger pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOff
                  size="20"
                  data-testid={"toogle-user-list-off-" + row.id}
                />
              </span>
            ))
          );
        },
      },
    ],
    []
  );

  const viewAsPopup = (form) => {
    return <AreaView form={form} />;
  };

  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  apis={areaListApis}
                  primaryKey="id"
                  viewTitle="Place of Operation"
                  editTitle="Edit Permissions"
                  customTableTitle="Place of Operation"
                  // hideSearch
                  // FilterBtn={newFilterBtn}
                  viewAsPopup={viewAsPopup}
                  // editAsPopup={editAsPopup}
                  hideSnColumn
                  pageLink={newAddLink}
                  pagination
                  hideDeleteButton
                  hideEditButton
                  cref={cref}
                  fields={columnFields}
                  deleteFun
                  // childrenAfterAddButton={tabButtons}
                  editFunButton={onEdit}
                  breadCrumb={[
                    {
                      name: "Safety Management",
                      path: "#",
                    },
                    {
                      name: "Configuration",
                      path: "/safety-management-system/configuration",
                    },
                    {
                      name: "Place of Operation",
                      path: "#",
                    },
                  ]}
                  title="Data"
                  isNoTabOnPage
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <EmptyDataList
                // tabButtons={tabButtons}
                customTableTitle="Place of Operation"
                pageLink={newAddLink}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default AreaList;
