import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GiReturnArrow } from "react-icons/gi";
import { FiPlusCircle, FiEdit3, FiEye, FiMinusCircle } from "react-icons/fi";
import { BiCheck, BiImport } from "react-icons/bi";
import { confirmAlert } from "react-confirm-alert";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./dataTable.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DataTable from "react-data-table-component";
import toastr from "toastr";
import { useToggle, useForm, useDebounce } from "../../_helpers/hooks";
import { SetPermission } from "../SetPermission/Permissions";
import {
  ApiStatusUpdateView,
  downloadFileByURL,
  getQueryParam,
} from "../../_helpers/commonHelper";
import CustomSearch from "../../components/Common/CustomSearch";
import CustomTooltipComponent from "../../_pages/Components/CommanComponents/CustomTooltipComponent";
import {
  AiFillMobile,
  AiOutlineFileExcel,
  AiOutlinePlus,
} from "react-icons/ai";
import { Button } from "react-bootstrap";
import FormInputText from "../FormInputText/FormInputText";
import ThreeDotPopover from "../popover/ThreeDotPopover";
import CustomPagination from "./CustomPagination";
import { saveAsExcelXlsx } from "../export/exportExcel";
import { ImFilePdf } from "react-icons/im";
import { permissionCheck } from "../../_services/Auth/helper";
import { IS_MOBILE, auth_user } from "../../_helpers/helper";
import MainBreadcrumb from "../Breadcrumb/MainBreadcrumb";
import { TABLE_EXPEND_ROW } from "./DATA";
import ApproveReject from "../Common/ApproveReject/ApproveReject";
import InputError from "../_form/InputError/InputError";
import { performanceStatus } from "../../_pages/ContractorManagement/Hindrance/DATA";
import { UserService } from "../../_services/UserService/UserService";
import ConfirmationPopup from "../MessagePopup/ConfirmationPopup";
const makeActionsColumn = ({
  primaryKey,
  pageLink,
  handleDelete,
  onClickView,
  onClickHistory,
  onClickApprove,
  onClickReject,
  hideViewButton = false,
  hideEditButton = false,
  hideDeleteButton = false,
  showApproveButton = false,
  showRejectButton = false,
  permissionViewButton = null,
  permissionEditButton = null,
  permissionDeleteButton = null,
  permissionLoggedInButton = null,
  mfiSettingIcon,
  onClickDownloadExcel,
  onClickDownloadPdf,
  onClickDownloadFormA,
  onClickDownloadFormB,
  onClickDownloadFormC,
  ishideThreeDotButton,
  approveFun,
  editFun,
  editFunButton,
  editContractorPerformanceFun,
  listname,
  statusByOptions,
  deleteCheck,
  dgmsCheck,
  deleteFun,
  navigate,
  onClickEdit,
  viewLink,
  optionType,
  icons,
  hideEditIcon,
  updatePageLink,
  updateStatus,
  coalStatus,
  enableMobileIcon,
  editPage,
  hideExceDownloadByRow,
  editPageLink,
  formClink,
}) => ({
  name: `${IS_MOBILE() ? "Action" : ""}`,
  // selector: "action",
  isShowMobile: true,
  selector: (row) => row?.["action"],
  sortable: false,
  right: true,
  cell: (rowContent) => {
    const primaryValue = rowContent[primaryKey] || 1;
    const status = rowContent["status"] || 1;
    const current_label = rowContent["current_label"] || 1;
    const current_label_coal = rowContent[coalStatus] || 1;
    // const personnelDetails = rowContent["personalDetails"];
    // const filteredArray = personnelDetails.filter(
    //   (item) => item.outcome !== "Fatality"
    // );
    // const personnelCheck = filteredArray.length >= 1 ? true : false;

    const formCPending = rowContent["pending_formc"] === "true" ? 1 : 0;
    const viewPageLink =
      typeof pageLink === "function"
        ? pageLink(rowContent)
        : `${pageLink}/${primaryValue}`;

    const viewIcon = onClickView ? (
      <div
        data-testid="viewDetails"
        className="view"
        style={{ display: "flex" }}
      >
        {/* here adding formC link */}
        {formClink && formCPending ? (
          <span>
            <Link
              to={`${formClink}${primaryValue}`}
              className="icons text-cyanBlue"
              style={{ marginRight: "10px" }}
            >
              {hideEditIcon}
              <FiEdit3 style={{ fontSize: "17px" }} title="Form 4 - C" />
            </Link>
          </span>
        ) : (
          ""
        )}

        {icons &&
          !hideEditIcon &&
          (status === "draft" ||
            (statusByOptions && statusByOptions(rowContent))) && (
            <span>
              <Link
                to={`${
                  editPageLink ? editPageLink : updatePageLink
                }/${primaryValue}`}
                className="icons text-cyanBlue"
                style={{ marginRight: "10px" }}
              >
                {hideEditIcon}
                <FiEdit3 style={{ fontSize: "17px" }} title="Edit" />
              </Link>
            </span>
          )}

        {icons &&
          !ishideThreeDotButton &&
          updateStatus &&
          current_label !== "approved" &&
          current_label !== "draft" &&
          current_label_coal !== "Submitted" && (
            <span>
              <Link
                to={`${updatePageLink}/${primaryValue}`}
                className="icons text-cyanBlue"
                style={{ marginRight: "10px" }}
              >
                <BiCheck style={{ fontSize: "23px" }} title="Update Status" />
              </Link>
            </span>
          )}

        <span
          onClick={() => onClickView(rowContent)}
          style={{ marginRight: "10px" }}
        >
          {icons ? (
            <Link className="icons text-cyanBlue">
              <FiEye style={{ fontSize: "17px" }} title="View" />
            </Link>
          ) : (
            "View Detail"
          )}
        </span>
      </div>
    ) : viewLink ? (
      <Link to={viewPageLink} className="icons text-cyanBlue">
        <i className="ri-eye-line">View Details</i>
      </Link>
    ) : (
      ""
    );

    const editPermission =
      onClickEdit && rowContent?.roleId !== 1 ? (
        <div className="popoverList" onClick={() => onClickEdit(rowContent)}>
          {" "}
          Edit Permission
        </div>
      ) : null;

    const isDraft = statusByOptions ? statusByOptions(rowContent) : null;
    const isAuthorized = deleteCheck ? deleteCheck(rowContent) : null;
    const isDgms = rowContent?.dbms_id ? true : false;
    const isIncidentClose =
      rowContent?.dbms_id && rowContent?.status === "incident_closed"
        ? true
        : false;

    const formCStatus = rowContent.pending_formc === "false";
    const isMobileEnabled = enableMobileIcon ? rowContent?.mobileEnabled : null;

    const edtiEcont =
      listname && listname === "hindrance"
        ? permissionCheck(["contractor"]) &&
          rowContent?.current_label === "draft"
        : false;
    const edtiContractorPer =
      listname && listname === "performance"
        ? permissionCheck(["section_incharge"]) &&
          rowContent?.status === "draft"
        : false;
    const approveEcont =
      listname && listname === "hindrance"
        ? (permissionCheck(["section_incharge"]) &&
            rowContent?.current_label === "section_incharge") ||
          (permissionCheck(["representative"]) &&
            rowContent?.current_label === "representative") ||
          (permissionCheck(["colliery_manager"]) &&
            rowContent?.current_label === "colliery_manager")
        : false;
    let incidentUpdateStatus = false;
    const authUser = auth_user();

    let pdfDownLoadOption = false;

    if (
      listname &&
      listname === "incident" &&
      rowContent?.status !== "incident_closed"
    ) {
      incidentUpdateStatus = true;
      // onClickDownloadPdf = false;
    } else if (
      listname &&
      rowContent?.status !== "incident_closed" &&
      ((listname === "incident_investigation" &&
        rowContent?.currentAssignedUser === authUser.id) ||
        (listname === "incident_capa_table" &&
          rowContent?.ownerId === authUser.id))
    ) {
      incidentUpdateStatus = true;
    }

    if (
      listname &&
      listname === "incident" &&
      rowContent?.status === "incident_closed"
    ) {
      pdfDownLoadOption = true;
    }

    const incidentUpdateStatusFn = () => {
      navigate(`${pageLink}/edit/${primaryValue}`);
    };

    return (
      <div className="actionIconsGroup">
        {!hideViewButton &&
          (viewPageLink || onClickView) &&
          (permissionViewButton ? (
            <SetPermission permission={permissionViewButton} data={viewIcon} />
          ) : (
            viewIcon
          ))}
        {enableMobileIcon && (
          <ConfirmationPopup
            title={`${isMobileEnabled ? "Disabled" : "Enable"} Mobile`}
            subTitle={"Do you want to proceed?"}
            callFunc={enableMobileIcon}
            id={primaryValue}
            data={isMobileEnabled}
          />
        )}

        {!hideEditButton &&
          (permissionEditButton ? (
            <SetPermission
              permission={permissionEditButton}
              data={
                <CustomTooltipComponent tooltipData="Edit">
                  <Link
                    to={`${pageLink}/edit/${primaryValue}`}
                    className="icons text-cyanBlue"
                  >
                    <i className="ri-edit-line"></i>
                  </Link>
                </CustomTooltipComponent>
              }
            />
          ) : (
            <CustomTooltipComponent tooltipData="Edit">
              <Link
                to={`${pageLink}/edit/${primaryValue}`}
                className="icons text-cyanBlue"
              >
                <i className="ri-edit-line"></i>
              </Link>
            </CustomTooltipComponent>
          ))}
        {!hideEditButton && mfiSettingIcon && permissionEditButton && (
          <SetPermission
            permission={permissionEditButton}
            data={
              <CustomTooltipComponent tooltipData="Setting">
                <Link
                  to={`${pageLink}/setting/${primaryValue}`}
                  className="icons text-cyanBlue"
                >
                  <i className="ri-settings-2-fill"></i>
                </Link>
              </CustomTooltipComponent>
            }
          />
        )}
        {!hideDeleteButton &&
          (permissionDeleteButton ? (
            <SetPermission
              permission={permissionDeleteButton}
              data={
                <CustomTooltipComponent tooltipData="Delete">
                  <span
                    onClick={() => handleDelete(primaryValue)}
                    className="icons text-danger pointer"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </span>
                </CustomTooltipComponent>
              }
            />
          ) : (
            <CustomTooltipComponent tooltipData="Delete">
              <span
                onClick={() => handleDelete(primaryValue)}
                className="icons text-danger pointer"
              >
                <i className="ri-delete-bin-line"></i>
              </span>
            </CustomTooltipComponent>
          ))}

        {permissionLoggedInButton && (
          <SetPermission
            permission={permissionLoggedInButton}
            data={
              <CustomTooltipComponent tooltipData="Login MFI">
                <Link
                  to={"company/" + rowContent.id + "/dashboard"}
                  className="icons text-cyanBlue"
                >
                  <i className="ri-admin-line"></i>
                </Link>
              </CustomTooltipComponent>
            }
          />
        )}

        {!ishideThreeDotButton && (
          <ThreeDotPopover
            excelDownload={
              !hideExceDownloadByRow
                ? statusByOptions
                  ? !isDraft || !editPage
                    ? onClickDownloadExcel
                    : null
                  : onClickDownloadExcel
                : null
            }
            pdfDownload={pdfDownLoadOption ? onClickDownloadPdf : null}
            onClickDownloadFormA={isDgms ? onClickDownloadFormA : ""}
            onClickDownloadFormB={isIncidentClose ? onClickDownloadFormB : ""}
            onClickDownloadFormC={formCStatus ? onClickDownloadFormC : ""}
            search={`search[id]=${primaryValue}`}
            approveFun={approveEcont ? approveFun : false}
            editFun={edtiEcont ? editFun : false}
            editFunButton={
              statusByOptions ? (isDraft ? editFunButton : null) : editFunButton
            }
            editContractorPerformanceFun={
              edtiContractorPer && editContractorPerformanceFun
                ? editContractorPerformanceFun
                : false
            }
            primaryKey={primaryValue}
            deleteFun={isAuthorized ? deleteFun : ""}
            optionTypeValue={optionType}
            editPopup={
              statusByOptions
                ? isDraft
                  ? editPermission
                  : null
                : editPermission
            }
            incidentUpdateStatus={
              incidentUpdateStatus ? incidentUpdateStatusFn : false
            }
            updateStatus
            rowData={rowContent}
          />
        )}

        <ApproveReject
          showApproveButton={showApproveButton}
          showRejectButton={showRejectButton}
          rowContent={rowContent}
          onClickApprove={onClickApprove}
          onClickReject={onClickReject}
        />
      </div>
    );
  },
});

const makeSnColumn = ({ primaryKey }) => ({
  name: "S.No.",
  // selector: "sid",
  selector: (row) => row?.["sid"],
  // sortable: true,
  sortField: "id",

  // cell: (value, index) => <span>{index + 1}</span>,
});

const actionStatusColumn = (handleFunc) => ({
  name: "Status",
  sortable: true,
  selector: "current_label",
  minWidth: "200px",
  cell: (row) => (
    <>
      <span className="statusSec draft" style={{ cursor: "pointer" }}>
        {performanceStatus[row?.current_label]}
        {row?.isBack && (
          <span onClick={() => handleFunc(row)}>
            <GiReturnArrow />
          </span>
        )}
      </span>
    </>
  ),
});
const defaultInitialForm = Object.freeze({ id: null });
const defaultApis = Object.freeze({
  getAll: null,
  create: null,
  update: null,
  delete: null,
});

const defaultFields = [];

const paginationPerPageArray = [10, 15, 20, 25, 30];

const DataTableWithServer = ({
  ationStatusCol = false,
  fields = defaultFields,
  apis = defaultApis,
  initialForm = defaultInitialForm,
  title = "Title",
  tableTitle = "Table Title",
  pageLink = "/page",
  primaryKey = "id",
  highlightOnHover = true,
  pagination = true,
  hideSnColumn = false,
  hideHeader = false,
  hideSearch = false,
  hideActionsColumn = false,
  hideAddButton = false,
  hideDeleteButton = false,
  hideEditButton = false,
  hideViewButton = false,
  showApproveButton = false,
  showRejectButton = false,
  viewAsPopup = false,
  viewHistory = false,
  // Can provide extra buttons after the Add Button.
  childrenAfterAddButton,
  getAllFilter,
  childrenBeforeTable,
  childrenUnderTable,
  // permissions
  permissionAddButton = null,
  permissionViewButton = null,
  permissionEditButton = null,
  permissionDeleteButton = null,
  permissionLoggedInButton = null,
  uploadPermission = null,
  onApproveReject,
  getAllDelete,
  mfiSettingIcon,
  searchText,
  keyField,
  getAllCounter,
  cssClass,
  coIPage,
  downloadExcel,
  FilterBtn = false,
  coalGradeType = "",
  downloadPdf,
  downloadFormA,
  downloadFormB,
  downloadFormC,
  rightViewPopup,
  leftViewPopup,
  modalSize,
  hideThreeDotButton,
  approveFun,
  editFun,
  editContractorPerformanceFun,
  listname,
  customTableTitle,
  viewTitle,
  statusByOptions,
  deleteCheck,
  dgmsCheck,
  editFunButton,
  deleteFun,
  editAsPopup,
  editTitle,
  actionBtn,
  cref,
  viewLink,
  breadCrumb,
  optionType,
  excelFilterOption,
  isNoTabOnPage,
  icons,
  updatePageLink,
  updateStatus,
  hideEditIcon,
  coalStatus,
  importExcelFile,
  customHeader = false,
  enableMobileIcon,
  apiUpdateView,
  hideApiUpdateStatus,
  hideExceDownloadByRow,
  editPageLink,
  formClink,
}) => {
  const [rows, setRows] = useState(() => []);
  const [search, setSearch] = useState(() => getQueryParam("q") || "");
  const [approvalMessage, setApprovalMessage] = useState("");
  const [approvalMessageErr, setApprovalMessageErr] = useState("");
  const [form, onChange, setForm] = useForm(initialForm);

  const [showAddEdit, toggleShowAddEdit, setShowAddEdit] = useToggle(false);
  const [showView, toggleShowView, setShowView] = useToggle(false);
  const [showHistory, toggleShowHistory, setShowHistory] = useToggle(false);

  const [showApprove, toggleShowApprove] = useToggle(false);
  const [showReject, toggleShowReject] = useToggle(false);

  // new
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(() =>
    paginationPerPageArray.includes(+getQueryParam("limit"))
      ? +getQueryParam("limit")
      : 10
  );
  const [page, setPage] = useState(() => +(getQueryParam("page") || 1));
  const [sortColumn, setSortColumn] = useState(
    () => getQueryParam("sortBy") || ""
  );
  const [sortDirection, setSortDirection] = useState(
    () => getQueryParam("orderBy") || ""
  );
  const [filter, setFilter] = useState(null);
  const [pendingAction, setPendingAction] = useState(0);
  const [checkType, setCheckType] = useState();
  const [finalColumns, setFinalColumns] = useState(() => []);

  const actionRow = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = window.matchMedia("(max-width: 767px)")?.matches;

  const formFilter = useMemo(
    () => (getAllFilter ? getAllFilter : null),
    [getAllFilter]
  );

  const handleView = useCallback(
    (rowData) => {
      setForm(rowData);
      setShowView(true);
    },
    [setForm, setShowView]
  );
  const handleHistory = useCallback(
    (rowData) => {
      setForm(rowData);
      setShowHistory(true);
    },
    [setForm, setShowHistory]
  );
  useEffect(() => {
    setCheckType(coalGradeType);
  }, [coalGradeType]);

  useEffect(() => {
    if (checkType === coalGradeType) return;
    setPage(1);
  }, [checkType, coalGradeType, page]);
  const handleEdit = useCallback(
    (rowData) => {
      setForm(rowData);
      setShowAddEdit(true);
    },
    [setForm, setShowAddEdit]
  );

  const getAll = useDebounce(() => {
    if (!apis.getAll) return;

    (async () => {
      // let filterObj = defaultFilter
      //   ? filter
      //     ? `${defaultFilter}&${filter}`
      //     : filter
      //   : defaultFilter

      let filterObj = formFilter && filter ? `${formFilter}&${filter}` : filter;
      if (!filterObj) return;
      const response = await apis.getAll.api(filterObj);

      setRows(
        response?.data?.map((d, i) => {
          d.sid = (page - 1) * perPage + i + 1;
          return d;
        })
      );
      setTotalRows(response?.totalCount);
      setLoading(false);
    })();
  }, [apis.getAll, formFilter, filter, page, perPage]);

  const enabledMobileClick = useCallback(
    async (id, enableValue) => {
      const response = await UserService.enabledMobileByUser(id, {
        mobileEnabled: !enableValue ? 1 : 0,
      });
      if (!response?.status || response?.status === 401)
        return !response?.status && toastr.error(response?.message);

      toastr.success(response?.message);
      getAll();
    },
    [getAll]
  );

  const deleteFunClick = useCallback(
    async (id, optionType) => {
      if (!apis.delete) return;

      const response = await apis.delete.api(id, optionType);
      if (!response?.status || response?.status === 401)
        return (
          !response?.status &&
          toastr.error(response?.message || `Could not delete`)
        );

      toastr.success(response?.message || `Deleted the successfully.`);

      getAllDelete
        ? getAll()
        : setRows((prev) => prev.filter((row) => row[primaryKey] !== id));
    },
    [apis.delete, getAll, getAllDelete, primaryKey]
  );

  const handleDelete = useCallback(
    (id) => {
      confirmAlert({
        title: `Delete ${title}`,
        message: "Do you want to proceed?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              if (!apis.delete) return;

              const response = await apis.delete.api(id);
              if (!response?.status)
                return toastr.error(
                  response?.message || `Could not delete ${title}`
                );

              toastr.success(
                response?.message || `Deleted the ${title} successfully.`
              );

              getAllDelete
                ? getAll()
                : setRows((prev) =>
                    prev.filter((row) => row[primaryKey] !== id)
                  );
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    },
    [title, apis.delete, getAllDelete, getAll, primaryKey]
  );

  // const handleSubmit = () => {
  //   setRows((prev) =>
  //     form[primaryKey]
  //       ? prev
  //       : [...prev, { ...form, [primaryKey]: prev.length + 1 }]
  //   );
  //   toggleShowAddEdit();
  // };

  const handleCloseApprove = () => {
    actionRow.current = null;
    setApprovalMessage("");
    setApprovalMessageErr("");
    toggleShowApprove();
  };
  const handleCloseReject = () => {
    actionRow.current = null;
    setApprovalMessage("");
    setApprovalMessageErr("");
    toggleShowReject();
  };
  const handleApprove = useCallback(
    (rowData) => {
      toggleShowApprove();
      actionRow.current = rowData;
    },
    [toggleShowApprove]
  );

  const handleReject = useCallback(
    (rowData) => {
      toggleShowReject();
      actionRow.current = rowData;
    },
    [toggleShowReject]
  );

  const downloadExcelFn = useCallback(
    async (filterValue = "", rowData) => {
      let filterForExcel = filter;
      if (filterValue) filterForExcel += "&" + filterValue;
      let response = await downloadExcel(filterForExcel, rowData);
      if (response?.data && response?.status) {
        await saveAsExcelXlsx(
          response?.data,
          response?.column,
          response?.fileName
        );
      }
    },
    [downloadExcel, filter]
  );
  const downloadPdfFn = useCallback(
    async (filterValue = "") => {
      let filterForPdf = filter;
      if (filterValue) filterForPdf += "&" + filterValue;

      await downloadPdf(filterForPdf);
    },
    [downloadPdf, filter]
  );

  const formAFn = useCallback(
    async (filterValue = "") => {
      let filterForPdf = filter;
      if (filterValue) filterForPdf += "&" + filterValue;

      await downloadFormA(filterForPdf);
    },
    [downloadFormA]
  );

  const formBFn = useCallback(
    async (filterValue = "") => {
      let filterForPdf = filter;
      if (filterValue) filterForPdf += "&" + filterValue;
      await downloadFormB(filterForPdf);
    },
    [downloadFormB]
  );

  const formCFn = useCallback(
    async (filterValue = "") => {
      let filterForPdf = filter;
      if (filterValue) filterForPdf += "&" + filterValue;
      await downloadFormC(filterForPdf);
    },
    [downloadFormC]
  );

  const approveClickFn = useCallback(
    async (primaryKeyValue) => {
      approveFun(primaryKeyValue);
    },
    [approveFun]
  );

  const editClickFn = useCallback(
    async (id) => {
      if (editFun) editFun(id);
      if (editFunButton) editFunButton(id);
    },
    [editFun, editFunButton]
  );
  const editClickContractorFn = useCallback(
    async (id) => {
      editContractorPerformanceFun(id);
    },
    [editContractorPerformanceFun]
  );

  const onApprove = () => {
    if (onApproveReject && approvalMessage) {
      onApproveReject({
        id: actionRow.current ? actionRow.current[primaryKey] : null,
        comment: approvalMessage,
        // data: actionRow.current,
        status: "Approved",
      });
      handleCloseApprove();
      getAll();
    }
    if (!approvalMessage) setApprovalMessageErr("Message is Required");
  };
  const onReject = () => {
    if (onApproveReject && approvalMessage) {
      onApproveReject({
        id: actionRow.current ? actionRow.current[primaryKey] : null,
        comment: approvalMessage,
        // data: actionRow.current,
        status: "Rejected",
      });
      handleCloseReject();
      getAll();
    }
    if (!approvalMessage) setApprovalMessageErr("Message is Required");
  };

  const columns = useMemo(() => {
    const onlyColumns = fields
      .filter((field) => !field.hideTableColumn)
      ?.map((columnObj) => ({
        ...columnObj,
        sortField: columnObj?.selector,
        selector:
          typeof columnObj?.selector !== "function"
            ? (row) => row?.[columnObj?.selector]
            : columnObj?.selector,
      }));
    const cols = !hideSnColumn
      ? [makeSnColumn(primaryKey), ...onlyColumns]
      : ationStatusCol
      ? [actionStatusColumn(handleHistory), ...onlyColumns]
      : [...onlyColumns];

    if (!hideActionsColumn)
      cols.push(
        makeActionsColumn({
          pageLink,
          primaryKey,
          onClickView: viewAsPopup ? handleView : null,
          onClickEdit: editAsPopup ? handleEdit : null,
          onClickHistory: viewHistory ? handleHistory : null,
          handleDelete,
          hideDeleteButton,
          hideViewButton,
          hideEditButton,
          showApproveButton,
          showRejectButton,
          permissionViewButton,
          permissionEditButton,
          permissionDeleteButton,
          permissionLoggedInButton,
          onClickApprove: handleApprove,
          onClickReject: handleReject,
          mfiSettingIcon,
          onClickDownloadExcel: downloadExcel ? downloadExcelFn : null,
          onClickDownloadPdf: !!downloadPdf ? downloadPdfFn : null,
          onClickDownloadFormA: !!downloadFormA ? formAFn : null,
          onClickDownloadFormB: !!downloadFormB ? formBFn : null,
          onClickDownloadFormC: !!downloadFormC ? formCFn : null,

          approveFun: approveFun ? approveClickFn : null,
          editFun: editFun ? editClickFn : null,
          editFunButton: editFunButton ? editClickFn : null,
          editContractorPerformanceFun: editContractorPerformanceFun
            ? editClickContractorFn
            : null,
          ishideThreeDotButton: hideThreeDotButton ? hideThreeDotButton : null,
          statusByOptions: statusByOptions ? statusByOptions : null,
          deleteCheck: deleteCheck ? deleteCheck : null,
          deleteFun: deleteFun ? deleteFunClick : null,
          optionType: optionType ? optionType : "",
          listname,
          navigate,
          viewLink: viewLink ? true : null,
          icons,
          updatePageLink,
          updateStatus,
          hideEditIcon,
          coalStatus,
          enableMobileIcon: enableMobileIcon ? enabledMobileClick : null,
          hideExceDownloadByRow,
          editPageLink,
          formClink,
        })
      );
    if (ationStatusCol) {
      const firstElement = cols.shift();
      cols.splice(cols.length - 1, 0, firstElement);
    }

    return cols;
  }, [
    fields,
    hideSnColumn,
    primaryKey,
    hideActionsColumn,
    pageLink,
    viewAsPopup,
    viewHistory,
    handleView,
    handleDelete,
    hideDeleteButton,
    hideViewButton,
    hideEditButton,
    showApproveButton,
    showRejectButton,
    permissionViewButton,
    permissionEditButton,
    permissionDeleteButton,
    permissionLoggedInButton,
    handleApprove,
    handleReject,
    mfiSettingIcon,
    downloadExcel,
    downloadExcelFn,
    formAFn,
    formBFn,
    formCFn,
    downloadPdf,
    downloadPdfFn,
    approveFun,
    approveClickFn,
    editFun,
    editFunButton,
    editClickContractorFn,
    editContractorPerformanceFun,
    editClickFn,
    hideThreeDotButton,
    listname,
    statusByOptions,
    deleteCheck,
    dgmsCheck,
    deleteFun,
    deleteFunClick,
    editAsPopup,
    handleEdit,
    viewLink,
    optionType,
    updatePageLink,
    updateStatus,
    enableMobileIcon,
    enabledMobileClick,
    hideExceDownloadByRow,
    editPageLink,
    formClink,
  ]);

  useEffect(() => {
    const columnsData = IS_MOBILE()
      ? columns?.filter((d) => d?.isShowMobile)
      : columns;
    setFinalColumns(columnsData);
  }, [columns]);

  /************ Server side pagination , sorting and searching*************/

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handlePerRowsChange = useCallback((newPerPage, page) => {
    setPerPage(newPerPage);
  }, []);

  const handleSort = useCallback((column, sortDirection) => {
    setSortColumn(column.sortField || column.selector);
    setSortDirection(sortDirection);
  }, []);

  useImperativeHandle(
    cref,
    () => ({
      getAll: () => getAll(),
    }),
    [getAll]
  );

  // Query param handle

  useEffect(() => {
    let text =
      `page=${page}&limit=${perPage}` +
      (search ? `&q=${search}` : "") +
      (sortColumn ? `&sortBy=${sortColumn}` : "") +
      (sortDirection ? `&orderBy=${sortDirection}` : "") +
      (coalGradeType ? `&type=${coalGradeType}` : "") +
      (pendingAction ? `&pendingAction=${pendingAction}` : "");

    setFilter(text);
  }, [
    page,
    perPage,
    search,
    sortColumn,
    sortDirection,
    location.pathname,
    coalGradeType,
    pendingAction,
  ]);

  useEffect(() => {
    const q = getQueryParam("q");
    setSearch(q || "");
    const perPage = getQueryParam("limit");
    setPerPage(paginationPerPageArray.includes(+perPage) ? +perPage : 10);
    const page = getQueryParam("page");
    setPage(+(page || 1));
    const sortBy = getQueryParam("sortBy");
    setSortColumn(sortBy || "");
    const orderBy = getQueryParam("orderBy");
    setSortDirection(orderBy || "");
  }, [location.search]);

  useEffect(() => {
    if (!filter) return;
    setLoading(true);
    getAll(filter);
  }, [filter, getAll]);

  const onChangeSearch = ({ target: { value } }) => {
    if (page !== 1 && value) setPage(1);
    setSearch(value);
  };

  useEffect(() => {
    if (getAllFilter) {
      getAll();
    }
  }, [getAll, getAllFilter]);

  return (
    <>
      {coIPage && childrenUnderTable && childrenUnderTable()}
      {!hideHeader && (
        <>
          {!IS_MOBILE() && !!breadCrumb && (
            <MainBreadcrumb
              routeSegments={breadCrumb}
              // module={ACTIVE_MODULE.incident}
            />
          )}
          {!customTableTitle ? (
            <h3
              className={`title mb-0 ${isNoTabOnPage ? "titleWtoutTab" : ""}`}
            >
              ${tableTitle} Data lists
            </h3>
          ) : customTableTitle !== "Blank" ? (
            <h3
              className={`title mb-0 ${isNoTabOnPage ? "titleWtoutTab" : ""}`}
            >
              {customTableTitle}
            </h3>
          ) : (
            ""
          )}

          <div className="contentHeading mb-2">
            <div className="tabSection">
              {childrenAfterAddButton && childrenAfterAddButton()}
            </div>
            <div className="mobSearch">
              {isMobile && !hideSearch && (
                <CustomSearch>
                  <div className="tblSearch">
                    <FormInputText
                      type="text"
                      name="search"
                      formGroupClass="mb-0 me-3"
                      value={search}
                      onChange={onChangeSearch}
                      // placeholder={searchPlaceholder(columns)}
                      placeholder={"Search"}
                    />
                  </div>
                </CustomSearch>
              )}
            </div>
            <div className="rightSideContent">
              {apiUpdateView && (
                <ApiStatusUpdateView
                  data={apiUpdateView}
                  hideApiUpdateStatus={hideApiUpdateStatus}
                />
              )}

              {actionBtn && (
                <>
                  {pendingAction ? (
                    <div
                      onClick={() => setPendingAction(0)}
                      className="approvalBtn pendingBtn"
                    >
                      <span>{`Pending Actions`}</span>
                    </div>
                  ) : (
                    <div
                      onClick={() => setPendingAction(1)}
                      className="approvalBtn approvedBtn"
                    >
                      <span>{`Pending Actions`}</span>
                    </div>
                  )}
                </>
              )}

              {!isMobile && !hideSearch && (
                <CustomSearch>
                  <div className="tblSearch">
                    <FormInputText
                      type="text"
                      name="search"
                      formGroupClass="mb-0 me-3"
                      value={search}
                      onChange={onChangeSearch}
                      placeholder={"Search"}
                    />
                  </div>
                </CustomSearch>
              )}
              {!isMobile && FilterBtn && FilterBtn()}
              {!hideAddButton &&
                (permissionAddButton ? (
                  <SetPermission
                    permission={permissionAddButton}
                    data={
                      <Button
                        data-testid="add-button"
                        onClick={() => navigate(`${pageLink}/create`)}
                        className="primaryBtn"
                      >
                        <AiOutlinePlus /> <span>{`Add ${title}`}</span>
                      </Button>
                    }
                  />
                ) : (
                  <Button
                    onClick={() => navigate(`${pageLink}/create`)}
                    className="primaryBtn"
                    data-testid="add-button"
                  >
                    <AiOutlinePlus /> <span>{`Add ${title}`}</span>
                  </Button>
                ))}
              {importExcelFile && (
                <Button
                  style={{ marginLeft: "14px" }}
                  onClick={() => importExcelFile()}
                  className="primaryBtn"
                >
                  {" "}
                  <BiImport /> <span>{`Import`}</span>
                </Button>
              )}
              {downloadExcel && (
                <span
                  onClick={() =>
                    downloadExcelFn(
                      excelFilterOption ? excelFilterOption : formFilter
                    )
                  }
                  className="btn-icon excel"
                >
                  <AiOutlineFileExcel />
                </span>
              )}

              {/* {downloadPdf && (
                <span onClick={() => downloadPdfFn()} className="btn-icon pdf">
                  <ImFilePdf />
                </span>
              )} */}
              {isMobile && FilterBtn && FilterBtn()}
            </div>
          </div>
        </>
      )}

      {childrenBeforeTable && childrenBeforeTable()}
      {!coIPage && childrenUnderTable && childrenUnderTable()}
      {customHeader === true && (
        <div className="d-flex">
          <span className="cildot"></span>CIL Lab
          <span className="thirddot"></span> Third Party Lab
          <span className="refdot"></span> Referee Lab
        </div>
      )}
      <PerfectScrollbar>
        <DataTable
          noDataComponent={
            <div style={{ padding: "24px" }}>
              {loading ? "Loading" : "There are no records to display"}
            </div>
          }
          // columns={columns}
          columns={finalColumns}
          data={rows}
          highlightOnHover={highlightOnHover}
          pagination={pagination}
          keyField={keyField || "id"}
          paginationServer={true}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          sortServer={true}
          onSort={handleSort}
          progressPending={loading}
          persistTableHead
          paginationComponent={CustomPagination}
          className={`slds-table ${
            customHeader === true ? "custom-table-header mt-3" : ""
          } `}
          paginationDefaultPage={page}
          expandableRows={IS_MOBILE() ? true : false}
          expandableIcon={{
            collapsed: <FiPlusCircle className="collapsedIcon" size={20} />,
            expanded: (
              <FiMinusCircle className="expandedIcon text-danger" size={20} />
            ),
          }}
          expandableRowsComponent={(data) => TABLE_EXPEND_ROW(data, columns)}
        />
      </PerfectScrollbar>

      <Modal
        toggle={toggleShowAddEdit}
        isOpen={showAddEdit}
        centered={rightViewPopup || leftViewPopup ? false : true}
        size={modalSize ? modalSize : "lg"}
      >
        <ModalHeader toggle={toggleShowAddEdit}>
          {" "}
          {editTitle || "Edit Data"}
        </ModalHeader>
        <ModalBody>
          {editAsPopup && editAsPopup(form, setShowAddEdit, getAll)}
        </ModalBody>
      </Modal>

      <Modal
        toggle={toggleShowView}
        isOpen={showView}
        centered={rightViewPopup || leftViewPopup ? false : true}
        className={`${
          rightViewPopup
            ? "modalRightSide"
            : leftViewPopup
            ? "modalLeftSide"
            : ""
        }`}
        size={modalSize ? modalSize : "lg"}
      >
        <ModalHeader toggle={toggleShowView}>
          {" "}
          {!viewTitle ? `${tableTitle} Data` : viewTitle}
        </ModalHeader>
        <ModalBody>{viewAsPopup && viewAsPopup(form,setShowView,getAll)}</ModalBody>
      </Modal>

      <Modal
        toggle={toggleShowHistory}
        isOpen={showHistory}
        centered={rightViewPopup || leftViewPopup ? false : true}
        className={`${
          rightViewPopup
            ? "modalRightSide"
            : leftViewPopup
            ? "modalLeftSide"
            : ""
        }`}
        size={modalSize ? modalSize : "lg"}
      >
        <ModalHeader toggle={toggleShowHistory}>History details</ModalHeader>
        <ModalBody>{viewHistory && viewHistory(form)}</ModalBody>
      </Modal>

      <Modal
        toggle={handleCloseApprove}
        isOpen={showApprove}
        size="lg"
        // centered
      >
        <ModalHeader toggle={handleCloseApprove}>{`Approve`}</ModalHeader>
        <ModalBody className="pb-2">
          <div className="preview">
            <h6>Are you sure to approve this record ?</h6>
            <div className="form-group my-2">
              <textarea
                className="form-control"
                type="text"
                value={approvalMessage}
                onChange={(event) => setApprovalMessage(event.target.value)}
                placeholder="Reason for approving the record (or any message)."
              />
              <InputError error={approvalMessageErr} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={handleCloseApprove} className="secondaryBtn">
            No
          </Button>
          <Button onClick={onApprove} className="primaryBtn">
            Yes
          </Button>
        </ModalFooter>
      </Modal>
      <Modal toggle={handleCloseReject} isOpen={showReject} size="lg" centered>
        <ModalHeader toggle={handleCloseReject}>{`Return`}</ModalHeader>
        <ModalBody className="pb-2">
          <div className="preview">
            <h6>Are you sure to return this record ?</h6>
            <div className="form-group my-2">
              <textarea
                className="form-control"
                type="text"
                value={approvalMessage}
                onChange={(event) => setApprovalMessage(event.target.value)}
                placeholder="Reason for returning the record (or any message)."
              />
              <InputError error={approvalMessageErr} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={handleCloseReject} className="secondaryBtn">
            No
          </Button>
          <Button onClick={onReject} className="primaryBtn">
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default DataTableWithServer;
