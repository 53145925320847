import React from "react";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import Select from "../../../../components/Select/Select";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import { evidenceTypes, evidenceTypesObj } from "../DATA";
import IncidentEvidence from "./IncidentEvidence";
import EvidencePreview from "./EvidencePreview";
import Asterik from "../../../../components/Common/Asterik/Asterik";

const IncidentEvidences = ({
  dataArray = [],
  errorArray = [],
  onChange = () => {},
  onChangeFile = () => {},
  onAdd = () => {},
  onDelete = () => {},
  viewOnly,
  isDocuments,
  isDocumentObj,
  source,
  asterikRules,
}) => (
  <div className="table-responsive">
    <table className="table table-bordered">
      <thead className="tableHead-bg">
        <tr>
          <th>Category</th>
          <th>
            {viewOnly ? "Description" : "Description/Upload File"}
            {!viewOnly ? (
              <Asterik fieldName={"description"} requiredRules={asterikRules} />
            ) : (
              ""
            )}
          </th>
          <th>File View</th>
          {!viewOnly && <th>Remove</th>}
        </tr>
      </thead>
      <tbody>
        {dataArray.length > 0 ? (
          dataArray.map((x, index) => {
            return x.deleted ? null : (
              <tr key={index}>
                <th>
                  {viewOnly ? (
                    <span>
                      {isDocuments
                        ? x.type
                        : isDocumentObj
                        ? evidenceTypesObj[x.type]
                        : evidenceTypes[x.type]}
                    </span>
                  ) : isDocuments ? (
                    <FormInputText
                      name="type"
                      value={x.type}
                      onChange={({ target: { name, value } }) =>
                        onChange({ name, value, index })
                      }
                      id="id"
                      disabled
                    ></FormInputText>
                  ) : (
                    <Select
                      name="type"
                      value={x.type}
                      onChange={({ target: { name, value } }) =>
                        onChange({ name, value, index })
                      }
                      // className="form-control"
                      disabled={!!x.fileId}
                      options={Object.keys(
                        isDocumentObj ? evidenceTypesObj : evidenceTypes
                      )}
                      object={isDocumentObj ? evidenceTypesObj : evidenceTypes}
                      init="none"
                      blankSelect="none"
                    ></Select>
                  )}
                </th>
                <td>
                  {viewOnly ? (
                    <span>{x.description}</span>
                  ) : (
                    <IncidentEvidence
                      onChangeFile={({ file }, responseData) => {
                        onChangeFile({ file, responseData, index });
                      }}
                      onChangeDescription={(value) =>
                        onChange({ index, name: "description", value })
                      }
                      source
                      description={x.description}
                      fileId={x.fileId}
                      index={index}
                      extensions={
                        evidenceTypes[x.type] === evidenceTypes.photo
                          ? ["jpg", "jpeg", "png"]
                          : evidenceTypes[x.type] === evidenceTypes.video
                          ? ["mp4"]
                          : x.type === "Document" || x.type === "document"
                          ? // ? ["doc", "html", "pdf", "txt", "csv", "xls", "xlsx"]
                            ["pdf"]
                          : []
                      }
                    />
                  )}
                  <span className="small text-danger">
                    {errorArray[index]?.description ||
                      errorArray[index]?.fileId}
                  </span>
                </td>
                <td style={{ width: "35%" }}>
                  {x.file && (
                    <EvidencePreview
                      videoHight={460}
                      file={x.file}
                      type={x.type}
                    />
                  )}
                </td>
                {/* <td style={{ width: "35%" }}>{x.file && ""}</td> */}
                {!viewOnly && (
                  <td className="text-center">
                    <div className="actionIconsGroup">
                      <span
                        data-testid={"remove-leaf" + index}
                        onClick={() => onDelete(index)}
                        className="icons font-21 text-danger"
                      >
                        <AiOutlineMinusCircle
                          size="24"
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </div>
                  </td>
                  // <td>
                  //   <div className="actionIconsGroup">
                  //     <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
                  //       <span onClick={() => onDelete(index)} className="pointer icons text-danger font-20">
                  //         <FiMinusCircle />
                  //       </span>
                  //     </OverlayTrigger>
                  //   </div>
                  // </td>
                )}
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="10" align="center">
              {/* <div className="alert alert-danger d-inline-block m-0">
                                <strong>No Evidence</strong>
                            </div> */}
            </td>
          </tr>
        )}
        {!viewOnly && (
          <tr>
            <td colSpan="10">
              <span className="addTxt" onClick={onAdd} data-testid="add-leaf2">
                <AiOutlinePlus />
                Add
              </span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default IncidentEvidences;
