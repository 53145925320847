import React from "react";
import InputError from "../InputError/InputError";
import "./checkbox.scss";

const CheckBoxField = ({
  name,
  checked,
  onChange,
  label,
  disabled,
  className,
  error,
  id,
  labelStyleML,
  labelStyleMB,
}) => {
  return (
    <>
      <div className="customCheckbox">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={className}
          id={`${id}_permission`}
        />
        <label
          style={{
            marginLeft: labelStyleML ? labelStyleML : "",
            marginBottom: labelStyleMB ? labelStyleMB : "",
          }}
          htmlFor={`${id}_permission`}
        >
          {label}
        </label>
      </div>
      <InputError error={error} />
    </>
  );
};

export default CheckBoxField;
