import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Button, Modal } from "react-bootstrap";

const MultiSelectWithCheckbox = ({
  className,
  options,
  value,
  onChange,
  disabled,
  label,
  disableSearch = true,
  hasSelectAll = true,
  headerLabel,
  limit,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <div>
        <input
          onClick={() => setShow(!disabled && true)}
          disabled={disabled ? disabled : false}
          value={value?.map((d) => d?.label)?.join(", ")}
          type="text"
          className={"form-control fieldDropDownIco"}
          placeholder={"Select"}
        />
      </div>

      <Modal className="singleSelectModal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h5>{headerLabel ? headerLabel : ""}</h5>
        </Modal.Header>
        <SelectModal
          className={className}
          disableSearch={disableSearch}
          disabled={disabled}
          label={label}
          hasSelectAll={hasSelectAll}
          options={options}
          setShow={setShow}
          onChangeSelect={onChange}
          value={value}
          limit={limit}
        />
      </Modal>
    </>
  );
};

const SelectModal = ({
  className,
  disableSearch,
  disabled,
  options,
  label,
  hasSelectAll,
  setShow,
  onChangeSelect,
  value,
  limit,
}) => {
  const [selectValue, setSelectValue] = useState(() => value);

  const saveFun = () => {
    onChangeSelect(selectValue);
    setShow(false);
  };

  const cancelFun = () => {
    setShow(false);
  };

  const onChange = (ob) => {
    setSelectValue(ob);
  };
  const selected = selectValue?.map((s) => {
    return s.label;
  });
  return (
    <>
      <Modal.Body>
        <div>
          <MultiSelect
            className={`iED-multiselect ${className || ""}`}
            options={options || []}
            // options={options.map((d) => ({
            //   ...d,
            //   disabled: selected
            //     ? selected.includes("Not Applicable")
            //       ? d.label === "Not Applicable"
            //         ? false
            //         : true
            //       : selected.length >= limit
            //       ? selected.includes(d.label)
            //         ? false
            //         : true
            //       : false
            //     : false,
            // }))}
            // value={
            //   selected?.includes("Not Applicable")
            //     ? [
            //         selectValue.find((s) => {
            //           return s.label === "Not Applicable";
            //         }),
            //       ]
            //     : selectValue || []
            // }
            value={selectValue || []}
            onChange={(e) => onChange(e)}
            labelledBy={"object"}
            disableSearch={disableSearch}
            disabled={!!disabled}
            menuIsOpen={true}
            overrideStrings={{
              selectSomeItems: label || "Select",
              allItemsAreSelected: "All items are selected.",
              selectAll: "Select All",
              search: "Search",
            }}
            hasSelectAll={hasSelectAll}
            // closeOnChangedValue={false}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelFun}>
          Cancel
        </Button>
        <Button variant="primary" onClick={saveFun}>
          Select
        </Button>
      </Modal.Footer>
    </>
  );
};
export default MultiSelectWithCheckbox;
