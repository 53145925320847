import React from "react";
import { Col, Modal, Button } from "react-bootstrap";
import { Row } from "reactstrap";
import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../_helpers/dateHelper";
import HindranceAudit from "./HindranceAudit";
import { useEffect } from "react";
import { useState } from "react";
import { hindranceHistory } from "../../../services/_hindrance/hindrance";
import { converEachFirstWordCapitalize } from "../../../_helpers/commonHelper";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
const HindranceHistory = ({ data,contractor=false }) => {
  const [historyData, setHistoryData] = useState([]);
  const [showAudit, setshowAudit] = useState(false);
  const getHistoryData = async () => {
    let response = await hindranceHistory(data?.id);
    setHistoryData(response?.data);
  };
  useEffect(() => {
    getHistoryData();
  }, [data]);
  return (
    <>
      <Modal.Body className="p-0 pb-5">
        <HindranceAudit historyData={historyData} contractor={contractor}/>
      </Modal.Body>
    </>
  );
};

export default HindranceHistory;
