import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { RxDotsVertical } from "react-icons/rx";
import DeletePopup from "../MessagePopup/DeletePopup";

const popover = (
  rowData,
  excelDownload,
  pdfDownload,
  onClickDownloadFormA,
  onClickDownloadFormB,
  onClickDownloadFormC,
  search,
  approveFun,
  editFun,
  id,
  editContractorPerformanceFun,
  editFunButton,
  deleteFun,
  editPopup,
  incidentUpdateStatus,
  optionTypeValue,
  updateStatus
) => (
  <Popover id="popover-basic" className="action-popup">
    <Popover.Body>
      {excelDownload && (
        <>
          <div
            onClick={() => excelDownload(search, rowData)}
            className="popoverList"
          >
            {" "}
            Download Excelsheet
          </div>
        </>
      )}
      {pdfDownload && (
        <>
          {/* <div className="seperator"></div> */}
          <div className="popoverList" onClick={() => pdfDownload(search)}>
            {" "}
            Download Pdf
          </div>
        </>
      )}

      {onClickDownloadFormA && (
        <>
          <div
            className="popoverList"
            onClick={() => onClickDownloadFormA(search)}
          >
            {" "}
            Download Form 4 - A
          </div>
        </>
      )}
      {onClickDownloadFormB && (
        <>
          <div
            className="popoverList"
            onClick={() => onClickDownloadFormB(search)}
          >
            {" "}
            Download Form 4 - B
          </div>
        </>
      )}
      {onClickDownloadFormC && (
        <>
          <div
            className="popoverList"
            onClick={() => onClickDownloadFormC(search)}
          >
            {" "}
            Download Form 4 - C
          </div>
        </>
      )}

      {approveFun && (
        <>
          {/* <div className="seperator"></div> */}
          <div className="popoverList" onClick={() => approveFun(id)}>
            {" "}
            Approve
          </div>
        </>
      )}
      {editFun && (
        <>
          {/* <div className="seperator"></div> */}
          <div
            className="popoverList"
            data-testid="edit-form"
            onClick={() => editFun(id)}
          >
            {" "}
            Edit
          </div>
        </>
      )}
      {editContractorPerformanceFun && (
        <>
          {/* <div className="seperator"></div> */}
          <div
            className="popoverList"
            onClick={() => editContractorPerformanceFun(id)}
            data-testid="edit-contractor-performance-btn"
          >
            {" "}
            Edit
          </div>
        </>
      )}
      {editFunButton && (
        <>
          {/* <div className="seperator"></div> */}
          <div
            className="popoverList"
            data-testid="edit-fun-btn"
            onClick={() => editFunButton(id)}
          >
            {" "}
            Edit
          </div>
        </>
      )}
      {deleteFun && (
        <>
          <DeletePopup
            deleteFun={deleteFun}
            id={id}
            optionTypeValue={optionTypeValue}
          />
          {/* <div className="seperator"></div>
          <div className="popoverList" onClick={() => deleteFun(id)}>
            {" "}
            Delete
          </div> */}
        </>
      )}
      {editPopup && <>{editPopup}</>}
      {incidentUpdateStatus && updateStatus && (
        <>
          <div className="popoverList" onClick={() => incidentUpdateStatus()}>
            {" "}
            Update Status
          </div>
        </>
      )}
    </Popover.Body>
  </Popover>
);

const ThreeDotPopover = ({
  excelDownload,
  pdfDownload,
  onClickDownloadFormA,
  onClickDownloadFormB,
  onClickDownloadFormC,
  search,
  approveFun,
  editFun,
  primaryKey,
  editContractorPerformanceFun,
  editFunButton,
  deleteFun,
  editPopup,
  incidentUpdateStatus,
  optionTypeValue,
  updateStatus,
  rowData,
}) => {
  // excelDownload ||
  //   pdfDownload ||
  //   approveFun ||
  //   editFun ||
  //   editContractorPerformanceFun ||
  //   editFunButton ||
  //   deleteFun ||
  //   editPopup ||
  //   (incidentUpdateStatus && updateStatus) ?
  return excelDownload ||
    pdfDownload ||
    onClickDownloadFormA ||
    onClickDownloadFormB ||
    onClickDownloadFormC ||
    approveFun ||
    editFun ||
    editContractorPerformanceFun ||
    editFunButton ||
    deleteFun ||
    editPopup ||
    (incidentUpdateStatus && updateStatus) ? (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={popover(
        rowData,
        excelDownload,
        pdfDownload,
        onClickDownloadFormA,
        onClickDownloadFormB,
        onClickDownloadFormC,
        search,
        approveFun,
        editFun,
        primaryKey,
        editContractorPerformanceFun,
        editFunButton,
        deleteFun,
        editPopup,
        incidentUpdateStatus,
        optionTypeValue
      )}
    >
      {/* <RxDotsVertical size={20} aria-describedby="popover-basic" /> */}
      <Button className="threedot">
        <RxDotsVertical size={20} />
      </Button>
    </OverlayTrigger>
  ) : (
    <div style={{ width: "45px" }}></div>
  );
};

export default ThreeDotPopover;
