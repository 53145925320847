import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { IS_MOBILE } from "../../_helpers/helper";

function CustomPopover(props) {
  const myPopover = (
    <Popover id="popover-basic" className={`customize-tooltip`}>
      <Popover.Body>{props?.tooltip}</Popover.Body>
    </Popover>
  );
  return (
    <>
      <OverlayTrigger
        trigger={IS_MOBILE() || props?.click ? "click" : ["hover", "focus"]}
        placement={props?.placement}
        overlay={myPopover}
      >
        <span>{props?.children}</span>
      </OverlayTrigger>
    </>
  );
}

export default CustomPopover;
