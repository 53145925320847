import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Container } from "react-bootstrap";
import { deviceApi, fuelLocationColumnFields } from "./DATA";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeviceView from "./DeviceView";
import DataTableWithServer from "../../../../../components/DataTableWithServer/DataTableWithServer";
import SafetySideBar from "../../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import EmptyDataList from "../../../../ContractorManagement/Hindrance/EmptyDataList";
import { SidebarData } from "../../DATA";
import { exportExportDevice, getAllDevice } from "../../../../../services/_uploadedFiles/uploadedFiles";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../../../_services/CommonService/CommonService";
import toastr from "toastr";

const DeviceList = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [lastSync, setLastSync] = useState({});
  const cref = useRef(null);


  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    let response;
    if (params?.id)
      response = await getAllDevice("page=1&limit=2&createdBy=" + params?.id);
    else response = await getAllDevice("page=1&limit=2");

    setLastSync(response?.lastSync?.data);
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const viewAsPopup = (form) => {
    return <DeviceView form={form} />;
  };

  const onEdit = (id) => {
    navigate("/environment-management/configuration/device/edit/" + id);
  };

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);

  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: 'env_devices',
        id: id,
        status: status
      }
      const response = await changeStatus(data);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const finalTabelColumns= useMemo(
    () => [
      ...fuelLocationColumnFields,
      {
        name: "Status",
        selector: "status",
        cell: (row) => {
          return (
            (row.status === 1 && (
              <span
                href="#"
                className="icons text-success pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOn
                  size="20"
                  data-testid={"toogle-user-list-on-" + row.id}
                />
              </span>
            )) ||
            (row.status === 0 && (
              <span
                href="#"
                className="icons text-danger pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOff
                  size="20"
                  data-testid={"toogle-user-list-off-" + row.id}
                />
              </span>
            ))
          );
        },
      },
    ],
    []
  );

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                downloadExcel={exportExportDevice}
                fields={finalTabelColumns}
                apis={deviceApi}
                title="Data"
                cref={cref}
                // FilterBtn={FilterBtn}
                hideAddButton={true}
                // viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/environment-management/configuration/device"}
                primaryKey="id"
                pagination={true}
                hideDeleteButton
                hideEditButton
                // hideAddButton
                getAllFilter={getAllFilter}
                breadCrumb={[
                  {
                    path: "/",
                    name: "Environment Management System",
                  },
                  {
                    name: "Configuration",
                    path: "/environment-management/configuration",
                  },
                  {
                    name: "Device",
                    path: "#",
                  },
                ]}
                customTableTitle={"Device"}
                editFunButton={onEdit}
                viewTitle={"Device Details"}
                isNoTabOnPage
                // statusByOptions={statusByOptions}
                hideExceDownloadByRow
                apiUpdateView={lastSync}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            hideAddBtn={true}
            pageLink={"/environment-management/configuration"}
            title={"Device"}
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceList;
