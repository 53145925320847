import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import {} from "../../../services/_contractorList/contractorList";
import { deleteNit, getAllNitList } from "../../../services/_nitList/nitList";
import { nitTenderIdColumn } from "../../../_helpers/helper";

export const nitListColumnFields = [
  {
    name: "Subsidiary",
    selector: "siteData",
    // sortable: true,
    cell: (row) => row?.siteData?.parentSite?.name,
    width: "115px",
  },

  {
    name: "Mine",
    selector: "siteData",
    // sortable: true,
    cell: (row) => row?.siteData?.name,
    width: "115px",
  },
  {
    name: "Contractor Code",
    selector: "contractor",
    // sortable: true,
    cell: (row) => row?.contractor?.contractor_code,
    width: "200px",
  },
  {
    name: "Contractor Name",
    selector: "contractor",
    // sortable: true,
    cell: (row) => row?.contractor?.contractor,
    width: "370px",
  },
  // {
  //   name: "NIT Master",
  //   selector: "nit",
  //   sortable: true,
  //   cell: (row) => (row?.nit ? row?.nit : ""),
  //   width: "210px",
  // },
  // {
  //   name: "Tender ID",
  //   selector: "tender_id",
  //   sortable: true,
  //   cell: (row) => (row?.tender_id ? row?.tender_id : ""),
  //   width: "210px",
  // },

  {
    name: "NIT / PO",
    selector: "nit",
    // sortable: true,
    minWidth: "430px",
    cell: (row) => nitTenderIdColumn(row),
  },
  {
    name: "Start Date",
    selector: "startDate",
    sortable: true,
    cell: (row) => (row?.startDate ? DDMMYYYYFormat(row?.startDate) : ""),
    width: "130px",
  },
  {
    name: "End Date",
    selector: "endDate",
    sortable: true,
    width: "130px",

    cell: (row) => (row?.endDate ? DDMMYYYYFormat(row?.endDate) : ""),
  },
];
export const formFields = {
  contractor: "",
  contractor_person: "",
  nit: "",
  startDate: "",
  endDate: "",
  site_id: "",
  contractor_code: "",
  contractor_id: "",
  tender_id: "",
  excavator: "",
  tipper: "",
  drill: "",
  dozer: "",
  waterTanker: "",
  grader: "",
  section: "",
  payLoader: "",
  production_type: "",
  crusher: "",
};

export const nitListApis = Object.freeze({
  getAll: {
    api: getAllNitList,
  },
  delete: {
    api: deleteNit,
  },
});

export const nitRules = [
  {
    maxNumber: 1000,
    field_name: "excavator",
    label: "Excavator",
    type: "number",
    isRequired: true,
    minNumber: 1,
  },
  {
    maxNumber: 1000,
    field_name: "crusher",
    label: "Crusher",
    type: "number",
    isRequired: true,
    minNumber: 1,
  },
  {
    type: "number",
    field_name: "tipper",
    label: "Tipper",
    isRequired: true,
    maxNumber: 1000,
    minNumber: 1,
  },
  {
    field_name: "drill",
    label: "Drill",
    type: "number",
    isRequired: true,
    maxNumber: 1000,
    minNumber: 1,
  },
  {
    label: "Dozer",
    type: "number",
    field_name: "dozer",
    isRequired: true,
    maxNumber: 1000,
    minNumber: 1,
  },
  {
    minNumber: 1,
    isRequired: true,
    field_name: "payLoader",
    label: "Pay Loader",
    maxNumber: 1000,
    type: "number",
  },
  {
    field_name: "nit",
    type: "string",
    isRequired: true,
    label: "NIT Number",
  },
  {
    field_name: "contractor_id",
    label: "Contractor",
    type: "text",
    isRequired: true,
  },
  {
    field_name: "startDate",
    label: "Start Date",
    // type: "date",
    isRequired: true,
  },
  {
    field_name: "endDate",
    label: "End Date",
    // type: "date",
    isRequired: true,
  },
  {
    field_name: "tender_id",
    label: "Contractor ID",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "production_type",
    label: "Production Type",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "waterTanker",
    label: "Water Tanker",
    type: "number",
    isRequired: true,
    maxNumber: 1000,
    minNumber: 1,
  },
  {
    field_name: "grader",
    label: "Grader",
    type: "number",
    isRequired: true,
    maxNumber: 1000,
    minNumber: 1,
  },
  {
    field_name: "section",
    label: "Section / Patch",
    type: "integer",
    isRequired: true,
  },
  {
    field_name: "site_id",
    label: "Mine",
    type: "integer",
    isRequired: true,
  },
];

export const NitMasterFilterForm = {
  startDate: "",
  endDate: "",
  contractor_id: "",
  // status: "",
};

export const sectionList = [
  {
    id: "east",
    section: "East",
  },
  {
    id: "west",
    section: "West",
  },
];

export const production_option = [
  {
    value: "1",
    label: "Coal",
  },
  {
    value: "2",
    label: "OB",
  },
  {
    value: "3",
    label: "Coal And OB",
  },
];

export const production_types = {
  1: "Coal",
  2: "OB",
  3: "Coal And OB",
};
