import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { UserService } from "../../_services/UserService/UserService";
import toastr from "toastr";
import { DDMMYYYYHHMMAFormat } from "../../_helpers/dateHelper";
const CreatedByCreatedAt = ({ id, table, border = false }) => {
  const [createdByData, setCreatedByData] = useState({});

  const getData = async () => {
    let response = await UserService.getCreatedBy(id, table);
    if (response.status) setCreatedByData(response.data);
    else
      toastr.error(
        "Error occurred for fetching created by data for this record"
      );
  };

  useEffect(() => {
    getData();
  }, [id, table]);

  return (
    <div className={border ? "preview" : ""}>
      <div
        className={
          !!createdByData?.createdBy || !!createdByData?.updatedBy
            ? "previewContent"
            : ""
        }
      >
        {createdByData?.createdAt &&
        createdByData?.createdBy?.email &&
        (createdByData?.createdBy?.firstName ||
          createdByData?.createdBy?.lastName) ? (
          <Row>
            <Col>
              <label>Created At</label>
              <p>{DDMMYYYYHHMMAFormat(createdByData?.createdAt)}</p>
            </Col>
            <Col>
              <label>Created By</label>
              <p>
                {createdByData?.createdBy?.firstName +
                  " " +
                  createdByData?.createdBy?.lastName}{" "}
                &lt;{createdByData?.createdBy?.email}&gt;
              </p>
            </Col>
          </Row>
        ) : (
          ""
        )}

        {createdByData?.updatedAt &&
        createdByData?.updatedBy?.email &&
        (createdByData?.updatedBy?.firstName ||
          createdByData?.updatedBy?.lastName) ? (
          <Row>
            <Col>
              <label>Last Updated At</label>
              <p>{DDMMYYYYHHMMAFormat(createdByData?.updatedAt)}</p>
            </Col>
            <Col>
              <label>Last Updated By</label>
              <p>
                {createdByData?.updatedBy?.firstName +
                  " " +
                  createdByData?.updatedBy?.lastName}{" "}
                &lt;{createdByData?.updatedBy?.email}&gt;
              </p>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CreatedByCreatedAt;
