import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../../components/Select/Select";
import { capaStatusView } from "../DATA";

const CapaFilterForm = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
  contractorList,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>CAPA Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              name="endDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={12}>
            <Select
              label={"Status"}
              onChange={onChange}
              value={form?.capaStatus}
              data-testid="filter-button-capa-status"
              name="capaStatus"
              options={Object.keys(capaStatusView)?.map((key) => ({
                label: capaStatusView[key],
                value: key,
              }))}
            />
          </Col>
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              onClick={onResetFilter}
              type="button"
              className="secondaryBtn"
            >
              Clear all
            </Button>
          </Col>
          <Col>
            <Button
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0"
              type="button"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default CapaFilterForm;
