import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { coalGradeLabel } from "../DATA";

const SampleDecodingFilterForm = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>{coalGradeLabel?.date_given_for_test}</Form.Label>
        <Row>
          <Col sm={6}>
            <CustomDatePicker
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col sm={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              name="endDate"
              onChangeDate={onChange}
            />
          </Col>
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              type="button"
              onClick={onResetFilter}
              className="secondaryBtn"
              data-testid="reset-filter-button"
            >
              Clear all
            </Button>
          </Col>
          <Col className="justify-content-end d-flex">
            <Button
              type="button"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default SampleDecodingFilterForm;
