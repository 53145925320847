import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { PAGE_TYPE_BY_CHART_LIST, pollutionPageChartList } from "./DATA";
import Select from "../../../components/Select/Select";
import {
  BasicBarChartCardWithCustomToolTip,
  MultipleBarChartCard,
} from "../../../components/ChartCard/ChartCard";
import { isArray } from "lodash";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import { SimpleBarChart } from "../../../components/Common/SimpleBarChart";

const PerformanceReportOfPollutionDas = ({
  data,
  form,
  onChange,
  pageType,
}) => {
  // console.log(pollutionPageChartList,pollutionPageChartList);
  // console.log('dataa' , data);
  return (
    <div className="dashboardWrapper">
      <Row id={`${"screen" + pageType}`}>
        {pollutionPageChartList?.map((d, idx) => {
          return (
            <Col md={6} key={idx} id={`zoomSec_${idx}`}>
              <Card className="chartCard chartCardBtmSpace CAAQMS_chart">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>{d?.name}</span>
                  <div className="filterZoomSec">
                    {!d?.hideFilter && (
                      <Col className="mbRemove">
                        <Select
                          value={form?.[d?.value]}
                          options={d?.options}
                          // label={"Incident Type"}
                          name={d?.value}
                          onChange={onChange}
                          blankSelect={"none"}
                        />
                      </Col>
                    )}
                    <ZoomScreen id={`zoomSec_${idx}`} />
                  </div>
                </Card.Header>
                <Card.Body className="pb-5">
                  {d?.type === "multiLine" && (
                    <MultipleBarChartCard
                      seriesData={
                        isArray(data?.[d?.value]?.data) &&
                        data[d?.value]?.data?.map((d) => ({
                          ...d,
                          // type: item.type === "multiLine" ? d.type : item.type,
                        }))
                      }
                      category={data?.[d?.value]?.categoryData}
                      yAxisName={d?.yAxisName}
                      customToolTip
                      minMaxData={data?.[d?.value]?.minMaxData}
                      // xAxisName={item?.xAxisName}
                    />
                  )}
                  {d?.type === "barWithCustomTooltip" && (
                    <SimpleBarChart
                      yAxisName={d?.yAxisName}
                      xAxis={data[d?.value]?.xAxis}
                      series={data[d?.value]?.series}
                      legend={data[d?.value]?.legend}
                      customTooltip
                      height="270px"
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default PerformanceReportOfPollutionDas;
