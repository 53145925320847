import { IS_MOBILE } from "../../../_helpers/helper";
import { sections } from "../ContractorPerformance/DATA";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import {
  getAllHindrance,
  getAllMyActionHindrance,
  getAllMyHindrance,
  getAllMyHistoryHindrance,
} from "../../../services/_hindrance/hindrance";
import { TenderIdColumn } from "../../../_helpers/helper";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import selectionIcon1 from "../../../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../../../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../../../Assets/Images/selectionIcon3.svg";
import selectionIcon4 from "../../../Assets/Images/selectionIcon4.svg";
import selectionIcon5 from "../../../Assets/Images/selectionIcon5.svg";
import CustomProgressBar from "../../../components/Common/CustomProgressBar/CustomProgressBar";
import { SidebarData as SidebarData1 } from "../../ContractorManagement/DATA";
export const statusProgressObj = {
  representative: "25.00",
  section_incharge: "50.00",
  colliery_manager: "75.00",
  approved: "100.00",
  draft: "N/A",
};

export const addContractorsHours = (data, arrProperty) => {
  let sum = 0;

  data?.forEach((reason) => {
    sum += reason[arrProperty];
  });
  return sum || 0;
};

export const switchResponsiblity = (data) => {
  switch (data.current_label) {
    case "approved":
      return "hrs_of_hindrance_by_colliery_manager";

    case "colliery_manager":
      return "hrs_of_hindrance_by_section_incharge";

    case "section_incharge":
      return "hrs_of_hindrance_by_representative";

    case "representative":
      return "nill";

    default:
      return "";
  }
};
export const exportContractorActionHindrance = async (filter) => {
  try {
    let url = "/contractor-hindrance/export-contractor-hindrance";
    filter += "&tableName=action";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const exportContractorHistoryHindrance = async (filter) => {
  try {
    let url = "/contractor-hindrance/export-contractor-hindrance";
    filter += "&tableName=history";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const exportContractorHindrance = async (filter) => {
  try {
    let url = "/contractor-hindrance/export-contractor-hindrance";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export const exportMyContractorHindrance = async (filter) => {
  try {
    let url = "/contractor-hindrance/export-my-contractor-hindrance";
    if (filter) url += "?" + filter;
    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
export const hindranceApis = Object.freeze({
  getAll: {
    api: getAllHindrance,
  },
});
export const myHindranceApis = Object.freeze({
  getAll: {
    api: getAllMyHindrance,
  },
});
export const myActionHindranceApis = Object.freeze({
  getAll: {
    api: getAllMyActionHindrance,
  },
});
export const myHindranceHistoryApis = Object.freeze({
  getAll: {
    api: getAllMyHistoryHindrance,
  },
});
export const performanceStatus = {
  draft: "Draft",
  representative: "Representative ",
  section_incharge: "Section Incharge ",
  colliery_manager: "Colliery Manager ",
  approved: "Approved",
};

export const commenStatus = {
  draft: "Draft",
  representative: "representative_comments",
  section_incharge: "section_incharge_comments",
  colliery_manager: "colliery_manager_comments",
  approved: "Approved",
};
export const pastSatus = {
  representative: "Vendor",
  section_incharge: "Representative",
  colliery_manager: "Section Incharge",
};

export const fields = [
  {
    name: "Subsidiary",
    selector: "site_id",
    minWidth: "140px",
    sortable: true,
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    sortable: true,
    selector: "name",
    minWidth: "130px",
    cell: (row) => row?.site?.name || "",
  },
  {
    name: "NIT / PO",
    selector: "nit",
    // sortable: true,
    minWidth: "250px",
    cell: (row) => TenderIdColumn(row),
  },
  {
    name: "Contractor",
    sortable: true,
    selector: "contractor",
    // minWidth: "270px",
    minWidth: "300px",
    cell: (row) => row?.contractor?.contractor || "",
  },
  {
    name: "Date",
    minWidth: "130px",
    selector: "input_date",
    sortable: true,
    cell: (row) => (row?.input_date ? DDMMYYYYFormat(row?.input_date) : ""),
  },

  {
    name: "Progress",
    selector: "progress",
    minWidth: "150px",
    // sortable: true,
    cell: (row) => {
      return row?.status === "draft" ? (
        statusProgressObj[row?.current_label]
      ) : (
        <CustomProgressBar value={statusProgressObj[row?.current_label]} />
      );
    },
  },
  {
    name: "Vendor Hours",
    selector: "hrs_of_hindrance",
    minWidth: "140px",
    center: true,
    cell: (row) =>
      row?.contractor_hindrance_reasons
        ? addContractorsHours(
            row?.contractor_hindrance_reasons,
            "hrs_of_hindrance"
          )
        : "",
  },
  {
    name: "Approved Hours",
    minWidth: "160px",
    center: true,
    cell: (row) =>
      row?.contractor_hindrance_reasons
        ? addContractorsHours(
            row?.contractor_hindrance_reasons,
            switchResponsiblity(row)
          )
        : "",
  },
  // {
  //   name: "Status",
  //   sortable: true,
  //   selector: "current_label",
  //   minWidth: "200px",
  //   cell: (row) => (
  //     <>
  //       <span className="statusSec draft">
  //         {row?.isBack ? "Returned" : performanceStatus[row?.current_label]}
  //       </span>
  //     </>
  //   ),
  // },
];

export const initialForm = {
  reason: "",
  hoursOfHindrance: "",
  comment: "",
};

export const resons = [
  {
    value: "Traffic Jam/ Road Repairing",
    label: "Traffic Jam/ Road Repairing",
  },
  { value: "Blasting", label: "Blasting" },
  { value: "Rainfall/ Wet Weather", label: "Rainfall/ Wet Weather" },
  { value: "Local Disturbance", label: "Local Disturbance" },
  { value: "Reason 5", label: "Reason 5" },
  { value: "Reason 7", label: "Reason 7" },
  { value: "Reason 8", label: "Reason 8" },
  { value: "Reason 9", label: "Reason 9" },
  { value: "Reason 6", label: "Reason 6" },
];

export const hindranceRule = [
  {
    field_name: "reason",
    label: "reason",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "hrs_of_hindrance",
    label: "hrs_of_hindrance",
    type: "integer",
    isRequired: true,
  },
  {
    field_name: "vendor_comments",
    label: "Comment",
    type: "string",
    isRequired: true,
  },
];

export const initialFilters = {
  startDate: "",
  endDate: "",
  mode_of_dispatch: "",
  current_label: "",
  contractor_id: "",
};

export const addInitialFields = {
  reason: "",
  hrs_of_hindrance: null,
  vendor_comments: "",
};

export const changedHour = {
  acceptedHours: "",
};

export const contractorPerformanceFilterForm = {
  startDate: "",
  endDate: "",
  contractor_id: "",
  current_label: "",
};

export const SidebarData = SidebarData1;
export const hindranceFlowList = [
  {
    img: selectionIcon1,
    label: "Add Contractor Hindrance",
    link: "/contractor-management/contractor-hindrance/create",
    permission: [
      // "contractor",
      "contractor_hindrance",
    ],
  },
  {
    img: selectionIcon2,
    label: "My Contractor Hindrance",
    link: "/contractor-management/my-contractor-hindrance",
    permission: [
      // "contractor",
      "contractor_hindrance",
    ],
  },
  {
    img: selectionIcon3,
    label: "View Contractor Hindrance",
    link: "/contractor-management/contractor-hindrance",
    permission: ["contractor_module_admin"],
  },
  {
    img: selectionIcon3,
    label: "My Approval Action",
    link: "/contractor-management/my-approval-action-contractor-hindrance",
    permission: ["section_incharge", "representative", "colliery_manager"],
  },
  {
    img: selectionIcon3,
    label: "My Approval History",
    link: "/contractor-management/my-approval-history-contractor-hindrance",
    permission: ["section_incharge", "representative", "colliery_manager"],
  },
];

export const statusUpdateLink = `${
  IS_MOBILE() ? "/mobile" : ""
}/contractor-management/contractor-hindrance/approve/representative`;

export const shiftOpt = [
  {
    value: 1,
    label: "1st Shift",
  },
  {
    value: 2,
    label: "2nd Shift",
  },
  {
    value: 3,
    label: "Night Shift",
  },
  {
    value: 4,
    label: "Not Applicable",
  },
];

export const shiftsName = {
  1: "1st Shift",
  2: "2nd Shift",
  3: "Night Shift",
  4: "Not Applicable",
};

export const checkHrsOfHindrance = (data, type) => {
  const shiftHours = { 1: 0, 2: 0, 3: 0 };
  let totalHours = 0;
  for (const entry of data) {
    const shift = entry.shift;
    const hindranceHours = parseInt(entry[type], 10);

    if (!shiftHours[shift]) {
      shiftHours[shift] = 0;
    }
    if (+entry.shift !== 4) {
      shiftHours[shift] += hindranceHours;
    }
    totalHours += hindranceHours;
  }

  for (const shift in shiftHours) {
    if (shiftHours[shift] > 8) {
      return ` ${
        shift == 3 ? "Night Shift" : shift == 2 ? "2nd Shift" : "1st Shift"
      }  exceeded 8 hours of hindrance.`;
    }
  }

  if (totalHours > 24) {
    return "Total hours of hindrance exceeded 24 hours.";
  }

  return "validated";
};

export const hrsToCheck = {
  representative: "hrs_of_hindrance_by_representative",
  section_incharge: "hrs_of_hindrance_by_section_incharge",
  colliery_manager: "hrs_of_hindrance_by_colliery_manager",
};
