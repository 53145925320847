import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { groundWaterPageChartList, quarterlyObj } from "./DATA";
import Select from "../../../components/Select/Select";
import {
  BasicBarChartCardWithCustomToolTip,
  MultipleBarChartCard,
} from "../../../components/ChartCard/ChartCard";
import { isArray } from "lodash";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import { SimpleBarChart } from "../../../components/Common/SimpleBarChart";

const GroundWaterLevelCmpdilDas = ({ data, form, onChange, pageType }) => {
  console.log("datas", data);
  return (
    <div className="dashboardWrapper">
      <Row id={`${"screen" + pageType}`}>
        {groundWaterPageChartList?.map((d, idx) => {
          return (
            <Col md={6} key={idx} id={`zoomSec_${idx}`}>
              <Card className="chartCard CMPDIL_chart chartCardBtmSpace">
                <Card.Header className="d-flex justify-content-between">
                  <span>{d?.name}</span>
                  <div className="filterZoomSec">
                    {!d?.hideFilter && (
                      <Col className="mbRemove">
                        <Select
                          options={d?.options}
                          name={d?.value}
                          value={form?.[d?.value]}
                          onChange={onChange}
                          // label={"Incident Type"}
                          blankSelect={"none"}
                        />
                      </Col>
                    )}
                    <ZoomScreen id={`zoomSec_${idx}`} />
                  </div>
                </Card.Header>
                <Card.Body className="pb-5">
                  {d?.type === "multiLine" && (
                    <MultipleBarChartCard
                      customToolTip
                      yAxisName={d?.yAxisName}
                      // xAxisName={item?.xAxisName}
                      category={data?.[d?.value]?.categoryData?.map((d) =>
                        d?.replace(
                          d?.split("-")?.[0],
                          quarterlyObj[d?.split("-")?.[0]]
                        )
                      )}
                      seriesData={
                        isArray(data?.[d?.value]?.data) &&
                        data[d?.value]?.data.map((d) => ({
                          ...d,
                          // type: item.type === "multiLine" ? d.type : item.type,
                        }))
                      }
                      minMaxData={data?.[d?.value]?.minMaxData}
                      inverseType
                    />
                  )}
                  {d?.type === "barWithCustomTooltip" && (
                    <SimpleBarChart
                      yAxisName={d?.yAxisName}
                      xAxis={data[d?.value]?.xAxis}
                      series={data[d?.value]?.series}
                      legend={data[d?.value]?.legend}
                      customTooltip
                      height="270px"
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default GroundWaterLevelCmpdilDas;
