import moment from "moment";

/*
 * get two date (start and end) as parameters
 * return true if start < end (less) else false
 */
export const isBefore = (start, end) => {
  return moment(start).isBefore(end); // true
};
export const isSameOrBefore = (start, end) => {
  return moment(start).isSameOrBefore(end); // true
};
export const isAfter = (start, end) => {
  return moment(start).isAfter(end); // true
};
/*
 * get two date (start and end) as parameters
 * return true if start >= end (less) else false
 */
export const isSameOrAfter = (start, end) => {
  return moment(start).isSameOrAfter(end); // true
};

/*
 * get 3 date (date,start and end) as parameters
 * return true if date lie bw start and end else flas
 */
export const isBetween = (date, start, end) => {
  return moment(date).isBetween(start, end, undefined, "[]"); // true
};

export const YYYYMMDDFormat = (date) => {
  return moment(date).format("YYYY-MM-DD"); // true
};

export const YYYYMMDDHHMMAFormat = (date) => {
  return moment(date).format("YYYY-MM-DD hh:mm A"); // true
};

export const DDMMYYYYHHMMAFormat = (date) => {
  return moment(date).format("DD-MM-YYYY hh:mm A"); // true
};

export const DDMMYYYYFormat = (date) => {
  return date
    ? moment(date).format("DD-MM-YYYY")
    : moment().format("DD-MM-YYYY"); // true
};

export const DDMMYYYYFormatBackslash = (date) => {
  return date
    ? moment(date).format("DD/MM/YYYY")
    : moment().format("DD/MM/YYYY"); // true
};

export const disableFutureDate = (date) => {
  return date.isSameOrBefore(YYYYMMDDFormat());
};

export const calaculateDatesDifference = (date) => {
  const startDate = moment(date, "YYYY-MM-DD");
  return moment().diff(startDate, "years");
};

export const DDMMYYYYHHMMAExcelFormat = (date) => {
  return moment(date).format("DD/MM/YYYY HH:mm:ss ");
};

export const MonthYearFormat = (date) => {
  return moment(date, "YYYY-MM-DD").format("MMM-YYYY");
};

export const checkDatesIsBetween = (checkDate, startDate, endDate) => {
  const checkDateParts = checkDate?.split("-");
  const startDateParts = startDate?.split("-");
  const endDateParts = endDate?.split("-");

  let checkDateObj;
  let startDateObj;
  let endDateObj;

  if (checkDateParts) {
    checkDateObj = new Date(
      checkDateParts[2], // Year
      checkDateParts[1] - 1, // Month (subtract 1 since months are 0-indexed)
      checkDateParts[0] // Day
    );
  }

  if (startDateParts) {
    startDateObj = new Date(
      startDateParts[2],
      startDateParts[1] - 1,
      startDateParts[0]
    );
  }

  if (endDateParts) {
    endDateObj = new Date(
      endDateParts[2],
      endDateParts[1] - 1,
      endDateParts[0]
    );
  }
  return checkDateObj >= startDateObj && checkDateObj <= endDateObj;
};

export const checkMonthYearIsBetween = (checkDate, startDate, endDate) => {
  checkDate = moment(checkDate, "DD-MM-YYYY");
  startDate = moment(startDate, "DD-MM-YYYY");
  endDate = moment(endDate, "DD-MM-YYYY");

  const checkMonthYear = moment(checkDate).format("YYYY-MM");
  const startMonthYear = moment(startDate).format("YYYY-MM");
  const endMonthYear = moment(endDate).format("YYYY-MM");

  if (checkMonthYear === startMonthYear || checkMonthYear === endMonthYear) {
    return true;
  } else {
    if (
      moment(checkMonthYear).isSameOrAfter(startMonthYear, "month") &&
      moment(checkMonthYear).isSameOrBefore(endMonthYear, "month")
    ) {
      return true;
    } else {
      return false;
    }
  }
};
