import React from "react";
import SelectionFlow from "./SelectionFlow/SelectionFlow";
import { IS_MOBILE } from "../_helpers/helper";
import MobHeader from "../components/Header/MobHeader";

const Dashboard = () => {
  return <>
    {IS_MOBILE() ?
      <div className="mobileSidebarWrapper">
        <MobHeader />
        <div className="content" id="page-content-wrapper">
          <SelectionFlow />
        </div>
      </div>

      :

      <SelectionFlow />
    }
  </>;
};
export default Dashboard;
