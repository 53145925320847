import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SidebarData, coalQualityActionsList } from "./DATA";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../components/Breadcrumb/MainBreadcrumb";
import { converEachFirstWordCapitalize } from "../../_helpers/commonHelper";
import toastr from "toastr";

const CoalGradeActionList = () => {
    const { type } = useParams();
    const [tabs, setTabs] = useState([...coalQualityActionsList]);
    const navigator = useNavigate();

    useEffect(() => {
        if (type === "consolidated") {
            toastr.error("Unauthorized");
            navigator(-1);
        }

        setTabs(
            coalQualityActionsList
                ?.map((d) => {
                    if (d?.link) return { ...d, link: d?.link.replace(":type", type) }
                    else return d
                })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="sidebarWrapper">
                <SafetySideBar SidebarData={SidebarData} />
                <div className="content" id="page-content-wrapper">
                    <div className="contentArea">
                        <Container fluid>
                            <h3 className="title" style={{ paddingBottom: "1px", paddingTop: "5px" }}>
                                <MainBreadcrumb
                                    routeSegments={[
                                        {
                                            name:"Coal Grade",
                                            path: "/",
                                        },
                                        {
                                            name: "Coal Quality",
                                            path: "/coal-grade/list",
                                        },
                                        {
                                            name: `${converEachFirstWordCapitalize(type, "-")}`,
                                            path: "#",
                                        }
                                    ]}
                                />
                            </h3>
                            <div className="MgmtWrapper pt-4">
                                <Row className="dashboardItem">
                                    {tabs?.map((d, idx) => {
                                        return (
                                            <Col lg={4} key={idx} className="mb-4">
                                                <Link to={d?.link}>
                                                    <div className="dashboardItemContent">
                                                        <div className="imgArea">
                                                            <img
                                                                src={d?.img}
                                                                className="img-fluid"
                                                                alt="dashboardItemIcons"
                                                            />
                                                        </div>
                                                        <label>{d?.label}</label>
                                                    </div>
                                                </Link>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CoalGradeActionList;
