import { RestMethod } from "../../../../_helpers/ApiConfig/RestMethod";
import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";
import {
  deleteSensor,
  getAllFuelSensor,
  getAllReceipt,
  getFuelSensorDataBydataId,
  getMyReceipt,
  getReceiptDataBydataId,
} from "../../../../services/_fuelDispense/fuelDispense";
export const initialForm = {
  tank_id: "",
  site_id: "",
  supplier_id: "",
  receiptDate: "",
  receiptQyt: null,
  invoiceDate: "",
  invoiceNumber: null,
};
export const initialMaxObj = {
  availableCapacity: "",
  maxCapacity: "",
  tankLevel: "",
};
export const fuelSensorLabel = {
  hemm_id: "Hemm Id",
  date: "Date",
  time: "Time",
  fuel_level: "Fuel Level (L)",
  createdAt: "Created At",
};
export const validationRules = [
  {
    label: fuelSensorLabel.hemm_id,
    type: "string",
    isRequired: true,
    field_name: "hemm_id",
  },
  {
    field_name: "date",
    label: fuelSensorLabel.date,
    isRequired: true,
    type: "string",
  },
  {
    label: fuelSensorLabel.time,
    field_name: "time",
    type: "string",
    isRequired: true,
  },
  {
    label: fuelSensorLabel.fuel_level,
    field_name: "fuel_level",
    type: "string",
    isRequired: true,
  },
  {
    label: fuelSensorLabel.createdAt,
    field_name: "createdAt",
    type: "string",
    isRequired: true,
  },
];

export const fuelSensorApi = {
  getAll: {
    api: getAllFuelSensor,
  },
  getById: {
    api: getFuelSensorDataBydataId,
  },
  delete: {
    api: deleteSensor,
  },
};

export const myReceiptApi = {
  getAll: {
    api: getMyReceipt,
  },
  getById: {
    api: getReceiptDataBydataId,
  },
};

export const fuelSensorFields = [
  {
    name: fuelSensorLabel.hemm_id,
    selector: "hemm_id",
    sortable: true,
    cell: (row) => row?.hemm_id,
    minWidth: "150px",
  },
  {
    name: fuelSensorLabel.date,
    selector: "date",
    sortable: true,
    cell: (row) => row?.date && DDMMYYYYFormat(row?.date),
    minWidth: "150px",
  },
  {
    name: fuelSensorLabel.time,
    selector: "time",
    sortable: true,
    cell: (row) => row?.time,
    minWidth: "150px",
  },
  {
    name: fuelSensorLabel.fuel_level,
    selector: "fuel_level",
    sortable: true,
    cell: (row) => row?.fuel_level,
    minWidth: "250px",
  },
];

export const fuelSensorFilterForm = {
  startDate: "",
  endDate: "",
};

export const exportFuelSensor = async (filter) => {
  try {
    let url = "/fuel-sensor/export-data";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      // column: response?.data?.data?.column,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
