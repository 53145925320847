import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../components/Select/Select";
import { performanceStatus } from "./DATA";
const ContractorPerformanceFilterForm = ({
  onSubmitFilter,
  onResetFilter,
  form,
  onChange,
  isOpen,
  setOpen,
  contractorList,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>Input Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              name="startDate"
              onChangeDate={onChange}
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              name="endDate"
              dateFormat="YYYY-MM-DD"
              value={form?.endDate}
              onChangeDate={onChange}
            />
          </Col>
          <Col md={12}>
            <Select
              onChange={onChange}
              value={form?.contractor_id}
              options={contractorList}
              label={"Contractor"}
              name="contractor_id"
            />
          </Col>
          <Col md={12}>
            <Select
              label={"Status"}
              value={form?.status}
              name="status"
              onChange={onChange}
              options={Object.keys(performanceStatus)?.map((key) => ({
                label: performanceStatus[key],
                value: key,
              }))}
            />
          </Col>
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              className="secondaryBtn"
              data-testid = "reset-filter"
              onClick={onResetFilter}
              type="button"
            >
              Clear all
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              className="primaryBtn me-0"
              data-testid='submit-filter'
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default ContractorPerformanceFilterForm;
