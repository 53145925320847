import React, { useState } from "react";
// import path from "path";
import {
  AiFillFileExcel,
  AiOutlineFile,
  AiOutlineFileJpg,
  AiOutlineFilePdf,
  AiOutlineFileWord,
} from "react-icons/ai";
import { Button, Modal, Row } from "react-bootstrap";
import { FcVideoFile } from "react-icons/fc";
import { URL_CONFIG } from "../../../../_constants/Config/URL_CONFIG";
import PopUpFile from "../../../../components/PopUpFile/PopUpFile";
import { evidenceTypes } from "../DATA";
import { PARSE_IMG_URL } from "../../../../_helpers/commonHelper";
import PdfPopup from "../../../../components/PdfPopup/PdfPopup";

const docType = (name, size = 50, color = "", classNam = "") => {
  let extenstion = name.split(".").pop().toLowerCase();
  // let extenstion = path.extname(name);
  // let extenstion = "";
  extenstion = extenstion ? extenstion.toLowerCase() : extenstion;

  if (extenstion) {
    if (extenstion === "jpg" || extenstion == "jpeg" || extenstion == "jpeg") {
      return (
        <AiOutlineFileJpg size={size} color={color} className={classNam} />
      );
    } else if (extenstion === "pdf") {
      return (
        <AiOutlineFilePdf size={size} color={color} className={classNam} />
      );
    } else if (extenstion === "doc" || extenstion == "docx") {
      return (
        <AiOutlineFileWord size={size} color={color} className={classNam} />
      );
    } else if (extenstion === "xls" || extenstion == "xlsx") {
      return <AiFillFileExcel size={size} color={color} className={classNam} />;
    } else {
      return <AiOutlineFile size={size} color={color} className={classNam} />;
    }
  }
};

const EvidencePreview = ({ type, file, size, videoHight }) => {
  const [showManageModal, setShowManageModal] = useState(false);
  const imageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "webp",
    "tiff",
  ];
  //   evidenceTypes[type] === evidenceTypes.audio ? (
  //     <div>
  //       <audio className="audio-element evidenceaudio" src={`${URL_CONFIG.IMG_URL}${file.document_name}`} source controls />
  //     </div>
  //   ) :
  return (
    <>
      {evidenceTypes[type] === evidenceTypes.video ? (
        // <EvidenceVideo
        //   src={`${URL_CONFIG.IMG_URL}${file.document_name}`}
        //   title={file.real_document_name}
        // />
        <FcVideoFile size={25} onClick={() => setShowManageModal(true)} />
      ) : type === "Document" || type === "document" ? (
        <div>
          {/* <audio className="audio-element evidenceaudio" src={`${URL_CONFIG.IMG_URL}${file.document_name}`} source controls /> */}
          <span onClick={() => setShowManageModal(true)}>
            {docType(file?.document_name, 25)}
          </span>
        </div>
      ) : (
        <img
          // onClick={() =>
          //   window.open(`${URL_CONFIG.IMG_URL}${file.document_name}`, "_blank")
          // }
          onClick={() => setShowManageModal(true)}
          className="evidenceImg"
          // src={`${URL_CONFIG.IMG_URL}${file.document_name}`}
          src={PARSE_IMG_URL(URL_CONFIG.DEV_URL, file?.document_name)}
          alt={file.description}
          width={"80px"}
        />
      )}

      <Modal
        show={showManageModal}
        onHide={() => setShowManageModal(false)}
        size="lg"
      >
        <Modal.Header closeButton className="customClose"></Modal.Header>
        <div className="evidenceCard" style={{ overflow: "visible" }}>
          <div className="popupImgVideo">
            {evidenceTypes[type] === evidenceTypes.video ? (
              <PopUpFile
                src={`${PARSE_IMG_URL(
                  URL_CONFIG.DEV_URL,
                  file?.document_name
                )}`}
                title={file.real_document_name}
                hideClass
                height={videoHight || "105"}
              />
            ) : evidenceTypes[type] === evidenceTypes.photo ||
              imageExtensions.includes(type) ? (
              <div className="popupImgVideo text-center">
                <img
                  className="img-fluid"
                  src={PARSE_IMG_URL(URL_CONFIG.DEV_URL, file?.document_name)}
                  alt={file.description}
                  // width={"100px"}
                />
              </div>
            ) : (
              <PdfPopup hideDownload fileName={file?.document_name} />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EvidencePreview;
