import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import {
  fuelDispenseApis,
  tabUrl,
  fuelDispenseColumnFields,
  statusListObj,
  fuelManagementFilterForm,
  pageLink,
  fuelStocksApis,
  fuelStockColumnFields,
} from "./DATA";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FuelDispenseFilterForm from "./FuelDispenseFilterForm";
import FuelDispenseView from "./FuelDispenseView";
import filter from "../../Assets/Images/filter.svg";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../_helpers/hooks";
import SafetySideBar from "../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../_pages/FuelManagementSystem/FuelManagement/DATA";

import {
  exportFuelDispense,
  exportFuelStock,
  getFuelStockListWithPagination,
} from "../../services/_fuelDispense/fuelDispense";
import "./fuelmgmt.scss";
import { IS_MOBILE, findUserPermissionedTabList } from "../../_helpers/helper";
import FuelStockView from "./FuelStockView";
const FuelManagementStock = () => {
  const params = useParams();
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(fuelManagementFilterForm);

  const getAll = async () => {
    let response;
    if (params?.id)
      response = await getFuelStockListWithPagination(
        "page=1&limit=2&createdBy=" + params?.id
      );
    else response = await getFuelStockListWithPagination("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const onResetFilter = () => {
    setForm({ fuelManagementFilterForm });
    if (!params?.id) setGetAllFilter();
    else setGetAllFilter("&createdBy=" + params?.id);
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const viewAsPopup = (form) => {
    return <FuelStockView form={form} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          aria-expanded={open}
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
        >
          <img src={filter} alt="fliterIcon" className="img-fluid" />
        </Button>
        <FuelDispenseFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          setOpen={setOpen}
          isOpen={open}
        />
      </div>
    );
  };

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }
    if (params?.id) filter += `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [filterStatus]);

  const onEdit = (id) => {
    navigate(`${IS_MOBILE() ? "/mobile" : ""}/fuel-management-system/${id}`);
  };

  const statusByOptions = (row) => {
    return !!(row?.status === statusListObj?.draft);
  };

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);

  return isListData ? (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        goBack={() => navigate(-1)}
        pageTitle={params?.id ? "My Fuel Dispenses" : "View Fuel Dispenses"}
      />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={fuelStockColumnFields}
                apis={fuelStocksApis}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={pageLink}
                primaryKey="id"
                pagination
                hideDeleteButton
                hideThreeDotButton={true}
                hideEditButton={params?.id ? true : false}
                getAllFilter={getAllFilter}
                // hideAddButton
                breadCrumb={[
                  {
                    name: "Fuel Management",
                    path: "/",
                  },
                  {
                    name: "Fuel Management",
                    path: "/fuel-management-system",
                  },
                  {
                    name: "View Tank & Bowser Stock",
                    path: "#",
                  },
                ]}
                // childrenAfterAddButton={tabButtons}
                downloadExcel={exportFuelStock}
                customTableTitle={"Fuel Stocks"}
                editFunButton={params?.id && onEdit}
                viewTitle={"Fuel Stock Details"}
                isNoTabOnPage
                hideAddButton
                statusByOptions={statusByOptions}
                modalSize={"xl"}
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={"View  DIP Datas"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            // pageLink={pageLink}
            breadCrumb={[
              {
                name: "Fuel Management",
                path: "/",
              },
              {
                name: "Fuel Management",
                path: "/fuel-management-system",
              },
              {
                name: "View  DIP Datas",
                path: "#",
              },
            ]}
            title={"View  DIP Data"}
            hideAddBtn
          />
        </div>
      </div>
    </div>
  );
};

export default FuelManagementStock;
