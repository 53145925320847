import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ForgetPasswordService } from "../../_services/ForgotPassword/ForgetPasswordService";
import toastr from "toastr";
import { Validation } from "../../_helpers/validation/Validation";
import { initialForm, restPasswordRules } from "./DATA";
import { useForm } from "../../_helpers/hooks";
import { Button, Card } from "react-bootstrap";
import "../ForgotPassword/ForgotPassword.scss";
import logo from "../../Assets/Images/logo.png";

const Reset = () => {
  const [form, onChange] = useForm(initialForm);
  const [error, setError] = useState({});
  const [samePasswordErr, setSamePasswordErr] = useState("");
  let params = useParams("id");
  const navigate = useNavigate();

  const onSubmit = async () => {
    let errorObj = Validation(form, restPasswordRules);
    setError(errorObj);
    let data = {
      token: params?.id,
      password: form.password,
    };
    if (form.confirmPassword !== form.password) {
      setSamePasswordErr("Both password should be same");
    }

    if (
      errorObj &&
      errorObj.password === "" &&
      errorObj.confirmPassword === "" &&
      form.confirmPassword === form.password
    ) {
      setSamePasswordErr("");
      let response = await ForgetPasswordService.resetPassword(data);
      if (response && response.status === 1) {
        toastr.success(response.message);
        return navigate("/auth/login");
      } else {
        toastr.warning(response.message);
      }
    }
  };

  return (
    <Card className="forgotWrapper">
      <img src={logo} className="img-fluid DigicoalLogo" alt="DigicoalLogo" />
      <h3 className="forgotTitle">Change Password</h3>
      <div className="row">
        <div className="col-md-12 form-group">
          {/* <label>New Password</label> */}
          <input
            name="password"
            type="password"
            className="form-control"
            placeholder="New Password"
            value={form.password}
            onChange={onChange}
            error={error?.password}
            data-testid="password"
          />
          <p className="errorMssg text-danger">{error?.password}</p>
        </div>
        <div className="col-md-12 form-group">
          {/* <label>Confirm Password</label> */}
          <input
            name="confirmPassword"
            type="password"
            className="form-control"
            placeholder="Confirm Password"
            value={form.confirmPassword}
            onChange={onChange}
            data-testid="ConfirmPassword"
          />
          <p className="errorMssg text-danger">
            {error?.confirmPassword
              ? error?.confirmPassword
              : samePasswordErr
              ? samePasswordErr
              : ""}
          </p>
        </div>
        <div className="loginBtnSec">
          <Button className="btn loginBtn" onClick={() => onSubmit()}>
            Submit
          </Button>
        </div>
        <div className="signUp">
          <p>
            Want to <Link to="/auth/login">Login ?</Link>
          </p>
        </div>
      </div>
    </Card>
  );
};

export default Reset;
