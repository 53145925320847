import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import ContractorTenderDetails from "../CommonComponent/ContractorTenderDetails";
import CreatedByCreatedAt from "../../../components/Common/CreatedByCreatedAt";

const ContractorPerformanceView = ({ form }) => {
  return (
    <>
      <ContractorTenderDetails form={form} />
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="webformViewContent">
            <h3 className="font18">Equipment Available</h3>
            <div className="webformCardContent">
              <table cellPadding={10}>
                <thead>
                  <tr>
                    <th></th>
                    <th>NIT Specs</th>
                    <th>1st Shift</th>
                    <th>2nd Shift</th>
                    <th>Night Shift</th>
                  </tr>
                </thead>
                <tbody>
                  {form?.contractorPerformanceData.map((d, i) => {
                    if (d.committed_count) {
                      return (
                        d.type === "available" && (
                          <tr key={i}>
                            <th style={{ backgroundColor: "#eef9ff" }}>
                              <label
                                style={{
                                  fontWeight: "600",
                                  fontSize: "16",
                                }}
                              >
                                {d.equipment_name}
                              </label>
                            </th>
                            <td>{d.committed_count}</td>
                            <td>{d.shift1_available}</td>
                            <td>{d.shift2_available}</td>
                            <td>{d.shift3_available}</td>
                          </tr>
                        )
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="webformViewContent">
            <h3 className="font18">Equipment Working</h3>
            <div className="webformCardContent">
              <table cellPadding={10}>
                <thead>
                  <tr>
                    <th></th>
                    {/* <th>NIT Specs</th> */}
                    <th>1st Shift</th>
                    <th>2nd Shift</th>
                    <th>Night Shift</th>
                  </tr>
                </thead>
                <tbody>
                  {form?.contractorPerformanceData.map((d, i) => {
                    if (d?.committed_count)
                      return (
                        d?.type === "utilized" && (
                          <tr key={i}>
                            <th style={{ backgroundColor: "#eef9ff" }}>
                              <label
                                style={{
                                  fontSize: "16",
                                  fontWeight: "600",
                                }}
                              >
                                {d.equipment_name}
                              </label>
                            </th>
                            {/* <td>{d?.committed_count}</td> */}
                            <td>{d?.shift1_available}</td>
                            <td>{d?.shift2_available}</td>
                            <td>{d?.shift3_available}</td>
                          </tr>
                        )
                      );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <CreatedByCreatedAt id={form?.id} table={"contractor_performances"} />
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default ContractorPerformanceView;
