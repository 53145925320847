import React from "react";
import { Dropdown } from "react-bootstrap";
import { getTimeDifference } from "../../_helpers/helper";
import "./header.scss";

const Notification = ({ notificationList, updateNotification, readList }) => {
  return notificationList?.length
    ? notificationList.map((note, index) => (
        <Dropdown.Header key={index}>
          <div
            // onClick={() => {
            //   updateNotification(note?.id);
            // }}
            className={`dropdown-item ${
              !readList?.includes(note?.id) && note?.color
            }`}
            style={{ height: "69px" }}
          >
            <div className="notification-details flex-grow-1">
              <div className="m-0 d-flex align-items-start">
                <span
                  className={
                    note?.color && !readList?.includes(note?.id) ? "new" : ""
                  }
                >
                  {note?.status}
                </span>
                <div className="mssg text-note">{note?.messsage}</div>

                <div className="date">
                  {`${getTimeDifference(
                    // new Date("2019-10-30T02:10:18.931Z")
                    new Date(note?.createdAt)
                  )} ago`}
                </div>
              </div>
              {/* <p className="text-small text-muted m-0">{note?.description}</p> */}
            </div>
          </div>
        </Dropdown.Header>
      ))
    : "";
};

export default Notification;
