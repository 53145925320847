import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { modeOfDispatchLabelTable } from "../../DATA";
import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../../_helpers/dateHelper";

const SapDataView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <Row>
            <Col md={4}>
              <label>Subsidiary</label>
              <p className="content">{form?.site?.parentSite?.name}</p>
            </Col>
            <Col md={4}>
              <label>Mine</label>
              <p className="content">{form?.site?.name}</p>
            </Col>
            <Col md={4}>
              <label>Plant</label>
              <p className="content">{form?.plant}</p>
            </Col>
            <Col md={4}>
              <label>Mode</label>
              <p className="content">
                {form?.mode_of_dispatch &&
                  modeOfDispatchLabelTable[form?.mode_of_dispatch]}
              </p>
            </Col>
            <Col md={4}>
              <label>Sample Id</label>
              <p className="content">{form?.sample_no}</p>
            </Col>
            <Col md={4}>
              <label>Buyer Name</label>
              <p className="content">{form?.buyer_name}</p>
            </Col>
            <Col md={4}>
              <label>Material Description</label>
              <p className="content">{form?.material_description}</p>
            </Col>
            <Col md={4}>
              <label>Agency</label>
              <p className="content">{form?.agency}</p>
            </Col>
            <Col md={4}>
              <label>Bill Quantity</label>
              <p className="content">{form?.bill_quantity}</p>
            </Col>
            <Col md={4}>
              <label>Declared Grade</label>
              <p className="content">{form?.declared_grade}</p>
            </Col>
            <Col md={4}>
              <label>Collection Date</label>
              <p className="content">
                {form?.maxCollectionDate &&
                  DDMMYYYYFormat(form?.maxCollectionDate)}
              </p>
            </Col>
            <Col md={4}>
              <label>Created At</label>
              <p className="content">
                {form?.maxCreatedAt && DDMMYYYYHHMMAFormat(form?.maxCreatedAt)}
              </p>
            </Col>

            <Col md={4}>
              <label>Fnote No</label>
              <p className="content">{form?.fnote_dnote_no}</p>
            </Col>
            <Col md={4}>
              <label>RR No</label>
              <p className="content">{form?.rr_no}</p>
            </Col>
            <Col md={4}>
              <label>Invoice Number</label>
              <p className="content">{form?.invoice}</p>
            </Col>

            <Col md={4}>
              <label>Billed Quantity</label>
              <p className="content">{form?.bill_quantity}</p>
            </Col>

            <Col md={4}>
              <label>Fnote Date</label>
              <p className="content">
                {form?.fnote_dnote_date &&
                  DDMMYYYYHHMMAFormat(form?.fnote_dnote_date)}
              </p>
            </Col>

            <Col md={4}>
              <label>RR Date</label>
              <p className="content">
                {form?.rr_date && DDMMYYYYHHMMAFormat(form?.rr_date)}
              </p>
            </Col>

            <Col md={4}>
              <label>Sales Document Number</label>
              <p className="content">{form?.sales_order}</p>
            </Col>
    

            <Col md={4}>
              <label>Delivery Number</label>
              <p className="content">{form?.delivery_no}</p>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </>
  );
};

export default SapDataView;
