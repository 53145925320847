import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";

const UserView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <Row>
              <Col sm={6}>
                <label for="name">Name</label>
                <p
                  name="name"
                  className="content"
                >{`${form?.firstName} ${form?.lastName}`}</p>
              </Col>
              <Col sm={6}>
                <label for="email">Email</label>
                <p name="email" className="content">
                  {form?.email}
                </p>
              </Col>
              <Col sm={6}>
                <label for="site">Site Location</label>
                <p name="site" className="content">
                  {form?.site?.name}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default UserView;
