import { URL_CONFIG } from "../../../../_constants/Config/URL_CONFIG";
import SafetySideBar from "../../../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import Sidebar from "../Sidebar";

const SafetyMgmtDashboard = () => {
  return (
    <div className="wrapper">
      <div className="sidebar">
        {/* <Sidebar /> */}
        <SafetySideBar />
      </div>
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <iframe
            src={`${URL_CONFIG.AWS_QUICKSIGHT_URL}e74e8efc-fb50-44c5-b9d4-c79017eb9818?directory_alias=cil-dev-si-bi`}
            title="Loading..."
          />
        </div>
      </div>
    </div>
  );
};

export default SafetyMgmtDashboard;
