import moment from "moment/moment";
import selectionIcon1 from "../../../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../../../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../../../Assets/Images/selectionIcon3.svg";
import selectionIcon4 from "../../../Assets/Images/selectionIcon4.svg";
import selectionIcon5 from "../../../Assets/Images/selectionIcon5.svg";
import {
  getSafetyManagementCapaAssignedIncidentListWithPagination,
  getSafetyManagementCapaOwnerIncidentListWithPagination,
  getSafetyManagementInvestigationAssignedIncidentListWithPagination,
  getSafetyManagementInvestigatorIncidentListWithPagination,
  getSafetyManagementReportedIncidentHistoryListWithPagination,
  getSafetyManagementReportedIncidentListWithPagination,
} from "../../../services/_safetyManagement/safetyManagement";
import {
  fuelDashboardLink,
  fuelDashboardLinkMobile,
} from "../../../_services/CommonService/CommonService";
import { isNegative } from "../../../_helpers/helper";

// import { statusColor, statusList } from "../DATA";

let result = moment().subtract(15, "days").format("YYYY-MM-DD");

export const fuelManagementFilterForm = {
  startDate: "",
  endDate: "",
  // contractor_id: "",
};

export const tabUrl = {
  safetyManagement: [
    {
      active: 0,
      to: "/safety-management-system/incident-management/incident-reported",
      label: "Admin Actions",
      permission: ["safety_admin"],
    },
    {
      to: "/safety-management-system/incident-management/incident-investigation",
      label: "Investigator Actions",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
    {
      to: "/safety-management-system/incident-management/incident-capa",
      active: 0,
      label: "CAPA Owner Actions",
      // permission: ["fuel_admin", "dispenser"],
    },
  ],
  safetyManagementApprovalHistory: [
    {
      label: "Admin",
      active: 0,
      permission: ["safety_admin"],
      to: "/safety-management-system/incident-management/incident-reported-approval_history",
    },
    {
      to: "/safety-management-system/incident-management/incident-investigator-approval_history",
      label: "Investigator",
      active: 0,
      // permission: ["fuel_admin", "dispenser"],
    },
    {
      to: "/safety-management-system/incident-management/incident-capa-owner-approval_history",
      label: "CAPA Owner",
      // permission: ["fuel_admin", "dispenser"],
      active: 0,
    },
  ],
};
//   {
//     name: "Incident ID",
//     selector: "incident_uid",
//     sortable: true,
//   },
//   {
//     name: "Subsidiary",
//     selector: "site_id",
//     sortable: true,
//     cell: (row) => row?.site?.parentSite?.name,
//   },
//   {
//     name: "Mine",
//     selector: "site_id",
//     sortable: true,
//     cell: (row) => row?.site?.name,
//   },
//   // {
//   //   name: "Incident Time",
//   //   selector: "incident_datetime",
//   //   sortable: true,
//   //   cell: (row) => DDMMYYYYHHMMAFormat(row?.incident_datetime),
//   //   minWidth: "200px",
//   // },
//   {
//     selector: "incidentMedias",
//     sortable: true,
//     name: "Incident Category",
//     cell: (row) => row?.category,
//     minWidth: "150px",
//   },
//   {
//     name: "Department",
//     selector: "department",
//     sortable: true,
//     // cell: (row) => row?.site?.name,
//   },
//   {
//     name: "Status",
//     selector: "status",
//     sortable: true,
//     minWidth: "250px",
//     // cell: (row) => statusList[row?.status],
//     cell: (row) => {
//       return (
//         <span className={`statusSec ${statusColor(row?.status)}`}>
//           {statusList[row?.status]}
//         </span>
//       );
//     },
//   },
// ];

export const fuelDispenseApis = Object.freeze({
  getAll: {
    api: getSafetyManagementReportedIncidentListWithPagination,
  },
});

export const Tabs = [
  { id: 1, label: "L", value: "L" },
  { id: 2, label: "KL", value: "KL" },
];

export const SidebarData = [
  {
    img: selectionIcon1,
    title: "Dashboard",
    active: 0,
    link: fuelDashboardLink(),
    customLink: true,
    activeUrl: "dashboard",
    permission: ["fuel_management_dashbaord"],
  },
  {
    img: selectionIcon1,
    title: "Fuel Sensor Graph",
    active: 0,
    link: "/fuel-management-system/fuel-sensor-graph",
    activeUrl: "fuel-sensor-graph",
    permission: ["fuel_admin"],
  },
  {
    permission: ["quick_sight_dashboard"],
    img: selectionIcon1,
    title: "SAP Report",
    active: 0,
    link: "/fuel-management-system/sap-report",
    activeUrl: "sap-report",
  },
  {
    title: "User Management",
    img: selectionIcon2,
    link: "/fuel-management-system/all-user-list",
    active: 0,
    permission: ["fuel_admin"],
    activeUrl: "user-list",
  },
  {
    activeUrl: "configuration",
    img: selectionIcon3,
    title: "Configuration",
    active: 0,
    link: "/fuel-management-system/configuration",
    permission: ["fuel_admin", "super_admin"],
  },
  {
    active: 0,
    title: "Fuel Management",
    img: selectionIcon4,
    link: "/fuel-management-system",
    activeUrl: "fuel-management-list",
    permission: ["fuel_admin", "dispenser"],
  },
  {
    // permission: ["fuel_admin", "dispenser", "super_admin"],
    img: selectionIcon5,
    active: 0,
    title: "Help Manual",
    link: "/fuel-management-system/help-manual",
    activeUrl: "help-manual",
  },
];

export const FuelSidebarData = [
  {
    title: "Dashboard",
    active: 0,
    img: selectionIcon1,
    link: "/fuel-management-system/dashboard/1",
    activeUrl: "dashboard",
    permission: ["fuel_management_dashbaord"],
  },
  {
    img: selectionIcon2,
    title: "User Management",
    link: "/fuel-management-system/all-user-list",
    active: 0,
    activeUrl: "user-list",
    permission: ["fuel_admin"],
  },
  {
    img: selectionIcon3,
    title: "Configuration",
    active: 0,
    link: "/fuel-management-system/configuration",
    activeUrl: "configuration",
    permission: ["fuel_admin", "super_admin"],
  },
  {
    img: selectionIcon4,
    title: "Fuel Management",
    active: 0,
    link: "/fuel-management-system",
    activeUrl: "fuel-management-list",
    permission: ["fuel_admin", "dispenser"],
  },
  {
    img: selectionIcon5,
    title: "Help Manual",
    active: 0,
    activeUrl: "help-manual",
    link: "/fuel-management-system/help-manual",
    // permission: ["fuel_admin", "dispenser", "super_admin"],
  },
];
export const mobileFuelSidebarData = [
  {
    img: selectionIcon1,
    title: "Dashboard",
    active: 0,
    link: fuelDashboardLinkMobile(),
    permission: ["fuel_management_dashbaord"],
  },
  // {
  //   img: selectionIcon2,
  //   title: "User Management",
  //   link: "/mobile/user-list",
  //   // activeUrl: "user-management",
  //   active: 0,
  //   // permission: ["fuel_management_dashbaord"],
  // },
  // {
  //   img: selectionIcon3,
  //   title: "Configuration",
  //   active: 0,
  //   link: "/mobile/fuel-management-system/configuration",
  //   activeUrl: "configuration",
  //   permission: ["safety_management_dashbaord"],
  // },
  {
    img: selectionIcon4,
    title: "Fuel Management",
    active: 0,
    link: "/mobile/fuel-management-system",
    // activeUrl: "incident-management",
    permission: ["fuel_admin", "dispenser"],
  },
  // {
  //   img: selectionIcon5,
  //   title: "Help Manual",
  //   active: 0,
  //   link: "/mobile/fuel-management-system/help-manual",
  //   // permission: ["environment_management_dashboard"],
  // },
];
export const incidentApprovalFlowList = [
  {
    img: selectionIcon1,
    label: "Reported Incident",
    link: "/safety-management-system/incident-management/incident-reported",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon2,
    label: "Investigation Assigned",
    link: "/safety-management-system/incident-management/incident-investigation",
    // role: ["contractor_dashboard"],
  },
  {
    img: selectionIcon3,
    label: "CAPA Assigned",
    // link: "/dashboard/contractor-performance-dashboard",
    // role: ["contractor_dashboard"],
  },
];
export const fuelFlowList = [
  {
    img: selectionIcon3,
    label: "Add Fuel Dispense",
    link: "/fuel-management-system/fuel-management-list/create",
    permission: ["dispenser", "fuel_admin"],
  },
  {
    img: selectionIcon1,
    label: "Add Fuel Receipt",
    link: "/fuel-management-system/fuel-management-list/fuel-receipt/create",
    permission: ["fuel_admin", "dispenser"],
  },
  {
    img: selectionIcon1,
    label: "Update DIP Data",
    link: "/fuel-management-system/fuel-management-list/stock/create",
    permission: ["fuel_admin", "dispenser"],
  },
  {
    img: selectionIcon4,
    label: "View Fuel Dispense",
    link: "/fuel-management-system/fuel-management-list",
    permission: ["fuel_admin", "dispenser"],
  },
  {
    img: selectionIcon1,
    label: "View Fuel Receipt",
    link: "/fuel-management-system/fuel-management-list/fuel-receipt",
    permission: ["fuel_admin", "dispenser"],
  },
  {
    img: selectionIcon1,
    label: "View Tank & Bowser Stock",
    link: "/fuel-management-system/fuel-management-list/stock",
    permission: ["fuel_admin", "dispenser"],
  },
  {
    img: selectionIcon2,
    label: "My Fuel Dispense",
    link: "/fuel-management-system/fuel-management-list/",
    permission: ["dispenser", "fuel_admin"],
  },
  {
    img: selectionIcon2,
    label: "My Fuel Receipt",
    link: "/fuel-management-system/fuel-management-list/my-fuel-receipt",
    permission: ["dispenser", "fuel_admin"],
  },
  {
    img: selectionIcon2,
    label: "Reports",
    link: "/fuel-management-system/fuel-management-list/reports",
    permission: ["fuel_admin"],
  },
  {
    img: selectionIcon2,
    label: "Fuel Sensor",
    link: "/fuel-management-system/fuel-management-list/fuel-sensor",
    permission: ["fuel_admin"],
  },
];

export const mobileFuelFlowList = [
  {
    img: selectionIcon3,
    label: "Add Fuel Dispense",
    link: "/mobile/fuel-management-system/fuel-management-list/create",
    // role: ["contractor_dashboard"],
    permission: ["dispenser", "fuel_admin"],
  },
  {
    img: selectionIcon4,
    label: "View Fuel Dispense",
    link: "/mobile/fuel-management-system/fuel-management-list",
    permission: ["dispenser", "fuel_admin"],
  },
  {
    img: selectionIcon2,
    label: "My Fuel Dispense",
    link: "/mobile/fuel-management-system/fuel-management-list/",
    permission: ["dispenser", "fuel_admin"],
  },
  {
    img: selectionIcon3,
    label: "Add Fuel Receipt",
    link: "/mobile/fuel-management-system/fuel-management-list/fuel-receipt/create",
    permission: ["fuel_admin", "dispenser"],
  },
  {
    img: selectionIcon4,
    label: "View Fuel Receipt",
    link: "/mobile/fuel-management-system/fuel-management-list/fuel-receipt",
    permission: ["fuel_admin", "dispenser"],
  },
  {
    img: selectionIcon2,
    label: "My Fuel Receipt",
    link: "/mobile/fuel-management-system/fuel-management-list/my-fuel-receipt",
    permission: ["dispenser", "fuel_admin"],
  },
  {
    img: selectionIcon2,
    label: "Update DIP Data",
    link: "/mobile/fuel-management-system/fuel-management-list/stock/create",
    permission: ["fuel_admin","dispenser"],
  },
];

export const incidentTabList = [
  {
    label: "Incident Info",
    tab: 1,
    tabStatus: false,
  },
  {
    label: "General Info",
    tab: 2,
    tabStatus: false,
  },
  {
    label: "Personnel Details",
    tab: 3,
    tabStatus: false,
  },
  {
    label: "Equipment Details",
    tab: 4,
    tabStatus: false,
  },
  {
    label: "Vehicle Details",
    tab: 5,
    tabStatus: false,
  },
  {
    label: "Evidence",
    tab: 6,
    tabStatus: false,
  },
];

export const incidentTypes = {
  unsafeAct: "Unsafe Act",
  unsafeCondition: "Unsafe Condition",
  nearMiss: "Near Miss",
  mti: "MTI (Medical Treatment Injury)",
  lti: "LTI (Lost Time Injury)",
  seriousBInjury: "Serious bodily injury",
  fatality: "Fatality",
  others: "Others",
};

export const viewStaffType = {
  users: "Users",
  others: "Others",
};

export const objStaffType = {
  users: "users",
  others: "others",
};

export const sourceOptions = [
  { id: "fuel", name: "Fuel Management" },
  { id: "sap", name: "SAP" },
];

export const unitOptions = [
  { id: "KL", name: "KL" },
  { id: "L", name: "L" },
];

export const formInitialData = {
  equipment_report: {
    startDate: result,
    endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  },
  tank_bowser_report: {
    startDate: result,
    endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  },
  consolidated_daily_report: {
    startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  },
  hsd_consumption_report: {
    startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  },
  workshop: null,
};

export const fuelDispenseDetailObj = {
  subsidiary: "Subsidiary",
  mine: "Mine",
  workshop_id: "Workshop",
  fuel_location_id: "Fuel Station",
  fuelLevelTank: "Fuel Level in Tank (L)",
  capacity: "HEMM Tank Capacity",
  capacityBowser: "Bowser Tank Capacity",
};

export const MIXUP_DATA = [
  "subsidiary",
  "mine",
  "workshop_id",
  "fuel_location_id",
];

export const MOB_MIXUP_DATA1 = ["subsidiary", "mine"];

export const MOB_MIXUP_DATA2 = ["workshop_id", "fuel_location_id"];

export const isNegativeFuelLevelTank = (form, maxCapacity) => {
  const finalCapacity = +maxCapacity - +form?.fuel_dispensing_qty;
  return !!(maxCapacity && isNegative(finalCapacity));
};

export const GET_MIXUP_VALUE_TEXT = (mixupKey, useWorkshop, formObjData) => {
  let mixValue = "";
  mixupKey
    ?.filter((d) => useWorkshop || (!useWorkshop && d !== "workshop_id"))
    ?.forEach((key) => {
      if (formObjData[key]) {
        let devider = mixValue && formObjData[key] ? " - " : "";
        mixValue = mixValue + devider + formObjData[key];
      }
    });

  return mixValue;
};
