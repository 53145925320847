import React from "react";
import { Col, Row } from "react-bootstrap";
import { YES_NO_BY_NUMBER } from "../../../../../_helpers/helper";
import { DDMMYYYYFormat } from "../../../../../_helpers/dateHelper";

const GeneralInfoView = ({ form }) => {
  return (
    <>
      <Row>
        <Col md={4}>
          <label>Fire Involved</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.fireInvolved)}</p>
        </Col>
        <Col md={4}>
          <label>Ground Movement / Slope Stability</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.electricShock)}</p>
        </Col>
        <Col md={4}>
          <label>Lighting</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.lighting)}</p>
        </Col>
        <Col md={4}>
          <label>Due to moving equipment</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.movingEquipmet)}</p>
        </Col>
        <Col md={4}>
          <label>Explosive Involved</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.explosion)}</p>
        </Col>
        <Col md={4}>
          <label>Fall from Height / Level</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.fallFromHeight)}</p>
        </Col>
        <Col md={4}>
          <label>Vehicle Collision/toppling</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.vehicleCollision)}</p>
        </Col>
        <Col md={4}>
          <label>Fall of object</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.fallOfObject)}</p>
        </Col>
        <Col md={4}>
          <label>Electrical Accident</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.inundation)}</p>
        </Col>
        <Col md={4}>
          <label>Others</label>
          <p className="content">
            {form?.othersInvolved
              ? form?.othersInfo
              : YES_NO_BY_NUMBER(form?.othersInvolved)}
          </p>
        </Col>
        {/* <Col md={4}>
          <label>Lost Time Injury</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.is_lti)}</p>
        </Col>
        <Col md={4}>
          <label>Lti Count</label>
          <p className="content">{YES_NO_BY_NUMBER(form?.lti_count)}</p>
        </Col> */}
        {form?.category === "fatality" && (
          <Col md={6}>
            <label>Fatality Category</label>
            <p className="content">{form?.fatalityCategory}</p>
          </Col>
        )}
        <Col md={6}>
          <label>Indirect Losses</label>
          <p className="content">{form?.indirectLosses}</p>
        </Col>
        <Col md={6}>
          <label>Mine - Activity Operations Status</label>
          <p className="content">{form?.facilityStatus}</p>
        </Col>
        <Col md={6}>
          <label>
            Whether similar incident happened in the past at same location /
            mine
          </label>
          <p className="content">{form?.similarIncidentHappened}</p>
        </Col>
        <Col md={6}>
          <label>Cost of the accident</label>
          <p className="content">{form?.costOfAccident}</p>
        </Col>
        <Col md={6}>
          <label>Incident/Accident Cause</label>
          <p className="content">
            {form?.violationsLeadingData?.code +
              " - " +
              form?.violationsLeadingData?.name}
          </p>
        </Col>
        <Col md={6}>
          <label>Incident/Accident Short Summary</label>
          <p className="content">{form?.summary}</p>
        </Col>
        <Col md={6}>
          <label>Date of Immediate Last Accident</label>
          <p className="content">
            {form?.dateOfImmediate ? DDMMYYYYFormat(form?.dateOfImmediate) : ""}
          </p>
        </Col>
        <Col md={6}>
          <label>Immediate Last Accident</label>
          <p className="content">{form?.immediateLastAccident}</p>
        </Col>
        <Col md={6}>
          <label>Brief Description of Cause</label>
          <p className="content">{form?.briefDescription}</p>
        </Col>
        <Col md={6}>
          <label>Distance from the face (m)</label>
          <p className="content">{form?.distanceFromFace}</p>
        </Col>
        <Col md={6}>
          <label>Seam</label>
          <p className="content">{form?.seam}</p>
        </Col>
        <Col md={6}>
          <label>Level</label>
          <p className="content">{form?.level}</p>
        </Col>
        <Col md={6}>
          <label>Pit / Incline No</label>
          <p className="content">{form?.pitIncline}</p>
        </Col>
        <Col md={6}>
          <label>District / Panel / Bench outside working district</label>
          <p className="content">{form?.districtPanel}</p>
        </Col>
        <Col md={6}>
          <label>Dip Number</label>
          <p className="content">{form?.dip}</p>
        </Col>
        <Col md={6}>
          <label>Details not covered above(if within district)</label>
          <p className="content">{form?.detailsNotCovered}</p>
        </Col>
        <Col md={6}>
          <label>Incident/Accident – Detailed Description</label>
          <p className="content">{form?.description}</p>
        </Col>
        <Col md={6}>
          <label>
            Immediate Measures/Action Taken (Do not enter CAPA here)
          </label>
          <p className="content">{form?.measuresOrActions}</p>
        </Col>
      </Row>
    </>
  );
};

export default GeneralInfoView;
