import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../components/DateTimeCustom/CustomDateTime";
import Select from "../../components/Select/Select";
import { dispensedType } from "./DATA";
import {
  getAllBowsers,
  getAllMineLists,
  getAllTanks,
  getAllWorkshops,
} from "../../services/_fuelDispense/fuelDispense";
import { IS_USER_SITE_MINES, auth_user } from "../../_helpers/helper";
import FuelAdditionalFilter from "./FuelManagementConfiguration/FuelAdditionalFilter";

const FuelDispenseFilterForm = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
  setForm,
  showFuelFilterOptions,
  bowserText,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>Fuel Dispense Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              name="endDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={6}>
            <Select
              onChange={onChange}
              value={form?.dispensedType}
              name="dispensedType"
              options={dispensedType}
              label={"Dispensed Type"}
            />
          </Col>
          {showFuelFilterOptions ? (
            <>
              <FuelAdditionalFilter
                form={form}
                onChange={onChange}
                setForm={setForm}
                bowserText={bowserText}
              />
            </>
          ) : (
            ""
          )}
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              type="button"
              data-testid="reset-filter-button"
              onClick={onResetFilter}
              className="secondaryBtn"
            >
              Clear all
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              data-testid="submit-filter-button"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default FuelDispenseFilterForm;
