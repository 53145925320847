import React, { useMemo, useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../../Assets/Images/filter.svg";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  incidentKpiApi,
  activityFilterForm,
  activityLabel,
  addLink,
} from "./DATA";
import { useForm } from "../../../../_helpers/hooks";
import { capitalizeName } from "../../../../_helpers/helper";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import IncidentKpiView from "./IncidentKpiView";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { SidebarData } from "../../IncidentManagement/DATA";

const IncidentKpiList = () => {
  let newAddLink = addLink;
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [isListData, setIsListData] = useState(true);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onEdit = (id) => {
    navigate(`${newAddLink}/${id}`);
  };

  // const onSubmitFilter = () => {
  //   setFilterStatus((prev) => prev + 1);
  // };

  const columnFields = useMemo(
    () => [
      {
        name: "Subsidiary",
        selector: "site_id",
        sortable: true,
        cell: (row) => row?.site?.parentSite?.name,
      },
      {
        name: "Mine",
        selector: "mine",
        sortable: true,
        cell: (row) => {
          return capitalizeName(`${row?.site?.name}`);
        },
      },
      {
        name: "Year",
        selector: "kpi_year",
        sortable: true,
        cell: (row) => {
          return capitalizeName(`${row?.kpi_year}`);
        },
      },
      {
        name: "Total Work Hours By All Staff",
        selector: "total_hrs",
        sortable: true,
        cell: (row) => {
          return capitalizeName(`${row?.total_hrs}`);
        },
      },
    ],
    []
  );

  // const viewAsPopup = (form) => {
  //   return <IncidentKpiView form={form} />;
  // };

  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  getAllFilter={getAllFilter}
                  fields={columnFields}
                  apis={incidentKpiApi}
                  // title={"Activity"}
                  viewTitle="KPI Data"
                  editTitle="KPI Data"
                  customTableTitle="KPI Data"
                  hideSnColumn
                  pageLink={newAddLink}
                  primaryKey="id"
                  pagination
                  hideDeleteButton
                  hideEditButton
                  deleteFun
                  // childrenAfterAddButton={tabButtons}
                  editFunButton={onEdit}
                  isNoTabOnPage
                  breadCrumb={[
                    {
                      name: "Safety Management",
                      path: "#",
                    },
                    {
                      name: "Configuration",
                      path: "/safety-management-system/configuration",
                    },
                    {
                      name: "KPI Data",
                      path: "#",
                    },
                  ]}
                  title="Data"
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <EmptyDataList
                // tabButtons={tabButtons}
                customTableTitle="Activity"
                pageLink={newAddLink}
                breadCrumb={[
                  {
                    name: "Safety Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/safety-management-system/configuration",
                  },
                  {
                    name: "KPI Data",
                    path: "#",
                  },
                ]}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default IncidentKpiList;
