import React from "react";
import { Col, Form } from "react-bootstrap";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import CustomDatePicker from "../../../../../../components/MobileComponents/DateTimeCustom/CustomDateTime";
import moment from "moment";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { capaPriority } from "../../../../DATA";
import Select from "../../../../../../components/MobileComponents/Select/Select";
import FormInputText from "../../../../../../components/FormInputText/FormInputText";
import { getArraayofKeys } from "../../../Helper";
import AsterikOnly from "../../../../../../components/Common/Asterik/AstrikOnly";
const CapasComponent = ({
  form,
  capas = [],
  error = {},
  capasErrors = {},
  onChangeCapa = () => {},
  addCapa = () => {},
  deleteCapa = () => {},
  users = [],
  departments = [],
  incidentsOnly,
  viewOnly,
  area = [],
  capaowner = [],
  approveCapa,
  approverLevel,
  showReject = false,
  siteId,
  capaFormRules,
}) => {
  return (
    <div className="ehsFormWrapper p-0">
      <Form>
        <Col md="12">
          {capas.length ? (
            capas.map((x, index) => {
              return x.deleted ? null : (
                <div className="addDataformCards" key={index}>
                  <div className="formFields mb-3">
                    <Select
                      label={"CAPA Type"}
                      name="capaType"
                      value={x.capaType}
                      onChange={({ target: { name, value } }) =>
                        onChangeCapa({ name, value, index })
                      }
                      id="id"
                      options={["Corrective", "Preventive"]}
                      error={capasErrors[index]?.capaType}
                      init="none"
                      array="single"
                      asterikRules={capaFormRules}
                    ></Select>
                  </div>
                  <div className="formFields mb-3">
                    <label>
                      CAPA Description
                      <AsterikOnly />
                    </label>
                    <FormInputText
                      type="text"
                      name="remarks"
                      onChange={({ target: { name, value } }) =>
                        onChangeCapa({
                          index,
                          name,
                          value,
                        })
                      }
                      value={x.remarks}
                      className="form-control"
                      error={capasErrors[index]?.remarks}
                      // asterikRules={capaFormRules}
                    />
                  </div>
                  <div className="formFields mb-3">
                    <Select
                      label={"CAPA Owner"}
                      name="ownerId"
                      value={x.ownerId}
                      onChange={({ target: { name, value } }) =>
                        onChangeCapa({
                          index,
                          name,
                          value,
                        })
                      }
                      options={users}
                      error={capasErrors[index]?.ownerId}
                      KEY_label={"name_email_employeeId"}
                      placeholder={"Search by Name / Email Id"}
                      KEY_value={"id"}
                      blankSelect={"none"}
                      asterikRules={capaFormRules}
                    ></Select>
                  </div>
                  <div className="formFields mb-3">
                    <Select
                      label={"CAPA Priority"}
                      name="priority"
                      value={x.priority}
                      onChange={({ target: { name, value } }) =>
                        onChangeCapa({
                          index,
                          name,
                          value,
                        })
                      }
                      options={getArraayofKeys(capaPriority)}
                      object={{
                        low: "Low",
                        medium: "Medium",
                        high: "High",
                      }}
                      error={capasErrors[index]?.priority}
                      blankSelect={"none"}
                      asterikRules={capaFormRules}
                    ></Select>
                  </div>

                  <div className="formFields mb-3">
                    <label>
                      Due Date
                      <AsterikOnly />
                    </label>
                    <CustomDatePicker
                      value={x.dueDate}
                      name="dueDate"
                      onChange={({ target: { name, value } }) =>
                        onChangeCapa({
                          index,
                          name,
                          value,
                        })
                      }
                      dateFormat="YYYY-MM-DD"
                      error={capasErrors[index]?.dueDate}
                      isValidDate={(day) =>
                        moment(
                          !form?.capa_start_time
                            ? moment().format("YYYY-MM-DD HH:mm")
                            : form?.capa_start_time
                        ).isBefore(moment(day))
                      }
                    />
                  </div>
                  <div className="actionIconsGroup">
                    <span
                      data-testid={"remove-leaf" + index}
                      onClick={() => deleteCapa(index)}
                      className="icons font-21 text-danger"
                    >
                      <IoIosCloseCircleOutline
                        size="26"
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
          <div className="addMoreBtn">
            <span className="addTxt" onClick={addCapa} data-testid="add-leaf">
              <AiOutlinePlus />
              Add
            </span>
          </div>
        </Col>
      </Form>
    </div>
  );
};

export default CapasComponent;
