import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import { useNavigate, useParams } from "react-router";
import FormInputText from "../../../components/FormInputText/FormInputText";
import {
  initialFormWell,
  createWell,
  UpdateWell,
  SidebarData,
} from "../UploadedFiles/DATA";
import { useForm } from "../../../_helpers/hooks";
import toastr from "toastr";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import Select from "../../../components/Select/Select";
import { getAllSites } from "../../../services/_uploadedFiles/uploadedFiles";
import "./environment.scss";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";

const MasterGroundWaterWell = () => {
  const [show, setShow] = useState(false);
  const [formFieldError, setFormFieldError] = useState({
    well: "",
    subsidiary: "",
    mine: "",
  });
  const [form, onChange, setForm] = useForm(initialFormWell);
  const [allsiteListDrop, setAllSiteListDrop] = useState({});
  const [allMineListDrop, setAllMineListDrop] = useState({});
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [sites, setSites] = useState({});
  const param = useParams();
  const navigate = useNavigate();
  const closePopup = () => {
    setShow(false);
    navigate("/configuration/environment/ground-water/well");
  };
  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };

  const getData = useCallback(async () => {
    const response = await RestMethod.GET("/ground-water-well/" + param.id);
    setForm({ ...response?.data });
  }, [param.id]);
  useEffect(() => {
    if (!param.id) return;
    getData();
  }, [getData, param.id]);
  const validation = (row) => {
    if (row.well === "") {
      return { well: "Well is required" };
    } else {
      return { well: "" };
    }
  };

  useEffect(() => {
    let dataObjMine = [];
    if (sites.length > 0) {
      sites?.forEach((data) => {
        if (data.siteType === 3) {
          dataObjMine.push({
            value: data.id,
            label: data.name,
          });
        }
      });
    }
    setAllMineListDrop(dataObjMine);
  }, [allsiteListDrop]);

  const getSiteList = async () => {
    let allsiteList = await getAllSites();
    if (allsiteList?.status === 1 && allsiteList?.message == "success") {
      setSites(allsiteList?.data);

      let dataObjSite = [];
      allsiteList?.data?.forEach((data) => {
        if (data.siteType === 2)
          dataObjSite.push({
            label: data.name,
            value: data.id,
          });
      });
      setAllSiteListDrop(dataObjSite);
    }
  };

  useEffect(() => {
    getSiteList();
  }, []);

  const onSubmit = async () => {
    let response = "";
    let data = {
      subsidiary: form?.subsidiary,
      mine: form?.mine,
      well: form?.well,
    };
    setFormFieldError(validation(form));
    if (form.well === "") return;
    // if (form.mine === "") return;
    // if (form.subsidiary === "") return;

    if (param.id) {
      response = await UpdateWell(data, param.id);
      if (response.status)
        navigate("/configuration/environment/ground-water/well");
    } else {
      response = await createWell(data);
      if (response.status)
        navigate("/configuration/environment/ground-water/well");
    }

    if (!response?.data?.status) {
      toastr.error(response?.data?.message);
    } else {
      toastr.success("data saved successfully");
    }
  };

  if (sites.length > 0) {
    sites?.forEach((data) => {
      if (data.id == form.mine) {
        form.subsidiary = data.parentSiteId;
      }
    });
  }

  useEffect(() => {
    let authUser = localStorage.getItem("auth_user");
    let user_site = authUser ? JSON.parse(JSON.parse(authUser)?.siteId) : "";
    form.mine = user_site;
  }, [allMineListDrop]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="creationWrapper">
            <Container fluid>
              <h3 style={{ paddingBottom: "1px", paddingTop: "5px" }}>
                <MainBreadcrumb
                  routeSegments={[
                    {
                      name: "Environment Management",
                      path: "#",
                    },
                    {
                      path: "/environment-management/configuration",
                      name: "Configuration",
                    },
                    {
                      name: "Ground Water Well Data lists",
                      path: "/configuration/environment/ground-water/well",
                    },
                    {
                      name: param?.id ? "Edit" : "Add",
                      path: "#",
                    },
                  ]}
                />
              </h3>
              <h3 className="title d-flex align-items-end">
                {param?.id ? "Edit" : "Add"} Ground Water Well
                <span className="blueLabel"></span>
              </h3>
              <div className="reportCard">
                <div className="creationWrapper">
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Select
                          disabled
                          onChange={onChange}
                          value={form?.subsidiary}
                          name="subsidiary"
                          options={allsiteListDrop}
                          label={"Subsidiary"}
                        />
                      </Col>
                      <Col md={6}>
                        <Select
                          disabled
                          onChange={onChange}
                          value={form?.mine}
                          name="mine"
                          options={allMineListDrop}
                          label={"Mine"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormInputText
                          type="text"
                          placeHolder="Enter here"
                          label={"Well"}
                          onChange={onChange}
                          name="well"
                          error={formFieldError?.well}
                          value={form?.well}
                        />
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
              <div className="btnSec">
                <Button onClick={() => closeFun()} className="secondaryBtn" data-testid="handle-click-for-show">
                  Close
                </Button>
                <div className="d-flex">
                  <Button onClick={() => onSubmit()} className="primaryBtn">
                    Submit
                  </Button>
                </div>
              </div>
            </Container>
            <MessagePopup
              messagePopupType={messagePopupType}
              show={show}
              setShow={setShow}
              closePopup={closePopup}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterGroundWaterWell;
