import React, { Component } from "react";
import { TiInfoOutline } from "react-icons/ti";

export default class PleaseSelect extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let {
      type,
    } = this.props;
    return (
      <div className="noRecordFound text-danger">
        <TiInfoOutline /> Please Select {type}...!
      </div>
    );
  }
}
