import React from "react";
import { Col } from "react-bootstrap";
import { Row } from "reactstrap";
import {
  DDMMYYYYFormat,
  DDMMYYYYHHMMAFormat,
} from "../../../_helpers/dateHelper";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { converEachFirstWordCapitalize } from "../../../_helpers/commonHelper";
import { sections } from "../ContractorPerformance/DATA";
import { shiftsName } from "./DATA";
const HindranceAudit = ({ historyData, contractor = false }) => {
  return (
    <>
      <div>
        <div>
          {/* <p className="mt-5 viewTitle">Audit trail</p> */}

          {Array.isArray(historyData) &&
            !contractor &&
            historyData?.reverse().map((data, idx) => (
              <div key={idx}>
                <div className="preview hindrancePreview pt-0">
                  <div className="previewContent">
                    <Col lg={12}>
                      <div className="form-group d-flex">
                        <label className="reportedBy">
                          {data?.from &&
                            converEachFirstWordCapitalize(data?.from)}
                          -
                        </label>
                        <label className="userDetails me-3">
                          {" " +
                            data?.users?.firstName +
                            " " +
                            data?.users?.lastName +
                            " "}
                          /{" " + data?.users?.email + " "}{" "}
                          {data?.users?.employeeId ? " / " : ""}
                          {" " + data?.users?.employeeId
                            ? data?.users?.employeeId
                            : "" + " "}
                        </label>
                        {data?.action !== "rejected" ? (
                          <AiOutlineArrowUp color="#2ecc71" size={24} />
                        ) : (
                          <AiOutlineArrowDown size={24} color="#ff0000" />
                        )}
                        <label className="rightend">
                          {data?.createdAt &&
                            DDMMYYYYHHMMAFormat(data?.createdAt)}
                        </label>
                      </div>
                    </Col>
                    <div className="dividerView"></div>
                    {data?.action === "rejected" ? (
                      <Row>
                        <Col lg={4}>
                          <div
                            style={{ marginTop: "10px" }}
                            className="form-group d-flex "
                          >
                            <label style={{ marginRight: "10px" }}>
                              Review Date:
                            </label>
                            <p>
                              {data?.createdAt &&
                                DDMMYYYYFormat(data?.createdAt)}
                            </p>
                          </div>
                        </Col>

                        <Col lg={8}>
                          <div
                            className="form-group d-flex "
                            style={{ marginTop: "10px" }}
                          >
                            <label style={{ marginRight: "10px" }}>
                              Return Reason:
                            </label>
                            <p className="content">{data?.reason}</p>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        {JSON.parse(
                          data?.changedData
                        )?.contractor_hindrance_reasons?.map((d, idx) => {
                          return (
                            <>
                              {idx === 0 && (
                                <Row>
                                  <Col lg={4}>
                                    <div
                                      className="form-group d-flex "
                                      style={{ marginTop: "10px" }}
                                    >
                                      <label style={{ marginRight: "10px" }}>
                                        Hindrance Date:
                                      </label>
                                      <p>
                                        {JSON.parse(data?.changedData)
                                          .record_created_datetime &&
                                          DDMMYYYYFormat(
                                            JSON.parse(data?.changedData)
                                              .record_created_datetime
                                          )}
                                      </p>
                                    </div>
                                  </Col>{" "}
                                  <Col lg={8}>
                                    <div
                                      className="form-group d-flex "
                                      style={{ marginTop: "10px" }}
                                    >
                                      <label style={{ marginRight: "10px" }}>
                                        NIT Number / Tender ID - patch:
                                      </label>
                                      <p>
                                        {JSON.parse(data?.changedData)?.nit
                                          ?.nit + " "}{" "}
                                        /
                                        {" " +
                                          JSON.parse(data?.changedData)?.nit
                                            ?.tender_id}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <Col lg={2}>
                                <div
                                  className="form-group d-flex "
                                  style={{ marginTop: "10px" }}
                                >
                                  <label style={{ marginRight: "10px" }}>
                                    Shift:
                                  </label>
                                  <p>{d?.shift && shiftsName[d?.shift]}</p>
                                </div>
                              </Col>
                              <Col lg={2}>
                                <div
                                  className="form-group d-flex "
                                  style={{ marginTop: "10px" }}
                                >
                                  <label style={{ marginRight: "10px" }}>
                                    Reason:
                                  </label>
                                  <p>{d?.reasons_label?.name}</p>
                                </div>
                              </Col>
                              <Col lg={data?.from !== "contractor" ? 3 : 2}>
                                <div
                                  className="form-group d-flex "
                                  style={{ marginTop: "10px" }}
                                >
                                  <label style={{ marginRight: "10px" }}>
                                    Accepted Hours:
                                  </label>
                                  {data?.from === "contractor" && (
                                    <p>{d?.hrs_of_hindrance}</p>
                                  )}
                                  {data?.from === "representative" && (
                                    <p>
                                      {d?.hrs_of_hindrance_by_representative}
                                    </p>
                                  )}
                                  {data?.from === "section incharge" && (
                                    <p>
                                      {d?.hrs_of_hindrance_by_section_incharge}
                                    </p>
                                  )}
                                  {data?.from === "colliery manager" && (
                                    <p>
                                      {d?.hrs_of_hindrance_by_colliery_manager}
                                    </p>
                                  )}
                                </div>
                              </Col>
                              <Col lg={data?.from !== "contractor" ? 4 : 3}>
                                <div
                                  className="form-group d-flex "
                                  style={{ marginTop: "10px" }}
                                >
                                  <label style={{ marginRight: "10px" }}>
                                    Comment:
                                  </label>
                                  {data?.from === "contractor" && (
                                    <p className="content">
                                      {d?.vendor_comments}
                                    </p>
                                  )}
                                  {data?.from === "representative" && (
                                    <p className="content">
                                      {d?.representative_comments || ""}
                                    </p>
                                  )}
                                  {data?.from === "section incharge" && (
                                    <p className="content">
                                      {d?.section_incharge_comments}
                                    </p>
                                  )}
                                  {data?.from === "colliery manager" && (
                                    <p className="content">
                                      {d?.colliery_manager_comments || ""}
                                    </p>
                                  )}
                                </div>
                              </Col>
                              {data?.from === "contractor" && (
                                <Col lg={2}>
                                  <div
                                    className="form-group d-flex "
                                    style={{ marginTop: "10px" }}
                                  >
                                    <label style={{ marginRight: "10px" }}>
                                      Place:
                                    </label>
                                    {data?.from === "contractor" && (
                                      <p className="content">{d?.place}</p>
                                    )}
                                  </div>
                                </Col>
                              )}
                            </>
                          );
                        })}
                        {/* {JSON.parse(data.changedData).id} */}
                      </Row>
                    )}
                  </div>
                </div>
              </div>
            ))}

          {Array.isArray(historyData) &&
            contractor &&
            historyData
              ?.reverse()
              .slice(0, 1)
              .map((data, idx) => (
                <div key={idx}>
                  <div className="preview hindrancePreview pt-0">
                    <div className="previewContent">
                      <Col lg={12}>
                        <div className="form-group d-flex">
                          <label className="reportedBy">
                            {data?.from &&
                              converEachFirstWordCapitalize(data?.from)}
                            -
                          </label>
                          <label className="userDetails me-3">
                            {data?.users?.firstName}/{data?.users?.email}/
                            {data?.users?.employeeId}
                          </label>
                          {data?.action !== "rejected" ? (
                            <AiOutlineArrowUp size={24} color="#2ecc71" />
                          ) : (
                            <AiOutlineArrowDown size={24} color="#ff0000" />
                          )}
                          <label className="rightend">
                            {data?.createdAt &&
                              DDMMYYYYHHMMAFormat(data?.createdAt)}
                          </label>
                        </div>
                      </Col>
                      <div className="dividerView"></div>
                      {data?.action === "rejected" ? (
                        <Row>
                          <Col lg={4}>
                            <div
                              className="form-group d-flex "
                              style={{ marginTop: "10px" }}
                            >
                              <label style={{ marginRight: "10px" }}>
                                Review Date:
                              </label>
                              <p>
                                {data?.createdAt &&
                                  DDMMYYYYFormat(data?.createdAt)}
                              </p>
                            </div>
                          </Col>

                          <Col lg={8}>
                            <div
                              className="form-group d-flex "
                              style={{ marginTop: "10px" }}
                            >
                              <label style={{ marginRight: "10px" }}>
                                Return Reason:
                              </label>
                              <p className="content">{data?.reason}</p>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          {JSON.parse(
                            data?.changedData
                          )?.contractor_hindrance_reasons?.map((d, id) => {
                            return (
                              <>
                                <Row>
                                  <Col lg={4}>
                                    <div
                                      className="form-group d-flex "
                                      style={{ marginTop: "10px" }}
                                    >
                                      <label style={{ marginRight: "10px" }}>
                                        Hindrance Date:
                                      </label>
                                      <p>
                                        {JSON.parse(data?.changedData)
                                          .record_created_datetime &&
                                          DDMMYYYYFormat(
                                            JSON.parse(data?.changedData)
                                              .record_created_datetime
                                          )}
                                      </p>
                                    </div>
                                  </Col>{" "}
                                  <Col lg={8}>
                                    <div
                                      className="form-group d-flex "
                                      style={{ marginTop: "10px" }}
                                    >
                                      <label style={{ marginRight: "10px" }}>
                                        NIT Number / Tender ID:
                                      </label>
                                      <p>
                                        {JSON.parse(data?.changedData)?.nit
                                          ?.nit + " "}
                                        /
                                        {" " +
                                          JSON.parse(data?.changedData)?.nit
                                            ?.tender_id}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div
                                      className="form-group d-flex "
                                      style={{ marginTop: "10px" }}
                                    >
                                      <label style={{ marginRight: "10px" }}>
                                        Reason:
                                      </label>
                                      <p>{d?.reasons_label?.name}</p>
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div
                                      className="form-group d-flex "
                                      style={{ marginTop: "10px" }}
                                    >
                                      <label style={{ marginRight: "10px" }}>
                                        Accepted Hours:
                                      </label>
                                      {data?.from === "contractor" && (
                                        <p>{d?.hrs_of_hindrance}</p>
                                      )}
                                      {data?.from === "representative" && (
                                        <p>
                                          {
                                            d?.hrs_of_hindrance_by_representative
                                          }
                                        </p>
                                      )}
                                      {data?.from === "section incharge" && (
                                        <p>
                                          {
                                            d?.hrs_of_hindrance_by_section_incharge
                                          }
                                        </p>
                                      )}
                                      {data?.from === "colliery manager" && (
                                        <p>
                                          {
                                            d?.hrs_of_hindrance_by_colliery_manager
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div
                                      className="form-group d-flex "
                                      style={{ marginTop: "10px" }}
                                    >
                                      <label style={{ marginRight: "10px" }}>
                                        Comment:
                                      </label>
                                      {data?.from === "contractor" && (
                                        <p className="content">
                                          {d?.vendor_comments}
                                        </p>
                                      )}
                                      {data?.from === "representative" && (
                                        <p className="content">
                                          {d?.representative_comments}
                                        </p>
                                      )}
                                      {data?.from === "section incharge" && (
                                        <p className="content">
                                          {d?.section_incharge_comments}
                                        </p>
                                      )}
                                      {data?.from === "colliery manager" && (
                                        <p className="content">
                                          {d?.colliery_manager_comments}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </Row>
                      )}
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default HindranceAudit;
