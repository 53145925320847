import React from 'react'
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap'

const TabFilterButton = ({ label, toggleValue, id, handleChange, selectedTab }) => {
    return (
        <ToggleButtonGroup type="checkbox" value={selectedTab} onChange={handleChange}>
            <ToggleButton id={id} value={toggleValue}>
                {label}
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default TabFilterButton
