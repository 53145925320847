/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import jwtAuthService from "../../services/jwtAuthService";
import { AwsCognitoServices } from "../../_services/AwsCognitoServices/AwsCognitoServices";
import toastr from "toastr";
import Loading from "../../components/Loader/Loading";
const AwsCognito = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      if (!searchParams.get("code")) return;

      const response = await AwsCognitoServices.login({
        code: searchParams.get("code"),
        isMobile: 0,
      });

      if (!response?.status) {
        toastr.error(response?.message || "unauthorized");
        navigate("/");
      }

      let data = response?.data;
      if (data) {
        jwtAuthService.setSession(data.accessToken);
        jwtAuthService.setTokenId(data.id_token);
        jwtAuthService.setAccessToken(data.access_token);
        jwtAuthService.setRefreshToken(data.refresh_token);
        jwtAuthService.setExpireIn(data.expires_in);
        jwtAuthService.setTokenType(data.token_type);
        jwtAuthService.setUser(data);
        window.location.href = "/";
      }
    })();
  }, [searchParams]);

  return (
    <>
      <Loading />
    </>
  );
};

export default AwsCognito;
