import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import moment from "moment";
import "./Dashboard.css";
import {
  CHART_TYPE_BY_DATA,
  PAGE_TYPE_BY_CHART_FILTER,
  PAGE_TYPE_BY_CHART_LIST,
  RefreeChartList,
} from "./DATA";
import { Card, Col, Row } from "react-bootstrap";
import PieChartWithModal from "../../../components/PieChartWithModal/PieChartWithModal";
import { coalGradeRefreeData } from "../../../services/_coalGrade/coalGrade";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";
import MultiSelectWithCheckbox from "../../../components/SearchableSelect/MultiSelectCkeckOption";

const RefreeData = ({
  onChange,
  cref,
  form,
  pageType,
  appliedFilters,
  topSelectedTab,
  setMinMaxDate,
}) => {
  const [chartObj, setChartObj] = useState({});
  const [chartDropdown, setChartDropdown] = useState({});
  const [chartData, setChartData] = useState({});
  const [chartFilterForm, setChartFilterForm] = useState(
    PAGE_TYPE_BY_CHART_FILTER(+pageType)
  );
  const [actionChartName, setActionChartName] = useState("");

  const singleDashboardFun = useCallback(async () => {
    if (!actionChartName) return;
    let singleChartObj = chartObj?.[actionChartName];

    const chartTypeObj =
      PAGE_TYPE_BY_CHART_LIST(+pageType)?.find(
        (obj) => obj?.value === actionChartName
      ) || {};
    const filterChartData = chartFilterForm[actionChartName]
      ? singleChartObj?.filter((d) =>
          chartFilterForm[actionChartName]
            ?.split(",")
            ?.includes(d?.name.replace(",", " "))
        )
      : singleChartObj;
    setChartData((prev) => ({
      ...prev,
      [actionChartName]: CHART_TYPE_BY_DATA(
        chartTypeObj?.type,
        filterChartData
      ),
    }));
  }, [actionChartName, chartFilterForm, pageType]);

  const fetchData = useCallback(
    async (appliedFilters, isClickApply) => {
      if (isClickApply) {
        setChartFilterForm(PAGE_TYPE_BY_CHART_FILTER(+pageType));
        setActionChartName("");
      }
      appliedFilters = appliedFilters || "";
      let response = await coalGradeRefreeData(appliedFilters);
      if (!response?.status) {
        setChartData({});
        return;
      }
      const resData = response.data;
      setChartObj(response.data);
      setChartDropdown({
        referredBy: response?.data?.referredBy?.map((d) => ({
          ...d,
          name: d.name.replace(",", " "),
          value: d.name.replace(",", " "),
          label: d.name,
        })),
        referredTo: response?.data?.referredTo?.map((d) => ({
          ...d,
          name: d.name.replace(",", " "),
          value: d.name.replace(",", " "),
          label: d.name,
        })),
      });
      setMinMaxDate(resData?.minMax_date || {});
      RefreeChartList?.forEach((item) => {
        setChartData((prev) => ({
          ...prev,
          [item?.value]: CHART_TYPE_BY_DATA(item?.type, resData?.[item?.value]),
        }));
      });
    },
    [pageType]
  );

  useEffect(() => {}, []);

  useEffect(() => {
    if (!actionChartName) return;
    singleDashboardFun();
  }, [actionChartName, singleDashboardFun]);

  useEffect(() => {
    let isClickApply = true;
    let finalFltr = `${appliedFilters || ""}`;
    fetchData(finalFltr, isClickApply);
  }, [fetchData, topSelectedTab]);

  const onChangeChart = ({ target: { name, value } }) => {
    setChartFilterForm((prev) => ({ ...prev, [name]: value }));
    setActionChartName(name);
  };

  useImperativeHandle(
    cref,
    () => {
      let finalFltr = `${appliedFilters || ""}`;
      let isClickApply = true;
      return {
        fetchData: () => fetchData(finalFltr, isClickApply),
      };
    },
    [appliedFilters, topSelectedTab, fetchData]
  );

  return (
    <div className="dashboardWrapper">
      <Row className="chart-listing" id={`${"screen" + pageType}`}>
        {PAGE_TYPE_BY_CHART_LIST(pageType)?.map((d, idx) => {
          return (
            <Col md={6} key={idx} id={`zoomSec_${idx}`}>
              <Card className="chartCard chartCardBtmSpace CAAQMS_chart">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>{d?.name}</span>

                  <div className="filterZoomSec">
                    {!d?.hideFilter && (
                      <Col className="mbRemove">
                        <MultiSelectWithCheckbox
                          className="multiSelectWth"
                          options={chartDropdown?.[d?.value] || []}
                          onChange={(selectedRoles) => {
                            onChangeChart({
                              target: {
                                name: d?.value,
                                value: selectedRoles
                                  ?.map((obj) => obj.name.replace(",", " "))
                                  .join(","),
                              },
                            });
                          }}
                          value={chartFilterForm?.[d?.value]
                            ?.split(",")
                            ?.map((role) => {
                              const foundObj = (
                                chartDropdown?.[d?.value] || []
                              )?.find((obj) => obj.name === role);
                              return foundObj ? { ...foundObj } : null;
                            })
                            ?.filter((d) => !!d)}
                          disableSearch={false}
                          hasSelectAll={false}
                        />
                      </Col>
                    )}
                    <ZoomScreen
                      id={`zoomSec_${idx}`}
                      cardSizeClass={"col-md-6"}
                    />
                  </div>
                </Card.Header>
                <Card.Body
                  style={{ height: d?.type === "pieVs" ? "340px" : "" }}
                  className="pb-4"
                >
                  {d?.type === "pieVs" && (
                    <PieChartWithModal
                      pieChartColour={false}
                      seriesData={chartData[d?.value] || []}
                      customLabelFalse={true}
                      isCustomLegend={true}
                      height={"300px"}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default RefreeData;
