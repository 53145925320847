import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "../../SafetyManagementSystem/IncidentManagement/Dashboard/Dashboard.scss";
import FuelCard from "./components/FuelCard";
import { Container, Card, Col, Row, Table } from "react-bootstrap";
import { getHsdInventorySapData } from "../../../services/_fuelDispense/fuelDispense";
import {
  CONVERT_KL_L_INVENTORY,
  fuelDispenseDetails,
  fuelReceiptDetails,
  fuelTypeObj,
  getFuelReceiptDashboardTbl,
  getFuelReceiptSapDashboardTbl,
  inventoryWidgetData,
} from "./DATA";
import StackWithLineChart from "../../../components/Common/StackWithLineChart";
import { FuelDispencestackChartDataFormat } from "../../../components/Chart/Common/config";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import DataTableComponent from "../../../components/DataTableComponent/DataTableComponent";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";

const HsdInventorySap = ({
  filterForm,
  cref,
  tabValIs,
  form,
  setMinMaxDate,
}) => {
  const [inventoryData, setInventoryData] = useState({});
  const [totalReceipt, setTotalReceipt] = useState("");
  const [totalDispense, setTotalDispense] = useState("");
  const [totalFuel, setTotalFuel] = useState("");
  const [pageTable1, setPageTable1] = useState(1);
  const [receiptDataList, setReceiptDataList] = useState([]);
  const [fuelDataList, setFuelDataList] = useState([]);
  const [pageTable2, setPageTable2] = useState(1);
  const [stackData, setStackData] = useState({});
  const tableRef1 = useRef(null);
  const tableRef2 = useRef(null);

  const fetchData = useCallback(
    async (filter) => {
      let response = await getHsdInventorySapData(filter);
      if (response?.status === 1) {
        setPageTable1(1);
        setPageTable2(1);
        setTotalFuel(response?.data?.getAllFuel);

        let tableData = response?.data?.fuelReceiptDetails;
        let formattedData = tableData.map((d) => {
          return {
            date: d?.date,
            dip: tabValIs === "KL" ? d?.dip / 1000 : d?.dip,
            inventoryClosingStock:
              tabValIs === "KL"
                ? d?.inventoryClosingStock / 1000
                : d?.inventoryClosingStock,
            inventoryOpeningStock:
              tabValIs === "KL"
                ? d?.inventoryOpeningStock / 1000
                : d?.inventoryOpeningStock,
            receiptQuantity:
              tabValIs === "KL"
                ? d?.receiptQuantity / 1000
                : d?.receiptQuantity,
            tank: "1",
            totalDispense:
              tabValIs === "KL" ? d?.totalDispense / 1000 : d?.totalDispense,
          };
        });
        setReceiptDataList(formattedData || []);
        setFuelDataList(response?.data?.fuelDispnecDetails || []);

        function renameKeys(obj, newKeys) {
          const keys = Object.keys(obj);
          const renamedObj = {};
          keys.forEach((key) => {
            if (newKeys[key]) {
              renamedObj[newKeys[key]] = obj[key].map((item) => {
                if (tabValIs === "KL") {
                  return {
                    date: item.date,
                    value: parseFloat(item.value) / 1000,
                  };
                } else {
                  return {
                    date: item.date,
                    value: item.value,
                  };
                }
              });
            } else {
              renamedObj[key] = obj[key];
            }
          });
          return renamedObj;
        }

        // New key names
        const newKeyNames = {
          receipt: "Receipt",
          consumption: "Consumption",
          dip: "dip",
          opening_stock: "Opening Stock",
          closing_stock: "Closing Stock",
        };
        // Renaming keys
        const renamedData = renameKeys(
          response?.data?.fuelInventoryTrend,
          newKeyNames
        );
        const fuelInventoryTrendData = FuelDispencestackChartDataFormat(
          renamedData || {},
          false
        );
        setStackData({
          series: fuelInventoryTrendData.data,
          category: fuelInventoryTrendData.categoryData,
        });

        const FinalFilterData =
          tabValIs === "KL"
            ? CONVERT_KL_L_INVENTORY(
                {
                  ...response?.data,
                  fuelInventoryTrend: fuelInventoryTrendData,
                },
                tabValIs
              )
            : {};
        if (tabValIs === "L") {
          FinalFilterData["fuelInventoryTrend"] = {
            ...fuelInventoryTrendData,
          };
        }

        setMinMaxDate(response?.data?.minMax_date || {});
      }
    },
    [setMinMaxDate, tabValIs]
  );

  useEffect(() => {
    fetchData(filterForm);
  }, [fetchData, filterForm, tabValIs]);

  useImperativeHandle(
    cref,
    () => ({
      dashboardDataFun: () => fetchData(filterForm),
    }),
    [fetchData, filterForm]
  );

  const fuelReceiptDetail = [
    {
      name: "Date",
      selector: "date",
      cell: (row) => (row?.date ? DDMMYYYYFormat(row?.date) : ""),
    },
    {
      name: "Tank",
      selector: "tank",
      isShowMobile: true,
    },
    {
      name: "Opening Stock",
      selector: "inventoryOpeningStock",
      isShowMobile: true,
    },
    {
      name: "Receipt",
      selector: "receiptQuantity",
      isShowMobile: true,
    },
    {
      name: "Dispense",
      selector: "totalDispense",
      isShowMobile: true,
    },
    {
      name: "Closing Stock",
      selector: "inventoryClosingStock",
      isShowMobile: true,
    },
    {
      name: "Dip",
      selector: "dip",
      isShowMobile: true,
    },
  ];

  const fuelReceiptDashboardTblFunc = async (filter, type, setPage) => {
    return;
    // const response = await getFuelReceiptSapDashboardTbl(filter);

    // if (!response?.status) {
    //   setPage((prev) => prev - 1);
    //   return;
    // }
    // let appendData = response?.data?.fuelReceiptDatas || [];
    // if (type === fuelTypeObj?.receiptData) {
    //   if (tabValIs === "KL") {
    //     appendData = appendData?.map((ele) => {
    //       Object.assign(ele, { Receipt_Qty: ele?.Receipt_Qty / 1000 });
    //       return ele;
    //     });
    //   }
    //   setReceiptDataList((prev) => [...prev, ...appendData]);
    // } else {
    //   if (tabValIs === "KL") {
    //     appendData = appendData?.map((ele) => {
    //       Object.assign(ele, { Fueling_Qty: ele?.Fueling_Qty / 1000 });
    //       return ele;
    //     });
    //   }
    //   setFuelDataList((prev) => [...prev, ...appendData]);
    // }
  };

  useEffect(() => {
    const totalReceipt = receiptDataList.reduce(
      (acc, curr) => acc + +curr.receiptQuantity,
      0
    );
    setTotalReceipt(totalReceipt);
    const totalDispense = receiptDataList.reduce(
      (acc, curr) => acc + +curr.totalDispense,
      0
    );
    setTotalDispense(totalDispense);

    let data = {
      getAllFuel: tabValIs === "KL" ? totalFuel / 1000 : totalFuel,
      dailyFuelReceipt: tabValIs === "KL" ? totalReceipt / 1000 : totalReceipt,
      dailyFuelDispense:
        tabValIs === "KL" ? totalDispense / 1000 : totalDispense,
    };
    setInventoryData(data);
  }, [receiptDataList, tabValIs, totalFuel]);

  return (
    <div className="dashboardWrapper">
      <Row id="screen2">
        <Col md={3} className="dashboardLeftWidgets">
          <div className="dashCards">
            {inventoryWidgetData(inventoryData, tabValIs, form).map(
              (item, i) => (
                <FuelCard key={i} label={item.label} quantity={item.quantity} />
              )
            )}
          </div>
        </Col>
        <Col md={9} className="dashboardRightCharts" id="fuelSapChart3">
          <Card className="chartCard chartCardBtmSpace">
            <Card.Header className="d-flex justify-content-between">
              <span>Daily Fuel Inventory Trend</span>
              <ZoomScreen
                id={`fuelSapChart3`}
                cardSizeClass={"dashboardRightCharts col-md-9"}
              />
            </Card.Header>
            <Card.Body>
              <StackWithLineChart
                category={stackData.category}
                series={stackData.series || []}
                yAxisName={tabValIs}
                customTooltip
              />
            </Card.Body>
          </Card>
          <Row>
            <Col md={12}>
              <Card className="chartCard tableCard totalValueTbl">
                <Card.Header> Daily Fuel Receipt Details </Card.Header>

                <Card.Body className="p-0">
                  <DataTableComponent
                    columns={fuelReceiptDetail}
                    data={receiptDataList}
                    page={pageTable1}
                    setPage={setPageTable1}
                    ref={tableRef1}
                    tableName={fuelTypeObj?.receiptData}
                    totalCount={inventoryData?.totalCountReceipt}
                    matchCount={receiptDataList?.length}
                    filterData={filterForm}
                    onCallScrollFunc={fuelReceiptDashboardTblFunc}
                  />
                  <div className="tblTotalData">
                    <span>
                      <label>Total Dispensed:</label>
                      <strong>{totalDispense + tabValIs}</strong>
                    </span>

                    <span className="ms-3">
                      <label>Total Receipt:</label>
                      <strong>{totalReceipt + tabValIs}</strong>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default HsdInventorySap;
