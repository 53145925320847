import React from "react";
import ReactEcharts from "echarts-for-react";
import NoRecord from "../../Common/NoRecord";
import { IS_MOBILE } from "../../../_helpers/helper";
import { isDecimal } from "../../../_helpers/helper";
class BasicBarChartWithCustomToolTip extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.seriesData !== this.props.seriesData) {
      // console.log("forceUpdate");
      this.forceUpdate();
    }
  }

  render() {
    let {
      category,
      seriesData,
      xAxis,
      yAxis,
      xAxisName,
      yAxisName,
      customToolTip,
      legendFlag,
      valueWithUnit,
      minMaxData,
      noRecordText,
    } = this.props;

    // console.log("seriesData", seriesData);


  //   category.sort((a, b) => {
  //     const [monthA, yearA] = a.split('-');
  //     const [monthB, yearB] = b.split('-');
  //     if (yearA !== yearB) {
  //         return parseInt(yearA) - parseInt(yearB);
  //     } else {
  //         const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  //         return months.indexOf(monthA) - months.indexOf(monthB);
  //     }
  // });


    let echartMultipleBar2Option = {
      tooltip: {
        formatter: (params) => {
          // console.log("BasicBarChartWithCustomToolTip", customToolTip[params?.name][0]?.fullName, customToolTip, params);
          return `${params?.marker}${"&nbsp"}${
            customToolTip[params?.name][0]?.fullName
          } : ${isDecimal(params?.value)} ${
            valueWithUnit ? valueWithUnit : ""
          }<br/>`;
        },
      },
      yAxis: yAxis
        ? yAxis
        : {
            name: yAxisName ? yAxisName : "",
            nameLocation: "middle",
            nameGap: 30,
            type: "value",
            min: 0, // minimum value to start
            minInterval: 1, // for integer axis value
            nameTextStyle: {
              fontWeight: "bold",
            },
          },
      xAxis: xAxis
        ? {
            ...xAxis,
            nameTextStyle: {
              color: "white",
            },
          }
        : {
            name: xAxisName ? xAxisName : "",
            nameLocation: "end",
            nameRotate: 90,
            nameTextStyle: {
              fontWeight: "bold",
              color: "white",
            },
            type: "category",
            data: category ? category : [],
          },
      legend:
        legendFlag == false
          ? false
          : {
              type: "scroll",
              // horizontalAlign: 'center',
              itemGap: 20,
              to: 100,
            },
      series: seriesData ? seriesData : [],
      grid: {
        top: 10,
        right: 20,
      },
      dataZoom: [
        {
          start: 0,
          type: "inside",
        },
        {
          start: 0,
        },
      ],
    };

    if (this.props.isLegeng) {
      echartMultipleBar2Option.legend = this.props.isLegeng;
    }
    if (this.props.grid) {
      echartMultipleBar2Option.grid = this.props.grid;
    }
    let name =
      echartMultipleBar2Option.xAxis &&
      Object.keys(echartMultipleBar2Option.xAxis).length > 0 &&
      echartMultipleBar2Option.xAxis.name
        ? echartMultipleBar2Option.xAxis.name
        : null;

    // echartMultipleBar2Option = JSON.parse(JSON.stringify(echartMultipleBar2Option));
    // console.log("echartMultipleBar2Option", echartMultipleBar2Option);
    return (
      <React.Fragment>
        {
          Array.isArray(category) && category.length > 0 ? (
            <>
              <ReactEcharts
                style={{
                  height: IS_MOBILE()
                    ? "200px"
                    : this.props.height
                    ? this.props.height
                    : "264px",
                }}
                // style={{ height: IS_MOBILE() ? '200px' : height ? height : "250px" }}
                option={echartMultipleBar2Option}
              />
              <div className="text-center" style={{ marginTop: -38 }}>
                <span
                  className="font-weight-bold font-12"
                  style={{ textTransform: "capitalize" }}
                >
                  {name}
                </span>
                {minMaxData && (
                  <div className="minMaxSec">
                    <div className="contentData">
                      <label>{"Min"}:</label>
                      <p>{`${minMaxData?.min || 0} ${yAxisName}`}</p>
                    </div>
                    <div className="contentData">
                      <label>{"Max"}:</label>
                      <p>{`${minMaxData?.max || 0} ${yAxisName}`}</p>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <NoRecord noRecordText={noRecordText} />
          )
          // <div className="noRecordFound"><TiInfoOutline /> No Records found...!</div>
        }
      </React.Fragment>
    );
  }
}

export default BasicBarChartWithCustomToolTip;
