import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "../../../../../../components/MobileComponents/Select/Select";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import ReportButton from "../../ReportButton/ReportButton";
import { CustomSwitch } from "../../../../../../components/CustomSwitch/CustomSwitch";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Asterik from "../../../../../../components/Common/Asterik/Asterik";

const EquipmentDetailsTab = ({
  form = {},
  onChange,
  error = {},
  setTab,
  onSubmit,
  assetDetails,
  matDetails,
  onChangeAssetDetailsSku,
  onChangeAssetDetailsSkuInput,
  mats,
  assetDetailsErrors,
  onChangeAssetDetails,
  deleteRowAssetDetails,
  addRowAssetDetails,
  tab,
  asterikRules,
}) => {
  return (
    <>
      <div className="mobileFormWrapper">
        <h3>Equipment Details</h3>
        <div className="ehsFormWrapper p-0">
          <div className="customSwitchSec">
            <CustomSwitch
              name="isAssetIncident"
              value={
                form.isAssetIncident === null ? false : form.isAssetIncident
              }
              onChange={onChange}
              title="Equipment Affected"
            />
          </div>
          <p className="text-danger">{error?.isAssetIncident}</p>
          <Form>
            <Row>
              {form.isAssetIncident && (
                <Col md="12">
                  {assetDetails.length ? (
                    assetDetails.map((x, index) => {
                      return x.deleted ? null : (
                        <div className="addDataformCards" key={index}>
                          <div className="formFields">
                            <label>
                              Equipment SKU / Id{" "}
                              <Asterik
                                fieldName={"sku"}
                                requiredRules={asterikRules}
                              />
                            </label>
                            <input
                              name="sku"
                              data-testid="sku"
                              value={x.sku}
                              onChange={({ target: { name, value } }) =>
                                onChangeAssetDetails(index, name, value)
                              }
                              type="text"
                              className="form-control"
                            />
                            <span className="small text-danger">
                              {assetDetailsErrors[index]?.sku}
                            </span>
                          </div>
                          <div className="formFields mb-3">
                            {x.hemm_id ? (
                              <>
                                <label>
                                  Equipment Type
                                  <Asterik
                                    fieldName={"type"}
                                    requiredRules={asterikRules}
                                  />
                                </label>
                                <input
                                  name="type"
                                  data-testid="type"
                                  value={x.type}
                                  onChange={({ target: { name, value } }) =>
                                    onChangeAssetDetails(index, name, value)
                                  }
                                  type="text"
                                  className="form-control"
                                  disabled={true}
                                />
                                <span className="small text-danger">
                                  {assetDetailsErrors[index]?.type}
                                </span>
                              </>
                            ) : (
                              <Select
                                label={"Equipment Type"}
                                name="type"
                                options={matDetails.map((obj) => ({
                                  label: obj.name,
                                  value: obj.id,
                                }))}
                                value={+x.type}
                                onChange={({ target: { name, value } }) =>
                                  onChangeAssetDetails(index, name, value)
                                }
                                error={assetDetailsErrors[index]?.type}
                                asterikRules={asterikRules}
                              />
                            )}
                          </div>
                          <div className="formFields mb-3">
                            <label>
                              Equipment Name{" "}
                              <Asterik
                                fieldName={"name"}
                                requiredRules={asterikRules}
                              />
                            </label>
                            <input
                              name="name"
                              value={x.name}
                              onChange={({ target: { name, value } }) =>
                                onChangeAssetDetails(index, name, value)
                              }
                              type="text"
                              className="form-control"
                              disabled={x.hemm_id ? true : false}
                            />
                            <span className="small text-danger">
                              {assetDetailsErrors[index]?.name}
                            </span>
                          </div>
                          <div className="formFields mb-3">
                            <label>
                              Equipment Incident Details{" "}
                              <Asterik
                                fieldName={"details"}
                                requiredRules={asterikRules}
                              />
                            </label>
                            <input
                              name="details"
                              value={x.details}
                              onChange={({ target: { name, value } }) =>
                                onChangeAssetDetails(index, name, value)
                              }
                              type="text"
                              className="form-control"
                            />
                            <span className="small text-danger">
                              {assetDetailsErrors[index]?.details}
                            </span>
                          </div>
                          <div className="formFields mb-3">
                            <label>Equipment Damage (Y/N)</label>
                            <div className="d-flex">
                              <label className="radio radio-primary form-check-inline mr-3">
                                <input
                                  onChange={({ target: { name, value } }) =>
                                    onChangeAssetDetails(
                                      index,
                                      name.split("-")[0],
                                      value
                                    )
                                  }
                                  type="radio"
                                  name={`damage-${index}`}
                                  value="Yes"
                                  checked={x.damage === "Yes"}
                                />
                                <span>Yes</span>
                                <span className="checkmark" />
                              </label>
                              <label className="radio radio-primary form-check-inline ms-3">
                                <input
                                  onChange={({ target: { name, value } }) =>
                                    onChangeAssetDetails(
                                      index,
                                      name.split("-")[0],
                                      value
                                    )
                                  }
                                  type="radio"
                                  name={`damage-${index}`}
                                  value="No"
                                  checked={x.damage === "No"}
                                />
                                <span>No</span>
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                          <div className="actionIconsGroup">
                            <span
                              data-testid={"remove-leaf" + index}
                              onClick={() => deleteRowAssetDetails(index)}
                              className="icons font-21 text-danger"
                            >
                              <IoIosCloseCircleOutline
                                size="26"
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                  <div className="addMoreBtn">
                    <span
                      className="addTxt"
                      onClick={addRowAssetDetails}
                      data-testid="add-leaf"
                    >
                      <AiOutlinePlus />
                      Add
                    </span>
                  </div>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </div>
      <ReportButton setTab={setTab} tab={tab} />
    </>
  );
};

export default EquipmentDetailsTab;
