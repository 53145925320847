import React from "react";
import { Form } from "react-bootstrap";
import { Input } from "reactstrap";
import InputError from "../_form/InputError/InputError";
import Asterik from "../Common/Asterik/Asterik";

function FormTextArea(props) {
  const {
    label,
    error,
    errorClassName,
    name,
    readOnly,
    formGroupClass,
    rows,
    value,
    onChange,
    asterikRules,
  } = props;

  return (
    <Form.Group className={formGroupClass || "mb-3"}>
      {label && (
        <Form.Label className="form-label-input">
          {label}
          <Asterik fieldName={name} requiredRules={asterikRules} />
        </Form.Label>
      )}
      <Input
        type="textarea"
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
        readOnly={readOnly}
      />
      <InputError error={error} className={errorClassName} />
    </Form.Group>
  );
}

export default FormTextArea;
