import React, { useMemo, useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../Assets/Images/filter.svg";
import { Container } from "react-bootstrap";
import { useForm } from "../../../_helpers/hooks";
import CoalGradeUserFilterForm from "./CoalGradeUserFilterForm";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import {
  auth_user,
  capitalizeName,
  findUserPermissionedTabList,
} from "../../../_helpers/helper";
import { useLocation } from "react-router-dom";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import CoalGradeUserView from "./CoalGradeUserView";
import { SidebarData } from "../DATA";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import PermissionsFormEdit from "./PermissionsFormEdit";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import {
  addLink,
  userLabel,
  IncidentuserListApis,
  userFilterForm,
  customTabsData,
} from "../../SafetyManagementSystem/IncidentManagement/UserManagement/DATA";
import {
  CONTRACTOR_ROLE_OBJECT,
  Env_ROLE_OBJECT,
  COAL_GRADE_VIEW,
  USER_MANAGEMENT_ROLE,
  isContractorModuleAdmin,
} from "../../../_services/Auth/helper";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";

const CoalGradeUserList = () => {
  const [getAllFilter, setGetAllFilter] = useState(`&type=users`);
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm({
    ...userFilterForm,
    siteId: auth_user() ? auth_user()?.siteId : null,
  });
  const [isListData, setIsListData] = useState(true);
  const { pathname } = useLocation();
  const [customTabList, setCustomTabList] = useState(
    findUserPermissionedTabList(customTabsData)
  );

  let newAddLink = addLink;

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          data-testid="open-btn"
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>

        <CoalGradeUserFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      </div>
    );
  };

  let newFilterBtn = FilterBtn;

  const getAll = async () => {
    const response = await IncidentuserListApis.getAll.api(
      `&type=users&page=1&limit=10`
    );
    // console.log("33333333333333333",response);
    if (Array.isArray(response?.data) && response?.data?.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  useEffect(() => {
    setCustomTabList((prev) => {
      return prev.map((d) => {
        if (d.to === pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
  }, [pathname]);

  const onResetFilter = () => {
    setForm({
      ...userFilterForm,
      siteId: auth_user() ? auth_user()?.siteId : null,
    });
    setGetAllFilter(`&type=users`);
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];

      if (element) {
        if (key === "siteId") filter += `&search[${key}]=${element}`;
        else filter += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(`&type=users` + filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const columnFields = useMemo(
    () => [
      {
        name: userLabel?.name,
        minWidth: "200px",
        selector: "name",
        sortable: true,
        cell: (row) => {
          return capitalizeName(
            `${row?.firstName ? row?.firstName : ""} ${
              row?.lastName ? row?.lastName : ""
            }`
          );
        },
      },
      {
        name: userLabel?.roleId,
        selector: "roleId",
        sortable: true,
        cell: (row) => {
          return row?.role?.title || "";
        },
      },
      {
        selector: "siteId",
        sortable: true,
        cell: (row) => {
          return row?.site?.name || "";
        },
        name: userLabel?.siteId,
      },
      {
        name: userLabel?.email,
        selector: "email",
        sortable: true,
      },
      {
        name: "Permissions",
        sortable: false,
        selector: "accessPermissions",
        cell: (row) => {
          let role = row.accessPermissions?.map(
            (d) => COAL_GRADE_VIEW?.userManagement[d.permission]
          );
          // console.log(role);
          let roleStr = role.filter((val) => val !== undefined);
          let roleStrNew = roleStr.join(",").substring(0, 20);
          console.log(roleStrNew.length);
          if (roleStrNew.length > 19) {
            roleStrNew = roleStrNew + "...";
          }
          return roleStrNew;
        },
        minWidth: "200px",
      },
    ],
    []
  );

  const viewAsPopup = (form) => {
    return <CoalGradeUserView form={form} />;
  };

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabList} />;
  };

  const editAsPopup = (form, setShowAddEdit, getAll) => {
    return (
      <PermissionsFormEdit
        formData={form}
        setShowAddEdit={setShowAddEdit}
        getAll={getAll}
      />
    );
  };

  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  viewTitle="User Details"
                  fields={columnFields}
                  apis={IncidentuserListApis}
                  title={"User"}
                  // hideAddButton={true}
                  enableMobileIcon={true}
                  hideEditButton={true}
                  editTitle="Edit Permissions"
                  customTableTitle="User Management"
                  FilterBtn={newFilterBtn}
                  viewAsPopup={viewAsPopup}
                  editAsPopup={editAsPopup}
                  hideSnColumn
                  pageLink={newAddLink}
                  primaryKey="id"
                  pagination
                  hideDeleteButton
                  
                  breadCrumb={[
                    {
                      name: "Coal Grade",
                      path: "/",
                    },

                    {
                      name: "Users Management",
                      path: "#",
                    },
                  ]}
                  getAllFilter={getAllFilter}
                  isNoTabOnPage
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              // tabButtons={tabButtons}
              hideAddBtn={true}
              // pageLink={newAddLink}
              breadCrumb={[
                {
                  name: "Coal Grade",
                  path: "#",
                },
                {
                  name: "User Management",
                  path: "#",
                },
              ]}
              customTableTitle={"Blank"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CoalGradeUserList;
