import React from "react";
import SafetySideBar from "../../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../IncidentManagement/DATA";
import HelpManualCommonComponent from "../../ContractorManagement/CommonComponent/HelpManualCommonComponent";

const SafetyDocument = () => {
  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <HelpManualCommonComponent
        isSafetyDocuments
        moduleName={"Safety Document"}
      />
    </div>
  );
};

export default SafetyDocument;
