import { DDMMYYYYFormat } from "../../../../_helpers/dateHelper";
import {
  getAllBowserStock,
  getAllFuelStock,
  getStockDataBydataId,
} from "../../../../services/_fuelDispense/fuelDispense";
export const initialForm = {
  site_id: "",
  tank_id: "",
  date: "",
  openingDip: "",
  closingDip: "",
  useOpeningDip: "",
  useClosingDip: "",
  openingDipMeasurement: "",
  closingDipMeasurement: "",
  type: "",
};
export const tankLabel = {
  site_id: "Mine",
  tank_id: "Tank Name",
  date: "Date",
  openingDip: "Opening Dip (L)",
  closingDip: "Closing Dip (L)",
  useOpeningDip: "Use Opening Dip Data as Opening Stock",
  useClosingDip: "Use Closing Dip Data as Closing Stock",
  useOpeningStock: "Opening Stock",
  useClosingStock: "Closing Stock",
  finalOpeningStock: "Opening Stock (L)",
  finalClosingStock: "Closing Stock (L)",
  isManual: "Is Manual",
  dispenseQyt: "Total Dispensed (L)",
  totalReceipt: "Total Receipt (L)",
  openingDipMeasurement: "Opening Dip Measurement",
  closingDipMeasurement: "Closing Dip measurement",
  type: "Type",
  hemm_id: "Bowser",
};
export const validationRules = [
  {
    label: tankLabel.type,
    type: "string",
    isRequired: true,
    field_name: "type",
  },
  {
    label: tankLabel.tank_id,
    type: "string",
    isRequired: true,
    field_name: "tank_id",
  },
  {
    field_name: "site_id",
    label: tankLabel.site_id,
    isRequired: true,
    type: "string",
  },
  {
    label: tankLabel.date,
    field_name: "date",
    type: "string",
    isRequired: true,
  },
  // {
  //   label: tankLabel.openingDip,
  //   field_name: "openingDip",
  //   type: "number",
  //   isRequired: false,
  // },
  // {
  //   label: tankLabel.closingDip,
  //   field_name: "closingDip",
  //   type: "number",
  //   isRequired: false,
  // },
  // {
  //   label: tankLabel.openingDipMeasurement,
  //   field_name: "openingDipMeasurement",
  //   type: "number",
  //   isRequired: false,
  // },
  // {
  //   label: tankLabel.closingDipMeasurement,
  //   field_name: "closingDipMeasurement",
  //   type: "number",
  //   isRequired: false,
  // },
];

export const tankApi = {
  getAll: {
    api: getAllFuelStock,
  },
  getById: {
    api: getStockDataBydataId,
  },
};

export const bowserApi = {
  getAll: {
    api: getAllBowserStock,
  },
  getById: {
    api: getStockDataBydataId,
  },
};

export const fuelLocationColumnFields = [
  {
    name: tankLabel.site_id,
    selector: "site_id",
    sortable: true,
    cell: (row) => row?.site?.name,
    minWidth: "150px",
  },
  {
    name: tankLabel.tank_id,
    selector: "tank",
    sortable: true,
    cell: (row) => row?.tank?.name,
    minWidth: "200px",
  },
  {
    name: tankLabel.date,
    selector: "date",
    sortable: true,
    cell: (row) => row?.date && DDMMYYYYFormat(row?.date),
    minWidth: "200px",
  },
  {
    name: tankLabel.dispenseQyt,
    selector: "dispenseQyt",
    sortable: true,
    cell: (row) => row?.dispenseQyt,
    minWidth: "200px",
  },
  {
    name: tankLabel.totalReceipt,
    selector: "totalReceipt",
    sortable: true,
    cell: (row) => row?.totalReceipt,
    minWidth: "200px",
  },
  {
    name: tankLabel.openingDip,
    selector: "openingDip",
    sortable: true,
    cell: (row) => row?.openingDip,
    minWidth: "250px",
  },
  {
    name: tankLabel.closingDip,
    selector: "closingDip",
    sortable: true,
    cell: (row) => row?.closingDip,
    minWidth: "250px",
  },
  {
    name: tankLabel.finalOpeningStock,
    selector: "finalOpeningStock",
    minWidth: "200px",
    sortable: true,
    cell: (row) => row?.finalOpeningStock,
  },
  {
    name: tankLabel.finalClosingStock,
    selector: "finalClosingStock",
    sortable: true,
    minWidth: "200px",
    cell: (row) => row?.finalClosingStock,
  },
];

export const stockFilterForm = {
  isMannualEntry: "",
};
export const MannulAutoType = [
  {
    value: "auto",
    label: "Auto Calculated",
  },
  {
    value: "manual",
    label: "Manual",
  },
];

export const StockType = [
  {
    value: "negative",
    label: "Negative Stock",
  },
  {
    value: "positive",
    label: "Positive Stock",
  },
  {
    value: "both",
    label: "Non Zero Dispense",
  },
  {
    value: "null",
    label: "Zero Dispense",
  },
];

export const types = [
  {
    value: "bowser",
    label: "Bowser",
  },
  {
    value: "tank",
    label: "Tank",
  },
];

export const tabUrl = {
  stock: [
    {
      to: "/fuel-management-system/fuel-management-list/stock",
      // permission: ["safety_admin"],
      label: "Tank Stock",
      active: 0,
    },
    {
      to: "/fuel-management-system/fuel-management-list/bowser-stock",
      label: "Bowser Stock",
      active: 0,
    },
  ],
};
