import React, { useMemo, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom/dist";
import "../../components/Common/dashboard/HomeDashboard.scss";
import { getPermission } from "../../_services/Auth/helper";
import "./SideBarComponent.scss";
import logo from "../../Assets/Images/logo.png";
import { IS_MOBILE, auth_user } from "../../_helpers/helper";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineMenu } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import {
  filterUrlFunc,
  fuelDashboardLink,
} from "./../../_services/CommonService/CommonService.js";
import { useSelector } from "react-redux";

const SideBarComponent = ({ SidebarData, pageTitle, goBack, moduleTitle }) => {
  const [activeSideBar, setActiveSideBar] = useState(false);
  let navigate = useNavigate();
  const url = useLocation();

  const storeFilter = useSelector((state) => state?.dashboardFilter?.fuelDash);

  const list = filterUrlFunc(SidebarData)?.map((row) => {
    let found = false;

    if (typeof row?.activeUrl !== "string") {
      for (const searchTerm of row?.activeUrl || []) {
        if (!found && url?.pathname.includes(searchTerm)) {
          found = true;
        }
      }
      if (typeof row?.link !== "string") {
        return { ...row, link: row.link(), active: found ? 1 : 0 };
      } else {
        return { ...row, active: found ? 1 : 0 };
      }
    } else {
      if (row.link === url.pathname || url.pathname?.includes(row?.activeUrl)) {
        if (typeof row?.link !== "string") {
          return { ...row, link: row.link(), active: 1 };
        } else {
          return { ...row, active: 1 };
        }
      } else {
        if (typeof row?.link !== "string") {
          return { ...row, link: row.link() };
        } else return row;
      }
    }
  });
  let fuelDashboardUrl = fuelDashboardLink(storeFilter?.source);

  return IS_MOBILE() ? (
    <>
      <div className="mobileHeader">
        {!pageTitle ? (
          <span className="mobileHeaderHeading">
            <AiOutlineMenu onClick={() => setActiveSideBar(true)} />
            <span className="pageTitle">{moduleTitle}</span>
          </span>
        ) : (
          <span className="mobileHeaderHeading">
            <BsArrowLeft onClick={() => goBack()} />
            <span className="pageTitle">{pageTitle}</span>
          </span>
        )}
      </div>
      {activeSideBar && (
        <div className={`mobileSidebar`}>
          <Nav className="nav-menu">
            <div className="divider"></div>
            <div className="nav-items">
              <div className="mobLogoSec">
                <img
                  src={logo}
                  alt="main-logo"
                  onClick={() => navigate("/mobile")}
                  className="img-fluid"
                />
                <span
                  className="crossClick"
                  onClick={() => setActiveSideBar(false)}
                >
                  <RxCross2 />{" "}
                </span>
              </div>
              {list?.map((row, key) => {
                return (
                  <>
                    <Nav.Item
                      key={row.id}
                      className={row.active ? "active" : ""}
                    >
                      <Nav.Link
                        eventKey={row.activeKey}
                        as={Link}
                        to={
                          row.link ===
                            "/mobile/fuel-management-system/dashboard/1" ||
                          row.link ===
                            "/mobile/fuel-management-system/sap-dashboard/1"
                            ? "/mobile" + fuelDashboardUrl
                            : row.link
                        }
                      >
                        <img src={row.img} alt="linkImg1" className="mx-auto" />
                        <span>{row.title} </span>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                );
              })}
            </div>
          </Nav>
        </div>
      )}
    </>
  ) : (
    <>
      <div className={`mgmtSidebar`}>
        <Nav className="nav-menu">
          <div className="divider"></div>
          <div className="nav-items">
            {list?.map((row, key) => {
              return (
                <Nav.Item
                  key={key + row.id}
                  className={row.active ? "active" : ""}
                >
                  <Nav.Link
                    eventKey={row.activeKey}
                    as={Link}
                    to={
                      row.link === "/fuel-management-system/dashboard/1" ||
                      row.link === "/fuel-management-system/sap-dashboard/1"
                        ? fuelDashboardUrl
                        : row.link
                    }
                  >
                    <img src={row.img} alt="linkImg1" className="mx-auto" />
                    <span>{row.title}</span>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </div>
        </Nav>
      </div>
    </>
  );
};

export default SideBarComponent;
