import Modal from "react-bootstrap/Modal";
import Select from "../../../components/Select/Select";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { Button, Col, Row } from "react-bootstrap";
import {
  IS_MOBILE,
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
} from "../../../_helpers/helper";
import MultiSelectWithCheckbox from "../../../components/SearchableSelect/MultiSelectCkeckOption";
import CustomRadio from "../../../components/_form/CustomRadio/CustomRadio";

const HsdInventoryFilter = ({
  form,
  onChange,
  area,
  subsidery,
  sites,
  equipment,
  resetFun,
  applyFun,
  handleClose,
  show,
  optionsList,
  fuelingLocations,
  workshopIds,
  hideInventoryFilter,
  tankOpt,
  bowserOpt,
}) => {
  return (
    <>
      <Modal
        onHide={handleClose}
        backdrop="static"
        className="filterModal"
        keyboard={false}
        show={show}
      >
        <Modal.Header closeButton className="px-3">
          <h5 className="modal-title">Filter</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <div className="form-group mb-3">
              <Select
                name="subsideryId"
                value={form?.subsideryId || ""}
                onChange={onChange}
                label={"Subsidiary"}
                options={subsidery}
                KEY_label={"name"}
                KEY_value={"id"}
                blankSelect={"Select"}
                disabled={IS_USER_SITE_MINES() || IS_USER_SITE_RO()}
                menuPortalTarget={null}
              />
            </div>
            <div className="form-group mb-3">
              <Select
                name="siteId"
                value={form?.siteId || ""}
                onChange={onChange}
                options={sites}
                KEY_label={"name"}
                KEY_value={"id"}
                label={"Site"}
                blankSelect={"Select"}
                disabled={IS_USER_SITE_MINES()}
                menuPortalTarget={null}
              />
            </div>
            {!hideInventoryFilter ? (
              <>
                <div className="form-group mb-3">
                  <Select
                    name="workshopId"
                    label={"Workshop Id"}
                    value={form?.workshopId || ""}
                    onChange={onChange}
                    menuPortalTarget={null}
                    options={workshopIds}
                    blankSelect={"Select"}
                  />
                </div>
                <div className="form-group mb-3">
                  <Select
                    name="fuelLocationId"
                    label={"Fueling Location"}
                    value={form?.fuelLocationId || ""}
                    onChange={onChange}
                    menuPortalTarget={null}
                    options={fuelingLocations}
                    blankSelect={"Select"}
                  />
                </div>
                <div className="form-group mb-3">
                  <label class="form-label">Equipment Type</label>
                  <MultiSelectWithCheckbox
                    value={
                      form?.eqptType
                        ? form?.eqptType
                            ?.map((role) => {
                              const found = optionsList?.equipment_type?.find(
                                (obj) => obj.value === role
                              );
                              return found ? { ...found } : null;
                            })
                            ?.filter((d) => !!d)
                        : []
                    }
                    disableSearch={true}
                    options={optionsList?.equipment_type || []}
                    onChange={(selectedRoles) => {
                      onChange({
                        target: {
                          value: selectedRoles?.map((obj) => obj.value),
                          name: "eqptType",
                        },
                      });
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="form-group mb-3">
                <label className="form-label">Dashboard Type</label>
                <CustomRadio
                  customStyle={"-29px"}
                  type="checkbox"
                  name="inventoryTypeBowser"
                  onChange={onChange}
                  title={""}
                  acceptTitle={"Bowser"}
                  rejectTitle={"Tank"}
                  checkBox={true}
                  customClass={
                    "customRadioClass " +
                    (IS_MOBILE() ? "d-block" : "form-label-input mb-2")
                  }
                  value={
                    form.inventoryTypeBowser === null
                      ? false
                      : form.inventoryTypeBowser
                  }
                  removeSpace={IS_MOBILE() ? true : false}
                />

                {form.inventoryTypeBowser ? (
                  <Select
                    name="bowserId"
                    label={"Bowser"}
                    value={form?.bowserId || ""}
                    onChange={onChange}
                    menuPortalTarget={null}
                    options={
                      form?.siteId
                        ? bowserOpt.filter((d) => d.site === form?.siteId)
                        : bowserOpt
                    }
                    blankSelect={"Select"}
                    KEY_label={"name"}
                    KEY_value={"id"}
                  />
                ) : (
                  <Select
                    name="tankId"
                    label={"Tank"}
                    value={form?.tankId || ""}
                    onChange={onChange}
                    menuPortalTarget={null}
                    options={
                      form?.siteId
                        ? tankOpt.filter((d) => d.site === form?.siteId)
                        : tankOpt
                    }
                    blankSelect={"Select"}
                    KEY_label={"name"}
                    KEY_value={"id"}
                  />
                )}
              </div>
            )}
            {/* <div className="form-group mb-3">
              <Select
                name="workshopId"
                label={"Workshop Id"}
                value={form?.workshopId || ""}
                onChange={onChange}
                menuPortalTarget={null}
                options={workshopIds}
                blankSelect={"Select"}
              />
            </div>
            <div className="form-group mb-3">
              <Select
                name="fuelLocationId"
                label={"Fueling Location"}
                value={form?.fuelLocationId || ""}
                onChange={onChange}
                menuPortalTarget={null}
                options={fuelingLocations}
                blankSelect={"Select"}
              />
            </div>
            <div className="form-group mb-3">
              <label class="form-label">Equipment Type</label>
              <MultiSelectWithCheckbox
                value={
                  form?.eqptType
                    ? form?.eqptType
                        ?.map((role) => {
                          const found = optionsList?.equipment_type?.find(
                            (obj) => obj.value === role
                          );
                          return found ? { ...found } : null;
                        })
                        ?.filter((d) => !!d)
                    : []
                }
                disableSearch={true}
                options={optionsList?.equipment_type || []}
                onChange={(selectedRoles) => {
                  onChange({
                    target: {
                      value: selectedRoles?.map((obj) => obj.value),
                      name: "eqptType",
                    },
                  });
                }}
              />
            </div> */}
            <Row>
              <label className="form-label">Date Duration</label>
              <Col lg={12}>
                <CustomDatePicker
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  onChangeDate={onChange}
                  value={form?.startDate}
                  placeholder={"Start Date"}
                />
              </Col>
              <Col lg={12}>
                <CustomDatePicker
                  // className={"cndrPosition"}
                  value={form?.endDate}
                  dateFormat="YYYY-MM-DD"
                  name="endDate"
                  onChangeDate={onChange}
                  placeholder={"End Date"}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="filterBtn">
            <Button
              style={{ minWidth: "auto" }}
              data-testid="resetFunId"
              className="secondaryBtn"
              onClick={() => resetFun()}
            >
              Reset
            </Button>
            <Button
              className="primaryBtn"
              onClick={() => applyFun()}
              style={{ minWidth: "auto" }}
              data-testid="close-message-popup"
            >
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HsdInventoryFilter;
