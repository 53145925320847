import React, { useMemo, useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../Assets/Images/filter.svg";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { useForm } from "../../_helpers/hooks";
import UserManagementFilterForm from "./UserManagementFilterForm";
import {
  userFilterForm,
  userListApis,
  addLink,
  customTabsData,
  userLabel,
  SidebarData,
} from "./DATA";
import EmptyDataList from "../../components/EmptyDataList/EmptyDataList";
import {
  capitalizeName,
  findUserPermissionedTabList,
} from "../../_helpers/helper";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import UserManagementView from "./UserManagementView";
import PermissionsFormEdit from "./PermissionsFormEdit";
import {
  ROLE_OBJECT_VIEW,
  isContractorModuleAdmin,
} from "../../_services/Auth/helper";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { UserService } from "../../_services/UserService/UserService";

const UserManagementList = () => {
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(userFilterForm);
  const [isListData, setIsListData] = useState(true);
  let newCustomTabsData = customTabsData;
  const [customTabList, setCustomTabList] = useState(
    findUserPermissionedTabList(newCustomTabsData)
  );

  let newAddLink = addLink;

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          className="secondaryBtn"
          aria-expanded={open}
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>

        <UserManagementFilterForm
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          form={form}
          isOpen={open}
          setOpen={setOpen}
        />
      </div>
    );
  };

  let newFilterBtn = FilterBtn;

  if (isContractorModuleAdmin()) {
    newCustomTabsData = customTabsData.filter((d, i) => {
      if (d.label === "User List") {
        return false;
      }
      return true;
    });
    newAddLink = "/contractor-management/user-list";
    newFilterBtn = null;
  }

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getAll = async () => {
    const response = await userListApis?.getAll.api(`page=1&limit=10`);
    if (Array.isArray(response?.data) && response?.data?.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const onEdit = (id) => {
    navigate(`${newAddLink}/${id}`);
  };
  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    setCustomTabList((prev) => {
      return prev.map((data) => {
        if (data.to === pathname) {
          return {
            ...data,
            active: 1,
          };
        }
        return data;
      });
    });
  }, [pathname]);

  const onResetFilter = () => {
    setGetAllFilter();
    setForm({ ...userFilterForm });
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    let filterData = "";
    for (const key in form) {
      const element = form[key];

      if (element) {
        filterData += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(filterData);
  }, [filterStatus]);

  const columnFields = useMemo(
    () => [
      {
        minWidth: "200px",
        name: userLabel?.name,
        selector: "firstName",
        sortable: true,
        cell: (row) => {
          return capitalizeName(
            `${row?.firstName ? row?.firstName : ""} ${
              row?.lastName ? row?.lastName : ""
            }`
          );
        },
      },
      {
        cell: (row) => {
          return row?.role?.title || "";
        },
        name: userLabel?.roleId,
        selector: "roleId",
        sortable: true,
      },
      {
        name: userLabel?.siteId,
        sortable: true,
        selector: "siteId",
        cell: (row) => {
          return row?.site?.name || "";
        },
      },
      {
        name: userLabel?.email,
        sortable: true,
        selector: "email",
      },
      {
        selector: "accessPermissions",
        sortable: false,
        cell: (row) => {
          let role = row.accessPermissions?.map(
            (obj) => ROLE_OBJECT_VIEW[obj?.permission]
          );
          let roleStr;
          roleStr = role?.join(",").substring(0, 20);
          if (roleStr.length > 19) {
            roleStr = roleStr + "...";
          }
          return roleStr;
        },
        name: "Permissions",
        minWidth: "200px",
      },
    ],
    []
  );

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const editAsPopup = (form, setShowAddEdit, getAll) => {
    console.log("444444444444444444", form);
    return (
      <PermissionsFormEdit
        formData={form}
        setShowAddEdit={setShowAddEdit}
        getAll={getAll}
      />
    );
  };

  const viewAsPopup = (form) => {
    return <UserManagementView form={form} />;
  };
  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={columnFields}
                apis={userListApis}
                title={"User"}
                viewTitle="User Details"
                editTitle="Edit Permissions"
                customTableTitle="User Management"
                FilterBtn={newFilterBtn}
                viewAsPopup={viewAsPopup}
                editAsPopup={editAsPopup}
                downloadExcel={UserService.getAllUsersUsersExport}
                hideSnColumn
                pageLink={newAddLink}
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                getAllFilter={getAllFilter}
                // childrenAfterAddButton={tabButtons}
                editFunButton={onEdit}
                enableMobileIcon
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <EmptyDataList
          // tabButtons={tabButtons}
          customTableTitle="User Management"
          pageLink={newAddLink}
        />
      </div>
    </div>
  );
};

export default UserManagementList;
