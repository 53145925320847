import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "reactstrap";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import toastr from "toastr";
import { Container } from "react-bootstrap";
import { tabUrl, UserApis, userFilterForm, userTabUrl } from "./DATA";
import { useLocation } from "react-router-dom";
import filter from "../../Assets/Images/filter.svg";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../_helpers/hooks";
import { getUserListWithPagination } from "../../services/_fuelDispense/fuelDispense";
import "./fuelmgmt.scss";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { findUserPermissionedTabList } from "../../_helpers/helper";
import UserView from "./UserView";
import SafetySideBar from "../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../_pages/FuelManagementSystem/FuelManagement/DATA";
import UserFilterForm from "./UserFilterForm";
import {
  Env_ROLE_OBJECT,
  ROLE_OBJECT,
  CONTRACTOR_ROLE_OBJECT,
} from "../../_services/Auth/helper";

const UserList = () => {
  const usrl = useLocation();
  const cref = useRef(null);
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(userTabUrl.fuelManagement)
  );
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(userFilterForm);

  const getAll = async () => {
    const response = await getUserListWithPagination();
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    console.log("custom" , customTabs);
    setCustomTabs((prev) => {
      return prev?.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    getAll();
  }, []);

  const onResetFilter = () => {
    setForm(userFilterForm);
    setGetAllFilter();
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabs} />;
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }
    setGetAllFilter(filter);
  }, [filterStatus, form]);

  const viewAsPopup = (form) => <UserView form={form} />;

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filter-button-user-list"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <UserFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      </div>
    );
  };

  const activeInactive = useCallback(async (e, id, gps_active, columnName) => {
    try {
      e.preventDefault();
      let url =
        "/user-management/set-gps-mark-unmark/" +
        id +
        "?column_name=" +
        columnName;
      const response = await RestMethod.PUT(url);
      if (response?.data?.status) {
        toastr.success(response?.data?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const usersColumnFields = [
    {
      name: "Name",
      selector: "firstName",
      sortable: true,
      cell: (row) => `${row?.firstName} ${row?.lastName}`,
      minWidth: "200px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      // cell: (row) => row?.site?.name,
    },
    {
      name: "Mine",
      selector: "site_id",
      sortable: true,
      cell: (row) => row?.site?.name,
    },
    {
      selector: "gps_active",
      cell: (row) => {
        return (
          (row.gps_active === 1 && (
            <span
              className="icons text-success pointer"
              href="#"
              onClick={(e) =>
                activeInactive(e, row.id, row.gps_active, "gps_active")
              }
            >
              <FaToggleOn
                size="20"
                data-testid={"toogle-user-list-on-" + row.id}
              />
            </span>
          )) ||
          (row.gps_active === 0 && (
            <span
              href="#"
              className="icons text-danger pointer"
              onClick={(e) =>
                activeInactive(e, row.id, row.gps_active, "gps_active")
              }
            >
              <FaToggleOff
                data-testid={"toogle-user-list-off-" + row.id}
                size="20"
              />
            </span>
          ))
        );
      },
      name: "GPS Status",
    },
    {
      selector: "only_barcode_scan",
      name: "Only Bar Code Scan",
      cell: (row) => {
        return (
          (row.only_barcode_scan === 1 && (
            <span
              href="#"
              className="icons text-success pointer"
              onClick={(e) =>
                activeInactive(
                  e,
                  row.id,
                  row.only_barcode_scan,
                  "only_barcode_scan"
                )
              }
            >
              <FaToggleOn
                data-testid={"toogle-user-list-on-" + row.id}
                size="20"
              />
            </span>
          )) ||
          (row.only_barcode_scan === 0 && (
            <span
              href="#"
              className="icons text-danger pointer"
              onClick={(e) =>
                activeInactive(
                  e,
                  row.id,
                  row.only_barcode_scan,
                  "only_barcode_scan"
                )
              }
            >
              <FaToggleOff
                size="20"
                data-testid={"toogle-user-list-off-" + row?.id}
              />
            </span>
          ))
        );
      },
    },
    {
      name: "Permissions",
      selector: "accessPermissions",
      sortable: false,
      cell: (row) => {
        let role = row?.accessPermissions?.map(
          (d) => ROLE_OBJECT?.fuelManagement[d.permission]
        );
        let roleStr = role.filter((val) => val !== undefined);
        // if (role && role.length > 0) {
        let roleStrNew = roleStr.join(",").substring(0, 20);
        if (roleStrNew.length > 19) {
          roleStrNew = roleStrNew + "...";
        }
        // }
        //  else {
        //   roleStr = "";
        // }
        return roleStrNew;
      },
      minWidth: "200px",
    },
  ];

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={usersColumnFields}
                apis={UserApis}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/user-list"}
                primaryKey="id"
                pagination
                hideDeleteButton
                hideEditButton
                getAllFilter={getAllFilter}
                hideAddButton
                childrenAfterAddButton={tabButtons}
                // hideThreeDotButton={true}
                customTableTitle={"Blank"}
                viewTitle={"User Details"}
                cref={cref}
                // isNoTabOnPage
                breadCrumb={[
                  {
                    name: "Fuel Management",
                    path: "/",
                  },
                  {
                    name: "User Management",
                    path: "#",
                  },
                ]}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            breadCrumb={[
              {
                name: "Fuel Management",
                path: "/",
              },
              {
                name: "User Management",
                path: "#",
              },
            ]}
            pageLink={"/user-list"}
            customTableTitle={"Blank"}
            tabButtons={tabButtons}
            hideAddBtn
          />
        </div>
      </div>
    </div>
  );
};

export default UserList;
