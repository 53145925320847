import { DDMMYYYYFormat, DDMMYYYYHHMMAFormat } from "../../../../_helpers/dateHelper";
import { capitalizeName } from "../../../../_helpers/helper";
import { apiSapData } from "../../../../services/_coalGrade/coalGrade";
import { modeOfDispatchLabelTable } from "../../DATA";
const formatAreas = (input) => {
  const areas = input.split("\n");

  const validAreas = areas.filter((area) => area.trim() !== "");

  const formattedAreas = validAreas.map((area) =>
    area
      .trim()
      .substring(7)
      .replace(/\bAREA\b/, "")
      .toLowerCase()
  );

  const output = formattedAreas.join(" ");

  return capitalizeName(output);
};

export const apiListColumnFields = [
  {
    name: "Subsidiary",
    selector: "subsidiary",
    // sortable: true,
    cell: (row) => row?.site?.parentSite?.name,
  },
  {
    name: "Mine",
    selector: "site",
    // sortable: true,
    cell: (row) => row?.site?.name,
  },
  {
    name: "Plant",
    selector: "plant",
    sortable: true,
    cell: (row) => row?.plant,
  },
  {
    name: "Mode",
    selector: "mode_of_dispatch",
    sortable: true,
    cell: (row) =>
      row?.mode_of_dispatch && modeOfDispatchLabelTable[row?.mode_of_dispatch],
  },
  {
    name: "Last Collection Date",
    selector: "collection_date",
    sortable: true,
    cell: (row) =>
      row?.maxCollectionDate && DDMMYYYYFormat(row?.maxCollectionDate),
  },
  {
    name: "Last Sync Time Stamp",
    selector: "createdAt",
    sortable: true,
    cell: (row) => row?.maxCreatedAt && DDMMYYYYHHMMAFormat(row?.maxCreatedAt),
  },
];
export const sapFilterForm = {
  startDate: "",
  endDate: "",
};

export const sapListApis = Object.freeze({
  getAll: {
    api: apiSapData,
  },
});
