import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Button } from "reactstrap";

import { Container } from "react-bootstrap";
import {
  fuelLocationApi,
  tabUrl,
  fuelLocationColumnFields,
  //   statusListObj,
  //   fuelManagementFilterForm,
} from "./DATA";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import FuelDispenseFilterForm from "./FuelDispenseFilterForm";
import FuelLocationView from "./FuelLocationView";
import toastr from "toastr";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../../../_helpers/hooks";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../FuelManagement/DATA";

import { exportFuelLocation, getAllFuelLocation } from "../../../../services/_fuelDispense/fuelDispense";
import "../../fuelmgmt.scss";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../../_services/CommonService/CommonService";
const FuelManagementList = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const cref = useRef(null);

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    let response;
    if (params?.id)
      response = await getAllFuelLocation(
        "page=1&limit=2&createdBy=" + params?.id
      );
    else response = await getAllFuelLocation("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const viewAsPopup = (form) => {
    return <FuelLocationView form={form} />;
  };

  const onEdit = (id) => {
    navigate("/fuel-management-system/configuration/fuel-location/" + id);
  };

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);

  const activeInactive = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: `fueling_locations`,
        id: id,
        status: status
      }
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const finalFuelLocationColumnFields = useMemo(
    () => [
      ...fuelLocationColumnFields,
      {
        name: "Status",
        selector: "status",
        cell: (row) => {
          return (
            (row.status === 1 && (
              <span
                href="#"
                className="icons text-success pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOn
                  size="20"
                  data-testid={"toogle-user-list-on-" + row.id}
                />
              </span>
            )) ||
            (row.status === 0 && (
              <span
                href="#"
                className="icons text-danger pointer"
                onClick={(e) =>
                  activeInactive(e, row.id, row.status, "status")
                }
              >
                <FaToggleOff
                  size="20"
                  data-testid={"toogle-user-list-off-" + row.id}
                />
              </span>
            ))
          );
        },
      },
    ],
    []
  );

  // useEffect(() => {
  //   // if (filterStatus === 0) return;

  //   let filter = "";
  //   if (params?.id) filter += `&createdBy=${params?.id}`;
  //   setGetAllFilter(filter);
  // }, []);

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={finalFuelLocationColumnFields}
                apis={fuelLocationApi}
                title="Data"
                // FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/fuel-management-system/configuration/fuel-location"}
                primaryKey="id"
                pagination
                hideDeleteButton
                cref={cref}
                hideEditButton
                // hideAddButton
                getAllFilter={getAllFilter}
                breadCrumb={[
                  {
                    path: "/",
                    name: "Fuel Management",
                  },
                  {
                    name: "Configuration",
                    path: "/fuel-management-system/configuration",
                  },
                  {
                    name: "Fuel Location",
                    path: "#",
                  },
                ]}
                customTableTitle={"Fuel Locations"}
                editFunButton={onEdit}
                viewTitle={"Fuel Location Details"}
                isNoTabOnPage
                downloadExcel={exportFuelLocation}
                // statusByOptions={statusByOptions}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            hideAddButton
            pageLink={"/fuel-management-system/configuration/fuel-location"}
            title={"Fuel Locations"}
          />
        </div>
      </div>
    </div>
  );
};

export default FuelManagementList;
