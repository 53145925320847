import React, { useEffect, useState, useRef } from "react";
import {
  FaAngleDown,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
} from "react-icons/fa";
import {
  getAllContractorByNit,
  getDashboardDataNit,
  getPerformanceDashboard,
  getNitList,
} from "../../../services/_contractorPerformance/contractorPerformance";
import { useForm } from "../../../_helpers/hooks";
import { getAllSites } from "../../../services/_uploadedFiles/uploadedFiles";
import { DDMMYYYYFormatBackslash } from "../../../_helpers/dateHelper";
import {
  Col,
  Container,
  Form,
  Row,
  Card,
  Modal,
  Button,
} from "react-bootstrap";
import DonutChart from "../../../components/Common/DonutChart";
import { contractorPerformanceScoreDashboardForm } from "./DATA";
import Select from "../../../components/Select/Select";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { useNavigate } from "react-router";
import NoRecord from "../../../components/Common/NoRecord";
import PleaseSelect from "../../../components/Common/PleaseSelect";
import FilesExport from "../../../components/Common/FilesExport";
import { SelectedDateLable } from "../../SafetyManagementSystem/IncidentManagement/Dashboard/DATA";
import { capitalizeName } from "../../../_helpers/helper";
import { DaysBetweenDates } from "../../../_helpers/helper";
import { isDecimal } from "../../../_helpers/helper";
import { IS_USER_SITE_MINES, IS_USER_SITE_RO } from "../../../_helpers/helper";
import { permissionCheck } from "../../../_services/Auth/helper";
import { getAllPoList } from "../../../services/_poList/poList";
import { useDispatch, useSelector } from "react-redux";
import { setContractorDashFilter } from "../../../redux/actions/UserActions";

const ContractorPerformanceDashboard = () => {
  const [form, onChange, setForm] = useForm({
    ...contractorPerformanceScoreDashboardForm,
  });
  const [allsiteListDrop, setAllSiteListDrop] = useState({});
  const [nitList, setNitList] = useState([]);
  const [poList, setPoList] = useState([]);
  const [allNitList, setallNitList] = useState([]);
  const [scroll, setScroll] = useState(0);
  const [nitData, setNitData] = useState();
  const [sites, setSites] = useState([]);
  const [allMineListDrop, setAllMineListDrop] = useState({});
  const [show, setShow] = useState(false);
  const [dashboardData, setdashboardData] = useState("");
  const [applyFilter, setApplyFilter] = useState(false);
  const [contractorList, setContractorList] = useState([]);
  const [minMaxDate, setMinMaxDate] = useState({});
  const [achievedTillDate, setAchievedTillDate] = useState({});
  const [dataExists, setDataExists] = useState({});
  const [suggestion, setSuggestion] = useState(true);
  const [storeFlag, setStoreFlag] = useState(true);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const storeFilter = useSelector(
    (state) => state?.dashboardFilter?.contractorDash
  );

  useEffect(() => {
    getSiteList();
    getNitForDashboard();
    getPoList();
  }, []);

  // To get mine vendor subsidiary for which data available
  const getNitForDashboard = async () => {
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    let res = await getDashboardDataNit(
      "contractor_performance",
      auth_user?.siteId
    );
    if (res.data != null) {
      setDataExists(res?.data?.data);
      setSuggestion(true);
      if (!Object.keys(storeFilter)?.length) {
        setForm((prevForm) => ({
          ...prevForm,
          vendor: res?.data?.data?.contractor_id,
          mine: res?.data?.data?.site_id || prevForm.mine,
          subsidiary:
            res?.data?.data?.site?.parentSite?.id || prevForm.subsidiary,
        }));
      }
    }
  };

  const getAllNitList = async () => {
    const allNitListData = await getNitList(form?.vendor);
    if (allNitListData?.status) {
      setallNitList(allNitListData?.data);
      setNitList(
        allNitListData?.data
          ?.filter(
            (entry) => entry?.site_id === form?.mine && entry?.status === 1
          )
          ?.map((d) => {
            return { value: d.id, label: d.nit };
          })
      );
    }
  };

  const getPoList = async () => {
    let res = await getAllPoList();
    let poOpt = res.data.map((d) => {
      return {
        value: d?.id,
        nit: d?.nit_id,
        label: d?.po,
        nitNumber: d?.nitNumber?.nit,
      };
    });
    setPoList(poOpt);
  };

  const getDashboardData = async () => {
    if (!form.vendor) return;
    let queryString = "";
    if (form.vendor) {
      queryString += `&contractor_id=${form.vendor}`;
    }
    if (form.tender) {
      queryString += `&tender=${form.tender}`;
    }
    if (form.mine) {
      queryString += `&mine=${form.mine}`;
    }
    if (form.startDate) {
      queryString += `&startDate=${form.startDate}`;
    }
    if (form.endDate) {
      queryString += `&endDate=${form.endDate}`;
    }
    if (form.po) {
      queryString += `&po=${form.po}`;
    }
    const finalDashboardData = await getPerformanceDashboard(queryString);
    if (finalDashboardData?.status) {
      setdashboardData(finalDashboardData?.data?.data);
      setMinMaxDate(finalDashboardData?.data?.data?.minMax_date || {});
      setAchievedTillDate(finalDashboardData?.data?.data?.achievedTillDate);
    }
  };

  useEffect(() => {
    if (!applyFilter) return;
    getDashboardData();
    setApplyFilter(false);
  }, [applyFilter]);

  useEffect(() => {
    getDashboardData();
  }, [form.mine, form.tender, form.vendor, form.subsidiary, form.po]);

  const getSiteList = async () => {
    let allsiteList = await getAllSites();
    if (allsiteList?.status === 1 && allsiteList?.message == "success") {
      setSites(allsiteList?.data);
      let dataObjSite = [];
      allsiteList?.data?.forEach((data) => {
        if (data?.parentSite?.id) {
          const labelExists = dataObjSite?.some(
            (obj) => obj?.label === data?.parentSite?.name
          );
          if (!labelExists) {
            dataObjSite.push({
              value: data?.parentSite?.id,
              label: data?.parentSite?.name,
            });
          }
        }
      });
      setAllSiteListDrop(dataObjSite);
    }
  };

  // auto select in case of single value
  useEffect(() => {
    let dataObjMine = [];
    if (sites.length > 0) {
      sites?.forEach((data) => {
        if (data.siteType === 3) {
          dataObjMine.push({
            label: data.name,
            value: data.id,
          });
        }
      });
    }
    setAllMineListDrop(dataObjMine);
    if (allsiteListDrop.length === 1 && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({
        ...prevForm,
        subsidiary: allsiteListDrop[0].value,
      }));
    }
  }, [allsiteListDrop]);
  useEffect(() => {
    if (allMineListDrop.length === 1 && !Object.keys(storeFilter)?.length) {
      setForm((prevForm) => ({
        ...prevForm,
        mine: allMineListDrop[0].value,
      }));
    }
  }, [allMineListDrop]);
  const ventorList = async () => {
    if (!form?.mine) return;
    let site = `site_id=${form?.mine}`;
    const allContractorList = await getAllContractorByNit(site);
    setContractorList(
      allContractorList?.map((d) => {
        return { value: d?.id, label: d?.contractor };
      })
    );
  };

  // changing site and mine
  useEffect(() => {
    if (!form?.subsidiary) {
      setAllMineListDrop([]);
    } else {
      let mines = sites?.filter(
        (obj) => obj?.parentSiteId === form?.subsidiary
      );
      let dataObjMine = [];
      if (mines.length > 0) {
        mines?.forEach((data) => {
          dataObjMine.push({
            value: data?.id,
            label: data?.name,
          });
        });
      }
      setAllMineListDrop(dataObjMine);
    }
  }, [form?.subsidiary, sites]);
  useEffect(() => {
    if (form?.mine) {
      ventorList();
      let mines = allNitList?.filter((obj) => obj.site_id === form?.mine);
      let dataObjMine = [];
      if (mines?.length > 0) {
        mines?.forEach((data) => {
          dataObjMine.push({
            value: data?.id,
            label: data?.tender_id,
          });
        });
      }
      setNitList(dataObjMine);
    } else setNitList([]);
    // form.tender = "";
  }, [form?.mine]);

  // on selecting vendor
  useEffect(() => {
    if (form.vendor) getAllNitList();
    // form.tender = "";
  }, [form.vendor]);

  useEffect(() => {
    setNitData(allNitList?.find((obj) => obj.id === form?.tender));
  }, [allNitList, form?.tender]);

  // console.log("setForm++++", form);
  // console.log("allNitList++++", allNitList);

  // donutAvailable
  let dozerObjectAvaialbele = "";
  let drillObjectAvaialbele = "";
  let dumperObjectAvaialbele = "";
  let shovelObjectAvaialbele = "";
  let waterTankObjectAvaialable = "";
  let GraderObjectAvaialable = "";
  let PayLoaderObjectAvaialable = "";
  let CrusherObjectAvaialable = "";

  let dozerObjectUtilized = "";
  let drillObjectUtilized = "";
  let dumperObjectUtilized = "";
  let shovelObjectUtilized = "";
  let waterTankObjectUtilized = "";
  let GraderObjectUtilized = "";
  let PayLoaderObjectUtilized = "";
  let CrusherObjectUtilized = "";

  if (
    Array.isArray(dashboardData?.donutAvailable) &&
    Array.isArray(dashboardData?.donutUtilized)
  ) {
    //available
    dozerObjectAvaialbele = dashboardData?.donutAvailable.find(
      (obj) => obj.equipment === "Dozer"
    );

    drillObjectAvaialbele = dashboardData?.donutAvailable.find(
      (obj) => obj.equipment === "Drill"
    );

    dumperObjectAvaialbele = dashboardData?.donutAvailable.find(
      (obj) => obj.equipment === "Tipper"
    );

    shovelObjectAvaialbele = dashboardData?.donutAvailable.find(
      (obj) => obj.equipment === "Excavator"
    );
    waterTankObjectAvaialable = dashboardData?.donutAvailable.find(
      (obj) => obj.equipment === "Water Tanker"
    );
    GraderObjectAvaialable = dashboardData?.donutAvailable.find(
      (obj) => obj.equipment === "Grader"
    );
    PayLoaderObjectAvaialable = dashboardData?.donutAvailable.find(
      (obj) => obj.equipment === "Pay Loader"
    );
    CrusherObjectAvaialable = dashboardData?.donutAvailable.find(
      (obj) => obj.equipment === "Crusher"
    );
    //utilized
    dozerObjectUtilized = dashboardData?.donutUtilized.find(
      (obj) => obj.equipment === "Dozer"
    );

    drillObjectUtilized = dashboardData?.donutUtilized.find(
      (obj) => obj.equipment === "Drill"
    );

    dumperObjectUtilized = dashboardData?.donutUtilized.find(
      (obj) => obj.equipment === "Tipper"
    );

    shovelObjectUtilized = dashboardData?.donutUtilized.find(
      (obj) => obj.equipment === "Excavator"
    );
    waterTankObjectUtilized = dashboardData?.donutUtilized.find(
      (obj) => obj.equipment === "Water Tanker"
    );
    GraderObjectUtilized = dashboardData?.donutUtilized.find(
      (obj) => obj.equipment === "Grader"
    );
    PayLoaderObjectUtilized = dashboardData?.donutUtilized.find(
      (obj) => obj.equipment === "Pay Loader"
    );
    CrusherObjectUtilized = dashboardData?.donutUtilized.find(
      (obj) => obj.equipment === "Crusher"
    );
  }

  //side filter controls function
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setApplyFilter(true);
    setShow(false);
  };
  const reset = () => {
    setForm((prevForm) => ({
      ...prevForm,
      startDate: "",
      endDate: "",
    }));
    // handleClose();
  };

  //slide control function
  const divRef = useRef(null);
  const scrollToLeftEnd = () => {
    if (divRef.current && scroll === 1) {
      divRef.current.scrollLeft = 0;
      setScroll(0);
    }
  };
  const scrollToRightEnd = () => {
    if (divRef.current && scroll === 0) {
      divRef.current.scrollLeft = divRef.current.scrollWidth;
      setScroll(1);
    }
  };

  useEffect(() => {
    if (!storeFlag) return;
    if (!Object.keys(storeFilter)?.length) {
      setStoreFlag(false);
      return;
    }
    setForm((prev) => ({ ...prev, ...storeFilter }));
    setStoreFlag(false);
  }, [setForm, storeFilter, storeFlag]);

  useEffect(() => {
    if (storeFlag) return;
    let formObj = {};
    Object.keys(form)?.forEach((d) => {
      if (d !== "startDate" && d !== "endDate") formObj[d] = form?.[d];
    });
    dispatch(setContractorDashFilter(formObj));
  }, [dispatch, form, storeFlag]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <div className="dashboardTabs coalGradeScreenTabs">
            <ul>
              {permissionCheck(["contractor_hindrance_dashboard"]) && (
                <li
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/hindrance`
                    )
                  }
                >
                  Contractor Hindrance
                </li>
              )}

              {permissionCheck(["performance_production_dashboard"]) && (
                <li
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/production-performance`
                    )
                  }
                >
                  Production Performance
                </li>
              )}

              {permissionCheck([
                "contractor_performance_score_card_dashboard",
              ]) && (
                <li
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/performance-score-card-vendor`
                    )
                  }
                  data-testid="nav-link-3"
                >
                  Contractor Score Card
                </li>
              )}

              {permissionCheck(["contractor_performance_dashboard"]) && (
                <li
                  className="active scoreCardDashboards"
                  data-testid="nav-link-4"
                  onClick={() =>
                    navigate(
                      `/contractor-management/contractor-dashboard/performance`
                    )
                  }
                >
                  Contractor Performance
                </li>
              )}
            </ul>
            <SelectedDateLable minMaxDate={minMaxDate} form={form} />
            <FilesExport id={`#${"ContractorPerformance"}`} fullScreen />
            <div
              onClick={handleShow}
              className="dashboardFilter"
              data-testid="filter"
            ></div>
          </div>
          <Container fluid>
            <div className="dashboardWrapper innerDashboards">
              <Row id="ContractorPerformance">
                <div className="dashboardHeader breadcrumbWithTitle d-none">
                  <MainBreadcrumb
                    routeSegments={[
                      {
                        name: "Contractor Management",
                        path: "#",
                      },
                      {
                        name: "Contractor Performance Dashboard",
                        path: "#",
                      },
                    ]}
                  />
                  <h3 className="title mb-0">
                    Contractor Performance Dashboard
                  </h3>
                </div>

                <div>
                  <Form>
                    <div className="topFilterSec">
                      <div className="colindiaCard borderRadius5">
                        <Row>
                          <Col lg={2}>
                            <Select
                              disabled={allsiteListDrop.length === 1}
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  vendor: "",
                                }));
                              }}
                              value={form?.subsidiary}
                              name="subsidiary"
                              label="Subsidiary"
                              options={allsiteListDrop}
                            />
                          </Col>
                          <Col lg={2}>
                            <Select
                              options={allMineListDrop}
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  tender: "",
                                }));
                              }}
                              value={form?.mine}
                              name="mine"
                              label={"Mine"}
                              disabled={
                                allMineListDrop.length === 1 ? true : false
                              }
                            />
                          </Col>
                          <Col lg={4}>
                            <Select
                              options={form?.mine ? contractorList : []}
                              onChange={(e) => {
                                onChange(e);
                                setForm((prevForm) => ({
                                  ...prevForm,
                                  tender: "",
                                }));
                              }}
                              value={form?.vendor}
                              name="vendor"
                              label={"Vendor"}
                            />
                          </Col>
                          <Col lg={2}>
                            <Select
                              options={form?.vendor ? nitList : []}
                              onChange={onChange}
                              value={form?.tender}
                              name="tender"
                              label={"NIT"}
                            />
                          </Col>
                          <Col lg={2}>
                            <Select
                              blankSelect="Select All"
                              onChange={onChange}
                              value={form?.po}
                              name="po"
                              options={
                                form?.tender
                                  ? poList.filter(
                                      (item) => item.nit === form?.tender
                                    )
                                  : []
                              }
                              label={"PO"}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {form?.tender &&
                      form?.subsidiary &&
                      form?.mine &&
                      form?.vendor && (
                        <Row className="pt-3">
                          <Col lg={12}>
                            <div className="colindiaCard contractorPerformanceTopTbl">
                              {/* <strong className="mb-0">Contractor Details</strong> */}
                              <Row>
                                {/* <Col md={4} className="d-flex">
                                  <strong>Contract /Patch</strong>
                                  <p>{nitData?.contractor_patch}</p>
                                </Col> */}
                                <Col md={4} className="d-flex">
                                  <strong>Contractor</strong>
                                  <p>{nitData?.contractor?.contractor}</p>
                                </Col>
                                <Col md={2} className="d-flex">
                                  <strong>QPD Plan</strong>
                                  <p>
                                    {nitData?.qpd_proposed &&
                                      isDecimal(nitData?.qpd_proposed / 30)}
                                    {+achievedTillDate?.type === 1
                                      ? " Tonne"
                                      : " CuM"}
                                  </p>
                                </Col>
                                <Col md={2} className="d-flex">
                                  <strong>Start Date</strong>
                                  <p>
                                    {DDMMYYYYFormatBackslash(
                                      nitData?.startDate
                                    )}
                                  </p>
                                </Col>
                                <Col md={2} className="d-flex">
                                  <strong>End Date</strong>
                                  <p>
                                    {DDMMYYYYFormatBackslash(nitData?.endDate)}
                                  </p>
                                </Col>
                                <Col md={2} className="d-flex">
                                  <strong>Achieved till Date</strong>
                                  <p>
                                    {(achievedTillDate?.achievedTillDate &&
                                      isDecimal(
                                        achievedTillDate?.achievedTillDate
                                      )) ||
                                      0}
                                    {+achievedTillDate?.type === 1
                                      ? " Tonne"
                                      : " CuM"}
                                  </p>
                                </Col>
                                <Col md={2} className="d-flex">
                                  <strong>NIT</strong>
                                  <p>{nitData?.nit}</p>
                                </Col>

                                <Col md={2} className="d-flex">
                                  <strong>Tenure</strong>
                                  <p>
                                    {DaysBetweenDates(
                                      nitData?.startDate,
                                      nitData?.endDate
                                    )}{" "}
                                    days
                                  </p>
                                </Col>

                                {nitData?.sectionData?.name && (
                                  <Col md={2} className="d-flex">
                                    <strong>Section / Patch</strong>
                                    <p>
                                      {nitData?.sectionData?.name
                                        ? capitalizeName(
                                            nitData?.sectionData?.name
                                          )
                                        : "Not Applicable"}
                                    </p>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      )}
                    {form?.vendor && form?.subsidiary && form?.mine && (
                      <Row className="pt-3">
                        <Col md={6}>
                          <Card className="chartCard mb-3">
                            <Card.Header>Equipment Available (Avg)</Card.Header>
                            <Card.Body className="p-0">
                              <div className="webformCardContent table-responsive avgTblOuter">
                                <table className="avgTblData table table-striped table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>NIT Specs</th>
                                      <th>1st Shift</th>
                                      <th>2nd Shift</th>
                                      <th>Night Shift</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Array.isArray(dashboardData?.available) &&
                                    dashboardData?.available.length > 0 ? (
                                      dashboardData.available
                                        .filter(
                                          (d) => d.avg_committed_count !== null
                                        )
                                        .map((d, i) => (
                                          <tr key={i}>
                                            <th>
                                              <label>{d?.equipment_name}</label>
                                            </th>
                                            <td>
                                              {d?.avg_committed_count &&
                                                isDecimal(
                                                  +d?.avg_committed_count
                                                )}
                                            </td>
                                            <td className="percentageValue">
                                              <strong>
                                                {d?.avg_shift1_available}
                                              </strong>
                                              <span>
                                                {(
                                                  (d?.avg_shift1_available /
                                                    d?.avg_committed_count) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </span>
                                            </td>
                                            <td className="percentageValue">
                                              <strong>
                                                {d?.avg_shift2_available}
                                              </strong>
                                              <span>
                                                {(
                                                  (d?.avg_shift2_available /
                                                    d?.avg_committed_count) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </span>
                                            </td>
                                            <td className="percentageValue">
                                              <strong>
                                                {d?.avg_shift3_available}
                                              </strong>
                                              <span>
                                                {(
                                                  (d?.avg_shift3_available /
                                                    d?.avg_committed_count) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </span>
                                            </td>
                                          </tr>
                                        ))
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </table>
                                {!dashboardData?.available?.length > 0 && (
                                  <span className="text-center">
                                    <NoRecord />
                                  </span>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card className="chartCard mb-3">
                            <Card.Header>Equipment Working (Avg)</Card.Header>
                            <Card.Body className="p-0">
                              <div className="webformCardContent table-responsive avgTblOuter">
                                <table className="avgTblData table table-striped table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>NIT Specs</th>
                                      <th>1st Shift</th>
                                      <th>2nd Shift</th>
                                      <th>Night Shift</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dashboardData?.utilized &&
                                      dashboardData?.utilized
                                        .filter(
                                          (d) => d.avg_committed_count !== null
                                        )
                                        .map((d, i) => (
                                          <tr key={i}>
                                            <th>
                                              <label>{d?.equipment_name}</label>
                                            </th>
                                            <td>
                                              {d?.avg_committed_count &&
                                                isDecimal(
                                                  +d?.avg_committed_count
                                                )}
                                            </td>
                                            <td className="percentageValue">
                                              <strong>
                                                {d?.avg_shift1_available}
                                              </strong>
                                              <span>
                                                {(
                                                  (d?.avg_shift1_available /
                                                    d?.avg_committed_count) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </span>
                                            </td>
                                            <td className="percentageValue">
                                              <strong>
                                                {d?.avg_shift2_available}
                                              </strong>
                                              <span>
                                                {" "}
                                                {(
                                                  (d?.avg_shift2_available /
                                                    d?.avg_committed_count) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </span>
                                            </td>
                                            <td className="percentageValue">
                                              <strong>
                                                {d?.avg_shift3_available}
                                              </strong>
                                              <span>
                                                {" "}
                                                {(
                                                  (d?.avg_shift3_available /
                                                    d?.avg_committed_count) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </span>
                                            </td>
                                          </tr>
                                        ))}
                                  </tbody>
                                </table>
                                {!dashboardData?.utilized?.length > 0 && (
                                  <span className="text-center">
                                    <NoRecord />
                                  </span>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </div>
                {form?.vendor && form?.subsidiary && form?.mine && (
                  <>
                    {dashboardData?.donutAvailable?.length > 4 && (
                      <div className="d-flex justify-content-between align-items-center">
                        <FaAngleDoubleLeft
                          onClick={scrollToLeftEnd}
                          style={{ color: scroll === 0 ? "grey" : "black" }}
                          data-testid={"scrollToLeftEndId"}
                        />
                        <FaAngleDoubleRight
                          onClick={scrollToRightEnd}
                          style={{ color: scroll === 1 ? "grey" : "black" }}
                          data-testid={"scrollToRightEndId"}
                        />
                      </div>
                    )}

                    <div className="scrollDiv" ref={divRef}>
                      {(shovelObjectAvaialbele?.dataAvailable?.[0]?.value ||
                        shovelObjectUtilized?.dataUtilized?.[0]?.value) && (
                        <div className="cardsDonut">
                          <Card className="chartCard chartCardBtmSpace ">
                            <Card.Header>
                              {shovelObjectAvaialbele?.equipment}
                            </Card.Header>
                            <Card.Body className="d-flex chartPullTop">
                              {Array.isArray(
                                shovelObjectAvaialbele?.dataAvailable
                              ) &&
                              shovelObjectAvaialbele?.dataAvailable.length >
                                0 ? (
                                <>
                                  <DonutChart
                                    customName={
                                      shovelObjectAvaialbele?.typeAvailable
                                    }
                                    dataArray={
                                      shovelObjectAvaialbele?.dataAvailable
                                    }
                                  />
                                  <DonutChart
                                    customName={
                                      shovelObjectUtilized?.typeAvailable
                                    }
                                    dataArray={
                                      shovelObjectUtilized?.dataUtilized
                                    }
                                  />
                                </>
                              ) : (
                                <span className="text-center">
                                  <NoRecord />
                                </span>
                              )}
                            </Card.Body>
                          </Card>
                        </div>
                      )}

                      {(dumperObjectUtilized?.dataUtilized?.[0]?.value ||
                        dumperObjectAvaialbele?.dataAvailable?.[0]?.value) && (
                        <div className="cardsDonut">
                          <Card className="chartCard chartCardBtmSpace ">
                            <Card.Header>
                              {dumperObjectAvaialbele?.equipment}
                            </Card.Header>
                            <Card.Body className="d-flex chartPullTop">
                              {Array.isArray(
                                dumperObjectAvaialbele?.dataAvailable
                              ) &&
                              dumperObjectAvaialbele?.dataAvailable.length >
                                0 ? (
                                <>
                                  <DonutChart
                                    customName={
                                      dumperObjectAvaialbele?.typeAvailable
                                    }
                                    dataArray={
                                      dumperObjectAvaialbele?.dataAvailable
                                    }
                                  />
                                  <DonutChart
                                    customName={
                                      dumperObjectUtilized?.typeAvailable
                                    }
                                    dataArray={
                                      dumperObjectUtilized?.dataUtilized
                                    }
                                  />
                                </>
                              ) : (
                                <span className="text-center">
                                  <NoRecord />
                                </span>
                              )}
                            </Card.Body>
                          </Card>
                        </div>
                      )}

                      {(drillObjectAvaialbele?.dataAvailable?.[0]?.value ||
                        drillObjectUtilized?.dataUtilized?.[0]?.value) && (
                        <div className="cardsDonut">
                          <Card className="chartCard chartCardBtmSpace ">
                            <Card.Header>
                              {drillObjectAvaialbele?.equipment}
                            </Card.Header>
                            <Card.Body className="d-flex chartPullTop">
                              {Array.isArray(
                                drillObjectAvaialbele?.dataAvailable
                              ) &&
                              drillObjectAvaialbele?.dataAvailable.length >
                                0 ? (
                                <>
                                  <DonutChart
                                    customName={
                                      drillObjectAvaialbele?.typeAvailable
                                    }
                                    dataArray={
                                      drillObjectAvaialbele?.dataAvailable
                                    }
                                  />
                                  <DonutChart
                                    customName={
                                      drillObjectUtilized?.typeAvailable
                                    }
                                    dataArray={
                                      drillObjectUtilized?.dataUtilized
                                    }
                                  />
                                </>
                              ) : (
                                <span className="text-center">
                                  <NoRecord />
                                </span>
                              )}
                            </Card.Body>
                          </Card>
                        </div>
                      )}

                      {(dozerObjectAvaialbele?.dataAvailable?.[0]?.value ||
                        dozerObjectUtilized?.dataUtilized?.[0]?.value) && (
                        <div className="cardsDonut">
                          <Card className="chartCard chartCardBtmSpace ">
                            <Card.Header>
                              {dozerObjectAvaialbele?.equipment}
                            </Card.Header>
                            <Card.Body className="d-flex chartPullTop">
                              {Array.isArray(
                                dozerObjectUtilized?.dataUtilized
                              ) &&
                              dozerObjectUtilized?.dataUtilized.length > 0 ? (
                                <>
                                  <DonutChart
                                    customName={
                                      dozerObjectAvaialbele?.typeAvailable
                                    }
                                    dataArray={
                                      dozerObjectAvaialbele?.dataAvailable
                                    }
                                  />
                                  <DonutChart
                                    customName={
                                      dozerObjectUtilized?.typeAvailable
                                    }
                                    dataArray={
                                      dozerObjectUtilized?.dataUtilized
                                    }
                                  />
                                </>
                              ) : (
                                <span className="text-center">
                                  <NoRecord />
                                </span>
                              )}
                            </Card.Body>
                          </Card>
                        </div>
                      )}

                      {(waterTankObjectUtilized?.dataUtilized?.[0]?.value ||
                        waterTankObjectAvaialable?.dataAvailable?.[0]
                          ?.value) && (
                        <div className="cardsDonut">
                          <Card className="chartCard chartCardBtmSpace ">
                            <Card.Header>
                              {waterTankObjectAvaialable?.equipment}
                            </Card.Header>
                            <Card.Body className="d-flex chartPullTop">
                              {Array.isArray(
                                waterTankObjectUtilized?.dataUtilized
                              ) &&
                              waterTankObjectUtilized?.dataUtilized.length >
                                0 ? (
                                <>
                                  <DonutChart
                                    customName={
                                      waterTankObjectAvaialable?.typeAvailable
                                    }
                                    dataArray={
                                      waterTankObjectAvaialable?.dataAvailable
                                    }
                                  />
                                  <DonutChart
                                    customName={
                                      waterTankObjectUtilized?.typeAvailable
                                    }
                                    dataArray={
                                      waterTankObjectUtilized?.dataUtilized
                                    }
                                  />
                                </>
                              ) : (
                                <span className="text-center">
                                  <NoRecord />
                                </span>
                              )}
                            </Card.Body>
                          </Card>
                        </div>
                      )}

                      {(GraderObjectUtilized?.dataUtilized?.[0]?.value ||
                        GraderObjectAvaialable?.dataAvailable?.[0]?.value) && (
                        <div className="cardsDonut">
                          <Card className="chartCard chartCardBtmSpace ">
                            <Card.Header>
                              {GraderObjectAvaialable?.equipment}
                            </Card.Header>
                            <Card.Body className="d-flex chartPullTop">
                              {Array.isArray(
                                GraderObjectUtilized?.dataUtilized
                              ) &&
                              GraderObjectUtilized?.dataUtilized.length > 0 ? (
                                <>
                                  <DonutChart
                                    customName={
                                      GraderObjectAvaialable?.typeAvailable
                                    }
                                    dataArray={
                                      GraderObjectAvaialable?.dataAvailable
                                    }
                                  />
                                  <DonutChart
                                    customName={
                                      GraderObjectUtilized?.typeAvailable
                                    }
                                    dataArray={
                                      GraderObjectUtilized?.dataUtilized
                                    }
                                  />
                                </>
                              ) : (
                                <span className="text-center">
                                  <NoRecord />
                                </span>
                              )}
                            </Card.Body>
                          </Card>
                        </div>
                      )}

                      {(PayLoaderObjectAvaialable?.dataAvailable?.[0]?.value ||
                        PayLoaderObjectUtilized?.dataUtilized?.[0]?.value) && (
                        <div className="cardsDonut">
                          <Card className="chartCard chartCardBtmSpace ">
                            <Card.Header>
                              {PayLoaderObjectAvaialable?.equipment}
                            </Card.Header>
                            <Card.Body className="d-flex chartPullTop">
                              {Array.isArray(
                                PayLoaderObjectUtilized?.dataUtilized
                              ) &&
                              PayLoaderObjectUtilized?.dataUtilized.length >
                                0 ? (
                                <>
                                  <DonutChart
                                    customName={
                                      PayLoaderObjectAvaialable?.typeAvailable
                                    }
                                    dataArray={
                                      PayLoaderObjectAvaialable?.dataAvailable
                                    }
                                  />
                                  <DonutChart
                                    customName={
                                      PayLoaderObjectUtilized?.typeAvailable
                                    }
                                    dataArray={
                                      PayLoaderObjectUtilized?.dataUtilized
                                    }
                                  />
                                </>
                              ) : (
                                <span className="text-center">
                                  <NoRecord />
                                </span>
                              )}
                            </Card.Body>
                          </Card>
                        </div>
                      )}

                      {(CrusherObjectAvaialable?.dataAvailable?.[0]?.value ||
                        CrusherObjectUtilized?.dataUtilized?.[0]?.value) && (
                        <div className="cardsDonut">
                          <Card className="chartCard chartCardBtmSpace ">
                            <Card.Header>
                              {CrusherObjectAvaialable?.equipment}
                            </Card.Header>
                            <Card.Body className="d-flex chartPullTop">
                              {Array.isArray(
                                CrusherObjectUtilized?.dataUtilized
                              ) &&
                              CrusherObjectUtilized?.dataUtilized.length > 0 ? (
                                <>
                                  <DonutChart
                                    customName={
                                      CrusherObjectAvaialable?.typeAvailable
                                    }
                                    dataArray={
                                      CrusherObjectAvaialable?.dataAvailable
                                    }
                                  />
                                  <DonutChart
                                    customName={
                                      CrusherObjectUtilized?.typeAvailable
                                    }
                                    dataArray={
                                      CrusherObjectUtilized?.dataUtilized
                                    }
                                  />
                                </>
                              ) : (
                                <span className="text-center">
                                  <NoRecord />
                                </span>
                              )}
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {!form?.vendor || !form?.subsidiary || !form?.mine ? (
                  <div className="text-center">
                    <PleaseSelect type={"Vendor"} />
                  </div>
                ) : (
                  <></>
                )}
              </Row>
            </div>
          </Container>
        </div>
      </div>
      {/* Filter Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="filterModal"
      >
        <Modal.Header closeButton className="px-3">
          <h5 className="modal-title">Filter</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <Row>
              <label className="pb-2">Date Duration</label>
              <Col lg={12}>
                <CustomDatePicker
                  value={form?.startDate}
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  onChangeDate={onChange}
                  placeholder="Start Date"
                />
              </Col>
              <Col lg={12}>
                <CustomDatePicker
                  value={form?.endDate}
                  dateFormat="YYYY-MM-DD"
                  name="endDate"
                  onChangeDate={onChange}
                  //className={"cndrPosition"}
                  placeholder="End Date"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="filterBtn">
            <Button
              className="secondaryBtn"
              style={{ minWidth: "auto" }}
              data-testid="reset"
              onClick={() => reset()}
            >
              Reset
            </Button>
            <Button
              className="primaryBtn"
              style={{ minWidth: "auto" }}
              data-testid="close-message-popup"
              onClick={() => handleClose()}
            >
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContractorPerformanceDashboard;
