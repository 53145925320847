import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { useForm } from "../../../_helpers/hooks";
import {
  contractorPerformanceApis,
  contractorPerformanceColumnFields,
  contractorPerformanceFilterForm,
  performanceStatus,
} from "./DATA";
import ContractorPerformanceView from "./ContractorPerformanceView";
import ContractorPerformanceFilterForm from "./ContractorPerformanceFilterForm";
import { useLocation, useNavigate } from "react-router-dom";
// import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import {
  exportContractorPerformance,
  getAllContractorList,
  getContractorPerformancesListWithPagination,
} from "../../../services/_contractorPerformance/contractorPerformance";
import EmptyDataList from "./EmptyDataList";
import "./contractorPerformance.scss";
import filter from "../../../Assets/Images/filter.svg";
import { tabUrl } from "../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import { permissionCheck } from "../../../_services/Auth/helper";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
// import ContractorTenderDetails from "../CommonComponent/ContractorTenderDetails";

const ContractorPerformanceList = () => {
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [contractorList, setContractorList] = useState([]);
  // const [customTabs, setCustomTabs] = useState(
  //   findUserPermissionedTabList(tabUrl.contractorPerformance)
  // );
  let navigate = useNavigate();
  const getAll = async () => {
    const response = await getContractorPerformancesListWithPagination(
      "page=1&limit=2"
    );
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
    const allContractorList = await getAllContractorList();
    // console.log(allContractorList, "cont list");
    setContractorList(
      allContractorList?.map((d) => {
        return { value: d.id, label: d.contractor };
      })
    );
  };

  const onSubmitFilter = () => setFilterStatus((prev) => prev + 1);

  // const onEdit = (id) => {
  //   navigate("/contractor-management/contractor-performances/edit/" + id);
  // };

  useEffect(() => {
    getAll();
  }, []);
  // useEffect(() => {
  //   setCustomTabs((prev) => {
  //     return prev.map((d) => {
  //       console.log("d", d, usrl.pathname);
  //       if (d.to === usrl.pathname) {
  //         return {
  //           ...d,
  //           active: 1,
  //         };
  //       }
  //       return d;
  //     });
  //   });
  //   navigate(customTabs[0]?.to);
  //   getAll();
  // }, []);
  const [form, onChange, setForm] = useForm(contractorPerformanceFilterForm);
  const [filterStatus, setFilterStatus] = useState(0);

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }

    setGetAllFilter(filter);
  }, [filterStatus]);

  const viewAsPopup = (form) => {
    return <ContractorPerformanceView form={form} />;
  };

  const onResetFilter = () => {
    setForm(contractorPerformanceFilterForm);
    setGetAllFilter();
  };
  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <ContractorPerformanceFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          contractorList={contractorList}
        />
      </div>
    );
  };

 

  const statusByOptions = (row) => {
    return !!(row?.status === "draft");
  };

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            {/* <h4>Contractor Performance</h4> */}
            {/* <CustomTabs tabeList={customTabs} /> */}
            <div className="customDataTbl">
              <DataTableWithServer
                hideDeleteButton
                fields={contractorPerformanceColumnFields}
                apis={contractorPerformanceApis}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/contractor-management/contractor-performances"}
                primaryKey="id"
                pagination
                hideEditButton
                hideEditIcon
                getAllFilter={getAllFilter}
                // childrenAfterAddButton={tabButtons}
                downloadExcel={exportContractorPerformance}
                // hideAddButton={!permissionCheck(["section_incharge"])}
                hideAddButton
                // editContractorPerformanceFun={onEdit}
                listname={"performance"}
                customTableTitle={"Contractor Performance"}
                viewTitle={"Contractor Performance Details"}
                isNoTabOnPage
                icons
                // hideEditIcon
                updatePageLink={
                  "/contractor-management/contractor-performances/edit"
                }
                breadCrumb={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Contractor Performance",
                    path: "/contractor-management/contractor-performance",
                  },
                  {
                    name: "View Contractor Performance",
                    path: "#",
                  },
                ]}
                statusByOptions={statusByOptions}
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <h3
            style={{ paddingBottom: "1px", paddingTop: "5px" }}
            className="title"
          >
            <MainBreadcrumb
              routeSegments={[
                {
                  name: "Contractor Management",
                  path: "#",
                },
                {
                  name: "Contractor Performance",
                  path: "/contractor-management/contractor-performance",
                },
                {
                  name: "View Contractor Performance",
                  path: "#",
                },
              ]}
              // module={ACTIVE_MODULE.incident}
            />
          </h3>
          <EmptyDataList
            // tabButtons={tabButtons}
            pageLink={"/contractor-management/contractor-performances"}
            hideAddBtn={!permissionCheck(["section_incharge"])}
            title={"Contractor Performance"}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractorPerformanceList;
