import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { useForm } from "../../../_helpers/hooks";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  contractorScoreApi,
  contractorScoreColumnFields,
  contractorScoreColumnFieldsMy,
  exportContractorScoreCard,
  exportMyContractorScoreCard,
  myContractorScoreApi,
  statusListObj,
  EditLink,
  filterForm,
} from "./DATA";
import ContractorScoreView from "./ContractorScoreView";
import ContractorScoreFilterForm from "./ContractorScoreFilter";
import { getAllContractorByNit } from "../../../services/_contractorPerformance/contractorPerformance";
import {
  getContractorScoreListWithPagination,
  getMyContractorScoreListWithPagination,
} from "../../../services/_contractorScore/contractorScore";
import { SidebarData } from "../../../_pages/ContractorManagement/DATA";
import SafetySideBar from "../../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import "./contractorScore.scss";
import filter from "../../../Assets/Images/filter.svg";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";




const   ContractorscoreList = ({ params }) => {
  // const params = useParams();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [contractorList, setContractorList] = useState([]);
  let navigate = useNavigate();
  const getAll = async () => {
    let response;
    if (params) {
      response = await getMyContractorScoreListWithPagination("page=1&limit=2");
    } else {
      response = await getContractorScoreListWithPagination("page=1&limit=2");
    }
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
    const allContractorList = await getAllContractorByNit();
    setContractorList(
      allContractorList.map((d) => {
        return { value: d.id, label: d.contractor };
      })
    );
  };

  // const onEdit = (id) => {
  //   navigate("/contractor-management/contractor-score/edit/" + id);
  // };

  useEffect(() => {
    getAll();
  }, []);

  const [form, onChange, setForm] = useForm(filterForm);
  const [filterStatus, setFilterStatus] = useState(0);

  const onSubmitFilter = () => setFilterStatus((prev) => prev + 1);

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }
    setGetAllFilter(filter);
  }, [filterStatus]);

  const onResetFilter = () => {
    setForm(filterForm);
    setGetAllFilter();
  };

  const viewAsPopup = (form) => {
    return <ContractorScoreView form={form} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          data-testid="filterBtn"
          className="secondaryBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <ContractorScoreFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          contractorList={contractorList}
          id={params}
        />
      </div>
    );
  };

  const statusByOptions = (row) => {
    return !!(row?.status === statusListObj?.draft);
  };
  const editPermission = "safety_admin";
  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={
                  params
                    ? contractorScoreColumnFieldsMy
                    : contractorScoreColumnFields
                }
                apis={params ? myContractorScoreApi : contractorScoreApi}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/contractor-management/contractor-score"}
                primaryKey="id"
                pagination
                hideAddButton
                hideDeleteButton
                // hideEditButton
                getAllFilter={getAllFilter}
                downloadExcel={
                  params
                    ? exportMyContractorScoreCard
                    : exportContractorScoreCard
                }
                breadCrumb={[
                  {
                    name: "Contractor Management",
                    path: "/",
                  },
                  {
                    name: "Contractor score card",
                    path: "/contractor-management/contractorscore-management",
                  },
                  {
                    name: params
                      ? "My Contractor Score Card"
                      : "View Contractor Score Card",
                    path: "#",
                  },
                ]}
                listname={"score"}
                // editFunButton={params && onEdit}
                customTableTitle={
                  params
                    ? "My Contractor score cards"
                    : "Contractor score cards"
                }
                viewTitle={"Contractor Score Details"}
                isNoTabOnPage
                modalSize={"xl"}
                statusByOptions={statusByOptions}
                permissionEditButton={params && editPermission}
                icons
                updatePageLink={EditLink}
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            pageLink={"/contractor-management/contractor-score"}
            title={params ? "My Contractor score" : "Contractor score"}
            hideAddBtn
            breadCrumb={[
              {
                name: "Contractor Management",
                path: "/",
              },
              {
                name: "Contractor Score",
                path: "/contractor-management/contractorscore-management",
              },
              {
                name: params
                  ? "My Contractor Score Card"
                  : "View Contractor Score Card",
                path: "#",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractorscoreList;
