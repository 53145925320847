import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { coalGradeLabel } from "../DATA";
import CreatedByCreatedAt from "../../../components/Common/CreatedByCreatedAt";

const SampleDecodingView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">Sample De-Coding Related Data</p>
            <Row>
              <Col sm={6}>
                <label>{coalGradeLabel?.date_given_for_test}</label>
                <p className="content">
                  {form?.date_given_for_test
                    ? DDMMYYYYFormat(form?.date_given_for_test)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.date_of_test_result_recieved}</label>
                <p className="content">
                  {form?.date_of_test_result_recieved
                    ? DDMMYYYYFormat(form?.date_of_test_result_recieved)
                    : ""}
                </p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.code_given_to_sample}</label>
                <p className="content">{form?.code_given_to_sample}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.decoded_by}</label>
                <p className="content">{form?.decoded_by}</p>
              </Col>
            </Row>
          </div>
          <div className="previewContent">
            <p className="head">Lab Quality Results Data</p>
            <h6>On Air Dry Basis</h6>
            <Row>
              <Col sm={6}>
                <label>
                  {coalGradeLabel?.lab_on_air_dry_bases_moisture + ` %`}
                </label>
                <p className="content">{form?.lab_on_air_dry_bases_moisture}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.lab_on_air_dry_bases_ash + ` %`}</label>
                <p className="content">{form?.lab_on_air_dry_bases_ash}</p>
              </Col>
              <Col sm={6}>
                <label>{coalGradeLabel?.lab_on_air_dry_bases_vm + ` %`}</label>
                <p className="content">{form?.lab_on_air_dry_bases_vm}</p>
              </Col>
              <Col sm={6}>
                <label>
                  {coalGradeLabel?.lab_on_air_dry_bases_gcv + ` (kCal/kg)`}
                </label>
                <p className="content">{form?.lab_on_air_dry_bases_gcv}</p>
              </Col>
              <div className="seperator"></div>
              <h6>On EM Basis ​</h6>
              <Row>
                <Col sm={6}>
                  <label>
                    {coalGradeLabel?.lab_on_em_bases_moisture + ` %`}
                  </label>
                  <p className="content">{form?.lab_on_em_bases_moisture}</p>
                </Col>
                <Col sm={6}>
                  <label>{`${coalGradeLabel?.lab_on_em_bases_ash} %`}​</label>
                  <p className="content">{form?.lab_on_em_bases_ash}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.lab_on_em_bases_vm + ` %`}​</label>
                  <p className="content">{form?.lab_on_em_bases_vm}</p>
                </Col>
                <Col sm={6}>
                  <label>
                    {`${coalGradeLabel?.lab_on_em_bases_gcv} (kCal/kg)`}
                  </label>
                  <p className="content">{`${form?.lab_on_em_bases_gcv}`}</p>
                </Col>
              </Row>
              <div className="seperator"></div>
              <Row>
                <Col sm={6}>
                  <label>{coalGradeLabel?.lab_analyzed_grade}</label>
                  <p className="content">{form?.lab_analyzed_grade}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.lab_result_checked_by}</label>
                  <p className="content">{`${form?.lab_result_checked_by}`}</p>
                </Col>

                <Col sm={6}>
                  <label>{coalGradeLabel?.lab_result_tested_by}​</label>
                  <p className="content">{form?.lab_result_tested_by}</p>
                </Col>
                <Col sm={6}>
                  <label>{coalGradeLabel?.result_reporting_to_customer}​</label>
                  <p className="content">
                    {!form?.result_reporting_to_customer
                      ? ""
                      : DDMMYYYYFormat(form?.result_reporting_to_customer)}
                  </p>
                </Col>
              </Row>
            </Row>
          </div>
          <CreatedByCreatedAt id={form?.id} table={"coal_grade_quality_data"} />
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="border-top-0">
        <Button className="secondaryBtn">Download PDF</Button>
        <Button className="secondaryBtn">Download Excel</Button>
      </Modal.Footer> */}
    </>
  );
};

export default SampleDecodingView;
