import React, { useRef, useState } from "react";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import EvidencePreview from "../../../../_pages/SafetyManagementSystem/IncidentManagement/components/EvidencePreview";
import ReportButton from "../../../SafetyManagementSystem/IncidentManagement/components/ReportButton/ReportButton";
import { CommmonService } from "../../../../_services/CommonService/CommonService";
import toastr from "toastr";
import moment from "moment";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import InputError from "../../../../components/_form/InputError/InputError";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { AiOutlineMinusCircle } from "react-icons/ai";
import "../FuelMgmt.scss";
import { IS_MOBILE } from "../../../../_helpers/helper";
import { Col, Form, Row } from "react-bootstrap";
import AddDispancedDetails from "./AddDispancedDetails";
import { uploadFileOnS3 } from "../../../../services/S3Service";
import "../../../../components/_form/CheckBoxField/checkbox.scss";
import { Label } from "reactstrap";
import FormTextArea from "../../../../components/FormTextArea/FormTextAres";
const AddFuelDispenced = ({
  id,
  form = {},
  error = {},
  onChange = () => {},
  onSubmit = () => {},
  condition,
  setTab,
  tab,
  onDraft = () => {},
  loaderValue,
  maxCapacity,
  negativeMssg,
}) => {
  const [loading, setLoading] = useState(false);
  const [fileDataOdometer, setFileDataOdometer] = useState("");
  const [fileDataHmrmeter, setFileDataHmrmeter] = useState("");
  const [fileDataFuelMtr, setFileDataFuelMtr] = useState("");

  const lastReadingLabel = form?.dispenseData && form?.dispenseData?.isExist;
  const lastReadingField =
    (!form?.dispenseData && true) ||
    (form?.dispenseData?.status === "Draft" && !form?.dispenseData?.isExist);

  useState(() => {
    if (condition === "before_") {
      setFileDataOdometer(form.dispancer_pre_image_data);
      setFileDataHmrmeter(form.hemm_pre_image_data);
      setFileDataFuelMtr(form?.fuel_meter_img);
    } else {
      setFileDataOdometer(form.dispancer_post_image_data);
      setFileDataHmrmeter(form.hemm_post_image_data);
      setFileDataFuelMtr(form?.fuel_meter_img_after);
    }
  }, [id]);

  const onChangeHandler = async ({ target }, type) => {
    const file = target.files[0];
    target.value = null;

    if (!file) return;

    const extension = file.name.split(".").pop().toLowerCase();
    const extensions = ["jpeg", "png", "jpg"];

    if (!extensions.includes(extension))
      return toastr.warning(
        `Only ${extensions
          .map((ext) => ext.toUpperCase())
          .join(", ")} file(s) allowed!`
      );

    setLoading(true);
    // const formData = new FormData();
    // formData.append("alias", form[`${condition}_${type}`]);
    // formData.append("file", file);
    const dataForDoc = await uploadFileOnS3(file);
    const response = await CommmonService.uploadDocument({
      ...dataForDoc,
      alias: form[`${condition}_${type}`],
      source: "Fuel dispense",
    });
    setLoading(false);

    if (!response?.status) return toastr.error(response?.message);

    if (type === "fuel_dispenced_odometer_reading")
      setFileDataOdometer(response?.data);
    if (type === "fuel_dispenced_hmr_reading")
      setFileDataHmrmeter(response?.data);
    if (type === "fuel_meter") setFileDataFuelMtr(response?.data);

    if (condition === "before_" && type === "fuel_dispenced_odometer_reading") {
      form["dispancer_pre_image_id"] = response?.data?.id;
      form["dispancer_pre_image_data"] = response?.data;
    }
    if (condition === "before_" && type === "fuel_dispenced_hmr_reading") {
      form["hemm_pre_image_data"] = response?.data;
      form["hemm_pre_image_id"] = response?.data?.id;
    }
    if (condition === "before_" && type === "fuel_meter") {
      form["fuel_meter_img_id"] = response?.data?.id;
      form["fuel_meter_img"] = response?.data;
    }
    if (condition === "after_" && type === "fuel_meter") {
      form["fuel_meter_img_after"] = response?.data;
      form["fuel_meter_img_after_id"] = response?.data?.id;
    }
    if (condition === "after_" && type === "fuel_dispenced_odometer_reading") {
      form["dispancer_post_image_id"] = response?.data?.id;
      form["dispancer_post_image_data"] = response?.data;
    }
    if (condition === "after_" && type === "fuel_dispenced_hmr_reading") {
      form["hemm_post_image_data"] = response?.data;
      form["hemm_post_image_id"] = response?.data?.id;
    }
  };

  const onRemoveHandler = async (condition, type) => {
    if (condition === "before_" && type === "fuel_dispenced_odometer_reading") {
      form["dispancer_pre_image_data"] = "";
      form["dispancer_pre_image_id"] = "";
      setFileDataOdometer();
    }
    if (condition === "before_" && type === "fuel_dispenced_hmr_reading") {
      form["hemm_pre_image_data"] = "";
      form["hemm_pre_image_id"] = "";
      setFileDataHmrmeter();
    }
    if (condition === "before_" && type === "fuel_meter") {
      form["fuel_meter_img"] = "";
      form["fuel_meter_img_id"] = "";
      setFileDataFuelMtr();
    }
    if (condition === "after_" && type === "fuel_meter") {
      form["fuel_meter_img_after"] = "";
      form["fuel_meter_img_after_id"] = "";
      setFileDataFuelMtr();
    }
    if (condition === "after_" && type === "fuel_dispenced_odometer_reading") {
      form["dispancer_post_image_data"] = "";
      form["dispancer_post_image_id"] = "";
      setFileDataOdometer();
    }
    if (condition === "after_" && type === "fuel_dispenced_hmr_reading") {
      form["hemm_post_image_data"] = "";
      form["hemm_post_image_id"] = "";
      setFileDataHmrmeter();
    }
  };
  const errorClassName = "d-flex small text-danger position-relative";
  return (
    <>
      {IS_MOBILE() ? (
        <div className="mobileFormWrapper">
          <h3>{`${
            condition === "before_" ? "Before" : "After"
          } Fuel Dispensed Details`}</h3>
          <div className="ehsFormWrapper p-0">
            {condition === "after_" && (
              <AddDispancedDetails
                form={form}
                error={error}
                onChange={onChange}
              />
            )}

            <Form>
              <Row>
                <Col md="12">
                  <div className="addDataformCards">
                    {condition === "before_" && (
                      <>
                        <Form.Label className="form-label-input">
                          Dispensed Date and Time
                        </Form.Label>
                        <CustomDatePicker
                          value={
                            form.createdAt
                              ? form.createdAt
                              : moment().format("YYYY-MM-DD HH:mm")
                          }
                          isValidDate={(day) =>
                            day.isSameOrAfter(moment().subtract(3, "d")) &&
                            day.isSameOrBefore(moment())
                          }
                          name="createdAt"
                          onChange={onChange}
                          timeFormat={"HH:mm"}
                          dateFormat="YYYY-MM-DD"
                        />
                        <span className="small text-danger">
                          {error.createdAt}
                        </span>
                      </>
                    )}

                    <div className="formFields mb-3">
                      <label>Category</label>
                      <div className="labelNa">
                        <div>
                          <h5>HMR</h5>
                        </div>
                      </div>
                    </div>
                    {condition === "before_" && (
                      <>
                        <div className="formFields labelNa">
                          <FormInputText
                            label={`Current HMR Reading`}
                            type={"number"}
                            name={`user_entered_hmr_reading`}
                            value={form[`user_entered_hmr_reading`]}
                            onChange={onChange}
                            disabled={form?.hmr_reading_check_box}
                            formGroupClass={
                              lastReadingLabel ? "inputText" : false
                            }
                            error={error[`user_entered_hmr_reading`]}
                          ></FormInputText>
                          <div className="naCheckbox">
                            <input
                              type="checkbox"
                              name={`hmr_reading_check_box`}
                              value={form[`hmr_reading_check_box`]}
                              checked={form[`hmr_reading_check_box`]}
                              disabled={
                                form[`user_entered_hmr_reading`] &&
                                !form[`hmr_reading_check_box`]
                              }
                              onChange={onChange}
                            />
                            <Label>N/A</Label>
                          </div>
                        </div>
                        {/* {lastReadingField ? ( */}
                        <div className="formFields labelNa">
                          <FormInputText
                            label={`Last HMR Reading`}
                            type={"number"}
                            name={`last_hmr_reading`}
                            value={form?.last_hmr_reading}
                            disabled={form?.last_hmr_reading_check_box}
                            onChange={onChange}
                            error={error[`user_entered_odometer_kms`]}
                          ></FormInputText>
                          <div className="naCheckbox">
                            <input
                              type="checkbox"
                              name={`last_hmr_reading_check_box`}
                              value={form[`last_hmr_reading_check_box`]}
                              checked={form[`last_hmr_reading_check_box`]}
                              disabled={
                                form[`last_hmr_reading`] &&
                                !form[`last_hmr_reading_check_box`]
                              }
                              onChange={onChange}
                            />
                            <Label>N/A</Label>
                          </div>
                        </div>
                        {/* ) : (
                          <span className style={{ fontSize: "12px" }}>
                            Last HMR Reading : {form?.last_hmr_reading}
                          </span>
                        )} */}
                        <div className="formFields labelNa">
                          <FormInputText
                            label={`HMR Hours`}
                            type={"number"}
                            data-testid="reading"
                            name={`user_entered_hmr_hours`}
                            value={form[`user_entered_hmr_hours`]}
                            disabled={form?.hmr_hours_check_box}
                            onChange={onChange}
                            error={error[`user_entered_hmr_hours`]}
                          ></FormInputText>

                          <div className="naCheckbox">
                            <input
                              type="checkbox"
                              name={`hmr_hours_check_box`}
                              value={form[`hmr_hours_check_box`]}
                              checked={form[`hmr_hours_check_box`]}
                              disabled={
                                form[`user_entered_hmr_hours`] &&
                                !form[`hmr_hours_check_box`]
                              }
                              onChange={onChange}
                            />
                            <Label>N/A</Label>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="formFields mb-3 mt-3">
                      <label>Upload Photo/View Photo</label>
                      <div className="d-flex align-items-start">
                        <div className="uploadFileBtn me-2">
                          <label
                            className="uploadBtn"
                            htmlFor={`upload${condition}+fuel_dispenced_hmr_reading`}
                          >
                            <BsFileEarmarkArrowUp
                              style={{ fontSize: "24px", color: "white" }}
                            />
                          </label>
                          <input
                            type="file"
                            id={`upload${condition}+fuel_dispenced_hmr_reading`}
                            className="uploadFile d-none"
                            data-testid="uploadFile"
                            onChange={(e) =>
                              onChangeHandler(e, "fuel_dispenced_hmr_reading")
                            }
                            disabled={loading && true}
                          />
                        </div>

                        {condition === "before_" ? (
                          <InputError
                            className={errorClassName}
                            error={error["hemm_pre_image_id"]}
                          />
                        ) : (
                          <InputError
                            error={error["hemm_post_image_id"]}
                            className={errorClassName}
                          />
                        )}
                        {fileDataHmrmeter && (
                          <EvidencePreview
                            type={"jpg"}
                            file={fileDataHmrmeter}
                          ></EvidencePreview>
                        )}
                      </div>
                    </div>
                    <div className="seperatorLine"></div>
                    {/* </div>
                  <div className=""> */}
                    <div className="formFields mb-3">
                      <label>Category</label>
                      <div className="labelNa">
                        <div>
                          <h5>Odometer</h5>
                        </div>
                      </div>
                    </div>
                    {condition === "before_" && (
                      <>
                        <div className="formFields labelNa">
                          <FormInputText
                            label={`Current Odometer Reading`}
                            type={"number"}
                            formGroupClass={
                              lastReadingLabel ? "inputText" : false
                            }
                            name={`user_entered_odometer_reading`}
                            value={form[`user_entered_odometer_reading`]}
                            disabled={form?.odometer_reading_check_box}
                            onChange={onChange}
                            error={error[`user_entered_odometer_reading`]}
                          ></FormInputText>
                          <div className="naCheckbox">
                            <input
                              type="checkbox"
                              name={`odometer_reading_check_box`}
                              value={form[`odometer_reading_check_box`]}
                              checked={form[`odometer_reading_check_box`]}
                              disabled={
                                form[`user_entered_odometer_reading`] &&
                                !form[`odometer_reading_check_box`]
                              }
                              onChange={onChange}
                            />
                            <Label>N/A</Label>
                          </div>
                        </div>
                        {lastReadingField ? (
                          <div className="formFields labelNa">
                            <FormInputText
                              label={`Last Odometer Reading`}
                              type={"number"}
                              name={`last_odometer_reading`}
                              value={form?.last_odometer_reading}
                              disabled={form?.last_odometer_reading_check_box}
                              onChange={onChange}
                              error={error[`user_entered_odometer_kms`]}
                            ></FormInputText>
                            <div className="naCheckbox">
                              <input
                                type="checkbox"
                                name={`last_odometer_reading_check_box`}
                                disabled={
                                  form[`last_odometer_reading`] &&
                                  !form[`last_odometer_reading_check_box`]
                                }
                                value={form[`last_odometer_reading_check_box`]}
                                checked={
                                  form[`last_odometer_reading_check_box`]
                                }
                                onChange={onChange}
                              />
                              <Label>N/A</Label>
                            </div>
                          </div>
                        ) : (
                          <span className style={{ fontSize: "12px" }}>
                            Last Odometer Reading :{" "}
                            {form?.last_odometer_reading}
                          </span>
                        )}
                        <div className="formFields labelNa">
                          <FormInputText
                            label={`Odometer Kms`}
                            type={"number"}
                            name={`user_entered_odometer_kms`}
                            value={form[`user_entered_odometer_kms`]}
                            disabled={form?.odometer_kms_check_box}
                            onChange={onChange}
                            error={error[`user_entered_odometer_kms`]}
                          ></FormInputText>
                          <div className="naCheckbox">
                            <input
                              type="checkbox"
                              name={`odometer_kms_check_box`}
                              disabled={
                                form[`user_entered_odometer_kms`] &&
                                !form[`odometer_kms_check_box`]
                              }
                              value={form[`odometer_kms_check_box`]}
                              checked={form[`odometer_kms_check_box`]}
                              onChange={onChange}
                            />
                            <Label>N/A</Label>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="formFields mb-3 mt-3">
                      <label>Upload Photo/View Photo</label>
                      <div className="d-flex align-items-start">
                        <div className="uploadFileBtn me-2">
                          <label
                            htmlFor={`upload${condition}+fuel_dispenced_odometer_reading`}
                            className="uploadBtn"
                          >
                            <BsFileEarmarkArrowUp
                              style={{ fontSize: "24px", color: "white" }}
                            />
                          </label>
                          <input
                            onChange={(e) =>
                              onChangeHandler(
                                e,
                                "fuel_dispenced_odometer_reading"
                              )
                            }
                            id={`upload${condition}+fuel_dispenced_odometer_reading`}
                            className="uploadFile d-none"
                            data-testid="uploadFile"
                            type="file"
                            disabled={loading && true}
                          />
                        </div>
                        {condition === "before_" ? (
                          <InputError
                            className={errorClassName}
                            error={error["dispancer_pre_image_id"]}
                          />
                        ) : (
                          <InputError
                            error={error["dispancer_post_image_id"]}
                            className={errorClassName}
                          />
                        )}
                        {fileDataOdometer && (
                          <EvidencePreview
                            type={"jpg"}
                            file={fileDataOdometer}
                          ></EvidencePreview>
                        )}
                      </div>
                    </div>
                    <div className="seperatorLine"></div>
                    <div className="formFields mb-3">
                      <label>Category</label>
                      <div className="labelNa">
                        <div>
                          <h5>Fuel Meter</h5>
                        </div>
                      </div>
                    </div>
                    {condition === "before_" && (
                      <>
                        <div className="formFields labelNa">
                          <FormInputText
                            type={"number"}
                            name={`fuel_meter`}
                            value={form[`fuel_meter`]}
                            disabled={form?.fuel_reading_check_box}
                            onChange={onChange}
                            error={error[`fuel_meter`]}
                          ></FormInputText>
                          <div className="naCheckbox">
                            <input
                              type="checkbox"
                              name={`fuel_reading_check_box`}
                              value={form[`fuel_reading_check_box`]}
                              checked={form[`fuel_reading_check_box`]}
                              disabled={form[`fuel_meter`]}
                              onChange={onChange}
                            />
                            <Label>N/A</Label>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="formFields mb-3">
                      <label>Upload Photo/View Photo</label>
                      <div className="d-flex align-items-start">
                        <div className="uploadFileBtn me-2">
                          <label
                            htmlFor={`upload${condition}+fuel_meter`}
                            className="uploadBtn"
                          >
                            <BsFileEarmarkArrowUp
                              style={{ fontSize: "24px", color: "white" }}
                            />
                          </label>
                          <input
                            id={`upload${condition}+fuel_meter`}
                            className="uploadFile d-none"
                            data-testid="uploadFile"
                            onChange={(e) => onChangeHandler(e, "fuel_meter")}
                            disabled={loading && true}
                            type="file"
                          />
                        </div>
                        {condition === "before_" ? (
                          <InputError
                            error={error["dispancer_pre_image_id"]}
                            className={errorClassName}
                          />
                        ) : (
                          <InputError
                            className={errorClassName}
                            error={error["dispancer_post_image_id"]}
                          />
                        )}
                        {fileDataFuelMtr && (
                          <EvidencePreview
                            file={fileDataFuelMtr}
                            type={"jpg"}
                          ></EvidencePreview>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
            {condition === "after_" && (
              <>
                <Row>
                  <Col lg={4}>
                    <Label style={{ marginTop: "6px" }}>Receiver</Label>
                    <FormInputText
                      type={"text"}
                      name={`receiver`}
                      value={form[`receiver`]}
                      onChange={onChange}
                      error={error[`receiver`]}
                    ></FormInputText>
                  </Col>
                  <Col lg={4}>
                    <Label style={{ marginTop: "6px" }}>Issuer</Label>
                    <FormInputText
                      type={"text"}
                      name={`issuer`}
                      value={form[`issuer`]}
                      onChange={onChange}
                      error={error[`issuer`]}
                    ></FormInputText>
                  </Col>
                </Row>
                <FormTextArea
                  name="afterFuelDispense"
                  value={form.afterFuelDispense}
                  onChange={onChange}
                  className="form-control "
                  label={"Remarks"}
                  error={error?.afterFuelDispense}
                />

                {negativeMssg && (
                  <Col lg={12}>
                    <div class="alert alert-warning mb-0 mt-2">
                      {negativeMssg}
                    </div>
                  </Col>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="formWrapper">
          {condition === "after_" && (
            <AddDispancedDetails
              error={error}
              onChange={onChange}
              form={form}
            />
          )}
          {condition === "before_" && (
            <Row>
              <Col md={3}>
                <Form.Label className="form-label-input">
                  Dispensed Date and Time
                </Form.Label>
                <CustomDatePicker
                  value={
                    form.createdAt
                      ? form.createdAt
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  isValidDate={(day) =>
                    day.isSameOrAfter(moment().subtract(3, "d")) &&
                    day.isSameOrBefore(moment())
                  }
                  name="createdAt"
                  onChange={onChange}
                  timeFormat={"HH:mm"}
                  dateFormat="YYYY-MM-DD"
                />
                <span className="small text-danger">{error.createdAt}</span>
              </Col>
            </Row>
          )}
          <div className="table-responsive" style={{ overflowX: "hidden" }}>
            <table className="table table-bordered">
              <thead className="tableHead-bg">
                <tr>
                  <th>Category</th>
                  {/* {condition === "before_" && <th>N/A</th>} */}
                  {condition === "before_" && <th>Reading</th>}
                  <th>Upload Photo/View Photo</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>HMR</p>{" "}
                  </td>

                  {condition === "before_" && (
                    <>
                      {/* <td>
                        {condition === "before_" && (
                          <input
                            type="checkbox"
                            name={`hmr_reading_check_box`}
                            value={form[`hmr_reading_check_box`]}
                            checked={form[`hmr_reading_check_box`]}
                            onChange={onChange}
                          />
                        )}
                      </td> */}
                      <>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              width: "650px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Label style={{ marginTop: "6px" }}>
                              Current HMR Reading
                            </Label>
                            <div style={{ marginLeft: "50px" }}>
                              <FormInputText
                                type={"number"}
                                name={`user_entered_hmr_reading`}
                                value={form[`user_entered_hmr_reading`]}
                                data-testid="user_entered_hmr_readingId"
                                onChange={onChange}
                                disabled={form?.hmr_reading_check_box}
                                formGroupClass={
                                  form?.dispenseData ? "inputText" : false
                                }
                                // error={error[`user_entered_hmr_reading`]}
                              ></FormInputText>
                              {/* {lastReadingLabel && (
                                <span className style={{ fontSize: "12px" }}>
                                  Last HMR Reading : {form?.last_hmr_reading}
                                </span>
                              )} */}
                              <InputError
                                error={error[`user_entered_hmr_reading`]}
                                className={errorClassName}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginBottom: lastReadingLabel ? "40px" : "0px",
                              }}
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  name={`hmr_reading_check_box`}
                                  value={form[`hmr_reading_check_box`]}
                                  checked={form[`hmr_reading_check_box`]}
                                  disabled={
                                    form[`user_entered_hmr_reading`] &&
                                    !form[`hmr_reading_check_box`]
                                  }
                                  onChange={onChange}
                                />
                              </div>
                              <div style={{ marginBottom: "4px" }}>
                                Not Available
                              </div>
                            </div>
                          </div>
                          {/* {lastReadingField && ( */}
                          <div
                            style={{
                              display: "flex",
                              width: "650px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <Label>Last HMR Reading</Label>
                            </div>
                            <div style={{ marginLeft: "70px" }}>
                              <FormInputText
                                type={"number"}
                                name={`last_hmr_reading`}
                                value={form?.last_hmr_reading}
                                disabled={form?.last_hmr_reading_check_box}
                                onChange={onChange}
                                // error={error[`last_hmr_reading`]}
                              ></FormInputText>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  name={`last_hmr_reading_check_box`}
                                  value={form[`last_hmr_reading_check_box`]}
                                  checked={form[`last_hmr_reading_check_box`]}
                                  disabled={
                                    form[`last_hmr_reading`] &&
                                    form[`last_hmr_reading`] !== "N/A" &&
                                    !form[`last_hmr_reading_check_box`]
                                  }
                                  onChange={onChange}
                                />
                              </div>
                              <div style={{ marginBottom: "4px" }}>
                                Not Available
                              </div>
                            </div>
                          </div>
                          {/* )} */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "650px",
                            }}
                          >
                            <Label style={{ marginTop: "6px" }}>
                              HMR Hours
                            </Label>
                            <div style={{ marginLeft: "125px" }}>
                              <FormInputText
                                type={"number"}
                                data-testid="reading"
                                name={`user_entered_hmr_hours`}
                                value={form[`user_entered_hmr_hours`]}
                                disabled={form?.hmr_hours_check_box}
                                onChange={onChange}
                                error={error[`user_entered_hmr_hours`]}
                              ></FormInputText>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginBottom: form?.dispenseData
                                  ? "10px"
                                  : "0px",
                              }}
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  name={`hmr_hours_check_box`}
                                  value={form[`hmr_hours_check_box`]}
                                  checked={form[`hmr_hours_check_box`]}
                                  disabled={
                                    form[`user_entered_hmr_hours`] &&
                                    !form[`hmr_hours_check_box`]
                                  }
                                  onChange={onChange}
                                />
                              </div>
                              <div style={{ marginBottom: "4px" }}>
                                Not Available
                              </div>
                            </div>
                          </div>
                        </td>
                      </>
                    </>
                  )}
                  <td>
                    <div className="d-flex align-items-start">
                      <div className="uploadFileBtn me-2">
                        <label
                          htmlFor={`upload${condition}+fuel_dispenced_hmr_reading`}
                          className="uploadBtn"
                        >
                          <BsFileEarmarkArrowUp
                            style={{ fontSize: "24px", color: "white" }}
                          />
                        </label>
                        <input
                          id={`upload${condition}+fuel_dispenced_hmr_reading`}
                          className="uploadFile d-none"
                          data-testid="uploadFile"
                          type="file"
                          onChange={(e) =>
                            onChangeHandler(e, "fuel_dispenced_hmr_reading")
                          }
                          disabled={loading && true}
                        />
                      </div>

                      {condition === "before_" ? (
                        <InputError
                          error={error["hemm_pre_image_id"]}
                          className={errorClassName}
                        />
                      ) : (
                        <InputError
                          error={error["hemm_post_image_id"]}
                          className={errorClassName}
                        />
                      )}
                      {fileDataHmrmeter && (
                        <EvidencePreview
                          file={fileDataHmrmeter}
                          type={"jpg"}
                        ></EvidencePreview>
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="icons font-21 text-danger">
                      <AiOutlineMinusCircle
                        size="24"
                        style={{ cursor: "pointer" }}
                        data-testid="removeFile"
                        onClick={(e) =>
                          onRemoveHandler(
                            condition,
                            "fuel_dispenced_hmr_reading"
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p>Odometer</p>{" "}
                  </td>

                  {condition === "before_" && (
                    <>
                      {/* <td>
                        {condition === "before_" && (
                          <input
                            type="checkbox"
                            name={`odometer_reading_check_box`}
                            value={form[`odometer_reading_check_box`]}
                            checked={form[`odometer_reading_check_box`]}
                            onChange={onChange}
                          />
                        )}
                      </td> */}
                      <>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "650px",
                            }}
                          >
                            <Label style={{ marginTop: "6px" }}>
                              Current Odometer Reading
                            </Label>
                            <div>
                              <FormInputText
                                type={"number"}
                                formGroupClass={
                                  form?.dispenseData ? "inputText" : false
                                }
                                name={`user_entered_odometer_reading`}
                                value={form[`user_entered_odometer_reading`]}
                                disabled={form?.odometer_reading_check_box}
                                onChange={onChange}
                                // error={error[`user_entered_odometer_reading`]}
                              ></FormInputText>
                              {lastReadingLabel && (
                                <span className style={{ fontSize: "12px" }}>
                                  Last Odometer Reading :{" "}
                                  {form?.last_odometer_reading}
                                </span>
                              )}
                              <InputError
                                error={error[`user_entered_odometer_reading`]}
                                className={errorClassName}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginBottom: lastReadingLabel ? "40px" : "0px",
                              }}
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  name={`odometer_reading_check_box`}
                                  value={form[`odometer_reading_check_box`]}
                                  checked={form[`odometer_reading_check_box`]}
                                  disabled={
                                    form[`user_entered_odometer_reading`] &&
                                    !form[`odometer_reading_check_box`]
                                  }
                                  onChange={onChange}
                                />
                              </div>
                              <div style={{ marginBottom: "4px" }}>
                                Not Available
                              </div>
                            </div>
                          </div>
                          {lastReadingField && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "650px",
                              }}
                            >
                              <Label>Last Odometer Reading</Label>
                              <div style={{ marginLeft: "20px" }}>
                                <FormInputText
                                  type={"number"}
                                  name={`last_odometer_reading`}
                                  value={form?.last_odometer_reading}
                                  disabled={
                                    form?.last_odometer_reading_check_box
                                  }
                                  onChange={onChange}
                                  // error={error[`last_odometer_reading`]}
                                ></FormInputText>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    name={`last_odometer_reading_check_box`}
                                    disabled={
                                      form[`last_odometer_reading`] &&
                                      form[`last_odometer_reading`] !== "N/A" &&
                                      !form[`last_odometer_reading_check_box`]
                                    }
                                    value={
                                      form[`last_odometer_reading_check_box`]
                                    }
                                    checked={
                                      form[`last_odometer_reading_check_box`]
                                    }
                                    onChange={onChange}
                                  />
                                </div>
                                <div style={{ marginBottom: "4px" }}>
                                  Not Available
                                </div>
                              </div>
                            </div>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "650px",
                            }}
                          >
                            <Label style={{ marginTop: "6px" }}>
                              Odometer Kms
                            </Label>
                            <div style={{ marginLeft: "85px" }}>
                              <FormInputText
                                type={"number"}
                                name={`user_entered_odometer_kms`}
                                value={form[`user_entered_odometer_kms`]}
                                disabled={form?.odometer_kms_check_box}
                                onChange={onChange}
                                error={error[`user_entered_odometer_kms`]}
                              ></FormInputText>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginBottom: form?.dispenseData
                                  ? "10px"
                                  : "0px",
                              }}
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  name={`odometer_kms_check_box`}
                                  disabled={
                                    form[`user_entered_odometer_kms`] &&
                                    !form[`odometer_kms_check_box`]
                                  }
                                  value={form[`odometer_kms_check_box`]}
                                  checked={form[`odometer_kms_check_box`]}
                                  onChange={onChange}
                                />
                              </div>
                              <div style={{ marginBottom: "4px" }}>
                                Not Available
                              </div>
                            </div>
                          </div>
                        </td>
                      </>
                    </>
                  )}
                  <td>
                    <div className="d-flex align-items-start">
                      <div className="uploadFileBtn me-2">
                        <label
                          htmlFor={`upload${condition}+fuel_dispenced_odometer_reading`}
                          className="uploadBtn"
                        >
                          <BsFileEarmarkArrowUp
                            style={{ fontSize: "24px", color: "white" }}
                          />
                        </label>
                        <input
                          id={`upload${condition}+fuel_dispenced_odometer_reading`}
                          className="uploadFile d-none"
                          data-testid="uploadFile"
                          type="file"
                          onChange={(e) =>
                            onChangeHandler(
                              e,
                              "fuel_dispenced_odometer_reading"
                            )
                          }
                          disabled={loading && true}
                        />
                      </div>
                      {condition === "before_" ? (
                        <InputError
                          error={error["dispancer_pre_image_id"]}
                          className={errorClassName}
                        />
                      ) : (
                        <InputError
                          error={error["dispancer_post_image_id"]}
                          className={errorClassName}
                        />
                      )}
                      {fileDataOdometer && (
                        <EvidencePreview
                          file={fileDataOdometer}
                          type={"jpg"}
                        ></EvidencePreview>
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="icons font-21 text-danger">
                      <AiOutlineMinusCircle
                        size="24"
                        style={{ cursor: "pointer" }}
                        data-testid="removeFile"
                        onClick={(e) =>
                          onRemoveHandler(
                            condition,
                            "fuel_dispenced_odometer_reading"
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Fuel Meter</td>
                  {condition === "before_" && (
                    <>
                      {/* <td>
                        <input
                          type="checkbox"
                          name={`fuel_reading_check_box`}
                          value={form[`fuel_reading_check_box`]}
                          checked={form[`fuel_reading_check_box`]}
                          onChange={onChange}
                        />
                      </td> */}

                      <>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "650px",
                            }}
                          >
                            <Label style={{ marginTop: "6px" }}>
                              Fuel Meter Reading
                            </Label>
                            <div style={{ marginLeft: "50px" }}>
                              <FormInputText
                                type={"number"}
                                name={`fuel_meter`}
                                value={form[`fuel_meter`]}
                                disabled={form?.fuel_reading_check_box}
                                onChange={onChange}
                                // error={error[`fuel_meter`]}
                              ></FormInputText>
                              <InputError
                                error={error[`fuel_meter`]}
                                className={errorClassName}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                marginBottom: form?.dispenseData
                                  ? "10px"
                                  : "0px",
                              }}
                            >
                              <div>
                                {" "}
                                <input
                                  type="checkbox"
                                  name={`fuel_reading_check_box`}
                                  value={form[`fuel_reading_check_box`]}
                                  checked={form[`fuel_reading_check_box`]}
                                  disabled={form[`fuel_meter`]}
                                  onChange={onChange}
                                />
                              </div>
                              <div style={{ marginBottom: "4px" }}>
                                Not Available
                              </div>
                            </div>
                          </div>
                        </td>
                      </>
                    </>
                  )}
                  <td>
                    <div className="d-flex align-items-start">
                      <div className="uploadFileBtn me-2">
                        <label
                          htmlFor={`upload${condition}+fuel_meter`}
                          className="uploadBtn"
                        >
                          <BsFileEarmarkArrowUp
                            style={{ fontSize: "24px", color: "white" }}
                          />
                        </label>
                        <input
                          id={`upload${condition}+fuel_meter`}
                          className="uploadFile d-none"
                          data-testid="uploadFile"
                          type="file"
                          onChange={(e) => onChangeHandler(e, "fuel_meter")}
                          disabled={loading && true}
                        />
                      </div>
                      {condition === "before_" ? (
                        <InputError
                          error={error["fuel_meter_img_id"]}
                          className={errorClassName}
                        />
                      ) : (
                        <InputError
                          error={error["fuel_meter_img_after_id"]}
                          className={errorClassName}
                        />
                      )}
                      {fileDataFuelMtr && (
                        <EvidencePreview
                          file={fileDataFuelMtr}
                          type={"jpg"}
                        ></EvidencePreview>
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="icons font-21 text-danger">
                      <AiOutlineMinusCircle
                        size="24"
                        style={{ cursor: "pointer" }}
                        data-testid="removeFile"
                        onClick={(e) =>
                          onRemoveHandler(condition, "fuel_meter")
                        }
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* {form.fuel_location_id && (
                <Col lg={4}>
                  {"Max Capacity (L) "}
                  {maxCapacity ? ": " + maxCapacity : "Not Available"}
                </Col>
              )} */}
            {condition === "after_" && (
              <>
                <Row>
                  <Col lg={4}>
                    <Label style={{ marginTop: "6px" }}>Receiver</Label>
                    <FormInputText
                      type={"text"}
                      name={`receiver`}
                      value={form[`receiver`]}
                      onChange={onChange}
                      error={error[`receiver`]}
                    ></FormInputText>
                  </Col>
                  <Col lg={4}>
                    <Label style={{ marginTop: "6px" }}>Issuer</Label>
                    <FormInputText
                      type={"text"}
                      name={`issuer`}
                      value={form[`issuer`]}
                      onChange={onChange}
                      error={error[`issuer`]}
                    ></FormInputText>
                  </Col>
                </Row>

                <FormTextArea
                  name="afterFuelDispense"
                  value={form.afterFuelDispense}
                  onChange={onChange}
                  className="form-control "
                  label={"Remarks"}
                  error={error?.afterFuelDispense}
                />

                {negativeMssg && (
                  <Col lg={12}>
                    <div class="alert alert-warning mb-0 mt-2">
                      {negativeMssg}
                    </div>
                  </Col>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {condition === "after_" ? (
        <ReportButton
          setTab={setTab}
          tab={tab}
          onSubmit={onSubmit}
          onDraft={onDraft}
          loaderValue={loaderValue}
          id={id && form?.statusFlag === "Submitted"}
        />
      ) : (
        <ReportButton setTab={setTab} tab={tab} />
      )}
    </>
  );
};
export default AddFuelDispenced;
