import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "../../../../components/Select/Select";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import { AiOutlinePlus } from "react-icons/ai";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  createHindrance,
  getHinderanceById,
  updateHindranceStatus,
  getContractorById,
} from "../../../../services/_hindrance/hindrance";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MessagePopup from "../../../../components/MessagePopup/MessagePopup";
import StatusPopup from "../../../../components/MessagePopup/StatusPopup";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import { disableFutureDate } from "../../../../_helpers/dateHelper";
import toastr from "toastr";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import {
  SidebarData,
  getEnvConfigPermissibleById,
  createPermissibleConfig,
  updateEnvPermissibleConfig,
  EnvConfigPermissibleRule,
  EnvConfigPermissibleCaaRule,
  EnvConfigPermissibleCmpRule,
  EnvConfigPermissibleNoiseRule,
} from "../DATA";
import { useForm } from "../.././../../_helpers/hooks";
import { Validation } from "../../../../_helpers/validation/Validation";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";

const EnvConfigPermissionAddEdit = () => {
  let navigate = useNavigate();
  let params = useParams();
  const usrl = useLocation();

  const segments = usrl.pathname.split("/");
  const lastSegment = segments[segments.length - 2];
  const words = lastSegment.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const title = capitalizedWords.join(" ");
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [show, setShow] = useState(false);
  const [messageshow, setMessageShow] = useState(false);
  const [pollutionForm, setPollutionForm] = useState([]);
  const [mineList, setMineList] = useState([]);
  const [contractorData, setContractorData] = useState();
  const [hindranceBasicDetailsForm, setHindranceBasicDetailsForm] = useState({
    input_date: null,
  });

  const [hindranceBasicDetailsErrorForm, setHindranceBasicDetailsErrorForm] =
    useState({
      input_date: null,
    });
  const closePopup = () => {
    console.log("close-popup");
    setShow(false);
    navigate(
      `/environment-management/configuration/permissible/${lastSegment}`
    );
  };
  const closeMessagePopup = () => {
    setMessageShow(false);
  };
  const hideMessagePopup = () => {
    setMessagePopupType(true);

    setShow(false);
  };
  const [formFields, setFormFields] = useState({
    pm10: "",
    pm25: "",
    so2: "",
    no: "",
    co: "",
    nox: "",
    no2: "",
    spm: "",
    dba_day: "",
    dba_night: "",
    type: "",
    category: "",
  });
  const [error, setError] = useState({
    ...formFields,
  });

  const getConfigById = async () => {
    let response;
    response = await getEnvConfigPermissibleById(params?.id);
    setFormFields({
      pm10: response?.pm10,
      pm25: response?.pm25,
      so2: response?.so2,
      no: response?.no,
      co: response?.co,
      nox: response?.nox,
      no2: response?.no2,
      spm: response?.spm,
      dba_day: response?.dba_day,
      dba_night: response?.dba_night,
      type: response?.type,
      category: response?.category,
    });
    setHindranceBasicDetailsForm((prev) => {
      return {
        ...prev,
        input_date: response?.data?.input_date,
      };
    });
  };
// console.log("fas",params?.id);
  useEffect(() => {
    params?.id && getConfigById();
  }, []);
  const handleFormChange = (event) => {
    setFormFields((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = async () => {
    formFields.type = lastSegment;
    let rules;
    if (lastSegment === "CAAQMS_permissible") {
      rules = EnvConfigPermissibleCaaRule;
    } else if (lastSegment === "CMPDIL_permissible") {
      rules = EnvConfigPermissibleCmpRule;
    } else if (lastSegment === "noise_permissible") {
      rules = EnvConfigPermissibleNoiseRule;
    }

    const errorObj = Validation(formFields, rules);
    setError(errorObj);

    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }

    let response;
    // if (
    //   lastSegment === "power_consumption" ||
    //   lastSegment === "land_reclamation"
    // )
    //  console.log("params" , params?.id);
    response = params?.id
      ? await updateEnvPermissibleConfig(params.id, formFields)
      : await createPermissibleConfig(formFields);
      
      // console.log("qqqq" , response);

    if (response?.status === 1) {
      setShow(true);
      setMessagePopupType(false);
      toastr.success(response?.message);
    } else {
      toastr.error(response?.message);
    }
  };

  const category = [
    {
      value: "Industrial",
      label: "Industrial",
    },
    {
      label: "Residential",
      value: "Residential",
    },
  ];

  function handleClick() {
    setShow(true);
    setMessagePopupType(true);
  }

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              className="title"
              style={{ paddingTop: "5px", paddingBottom: "1px" }}
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    name: "Environment Management",
                    path: "#",
                  },
                  {
                    name: "Configuration",
                    path: "/environment-management/configuration",
                  },
                  {
                    name: title,
                    path: `/environment-management/configuration/permissible/${lastSegment}`,
                  },
                  {
                    path: "#",
                    name: params.id ? "Edit" : "Add",
                  },
                ]}
                // module={ACTIVE_MODULE.incident}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              {title} &nbsp;
              {/* <span className="blueLabel">
                {moment().format("DD/MM/YYYY hh:mm A")}
              </span> */}
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="hindranceContent">
                  <div>
                    <div className="hindranceData">
                      <Row>
                        <Col md={4} lg={6}>
                          <Select
                            onChange={(event) => handleFormChange(event)}
                            name="category"
                            data-testid="select-category"
                            options={category}
                            label={"Category"}
                            placeholder={"Enter Here"}
                            value={formFields?.category}
                            error={error?.category}
                          />
                        </Col>
                        {lastSegment === "CAAQMS_permissible" ||
                        lastSegment === "CMPDIL_permissible" ? (
                          <>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"PM 10"}
                                onChange={(event) => handleFormChange(event)}
                                name="pm10"
                                value={formFields?.pm10}
                                error={error?.pm10}
                              />
                            </Col>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"PM 2.5"}
                                onChange={(event) => handleFormChange(event)}
                                name="pm25"
                                value={formFields?.pm25}
                                error={error?.pm25}
                              />
                            </Col>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"SO2"}
                                onChange={(event) => handleFormChange(event)}
                                name="so2"
                                value={formFields?.so2}
                                error={error?.so2}
                              />
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}

                        {lastSegment === "CAAQMS_permissible" ? (
                          <>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"NO"}
                                onChange={(event) => handleFormChange(event)}
                                name="no"
                                value={formFields?.no}
                                error={error?.no}
                              />
                            </Col>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"CO"}
                                onChange={(event) => handleFormChange(event)}
                                name="co"
                                value={formFields?.co}
                                error={error?.co}
                              />
                            </Col>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"Nox"}
                                onChange={(event) => handleFormChange(event)}
                                name="nox"
                                value={formFields?.nox}
                                error={error?.nox}
                              />
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                        {lastSegment === "CMPDIL_permissible" ? (
                          <>
                            {" "}
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"spm"}
                                onChange={(event) => handleFormChange(event)}
                                name="spm"
                                value={formFields?.spm}
                                error={error?.spm}
                              />
                            </Col>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"NO2"}
                                onChange={(event) => handleFormChange(event)}
                                name="no2"
                                value={formFields?.no2}
                                error={error?.no2}
                              />
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}

                        {lastSegment === "noise_permissible" ? (
                          <>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"Decibel day"}
                                onChange={(event) => handleFormChange(event)}
                                name="dba_day"
                                value={formFields?.dba_day}
                                error={error?.dba_day}
                              />
                            </Col>
                            <Col md={4} lg={6}>
                              <FormInputText
                                type="number"
                                label={"Decibel night"}
                                onChange={(event) => handleFormChange(event)}
                                name="dba_night"
                                value={formFields?.dba_night}
                                error={error?.dba_night}
                              />
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </div>
                  </div>

                  <div className="btnSec">
                    <Button
                      className="secondaryBtn"
                      onClick={handleClick}
                      data-testid="handle-click-for-show"
                    >
                      Close
                    </Button>
                    <div>
                      <Button
                        className="primaryBtn"
                        onClick={onSubmit}
                        data-testid="submit-hindrance"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            messagePopupType={messagePopupType}
            show={show}
            closePopup={closePopup}
            setShow={hideMessagePopup}
          />
          <StatusPopup
            setShow={setMessageShow}
            messagePopupType={false}
            show={messageshow}
            closePopup={closeMessagePopup}
          />
        </div>
      </div>
    </div>
  );
};

export default EnvConfigPermissionAddEdit;
