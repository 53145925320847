import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import "./MainBreadcrumb.scss";
const MainBreadcrumb = ({
  routeSegments,
  breadCrumbsText,
  module,
  isNoTabOnPage,
}) => {
  return (
    <Fragment>
      <div
        className={`breadcrumb ${isNoTabOnPage ? "breadcrumbWdoutTabs" : ""}`}
      >
        {/* <div className="clientLogo"><img src="/assets/images/cf-logo.png" /></div> */}
        <div className="breadcrumbRightdata">
          <div className="breadcrumb_Title customBreadCrumb">
            {routeSegments ? (
              <Fragment>
                {/* <h1>{routeSegments[routeSegments.length - 1]["name"]}</h1> */}
                <NavLink to={routeSegments[0].path}>
                  <span className="nolink">{routeSegments[0]["name"]}</span>
                </NavLink>
                {/* <h1>{routeSegments[0]["name"]}</h1> */}
              </Fragment>
            ) : null}

            <span className="currentOption">
              {routeSegments
                ? routeSegments.map((route, index) =>
                    index !== routeSegments.length - 1 ? (
                      index != 0 ? (
                        <div key={index}>
                          <NavLink to={route?.path}>
                            <span className="capitalize clickableLink">
                              {route?.name}
                            </span>
                          </NavLink>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      <div key={index}>
                        <span className="capitalize text-muted">
                          {route?.name}
                        </span>
                      </div>
                    )
                  )
                : null}
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MainBreadcrumb;
