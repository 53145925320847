import { values } from "lodash";
import { UserService } from "../../_services/UserService/UserService";
import selectionIcon1 from "../../Assets/Images/selectionIcon1.svg";
import selectionIcon2 from "../../Assets/Images/selectionIcon2.svg";
import selectionIcon3 from "../../Assets/Images/selectionIcon3.svg";
import selectionIcon4 from "../../Assets/Images/selectionIcon4.svg";
import selectionIcon5 from "../../Assets/Images/selectionIcon5.svg";

export const addLink = "/user-management/user-list";
export const confAddLink =
  "/contractor-management/configuration/contractor-users";
export const userFilterForm = {
  startDate: "",
  endDate: "",
  siteId: "",
  userType: "",
  permissions: "",
};
export const settingKeys = {
  coal_ob_conversion: "Coal OB Conversion",
  logo: "Logo",
};
export const settingKeysType = {
  coal_ob_conversion: "float",
  logo: "string",
};

export const userInitialForm = {
  firstName: "",
  lastName: "",
  email: "",
  dob: "",
  contactNumber: "",
  userPermission: {},
  roleId: "",
  siteId: "",
  // userType: "",
  siteType: "",
  contractor_id: null,
};

export const settingInitialForm = {
  settingValue: "",
  settingName: "",
  settingType: "",
};
export const settingRules = [
  {
    field_name: "settingValue",
    label: "Setting Value",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "settingName",
    label: "Setting Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "settingType",
    label: "Setting Type",
    type: "string",
    isRequired: true,
  },
];
export const settingTypeOpt = [
  {
    name: "String",
    value: "string",
  },
  {
    name: "Integer",
    value: "integer",
  },
  {
    name: "Float",
    value: "float",
  },
];

export const permissionInitialForm = {
  representative: false,
  section_incharge: false,
  colliery_manager: false,
  dispenser: false,
  chemist: false,
  lab_quality_manager: false,
  area_quality_manager: false,
  coal_grade_admin: false,
  contractor: false,
  contractor_module_admin: false,
  safety_admin: false,
  fuel_admin: false,
  env_admin: false,
  env_user: false,
  safety_user: false,
  sap_import: false,
  contractor_hindrance: false,
  contractor_performance: false,
};
export const SidebarData = [
  {
    img: selectionIcon2,
    title: "User Management",
    link: "/user-management/user-list",
    activeUrl: "user-list",
    active: 0,
    permission: ["super_admin"],
  },
  {
    img: selectionIcon1,
    title: "Settings",
    link: "/user-management/settings",
    activeUrl: "settings",
    active: 0,
    permission: ["super_admin"],
  },

  {
    img: selectionIcon5,
    title: "Help Manual",
    active: 0,
    activeUrl: "help-manual",
    link: "/super-admin/help-manual",
    permission: ["super_admin"],
  },
];
export const customTabsData = [
  {
    to: addLink,
    label: "User List",
    active: 0,
    // permission: ["section_incharge", "contractor_module_admin"]
  },
  {
    to: "/user-management/help-maunal",
    label: "Help Manual",
    active: 0,
  },

  {
    to: "/contractor-management/configuration/reason-list",
    label: "Reason List",
    active: 0,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    to: "/contractor-management/configuration/contractor-list",
    label: "Contractor List",
    active: 0,
    permission: ["contractor_module_admin", "super_admin"],
  },
  {
    to: "/contractor-management/user-list",
    label: "Contractor User List",
    active: 0,
    permission: ["contractor_module_admin"],
  },
];

export const userLabel = {
  name: "Name",
  email: "Email",
  dob: "Date of birth",
  firstName: "First Name",
  lastName: "Last Name",
  contactNumber: "Mobile No.",
  siteId: "Location",
  userType: "Users Type",
  contractor_id: "Contractor",
  roleId: "Users Type",
  siteType: "Location Type",
};

export const siteTypeObj = {
  1: "Head Office",
  2: "Subsidiary",
  3: "Mine",
};

export const userFormRules = [
  {
    field_name: "firstName",
    label: userLabel?.firstName,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "lastName",
    label: userLabel?.lastName,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "contactNumber",
    label: userLabel?.contactNumber,
    type: "number",
    isRequired: true,
  },
  {
    field_name: "email",
    label: userLabel?.email,
    type: "email",
    isRequired: true,
  },
  // {
  //   field_name: "dob",
  //   label: userLabel?.dob,
  //   type: "string",
  //   isRequired: false,
  // },
  {
    field_name: "siteId",
    label: userLabel?.siteId,
    type: "string",
    isRequired: true,
  },
  {
    field_name: "siteType",
    label: userLabel?.siteType,
    type: "string",
    isRequired: true,
  },
  // {
  //   field_name: "userType",
  //   label: userLabel?.userType,
  //   type: "string",
  //   isRequired: true,
  // },
  {
    field_name: "contractor_id",
    label: userLabel?.contractor_id,
    type: "string",
    isRequired: false,
  },
  {
    field_name: "roleId",
    label: userLabel?.roleId,
    type: "string",
    isRequired: true,
  },
];

export const userListApis = Object.freeze({
  getAll: {
    api: UserService.getAllUsersWithServer,
  },
  delete: {
    api: UserService.deleteUser,
  },
});

export const userListApisContractorStaff = Object.freeze({
  getAll: {
    api: UserService.getAllContractorUser,
  },
  delete: {
    api: UserService.deleteUser,
  },
});

export const settingApis = Object.freeze({
  getAll: {
    api: UserService.getSettings,
  },
  delete: {
    api: UserService.deleteSetting,
  },
  getById: {
    api: UserService.getByIdSettings,
  },
});
