import React from "react";
import { Col, Modal, Button } from "react-bootstrap";
import { Row } from "reactstrap";
import {
  reportTypeList,
  exportUploadedFile,
  viewEnvFields,
  reportTypeListTableLabel,
  reportTypeListObj,
  modelNames
} from "./DATA";
import {
  DDMMYYYYFormatBackslash,
  DDMMYYYYFormat,
} from "../../../../src/_helpers/dateHelper";
import { saveAsExcelXlsx } from "../../../../src/components/export/exportExcel";
import { URL_CONFIG } from "../../../../src/_constants/Config/URL_CONFIG";
import { PARSE_IMG_URL } from "../../../../src/_helpers/commonHelper";
import CreatedByCreatedAt from "../../../components/Common/CreatedByCreatedAt";

const UploadedFileViewData = ({ form, fileType, urlFileType }) => {
  function isDate(value) {
    const timestamp = new Date(value).getFullYear();
    return timestamp;
  }
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview hindrancePreview">
          <div className="previewContent">
            <Row>
              <Col md={6}>
                <label>Subsidiary</label>
                <p className="content">{form?.site?.parentSite?.name || ""}</p>
              </Col>
              <Col md={6}>
                <label>Project</label>
                <p className="content">{form?.site?.name}</p>
              </Col>
            </Row>
            {fileType === reportTypeListTableLabel[urlFileType] && (
              <>
                <Row>
                  {viewEnvFields[reportTypeListObj[urlFileType]].map((d) => {
                    let fieldValue = d?.value.split(".");
                    let yearIs = isDate(form[fieldValue[0]]);
                    return (
                      <Col md={6}>
                        <label>{d?.label}</label>
                        <p className="content">
                          {(fieldValue.length === 2 &&
                            form[fieldValue[0]][fieldValue[1]]) ||
                            (yearIs > "1970" &&
                              form[fieldValue[0]].length > 4 &&
                              DDMMYYYYFormat(form[fieldValue[0]])) ||
                            form[fieldValue[0]]}
                        </p>
                      </Col>
                    );
                  })}
                </Row>
              </>
            )}
          </div>
          <CreatedByCreatedAt
            id={form?.id}
            table={modelNames[urlFileType]}
            border={false}
          />
        </div>
      </Modal.Body>
    </>
  );
};

export default UploadedFileViewData;
