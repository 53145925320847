import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData as contractorSideBar } from "../../_pages/ContractorManagement/DATA";
import { SidebarData as fuelSideBar } from "../FuelManagementSystem/FuelManagement/DATA";
import { SidebarData as coalSideBar } from "../../_pages/CoalGrade/DATA";
import "../../_pages/FuelManagementSystem/FuelManagement/FuelMgmt.scss";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import {
  getQuickSightParams,
  getQuickSightUrl,
  getAuthHeader,
} from "./QuickSight";

const QuickSightDashboard = ({ dashboardId }) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [sideBar, setSideBar] = useState([]);
  let divstyle = {
    width: "100%",
    height: `${windowSize.current[1] - 10}px`,
    overflow: "hidden",
    paddingBottom: "10px",
  };
  const getDashboard = async () => {
    let id_token = localStorage.getItem("id_token");
    let jwt_token = localStorage.getItem("jwt_token");

    if (id_token) {
      fetch(
        getQuickSightUrl(id_token.replace(/"/g, "")),
        getAuthHeader(jwt_token)
      )
        .then((res) => {
          return res.json();
        })
        .then(async (dashboardList) => {
          const embeddingContext = await createEmbeddingContext();
          document.getElementById("dashboard-container").innerHTML = "";
          embeddingContext.embedDashboard(
            getQuickSightParams(
              dashboardList.DashboardEmbedUrl.replace(
                "non-existent-id",
                dashboardId
              ),
              document.getElementById("dashboard-container")
            )
          );
        });
    }
  };

  useEffect(() => {
    getDashboard();
    switch (dashboardId) {
      case "Fuel_Management_Dashboard":
        setSideBar(fuelSideBar);
        break;
      case "Coal_Grade_Dashboard":
        setSideBar(coalSideBar);
        break;
      case "Contractor_Performance_Dashboard":
        setSideBar(contractorSideBar);
        break;
      case "Contractor_Performance_Actual":
        setSideBar(contractorSideBar);
        break;
      default:
        break;
    }
  }, [dashboardId]);

  return (
    <div className={`sidebarWrapper`}>
      <SafetySideBar SidebarData={sideBar} moduleTitle={"Fuel Management"} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div style={divstyle} id="dashboard-container"></div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default QuickSightDashboard;
