import {
  deleteMasterDropdown,
  getAllMasterDropdownDesignation,
  getAllMasterDropdownOtherReson,
  getAllMasterDropdownReson,
  getAllMasterDropdownSupplier,
  getAllMasterDropdownViolationsLeading,
  getAllWorkshopMasters,
} from "../../../services/_reasonList/reasonList";

export const reasonListColumnFields = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
    cell: (row) => row?.name,
  },
];
export const listWithCode = [
  {
    name: "Code",
    selector: "code",
    sortable: true,
    cell: (row) => row?.code,
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
    cell: (row) => row?.name,
  },
];

export const workshopIdListColumnFields = [
  {
    name: "Workshop Id",
    selector: "name",
    sortable: true,
    cell: (row) => row?.name,
  },
];

export const reasonListApis = Object.freeze({
  getAll: {
    api: getAllMasterDropdownReson,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});

export const reasonListOtherApis = Object.freeze({
  getAll: {
    api: getAllMasterDropdownOtherReson,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});

export const designationListApi = Object.freeze({
  getAll: {
    api: getAllMasterDropdownDesignation,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});

export const masterListApis = Object.freeze({
  getAll: {
    api: getAllWorkshopMasters,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});

export const violationsLeadingListApis = Object.freeze({
  getAll: {
    api: getAllMasterDropdownViolationsLeading,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});

export const supplierListApis = Object.freeze({
  getAll: {
    api: getAllMasterDropdownSupplier,
  },
  delete: {
    api: deleteMasterDropdown,
  },
});
