import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { tankLabel } from "./DATA";
import CreatedByCreatedAt from "../../../../components/Common/CreatedByCreatedAt";

const TankView = ({ form }) => {
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          {/* <div className="previewContent"> */}
          {/* <p className="head">Tank Details</p> */}
          <Row>
            <Col sm={6}>
              <label>{tankLabel.site_id}</label>
              <p className="content">{form?.site?.name}</p>
            </Col>
            <Col sm={6}>
              <label>{tankLabel.name}</label>
              <p className="content">{form?.name}</p>
            </Col>
            <Col sm={6}>
              <label>{tankLabel.tankId}</label>
              <p className="content">{form?.tankId}</p>
            </Col>

            <Col sm={6}>
              <label>{tankLabel.maxCapacity}</label>
              <p className="content">{form?.maxCapacity}</p>
            </Col>
            {/* <Col sm={6}>
              <label>{tankLabel.closingStock}</label>
              <p className="content">{form?.closingStock}</p>
            </Col> */}
            <Col sm={6}>
              <label>{tankLabel.closingDip}</label>
              <p className="content">{form?.closingDip}</p>
            </Col>
          </Row>
          <CreatedByCreatedAt
            id={form?.id}
            table={"fuel_tank_masters"}
            border={false}
          />
        </div>
        {/* </div> */}
      </Modal.Body>
    </>
  );
};

export default TankView;
