import React from "react";
import Switch from "react-switch";

export const CustomSwitch = ({
  title,
  value,
  name,
  disabled,
  onChange = () => {},
}) => {
  return (
    <>
      <div className="switchTitle">{title}</div>
      <Switch
        checked={value === true}
        onChange={() =>
          onChange({
            target: {
              name: name,
              type: "checkbox",
              checked: !value ? true : false,
            },
          })
        }
        checkedIcon={false}
        uncheckedIcon={false}
        onColor={"#1770c0"}
        width={54}
        disabled={disabled}
      />
    </>
  );
};

{
  /* <CustomSwitch
                name="isPersonalInjury"
                value={
                  form.isPersonalInjury === null ? false : form.isPersonalInjury
                }
                onChange={onChange}
                title="Personnel Affected"
              /> */
}
