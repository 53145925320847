import React, { useCallback, useEffect, useState } from "react";
import {
  initialForm,
  tankApi,
  mineLabel,
  mineTabList,
  validationRulesMine,
  validationRulesMineParticular,
  validationRulesMineOwner,
  validationRulesAgent,
  validationRulesManager,
} from "./DATA";
import { Col, Form, Row, Button } from "react-bootstrap";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import { useNavigate, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { SidebarData } from "../../../SafetyManagementSystem/IncidentManagement/DATA";
import { isNotInteger, subsidiaryListByMine } from "../../../../_helpers/helper";
import { useForm } from "../../../../_helpers/hooks";
import { Validation } from "../../../../_helpers/validation/Validation";
import toastr from "toastr";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import Select from "../../../../components/Select/Select";
import MineDetailsForm from "./MineDetailsForm";
import MineOwnerForm from "./MineOwnerForm";
import CustomTabsComponent from "../../../../components/CustomTabs/CustomTabsComponent";
import {
  addMine,
  updateMine,
} from "../../../../services/_safetyManagement/incidentServices";
import AgentDetailsForms from "./AgentDetailsForms";
import ManagerDetailsForm from "./ManagerDetailsForms";
import { isSpecialChar } from "../../../../_helpers/commonHelper";

const MineDetailsAdd = ({ fetchIncident, incidentBy = false }) => {
  const [updatedMineTabList, setUpdatedMineTabList] = useState(mineTabList);
  const params = useParams();
  const navigate = useNavigate();
  const [form, onChange, setForm] = useForm(initialForm);
  const [tab, setTab] = useState(1);
  const [allSiteListDrop, setAllSiteListDrop] = useState([]);
  const [error, setError] = useState({
    ...initialForm,
  });
  const onSubmit = async () => {
    const errorObj = Validation(form, validationRulesMine);
    let errorObjSiteParticular = Validation(
      form,
      validationRulesMineParticular
    );
    let errorObjSiteOwner = Validation(form, validationRulesMineOwner);
    let errorObjAgent = Validation(form, validationRulesAgent);
    let errorObjManager = Validation(form, validationRulesManager);

    setError((prev) => ({
      ...prev,
      ...errorObj,
      ...errorObjAgent,
      ...errorObjManager,
      ...errorObjSiteOwner,
      ...errorObjSiteParticular,
    }));
    if (String(form?.lin)?.length !== 10) {
      setError((prev) => ({
        ...prev,
        lin: "Labour Identification Number should be 10 digits",
      }));
      return;
    }
    if (String(form?.dgmsMineCode)?.length > 6) {
      setError((prev) => ({
        ...prev,
        dgmsMineCode: "DGMS mine code max have 6 digits",
      }));
      return;
    }
    if (
      Object.values(errorObj).filter((d) => d !== "").length ||
      Object.values(errorObjSiteOwner).filter((d) => d !== "").length ||
      Object.values(errorObjSiteParticular).filter((d) => d !== "").length ||
      Object.values(errorObjManager).filter((d) => d !== "").length ||
      Object.values(errorObjAgent).filter((d) => d !== "").length
    ) {
      toastr.error("All fields are required");
      return;
    }
    let id = !incidentBy ? params?.id : fetchIncident?.id;
    let response = id
      ? await updateMine({ ...form, incidentId: fetchIncident?.id || null }, id)
      : await addMine(form);
    if (!response?.status) {
      return toastr.error(response?.message?.message || "something went wrong");
    }
    if (response?.status) toastr.success(`Saved Successfully`);
    navigate(
      !incidentBy
        ? "/safety-management-system/configuration/mine-details"
        : "/safety-management-system/incident-management/view-incidents"
    );
  };

  const fetchData = useCallback(async () => {
    let response = await tankApi.getById.api(params?.id);
    if (response?.status) {
      setForm((prev) => {
        return {
          ...prev,
          subsidiary: response?.data?.parentSiteId,
          name: response?.data.name,
          site_id: response?.data?.site_id,
          siteIdCode: response?.data?.siteIdCode,
          lin: response?.data?.siteData?.lin,
          ownerName: response?.data?.siteData?.ownerName,
          mineral: response?.data?.siteData?.mineral,
          mineVillageAreaRoad: response?.data?.siteData?.mineVillageAreaRoad,
          minePostOffice: response?.data?.siteData?.minePostOffice,
          mineSubDivision: response?.data?.siteData?.mineSubDivision,
          mineDistrict: response?.data?.siteData?.mineDistrict,
          mineState: response?.data?.siteData?.mineState,
          minePinCode: response?.data?.siteData?.minePinCode,
          minePoliceStation: response?.data?.siteData?.minePoliceStation,
          mineRailwayStation: response?.data?.siteData?.mineRailwayStation,
          ownerVillageAreaRoad: response?.data?.siteData?.ownerVillageAreaRoad,
          ownerPostOffice: response?.data?.siteData?.ownerPostOffice,
          ownerSubDivision: response?.data?.siteData?.ownerSubDivision,
          ownerDistrict: response?.data?.siteData?.ownerDistrict,
          ownerState: response?.data?.siteData?.ownerState,
          ownerPinCode: response?.data?.siteData?.ownerPinCode,
          ownerPoliceStation: response?.data?.siteData?.ownerPoliceStation,
          ownerRailwayStation: response?.data?.siteData?.ownerRailwayStation,
          ownerEmail: response?.data?.siteData?.ownerEmail,
          ownerTelephone: response?.data?.siteData?.ownerTelephone,
          ownerFax: response?.data?.siteData?.ownerFax,
          ownerMobile: response?.data?.siteData?.ownerMobile,
          nameAgent: response?.data?.siteData?.nameAgent,
          postalAddressAgent: response?.data?.siteData?.postalAddressAgent,
          districtAgent: response?.data?.siteData?.districtAgent,
          stateAgent: response?.data?.siteData?.stateAgent,
          pinAgent: response?.data?.siteData?.pinAgent,
          mobileAgent: response?.data?.siteData?.mobileAgent,
          teleAgent: response?.data?.siteData?.teleAgent,
          faxAgent: response?.data?.siteData?.faxAgent,
          emailAgent: response?.data?.siteData?.emailAgent,
          nameManager: response?.data?.siteData?.nameManager,
          postalAddressManager: response?.data?.siteData?.postalAddressManager,
          districtManager: response?.data?.siteData?.districtManager,
          stateManager: response?.data?.siteData?.stateManager,
          pinManager: response?.data?.siteData?.pinManager,
          mobileManager: response?.data?.siteData?.mobileManager,
          teleManager: response?.data?.siteData?.teleManager,
          faxManager: response?.data?.siteData?.faxManager,
          emailManager: response?.data?.siteData?.emailManager,
          region: response?.data?.siteData?.region,
          dgmsMineCode: response?.data?.siteData?.dgmsMineCode,
        };
      });
    }
  }, [params?.id, setForm]);

  const getSiteList = async () => {
    let allSiteList = await getAllSites(
      "filter[siteType]=1,2,3&filter[activeStatus]=active"
    );
    if (allSiteList.status === 1 && allSiteList.message == "success") {
      setAllSiteListDrop(subsidiaryListByMine(allSiteList?.data));
    }
  };

  useEffect(() => {
    getSiteList();
  }, []);

  useEffect(() => {
    if (!params.id || incidentBy) return;
    fetchData();
  }, [fetchData, incidentBy, params.id, setForm]);

  useEffect(() => {
    if (!incidentBy) return;
    setForm((prev) => ({
      ...prev,
      ...fetchIncident?.mineDetails,
      subsidiary: fetchIncident?.site?.parentSiteId,
      name: fetchIncident?.site?.name,
      siteIdCode: fetchIncident?.site?.siteIdCode,
      site_id: fetchIncident?.site_id,
    }));
  }, [
    fetchIncident?.mineDetails,
    fetchIncident?.site?.name,
    fetchIncident?.site?.parentSiteId,
    fetchIncident?.site?.siteIdCode,
    fetchIncident?.site_id,
    incidentBy,
    setForm,
  ]);

  useEffect(() => {
    let errorObj = Validation(form, validationRulesMine);

    if (
      !Object.values(errorObj).filter((d) => d !== "").length &&
      String(form?.lin)?.length === 10 &&
      String(form?.dgmsMineCode)?.length < 7
    ) {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Mine Details") {
            return { ...item, tabStatus: true };
          }
          return item;
        });
      });
    } else {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Mine Details") {
            return { ...item, tabStatus: false };
          }
          return item;
        });
      });
    }

    let errorObjSiteParticular = Validation(
      form,
      validationRulesMineParticular
    );
    if (!Object.values(errorObjSiteParticular).filter((d) => d !== "").length) {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Particulars of the mine") {
            return { ...item, tabStatus: true };
          }
          return item;
        });
      });
    } else {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Particulars of the mine") {
            return { ...item, tabStatus: false };
          }
          return item;
        });
      });
    }

    let errorObjSiteOwner = Validation(form, validationRulesMineOwner);
    if (!Object.values(errorObjSiteOwner).filter((d) => d !== "").length) {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Postal address of owner") {
            return { ...item, tabStatus: true };
          }
          return item;
        });
      });
    } else {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Postal address of owner") {
            return { ...item, tabStatus: false };
          }
          return item;
        });
      });
    }

    let errorObjAgent = Validation(form, validationRulesAgent);
    if (!Object.values(errorObjAgent).filter((d) => d !== "").length) {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Agent Details") {
            return { ...item, tabStatus: true };
          }
          return item;
        });
      });
    } else {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Agent Details") {
            return { ...item, tabStatus: false };
          }
          return item;
        });
      });
    }

    let errorObjManager = Validation(form, validationRulesManager);
    if (!Object.values(errorObjManager).filter((d) => d !== "").length) {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Manager Details") {
            return { ...item, tabStatus: true };
          }
          return item;
        });
      });
    } else {
      setUpdatedMineTabList((prev) => {
        return prev.map((item) => {
          if (item.label === "Manager Details") {
            return { ...item, tabStatus: false };
          }
          return item;
        });
      });
    }
  }, [form]);

  return (
    <div className="wrapper sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              <MainBreadcrumb
                routeSegments={
                  !incidentBy
                    ? [
                        {
                          path: "/",
                          name: "Safety Management",
                        },
                        {
                          name: "Configuration",
                          path: "/safety-management-system/configuration",
                        },
                        {
                          path: "#",
                          name: "Add Mine",
                        },
                      ]
                    : [
                        {
                          path: "/",
                          name: "Safety Management",
                        },
                        {
                          name: "Incident Management",
                          path: "/safety-management-system/incident-management",
                        },
                        {
                          name: "View Incidents",
                          path: "/safety-management-system/incident-management/view-incidents",
                        },
                        {
                          path: "#",
                          name: "Edit Mine",
                        },
                      ]
                }
              />
              <CustomTabsComponent
                tabeList={updatedMineTabList}
                tab={tab}
                setTab={setTab}
                fontSize={"initial"}
              />
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <div className="formWrapper">
                  <div className="maverickFormCard">
                    <div className="creationWrapper">
                      <Form>
                        {tab === 1 && (
                          <Row>
                            <Col lg={3}>
                              <Select
                                onChange={onChange}
                                value={form?.subsidiary}
                                name="subsidiary"
                                options={allSiteListDrop}
                                label={"Subsidiary"}
                                KEY_label={"name"}
                                KEY_value={"id"}
                                disabled={true}
                              />
                            </Col>
                            <Col lg={3}>
                              <FormInputText
                                name="name"
                                value={form.name}
                                onChange={onChange}
                                type="text"
                                class="form-control"
                                placeholder=""
                                label={mineLabel.name}
                                error={error?.name}
                                disabled={true}
                              />
                            </Col>

                            <Col lg={3}>
                              <FormInputText
                                name="siteIdCode"
                                value={form.siteIdCode}
                                onChange={onChange}
                                type="text"
                                class="form-control"
                                placeholder=""
                                label={mineLabel.siteIdCode}
                                error={error?.siteIdCode}
                                disabled={true}
                              />
                            </Col>

                            <Col lg={3}>
                              <FormInputText
                                name="lin"
                                value={form.lin}
                                onChange={({ target: { name, value } }) =>
                              !isNotInteger(value) &&
                              !isSpecialChar(value) &&
                              onChange({ target: { name, value } })
                            }
                                type="text"
                                class="form-control"
                                placeholder=""
                                label={mineLabel.lin}
                                error={error?.lin}
                              />
                            </Col>

                            <Col lg={3}>
                              <FormInputText
                                name="ownerName"
                                value={form.ownerName}
                                onChange={onChange}
                                type="text"
                                class="form-control"
                                placeholder=""
                                label={mineLabel.ownerName}
                                error={error?.ownerName}
                              />
                            </Col>
                            <Col lg={3}>
                              <FormInputText
                                name="region"
                                value={form.region}
                                onChange={onChange}
                                type="text"
                                class="form-control"
                                placeholder=""
                                label={mineLabel.region}
                                error={error?.region}
                              />
                            </Col>
                            <Col lg={3}>
                              <FormInputText
                                name="dgmsMineCode"
                                value={form.dgmsMineCode}
                                onChange={onChange}
                                type="text"
                                class="form-control"
                                placeholder=""
                                label={mineLabel.dgmsMineCode}
                                error={error?.dgmsMineCode}
                              />
                            </Col>
                          </Row>
                        )}
                        {tab === 2 && (
                          <MineDetailsForm
                            form={form}
                            error={error}
                            onChange={onChange}
                          />
                        )}
                        {tab === 3 && (
                          <AgentDetailsForms
                            form={form}
                            error={error}
                            onChange={onChange}
                          />
                        )}
                        {tab === 4 && (
                          <ManagerDetailsForm
                            form={form}
                            error={error}
                            onChange={onChange}
                          />
                        )}
                        {tab === 5 && (
                          <MineOwnerForm
                            form={form}
                            error={error}
                            onChange={onChange}
                          />
                        )}
                      </Form>
                      <div className="btnSec">
                        <Button
                          data-testid="next-btn"
                          onClick={() =>
                            navigate(
                              !incidentBy
                                ? `/safety-management-system/configuration/mine-details`
                                : "/safety-management-system/incident-management/view-incidents"
                            )
                          }
                          className="secondaryBtn iconBtnForm"
                        >
                          Back
                        </Button>
                        {tab === 5 ? (
                          <Button
                            data-testid="next-btn-submit"
                            onClick={() => onSubmit()}
                            className="primaryBtn iconBtnForm"
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            data-testid="next-btn-submit"
                            onClick={() => setTab(1 + tab)}
                            className="primaryBtn iconBtnForm"
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MineDetailsAdd;
