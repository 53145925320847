import React from "react";

const CustomSearch = ({ children }) => {
  return (
    <>
      <div className="">{children}</div>
    </>
  );
};

export default CustomSearch;
