import React from "react";
import { SidebarData, performanceFlowList } from "../DATA";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { filterUrlFunc } from "../../../_services/CommonService/CommonService";
import ManageCardTab from "../../../components/Common/ManageCardTab/ManageCardTab";

const ContractorPerformanceManagement = () => {
  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <ManageCardTab
          breadCrumbData={[
            {
              name: "Contractor Management",
              path: "#",
            },
            {
              name: "Contractor Performance",
              path: "#",
            },
          ]}
          flowList={filterUrlFunc(performanceFlowList)}
        />
      </div>
    </>
  );
};

export default ContractorPerformanceManagement;
