import React, { useEffect, useState } from "react";
import { GrUpload } from "react-icons/gr";
import { AiOutlineCloudDownload } from "react-icons/ai";
import "./uploadfile.scss";
import deleteIcon from "../../../Assets/Images/delete.svg";
import corruptMssg from "../../../Assets/Images/corruptMssg.svg";
import corruptFile from "../../../Assets/Images/corruptfile.svg";
import excelIcon from "../../../Assets/Images/excelIcon.svg";
import { useParams } from "react-router-dom";

const UploadFile = ({
  extensions,
  description,
  onChangeFile,
  // onChangeDescription,
  removeFile,
  sapmleFile,
  addFile
  // downloadSampleFile,
}) => {
  const [fileName, setFileName] = useState("");
  const [errorFile, setErrorFile] = useState(false);
  const param= useParams();
  useEffect(()=>{
    if(addFile){
      setFileName(addFile)
    }
  },[]
  )
  const onChangeHandler = async ({ target }) => {
    const file = target.files[0];
    target.value = null;

    if (!file) return;

    const extension = file.name.split(".").pop().toLowerCase();
    setFileName(file?.name);
    if (!extensions.includes(extension)) {
      setErrorFile(true);
    }
    setErrorFile(false);

    onChangeFile({ file });
  };

  const deleteFile = () => {
    setFileName("");
    removeFile();
  };

  let filePathName="../../../environment_sample/";
  if(sapmleFile==="hemm_datas")filePathName="../../../../environment_sample/";

  return (
    <div className={"form-group mb-1"}>
      <div className="docUpload">
        {sapmleFile ? (
          <label className="form-label">
            Download Sample File.
            <a href={filePathName + sapmleFile + ".xlsx"}>
              <AiOutlineCloudDownload size={30} color="blue" />
            </a>
          </label>
        ) : (
          ""
        )}

        <div className="uploadFileBtn">
          <label htmlFor="upload-single-file">
            <GrUpload />
            <h6>Drag & Drop files to upload</h6>
            <p>or</p>
            <p className="browse">Browse</p>
          </label>
          <input
            className="uploadFile"
            type="file"
            onChange={(e) => onChangeHandler(e)}
            id="upload-single-file"
            data-testid={"fileUploadTestId"}
          />
        </div>
        {fileName && (
          <div className="uploadFileSec">
            <label className="form-label">File Type</label>
            <div className="uploadFileValue">
              <div>
                {errorFile ? (
                  <img src={corruptFile} alt="corrupt File" />
                ) : (
                  <img src={excelIcon} alt="excel File" />
                )}
                <span>&nbsp;{fileName||addFile}</span>
              </div>
              <img
                src={deleteIcon}
                onClick={() => deleteFile()}
                alt="delete"
                className="deleteIcon"
              />
            </div>
            {errorFile && (
              <div className="errorBox">
                <span className="errorMSsg">
                  {" "}
                  <img src={corruptMssg} alt="corrupt File" />
                  We found some errors while reading the file; please
                  cross-check and re-upload the correct file.
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      {/* <NotificationContainer /> */}
    </div>
  );
};

export default UploadFile;
