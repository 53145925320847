import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { initialForm, safetyManagementApis, statusList } from "./DATA";
import { useForm } from "../../_helpers/hooks";
import FormInputText from "../../components/FormInputText/FormInputText";
import {
  AiOutlineMinus,
  AiOutlineMinusCircle,
  AiOutlinePlus,
} from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import CustomRadio from "../../components/_form/CustomRadio/CustomRadio";
import FormTextArea from "../../components/FormTextArea/FormTextAres";
import { Label } from "reactstrap";
import AsterikOnly from "../../components/Common/Asterik/AstrikOnly";

const RootCause = ({
  categoryFormFields,
  removeCategoryFields,
  addCategoryFields,
  addFields,
  removeFields,
  onChangeSubField,
  onChangeCategory,
  onRootCauseChange,
  rootCause,
  errorCategory,
  rootDesc,
  onChangeRootDesc,
  rootDescErr,
  asterikRulesRoot,
}) => {
  const params = useParams("id");
  const [form, onChange, setForm] = useForm({
    ...initialForm,
  });
  const [editStatusList, setEditStatusList] = useState([]);

  const getAll = async (filter) => {
    let response = await safetyManagementApis.getAll.api(filter);
    return response?.data || [];
  };

  useEffect(() => {
    if (!params?.id) return;
    (async () => {
      let statusIndex;
      let response = await getAll(`search[id]=${params?.id}`);

      response?.map((data) => {
        statusIndex = Object.keys(statusList).indexOf(data?.status);
        setForm((prev) => {
          return { ...prev, ...data };
        });
      });

      setEditStatusList(
        Object.keys(statusList)
          .filter((d, i) => i > statusIndex)
          .map((data) => {
            return { label: statusList[data], value: data };
          })
      );
    })();
  }, [params?.id]);
  let ind = 0;
  let branchRule = [
    {
      field_name: "category",
      isRequired: true,
    },
  ];
  return (
    <>
      <div className="md-4">
        <Col md={6}>
          <CustomRadio
            title={"Root Cause"}
            name="capaStatus"
            value={rootCause}
            onChange={onRootCauseChange}
            acceptTitle="Yes"
            rejectTitle="Not Applicable"
          />
        </Col>
      </div>
      {rootCause && (
        <div className="formWrapper rootCause">
          <Row className="mb-3">
            <Col md={12}>
              <Label>
                Root Cause Description
                <AsterikOnly />
              </Label>
              <FormTextArea
                // label={"Root Cause Description"}
                error={rootDescErr}
                name={"rootDesc"}
                value={rootDesc}
                onChange={(e) => onChangeRootDesc(e)}
                rows={3}
              />
            </Col>
            {Array.isArray(categoryFormFields) &&
              categoryFormFields.map((data, index) => {
                return (
                  <Col md={12} className="leafLet">
                    <Row key={index} className="align-items-center">
                      <Col md={index === 0 ? "12" : "10"}>
                        <div className="form-group">
                          <Label>{"Branch Number : " + (index + 1)}</Label>
                          <FormInputText
                            type="text"
                            placeholder="Enter here"
                            label={"Branch Description"}
                            onChange={(e) => onChangeCategory(e, index)}
                            name="category"
                            value={data?.branch}
                            error={errorCategory[index]?.branch}
                            asterikRules={branchRule}
                          />
                        </div>
                      </Col>
                      <Col md="2" className="p-0 mt-4">
                        {index !== 0 && (
                          <span
                            className="icons font-21 text-danger remove"
                            data-testid={"remove-branch" + index}
                            onClick={() => removeCategoryFields(index)}
                          >
                            <AiOutlineMinus /> Remove Branch
                          </span>
                        )}
                      </Col>
                      <Col md="12">
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead className="tableHead-bg">
                              <tr>
                                <th style={{ width: "130px" }}>Leaf Number</th>
                                <th>
                                  Leaf
                                  <AsterikOnly />
                                </th>
                                <th
                                  style={{
                                    width: "100px",
                                    textAlign: "center",
                                  }}
                                >
                                  Remove
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(data.leafOb) &&
                                data.leafOb.map((d, i) => {
                                  ind++;
                                  return (
                                    <tr key={i}>
                                      <th>{ind}</th>
                                      <td>
                                        <FormInputText
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Name"
                                          onChange={(e) =>
                                            onChangeSubField(e, index, i)
                                          }
                                          value={d?.leaf}
                                          name="subCategory"
                                          error={
                                            errorCategory[index]?.leaf[i]?.leaf
                                          }
                                        />
                                      </td>

                                      <td className="text-center">
                                        <div className="actionIconsGroup">
                                          <span
                                            data-testid={"remove-leaf" + index}
                                            onClick={() =>
                                              removeFields(index, i)
                                            }
                                            className="icons font-21 text-danger"
                                          >
                                            <AiOutlineMinusCircle
                                              size="24"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}

                              <tr>
                                <td colSpan="4">
                                  <span
                                    className="addTxt"
                                    onClick={() => addFields(index)}
                                    data-testid="add-leaf"
                                  >
                                    <AiOutlinePlus />
                                    Add Leaf
                                  </span>
                                  {/* <Button
                                    className="addRowPluseIcon ml-0"
                                    
                                  >
                                    
                                    {/* <AiOutlinePlus /> 
                                  </Button> */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            <tr>
              <td colSpan="4">
                <span
                  className="addTxt"
                  onClick={() => addCategoryFields()}
                  data-testid="add-branch"
                >
                  {" "}
                  <AiOutlinePlus />
                  Add Branch
                </span>
                {/* <Button
                  className="addRowPluseIcon ml-0"
                  onClick={() => addCategoryFields()}
                >
                  Add Branch
                  {/* <AiOutlinePlus /> 
              </Button> */}
              </td>
            </tr>
          </Row>
        </div>
      )}
    </>
  );
};

export default RootCause;
