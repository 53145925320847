import React from "react";
import { Col, Row } from "react-bootstrap";
import { matDetailsList } from "../../DATA";

const EquipmentDetailsView = ({ form }) => {
  // console.log("formmmmmm" ,form);

  return (
    <>
      {form?.assetDetails?.length ? (
        form?.assetDetails?.map((obj, idx) => {
          const matDetailsObj =
            matDetailsList.find((d) => +d.id === +obj?.type) || {};
          return (
            <>
              <Row>
                <Col lg={4}>
                  <label>Equipment SKU / Id</label>
                  <p>{obj?.sku}</p>
                </Col>
                <Col lg={4} key={"idx"}>
                  <label>Equipment Type</label>
                  <p>
                    {obj?.hemm_id === null ? matDetailsObj?.name : obj?.type}
                  </p>
                </Col>
                <Col lg={4}>
                  <label>Equipment Name</label>
                  <p>{obj?.name}</p>
                </Col>
                <Col lg={4}>
                  <label>Equipment Damage (Y/N)</label>
                  <p>{obj?.damage}</p>
                </Col>
                <Col lg={8}>
                  <label>Equipment Incident Details </label>
                  <p>{obj?.details}</p>
                </Col>
                {form?.assetDetails?.length - 1 !== idx ? (
                  <div className="seperatorLine"></div>
                ) : (
                  ""
                )}
              </Row>
            </>
          );
        })
      ) : (
        <span className="text-danger">Not Applicable</span>
      )}
    </>
  );
};

export default EquipmentDetailsView;
