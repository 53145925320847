import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "reactstrap";
import toastr from "toastr";
import { Container } from "react-bootstrap";
import {
  hemmApi,
  tabUrl,
  fuelLocationColumnFields,
  //   statusListObj,
  //   fuelManagementFilterForm,
} from "./DATA";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import FuelDispenseFilterForm from "./FuelDispenseFilterForm";
import FuelLocationView from "./HemmView";
import filter from "../../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../../../_helpers/hooks";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../FuelManagement/DATA";

import {
  exportHemmExcel,
  getAllFuelLocation,
  getAllHemm,
} from "../../../../services/_fuelDispense/fuelDispense";
import "../../fuelmgmt.scss";
import { RestMethod } from "../../../../_helpers/ApiConfig/RestMethod";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { changeStatus } from "../../../../_services/CommonService/CommonService";
const HemmList = () => {
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const params = useParams();
  let navigate = useNavigate();
  const usrl = useLocation();
  const cref = useRef(null);

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    let response;
    if (params?.id)
      response = await getAllFuelLocation(
        "page=1&limit=2&createdBy=" + params?.id
      );
    else response = await getAllHemm("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const importExcelFileFun = () => {
    navigate("/fuel-management-system/configuration/hemm-data/importExcelFile");
  };

  const activeInactive = useCallback(async (e, id, gps_active, columnName) => {
    try {
      e.preventDefault();
      console.log("idd", id);
      let url =
        "/fuel-dispence/set-gps-mark-unmark/" +
        id +
        "?column_name=" +
        columnName;
      const response = await RestMethod.PUT(url);
      console.log("ress", response);
      if (response?.data?.status) {
        toastr.success(response?.data?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  // const onResetFilter = () => {
  //   // setForm({ fuelManagementFilterForm });
  //   if (!params?.id) setGetAllFilter();
  //   else setGetAllFilter("&createdBy=" + params?.id);
  // };

  // const onSubmitFilter = () => {
  //   setFilterStatus((prev) => prev + 1);
  // };

  const viewAsPopup = (form) => {
    return <FuelLocationView form={form} />;
  };

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);

  // const FilterBtn = () => {
  //   const [open, setOpen] = useState(false);

  //   return (
  //     <div className="filter">
  //       <Button
  //         onClick={() => setOpen(!open)}
  //         aria-controls="example-collapse-text"
  //         aria-expanded={open}
  //         className="secondaryBtn"
  //         data-testid="filter-button-Fuel-management"
  //       >
  //         <img src={filter} className="img-fluid" alt="fliterIcon" />
  //       </Button>
  //       {/* <FuelDispenseFilterForm
  //         form={form}
  //         onChange={onChange}
  //         onResetFilter={onResetFilter}
  //         onSubmitFilter={onSubmitFilter}
  //         isOpen={open}
  //         setOpen={setOpen}
  //       /> */}
  //     </div>
  //   );
  // };
  const onEdit = (id) => {
    navigate("/fuel-management-system/configuration/hemm-data/" + id);
  };
  useEffect(() => {
    // if (filterStatus === 0) return;

    let filter = "";
    if (params?.id) filter += `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [filterStatus]);

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${params?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);

  const activeInactiveStatus = useCallback(async (e, id, status) => {
    try {
      e.preventDefault();
      const data = {
        modelName: `fuel_dispense_hemm_datas`,
        id: id,
        status: status,
      };
      const response = await changeStatus(data);
      console.log("ress", response);
      if (response?.status) {
        toastr.success(response?.message);
        cref.current.getAll();
      }
    } catch (error) {
      toastr.error(error);
      return {
        status: 0,
        message: error,
      };
    }
  }, []);

  const finalFuelLocationColumnFields = [
    ...fuelLocationColumnFields,
    {
      name: "GPS Status",
      selector: "gps_active",
      cell: (row) => {
        return (
          (row.gps_active === 1 && (
            <span
              href="#"
              className="icons text-success pointer"
              onClick={(e) =>
                activeInactive(e, row.id, row.gps_active, "gps_active")
              }
            >
              <FaToggleOn
                size="20"
                data-testid={"toogle-user-list-on-" + row.id}
              />
            </span>
          )) ||
          (row.gps_active === 0 && (
            <span
              href="#"
              className="icons text-danger pointer"
              onClick={(e) =>
                activeInactive(e, row.id, row.gps_active, "gps_active")
              }
            >
              <FaToggleOff
                size="20"
                data-testid={"toogle-user-list-off-" + row.id}
              />
            </span>
          ))
        );
      },
      minWidth: "150px",
    },
    {
      name: "Fuel Meter Mandatory",
      selector: "fuel_meter_mandatory",
      cell: (row) => {
        return (
          (row.fuel_meter_mandatory === 1 && (
            <span
              href="#"
              className="icons text-success pointer"
              onClick={(e) =>
                activeInactive(
                  e,
                  row.id,
                  row.fuel_meter_mandatory,
                  "fuel_meter_mandatory"
                )
              }
            >
              <FaToggleOn
                size="20"
                data-testid={"toogle-user-list-on-" + row.id}
              />
            </span>
          )) ||
          (row.fuel_meter_mandatory === 0 && (
            <span
              href="#"
              className="icons text-danger pointer"
              onClick={(e) =>
                activeInactive(
                  e,
                  row.id,
                  row.fuel_meter_mandatory,
                  "fuel_meter_mandatory"
                )
              }
            >
              <FaToggleOff
                size="20"
                data-testid={"toogle-user-list-off-" + row.id}
              />
            </span>
          ))
        );
      },
      minWidth: "200px",
    },
    {
      name: "Status",
      selector: "status",
      cell: (row) => {
        return (
          (row.status === 1 && (
            <span
              href="#"
              className="icons text-success pointer"
              onClick={(e) =>
                activeInactiveStatus(e, row.id, row.status, "status")
              }
            >
              <FaToggleOn
                size="20"
                data-testid={"toogle-user-list-on-" + row.id}
              />
            </span>
          )) ||
          (row.status === 0 && (
            <span
              href="#"
              className="icons text-danger pointer"
              onClick={(e) =>
                activeInactiveStatus(e, row.id, row.status, "status")
              }
            >
              <FaToggleOff
                size="20"
                data-testid={"toogle-user-list-off-" + row.id}
              />
            </span>
          ))
        );
      },
    },
  ];

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                pagination
                hideDeleteButton
                hideEditButton
                importExcelFile={importExcelFileFun}
                // fields={fuelLocationColumnFields}
                fields={finalFuelLocationColumnFields}
                apis={hemmApi}
                title="Data"
                // FilterBtn={FilterBtn}
                cref={cref}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={"/fuel-management-system/configuration/hemm-data"}
                primaryKey="id"
                getAllFilter={getAllFilter}
                // hideAddButton
                breadCrumb={[
                  {
                    name: "Fuel Management",
                    path: "/",
                  },
                  {
                    name: "Configuration",
                    path: "/fuel-management-system/configuration",
                  },
                  {
                    name: "HEMM / Bowser",
                    path: "#",
                  },
                ]}
                // childrenAfterAddButton={tabButtons}
                downloadExcel={exportHemmExcel}
                customTableTitle={"HEMM / Bowser"}
                editFunButton={onEdit}
                viewTitle={"HEMM / Bowser Details"}
                isNoTabOnPage
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea" data-testid="empty-data-list">
          <EmptyDataList
            pageLink={"/fuel-management-system/configuration/hemm-data"}
            title={"HEMM / Bowser"}
            hideAddButton
          />
        </div>
      </div>
    </div>
  );
};

export default HemmList;
