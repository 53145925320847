import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import { MannulAutoType, StockType } from "./DATA";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import Select from "../../../../components/Select/Select";
import FuelAdditionalFilter from "../FuelAdditionalFilter";
const StockFilter = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
  setForm,
  showFuelFilterOptions,
  hideBowser,
  hideWorkshop,
  hideTank
}) => { 
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        {/* <Form.Label>Created By</Form.Label> */}
        <Form.Label>Stock Date</Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              name="endDate"
              onChangeDate={onChange}
            />
            </Col>
          <Col md={12}>
            <Select
              onChange={onChange}
              value={form?.isMannualEntry}
              name="isMannualEntry"
              options={MannulAutoType}
              label={"Created Type"}
            />
          </Col>
          <Col md={12}>
            <Select
              onChange={onChange}
              value={form?.StockType}
              name="StockType"
              options={StockType}
              label={"Stock Type"}
            />
          </Col>
          {showFuelFilterOptions ? (
            <>
              <FuelAdditionalFilter
                form={form}
                onChange={onChange}
                setForm={setForm}
                hideBowser={hideBowser}
                hideTank={hideTank}
                hideWorkshop={hideWorkshop}
              />
            </>
          ) : (
            ""
          )}
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              type="button"
              data-testid="reset-filter-button"
              onClick={onResetFilter}
              className="secondaryBtn"
            >
              Clear all
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              data-testid="submit-filter-button"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default StockFilter;
