import {
  ADD_FUEL_DISPENSE,
  CLEAR_FUEL_DISPENSE_DATA,
} from "../actions/UserActions";

const INITIAL_STATE = {
  addFuelDispense: {},
};

export default function userData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_FUEL_DISPENSE:
      return {
        ...state,
        addFuelDispense: action.data,
      };
    case CLEAR_FUEL_DISPENSE_DATA:
      return {
        ...state,
        addFuelDispense: {}, // Clearing the fuel dispense data
      };
    default:
      return state;
  }
}
