import React from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../components/DateTimeCustom/CustomDateTime";
import Select from "../../components/Select/Select";
import { formCOpt, isDgmsReportableOpt, statusList } from "./DATA";
import CheckBoxField from "../../components/_form/CheckBoxField/CheckBoxField";
const SafetyManagementFilterForm = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
  contractorList,
  csisFilter,
  showFormCFilter,
}) => {
  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Form.Label>
          {!csisFilter ? "Incident Date" : "Accident Date"}
        </Form.Label>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              value={form?.startDate}
              dateFormat="YYYY-MM-DD"
              name="startDate"
              onChangeDate={onChange}
            />
          </Col>
          <Col md={6} className="rightCalendar">
            <CustomDatePicker
              value={form?.endDate}
              dateFormat="YYYY-MM-DD"
              name="endDate"
              onChangeDate={onChange}
            />
          </Col>
          {!csisFilter ? (
            <>
              <Col md={12}>
                <Select
                  onChange={onChange}
                  value={form?.status}
                  name="status"
                  options={Object.keys(statusList)?.map((key) => ({
                    label: statusList[key],
                    value: key,
                  }))}
                  label={"Status"}
                />
              </Col>
              <Col md={12}>
                <Select
                  onChange={onChange}
                  value={form?.isDgmsReportable}
                  name="isDgmsReportable"
                  options={Object.keys(isDgmsReportableOpt)?.map((key) => ({
                    label: isDgmsReportableOpt[key],
                    value: key,
                  }))}
                  label={"DGMS ID"}
                />
              </Col>
              {showFormCFilter ? (
                <Col md={12}>
                  <Select
                    onChange={onChange}
                    value={form?.pending_formc}
                    name="pending_formc"
                    options={Object.keys(formCOpt)?.map((key) => ({
                      label: formCOpt[key],
                      value: key,
                    }))}
                    label={"Form 4 - C"}
                  />
                </Col>
              ) : (
                ""
              )}

              <Col md={7}>
                <CheckBoxField
                  name={"is_investigated"}
                  checked={form?.is_investigated}
                  onChange={onChange}
                  label={"Investigation Involved"}
                  id={`is_investigated`}
                />
              </Col>
              <Col md={5}>
                <CheckBoxField
                  name={"is_capa"}
                  checked={form?.is_capa}
                  onChange={onChange}
                  label={"CAPA Involved"}
                  id={`is_capa`}
                />
              </Col>

              {/* <Col md={7}>
                <CheckBoxField
                  name={"pending_formc"}
                  checked={form?.pending_formc}
                  onChange={onChange}
                  label={"Pending Form 4 - C"}
                  id={`pending_formc`}
                />
              </Col>
              <Col md={7}>
                <CheckBoxField
                  name={"completed_formc"}
                  checked={form?.completed_formc}
                  onChange={onChange}
                  label={"Completed Form 4 - C"}
                  id={`completed_formc`}
                />
              </Col> */}
              {/* <Col md={5}>
                <CheckBoxField
                  name={"is_capa"}
                  checked={form?.is_capa}
                  onChange={onChange}
                  label={"CAPA Involved"}
                  id={`is_capa`}
                />
              </Col> */}
            </>
          ) : (
            ""
          )}
        </Row>
        <Row className="btnArea">
          <Col>
            <Button
              type="button"
              data-testid="reset-filter"
              onClick={onResetFilter}
              className="secondaryBtn"
            >
              Clear all
            </Button>
          </Col>
          <Col>
            <Button
              type="button"
              data-testid="submit-filter"
              onClick={(e) => {
                onSubmitFilter();
                setOpen(false);
              }}
              className="primaryBtn me-0"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default SafetyManagementFilterForm;
