import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Modal, Button } from "react-bootstrap";
import { isArray } from "lodash";
import { MultipleBarChartCard } from "../../../components/ChartCard/ChartCard";
import {
  multiLineChartDataDateTimeFormat,
  multiLineChartDataFormat,
} from "../../../components/Chart/Common/config";
import { SidebarData } from "../FuelManagement/DATA";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import MultiSelectWithCheckbox from "../../../components/SearchableSelect/MultiSelectCkeckOption";
import {
  getAllFuelSensorHemm,
  getAllHemmChartData,
  getAllTypeList,
  getFuelSensorMinMaxDate,
} from "../../../services/_fuelDispense/fuelDispense";
import { useForm } from "../../../_helpers/hooks";

import { getAllSites } from "../../../_services/SiteServices/SiteServices";
import Filter from "../../../Assets/Images/filter.svg";
import moment from "moment";
import PleaseSelect from "../../../components/Common/PleaseSelect";
import HsdConsumptionFilter from "./HsdConsumptionFilter";
import { IS_USER_SITE_HO, IS_USER_SITE_MINES, auth_user, capitalizeName } from "../../../_helpers/helper";
import { SelectedDateLable } from "../../SafetyManagementSystem/IncidentManagement/Dashboard/DATA";
import ZoomScreen from "../../../components/ZoomScreen/ZoomScreen";

const FuelSensorGraph = () => {
  // Define initial state for select components
  const [form, onChange, setForm] = useForm({
    subsideryId: "",
    siteId: "",
    equipment_type: "",
    make_data: "",
    capacity_data: "",
    model_data: "",
    eqptId: "",
    startDate: "",
    endDate: "",
  });

  const [hemmSerialOptions, setHemmSerialOptions] = useState([]);
  const [subsidery, setSubsidery] = useState([]);
  const [sites, setSites] = useState([]);
  const [optionsList, setOptionsList] = useState({});
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [finalSiteList, setFinalSiteList] = useState([]);
  const [hemmData, setHemmData] = useState([]);
  const [minMaxDate, setMinMaxDate] = useState({});

  async function getAllHemmChart() {
    if (form.eqptId === "" || form.eqptId === null) return;
    let filter = Object.keys(form)
      .filter((key) => {
        if (Array.isArray(form[key]) && form[key].length > 0) {
          return true; // Include if it's a non-empty array
        } else if (key === "startDate" || key === "endDate") {
          return true; // Include date_time
        }
        return false;
      })
      .map((key) => {
        if (key === "hemm_id") {
          // For hemm_id, extract data before '/'
          const values = form[key].map((value) => {
            const parts = value.split("/");
            return encodeURIComponent(parts[0]); // Get the data before '/'
          });
          return `${key}=${JSON.stringify(values)}`;
        } else if (Array.isArray(form[key])) {
          // For other arrays, encode values
          const values = form[key].map((value) => encodeURIComponent(value));
          return `${key}=${JSON.stringify(values)}`;
        } else {
          // For non-array values, encode the value
          return `${key}=${encodeURIComponent(form[key])}`;
        }
      })
      .join("&");
    let response = await getAllHemmChartData(filter);
    if (response?.status === 0) return;
    setData(response?.data);
  }

  useEffect(() => {
    getAllTypeList().then((response) => {
      if (!response?.data) return;
      let optionsObj = {};
      Object.keys(response?.data)?.forEach((d) => {
        optionsObj[d] = response?.data[d]?.map((o) => ({
          value: o.name,
          label: capitalizeName(o.name),
          site_id: o?.site_id,
          fms_vehicle_type: o?.eqptType,
          make: o?.make,
          model: o?.modelValue,
          capacity: o?.capacity,
        }));
      });
      setOptionsList(optionsObj);
    });
    getAllSites("filter[siteType]=1,2,3&filter[activeStatus]=active").then(
      (response) => {
        if (!response?.data) return;
        const siteData = response?.data?.filter((d) => d?.siteType === 3);
        let subsideryData = [];
        if (IS_USER_SITE_MINES()) {
          subsideryData = [
            {
              id: response.data?.length
                ? response.data?.[0]?.parentSite?.id
                : "",
              name: response.data?.length
                ? response.data?.[0]?.parentSite?.name
                : "",
            },
          ];
        } else {
          subsideryData = response?.data?.filter((d) => d?.siteType === 2);
        }
        setSubsidery(subsideryData);
        setFinalSiteList(siteData);
        setSites(siteData);
      }
    );
    getAllHemmData();
    getMinMaxDate();
  }, []);

  async function getAllHemmData(filter) {
    let response = await getAllFuelSensorHemm(filter);
    if (response?.status === 0) return;
    setHemmData(response?.data);
    response?.data?.forEach((element) => {
      const optionLabel = `${element?.hemm_id}/${element?.machine_serial}`;
      const existingOption = hemmSerialOptions.find(
        (option) => option.label === optionLabel
      );

      if (!existingOption) {
        setHemmSerialOptions((prevOptions) => [
          ...prevOptions,
          {
            label: optionLabel,
            value: optionLabel,
            site_id: element?.site_id,
          },
        ]);
      }
    });
  }

  useEffect(() => {
    getAllHemmChart();
  }, [form.hemm_id]);

  const handleClose = () => {
    getAllHemmChart();
    setShow(false);
  };

  const handleShow = () => {
    getAllHemmChart();
    setShow(true);
  };

  const resetFilter = () => {
    setForm({
      subsideryId: "",
      siteId: "",
      equipment_type: "",
      make_data: "",
      capacity_data: "",
      model_data: "",
      eqptId: "",
      startDate: "",
      endDate: "",
    });
    setReset(!reset);
  };

  useEffect(() => {
    handleClose();
  }, [reset]);

  useEffect(() => {
    setFinalSiteList(
      form?.workshopId
        ? sites?.filter((d) => d?.parentSiteId === +form?.subsideryId)
        : sites
    );
  }, [form?.subsideryId, sites]);

  const finalData =
    form?.eqptId?.length && multiLineChartDataDateTimeFormat(data);
  const getMinMaxDate = async () => {
    let result = await getFuelSensorMinMaxDate();
    setMinMaxDate(result?.data);
  };
  const checkMineObj = () => {
    let siteDataObj = {};
    if (IS_USER_SITE_HO()) return siteDataObj;
    const siteObj = sites?.find((d) => d.id === auth_user()?.siteId) || {};
    siteDataObj = {
      siteId: IS_USER_SITE_MINES() ? siteObj?.id : "",
      subsideryId: IS_USER_SITE_MINES()
        ? siteObj?.parentSite?.id
        : auth_user()?.siteId,
    };
    return siteDataObj;
  };
  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        ...checkMineObj(),
      };
    });
  }, [sites]);
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container style={{ maxWidth: "100%" }}>
            <div className="reportCard fuelSensor">
              <Row>
                <Col md={7}>
                  <h3 className={`title mb-0 titleWtoutTab"}`}>
                    Fuel Sensor Graph
                  </h3>
                </Col>
                <Col md={4}>
                  <SelectedDateLable form={form} minMaxDate={minMaxDate} />
                </Col>
                <Col md={1}>
                  <div className="dashboardHeader">
                    <div className="dashboardOptions">
                      <div
                        className="dashboardFilter"
                        onClick={handleShow}
                        data-testid="handleShowId"
                      >
                        <img src={Filter} className="img-fluid" alt="filter" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dashboardWrapper">
              <Row>
                <Col md={"12"} id={`zoom-sensor-graph`}>
                  <Card className="chartCard chartCardBtmSpace">
                  <Card.Header className="d-flex justify-content-between align-items-center">
                      <span>Fuel Sensor</span>
                      <ZoomScreen
                        id={`zoom-sensor-graph`}
                        cardSizeClass={"col-md-12"}
                      />
                    </Card.Header>
                    <Card.Body className="pieCartOuter">
                      {form?.eqptId &&
                      form?.eqptId?.length > 0 &&
                      finalData?.categoryData?.length > 0 ? (
                        <MultipleBarChartCard
                          yAxisName={"(L)"}
                          height={"500px"}
                          category={finalData?.categoryData}
                          seriesData={
                            isArray(finalData.data) &&
                            finalData?.data.map((d) => ({
                              ...d,
                            }))
                          }
                          customFuelToolTip
                        />
                      ) : (
                        <div className="text-center">
                          <PleaseSelect type={"HEMM ID"} />
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div> 
      <HsdConsumptionFilter
        onChange={onChange}
        show={show}
        subsidery={subsidery}
        sites={finalSiteList}
        optionsList={optionsList}
        form={form}
        resetFun={resetFilter}
        applyFun={handleClose}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FuelSensorGraph;
