import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Container, Button } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import FormInputText from "../../components/FormInputText/FormInputText";
import SafetySideBar from "../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MessagePopup from "../../components/MessagePopup/MessagePopup";
import { useForm } from "../../_helpers/hooks";
import {
  permissionInitialForm,
  siteTypeObj,
  userFormRules,
  userInitialForm,
  userLabel,
} from "./DATA";
import CustomDatePicker from "../../components/DateTimeCustom/CustomDateTime";
import { StepButton } from "../../components/_button/StepButton";
import toastr from "toastr";
import { Validation } from "../../_helpers/validation/Validation";
import { UserService } from "../../_services/UserService/UserService";
import PermissionsForm from "./PermissionsForm";
import Select from "../../components/Select/Select";
import { getAllSites } from "../../_services/SiteServices/SiteServices";
import {
  getAllContractor,
  getAllContractorByNit,
} from "../../services/_contractorPerformance/contractorPerformance";
import { calaculateDatesDifference } from "../../_helpers/dateHelper";
import {
  isContractorModuleAdmin,
  isLoggedSite,
  permissionCheck,
} from "../../_services/Auth/helper";
import { handleURL } from "../../_helpers/commonHelper";
import { auth_user } from "../../_helpers/helper";

const UserAddEdit = () => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({
    ...userInitialForm,
    roleId: isContractorModuleAdmin() ? 3 : "",
    siteId: isContractorModuleAdmin() ? isLoggedSite() : "",
  });
  const [permissionForm, onChangePermission, setPermissionForm] = useForm({
    ...permissionInitialForm,
  });
  const [error, setError] = useState({});
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [finalSiteList, setFinalSiteList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [contractorList, setContractorList] = useState([]);

  let navigate = useNavigate();
  const params = useParams("id");
  const { pathname } = useLocation();
  let module = handleURL(pathname);

  let UserData = auth_user();

  useEffect(() => {
    if (module.module_admin_user_create === 1) {
      setForm((prev) => {
        return { ...prev, roleId: 4 };
      });
    }
    if (UserData?.site?.siteType === 3) {
      setForm((prev) => {
        return { ...prev, siteType: 3 };
      });
    }
    if (UserData?.site?.siteType === 2) {
      setForm((prev) => {
        return { ...prev, siteType: 2 };
      });
    }
  }, [module.module_admin_user_create]);

  const allSites = async () => {
    const response = await getAllSites("filter[siteType]=1,2,3");
    if (!response?.status) return;
    setSiteList(
      response?.data?.map((s) => {
        return {
          ...s,
          name:
            s?.siteType === 3
              ? `${s?.name} ( ${s?.parentSite?.name} )`
              : s?.name,
        };
      })
    );
  };

  const allRoles = async () => {
    const response = await UserService.getAllRoles();
    if (!response?.status) return;
    setRoleList(response?.data);
  };

  const allContractor = async () => {
    const response = await getAllContractorByNit();
    if (!response) return;
    setContractorList(response);
  };

  useEffect(() => {
    setFinalSiteList(
      siteList?.filter((d) => d?.siteType === +form?.siteType) || []
    );
  }, [form?.siteType, siteList]);

  useEffect(() => {
    allSites();
    allRoles();
    allContractor();
  }, []);

  const onChange = ({ target: { name, value } }) => {
    if (name === "roleId" && value === 4) {
      setPermissionForm({ ...permissionForm, contractor: false });
    } else if (name === "roleId" && value === 3) {
      setPermissionForm({ ...permissionInitialForm, contractor: true });
    } else if (name === "roleId") {
      setPermissionForm({ ...permissionInitialForm });
    }
    setForm((prev) => {
      if (name === "roleId") {
        prev["siteType"] = "";
        prev["siteId"] = "";
      }
      if (name === "siteType") prev["siteId"] = "";
      if (name === "roleId" && value === 3) prev["siteType"] = 3;
      if (name === "roleId" && value === 1) prev["siteType"] = 1;
      return { ...prev, [name]: value };
    });
  };

  const userById = useCallback(async () => {
    const response = await UserService.getUserByID(params?.id);
    if (!response?.status) return;

    setForm((prev) => ({
      ...prev,
      ...response?.data,
      siteType: +siteList?.find((d) => prev.siteId == d.id)?.siteType,
    }));
    setPermissionForm((prev) => ({
      ...prev,
      ...response?.data?.userPermission,
    }));
  }, [params?.id, setForm, setPermissionForm, siteList]);

  useEffect(() => {
    if (!params?.id) return;
    userById();
  }, [siteList, params?.id, userById]);

  const handleSubmit = async () => {
    let errorObj = Validation(
      { ...form, contractor_id: form?.contractor_id ?? "" },
      userFormRules?.map((d) => {
        if (d.field_name === "contractor_id") {
          return { ...d, isRequired: form?.roleId === 3 ? true : false };
        } else return d;
      })
    );

    if (form?.dob) {
      let age = calaculateDatesDifference(form?.dob);
      if (age < 18) {
        errorObj.dob = "DOB should be at least 18 years.";
      }
    }

    setError(errorObj);

    if (Object.values(errorObj).filter((d) => d !== "").length) return;

    let contractorPermission = {};
    for (const key in permissionForm) {
      if (permissionForm.hasOwnProperty(key) && permissionForm[key] === true) {
        contractorPermission[key] = true;
      }
    }
    contractorPermission = {
      ...contractorPermission,
      contractor: true,
    };
    let permissions = {};
    if (form?.roleId === 3) permissions = contractorPermission;
    else permissions = permissionForm;

    const formObj = {
      ...form,
      contractor_id: form?.contractor_id ? form?.contractor_id : null,
      userPermission: permissions,
    };

    const response = !params?.id
      ? await UserService.addUser(formObj)
      : await UserService.updateUser(params?.id, formObj);

    if (!response?.status || response?.status === 401)
      return !response?.status && toastr.error(response?.message);
    setShow(true);
    setMessagePopupType(false);
  };

  function handleClick() {
    setShow(false);
    // navigate("/user-management/user-list");
    // if (isContractorModuleAdmin()) {
    //   navigate("/contractor-management/user-list");
    // }
    navigate(module.back || "/user-management/user-list");
  }

  const closeFun = () => {
    setShow(true);
    setMessagePopupType(true);
  };

  return (
    <div
      className={
        module.module_admin_user_create === 1
          ? "sidebarWrapper"
          : "contentArea mt-5 pt-4"
      }
    >
      {module.module_admin_user_create === 1 ? (
        <SafetySideBar SidebarData={module.sidebar} />
      ) : (
        <></>
      )}
      <div
        className={module.module_admin_user_create === 1 ? "content" : ""}
        id="page-content-wrapper"
      >
        <div
          className={module.module_admin_user_create === 1 ? "contentArea" : ""}
        >
          <div
            className={
              module.module_admin_user_create === 1 ? "creationWrapper" : ""
            }
          >
            {" "}
            <Container fluid>
              <h3 className="title mb-3">{`${
                params?.id ? "Edit" : "Add"
              } User`}</h3>
              <div className=" reportCard">
                <Form>
                  <Row>
                    <>
                      <Col sm={4}>
                        <Select
                          onChange={onChange}
                          value={form?.roleId}
                          name="roleId"
                          options={roleList}
                          error={error?.roleId}
                          label={userLabel?.roleId}
                          KEY_label={"title"}
                          KEY_value={"id"}
                          // disabled={isContractorModuleAdmin()}
                          disabled={!permissionCheck(["super_admin"])}
                        />
                      </Col>
                      {form?.roleId === 3 && (
                        <Col sm={4}>
                          <Select
                            onChange={onChange}
                            value={form?.contractor_id}
                            name="contractor_id"
                            options={contractorList}
                            error={error?.contractor_id}
                            label={userLabel?.contractor_id}
                            KEY_label={"contractor"}
                            KEY_value={"id"}
                          />
                        </Col>
                      )}
                      <Col sm={4}>
                        <Select
                          onChange={onChange}
                          value={+form?.siteType}
                          name="siteType"
                          options={Object.keys(siteTypeObj)
                            .filter(
                              (key) =>
                                (UserData?.site?.siteType === 2 &&
                                  key !== "1") ||
                                UserData?.site?.siteType !== 2
                            )
                            ?.map((keys) => ({
                              label: siteTypeObj[keys],
                              value: +keys,
                            }))}
                          error={error?.siteType}
                          disabled={
                            UserData?.site?.siteType === 3
                              ? true
                              : +form?.roleId !== 4
                          }
                          label={userLabel?.siteType}
                        />
                      </Col>
                      <Col sm={4}>
                        <Select
                          onChange={onChange}
                          value={form?.siteId}
                          name="siteId"
                          options={finalSiteList}
                          error={error?.siteId}
                          label={userLabel?.siteId}
                          KEY_label={"name"}
                          KEY_value={"id"}
                          disabled={
                            module.module_admin_user_create === 1
                              ? false
                              : isContractorModuleAdmin()
                          }
                        />
                      </Col>
                    </>
                    <Col md={4}>
                      <FormInputText
                        type="input"
                        placeHolder="Enter here"
                        label={userLabel?.firstName}
                        onChange={onChange}
                        name="firstName"
                        value={form?.firstName}
                        error={error?.firstName}
                      />
                    </Col>
                    <Col md={4}>
                      <FormInputText
                        type="input"
                        placeHolder="Enter here"
                        label={userLabel?.lastName}
                        onChange={onChange}
                        name="lastName"
                        value={form?.lastName}
                        error={error?.lastName}
                      />
                    </Col>
                    <Col md={4}>
                      <FormInputText
                        type="email"
                        placeHolder="Enter here"
                        label={userLabel?.email}
                        onChange={onChange}
                        name="email"
                        value={form?.email}
                        error={error?.email}
                      />
                    </Col>
                    <Col md={4}>
                      <Form.Label>{userLabel?.dob}</Form.Label>
                      <CustomDatePicker
                        value={form?.dob}
                        name="dob"
                        onChange={onChange}
                        error={error?.dob}
                      />
                    </Col>
                    <Col md={4}>
                      <FormInputText
                        type="input"
                        placeHolder="Enter here"
                        label={userLabel?.contactNumber}
                        onChange={onChange}
                        name="contactNumber"
                        value={form?.contactNumber}
                        error={error?.contactNumber}
                      />
                    </Col>
                  </Row>
                  {!params?.id &&
                    form?.roleId &&
                    (form?.roleId === 4 || form?.roleId === 3) && (
                      <>
                        <PermissionsForm
                          form={form}
                          onChange={onChangePermission}
                          moduleType={module.permission}
                        />
                      </>
                    )}
                </Form>
                <div className="btnSec mb-4">
                  <Button onClick={closeFun} className="secondaryBtn">
                    Close
                  </Button>
                  <StepButton
                    tab={3}
                    tabFun={""}
                    onSubmit={handleSubmit}
                    nextStapeFun={""}
                    hideSaveBtn
                  />
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <MessagePopup
        messagePopupType={messagePopupType}
        show={show}
        setShow={setShow}
        closePopup={handleClick}
      />
    </div>
  );
};

export default UserAddEdit;
