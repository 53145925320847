import React, { useEffect, useRef, useState } from "react";
import "../../../FuelManagementSystem/fuelmgmt.scss";

import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useForm } from "../../../../_helpers/hooks";
import { initialFilterForm, screenPage } from "./DATA";
import { getAllAreaOfOperation } from "../../../../_services/_master/Activity/ehs/ehsAreaOfOperationServices";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";
import {
  IS_MOBILE,
  IS_USER_SITE_HO,
  IS_USER_SITE_MINES,
  IS_USER_SITE_RO,
  auth_user,
} from "../../../../_helpers/helper";
import SafetySideBar from "../../SafetySideBar/SafetySideBar";
import DashboardFilter from "./DashboardFilter";
import IncidentDashboard from "./IncidentDashboard";
import IncidentDashboard2 from "./IncidentDashboard2";
import IncidentDashboard3 from "./IncidentDashboard3";
import { SidebarData, mobileSidebarData } from "../DATA";
import FilesExport from "../../../../components/Common/FilesExport";

const IncidentManagementDashboard = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const cref = useRef(null);
  const [form, onChange, setForm] = useForm(initialFilterForm);
  const [area, setArea] = useState([]);
  const [sites, setSites] = useState([]);
  const [finalSiteList, setFinalSiteList] = useState([]);
  const [subsidery, setSubsidery] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [minMaxDate, setMinMaxDate] = useState({});

  const autoSelectSitesForFilterForm = (filterForm) => {
    if (IS_USER_SITE_HO()) return;
    const siteObj = sites?.find((d) => d.id === auth_user()?.siteId) || {};
    setForm((prev) => {
      let siteDataObj = {};
      siteDataObj = {
        siteId: IS_USER_SITE_MINES() ? siteObj?.id : "",
        subsideryId: IS_USER_SITE_MINES()
          ? siteObj?.parentSite?.id
          : auth_user()?.siteId,
      };
      filterForm === "initialFilterForm" &&
        Object.assign(siteDataObj, { ...prev, ...siteDataObj });
      return {
        ...siteDataObj,
      };
    });
  };

  useEffect(() => {
    getAllSites("filter[siteType]=1,2,3&filter[activeStatus]=active").then(
      (response) => {
        if (!response?.data) return;
        const siteData = response.data.filter((d) => d?.siteType === 3);
        setSites(siteData);
        setFinalSiteList(siteData);
        let subsideryData = [];
        if (IS_USER_SITE_MINES()) {
          subsideryData = [
            {
              id: response.data?.length
                ? response.data?.[0]?.parentSite?.id
                : "",
              name: response.data?.length
                ? response.data?.[0]?.parentSite?.name
                : "",
            },
          ];
        } else {
          subsideryData = response.data.filter((d) => d?.siteType === 2);
        }
        setSubsidery(subsideryData);
      }
    );

    getAllAreaOfOperation().then((response) => {
      if (!response?.data) return;
      setArea(response.data);
    });
  }, []);

  useEffect(() => {
    autoSelectSitesForFilterForm("initialFilterForm");
  }, [sites]);

  useEffect(() => {
    setFinalSiteList(
      form?.subsideryId
        ? sites?.filter((d) => d?.parentSiteId === +form?.subsideryId)
        : sites
    );
  }, [form?.subsideryId, sites]);

  useEffect(() => {
    const checkScreen = screenPage?.find((obj) => obj.screen === +id);
    if (!checkScreen) navigate("/");
  }, [id, navigate]);

  const resetFun = () => {
    setForm(initialFilterForm);
    setIsReset(true);
    autoSelectSitesForFilterForm();
  };

  const applyFun = () => {
    cref?.current?.dashboardDataFun();
  };

  useEffect(() => {
    if (!isReset) return;
    if (isReset) cref?.current?.dashboardDataFun();
    setIsReset(false);
  }, [isReset]);

  return (
    <>
      <div className={IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}>
        <SafetySideBar
          SidebarData={IS_MOBILE() ? mobileSidebarData : SidebarData}
          // pageTitle={"Dashboard"}
          moduleTitle={"Safety Management"}
          goBack={() => navigate(-1)}
        />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            {/* <iframe
              src={`${URL_CONFIG.AWS_QUICKSIGHT_URL}e74e8efc-fb50-44c5-b9d4-c79017eb9818?directory_alias=cil-dev-si-bi`}
              title="Loading..."
            /> */}
            <Container fluid>
              <DashboardFilter
                title={"Incident Dashboard"}
                screens={screenPage}
                isActive={id}
                area={area}
                subsidery={subsidery}
                sites={finalSiteList}
                form={form}
                onChange={onChange}
                resetFun={resetFun}
                applyFun={applyFun}
                minMaxDate={minMaxDate}
              />
              {+id === 1 && (
                <IncidentDashboard
                  cref={cref}
                  filterForm={form}
                  screen={+id}
                  setMinMaxDate={setMinMaxDate}
                />
              )}
              {+id === 2 && (
                <IncidentDashboard2
                  cref={cref}
                  filterForm={form}
                  setMinMaxDate={setMinMaxDate}
                />
              )}
              {+id === 3 && (
                <IncidentDashboard3
                  cref={cref}
                  filterForm={form}
                  setMinMaxDate={setMinMaxDate}
                />
              )}
              {+id === 4 && (
                <IncidentDashboard
                  cref={cref}
                  filterForm={form}
                  isScreenFour
                  screen={+id}
                  setMinMaxDate={setMinMaxDate}
                />
              )}
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncidentManagementDashboard;
