import React from "react";
import { Button, Col, Row } from "react-bootstrap";

const FilterFormBtn = ({ onResetFilter, onSubmitFilter, setOpen }) => {
  return (
    <Row className="btnArea mt-3">
      <Col>
        <Button
          type="button"
          data-testid="reset-filter"
          onClick={onResetFilter}
          className="secondaryBtn"
        >
          Clear all
        </Button>
      </Col>
      <Col className="justify-content-end d-flex">
        <Button
          type="button"
          onClick={(e) => {
            onSubmitFilter();
            setOpen(false);
          }}
          className="primaryBtn me-0"
          data-testid="submit-filter-button"
        >
          Apply
        </Button>
      </Col>
    </Row>
  );
};

export default FilterFormBtn;
