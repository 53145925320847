import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CreateWebform from "../../../components/_form/Webform/CreateWebform";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  createContractorPerformance,
  getAllContractor,
  updateContractorPerformance,
  getContractorById,
  getNitList,
  getNitById,
} from "../../../services/_contractorPerformance/contractorPerformance";
import {
  DDMMYYYYFormat,
  checkDatesIsBetween,
} from "../../../_helpers/dateHelper";
import toastr from "toastr";
import {
  availabilityGraderInitialObj,
  availabilityWaterTankerInitialObj,
  availableInitialData,
  availableInitialDataError,
  contractorPerformanceInitialForm,
  formFieldDataError,
  utilizedGraderInitialObj,
  utilizedInitialData,
  utilizedInitialDataError,
  sections,
  availabilityPayLoaderInitialObj,
  utilizedPayLoaderInitialObj,
  utilizedWaterTankerInitialObj,
  utilizedCrusherInitialObj,
  availabilityCrusherInitialObj,
} from "./DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import MessagePopup from "../../../components/MessagePopup/MessagePopup";
import Select from "../../../components/Select/Select";
import StatusPopup from "../../../components/MessagePopup/StatusPopup";
import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import { disableFutureDate } from "../../../_helpers/dateHelper";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../DATA";
import { capitalizeName } from "../../../_helpers/helper";
import { checkContractStatus } from "../../../_helpers/helper";
import { getAllPoList } from "../../../services/_poList/poList";
import { findElementByArray } from "../../../_helpers/commonHelper";

const ContractorPerformanceAdd = () => {
  const [form, setForm] = useState({ ...contractorPerformanceInitialForm });
  const [availableForm, setAvailableForm] = useState(availableInitialData);
  const [availableError, setAvailableError] = useState(
    availableInitialDataError
  );
  const [utilizedForm, setUtilizedForm] = useState(utilizedInitialData);
  const [utilizedError, setUtilizedError] = useState(utilizedInitialDataError);
  const [formFieldError, setFormFieldError] = useState(formFieldDataError);
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [showStatusPopUp, setShowStatusPopUp] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const [error, setError] = useState([]);
  const [messagePopupType, setMessagePopupType] = useState(false);
  const [contractorList, setContractorList] = useState([]);
  const [nitList, setNitList] = useState([]);
  const [recordId, setRecordId] = useState("");
  const [contractorStatus, setContractorStatus] = useState("");
  const [poList, setPoList] = useState([]);
  const [contractorData, setContractorData] = useState("");
  const [allSites, setAllSites] = useState([]);
  const [title, setTitle] = useState("submitted");
  const param = useParams();
  const [fieldData, setFieldData] = useState({});
  const [equipmentAvailable, setEquipmentAvailable] = useState(false);
  const [notAppliedSpecs, setNotAppliedSpecs] = useState([]);
  const getData = useCallback(async () => {
    const response = await RestMethod.GET(
      "/contractor-performance/" + param?.id
    );
    if (response?.data?.status && response?.data?.data?.status !== "draft") {
      navigate(
        "/contractor-management/contractor-performance/my-contractor-performance"
      );
    }
    const availabilityData = response?.data?.data?.contractorPerformanceData
      ?.filter((obj) => obj.type === "available")
      .map((d) => {
        delete d.contractor_performance_id;
        delete d.createdAt;
        delete d.deleted;
        delete d.createdBy;
        delete d.updatedAt;
        delete d.updatedBy;
        return d;
      });

    const utilizationData = response?.data?.data?.contractorPerformanceData
      ?.filter((obj) => obj.type === "utilized")
      .map((d) => {
        delete d.contractor_performance_id;
        delete d.createdAt;
        delete d.deleted;
        delete d.createdBy;
        delete d.updatedAt;
        delete d.updatedBy;
        return d;
      });

    setForm({ ...response?.data?.data });
    setAvailableForm(availabilityData);
    setUtilizedForm(utilizationData);
  }, [fieldData, param?.id]);

  let navigate = useNavigate();

  const closeMessagePopup = () => {
    setShowMessagePopUp(false);
    setShowStatusPopUp(false);
    setMessagePopupType(false);

    navigate(
      "/contractor-management/contractor-performance/my-contractor-performance"
    );
  };

  const hideMessagePopup = () => {
    setShowMessagePopUp(false);
    setMessagePopupType(false);
  };

  const closeStatusPopup = () => {
    setShowMessagePopUp(false);
    setShowStatusPopUp(false);
    setMessagePopupType(false);

    navigate("/contractor-management/contractor-performances/edit/" + recordId);
  };

  const hideStatusPopup = () => {
    setShowStatusPopUp(false);
    setMessagePopupType(false);
  };

  const closeFun = () => {
    setShowMessagePopUp(true);
    setMessagePopupType(true);
  };

  let errorObjFinal;
  const availableOnChange = (e, idx) => {
    let { name, value } = e.target;
    setAvailableForm((prev) => {
      return prev?.map((d, i) => {
        if (i !== idx) {
          return d;
        } else {
          availableError[idx] = { ...availableError[idx], [name]: "" };
          return { ...d, [name]: value };
        }
      });
    });
  };


  const onChange = (e, idx) => {
    const { name, value } = e.target;
    name === "contractor_id" && setNitList([]);
    const nit_id = name === "contractor_id" ? "" : form?.nit_id;
    formFieldError[name] = "";
    setForm({ ...form, [name]: value, nit_id });
  };

  const onChangeNit = (e, idx) => {
    const { name, value } = e.target;
    formFieldError[name] = "";
    setForm({ ...form, [name]: value });
  };

  const utilizedOnChange = (e, idx) => {
    let { name, value } = e.target;
    setUtilizedForm((prev) => {
      return prev?.map((d, i) => {
        if (i !== idx) {
          return d;
        } else {
          utilizedError[idx] = { ...utilizedError[idx], [name]: "" };
          return { ...d, [name]: value };
        }
      });
    });
  };

  const validationForLength = (row, name) => {
    let error = { ...row };

    if (
      (row.committed_count === "" || row.committed_count === null) &&
      row?.isRequired &&
      name === "submit"
    ) {
      error = { ...error, committed_count: "required" };
    } else {
      if (row.committed_count < 0) {
        error = { ...error, committed_count: "min 0" };
      } else if (row.committed_count > 1000) {
        error = { ...error, committed_count: "max 1000" };
      } else {
        error = { ...error, committed_count: "" };
      }
    }
    if (
      (row.shift1_available === "" || row.shift1_available === null) &&
      row?.isRequired &&
      name === "submit"
    ) {
      error = { ...error, shift1_available: "required" };
    } else {
      if (row.shift1_available < 0) {
        error = { ...error, shift1_available: "min 0" };
      } else if (row.shift1_available > 1000) {
        error = { ...error, shift1_available: "max 1000" };
      } else {
        error = { ...error, shift1_available: "" };
      }
    }
    if (
      (row.shift2_available === "" || row.shift2_available === null) &&
      row?.isRequired &&
      name === "submit"
    ) {
      error = { ...error, shift2_available: "required" };
    } else {
      if (row.shift2_available < 0) {
        error = { ...error, shift2_available: "min 0" };
      } else if (row.shift2_available > 1000) {
        error = { ...error, shift2_available: "max 1000" };
      } else {
        error = { ...error, shift2_available: "" };
      }
    }
    if (
      (row.shift3_available === "" || row.shift3_available === null) &&
      row?.isRequired &&
      name === "submit"
    ) {
      error = { ...error, shift3_available: "required" };
    } else {
      if (row.shift3_available < 0) {
        error = { ...error, shift3_available: "min 0" };
      } else if (row.shift3_available > 1000) {
        error = { ...error, shift3_available: "max 1000" };
      } else {
        error = { ...error, shift3_available: "" };
      }
    }
    return error;
  };

  const onSubmit = async (e) => {
    const { name } = e.target;
    let response;
    if (name === "save") {
      form.status = "draft";
    }
    if (name === "submit") {
      form.status = "submitted";
    }

    const formData = [...availableForm, ...utilizedForm];
    const finalObj = { ...form, contractor_performance_data: formData };
    let finalAvailableForm = availableForm;
    finalAvailableForm = finalAvailableForm.map((obj) =>
      !obj.committed_count && form?.nit_id
        ? { ...obj, isRequired: false }
        : { ...obj, isRequired: true }
    );
    const availableErr = finalAvailableForm.map((d, idx) =>
      validationForLength(d, name)
    );

    setAvailableError(availableErr);

    let finalUtilizedForm = utilizedForm;
    finalUtilizedForm = finalUtilizedForm.map((obj) =>
      !obj.committed_count && form?.nit_id
        ? { ...obj, isRequired: false }
        : { ...obj, isRequired: true }
    );
    const utilizedErr = finalUtilizedForm.map((d, idx) =>
      validationForLength(d, name)
    );
    setUtilizedError(utilizedErr);

    if (finalObj.contractor_id === "") {
      formFieldError.contractor_id = "Contractor is required";
    }
    if (finalObj.nit_id === "") {
      formFieldError.nit_id = "Nit Number is required";
    }

    if (finalObj.input_date === "") {
      formFieldError.input_date = "Date is required";
    }
    if (finalObj.po_id === "") {
      formFieldError.po_id = "PO is required";
    }
    errorObjFinal = [...availableErr, ...utilizedErr];

    setError(errorObjFinal);
    if (Object.values(formFieldError).filter((d) => d !== "").length > 0) {
      return;
    }
    let checkResult = true;

    if (!checkResult) return;

    if (param?.id) {
      delete finalObj.contractorPerformanceData;
      response = await updateContractorPerformance(finalObj, param?.id);
    } else {
      response = await createContractorPerformance(finalObj);
    }
    if (!response?.data?.status) {
      toastr.error(response?.data?.message);
    } else {
      if (name === "submit") {
        setShowMessagePopUp(true);
        setTitle("submitted");
      } else {
        setRecordId(response?.data?.data?.id);
        setShowMessagePopUp(true);
        setTitle("saved");
      }
    }
  };

  const getAll = async () => {
    const allContractorList = await getAllContractor();
    const uniqueContractorList = allContractorList
      ?.filter((ele) => ele?.contractor?.status)
      ?.reduce((uniqueList, d) => {
        const contractorId = String(d?.contractor?.id);
        const idExists = uniqueList.some((item) => item.value === contractorId);
        if (!idExists) {
          uniqueList.push({
            value: contractorId,
            label: d?.contractor?.contractor,
          });
        }
        return uniqueList;
      }, []);
    if (JSON.parse(localStorage.getItem("auth_user"))?.roleId === 3) {
      setForm((prev) => ({
        ...prev,
        contractor_id: JSON.parse(localStorage.getItem("auth_user"))
          .contractor_id,
      }));
    }

    setContractorList(uniqueContractorList);
  };

  const getAllNitList = async (contractor_id) => {
    const allNitList = await getNitList(contractor_id);
    if (allNitList?.status) {
      setNitList(
        allNitList?.data
          ?.filter((ele) => ele?.status)
          ?.map((d) => {
            return {
              endDate: d?.endDate && DDMMYYYYFormat(d?.endDate),
              value: d.id,
              label: d.nit,
              grader: d.grader,
              startDate: d.startDate && DDMMYYYYFormat(d.startDate),
              excavator: d.excavator,
              tipper: d.tipper,
              drill: d.drill,
              dozer: d.dozer,
              waterTanker: d.waterTanker,
              payLoader: d.payLoader,
              crusher: d.crusher,
            };
          })
      );
      setAllSites(allNitList.data);
    }
  };

  const getPo = async () => {
    let res = await getAllPoList();
    let poData = res.data.map((d) => {
      return {
        nit_id: d?.nit_id,
        id: d?.id,
        label: d?.po,
        nit_label: d?.nitNumber?.nit,
      };
    });
    setPoList(poData);
  };

  useEffect(() => {
    setAvailableError([]);
    setUtilizedError([]);
    setFormFieldError({});
    getAll();
    getPo();
  }, []);

  useEffect(() => {
    if (!param?.id) return;
    getData();
  }, [getData, param?.id]);

  const getContractor = async () => {
    let contractor = await getContractorById(form?.contractor_id);
    if (contractor?.status) {
      setContractorData(contractor.data);
    }
  };
  const getNit = async () => {
    let nit = await getNitById(form?.nit_id);
    if (nit?.status)
      setContractorData((prevData) => ({
        ...prevData,
        poDetails: nit.data.poDetails,
        new_nit_number: nit.data.nit,
        new_tender_id: nit.data.tender_id,
        nit_mine: nit.data.siteData?.name,
        nit_subsidiary: nit.data.siteData?.parentSite?.name,
        section: nit?.data?.sectionData,
        grader: nit?.data?.grader,
        payLoader: nit?.data?.payLoader,
        waterTanker: nit?.data?.waterTanker,
        crusher: nit?.data?.crusher,
      }));
  };

  useEffect(() => {
    if (form?.contractor_id) {
      getContractor();
      getAllNitList(form?.contractor_id);
    }
  }, [form?.contractor_id]);

  useEffect(() => {
    if (form?.nit_id) {
      getNit();
    }
    if (!form?.nit_id) return;
    setContractorStatus(checkContractStatus(nitList, form?.nit_id));
  }, [form?.nit_id]);

  useEffect(() => {
    if (form.nit_id) {
      let site = allSites?.find((obj) => obj.id === form.nit_id);
      form.siteId = site?.site_id;
    }
  }, [form]);

  useEffect(() => {
    if (param?.id) return;
    let nit = [];
    if (form.input_date) {
      nit = nitList.filter((d) => {
        return d.value === form.nit_id;
      });

      let dateCheck = checkDatesIsBetween(
        DDMMYYYYFormat(form?.input_date),
        nit[0]?.startDate,
        nit[0]?.endDate
      );
      if (!dateCheck) {
        setShowStatusPopUp(true);
        setInvalidData(true);
      }
    }
  }, [form.input_date]);

  const closeInvalidPopUp = () => {
    setShowStatusPopUp(false);
    form.input_date = "";
    setInvalidData(false);
  };

  useEffect(() => {
    if (param?.id) return;

    setAvailableError(availableInitialDataError);
    setUtilizedError(utilizedInitialDataError);
    let selectedNit = nitList.filter((d) => {
      return d.value === form?.nit_id;
    });
    if (Array.isArray(selectedNit) && selectedNit.length)
      setNotAppliedSpecs(
        Object.keys(selectedNit[0]).filter(
          (key) => selectedNit[0][key] === null
        )
      );

    if (Array.isArray(selectedNit) && selectedNit.length) {
      setEquipmentAvailable(true);
      setAvailableForm((prev) => {
        const finalList = prev.map((obj) => {
          if (obj?.equipment_name === "Excavator") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].excavator !== null
                  ? selectedNit[0].excavator
                  : "",
            };
          }
          if (obj?.equipment_name === "Tipper") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].tipper !== null ? selectedNit[0].tipper : "",
            };
          }
          if (obj?.equipment_name === "Drill") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].drill !== null ? selectedNit[0].drill : "",
            };
          }
          if (obj?.equipment_name === "Dozer") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].dozer !== null ? selectedNit[0].dozer : "",
            };
          }
          if (obj?.equipment_name === "Water Tanker") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].waterTanker !== null
                  ? selectedNit[0].waterTanker
                  : "",
            };
          }
          if (obj?.equipment_name === "Grader") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].grader !== null ? selectedNit[0].grader : "",
            };
          }
          if (obj?.equipment_name === "Pay Loader") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].payLoader !== null
                  ? selectedNit[0].payLoader
                  : "",
            };
          }
          if (obj?.equipment_name === "Crusher") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].crusher !== null ? selectedNit[0].crusher : "",
            };
          }
          return obj;
        });
        return finalList;
      });
      setUtilizedForm((prev) => {
        const finalList = prev.map((obj) => {
          if (obj?.equipment_name === "Water Tanker") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].waterTanker !== null
                  ? selectedNit[0].waterTanker
                  : "",
            };
          }
          if (obj?.equipment_name === "Excavator") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].excavator !== null
                  ? selectedNit[0].excavator
                  : "",
            };
          }
          if (obj?.equipment_name === "Tipper") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].tipper !== null ? selectedNit[0].tipper : "",
            };
          }
          if (obj?.equipment_name === "Drill") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].drill !== null ? selectedNit[0].drill : "",
            };
          }
          if (obj?.equipment_name === "Dozer") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].dozer !== null ? selectedNit[0].dozer : "",
            };
          }
          if (obj?.equipment_name === "Grader") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].grader !== null ? selectedNit[0].grader : "",
            };
          }
          if (obj?.equipment_name === "Pay Loader") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].payLoader !== null
                  ? selectedNit[0].payLoader
                  : "",
            };
          }
          if (obj?.equipment_name === "Crusher") {
            return {
              ...obj,
              committed_count:
                selectedNit[0].crusher !== null ? selectedNit[0].crusher : "",
            };
          }
          return obj;
        });
        return finalList;
      });
    } else {
      setEquipmentAvailable(false);
      setAvailableForm((prev) => {
        const finalList = prev.map((obj) => {
          if (obj?.equipment_name === "Dozer") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Excavator") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Tipper") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Drill") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Water Tanker") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Grader") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Pay Loader") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Crusher") {
            return { ...obj, committed_count: "" };
          }
          return obj;
        });
        return finalList;
      });
      setUtilizedForm((prev) => {
        const finalList = prev.map((obj) => {
          if (obj?.equipment_name === "Tipper") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Excavator") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Drill") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Dozer") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Pay Loader") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Water Tanker") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Grader") {
            return { ...obj, committed_count: "" };
          }
          if (obj?.equipment_name === "Crusher") {
            return { ...obj, committed_count: "" };
          }
          return obj;
        });
        return finalList;
      });
    }
  }, [form?.nit_id]);

  useEffect(() => {
    setUtilizedForm((prev) => {
      const finalList = prev.map((obj, index) => {
        if (
          availableForm[index] &&
          availableForm[index].committed_count !== undefined
        ) {
          return {
            ...obj,
            committed_count: availableForm[index].committed_count,
          };
        }
        return obj;
      });
      return finalList;
    });
  }, [availableForm]);
  console.log("contractorDatacontractorData", nitList);
  useEffect(() => {
    if (form.nit_id === null || form.nit_id === "")
      setForm((prev) => ({ ...prev, po_id: "" }));
  }, [form.nit_id]);

  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <h3
              style={{ paddingTop: "5px", paddingBottom: "1px" }}
              className="title"
            >
              <MainBreadcrumb
                routeSegments={[
                  {
                    path: "#",
                    name: "Contractor Management",
                  },
                  {
                    name: "Contractor Performance",
                    path: "/contractor-management/contractor-performance",
                  },
                  {
                    name: "Add Contractor Performance",
                    path: "#",
                  },
                ]}
              />
            </h3>
            <h3 className="title d-flex align-items-end">
              Create Contractor Performance &nbsp;
              <span className="blueLabel">
                {moment().format("DD/MM/YYYY hh:mm A")}
              </span>
            </h3>
            <PerfectScrollbar className="customHeight">
              <div className="reportCard">
                <Form>
                  <Row>
                    <Col md={3}>
                      <Select
                        onChange={onChange}
                        value={form?.contractor_id?.toString()}
                        name="contractor_id"
                        options={contractorList}
                        label={"Contractor"}
                        data-testid={"contractor_testid"}
                        error={formFieldError?.contractor_id}
                        disabled={
                          !!param?.id ||
                          JSON.parse(localStorage.getItem("auth_user"))
                            ?.roleId === 3
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <Select
                        onChange={onChangeNit}
                        value={form?.nit_id}
                        name="nit_id"
                        options={nitList}
                        label={"NIT"}
                        error={formFieldError?.nit_id}
                        disabled={!!param?.id}
                      />
                    </Col>
                    <Col md={3}>
                      <Select
                        onChange={onChangeNit}
                        value={form?.po_id}
                        label={"PO"}
                        error={formFieldError?.po_id}
                        disabled={!!param?.id}
                        name="po_id"
                        options={poList.filter(
                          (d) => d.nit_id === form?.nit_id
                        )}
                        KEY_value={"id"}
                      />
                    </Col>
                    <Col md={3} className="">
                      <Form.Label className="form-label-input">Date</Form.Label>
                      <CustomDatePicker
                        value={form?.input_date}
                        dateFormat="YYYY-MM-DD"
                        isValidDate={(date) => disableFutureDate(date)}
                        name="input_date"
                        onChangeDate={onChange}
                        disabled={!!param?.id}
                        error={formFieldError?.input_date}
                      />
                    </Col>
                  </Row>
                  {contractorData && (
                    <div className="contractorForm">
                      <strong className="mb-0">Contractor Details</strong>
                      <Row>
                        {form?.contractor_id && (
                          <>
                            <Col>
                              <label>Contractor Code</label>
                              <p className="content">
                                {contractorData?.contractor_code}
                              </p>
                            </Col>
                            <Col>
                              <label>Contractor</label>
                              <p className="content">
                                {contractorData?.contractor}
                              </p>
                            </Col>

                            <Col>
                              <label>Contractor Person</label>
                              <p className="content">
                                {contractorData?.contractor_person}
                              </p>
                            </Col>
                          </>
                        )}

                        {form?.nit_id && (
                          <>
                            <Col>
                              <label>NIT / PO</label>
                              <p className="content">
                                {`${
                                  contractorData?.new_nit_number
                                } / ${findElementByArray(
                                  poList,
                                  form?.po_id,
                                  "id",
                                  "label"
                                )} `}
                              </p>
                            </Col>

                            <Col>
                              <label>Subsidiary</label>
                              <p className="content">
                                {contractorData?.nit_subsidiary}
                              </p>
                            </Col>
                            <Col>
                              <label>Mine</label>
                              <p className="content">
                                {contractorData?.nit_mine}
                              </p>
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  )}
                  {form?.nit_id && (
                    <span className="text-danger">{contractorStatus}</span>
                  )}
                  <Row className="mt-3">
                    <Col md={7}>
                      <CreateWebform
                        formFields={availableForm}
                        formFieldType={"available"}
                        name={"available"}
                        form={form?.contractorPerformanceData?.slice(0, 4)}
                        onChange={availableOnChange}
                        label={"Available"}
                        error={availableError}
                        // addWaterTankerRow={addWaterTankerRow}
                        // addGraderRow={addGraderRow}
                        // addPayLoaderRow={addPayLoaderRow}
                        disableAvailable={equipmentAvailable}
                        showNitRequirement={true}
                        showColumnName={true}
                        contractorData={contractorData}
                        updateDisable={param.id}
                        nitDataFetched={form?.nit_id ? true : false}
                      />
                    </Col>
                    <Col md={5}>
                      <CreateWebform
                        formFields={utilizedForm}
                        formFieldType={"utilized"}
                        name={"utilized"}
                        form={form?.contractorPerformanceData?.slice(4, 8)}
                        onChange={utilizedOnChange}
                        label={"Working"}
                        error={utilizedError}
                        // removeWaterTankerRow={removeWaterTankerRow}
                        // removeGraderRow={removeGraderRow}
                        // removePayLoaderRow={removePayLoaderRow}
                        // showNitRequirement={true}
                        disableAvailable={equipmentAvailable}
                        nitDataFetched={form?.nit_id ? true : false}
                      />
                    </Col>
                  </Row>
                </Form>

                <div className="btnSec">
                  <Button
                    onClick={() => closeFun()}
                    className="secondaryBtn"
                    data-testid="message-popp-buttonId"
                  >
                    Close
                  </Button>
                  <div className="d-flex">
                    <Button
                      name="save"
                      onClick={(event) => onSubmit(event)}
                      className="primaryBtn"
                    >
                      Save
                    </Button>
                  </div>
                  <div>
                    <Button
                      name="submit"
                      onClick={(event) => onSubmit(event)}
                      className="primaryBtn"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </Container>
          <MessagePopup
            title={title}
            messagePopupType={messagePopupType}
            show={showMessagePopUp}
            setShow={hideMessagePopup}
            closePopup={closeMessagePopup}
          />
          <StatusPopup
            messagePopupType={messagePopupType}
            show={showStatusPopUp}
            setShow={hideStatusPopup}
            closePopup={closeStatusPopup}
            invalidData={invalidData}
            closeInvalidPopUp={closeInvalidPopUp}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractorPerformanceAdd;
