import React, { useMemo, useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../Assets/Images/filter.svg";
import { Container } from "react-bootstrap";

import { useLocation, useNavigate } from "react-router-dom";
import {
  userFilterForm,
  userListApis,
  addLink,
  customTabsData,
  userLabel,
  IncidentuserListApis,
} from "../../SafetyManagementSystem/IncidentManagement/UserManagement/DATA";
import { Fuel_ROLE_OBJECT } from "../../../_services/Auth/helper";
import {
  capitalizeName,
  findUserPermissionedTabList,
} from "../../../_helpers/helper";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "./DATA";
import { userTabUrl } from "../DATA";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import UserManagementFilterForm from "../../UserManagement/UserManagementFilterForm";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../../_helpers/hooks";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import UserManagementView from "../../SafetyManagementSystem/IncidentManagement/UserManagement/UserManagementView";
import PermissionsFormEdit from "../../SafetyManagementSystem/IncidentManagement/UserManagement/PermissionsFormEdit";

const FuelUserList = () => {
  const [form, onChange, setForm] = useForm(userFilterForm);
  const [getAllFilter, setGetAllFilter] = useState(`&type=users`);
  const [filterStatus, setFilterStatus] = useState(0);
  const [isListData, setIsListData] = useState(true);
  const [customTabList, setCustomTabList] = useState(
    findUserPermissionedTabList(userTabUrl.fuelManagement)
  );

  let newAddLink = "/fuel-management-system/all-user-list";
  let newCustomTabsData = customTabsData;
  const { pathname } = useLocation();

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          className="secondaryBtn"
          onClick={() => setOpen(!open)}
          // rootClose
          data-testid="open-btn"
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>

        <UserManagementFilterForm
          setOpen={setOpen}
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
        />
      </div>
    );
  };

  useEffect(() => {
    setCustomTabList((prev) => {
      return prev.map((d) => {
        if (d.to === pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
  }, [pathname]);

  let newFilterBtn = FilterBtn;
  //   if (isContractorModuleAdmin()) {
  //     newCustomTabsData = customTabsData.filter((d, i) => {
  //       if (d.label === "User List") {
  //         return false;
  //       }
  //       return true;
  //     });
  //     newAddLink = "/contractor-management/user-list";
  //     newFilterBtn = null;
  //   }

  const getAll = async () => {
    const response = await IncidentuserListApis.getAll.api(
      `&type=users&page=1&limit=2`
    );
    // console.log('data --- >' , response.data.length);
    if (Array.isArray(response?.data) && response?.data?.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    console.log("formm", form);
    for (const key in form) {
      const element = form[key];
      console.log("element", element);
      if (element) {
        filter += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(`&type=users` + filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const onResetFilter = () => {
    setForm({ ...userFilterForm });
    setGetAllFilter(`&type=users`);
  };

  const columnFields = useMemo(
    () => [
      {
        name: userLabel?.name,
        selector: "firstName",
        sortable: true,
        minWidth: "200px",
        cell: (row) => {
          return capitalizeName(
            `${row?.firstName ? row?.firstName : ""} ${
              row?.lastName ? row?.lastName : ""
            }`
          );
        },
      },
      {
        name: userLabel?.roleId,
        selector: "roleId",
        sortable: true,
        cell: (row) => {
          return row?.role?.title || "";
        },
      },
      {
        selector: "siteId",
        name: userLabel?.siteId,
        sortable: true,
        cell: (row) => {
          return row?.site?.name || "";
        },
      },
      {
        name: userLabel?.email,
        selector: "email",
        sortable: true,
      },
      {
        sortable: false,
        name: "Permissions",
        selector: "accessPermissions",
        cell: (row) => {
          let role = row.accessPermissions?.map(
            (d) => Fuel_ROLE_OBJECT?.fuelManagement[d.permission]
          );
          let roleStr = role.filter((val) => val !== undefined);
          // if (role && role.length > 0) {
          let roleStrNew = roleStr.join(",").substring(0, 20);
          if (roleStrNew.length > 19) {
            roleStrNew = roleStrNew + "...";
          }
          // }
          //  else {
          //   roleStr = "";
          // }
          return roleStrNew;
        },
        minWidth: "200px",
      },
    ],
    []
  );

  const editAsPopup = (form, setShowAddEdit, getAll) => {
    return (
      <PermissionsFormEdit
        formData={form}
        setShowAddEdit={setShowAddEdit}
        getAll={getAll}
        USER_MANAGEMENT_ROLE_OBJECT={Fuel_ROLE_OBJECT}
      />
    );
  };

  const tabButtons = () => {
    return <CustomTabs tabeList={customTabList} />;
  };

  const viewAsPopup = (form) => {
    return (
      <UserManagementView form={form} USER_MANAGEMENT_ROLE={Fuel_ROLE_OBJECT} />
    );
  };

  //   const statusByOptions = (row) => {
  //     return !!(+row?.id !== LOGGED_USER_ID());
  //   };
  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  viewAsPopup={viewAsPopup}
                  fields={columnFields}
                  apis={IncidentuserListApis}
                  title={"User"}
                  // hideAddButton={true}
                  viewTitle="User Details"
                  editTitle="Edit Permissions"
                  customTableTitle={"Blank"}
                  FilterBtn={newFilterBtn}
                  editAsPopup={editAsPopup}
                  hideSnColumn
                  pageLink={newAddLink}
                  primaryKey="id"
                  pagination
                  hideDeleteButton
                  hideEditButton
                  getAllFilter={getAllFilter}
                  breadCrumb={[
                    {
                      name: "Fuel Management",
                      path: "#",
                    },
                    {
                      name: "User Management",
                      path: "#",
                    },
                  ]}
                  //   isNoTabOnPage
                  // editFunButton={onEdit}
                  childrenAfterAddButton={tabButtons}
                  //   statusByOptions={statusByOptions}
                  enableMobileIcon
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              tabButtons={tabButtons}
              hideAddBtn={true}
              // pageLink={newAddLink}
              breadCrumb={[
                {
                  name: "Fuel Management",
                  path: "#",
                },
                {
                  name: "User Management",
                  path: "#",
                },
              ]}
              customTableTitle={"Blank"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelUserList;
