import SafetySideBar from "../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData as contractorSideBar } from "../../_pages/ContractorManagement/DATA";
import ManageCardTab from "../../components/Common/ManageCardTab/ManageCardTab";
import { SidebarData as coalGradeSideBar } from "../../_pages/CoalGrade/DATA";
import { SidebarData as fuelManagementSideBar } from "../../_pages/FuelManagementSystem/FuelManagement/DATA";
import { EventFlowList, breadCrumb } from "./DATA";
import { useLocation } from "react-router-dom";
import React from "react";

const ImportSapUpload = () => {
  const urlFileType = useLocation();
  const breadcrumbText = breadCrumb(urlFileType.pathname);
  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar
          SidebarData={
            breadcrumbText?.[0] === "Coal Grade"
              ? coalGradeSideBar
              : breadcrumbText?.[0] === "Contractor Management"
              ? contractorSideBar
              : breadcrumbText?.[0] === "Fuel Management"||"HEMM Data"
              ? fuelManagementSideBar
              : null
          }
        />
        <ManageCardTab
          breadCrumbData={[
            {
              name: breadcrumbText?.[0],
              path: "/",
            },
            {
              name: "Configuration",
              path: breadcrumbText?.[1],
            },
             {
              name: urlFileType?.pathname?.includes("importExcelFile")?"Import Excel File":"Import SAP",
              path: "/",
            },
          ]}
          flowList={EventFlowList[breadcrumbText?.[0]]}
        />
      </div>
    </>
  );
};

export default ImportSapUpload;
