import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  bodyLabel,
  injuryCodeObj,
  objStaffType,
  viewStaffType,
} from "../../DATA";
import { DDMMYYYYFormat } from "../../../../../_helpers/dateHelper";

const PersonnelDetailsView = ({ form }) => {
  // console.log("formmmmmmmsssmsmsms" , form);
  return (
    <>
      {form?.personalDetails?.length ? (
        <>
          <Row>
            <Col md={3}>
              <label>Number of people involved</label>
              <p>{form?.peopleCount}</p>
            </Col>
            <Col md={3}>
              <label>Injuries Count</label>
              <p>{form?.number_of_injuries}</p>
            </Col>
            <Col md={2}>
              <label>Fatality Count</label>
              <p>{form?.number_of_fatality}</p>
            </Col>
            <Col md={4}>
              <label>Location of Accident/Incident</label>
              <p>{form?.place_of_incident}</p>
            </Col>
          </Row>
          <Row>
            {form?.personalDetails?.map((obj, idx) => {
              return (
                <>
                  <Col lg={3} key={"idx"}>
                    <label>Type</label>
                    <p>{viewStaffType[obj?.type]}</p>
                  </Col>
                  <Col lg={5}>
                    <label>Name</label>
                    <p>{obj?.name}</p>
                  </Col>
                  <Col lg={4}>
                    <label>Employer / Contractor</label>
                    <p>
                      {obj?.type === objStaffType.contractor
                        ? obj?.contractorData?.contractor || obj?.employer
                        : obj?.employer}
                    </p>
                  </Col>
                  <Col lg={3}>
                    <label>Gender</label>
                    <p>{obj?.gender}</p>
                  </Col>
                  <Col lg={3}>
                    <label>Age</label>
                    <p>{obj?.age}</p>
                  </Col>
                  <Col lg={3}>
                    <label>Hours at Work</label>
                    <p>{obj?.hoursAtWork}</p>
                  </Col>
                  <Col lg={3}>
                    <label>Form A No.</label>
                    <p>{obj?.formA_No}</p>
                  </Col>
                  <Col lg={3}>
                    <label>IME Date</label>
                    <p>{obj?.imeDate ? DDMMYYYYFormat(obj?.imeDate) : ""}</p>
                  </Col>
                  <Col lg={3}>
                    <label>PME Date</label>
                    <p>{obj?.pmeDate ? DDMMYYYYFormat(obj?.pmeDate) : ""}</p>
                  </Col>
                  <Col lg={2}>
                    <label>VTC Date</label>
                    <p>{obj?.vtcDate ? DDMMYYYYFormat(obj?.vtcDate) : ""}</p>
                  </Col>
                  <Col lg={4}>
                    <label>Designation</label>
                    <p>{obj?.designationData?.code+" - "+obj?.designationData?.name}</p>
                  </Col>
                  <Col lg={3}>
                    <label>Outcome</label>
                    <p>{obj?.outcome}</p>
                  </Col>
                  <Col lg={3}>
                    <label>Injury Code</label>
                    <p>{injuryCodeObj[obj?.injuryCode]}</p>
                  </Col>
                  <Col lg={6}>
                    <label>Body Parts Involved</label>
                    <p>
                      {obj?.bodyParts
                        ?.split(",")
                        ?.map((d) => bodyLabel[d])
                        ?.join(", ")}
                    </p>
                  </Col>
                  {form?.personalDetails?.length - 1 !== idx ? (
                    <div className="seperatorLine"></div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </Row>
        </>
      ) : (
        <span className="text-danger">Not Applicable</span>
      )}
    </>
  );
};

export default PersonnelDetailsView;
