import { RestMethod } from "../../../../_helpers/ApiConfig/RestMethod";

export async function getAllAreaOfOperation(filter) {
  let url = "/master-data/area-of-operation?";

  // if (filter) url += filter;

  try {
    const response = await RestMethod.GET(url);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
