const ProgressBar = (props) => {
  const { completed } = props;

  const containerStyles = {
    height: 24,
    width: "100%",
    backgroundColor: "rgb(169, 169, 163)",
    borderRadius: 5,
    textAlign: "center",
    position: "relative",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}`,
    backgroundColor: "green",
    borderRadius: "inherit",
    top: "0",
    position: "absolute",
  };

  const labelStyles = {
    zIndex: "99",
    color: "white",
    fontWeight: "500",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "100%",
  };

  return (
    <div className="progressBarBg" style={containerStyles}>
      <span style={labelStyles}>{`${completed}`}</span>
      <div style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
