import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";
import { submitStatus } from "../DATA";

export const sampleRelatedDataRules = [
  {
    label: "Date given for test",
    field_name: "date_given_for_test",
    isRequired: true,
    type: "string",
  },
  {
    label: "Code given to sample",
    field_name: "code_given_to_sample",
    isRequired: true,
    type: "string",
  },
  {
    label: "Date of test result recieved",
    field_name: "date_of_test_result_recieved",
    isRequired: true,
    type: "string",
  },
  {
    label: "Decoded By",
    field_name: "decoded_by",
    isRequired: true,
    type: "string",
  },
];

export const labQualityRules = [
  {
    label: "Checked by",
    field_name: "lab_result_checked_by",
    isRequired: true,
    type: "string",
  },
  {
    label: "Tested by",
    field_name: "lab_result_tested_by",
    isRequired: true,
    type: "string",
  },
  {
    field_name: "result_reporting_to_customer",
    label: "Result reporting date to customer",
    isRequired: true,
    type: "string",
  },
  {
    label: "Moisture",
    field_name: "lab_on_air_dry_bases_moisture",
    isRequired: true,
    type: "number",
    maxNumber: 100,
    minNumber: 0,
  },
  {
    field_name: "lab_on_air_dry_bases_ash",
    isRequired: true,
    type: "number",
    label: "Ash",
    maxNumber: 100,
    minNumber: 0,
  },
  {
    field_name: "lab_on_air_dry_bases_vm",
    isRequired: false,
    type: "number",
    maxNumber: 100,
    label: "VM",
    minNumber: 0,
  },
  {
    label: "GCV",
    isRequired: true,
    field_name: "lab_on_air_dry_bases_gcv",
    type: "number",
    minNumber: 0,
    // maxNumber: 100,
  },
  {
    label: "Moisture",
    field_name: "lab_on_em_bases_moisture",
    isRequired: true,
    type: "number",
    maxNumber: 100,
    minNumber: 0,
  },
  {
    label: "Ash",
    field_name: "lab_on_em_bases_ash",
    isRequired: true,
    type: "number",
    maxNumber: 100,
    minNumber: 0,
  },
  {
    label: "VM",
    field_name: "lab_on_em_bases_vm",
    isRequired: false,
    type: "number",
    minNumber: 0,
    maxNumber: 100,
  },
  {
    label: "GCV",
    field_name: "lab_on_em_bases_gcv",
    type: "number",
    // maxNumber: 100,
    isRequired: true,
    minNumber: 0,
  },
  {
    label: "Lab analyzed grade",
    field_name: "lab_analyzed_grade",
    isRequired: true,
    type: "string",
  },
];

export const sampleDecodingRegisterInitialForm = {
  date_given_for_test: "",
  code_given_to_sample: "",
  date_of_test_result_recieved: "",
  decoded_by: "",
  lab_result_checked_by: "",
  lab_result_tested_by: "",
  result_reporting_to_customer: "",
  lab_on_air_dry_bases_moisture: "",
  lab_on_air_dry_bases_ash: "",
  lab_on_air_dry_bases_vm: "",
  lab_on_air_dry_bases_gcv: "",
  lab_on_em_bases_moisture: "",
  lab_on_em_bases_ash: "",
  lab_on_em_bases_vm: "",
  lab_on_em_bases_gcv: "",
  lab_analyzed_grade: "",
};

export const sampleDecodingColumnFields = [
  {
    name: "Sample ID",
    selector: "sample_id",
    sortable: true,
  },
  {
    name: "Date given for Test​",
    selector: "date_given_for_test",
    sortable: true,
    cell: (row) =>
      row?.date_given_for_test ? DDMMYYYYFormat(row?.date_given_for_test) : "",
  },
  {
    name: "Date of Test Result recieved",
    selector: "date_of_test_result_recieved",
    sortable: true,
    cell: (row) =>
      row?.date_of_test_result_recieved
        ? DDMMYYYYFormat(row?.date_of_test_result_recieved)
        : "",
  },
  {
    name: "Code given to Sample",
    selector: "code_given_to_sample",
    sortable: true,
    isSearchable: true,
  },
  {
    name: "Decoded By",
    selector: "decoded_by",
    sortable: true,
  },
  {
    name: "Status",
    // selector: "sample_decoding_status",
    // sortable: true,
    minWidth: "160px",
    cell: (row) => {
      if (row?.sample_decoding_approval === "Pending") {
        if (row?.sample_decoding_status === submitStatus?.submitted) {
          return (
            <span className="statusSec submit">{submitStatus?.submitted}</span>
          );
        } else {
          return (
            <span className="statusSec draft">{submitStatus?.drafted}</span>
          );
        }
      } else {
        return (
          <span className="statusSec draft">
            {row?.sample_decoding_approval}
          </span>
        );
      }
    },
  },
  // {
  //   name: "System Feedback",
  //   selector: (row) => <GiGolfFlag className="feedbackIcon" />,
  //   center: true,
  // },
];

export const sampleDecodingFilterForm = {
  startDate: "",
  endDate: "",
  // mode_of_dispatch: "",
};

export const sampleDecodingData = {
  tableColumn: sampleDecodingColumnFields,
  filterForm: sampleDecodingFilterForm,
  pageLink: "/coal-grade/sample-decoding",
  tableTittle: "Sample De-Coding Register",
  type: "sample-decoding",
  role: ["lab_quality_manager"],
  // viewComponent: SamplePrepareRegisterView,
};
