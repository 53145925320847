import { useMemo } from "react";

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = (
  currentPage,
  totalCount,
  pageSize,
  siblingCount = 1
) => {
  const paginationRange = useMemo(() => {
    const DOTS = "dots";
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
        Case 1:
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
      */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
          Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
      */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
        We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
      */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
          Case 2: No left dots to show, but rights dots to be shown
      */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
          Case 3: No right dots to show, but left dots to be shown
      */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
          Case 4: Both left and right dots to be shown
      */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

export const TABLE_EXPEND_ROW = (rowData, sortableColumn) => {
  const { data } = rowData;
  const expandRow = sortableColumn.map((field) => {
    // console.log("888888", field["cell"] && field["cell"](data));

    // console.log(
    //   "sortttt",
    //   field["cell"] && field["cell"](data),
    //   field?.name,
    //   field["cell"] && field["cell"](data)?.props?.children,
    //   data[field?.sortField]
    // );
    if (!field?.isShowMobile) {
      return (
        <div className="tblCollapesedData">
          <div
            className={`row p-1 ml-1 ${
              field?.customClass ? field?.customClass : ""
            }`}
          >
            <strong>{field?.name} :</strong>
            {"  "}
            <label className={`col`}>
              {field?.cell
                ? // ? field["cell"](data)?.props?.children
                  field["cell"](data)
                : field?.sortField &&
                  (typeof data[field?.sortField] === "string" ||
                    typeof data[field?.sortField] === "number")
                ? data[field?.sortField]
                : ""}
            </label>
          </div>
        </div>
      );
    }
  });
  return expandRow;
};
