import React, { useEffect, useState } from "react";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import {
  initialFilters,
  exportUploadedFile,
  uploadedDataApis,
  reportTypeListObj,
  reportTypeListTableLabel,
  envTableData,
  SidebarData,
  reportTypeListUrl,
  exportUploadedFileByType,
} from "./DATA";
import { Button } from "reactstrap";
import filter from "../../../Assets/Images/filter.svg";
import { useForm } from "../../../_helpers/hooks";
import EmptyDataList from "../../../components/EmptyDataList/EmptyDataList";
import UploadFileView from "./UploadFileView";
import { getAllUploadedData } from "../../../../src/services/_uploadedFiles/uploadedFiles";
import UploadFileFilterForm from "../../../../src/_pages/EnvironmentManagement/UploadedFiles/UploadFileFilter";
import { permissionCheck } from "../../../_services/Auth/helper";
import { useLocation, useNavigate } from "react-router-dom";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import UploadedFileViewData from "./UploadedFileViewData";

const AirQualityUploadedData = () => {
  const [form, onChange, setForm] = useForm(initialFilters);
  const [filterStatus, setFilterStatus] = useState(0);
  const [getAllFilter, setGetAllFilter] = useState();
  const [isListData, setIsListData] = useState(true);

  const urlFileType = useLocation().search.replace("?type=", "");
  const tableListKey = reportTypeListObj[urlFileType];
  const tableList = envTableData[tableListKey];
  const viewAsPopup = (form) => {
    return (
      <UploadedFileViewData
        form={form}
        fileType={reportTypeListTableLabel[urlFileType]}
        urlFileType={urlFileType}
      />
    );
  };

  let navigate = useNavigate();

  const onEdit = (id) => {
    navigate(
      "/environment-management/manage/all-data/edit/" +
        id +
        "?type=" +
        urlFileType
    );
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    const onSubmitFilter = () => {
      setFilterStatus((prev) => prev + 1);
    };

    const onResetFilter = () => {
      setFilterStatus(0);
    };
    const getAll = async () => {
      const response = await getAllUploadedData(
        "fileType=" + reportTypeListObj[urlFileType]
      );
      if (Array.isArray(response?.data) && response?.data.length > 0) {
        setIsListData(true);
      } else setIsListData(false);
    };

    useEffect(() => {
      getAll();
    }, []);

    useEffect(() => {
      // getAll();
      if (filterStatus === 0) {
        setGetAllFilter("&fileType=" + reportTypeListObj[urlFileType]);
        setForm(initialFilters);
      } else {
        let filter = "&fileType=" + reportTypeListObj[urlFileType];
        for (const key in form) {
          const element = form[key];

          if (element) {
            if (key == "startDate" || key == "endDate")
              filter += `&${key}=${element}`;
            else filter += `&search[${key}]=${element}`;
          }
        }
        setGetAllFilter(filter);
      }
    }, [filterStatus]);

    return (
      <div className="filter">
        <Button
          className="filterBtn"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          data-testid="filter-button-upload-list"
          aria-controls="example-collapse-text"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <UploadFileFilterForm
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          onChange={onChange}
          onResetFilter={onResetFilter}
          form={form}
        />
      </div>
    );
  };

  const statusByOptions = (row) => {
    return true;
  };

  return isListData ? (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                fields={tableList}
                apis={uploadedDataApis}
                // title="Data"
                pageLink="/environment-management/uploaded-files"
                tableTitle={`${reportTypeListTableLabel[urlFileType]}`}
                FilterBtn={FilterBtn}
                primaryKey="id"
                getAllFilter={getAllFilter}
                hideAddButton={true}
                // hideHeader
                hideSnColumn={true}
                editFunButton={onEdit}
                breadCrumb={[
                  {
                    name: "Environment Management System",
                    path: "#",
                  },
                  {
                    name: "Environment Management",
                    path: "/environment-management/manage",
                  },
                  {
                    name: reportTypeListTableLabel[urlFileType],
                    path: "/environment-management/manage/" + urlFileType,
                  },
                  {
                    name: "Uploaded File Data",
                    path: "#",
                  },
                ]}
                //   viewAsPopup={viewAsPopup}
                downloadExcel={exportUploadedFileByType}
                customTableTitle={`${reportTypeListTableLabel[urlFileType]} Uploaded File Data`}
                deleteFun
                optionType={reportTypeListObj[urlFileType]}
                excelFilterOption={`fileType=${reportTypeListObj[urlFileType]}`}
                viewAsPopup={viewAsPopup}
                statusByOptions={statusByOptions}
                isNoTabOnPage
              />
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            pageLink={"/environment-management/uploaded-files"}
            title={`${reportTypeListTableLabel[urlFileType]} Uploaded File Data`}
            // hideAddBtn={
            //   !permissionCheck(["env_admin", "safety_admin", "env_user"])
            // }
            hideAddBtn={true}
            breadCrumb={[
              {
                name: "Environment Management System",
                path: "#",
              },
              {
                name: "Environment Management",
                path: "/environment-management/manage",
              },
              {
                name: reportTypeListTableLabel[urlFileType],
                path: "/environment-management/manage/" + urlFileType,
              },
              {
                name: "Uploaded File Data",
                path: "#",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AirQualityUploadedData;
