import logo from "../../Assets/Images/logo.jpg";
import Container from "react-bootstrap/Container";
import { Dropdown } from "react-bootstrap";
import { UserService } from "../../_services/UserService/UserService";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { logout, logoutUser } from "../../services/logoutService";
import "./header.scss";
import { useEffect, useRef, useState } from "react";
import {
  IS_MOBILE,
  UserPrivilege,
  auth_user,
  capitalizeName,
  isLogin,
} from "../../_helpers/helper";
import { getPermission, ROLE_OBJECT_VIEW } from "../../_services/Auth/helper";
import { IoNotificationsOutline } from "react-icons/io5";
import toastr from "toastr";
import Notification from "./Notification";

function Header() {
  const [firstChar, setChar] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [page, setPage] = useState(1);
  const [readableNotiId, setReadableNotiId] = useState([]);
  const [finalNotificationList, setFinalNotificationList] = useState([]);
  const [unReadIds, setUnReadIds] = useState([]);
  const [notificationList, setNotificationList] = useState([
    // {
    //   icon: "i-Speach-Bubble-6",
    //   message: "New message",
    //   description: "James: Hey! are you busy?",
    //   time: "2019-10-30T02:10:18.931Z",
    //   color: "primary",
    //   status: "New",
    // },
    // {
    //   icon: "i-Speach-Bubble-6",
    //   message: "New message",
    //   description: "James: Hey! are you busy?",
    //   time: "2019-10-30T02:10:18.931Z",
    //   color: "primary",
    //   status: "New",
    // },
  ]);
  const eleRef = useRef(null);
  const navigate = useNavigate();
  const signOut = async () => {
    await logoutUser();
    navigate(IS_MOBILE() ? "/mobile/auth/login" : "/auth/login");
  };

  const getAllNotification = async () => {
    const response = await UserService.getUserNotifications();
    if (!response?.status) return toastr.error(response?.message);
    const countLength = response?.data?.filter((d) => !d.readStatus)?.length;
    setNotificationCount(countLength || 0);
    const notificationRecord = response?.data?.map((d) => {
      let statusMssg = !d?.readStatus
        ? { status: "", color: "notRead" }
        : { status: "", color: "" };
      return { ...d, ...statusMssg };
    });
    // .slice(55, 75);
    setNotificationList(notificationRecord);
    setFinalNotificationList(notificationRecord.slice(0, 4));
    setUnReadIds([]);
  };

  const openNotification = () => {
    setIsOpen(!isOpen);
    !isOpen && checkReadUnread(finalNotificationList);
    if (isOpen) {
      setPage(1);
      !unReadIds?.length && getAllNotification();
      unReadIds?.length && updateNotificationReadStatus(unReadIds);
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    setTimeout(
      () =>
        setReadableNotiId((prev) => [
          // ...prev,
          ...finalNotificationList.map((d) => d?.id),
        ]),
      [1000]
    );
  }, [finalNotificationList, isOpen]);

  const updateNotificationReadStatus = async (ids) => {
    // console.log("checkId", ids);
    // getAllNotification();
    // return;
    const response = await UserService.updateNotificationReadStatus({
      notificationIds: ids,
    });
    if (!response?.status) return toastr.error(response?.message);
    getAllNotification();
  };

  const checkReadUnread = (readUnreadList) => {
    const finalUnReadIds = readUnreadList?.filter((d) => {
      return notificationList?.find(
        (obj) => obj?.id === d?.id && !obj?.readStatus
      )
        ? true
        : false;
    });
    setTimeout(
      () => setNotificationCount((prev) => prev - finalUnReadIds?.length),
      [1000]
    );
    setUnReadIds((prev) => [...prev, ...finalUnReadIds.map((d) => d?.id)]);
  };

  let onChangeScroll = (
    // type,
    matchCountNum,
    totalCountNum,
    refObj,
    setPageNum,
    pageNum
  ) => {
    if (!isOpen) return;
    let tableEl = refObj?.current;
    if (
      tableEl?.scrollTop > tableEl?.scrollHeight - tableEl?.offsetHeight - 1 &&
      +matchCountNum !== +totalCountNum
    ) {
      setPageNum((prev) => prev + 1);
      const notificationData = notificationList;
      const tillIndex =
        (pageNum + 1) * 4 > +totalCountNum ? +totalCountNum : (pageNum + 1) * 4;
      const filterList = notificationData.slice(+matchCountNum, tillIndex);
      setFinalNotificationList((prev) => [...prev, ...filterList]);
      checkReadUnread(filterList);
    }
  };

  useEffect(() => {
    const isAuthenticated = isLogin();
    if (!isAuthenticated) {
      return <Navigate to={"/auth/login"} />;
    }

    let data = auth_user();
    setChar(data?.email?.charAt(0)?.toUpperCase());

    let permissionData = getPermission();
    const FinalPermission = Object.keys(permissionData).filter((role) => {
      return !!permissionData[role];
    });
    console.log("FinalPermission+++++++", permissionData);

    setPermissions(FinalPermission);
    getAllNotification();
  }, []);

  return (
    <header className="main-header">
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/">
            <img src={logo} className="mainLogo" alt="logo" />
          </Link>

          <div className="d-flex align-items-center">
            <Dropdown className="notificationIcon">
              <Dropdown.Toggle id="dropdown-basic">
                <span className="blueLabel" style={{ fontSize: "18px" }}>
                  {UserPrivilege()}
                </span>
              </Dropdown.Toggle>
            </Dropdown>

            <Dropdown
              show={isOpen}
              className="notificationIcon"
              data-testid="openNotificationTestId"
              onClick={() => openNotification()}
            >
              <Dropdown.Toggle id="dropdown-basic">
                <span className="badge bg-danger position-absolute top-4 rounded-circle">
                  {notificationCount ? notificationCount : ""}
                </span>
                <IoNotificationsOutline size={35} className="" />
              </Dropdown.Toggle>

              <Dropdown.Menu
                data-testid="onChangeScrollTestId"
                ref={eleRef}
                onScroll={() =>
                  onChangeScroll(
                    finalNotificationList?.length,
                    notificationList?.length,
                    eleRef,
                    setPage,
                    page
                  )
                }
              >
                <Notification
                  notificationList={finalNotificationList}
                  readList={readableNotiId}
                  // updateNotification={updateNotificationReadStatus}
                />
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="user">
              <Dropdown.Toggle id="dropdown-basic" data-testid="dropDownId">
                <h4 className="userName mb-0">{firstChar}</h4>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Header>
                  {auth_user()
                    ? capitalizeName(
                        `${auth_user()?.firstName} ${
                          auth_user()?.lastName ?? ""
                        }`
                      )
                    : ""}
                  <div className="permission">
                    {permissions?.map((roleName, idx) => {
                      return (
                        <span key={idx}>{ROLE_OBJECT_VIEW?.[roleName]}</span>
                      );
                    })}
                  </div>
                </Dropdown.Header>
                <Dropdown.Item
                  data-testid="viewProfile"
                  onClick={() => navigate(`/view-profile`)}
                >
                  View Profile
                </Dropdown.Item>
                <Dropdown.Item
                  data-testid="changePassword"
                  onClick={() => navigate(`/change-password`)}
                >
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  data-testid="logOutId"
                  href="#"
                  onClick={signOut}
                >
                  Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
