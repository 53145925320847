import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import { avgValueOfArray } from "../../_helpers/commonHelper";

// Get Coal Grade Quality List With Server Side Pagination
export const getCoalGradeListWithPagination = async (filter) => {
  let url = "/coal-grade-quality-data/get-all-with-pagination";

  if (filter) url += "?" + filter;

  try {
    const response = await RestMethod.GET(url);
    const { status, message } = response.data;
    return {
      status,
      message,
      totalCount: response.data?.data?.totalCount,
      data: Array.isArray(response?.data?.data?.coalGradeQualityData)
        ? response?.data?.data?.coalGradeQualityData.map((data) => {
            if (!data?.sample_coding_users)
              return {
                ...data,
                sample_coding_users: { id: "", firstName: "" },
              };
            else return data;
          })
        : [],
    };
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return {
      data: [],
    };
  }
};

// Update Sample Coding

export const sampleCodingRegister = async (data) => {
  try {
    const response = await RestMethod.PUT(
      `/coal-grade-quality-data/sample-coding-register/${data?.id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return null;
  }
};

// Update Sample Deoding

export const sampleDeodingRegister = async (data) => {
  try {
    const response = await RestMethod.PUT(
      `/coal-grade-quality-data/sample-decoding-register/${data?.id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return null;
  }
};

// Update Third Party Result

export const thirdPartyResult = async (data) => {
  try {
    const response = await RestMethod.PUT(
      `/coal-grade-quality-data/third-party-lab-result/${data?.id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return null;
  }
};

// Update Referee Lab Result

export const refereeLabResult = async (data) => {
  try {
    const response = await RestMethod.PUT(
      `/coal-grade-quality-data/referee-lab-result/${data?.id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return null;
  }
};

export const createSamplePrepareRegister = async (data) => {
  let url = "/coal-grade-quality-data/sample-prepare-register";
  try {
    const response = await RestMethod.POST(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

// Export Excel
export const exportCoalGrade = async (filter) => {
  try {
    let url = URL_CONFIG.DEV_URL + "coal-grade-quality-data/export-excel";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      column: response?.data?.data?.column,
      data: response?.data?.data?.coalGradeQuality || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};

export async function coalGradeDispatchDistribution(filter) {
  try {
    const response = await RestMethod.GET(
      `/coal-grade-quality-data/dashabord-one/?${filter ? filter : ""}`
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function coalGradeDispatchTrend(filter) {
  try {
    const response = await RestMethod.GET(
      `/coal-grade-quality-data/dashabord-two/?${filter ? filter : ""}`
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function coalGradeSlippageReport(filter) {
  try {
    const response = await RestMethod.GET(
      `/coal-grade-quality-data/dashabord-three/?${filter ? filter : ""}`
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function coalGradeRefreeData(filter) {
  try {
    const response = await RestMethod.GET(
      `/coal-grade-quality-data/dashabord-four/?${filter ? filter : ""}`
      // `/coal-grade-quality-data/dashabord-four/?&static=${true}`
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function coalGradeSingleChart(fileType, filter) {
  try {
    const response = await RestMethod.GET(
      `/coal-grade-quality-data/single-dashboard-data/${fileType}?${filter}`
    );

    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error.message);
    return null;
  }
}

export async function coalGradeRrGradeList(filter) {
  try {
    const response = await RestMethod.GET(
      `/coal-grade/?${filter ? filter : ""}`
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export const getApproveReject = async (data, id) => {
  let url = "/coal-grade-quality-data/update-status/" + id;
  try {
    const response = await RestMethod.PUT(url, data);
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
};

//Get sales data
export const salesData = async (data) => {
  try {
    const response = await RestMethod.GET(
      `/coal-grade-quality-data/sales-data/?` + data
    );
    return response.data;
  } catch (error) {
    console.log("error detected while fetching data from api", error);
    return null;
  }
};

export async function apiSapData(filter) {
  try {
    const response = await RestMethod.GET(
      `/coal-grade-quality-data/sap-update/?${filter ? filter : ""}`
    );
    const { status, message, data } = response.data;
    let formatData = data?.rows.map((d) => {
      if (d.plant === "6008" && d.site === null) {
        return {
          ...d,
          site: {
            name: "Khadia",
            parentSite: {
              name: "NCL",
            },
          },
        };
      }
      if (d.plant === "6012" && d.site === null) {
        return {
          ...d,
          site: {
            name: "Dudhichua",
            parentSite: {
              name: "NCL",
            },
          },
        };
      }

      return d;
    });
    const d = {
      status,
      message,
      // totalCount: data?.count,
      data: formatData,
      lastSync: response.data.data.lastSync,
    };

    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}
export async function exportUpdateData(filter) {
  try {
    const response = await RestMethod.GET(
      `/coal-grade-quality-data/export-sap-update/?${filter ? filter : ""}`
    );
    const { status, message, data, fileName } = response.data;

    let formatData = data.map((d) => {
      if (d.Plant === "6008") {
        console.log("yes plant is 6008");
        return {
          ...d,
          Mine: "Khadia",
        };
      }
      if (d.Plant === "6012") {
        return {
          ...d,
          Mine: "Dudhichua",
        };
      }
      return d;
    });
    let d = { message, status: status, data: formatData, fileName: fileName };
    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export async function apiSapAllData(filter) {
  try {
    const response = await RestMethod.GET(
      `/coal-grade-quality-data/sap-list/?${filter ? filter : ""}`
    );
    const { status, message, data } = response.data;
    let formatData = data?.rows.map((d) => {
      if (d.plant === "6008" && d.site === null) {
        return {
          ...d,
          site: {
            name: "Khadia",
            parentSite: {
              name: "NCL",
            },
          },
        };
      }
      if (d.plant === "6012" && d.site === null) {
        return {
          ...d,
          site: {
            name: "Dudhichua",
            parentSite: {
              name: "NCL",
            },
          },
        };
      }

      return d;
    });

    const d = {
      status,
      message,
      totalCount: data?.count,
      data: formatData,
    };

    return d;
  } catch (error) {
    console.log("error detected while fetching data from api");
    return null;
  }
}

export const exportSapData = async (filter) => {
  try {
    let url = URL_CONFIG.DEV_URL + "coal-grade-quality-data/sap-list-export";
    if (filter) url += "?" + filter;

    const response = await RestMethod.GET(url);
    const { status, message, fileName } = response.data;
    return {
      status,
      message,
      fileName,
      data: response?.data?.data || [],
    };
  } catch (error) {
    return {
      data: [],
    };
  }
};
