import React from "react";
import { configuration } from "./DATA";
import SafetySideBar from "../../../src/_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../_pages/FuelManagementSystem/FuelManagement/DATA";
import ManageCardTab from "../../components/Common/ManageCardTab/ManageCardTab";

const FuelManagementConfiguration = () => {
  return (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <ManageCardTab
          breadCrumbData={[
            {
              name: "Fuel Management",
              path: "/",
            },
            {
              name: "Configuration",
              path: "#",
            },
          ]}
          flowList={configuration}
        />
      </div>
    </>
  );
};

export default FuelManagementConfiguration;
