import React, { useState, useEffect } from "react";
import DataTableWithServer from "../../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import { Button } from "reactstrap";
import { sapListApis, apiListColumnFields } from "./DATA";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import { tabUrl2 } from "../../../../_constants/Constant";
import { findUserPermissionedTabList } from "../../../../_helpers/helper";
import EmptyDataList from "../../../../components/EmptyDataList/EmptyDataList";
import { getAllMasterDropdownSamplingAgency } from "../../../../services/_reasonList/reasonList";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../DATA";
import FuelDispenseFilterForm from "./SapUpdateFilter";
import { useForm } from "../../../../_helpers/hooks";
import { sapFilterForm } from "./DATA";
import Filter from "../../../../Assets/Images/filter.svg";
import {
  apiSapData,
  exportUpdateData,
} from "../../../../services/_coalGrade/coalGrade";

const SapUpdate = () => {
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(sapFilterForm);
  const [lastSync, setLastSync] = useState({});
  let navigate = useNavigate();
  const getAll = async () => {
    const response = await apiSapData();
    setLastSync(response?.lastSync?.data);
    if (Array.isArray(response?.data) && !response?.data.length) {
      setIsListData(false);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  const onResetFilter = () => {
    setForm({ sapFilterForm });
  };

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };
  useEffect(() => {
    if (filterStatus === 0) return;
    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else if (key === "status") filter += `status=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }

    setGetAllFilter(filter);
  }, [filterStatus]);
  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          aria-expanded={open}
          data-testid="filterBtn"
          // className="d-none"
          onClick={() => setOpen(!open)}
        >
          <img src={Filter} alt="fliterIcon" className="img-fluid" />
        </Button>

        <FuelDispenseFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
        />
      </div>
    );
  };
 
  return isListData ? (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <Container fluid>
              <div className="customDataTbl">
                <DataTableWithServer
                  hideThreeDotButton
                  customTableTitle={"API status List"}
                  // viewTitle={""}
                  hideSearch={false}
                  FilterBtn={FilterBtn}
                  apis={sapListApis}
                  downloadExcel={exportUpdateData}
                  title="Data"
                  hideAddButton
                  fields={apiListColumnFields}
                  hideSnColumn
                  pageLink={"/coal-grade/configuration/api-status"}
                  pagination={false}
                  getAllFilter={getAllFilter}
                  hideDeleteButton
                  hideEditButton
                  listname={"API status"}
                  deleteFun
                  primaryKey="id"
                  breadCrumb={[
                    {
                      name: "Coal Grade",
                      path: "/",
                    },
                    {
                      name: "Configuration",
                      path: "/coal-grade/configuration",
                    },
                    {
                      name: "API Status Data",
                      path: "#",
                    },
                  ]}
                  isNoTabOnPage
                  apiUpdateView={lastSync}
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sidebarWrapper">
        <SafetySideBar SidebarData={SidebarData} />
        <div className="content" id="page-content-wrapper">
          <div className="contentArea">
            <EmptyDataList
              pageLink={"/coal-grade/configuration/api-status"}
              hideAddBtn
              title={"API status"}
              breadCrumb={[
                {
                  name: "Coal Grade",
                  path: "/",
                },
                {
                  name: "Configuration",
                  path: "/coal-grade/configuration",
                },
                {
                  name: "API Status Data",
                  path: "#",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SapUpdate;
