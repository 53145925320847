import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Row } from "react-bootstrap";
import Select from "../../../components/Select/Select";
import { getAllSites } from "../../../_services/SiteServices/SiteServices";
import FilterFormBtn from "../../../components/Common/FilterFormBtn/FilterFormBtn";

const CoalGradeUserFilterForm = ({
  form,
  onChange,
  onResetFilter,
  onSubmitFilter,
  isOpen,
  setOpen,
}) => {
  const [siteList, setSiteList] = useState([]);

  useEffect(() => {
    allSites();
  }, []);

  const allSites = async () => {
    const response = await getAllSites();
    console.log("qqqq" , response);
    if (!response?.status) return;
    setSiteList(response?.data);
  };

  return (
    <Collapse in={isOpen}>
      <div id="filter-collapse">
        <Row>
          <Col md={12}>
            <Select
              value={form?.siteId}
              name="siteId"
              options={siteList}
              label={"Location"}
              onChange={onChange}
              KEY_label={"name"}
              KEY_value={"id"}
            />
          </Col>
        </Row>
        <FilterFormBtn
          onSubmitFilter={onSubmitFilter}
          onResetFilter={onResetFilter}
          setOpen={setOpen}
        />
      </div>
    </Collapse>
  );
};

export default CoalGradeUserFilterForm;
