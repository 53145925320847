import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import filter from "../../../Assets/Images/filter.svg";
import DataTableWithServer from "../../../components/DataTableWithServer/DataTableWithServer";
import { Container } from "react-bootstrap";
import{getAllContractorByNit} from "../../../services/_contractorPerformance/contractorPerformance"
import {
  hindranceApis,
  fields,
  initialFilters,
  SidebarData,
  exportContractorHindrance,
  myHindranceApis,
  myHindranceHistoryApis,
  myActionHindranceApis,
  exportContractorActionHindrance,
  exportContractorHistoryHindrance,
  statusUpdateLink,
} from "./DATA";
import EmptyDataList from "./EmptyDataList";
import HindranceFilterForm from "../Hindrance/HindranceFilterForms";
import { getAllHindrance } from "../../../services/_hindrance/hindrance";
import { useForm } from "../../../_helpers/hooks";
import HindranceView from "./HindranceView";
import { useLocation, useNavigate } from "react-router-dom";
import "./hindrance.scss";
import { getAllContractor } from "../../../../src/services/_hindrance/hindrance";
import { findUserPermissionedTabList } from "../../../_helpers/helper";
import { tabUrl } from "../../../_constants/Constant";
import { permissionCheck } from "../../../_services/Auth/helper";
import SafetySideBar from "../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import MainBreadcrumb from "../../../components/Breadcrumb/MainBreadcrumb";
import CustomTabsForList from "../../../components/CustomTabs/CustomTabsForList";
import HindranceHistory from "./HindranceHistory";
const MyApprovalHindrance = () => {
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(initialFilters);
  const [contractorList, setContractorList] = useState([]);
  let [noTab, setNoTab] = useState("");
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.contractorHindrance)
  );

  let finalApi;
  let finalExport;
  let navigateTo;
  console.log("uuu",usrl.pathname)
  if (usrl.pathname.includes("action")) {
    navigateTo =
      "/contractor-management/my-approval-action-contractor-hindrance";
    finalApi = myActionHindranceApis;
    finalExport = exportContractorActionHindrance;
  } else if (usrl.pathname.includes("history")) {
    navigateTo =
      "/contractor-management/my-approval-history-contractor-hindrance";
    finalApi = myHindranceHistoryApis;
    finalExport = exportContractorHistoryHindrance;
  }

  let defaultName;

  useEffect(() => {
    const defaultTab = findUserPermissionedTabList(tabUrl.contractorHindrance);
    defaultName = "&tab=" + defaultTab[0]?.permission;
    setGetAllFilter(defaultName);
    setNoTab(defaultName);
    setCustomTabs(
      customTabs?.map((d, i) => {
        if (i === 0) {
          d.active = 1;
        } else {
          d.active = 0;
        }
        return d;
      })
    );
  }, []);
console.log("huhuhu",customTabs)
  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const onEdit = (id) => {
    navigate("/contractor-management/contractor-hindrance/update/" + id);
  };

  // const onApprove = (id) => {
  //   navigate(
  //     "/contractor-management/contractor-hindrance/approve/representative/" + id
  //   );
  // };
  const urlSearchValue = useLocation().search;

  const urlDataObj = {
    "?type=1": "&tab=section_incharge",
    "?type=2": "&tab=representative",
    "?type=3": "&tab=colliery_manager",
  };
  const urlData = urlDataObj[urlSearchValue];

  const onResetFilter = () => {
    setForm({});
    const defaultTab = findUserPermissionedTabList(tabUrl.contractorHindrance);
    let tabName = "&tab=" + defaultTab[0]?.permission;
    setGetAllFilter(urlData ? urlData : tabName);
  };
  const getAll = async () => {
    const response = await getAllHindrance();
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else {
      setIsListData(false);
    }

    const allContractorList = await getAllContractorByNit();

    if (Array.isArray(allContractorList) && allContractorList.length > 0) {
      setContractorList(
        allContractorList?.map((d) => {
          return { value: d.id, label: d.contractor };
        })
      );
    } else {
      setContractorList([]);
    }
  };
  useEffect(() => {
    getAll();
  }, []);

  const viewAsPopup = (form) => {
    return <HindranceView form={form} />;
  };

  const viewHistory = (data) => {
    return <HindranceHistory data={data} />;
  };
  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <HindranceFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          contractorList={contractorList}
        />
      </div>
    );
  };

  useEffect(() => {
    if (filterStatus === 0) return;
    const defaultTab = findUserPermissionedTabList(tabUrl.contractorHindrance);
    let tabName = "&tab=" + defaultTab[0]?.permission;
    let filter = urlData ? urlData : tabName;
    for (const key in form) {
      const element = form[key];
      if (element) {
        filter += `&filterForm[${key}]=${element}`;
      }
    }
    setGetAllFilter(filter);
  }, [filterStatus]);

  const apiChange = (name, type) => {
    // setDisabled(true);
    setCustomTabs(
      customTabs?.map((d) => {
        if (d.type === type) {
          d.active = 1;
        } else {
          d.active = 0;
        }
        return d;
      })
    );
    defaultName = `&tab=${name}`;
    setForm({});
    setGetAllFilter(defaultName);
    navigate(navigateTo + "?type=" + type);
  };

  const tabButtons = () => {
    return <CustomTabsForList tabeList={customTabs} apiChange={apiChange} />;
  };

  // return isListData ?
  return (
    <div className="sidebarWrapper">
      <SafetySideBar SidebarData={SidebarData} />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            {/* <h4>Contractor Performance</h4> */}
            <div className="customDataTbl">
              <DataTableWithServer
                apis={finalApi} 
                title="Data"
                data-testid="dataTableWithServer"
                pageLink="/contractor-management/my-approval-action-contractor-hindrance"
                FilterBtn={FilterBtn}
                primaryKey="id"
                hideSnColumn
                fields={fields}
                pagination
                ationStatusCol={true}
                permissionAddButton={"contractor"}
                getAllFilter={getAllFilter}
                hideAddButton={true}
                viewAsPopup={viewAsPopup}
                viewHistory={viewHistory}
                childrenAfterAddButton={tabButtons}
                downloadExcel={finalExport}
                listname={"hindrance"}
                modalSize={"xl"}
                editFun={onEdit}
                // approveFun={onApprove}
                customTableTitle={"Blank"}
                viewTitle={"Contractor Hindrance Details"}
                // actionBtn
                breadCrumb={[
                  {
                    name: "Contractor Management",
                    path: "#",
                  },
                  {
                    name: "Contractor Hindrance",
                    path: "/contractor-management/contractor-hindrance/module",
                  },
                  {
                    name: usrl.pathname.includes("action")
                      ? "My Approval Action"
                      : "My Approval History",
                    path: "#",
                  },
                ]}
                // isNoTabOnPage
                updateStatus={usrl.pathname.includes("history") ? false : true}
                excelFilterOption={urlData ? urlData.replace("&", "") : noTab}
                icons
                updatePageLink={statusUpdateLink}
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
          {/* <HindranceHistory/> */}
        </div>
      </div>
    </div>
  );
  // : (
  //   <div className="sidebarWrapper">
  //     <SafetySideBar SidebarData={SidebarData} />
  //     <div className="content" id="page-content-wrapper">
  //       <div className="contentArea">
  //         <h3
  //           className="title"
  //           style={{ paddingBottom: "1px", paddingTop: "5px" }}
  //         >
  //           <MainBreadcrumb
  //             routeSegments={[
  //               {
  //                 name: "Contractor Hindrance Management",
  //                 path: "#",
  //               },
  //               {
  //                 name: "Contractor Hindrance",
  //                 path: "/contractor-management/contractor-hindrance/module",
  //               },
  //               {
  //                 name: "View Contractor Hindrance",
  //                 path: "#",
  //               },
  //             ]}
  //             // module={ACTIVE_MODULE.incident}
  //           />
  //         </h3>
  //         <CustomTabsForList tabeList={customTabs} apiChange={apiChange} />
  //         <EmptyDataList
  //           // tabButtons={tabButtons}
  //           pageLink={"/contractor-management/contractor-hindrance"}
  //           title={"Contractor Hindrance"}
  //           hideAddBtn={!permissionCheck(["contractor"])}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default MyApprovalHindrance;
