import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormInputText from "../../../components/FormInputText/FormInputText";
import LabelCustom from "../../../components/LabelCustom/LabelCustom";
import Select from "../../../components/Select/Select";
import { coalGradeLabel } from "../DATA";
import CustomDatePicker from "../../../components/DateTimeCustom/CustomDateTime";

function SampleRelatedForm({
  codeGivenToSampleList,
  form,
  onChange,
  error,
  onChangeCodeGivenToSample,
}) {
  return (
    <div className="creationWrapper">
      <Form>
        <Row>
          <Col md={6}>
            <LabelCustom> {coalGradeLabel?.date_given_for_test} </LabelCustom>
            <CustomDatePicker
              value={form?.date_given_for_test}
              name="date_given_for_test"
              onChange={(e) => {
                onChange(e);
                onChangeCodeGivenToSample(e);
              }}
              error={error?.date_given_for_test}
            />
            {/* <InputError error={error?.date_given_for_test} /> */}
          </Col>
          <Col md={6}>
            <LabelCustom>
              {" "}
              {coalGradeLabel?.date_of_test_result_recieved}{" "}
            </LabelCustom>
            <CustomDatePicker
              value={form?.date_of_test_result_recieved}
              name="date_of_test_result_recieved"
              onChange={onChange}
              error={error?.date_of_test_result_recieved}
            />
            {/* <InputError error={error?.date_of_test_result_recieved} /> */}
          </Col>

          <Col md={6}>
            <Select
              onChange={onChange}
              value={form?.code_given_to_sample}
              name="code_given_to_sample"
              options={codeGivenToSampleList}
              error={error?.code_given_to_sample}
              label={coalGradeLabel?.code_given_to_sample}
            />
          </Col>
          <Col md={6}>
            <FormInputText
              type="text"
              placeHolder="Enter here"
              label={coalGradeLabel?.decoded_by}
              onChange={onChange}
              name="decoded_by"
              value={form?.decoded_by}
              error={error?.decoded_by}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default SampleRelatedForm;
