import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Filter from "../../Assets/Images/filter.svg";
import { capitalizeName } from "../../_helpers/helper";
import FilesExport from "../Common/FilesExport";

const TabWithScreen = ({
  screens,
  isActive,
  handleDivClick,
  tabLabelObj,
  handleShow,
  activeTab,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="dashboardTabs tabswithNumber">
        {Object.keys(screens)?.map((screen, idx) => {
          return (
            <ul
              key={idx}
              style={{
                display: isActive === +screen ? "flex" : "none",
              }}
            >
              {screens?.[screen]?.map((tabs, i) => {
                return (
                  <li
                    key={i}
                    onClick={() =>
                      navigate(`/environment-management/dashboard/${tabs}`)
                    }
                    className={`${activeTab === tabs ? "active" : ""}`}
                  >
                    {capitalizeName(tabLabelObj[tabs])}
                  </li>
                );
              })}
            </ul>
          );
        })}
        <div class="dashboardHeader">
          <div className="dashboardOptions">
            <div className="dashboardOpt">
              {Object.keys(screens)?.map((screen, idx) => {
                return (
                  <Link
                    className={isActive === +screen ? "active" : ""}
                    onClick={() => handleDivClick(+screen)}
                    key={idx}
                  >
                    <span className="">{screen}</span>
                  </Link>
                );
              })}
              <FilesExport id={`#${"screen"+activeTab}`} fullScreen/>
            </div>
            <div className="dashboardFilter envFilter" onClick={handleShow}>
              <img src={Filter} className="img-fluid" alt="filter" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabWithScreen;
