import React from "react";
import { MultiSelect } from "react-multi-select-component";
import "./multiselect.scss";

const MultiSelectWithCheckbox = ({
  className,
  options,
  value,
  onChange,
  disabled,
  label,
  disableSearch = true,
  hasSelectAll = true,
}) => {
  return (
    <MultiSelect
      className={className || ""}
      options={options || []}
      value={value || []}
      onChange={(e) => onChange(e)}
      labelledBy={"object"}
      disableSearch={disableSearch}
      disabled={!!disabled}
      menuIsOpen={true}
      overrideStrings={{
        selectSomeItems: label || "Select",
        allItemsAreSelected: "All items are selected.",
        selectAll: "Select All",
        search: "Search",
      }}
      hasSelectAll={hasSelectAll}
      // isOpen={true}
    />
  );
};

// use below code where you want to use multiselect

export default MultiSelectWithCheckbox;
