import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";
import { DDMMYYYYFormat } from "../../../_helpers/dateHelper";

const ContractorTenderDetails = ({ form, hindranceDate }) => {
  return (
    <Modal.Body className="p-0">
      <div className="preview">
        <div className="previewContent">
          <p className="head">Contractor Details</p>

          <Row>
            <Col>
              <label>Subsidiary</label>
              {form?.site ? (
                <p className="content">{form?.site?.parentSite?.name}</p>
              ) : (
                <p className="content">{form?.siteData?.parentSite?.name}</p>
              )}
            </Col>
            <Col>
              <label>Mine</label>
              {form?.site ? (
                <p className="content">{form?.site?.name}</p>
              ) : (
                <p className="content">{form?.siteData?.name}</p>
              )}
            </Col>
            <Col>
              <label>NIT / PO</label>

              <p className="content">
                {form?.nit?.nit} / {form?.po?.po}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Contractor Code</label>
              {form?.contractor ? (
                <p className="content">{form?.contractor?.contractor_code}</p>
              ) : (
                <p className="content">
                  {form?.contractorData?.contractor_code}
                </p>
              )}
            </Col>
            <Col>
              <label>Contractor</label>
              {form?.contractor ? (
                <p className="content">{form?.contractor?.contractor}</p>
              ) : (
                <p className="content">{form?.contractorData?.contractor}</p>
              )}
            </Col>
            <Col>
              <label>Contractor Person</label>
              {form?.contractor ? (
                <p className="content">{form?.contractor?.contractor_person}</p>
              ) : (
                <p className="content">
                  {form?.contractorData?.contractor_person}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            {hindranceDate && (
              <Col>
                <label>Hindrance Date</label>
                <p className="content">
                  {DDMMYYYYFormat(form?.input_date)}
                </p>{" "}
              </Col>
            )}
            <Col></Col>
          </Row>
        </div>
      </div>
    </Modal.Body>
  );
};

export default ContractorTenderDetails;
