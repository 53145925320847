import React, { useEffect, useState } from "react";
import {
  initialForm,
  validationRules,
  tankApi,
  tankLabel,
  types,
} from "./DATA";
import { Col, Form, Row, Button } from "react-bootstrap";
import moment from "moment";
import CustomDatePicker from "../../../../components/DateTimeCustom/CustomDateTime";
import FormInputText from "../../../../components/FormInputText/FormInputText";
import { getAllSites } from "../../../../_services/SiteServices/SiteServices";
import SafetySideBar from "../../../SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { useNavigate, useParams } from "react-router-dom";
import { SidebarData } from "../../FuelManagement/DATA";
import {
  auth_user,
  digitCheckAfterDecimal,
  IS_MOBILE,
  IS_USER_SITE_MINES,
} from "../../../../_helpers/helper";
import { useForm } from "../../../../_helpers/hooks";
import { Validation } from "../../../../_helpers/validation/Validation";
import {
  updateFuelStock,
  addFuelStock,
  getTankDataBydataId,
  getAllHemm,
  getStockData,
  exportMaxFuelSpaceAvailableQuantity,
} from "../../../../services/_fuelDispense/fuelDispense";
import toastr from "toastr";
import MainBreadcrumb from "../../../../components/Breadcrumb/MainBreadcrumb";
import Select from "../../../../components/Select/Select";
import { removeDigitsAfterTwo } from "../../../../_helpers/commonHelper";
import { initialMaxObj } from "../Receipt/DATA";

const StockAdd = () => {
  const params = useParams();
  const [mineList, setMineList] = useState([]);
  const [finalFuelLocationOpt, setFinalFuelLocationOpt] = useState([]);
  const [maxAvailable, setMaxAvailable] = useState(initialMaxObj);
  const [fuelLocationOpt, setFuelLocationOpt] = useState([]);
  const navigate = useNavigate();
  const [tankOption, setTankOption] = useState([]);
  const [form, onChange, setForm] = useForm(initialForm);
  const [error, setError] = useState({
    ...initialForm,
  });
  const onSubmit = async () => {
    if (
      (!form.openingDipMeasurement || !form.openingDip) &&
      (!form.closingDipMeasurement || !form.closingDip)
    ) {
      toastr.error("Atleast opening dip or closing dip values are required");
      return;
    }

    const errorObj = Validation(form, validationRules);
    setError(errorObj);
    if (Object.values(errorObj).filter((d) => d !== "").length) {
      toastr.error("All fields are required");
      return;
    }

    let response =
      params?.id || form.id
        ? await updateFuelStock(form, params.id || form.id)
        : await addFuelStock(form);

    if (!response?.status) {
      return toastr.error(response?.message || "something went wrong");
    }
    if (response?.status) toastr.success(`Saved Successfully`);

    if (IS_MOBILE()) navigate("/mobile/fuel-management-system");
    else navigate("/fuel-management-system/fuel-management-list/stock");
  };

  const getBowser = async () => {
    let filter = "isBowser=1";
    let response = await getAllHemm(filter);
    if (response?.status === 0) return;
    response?.data
      ? setFinalFuelLocationOpt(response?.data?.filter((d) => d?.status))
      : setFinalFuelLocationOpt([]);
  };

  async function fetchData() {
    let response = await tankApi.getById.api(params?.id);
    if (response?.status) {
      setForm(() => {
        return {
          type: response.data.type,
          site_id: response?.data?.site_id,
          tank_id: response?.data?.tank_id,
          date: response?.data?.date,
          openingDip: response?.data?.openingDip
            ? response?.data?.openingDip
            : null,
          closingDip: response?.data?.closingDip
            ? response?.data?.closingDip
            : null,
          closingDipMeasurement: response?.data?.closingDipMeasurement
            ? response?.data?.closingDipMeasurement
            : null,
          openingDipMeasurement: response?.data?.openingDipMeasurement
            ? response?.data?.openingDipMeasurement
            : null,
          useOpeningDip: response?.data?.useOpeningDip === "1" ? true : false,
          useClosingDip: response?.data?.useClosingDip === "1" ? true : false,
        };
      });
    }
  }
  useEffect(() => {
    if (form?.site_id) {
      let filter = `site_id=${form?.site_id}`;
      let id = null;
      getTankDataBydataId(id, filter).then((response) => {
        if (!response?.data) return;
        console.log(
          " response.data response.data response.data",
          response.data
        );
        setTankOption(
          response.data
            .filter((d) => d?.status)
            .map((d) => {
              return { label: d.name, value: d.id };
            })
        );
      });
    }
  }, [form?.site_id]);
  const mineLists = async () => {
    let list = [];
    const response = await getAllSites(
      "filter[status]=1&filter[activeStatus]=active"
    );

    if (response.status) {
      list = response.data?.map((d) => {
        return { value: d.id, label: d.name };
      });
    }
    setMineList(list);
  };
  useEffect(() => {
    mineLists();
    getBowser();
  }, []);
  useEffect(() => {
    if (params.id) fetchData();
  }, [params.id, setForm]);

  useEffect(() => {
    if (!IS_USER_SITE_MINES()) return;
    const siteObj =
      mineList?.find((d) => +d?.value === +auth_user()?.siteId) || {};
    setForm((prev) => ({
      ...prev,
      site_id: siteObj?.value,
    }));
  }, [mineList]);

  useEffect(() => {
    const filteredLocations =
      finalFuelLocationOpt?.filter((d) => +d?.site_id === +form?.site_id) || [];
    const locationList = filteredLocations.map((d) => ({
      ...d,
      label: d.hemm_id,
      value: d.id,
    }));
    setFuelLocationOpt(locationList);
  }, [finalFuelLocationOpt, form?.site_id]);

  const getStock = async () => {
    let filter = `type=${form?.type}&mine=${form?.site_id}&tankId=${form?.tank_id}&date=${form?.date}`;
    let checkStock = await getStockData(filter);
    // if (checkStock.status) {
    let data = checkStock.data;
    setForm((prev) => ({
      type: prev.type,
      site_id: prev.site_id,
      tank_id: prev.tank_id,
      date: prev.date,
      id: data.id,
      openingDipMeasurement: data?.openingDipMeasurement || "",
      closingDipMeasurement: data?.closingDipMeasurement || "",
      openingDip: data?.openingDip || "",
      closingDip: data?.closingDip || "",
      useOpeningDip: data?.useOpeningDip === "1" ? true : false,
      useClosingDip: data?.useClosingDip === "1" ? true : false,
    }));
    // } else {
    //   setForm((prev) => ({
    //     type: prev.type,
    //     site_id: prev.site_id,
    //     tank_id: prev.tank_id,
    //     date: prev.date,
    //     id: "",
    //     openingDipMeasurement: null,
    //     closingDipMeasurement: null,
    //     openingDip: null,
    //     closingDip: null,
    //     useOpeningDip: "",
    //     useClosingDip: "",
    //   }));
    // }
  };

  useEffect(() => {
    if (
      form?.type &&
      form?.site_id &&
      form?.tank_id &&
      form?.date &&
      tankOption &&
      fuelLocationOpt
    )
      getStock();
  }, [
    form?.type,
    form?.site_id,
    form?.tank_id,
    form?.date,
    tankOption,
    fuelLocationOpt,
  ]);

  useEffect(() => {
    if (form?.openingDipMeasurement)
      setForm((prev) => {
        return {
          ...prev,
          openingDipMeasurement: removeDigitsAfterTwo(
            +form?.openingDipMeasurement
          ),
        };
      });
  }, [form?.openingDipMeasurement]);
  useEffect(() => {
    if (form?.closingDipMeasurement)
      setForm((prev) => {
        return {
          ...prev,
          closingDipMeasurement: removeDigitsAfterTwo(
            +form?.closingDipMeasurement
          ),
        };
      });
  }, [form?.closingDipMeasurement]);

  const getMaxReceiptCapacity = async () => {
    let filter = `type=${form.type === "tank" ? "tank" : "bowser"}`;
    if (form.date) filter += `&date=${form.date}`;
    let response = await exportMaxFuelSpaceAvailableQuantity(
      form?.tank_id,
      filter
    );
    if (response.data.status) setMaxAvailable(response.data.data);
    else setMaxAvailable(initialMaxObj);
  };

  useEffect(() => {
    if (form?.tank_id && form.type && form.date) getMaxReceiptCapacity();
  }, [form?.tank_id, form.type, form.date]);

  return (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={"Add Stock"}
        goBack={() => navigate(-1)}
      />
      <div id="page-content-wrapper" className="content">
        <div className="contentArea">
          <div className="creationWrapper">
            <h3
              style={{ paddingBottom: "1px", paddingTop: "5px" }}
              className="title"
            >
              {!IS_MOBILE() ? (
                <MainBreadcrumb
                  routeSegments={[
                    {
                      path: "/",
                      name: "Fuel Management system",
                    },
                    {
                      name: "Fuel Management",
                      path: "/fuel-management-system",
                    },
                    {
                      path: "#",
                      name: params.id ? "Edit DIP" : "Add DIP",
                    },
                  ]}
                />
              ) : (
                ""
              )}
            </h3>
            <div className="reportCard">
              <div className="formWrapper">
                <div className="maverickFormCard">
                  <div className="creationWrapper">
                    <Form>
                      <Row>
                        <Col lg={3}>
                          <Select
                            onChange={onChange}
                            name="type"
                            options={types}
                            label={tankLabel.type}
                            value={form?.type}
                            error={error?.type}
                          />
                        </Col>
                        <Col lg={3}>
                          <Select
                            onChange={onChange}
                            name="site_id"
                            options={mineList}
                            label={tankLabel.site_id}
                            value={form?.site_id}
                            error={error?.site_id}
                            disabled={IS_USER_SITE_MINES()}
                          />
                        </Col>
                        <Col lg={3}>
                          <Select
                            onChange={onChange}
                            name="tank_id"
                            options={
                              form.type === "tank"
                                ? tankOption
                                : fuelLocationOpt
                            }
                            label={
                              form.type === "tank"
                                ? tankLabel.tank_id
                                : "Bowser Name"
                            }
                            value={+form?.tank_id}
                            error={error?.tank_id}
                          />
                        </Col>
                        <Col lg={3}>
                          <Form.Label className="form-label-input">
                            {tankLabel.date}
                          </Form.Label>
                          <CustomDatePicker
                            value={form.date}
                            isValidDate={(day) =>
                              day.isSameOrAfter(moment().subtract(3, "d")) &&
                              day.isSameOrBefore(moment())
                            }
                            name="date"
                            onChange={onChange}
                            // timeFormat={"HH:mm"}
                            dateFormat="YYYY-MM-DD"
                          />
                          <span className="small text-danger">
                            {error.date}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <FormInputText
                            name="openingDipMeasurement"
                            value={form.openingDipMeasurement}
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              onChange({ target: { name, value } })
                            }
                            type="number"
                            class="form-control"
                            placeholder=""
                            label={tankLabel.openingDipMeasurement}
                            error={error?.openingDipMeasurement}
                            // disabled={!form.useOpeningDip}
                          />
                        </Col>
                        <Col md={4}>
                          <FormInputText
                            name="openingDip"
                            value={form.openingDip}
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              digitCheckAfterDecimal(value, 2) &&
                              onChange({ target: { name, value } })
                            }
                            type="number"
                            class="form-control"
                            placeholder=""
                            label={tankLabel.openingDip}
                            error={error?.openingDip}
                            // disabled={!form.useOpeningDip}
                          />
                        </Col>
                        <Col md={4} className="mb-3">
                          <div
                            className={IS_MOBILE() ? "d-flex" : "d-flex mt-4"}
                            style={{ gap: IS_MOBILE() ? "5px" : "35px" }}
                          >
                            <input
                              type="checkbox"
                              id={`useOpeningDip`}
                              name={`useOpeningDip`}
                              value={form.useOpeningDip}
                              checked={form.useOpeningDip}
                              onChange={onChange}
                            />
                            <label
                              className={IS_MOBILE() ? "" : "mt-2"}
                              htmlFor={`useOpeningDip`}
                              style={{
                                fontSize: IS_MOBILE() ? "15px" : "",
                                marginTop: IS_MOBILE() ? "11px" : "",
                              }}
                            >
                              {tankLabel.useOpeningDip}
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <FormInputText
                            name="closingDipMeasurement"
                            value={form.closingDipMeasurement}
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              onChange({ target: { name, value } })
                            }
                            type="number"
                            class="form-control"
                            placeholder=""
                            label={tankLabel.closingDipMeasurement}
                            error={error?.closingDipMeasurement}
                            // disabled={!form.useClosingDip}
                          />
                        </Col>
                        <Col md={4}>
                          <FormInputText
                            name="closingDip"
                            value={form.closingDip}
                            onChange={({ target: { name, value } }) =>
                              value >= 0 &&
                              digitCheckAfterDecimal(value, 2) &&
                              onChange({ target: { name, value } })
                            }
                            type="number"
                            class="form-control"
                            placeholder=""
                            label={tankLabel.closingDip}
                            error={error?.closingDip}
                            // disabled={!form.useClosingDip}
                          />
                        </Col>
                        <Col md={4} className="mb-3">
                          <div
                            className={IS_MOBILE() ? "d-flex" : "d-flex mt-4"}
                            style={{ gap: IS_MOBILE() ? "5px" : "35px" }}
                          >
                            <input
                              type="checkbox"
                              name={`useClosingDip`}
                              id={"useClosingDip"}
                              value={form.useClosingDip}
                              checked={form.useClosingDip}
                              onChange={onChange}
                            />
                            <label
                              className={IS_MOBILE() ? "" : "mt-2"}
                              htmlFor={"useClosingDip"}
                              style={{
                                fontSize: IS_MOBILE() ? "15px" : "",
                                marginTop: IS_MOBILE() ? "11px" : "",
                              }}
                            >
                              {tankLabel.useClosingDip}
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Note: Dip data to be entered in L after conversion
                        </Col>
                      </Row>
                      {form.tank_id && (
                        <Row>
                          <Col lg={4} className="mt-3">
                            <p>
                              Tank Max Capacity (L) :{" "}
                              {maxAvailable?.maxCapacity}
                            </p>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <p>
                              Available Fuel Capacity in Tank (L) :{" "}
                              {maxAvailable?.availableCapacity}
                            </p>
                          </Col>
                          <Col lg={4} className="mt-3">
                            <p>
                              Fuel Level in Tank (L) : {maxAvailable?.tankLevel}
                            </p>
                          </Col>
                        </Row>
                      )}
                    </Form>
                    <div className="btnSec">
                      <Button
                        data-testid="next-btn"
                        onClick={() =>
                          !IS_MOBILE()
                            ? navigate(`/fuel-management-system`)
                            : navigate(`/mobile/fuel-management-system`)
                        }
                        className="secondaryBtn iconBtnForm"
                      >
                        Back
                      </Button>
                      <Button
                        data-testid="next-btn-submit"
                        onClick={() => onSubmit()}
                        className="primaryBtn iconBtnForm"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockAdd;
