import React from "react";
import { Form, FormGroup } from "react-bootstrap";
import "./Select.scss";
import InputError from "../../_form/InputError/InputError";
import { SearchableSelect } from "../SearchableSelect/SSingleSelect";
import Asterik from "../../Common/Asterik/Asterik";

function Select({
  label,

  name,
  value,
  onChange,
  disabled,

  blankSelect = "Select",
  style,
  array,
  object,

  menuPortalTarget,
  menuPlacement,
  className,
  error,
  errorClassName,

  KEY_label,
  KEY_value,

  options,
  component = null,
  placeholder,
  isCreatable,
  asterikRules,
}) {
  return (
    <FormGroup className="mb-3">
      {label && (
        <Form.Label>
          {label}
          <Asterik fieldName={name} requiredRules={asterikRules} />
        </Form.Label>
      )}

      <SearchableSelect
        name={name ? name : ""}
        value={value}
        onChange={onChange}
        disabled={disabled ? disabled : false}
        options={options}
        KEY_label={KEY_label ? KEY_label : "label"}
        KEY_value={KEY_value ? KEY_value : "value"}
        blankSelect={blankSelect}
        className={className}
        style={style ? style : {}}
        array={array}
        object={object}
        menuPortalTarget={menuPortalTarget}
        component={component}
        placeholder={placeholder}
        menuPlacement="auto"
        label={label}
        isCreatable={isCreatable}
      />

      {error && <InputError error={error} className={errorClassName} />}
    </FormGroup>
  );
}

export default Select;
