import { CONTRACTOR_DASHBOARD_FILTER, FUEL_DASHBOARD_FILTER } from "./CONSTANT";
const initialState = {
  contractorDash: {},
  fuelDash: {},
};

export const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTRACTOR_DASHBOARD_FILTER:
      return { ...state, contractorDash: action.data };
    case FUEL_DASHBOARD_FILTER:
      return { ...state, fuelDash: action.data };
    default:
      return state;
  }
};
