import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Container } from "react-bootstrap";
import {
  fuelDispenseApis,
  tabUrl,
  fuelDispenseColumnFields,
  statusListObj,
  fuelManagementFilterForm,
  pageLink,
} from "./DATA";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FuelDispenseFilterForm from "./FuelDispenseFilterForm";
import FuelDispenseView from "./FuelDispenseView";
import filter from "../../Assets/Images/filter.svg";
import DataTableWithServer from "../../components/DataTableWithServer/DataTableWithServer";
import EmptyDataList from "../../components/EmptyDataList/EmptyDataList";
import { useForm } from "../../_helpers/hooks";
import SafetySideBar from "../../_pages/SafetyManagementSystem/SafetySideBar/SafetySideBar";
import { SidebarData } from "../../_pages/FuelManagementSystem/FuelManagement/DATA";
import {
  exportFuelDispense,
  getFuelDispenseListWithPagination,
} from "../../services/_fuelDispense/fuelDispense";
import "./fuelmgmt.scss";
import {
  IS_MOBILE,
  findUserPermissionedTabList,
  isDateFromThreeDaysBack,
  isDateFromYesterday,
} from "../../_helpers/helper";
import { permissionCheck } from "../../_services/Auth/helper";
const FuelManagementList = () => {
  const [customTabs, setCustomTabs] = useState(
    findUserPermissionedTabList(tabUrl.fuelManagement)
  );
  const params = useParams();
  let navigate = useNavigate();
  const usrl = useLocation();
  const [isListData, setIsListData] = useState(true);
  const [getAllFilter, setGetAllFilter] = useState();
  const [filterStatus, setFilterStatus] = useState(0);
  const [form, onChange, setForm] = useForm(fuelManagementFilterForm);
  let authUser = localStorage.getItem("auth_user");
  let user = authUser ? JSON.parse(authUser) : "";
  useEffect(() => {
    setCustomTabs((prev) => {
      return prev.map((d) => {
        if (d.to === usrl.pathname) {
          return {
            ...d,
            active: 1,
          };
        }
        return d;
      });
    });
    getAll();
  }, []);

  const getAll = async () => {
    let response;
    if (params?.id)
      response = await getFuelDispenseListWithPagination(
        "page=1&limit=2&createdBy=" + user?.id
      );
    else response = await getFuelDispenseListWithPagination("page=1&limit=2");
    if (Array.isArray(response?.data) && response?.data.length > 0) {
      setIsListData(true);
    } else setIsListData(false);
  };

  const onResetFilter = () => {
    setForm({ fuelManagementFilterForm });
    if (!params?.id) setGetAllFilter();
    else setGetAllFilter("&createdBy=" + user?.id);
  };

  useEffect(() => {
    getAll();
  }, []);

  const onSubmitFilter = () => {
    setFilterStatus((prev) => prev + 1);
  };

  const viewAsPopup = (form) => {
    return <FuelDispenseView form={form} />;
  };

  const FilterBtn = () => {
    const [open, setOpen] = useState(false);

    return (
      <div className="filter">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="secondaryBtn"
          data-testid="filter-button-Fuel-management"
        >
          <img src={filter} className="img-fluid" alt="fliterIcon" />
        </Button>
        <FuelDispenseFilterForm
          form={form}
          onChange={onChange}
          onResetFilter={onResetFilter}
          onSubmitFilter={onSubmitFilter}
          isOpen={open}
          setOpen={setOpen}
          setForm={setForm}
          showFuelFilterOptions={"true"}
          bowserText={"Bowser Dispensed From"}
        />
      </div>
    );
  };

  const onEdit = (id) => {
    navigate(`${IS_MOBILE() ? "/mobile" : ""}/fuel-management-system/${id}`);
  };

  useEffect(() => {
    if (filterStatus === 0) return;

    let filter = "";
    for (const key in form) {
      const element = form[key];
      if (element) {
        if (key === "startDate" || key === "endDate")
          filter += `&${key}=${element}`;
        else filter += `&search[${key}]=${element}`;
      }
    }
    if (params?.id) filter += `&createdBy=${user?.id}`;
    setGetAllFilter(filter);
  }, [filterStatus]);

  useEffect(() => {
    if (!params?.id) return;
    let filter = `&createdBy=${user?.id}`;
    setGetAllFilter(filter);
  }, [params?.id]);

  const statusByOptions = (row) => {
    if (
      row?.status === statusListObj?.draft ||
      (permissionCheck(["fuel_admin"]) === true &&
        isDateFromThreeDaysBack(row?.createdAt) === true &&
        !params?.id)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const deleteCheckFn = (row) => {
    return row.status === "Draft";
  };
  return isListData ? (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={params?.id ? "My Fuel Dispenses" : "View Fuel Dispenses"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <Container fluid>
            <div className="customDataTbl">
              <DataTableWithServer
                deleteCheck={deleteCheckFn}
                fields={fuelDispenseColumnFields}
                apis={fuelDispenseApis}
                title="Data"
                FilterBtn={FilterBtn}
                viewAsPopup={viewAsPopup}
                hideSnColumn
                pageLink={pageLink}
                primaryKey="id"
                pagination
                deleteFun={true}
                hideEditButton={false}
                getAllFilter={getAllFilter}
                // hideAddButton
                breadCrumb={[
                  {
                    name: "Fuel Management System",
                    path: "/",
                  },
                  {
                    name: "Fuel Management",
                    path: "/fuel-management-system",
                  },
                  {
                    name: params?.id
                      ? "My Fuel Dispense"
                      : "View Fuel Dispense",
                    path: "#",
                  },
                ]}
                // childrenAfterAddButton={tabButtons}
                downloadExcel={exportFuelDispense}
                customTableTitle={
                  params?.id ? "My Fuel Dispenses" : "Fuel Dispenses"
                }
                editFunButton={onEdit}
                viewTitle={"Fuel Dispense Details"}
                isNoTabOnPage
                hideAddButton
                statusByOptions={statusByOptions}
                editPage={params?.id ? true : false}
              />

              {/* <UploadFileModel model3={model3} toggle={toggle} onSubmitImportFile={onSubmitImportFile} fileName={"coi_request.xlsx"} fileTitle={"coi_request.xlsx"} /> */}
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${IS_MOBILE() ? "mobileSidebarWrapper" : "sidebarWrapper"}`}
    >
      <SafetySideBar
        SidebarData={SidebarData}
        pageTitle={params?.id ? "My Fuel Dispenses" : "View Fuel Dispenses"}
        goBack={() => navigate(-1)}
      />
      <div className="content" id="page-content-wrapper">
        <div className="contentArea">
          <EmptyDataList
            // pageLink={pageLink}
            breadCrumb={[
              {
                name: "Fuel Management System",
                path: "/",
              },
              {
                name: "Fuel Management",
                path: "/fuel-management-system",
              },
              {
                name: params?.id ? "My Fuel Dispense" : "View Fuel Dispense",
                path: "#",
              },
            ]}
            title={params?.id ? "My Fuel Dispenses" : "View Fuel Dispenses"}
            hideAddBtn
          />
        </div>
      </div>
    </div>
  );
};

export default FuelManagementList;
