import React from "react";
import { Col, Modal } from "react-bootstrap";
import { Row } from "reactstrap";

const EnvConfigPermissibleView = ({ form }) => {
  const words = form?.type.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const title = capitalizedWords.join(" ");
  return (
    <>
      <Modal.Body className="p-0">
        <div className="preview">
          <div className="previewContent">
            <p className="head">Environment Configuration for {title}</p>
            <Row>
              <Col sm={4}>
                <label>Category</label>
                <p className="content">{form?.category}</p>
              </Col>
              {form?.type === "CAAQMS_permissible" ||
                (form?.type === "CMPDIL_permissible" && (
                  <>
                    <Col sm={4}>
                      <label>PM 10</label>
                      <p className="content">{form?.pm10}</p>
                    </Col>
                    <Col sm={4}>
                      <label>PM 2.5</label>
                      <p className="content">{form?.pm25}</p>
                    </Col>
                    <Col sm={4}>
                      <label>SO 2</label>
                      <p className="content">{form?.so2}</p>
                    </Col>
                  </>
                ))}
              {form?.type === "CAAQMS_permissible" && (
                <>
                  <Col sm={4}>
                    <label>PM 10</label>
                    <p className="content">{form?.pm10}</p>
                  </Col> <Col sm={4}>
                    <label>PM 2.5</label>
                    <p className="content">{form?.pm25}</p>
                  </Col> <Col sm={4}>
                    <label>SO2</label>
                    <p className="content">{form?.so2}</p>
                  </Col> <Col sm={4}>
                    <label>NO</label>
                    <p className="content">{form?.no}</p>
                  </Col>
                  <Col sm={4}>
                    <label>CO</label>
                    <p className="content">{form?.co}</p>
                  </Col>
                  <Col sm={4}>
                    <label>NOX</label>
                    <p className="content">{form?.nox}</p>
                  </Col>
                </>
              )}
              {form?.type === "CMPDIL_permissible" && (
                <>
                  {" "}
                  <Col sm={4}>
                    <label>NO 2</label>
                    <p className="content">{form?.no2}</p>
                  </Col>
                  <Col sm={4}>
                    <label>SPM</label>
                    <p className="content">{form?.spm}</p>
                  </Col>
                </>
              )}

              {form?.type === "noise_permissible" && (
                <>
                  <Col sm={4}>
                    <label>Decibel Day</label>
                    <p className="content">{form?.dba_day}</p>
                  </Col>
                  <Col sm={4}>
                    <label>Decibel Night</label>
                    <p className="content">{form?.dba_night}</p>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default EnvConfigPermissibleView;
