export function getAuthHeader(access_token){
    return {
        method: 'GET',
        redirect: 'follow',
        headers: { 'Authorization': `Bearer ${access_token}` }
    };
}


export function getQuickSightParams(url, container){
    return {
        url: url,
        container: container,
        width:"100%",
        height:"100%",
        undoRedoDisabled: true, 
        resetDisabled: true,
        withIframePlaceholder: true
    };
}

export function getQuickSightUrl(id_token){
    return `https://digicoal-api.cilhq.coalindia.in/qsembed?mode=getUrl&urlType=dashboard&openIdToken=${id_token}&timestamp=${new Date().getTime()}`
}